/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getResult = /* GraphQL */ `query GetResult(
  $id: String!
  $bucket: String!
  $key: String!
  $top_k: Int
  $filter: AWSJSON
) {
  getResult(id: $id, bucket: $bucket, key: $key, top_k: $top_k, filter: $filter)
}
` as GeneratedQuery<APITypes.GetResultQueryVariables, APITypes.GetResultQuery>;
