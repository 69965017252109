import React from 'react';
import { LanguageSelect } from "..";
import { Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AccountDropdown from "./AccountDropdown";
import { useAuthentication, useAuthorization } from "../../store";
import { AuthState } from '../../store';
import { useTranslation } from "react-i18next";

import './AppNavbar.scss';
// import navbarLogo from "../../assets/JNP-Soft-OptiCat-Logo-RGB.svg"
import navbarLogoTA from "../../assets/TecAlliance-Logo-Web.svg"
const navbarTitle = "Part identification"

const AppNavbar = () => {
  const { userInfo, cognitoUser } = useAuthorization();
  const { authState, signOut, signIn } = useAuthentication();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSignOut = React.useCallback(async () => {
    await signOut();
    navigate("/");
  }, [signOut, navigate]);

  return (
    <Navbar className="navbar-fill" variant="light">
      <div className="container-fluid">
        <div className="col">
          <Navbar.Brand>
            <img
              src={navbarLogoTA}
              className="App-logo navbar-brand__logo"
              alt="logo"
            />
            {/* &nbsp;
            <img
              src={navbarLogo}
              className="App-logo navbar-brand__logo"
              alt="logo"
            /> */}
          </Navbar.Brand>
        </div>
        <div className="col-auto">
          <Navbar.Brand className="navbar-brand__title">
            {navbarTitle}
          </Navbar.Brand>
        </div>
        <Nav className="col justify-content-end">
          {authState === AuthState.SignedIn && cognitoUser && userInfo ? (
            <>
              <AccountDropdown
                userInfo={userInfo}
                handleSignOut={handleSignOut}
              />
            </>
          ) : null}
          {authState !== AuthState.SignedIn && <button className='btn btn-secondary bs-secondary me-2 pt-0 pb-0' onClick={signIn}>{t("Login")}</button>}
          {<LanguageSelect />}
        </Nav>
      </div>
    </Navbar>

  )
}

export default AppNavbar