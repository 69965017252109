/**
 * The reason for i18n slice is to separate the i18n logic from the i18n instance itself
 * In TecRMI Service Book, we have mulitple cases where the translations in some languages are not necessary
 * Mostly used for language selector
 */

import {
  supportedLngs,
  defaultLanguage,
} from "../../i18n";

import { ICountries } from '../../components/shared/countryList/ICountry'




export const createI18nSlice = (set: any, get: any) => ({
  availableLanguages: supportedLngs,
  selectedLanguage: defaultLanguage,
  countryCode: "",
  countries: {},

  setLanguage: (language: string) =>
    set((state: any) => {
      (state.selectedLanguage = language);
    }),
  setAvailableLanguages: (languages: any) =>
    set((state: any) => {
      state.availableLanguages = languages;
    }),
  resetAvailableLanguages: () =>
    set((state: any) => {
      state.availableLanguages = supportedLngs;
    }),
  loadCountriesByLangCode: async (langCode: string) => {
    const res:ICountries = await import(
      `../../components/shared/countryList/countryList.${langCode}.js`
    );

    set((state: any) => {
      state.countries[langCode] = res.default;
    });

    return res.default;
  },
  getCountriesByLangCode: async (langCode: string) => {
    const { countries, loadCountriesByLangCode } = get();
    let targetLanguageCountry = countries[langCode];

    if (!targetLanguageCountry) {
      targetLanguageCountry = await loadCountriesByLangCode(langCode);
    }

    return targetLanguageCountry;
  },
  getCountriesByCurrentLangCode: async () => {
    const { selectedLanguage, getCountriesByLangCode } = get();
    return await getCountriesByLangCode(selectedLanguage);
  },
});
