import React from "react";

import { langCodeToCountryCode } from "../../../i18n";

export const useLocalizationUtils = () => {
  const toCountryCode = React.useCallback(
    ({ langCode }) => langCodeToCountryCode[langCode] || langCode,
    []
  );

  return { toCountryCode };
};
