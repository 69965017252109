import { useRef } from 'react';
import { useConfigState } from '../../store';
import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';

const ShowMetadataSelector = () => {
    const showMetadata_ref = useRef<HTMLInputElement>(null)
    const { t } = useTranslation()
    const { setMetadataSelector, metadataSelector } = useConfigState()

    const change = (elem: any) => {
        if (showMetadata_ref.current?.value) {
            setMetadataSelector(Boolean(showMetadata_ref.current?.checked))
        }
    }

    return (
        <>
            <Form>
                <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch-MetadataSelector"
                    label={t('Config.MetadataSelector')}
                    onChange={(elem) => change(elem)}
                    ref={showMetadata_ref}
                    checked={metadataSelector}
                />
            </Form>
        </>
    )
}

export default ShowMetadataSelector