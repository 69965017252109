import { useStore } from "./useStore";

export const useConfigState = () => {

    const {
        top_k,
        setTop_k,
        top_productCategories,
        setTop_productCategories,
        metadataSelector, 
        setMetadataSelector,
        showProductCategories,
        setshowProductCategories,        
    } = useStore();

    return {
        top_k,
        setTop_k,
        top_productCategories,
        setTop_productCategories,
        metadataSelector,
        setMetadataSelector,
        showProductCategories,
        setshowProductCategories,
    }
}