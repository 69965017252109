import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import DE from "./de.json";
import EN from "./en.json";

export const defaultLanguage = "de";

const resources = {
  en: {
    default: EN,
  },
  de: {
    default: DE,
  }
};

export const supportedLngs = [
  "en",
  "de",
];

// Mapping when langCode !== countryCode
export const langCodeToCountryCode = {
  cs: "cz",
  da: "dk",
  sv: "se",
};

const languageDetector: any = {
  type: 'languageDetector',
  // eslint-disable-next-line no-unused-vars
  init: function(services: any, detectorOptions: any, i18nextOptions: any) {
    // no init needed
  },
  detect: function() {
    const { language } = window.navigator;
    const [langCode] = language.split("-");
    if (supportedLngs.includes(langCode)) {
      return langCode;
    }

    return 'en';
  },
  // eslint-disable-next-line no-unused-vars
  cacheUserLanguage: function(langCode: any) {
    // no cache
  }
}

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
  resources,
  supportedLngs,
  defaultNS: "default",
  interpolation: {
    escapeValue: false,
  }
});

export default i18n;
