import { useStore } from "./useStore";

export const useAuthentication = () => {
  const {
    authState,
    authInitialized,
    authError,
    cognitoUser,
    signOut,
    signIn,
    setAuthState,
    setAuthError,
    initializeAuthentication,
    expiredPassword,
    setExpiredPassword,
    populatedDataForExpiredPassword,
    setPopulatedDataForExpiredPassword
  } = useStore();

  return {
    // States
    authState,
    authInitialized,
    authError,
    cognitoUser,
    signOut,
    signIn,
    // Effects
    setAuthState,
    setAuthError,
    initializeAuthentication,
    expiredPassword,
    setExpiredPassword,
    populatedDataForExpiredPassword,
    setPopulatedDataForExpiredPassword
  };
};
