import { useRef } from 'react';
import { useConfigState } from '../../store';
import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';

const ShowProductCategories = () => {
    const showProductCategories_ref = useRef<HTMLInputElement>(null)
    const { t } = useTranslation()
    const { setshowProductCategories, showProductCategories } = useConfigState()

    const change = (elem: any) => {
        if (showProductCategories_ref.current?.value) {
            setshowProductCategories(Boolean(showProductCategories_ref.current?.checked))
        }
    }

    return (
        <>
            <Form>
                <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch-showProductCategories"
                    label={t('Config.ProductCategories')}
                    onChange={(elem) => change(elem)}
                    ref={showProductCategories_ref}
                    checked={showProductCategories}
                />
            </Form>
        </>
    )
}

export default ShowProductCategories