// import React from 'react';
import { useTranslation } from 'react-i18next';

import tecAllianceLogo from '../../assets/tecalliance-logo.png';
// import { AuthState, useAuthentication, useAuthorization } from '../../store';

export default function Footer() {
  const {t} = useTranslation();
  // const {authState} = useAuthentication();
  // const {userInfo} = useAuthorization();

  // const isSignedIn = authState === AuthState.SignedIn && userInfo;

  return (
    <footer className='footer'>
      <div className='links'>
        <a href={t('Legal notice_Link')} target='_blank' rel='noreferrer'>
          {t('Legal notice')}
        </a>{' '}
        |
        <a
          href={t('Data Privacy & Cookies_Link')}
          target='_blank'
          rel='noreferrer'
        >
          {t('Data Privacy & Cookies')}
        </a>{' '}
        |
        <a
          href={t('Licence Information_Link')}
          target='_blank'
          rel='noreferrer'
        >
          {t('Licence Information')}
        </a>
      </div>

      <div className='logo'>
        <a href='https://www.tecalliance.net/' target='_blank' rel='noreferrer'>
          <img
            src={tecAllianceLogo}
            className='App-logo'
            alt='logo'
            height='25'
          />
        </a>
      </div>
    </footer>
  );
}
