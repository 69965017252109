import React, { useEffect, } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import './App.scss';
// import FileUpload from './components/FileUpload/FileUpload'
import { AppNavbar, DropZone, Config } from './components';

import { Amplify, } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { AuthState, useAuthentication, useI18nState } from './store';
import awsconfig from './modified_aws-export';
import Footer from './components/Footer/Footer';
// import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

function App() {

  const { authState, authInitialized, initializeAuthentication } = useAuthentication();
  const { setLanguage, selectedLanguage } = useI18nState();

  useEffect(() => {
    if (!authInitialized) {
      initializeAuthentication();
    }
  }, [authInitialized, initializeAuthentication])

  React.useEffect(() => {
    console.log(selectedLanguage)
    // setLanguage(selectedLanguage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLanguage]);

  return (
    <Router>
      <div className="App container">
        <AppNavbar></AppNavbar>
        <main className="login-container">
          {authState === AuthState.SignedIn ?
            <Routes>
              <Route path="/" element={<DropZone />}></Route>
              <Route path="/config" element={<Config />}></Route>
              {/* <DropZone></DropZone> */}
            </Routes>
            : null
          }
        </main>
        <Footer />
      </div>
    </Router>
  );
}


export default App;
