
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import './DropZone.scss';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ImageCanvas from './ImageCanvas';
import { v4 as uuidv4 } from 'uuid'
import { Amplify, Storage, API } from 'aws-amplify';
import aws_config from '../../modified_aws-export';
import { GetResultQueryVariables, GetResultQuery } from '../../API';
import { GraphQLQuery, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as queries from '../../graphql/queries';
import ItemResults from '../ItemResults/ItemResults';
import { useConfigState } from '../../store';
import MetadataSelector from './MetadataSelector';
import { Link } from 'react-router-dom';
import ShowProductCategories from '../Config/ShowProductCategories';
// import { useNavigate } from "react-router-dom";

interface DropZoneProps {
}

const DropZone: React.FC<DropZoneProps> = () => {
    const [files, setFiles] = useState([]);
    const [count, setCount] = useState(0);
    const [uploadfile, setUploadfile] = useState<File>()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [uploadkey, setUploadkey] = useState<string>()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [uuid, setUuid] = useState<string>()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [filter, setFilter] = useState<object>({})
    const [result, setResult] = useState<any>([])
    const [displayResults, setDisplayResult] = useState<any>([])
    const [oldResults, setOldResults] = useState<any>([])
    // const navigate = useNavigate();

    const { t } = useTranslation();
    const { top_k, metadataSelector, showProductCategories } = useConfigState()

    useEffect(() => {
        if (uploadfile && count === 0) {
            Amplify.configure(aws_config);
            uploadImage(uploadfile)
            setCount(count + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadfile])

    useEffect(() => {
        if (uuid && uploadkey) {
            pineconeQuery(uuid, aws_config.aws_user_files_s3_bucket, uploadkey, Number(top_k), JSON.stringify(filter))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    useEffect(() => {
        if (result && result['ungrouped']) {
            let display: any = result['ungrouped']
            if (showProductCategories) {
                display = result['grouped']
            }
            setDisplayResult(display)
            setOldResults([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showProductCategories, result])

    const resetComponent = () => {
        if (oldResults && oldResults.length > 0) {
            setDisplayResult(oldResults)
            setOldResults([])
        } else {
            setCount(0);
            setFiles([]);
            setResult([]);
            setDisplayResult([]);
            setOldResults([])
            setUploadfile(undefined)
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        maxFiles: 1,
        onDrop: (acceptedFiles: any) => {
            setFiles(acceptedFiles.map((file: File) => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        },
        onDropRejected: (rejectedFiles: any) => {
            toast(t("DropZone.maxFilesExceeded"), { type: "error" })
        },
    });

    const pineconeQuery = async (id: string, bucket: string, key: string, top_k: number, filter: string) => {
        let result_pinecone = await API.graphql<GraphQLQuery<GetResultQuery>>({
            query: queries.getResult,
            variables: {
                id: id,
                bucket: bucket,
                key: key,
                top_k: top_k,
                filter: filter,
            } as GetResultQueryVariables,
            authMode: GRAPHQL_AUTH_MODE.API_KEY
        })
        if (result_pinecone.data) {
            let pinecone_response = result_pinecone.data?.getResult
            if (pinecone_response) {
                let parsed_results = JSON.parse(pinecone_response)
                if (parsed_results['Error']) {
                    toast(parsed_results['Error'], { type: "error" })
                    resetComponent()
                } else {
                    setResult(groupByProductCategory(parsed_results.matches))
                }
            }
        }
    }

    const groupByProductCategory = (results: any): any[] => {
        const lowestScoreObjects: any = {};
        const groupObjects: any = [];

        results.forEach((match: any) => {
            const genArtNo = match.metadata.GenArtNo;
            const score = match.score;

            if (!groupObjects[genArtNo]) {
                groupObjects[genArtNo] = []
            }
            groupObjects[genArtNo].push(match)

            if (!lowestScoreObjects[genArtNo] || score < lowestScoreObjects[genArtNo].score) {
                lowestScoreObjects[genArtNo] = match;
            }
        });
        groupObjects['grouped'] = JSON.parse(JSON.stringify(Object.values(lowestScoreObjects).sort((a: any, b: any) => a.score - b.score)));
        groupObjects['grouped'].forEach((element: any) => {
            let genart = element.metadata['GenArtNo']
            let totalScore = 0;
            for (const item of groupObjects[genart]) {
                totalScore += item.score;
            }

            const averageScore = totalScore / groupObjects[genart].length;
            element['elements'] = groupObjects[genart]
            element['average_score'] = averageScore
        });
        groupObjects['ungrouped'] = results
        console.log(groupObjects)
        return groupObjects
    }

    const uploadImage = async (uploadFile: File) => {
        var id = uuidv4()
        setUuid(id)
        var filename = id + ".png"
        // console.log('Upload the image: ' + filename);
        Storage.configure({ level: 'public' });
        var result = await Storage.put(filename, uploadFile, {
            contentType: "image/png",
            metadata: { 'top_k': top_k.toString() }
        });

        if (result) {
            let key = 'public/' + filename
            setUploadkey(key)
            await pineconeQuery(id, aws_config.aws_user_files_s3_bucket, key, Number(top_k), JSON.stringify(filter))

        }
    }

    const updateDisplayResults = (newResults: any) => {
        setOldResults(displayResults)
        setDisplayResult(newResults)
    }

    return (
        <div className='card border-0'>
            {!files || files.length === 0 ?
                <div {...getRootProps()} className='dropzone'>
                    <input {...getInputProps()} />
                    {
                        <>
                            <p>{t("DropZone.text")}</p>
                            <button className="btn btn-secondary  mt-2" type="button" >{t("Button.selectFile")}</button>
                        </>
                    }
                </div>
                :
                <>
                    <ImageCanvas file={files[0]} outputFunction={setUploadfile} />
                    {metadataSelector && result['ungrouped'] ? <MetadataSelector items={result['ungrouped']} onChange={setFilter}></MetadataSelector> : null}
                    <ShowProductCategories></ShowProductCategories>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                    <Link to={'/'} onClick={() => {
                        resetComponent()
                    }}>
                        <button className="btn btn-secondary mt-4" style={{position:"relative", top:"-20px"}} type="button" disabled={!result['ungrouped'] || result['ungrouped'].length === 0 ? true : false}>
                            {!result['ungrouped'] || result['ungrouped'].length === 0 ?
                                <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {t('DropZone.loading')}
                                </>
                                :
                                t("Button.back")
                            }
                        </button>
                    </Link>

                    <div className="row">
                        {/* <div className="card-group results"> */}
                        {
                            displayResults.map((elem: any) => {
                                return (
                                    <div className="col justify-content-center d-flex" key={elem.id}>
                                        <ItemResults score={elem.score as number} average_score={elem.average_score} metadata={elem.metadata} elements={elem.elements} updateDisplayResults={updateDisplayResults}></ItemResults>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                    {!result['ungrouped'] || result['ungrouped'].length === 0 ?
                        null
                        :
                        <Link to={'/'} onClick={() => {
                            resetComponent()
                        }}>
                            <button className="btn btn-secondary  mt-2" type="button" disabled={!result['ungrouped'] || result['ungrouped'].length === 0 ? true : false}>
                                {t("Button.back")}
                            </button>
                        </Link>
                    }
                </>
            }
        </div>
    )
}

export default DropZone