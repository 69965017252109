import { useRef, useState } from 'react';

interface DropFile extends File {
    preview: string
}
interface ImageCanvasProps {
    file: DropFile
    outputFunction: any
}



const ImageCanvas: React.FC<ImageCanvasProps> = ({ file, outputFunction }) => {


    const canvR = useRef<HTMLCanvasElement>(null)

    const [scale, setScale] = useState<number>(0);
    const [uploadFile, setUploadfile] = useState<number>(0);
    const maxHeight = 250;
    const maxWidth = 400;

    const image = new window.Image();
    image.src = file.preview;
    image.onload = () => {
        var yScale = (maxHeight / image.naturalHeight)
        var xScale = (maxWidth / image.naturalWidth)
        if (yScale < xScale) {
            setScale(yScale)
        } else {
            setScale(xScale)
        }
    };

    if (canvR != null && canvR.current != null && uploadFile === 0) {
        const canvas = canvR.current
        canvas.height = image.naturalHeight * scale
        canvas.width = image.naturalWidth * scale
        var ctx = canvas.getContext('2d');
        ctx?.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            (image.naturalWidth * scale),
            (image.naturalHeight * scale),
        )
        canvas.toBlob((blob) => {
            if (blob) {
                // Create a File object from the Blob
                const file = new File([blob], "tmp.png", { type: 'image/jpeg' });
                setUploadfile(uploadFile + 1)
                outputFunction(file)
            }
        }, 'image/png')
    }

    return (
        <div className='d-flex justify-content-center'>
            <canvas ref={canvR} height={maxHeight} width={maxWidth}></canvas>
        </div>
    )
}

export default ImageCanvas