import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelect } from "downshift";
import React from "react";

import { useI18nState } from "../../store/hooks/useI18nState";
import "../shared/components/AutocompleteDropdown.scss";
import { Flag } from "../shared/components/Flag";
import { useLocalizationUtils } from "../shared/hooks";

const LanguageSelect = () => {
  const { availableLanguages, selectedLanguage, setLanguage } = useI18nState();
  const { toCountryCode } = useLocalizationUtils();
  const onLanguageSelect = React.useCallback(
    ({ selectedItem }) => setLanguage(selectedItem),
    [setLanguage]
  );
  const toggleRef = React.useRef();
  const listRef = React.useRef();

  // Set correct position for icons and options list
  React.useEffect(() => {
    if (
      toggleRef.current &&
      listRef.current &&
      toggleRef.current.getClientRects().length > 0
    ) {
      const { height, width } = toggleRef.current.getClientRects()[0];

      const svg = toggleRef.current.querySelector("svg");
      if (svg) {
        svg.style.height = height + "px";
        svg.style.width = "10px";
      }

      listRef.current.style.width = width + "px";
      listRef.current.style.top = height + "px";
    }
  }, [toggleRef, listRef]);

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: availableLanguages,
    onSelectedItemChange: onLanguageSelect,
    selectedItem: selectedLanguage,
  });
  return (
    <div className="autocomplete-dropdown language-select">
      <button
        type="button"
        {...getToggleButtonProps({
          ref: toggleRef,
          className: "autocomplete-dropdown-toggle-btn justify-content-center",
        })}
      >
        <div className="d-flex pt-1 mx-4">
          <Flag
            className="mb-1 me-2"
            countryCode={toCountryCode({ langCode: selectedItem })}
          />
          {selectedItem.toUpperCase()}
        </div>
        <FontAwesomeIcon className="float-end" icon={faChevronDown} />
      </button>
      <div
        {...getMenuProps({
          ref: listRef,
          className: isOpen
            ? "autocomplete-dropdown-options-wrapper open"
            : "autocomplete-dropdown-options-wrapper",
        })}
      >
        {isOpen &&
          availableLanguages.map((item, index) => (
            <div
              className="option d-flex align-items-center justify-content-center"
              {...getItemProps({ item, index })}
              key={item}
              id={item}
            >
              <Flag
                countryCode={toCountryCode({ langCode: item })}
                className="mb-1 me-2"
              />
              {item.toUpperCase()}
            </div>
          ))}
      </div>
    </div>
  );
};

export default LanguageSelect;
