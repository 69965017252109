import { Form } from "react-bootstrap";
// import { useTranslation } from "react-i18next";
import React from "react";

import { supportedLngs } from "../../i18n";

const langCodeToLanguageName = {
  en: "English",
  de: "Deutsch",
  fr: "Français",
  nl: "Nederlands",
  it: "Italiano",
  es: "Español",
  cs: "Česky",
  pt: "Português",
  da: "Dansk",
  no: "Norsk",
  fi: "Suomi",
  sv: "Svenska"
};

export const LanguageSelectFormField = React.forwardRef((props, ref) => {
  // const { t } = useTranslation();

  return (
    <Form.Control as="select" className="form-select" {...props} ref={ref}>
      {supportedLngs.map((langCode) => (
        <option key={langCode} value={langCode}>
          {langCodeToLanguageName[langCode]}
        </option>
      ))}
    </Form.Control>
  );
});

LanguageSelectFormField.displayName = "LanguageSelectFormField";

LanguageSelectFormField.propTypes = {};
