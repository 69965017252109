interface configState {
    top_k: number;
    top_productCategories: number;
    metadataSelector: boolean;
    showProductCategories: boolean;
}
export const ConfigState: configState = {
    top_k: 100,
    top_productCategories: 5,
    metadataSelector: false,
    showProductCategories: true
}

const getLocalItem = (item: string, default_value: any = '') => {
    let item_value = window.localStorage.getItem(item)
    if (item_value) {
        return item_value
    } else {
        return default_value
    }
}
const setLocalItem = (item: string, value: any = '') => {
    window.localStorage.setItem(item, value);
}

export const createConfigSlice = (set: any, get: any) => ({
    ...createConfigStates(set),
    ...createConfigActions(set, get),
});

const createConfigStates = (set: any) => ({
    top_k: getLocalItem('top_k', ConfigState.top_k) as number,
    setTop_k: (top_k: number) =>
        set((state: any) => {
            setLocalItem('top_k', top_k)
            state.top_k = top_k;
        }),
    top_productCategories: getLocalItem('top_productCategories', ConfigState.top_productCategories) as number,
    setTop_productCategories: (top_productCategories: number) =>
        set((state: any) => {
            setLocalItem('top_productCategories', top_productCategories)
            state.top_productCategories = top_productCategories;
        }),
    metadataSelector: (getLocalItem('metadataSelector', ConfigState.metadataSelector) === "true") as boolean,
    setMetadataSelector: (metadataSelector: boolean) =>
        set((state: any) => {
            setLocalItem('metadataSelector', metadataSelector)
            state.metadataSelector = metadataSelector;
        }),
    showProductCategories: (getLocalItem('showProductCategories', ConfigState.showProductCategories) === "true") as boolean,
    setshowProductCategories: (showProductCategories: boolean) =>
        set((state: any) => {
            setLocalItem('showProductCategories', showProductCategories)
            state.showProductCategories = showProductCategories;
        }),
});

const createConfigActions = (set: any, get: any) => ({
});