import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { toLower } from "lodash";

import { useCountries } from "../hooks";
import { ICountry } from '../countryList/ICountry'

// To extends this component:
// 1. Added the flag with the corresponding country code into /public/flags/{countryCode}.svg
// 2. Remove the title in the SVG
// 3. Update the propTypes with the added country code
// Flag svgs can be found here: https://github.com/madebybowtie/FlagKit

export const Flag = ({
  countryCode,
  withTitle = false,
  scale = 1,
  ...props
}: any) => {
  const [title, setTitle] = React.useState(countryCode);
  const countries = useCountries();

  React.useEffect(() => {
    if (!countryCode) return;
    const country: ICountry = countries.filter((c: ICountry) => c.CountryCode === countryCode)[0];
    setTitle(country?.CountryName);
  }, [countries, countryCode]);

  if (!countryCode) {
    return null;
  }

  return (
    <ReactSVG
      {...props}
      src={`/flags/${countryCode.toUpperCase()}.svg`}
      title={withTitle && title ? title : ""}
      style={{
        transform: `scale(${scale})`,
      }}
    />
  );
};

const allowedCountryCodes = [
  "EN",
  "DE",
  "AT",
  "CH",
  "NO",
  "IT",
  "NL",
  "FR",
  "ES",
  "CZ",
  "PT",
  "GB",
  "SE",
  "FI",
  "DK",
];

Flag.propTypes = {
  countryCode: PropTypes.oneOf([
    ...allowedCountryCodes,
    ...allowedCountryCodes.map(toLower),
  ]).isRequired,
  withTitle: PropTypes.bool,
  scale: PropTypes.number,
};
