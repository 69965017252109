import { useRef } from 'react';
import { useConfigState } from '../../store';
import { useTranslation } from "react-i18next";

const SelectTopK = () => {
    const top_k_ref = useRef<HTMLInputElement>(null)
    const { t } = useTranslation()
    const { setTop_k, top_k } = useConfigState()

    const top_k_change = () => {
        if (top_k_ref.current?.value) {
            setTop_k(Number.parseInt(top_k_ref.current?.value))
        }
    }


    return (
        <>
            <label htmlFor='top_k' className="form-label">{t('Config.top_k')} ({top_k})</label>
            <input type="range" className="form-range" min="1" max="10000" step="1" id="top_k" ref={top_k_ref} onChange={() => top_k_change()} value={top_k}></input>
        </>
    )
}

export default SelectTopK