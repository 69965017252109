import { Amplify, Storage } from 'aws-amplify';
import aws_config from '../../modified_aws-export';
import { SetStateAction, useEffect, useState } from 'react';

import { ResultMetadata } from "./IResultMetadata";
import genarts from '../../assets/genart_descriptions'
import { useI18nState } from '../../store';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

Amplify.configure(aws_config);

interface ItemResultsProps {
    score: number,
    average_score: number | null,
    elements: any[] | null;
    metadata: ResultMetadata;
    updateDisplayResults?: SetStateAction<any>;
}

interface IGenart {
    [key: string]: IGenartDescription;
}

interface IGenartDescription {
    GenartName: string | null;
    NormTermName: string | null;
    AssGrpName: string | null;
    UsageName: string | null;
}

interface ILanguages {
    [key: string]: IGenart
}

const genart_descriptions: ILanguages = genarts

const ItemResults: React.FC<ItemResultsProps> = ({ score, average_score, metadata, elements, updateDisplayResults}) => {
    const [img, setImg] = useState<string | undefined>(undefined)
    const { t } = useTranslation();
    const { selectedLanguage } = useI18nState();

    useEffect(() => {
        Storage.configure({
            aws_user_files_s3_bucket_region: 'eu-central-1', // (required) - Amazon S3 bucket region
            aws_user_files_s3_bucket: 'tecdoc-taf-data-extracted' // (required) - Amazon S3 bucket URI
        })
        var config = {
            level: 'public',
            customPrefix: { public: '' }
        };

        const t = async (config: any) => {
            try {
                let e = await Storage.get(`MANUFACTURER/${metadata.BrandNo}/PIC/${metadata.FileName}`, { ...config, validateObjectExistence: true });
                setImg(e)
            } catch (error) {

            }
        }
        t(config)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCardTitle = (metadata: ResultMetadata) => {
        if (genart_descriptions[selectedLanguage] && genart_descriptions[selectedLanguage][metadata.GenArtNo] && genart_descriptions[selectedLanguage][metadata.GenArtNo]["GenartName"]) {
            return `${genart_descriptions[selectedLanguage][metadata.GenArtNo]["GenartName"]} (${metadata.GenArtNo})`
        } else {
            return `(${metadata.GenArtNo})`
        }
    }

    return (
            <div className="card card-body m-2 p-2" style={{ maxWidth: "12em", width: "12em" }}>
                <div>
                <span className="badge bg-secondary text-light" style={{position: "relative", left: "-1em", top: "-20px", width:"4em", float:"left"}}>{score.toFixed(3)}</span>
                {average_score ? 
                    <span className="badge bg-secondary text-light" style={{position: "relative", left: "0em", top: "-20px", width:"5em", float:"left"}}>&empty; {average_score.toFixed(3)}</span>
                    : null}
                {elements ? 
                    <span className="col-3 badge bg-primary text-light" style={{position: "relative", right: "-1em", top: "-20px", float:"right"}}>{elements.length}</span>
                    : null}
                </div>
                <img src={img} className="card-img-top" alt={metadata.FileName} />
                <div className="card-body">
                    <p className="card-title">{getCardTitle(metadata)} </p>
                    <p className="card-text">{t("brand")}: {metadata.BrandNo}</p>
                    <p className="card-text">{metadata['Filter type']}</p>

                    {/* <p className="card-text"><pre style={{height: "10em", overflow: "scroll"}}>{JSON.stringify(metadata,null,2)}</pre></p> */}
                </div>
                <div className="d-flex justify-content-center">
                    {elements ? 
                    <Link to={"/"} onClick={() => updateDisplayResults(elements)}>
                        <button className="btn btn-secondary bs-secondary">{t('Button.showProductCategorieResults').replace('{}', elements.length.toString())}</button>
                    </Link>
                    : <>
                    {metadata && metadata.ArtNo ? 
                        <a href={`https://web.tecalliance.net/tecdoc-internal/en/parts/${metadata.BrandNo}/${(metadata.ArtNo.startsWith(metadata.BrandNo) ? metadata.ArtNo.replace(metadata.BrandNo, '') : metadata.ArtNo)}/detail`} className="btn btn-secondary bs-secondary">{t('Button.open')}</a>
                        : null}    
                    </>
                    }
                </div>
            </div>
    )
}

export default ItemResults