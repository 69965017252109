import { useStore } from "./useStore";
import { useMemo } from "react";
export const useAuthorization = () => {
  // States
  const [cognitoUser, userInfo, role, permissions] = useStore((state: any) => [
    state.cognitoUser,
    state.userInfo,
    state.role,
    state.permissions,
  ]);

  // Effects
  const loadUserInfo = useStore((state: any) => state.loadUserInfo);

  const loadPermissions = useStore((state: any) => state.loadPermissions);

  const isHotline = useMemo(() => role === "HOTLINE", [role]);

  return {
    cognitoUser,
    userInfo,
    role,
    permissions,
    loadUserInfo,
    loadPermissions,
    isHotline,
  };
};
