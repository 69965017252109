import React, { useState } from 'react';
import './MetadataSelector.scss'; // Import your SCSS file

interface Item {
  id: string;
  score: number;
  values: any[];
  sparse_values: {
    indices: number[];
    values: any[];
  };
  metadata: Record<string, string>;
}

interface MetadataSelectorProps {
  items: Item[];
  onChange: React.Dispatch<React.SetStateAction<object>>;
}

const excludedMetadataKeys = ['ArtNo', 'DocType', 'DocName', 'DocNo', 'FileName'];

const MetadataSelector: React.FC<MetadataSelectorProps> = ({ items, onChange }) => {
  const allMetadata = items.map(item => item.metadata);

  const metadataKeys = Array.from(new Set(allMetadata.flatMap(metadata => Object.keys(metadata))));

  const [selectedMetadata, setSelectedMetadata] = useState<Record<string, string[]>>({});

  const uniqueMetadataValues: Record<string, string[]> = {};

  // Preprocess data to store unique values for each key
  metadataKeys.forEach(key => {
    uniqueMetadataValues[key] = Array.from(new Set(items.flatMap(item => item.metadata[key] || [])));
  });

  const handleBadgeClick = (key: string, value: string) => {
    setSelectedMetadata(prevSelected => {
      const updatedSelected = { ...prevSelected };
      const isSelected = (updatedSelected[key] || []).includes(value);
      
      if (isSelected) {
        updatedSelected[key] = (updatedSelected[key] || []).filter(val => val !== value);
      } else {
        updatedSelected[key] = [...(updatedSelected[key] || []), value];
      }

      // Construct the filter structure
      const filterArray = Object.keys(updatedSelected).flatMap(metadataKey =>
        updatedSelected[metadataKey].map(selectedValue => ({
          [metadataKey]: {
            $eq: selectedValue,
          },
        }))
      );

      const filterStructure = {
        $and: filterArray,
      };

      onChange(filterStructure); // Log the filter structure

      return updatedSelected;
    });
  };

  const filteredMetadataKeys = metadataKeys.filter(key => uniqueMetadataValues[key].length > 0);

  return (
      <div className="metadata-selector container">
      <div className="row">
        {filteredMetadataKeys.map(key => {
          if (excludedMetadataKeys.includes(key)) {
            return null;
          }
          return (
            <div key={key} className="col">
              <div className="card mb-1">
                <div className="card-body">
                  <h6 className="card-title metadata-type">{key}</h6>
                  <div className="metadata-badge-list">
                    {uniqueMetadataValues[key].map(value => {
                      const isSelected = (selectedMetadata[key] || []).includes(value);
                      const badgeClass = `badge badge-${isSelected ? 'primary' : 'secondary'} badge-clickable`;
                      return (
                        <span
                          key={value}
                          className={badgeClass}
                          onClick={() => handleBadgeClick(key, value)}
                        >
                          {value}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
    );
};

export default MetadataSelector;