import awsconfig from './aws-exports'

  // const isLocalhost = Boolean(
  //   window.location.hostname === "localhost" ||
  //   // [::1] is the IPv6 localhost address.
  //   window.location.hostname === "[::1]" ||
  //   // 127.0.0.1/8 is considered localhost for IPv4.
  //   window.location.hostname.match(
  //     /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  //   )
  // );
  

  var url = window.location.origin + "/"

  // Defalt values
  var currentRedirectSignIn = window.location.origin + "/"
  var currentRedirectSignOut = window.location.origin + "/"

  // Assuming you have multiple redirect URIs
  var redirectSignIn = awsconfig.oauth.redirectSignIn.split(",");
  var redirectSignOut = awsconfig.oauth.redirectSignOut.split(",");
  
  redirectSignIn.forEach(element => {
    if (element.startsWith(url)) {
      currentRedirectSignIn = element
    }
  });
  
  redirectSignOut.forEach(element => {
    if (element.startsWith(url)) {
      currentRedirectSignOut = element
    }
  });

  let updatedAwsConfig = {
    ...awsconfig,
    oauth: {
      ...awsconfig.oauth,
      redirectSignIn: currentRedirectSignIn,
      redirectSignOut: currentRedirectSignOut,
    }
  }

  export default updatedAwsConfig