import { Amplify, Auth } from "aws-amplify";
// import * as Sentry from "@sentry/react";
import awsconfig from "../../modified_aws-export";

// From @aws-amplify/ui-components
export const AuthState = {
  SignUp: "signup",
  SignOut: "signout",
  SignIn: "signin",
  Loading: "loading",
  SignedOut: "signedout",
  SignedIn: "signedin",
  SigningUp: "signingup",
  ConfirmSignUp: "confirmSignUp",
  confirmingSignUpCustomFlow: "confirmsignupcustomflow",
  ConfirmSignIn: "confirmSignIn",
  confirmingSignInCustomFlow: "confirmingsignincustomflow",
  VerifyingAttributes: "verifyingattributes",
  ForgotPassword: "forgotpassword",
  ResetPassword: "resettingpassword",
  SettingMFA: "settingMFA",
  TOTPSetup: "TOTPSetup",
  CustomConfirmSignIn: "customConfirmSignIn",
  VerifyContact: "verifyContact",
  ExpiredPassword: "expiredpassword",
};

export const configureDefault = () => Amplify.configure(awsconfig);

export const createAuthSlice = (set: any, get: any) => ({
  ...createAuthStates(set),
  ...createAuthActions(set, get),
});

const createAuthStates = (set: any) => ({
  authState: AuthState.SignIn,
  setAuthState: (authState: any) =>
    set((state: any) => {
      state.authState = authState;
    }),
  cognitoUser: undefined,
  role: undefined,
  permissions: undefined,
  signOut: undefined,
  authError: "",
  setAuthError: (authError: any) =>
    set((state: any) => {
      state.authError = authError;
    }),
  userInfo: undefined,
  authInitialized: false,
  expiredPassword: false,
  setExpiredPassword: (expiredPassword: any) =>
    set((state: any) => {
      state.expiredPassword = expiredPassword;
    }),
  populatedDataForExpiredPassword: undefined,
  setPopulatedDataForExpiredPassword: (data: any) =>
    set((state: any) => {
      state.populatedDataForExpiredPassword = data
    })
});

const createAuthActions = (set: any, get: any) => ({
  loadUserInfo: async (cognitoUser: any) => {
    set((state: any) => {
      let idToken = cognitoUser.signInUserSession.idToken.payload
      state.userInfo = {"lastName": idToken.family_name, "firstName": idToken.given_name, "email": idToken.email};
    });
  },
  initializeAuthentication: async (signIn = false) => {
    if (get().authInitialized && !signIn) {
      return;
    }

    
    let cognitoUser: any = null;
    try {
      cognitoUser = await Auth.currentAuthenticatedUser();
    } catch (error) {
      // Intentionally left blank
    }
    if (cognitoUser) {
      // Sentry.setUser({ username: cognitoUser.username });
      await get().loadUserInfo(cognitoUser);

      set((state: any) => {
        state.cognitoUser = cognitoUser;
        state.authState = AuthState.SignedIn;
      });
    }
    set((state: any) => {
      state.authInitialized = true;
    });
  },

  signIn: async () => {
    try {
      await Auth.federatedSignIn({ customProvider: awsconfig.aws_cognito_custom_provider })
      return get().initializeAuthentication(true);
    } catch (error) {
      console.log(error)
    }
  },

  signOut: async () => {
    await Auth.signOut();
    set((state: any) => {
      state.authState = AuthState.SignIn;
      state.cognitoUser = undefined;
      state.userInfo = undefined;
      state.role = undefined;
      state.authInitialized = false;
      state.permissions = undefined;
    });
    window.location.href = window.location.origin;
  }

});