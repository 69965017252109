const genart_descriptions = {
    "de": {
        "00001": {
            "GenartName": "Starterbatterie",
            "NormTermName": "Batterie",
            "AssGrpName": "Startanlage",
            "UsageName": null
        },
        "00002": {
            "GenartName": "Starter",
            "NormTermName": "Starter",
            "AssGrpName": "Startanlage",
            "UsageName": null
        },
        "00004": {
            "GenartName": "Generator",
            "NormTermName": "Generator",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "00005": {
            "GenartName": "Gelenksatz, Antriebswelle",
            "NormTermName": "Gelenksatz",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "00007": {
            "GenartName": "Ölfilter",
            "NormTermName": "Filter",
            "AssGrpName": "Schmierung",
            "UsageName": "Motoröl"
        },
        "00008": {
            "GenartName": "Luftfilter",
            "NormTermName": "Filter",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Luft"
        },
        "00009": {
            "GenartName": "Kraftstofffilter",
            "NormTermName": "Filter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoff"
        },
        "00010": {
            "GenartName": "Keilriemen",
            "NormTermName": "Riemen",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilriemen"
        },
        "00012": {
            "GenartName": "Hydraulikpumpe, Lenkung",
            "NormTermName": "Pumpe",
            "AssGrpName": "Lenkung",
            "UsageName": "Hydraulik"
        },
        "00013": {
            "GenartName": "Antriebswelle",
            "NormTermName": "Welle",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "00014": {
            "GenartName": "Streuscheibe, Hauptscheinwerfer",
            "NormTermName": "Streuscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "00015": {
            "GenartName": "Streuscheibe, Suchscheinwerfer",
            "NormTermName": "Streuscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Suchscheinwerfer"
        },
        "00016": {
            "GenartName": "Streuscheibe, Fernscheinwerfer",
            "NormTermName": "Streuscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "00017": {
            "GenartName": "Abgasrohr",
            "NormTermName": "Rohr",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "00019": {
            "GenartName": "Blende, Nebelscheinwerfer",
            "NormTermName": "Blende",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "00020": {
            "GenartName": "Blende, Stoßfänger",
            "NormTermName": "Blende",
            "AssGrpName": "Karosserie",
            "UsageName": "Stoßfänger"
        },
        "00021": {
            "GenartName": "Klappe, Abschlepphaken",
            "NormTermName": "Klappe",
            "AssGrpName": "Karosserie",
            "UsageName": "Abschlepphaken"
        },
        "00022": {
            "GenartName": "Abdeckung, Wasserkasten",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Karosserie",
            "UsageName": "Wasserkasten"
        },
        "00023": {
            "GenartName": "Abdeckung, Außenspiegel",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegel"
        },
        "00025": {
            "GenartName": "Kappe, Hauptscheinwerfer",
            "NormTermName": "Kappe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "00026": {
            "GenartName": "Abgasanlage",
            "NormTermName": "Abgasanlage",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "00027": {
            "GenartName": "Dichtung, Abgaskrümmer",
            "NormTermName": "Dichtung",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Abgaskrümmer"
        },
        "00028": {
            "GenartName": "Dichtungssatz, Abgaskrümmer",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Abgaskrümmer"
        },
        "00029": {
            "GenartName": "Ventil, Aktivkohlefilter",
            "NormTermName": "Ventil",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Aktivkohlefilter"
        },
        "00031": {
            "GenartName": "Leerlaufregelventil, Vergaser",
            "NormTermName": "Ventil",
            "AssGrpName": "Vergaser",
            "UsageName": "Leerlauf"
        },
        "00032": {
            "GenartName": "Abschirmblech, Anhängevorrichtung",
            "NormTermName": "Blech",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Abschirmung"
        },
        "00033": {
            "GenartName": "Ventil, Kraftstoffförderanlage",
            "NormTermName": "Ventil",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": null
        },
        "00034": {
            "GenartName": "Achsschenkelbolzen",
            "NormTermName": "Bolzen",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsschenkel"
        },
        "00035": {
            "GenartName": "Adaptersatz, Geschwindigkeitswarnanlage",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Geschwindigkeitswarnanlage",
            "UsageName": null
        },
        "00036": {
            "GenartName": "Amperemeter",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Amperemeter"
        },
        "00037": {
            "GenartName": "Montagesatz, Stoßdämpfer",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Stoßdämpfer"
        },
        "00038": {
            "GenartName": "Anhängebock, Anhängevorrichtung",
            "NormTermName": "Anhängebock",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "00039": {
            "GenartName": "Anhängevorrichtung",
            "NormTermName": "Anhängevorrichtung",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "00040": {
            "GenartName": "Dichtung, Ansaug-/Abgaskrümmer",
            "NormTermName": "Dichtung",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Ansaug-/Abgaskrümmer"
        },
        "00041": {
            "GenartName": "Dichtungssatz, Ansaug-/Abgaskrümmer",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Ansaug-/Abgaskrümmer"
        },
        "00042": {
            "GenartName": "Dichtung, Ansaugkrümmer",
            "NormTermName": "Dichtung",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Ansaugkrümmer"
        },
        "00043": {
            "GenartName": "Dichtungssatz, Ansaugkrümmer",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Ansaugkrümmer"
        },
        "00044": {
            "GenartName": "Dichtung, Ansaugkrümmergehäuse",
            "NormTermName": "Dichtung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ansaugkrümmergehäuse"
        },
        "00045": {
            "GenartName": "Anschraubplatte, Anhängevorrichtung",
            "NormTermName": "Anschraubplatte",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "00046": {
            "GenartName": "Antenne",
            "NormTermName": "Antenne",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Autoradio"
        },
        "00047": {
            "GenartName": "Zentralausrücker, Kupplung",
            "NormTermName": "Zentralausrücker",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "00048": {
            "GenartName": "Ausrücklager",
            "NormTermName": "Lager",
            "AssGrpName": "Kupplung",
            "UsageName": "Ausrücklager"
        },
        "00049": {
            "GenartName": "Ausrückteller, Kupplung",
            "NormTermName": "Ausrückteller",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "00050": {
            "GenartName": "Außenspiegel",
            "NormTermName": "Spiegel",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegel"
        },
        "00051": {
            "GenartName": "Axialgelenk, Spurstange",
            "NormTermName": "Gelenk",
            "AssGrpName": "Lenkung",
            "UsageName": "Axialgelenk"
        },
        "00052": {
            "GenartName": "Batterieaufnahme",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Karosserie",
            "UsageName": "Batterieaufnahme"
        },
        "00053": {
            "GenartName": "Bedienelement, Unfalldatenspeicher",
            "NormTermName": "Bedienelement",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Unfalldatenspeicher"
        },
        "00054": {
            "GenartName": "Schraube, Bremsscheibe",
            "NormTermName": "Schraube",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsscheibe"
        },
        "00055": {
            "GenartName": "Verschluss, Bremsflüssigkeitsbehälter",
            "NormTermName": "Verschluss",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsflüssigkeitsbehälter"
        },
        "00056": {
            "GenartName": "Verschlussdeckel, Kühlmittelbehälter",
            "NormTermName": "Verschluss",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelbehälter"
        },
        "00057": {
            "GenartName": "Verschluss, Waschwasserbehälter",
            "NormTermName": "Verschluss",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasserbehälter"
        },
        "00060": {
            "GenartName": "Blende, Hauptscheinwerfer",
            "NormTermName": "Blende",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "00061": {
            "GenartName": "Blinkgeber",
            "NormTermName": "Relais",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinkgeber"
        },
        "00062": {
            "GenartName": "Blinkleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinkleuchte"
        },
        "00063": {
            "GenartName": "Gehäuse, Blinkleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinkleuchte"
        },
        "00064": {
            "GenartName": "Blinkleuchtengrill",
            "NormTermName": "Gitter",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinkleuchte"
        },
        "00066": {
            "GenartName": "Karosserieboden",
            "NormTermName": "Boden",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "00068": {
            "GenartName": "Bootshalter, Dachträger",
            "NormTermName": "Bootshalter",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Dachträger"
        },
        "00069": {
            "GenartName": "Bootshalter, Heckträger",
            "NormTermName": "Bootshalter",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Heckträger"
        },
        "00070": {
            "GenartName": "Bremsbackensatz",
            "NormTermName": "Bremsbackensatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "00071": {
            "GenartName": "Bremsflüssigkeit",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "00073": {
            "GenartName": "Bremskraftregler",
            "NormTermName": "Regler",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremskraftregler"
        },
        "00074": {
            "GenartName": "Bremskraftverstärker",
            "NormTermName": "Verstärker",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremskraftverstärker"
        },
        "00076": {
            "GenartName": "Bremsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Signalanlage",
            "UsageName": "Bremsleuchte"
        },
        "00077": {
            "GenartName": "Gehäuse, Bremsleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Signalanlage",
            "UsageName": "Bremsleuchte"
        },
        "00078": {
            "GenartName": "Bremssattel",
            "NormTermName": "Bremssattel",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "00079": {
            "GenartName": "Bremssattelachssatz",
            "NormTermName": "Achssatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "00081": {
            "GenartName": "Bremssattelhaltersatz",
            "NormTermName": "Haltersatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "00082": {
            "GenartName": "Bremsscheibe",
            "NormTermName": "Bremsscheibe",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "00083": {
            "GenartName": "Bremsschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremshydraulik"
        },
        "00084": {
            "GenartName": "Streuscheibe, Arbeitsscheinwerfer",
            "NormTermName": "Streuscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "00085": {
            "GenartName": "Streuscheibe, Nebelscheinwerfer",
            "NormTermName": "Streuscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "00086": {
            "GenartName": "Scheinwerfereinsatz, Arbeitsscheinwerfer",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "00087": {
            "GenartName": "Scheinwerfereinsatz, Fernscheinwerfer",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "00088": {
            "GenartName": "Scheinwerfereinsatz, Hauptscheinwerfer",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "00089": {
            "GenartName": "Scheinwerfereinsatz, Nebelscheinwerfer",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "00090": {
            "GenartName": "Scheinwerfereinsatz, Suchscheinwerfer",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Suchscheinwerfer"
        },
        "00091": {
            "GenartName": "Lichtscheibe, Blinkleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinkleuchte"
        },
        "00092": {
            "GenartName": "Lichtscheibe, Bremsleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Signalanlage",
            "UsageName": "Bremsleuchte"
        },
        "00093": {
            "GenartName": "Lichtscheibe, Heckleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Heckleuchte"
        },
        "00094": {
            "GenartName": "Lichtscheibe, Kennzeichenleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kennzeichenleuchte"
        },
        "00095": {
            "GenartName": "Lichtscheibe, Nebelschlussleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelschlussleuchte"
        },
        "00096": {
            "GenartName": "Lichtscheibe, Rückfahrleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Rückfahrleuchte"
        },
        "00097": {
            "GenartName": "Lichtscheibe, Schlussleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Schlussleuchte"
        },
        "00098": {
            "GenartName": "Lichtscheibe, Rundumkennleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Signalanlage",
            "UsageName": "Rundumkennleuchte"
        },
        "00099": {
            "GenartName": "Halter, Arbeitsscheinwerfer",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "00100": {
            "GenartName": "Halter, Fernscheinwerfer",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "00101": {
            "GenartName": "Halter, Hauptscheinwerfer",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "00102": {
            "GenartName": "Halter, Nebelscheinwerfer",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "00103": {
            "GenartName": "Halter, Suchscheinwerfer",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Suchscheinwerfer"
        },
        "00104": {
            "GenartName": "Glühlampe, Arbeitsscheinwerfer",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "00105": {
            "GenartName": "Glühlampe, Blinkleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinkleuchte"
        },
        "00106": {
            "GenartName": "Glühlampe, Fernscheinwerfer",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "00107": {
            "GenartName": "Glühlampe, Hauptscheinwerfer",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "00108": {
            "GenartName": "Glühlampe, Nebelscheinwerfer",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "00109": {
            "GenartName": "Glühlampe, Suchscheinwerfer",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Suchscheinwerfer"
        },
        "00110": {
            "GenartName": "Glühlampe, Brems-/Schlusslicht",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Brems-/Schlusslicht"
        },
        "00111": {
            "GenartName": "Glühlampe, Bremsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Signalanlage",
            "UsageName": "Bremsleuchte"
        },
        "00112": {
            "GenartName": "Glühlampe, Kennzeichenleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kennzeichenleuchte"
        },
        "00113": {
            "GenartName": "Glühlampe, Nebelschlussleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelschlussleuchte"
        },
        "00114": {
            "GenartName": "Glühlampe, Rückfahrleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Rückfahrleuchte"
        },
        "00115": {
            "GenartName": "Glühlampe, Schlussleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Schlussleuchte"
        },
        "00116": {
            "GenartName": "Glühlampe, Rundumkennleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Signalanlage",
            "UsageName": "Rundumkennleuchte"
        },
        "00117": {
            "GenartName": "Glühlampe, Innenraumleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Innenraumleuchte"
        },
        "00118": {
            "GenartName": "Glühlampe, Türleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Türleuchte"
        },
        "00119": {
            "GenartName": "Glühlampe, Kofferraumleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kofferraumleuchte"
        },
        "00120": {
            "GenartName": "Glühlampe, Motorraumleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Motorraumleuchte"
        },
        "00121": {
            "GenartName": "Glühlampe, Instrumentenbeleuchtung",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Instrumentenbeleuchtung"
        },
        "00122": {
            "GenartName": "Halter, Bremsschlauch",
            "NormTermName": "Halter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsschlauch"
        },
        "00123": {
            "GenartName": "Bremstrommel",
            "NormTermName": "Bremstrommel",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "00124": {
            "GenartName": "Seilzug, Feststellbremse",
            "NormTermName": "Seilzug",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremse"
        },
        "00125": {
            "GenartName": "Seilzug, Betriebsbremse",
            "NormTermName": "Seilzug",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Betriebsbremse"
        },
        "00126": {
            "GenartName": "Dachbox",
            "NormTermName": "Dachbox",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": null
        },
        "00127": {
            "GenartName": "Dachträger",
            "NormTermName": "Träger",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Dachträger"
        },
        "00128": {
            "GenartName": "Dichtung, Zylinderlaufbuchse",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Zylinderlaufbuchse"
        },
        "00130": {
            "GenartName": "Lenkungsdämpfer",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "00131": {
            "GenartName": "Dichtung, Ölkühler",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölkühler"
        },
        "00132": {
            "GenartName": "Dichtung, Ölpumpe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "00133": {
            "GenartName": "Dichtung, Ölfilter",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölfilter"
        },
        "00134": {
            "GenartName": "Dichtung, Ölsieb",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölsieb"
        },
        "00135": {
            "GenartName": "Dichtring, Ölablassschraube",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölablassschraube"
        },
        "00136": {
            "GenartName": "Dichtring, Öldruckschalter",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schmierung",
            "UsageName": "Öldruckschalter"
        },
        "00137": {
            "GenartName": "Dichtung, Ölsumpf",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölsumpf"
        },
        "00138": {
            "GenartName": "Dichtung, Abgasrohr",
            "NormTermName": "Dichtung",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasrohr"
        },
        "00139": {
            "GenartName": "Dichtung, Thermostat",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kühlung",
            "UsageName": "Thermostat"
        },
        "00141": {
            "GenartName": "Dichtung, Lader",
            "NormTermName": "Dichtung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lader"
        },
        "00143": {
            "GenartName": "Dichtung, Kraftstoffpumpe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "00144": {
            "GenartName": "Dichtung, Kraftstoffilter",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstofffilter"
        },
        "00146": {
            "GenartName": "Dichtung, Schaltgetriebe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "00147": {
            "GenartName": "Dichtung, Automatikgetriebe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "00148": {
            "GenartName": "Dichtung, Tankgeber",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Tankgeber"
        },
        "00149": {
            "GenartName": "Dichtung, Kraftstoffeinfüllstutzen",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffeinfüllstutzen"
        },
        "00150": {
            "GenartName": "Dichtungssatz, Kurbelgehäuse",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäuse"
        },
        "00151": {
            "GenartName": "Dichtungssatz, Zylinderlaufbuchse",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Zylinderlaufbuchse"
        },
        "00152": {
            "GenartName": "Dichtungssatz, Lader",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lader"
        },
        "00155": {
            "GenartName": "Drahtring, Ausrückteller",
            "NormTermName": "Drahtring",
            "AssGrpName": "Kupplung",
            "UsageName": "Ausrückteller"
        },
        "00156": {
            "GenartName": "Drehstabgestänge",
            "NormTermName": "Gestänge",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Drehstab"
        },
        "00157": {
            "GenartName": "Drehzahlmesser",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Drehzahl"
        },
        "00158": {
            "GenartName": "Drosselklappenstutzen",
            "NormTermName": "Stutzen",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Drosselklappe"
        },
        "00159": {
            "GenartName": "Anzeige, Ladedruck",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Ladedruck"
        },
        "00160": {
            "GenartName": "Anzeige, Öldruck",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Öldruck"
        },
        "00162": {
            "GenartName": "Sensor, Öldruck",
            "NormTermName": "Sensor",
            "AssGrpName": "Instrumente",
            "UsageName": "Öldruck"
        },
        "00163": {
            "GenartName": "Öldruckhalteventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Schmierung",
            "UsageName": "Öldruckerhaltung"
        },
        "00164": {
            "GenartName": "Druckhalteventil, Kupplungsgeberzylinder",
            "NormTermName": "Ventil",
            "AssGrpName": "Kupplung",
            "UsageName": "Geberzylinder"
        },
        "00165": {
            "GenartName": "Vordruckventil, Trommelbremse",
            "NormTermName": "Ventil",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Drucksicherung"
        },
        "00166": {
            "GenartName": "Druckmodulator, Bremsdruck",
            "NormTermName": "Druckmodulator",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "00167": {
            "GenartName": "Bremsdruckregler",
            "NormTermName": "Regler",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsdruck"
        },
        "00168": {
            "GenartName": "Kraftstoffdruckregler",
            "NormTermName": "Regler",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffdruck"
        },
        "00170": {
            "GenartName": "Druckwandler",
            "NormTermName": "Druckwandler",
            "AssGrpName": "Abgasrückführung",
            "UsageName": null
        },
        "00171": {
            "GenartName": "Waschdüsenrohr",
            "NormTermName": "Rohr",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschdüse"
        },
        "00176": {
            "GenartName": "Kugelbolzen, Spurstange",
            "NormTermName": "Bolzen",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstangenkugelgelenk"
        },
        "00177": {
            "GenartName": "Einstiegsblech",
            "NormTermName": "Einstieg",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "00178": {
            "GenartName": "Einstiegsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Einstiegsholm"
        },
        "00179": {
            "GenartName": "Elektrosatz, Anhängevorrichtung",
            "NormTermName": "Elektrosatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "00181": {
            "GenartName": "Endrohrblende",
            "NormTermName": "Blende",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Endrohr"
        },
        "00183": {
            "GenartName": "Expansionsventil, Klimaanlage",
            "NormTermName": "Ventil",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Expansion"
        },
        "00184": {
            "GenartName": "Schnell-Reparatur-Service, Antriebswelle",
            "NormTermName": "Schnell-Reparatur-Service",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "00185": {
            "GenartName": "Schnell-Reparatur-Service, Antriebswellengelenk",
            "NormTermName": "Schnell-Reparatur-Service",
            "AssGrpName": "Radantrieb",
            "UsageName": "Gelenk"
        },
        "00186": {
            "GenartName": "Fahrradhalter, Heckträger",
            "NormTermName": "Fahrradhalter",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Heckträger"
        },
        "00187": {
            "GenartName": "Fahrradhalter, Dachträger",
            "NormTermName": "Fahrradhalter",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Dachträger"
        },
        "00188": {
            "GenartName": "Fahrwerksfeder",
            "NormTermName": "Feder",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "00189": {
            "GenartName": "Fahrwerkssatz, Federn",
            "NormTermName": "Federsatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federn"
        },
        "00190": {
            "GenartName": "Fahrwerkssatz, Federn/Dämpfer",
            "NormTermName": "Fahrwerkssatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "00191": {
            "GenartName": "Faltenbalg, Lenkung",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "00193": {
            "GenartName": "Faltenbalg, Antriebswelle",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "00194": {
            "GenartName": "Faltenbalgsatz, Antriebswelle",
            "NormTermName": "Faltenbalgsatz",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "00195": {
            "GenartName": "Faltenbalgsatz, Lenkung",
            "NormTermName": "Faltenbalgsatz",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "00196": {
            "GenartName": "Fanfare",
            "NormTermName": "Fanfare",
            "AssGrpName": "Signalanlage",
            "UsageName": null
        },
        "00198": {
            "GenartName": "Federbein",
            "NormTermName": "Federbein",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "00200": {
            "GenartName": "Elektromotor, Fensterheber",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Fensterheber"
        },
        "00201": {
            "GenartName": "Fernbedienung, Zentralverriegelung",
            "NormTermName": "Fernbedienung",
            "AssGrpName": "Schließanlage",
            "UsageName": "Zentralverriegelung"
        },
        "00202": {
            "GenartName": "Fernscheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "00203": {
            "GenartName": "Frontscheibenrahmen",
            "NormTermName": "Rahmen",
            "AssGrpName": "Karosserie",
            "UsageName": "Frontscheibenrahmen"
        },
        "00204": {
            "GenartName": "Dichtring, Abgasrohr",
            "NormTermName": "Dichtring",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasrohr"
        },
        "00205": {
            "GenartName": "Dichtung, Vergaserflansch",
            "NormTermName": "Dichtung",
            "AssGrpName": "Vergaser",
            "UsageName": "Vergaserflansch"
        },
        "00208": {
            "GenartName": "Frontverkleidung",
            "NormTermName": "Verkleidung",
            "AssGrpName": "Karosserie",
            "UsageName": "Frontverkleidung"
        },
        "00209": {
            "GenartName": "Führungsarm, Scheibenreinigung",
            "NormTermName": "Führungsarm",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": null
        },
        "00213": {
            "GenartName": "Führungshülse, Kupplung",
            "NormTermName": "Hülse",
            "AssGrpName": "Kupplung",
            "UsageName": "Führungshülse"
        },
        "00214": {
            "GenartName": "Fußmatte",
            "NormTermName": "Fußmatte",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "00215": {
            "GenartName": "Fußmattensatz",
            "NormTermName": "Fußmattensatz",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "00216": {
            "GenartName": "Anzeige, Fahrstufe",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Fahrstufe"
        },
        "00217": {
            "GenartName": "Gasabscheider, Vergaser",
            "NormTermName": "Abscheider",
            "AssGrpName": "Vergaser",
            "UsageName": "Gas"
        },
        "00219": {
            "GenartName": "Gasfeder, Koffer-/Laderaum",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Koffer-/Laderaumdeckel"
        },
        "00220": {
            "GenartName": "Gasfeder, Klapptisch",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Klapptisch"
        },
        "00221": {
            "GenartName": "Gasfeder, Handschuhfach",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Handschuhfach"
        },
        "00222": {
            "GenartName": "Gasfeder, Mittelkonsole",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Mittelkonsole"
        },
        "00223": {
            "GenartName": "Gasfeder, Telefonkonsole",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Telefonkonsole"
        },
        "00224": {
            "GenartName": "Gasfeder, Geräteraumklappe",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Geräteraumklappe"
        },
        "00225": {
            "GenartName": "Gasfeder, Liege",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Liege"
        },
        "00226": {
            "GenartName": "Gasfeder, Flügeltür",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Flügeltür"
        },
        "00227": {
            "GenartName": "Gasfeder, Kühleremblem",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühleremblem"
        },
        "00228": {
            "GenartName": "Gasfeder, Klappscheinwerfer",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Klappscheinwerfer"
        },
        "00229": {
            "GenartName": "Gasfeder, Fußfeststellbremse",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Fußfeststellbremse"
        },
        "00230": {
            "GenartName": "Gasfeder, Überrollbügel",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Überrollbügel"
        },
        "00231": {
            "GenartName": "Gasfeder, Reserveradhalter",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Reserveradhalter"
        },
        "00232": {
            "GenartName": "Gasfeder, Auflaufbremse",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Auflaufbremse"
        },
        "00234": {
            "GenartName": "Geberzylinder, Kupplung",
            "NormTermName": "Zylinder",
            "AssGrpName": "Kupplung",
            "UsageName": "Geberzylinder"
        },
        "00236": {
            "GenartName": "Gelenk, Antriebswelle",
            "NormTermName": "Gelenk",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "00237": {
            "GenartName": "Gelenkgabel, Spurstange",
            "NormTermName": "Gelenkgabel",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstange"
        },
        "00238": {
            "GenartName": "Gepäckkorb, Dachträger",
            "NormTermName": "Gepäckkorb",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Dachträger"
        },
        "00239": {
            "GenartName": "Gepäckkorb, Heckträger",
            "NormTermName": "Gepäckkorb",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Heckträger"
        },
        "00240": {
            "GenartName": "Geschwindigkeitsregelanlage",
            "NormTermName": "Regelanlage",
            "AssGrpName": "Geschwindigkeitsregelanlage",
            "UsageName": null
        },
        "00241": {
            "GenartName": "Geschwindigkeitswarnanlage",
            "NormTermName": "Warnanlage",
            "AssGrpName": "Geschwindigkeitswarnanlage",
            "UsageName": null
        },
        "00242": {
            "GenartName": "Wischergetriebe, Scheibenreinigung",
            "NormTermName": "Getriebe",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": null
        },
        "00243": {
            "GenartName": "Glühkerze",
            "NormTermName": "Glühkerze",
            "AssGrpName": "Glühanlage",
            "UsageName": null
        },
        "00244": {
            "GenartName": "Lüftungsgitter, Stoßfänger",
            "NormTermName": "Gitter",
            "AssGrpName": "Karosserie",
            "UsageName": "Stoßfänger"
        },
        "00245": {
            "GenartName": "Stütze, Kühlergitter",
            "NormTermName": "Stütze",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühlergitter"
        },
        "00246": {
            "GenartName": "Flansch, Lenksäule",
            "NormTermName": "Flansch",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenksäule"
        },
        "00247": {
            "GenartName": "Lagerung, Motor",
            "NormTermName": "Lagerung",
            "AssGrpName": "Motoraufhängung",
            "UsageName": null
        },
        "00248": {
            "GenartName": "Lagerung, Automatikgetriebe",
            "NormTermName": "Lagerung",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "00249": {
            "GenartName": "Lagerung, Schaltgetriebe",
            "NormTermName": "Lagerung",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "00251": {
            "GenartName": "Lagerung, Lenker",
            "NormTermName": "Lagerung",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Lenker"
        },
        "00252": {
            "GenartName": "Anschlagpuffer, Schalldämpfer",
            "NormTermName": "Puffer",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "00253": {
            "GenartName": "Haltering, Schalldämpfer",
            "NormTermName": "Haltering",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Schalldämpfer"
        },
        "00254": {
            "GenartName": "Clip, Stoßfänger",
            "NormTermName": "Clip",
            "AssGrpName": "Karosserie",
            "UsageName": "Stoßfänger"
        },
        "00255": {
            "GenartName": "Feder, Bremsbacken",
            "NormTermName": "Feder",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbacken"
        },
        "00256": {
            "GenartName": "Feder, Scheibenbremsbelag",
            "NormTermName": "Feder",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Scheibenbremsbelag"
        },
        "00257": {
            "GenartName": "Halterahmen, Scheibenreinigungsanlage",
            "NormTermName": "Rahmen",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Halterahmen"
        },
        "00258": {
            "GenartName": "Hauptbremszylinder",
            "NormTermName": "Zylinder",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hauptbremszylinder"
        },
        "00259": {
            "GenartName": "Hauptscheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "00261": {
            "GenartName": "Kupplungsdruckplatte",
            "NormTermName": "Druckplatte",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "00262": {
            "GenartName": "Kupplungsscheibe",
            "NormTermName": "Kupplungsscheibe",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "00264": {
            "GenartName": "Kupplungsverstärker",
            "NormTermName": "Verstärker",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Kupplung"
        },
        "00265": {
            "GenartName": "Hauptwelle, Schaltgetriebe",
            "NormTermName": "Welle",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Hauptwelle"
        },
        "00266": {
            "GenartName": "Kugelkopf, Schaltgestänge",
            "NormTermName": "Kugelkopf",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgestänge"
        },
        "00267": {
            "GenartName": "Steuergerät, Luftfederung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederung"
        },
        "00268": {
            "GenartName": "Sensor, Luftfederungsniveau",
            "NormTermName": "Sensor",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederungsniveau"
        },
        "00269": {
            "GenartName": "Wegeventilblock, Luftfederung",
            "NormTermName": "Wegeventilblock",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederung"
        },
        "00271": {
            "GenartName": "Dämpfer, Motorlagerung",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Motoraufhängung",
            "UsageName": "Motorlagerung"
        },
        "00273": {
            "GenartName": "Lenker, Radaufhängung",
            "NormTermName": "Lenker",
            "AssGrpName": "Radaufhängung",
            "UsageName": null
        },
        "00274": {
            "GenartName": "Stabilisator, Fahrwerk",
            "NormTermName": "Stabilisator",
            "AssGrpName": "Radaufhängung",
            "UsageName": null
        },
        "00276": {
            "GenartName": "Reparatursatz, Hauptbremszylinder",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hauptbremszylinder"
        },
        "00277": {
            "GenartName": "Radbremszylinder",
            "NormTermName": "Zylinder",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Radbremszylinder"
        },
        "00278": {
            "GenartName": "Reparatursatz, Radbremszylinder",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Radbremszylinder"
        },
        "00281": {
            "GenartName": "Bremsbacke",
            "NormTermName": "Bremsbacke",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "00282": {
            "GenartName": "Steuergerät, Brems-/Fahrdynamik",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "00283": {
            "GenartName": "Druckregler, Fahrdynamik",
            "NormTermName": "Regler",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Fahrdynamik"
        },
        "00284": {
            "GenartName": "Spurstange",
            "NormTermName": "Stange",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstange"
        },
        "00285": {
            "GenartName": "Lenkstange",
            "NormTermName": "Stange",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "00286": {
            "GenartName": "Lenkgetriebe",
            "NormTermName": "Getriebe",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "00287": {
            "GenartName": "Steuergerät, ASR",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Fahrdynamikregelung",
            "UsageName": "ASR"
        },
        "00288": {
            "GenartName": "Generatorregler",
            "NormTermName": "Regler",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "00289": {
            "GenartName": "Nebelscheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "00290": {
            "GenartName": "Schlussleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Schlussleuchte"
        },
        "00291": {
            "GenartName": "Nebelschlussleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelschlussleuchte"
        },
        "00292": {
            "GenartName": "Rückfahrleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Rückfahrleuchte"
        },
        "00293": {
            "GenartName": "Kennzeichenleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kennzeichenleuchte"
        },
        "00294": {
            "GenartName": "Begrenzungsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Begrenzungsleuchte"
        },
        "00295": {
            "GenartName": "Wischermotor",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Scheibenwischer"
        },
        "00297": {
            "GenartName": "Horn",
            "NormTermName": "Horn",
            "AssGrpName": "Signalanlage",
            "UsageName": null
        },
        "00298": {
            "GenartName": "Wischblatt",
            "NormTermName": "Wischblatt",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": null
        },
        "00299": {
            "GenartName": "Wischgummi",
            "NormTermName": "Wischgummi",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": null
        },
        "00300": {
            "GenartName": "Wischergestänge",
            "NormTermName": "Gestänge",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Wischer"
        },
        "00301": {
            "GenartName": "Wischarm, Scheibenreinigung",
            "NormTermName": "Wischarm",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": null
        },
        "00302": {
            "GenartName": "Wischerlager",
            "NormTermName": "Lager",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": "Wischer"
        },
        "00303": {
            "GenartName": "Waschwasserdüse, Scheibenreinigung",
            "NormTermName": "Düse",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasser"
        },
        "00304": {
            "GenartName": "Keilriemensatz",
            "NormTermName": "Riemensatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilriemen"
        },
        "00305": {
            "GenartName": "Keilrippenriemen",
            "NormTermName": "Riemen",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilrippenriemen"
        },
        "00306": {
            "GenartName": "Zahnriemen",
            "NormTermName": "Riemen",
            "AssGrpName": "Riementrieb",
            "UsageName": "Zahnriemen"
        },
        "00307": {
            "GenartName": "Zahnriemensatz",
            "NormTermName": "Riemensatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Zahnriemen"
        },
        "00308": {
            "GenartName": "Spannrolle, Zahnriemen",
            "NormTermName": "Rolle",
            "AssGrpName": "Riementrieb",
            "UsageName": "Zahnriemenspannung"
        },
        "00310": {
            "GenartName": "Spannrolle, Keilrippenriemen",
            "NormTermName": "Rolle",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilrippenriemenspannung"
        },
        "00312": {
            "GenartName": "Umlenk-/Führungsrolle, Keilrippenriemen",
            "NormTermName": "Rolle",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilrippenriemenumlenkung/-führung"
        },
        "00313": {
            "GenartName": "Umlenk-/Führungsrolle, Zahnriemen",
            "NormTermName": "Rolle",
            "AssGrpName": "Riementrieb",
            "UsageName": "Zahnriemenführung/-umlenkung"
        },
        "00314": {
            "GenartName": "Dichtung, Wasserpumpe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "00315": {
            "GenartName": "Dichtungssatz, Wasserpumpe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "00316": {
            "GenartName": "Thermostat, Kühlmittel",
            "NormTermName": "Thermostat",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittel"
        },
        "00317": {
            "GenartName": "Dichtung, Thermostatgehäuse",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kühlung",
            "UsageName": "Thermostatgehäuse"
        },
        "00318": {
            "GenartName": "Dichtung, Zylinderkopf",
            "NormTermName": "Dichtung",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Motorblock"
        },
        "00319": {
            "GenartName": "Dichtungssatz, Zylinderkopf",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Zylinderkopf",
            "UsageName": null
        },
        "00321": {
            "GenartName": "Dichtung, Zylinderkopfhaube",
            "NormTermName": "Dichtung",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Zylinderkopfhaube"
        },
        "00322": {
            "GenartName": "Dichtring, Ventilschaft",
            "NormTermName": "Dichtring",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Ventilschaft"
        },
        "00323": {
            "GenartName": "Dichtungssatz, Ventilschaft",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Ventilschaft"
        },
        "00324": {
            "GenartName": "Federbalg, Luftfederung",
            "NormTermName": "Federbalg",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederung"
        },
        "00325": {
            "GenartName": "Bediengerät, Luftfederung",
            "NormTermName": "Bediengerät",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederung"
        },
        "00326": {
            "GenartName": "Luftfederventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederung"
        },
        "00328": {
            "GenartName": "Druckregelventil, Liftanlage",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Liftanlage"
        },
        "00331": {
            "GenartName": "Lagerbuchse, Stabilisator",
            "NormTermName": "Buchse",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisatorlagerung"
        },
        "00332": {
            "GenartName": "Kompressor, Druckluftanlage",
            "NormTermName": "Kompressor",
            "AssGrpName": "Druckluftanlage",
            "UsageName": null
        },
        "00333": {
            "GenartName": "Zylinderkopf, Druckluftkompressor",
            "NormTermName": "Zylinderkopf",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "00334": {
            "GenartName": "Reparatursatz, Druckluftkompressor",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "00335": {
            "GenartName": "Druckregler, Druckluftanlage",
            "NormTermName": "Regler",
            "AssGrpName": "Druckluftanlage",
            "UsageName": null
        },
        "00336": {
            "GenartName": "Frostschutzpumpe, Druckluftanlage",
            "NormTermName": "Pumpe",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Frostschutz"
        },
        "00337": {
            "GenartName": "Wasserabscheider, Druckluftanlage",
            "NormTermName": "Abscheider",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Wasser"
        },
        "00338": {
            "GenartName": "Druckluftreiniger",
            "NormTermName": "Filter",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luft"
        },
        "00339": {
            "GenartName": "Lufttrockner, Druckluftanlage",
            "NormTermName": "Trockner",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luft"
        },
        "00340": {
            "GenartName": "Lufttrocknerpatrone, Druckluftanlage",
            "NormTermName": "Patrone",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Lufttrockner"
        },
        "00341": {
            "GenartName": "Mehrkreisschutzventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Mehrkreisschutz"
        },
        "00342": {
            "GenartName": "Ventil, Druckluftanlage",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": null
        },
        "00346": {
            "GenartName": "Luftbehälter, Druckluftanlage",
            "NormTermName": "Behälter",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluft"
        },
        "00347": {
            "GenartName": "Bremsventil, Betriebsbremse",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Betriebsbremse"
        },
        "00348": {
            "GenartName": "Steuerventil, Anhänger",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Anhängersteuerung"
        },
        "00350": {
            "GenartName": "Vorspannzylinder",
            "NormTermName": "Zylinder",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Vorspannzylinder"
        },
        "00354": {
            "GenartName": "Federspeicherbremszylinder",
            "NormTermName": "Zylinder",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Federspeicher"
        },
        "00355": {
            "GenartName": "Bremsventil, Feststellbremse",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Feststellbremse"
        },
        "00356": {
            "GenartName": "Bremsventil, Anhänger",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Anhängerbremse"
        },
        "00358": {
            "GenartName": "Überlastschutzventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Überlastschutz"
        },
        "00359": {
            "GenartName": "Membranbremszylinder",
            "NormTermName": "Zylinder",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Membran"
        },
        "00360": {
            "GenartName": "Kolbenbremszylinder",
            "NormTermName": "Zylinder",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Kolben"
        },
        "00361": {
            "GenartName": "Kombibremszylinder",
            "NormTermName": "Zylinder",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Kombination"
        },
        "00362": {
            "GenartName": "Arbeitszylinder",
            "NormTermName": "Zylinder",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Arbeitszylinder"
        },
        "00363": {
            "GenartName": "Arbeitszylinder, Tür",
            "NormTermName": "Zylinder",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Tür"
        },
        "00365": {
            "GenartName": "Differenzdruckwarnschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Differenzdruckwarnung"
        },
        "00366": {
            "GenartName": "Wegehahn",
            "NormTermName": "Wegehahn",
            "AssGrpName": "Druckluftanlage",
            "UsageName": null
        },
        "00367": {
            "GenartName": "Prüfanschluss",
            "NormTermName": "Prüfanschluss",
            "AssGrpName": "Druckluftanlage",
            "UsageName": null
        },
        "00368": {
            "GenartName": "Wendelschlauch",
            "NormTermName": "Wendelschlauch",
            "AssGrpName": "Druckluftanlage",
            "UsageName": null
        },
        "00369": {
            "GenartName": "Geräuschdämpfer, Druckluftanlage",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Geräuschdämpfer"
        },
        "00370": {
            "GenartName": "Kupplungskopf",
            "NormTermName": "Kupplungskopf",
            "AssGrpName": "Druckluftanlage",
            "UsageName": null
        },
        "00371": {
            "GenartName": "Heizflansch, Vergaser",
            "NormTermName": "Flansch",
            "AssGrpName": "Vergaser",
            "UsageName": "Vergaserheizung"
        },
        "00372": {
            "GenartName": "Heizklappenkasten",
            "NormTermName": "Kasten",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizklappen"
        },
        "00373": {
            "GenartName": "Verbinder, Druckluftleitung",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftleitung"
        },
        "00374": {
            "GenartName": "Reifenfüllanschluss",
            "NormTermName": "Reifenfüllanschluss",
            "AssGrpName": "Druckluftanlage",
            "UsageName": null
        },
        "00377": {
            "GenartName": "Absperrhahn",
            "NormTermName": "Absperrhahn",
            "AssGrpName": "Druckluftanlage",
            "UsageName": null
        },
        "00385": {
            "GenartName": "Federungskörper, Luftfederung",
            "NormTermName": "Federungskörper",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederung"
        },
        "00387": {
            "GenartName": "Unterdruckpumpe, Bremsanlage",
            "NormTermName": "Pumpe",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruck"
        },
        "00388": {
            "GenartName": "Hebelauge, Spurstange",
            "NormTermName": "Hebelauge",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstange"
        },
        "00389": {
            "GenartName": "Heckwand",
            "NormTermName": "Heckwand",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "00390": {
            "GenartName": "Hecktür",
            "NormTermName": "Tür",
            "AssGrpName": "Karosserie",
            "UsageName": "Hecktür"
        },
        "00391": {
            "GenartName": "Heckleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Heckleuchte"
        },
        "00392": {
            "GenartName": "Gehäuse, Heckleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Heckleuchte"
        },
        "00394": {
            "GenartName": "Heckträger",
            "NormTermName": "Träger",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Heckträger"
        },
        "00395": {
            "GenartName": "Achskörper",
            "NormTermName": "Achskörper",
            "AssGrpName": "Radaufhängung",
            "UsageName": null
        },
        "00396": {
            "GenartName": "Ausgleichsbehälter, Bremsflüssigkeit",
            "NormTermName": "Behälter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsflüssigkeit"
        },
        "00397": {
            "GenartName": "Ausgleichsbehälter, Kühlmittel",
            "NormTermName": "Behälter",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlwasser"
        },
        "00399": {
            "GenartName": "Faltenbalg, Bremssattelführung",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattelführung"
        },
        "00400": {
            "GenartName": "Druckstück, Scheibenbremsbelag",
            "NormTermName": "Druckstück",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Scheibenbremsbelag"
        },
        "00401": {
            "GenartName": "Dichtungssatz, Bremssattel",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "00402": {
            "GenartName": "Bremsbelagsatz, Scheibenbremse",
            "NormTermName": "Bremsbelagsatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "00404": {
            "GenartName": "Klemmhülse, Scheibenbremsbelag",
            "NormTermName": "Hülse",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Scheibenbremsbelag"
        },
        "00405": {
            "GenartName": "Reparatursatz, Bremssattel",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "00406": {
            "GenartName": "Halter, Scheibenbremsbelag",
            "NormTermName": "Halter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Scheibenbremsbelag"
        },
        "00407": {
            "GenartName": "Warnkontakt, Bremsbelagverschleiß",
            "NormTermName": "Kontakt",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Belagverschleiß"
        },
        "00408": {
            "GenartName": "Niete, Trommelbremsbelag",
            "NormTermName": "Niet",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Trommelbremsbelag"
        },
        "00410": {
            "GenartName": "Bremswelle, Trommelbremse",
            "NormTermName": "Welle",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Trommelbremse"
        },
        "00412": {
            "GenartName": "Sensor, Raddrehzahl",
            "NormTermName": "Sensor",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Raddrehzahl"
        },
        "00414": {
            "GenartName": "Regler, Niveauregulierung",
            "NormTermName": "Regler",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Niveauregulierung"
        },
        "00415": {
            "GenartName": "Hydraulikaggregat, Bremsanlage",
            "NormTermName": "Hydraulikaggregat",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "00416": {
            "GenartName": "Hydraulikfilter, Automatikgetriebe",
            "NormTermName": "Filter",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Hydrauliköl"
        },
        "00417": {
            "GenartName": "Hydraulikfilter, Lenkung",
            "NormTermName": "Filter",
            "AssGrpName": "Lenkung",
            "UsageName": "Hydrauliköl"
        },
        "00418": {
            "GenartName": "Hydraulikfilter, Niveauregulierung",
            "NormTermName": "Filter",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Niveauregulierung"
        },
        "00421": {
            "GenartName": "Innenkotflügel",
            "NormTermName": "Kotflügel",
            "AssGrpName": "Karosserie",
            "UsageName": "Innenkotflügel"
        },
        "00423": {
            "GenartName": "Innenraumleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Innenraumleuchte"
        },
        "00424": {
            "GenartName": "Filter, Innenraumluft",
            "NormTermName": "Filter",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumluft"
        },
        "00426": {
            "GenartName": "Kabelsatz, Nebelscheinwerfer",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "00427": {
            "GenartName": "Kabelsatz, Fernscheinwerfer",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "00428": {
            "GenartName": "Dauerpluskabel, Anhängevorrichtung",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Dauerplus"
        },
        "00429": {
            "GenartName": "Katalysator",
            "NormTermName": "Katalysator",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "00430": {
            "GenartName": "Nachrüstsatz, Katalysator",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Katalysator"
        },
        "00431": {
            "GenartName": "Gehäuse, Kennzeichenleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kennzeichenleuchte"
        },
        "00432": {
            "GenartName": "Kipphebel, Motorsteuerung",
            "NormTermName": "Hebel",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Kippen"
        },
        "00433": {
            "GenartName": "Kipphebelwelle, Motorsteuerung",
            "NormTermName": "Welle",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Kipphebel"
        },
        "00435": {
            "GenartName": "Gurtsatz, Fahrradhalter",
            "NormTermName": "Gurtsatz",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Fahrradhalter"
        },
        "00436": {
            "GenartName": "Nachrüstsatz, Klimaanlage",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": null
        },
        "00438": {
            "GenartName": "Kniehebelgelenk",
            "NormTermName": "Gelenk",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Kniehebelgelenk"
        },
        "00439": {
            "GenartName": "Karosserieboden, Koffer-/Laderaum",
            "NormTermName": "Boden",
            "AssGrpName": "Karosserie",
            "UsageName": "Koffer-/Laderaum"
        },
        "00440": {
            "GenartName": "Koffer-/Laderaumklappe",
            "NormTermName": "Klappe",
            "AssGrpName": "Karosserie",
            "UsageName": "Koffer-/Laderaum"
        },
        "00441": {
            "GenartName": "Kofferraumleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kofferraumleuchte"
        },
        "00442": {
            "GenartName": "Kofferraumstrebe, Anhängevorrichtung",
            "NormTermName": "Strebe",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Kofferraum"
        },
        "00443": {
            "GenartName": "Kolben",
            "NormTermName": "Kolben",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": null
        },
        "00444": {
            "GenartName": "Kolbenringsatz",
            "NormTermName": "Ringsatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kolben"
        },
        "00445": {
            "GenartName": "Kombi-Instrument",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Kombi-Instrument"
        },
        "00446": {
            "GenartName": "Kombinationsventil, Bremsanlage",
            "NormTermName": "Ventil",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Kombination"
        },
        "00447": {
            "GenartName": "Kompressor, Klimaanlage",
            "NormTermName": "Kompressor",
            "AssGrpName": "Klimaanlage",
            "UsageName": null
        },
        "00448": {
            "GenartName": "Kondensator, Klimaanlage",
            "NormTermName": "Kondensator",
            "AssGrpName": "Klimaanlage",
            "UsageName": null
        },
        "00449": {
            "GenartName": "Kotflügel",
            "NormTermName": "Kotflügel",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "00450": {
            "GenartName": "Verbreiterung, Kotflügel",
            "NormTermName": "Verbreiterung",
            "AssGrpName": "Karosserie",
            "UsageName": "Kotflügel"
        },
        "00451": {
            "GenartName": "Verbreiterungssatz, Kotflügel",
            "NormTermName": "Verbreiterungssatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Kotflügel"
        },
        "00453": {
            "GenartName": "Kraftstoffbehälter",
            "NormTermName": "Behälter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoff"
        },
        "00454": {
            "GenartName": "Kraftstofffördermodul",
            "NormTermName": "Pumpenmodul",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoff"
        },
        "00455": {
            "GenartName": "Dichtung, Ölwanne",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölwanne"
        },
        "00456": {
            "GenartName": "Dichtungssatz, Ölwanne",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölwanne"
        },
        "00457": {
            "GenartName": "Kupplungsschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungshydraulik"
        },
        "00458": {
            "GenartName": "Kraftstoffpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoff"
        },
        "00460": {
            "GenartName": "Pumpe, Kraftstoffvorförderung",
            "NormTermName": "Pumpe",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffvorförderung"
        },
        "00461": {
            "GenartName": "Anzeige, Kraftstoffvorrat",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Kraftstoffvorrat"
        },
        "00462": {
            "GenartName": "Gelenk, Lenksäule",
            "NormTermName": "Gelenk",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenksäule"
        },
        "00464": {
            "GenartName": "Kugelplatte, Anhängevorrichtung",
            "NormTermName": "Kugelplatte",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "00466": {
            "GenartName": "Kugelstangenhalter, Anhängevorrichtung",
            "NormTermName": "Halter",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Kugelstange"
        },
        "00467": {
            "GenartName": "Wärmetauscher, Innenraumheizung",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumheizung"
        },
        "00468": {
            "GenartName": "Ladeluftkühler",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladeluftkühlung"
        },
        "00469": {
            "GenartName": "Ölkühler, Motoröl",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Schmierung",
            "UsageName": "Motoröl"
        },
        "00470": {
            "GenartName": "Kühler, Motorkühlung",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Kühlung",
            "UsageName": "Motorkühlung"
        },
        "00471": {
            "GenartName": "Verdampfer, Klimaanlage",
            "NormTermName": "Verdampfer",
            "AssGrpName": "Klimaanlage",
            "UsageName": null
        },
        "00472": {
            "GenartName": "Kühlergitter",
            "NormTermName": "Gitter",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühler"
        },
        "00473": {
            "GenartName": "Halter, Kühlergitter",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühlergitter"
        },
        "00474": {
            "GenartName": "Rahmen, Kühlergitter",
            "NormTermName": "Rahmen",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühlergitter"
        },
        "00475": {
            "GenartName": "Kühlerschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühler"
        },
        "00476": {
            "GenartName": "Kühlerträger",
            "NormTermName": "Träger",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühlerträger"
        },
        "00477": {
            "GenartName": "Kupplungskugel, Anhängevorrichtung",
            "NormTermName": "Kupplungskugel",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "00478": {
            "GenartName": "Seilzug, Kupplungsbetätigung",
            "NormTermName": "Seilzug",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsbetätigung"
        },
        "00479": {
            "GenartName": "Kupplungssatz",
            "NormTermName": "Kupplungssatz",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "00481": {
            "GenartName": "Lagerdeckel, Kurbelwelle",
            "NormTermName": "Deckel",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwellenlager"
        },
        "00482": {
            "GenartName": "Lagerbock, Achskörper",
            "NormTermName": "Lagerbock",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achskörper"
        },
        "00483": {
            "GenartName": "Lagerbock, Motoraufhängung",
            "NormTermName": "Lagerbock",
            "AssGrpName": "Motoraufhängung",
            "UsageName": null
        },
        "00486": {
            "GenartName": "Lampenfassung, Suchscheinwerfer",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Suchscheinwerfer"
        },
        "00487": {
            "GenartName": "Lampenfassung, Arbeitsscheinwerfer",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "00488": {
            "GenartName": "Lampenfassung, Fernscheinwerfer",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "00489": {
            "GenartName": "Lampenfassung, Hauptscheinwerfer",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "00490": {
            "GenartName": "Lampenfassung, Nebelscheinwerfer",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "00492": {
            "GenartName": "Lampenträger, Blinkleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinkleuchte"
        },
        "00493": {
            "GenartName": "Lampenträger, Bremsleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Signalanlage",
            "UsageName": "Bremsleuchte"
        },
        "00494": {
            "GenartName": "Lampenträger, Heckleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Heckleuchte"
        },
        "00495": {
            "GenartName": "Lampenträger, Schlussleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Schlussleuchte"
        },
        "00496": {
            "GenartName": "Lampenträger, Rundumkennleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Signalanlage",
            "UsageName": "Rundumkennleuchte"
        },
        "00498": {
            "GenartName": "Lampenträger, Kennzeichenleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kennzeichenleuchte"
        },
        "00499": {
            "GenartName": "Lampenträger, Nebelschlussleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelschlussleuchte"
        },
        "00500": {
            "GenartName": "Lampenträger, Rückfahrleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Rückfahrleuchte"
        },
        "00502": {
            "GenartName": "Lenkgestänge",
            "NormTermName": "Gestänge",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "00503": {
            "GenartName": "Lenkhebel",
            "NormTermName": "Hebel",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "00504": {
            "GenartName": "Lenkspindel",
            "NormTermName": "Spindel",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "00505": {
            "GenartName": "Lenkstockhebel",
            "NormTermName": "Hebel",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkstock"
        },
        "00506": {
            "GenartName": "Lenkwelle",
            "NormTermName": "Welle",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "00507": {
            "GenartName": "Lenkzwischenhebel",
            "NormTermName": "Hebel",
            "AssGrpName": "Lenkung",
            "UsageName": "Zwischenhebel"
        },
        "00508": {
            "GenartName": "Lüfter, Motorkühlung",
            "NormTermName": "Lüfter",
            "AssGrpName": "Kühlung",
            "UsageName": "Motorkühlung"
        },
        "00509": {
            "GenartName": "Kupplung, Kühlerlüfter",
            "NormTermName": "Kupplung",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlerlüfter"
        },
        "00514": {
            "GenartName": "Gasfeder, Motorhaube",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorhaube"
        },
        "00515": {
            "GenartName": "Bremsleitung",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "00516": {
            "GenartName": "Kupplungsleitung",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "00518": {
            "GenartName": "Montagesatz, Katalysator",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Katalysator"
        },
        "00519": {
            "GenartName": "Montagesatz, Schalldämpfer",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Schalldämpfer"
        },
        "00522": {
            "GenartName": "Drehgelenk, Fahrerhaus",
            "NormTermName": "Gelenk",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhauslagerung"
        },
        "00523": {
            "GenartName": "Kippzylinder, Fahrerhaus",
            "NormTermName": "Zylinder",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Kippen"
        },
        "00525": {
            "GenartName": "Verriegelung, Fahrerhaus",
            "NormTermName": "Verriegelung",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhaus"
        },
        "00526": {
            "GenartName": "Feder, Fahrerhauslagerung",
            "NormTermName": "Feder",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhauslagerung"
        },
        "00527": {
            "GenartName": "Dämpfer, Fahrerhauslagerung",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhauslagerung"
        },
        "00528": {
            "GenartName": "Federbalg, Fahrerhauslagerung",
            "NormTermName": "Federbalg",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhauslagerung"
        },
        "00529": {
            "GenartName": "Ventil, Fahrerhauslagerung",
            "NormTermName": "Ventil",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhauslagerung"
        },
        "00530": {
            "GenartName": "Tür, Karosserie",
            "NormTermName": "Tür",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "00531": {
            "GenartName": "Motorhaube",
            "NormTermName": "Motorhaube",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "00533": {
            "GenartName": "Halter, Motoraufhängung",
            "NormTermName": "Halter",
            "AssGrpName": "Motoraufhängung",
            "UsageName": null
        },
        "00534": {
            "GenartName": "Hydraulikfilter, Kupplung",
            "NormTermName": "Filter",
            "AssGrpName": "Kupplung",
            "UsageName": "Hydrauliköl"
        },
        "00535": {
            "GenartName": "Hydraulikfilter, Bremsflüssigkeit",
            "NormTermName": "Filter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hydrauliköl"
        },
        "00536": {
            "GenartName": "Filter, Arbeitshydraulik",
            "NormTermName": "Filter",
            "AssGrpName": "Hydraulische Anlage",
            "UsageName": "Arbeitshydraulik"
        },
        "00537": {
            "GenartName": "Filter, Scheibenwaschwasser",
            "NormTermName": "Filter",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasser"
        },
        "00538": {
            "GenartName": "Abgasklappe, Motorbremse",
            "NormTermName": "Abgasklappe",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Motorbremse"
        },
        "00540": {
            "GenartName": "Spannarm, Keilrippenriemen",
            "NormTermName": "Spannarm",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilrippenriemen"
        },
        "00541": {
            "GenartName": "Riemenspanner, Keilrippenriemen",
            "NormTermName": "Spanner",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilrippenriemen"
        },
        "00542": {
            "GenartName": "Keilrippenriemensatz",
            "NormTermName": "Riemensatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilrippenriemen"
        },
        "00544": {
            "GenartName": "Spannarm, Zahnriemen",
            "NormTermName": "Spannarm",
            "AssGrpName": "Riementrieb",
            "UsageName": "Zahnriemen"
        },
        "00545": {
            "GenartName": "Riemenspanner, Zahnriemen",
            "NormTermName": "Spanner",
            "AssGrpName": "Riementrieb",
            "UsageName": "Zahnriemen"
        },
        "00546": {
            "GenartName": "Kühlmittelrohrleitung",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlwasser"
        },
        "00547": {
            "GenartName": "Dichtung, Kühlmittelrohrleitung",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelleitung"
        },
        "00548": {
            "GenartName": "Verschlussdeckel, Kühler",
            "NormTermName": "Verschluss",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlerverschluss"
        },
        "00549": {
            "GenartName": "Schraube, Kühlerverschluss",
            "NormTermName": "Schraube",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlerverschluss"
        },
        "00550": {
            "GenartName": "Hochdruckschalter, Klimaanlage",
            "NormTermName": "Schalter",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Hochdruck"
        },
        "00551": {
            "GenartName": "Dichtring, Kühlerverschlussschraube",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlerablassschraube"
        },
        "00552": {
            "GenartName": "Riemenscheibe, Lüfter",
            "NormTermName": "Riemenscheibe",
            "AssGrpName": "Kühlung",
            "UsageName": "Lüfter"
        },
        "00553": {
            "GenartName": "Halter, Ölkühler",
            "NormTermName": "Halter",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölkühler"
        },
        "00554": {
            "GenartName": "Lagerbock, Ölkühler",
            "NormTermName": "Lagerbock",
            "AssGrpName": "Karosserie",
            "UsageName": "Ölkühler"
        },
        "00555": {
            "GenartName": "Niederdruckschalter, Klimaanlage",
            "NormTermName": "Schalter",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Niederdruck"
        },
        "00556": {
            "GenartName": "Ölschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Schmierung",
            "UsageName": "Motoröl"
        },
        "00557": {
            "GenartName": "Dichtungssatz, Ölkühler",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölkühler"
        },
        "00558": {
            "GenartName": "Montagesatz, Wärmetauscher",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Wärmetauscher"
        },
        "00559": {
            "GenartName": "Dichtung, Heizklappenkasten",
            "NormTermName": "Dichtung",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizklappenkasten"
        },
        "00560": {
            "GenartName": "Dichtungsvollsatz, Motor",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Motor"
        },
        "00561": {
            "GenartName": "Schlepphebel, Motorsteuerung",
            "NormTermName": "Hebel",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Schleppen"
        },
        "00562": {
            "GenartName": "Stößelstange",
            "NormTermName": "Stange",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Kipphebelbetätigung"
        },
        "00563": {
            "GenartName": "Dichtung, Stößelstangenschutzrohr",
            "NormTermName": "Dichtung",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Stößelstangenschutzrohr"
        },
        "00564": {
            "GenartName": "Stößelstangenschutzrohr",
            "NormTermName": "Rohr",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Stößelstangenschutz"
        },
        "00566": {
            "GenartName": "Nockenwelle",
            "NormTermName": "Welle",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ventilbetätigung"
        },
        "00567": {
            "GenartName": "Nockenwellensatz",
            "NormTermName": "Wellensatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ventilbetätigung"
        },
        "00568": {
            "GenartName": "Dichtung, Steuergehäuse",
            "NormTermName": "Dichtung",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuergehäuse"
        },
        "00569": {
            "GenartName": "Dichtung, Steuergehäusedeckel",
            "NormTermName": "Dichtung",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuergehäusedeckel"
        },
        "00570": {
            "GenartName": "Dichtungssatz, Steuergehäuse",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuergehäuse"
        },
        "00571": {
            "GenartName": "Spanner, Steuerkette",
            "NormTermName": "Spanner",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkette"
        },
        "00572": {
            "GenartName": "Wellendichtring, Kurbelwelle",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwelle"
        },
        "00574": {
            "GenartName": "Kurbelwellenlager",
            "NormTermName": "Lagerschale",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwelle"
        },
        "00577": {
            "GenartName": "Schwungrad",
            "NormTermName": "Schwungrad",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": null
        },
        "00578": {
            "GenartName": "Zahnkranz, Schwungrad",
            "NormTermName": "Zahnkranz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Schwungrad"
        },
        "00579": {
            "GenartName": "Führungslager, Kupplung",
            "NormTermName": "Lager",
            "AssGrpName": "Kupplung",
            "UsageName": "Führung"
        },
        "00580": {
            "GenartName": "Zahnrad, Ausgleichswelle",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ausgleichswelle"
        },
        "00581": {
            "GenartName": "Pleuel",
            "NormTermName": "Pleuel",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": null
        },
        "00582": {
            "GenartName": "Pleuellager",
            "NormTermName": "Lagerschale",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Pleuel"
        },
        "00583": {
            "GenartName": "Lagerdeckel, Pleuel",
            "NormTermName": "Deckel",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Pleuellagerung"
        },
        "00584": {
            "GenartName": "Lagerbuchse, Pleuel",
            "NormTermName": "Buchse",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Pleuellagerung"
        },
        "00585": {
            "GenartName": "Sensor, Längsbeschleunigung",
            "NormTermName": "Sensor",
            "AssGrpName": "Fahrdynamikregelung",
            "UsageName": "Längsbeschleunigung"
        },
        "00586": {
            "GenartName": "Sensor, Querbeschleunigung",
            "NormTermName": "Sensor",
            "AssGrpName": "Fahrdynamikregelung",
            "UsageName": "Querbeschleunigung"
        },
        "00587": {
            "GenartName": "Kolbenbolzen, Kurbeltrieb",
            "NormTermName": "Bolzen",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kolben"
        },
        "00588": {
            "GenartName": "Sicherung, Kolbenbolzen",
            "NormTermName": "Sicherung",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kolbenbolzen"
        },
        "00589": {
            "GenartName": "Zylinderlaufbuchse",
            "NormTermName": "Buchse",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Zylinderlaufbuchse"
        },
        "00590": {
            "GenartName": "Gehäuse, Ölfilter",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölfilter"
        },
        "00591": {
            "GenartName": "Dichtung, Ölfiltergehäuse",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölfiltergehäuse"
        },
        "00592": {
            "GenartName": "Ölwanne",
            "NormTermName": "Ölwanne",
            "AssGrpName": "Schmierung",
            "UsageName": null
        },
        "00593": {
            "GenartName": "Verschlussschraube, Ölwanne",
            "NormTermName": "Schraube",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölablass"
        },
        "00594": {
            "GenartName": "Saugrohr, Ölpumpe",
            "NormTermName": "Rohr",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "00595": {
            "GenartName": "Sieb, Ölpumpensaugrohr",
            "NormTermName": "Sieb",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpensaugrohr"
        },
        "00596": {
            "GenartName": "Ölpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Schmierung",
            "UsageName": "Motoröl"
        },
        "00597": {
            "GenartName": "Verschluss, Öleinfüllstutzen",
            "NormTermName": "Verschluss",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Öleinfüllstutzen"
        },
        "00598": {
            "GenartName": "Dichtung, Öleinfüllstutzenverschluss",
            "NormTermName": "Dichtung",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Öleinfüllstutzen"
        },
        "00599": {
            "GenartName": "Ölpeilstab",
            "NormTermName": "Peilstab",
            "AssGrpName": "Schmierung",
            "UsageName": null
        },
        "00600": {
            "GenartName": "Gehäuse, Kraftstoffilter",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstofffilter"
        },
        "00601": {
            "GenartName": "Halteband, Kraftstoffbehälter",
            "NormTermName": "Band",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffbehälter"
        },
        "00602": {
            "GenartName": "Verschluss, Kraftstoffbehälter",
            "NormTermName": "Verschluss",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffbehälter"
        },
        "00603": {
            "GenartName": "Kraftstoffablassschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffablass"
        },
        "00604": {
            "GenartName": "Dichtring, Kraftsstoffablassschraube",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftsstoffablassschraube"
        },
        "00605": {
            "GenartName": "Sensor, Kraftstoffvorrat",
            "NormTermName": "Sensor",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffvorrat"
        },
        "00606": {
            "GenartName": "Dichtung, Kraftstoffbehälterverschluss",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffbehälterverschluss"
        },
        "00607": {
            "GenartName": "Einfüllstutzen, Kraftstoffbehälter",
            "NormTermName": "Einfüllstutzen",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffbehälter"
        },
        "00608": {
            "GenartName": "Kipppumpe, Fahrerhaus",
            "NormTermName": "Pumpe",
            "AssGrpName": "Hydraulische Anlage",
            "UsageName": "Fahrerhaus"
        },
        "00609": {
            "GenartName": "Kraftstoffschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": null
        },
        "00610": {
            "GenartName": "Kraftstoffleitung",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": null
        },
        "00611": {
            "GenartName": "Dichtung, Kraftstoffleitung",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffleitung"
        },
        "00612": {
            "GenartName": "Schlauchverbinder, Kraftstoffschlauch",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffschlauch"
        },
        "00613": {
            "GenartName": "Reparatursatz, Kraftstoffpumpe",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "00615": {
            "GenartName": "Reparatursatz, Handförderpumpe",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Handförderpumpe"
        },
        "00616": {
            "GenartName": "Schauglas, Handförderpumpe",
            "NormTermName": "Schauglas",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Handförderpumpe"
        },
        "00618": {
            "GenartName": "Gaszug",
            "NormTermName": "Seilzug",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Drosselklappe"
        },
        "00619": {
            "GenartName": "Reparatursatz, Ausrücklager",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Ausrücklager"
        },
        "00620": {
            "GenartName": "Nehmerzylinder, Kupplung",
            "NormTermName": "Zylinder",
            "AssGrpName": "Kupplung",
            "UsageName": "Nehmerzylinder"
        },
        "00621": {
            "GenartName": "Reparatursatz, Kupplungsgeberzylinder",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Geberzylinder"
        },
        "00622": {
            "GenartName": "Reparatursatz, Kupplungsnehmerzylinder",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Nehmerzylinder"
        },
        "00623": {
            "GenartName": "Dichtungssatz, Schaltgetriebe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "00624": {
            "GenartName": "Wellendichtring, Schaltgetriebe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebewelle"
        },
        "00625": {
            "GenartName": "Dichtungssatz, Automatikgetriebe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "00626": {
            "GenartName": "Wellendichtring, Automatikgetriebe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Getriebewelle"
        },
        "00627": {
            "GenartName": "Retarder",
            "NormTermName": "Retarder",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Dauerbremsanlage"
        },
        "00628": {
            "GenartName": "Dichtung, Differential",
            "NormTermName": "Dichtung",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "00629": {
            "GenartName": "Dichtungssatz, Differential",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "00630": {
            "GenartName": "Wellendichtring, Differential",
            "NormTermName": "Dichtring",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "00631": {
            "GenartName": "Dichtung, Verteilergetriebe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Verteilergetriebe"
        },
        "00632": {
            "GenartName": "Dichtungssatz, Verteilergetriebe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Verteilergetriebe"
        },
        "00633": {
            "GenartName": "Wellendichtring, Verteilergetriebe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Verteilergetriebewelle"
        },
        "00634": {
            "GenartName": "Dichtung, Nebenantrieb",
            "NormTermName": "Dichtung",
            "AssGrpName": "Nebenantrieb",
            "UsageName": null
        },
        "00635": {
            "GenartName": "Dichtungssatz, Nebenantrieb",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Nebenantrieb",
            "UsageName": null
        },
        "00636": {
            "GenartName": "Wellendichtring, Nebenantrieb",
            "NormTermName": "Dichtring",
            "AssGrpName": "Nebenantrieb",
            "UsageName": null
        },
        "00637": {
            "GenartName": "Federnpaket",
            "NormTermName": "Federpaket",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "00638": {
            "GenartName": "Federblatt",
            "NormTermName": "Federblatt",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "00639": {
            "GenartName": "Rohrleitung",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": null
        },
        "00643": {
            "GenartName": "Reparatursatz, Federbolzen",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbolzen"
        },
        "00644": {
            "GenartName": "Federbolzen",
            "NormTermName": "Bolzen",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbolzen"
        },
        "00645": {
            "GenartName": "Lagerbuchse, Blattfeder",
            "NormTermName": "Buchse",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Blattfederlagerung"
        },
        "00646": {
            "GenartName": "Federgehänge",
            "NormTermName": "Federgehänge",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "00647": {
            "GenartName": "Buchse, Federgehänge",
            "NormTermName": "Buchse",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federgehänge"
        },
        "00648": {
            "GenartName": "Federbock",
            "NormTermName": "Federbock",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "00649": {
            "GenartName": "Federbride",
            "NormTermName": "Federbride",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "00650": {
            "GenartName": "Zentrierring, Blattfeder",
            "NormTermName": "Zentrierring",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Blattfeder"
        },
        "00651": {
            "GenartName": "Herzbolzen",
            "NormTermName": "Bolzen",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Blattfeder"
        },
        "00652": {
            "GenartName": "Reparatursatz, Achsschenkelbolzen",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsschenkelbolzen"
        },
        "00653": {
            "GenartName": "Radnabe",
            "NormTermName": "Radnabe",
            "AssGrpName": "Radaufhängung",
            "UsageName": null
        },
        "00654": {
            "GenartName": "Radlagersatz",
            "NormTermName": "Lagersatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radnabe"
        },
        "00655": {
            "GenartName": "Radlager",
            "NormTermName": "Lager",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radnabe"
        },
        "00656": {
            "GenartName": "Wellendichtring, Radnabe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radnabe"
        },
        "00657": {
            "GenartName": "Radbolzen",
            "NormTermName": "Bolzen",
            "AssGrpName": "Räder",
            "UsageName": "Rad"
        },
        "00658": {
            "GenartName": "Radmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Räder",
            "UsageName": "Rad"
        },
        "00659": {
            "GenartName": "Limesring, Felge",
            "NormTermName": "Limesring",
            "AssGrpName": "Räder",
            "UsageName": "Felge"
        },
        "00660": {
            "GenartName": "Zentrierring, Felge",
            "NormTermName": "Zentrierring",
            "AssGrpName": "Räder",
            "UsageName": "Felge"
        },
        "00661": {
            "GenartName": "Felge",
            "NormTermName": "Felge",
            "AssGrpName": "Räder",
            "UsageName": null
        },
        "00662": {
            "GenartName": "Reparatursatz, Bremskraftverstärker",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremskraftverstärker"
        },
        "00663": {
            "GenartName": "Ventil, Bremskraftverstärker",
            "NormTermName": "Ventil",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremskraftverstärker"
        },
        "00664": {
            "GenartName": "Dichtung, Unterdruckpumpenkolben",
            "NormTermName": "Dichtung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruckpumpenkolben"
        },
        "00665": {
            "GenartName": "Ventil, Unterdruckpumpe",
            "NormTermName": "Ventil",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruckpumpe"
        },
        "00666": {
            "GenartName": "Dichtung, Unterdruckpumpe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruckpumpe"
        },
        "00667": {
            "GenartName": "Unterdruckschlauch, Bremsanlage",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruck"
        },
        "00668": {
            "GenartName": "Sensor, Bremsflüssigkeitsvorrat",
            "NormTermName": "Sensor",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsflüssigkeitsvorrat"
        },
        "00669": {
            "GenartName": "Bremsleitungssatz",
            "NormTermName": "Rohrleitungssatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "00671": {
            "GenartName": "Parkdose",
            "NormTermName": "Parkdose",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "00672": {
            "GenartName": "ABS-Verbindungskabel",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "ABS"
        },
        "00673": {
            "GenartName": "Reparatursatz, Umlenkhebel",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Umlenkhebel"
        },
        "00676": {
            "GenartName": "Gelenk, Lenkwelle",
            "NormTermName": "Gelenk",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkwelle"
        },
        "00677": {
            "GenartName": "Hydraulikschlauch, Lenkung",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkhydraulik"
        },
        "00678": {
            "GenartName": "Steuergerät, Servolenkung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Lenkung",
            "UsageName": "Servolenkung"
        },
        "00679": {
            "GenartName": "Ventil, Servolenkung",
            "NormTermName": "Ventil",
            "AssGrpName": "Lenkung",
            "UsageName": "Servolenkung"
        },
        "00680": {
            "GenartName": "Dichtung, Lenkgetriebe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkgetriebe"
        },
        "00681": {
            "GenartName": "Dichtungssatz, Lenkgetriebe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkgetriebe"
        },
        "00682": {
            "GenartName": "Magnetschalter, Starter",
            "NormTermName": "Schalter",
            "AssGrpName": "Startanlage",
            "UsageName": "Magnetschalter"
        },
        "00683": {
            "GenartName": "Zündverteiler",
            "NormTermName": "Verteiler",
            "AssGrpName": "Zündanlage",
            "UsageName": null
        },
        "00684": {
            "GenartName": "Zündleitung",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Zündanlage",
            "UsageName": null
        },
        "00685": {
            "GenartName": "Zündleitungssatz",
            "NormTermName": "Elektroleitungssatz",
            "AssGrpName": "Zündanlage",
            "UsageName": null
        },
        "00686": {
            "GenartName": "Zündkerze",
            "NormTermName": "Zündkerze",
            "AssGrpName": "Zündanlage",
            "UsageName": null
        },
        "00688": {
            "GenartName": "Kontaktsatz, Zündverteiler",
            "NormTermName": "Kontaktsatz",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteiler"
        },
        "00689": {
            "GenartName": "Zündspule",
            "NormTermName": "Zündspule",
            "AssGrpName": "Zündanlage",
            "UsageName": null
        },
        "00690": {
            "GenartName": "Kondensator, Zündanlage",
            "NormTermName": "Kondensator",
            "AssGrpName": "Zündanlage",
            "UsageName": null
        },
        "00691": {
            "GenartName": "Zündverteilerläufer",
            "NormTermName": "Zündverteilerläufer",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteiler"
        },
        "00692": {
            "GenartName": "Zündverteilerkappe",
            "NormTermName": "Kappe",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteiler"
        },
        "00694": {
            "GenartName": "Halter, Zündverteilerläufer",
            "NormTermName": "Halter",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteilerläufer"
        },
        "00695": {
            "GenartName": "Stecker, Zündverteiler",
            "NormTermName": "Stecker",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteiler"
        },
        "00696": {
            "GenartName": "Stecker, Zündkerze",
            "NormTermName": "Stecker",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündkerze"
        },
        "00698": {
            "GenartName": "Stecker, Zündspule",
            "NormTermName": "Stecker",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündspule"
        },
        "00699": {
            "GenartName": "Steuergerät, Glühzeit",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Glühanlage",
            "UsageName": "Glühzeit"
        },
        "00700": {
            "GenartName": "Stellelement, Leuchtweitenregulierung",
            "NormTermName": "Stellelement",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Leuchtweitenregulierung"
        },
        "00702": {
            "GenartName": "Reflektor, Hauptscheinwerfer",
            "NormTermName": "Reflektor",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "00703": {
            "GenartName": "Rahmen, Hauptscheinwerfer",
            "NormTermName": "Rahmen",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "00704": {
            "GenartName": "Kabelsatz, Hauptscheinwerfer",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "00705": {
            "GenartName": "Gehäuse, Hauptscheinwerfer",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "00706": {
            "GenartName": "Vorwiderstand, Zündanlage",
            "NormTermName": "Widerstand",
            "AssGrpName": "Zündanlage",
            "UsageName": "Vorwiderstand"
        },
        "00707": {
            "GenartName": "Glühüberwacher",
            "NormTermName": "Glühüberwacher",
            "AssGrpName": "Glühanlage",
            "UsageName": null
        },
        "00708": {
            "GenartName": "Nachrüstsatz, Schnellglühanlage",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Glühanlage",
            "UsageName": "Schnellglühanlage"
        },
        "00709": {
            "GenartName": "Vorwiderstand, Glühanlage",
            "NormTermName": "Widerstand",
            "AssGrpName": "Glühanlage",
            "UsageName": "Vorwiderstand"
        },
        "00710": {
            "GenartName": "Reflektor, Nebelscheinwerfer",
            "NormTermName": "Reflektor",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "00712": {
            "GenartName": "Rahmen, Nebelscheinwerfer",
            "NormTermName": "Rahmen",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "00713": {
            "GenartName": "Reflektor, Fernscheinwerfer",
            "NormTermName": "Reflektor",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "00715": {
            "GenartName": "Rahmen, Fernscheinwerfer",
            "NormTermName": "Rahmen",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "00716": {
            "GenartName": "Gehäuse, Fernscheinwerfer",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "00717": {
            "GenartName": "Suchscheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Suchscheinwerfer"
        },
        "00718": {
            "GenartName": "Reflektor, Suchscheinwerfer",
            "NormTermName": "Reflektor",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Suchscheinwerfer"
        },
        "00720": {
            "GenartName": "Rahmen, Suchscheinwerfer",
            "NormTermName": "Rahmen",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Suchscheinwerfer"
        },
        "00721": {
            "GenartName": "Kabelsatz, Suchscheinwerfer",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Suchscheinwerfer"
        },
        "00722": {
            "GenartName": "Blende, Suchscheinwerfer",
            "NormTermName": "Blende",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Suchscheinwerfer"
        },
        "00723": {
            "GenartName": "Gehäuse, Suchscheinwerfer",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Suchscheinwerfer"
        },
        "00724": {
            "GenartName": "Arbeitsscheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "00725": {
            "GenartName": "Reflektor, Arbeitsscheinwerfer",
            "NormTermName": "Reflektor",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "00727": {
            "GenartName": "Rahmen, Arbeitsscheinwerfer",
            "NormTermName": "Rahmen",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "00728": {
            "GenartName": "Kabelsatz, Arbeitsscheinwerfer",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "00729": {
            "GenartName": "Blende, Arbeitsscheinwerfer",
            "NormTermName": "Blende",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "00730": {
            "GenartName": "Gehäuse, Arbeitsscheinwerfer",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "00731": {
            "GenartName": "Schraube, Abgasanlage",
            "NormTermName": "Schraube",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "00734": {
            "GenartName": "Montagesatz, Anhängevorrichtung",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "00737": {
            "GenartName": "Montagesatz, Dachträger",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Dachträger"
        },
        "00738": {
            "GenartName": "Montagesatz, Kraftstoffbehälter",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffbehälter"
        },
        "00740": {
            "GenartName": "Scharnier, Motorhaube",
            "NormTermName": "Scharnier",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorhaube"
        },
        "00743": {
            "GenartName": "Nachsteller, Trommelbremse",
            "NormTermName": "Nachsteller",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Trommelbremse"
        },
        "00744": {
            "GenartName": "Gehäuse, Nebelschlussleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelschlussleuchte"
        },
        "00746": {
            "GenartName": "Kennzeichenhalter",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie",
            "UsageName": "Nummernschild"
        },
        "00751": {
            "GenartName": "Regler, Wisch-Wasch-Intervall",
            "NormTermName": "Regler",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Wisch-Wasch-Intervall"
        },
        "00752": {
            "GenartName": "Regler, Instrumentenbeleuchtung",
            "NormTermName": "Regler",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Instrumentenbeleuchtung"
        },
        "00753": {
            "GenartName": "Pulsationsdämpfer, Kraftstoffförderanlage",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpulsation"
        },
        "00754": {
            "GenartName": "Querträger",
            "NormTermName": "Träger",
            "AssGrpName": "Karosserie",
            "UsageName": "Querträger"
        },
        "00755": {
            "GenartName": "Radhaus",
            "NormTermName": "Radhaus",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "00757": {
            "GenartName": "Wellendichtring, Nockenwelle",
            "NormTermName": "Dichtring",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwelle"
        },
        "00758": {
            "GenartName": "Unterdrucksteuerventil, Abgasrückführung",
            "NormTermName": "Ventil",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "Unterdrucksteuerung"
        },
        "00761": {
            "GenartName": "Ladedruckregelventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladedruckregelung"
        },
        "00762": {
            "GenartName": "Relais, ABS",
            "NormTermName": "Relais",
            "AssGrpName": "Bremsanlage",
            "UsageName": "ABS"
        },
        "00763": {
            "GenartName": "Relais, Startsperrung",
            "NormTermName": "Relais",
            "AssGrpName": "Startanlage",
            "UsageName": "Startsperrung"
        },
        "00764": {
            "GenartName": "Verzögerungsrelais, Anhängevorrichtung",
            "NormTermName": "Relais",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Zeitfunktion"
        },
        "00765": {
            "GenartName": "Reserveradwanne",
            "NormTermName": "Boden",
            "AssGrpName": "Karosserie",
            "UsageName": "Reserveradwanne"
        },
        "00768": {
            "GenartName": "Reparatursatz, Scheibenwaschwasserdüse",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Scheibenwaschwasserdüse"
        },
        "00769": {
            "GenartName": "Reparatursatz, Führungsstrebe",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Führungsstrebe"
        },
        "00770": {
            "GenartName": "Reparatursatz, Spurstange",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstange"
        },
        "00771": {
            "GenartName": "Reparatursatz, Radaufhängung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": null
        },
        "00772": {
            "GenartName": "Reparatursatz, Querlenker",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Querlenker"
        },
        "00773": {
            "GenartName": "Reparatursatz, Spurstangenkopf",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstangenkopf"
        },
        "00774": {
            "GenartName": "Reparatursatz, Spurstangenkugelbolzen",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstangenkugelbolzen"
        },
        "00775": {
            "GenartName": "Reparatursatz, Drehzapfen",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Drehzapfen"
        },
        "00776": {
            "GenartName": "Reparatursatz, Achsschenkel",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsschenkel"
        },
        "00780": {
            "GenartName": "Reparatursatz, Führungsbolzen",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Führungsbolzen"
        },
        "00781": {
            "GenartName": "Reparatursatz, Automatische Nachstellung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Automatische Nachstellung"
        },
        "00782": {
            "GenartName": "Reparatursatz, Bremskraftregler",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremskraftregler"
        },
        "00783": {
            "GenartName": "Reparatursatz, Expander",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Expander"
        },
        "00784": {
            "GenartName": "Heckblende",
            "NormTermName": "Blende",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Heckleuchte"
        },
        "00785": {
            "GenartName": "Halter, Nebelschlussleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelschlussleuchte"
        },
        "00786": {
            "GenartName": "Rundumkennleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Signalanlage",
            "UsageName": "Rundumkennleuchte"
        },
        "00787": {
            "GenartName": "Halter, Scheibenwischermotor",
            "NormTermName": "Halter",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Wischermotor"
        },
        "00788": {
            "GenartName": "Elektromotor, Schiebedach",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Schiebedach"
        },
        "00789": {
            "GenartName": "Stellelement, Außenspiegel",
            "NormTermName": "Stellelement",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Außenspiegel"
        },
        "00790": {
            "GenartName": "Elektromotor, Sitzverstellung",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sitzverstellung"
        },
        "00791": {
            "GenartName": "Stellelement, Zentralverriegelung",
            "NormTermName": "Stellelement",
            "AssGrpName": "Schließanlage",
            "UsageName": "Zentralverriegelung"
        },
        "00792": {
            "GenartName": "Elektromotor, Kühlerlüfter",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Kühlung",
            "UsageName": "Lüfter"
        },
        "00793": {
            "GenartName": "Elektromotor, Innenraumgebläse",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumgebläse"
        },
        "00794": {
            "GenartName": "Waschwasserpumpe, Scheibenreinigung",
            "NormTermName": "Pumpe",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasser"
        },
        "00795": {
            "GenartName": "Waschwasserpumpe, Scheinwerferreinigung",
            "NormTermName": "Pumpe",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": "Waschwasser"
        },
        "00796": {
            "GenartName": "Relais, Wisch-Wasch-Intervall",
            "NormTermName": "Relais",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Wisch-Wasch-Intervall"
        },
        "00797": {
            "GenartName": "Relais, Kaltstartsteuerung",
            "NormTermName": "Relais",
            "AssGrpName": "Vergaser",
            "UsageName": "Kaltstartsteuerung"
        },
        "00799": {
            "GenartName": "Batterierelais",
            "NormTermName": "Relais",
            "AssGrpName": "Batterie",
            "UsageName": null
        },
        "00800": {
            "GenartName": "Relais, Arbeitsstrom",
            "NormTermName": "Relais",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Arbeitsstrom"
        },
        "00802": {
            "GenartName": "Warnblinkrelais",
            "NormTermName": "Relais",
            "AssGrpName": "Signalanlage",
            "UsageName": "Warnblinker"
        },
        "00803": {
            "GenartName": "Relais, Klimaanlage",
            "NormTermName": "Relais",
            "AssGrpName": "Klimaanlage",
            "UsageName": null
        },
        "00804": {
            "GenartName": "Unterdruckschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Unterdruck"
        },
        "00805": {
            "GenartName": "Öldruckschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Schmierung",
            "UsageName": "Öldruck"
        },
        "00806": {
            "GenartName": "Bremslichtschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Signalanlage",
            "UsageName": "Bremsleuchte"
        },
        "00807": {
            "GenartName": "Schalter, Rückfahrleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Rückfahrleuchte"
        },
        "00808": {
            "GenartName": "Schalter, Türkontakt",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Türkontakt"
        },
        "00809": {
            "GenartName": "Schalter, Hauptlicht",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptlicht"
        },
        "00810": {
            "GenartName": "Schalter, Nebellicht",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebellicht"
        },
        "00811": {
            "GenartName": "Schalter, Innenraumleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Innenraumleuchte"
        },
        "00812": {
            "GenartName": "Blinkerschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinker"
        },
        "00813": {
            "GenartName": "Zünd-/Startschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Startanlage",
            "UsageName": "Zündung/Anlasser"
        },
        "00814": {
            "GenartName": "Regler, Innenraumleuchte",
            "NormTermName": "Regler",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Innenraumleuchte"
        },
        "00815": {
            "GenartName": "Regler, Leuchtweitenregulierung",
            "NormTermName": "Regler",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Leuchtweitenregulierung"
        },
        "00816": {
            "GenartName": "Warnblinkschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Signalanlage",
            "UsageName": "Warnblinker"
        },
        "00817": {
            "GenartName": "Schalter, Kraftstoffdruck",
            "NormTermName": "Schalter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffdruck"
        },
        "00818": {
            "GenartName": "Sicherung",
            "NormTermName": "Sicherung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "00819": {
            "GenartName": "Sicherungssatz",
            "NormTermName": "Sicherungssatz",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "00820": {
            "GenartName": "Sicherungsdose",
            "NormTermName": "Sicherungsdose",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "00821": {
            "GenartName": "Elektroleitung",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "00822": {
            "GenartName": "Leitungsverbinder",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "00823": {
            "GenartName": "Leitungsverbindersatz",
            "NormTermName": "Verbindungselementsatz",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "00824": {
            "GenartName": "Stecker",
            "NormTermName": "Stecker",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "00825": {
            "GenartName": "Steckdose",
            "NormTermName": "Steckdose",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "00826": {
            "GenartName": "Fahrtenschreiber",
            "NormTermName": "Fahrtenschreiber",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": null
        },
        "00827": {
            "GenartName": "Fahrtenschreiberscheibe",
            "NormTermName": "Fahrtenschreiberscheibe",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": null
        },
        "00828": {
            "GenartName": "Relais, Alarmanlage",
            "NormTermName": "Relais",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Alarmanlage"
        },
        "00829": {
            "GenartName": "Sensor, Öltemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Schmierung",
            "UsageName": "Öltemperatur"
        },
        "00830": {
            "GenartName": "Sensor, Kühlmitteltemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmitteltemperatur"
        },
        "00832": {
            "GenartName": "Sensor, Innenraumtemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Innenraumtemperatur"
        },
        "00833": {
            "GenartName": "Impulsgeber, Kurbelwelle",
            "NormTermName": "Sensor",
            "AssGrpName": "Zündanlage",
            "UsageName": "Kurbelwelle"
        },
        "00834": {
            "GenartName": "Sensor, Zündimpuls",
            "NormTermName": "Sensor",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündimpuls"
        },
        "00836": {
            "GenartName": "Wischblatt, Scheinwerferreinigung",
            "NormTermName": "Wischblatt",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": null
        },
        "00837": {
            "GenartName": "Waschwasserbehälter, Scheibenreinigung",
            "NormTermName": "Behälter",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasser"
        },
        "00838": {
            "GenartName": "Waschwasserleitung",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasser"
        },
        "00839": {
            "GenartName": "Verbindungsstück, Waschwasserleitung",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasserleitung"
        },
        "00840": {
            "GenartName": "Ventil, Waschwasserleitung",
            "NormTermName": "Ventil",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasserleitung"
        },
        "00841": {
            "GenartName": "Faltenbalg, Wischergestänge",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Wischergestänge"
        },
        "00842": {
            "GenartName": "Kappe, Wischarm",
            "NormTermName": "Kappe",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Wischarm"
        },
        "00843": {
            "GenartName": "Wischarm, Scheinwerferreinigung",
            "NormTermName": "Wischarm",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": null
        },
        "00844": {
            "GenartName": "Windleitblech, Fahrerhaus",
            "NormTermName": "Spoiler",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Windleitblech"
        },
        "00845": {
            "GenartName": "Spoiler",
            "NormTermName": "Spoiler",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "00846": {
            "GenartName": "Gasfeder, Windleitblech",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Windleitblech"
        },
        "00847": {
            "GenartName": "Rampenspiegel",
            "NormTermName": "Spiegel",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Rampe"
        },
        "00848": {
            "GenartName": "Halter, Außenspiegel",
            "NormTermName": "Halter",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Außenspiegel"
        },
        "00849": {
            "GenartName": "Innenspiegel",
            "NormTermName": "Spiegel",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Innen"
        },
        "00851": {
            "GenartName": "Trockner, Klimaanlage",
            "NormTermName": "Trockner",
            "AssGrpName": "Klimaanlage",
            "UsageName": null
        },
        "00852": {
            "GenartName": "Drehschieberventil, Druckluftanlage",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Niveauregulierung"
        },
        "00853": {
            "GenartName": "Pleuelschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Pleuel"
        },
        "00854": {
            "GenartName": "Stoßdämpfer",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "00855": {
            "GenartName": "Gehäuse, Nebelscheinwerfer",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "00856": {
            "GenartName": "Blende, Fernscheinwerfer",
            "NormTermName": "Blende",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "00857": {
            "GenartName": "Gehäuse, Schlussleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Schlussleuchte"
        },
        "00858": {
            "GenartName": "Gehäuse, Rückfahrleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Rückfahrleuchte"
        },
        "00859": {
            "GenartName": "Saugrohr, Luftversorgung",
            "NormTermName": "Rohr",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Saugrohr"
        },
        "00861": {
            "GenartName": "Rolle, Heckträger",
            "NormTermName": "Rolle",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Heckträger"
        },
        "00862": {
            "GenartName": "Ventil, Kraftstoffpumpe",
            "NormTermName": "Ventil",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "00863": {
            "GenartName": "Ventil, Sekundärluftpumpsystem",
            "NormTermName": "Ventil",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": "Sekundärluft-Pumpsystem"
        },
        "00864": {
            "GenartName": "Schalter, Türverriegelung",
            "NormTermName": "Schalter",
            "AssGrpName": "Schließanlage",
            "UsageName": "Türverriegelung"
        },
        "00865": {
            "GenartName": "Schalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "00870": {
            "GenartName": "Drehzahlschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Instrumente",
            "UsageName": "Drehzahl"
        },
        "00871": {
            "GenartName": "Schaltergehäuse",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Schalter"
        },
        "00872": {
            "GenartName": "Schalteraufnahme",
            "NormTermName": "Schalteraufnahme",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "00873": {
            "GenartName": "Schaltergriff",
            "NormTermName": "Griff",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Schalter"
        },
        "00874": {
            "GenartName": "Schalterhebel",
            "NormTermName": "Hebel",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Schalter"
        },
        "00875": {
            "GenartName": "Schalterblende",
            "NormTermName": "Blende",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Schalter"
        },
        "00877": {
            "GenartName": "Wischerschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": null
        },
        "00878": {
            "GenartName": "Bedienschalter, Geschwindigkeitsregelanlage",
            "NormTermName": "Schalter",
            "AssGrpName": "Geschwindigkeitsregelanlage",
            "UsageName": null
        },
        "00879": {
            "GenartName": "Scheibenreinigungsanlage",
            "NormTermName": "Reinigungsanlage",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": null
        },
        "00882": {
            "GenartName": "Scheinwerferleiste",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie",
            "UsageName": "Scheinwerfer"
        },
        "00887": {
            "GenartName": "Schlingertopf, Kraftstoffpumpe",
            "NormTermName": "Schlingertopf",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": null
        },
        "00888": {
            "GenartName": "Schlosssatz, Dachträger",
            "NormTermName": "Schlosssatz",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Dachträger"
        },
        "00890": {
            "GenartName": "Schraubensatz, Kupplung",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "00896": {
            "GenartName": "Kappe, Wischarm",
            "NormTermName": "Kappe",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": "Wischarm"
        },
        "00900": {
            "GenartName": "Seitenmarkierungsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Seitenleuchte"
        },
        "00901": {
            "GenartName": "Seitenwand",
            "NormTermName": "Seitenwand",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "00902": {
            "GenartName": "Sekundärluftanlage",
            "NormTermName": "Regelanlage",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": null
        },
        "00903": {
            "GenartName": "Sekundärluftpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": "Sekundärluft"
        },
        "00904": {
            "GenartName": "Ventil, Sekundärluftsystem",
            "NormTermName": "Ventil",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": "Sekundärluft"
        },
        "00905": {
            "GenartName": "Siebscheibeneinsatz",
            "NormTermName": "Einsatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Siebscheiben"
        },
        "00906": {
            "GenartName": "Skihalter, Dachträger",
            "NormTermName": "Skihalter",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Dachträger"
        },
        "00907": {
            "GenartName": "Skihalter, Heckträger",
            "NormTermName": "Skihalter",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Heckträger"
        },
        "00908": {
            "GenartName": "Spanngurt, Trägersystem",
            "NormTermName": "Gurt",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": null
        },
        "00910": {
            "GenartName": "Halter, Dachträgerspanngurt",
            "NormTermName": "Halter",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Dachträgerspanngurt"
        },
        "00912": {
            "GenartName": "Sportschalldämpferanlage",
            "NormTermName": "Abgasanlage",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Sportschalldämpfer"
        },
        "00913": {
            "GenartName": "Spritzwand",
            "NormTermName": "Spritzwand",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "00914": {
            "GenartName": "Spurstangenkopf",
            "NormTermName": "Gelenk",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstangenkopf"
        },
        "00915": {
            "GenartName": "Spurstangenrohr",
            "NormTermName": "Rohr",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstange"
        },
        "00917": {
            "GenartName": "Standheizung",
            "NormTermName": "Standheizung",
            "AssGrpName": "Komfortsysteme",
            "UsageName": null
        },
        "00918": {
            "GenartName": "Steckerhülse, Zündanlage",
            "NormTermName": "Hülse",
            "AssGrpName": "Zündanlage",
            "UsageName": "Stecker"
        },
        "00919": {
            "GenartName": "Staubschutzsatz, Stoßdämpfer",
            "NormTermName": "Staubschutzsatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Stoßdämpfer"
        },
        "00920": {
            "GenartName": "Stellelement, Sitzverstellung",
            "NormTermName": "Stellelement",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sitzverstellung"
        },
        "00923": {
            "GenartName": "Stellelement, Sitzhöhenverstellung",
            "NormTermName": "Stellelement",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sitzhöhenverstellung"
        },
        "00924": {
            "GenartName": "Stellelement, Sitzlehnenverstellung",
            "NormTermName": "Stellelement",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sitzlehnenverstellung"
        },
        "00925": {
            "GenartName": "Stellelement, Kopfstütze",
            "NormTermName": "Stellelement",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Kopfstütze"
        },
        "00926": {
            "GenartName": "Stellelement, Klappscheinwerfer",
            "NormTermName": "Stellelement",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Klappscheinwerfer"
        },
        "00930": {
            "GenartName": "Steuergerät, Einparkhilfe",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Einparkhilfe"
        },
        "00932": {
            "GenartName": "Steuergerät, Zündanlage",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Zündanlage",
            "UsageName": null
        },
        "00935": {
            "GenartName": "Steuergerät, Vergaser",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "00937": {
            "GenartName": "Steuerklappe, Luftversorgung",
            "NormTermName": "Klappe",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Luftsteuerung"
        },
        "00938": {
            "GenartName": "Stoßfänger",
            "NormTermName": "Stoßfänger",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "00939": {
            "GenartName": "Halter, Stoßfänger",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie",
            "UsageName": "Stoßfänger"
        },
        "00941": {
            "GenartName": "Stoßfängerträger, Anhängevorrichtung",
            "NormTermName": "Träger",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Stoßfänger"
        },
        "00943": {
            "GenartName": "Stoßfängerabdeckung, Anhängevorrichtung",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Stoßfänger"
        },
        "00945": {
            "GenartName": "Stoßfängerblech, Anhängevorrichtung",
            "NormTermName": "Blech",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Stoßfänger"
        },
        "00949": {
            "GenartName": "Stütze, Anhängevorrichtung",
            "NormTermName": "Stütze",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "00950": {
            "GenartName": "Lagerung, Stoßdämpfer",
            "NormTermName": "Lagerung",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Stoßdämpfer"
        },
        "00951": {
            "GenartName": "Surfbretthalter, Dachträger",
            "NormTermName": "Surfbretthalter",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Dachträger"
        },
        "00952": {
            "GenartName": "Tachometer",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Tachometer"
        },
        "00955": {
            "GenartName": "Anzeige, Kühlmitteltemperatur",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Kühlmitteltemperatur"
        },
        "00956": {
            "GenartName": "Anzeige, Öltemperatur",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Öltemperatur"
        },
        "00957": {
            "GenartName": "Anzeige, Außentemperatur",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Außentemperatur"
        },
        "00962": {
            "GenartName": "Anzeige, Außen-/Innenraumtemperatur",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Außen-/Innenraumtemperatur"
        },
        "00963": {
            "GenartName": "Thermometer, Außen-/Innenraumtemperatur",
            "NormTermName": "Thermometer",
            "AssGrpName": "Instrumente",
            "UsageName": "Außen-/Innenraumtemperatur"
        },
        "00964": {
            "GenartName": "Thermometer, Außentemperatur",
            "NormTermName": "Thermometer",
            "AssGrpName": "Instrumente",
            "UsageName": "Außentemperatur"
        },
        "00965": {
            "GenartName": "Transportplattform, Heckträger",
            "NormTermName": "Transportplattform",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Heckträger"
        },
        "00966": {
            "GenartName": "Thermostat, Ölkühlung",
            "NormTermName": "Thermostat",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölkühlung"
        },
        "00969": {
            "GenartName": "Trittbrett, Anhängevorrichtung",
            "NormTermName": "Trittbrett",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "00970": {
            "GenartName": "Trittbrettstufe, Anhängevorrichtung",
            "NormTermName": "Trittbrettstufe",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "00971": {
            "GenartName": "Dachsäule",
            "NormTermName": "Dachsäule",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "00974": {
            "GenartName": "Ventil, Vergaserumluft",
            "NormTermName": "Ventil",
            "AssGrpName": "Vergaser",
            "UsageName": "Umluft"
        },
        "00975": {
            "GenartName": "Unfalldatenspeicher",
            "NormTermName": "Datenspeicher",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Unfalldaten"
        },
        "00976": {
            "GenartName": "Unterdruckdose, Vergaser",
            "NormTermName": "Unterdruckdose",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "00977": {
            "GenartName": "Zylinderkopfhaube",
            "NormTermName": "Haube",
            "AssGrpName": "Zylinderkopf",
            "UsageName": null
        },
        "00979": {
            "GenartName": "Dichtungssatz, Zylinderkopfhaube",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Zylinderkopfhaube"
        },
        "00981": {
            "GenartName": "Vergaser",
            "NormTermName": "Vergaser",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "00982": {
            "GenartName": "Verschlussschraube, Hauptbremszylinder",
            "NormTermName": "Schraube",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hauptbremszylinderverschluss"
        },
        "00987": {
            "GenartName": "Voltmeter",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Voltmeter"
        },
        "00990": {
            "GenartName": "Aufnahme, Wagenheber",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Karosserie",
            "UsageName": "Wagenheber"
        },
        "00992": {
            "GenartName": "Dichtung, Wärmetauscher",
            "NormTermName": "Dichtung",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Wärmetauscher"
        },
        "00993": {
            "GenartName": "Warmluftfangblech",
            "NormTermName": "Blech",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Warmluftfangblech"
        },
        "00994": {
            "GenartName": "Rohrleitung, Wärmetauscher",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Warmluft"
        },
        "00995": {
            "GenartName": "Warndreieck",
            "NormTermName": "Warndreieck",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": null
        },
        "00996": {
            "GenartName": "Warnleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Warnleuchte"
        },
        "00997": {
            "GenartName": "Warnweste",
            "NormTermName": "Warnweste",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": null
        },
        "00999": {
            "GenartName": "Wasserumwälzpumpe, Standheizung",
            "NormTermName": "Pumpe",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Standheizung"
        },
        "01000": {
            "GenartName": "Zentralverriegelung",
            "NormTermName": "Zentralverriegelung",
            "AssGrpName": "Schließanlage",
            "UsageName": null
        },
        "01001": {
            "GenartName": "Uhr",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Uhr"
        },
        "01002": {
            "GenartName": "Zier-/Schutzleiste, Stoßfänger",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie",
            "UsageName": "Stoßfänger"
        },
        "01003": {
            "GenartName": "Zier-/Schutzleiste, Seitenwand",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie",
            "UsageName": "Seitenwand"
        },
        "01004": {
            "GenartName": "Zier-/Schutzleiste, Kotflügel",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie",
            "UsageName": "Kotflügel"
        },
        "01005": {
            "GenartName": "Zier-/Schutzleiste, Tür",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie",
            "UsageName": "Tür"
        },
        "01006": {
            "GenartName": "Zier-/Schutzleiste, Frontverkleidung",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie",
            "UsageName": "Frontverkleidung"
        },
        "01007": {
            "GenartName": "Zier-/Schutzleistensatz, Stoßfänger",
            "NormTermName": "Zier-/Schutzleistensatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Stoßfänger"
        },
        "01008": {
            "GenartName": "Membran, Unterdruckpumpe",
            "NormTermName": "Membran",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruckpumpe"
        },
        "01009": {
            "GenartName": "Halter, Bremssattel",
            "NormTermName": "Halter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "01047": {
            "GenartName": "Ölkühler, Automatikgetriebe",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Hydrauliköl"
        },
        "01048": {
            "GenartName": "Ölkühler, Lenkung",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Lenkung",
            "UsageName": "Hydrauliköl"
        },
        "01049": {
            "GenartName": "Wellrohr, Abgasanlage",
            "NormTermName": "Rohr",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Wellrohr"
        },
        "01050": {
            "GenartName": "Rohrverbinder, Abgasanlage",
            "NormTermName": "Rohrverbinder",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "01055": {
            "GenartName": "Gummistreifen, Abgasanlage",
            "NormTermName": "Gummistreifen",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "01056": {
            "GenartName": "Klemmstück, Abgasanlage",
            "NormTermName": "Klemmstück",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "01057": {
            "GenartName": "Halter, Schalldämpfer",
            "NormTermName": "Halter",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Schalldämpfer"
        },
        "01058": {
            "GenartName": "Flansch, Abgasrohr",
            "NormTermName": "Flansch",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasrohr"
        },
        "01059": {
            "GenartName": "Halter, Abgasanlage",
            "NormTermName": "Halter",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "01060": {
            "GenartName": "Feder, Abgasrohr",
            "NormTermName": "Feder",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasrohr"
        },
        "01061": {
            "GenartName": "Feder, Schalldämpfer",
            "NormTermName": "Feder",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Schalldämpfer"
        },
        "01062": {
            "GenartName": "Halter, Abgasrohr",
            "NormTermName": "Halter",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Rohr"
        },
        "01063": {
            "GenartName": "Drahtbügel, Abgasanlage",
            "NormTermName": "Drahtbügel",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "01064": {
            "GenartName": "Druckspeicher, Bremsanlage",
            "NormTermName": "Druckspeicher",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsdruck"
        },
        "01066": {
            "GenartName": "Zahnrad, Kurbelwelle",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Kurbelwelle"
        },
        "01067": {
            "GenartName": "Zahnrad, Nockenwelle",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwelle"
        },
        "01068": {
            "GenartName": "Zahnrad, Verteilerwelle",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Verteilerwelle"
        },
        "01069": {
            "GenartName": "Zahnrad, Zwischenwelle",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Zwischenwelle"
        },
        "01075": {
            "GenartName": "Gleitschiene, Steuerkette",
            "NormTermName": "Gleitschiene",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkette"
        },
        "01076": {
            "GenartName": "Kappe, Fernscheinwerfer",
            "NormTermName": "Kappe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "01077": {
            "GenartName": "Kappe, Nebelscheinwerfer",
            "NormTermName": "Kappe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "01078": {
            "GenartName": "Kappe, Suchscheinwerfer",
            "NormTermName": "Kappe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Suchscheinwerfer"
        },
        "01079": {
            "GenartName": "Kappe, Arbeitsscheinwerfer",
            "NormTermName": "Kappe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "01080": {
            "GenartName": "Lagerung, Achskörper",
            "NormTermName": "Lagerung",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achskörper"
        },
        "01081": {
            "GenartName": "Flansch, Vergaser",
            "NormTermName": "Flansch",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01085": {
            "GenartName": "Längsträger",
            "NormTermName": "Träger",
            "AssGrpName": "Karosserie",
            "UsageName": "Längsträger"
        },
        "01087": {
            "GenartName": "Aufpralldämpfer, Stoßfänger",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Karosserie",
            "UsageName": "Stoßfänger"
        },
        "01088": {
            "GenartName": "Kühleremblem",
            "NormTermName": "Emblem",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühlergitter"
        },
        "01089": {
            "GenartName": "Dichtungssatz, Vergaser",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01090": {
            "GenartName": "Reparatursatz, Vergaser",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01091": {
            "GenartName": "Lagerbuchse, Bremswelle",
            "NormTermName": "Buchse",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremswellenlagerung"
        },
        "01092": {
            "GenartName": "Glühlampe, Park-/Positionsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Park-/Begrenzungsleuchte"
        },
        "01093": {
            "GenartName": "Rückstrahler",
            "NormTermName": "Rückstrahler",
            "AssGrpName": "Beleuchtung",
            "UsageName": null
        },
        "01094": {
            "GenartName": "Alarmanlage",
            "NormTermName": "Warnanlage",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Diebstahlwarnung"
        },
        "01095": {
            "GenartName": "Bremsbackenbolzen",
            "NormTermName": "Bolzen",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbacken"
        },
        "01096": {
            "GenartName": "Feder, Gestängesteller",
            "NormTermName": "Feder",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Gestängesteller"
        },
        "01097": {
            "GenartName": "Reparatursatz, Stabilisatorlager",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisatorlager"
        },
        "01098": {
            "GenartName": "Kabelsatz, Vergaser",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01099": {
            "GenartName": "Dichtung, Hauptscheinwerfer",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "01100": {
            "GenartName": "Dichtung, Nebelscheinwerfer",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "01101": {
            "GenartName": "Halter, Bremsleitung",
            "NormTermName": "Halter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsleitung"
        },
        "01102": {
            "GenartName": "Dichtung, Zylinderkopfhaubenschrauben",
            "NormTermName": "Dichtung",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Zylinderkopfhaubenschrauben"
        },
        "01103": {
            "GenartName": "Temperaturschalter, Kühlerlüfter",
            "NormTermName": "Schalter",
            "AssGrpName": "Kühlung",
            "UsageName": "Motorkühlung"
        },
        "01104": {
            "GenartName": "Schalter, Vergaser",
            "NormTermName": "Schalter",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01105": {
            "GenartName": "Öldruckventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Schmierung",
            "UsageName": "Öldruck"
        },
        "01108": {
            "GenartName": "Riemenscheibe, Generator",
            "NormTermName": "Riemenscheibe",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "01110": {
            "GenartName": "Lüfter, Generator",
            "NormTermName": "Lüfter",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "01111": {
            "GenartName": "Relais, Kraftstoffpumpe",
            "NormTermName": "Relais",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "01112": {
            "GenartName": "Montagesatz, Klimaanlage",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": null
        },
        "01113": {
            "GenartName": "Scheinwerferaufnahme",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Karosserie",
            "UsageName": "Scheinwerfer"
        },
        "01114": {
            "GenartName": "Leuchtenaufnahme",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Karosserie",
            "UsageName": "Leuchten"
        },
        "01115": {
            "GenartName": "Hydraulikpumpe, Bremsanlage",
            "NormTermName": "Pumpe",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremshydraulik"
        },
        "01116": {
            "GenartName": "Halter, Blinkleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinkleuchte"
        },
        "01117": {
            "GenartName": "Halter, Bremsleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Signalanlage",
            "UsageName": "Bremsleuchte"
        },
        "01118": {
            "GenartName": "Halter, Rückfahrleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Rückfahrleuchte"
        },
        "01122": {
            "GenartName": "Karosserie",
            "NormTermName": "Karosserie",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "01123": {
            "GenartName": "Steuerkette",
            "NormTermName": "Kette",
            "AssGrpName": "Motorsteuerung",
            "UsageName": null
        },
        "01125": {
            "GenartName": "Druckregler, Kraftstoffpumpe",
            "NormTermName": "Regler",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "01126": {
            "GenartName": "Halter, Kraftstoffpumpe",
            "NormTermName": "Halter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "01127": {
            "GenartName": "Montagesatz, Abgasanlage",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "01128": {
            "GenartName": "Sportschalldämpfer",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Sportschalldämpfer"
        },
        "01129": {
            "GenartName": "Ventil, Abgasrückführung",
            "NormTermName": "Ventil",
            "AssGrpName": "Abgasrückführung",
            "UsageName": null
        },
        "01131": {
            "GenartName": "Türsicherungsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Tür"
        },
        "01132": {
            "GenartName": "Motorraumleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Motorraum"
        },
        "01134": {
            "GenartName": "Druckwandler, Abgassteuerung",
            "NormTermName": "Druckwandler",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "Abgassteuerung"
        },
        "01136": {
            "GenartName": "Ventil, Sekundärluft-Saugsystem",
            "NormTermName": "Ventil",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": "Sekundärluft-Saugsystem"
        },
        "01137": {
            "GenartName": "Ventil, AGR-Abgassteuerung",
            "NormTermName": "Ventil",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "Abgassteuerung"
        },
        "01145": {
            "GenartName": "AGR-Ventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "Abgasrückführung"
        },
        "01146": {
            "GenartName": "SLS-Ventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": "Sekundärluftsystem"
        },
        "01147": {
            "GenartName": "Tripodestern, Antriebswelle",
            "NormTermName": "Tripodestern",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "01149": {
            "GenartName": "Zusatzluftschieber",
            "NormTermName": "Ventil",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Zusatzluft"
        },
        "01150": {
            "GenartName": "Tachowelle",
            "NormTermName": "Welle",
            "AssGrpName": "Instrumente",
            "UsageName": "Tachometer"
        },
        "01151": {
            "GenartName": "Vergaserdämpfer",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01152": {
            "GenartName": "Schließdämpfer, Vergaser",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Vergaser",
            "UsageName": "Drosselklappe"
        },
        "01153": {
            "GenartName": "Halter, Kraftstoffleitung",
            "NormTermName": "Halter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffleitung"
        },
        "01156": {
            "GenartName": "Luftfilter, Kompressor-Ansaugluft",
            "NormTermName": "Filter",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Kompressor-Ansaugluft"
        },
        "01158": {
            "GenartName": "Dichtungssatz, Bremsflüssigkeitsbehälter",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsflüssigkeitsbehälter"
        },
        "01159": {
            "GenartName": "Achsschenkel, Radaufhängung",
            "NormTermName": "Achsschenkel",
            "AssGrpName": "Radaufhängung",
            "UsageName": null
        },
        "01162": {
            "GenartName": "Zwischenblech, Bremssattelkolben",
            "NormTermName": "Blech",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattelkolben"
        },
        "01164": {
            "GenartName": "Zubehörsatz, Scheibenbremsbelag",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Scheibenbremsbelag"
        },
        "01165": {
            "GenartName": "Führungshülsensatz, Bremssattel",
            "NormTermName": "Hülsensatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "01166": {
            "GenartName": "Lagerung, Lenkgetriebe",
            "NormTermName": "Lagerung",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkgetriebe"
        },
        "01167": {
            "GenartName": "AKF-Ventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "AKF-Ventil"
        },
        "01168": {
            "GenartName": "Halter, Schlussleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Schlussleuchte"
        },
        "01169": {
            "GenartName": "Elektromotor, Rundumkennleuchte",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Signalanlage",
            "UsageName": "Rundumkennleuchte"
        },
        "01170": {
            "GenartName": "Reflektor, Rundumkennleuchte",
            "NormTermName": "Reflektor",
            "AssGrpName": "Signalanlage",
            "UsageName": "Rundumkennleuchte"
        },
        "01171": {
            "GenartName": "Halter, Rundumkennleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Signalanlage",
            "UsageName": "Rundumkennleuchte"
        },
        "01172": {
            "GenartName": "Glühlampe, Umriss-/Begrenzungsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Umriss-/Begrenzungsleuchte"
        },
        "01173": {
            "GenartName": "Parkleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Parkleuchte"
        },
        "01174": {
            "GenartName": "Lichtscheibe, Positionsleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Positionsleuchte"
        },
        "01175": {
            "GenartName": "Lichtscheibe, Begrenzungsleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Begrenzungsleuchte"
        },
        "01176": {
            "GenartName": "Elektromotor, Scheinwerferreinigung",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": null
        },
        "01177": {
            "GenartName": "Fahrtenbuch",
            "NormTermName": "Fahrtenbuch",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "01180": {
            "GenartName": "Federbeinstützlager",
            "NormTermName": "Lager",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Federbein"
        },
        "01182": {
            "GenartName": "Anschlagpuffer, Federung",
            "NormTermName": "Puffer",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Feder"
        },
        "01184": {
            "GenartName": "Federaufnahme",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Karosserie",
            "UsageName": "Federaufnahme"
        },
        "01185": {
            "GenartName": "Fahrwerkssatz, Stoßdämpfer",
            "NormTermName": "Dämpfersatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Stoßdämpfer"
        },
        "01186": {
            "GenartName": "Sensor, Außentemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Instrumente",
            "UsageName": "Außentemperatur"
        },
        "01187": {
            "GenartName": "Relais, Saugrohrvorwärmung",
            "NormTermName": "Relais",
            "AssGrpName": "Vergaser",
            "UsageName": "Saugrohrvorwärmung"
        },
        "01188": {
            "GenartName": "Stellelement, Geschwindigkeitsregelanlage",
            "NormTermName": "Stellelement",
            "AssGrpName": "Geschwindigkeitsregelanlage",
            "UsageName": null
        },
        "01189": {
            "GenartName": "Sensor, Geschwindigkeit",
            "NormTermName": "Sensor",
            "AssGrpName": "Instrumente",
            "UsageName": "Geschwindigkeit"
        },
        "01190": {
            "GenartName": "Sensor, Geschwindigkeit/Drehzahl",
            "NormTermName": "Sensor",
            "AssGrpName": "Instrumente",
            "UsageName": "Geschwindigkeit/Drehzahl"
        },
        "01191": {
            "GenartName": "Frontscheibe",
            "NormTermName": "Frontscheibe",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "01192": {
            "GenartName": "Heckscheibe",
            "NormTermName": "Heckscheibe",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "01193": {
            "GenartName": "Türscheibe",
            "NormTermName": "Türscheibe",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "01194": {
            "GenartName": "Seitenscheibe",
            "NormTermName": "Seitenscheibe",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "01195": {
            "GenartName": "Halter, Waschwasserleitung",
            "NormTermName": "Halter",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasserleitung"
        },
        "01196": {
            "GenartName": "Scheinwerferreinigungsanlage",
            "NormTermName": "Reinigungsanlage",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": null
        },
        "01197": {
            "GenartName": "Sensor, Wegstrecke",
            "NormTermName": "Sensor",
            "AssGrpName": null,
            "UsageName": "Wegstrecke"
        },
        "01198": {
            "GenartName": "Kennzeichenleuchte, Anhängevorrichtung",
            "NormTermName": "Leuchte",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Kennzeichenleuchte"
        },
        "01199": {
            "GenartName": "Stoßfängerhalter, Anhängevorrichtung",
            "NormTermName": "Halter",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Stoßfänger"
        },
        "01200": {
            "GenartName": "Dachträgerstange",
            "NormTermName": "Stange",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Dachträger"
        },
        "01201": {
            "GenartName": "Dachträgerfuß",
            "NormTermName": "Fuß",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Dachträger"
        },
        "01202": {
            "GenartName": "Stehbolzensatz, Dachträger",
            "NormTermName": "Bolzensatz",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Dachträger"
        },
        "01203": {
            "GenartName": "Adapter, Heckträger",
            "NormTermName": "Adapter",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Heckträger"
        },
        "01204": {
            "GenartName": "Halteband, Skihalter",
            "NormTermName": "Band",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Skihalter"
        },
        "01205": {
            "GenartName": "Radaufnahme, Fahrradhalter",
            "NormTermName": "Radaufnahme",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Fahrradhalter"
        },
        "01207": {
            "GenartName": "Gasfeder, Verdeck",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Verdeck"
        },
        "01208": {
            "GenartName": "Umrüstsatz, Klimaanlage",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": null
        },
        "01209": {
            "GenartName": "Einparkhilfe",
            "NormTermName": "Einparkhilfe",
            "AssGrpName": "Komfortsysteme",
            "UsageName": null
        },
        "01210": {
            "GenartName": "Positionserkennungssystem",
            "NormTermName": "Positionserkennungssystem",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": null
        },
        "01211": {
            "GenartName": "Lenksäule",
            "NormTermName": "Lenksäule",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "01212": {
            "GenartName": "Relais, Einparkhilfe",
            "NormTermName": "Relais",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Einparkhilfe"
        },
        "01213": {
            "GenartName": "Reparatursatz, Axialgelenk Spurstange",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Axialgelenk"
        },
        "01214": {
            "GenartName": "Regelventil, Kraftstoffdruck",
            "NormTermName": "Ventil",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffdruck"
        },
        "01216": {
            "GenartName": "Ventilstößel",
            "NormTermName": "Ventilstößel",
            "AssGrpName": "Motorsteuerung",
            "UsageName": null
        },
        "01217": {
            "GenartName": "Zylinderkopfschraubensatz",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Zylinderkopf",
            "UsageName": null
        },
        "01218": {
            "GenartName": "Schaltgerät, Zündanlage",
            "NormTermName": "Schaltgerät",
            "AssGrpName": "Zündanlage",
            "UsageName": null
        },
        "01219": {
            "GenartName": "Schaltgerät, Bremsanlage",
            "NormTermName": "Schaltgerät",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "01220": {
            "GenartName": "Mutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "01221": {
            "GenartName": "Federscheibe",
            "NormTermName": "Federscheibe",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "01222": {
            "GenartName": "Unterlegscheibe",
            "NormTermName": "Unterlegscheibe",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "01224": {
            "GenartName": "Druckspeicher, Fahrdynamikregelung",
            "NormTermName": "Druckspeicher",
            "AssGrpName": "Fahrdynamikregelung",
            "UsageName": null
        },
        "01226": {
            "GenartName": "Klemmhülse, Raddrehzahlsensor",
            "NormTermName": "Hülse",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Drehzahlsensor"
        },
        "01231": {
            "GenartName": "Sensor, Druckluftanlage",
            "NormTermName": "Sensor",
            "AssGrpName": "Druckluftanlage",
            "UsageName": null
        },
        "01232": {
            "GenartName": "Überspannungsschutzgerät, Generator",
            "NormTermName": "Überspannungsschutzgerät",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "01235": {
            "GenartName": "Montagesatz, Zündschaltgerät",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündschaltgerät"
        },
        "01236": {
            "GenartName": "Magnetkupplung, Klimakompressor",
            "NormTermName": "Kupplung",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kompressor"
        },
        "01237": {
            "GenartName": "Resonator, Abgasanlage",
            "NormTermName": "Resonator",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "01238": {
            "GenartName": "Motorhaubenzug",
            "NormTermName": "Seilzug",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorhaube"
        },
        "01239": {
            "GenartName": "Heizklappenzug",
            "NormTermName": "Seilzug",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizklappen"
        },
        "01240": {
            "GenartName": "Starterzug",
            "NormTermName": "Seilzug",
            "AssGrpName": "Startanlage",
            "UsageName": "Starter"
        },
        "01244": {
            "GenartName": "Relais, Startwiederholung",
            "NormTermName": "Relais",
            "AssGrpName": "Startanlage",
            "UsageName": "Startwiederholung"
        },
        "01245": {
            "GenartName": "Dichtung, Frontscheibe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Karosserie",
            "UsageName": "Frontscheibe"
        },
        "01246": {
            "GenartName": "Dichtung, Heckscheibe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Karosserie",
            "UsageName": "Heckscheibe"
        },
        "01247": {
            "GenartName": "Dichtung, Türscheibe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Karosserie",
            "UsageName": "Türscheibe"
        },
        "01248": {
            "GenartName": "Dichtung, Seitenscheibe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Karosserie",
            "UsageName": "Seitenscheibe"
        },
        "01249": {
            "GenartName": "Zier-/Schutzleiste, Frontscheibe",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie",
            "UsageName": "Frontscheibe"
        },
        "01250": {
            "GenartName": "Zier-/Schutzleiste, Heckscheibe",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie",
            "UsageName": "Heckscheibe"
        },
        "01251": {
            "GenartName": "Zier-/Schutzleiste, Türscheibe",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie",
            "UsageName": "Türscheibe"
        },
        "01252": {
            "GenartName": "Zier-/Schutzleiste, Seitenscheibe",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie",
            "UsageName": "Seitenscheibe"
        },
        "01253": {
            "GenartName": "Halteclip, Scheibeneinbau",
            "NormTermName": "Clip",
            "AssGrpName": "Karosserie",
            "UsageName": "Scheibeneinbau"
        },
        "01254": {
            "GenartName": "Lüfter, Klimakondensator",
            "NormTermName": "Lüfter",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kühler"
        },
        "01255": {
            "GenartName": "Reparatursatz, Bremsflüssigkeitsbehälter",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsflüssigkeitsbehälter"
        },
        "01256": {
            "GenartName": "Ruß-/Partikelfilter, Abgasanlage",
            "NormTermName": "Filter",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Ruß"
        },
        "01259": {
            "GenartName": "Tür, Fahrerhaus",
            "NormTermName": "Tür",
            "AssGrpName": "Fahrerhaus",
            "UsageName": null
        },
        "01260": {
            "GenartName": "Wasserpumpe, Motorkühlung",
            "NormTermName": "Pumpe",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlwasser"
        },
        "01261": {
            "GenartName": "Relais, Scheinwerferreinigung",
            "NormTermName": "Relais",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": null
        },
        "01263": {
            "GenartName": "Zylinderkopf",
            "NormTermName": "Zylinderkopf",
            "AssGrpName": "Zylinderkopf",
            "UsageName": null
        },
        "01264": {
            "GenartName": "Komplettmotor",
            "NormTermName": "Komplettmotor",
            "AssGrpName": "Tauschmotor",
            "UsageName": null
        },
        "01265": {
            "GenartName": "Teilmotor",
            "NormTermName": "Teilmotor",
            "AssGrpName": "Tauschmotor",
            "UsageName": null
        },
        "01266": {
            "GenartName": "Kältemittelfilter, Klimaanlage",
            "NormTermName": "Filter",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kältemittel"
        },
        "01267": {
            "GenartName": "Relais, Zentralverriegelung",
            "NormTermName": "Relais",
            "AssGrpName": "Schließanlage",
            "UsageName": "Zentralverriegelung"
        },
        "01268": {
            "GenartName": "Spoiler, Kühlergitter",
            "NormTermName": "Spoiler",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühlergitter"
        },
        "01269": {
            "GenartName": "Einlassventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Einlass"
        },
        "01270": {
            "GenartName": "Auslassventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Auslass"
        },
        "01271": {
            "GenartName": "Ventilkeil",
            "NormTermName": "Ventilkeil",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ventil"
        },
        "01272": {
            "GenartName": "Ventilteller",
            "NormTermName": "Ventilteller",
            "AssGrpName": "Motorsteuerung",
            "UsageName": null
        },
        "01273": {
            "GenartName": "Ventilfeder",
            "NormTermName": "Feder",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ventil"
        },
        "01274": {
            "GenartName": "Ventilsitzring",
            "NormTermName": "Sitzring",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Ventil"
        },
        "01275": {
            "GenartName": "Kipphebellager",
            "NormTermName": "Lager",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Kipphebel"
        },
        "01276": {
            "GenartName": "Nockenwellenlager",
            "NormTermName": "Lager",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwelle"
        },
        "01278": {
            "GenartName": "Steuergerät, Automatikgetriebe",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "01279": {
            "GenartName": "Waschwasserleitung, Scheinwerferreinigung",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": "Waschwasser"
        },
        "01280": {
            "GenartName": "Waschwasserdüse, Scheinwerferreinigung",
            "NormTermName": "Düse",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": "Waschwasser"
        },
        "01281": {
            "GenartName": "Reparatursatz, Achskörper",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achskörper"
        },
        "01284": {
            "GenartName": "Aktivkohlefilter, Tankentlüftung",
            "NormTermName": "Filter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Tankentlüftung"
        },
        "01285": {
            "GenartName": "Relais, Gurt-Warnung",
            "NormTermName": "Relais",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Gurt-Warnung"
        },
        "01286": {
            "GenartName": "Relais, Nebelschlussleuchte",
            "NormTermName": "Relais",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelschlussleuchte"
        },
        "01287": {
            "GenartName": "Relais, Fernscheinwerfer",
            "NormTermName": "Relais",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "01288": {
            "GenartName": "Sensor, Kühlmittelstand",
            "NormTermName": "Sensor",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelstand"
        },
        "01289": {
            "GenartName": "Sensor, Motorölstand",
            "NormTermName": "Sensor",
            "AssGrpName": "Schmierung",
            "UsageName": "Motorölstand"
        },
        "01290": {
            "GenartName": "Relais, Heckscheibenheizung",
            "NormTermName": "Relais",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Heckscheibenheizung"
        },
        "01291": {
            "GenartName": "Relais, Kühlerlüfternachlauf",
            "NormTermName": "Relais",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlerlüfternachlauf"
        },
        "01292": {
            "GenartName": "Relais, Fensterheber",
            "NormTermName": "Relais",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Fensterheber"
        },
        "01293": {
            "GenartName": "Verzögerungsrelais, Innenraumbeleuchtung",
            "NormTermName": "Relais",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Innenraumleuchte"
        },
        "01294": {
            "GenartName": "Relais, Nebelscheinwerfer",
            "NormTermName": "Relais",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "01295": {
            "GenartName": "Sensor, Motorraumtemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Kühlung",
            "UsageName": "Motorraumtemperatur"
        },
        "01298": {
            "GenartName": "Leerlaufregelventil, Luftversorgung",
            "NormTermName": "Ventil",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Leerlauf"
        },
        "01299": {
            "GenartName": "Außenspiegel, Fahrerhaus",
            "NormTermName": "Spiegel",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Außenspiegel"
        },
        "01300": {
            "GenartName": "Federteller",
            "NormTermName": "Federteller",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "01301": {
            "GenartName": "Waschwasserbehälter, Scheinwerferreinigung",
            "NormTermName": "Behälter",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": "Waschwasser"
        },
        "01303": {
            "GenartName": "Druckspeicher, Kraftstoffdruck",
            "NormTermName": "Druckspeicher",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffdruck"
        },
        "01304": {
            "GenartName": "Druckhalteventil, Kupplungsnehmerzylinder",
            "NormTermName": "Ventil",
            "AssGrpName": "Kupplung",
            "UsageName": "Nehmerzylinder"
        },
        "01305": {
            "GenartName": "Ventil, Luftsteuerung-Ansaugluft",
            "NormTermName": "Ventil",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Luftsteuerung"
        },
        "01306": {
            "GenartName": "Ventil, Vergaser",
            "NormTermName": "Ventil",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01307": {
            "GenartName": "Wischergestänge, Scheinwerferreinigung",
            "NormTermName": "Gestänge",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": "Wischer"
        },
        "01308": {
            "GenartName": "Sekundärluftpumpenmodul",
            "NormTermName": "Pumpenmodul",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": "Sekundärluft"
        },
        "01309": {
            "GenartName": "Flansch, Kraftstoffördereinheit",
            "NormTermName": "Flansch",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstofffördereinheit"
        },
        "01312": {
            "GenartName": "Druckbegrenzungsventil, Luftfederung",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederungsdruckbegrenzung"
        },
        "01313": {
            "GenartName": "Schnellöseventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Schnellösung"
        },
        "01314": {
            "GenartName": "Drucksteuerventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Drucksteuerung"
        },
        "01315": {
            "GenartName": "Druckknopfventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckknopf"
        },
        "01316": {
            "GenartName": "Last-/Leerventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Last/Leer"
        },
        "01317": {
            "GenartName": "Drosselventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Drossel"
        },
        "01318": {
            "GenartName": "Entwässerungsventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Entwässerung"
        },
        "01319": {
            "GenartName": "Mehrwegeventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Mehrwege"
        },
        "01320": {
            "GenartName": "Entlüftungsventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Entlüftung"
        },
        "01321": {
            "GenartName": "Druckbegrenzungsventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckbegrenzung"
        },
        "01322": {
            "GenartName": "Drucksicherungsventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Drucksicherung"
        },
        "01323": {
            "GenartName": "Magnetventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Magnet"
        },
        "01324": {
            "GenartName": "Rückschlagventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Rückschlag"
        },
        "01325": {
            "GenartName": "Überströmventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Überströmen"
        },
        "01326": {
            "GenartName": "Drehknopfventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Drehknopf"
        },
        "01327": {
            "GenartName": "Doppelabsperrventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Doppelabsperrung"
        },
        "01328": {
            "GenartName": "Anpassungsventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Anpassung"
        },
        "01329": {
            "GenartName": "Relaisventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Relais"
        },
        "01330": {
            "GenartName": "Spritzblech, Bremsscheibe",
            "NormTermName": "Blech",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsscheibe"
        },
        "01332": {
            "GenartName": "Wegfahrsperre",
            "NormTermName": "Wegfahrsperre",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": null
        },
        "01333": {
            "GenartName": "Absperrventil, Wegfahrsperre",
            "NormTermName": "Ventil",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Wegfahrsperre"
        },
        "01334": {
            "GenartName": "Lagerung, Stabilisator",
            "NormTermName": "Lagerung",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisatorlager"
        },
        "01335": {
            "GenartName": "Zier-/Schutzleistensatz",
            "NormTermName": "Zier-/Schutzleistensatz",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "01336": {
            "GenartName": "Bremsbelagsatz, Scheibenfeststellbremse",
            "NormTermName": "Bremsbelagsatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremse"
        },
        "01337": {
            "GenartName": "Zubehörsatz, Feststellbremsbacken",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremsbacken"
        },
        "01338": {
            "GenartName": "Steckertülle",
            "NormTermName": "Tülle",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "01340": {
            "GenartName": "Leitungsfilter, Druckluftanlage",
            "NormTermName": "Filter",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Leitung"
        },
        "01341": {
            "GenartName": "Regler, ALB",
            "NormTermName": "Regler",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "ALB"
        },
        "01342": {
            "GenartName": "Relais, ALB",
            "NormTermName": "Relais",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "ALB"
        },
        "01343": {
            "GenartName": "Armlehne",
            "NormTermName": "Lehne",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Arm"
        },
        "01344": {
            "GenartName": "Einstiegsleiste",
            "NormTermName": "Einstiegsleiste",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "01345": {
            "GenartName": "Schwellerverkleidung",
            "NormTermName": "Verkleidung",
            "AssGrpName": "Karosserie",
            "UsageName": "Schwellerkasten"
        },
        "01346": {
            "GenartName": "Abdeckung, Griffmulde",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Karosserie",
            "UsageName": "Griffmulde"
        },
        "01347": {
            "GenartName": "Laderaumabdeckung",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Koffer-/Laderaum"
        },
        "01348": {
            "GenartName": "Surfbretthalter, Heckträger",
            "NormTermName": "Surfbretthalter",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Heckträger"
        },
        "01349": {
            "GenartName": "Dichtungssatz, Abgasanlage",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "01350": {
            "GenartName": "Umrissleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Umrissleuchte"
        },
        "01351": {
            "GenartName": "Zusatzwasserpumpe (Kühlwasserkreislauf)",
            "NormTermName": "Pumpe",
            "AssGrpName": "Kühlung",
            "UsageName": "Zusatzpumpe"
        },
        "01353": {
            "GenartName": "Motorraumdämmung",
            "NormTermName": "Schalldämmung",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorraum"
        },
        "01354": {
            "GenartName": "Adaptersatz, Fahrtenbuch",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Fahrtenbuch"
        },
        "01355": {
            "GenartName": "Schlauchverbinder",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsschlauch"
        },
        "01356": {
            "GenartName": "Antennenkopf",
            "NormTermName": "Antennenkopf",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": null
        },
        "01357": {
            "GenartName": "Antennenteleskop",
            "NormTermName": "Antennenteleskop",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": null
        },
        "01358": {
            "GenartName": "Antennenkabel",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Antenne"
        },
        "01359": {
            "GenartName": "Antennenverstärker",
            "NormTermName": "Verstärker",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Antenne"
        },
        "01360": {
            "GenartName": "Druckschalter, Klimaanlage",
            "NormTermName": "Schalter",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Druckschalter"
        },
        "01361": {
            "GenartName": "Türschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Schließanlage",
            "UsageName": "Tür"
        },
        "01362": {
            "GenartName": "Heckklappenschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Schließanlage",
            "UsageName": "Heckklappe"
        },
        "01363": {
            "GenartName": "Kofferraumdeckelschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Schließanlage",
            "UsageName": "Kofferraumdeckel"
        },
        "01364": {
            "GenartName": "Schlossanschlag, Kofferraumdeckel",
            "NormTermName": "Anschlag",
            "AssGrpName": "Schließanlage",
            "UsageName": "Kofferraumdeckelschloss"
        },
        "01365": {
            "GenartName": "Motorhaubenschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Schließanlage",
            "UsageName": "Motorhaube"
        },
        "01366": {
            "GenartName": "Tankdeckelschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Schließanlage",
            "UsageName": "Tankdeckel"
        },
        "01367": {
            "GenartName": "Lenkschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Schließanlage",
            "UsageName": "Lenksäule"
        },
        "01368": {
            "GenartName": "Handschuhfachschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Schließanlage",
            "UsageName": "Handschuhfach"
        },
        "01369": {
            "GenartName": "Schlosskörper, Lenkschloss",
            "NormTermName": "Schlosskörper",
            "AssGrpName": "Schließanlage",
            "UsageName": "Lenksäule"
        },
        "01370": {
            "GenartName": "Airbagschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Schließanlage",
            "UsageName": "Airbag"
        },
        "01371": {
            "GenartName": "Schloss, Alarmanlage",
            "NormTermName": "Schloss",
            "AssGrpName": "Schließanlage",
            "UsageName": "Alarmanlage"
        },
        "01372": {
            "GenartName": "Schlosssatz, Schließanlage",
            "NormTermName": "Schlosssatz",
            "AssGrpName": "Schließanlage",
            "UsageName": null
        },
        "01373": {
            "GenartName": "Türaußengriff",
            "NormTermName": "Griff",
            "AssGrpName": "Schließanlage",
            "UsageName": "Tür"
        },
        "01374": {
            "GenartName": "Heckklappengriff",
            "NormTermName": "Griff",
            "AssGrpName": "Schließanlage",
            "UsageName": "Heckklappe"
        },
        "01375": {
            "GenartName": "Kofferraumdeckelgriff",
            "NormTermName": "Griff",
            "AssGrpName": "Schließanlage",
            "UsageName": "Kofferraumdeckel"
        },
        "01376": {
            "GenartName": "Türgriffbetätigung",
            "NormTermName": "Griffbetätigung",
            "AssGrpName": "Schließanlage",
            "UsageName": "Tür"
        },
        "01377": {
            "GenartName": "Rahmen, Türaußengriff",
            "NormTermName": "Griffrahmen",
            "AssGrpName": "Schließanlage",
            "UsageName": "Tür"
        },
        "01378": {
            "GenartName": "Schließzylinder",
            "NormTermName": "Schließzylinder",
            "AssGrpName": "Schließanlage",
            "UsageName": null
        },
        "01379": {
            "GenartName": "Schließzylindersatz",
            "NormTermName": "Schließzylindersatz",
            "AssGrpName": "Schließanlage",
            "UsageName": null
        },
        "01380": {
            "GenartName": "Schließzylindergehäuse",
            "NormTermName": "Schließzylindergehäuse",
            "AssGrpName": "Schließanlage",
            "UsageName": null
        },
        "01381": {
            "GenartName": "Schraube, Spureinstellung",
            "NormTermName": "Schraube",
            "AssGrpName": "Lenkung",
            "UsageName": "Spureinstellung"
        },
        "01382": {
            "GenartName": "Kraftstoff-Fördereinheit",
            "NormTermName": "Fördereinheit",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": null
        },
        "01383": {
            "GenartName": "Niveauschalter, Motorölstand",
            "NormTermName": "Schalter",
            "AssGrpName": "Schmierung",
            "UsageName": "Motorölstand"
        },
        "01384": {
            "GenartName": "Steuergerät, Airbag",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Airbag"
        },
        "01385": {
            "GenartName": "Steuergerät, Heizung/Lüftung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": null
        },
        "01386": {
            "GenartName": "Steuergerät, Sitzverstellung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sitzverstellung"
        },
        "01387": {
            "GenartName": "Steuergerät, Beleuchtung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Beleuchtung",
            "UsageName": null
        },
        "01388": {
            "GenartName": "Kühlmittelfilter",
            "NormTermName": "Filter",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittel"
        },
        "01389": {
            "GenartName": "Steuerkettensatz",
            "NormTermName": "Steuerkettensatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": null
        },
        "01390": {
            "GenartName": "Generatorfreilauf",
            "NormTermName": "Freilauf",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "01391": {
            "GenartName": "Adapter, Lambdasonde",
            "NormTermName": "Adapter",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Lambdasonde"
        },
        "01392": {
            "GenartName": "Hitzeschutzblech",
            "NormTermName": "Hitzeschutzblech",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Hitzeschutz"
        },
        "01393": {
            "GenartName": "Klemmstücksatz, Abgasanlage",
            "NormTermName": "Klemmstücksatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasrohr"
        },
        "01395": {
            "GenartName": "Montagesatz, Abgasrohr",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasrohr"
        },
        "01396": {
            "GenartName": "Verschlussschraube, Lambdasondenaufnahme",
            "NormTermName": "Schraube",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Lambdasondenaufnahme"
        },
        "01397": {
            "GenartName": "Steuergerät, Zentralverriegelung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Schließanlage",
            "UsageName": "Zentralverriegelung"
        },
        "01398": {
            "GenartName": "Adaptersatz, Geschwindigkeitsregelanlage",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Geschwindigkeitsregelanlage",
            "UsageName": null
        },
        "01399": {
            "GenartName": "Frequenzmodul, Geschwindigkeitsregelanlage",
            "NormTermName": "Frequenzmodul",
            "AssGrpName": "Geschwindigkeitsregelanlage",
            "UsageName": null
        },
        "01400": {
            "GenartName": "Betriebsstundenzähler",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Betriebsstundenzähler"
        },
        "01401": {
            "GenartName": "Traktormeter",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Traktormeter"
        },
        "01402": {
            "GenartName": "Sensor, Drehzahl",
            "NormTermName": "Sensor",
            "AssGrpName": "Instrumente",
            "UsageName": "Drehzahl"
        },
        "01403": {
            "GenartName": "Vorwiderstand, Kombi-Instrument",
            "NormTermName": "Widerstand",
            "AssGrpName": "Instrumente",
            "UsageName": "Kombi-Instrument"
        },
        "01404": {
            "GenartName": "Software, Fahrtenbuch",
            "NormTermName": "Software",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Fahrtenbuch"
        },
        "01405": {
            "GenartName": "Neigungssensor, Alarmanlage",
            "NormTermName": "Sensor",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Neigungssensor"
        },
        "01406": {
            "GenartName": "Steuerventil, Zentralverriegelung",
            "NormTermName": "Ventil",
            "AssGrpName": "Schließanlage",
            "UsageName": "Steuerung Zentralverriegelung"
        },
        "01407": {
            "GenartName": "Schlingertopf-Dämpfung",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Schlingertopf"
        },
        "01408": {
            "GenartName": "Halter, Schlingertopf-Dämpfung",
            "NormTermName": "Halter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Schlingertopf-Dämpfung"
        },
        "01409": {
            "GenartName": "Niveauschalter, Kraftstoff",
            "NormTermName": "Schalter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffniveau"
        },
        "01410": {
            "GenartName": "Schwingelement, Kraftstoffpumpe",
            "NormTermName": "Schwingelement",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "01411": {
            "GenartName": "Be-/Entlüftungsventil, Kraftstoffbehälter",
            "NormTermName": "Ventil",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Be-/Entlüftung"
        },
        "01412": {
            "GenartName": "Niveauschalter, Kühlmittelmangelanzeige",
            "NormTermName": "Schalter",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelmangelanzeige"
        },
        "01413": {
            "GenartName": "Steuergerät, Kühlmittelmangelanzeige",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelmangelanzeige"
        },
        "01414": {
            "GenartName": "Düsennadel, Scheibenreinigung",
            "NormTermName": "Düsennadel",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": null
        },
        "01415": {
            "GenartName": "Saugrohrmodul",
            "NormTermName": "Saugrohrmodul",
            "AssGrpName": "Luftversorgung",
            "UsageName": null
        },
        "01416": {
            "GenartName": "Unterdruckpumpe, Klimaanlage",
            "NormTermName": "Pumpe",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Unterdruck"
        },
        "01417": {
            "GenartName": "Unterdruckpumpe, Zentralverriegelung",
            "NormTermName": "Pumpe",
            "AssGrpName": "Schließanlage",
            "UsageName": "Zentralverriegelung"
        },
        "01418": {
            "GenartName": "Abgasklappe",
            "NormTermName": "Abgasklappe",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "01419": {
            "GenartName": "Bremsbackensatz, Feststellbremse",
            "NormTermName": "Bremsbackensatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremse"
        },
        "01420": {
            "GenartName": "Lagerung, Gelenkwelle",
            "NormTermName": "Lagerung",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwelle"
        },
        "01421": {
            "GenartName": "Elektrosatz, Checkcontrol-Erweiterung",
            "NormTermName": "Elektrosatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Checkcontrol"
        },
        "01422": {
            "GenartName": "Verstärkungssatz, Karosserie",
            "NormTermName": "Verstärkungssatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Karosserieboden"
        },
        "01423": {
            "GenartName": "Montagesatz, Wechselsystem",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Wechselsystem"
        },
        "01424": {
            "GenartName": "Montagesatz, Maulkupplung",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Maulkupplung"
        },
        "01425": {
            "GenartName": "Wechselsystem, Anhängebock",
            "NormTermName": "Wechselsystem",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Anhängebock"
        },
        "01426": {
            "GenartName": "Halterung, Maul-Kugelkupplung",
            "NormTermName": "Halterung",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Maul-Kugelkupplung"
        },
        "01427": {
            "GenartName": "Gelenk, Längswelle",
            "NormTermName": "Gelenk",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Längswelle"
        },
        "01428": {
            "GenartName": "Zusatzbremsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Signalanlage",
            "UsageName": "Zusatzbremsleuchte"
        },
        "01429": {
            "GenartName": "Steuergerät, Geschwindigkeitsregelanlage",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Geschwindigkeitsregelanlage",
            "UsageName": null
        },
        "01430": {
            "GenartName": "Starthilfekabel",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Starthilfe"
        },
        "01431": {
            "GenartName": "Vorschaltgerät, Gasentladungslampe",
            "NormTermName": "Schaltgerät",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Gasentladungslampe"
        },
        "01432": {
            "GenartName": "Steuergerät, Leuchtweitenregulierung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Leuchtweitenregulierung"
        },
        "01433": {
            "GenartName": "Lichtwarner",
            "NormTermName": "Lichtwarner",
            "AssGrpName": "Beleuchtung",
            "UsageName": null
        },
        "01434": {
            "GenartName": "Prüfgerät, Scheinwerfereinstellung",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Scheinwerfereinstellung"
        },
        "01435": {
            "GenartName": "Klemme, elektrische Universalteile",
            "NormTermName": "Klemme",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "01436": {
            "GenartName": "Zierblende, Räder",
            "NormTermName": "Blende",
            "AssGrpName": "Räder",
            "UsageName": "Radabdeckung"
        },
        "01437": {
            "GenartName": "Widerstand",
            "NormTermName": "Widerstand",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "01438": {
            "GenartName": "Schraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "01439": {
            "GenartName": "Steuergerät, Standheizung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Standheizung"
        },
        "01440": {
            "GenartName": "Steuergerät, Gurtgeber",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Gurtgeber"
        },
        "01441": {
            "GenartName": "Steuergerät, Klimaanlage",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Klimaanlage",
            "UsageName": null
        },
        "01442": {
            "GenartName": "Kältemittel, Klimaanlage",
            "NormTermName": "Kältemittel",
            "AssGrpName": "Klimaanlage",
            "UsageName": null
        },
        "01443": {
            "GenartName": "Zentralelektrik",
            "NormTermName": "Zentralelektrik",
            "AssGrpName": "Bordnetz",
            "UsageName": null
        },
        "01444": {
            "GenartName": "Kühlbox",
            "NormTermName": "Kühlbox",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "01445": {
            "GenartName": "Leitungssatz",
            "NormTermName": "Elektroleitungssatz",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "01446": {
            "GenartName": "Zigarettenanzünder",
            "NormTermName": "Zigarettenanzünder",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "01447": {
            "GenartName": "Abdeckung",
            "NormTermName": "Abdeckung",
            "AssGrpName": null,
            "UsageName": null
        },
        "01448": {
            "GenartName": "Anker",
            "NormTermName": "Anker",
            "AssGrpName": null,
            "UsageName": null
        },
        "01449": {
            "GenartName": "Aufnahme",
            "NormTermName": "Aufnahme",
            "AssGrpName": null,
            "UsageName": null
        },
        "01450": {
            "GenartName": "Behälter",
            "NormTermName": "Behälter",
            "AssGrpName": null,
            "UsageName": null
        },
        "01451": {
            "GenartName": "Dichtung",
            "NormTermName": "Dichtung",
            "AssGrpName": null,
            "UsageName": null
        },
        "01452": {
            "GenartName": "Düse",
            "NormTermName": "Düse",
            "AssGrpName": null,
            "UsageName": null
        },
        "01453": {
            "GenartName": "Feder",
            "NormTermName": "Feder",
            "AssGrpName": null,
            "UsageName": null
        },
        "01454": {
            "GenartName": "Fernbedienung",
            "NormTermName": "Fernbedienung",
            "AssGrpName": null,
            "UsageName": null
        },
        "01455": {
            "GenartName": "Gehäuse",
            "NormTermName": "Gehäuse",
            "AssGrpName": null,
            "UsageName": null
        },
        "01456": {
            "GenartName": "Gitter",
            "NormTermName": "Gitter",
            "AssGrpName": null,
            "UsageName": null
        },
        "01457": {
            "GenartName": "Glühlampe",
            "NormTermName": "Glühlampe",
            "AssGrpName": null,
            "UsageName": null
        },
        "01458": {
            "GenartName": "Griff",
            "NormTermName": "Griff",
            "AssGrpName": null,
            "UsageName": null
        },
        "01459": {
            "GenartName": "Halter",
            "NormTermName": "Halter",
            "AssGrpName": null,
            "UsageName": null
        },
        "01460": {
            "GenartName": "Hülse",
            "NormTermName": "Hülse",
            "AssGrpName": null,
            "UsageName": null
        },
        "01461": {
            "GenartName": "Isolierband",
            "NormTermName": "Isolierband",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "01462": {
            "GenartName": "Kappe",
            "NormTermName": "Kappe",
            "AssGrpName": null,
            "UsageName": null
        },
        "01463": {
            "GenartName": "Knopf",
            "NormTermName": "Knopf",
            "AssGrpName": null,
            "UsageName": null
        },
        "01464": {
            "GenartName": "Kontrollgerät",
            "NormTermName": "Kontrollgerät",
            "AssGrpName": null,
            "UsageName": null
        },
        "01465": {
            "GenartName": "Lampenfassung",
            "NormTermName": "Lampenfassung",
            "AssGrpName": null,
            "UsageName": null
        },
        "01466": {
            "GenartName": "Lampenträger",
            "NormTermName": "Lampenträger",
            "AssGrpName": null,
            "UsageName": null
        },
        "01467": {
            "GenartName": "Leuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": null,
            "UsageName": null
        },
        "01468": {
            "GenartName": "Lichtleiter",
            "NormTermName": "Lichtleiter",
            "AssGrpName": null,
            "UsageName": null
        },
        "01469": {
            "GenartName": "Lichtscheibe",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": null,
            "UsageName": null
        },
        "01470": {
            "GenartName": "Linse",
            "NormTermName": "Linse",
            "AssGrpName": null,
            "UsageName": null
        },
        "01471": {
            "GenartName": "Messgerät",
            "NormTermName": "Messgerät",
            "AssGrpName": null,
            "UsageName": null
        },
        "01472": {
            "GenartName": "Mikrofon",
            "NormTermName": "Mikrofon",
            "AssGrpName": null,
            "UsageName": null
        },
        "01473": {
            "GenartName": "Mutter",
            "NormTermName": "Mutter",
            "AssGrpName": null,
            "UsageName": null
        },
        "01474": {
            "GenartName": "Niet",
            "NormTermName": "Niet",
            "AssGrpName": null,
            "UsageName": null
        },
        "01475": {
            "GenartName": "Prüfgerät",
            "NormTermName": "Prüfgerät",
            "AssGrpName": null,
            "UsageName": null
        },
        "01476": {
            "GenartName": "Pumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": null,
            "UsageName": null
        },
        "01477": {
            "GenartName": "Rahmen",
            "NormTermName": "Rahmen",
            "AssGrpName": null,
            "UsageName": null
        },
        "01478": {
            "GenartName": "Reflektor",
            "NormTermName": "Reflektor",
            "AssGrpName": null,
            "UsageName": null
        },
        "01479": {
            "GenartName": "Schalter",
            "NormTermName": "Schalter",
            "AssGrpName": null,
            "UsageName": null
        },
        "01480": {
            "GenartName": "Schild",
            "NormTermName": "Schild",
            "AssGrpName": null,
            "UsageName": null
        },
        "01481": {
            "GenartName": "Schlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": null,
            "UsageName": null
        },
        "01483": {
            "GenartName": "Schlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": null,
            "UsageName": null
        },
        "01484": {
            "GenartName": "Schraube",
            "NormTermName": "Schraube",
            "AssGrpName": null,
            "UsageName": null
        },
        "01485": {
            "GenartName": "Sensor",
            "NormTermName": "Sensor",
            "AssGrpName": null,
            "UsageName": null
        },
        "01486": {
            "GenartName": "Sicherungselement",
            "NormTermName": "Sicherungselement",
            "AssGrpName": null,
            "UsageName": null
        },
        "01487": {
            "GenartName": "Spannband",
            "NormTermName": "Spannband",
            "AssGrpName": null,
            "UsageName": null
        },
        "01488": {
            "GenartName": "Spiegelglas",
            "NormTermName": "Spiegelglas",
            "AssGrpName": null,
            "UsageName": null
        },
        "01489": {
            "GenartName": "Spule",
            "NormTermName": "Spule",
            "AssGrpName": null,
            "UsageName": null
        },
        "01490": {
            "GenartName": "Stativ",
            "NormTermName": "Stativ",
            "AssGrpName": null,
            "UsageName": null
        },
        "01491": {
            "GenartName": "Steuergerät",
            "NormTermName": "Steuergerät",
            "AssGrpName": null,
            "UsageName": null
        },
        "01492": {
            "GenartName": "Streuscheibe",
            "NormTermName": "Streuscheibe",
            "AssGrpName": null,
            "UsageName": null
        },
        "01493": {
            "GenartName": "Verbindungsstück",
            "NormTermName": "Verbindungselement",
            "AssGrpName": null,
            "UsageName": "Verbindungsstück"
        },
        "01494": {
            "GenartName": "Taste",
            "NormTermName": "Taste",
            "AssGrpName": null,
            "UsageName": null
        },
        "01495": {
            "GenartName": "Trichter",
            "NormTermName": "Trichter",
            "AssGrpName": null,
            "UsageName": null
        },
        "01496": {
            "GenartName": "Tülle",
            "NormTermName": "Tülle",
            "AssGrpName": null,
            "UsageName": null
        },
        "01497": {
            "GenartName": "Überwurfmutter",
            "NormTermName": "Überwurfmutter",
            "AssGrpName": null,
            "UsageName": null
        },
        "01498": {
            "GenartName": "Ventil",
            "NormTermName": "Ventil",
            "AssGrpName": null,
            "UsageName": null
        },
        "01499": {
            "GenartName": "Verschluss",
            "NormTermName": "Verschluss",
            "AssGrpName": null,
            "UsageName": null
        },
        "01500": {
            "GenartName": "Visier",
            "NormTermName": "Visier",
            "AssGrpName": null,
            "UsageName": null
        },
        "01501": {
            "GenartName": "Werkzeug",
            "NormTermName": "Werkzeug",
            "AssGrpName": null,
            "UsageName": null
        },
        "01502": {
            "GenartName": "Zubehörsatz, Bremsbacken",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbacken"
        },
        "01503": {
            "GenartName": "Schlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "01504": {
            "GenartName": "Klemmschelle",
            "NormTermName": "Schelle",
            "AssGrpName": "Normteile",
            "UsageName": "Klemmschelle"
        },
        "01505": {
            "GenartName": "Schlauchverbinder",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Normteile",
            "UsageName": "Schlauch"
        },
        "01506": {
            "GenartName": "Koffer-/Laderaumdeckel",
            "NormTermName": "Deckel",
            "AssGrpName": "Karosserie",
            "UsageName": "Koffer-/Laderaumdeckel"
        },
        "01507": {
            "GenartName": "Federbeinaufnahme",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Karosserie",
            "UsageName": "Federbeinaufnahme"
        },
        "01508": {
            "GenartName": "Blende, Kühlergitter",
            "NormTermName": "Blende",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühlergitter"
        },
        "01509": {
            "GenartName": "Haltersatz, Stoßfänger",
            "NormTermName": "Haltersatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Stoßfänger"
        },
        "01510": {
            "GenartName": "Trittbrett",
            "NormTermName": "Trittbrett",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "01511": {
            "GenartName": "Abdeckung, Scheinwerfer",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Karosserie",
            "UsageName": "Scheinwerfer"
        },
        "01512": {
            "GenartName": "Zier-/Schutzleiste, Kühlergitter",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühlergitter"
        },
        "01513": {
            "GenartName": "Sensorsatz, Xenonlichtumrüstung (Leuchtweitenregulierung)",
            "NormTermName": "Sensorsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Xenonlichtumrüstung (Leuchtweitenregulierung)"
        },
        "01514": {
            "GenartName": "Blende, Heizungsregulierung",
            "NormTermName": "Blende",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizungsregulierung"
        },
        "01515": {
            "GenartName": "Deckel",
            "NormTermName": "Deckel",
            "AssGrpName": null,
            "UsageName": null
        },
        "01516": {
            "GenartName": "Filter",
            "NormTermName": "Filter",
            "AssGrpName": null,
            "UsageName": null
        },
        "01517": {
            "GenartName": "Clip",
            "NormTermName": "Clip",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "01518": {
            "GenartName": "Winkeltrieb",
            "NormTermName": "Winkeltrieb",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "01519": {
            "GenartName": "Schaltelement",
            "NormTermName": "Schaltelement",
            "AssGrpName": null,
            "UsageName": null
        },
        "01520": {
            "GenartName": "Rotor, Ventildrehung",
            "NormTermName": "Rotor",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ventildrehung"
        },
        "01521": {
            "GenartName": "Einstellscheibe, Ventilspiel",
            "NormTermName": "Einstellscheibe",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ventilspiel"
        },
        "01522": {
            "GenartName": "Steuergehäuse",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuergehäuse"
        },
        "01523": {
            "GenartName": "Anlaufscheibe, Kupplung",
            "NormTermName": "Anlaufscheibe",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "01524": {
            "GenartName": "Bremspedal",
            "NormTermName": "Pedal",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "01525": {
            "GenartName": "Kupplungspedal",
            "NormTermName": "Pedal",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "01526": {
            "GenartName": "Fahrpedal",
            "NormTermName": "Pedal",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Drosselklappe"
        },
        "01527": {
            "GenartName": "Druckwandler",
            "NormTermName": "Druckwandler",
            "AssGrpName": null,
            "UsageName": null
        },
        "01528": {
            "GenartName": "Kohlebürste, Generator",
            "NormTermName": "Kohlebürste",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "01529": {
            "GenartName": "Gleichrichter, Generator",
            "NormTermName": "Gleichrichter",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "01530": {
            "GenartName": "Ritzel, Starter",
            "NormTermName": "Ritzel",
            "AssGrpName": "Startanlage",
            "UsageName": "Starter"
        },
        "01531": {
            "GenartName": "Kohlebürste, Starter",
            "NormTermName": "Kohlebürste",
            "AssGrpName": "Startanlage",
            "UsageName": "Starter"
        },
        "01532": {
            "GenartName": "Relais, Abblendlicht",
            "NormTermName": "Relais",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Abblendlicht"
        },
        "01533": {
            "GenartName": "Zylinderkopfschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Zylinderkopf",
            "UsageName": null
        },
        "01534": {
            "GenartName": "Unterlegscheibe, Zylinderkopfschraube",
            "NormTermName": "Unterlegscheibe",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Zylinderkopfschraube"
        },
        "01535": {
            "GenartName": "Bremsbelag, Trommelbremse",
            "NormTermName": "Bremsbackenbelag",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "01537": {
            "GenartName": "Bremsbelagsatz, Trommelbremse",
            "NormTermName": "Bremsbackenbelagsatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "01538": {
            "GenartName": "Niveauschalter, Waschwasservorrat",
            "NormTermName": "Schalter",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasservorrat"
        },
        "01539": {
            "GenartName": "Dichtung, Kerzenschaft",
            "NormTermName": "Dichtung",
            "AssGrpName": "Zündanlage",
            "UsageName": "Kerzenschaftabdichtung"
        },
        "01541": {
            "GenartName": "Schaltventil, Automatikgetriebe",
            "NormTermName": "Ventil",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Schalter"
        },
        "01542": {
            "GenartName": "Lagerung, Spurstange",
            "NormTermName": "Lagerung",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstange"
        },
        "01543": {
            "GenartName": "Krümmer, Abgasanlage",
            "NormTermName": "Krümmer",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "01545": {
            "GenartName": "Anschlagpuffer, Motoraufhängung",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Motoraufhängung",
            "UsageName": "Puffer"
        },
        "01546": {
            "GenartName": "Schraube, Bremssattel",
            "NormTermName": "Schraube",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "01547": {
            "GenartName": "Lenkrad",
            "NormTermName": "Lenkrad",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "01548": {
            "GenartName": "Abschleppseil",
            "NormTermName": "Seil",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Abschlepphilfe"
        },
        "01549": {
            "GenartName": "Absperrgitter, Koffer-/Laderaum",
            "NormTermName": "Gitter",
            "AssGrpName": "Zubehör",
            "UsageName": "Koffer-/Laderaum"
        },
        "01550": {
            "GenartName": "Radiostummschaltung, Einparkhilfe",
            "NormTermName": "Stummschaltung",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Einparkhilfe"
        },
        "01551": {
            "GenartName": "Handsender, Alarmanlage",
            "NormTermName": "Handsender",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Alarmanlage"
        },
        "01552": {
            "GenartName": "Handsendergehäuse, Alarmanlage",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Alarmanlage"
        },
        "01553": {
            "GenartName": "Handsendergehäuse, Zentralverriegelung",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Schließanlage",
            "UsageName": "Zentralverriegelungshandsender"
        },
        "01554": {
            "GenartName": "Verlängerungsleitung, Ultraschallmodul",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Ultraschallmodul"
        },
        "01555": {
            "GenartName": "Gehäuse, Schaltgetriebe",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "01558": {
            "GenartName": "Abdeckung, Frontschürze",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Karosserie",
            "UsageName": "Frontschürze"
        },
        "01559": {
            "GenartName": "Dichtung, Kotflügel",
            "NormTermName": "Dichtung",
            "AssGrpName": "Karosserie",
            "UsageName": "Kotflügel"
        },
        "01560": {
            "GenartName": "Verkleidung, Radhaus",
            "NormTermName": "Verkleidung",
            "AssGrpName": "Karosserie",
            "UsageName": "Radhaus"
        },
        "01561": {
            "GenartName": "Fensterheber",
            "NormTermName": "Fensterheber",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "01563": {
            "GenartName": "Lenkstockschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Instrumente",
            "UsageName": "Lenkstock"
        },
        "01564": {
            "GenartName": "Spannungsstabilisator, Kombi-Instrument",
            "NormTermName": "Regler",
            "AssGrpName": "Instrumente",
            "UsageName": "Spannungsstabilisierung"
        },
        "01565": {
            "GenartName": "Federstecker",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Normteile",
            "UsageName": "Federstecker"
        },
        "01566": {
            "GenartName": "Träger, Stoßfänger",
            "NormTermName": "Träger",
            "AssGrpName": "Karosserie",
            "UsageName": "Stoßfängerverstärkung"
        },
        "01567": {
            "GenartName": "Stoßdämpferaufnahme",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Karosserie",
            "UsageName": "Stoßdämpfer"
        },
        "01568": {
            "GenartName": "Türscharnier",
            "NormTermName": "Scharnier",
            "AssGrpName": "Karosserie",
            "UsageName": "Tür"
        },
        "01569": {
            "GenartName": "Haltersatz, Kühlergitter",
            "NormTermName": "Haltersatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühlergitter"
        },
        "01570": {
            "GenartName": "Zier-/Schutzleistensatz, Kühlergitter",
            "NormTermName": "Zier-/Schutzleistensatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühlergitter"
        },
        "01571": {
            "GenartName": "Halter, Kühlerlüfter",
            "NormTermName": "Halter",
            "AssGrpName": "Kühlung",
            "UsageName": "Lüfter"
        },
        "01572": {
            "GenartName": "Umrüstsatz, Stoßfänger",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Stoßfänger"
        },
        "01573": {
            "GenartName": "Warmluftkanal",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Karosserie",
            "UsageName": "Warmluft"
        },
        "01574": {
            "GenartName": "Haltersatz, Kraftstoffbehälter",
            "NormTermName": "Haltersatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffbehälter"
        },
        "01586": {
            "GenartName": "Domstrebe",
            "NormTermName": "Strebe",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Domstrebe"
        },
        "01587": {
            "GenartName": "Stabilisatorsatz",
            "NormTermName": "Stabilisatorsatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": null
        },
        "01589": {
            "GenartName": "Distanzscheibe, Kurbelwelle",
            "NormTermName": "Distanzscheibe",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwelle"
        },
        "01590": {
            "GenartName": "Achsmutter, Antriebswelle",
            "NormTermName": "Mutter",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "01591": {
            "GenartName": "Ansaugschlauch, Luftfilter",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ansaugschlauch"
        },
        "01593": {
            "GenartName": "Schraubensatz, Federbein-Radlagergehäuse",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbein/Radlagergehäuse"
        },
        "01594": {
            "GenartName": "Buchse, Starterwelle",
            "NormTermName": "Buchse",
            "AssGrpName": "Startanlage",
            "UsageName": "Anlasserwelle"
        },
        "01595": {
            "GenartName": "Buchse, Wähl-/Schaltstange",
            "NormTermName": "Buchse",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Wähl-/Schaltstange"
        },
        "01596": {
            "GenartName": "Buchse, Lenkhebelwelle",
            "NormTermName": "Buchse",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkhebelwelle"
        },
        "01597": {
            "GenartName": "Montagesatz, Lenker",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Lenker"
        },
        "01599": {
            "GenartName": "Hülse, Querlenkerlagerung",
            "NormTermName": "Hülse",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Querlenkerlagerung"
        },
        "01600": {
            "GenartName": "Schlauch, Kurbelgehäuseentlüftung",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "01601": {
            "GenartName": "Fett",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Fett"
        },
        "01602": {
            "GenartName": "Frostschutz",
            "NormTermName": "Frostschutz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "01603": {
            "GenartName": "Froststopfen",
            "NormTermName": "Stopfen",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Froststopfen"
        },
        "01604": {
            "GenartName": "Trichter, Ölpeilstab",
            "NormTermName": "Trichter",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpeilstab"
        },
        "01605": {
            "GenartName": "Flanschdeckel, Schaltgetriebe",
            "NormTermName": "Deckel",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Gelenkflansch"
        },
        "01606": {
            "GenartName": "Flanschdeckel, Automatikgetriebe",
            "NormTermName": "Deckel",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Gelenkflansch"
        },
        "01607": {
            "GenartName": "Wellendichtring, Schaltgetriebeflansch",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Gelenkflansch"
        },
        "01608": {
            "GenartName": "Wellendichtring, Automatikgetriebeflansch",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Gelenkflansch"
        },
        "01609": {
            "GenartName": "Reparatursatz, Schaltgetriebeflansch",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Gelenkflansch"
        },
        "01610": {
            "GenartName": "Reparatursatz, Automatikgetriebeflansch",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Gelenkflansch"
        },
        "01611": {
            "GenartName": "Schraube, Gelenkwellenflansch",
            "NormTermName": "Schraube",
            "AssGrpName": "Radantrieb",
            "UsageName": "Gelenkwelle"
        },
        "01613": {
            "GenartName": "Gewindehülse, Federbein",
            "NormTermName": "Hülse",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Gewindehülse"
        },
        "01614": {
            "GenartName": "Lagerung, Achsträger",
            "NormTermName": "Lagerung",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsträger"
        },
        "01615": {
            "GenartName": "Lagerung, Automatikgetriebeträger",
            "NormTermName": "Lagerung",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Getriebeträger"
        },
        "01616": {
            "GenartName": "Lagerung, Schaltgetriebeträger",
            "NormTermName": "Lagerung",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeträger"
        },
        "01617": {
            "GenartName": "Lagerung, Achsstrebe",
            "NormTermName": "Lagerung",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsstrebe"
        },
        "01618": {
            "GenartName": "Lagerung, Panhardstab",
            "NormTermName": "Lagerung",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Panhardstab"
        },
        "01620": {
            "GenartName": "Hydrauliköl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Hydrauliköl"
        },
        "01621": {
            "GenartName": "Kettenglied, Steuerkette",
            "NormTermName": "Kettenglied",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkette"
        },
        "01622": {
            "GenartName": "Zahnrad, Ölpumpe",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "01624": {
            "GenartName": "Montagesatz, Faltenbalg Antriebswelle",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Radantrieb",
            "UsageName": "Faltenbalg Antriebswelle"
        },
        "01625": {
            "GenartName": "Kontermutter, Ventilspieleinstellschraube",
            "NormTermName": "Mutter",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ventilspieleinstellschraube"
        },
        "01626": {
            "GenartName": "Wälzlager, Federbeinstützlager",
            "NormTermName": "Lager",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Federbeinstützlager"
        },
        "01627": {
            "GenartName": "Schwungradschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Schwungrad"
        },
        "01628": {
            "GenartName": "Riemenscheibenschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Riemenscheibe"
        },
        "01629": {
            "GenartName": "Pedalbelag, Bremspedal",
            "NormTermName": "Pedalbelag",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "01630": {
            "GenartName": "Pedalbelag, Kupplungspedal",
            "NormTermName": "Pedalbelag",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "01631": {
            "GenartName": "Pleuelmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Pleuel"
        },
        "01632": {
            "GenartName": "Reparatursatz, Federbeinstützlager",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Federbeinstützlager"
        },
        "01633": {
            "GenartName": "Reparatursatz, Schalthebel",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schalthebel"
        },
        "01634": {
            "GenartName": "Kette, Zwischenwelle",
            "NormTermName": "Kette",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Zwischenwelle"
        },
        "01635": {
            "GenartName": "Schraube, Laderbefestigung",
            "NormTermName": "Schraube",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lader"
        },
        "01636": {
            "GenartName": "Schwallblech, Ölwanne",
            "NormTermName": "Blech",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölwanne"
        },
        "01637": {
            "GenartName": "Schwingungsdämpfer, Keilrippenriemen",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilrippenriemenspannung"
        },
        "01639": {
            "GenartName": "Schraube, Zylinderkopfhaube",
            "NormTermName": "Schraube",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Zylinderkopfhaube"
        },
        "01640": {
            "GenartName": "Stutzen, Ansaugkrümmer",
            "NormTermName": "Stutzen",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ansaugkrümmer"
        },
        "01641": {
            "GenartName": "Umlenk-/Führungsrolle, Keilriemen",
            "NormTermName": "Rolle",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilriemenumlenkung/-führung"
        },
        "01642": {
            "GenartName": "Umlenkwelle, Schaltung",
            "NormTermName": "Welle",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Umlenkwelle"
        },
        "01644": {
            "GenartName": "Ventilführung",
            "NormTermName": "Führung",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Ventilführung"
        },
        "01645": {
            "GenartName": "Ventilspieleinstellschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ventilspieleinstellschraube"
        },
        "01646": {
            "GenartName": "Verriegelungsknopf",
            "NormTermName": "Knopf",
            "AssGrpName": "Schließanlage",
            "UsageName": "Verriegelungsknopf"
        },
        "01648": {
            "GenartName": "Vorkammer",
            "NormTermName": "Vorkammer",
            "AssGrpName": "Zylinderkopf",
            "UsageName": null
        },
        "01650": {
            "GenartName": "Wähl-/Schaltstange",
            "NormTermName": "Stange",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Wähl-/Schaltstange"
        },
        "01651": {
            "GenartName": "Reparatursatz, Zwischenwelle",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Zwischenwelle"
        },
        "01652": {
            "GenartName": "Wellendichtring, Zwischenwelle",
            "NormTermName": "Dichtring",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Zwischenwelle"
        },
        "01653": {
            "GenartName": "Dichtung, Zwischenwelle",
            "NormTermName": "Dichtung",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Zwischenwelle"
        },
        "01654": {
            "GenartName": "Wellendichtring, Radlager",
            "NormTermName": "Dichtring",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radlager"
        },
        "01655": {
            "GenartName": "Kappe, Radlager",
            "NormTermName": "Kappe",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radlager"
        },
        "01656": {
            "GenartName": "Sicherungsring",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Normteile",
            "UsageName": "Sicherungsring"
        },
        "01657": {
            "GenartName": "Schraube, Achsstummel",
            "NormTermName": "Schraube",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsstummel"
        },
        "01658": {
            "GenartName": "Splint",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Normteile",
            "UsageName": "Splint"
        },
        "01659": {
            "GenartName": "Sicherungsstift",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Normteile",
            "UsageName": "Sicherungsstift"
        },
        "01660": {
            "GenartName": "Halteteller, Bremsbacken",
            "NormTermName": "Halteteller",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbacken"
        },
        "01661": {
            "GenartName": "Abdeckblech, Bremstrommel",
            "NormTermName": "Blech",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremstrommel"
        },
        "01662": {
            "GenartName": "Halteschraube, Lenksäule",
            "NormTermName": "Schraube",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenksäule"
        },
        "01663": {
            "GenartName": "Hülse, Stabilisatorlagerung",
            "NormTermName": "Hülse",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisatorlagerung"
        },
        "01664": {
            "GenartName": "Laufring, Kurbelwelle",
            "NormTermName": "Laufring",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwelle"
        },
        "01665": {
            "GenartName": "Adapter, Elektrowendel",
            "NormTermName": "Adapter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Elektrowendel"
        },
        "01666": {
            "GenartName": "Montagesatz, Membranbremszylinder",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Membranbremszylinder"
        },
        "01667": {
            "GenartName": "Getriebeöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Getriebeöl"
        },
        "01668": {
            "GenartName": "Bedienelement, Klimaanlage",
            "NormTermName": "Bedienelement",
            "AssGrpName": "Klimaanlage",
            "UsageName": null
        },
        "01669": {
            "GenartName": "Bedienelement, Heizung/Lüftung",
            "NormTermName": "Bedienelement",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": null
        },
        "01670": {
            "GenartName": "Anlasserbuchse, Kupplungsglocke",
            "NormTermName": "Buchse",
            "AssGrpName": "Startanlage",
            "UsageName": "Kupplungsglocke"
        },
        "01671": {
            "GenartName": "Bridenmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Blattfeder"
        },
        "01672": {
            "GenartName": "Buchse, Lenkwelle",
            "NormTermName": "Buchse",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkwelle"
        },
        "01676": {
            "GenartName": "Kollektorlagerbuchse, Starter",
            "NormTermName": "Buchse",
            "AssGrpName": "Startanlage",
            "UsageName": "Kollektorlager"
        },
        "01677": {
            "GenartName": "Schalldämpfungsmatte",
            "NormTermName": "Matte",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Schalldämpfung"
        },
        "01678": {
            "GenartName": "Lagerung, Generator",
            "NormTermName": "Lagerung",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "01679": {
            "GenartName": "Dichtring, Druckluftkompressor",
            "NormTermName": "Dichtring",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "01680": {
            "GenartName": "Dichtung, Schwungrad",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Schwungrad"
        },
        "01684": {
            "GenartName": "Dichtungssatz, Außenplanetengetriebe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Radantrieb",
            "UsageName": "Außenplanetengetriebe"
        },
        "01685": {
            "GenartName": "Anlaufscheibe, Außenplanetengetriebe",
            "NormTermName": "Anlaufscheibe",
            "AssGrpName": "Radantrieb",
            "UsageName": "Außenplanetengetriebe"
        },
        "01686": {
            "GenartName": "Zahnscheibe, Außenplanetengetriebe",
            "NormTermName": "Zahnscheibe",
            "AssGrpName": "Radantrieb",
            "UsageName": "Außenplanetengetriebe"
        },
        "01687": {
            "GenartName": "Wellendichtring, Außenplanetengetriebe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Radantrieb",
            "UsageName": "Außenplanetengetriebe"
        },
        "01688": {
            "GenartName": "Dichtung, Außenplanetengetriebe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Radantrieb",
            "UsageName": "Außenplanetengetriebe"
        },
        "01689": {
            "GenartName": "Dichtungssatz, Hydraulikpumpe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Hydraulikpumpe"
        },
        "01690": {
            "GenartName": "Dichtungssatz, Radnabe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radnabe"
        },
        "01691": {
            "GenartName": "Laufring, Radnabe",
            "NormTermName": "Laufring",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radnabe"
        },
        "01692": {
            "GenartName": "Druckschalter, Längs-Ausgleichsperre",
            "NormTermName": "Schalter",
            "AssGrpName": "Instrumente",
            "UsageName": "Längs-Ausgleichsperre"
        },
        "01693": {
            "GenartName": "Druckscheibe, Federgehänge",
            "NormTermName": "Druckscheibe",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federgehänge"
        },
        "01694": {
            "GenartName": "Düsennadel, Vergaser",
            "NormTermName": "Düsennadel",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01696": {
            "GenartName": "Elektrowendel",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Elektrowendel"
        },
        "01697": {
            "GenartName": "Entlüftungsschlauch, Kraftstoffbehälter",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Tankentlüftung"
        },
        "01698": {
            "GenartName": "Anschlagpuffer, Fahrerhaus",
            "NormTermName": "Puffer",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Anschlagpuffer"
        },
        "01699": {
            "GenartName": "Lagerung, Blattfeder",
            "NormTermName": "Lagerung",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Blattfeder"
        },
        "01700": {
            "GenartName": "Gehäusedeckel, Kurbelgehäuse",
            "NormTermName": "Deckel",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Gehäuse/Flansch"
        },
        "01701": {
            "GenartName": "Gestängesteller, Bremsanlage",
            "NormTermName": "Gestängesteller",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "01702": {
            "GenartName": "Gewindebolzen, Lader",
            "NormTermName": "Bolzen",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lader"
        },
        "01703": {
            "GenartName": "Gleitbacke, Fensterheber",
            "NormTermName": "Gleitbacke",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Fensterheber"
        },
        "01704": {
            "GenartName": "Gleitschienenbelag, Steuerkette",
            "NormTermName": "Gleitschienenbelag",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkette"
        },
        "01705": {
            "GenartName": "Dichtung, Kühlmittelrohr",
            "NormTermName": "Dichtung",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Kühlmittel"
        },
        "01706": {
            "GenartName": "Druckscheibe, Blattfeder",
            "NormTermName": "Druckscheibe",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Blattfeder"
        },
        "01707": {
            "GenartName": "Lagerung, Federgehänge",
            "NormTermName": "Lagerung",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federgehänge"
        },
        "01708": {
            "GenartName": "Puffer, Motorhaube",
            "NormTermName": "Puffer",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorhaube"
        },
        "01709": {
            "GenartName": "Lagerung, Verteilergetriebe",
            "NormTermName": "Lagerung",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Verteilergetriebe"
        },
        "01710": {
            "GenartName": "Anschlagpuffer, Luftfilter",
            "NormTermName": "Puffer",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Luftfilteranschlag"
        },
        "01711": {
            "GenartName": "Halter, Gasgestänge",
            "NormTermName": "Halter",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Gasgestänge"
        },
        "01712": {
            "GenartName": "Halter, Hemmschuh",
            "NormTermName": "Halter",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Hemmschuh"
        },
        "01713": {
            "GenartName": "Halter, Starterzug",
            "NormTermName": "Halter",
            "AssGrpName": "Startanlage",
            "UsageName": "Starterzug"
        },
        "01714": {
            "GenartName": "Dichtung, Koffer-/Laderaumklappe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Karosserie",
            "UsageName": "Heckklappe"
        },
        "01715": {
            "GenartName": "Schmutzfänger",
            "NormTermName": "Schmutzfänger",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "01716": {
            "GenartName": "Hemmschuh",
            "NormTermName": "Hemmschuh",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "01717": {
            "GenartName": "Zusatzfeder",
            "NormTermName": "Feder",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Zusatzfeder"
        },
        "01718": {
            "GenartName": "Druckspeicher, Federung/Dämpfung",
            "NormTermName": "Druckspeicher",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "01719": {
            "GenartName": "Schraube, Nabenglocke",
            "NormTermName": "Schraube",
            "AssGrpName": "Radantrieb",
            "UsageName": "Nabenglocke"
        },
        "01720": {
            "GenartName": "Kabelbinder",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Normteile",
            "UsageName": "Kabelbinder"
        },
        "01721": {
            "GenartName": "Halteband, Druckluftbehälter",
            "NormTermName": "Band",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftbehälter"
        },
        "01722": {
            "GenartName": "Zahnradsatz, Ölpumpe",
            "NormTermName": "Zahnradsatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "01723": {
            "GenartName": "Klemmplatte, Trilex",
            "NormTermName": "Klemmplatte",
            "AssGrpName": "Räder",
            "UsageName": "Trilex"
        },
        "01724": {
            "GenartName": "Dichtung, Bremssattelkolben",
            "NormTermName": "Dichtung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattelkolben"
        },
        "01725": {
            "GenartName": "Kontaktbuchse",
            "NormTermName": "Buchse",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kontaktbuchse"
        },
        "01726": {
            "GenartName": "Kontaktstift",
            "NormTermName": "Stift",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kontaktstift"
        },
        "01727": {
            "GenartName": "Kugelpfanne",
            "NormTermName": "Kugelpfanne",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "01728": {
            "GenartName": "Kugelkopf",
            "NormTermName": "Kugelkopf",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "01729": {
            "GenartName": "Kreuzgelenk",
            "NormTermName": "Gelenk",
            "AssGrpName": "Normteile",
            "UsageName": "Kreuzgelenk"
        },
        "01730": {
            "GenartName": "Sicherungsbolzen",
            "NormTermName": "Bolzen",
            "AssGrpName": "Normteile",
            "UsageName": "Sicherungsbolzen"
        },
        "01731": {
            "GenartName": "Kronenmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Kronenmutter"
        },
        "01732": {
            "GenartName": "Halter, Bremswellenlager",
            "NormTermName": "Halter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremswellenlager"
        },
        "01735": {
            "GenartName": "Magnetschalter, Rundumkennleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Signalanlage",
            "UsageName": "Rundumkennleuchtenmagnetschalter"
        },
        "01736": {
            "GenartName": "Membran, Membranbremszylinder",
            "NormTermName": "Membran",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Bremszylinder"
        },
        "01737": {
            "GenartName": "Ventil, Dauerbremsanlage",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Dauerbremsanlage"
        },
        "01738": {
            "GenartName": "Mutter, Schaltgetriebehauptwelle",
            "NormTermName": "Mutter",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Hauptwelle"
        },
        "01739": {
            "GenartName": "Mutter, Hauptwelle",
            "NormTermName": "Mutter",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Hauptwelle"
        },
        "01740": {
            "GenartName": "Mutter, Kegelrad",
            "NormTermName": "Mutter",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Kegelrad"
        },
        "01741": {
            "GenartName": "Nachstellbolzen, Bremsanlage",
            "NormTermName": "Bolzen",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Automatische Nachstellung"
        },
        "01742": {
            "GenartName": "Nabe, Kurbelwelle",
            "NormTermName": "Nabe",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwelle"
        },
        "01743": {
            "GenartName": "Öldruckschalter, Automatikgetriebe",
            "NormTermName": "Schalter",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Öldruck"
        },
        "01744": {
            "GenartName": "Dichtring, Bremsbacke",
            "NormTermName": "Dichtring",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbacken"
        },
        "01745": {
            "GenartName": "Dichtring, Radnabe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radnabe"
        },
        "01747": {
            "GenartName": "Dichtring, Lufttrockner",
            "NormTermName": "Dichtring",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Lufttrockner"
        },
        "01748": {
            "GenartName": "Hydraulikpumpe, Niveauregulierung",
            "NormTermName": "Pumpe",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Niveauregulierung"
        },
        "01749": {
            "GenartName": "Schutzdeckel, Radnabe",
            "NormTermName": "Deckel",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radnabe"
        },
        "01750": {
            "GenartName": "Relais, Glühanlage",
            "NormTermName": "Relais",
            "AssGrpName": "Glühanlage",
            "UsageName": "vorglühen"
        },
        "01751": {
            "GenartName": "Reparatursatz, Automatische Kupplungsnachstellung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Automatische Nachstellung"
        },
        "01752": {
            "GenartName": "Einstellscheibe, Lenkspindel",
            "NormTermName": "Einstellscheibe",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkspindel"
        },
        "01753": {
            "GenartName": "Lager, Kupplungshebel",
            "NormTermName": "Lager",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsbetätigung"
        },
        "01754": {
            "GenartName": "Rotorsatz, Ölpumpe",
            "NormTermName": "Rotorsatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "01755": {
            "GenartName": "Feder, Gasgestänge",
            "NormTermName": "Feder",
            "AssGrpName": "Vergaser",
            "UsageName": "Gasgestänge"
        },
        "01756": {
            "GenartName": "Feder, Gasgestänge",
            "NormTermName": "Feder",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Gasgestänge"
        },
        "01757": {
            "GenartName": "Druckschalter, Achsdruckbegrenzung",
            "NormTermName": "Schalter",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Achsdruckbegrenzung"
        },
        "01758": {
            "GenartName": "Druckschalter, Achsdruckreglung",
            "NormTermName": "Schalter",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Achsdruckreglung"
        },
        "01759": {
            "GenartName": "Schalter, Arbeitslampe",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Arbeitslampe"
        },
        "01760": {
            "GenartName": "Schalter, Differentialsperre",
            "NormTermName": "Schalter",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differentialsperre"
        },
        "01761": {
            "GenartName": "Schalter, Fensterheber",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Fensterheber"
        },
        "01762": {
            "GenartName": "Schalter, \" Fahrerhaus gekippt\" Anzeige",
            "NormTermName": "Schalter",
            "AssGrpName": "Instrumente",
            "UsageName": "Fahrerhauskippung"
        },
        "01763": {
            "GenartName": "Schalter, Splitgetriebe",
            "NormTermName": "Schalter",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Splitgetriebe"
        },
        "01764": {
            "GenartName": "Schalter, Retarder",
            "NormTermName": "Schalter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "01765": {
            "GenartName": "Schalter, Allradantrieb",
            "NormTermName": "Schalter",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Allradantrieb"
        },
        "01766": {
            "GenartName": "Schalter, Handbremskontrolleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Instrumente",
            "UsageName": "Handbremskontrolleuchte"
        },
        "01767": {
            "GenartName": "Schalter, Hupe",
            "NormTermName": "Schalter",
            "AssGrpName": "Signalanlage",
            "UsageName": "Hupe"
        },
        "01768": {
            "GenartName": "Schalter, Scheibenwischerintervall",
            "NormTermName": "Schalter",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Intervallsteuerung"
        },
        "01769": {
            "GenartName": "Schalter, Nebenantrieb",
            "NormTermName": "Schalter",
            "AssGrpName": "Nebenantrieb",
            "UsageName": null
        },
        "01770": {
            "GenartName": "Schalter, Rückfahrwarner",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Rückfahrwarner"
        },
        "01771": {
            "GenartName": "Schalter, Rundumkennleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Signalanlage",
            "UsageName": "Rundumkennleuchte"
        },
        "01772": {
            "GenartName": "Schalter, Sitzheizung",
            "NormTermName": "Schalter",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Sitzheizung"
        },
        "01773": {
            "GenartName": "Schalter, Standheizung",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Standheizung"
        },
        "01774": {
            "GenartName": "Schalter, Türschloss",
            "NormTermName": "Schalter",
            "AssGrpName": "Schließanlage",
            "UsageName": "Türschloss"
        },
        "01775": {
            "GenartName": "Schalthebelverkleidung",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schalthebel"
        },
        "01776": {
            "GenartName": "Buchse, Schaltstange",
            "NormTermName": "Buchse",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltstange"
        },
        "01777": {
            "GenartName": "Dichtung, Schiebedach",
            "NormTermName": "Dichtung",
            "AssGrpName": "Karosserie",
            "UsageName": "Schiebedach"
        },
        "01778": {
            "GenartName": "Schlauch, Getriebeölkühler",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Getriebeölkühler"
        },
        "01779": {
            "GenartName": "Schlauch, Wärmetauscher (Heizung)",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizungskühler"
        },
        "01780": {
            "GenartName": "Halteschelle",
            "NormTermName": "Schelle",
            "AssGrpName": "Normteile",
            "UsageName": "Halteschelle"
        },
        "01781": {
            "GenartName": "Schmiernippel",
            "NormTermName": "Nippel",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "01782": {
            "GenartName": "Schließzylinder, Zündschloss",
            "NormTermName": "Schließzylinder",
            "AssGrpName": "Schließanlage",
            "UsageName": "Zündschloss"
        },
        "01784": {
            "GenartName": "Gitter, Rundumkennleuchte",
            "NormTermName": "Gitter",
            "AssGrpName": "Signalanlage",
            "UsageName": "Rundumkennleuchte"
        },
        "01785": {
            "GenartName": "Schwimmernadelventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Vergaser",
            "UsageName": "Schwimmernadel"
        },
        "01786": {
            "GenartName": "Schwingungstilger, Gelenkwelle",
            "NormTermName": "Schwingungstilger",
            "AssGrpName": "Radantrieb",
            "UsageName": "Gelenkwelle"
        },
        "01787": {
            "GenartName": "Seilzug, Schaltgetriebe",
            "NormTermName": "Seilzug",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltung"
        },
        "01788": {
            "GenartName": "Mutter, Abgaskrümmer",
            "NormTermName": "Mutter",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgaskrümmer"
        },
        "01789": {
            "GenartName": "Mutter, Ansaugkrümmer",
            "NormTermName": "Mutter",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ansaugkrümmer"
        },
        "01790": {
            "GenartName": "Kurbel, Standbremse",
            "NormTermName": "Kurbel",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremse"
        },
        "01791": {
            "GenartName": "Stecker, Elektrowendel",
            "NormTermName": "Stecker",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Elektrowendel"
        },
        "01792": {
            "GenartName": "Thermostatgehäuse",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Kühlung",
            "UsageName": "Thermostatgehäuse"
        },
        "01793": {
            "GenartName": "Schutzmittel, Nockenwelle",
            "NormTermName": "Schutzmittel",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwelle"
        },
        "01794": {
            "GenartName": "Unterdruckdose, Getriebesteuerung",
            "NormTermName": "Unterdruckdose",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Steuerung"
        },
        "01800": {
            "GenartName": "Verschlussschraube, Kurbelgehäuse",
            "NormTermName": "Schraube",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": null
        },
        "01801": {
            "GenartName": "Dichtung, Schaltgestänge",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgestänge"
        },
        "01802": {
            "GenartName": "Winkelgelenk",
            "NormTermName": "Gelenk",
            "AssGrpName": "Normteile",
            "UsageName": "Winkelgelenk"
        },
        "01805": {
            "GenartName": "Zentrierbuchse, Längswelle",
            "NormTermName": "Buchse",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Zentrierung Kardanwelle"
        },
        "01806": {
            "GenartName": "Zugöse, Anhängevorrichtung",
            "NormTermName": "Zugöse",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "01807": {
            "GenartName": "Zugösenbuchse, Anhängevorrichtung",
            "NormTermName": "Buchse",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Zugöse"
        },
        "01808": {
            "GenartName": "Zugsattelzapfen",
            "NormTermName": "Sattelzapfen",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "01809": {
            "GenartName": "Zwischenwellensatz",
            "NormTermName": "Wellensatz",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Zwischenwelle"
        },
        "01810": {
            "GenartName": "Kugelbolzen, Kupplungsbetätigung",
            "NormTermName": "Bolzen",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsbetätigung"
        },
        "01811": {
            "GenartName": "Silikonschmierstoff",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Silikon"
        },
        "01812": {
            "GenartName": "Hochtemperaturschmierstoff",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Hochtemperaturschmierstoff"
        },
        "01813": {
            "GenartName": "Kupferfett",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kupferfett"
        },
        "01814": {
            "GenartName": "Molybdänfett",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Molybdänfett"
        },
        "01815": {
            "GenartName": "Universalschmierstoff",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Universal"
        },
        "01816": {
            "GenartName": "Karosserieklebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Karosserieteile"
        },
        "01817": {
            "GenartName": "Karosseriesprühkleber",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Karosseriesprühkleber"
        },
        "01818": {
            "GenartName": "Klebstoff, Kunststoffreparatur",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kunststoffreparatur"
        },
        "01819": {
            "GenartName": "Scheibenklebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Scheibeneinbau"
        },
        "01820": {
            "GenartName": "Klebeband",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Klebeband"
        },
        "01821": {
            "GenartName": "Gummiklebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Gummi/Gummiprofile"
        },
        "01822": {
            "GenartName": "Glas-Metall-Klebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Glas-Metall"
        },
        "01823": {
            "GenartName": "Buchsen/Lager-Klebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Buchsen/Lager"
        },
        "01824": {
            "GenartName": "Schraubensicherung",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Schraubensicherung"
        },
        "01825": {
            "GenartName": "Universalklebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Universal"
        },
        "01826": {
            "GenartName": "Sekundenkleber",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Sofortkleber"
        },
        "01827": {
            "GenartName": "Dichtungsklebespray",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Dichtungen"
        },
        "01828": {
            "GenartName": "PVC-Klebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "PVC"
        },
        "01829": {
            "GenartName": "Epoxy-Klebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Epoxy"
        },
        "01830": {
            "GenartName": "Rückspiegel-Klebeset",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Rückspiegel"
        },
        "01831": {
            "GenartName": "Metall-Klebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Metall"
        },
        "01832": {
            "GenartName": "Kunststoff-Klebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kunststoff"
        },
        "01833": {
            "GenartName": "Reparatursatz, Heckscheibenheizdraht",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Heckscheibenheizdraht"
        },
        "01834": {
            "GenartName": "Reparatursatz, Kunststoffreparatur",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kunststoffreparatur"
        },
        "01835": {
            "GenartName": "Reparatursatz, Scheibeneinkleben",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Scheibeneinbau"
        },
        "01836": {
            "GenartName": "Reparatursatz, Abgasanlage",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Abgasanlage"
        },
        "01837": {
            "GenartName": "Verstärkungsstreifen, Kunststoffreparatur",
            "NormTermName": "Verstärkungsstreifen",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kunststoffreparatur"
        },
        "01838": {
            "GenartName": "Aktivator, Scheibenklebstoff",
            "NormTermName": "Aktivator",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Scheibeneinbau"
        },
        "01839": {
            "GenartName": "Primer, Scheibenklebstoff",
            "NormTermName": "Primer",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Scheibeneinbau"
        },
        "01840": {
            "GenartName": "Applikator",
            "NormTermName": "Applikator",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Scheibeneinbau"
        },
        "01841": {
            "GenartName": "Vorwärmbox, Kartuschen",
            "NormTermName": "Vorwärmbox",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Kartuschen"
        },
        "01842": {
            "GenartName": "Handdruckpistole",
            "NormTermName": "Handdruckpistole",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": null
        },
        "01843": {
            "GenartName": "Dosierspitze, Kartuschen",
            "NormTermName": "Mischelement",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Kartuschen"
        },
        "01844": {
            "GenartName": "Druckluftpistole",
            "NormTermName": "Druckluftpistole",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": null
        },
        "01845": {
            "GenartName": "Dichtstoff",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "01846": {
            "GenartName": "Feststoffdichtung",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Feststoffdichtung"
        },
        "01847": {
            "GenartName": "Dichtband",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Dichtband"
        },
        "01848": {
            "GenartName": "Gehäuse/Flansch-Dichtstoff",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Gehäuse/Flansch"
        },
        "01849": {
            "GenartName": "Kühlerdichtstoff",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kühler"
        },
        "01850": {
            "GenartName": "Dichtstoff, Abgasanlage",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Abgasanlage"
        },
        "01851": {
            "GenartName": "Unterbodenschutz",
            "NormTermName": "Unterbodenschutz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "01852": {
            "GenartName": "Steinschlagschutz",
            "NormTermName": "Steinschlagschutz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "01853": {
            "GenartName": "Hohlraumkonservierung",
            "NormTermName": "Konservierungsmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Hohlraum"
        },
        "01854": {
            "GenartName": "Motorraumkonservierung",
            "NormTermName": "Konservierungsmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Motorraum"
        },
        "01855": {
            "GenartName": "Zinkspray",
            "NormTermName": "Grundierung",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Zinkstaubgrundierung"
        },
        "01856": {
            "GenartName": "Sprühpistole, Unterbodenschutz",
            "NormTermName": "Sprühpistole",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Unterbodenschutz"
        },
        "01857": {
            "GenartName": "Sprühpistole, Druckbecher",
            "NormTermName": "Sprühpistole",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Druckbecher"
        },
        "01858": {
            "GenartName": "Nylonsonde",
            "NormTermName": "Sonde",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Nylonsonde"
        },
        "01859": {
            "GenartName": "Hakensonde",
            "NormTermName": "Sonde",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Hakensonde"
        },
        "01860": {
            "GenartName": "Fassanlage, Hohlraumkonservierung/Unterbodenschutz",
            "NormTermName": "Fassanlage",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Hohlraumkonservierung-/Unterbodenschutzverarbeitung"
        },
        "01861": {
            "GenartName": "Anti-Dröhn-Matte",
            "NormTermName": "Matte",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Anti-Dröhn"
        },
        "01862": {
            "GenartName": "Motoröl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Motoröl"
        },
        "01863": {
            "GenartName": "Frostschutz, Scheibenreinigungsanlage",
            "NormTermName": "Frostschutz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Scheibenreinigungsanlage"
        },
        "01864": {
            "GenartName": "Reiniger, Scheibenreinigungsanlage",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Scheibenreinigungsanlage"
        },
        "01865": {
            "GenartName": "Rostlöser",
            "NormTermName": "Rostlöser",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "01866": {
            "GenartName": "Pumpsprühflasche",
            "NormTermName": "Pumpsprühflasche",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": null
        },
        "01867": {
            "GenartName": "Füller",
            "NormTermName": "Füller",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "01868": {
            "GenartName": "Grundierung",
            "NormTermName": "Grundierung",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "01869": {
            "GenartName": "Rostschutzgrundierung",
            "NormTermName": "Grundierung",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Rostschutz"
        },
        "01870": {
            "GenartName": "Rostumwandler",
            "NormTermName": "Rostumwandler",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "01871": {
            "GenartName": "Lack",
            "NormTermName": "Lack",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "01872": {
            "GenartName": "Härter, Lack",
            "NormTermName": "Härter",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Lack"
        },
        "01873": {
            "GenartName": "Härter, Spachtelmasse",
            "NormTermName": "Härter",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Spachtelmasse"
        },
        "01874": {
            "GenartName": "Verdünner",
            "NormTermName": "Verdünner",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "01875": {
            "GenartName": "Glasfaserspachtel",
            "NormTermName": "Spachtelmasse",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Glasfaserspachtel"
        },
        "01876": {
            "GenartName": "Metallspachtel",
            "NormTermName": "Spachtelmasse",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Metallspachtel"
        },
        "01877": {
            "GenartName": "Feinspachtel",
            "NormTermName": "Spachtelmasse",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Feinspachtel"
        },
        "01878": {
            "GenartName": "Spritzspachtel",
            "NormTermName": "Spachtelmasse",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Spritzspachtel"
        },
        "01879": {
            "GenartName": "Universalspachtel",
            "NormTermName": "Spachtelmasse",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Universal"
        },
        "01880": {
            "GenartName": "Hautpflegemittel",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Hautpflege"
        },
        "01881": {
            "GenartName": "Hautschutzmittel",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Hautschutz"
        },
        "01882": {
            "GenartName": "Handreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Hautreinigung"
        },
        "01883": {
            "GenartName": "Bremsen/Kupplungs-Reiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Bremsen/Kupplung"
        },
        "01884": {
            "GenartName": "Felgenreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Felgen"
        },
        "01885": {
            "GenartName": "Textil/Teppich-Reiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Textil/Teppich"
        },
        "01886": {
            "GenartName": "Motorreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Motor"
        },
        "01887": {
            "GenartName": "Universalreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Universal"
        },
        "01888": {
            "GenartName": "Kaltreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kaltreiniger"
        },
        "01889": {
            "GenartName": "Reiniger/Verdünner",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Reiniger/Verdünner"
        },
        "01890": {
            "GenartName": "Schnellreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "01891": {
            "GenartName": "Lackreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Lack"
        },
        "01892": {
            "GenartName": "Teerentferner",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Teerentferner"
        },
        "01893": {
            "GenartName": "Scheibenreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Glasscheiben"
        },
        "01894": {
            "GenartName": "Kunststoffreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kunststoff"
        },
        "01895": {
            "GenartName": "Lackpolitur",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Lackpolitur"
        },
        "01896": {
            "GenartName": "Klimaanlagenreiniger/-desinfizierer",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Klimaanlage"
        },
        "01897": {
            "GenartName": "Insektenentferner",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Insektenentferner"
        },
        "01898": {
            "GenartName": "Kunststoffpflegemittel",
            "NormTermName": "Pflegemittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kunststoff"
        },
        "01899": {
            "GenartName": "Gummipflegemittel",
            "NormTermName": "Pflegemittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Gummi"
        },
        "01900": {
            "GenartName": "Ventileinschleifpaste",
            "NormTermName": "Schleifmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Ventileinschleifen"
        },
        "01901": {
            "GenartName": "Motorglanzlack",
            "NormTermName": "Lack",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Motorglanz"
        },
        "01902": {
            "GenartName": "Gewindedichtstoff",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Gewinde"
        },
        "01903": {
            "GenartName": "Scheibendichtstoff",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Scheibeneinbau"
        },
        "01904": {
            "GenartName": "Dichtungsoptimierer",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Dichtungsoptimierung"
        },
        "01905": {
            "GenartName": "O-Ring-Reparaturset",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "O-Ring"
        },
        "01906": {
            "GenartName": "Karosseriedichtstoff",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Nahtabdichtung"
        },
        "01907": {
            "GenartName": "Dichtstoff, Bördelfalzversiegelung",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Bördelfalzversiegelung"
        },
        "01908": {
            "GenartName": "Kunststoff-Primer",
            "NormTermName": "Primer",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kunststoffreparatur"
        },
        "01911": {
            "GenartName": "Distanzplatte, Anhängevorrichtung",
            "NormTermName": "Distanzplatte",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "01913": {
            "GenartName": "Regler",
            "NormTermName": "Regler",
            "AssGrpName": null,
            "UsageName": null
        },
        "01914": {
            "GenartName": "Spiegelglas, Außenspiegel",
            "NormTermName": "Spiegelglas",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegel"
        },
        "01915": {
            "GenartName": "Spiegelglas, Außenspiegel",
            "NormTermName": "Spiegelglas",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Außenspiegel"
        },
        "01916": {
            "GenartName": "Anschlaghebel, Vergaser",
            "NormTermName": "Hebel",
            "AssGrpName": "Vergaser",
            "UsageName": "Anschlaghebel"
        },
        "01917": {
            "GenartName": "Anstellerdose, Vergaser",
            "NormTermName": "Unterdruckdose",
            "AssGrpName": "Vergaser",
            "UsageName": "Anstellerdose"
        },
        "01918": {
            "GenartName": "Biegefeder, Vergaser",
            "NormTermName": "Feder",
            "AssGrpName": "Vergaser",
            "UsageName": "Biegefeder"
        },
        "01919": {
            "GenartName": "Halter, Seilzug",
            "NormTermName": "Halter",
            "AssGrpName": "Vergaser",
            "UsageName": "Seilzug"
        },
        "01920": {
            "GenartName": "Bypassbeheizung",
            "NormTermName": "Heizung",
            "AssGrpName": "Vergaser",
            "UsageName": "Bypass"
        },
        "01921": {
            "GenartName": "Deckel, Vergaser",
            "NormTermName": "Deckel",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01922": {
            "GenartName": "Dichtring, Vergaser",
            "NormTermName": "Dichtring",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01923": {
            "GenartName": "Drehfeder, Vergaser",
            "NormTermName": "Feder",
            "AssGrpName": "Vergaser",
            "UsageName": "Drehfeder"
        },
        "01924": {
            "GenartName": "Drosselhebel, Vergaser",
            "NormTermName": "Hebel",
            "AssGrpName": "Vergaser",
            "UsageName": "Drosselhebel"
        },
        "01925": {
            "GenartName": "Halter, Drosselhebel",
            "NormTermName": "Halter",
            "AssGrpName": "Vergaser",
            "UsageName": "Drosselhebel"
        },
        "01926": {
            "GenartName": "Sensor, Drosselklappenstellung",
            "NormTermName": "Sensor",
            "AssGrpName": "Vergaser",
            "UsageName": "Drosselklappe"
        },
        "01927": {
            "GenartName": "Welle, Drosselklappe",
            "NormTermName": "Welle",
            "AssGrpName": "Vergaser",
            "UsageName": "Drosselklappe"
        },
        "01928": {
            "GenartName": "Ansteller, Drosselklappe",
            "NormTermName": "Ansteller",
            "AssGrpName": "Vergaser",
            "UsageName": "Drosselklappe"
        },
        "01929": {
            "GenartName": "Düse, Vergaser",
            "NormTermName": "Düse",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01930": {
            "GenartName": "Anstellerhalter",
            "NormTermName": "Halter",
            "AssGrpName": "Vergaser",
            "UsageName": "Ansteller"
        },
        "01931": {
            "GenartName": "Düsenhülse, Vergaser",
            "NormTermName": "Hülse",
            "AssGrpName": "Vergaser",
            "UsageName": "Einspritzdüse"
        },
        "01932": {
            "GenartName": "Einstellschraube, Vergaser",
            "NormTermName": "Schraube",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01933": {
            "GenartName": "Umschaltbelüftung, Vergaser",
            "NormTermName": "Belüftung",
            "AssGrpName": "Vergaser",
            "UsageName": "Umschaltbelüftung"
        },
        "01935": {
            "GenartName": "Schraube, Gemischregulierung",
            "NormTermName": "Schraube",
            "AssGrpName": "Vergaser",
            "UsageName": "Gemischregulierung"
        },
        "01936": {
            "GenartName": "Halter, Vergaser",
            "NormTermName": "Halter",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01937": {
            "GenartName": "Handknopf, Vergaser",
            "NormTermName": "Knopf",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01938": {
            "GenartName": "Hauptdüsenabschaltung, Vergaser",
            "NormTermName": "Abschaltung",
            "AssGrpName": "Vergaser",
            "UsageName": "Hauptdüse"
        },
        "01939": {
            "GenartName": "Hebel, Vergaser",
            "NormTermName": "Hebel",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01940": {
            "GenartName": "Kappe, Vergaser",
            "NormTermName": "Kappe",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01941": {
            "GenartName": "Klemmrolle, Vergaser",
            "NormTermName": "Rolle",
            "AssGrpName": "Vergaser",
            "UsageName": "Klemmrolle"
        },
        "01942": {
            "GenartName": "Klemmschraube, Vergaser",
            "NormTermName": "Schraube",
            "AssGrpName": "Vergaser",
            "UsageName": "Klemmschraube"
        },
        "01943": {
            "GenartName": "Anschlussstutzen, Kraftstoffleitung",
            "NormTermName": "Stutzen",
            "AssGrpName": "Vergaser",
            "UsageName": "Kraftstoffleitung"
        },
        "01944": {
            "GenartName": "Kraftstoffsieb, Vergaser",
            "NormTermName": "Sieb",
            "AssGrpName": "Vergaser",
            "UsageName": "Kraftstoff"
        },
        "01945": {
            "GenartName": "Lagerbolzen, Vergaser",
            "NormTermName": "Bolzen",
            "AssGrpName": "Vergaser",
            "UsageName": "Lager"
        },
        "01946": {
            "GenartName": "Leerlaufdüse, Vergaser",
            "NormTermName": "Düse",
            "AssGrpName": "Vergaser",
            "UsageName": "Leerlauf"
        },
        "01947": {
            "GenartName": "Leerlaufluftregelung, Vergaser",
            "NormTermName": "Regler",
            "AssGrpName": "Vergaser",
            "UsageName": "Leerlaufluft"
        },
        "01949": {
            "GenartName": "Linsenschraube, Vergaser",
            "NormTermName": "Schraube",
            "AssGrpName": "Vergaser",
            "UsageName": "Linsenschraube"
        },
        "01950": {
            "GenartName": "Luftklappenwelle, Vergaser",
            "NormTermName": "Welle",
            "AssGrpName": "Vergaser",
            "UsageName": "Luftklappe"
        },
        "01951": {
            "GenartName": "Massekabel, Vergaser",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Vergaser",
            "UsageName": "Massekabel"
        },
        "01952": {
            "GenartName": "Membrandeckel, Vergaser",
            "NormTermName": "Deckel",
            "AssGrpName": "Vergaser",
            "UsageName": "Membran"
        },
        "01953": {
            "GenartName": "Membran, Vergaser",
            "NormTermName": "Membran",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01954": {
            "GenartName": "Mitnehmerhebel, Vergaser",
            "NormTermName": "Hebel",
            "AssGrpName": "Vergaser",
            "UsageName": "Mitnehmerhebel"
        },
        "01955": {
            "GenartName": "Nadelsteuerung, Vergaser",
            "NormTermName": "Nadelsteuerung",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01956": {
            "GenartName": "Beheizung, Vergaser",
            "NormTermName": "Heizung",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01957": {
            "GenartName": "Pulldowndose, Vergaser",
            "NormTermName": "Unterdruckdose",
            "AssGrpName": "Vergaser",
            "UsageName": "Pulldown"
        },
        "01958": {
            "GenartName": "Pulldownmembran, Vergaser",
            "NormTermName": "Membran",
            "AssGrpName": "Vergaser",
            "UsageName": "Pulldown"
        },
        "01959": {
            "GenartName": "Beschleunigerpumpe, Vergaser",
            "NormTermName": "Pumpe",
            "AssGrpName": "Vergaser",
            "UsageName": "Beschleunigerpumpe"
        },
        "01960": {
            "GenartName": "Deckel, Beschleunigerpumpe",
            "NormTermName": "Deckel",
            "AssGrpName": "Vergaser",
            "UsageName": "Beschleunigerpumpe"
        },
        "01961": {
            "GenartName": "Hebel, Beschleunigerpumpe",
            "NormTermName": "Hebel",
            "AssGrpName": "Vergaser",
            "UsageName": "Beschleunigerpumpe"
        },
        "01962": {
            "GenartName": "Kolben, Beschleunigerpumpe",
            "NormTermName": "Kolben",
            "AssGrpName": "Vergaser",
            "UsageName": "Beschleunigerpumpe"
        },
        "01963": {
            "GenartName": "Spritzrohr, Beschleunigerpumpe",
            "NormTermName": "Spritzrohr",
            "AssGrpName": "Vergaser",
            "UsageName": "Beschleunigerpumpe"
        },
        "01964": {
            "GenartName": "Verbindungsstange, Beschleunigerpumpe",
            "NormTermName": "Stange",
            "AssGrpName": "Vergaser",
            "UsageName": "Beschleunigerpumpe"
        },
        "01965": {
            "GenartName": "Regulierschraube, Vergaser",
            "NormTermName": "Schraube",
            "AssGrpName": "Vergaser",
            "UsageName": "Regulierschraube"
        },
        "01966": {
            "GenartName": "Rohr, Vergaser",
            "NormTermName": "Rohr",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01967": {
            "GenartName": "Schwimmer, Vergaser",
            "NormTermName": "Schwimmer",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01968": {
            "GenartName": "Reparatursatz, Schwimmernadelventil",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Vergaser",
            "UsageName": "Schwimmernadel"
        },
        "01969": {
            "GenartName": "Schwinge, Vergaser",
            "NormTermName": "Schwinge",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01970": {
            "GenartName": "Sieb, Vergaser",
            "NormTermName": "Sieb",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01971": {
            "GenartName": "Starterdeckel, Vergaser",
            "NormTermName": "Deckel",
            "AssGrpName": "Vergaser",
            "UsageName": "Starter"
        },
        "01972": {
            "GenartName": "Startergehäuse, Vergaser",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01974": {
            "GenartName": "Starterhebel, Vergaser",
            "NormTermName": "Hebel",
            "AssGrpName": "Vergaser",
            "UsageName": "Starter"
        },
        "01975": {
            "GenartName": "Starterklappenhebel, Vergaser",
            "NormTermName": "Hebel",
            "AssGrpName": "Vergaser",
            "UsageName": "Starterklappe"
        },
        "01976": {
            "GenartName": "Starterklappenwelle, Vergaser",
            "NormTermName": "Welle",
            "AssGrpName": "Vergaser",
            "UsageName": "Starterklappe"
        },
        "01977": {
            "GenartName": "Starterkörper, Vergaser",
            "NormTermName": "Starterkörper",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01978": {
            "GenartName": "Startersatz, Vergaser",
            "NormTermName": "Startersatz",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01979": {
            "GenartName": "Verbindungsstange, Starter",
            "NormTermName": "Stange",
            "AssGrpName": "Vergaser",
            "UsageName": "Starter"
        },
        "01980": {
            "GenartName": "Stopfen, Vergaser",
            "NormTermName": "Deckel",
            "AssGrpName": "Vergaser",
            "UsageName": "Stopfen"
        },
        "01981": {
            "GenartName": "Unterdruckregler, Vergaser",
            "NormTermName": "Regler",
            "AssGrpName": "Vergaser",
            "UsageName": "Unterdruck"
        },
        "01982": {
            "GenartName": "Verbindungsstange, Vergaser",
            "NormTermName": "Stange",
            "AssGrpName": "Vergaser",
            "UsageName": null
        },
        "01983": {
            "GenartName": "Verschlussschraube, Vergaser",
            "NormTermName": "Schraube",
            "AssGrpName": "Vergaser",
            "UsageName": "Verschluss"
        },
        "01984": {
            "GenartName": "Vordrosselsteller, Vergaser",
            "NormTermName": "Stellelement",
            "AssGrpName": "Vergaser",
            "UsageName": "Vordrossel"
        },
        "01986": {
            "GenartName": "Halter, Vordrosselsteller",
            "NormTermName": "Halter",
            "AssGrpName": "Vergaser",
            "UsageName": "Vordrossel"
        },
        "01987": {
            "GenartName": "Zusatzstarter, Vergaser",
            "NormTermName": "Starter",
            "AssGrpName": "Vergaser",
            "UsageName": "Zusatzstarter"
        },
        "01988": {
            "GenartName": "Vorzerstäuber, Vergaser",
            "NormTermName": "Zerstäuber",
            "AssGrpName": "Vergaser",
            "UsageName": "Vorzerstäuber"
        },
        "01989": {
            "GenartName": "Anschlussstutzen, Kühlmittelleitung",
            "NormTermName": "Stutzen",
            "AssGrpName": "Vergaser",
            "UsageName": "Kühlmittel"
        },
        "01990": {
            "GenartName": "Winkelrohr, Vergaser",
            "NormTermName": "Rohr",
            "AssGrpName": "Vergaser",
            "UsageName": "Winkel"
        },
        "01991": {
            "GenartName": "Zugfeder, Vergaser",
            "NormTermName": "Feder",
            "AssGrpName": "Vergaser",
            "UsageName": "Zugfeder"
        },
        "01992": {
            "GenartName": "Zwischenflansch, Vergaser",
            "NormTermName": "Flansch",
            "AssGrpName": "Vergaser",
            "UsageName": "Zwischenflansch"
        },
        "01993": {
            "GenartName": "Steuergerät, Leerlaufregelung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Leerlaufregelung"
        },
        "01995": {
            "GenartName": "ABS-Prüfgerät",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "ABS"
        },
        "01996": {
            "GenartName": "Prüfgerät, Pneumatische Regeleinrichtungen",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Pneumatische Regeleinrichtungen"
        },
        "01997": {
            "GenartName": "Prüfgerät, Motor",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Motor"
        },
        "01998": {
            "GenartName": "Eigendiagnose-Gerät",
            "NormTermName": "Diagnosegerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "01999": {
            "GenartName": "Prüfgerät, Lambdaregelung",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "02000": {
            "GenartName": "Prüfgerät, Zündanlage",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Zündanlage"
        },
        "02001": {
            "GenartName": "Multimeter",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Multimeter"
        },
        "02002": {
            "GenartName": "Prüflampe, Zündzeitpunkt",
            "NormTermName": "Prüfleuchte",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Zündzeitpunkt"
        },
        "02003": {
            "GenartName": "Prüfgerät, Druck/Unterdruck",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Druck/Unterdruck"
        },
        "02004": {
            "GenartName": "Prüfgerät, Druckverlust",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Druckverlust"
        },
        "02005": {
            "GenartName": "Drehzahlsensor, Motortestgeräte",
            "NormTermName": "Sensor",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Drehzahl"
        },
        "02006": {
            "GenartName": "CO-Messgerät",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "CO"
        },
        "02007": {
            "GenartName": "Abgasmessgerät",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Abgas"
        },
        "02008": {
            "GenartName": "Abgasdiagnosegerät",
            "NormTermName": "Diagnosegerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Abgas"
        },
        "02009": {
            "GenartName": "Bremsprüfstand",
            "NormTermName": "Prüfstand",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremse"
        },
        "02010": {
            "GenartName": "Software, Sicherheitsprüfung §29",
            "NormTermName": "Software",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Sicherheitsprüfung §29"
        },
        "02011": {
            "GenartName": "Messgerät, Pedalkraft",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Pedalkraft"
        },
        "02012": {
            "GenartName": "Prüfgerät, Bremsdruck",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsdruck"
        },
        "02013": {
            "GenartName": "Füll-/Entlüftungsgerät, Bremshydraulik",
            "NormTermName": "Entlüftungsgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremshydraulik"
        },
        "02014": {
            "GenartName": "Auffangbehälter, Bremssystementlüftung",
            "NormTermName": "Behälter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsflüssigkeit"
        },
        "02015": {
            "GenartName": "Leistungsprüfstand",
            "NormTermName": "Prüfstand",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Leistung"
        },
        "02016": {
            "GenartName": "Prüfstraße",
            "NormTermName": "Prüfstraße",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "02017": {
            "GenartName": "Prüfgerät, Achsgeometrie",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Achsgeometrie"
        },
        "02018": {
            "GenartName": "Prüfstand, Starter/Generator",
            "NormTermName": "Prüfstand",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Starter/Generator"
        },
        "02019": {
            "GenartName": "Spannungs-/Strom-Messgerät",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Spannung/Strom"
        },
        "02020": {
            "GenartName": "Prüfgerät, Drehstromgeneratoren",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Drehstromgenerator"
        },
        "02021": {
            "GenartName": "Batterieladegerät",
            "NormTermName": "Ladegerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Batterie"
        },
        "02022": {
            "GenartName": "Prüfgerät, Batterie",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Batterie"
        },
        "02023": {
            "GenartName": "Prüfstand, Diesel-Einspritzpumpe",
            "NormTermName": "Prüfstand",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Einspritzpumpe"
        },
        "02024": {
            "GenartName": "Messgerät, Dieseleinspritzmengen",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Einspritzdüse"
        },
        "02025": {
            "GenartName": "Absaugvorrichtung, Einspritzdüsenprüfgerät",
            "NormTermName": "Absauggerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Einspritzdüsenprüfgerät"
        },
        "02026": {
            "GenartName": "Prüfgerät, Einspritzdüsen",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Einspritzdüsenprüfgerät"
        },
        "02027": {
            "GenartName": "Starthilfegerät",
            "NormTermName": "Starthilfe",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Starthilfe"
        },
        "02028": {
            "GenartName": "Service-Gerät, Klimaanlage",
            "NormTermName": "Service-Gerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Klimaanlage"
        },
        "02031": {
            "GenartName": "Cassetten-Radio",
            "NormTermName": "Autoradio",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Radio-Cassette"
        },
        "02032": {
            "GenartName": "CD-Radio",
            "NormTermName": "Autoradio",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Radio-CD"
        },
        "02033": {
            "GenartName": "Minidisc-Radio",
            "NormTermName": "Autoradio",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Radio-Minidisc"
        },
        "02034": {
            "GenartName": "Radio/CD-Wechsler-Kombination",
            "NormTermName": "Radio/CD-Wechsler-Kombination",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": null
        },
        "02035": {
            "GenartName": "Minidisc-Radio/CD-Wechsler-Kombination",
            "NormTermName": "Radio/CD-Wechsler-Kombination",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Radio-Minidisc"
        },
        "02036": {
            "GenartName": "Radio/Navigationssystem-Kombination",
            "NormTermName": "Radio/Navigationssystem-Kombination",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": null
        },
        "02037": {
            "GenartName": "Lautsprechersystem",
            "NormTermName": "Lautsprechersystem",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": null
        },
        "02038": {
            "GenartName": "Audio-Verstärker",
            "NormTermName": "Verstärker",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Audio-Verstärker"
        },
        "02039": {
            "GenartName": "Navigationssystem",
            "NormTermName": "Navigationssystem",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": null
        },
        "02040": {
            "GenartName": "Software, Navigationssystem",
            "NormTermName": "Software",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Navigationssystem"
        },
        "02041": {
            "GenartName": "CD-ROM-Strassenkarte",
            "NormTermName": "Software",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "CD-ROM-Strassenkarte"
        },
        "02044": {
            "GenartName": "Ventil, ABS-Regelung",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "ABS-Regelung"
        },
        "02045": {
            "GenartName": "Überdruckventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Überdrucksicherung"
        },
        "02047": {
            "GenartName": "Steuergerät, Lufteinblasung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lufteinblasung"
        },
        "02048": {
            "GenartName": "Dichtring",
            "NormTermName": "Dichtring",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "02049": {
            "GenartName": "Feder",
            "NormTermName": "Feder",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "02050": {
            "GenartName": "Software, Systemdiagnose",
            "NormTermName": "Software",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Systemdiagnose"
        },
        "02051": {
            "GenartName": "Dichtringsatz",
            "NormTermName": "Dichtringsatz",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "02052": {
            "GenartName": "Ventil, Liftachssteuerung",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Liftachssteuerung"
        },
        "02053": {
            "GenartName": "Dichtring-/Federnsatz",
            "NormTermName": "Dichtring-/Federnsatz",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "02054": {
            "GenartName": "Sensor, Außentemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Außentemperatur"
        },
        "02055": {
            "GenartName": "Sensor, Kühlmitteltemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kühlmitteltemperatur"
        },
        "02056": {
            "GenartName": "Schalldämmungsatz, Motorraum",
            "NormTermName": "Schalldämmungsatz",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "02057": {
            "GenartName": "Türdichtung",
            "NormTermName": "Dichtung",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "02058": {
            "GenartName": "Proportionalventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "ASR/Geschwindigkeitsbegrenzung"
        },
        "02060": {
            "GenartName": "Gebläseschalter, Heizung/Lüftung",
            "NormTermName": "Schalter",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumgebläse"
        },
        "02061": {
            "GenartName": "Unterdruckdose, Zündverteiler",
            "NormTermName": "Unterdruckdose",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteiler"
        },
        "02062": {
            "GenartName": "Pedalwegsensor, Bremspedal",
            "NormTermName": "Sensor",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremspedal"
        },
        "02063": {
            "GenartName": "Fensterkurbel",
            "NormTermName": "Kurbel",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "02064": {
            "GenartName": "Achsschraube, Antriebswelle",
            "NormTermName": "Schraube",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "02065": {
            "GenartName": "Mutter, Achsstummel",
            "NormTermName": "Mutter",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsstummel"
        },
        "02066": {
            "GenartName": "Winkelgelenk, Lenkschubstange",
            "NormTermName": "Gelenk",
            "AssGrpName": "Lenkung",
            "UsageName": "Schubstange"
        },
        "02067": {
            "GenartName": "Reparatursatz, Stabilisatorkoppelstange",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisatorkoppelstange"
        },
        "02068": {
            "GenartName": "Lagerung, Stabilisatorkoppelstange",
            "NormTermName": "Lagerung",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisatorkoppelstange"
        },
        "02069": {
            "GenartName": "Halter, Stabilisatorlagerung",
            "NormTermName": "Halter",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisatorlagerung"
        },
        "02070": {
            "GenartName": "Radschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Räder",
            "UsageName": null
        },
        "02072": {
            "GenartName": "Ventil, Elektronische Niveauregulierung",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Elektronische Niveauregulierung"
        },
        "02073": {
            "GenartName": "Kühlmittelregelventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Kühlmittel"
        },
        "02074": {
            "GenartName": "Drucksicherungsventil, Anhänger",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Anhängerbremsdruck"
        },
        "02075": {
            "GenartName": "Redundanzventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Redundanz"
        },
        "02076": {
            "GenartName": "Anhängermodulator",
            "NormTermName": "Druckmodulator",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Anhängerbremsdruck"
        },
        "02077": {
            "GenartName": "Achsmodulator",
            "NormTermName": "Druckmodulator",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Bremsdruck"
        },
        "02078": {
            "GenartName": "Manometer",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Manometer"
        },
        "02079": {
            "GenartName": "Frostschutzmittel, Druckluftanlage",
            "NormTermName": "Frostschutz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Druckluftanlage"
        },
        "02080": {
            "GenartName": "Schnellkupplung",
            "NormTermName": "Kupplung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Bremsleitung"
        },
        "02081": {
            "GenartName": "Prüfgerät",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "ATC/ABS/ASR/EBS/ECAS"
        },
        "02082": {
            "GenartName": "Bremsschlauch, Druckluftanlage",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Bremsschlauch"
        },
        "02083": {
            "GenartName": "Prüfschlauch, Druckluftanlage",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Prüfschlauch"
        },
        "02085": {
            "GenartName": "Stellelement, Mischklappe",
            "NormTermName": "Stellelement",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Mischklappe"
        },
        "02086": {
            "GenartName": "Lampenträger, Begrenzungsleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Begrenzungsleuchte"
        },
        "02089": {
            "GenartName": "Scheinwerferaufnahme, Beleuchtung",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Scheinwerfer"
        },
        "02092": {
            "GenartName": "Lüfterrad, Motorkühlung",
            "NormTermName": "Lüfterrad",
            "AssGrpName": "Kühlung",
            "UsageName": null
        },
        "02094": {
            "GenartName": "Hochdruckleitung, Klimaanlage",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Hochdruck"
        },
        "02095": {
            "GenartName": "Niederdruckleitung, Klimaanlage",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Niederdruck"
        },
        "02096": {
            "GenartName": "Hochdruck-/Niederdruckleitung, Klimaanlage",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Hochdruck/Niederdruck"
        },
        "02101": {
            "GenartName": "Luftaufbereitungseinheit",
            "NormTermName": "Luftaufbereitungseinheit",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Lufttrocknung/Drucksicherung"
        },
        "02103": {
            "GenartName": "Löseventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Lösen"
        },
        "02105": {
            "GenartName": "Relaisventil, EBS",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Elektronisches Bremssystem"
        },
        "02108": {
            "GenartName": "Schwingungstilger, Gelenkwelle",
            "NormTermName": "Schwingungstilger",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwelle"
        },
        "02109": {
            "GenartName": "Lager, Gelenkwellenmittellager",
            "NormTermName": "Lager",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwellenmittellager"
        },
        "02110": {
            "GenartName": "Lager",
            "NormTermName": "Lager",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "02111": {
            "GenartName": "Wellendichtring, Gelenkwellenmittellager",
            "NormTermName": "Dichtring",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwellenmittellager"
        },
        "02112": {
            "GenartName": "Abdeckring, Gelenkwellenmittellager",
            "NormTermName": "Abdeckring",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwellenmittellager"
        },
        "02113": {
            "GenartName": "Schutzdeckel, Gelenkwellenmittellager",
            "NormTermName": "Deckel",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwellenmittellager"
        },
        "02114": {
            "GenartName": "Dichtung, Kurbelgehäuseentlüftung",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "02115": {
            "GenartName": "Zubehörsatz, Kipphebel",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Kipphebel"
        },
        "02117": {
            "GenartName": "Kolben, Steuerkettenspanner",
            "NormTermName": "Kolben",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkettenspanner"
        },
        "02118": {
            "GenartName": "Zahnrad, Steuerkettenumlenkung",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkettenumlenkung"
        },
        "02119": {
            "GenartName": "Reparatursatz, Ölwanne",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölwanne"
        },
        "02122": {
            "GenartName": "Sicherungsclip",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Normteile",
            "UsageName": "Sicherungsclip"
        },
        "02123": {
            "GenartName": "Distanzhülse",
            "NormTermName": "Hülse",
            "AssGrpName": "Normteile",
            "UsageName": "Abstandhaltung"
        },
        "02124": {
            "GenartName": "Anbauteilesatz, Unfalldatenspeicher",
            "NormTermName": "Anbauteilesatz",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Unfalldatenspeicher"
        },
        "02126": {
            "GenartName": "Glühlampe, Positions-/Begrenzungsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Positions-/Begrenzungsleuchte"
        },
        "02127": {
            "GenartName": "Hülse",
            "NormTermName": "Hülse",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "02128": {
            "GenartName": "Verschluss-/Schutzkappe",
            "NormTermName": "Kappe",
            "AssGrpName": "Normteile",
            "UsageName": "Verschluss"
        },
        "02130": {
            "GenartName": "Aufbewahrungstasche, abnehmbare Kugelstange",
            "NormTermName": "Tasche",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Kugelstangenaufbewahrung"
        },
        "02131": {
            "GenartName": "Zwischenwelle",
            "NormTermName": "Welle",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Zwischenwelle"
        },
        "02132": {
            "GenartName": "Lagerung, Kühler",
            "NormTermName": "Lagerung",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühler"
        },
        "02133": {
            "GenartName": "Dichtring, Thermoschalter",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kühlung",
            "UsageName": "Thermoschalter"
        },
        "02134": {
            "GenartName": "Temperaturschalter, Startautomatik",
            "NormTermName": "Schalter",
            "AssGrpName": "Vergaser",
            "UsageName": "Startautomatik"
        },
        "02135": {
            "GenartName": "Reparatursatz, Wasserpumpenentlüftung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpenentlüftung"
        },
        "02136": {
            "GenartName": "Temperaturschalter, Saugrohrvorwärmung",
            "NormTermName": "Schalter",
            "AssGrpName": "Vergaser",
            "UsageName": "Saugrohrvorwärmung"
        },
        "02137": {
            "GenartName": "Dichtung, Steuerkettenspanner",
            "NormTermName": "Dichtung",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkettenspanner"
        },
        "02138": {
            "GenartName": "Spannrolle, Keilriemen",
            "NormTermName": "Rolle",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilriemenspannung"
        },
        "02139": {
            "GenartName": "Rotor, Ölpumpe",
            "NormTermName": "Rotor",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "02140": {
            "GenartName": "Hülse, Ölpumpenrotor",
            "NormTermName": "Hülse",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpenrotor"
        },
        "02142": {
            "GenartName": "Deckel, Spannrollenhebel",
            "NormTermName": "Deckel",
            "AssGrpName": "Riementrieb",
            "UsageName": "Spannrollenhebel"
        },
        "02143": {
            "GenartName": "Lager, Spannrollenhebel",
            "NormTermName": "Lager",
            "AssGrpName": "Riementrieb",
            "UsageName": "Spannrollenhebel"
        },
        "02144": {
            "GenartName": "Lagerzapfen, Spannrollenhebel",
            "NormTermName": "Lagerzapfen",
            "AssGrpName": "Riementrieb",
            "UsageName": "Spannrollenhebel"
        },
        "02145": {
            "GenartName": "Wellendichtring, Ölpumpe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "02146": {
            "GenartName": "Haltefeder",
            "NormTermName": "Feder",
            "AssGrpName": "Normteile",
            "UsageName": "Haltefeder"
        },
        "02147": {
            "GenartName": "Schmierrohr, Nockenschmierung",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Schmierung",
            "UsageName": "Nockenschmierung"
        },
        "02148": {
            "GenartName": "Verschlussschraube, Kühlmittelleitung",
            "NormTermName": "Schraube",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelleitung"
        },
        "02149": {
            "GenartName": "Verschlussstopfen, Kühlmittelflansch",
            "NormTermName": "Verschluss",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelflansch"
        },
        "02151": {
            "GenartName": "Vorwiderstand, Kraftstoffpumpe",
            "NormTermName": "Widerstand",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "02152": {
            "GenartName": "Geber-/Nehmerzylindersatz, Kupplung",
            "NormTermName": "Zylindersatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Geber-/Nehmerzylinder"
        },
        "02153": {
            "GenartName": "Steuergerät, Gurtstraffer",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Gurtstraffer"
        },
        "02169": {
            "GenartName": "Führungsbolzen, Bremssattel",
            "NormTermName": "Bolzen",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattelführung"
        },
        "02170": {
            "GenartName": "Führungshülse, Bremssattel",
            "NormTermName": "Hülse",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattelführung"
        },
        "02176": {
            "GenartName": "Radbremszylindersatz",
            "NormTermName": "Zylindersatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "02177": {
            "GenartName": "Hydraulikpumpe, Fahrdynamikregelung",
            "NormTermName": "Pumpe",
            "AssGrpName": "Fahrdynamikregelung",
            "UsageName": null
        },
        "02178": {
            "GenartName": "Dichtung, Blinkleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinkleuchte"
        },
        "02179": {
            "GenartName": "Reflektor, Blinkleuchte",
            "NormTermName": "Reflektor",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinkleuchte"
        },
        "02180": {
            "GenartName": "Reflektor, Positions-/Begrenzungsleuchte",
            "NormTermName": "Reflektor",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Positions-/Begrenzungsleuchte"
        },
        "02181": {
            "GenartName": "Halter, Kohlebürsten",
            "NormTermName": "Halter",
            "AssGrpName": "Startanlage",
            "UsageName": "Kohlebürsten"
        },
        "02182": {
            "GenartName": "Freilaufgetriebe, Starter",
            "NormTermName": "Getriebe",
            "AssGrpName": "Startanlage",
            "UsageName": "Freilauf"
        },
        "02183": {
            "GenartName": "Lamellenkupplung, Starter",
            "NormTermName": "Kupplung",
            "AssGrpName": "Startanlage",
            "UsageName": "Starter"
        },
        "02184": {
            "GenartName": "Antriebslager, Starter",
            "NormTermName": "Lager",
            "AssGrpName": "Startanlage",
            "UsageName": "Antriebslager"
        },
        "02185": {
            "GenartName": "Kommutatorlager, Starter",
            "NormTermName": "Lager",
            "AssGrpName": "Startanlage",
            "UsageName": "Kommutator"
        },
        "02186": {
            "GenartName": "Erregerwicklung, Starter",
            "NormTermName": "Wicklung",
            "AssGrpName": "Startanlage",
            "UsageName": "Startererregung"
        },
        "02187": {
            "GenartName": "Anker, Starter",
            "NormTermName": "Anker",
            "AssGrpName": "Startanlage",
            "UsageName": null
        },
        "02188": {
            "GenartName": "Relais, Starter",
            "NormTermName": "Relais",
            "AssGrpName": "Startanlage",
            "UsageName": null
        },
        "02189": {
            "GenartName": "Halter, Kohlebürsten",
            "NormTermName": "Halter",
            "AssGrpName": "Generator",
            "UsageName": "Kohlebürsten"
        },
        "02190": {
            "GenartName": "Antriebslager, Generator",
            "NormTermName": "Lager",
            "AssGrpName": "Generator",
            "UsageName": "Antriebslager"
        },
        "02191": {
            "GenartName": "Schleifringlager, Generator",
            "NormTermName": "Lager",
            "AssGrpName": "Generator",
            "UsageName": "Schleifring"
        },
        "02192": {
            "GenartName": "Stator, Generator",
            "NormTermName": "Stator",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "02193": {
            "GenartName": "Läufer, Generator",
            "NormTermName": "Läufer",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "02194": {
            "GenartName": "Diodenträger, Generator",
            "NormTermName": "Diodenträger",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "02195": {
            "GenartName": "Sirene, Alarmanlage",
            "NormTermName": "Sirene",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Alarmanlage"
        },
        "02196": {
            "GenartName": "Sirene, Alarmanlage",
            "NormTermName": "Sirene",
            "AssGrpName": "Schließanlage",
            "UsageName": "Alarmanlage"
        },
        "02197": {
            "GenartName": "Schalter, Fahrstufe",
            "NormTermName": "Schalter",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Fahrstufe"
        },
        "02198": {
            "GenartName": "Schalter, Fahrstufe",
            "NormTermName": "Schalter",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Fahrstufe"
        },
        "02199": {
            "GenartName": "Potentiometer",
            "NormTermName": "Potentiometer",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "02200": {
            "GenartName": "Anzeige, Bremsdruck",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Bremsdruck"
        },
        "02201": {
            "GenartName": "Sensor, Öltemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Instrumente",
            "UsageName": "Öltemperatur"
        },
        "02202": {
            "GenartName": "Winkeltrieb, Tachowelle",
            "NormTermName": "Winkeltrieb",
            "AssGrpName": "Instrumente",
            "UsageName": "Tachometer"
        },
        "02203": {
            "GenartName": "Blinddeckel, Anzeige-Instrument",
            "NormTermName": "Deckel",
            "AssGrpName": "Instrumente",
            "UsageName": null
        },
        "02204": {
            "GenartName": "Cockpitgehäuse",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Instrumente",
            "UsageName": "Cockpit"
        },
        "02205": {
            "GenartName": "Signalgeber",
            "NormTermName": "Signalgeber",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "02206": {
            "GenartName": "Dichtung, Rahmen Hauptscheinwerfer",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerferrahmen"
        },
        "02207": {
            "GenartName": "Dichtung, Begrenzungsleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Begrenzungsleuchte"
        },
        "02212": {
            "GenartName": "Steuergerät, Automatische Kupplung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Kupplung",
            "UsageName": "Automatische Kupplung"
        },
        "02222": {
            "GenartName": "Ventil, Flammstartanlage",
            "NormTermName": "Ventil",
            "AssGrpName": "Glühanlage",
            "UsageName": "Flammstartanlage"
        },
        "02223": {
            "GenartName": "Isolator, Glühkerze",
            "NormTermName": "Isolator",
            "AssGrpName": "Glühanlage",
            "UsageName": "Glühkerze"
        },
        "02229": {
            "GenartName": "Ventil, Reifendruck-Kontrollsystem",
            "NormTermName": "Ventil",
            "AssGrpName": "Räder",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "02230": {
            "GenartName": "Steuergerät, Reifendruck-Kontrollsystem",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Räder",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "02231": {
            "GenartName": "Empfangsantenne, Reifendruck-Kontrollsystem",
            "NormTermName": "Antenne",
            "AssGrpName": "Räder",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "02232": {
            "GenartName": "Radsensor, Reifendruck-Kontrollsystem",
            "NormTermName": "Sensor",
            "AssGrpName": "Räder",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "02233": {
            "GenartName": "Verbindungsleitung, Reifendruck-Kontrollsystem",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Räder",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "02234": {
            "GenartName": "Lader, Aufladung",
            "NormTermName": "Lader",
            "AssGrpName": "Luftversorgung",
            "UsageName": null
        },
        "02235": {
            "GenartName": "Antriebsspindel, Feststellbremse Bremssattel",
            "NormTermName": "Spindel",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremse Bremssattel"
        },
        "02236": {
            "GenartName": "Halter, Bremsflüssigkeitsbehälter",
            "NormTermName": "Halter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsflüssigkeitsbehälter"
        },
        "02238": {
            "GenartName": "Auflaufbremse",
            "NormTermName": "Bremsgerätegruppe",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Auflaufbremse"
        },
        "02239": {
            "GenartName": "Adapter, Steckdose",
            "NormTermName": "Adapter",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Steckdose"
        },
        "02240": {
            "GenartName": "Steuergerät, Tür-Zuzieh-Hilfe",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Tür-Zuzieh-Hilfe"
        },
        "02241": {
            "GenartName": "Federsatz",
            "NormTermName": "Federsatz",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "02242": {
            "GenartName": "Ventileinheit, Hydraulikaggregat",
            "NormTermName": "Ventileinheit",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hydraulikaggregat"
        },
        "02243": {
            "GenartName": "Relaissatz, Nebelschlussleuchte",
            "NormTermName": "Relaissatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Nebelschlussleuchte"
        },
        "02245": {
            "GenartName": "Halter, Warnkontaktleitung",
            "NormTermName": "Halter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Warnkontaktleitung"
        },
        "02246": {
            "GenartName": "Führung, Warnkontaktleitung",
            "NormTermName": "Führung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Warnkontaktleitung"
        },
        "02247": {
            "GenartName": "Abdeckblech, Bremssattel",
            "NormTermName": "Blech",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "02248": {
            "GenartName": "Kolben, Bremssattel",
            "NormTermName": "Kolben",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "02249": {
            "GenartName": "Stange/Bolzen, Nehmerzylinder",
            "NormTermName": "Stange",
            "AssGrpName": "Kupplung",
            "UsageName": "Nehmerzylinder"
        },
        "02250": {
            "GenartName": "Stange/Bolzen, Geberzylinder",
            "NormTermName": "Stange",
            "AssGrpName": "Kupplung",
            "UsageName": "Geberzylinder"
        },
        "02251": {
            "GenartName": "Stange/Bolzen, Hauptbremszylinder",
            "NormTermName": "Stange",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hauptbremszylinder"
        },
        "02252": {
            "GenartName": "Stange/Bolzen, Radbremszylinder",
            "NormTermName": "Stange",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Radbremszylinder"
        },
        "02253": {
            "GenartName": "Spann-/Klemmhülse",
            "NormTermName": "Hülse",
            "AssGrpName": "Normteile",
            "UsageName": "Spann-/Klemm"
        },
        "02254": {
            "GenartName": "Sensorring, ABS",
            "NormTermName": "Sensorring",
            "AssGrpName": "Bremsanlage",
            "UsageName": "ABS"
        },
        "02256": {
            "GenartName": "Sortiment, Schrauben",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Schraube"
        },
        "02257": {
            "GenartName": "Verschraubungssortiment",
            "NormTermName": "Verschraubungssatz",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "02258": {
            "GenartName": "Anschlussstutzen, Schlauchleitung",
            "NormTermName": "Stutzen",
            "AssGrpName": "Normteile",
            "UsageName": "Schlauch"
        },
        "02259": {
            "GenartName": "Dicht-/Schutzstopfen",
            "NormTermName": "Stopfen",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "02260": {
            "GenartName": "Prüfanschluss, Rohrleitungen",
            "NormTermName": "Prüfanschluss",
            "AssGrpName": "Normteile",
            "UsageName": "Rohr"
        },
        "02261": {
            "GenartName": "Verbindungs-/Verteilerstück, Rohrleitungen",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Normteile",
            "UsageName": "Rohrleitung"
        },
        "02262": {
            "GenartName": "Relais, Hupe/Horn",
            "NormTermName": "Relais",
            "AssGrpName": "Signalanlage",
            "UsageName": "Hupe"
        },
        "02263": {
            "GenartName": "Hauptschalter, Batterie",
            "NormTermName": "Schalter",
            "AssGrpName": "Batterie",
            "UsageName": null
        },
        "02264": {
            "GenartName": "Schalter, Vorglühen",
            "NormTermName": "Schalter",
            "AssGrpName": "Glühanlage",
            "UsageName": "Glühzeit"
        },
        "02265": {
            "GenartName": "Einrückhebel, Starter",
            "NormTermName": "Hebel",
            "AssGrpName": "Startanlage",
            "UsageName": "Einrückhebel"
        },
        "02266": {
            "GenartName": "Reparatursatz, Starter",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Startanlage",
            "UsageName": null
        },
        "02267": {
            "GenartName": "Erregerwicklung, Generator",
            "NormTermName": "Wicklung",
            "AssGrpName": "Generator",
            "UsageName": "Magnetfeld"
        },
        "02268": {
            "GenartName": "Schutzkappe, Generator",
            "NormTermName": "Kappe",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "02269": {
            "GenartName": "Reparatursatz, Generator",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "02272": {
            "GenartName": "Drucksensor, Kraftstoffbehälter",
            "NormTermName": "Sensor",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffbehälter"
        },
        "02273": {
            "GenartName": "Elektromotor",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "02275": {
            "GenartName": "Regensensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Regensensor"
        },
        "02276": {
            "GenartName": "Regensensor-Nachrüstsatz",
            "NormTermName": "Sensorsatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Regensensor"
        },
        "02277": {
            "GenartName": "Steuergerät, Regensensor",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Regensensor"
        },
        "02278": {
            "GenartName": "Leitungssatz, Regensensor",
            "NormTermName": "Elektroleitungssatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Regensensor"
        },
        "02279": {
            "GenartName": "Abdeckkappe, Regensensor",
            "NormTermName": "Kappe",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Regensensor"
        },
        "02280": {
            "GenartName": "Temperaturschalter, Abgasrückführung",
            "NormTermName": "Schalter",
            "AssGrpName": "Abgasrückführung",
            "UsageName": null
        },
        "02281": {
            "GenartName": "Anschlussleitung, Unfalldatenspeicher",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Unfalldatenspeicher"
        },
        "02282": {
            "GenartName": "Reparatursatz, Hydraulikaggregat",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hydraulikaggregat"
        },
        "02283": {
            "GenartName": "Reparatursatz, Zündverteiler",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteiler"
        },
        "02284": {
            "GenartName": "Zündspuleneinheit",
            "NormTermName": "Zündspuleneinheit",
            "AssGrpName": "Zündanlage",
            "UsageName": null
        },
        "02285": {
            "GenartName": "Staubschutzkappe, Zündverteiler",
            "NormTermName": "Kappe",
            "AssGrpName": "Zündanlage",
            "UsageName": "Staubkappe"
        },
        "02286": {
            "GenartName": "Abschirmhaube, Zündverteiler",
            "NormTermName": "Haube",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteiler"
        },
        "02288": {
            "GenartName": "Schutzkappe, Zündkerzenstecker",
            "NormTermName": "Kappe",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündkerzenstecker"
        },
        "02289": {
            "GenartName": "Schutzkappe, Zündspulenstecker",
            "NormTermName": "Kappe",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündspulenstecker"
        },
        "02290": {
            "GenartName": "Schutzkappe, Zündverteilerstecker",
            "NormTermName": "Kappe",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteilerstecker"
        },
        "02291": {
            "GenartName": "Magnetzünder",
            "NormTermName": "Magnetzünder",
            "AssGrpName": null,
            "UsageName": null
        },
        "02292": {
            "GenartName": "Kontaktsatz, Magnetzünder",
            "NormTermName": "Kontaktsatz",
            "AssGrpName": null,
            "UsageName": "Magnetzünder"
        },
        "02293": {
            "GenartName": "Kondensator, Magnetzünder",
            "NormTermName": "Kondensator",
            "AssGrpName": null,
            "UsageName": "Magnetzünder"
        },
        "02294": {
            "GenartName": "Schaltgerät, Magnetzünder",
            "NormTermName": "Schaltgerät",
            "AssGrpName": null,
            "UsageName": "Magnetzünder"
        },
        "02295": {
            "GenartName": "Steuergerät, EPS",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "EPS"
        },
        "02296": {
            "GenartName": "Feder, Bremssattel",
            "NormTermName": "Feder",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "02297": {
            "GenartName": "Anschlagscheibe, Hauptbremszylinder",
            "NormTermName": "Anschlagscheibe",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hauptbremszylinder"
        },
        "02298": {
            "GenartName": "Ventilkolben, Bremskraftverstärker",
            "NormTermName": "Ventilkolben",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremskraftverstärker"
        },
        "02299": {
            "GenartName": "Manschette, Hauptbremszylinder",
            "NormTermName": "Manschette",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hauptbremszylinder"
        },
        "02300": {
            "GenartName": "Manschette, Radbremszylinder",
            "NormTermName": "Manschette",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Radbremszylinder"
        },
        "02301": {
            "GenartName": "Manschette, Geberzylinder",
            "NormTermName": "Manschette",
            "AssGrpName": "Kupplung",
            "UsageName": "Geberzylinder"
        },
        "02302": {
            "GenartName": "Manschette, Nehmerzylinder",
            "NormTermName": "Manschette",
            "AssGrpName": "Kupplung",
            "UsageName": "Nehmerzylinder"
        },
        "02303": {
            "GenartName": "Unterdruckleitung, Bremskraftverstärker",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremskraftverstärker"
        },
        "02304": {
            "GenartName": "Federring",
            "NormTermName": "Federring",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "02305": {
            "GenartName": "Stopfen, Bremsflüssigkeitsbehälter",
            "NormTermName": "Stopfen",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsflüssigkeitsbehälter"
        },
        "02306": {
            "GenartName": "Anschlussstutzen, Unterdruckleitung",
            "NormTermName": "Stutzen",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremskraftverstärker"
        },
        "02307": {
            "GenartName": "Dichtung, Unterdruck-Anschlussstutzen BKV",
            "NormTermName": "Dichtung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruck-Anschlussstutzen"
        },
        "02308": {
            "GenartName": "Druckschalter, Bremshydraulik",
            "NormTermName": "Schalter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsdruck"
        },
        "02309": {
            "GenartName": "Ventil, Unterdruckleitung",
            "NormTermName": "Ventil",
            "AssGrpName": "Normteile",
            "UsageName": "Unterdruckleitung"
        },
        "02310": {
            "GenartName": "Überwurfmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Überwurf"
        },
        "02311": {
            "GenartName": "Überwurfschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Überwurf"
        },
        "02312": {
            "GenartName": "Verschlussschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Verschluss"
        },
        "02313": {
            "GenartName": "Hohlschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Hohlschraube"
        },
        "02314": {
            "GenartName": "Entlüfterschraube/-ventil",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Entlüftung"
        },
        "02315": {
            "GenartName": "Adapter, Bremsleitung",
            "NormTermName": "Adapter",
            "AssGrpName": "Normteile",
            "UsageName": "Bremsleitung"
        },
        "02316": {
            "GenartName": "Ringstutzen, Rohrleitung",
            "NormTermName": "Stutzen",
            "AssGrpName": "Normteile",
            "UsageName": "Ringstutzen"
        },
        "02317": {
            "GenartName": "Kleinteile-Magazin",
            "NormTermName": "Magazin",
            "AssGrpName": "Normteile",
            "UsageName": "Kleinteile"
        },
        "02318": {
            "GenartName": "Sortiment, Armaturen",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Armaturen"
        },
        "02319": {
            "GenartName": "Sortiment, Entlüfterschrauben",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Entlüfterschrauben"
        },
        "02320": {
            "GenartName": "Sortiment, Befestigungselemente",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Befestigungselemente"
        },
        "02321": {
            "GenartName": "Konservierungsmittel, Bremsenteile",
            "NormTermName": "Konservierungsmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Bremse"
        },
        "02322": {
            "GenartName": "Wasserkasten, Kühler",
            "NormTermName": "Kasten",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühler"
        },
        "02323": {
            "GenartName": "Lüfterhaube",
            "NormTermName": "Haube",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühler"
        },
        "02325": {
            "GenartName": "Prüfgerät, Bremsflüssigkeit",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsflüssigkeit"
        },
        "02326": {
            "GenartName": "Entnahme-Set, Bremsflüssigkeit",
            "NormTermName": "Entnahmesatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsflüssigkeit"
        },
        "02327": {
            "GenartName": "Prüfgerät, Bremsanlage",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsanlage"
        },
        "02328": {
            "GenartName": "Prüfset, hydraulische/pneumatische Druckprüfung",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "hydraulische/pneumatische Druckprüfung"
        },
        "02329": {
            "GenartName": "Verlängerungskabel, Drucksensor",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Drucksensorverlängerungskabel"
        },
        "02330": {
            "GenartName": "Kupplung, Prüfanschluss",
            "NormTermName": "Kupplung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfanschluss"
        },
        "02331": {
            "GenartName": "Adapternippel, Entlüfterventil-Drucksensor",
            "NormTermName": "Nippel",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Adapternippel, Drucksensor"
        },
        "02332": {
            "GenartName": "Pedalfeststeller",
            "NormTermName": "Klemmwerkzeug",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Brems-/Kupplungspedal"
        },
        "02333": {
            "GenartName": "Prüfset, Bremsscheibenschlagprüfung",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsscheibenschlagprüfung"
        },
        "02334": {
            "GenartName": "Elektroleitung, Zigarettenanzünderanschluss",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Anschluss Zigarettenanzünder"
        },
        "02335": {
            "GenartName": "Prüfset, Siedepunktprüfung",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsflüssigkeits-Siedepunktprüfung"
        },
        "02336": {
            "GenartName": "Prüfgerät, Hydraulik-/Pneumatik Druck",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "hydraulische/pneumatische Druckprüfung"
        },
        "02337": {
            "GenartName": "Verlängerungsschlauch, Druckprüfgerät",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Druckprüfgerät"
        },
        "02338": {
            "GenartName": "Messvorrichtung, Bremsscheibenschlag",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsscheibenschlag"
        },
        "02339": {
            "GenartName": "Saugheber, Bremsflüssigkeit",
            "NormTermName": "Saugheber",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsflüssigkeitsabsaugung"
        },
        "02340": {
            "GenartName": "Absaugbalg, Bremsflüssigkeit",
            "NormTermName": "Balg",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsflüssigkeitsabsaugung"
        },
        "02341": {
            "GenartName": "Entlüftungsschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremssystementlüftung"
        },
        "02342": {
            "GenartName": "Anschlussschlauch, Entlüftungsgerät-Entlüfterventil",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Anschluss Entlüftungsgerät-Entlüfterventil"
        },
        "02343": {
            "GenartName": "Füllpistole, Bremsflüssigkeit",
            "NormTermName": "Füllpistole",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsflüssigkeit"
        },
        "02344": {
            "GenartName": "Ausgiesser, Bremsflüssigkeit",
            "NormTermName": "Ausgiesser",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsflüssigkeit"
        },
        "02345": {
            "GenartName": "Entlüfterstutzen-Set",
            "NormTermName": "Stutzensatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Anschluß Entlüftungsgerät-Ausgleichsbehälter"
        },
        "02346": {
            "GenartName": "Ring-/Gabelschlüsselsatz",
            "NormTermName": "Ring-/Gabelschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Entlüfterschraube/-ventil"
        },
        "02347": {
            "GenartName": "Doppel-Ringschlüssel, Entlüfterschraube/-ventil",
            "NormTermName": "Doppel-Ringschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Entlüfterschraube/-ventil"
        },
        "02348": {
            "GenartName": "Abfüllhahn, Bremsflüssigkeit",
            "NormTermName": "Ventil",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsflüssigkeitsabfüllung"
        },
        "02349": {
            "GenartName": "Entsorgungsgerät, Bremsflüssigkeit",
            "NormTermName": "Entsorgungsgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsflüssigkeit"
        },
        "02350": {
            "GenartName": "Anschlussrohr,Auffangbehälter-Entsorgungsgerät",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Anschluss Auffangbehälter-Entsorgungsgerät"
        },
        "02351": {
            "GenartName": "Splint-Treibersatz, Bremsbelag-Haltestift",
            "NormTermName": "Splint-Treibersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsbelag-Haltestift"
        },
        "02352": {
            "GenartName": "Bremsflüssigkeits-Sammelbehälter",
            "NormTermName": "Behälter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsflüssigkeits-Sammelbehälter"
        },
        "02353": {
            "GenartName": "Schlagauszieher, Bremsbelag",
            "NormTermName": "Schlagauszieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsbelag"
        },
        "02354": {
            "GenartName": "Auszieharm, Schlagauszieher",
            "NormTermName": "Auszieharm",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagauszieher"
        },
        "02355": {
            "GenartName": "Rückstellwerkzeug, Bremssattelkolben",
            "NormTermName": "Rückstellwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kolbenrückstellung"
        },
        "02356": {
            "GenartName": "Dreh-/Rückstellwerkzeug, Bremssattelkolben",
            "NormTermName": "Dreh-/Rückstellwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kolbenrückdrehung"
        },
        "02357": {
            "GenartName": "Dreh-/Rückstellwerkzeugsatz, Bremssattelkolben",
            "NormTermName": "Dreh-/Rückstellwerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kolbenrückdrehung"
        },
        "02358": {
            "GenartName": "Feile, Bremsbelagschacht-Reinigung",
            "NormTermName": "Feile",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsbelagschacht-Reinigung"
        },
        "02359": {
            "GenartName": "Drahtbürste, Bremssattelreinigung",
            "NormTermName": "Drahtbürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremssattelreinigung"
        },
        "02360": {
            "GenartName": "Schleifpad",
            "NormTermName": "Schleifpad",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "02361": {
            "GenartName": "Spanndorn, Schleifpads",
            "NormTermName": "Spanndorn",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schleif-Pad-Aufnahme"
        },
        "02362": {
            "GenartName": "Messschieber, Bremsscheibendicke",
            "NormTermName": "Messschieber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsscheibendicke"
        },
        "02363": {
            "GenartName": "Kolbendreh-Zange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kolbendrehung"
        },
        "02364": {
            "GenartName": "20°-Kolbenlehre",
            "NormTermName": "Lehre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "20 Grad Kolbeneinstellung"
        },
        "02365": {
            "GenartName": "Halter, Bremsscheibenschlag-Messuhr",
            "NormTermName": "Halter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsscheibenschlag-Messuhr"
        },
        "02366": {
            "GenartName": "Messuhr",
            "NormTermName": "Messuhr",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "02367": {
            "GenartName": "Haarlineal, Ebenheits-Prüfung",
            "NormTermName": "Lineal",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ebenheits-Prüfung"
        },
        "02368": {
            "GenartName": "Steckschlüsseleinsatz",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "02369": {
            "GenartName": "Bremssattel-Schlüssel",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremssattelbefestigungs-Schrauben"
        },
        "02370": {
            "GenartName": "Führungshülsenauszieher",
            "NormTermName": "Auszieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Führungshülsen, Bremssattel"
        },
        "02371": {
            "GenartName": "Schutzkappen-Auftreiber",
            "NormTermName": "Auftreiber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Führungshülsen-Schutzkappe"
        },
        "02372": {
            "GenartName": "Schutzkappen-Eindrücker",
            "NormTermName": "Eindrücker",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Führungshülsen-Schutzkappe"
        },
        "02373": {
            "GenartName": "4-Kant-Schlüssel, Bremsbacken-Exzenter",
            "NormTermName": "4-Kant-Schlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsbacken-Exzenter"
        },
        "02374": {
            "GenartName": "Bremsfedern-Zange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsbacken-Feder"
        },
        "02375": {
            "GenartName": "Montagewerkzeug, Radbremszylinder",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Radbremszylinder"
        },
        "02376": {
            "GenartName": "Montagewerkzeug, Bremsbacken-Feder",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsbacken-Feder"
        },
        "02377": {
            "GenartName": "Trommelbrems-Montage-Set",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Trommelbremse"
        },
        "02378": {
            "GenartName": "Bremsseilfeder-Zange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Feststellbremsseil"
        },
        "02379": {
            "GenartName": "Rohrschneider",
            "NormTermName": "Rohrschneider",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsrohrleitung"
        },
        "02380": {
            "GenartName": "Rohrentgrater",
            "NormTermName": "Entgrater",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsrohrleitung"
        },
        "02381": {
            "GenartName": "Bördelgerät",
            "NormTermName": "Bördelgerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "02382": {
            "GenartName": "Rohrbiegewerkzeug",
            "NormTermName": "Rohrbiegewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "02383": {
            "GenartName": "Zange, Bremsrohr",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsrohrleitung"
        },
        "02384": {
            "GenartName": "Bremsleitungs-Schlüsselsatz",
            "NormTermName": "Doppel-Ringschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsrohrleitung"
        },
        "02385": {
            "GenartName": "Bremsschlauchklemme",
            "NormTermName": "Klemmwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsschlauch"
        },
        "02386": {
            "GenartName": "Paste, Brems-/Kupplungshydraulikteile",
            "NormTermName": "Konservierungsmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Bremshydraulik"
        },
        "02387": {
            "GenartName": "Lochtafel, Bremsen-Werkzeuge",
            "NormTermName": "Lochblechtafelsatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsen-Werkzeuge"
        },
        "02388": {
            "GenartName": "Hakensatz, Werkzeuglochtafel",
            "NormTermName": "Hakensatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Werkzeuglochtafel"
        },
        "02389": {
            "GenartName": "Spezialhaken, Werkzeuglochtafel",
            "NormTermName": "Haken",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Werkzeuglochtafel"
        },
        "02390": {
            "GenartName": "Silhouetten-Folie",
            "NormTermName": "Folie",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Silhouetten-Folie"
        },
        "02391": {
            "GenartName": "Vorwiderstand, Klimalüfter",
            "NormTermName": "Widerstand",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Lüfter"
        },
        "02394": {
            "GenartName": "Sensor, Kühlmitteltemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Glühanlage",
            "UsageName": "Kühlmitteltemperatur"
        },
        "02395": {
            "GenartName": "Reparatursatz, Kupplungsverstärker",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Kupplungsverstärker"
        },
        "02397": {
            "GenartName": "Vakuumpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Unterdruck"
        },
        "02398": {
            "GenartName": "UV-Leuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "UV-Licht"
        },
        "02399": {
            "GenartName": "Füllwaage",
            "NormTermName": "Waage",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füllwaage"
        },
        "02400": {
            "GenartName": "Thermometer",
            "NormTermName": "Thermometer",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "02401": {
            "GenartName": "Saugpumpe, Kompressor-Öl",
            "NormTermName": "Pumpe",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Kompressor-Öl"
        },
        "02402": {
            "GenartName": "Füllzylinder",
            "NormTermName": "Zylinder",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füllzylinder"
        },
        "02403": {
            "GenartName": "Manometer",
            "NormTermName": "Manometer",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "02404": {
            "GenartName": "Adaptersatz, Klimaservicegerät",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Klimaservicegerät"
        },
        "02405": {
            "GenartName": "Steckschlüsseleinsatz, Retrofit",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Retrofit-Adapter"
        },
        "02406": {
            "GenartName": "Füllschlauch, Klimaservicegerät",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Klimaservicegerät"
        },
        "02407": {
            "GenartName": "Additiv, Lecksuche",
            "NormTermName": "Zusatzmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Lecksuche"
        },
        "02408": {
            "GenartName": "Einspritzdüse, Expansionsventil",
            "NormTermName": "Düse",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Expansionsventil"
        },
        "02409": {
            "GenartName": "Kompressor-Öl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kompressor-Öl"
        },
        "02410": {
            "GenartName": "Vakuumpumpen-Öl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Vakuumpumpen-Öl"
        },
        "02411": {
            "GenartName": "Sortiment, Ventile",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Ventil"
        },
        "02412": {
            "GenartName": "Sensor, Einparkhilfe",
            "NormTermName": "Sensor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Einparkhilfe"
        },
        "02413": {
            "GenartName": "Leitungssatz, Einparkhilfe",
            "NormTermName": "Elektroleitungssatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Einparkhilfe"
        },
        "02414": {
            "GenartName": "Kodierstecker, Wegimpulszahl",
            "NormTermName": "Stecker",
            "AssGrpName": "Geschwindigkeitsregelanlage",
            "UsageName": "Wegimpulszahl-Kodierung"
        },
        "02415": {
            "GenartName": "Schalter, Kupplungsbetätigung (GRA)",
            "NormTermName": "Schalter",
            "AssGrpName": "Geschwindigkeitsregelanlage",
            "UsageName": "Kupplungsbetätigung"
        },
        "02416": {
            "GenartName": "Leitungssatz, Geschwindigkeitsregelanlage",
            "NormTermName": "Elektroleitungssatz",
            "AssGrpName": "Geschwindigkeitsregelanlage",
            "UsageName": null
        },
        "02417": {
            "GenartName": "Halter, GRA-Steuergerät",
            "NormTermName": "Halter",
            "AssGrpName": "Geschwindigkeitsregelanlage",
            "UsageName": "Steuergerät"
        },
        "02420": {
            "GenartName": "Montagesatz, Lader",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lader"
        },
        "02422": {
            "GenartName": "Lagersatz, Torsionsstab",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Torsionsstab"
        },
        "02424": {
            "GenartName": "Halter, Trockner",
            "NormTermName": "Halter",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Trockner"
        },
        "02425": {
            "GenartName": "Steuergerätsatz",
            "NormTermName": "Steuergerätsatz",
            "AssGrpName": "Fahrdynamikregelung",
            "UsageName": null
        },
        "02426": {
            "GenartName": "Montagesatz, Geschwindigkeitsregelanlage",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Geschwindigkeitsregelanlage",
            "UsageName": null
        },
        "02427": {
            "GenartName": "Lochband",
            "NormTermName": "Lochband",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "02431": {
            "GenartName": "Sensor, Zylinderkopftemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Instrumente",
            "UsageName": "Zylinderkopftemperatur"
        },
        "02432": {
            "GenartName": "Anzeige, Zylinderkopftemperatur",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Zylinderkopftemperatur"
        },
        "02433": {
            "GenartName": "Schalter, Türkontakt",
            "NormTermName": "Schalter",
            "AssGrpName": "Schließanlage",
            "UsageName": "Türkontakt"
        },
        "02434": {
            "GenartName": "Schalter, Türkontakt",
            "NormTermName": "Schalter",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Türkontakt"
        },
        "02435": {
            "GenartName": "Schalter, Türkontakt",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Türkontakt"
        },
        "02436": {
            "GenartName": "Nachrüstsatz, Innenraumluftfilter",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumluftfilter"
        },
        "02437": {
            "GenartName": "Seilzug, Stellelement",
            "NormTermName": "Seilzug",
            "AssGrpName": "Geschwindigkeitsregelanlage",
            "UsageName": "Stellelement"
        },
        "02440": {
            "GenartName": "Druckschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Druckluftanlage",
            "UsageName": null
        },
        "02441": {
            "GenartName": "Reparatursatz, Lufttrockner",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Lufttrockner"
        },
        "02442": {
            "GenartName": "Verbindungskabel, Elektronisches Bremssystem",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Elektronisches Bremssystem"
        },
        "02443": {
            "GenartName": "Steuergerät, Türbetätigung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Türsteuerung"
        },
        "02444": {
            "GenartName": "Koffer-/Laderaumwanne",
            "NormTermName": "Wanne",
            "AssGrpName": "Zubehör",
            "UsageName": "Koffer-/Laderaum"
        },
        "02445": {
            "GenartName": "Anti-Rutsch-Matte",
            "NormTermName": "Matte",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Anti-Rutschmatte"
        },
        "02448": {
            "GenartName": "Anti-Rutsch-Schalenmatte",
            "NormTermName": "Matte",
            "AssGrpName": "Zubehör",
            "UsageName": "Anti-Rutsch-Schalenmatte"
        },
        "02449": {
            "GenartName": "Koffer-/Laderaumwanne",
            "NormTermName": "Wanne",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Koffer-/Laderaumwanne"
        },
        "02450": {
            "GenartName": "Universal-Anti-Rutsch-Schalenmatte",
            "NormTermName": "Matte",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Universal-Anti-Rutsch-Schalenmatte"
        },
        "02451": {
            "GenartName": "Off-Road-Staubox",
            "NormTermName": "Staubox",
            "AssGrpName": "Zubehör",
            "UsageName": "Off-Road-Staubox"
        },
        "02452": {
            "GenartName": "Gasfeder, Lenksäulenverstellung",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenksäulenverstellung"
        },
        "02453": {
            "GenartName": "Schwingungsdämpfer, Zahnriemen",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Riementrieb",
            "UsageName": "Zahnriemenspannung"
        },
        "02454": {
            "GenartName": "Gasfeder, Heckscheibe",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Heckscheibe"
        },
        "02455": {
            "GenartName": "Gasfeder, Sitzverstellung",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Sitzverstellung"
        },
        "02457": {
            "GenartName": "Steuergerät, Sitzheizung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sitzheizung"
        },
        "02458": {
            "GenartName": "Multifunktionsanzeige",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Multifunktion"
        },
        "02459": {
            "GenartName": "Umrüstsatz, Wechselfilter",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölfilter"
        },
        "02461": {
            "GenartName": "Steuergerät, Fensterheber",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Fensterheber"
        },
        "02462": {
            "GenartName": "Trag-/Führungsgelenk",
            "NormTermName": "Gelenk",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "02463": {
            "GenartName": "Gleitschienensatz, Steuerkette",
            "NormTermName": "Gleitschienensatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkette"
        },
        "02464": {
            "GenartName": "Verbindungskabel, Luftfederung",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederung"
        },
        "02465": {
            "GenartName": "Quetschzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Leitungsverbinder"
        },
        "02466": {
            "GenartName": "Tagfahrleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Tagfahrleuchte"
        },
        "02467": {
            "GenartName": "Montagekegel, Faltenbalg",
            "NormTermName": "Montagekegel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Universal-Achs-/Lenkmanschette"
        },
        "02470": {
            "GenartName": "Sekundärluftfilter",
            "NormTermName": "Filter",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Sekundärluft"
        },
        "02471": {
            "GenartName": "Ölfilterschlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ölfilter"
        },
        "02472": {
            "GenartName": "Kugelgelenk, Lenkungsdämpfer",
            "NormTermName": "Gelenk",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkungsdämpfer"
        },
        "02473": {
            "GenartName": "Reparatursatz, Lenker",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Lenker"
        },
        "02474": {
            "GenartName": "Kurvenscheinwerfer-System",
            "NormTermName": "Scheinwerfersatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kurvenausleuchtung"
        },
        "02475": {
            "GenartName": "Temperaturschalter, Kühlmittelwarnlampe",
            "NormTermName": "Schalter",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmitteltemperaturwarnung"
        },
        "02476": {
            "GenartName": "Blende, Außenspiegel",
            "NormTermName": "Blende",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Außenspiegel"
        },
        "02478": {
            "GenartName": "Handleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Handleuchte"
        },
        "02479": {
            "GenartName": "Halter, Handleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Handleuchte"
        },
        "02480": {
            "GenartName": "Halter, Rückstrahler",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Rückstrahler"
        },
        "02481": {
            "GenartName": "Rollenhalter",
            "NormTermName": "Halter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Rolle/Trommel"
        },
        "02482": {
            "GenartName": "Hauptscheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "02483": {
            "GenartName": "Fernscheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Fernscheinwerfer"
        },
        "02484": {
            "GenartName": "Nebelscheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelscheinwerfer"
        },
        "02485": {
            "GenartName": "Arbeitsscheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "02486": {
            "GenartName": "Suchscheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Suchscheinwerfer"
        },
        "02487": {
            "GenartName": "Schlussleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Schlussleuchte"
        },
        "02488": {
            "GenartName": "Nebelschlussleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelschlussleuchte"
        },
        "02489": {
            "GenartName": "Kennzeichenleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Kennzeichenleuchte"
        },
        "02490": {
            "GenartName": "Begrenzungsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Begrenzungsleuchte"
        },
        "02492": {
            "GenartName": "Heckleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Heckleuchte"
        },
        "02493": {
            "GenartName": "Rückfahrleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Rückfahrleuchte"
        },
        "02496": {
            "GenartName": "Türsicherungsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Tür"
        },
        "02497": {
            "GenartName": "Innenraumleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Innenraumleuchte"
        },
        "02498": {
            "GenartName": "Parkleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Parkleuchte"
        },
        "02500": {
            "GenartName": "Zusatzbremsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Zusatzbremsleuchte"
        },
        "02501": {
            "GenartName": "Rückstrahler",
            "NormTermName": "Rückstrahler",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": null
        },
        "02502": {
            "GenartName": "Glühlampe, Hauptscheinwerfer",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "02503": {
            "GenartName": "Bremsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Bremsleuchte"
        },
        "02504": {
            "GenartName": "Blinkleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blinkleuchte"
        },
        "02505": {
            "GenartName": "Rundumkennleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Rundumkennleuchte"
        },
        "02506": {
            "GenartName": "Halter, Arbeitsscheinwerfer",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "02507": {
            "GenartName": "Halter, Nebelscheinwerfer",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelscheinwerfer"
        },
        "02508": {
            "GenartName": "Halter, Fernscheinwerfer",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Fernscheinwerfer"
        },
        "02509": {
            "GenartName": "Lichtwarner",
            "NormTermName": "Lichtwarner",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": null
        },
        "02510": {
            "GenartName": "Vorschaltgerät, Gasentladungslampe",
            "NormTermName": "Schaltgerät",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Gasentladungslampe"
        },
        "02511": {
            "GenartName": "Schalter, Türkontakt",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Türkontakt"
        },
        "02512": {
            "GenartName": "Schalter, Hauptlicht",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptlicht"
        },
        "02513": {
            "GenartName": "Zubehörsatz, Bremssattel",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "02514": {
            "GenartName": "Schalter, Rückfahrleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Rückfahrleuchte"
        },
        "02515": {
            "GenartName": "Fanfare",
            "NormTermName": "Fanfare",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": null
        },
        "02516": {
            "GenartName": "Horn",
            "NormTermName": "Horn",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": null
        },
        "02517": {
            "GenartName": "Halter, Rundumkennleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Rundumkennleuchte"
        },
        "02518": {
            "GenartName": "Blinkgeber",
            "NormTermName": "Relais",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blinkgeber"
        },
        "02519": {
            "GenartName": "Schalter, Bremsleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Bremsleuchte"
        },
        "02520": {
            "GenartName": "Blinkerschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blinker"
        },
        "02521": {
            "GenartName": "Warnblinkschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Warnblinker"
        },
        "02522": {
            "GenartName": "Arbeitszylinder, Servolenkung",
            "NormTermName": "Zylinder",
            "AssGrpName": "Lenkung",
            "UsageName": "Servolenkung"
        },
        "02523": {
            "GenartName": "Reparatursatz,Trag-/Führungsgelenk",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "02524": {
            "GenartName": "Sicherungsblech, Trag-/Führungsgelenk",
            "NormTermName": "Sicherungsblech",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "02525": {
            "GenartName": "Umrissleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Umrissleuchte"
        },
        "02526": {
            "GenartName": "Blitz-Kennleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blitz-Kennleuchte"
        },
        "02528": {
            "GenartName": "Verbindungskette, Prüfanschluss",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Normteile",
            "UsageName": "Prüfanschluss"
        },
        "02529": {
            "GenartName": "Zubehörsatz, Bremsbelag",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbelag"
        },
        "02530": {
            "GenartName": "Rahmen, Sortimentskasten",
            "NormTermName": "Rahmen",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Sortimentskasten"
        },
        "02531": {
            "GenartName": "Sortimentskasten",
            "NormTermName": "Kasten",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Sortimentskasten"
        },
        "02532": {
            "GenartName": "Verschlusskappe, Prüfanschluss",
            "NormTermName": "Kappe",
            "AssGrpName": "Normteile",
            "UsageName": "Prüfanschluss"
        },
        "02534": {
            "GenartName": "Verschluss, Bremsflüssigkeitsbehälter (Service)",
            "NormTermName": "Verschluss",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsflüssigkeitsbehälter (Service)"
        },
        "02535": {
            "GenartName": "Entlüfterstutzen",
            "NormTermName": "Stutzen",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Anschluss Auffangbehälter-Entsorgungsgerät"
        },
        "02536": {
            "GenartName": "Adapter, Bremssattelkolben-Rückstellwerkzeug",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremssattelkolben-Rückstellwerkzeug"
        },
        "02537": {
            "GenartName": "Spannbacken, Bördelgerät",
            "NormTermName": "Spannbacken",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bördelgerät"
        },
        "02538": {
            "GenartName": "Druckstück, Bördelgerät",
            "NormTermName": "Druckstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bördelgerät"
        },
        "02541": {
            "GenartName": "Tafelblech",
            "NormTermName": "Blech",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Tafelblech"
        },
        "02543": {
            "GenartName": "Profilblech",
            "NormTermName": "Blech",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Profilblech"
        },
        "02544": {
            "GenartName": "Bremsleitung",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": null
        },
        "02545": {
            "GenartName": "Anschlussstutzen, Unterdruckleitung",
            "NormTermName": "Stutzen",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremskraftverstärker"
        },
        "02546": {
            "GenartName": "Druckschalter, Bremshydraulik",
            "NormTermName": "Schalter",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremsdruck"
        },
        "02547": {
            "GenartName": "Lichtscheibe, Parkleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Parkleuchte"
        },
        "02549": {
            "GenartName": "Halter, Bremsflüssigkeitsbehälter",
            "NormTermName": "Halter",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremsflüssigkeitsbehälter"
        },
        "02550": {
            "GenartName": "Halter, Bremsleitung",
            "NormTermName": "Halter",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremsleitung"
        },
        "02551": {
            "GenartName": "Halter, Bremsschlauch",
            "NormTermName": "Halter",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremsschlauch"
        },
        "02552": {
            "GenartName": "Schraube, Bremssattel",
            "NormTermName": "Schraube",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremssattel"
        },
        "02553": {
            "GenartName": "Stopfen, Bremsflüssigkeitsbehälter",
            "NormTermName": "Stopfen",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremsflüssigkeitsbehälter"
        },
        "02554": {
            "GenartName": "Ventil, Bremskraftverstärker",
            "NormTermName": "Ventil",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremskraftverstärker"
        },
        "02555": {
            "GenartName": "Verschluss, Bremsflüssigkeitsbehälter",
            "NormTermName": "Verschluss",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremsflüssigkeitsbehälter"
        },
        "02556": {
            "GenartName": "Dichtung, Unterdruck-Anschlussstutzen BKV",
            "NormTermName": "Dichtung",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Unterdruck-Anschlussstutzen"
        },
        "02557": {
            "GenartName": "Prüf-/Richtgerät, Kupplungsscheibe",
            "NormTermName": "Prüf-/Richtgerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplungsscheibe"
        },
        "02558": {
            "GenartName": "Zentrierwerkzeug, Kupplungsscheibe",
            "NormTermName": "Zentrierwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplungsscheibe"
        },
        "02559": {
            "GenartName": "Ausziehersatz, Innenlager",
            "NormTermName": "Ausziehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Innenlager"
        },
        "02560": {
            "GenartName": "Montagewerkzeug, Getriebewelle",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Getriebewelle"
        },
        "02561": {
            "GenartName": "Montagewerkzeugsatz, Kurbelwellendichtring/Führungslager",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kurbelwellendichtring/Führungslager"
        },
        "02562": {
            "GenartName": "Spannvorrichtung, Kupplungsdruckplatte",
            "NormTermName": "Spannvorrichtung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplungsdruckplatte"
        },
        "02563": {
            "GenartName": "Halteklammersatz, Kupplungsdruckplatte",
            "NormTermName": "Halteklammersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplungsdruckplatte"
        },
        "02564": {
            "GenartName": "Montagewerkzeugsatz, Kupplung/Schwungrad",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplung/Schwungrad"
        },
        "02565": {
            "GenartName": "Einstelllehre, Kupplungsbetätigung",
            "NormTermName": "Lehre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Grundeinstellung Kupplungsbetätigung"
        },
        "02566": {
            "GenartName": "Ein-/Auspressdorn, Dichtring",
            "NormTermName": "Montagedorn",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Dichtring Ein-/Auspressen"
        },
        "02567": {
            "GenartName": "Halteschlüssel, Schwungrad",
            "NormTermName": "Halteschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schwungrad"
        },
        "02568": {
            "GenartName": "Vorspannring, Kupplungsdruckplatte",
            "NormTermName": "Spannring",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplungsdruckplattenvorspannung"
        },
        "02569": {
            "GenartName": "Dicht-/Führungsdornsatz, Differential",
            "NormTermName": "Dicht-/Führungsdornsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Differential"
        },
        "02570": {
            "GenartName": "Verlängerung, Messuhr",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Messuhr"
        },
        "02571": {
            "GenartName": "Tastschuh, Messuhr",
            "NormTermName": "Tastschuh",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Messuhr"
        },
        "02572": {
            "GenartName": "Spreizdorn, Innenlager",
            "NormTermName": "Spreizdorn",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Innenlager"
        },
        "02573": {
            "GenartName": "Spannzange, Innenlager",
            "NormTermName": "Spannzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Innenlager"
        },
        "02574": {
            "GenartName": "Demontagehebel, Kurbelwellendichtring",
            "NormTermName": "Hebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Demontage Kurbelwellendichtring"
        },
        "02575": {
            "GenartName": "Ausziehhaken, Kurbelwellendichtring",
            "NormTermName": "Haken",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Demontage Kurbelwellendichtring"
        },
        "02576": {
            "GenartName": "Druckstück, Führungslager",
            "NormTermName": "Druckstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Führungslager"
        },
        "02577": {
            "GenartName": "Auszieher, Führungslager",
            "NormTermName": "Auszieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Führungslager"
        },
        "02578": {
            "GenartName": "Druckstück, Kurbelwellendichtring",
            "NormTermName": "Druckstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kurbelwellendichtring"
        },
        "02579": {
            "GenartName": "Verlängerungshebel, Kupplung/Schwungrad",
            "NormTermName": "Hebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplung/Schwungrad"
        },
        "02580": {
            "GenartName": "Knarre, Kupplung/Schwungrad",
            "NormTermName": "Knarre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplung/Schwungrad"
        },
        "02581": {
            "GenartName": "Haltebügel, Kupplungsspannvorrichtung",
            "NormTermName": "Haltebügel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplungsspannvorrichtung"
        },
        "02582": {
            "GenartName": "Druckstück, Kupplungsdruckplatte",
            "NormTermName": "Druckstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplungsdruckplatte"
        },
        "02583": {
            "GenartName": "Montagehebel, Kupplungsdruckplatte",
            "NormTermName": "Hebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Montage Kupplungsdruckplatte"
        },
        "02584": {
            "GenartName": "Dicht-/Führungsdorn, Differential",
            "NormTermName": "Dicht-/Führungsdorn",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Differential"
        },
        "02585": {
            "GenartName": "Gerätebatterie",
            "NormTermName": "Batterie",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Gerätebatterie"
        },
        "02586": {
            "GenartName": "Unterdruckschlauch, Bremsanlage",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Unterdruck"
        },
        "02587": {
            "GenartName": "Anschlagscheibe, Hauptbremszylinder",
            "NormTermName": "Anschlagscheibe",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Hauptbremszylinder"
        },
        "02588": {
            "GenartName": "Manschette, Hauptbremszylinder",
            "NormTermName": "Manschette",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Hauptbremszylinder"
        },
        "02589": {
            "GenartName": "Manschette, Radbremszylinder",
            "NormTermName": "Manschette",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Radbremszylinder"
        },
        "02590": {
            "GenartName": "Unterlage, Rundumkennleuchte",
            "NormTermName": "Unterlage",
            "AssGrpName": "Signalanlage",
            "UsageName": "Rundumkennleuchte"
        },
        "02591": {
            "GenartName": "Geschwindigkeitsregelanlage",
            "NormTermName": "Regelanlage",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": null
        },
        "02593": {
            "GenartName": "Bedienschalter, Geschwindigkeitsregelanlage",
            "NormTermName": "Schalter",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": null
        },
        "02594": {
            "GenartName": "Stellelement, Geschwindigkeitsregelanlage",
            "NormTermName": "Stellelement",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": null
        },
        "02595": {
            "GenartName": "Adaptersatz, Geschwindigkeitsregelanlage",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": null
        },
        "02596": {
            "GenartName": "Unterlage",
            "NormTermName": "Unterlage",
            "AssGrpName": null,
            "UsageName": null
        },
        "02597": {
            "GenartName": "Frequenzmodul, Geschwindigkeitsregelanlage",
            "NormTermName": "Frequenzmodul",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": null
        },
        "02598": {
            "GenartName": "Steuergerät, Geschwindigkeitsregelanlage",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": null
        },
        "02599": {
            "GenartName": "Kodierstecker, Wegimpulszahl",
            "NormTermName": "Stecker",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": "Wegimpulszahl-Kodierung"
        },
        "02600": {
            "GenartName": "Schalter, Kupplungsbetätigung",
            "NormTermName": "Schalter",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": "Kupplungsbetätigung"
        },
        "02601": {
            "GenartName": "Leitungssatz, Geschwindigkeitsregelanlage",
            "NormTermName": "Elektroleitungssatz",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": null
        },
        "02602": {
            "GenartName": "Halter, GRA-Steuergerät",
            "NormTermName": "Halter",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": "Steuergerät"
        },
        "02603": {
            "GenartName": "Montagesatz, Geschwindigkeitsregelanlage",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": null
        },
        "02604": {
            "GenartName": "Seilzug, Stellelement",
            "NormTermName": "Seilzug",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": "Stellelement"
        },
        "02606": {
            "GenartName": "Adaptersatz, Geschwindigkeitswarnanlage",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Geschwindigkeitswarnanlage, Universal",
            "UsageName": null
        },
        "02607": {
            "GenartName": "Geschwindigkeitswarnanlage",
            "NormTermName": "Warnanlage",
            "AssGrpName": "Geschwindigkeitswarnanlage, Universal",
            "UsageName": null
        },
        "02608": {
            "GenartName": "Amperemeter",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Amperemeter"
        },
        "02609": {
            "GenartName": "Drehzahlmesser",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Drehzahl"
        },
        "02610": {
            "GenartName": "Anzeige, Ladedruck",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Ladedruck"
        },
        "02611": {
            "GenartName": "Anzeige, Öldruck",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Öldruck"
        },
        "02612": {
            "GenartName": "Anzeige, Kraftstoffvorrat",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Kraftstoffvorrat"
        },
        "02613": {
            "GenartName": "Tachometer",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Tachometer"
        },
        "02614": {
            "GenartName": "Anzeige, Kühlmitteltemperatur",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Kühlmitteltemperatur"
        },
        "02615": {
            "GenartName": "Anzeige, Öltemperatur",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Öltemperatur"
        },
        "02616": {
            "GenartName": "Anzeige, Außentemperatur",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Außentemperatur"
        },
        "02617": {
            "GenartName": "Anzeige, Außen-/Innenraumtemperatur",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Außen-/Innenraumtemperatur"
        },
        "02618": {
            "GenartName": "Voltmeter",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Voltmeter"
        },
        "02619": {
            "GenartName": "Uhr",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Uhr"
        },
        "02620": {
            "GenartName": "Steuergerät, Einparkhilfe",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Einparkhilfe"
        },
        "02621": {
            "GenartName": "Einparkhilfe",
            "NormTermName": "Einparkhilfe",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": null
        },
        "02622": {
            "GenartName": "Radiostummschaltung, Einparkhilfe",
            "NormTermName": "Stummschaltung",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Einparkhilfe"
        },
        "02623": {
            "GenartName": "Sensor, Einparkhilfe",
            "NormTermName": "Sensor",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Einparkhilfe"
        },
        "02624": {
            "GenartName": "Leitungssatz, Einparkhilfe",
            "NormTermName": "Elektroleitungssatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Einparkhilfe"
        },
        "02625": {
            "GenartName": "Bedienelement, Unfalldatenspeicher",
            "NormTermName": "Bedienelement",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Unfalldatenspeicher"
        },
        "02626": {
            "GenartName": "Unfalldatenspeicher",
            "NormTermName": "Datenspeicher",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Unfalldaten"
        },
        "02627": {
            "GenartName": "CD-Radio",
            "NormTermName": "Autoradio",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Radio-CD"
        },
        "02628": {
            "GenartName": "Radio/Navigationssystem-Kombination",
            "NormTermName": "Radio/Navigationssystem-Kombination",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "02629": {
            "GenartName": "Führungshülse, Zylinderkopf",
            "NormTermName": "Hülse",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Zylinderkopfführung"
        },
        "02630": {
            "GenartName": "Anbauteilesatz, Unfalldatenspeicher",
            "NormTermName": "Anbauteilesatz",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Unfalldatenspeicher"
        },
        "02631": {
            "GenartName": "Anschlussleitung, Unfalldatenspeicher",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Unfalldatenspeicher"
        },
        "02632": {
            "GenartName": "Kraftstoffschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": null
        },
        "02633": {
            "GenartName": "Schlauchverbinder, Kraftstoffschlauch",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Kraftstoffschlauch"
        },
        "02634": {
            "GenartName": "Manschette, Geberzylinder",
            "NormTermName": "Manschette",
            "AssGrpName": "Kupplung, Universal",
            "UsageName": "Geberzylinder"
        },
        "02635": {
            "GenartName": "Manschette, Nehmerzylinder",
            "NormTermName": "Manschette",
            "AssGrpName": "Kupplung, Universal",
            "UsageName": "Nehmerzylinder"
        },
        "02636": {
            "GenartName": "Abzieher, Kupplung",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplung"
        },
        "02637": {
            "GenartName": "Abzieher, Schwungrad",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schwungrad"
        },
        "02638": {
            "GenartName": "Zentrierwerkzeugsatz, Hilfsrahmen",
            "NormTermName": "Zentrierwerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Hilfsrahmen"
        },
        "02639": {
            "GenartName": "Montagewerkzeug, Schiebehülse",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schiebehülse"
        },
        "02640": {
            "GenartName": "Faltenbalg, Lenkung",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Lenkung, Universal",
            "UsageName": null
        },
        "02641": {
            "GenartName": "Faltenbalgsatz, Lenkung",
            "NormTermName": "Faltenbalgsatz",
            "AssGrpName": "Lenkung, Universal",
            "UsageName": null
        },
        "02642": {
            "GenartName": "Hydraulikfilter, Lenkung",
            "NormTermName": "Filter",
            "AssGrpName": "Lenkung, Universal",
            "UsageName": "Hydrauliköl"
        },
        "02643": {
            "GenartName": "Montagevorrichtung, Motor-/Getriebeeinheit",
            "NormTermName": "Montagevorrichtung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Motor-/Getriebeeinheit"
        },
        "02644": {
            "GenartName": "Montagewerkzeug, Stahlklammer-Abgasanlage",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stahlklammer-Abgasanlage"
        },
        "02645": {
            "GenartName": "Arretierwerkzeugsatz, Kurbelwelle",
            "NormTermName": "Arretierwerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kurbelwelle"
        },
        "02646": {
            "GenartName": "Prüfgerät, Zahnriemenspannung",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zahnriemenspannung"
        },
        "02647": {
            "GenartName": "Schlüssel, Zahnriemenspannung",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zahnriemenspannung"
        },
        "02648": {
            "GenartName": "Verbundkeilriemen",
            "NormTermName": "Riemen",
            "AssGrpName": "Riementrieb",
            "UsageName": "Verbundkeilriemen"
        },
        "02649": {
            "GenartName": "Faltenbalg, Antriebswelle",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Radantrieb, Universal",
            "UsageName": "Antriebswelle"
        },
        "02650": {
            "GenartName": "Montagesatz, Faltenbalg Antriebswelle",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Radantrieb, Universal",
            "UsageName": "Faltenbalg Antriebswelle"
        },
        "02651": {
            "GenartName": "Fernbedienung, Zentralverriegelung",
            "NormTermName": "Fernbedienung",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Zentralverriegelung"
        },
        "02652": {
            "GenartName": "Stellelement, Zentralverriegelung",
            "NormTermName": "Stellelement",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Zentralverriegelung"
        },
        "02653": {
            "GenartName": "Handsendergehäuse, Zentralverriegelung",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Zentralverriegelungshandsender"
        },
        "02654": {
            "GenartName": "Sirene, Alarmanlage",
            "NormTermName": "Sirene",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Alarmanlage"
        },
        "02655": {
            "GenartName": "Schalter, Türkontakt",
            "NormTermName": "Schalter",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Türkontakt"
        },
        "02656": {
            "GenartName": "Warndreieck",
            "NormTermName": "Warndreieck",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": null
        },
        "02658": {
            "GenartName": "Alarmanlage",
            "NormTermName": "Warnanlage",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Diebstahlwarnung"
        },
        "02659": {
            "GenartName": "Neigungssensor, Alarmanlage",
            "NormTermName": "Sensor",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Neigungssensor"
        },
        "02660": {
            "GenartName": "Handsender, Alarmanlage",
            "NormTermName": "Handsender",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Alarmanlage"
        },
        "02661": {
            "GenartName": "Handsendergehäuse, Alarmanlage",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Alarmanlage"
        },
        "02662": {
            "GenartName": "Verlängerungsleitung, Ultraschallmodul",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Ultraschallmodul"
        },
        "02663": {
            "GenartName": "Sirene, Alarmanlage",
            "NormTermName": "Sirene",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Alarmanlage"
        },
        "02664": {
            "GenartName": "Schalter, Türkontakt",
            "NormTermName": "Schalter",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Türkontakt"
        },
        "02666": {
            "GenartName": "Dichtung, Motorkühler",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kühlung",
            "UsageName": "Motorkühler"
        },
        "02667": {
            "GenartName": "Rahmen, Motorkühler",
            "NormTermName": "Rahmen",
            "AssGrpName": "Kühlung",
            "UsageName": "Motorkühler"
        },
        "02668": {
            "GenartName": "Kühlmodul",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Kühlung",
            "UsageName": null
        },
        "02669": {
            "GenartName": "Innenraumgebläse",
            "NormTermName": "Gebläse",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumluft"
        },
        "02670": {
            "GenartName": "Tagfahrleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Tagfahrleuchte"
        },
        "02671": {
            "GenartName": "Kurvenscheinwerfer-System",
            "NormTermName": "Scheinwerfersatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Kurvenausleuchtung"
        },
        "02672": {
            "GenartName": "Unterlage, Rundumkennleuchte",
            "NormTermName": "Unterlage",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Rundumkennleuchte"
        },
        "02675": {
            "GenartName": "Thermometer, Außen-/Innenraumtemperatur",
            "NormTermName": "Thermometer",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Außen-/Innenraumtemperatur"
        },
        "02676": {
            "GenartName": "Thermometer, Außentemperatur",
            "NormTermName": "Thermometer",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Außentemperatur"
        },
        "02679": {
            "GenartName": "Betriebsstundenzähler",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Betriebsstundenzähler"
        },
        "02680": {
            "GenartName": "Traktormeter",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Traktormeter"
        },
        "02681": {
            "GenartName": "Manometer",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Manometer"
        },
        "02682": {
            "GenartName": "Anzeige, Bremsdruck",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Bremsdruck"
        },
        "02683": {
            "GenartName": "Anzeige, Zylinderkopftemperatur",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Zylinderkopftemperatur"
        },
        "02684": {
            "GenartName": "Multifunktionsanzeige",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Multifunktion"
        },
        "02685": {
            "GenartName": "Armlehne",
            "NormTermName": "Lehne",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": "Arm"
        },
        "02686": {
            "GenartName": "Fußmatte",
            "NormTermName": "Fußmatte",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": null
        },
        "02687": {
            "GenartName": "Fußmattensatz",
            "NormTermName": "Fußmattensatz",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": null
        },
        "02688": {
            "GenartName": "Gasfeder, Telefonkonsole",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": "Telefonkonsole"
        },
        "02689": {
            "GenartName": "Gasfeder, Reserveradhalter",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Reserveradhalter"
        },
        "02691": {
            "GenartName": "Zigarettenanzünder",
            "NormTermName": "Zigarettenanzünder",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": null
        },
        "02692": {
            "GenartName": "Kühlernetz, Motorkühlung",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühler-Motorkühlung"
        },
        "02697": {
            "GenartName": "Montagepaste",
            "NormTermName": "Montagehilfe",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Montagepaste"
        },
        "02698": {
            "GenartName": "Montagespray",
            "NormTermName": "Montagehilfe",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Montagespray"
        },
        "02699": {
            "GenartName": "Montageflüssigkeit",
            "NormTermName": "Montagehilfe",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Montageflüssigkeit"
        },
        "02700": {
            "GenartName": "Dichtung, Ölpeilstab",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpeilstab"
        },
        "02701": {
            "GenartName": "Handsender, Zentralverriegelung",
            "NormTermName": "Handsender",
            "AssGrpName": "Schließanlage",
            "UsageName": "Zentralverriegelung"
        },
        "02702": {
            "GenartName": "Montagehebel, Bremsbacke",
            "NormTermName": "Hebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Montage Bremsbacke"
        },
        "02704": {
            "GenartName": "Schalter, Heckscheibenheizung",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Heckscheibenheizung"
        },
        "02705": {
            "GenartName": "Buchse, Kipphebel",
            "NormTermName": "Buchse",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Kipphebel"
        },
        "02706": {
            "GenartName": "Kolbenhalteklammer, Radbremszylinder",
            "NormTermName": "Halteklammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Radbremszylinder-Kolben"
        },
        "02707": {
            "GenartName": "Winkelschraubendreher",
            "NormTermName": "Winkelschraubendreher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "02708": {
            "GenartName": "Handsender, Zentralverriegelung",
            "NormTermName": "Handsender",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Zentralverriegelung"
        },
        "02709": {
            "GenartName": "Signalgeber",
            "NormTermName": "Signalgeber",
            "AssGrpName": "Signalanlage",
            "UsageName": null
        },
        "02711": {
            "GenartName": "Adapter, Waschwasserpumpe-Scheibenreinigung",
            "NormTermName": "Adapter",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasserpumpe-Scheibenreinigung"
        },
        "02712": {
            "GenartName": "Adapter, Waschwasserpumpe-Scheinwerferreinigung",
            "NormTermName": "Adapter",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": "Waschwasserpumpe-Scheinwerferreinigung"
        },
        "02713": {
            "GenartName": "Reibahlensatz, ABS-Sensoraufnahme",
            "NormTermName": "Reibahlensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "ABS-Sensoraufnahme"
        },
        "02714": {
            "GenartName": "Reibahlenkopf, ABS-Sensoraufnahme",
            "NormTermName": "Reibahlenkopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "ABS-Sensoraufnahme"
        },
        "02715": {
            "GenartName": "Federhaltestift, Bremsbacke",
            "NormTermName": "Stift",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbacken"
        },
        "02716": {
            "GenartName": "Buchse, Bremsbackenbolzen",
            "NormTermName": "Buchse",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbackenbolzen"
        },
        "02717": {
            "GenartName": "Buchse, Bremsbackenrolle",
            "NormTermName": "Buchse",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbackenrolle"
        },
        "02718": {
            "GenartName": "Bremsbackenrolle",
            "NormTermName": "Rolle",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbacken"
        },
        "02719": {
            "GenartName": "Bolzen, Bremsbackenrolle",
            "NormTermName": "Bolzen",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbackenrolle"
        },
        "02720": {
            "GenartName": "Reparatursatz, Bremsbackenrolle",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbackenrolle"
        },
        "02721": {
            "GenartName": "Reparatursatz, Gestängesteller",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Gestängesteller"
        },
        "02722": {
            "GenartName": "Reparatursatz, Bremsnockenwelle",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsnockenwelle"
        },
        "02723": {
            "GenartName": "Spreizkeilbremse",
            "NormTermName": "Bremsgerätegruppe",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Spreizkeilbremse"
        },
        "02724": {
            "GenartName": "Bremsbackenbolzensatz",
            "NormTermName": "Bolzensatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbacken"
        },
        "02725": {
            "GenartName": "Kette, Ölpumpenantrieb",
            "NormTermName": "Kette",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "02726": {
            "GenartName": "Kettenglied, Ölpumpenantrieb",
            "NormTermName": "Kettenglied",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "02728": {
            "GenartName": "Reparatursatz, Schwungrad",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Schwungrad"
        },
        "02729": {
            "GenartName": "Reparatursatz, Spannarm-Keilrippenriemen",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Spannarm-Keilrippenriemen"
        },
        "02730": {
            "GenartName": "Ölspritzblech, Kurbelwelle",
            "NormTermName": "Blech",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwelle"
        },
        "02731": {
            "GenartName": "Buchse, Feststellbremswelle",
            "NormTermName": "Buchse",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremswelle"
        },
        "02732": {
            "GenartName": "Lager, Feststellbremswelle",
            "NormTermName": "Lager",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremswelle"
        },
        "02733": {
            "GenartName": "Lager, Bremsnockenwelle",
            "NormTermName": "Lager",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsnockenwelle"
        },
        "02734": {
            "GenartName": "Puffer, Bremskraftregler",
            "NormTermName": "Puffer",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremskraftregler"
        },
        "02735": {
            "GenartName": "Spannarm, Keilriemen",
            "NormTermName": "Spannarm",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilriemen"
        },
        "02736": {
            "GenartName": "Befestigungsbolzen, Stabilisator",
            "NormTermName": "Bolzen",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisator"
        },
        "02737": {
            "GenartName": "Relais, Innenraumgebläse",
            "NormTermName": "Relais",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumgebläse"
        },
        "02738": {
            "GenartName": "Lagerbock, Schaltgestänge",
            "NormTermName": "Lagerbock",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgestänge"
        },
        "02739": {
            "GenartName": "Distanzring",
            "NormTermName": "Distanzring",
            "AssGrpName": "Normteile",
            "UsageName": "Abstandhaltung/Ausrichtung"
        },
        "02740": {
            "GenartName": "Erweiterungssatz Einparkhilfe, Vorfahrwarnung",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Einparkhilfe-Vorfahrwarnung"
        },
        "02741": {
            "GenartName": "Sortiment, Rohrverbinder",
            "NormTermName": "Sortiment",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Rohrverbinder"
        },
        "02742": {
            "GenartName": "Wellrohr",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kabelschutz"
        },
        "02743": {
            "GenartName": "Adapter, Kupplungsgeberzylinder",
            "NormTermName": "Adapter",
            "AssGrpName": "Kupplung",
            "UsageName": "Geberzylinder"
        },
        "02744": {
            "GenartName": "Schalter, Innenraumleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Innenraumleuchte"
        },
        "02745": {
            "GenartName": "Hauptschalter, Batterie",
            "NormTermName": "Schalter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Batterie"
        },
        "02746": {
            "GenartName": "Verschleißanzeige, Bremsbelag",
            "NormTermName": "Anzeige",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbelagverschleiß"
        },
        "02747": {
            "GenartName": "Ölabstreifblech, Kurbelgehäuseentlüftung",
            "NormTermName": "Blech",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "02748": {
            "GenartName": "Relais, Außenspiegelheizung",
            "NormTermName": "Relais",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Außenspiegelheizung"
        },
        "02749": {
            "GenartName": "Reparatursatz, Feststellbremswelle",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremswelle"
        },
        "02750": {
            "GenartName": "Stopfen, Kipphebelwellen-Montagebohrung",
            "NormTermName": "Stopfen",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Kipphebelwellen-Montagebohrung"
        },
        "02751": {
            "GenartName": "Abdeckring, Bremsbackenbolzenbohrung",
            "NormTermName": "Abdeckring",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbackenbolzenbohrung"
        },
        "02752": {
            "GenartName": "Dichtring, Hydraulikfilter",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Hydraulikfilter"
        },
        "02753": {
            "GenartName": "Relais, Kühlerlüfternachlauf-Ladeluftkühler",
            "NormTermName": "Relais",
            "AssGrpName": "Kühlung",
            "UsageName": "Ladeluftkühlung"
        },
        "02754": {
            "GenartName": "Befestigungsbolzen",
            "NormTermName": "Bolzen",
            "AssGrpName": "Normteile",
            "UsageName": "Befestigungselemente"
        },
        "02755": {
            "GenartName": "Verschlussschraube, Achsgetriebe",
            "NormTermName": "Schraube",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Ölablass"
        },
        "02756": {
            "GenartName": "Wellendichtring, Bremsnockenwelle",
            "NormTermName": "Dichtring",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsnockenwelle"
        },
        "02757": {
            "GenartName": "Wellendichtring, Feststellbremswelle",
            "NormTermName": "Dichtring",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremswelle"
        },
        "02758": {
            "GenartName": "Klemmstück, Keilrippenriemenspannarm",
            "NormTermName": "Klemmstück",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilrippenriemenspannarm"
        },
        "02760": {
            "GenartName": "Aufnahmeteller, Zugsattelzapfen",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Zugsattelzapfen"
        },
        "02761": {
            "GenartName": "Lager, Keilrippenriemenspannarm",
            "NormTermName": "Lager",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilrippenriemenspannarm"
        },
        "02762": {
            "GenartName": "Aufnahme, Wellendichtring-Bremsnockenwelle",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Wellendichtring-Bremsnockenwelle"
        },
        "02763": {
            "GenartName": "Batteriepolklemme",
            "NormTermName": "Klemme",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Batteriepol"
        },
        "02764": {
            "GenartName": "Wählhebelmodul, Getriebeschaltung",
            "NormTermName": "Sensor",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeschaltung"
        },
        "02766": {
            "GenartName": "Masseband",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Batterie-Masseverbindung"
        },
        "02767": {
            "GenartName": "Umrüst-Katalysator",
            "NormTermName": "Katalysator",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Umrüstung"
        },
        "02768": {
            "GenartName": "Batterieladezange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Batterieaufladung"
        },
        "02769": {
            "GenartName": "Glühlampe, Arbeitsscheinwerfer",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "02770": {
            "GenartName": "Glühlampe, Blinkleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blinkleuchte"
        },
        "02771": {
            "GenartName": "Glühlampe, Brems-/Schlusslicht",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Brems-/Schlusslicht"
        },
        "02772": {
            "GenartName": "Anschlussstutzen, Kältemittelleitung",
            "NormTermName": "Stutzen",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Anschluss Kältemittelleitung"
        },
        "02773": {
            "GenartName": "Glühlampe, Bremsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Bremsleuchte"
        },
        "02774": {
            "GenartName": "Glühlampe, Fernscheinwerfer",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Fernscheinwerfer"
        },
        "02775": {
            "GenartName": "Glühlampe, Innenraumleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Innenraumleuchte"
        },
        "02776": {
            "GenartName": "Glühlampe, Instrumentenbeleuchtung",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Instrumentenbeleuchtung"
        },
        "02777": {
            "GenartName": "Glühlampe, Kennzeichenleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Kennzeichenleuchte"
        },
        "02778": {
            "GenartName": "Glühlampe, Kofferraumleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Kofferraumleuchte"
        },
        "02779": {
            "GenartName": "Glühlampe, Motorraumleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Motorraumleuchte"
        },
        "02780": {
            "GenartName": "Glühlampe, Nebelscheinwerfer",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelscheinwerfer"
        },
        "02781": {
            "GenartName": "Glühlampe, Nebelschlussleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelschlussleuchte"
        },
        "02782": {
            "GenartName": "Glühlampe, Park-/Positionsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Park-/Begrenzungsleuchte"
        },
        "02783": {
            "GenartName": "Glühlampe, Positions-/Begrenzungsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Positions-/Begrenzungsleuchte"
        },
        "02784": {
            "GenartName": "Glühlampe, Rückfahrleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Rückfahrleuchte"
        },
        "02785": {
            "GenartName": "Glühlampe, Rundumkennleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Rundumkennleuchte"
        },
        "02786": {
            "GenartName": "Glühlampe, Schlussleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Schlussleuchte"
        },
        "02787": {
            "GenartName": "Glühlampe, Suchscheinwerfer",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Suchscheinwerfer"
        },
        "02788": {
            "GenartName": "Glühlampe, Türleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Türleuchte"
        },
        "02789": {
            "GenartName": "Glühlampe, Umriss-/Begrenzungsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Umriss-/Begrenzungsleuchte"
        },
        "02790": {
            "GenartName": "Schalter, Spiegelverstellung",
            "NormTermName": "Schalter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Spiegelverstellung"
        },
        "02791": {
            "GenartName": "Regal/-system",
            "NormTermName": "Regal",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "02792": {
            "GenartName": "Umrüstsatz, Kaltstartsteuerung",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Vergaser",
            "UsageName": "Kaltstartsteuerung"
        },
        "02793": {
            "GenartName": "Wärmeschrumpfschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Normteile",
            "UsageName": "Wärmeschrumpfschlauch"
        },
        "02794": {
            "GenartName": "Isolierschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Normteile",
            "UsageName": "Isolierung"
        },
        "02797": {
            "GenartName": "Glühlampe, Zusatzbremsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Signalanlage",
            "UsageName": "Zusatzbremsleuchte"
        },
        "02798": {
            "GenartName": "Glühlampe, Zusatzbremsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Zusatzbremsleuchte"
        },
        "02799": {
            "GenartName": "Klemmzange, Faltenbalg",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Klemmschelle-Faltenbalg"
        },
        "02800": {
            "GenartName": "Außenspiegel, Fahrerhaus",
            "NormTermName": "Spiegel",
            "AssGrpName": "Fahrerhaus, Universal",
            "UsageName": "Außenspiegel"
        },
        "02801": {
            "GenartName": "Außenspiegel",
            "NormTermName": "Spiegel",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Außenspiegel"
        },
        "02802": {
            "GenartName": "Druckschalter, Klimaanlage",
            "NormTermName": "Schalter",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Druckschalter"
        },
        "02803": {
            "GenartName": "Halter, Außenspiegel",
            "NormTermName": "Halter",
            "AssGrpName": "Fahrerhaus, Universal",
            "UsageName": "Außenspiegel"
        },
        "02804": {
            "GenartName": "Gehäuse, Arbeitsscheinwerfer",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "02805": {
            "GenartName": "Kältemittel, Klimaanlage",
            "NormTermName": "Kältemittel",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": null
        },
        "02806": {
            "GenartName": "Niederdruckschalter, Klimaanlage",
            "NormTermName": "Schalter",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Niederdruck"
        },
        "02807": {
            "GenartName": "Schalter, Rundumkennleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Rundumkennleuchte"
        },
        "02808": {
            "GenartName": "Scheinwerferreinigungsanlage",
            "NormTermName": "Reinigungsanlage",
            "AssGrpName": "Scheinwerferreinigung, Universal",
            "UsageName": null
        },
        "02809": {
            "GenartName": "Spiegelglas, Außenspiegel",
            "NormTermName": "Spiegelglas",
            "AssGrpName": "Fahrerhaus, Universal",
            "UsageName": "Außenspiegel"
        },
        "02810": {
            "GenartName": "Spiegelglas, Außenspiegel",
            "NormTermName": "Spiegelglas",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Außenspiegel"
        },
        "02811": {
            "GenartName": "Streuscheibe, Arbeitsscheinwerfer",
            "NormTermName": "Streuscheibe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "02812": {
            "GenartName": "Abdeckkappe, Regensensor",
            "NormTermName": "Kappe",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Regensensor"
        },
        "02813": {
            "GenartName": "Elektromotor, Innenraumgebläse",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Heizung/Lüftung, Universal",
            "UsageName": "Innenraumgebläse"
        },
        "02814": {
            "GenartName": "Elektromotor, Rundumkennleuchte",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Rundumkennleuchte"
        },
        "02815": {
            "GenartName": "Expansionsventil, Klimaanlage",
            "NormTermName": "Ventil",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Expansion"
        },
        "02816": {
            "GenartName": "Gehäuse, Blinkleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blinkleuchte"
        },
        "02817": {
            "GenartName": "Gehäuse, Bremsleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Bremsleuchte"
        },
        "02818": {
            "GenartName": "Gehäuse, Fernscheinwerfer",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Fernscheinwerfer"
        },
        "02819": {
            "GenartName": "Gehäuse, Hauptscheinwerfer",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "02820": {
            "GenartName": "Gehäuse, Heckleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Heckleuchte"
        },
        "02821": {
            "GenartName": "Gehäuse, Kennzeichenleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Kennzeichenleuchte"
        },
        "02822": {
            "GenartName": "Gehäuse, Nebelscheinwerfer",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelscheinwerfer"
        },
        "02823": {
            "GenartName": "Gehäuse, Nebelschlussleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelschlussleuchte"
        },
        "02824": {
            "GenartName": "Gehäuse, Rückfahrleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Rückfahrleuchte"
        },
        "02825": {
            "GenartName": "Gehäuse, Schlussleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Schlussleuchte"
        },
        "02828": {
            "GenartName": "Halteband, Kraftstoffbehälter",
            "NormTermName": "Band",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Kraftstoffbehälter"
        },
        "02829": {
            "GenartName": "Halter, Blinkleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blinkleuchte"
        },
        "02830": {
            "GenartName": "Halter, Bremsleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Bremsleuchte"
        },
        "02831": {
            "GenartName": "Halter, Hauptscheinwerfer",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "02832": {
            "GenartName": "Halter, Nebelschlussleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelschlussleuchte"
        },
        "02833": {
            "GenartName": "Halter, Rückfahrleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Rückfahrleuchte"
        },
        "02834": {
            "GenartName": "Halter, Rückstrahler",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Rückstrahler"
        },
        "02835": {
            "GenartName": "Halter, Schlussleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Schlussleuchte"
        },
        "02836": {
            "GenartName": "Halter, Trockner",
            "NormTermName": "Halter",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Trockner"
        },
        "02837": {
            "GenartName": "Hochdruck-/Niederdruckleitung, Klimaanlage",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Hochdruck/Niederdruck"
        },
        "02838": {
            "GenartName": "Kappe, Arbeitsscheinwerfer",
            "NormTermName": "Kappe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "02839": {
            "GenartName": "Kappe, Fernscheinwerfer",
            "NormTermName": "Kappe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Fernscheinwerfer"
        },
        "02840": {
            "GenartName": "Kappe, Hauptscheinwerfer",
            "NormTermName": "Kappe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "02841": {
            "GenartName": "Kappe, Nebelscheinwerfer",
            "NormTermName": "Kappe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelscheinwerfer"
        },
        "02842": {
            "GenartName": "Kompressor, Klimaanlage",
            "NormTermName": "Kompressor",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": null
        },
        "02843": {
            "GenartName": "Kondensator, Klimaanlage",
            "NormTermName": "Kondensator",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": null
        },
        "02845": {
            "GenartName": "Lampenfassung, Arbeitsscheinwerfer",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "02846": {
            "GenartName": "Lampenfassung, Fernscheinwerfer",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Fernscheinwerfer"
        },
        "02847": {
            "GenartName": "Lampenfassung, Hauptscheinwerfer",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "02848": {
            "GenartName": "Lampenfassung, Nebelscheinwerfer",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelscheinwerfer"
        },
        "02849": {
            "GenartName": "Lampenträger, Blinkleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blinkleuchte"
        },
        "02850": {
            "GenartName": "Lampenträger, Bremsleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Bremsleuchte"
        },
        "02851": {
            "GenartName": "Lampenträger, Kennzeichenleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Kennzeichenleuchte"
        },
        "02852": {
            "GenartName": "Lampenträger, Rückfahrleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Rückfahrleuchte"
        },
        "02853": {
            "GenartName": "Lampenträger, Schlussleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Schlussleuchte"
        },
        "02854": {
            "GenartName": "Lenkstockschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Lenkstock"
        },
        "02855": {
            "GenartName": "Lichtscheibe, Begrenzungsleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Begrenzungsleuchte"
        },
        "02856": {
            "GenartName": "Lichtscheibe, Blinkleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blinkleuchte"
        },
        "02857": {
            "GenartName": "Lichtscheibe, Bremsleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Bremsleuchte"
        },
        "02858": {
            "GenartName": "Lichtscheibe, Heckleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Heckleuchte"
        },
        "02859": {
            "GenartName": "Lichtscheibe, Kennzeichenleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Kennzeichenleuchte"
        },
        "02860": {
            "GenartName": "Lichtscheibe, Nebelschlussleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelschlussleuchte"
        },
        "02861": {
            "GenartName": "Lichtscheibe, Positionsleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Positionsleuchte"
        },
        "02862": {
            "GenartName": "Lichtscheibe, Rückfahrleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Rückfahrleuchte"
        },
        "02863": {
            "GenartName": "Lichtscheibe, Rundumkennleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Rundumkennleuchte"
        },
        "02864": {
            "GenartName": "Lichtscheibe, Schlussleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Schlussleuchte"
        },
        "02865": {
            "GenartName": "Magnetkupplung, Klimakompressor",
            "NormTermName": "Kupplung",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Kompressor"
        },
        "02866": {
            "GenartName": "Montagesatz, Klimaanlage",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": null
        },
        "02867": {
            "GenartName": "Nachrüstsatz, Klimaanlage",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": null
        },
        "02868": {
            "GenartName": "Rahmen, Arbeitsscheinwerfer",
            "NormTermName": "Rahmen",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "02869": {
            "GenartName": "Rahmen, Fernscheinwerfer",
            "NormTermName": "Rahmen",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Fernscheinwerfer"
        },
        "02870": {
            "GenartName": "Rahmen, Hauptscheinwerfer",
            "NormTermName": "Rahmen",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "02871": {
            "GenartName": "Rahmen, Kühlergitter",
            "NormTermName": "Rahmen",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Kühlergitter"
        },
        "02872": {
            "GenartName": "Rahmen, Nebelscheinwerfer",
            "NormTermName": "Rahmen",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelscheinwerfer"
        },
        "02873": {
            "GenartName": "Rampenspiegel",
            "NormTermName": "Spiegel",
            "AssGrpName": "Fahrerhaus, Universal",
            "UsageName": "Rampe"
        },
        "02874": {
            "GenartName": "Reflektor, Arbeitsscheinwerfer",
            "NormTermName": "Reflektor",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "02875": {
            "GenartName": "Reflektor, Blinkleuchte",
            "NormTermName": "Reflektor",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blinkleuchte"
        },
        "02876": {
            "GenartName": "Reflektor, Fernscheinwerfer",
            "NormTermName": "Reflektor",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Fernscheinwerfer"
        },
        "02877": {
            "GenartName": "Reflektor, Hauptscheinwerfer",
            "NormTermName": "Reflektor",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "02878": {
            "GenartName": "Reflektor, Nebelscheinwerfer",
            "NormTermName": "Reflektor",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelscheinwerfer"
        },
        "02879": {
            "GenartName": "Reflektor, Positions-/Begrenzungsleuchte",
            "NormTermName": "Reflektor",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Positions-/Begrenzungsleuchte"
        },
        "02880": {
            "GenartName": "Reflektor, Rundumkennleuchte",
            "NormTermName": "Reflektor",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Rundumkennleuchte"
        },
        "02881": {
            "GenartName": "Reflektor, Suchscheinwerfer",
            "NormTermName": "Reflektor",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Suchscheinwerfer"
        },
        "02882": {
            "GenartName": "Regler, Wisch-Wasch-Intervall",
            "NormTermName": "Regler",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": "Wisch-Wasch-Intervall"
        },
        "02883": {
            "GenartName": "Relais, Fensterheber",
            "NormTermName": "Relais",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Fensterheber"
        },
        "02884": {
            "GenartName": "Relais, Gurt-Warnung",
            "NormTermName": "Relais",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Gurt-Warnung"
        },
        "02885": {
            "GenartName": "Relais, Heckscheibenheizung",
            "NormTermName": "Relais",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Heckscheibenheizung"
        },
        "02886": {
            "GenartName": "Relais, Nebelschlussleuchte",
            "NormTermName": "Relais",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelschlussleuchte"
        },
        "02887": {
            "GenartName": "Relais, Scheinwerferreinigung",
            "NormTermName": "Relais",
            "AssGrpName": "Scheinwerferreinigung, Universal",
            "UsageName": null
        },
        "02888": {
            "GenartName": "Relais, Wisch-Wasch-Intervall",
            "NormTermName": "Relais",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": "Wisch-Wasch-Intervall"
        },
        "02889": {
            "GenartName": "Rohrverbinder, Abgasanlage",
            "NormTermName": "Rohrverbinder",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": null
        },
        "02890": {
            "GenartName": "Stellelement, Leuchtweitenregulierung",
            "NormTermName": "Stellelement",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Leuchtweitenregulierung"
        },
        "02891": {
            "GenartName": "Sensorsatz, Xenonlichtumrüstung (Leuchtweitenregulierung)",
            "NormTermName": "Sensorsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Xenonlichtumrüstung (Leuchtweitenregulierung)"
        },
        "02892": {
            "GenartName": "Verzögerungsrelais, Anhängevorrichtung",
            "NormTermName": "Relais",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Zeitfunktion"
        },
        "02893": {
            "GenartName": "Streuscheibe, Fernscheinwerfer",
            "NormTermName": "Streuscheibe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Fernscheinwerfer"
        },
        "02894": {
            "GenartName": "Streuscheibe, Hauptscheinwerfer",
            "NormTermName": "Streuscheibe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "02895": {
            "GenartName": "Streuscheibe, Nebelscheinwerfer",
            "NormTermName": "Streuscheibe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelscheinwerfer"
        },
        "02896": {
            "GenartName": "Trockner, Klimaanlage",
            "NormTermName": "Trockner",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": null
        },
        "02897": {
            "GenartName": "Umrüstsatz, Klimaanlage",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": null
        },
        "02898": {
            "GenartName": "Verdampfer, Klimaanlage",
            "NormTermName": "Verdampfer",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": null
        },
        "02899": {
            "GenartName": "Verschluss, Kraftstoffbehälter",
            "NormTermName": "Verschluss",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Kraftstoffbehälter"
        },
        "02900": {
            "GenartName": "Waschwasserbehälter, Scheibenreinigung",
            "NormTermName": "Behälter",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": "Waschwasser"
        },
        "02901": {
            "GenartName": "Waschwasserdüse, Scheibenreinigung",
            "NormTermName": "Düse",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": "Waschwasser"
        },
        "02902": {
            "GenartName": "Waschwasserdüse, Scheinwerferreinigung",
            "NormTermName": "Düse",
            "AssGrpName": "Scheinwerferreinigung, Universal",
            "UsageName": "Waschwasser"
        },
        "02903": {
            "GenartName": "Waschwasserpumpe, Scheibenreinigung",
            "NormTermName": "Pumpe",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": "Waschwasser"
        },
        "02904": {
            "GenartName": "Waschwasserpumpe, Scheinwerferreinigung",
            "NormTermName": "Pumpe",
            "AssGrpName": "Scheinwerferreinigung, Universal",
            "UsageName": "Waschwasser"
        },
        "02905": {
            "GenartName": "Lenkersatz, Radaufhängung",
            "NormTermName": "Lenkersatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": null
        },
        "02906": {
            "GenartName": "Dichtring, Vorkammer",
            "NormTermName": "Dichtring",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Vorkammer"
        },
        "02907": {
            "GenartName": "Zünd-/Startschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Startanlage, Universal",
            "UsageName": "Zündung/Anlasser"
        },
        "02908": {
            "GenartName": "Leuchtenaufnahme",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Leuchten"
        },
        "02909": {
            "GenartName": "Halter, Kühlerlüfter",
            "NormTermName": "Halter",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Lüfter"
        },
        "02910": {
            "GenartName": "Antriebsriemen, Rundumkennleuchte",
            "NormTermName": "Riemen",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Antrieb Rundumkennleuchte"
        },
        "02911": {
            "GenartName": "Sicherungskasten",
            "NormTermName": "Kasten",
            "AssGrpName": "Bordnetz, Universal",
            "UsageName": "Sicherung"
        },
        "02913": {
            "GenartName": "Dichtungssatz, Kompressor",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Kompressor"
        },
        "02914": {
            "GenartName": "Spule, Magnetkupplung-Kompressor",
            "NormTermName": "Spule",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Magnetkupplung-Kompressor"
        },
        "02915": {
            "GenartName": "Lager, Kompressorwelle",
            "NormTermName": "Lager",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Kompressorwelle"
        },
        "02916": {
            "GenartName": "Anschlussflansch, Kompressor",
            "NormTermName": "Flansch",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kompressor"
        },
        "02917": {
            "GenartName": "Verschlusskappe, Kompressor",
            "NormTermName": "Kappe",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Kompressor"
        },
        "02918": {
            "GenartName": "Temperaturschalter, Klimalüfter",
            "NormTermName": "Schalter",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Klimalüfter"
        },
        "02919": {
            "GenartName": "Zylinderkopf, Kompressor",
            "NormTermName": "Zylinderkopf",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kompressor"
        },
        "02920": {
            "GenartName": "Regelventil, Kompressor",
            "NormTermName": "Ventil",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kompressor"
        },
        "02921": {
            "GenartName": "Dichtungssatz, Kompressorregelventil",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kompressorregelventil"
        },
        "02922": {
            "GenartName": "Schraube, Spannrolle",
            "NormTermName": "Schraube",
            "AssGrpName": "Riementrieb",
            "UsageName": "Spannrolle"
        },
        "02923": {
            "GenartName": "Distanzscheibe, Kompressorwelle",
            "NormTermName": "Distanzscheibe",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kompressorwelle"
        },
        "02924": {
            "GenartName": "Anschlussadapter, Kompressor",
            "NormTermName": "Adapter",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Kompressor"
        },
        "02925": {
            "GenartName": "Adapter, Klimaservicegerät",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Klimaservicegerät"
        },
        "02926": {
            "GenartName": "Halter, Kompressor",
            "NormTermName": "Halter",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kompressor"
        },
        "02927": {
            "GenartName": "Glühkerze, elektr. Zuheizer",
            "NormTermName": "Glühkerze",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Kühlwasservorwärmung"
        },
        "02929": {
            "GenartName": "Vorkatalysator",
            "NormTermName": "Katalysator",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasvorentgiftung"
        },
        "02930": {
            "GenartName": "Kettenspanner, Ölpumpenantrieb",
            "NormTermName": "Spanner",
            "AssGrpName": "Schmierung",
            "UsageName": "Kette-Ölpumpenantrieb"
        },
        "02931": {
            "GenartName": "Gleitschiene, Antriebskette-Ölpumpe",
            "NormTermName": "Gleitschiene",
            "AssGrpName": "Schmierung",
            "UsageName": "Kette-Ölpumpenantrieb"
        },
        "02932": {
            "GenartName": "Umrüstsatz, Handbremszug",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Handbremszug"
        },
        "02933": {
            "GenartName": "Filter, Kurbelgehäuseentlüftung",
            "NormTermName": "Filter",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "02934": {
            "GenartName": "Spannelement, Kettenspanner",
            "NormTermName": "Spannelement",
            "AssGrpName": "Schmierung",
            "UsageName": "Kettenspanner"
        },
        "02935": {
            "GenartName": "Spannelement, Kettenspanner",
            "NormTermName": "Spannelement",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Kettenspanner"
        },
        "02936": {
            "GenartName": "Spannschiene, Antriebskette-Ölpumpe",
            "NormTermName": "Spannschiene",
            "AssGrpName": "Schmierung",
            "UsageName": "Kette-Ölpumpenantrieb"
        },
        "02937": {
            "GenartName": "Spannschiene, Steuerkette",
            "NormTermName": "Spannschiene",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkette"
        },
        "02938": {
            "GenartName": "Spannschienenbelag, Steuerkette",
            "NormTermName": "Spannschienenbelag",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkette"
        },
        "02939": {
            "GenartName": "Spannschienensatz, Steuerkette",
            "NormTermName": "Spannschienensatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkette"
        },
        "02940": {
            "GenartName": "Spannschienenbelag, Antriebskette-Ölpumpe",
            "NormTermName": "Spannschienenbelag",
            "AssGrpName": "Schmierung",
            "UsageName": "Kette-Ölpumpenantrieb"
        },
        "02941": {
            "GenartName": "Spannschienensatz, Antriebskette-Ölpumpe",
            "NormTermName": "Spannschienensatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Kette-Ölpumpenantrieb"
        },
        "02942": {
            "GenartName": "Gleitschienenbelag, Antriebskette-Ölpumpe",
            "NormTermName": "Gleitschienenbelag",
            "AssGrpName": "Schmierung",
            "UsageName": "Kette-Ölpumpenantrieb"
        },
        "02943": {
            "GenartName": "Gleitschienensatz, Antriebskette-Ölpumpe",
            "NormTermName": "Gleitschienensatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Kette-Ölpumpenantrieb"
        },
        "02944": {
            "GenartName": "Kraftstofffilter",
            "NormTermName": "Filter",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Kraftstoff"
        },
        "02945": {
            "GenartName": "Druckstück, Einlass-/Auslassventil",
            "NormTermName": "Druckstück",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Einlass-/Auslassventil"
        },
        "02948": {
            "GenartName": "Tonfolgerelais, Starktonhorn",
            "NormTermName": "Relais",
            "AssGrpName": "Signalanlage",
            "UsageName": "Starktonhorn"
        },
        "02949": {
            "GenartName": "Bodendisplay",
            "NormTermName": "Verkaufsdisplay",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Boden"
        },
        "02950": {
            "GenartName": "Thekendisplay",
            "NormTermName": "Verkaufsdisplay",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Theke"
        },
        "02951": {
            "GenartName": "Ansauggebläse, Innenraumluft",
            "NormTermName": "Gebläse",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Ansaugung Innenraumluft"
        },
        "02952": {
            "GenartName": "Umschaltrelais, Fernlicht-Abblendlicht",
            "NormTermName": "Relais",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Umschaltung Fernlicht-Abblendlicht"
        },
        "02953": {
            "GenartName": "Getriebe, Elektromotor-Schiebedach",
            "NormTermName": "Getriebe",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Elektromotor-Schiebedach"
        },
        "02955": {
            "GenartName": "Umrüstsatz-Stoßdämpfer, Niveauregulierung",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Niveauregulierung"
        },
        "02956": {
            "GenartName": "Kontrollleuchte",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Kontrollanzeige (Leuchte)"
        },
        "02957": {
            "GenartName": "Spannungswandler",
            "NormTermName": "Spannungswandler",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "02958": {
            "GenartName": "Heizfolie, Außenspiegel",
            "NormTermName": "Folie",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "beheizbarer Außenspiegel"
        },
        "02959": {
            "GenartName": "Heizfolie, Außenspiegel",
            "NormTermName": "Folie",
            "AssGrpName": "Fahrerhaus, Universal",
            "UsageName": "beheizbarer Außenspiegel"
        },
        "02960": {
            "GenartName": "Weitwinkelspiegel",
            "NormTermName": "Spiegel",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Weitwinkel"
        },
        "02961": {
            "GenartName": "Weitwinkelspiegel",
            "NormTermName": "Spiegel",
            "AssGrpName": "Fahrerhaus, Universal",
            "UsageName": "Weitwinkel"
        },
        "02962": {
            "GenartName": "Schalter, Schiebedach",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Schiebedach"
        },
        "02963": {
            "GenartName": "Schalter, Schiebedach",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Schiebedach"
        },
        "02964": {
            "GenartName": "Schalter, Kindersicherung (Türverriegelung)",
            "NormTermName": "Schalter",
            "AssGrpName": "Schließanlage",
            "UsageName": "Kindersicherung/ Türverriegelung"
        },
        "02965": {
            "GenartName": "Schalter, Motorbremse",
            "NormTermName": "Schalter",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Motorbremse"
        },
        "02966": {
            "GenartName": "Kontrollschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kontrollschalter"
        },
        "02967": {
            "GenartName": "Sturzkorrekturschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Sturzeinstellung"
        },
        "02968": {
            "GenartName": "Blende, Heckklappe",
            "NormTermName": "Blende",
            "AssGrpName": "Karosserie",
            "UsageName": "Heckklappe"
        },
        "02970": {
            "GenartName": "Außenspiegel-Satz",
            "NormTermName": "Spiegelsatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegel"
        },
        "02971": {
            "GenartName": "Kondensator, Zündspannung-Airbag",
            "NormTermName": "Kondensator",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Zündspannung-Airbag"
        },
        "02972": {
            "GenartName": "Spannungswandler, Zündspannung-Airbag",
            "NormTermName": "Spannungswandler",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Zündspannung-Airbag"
        },
        "02973": {
            "GenartName": "Steuergerät, Sitzbelegungserkennung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Sitzbelegungserkennung"
        },
        "02974": {
            "GenartName": "Gebläserad, Innenraumgebläse",
            "NormTermName": "Lüfterrad",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumgebläse"
        },
        "02975": {
            "GenartName": "Widerstand, Innenraumgebläse",
            "NormTermName": "Widerstand",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumgebläse"
        },
        "02976": {
            "GenartName": "Halter, Zusatzscheinwerfer",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Zusatzscheinwerfer"
        },
        "02977": {
            "GenartName": "Haltersatz, Zusatzscheinwerfer",
            "NormTermName": "Haltersatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Zusatzscheinwerfer"
        },
        "02978": {
            "GenartName": "Halter, Zusatzscheinwerfer",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Zusatzscheinwerfer"
        },
        "02979": {
            "GenartName": "Haltersatz, Zusatzscheinwerfer",
            "NormTermName": "Haltersatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Zusatzscheinwerfer"
        },
        "02980": {
            "GenartName": "Hilfsrahmen/Aggregateträger",
            "NormTermName": "Rahmen",
            "AssGrpName": "Karosserie",
            "UsageName": "Aufnahme Aggregate"
        },
        "02981": {
            "GenartName": "Kühlergittereinsatz",
            "NormTermName": "Einsatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühlergitter"
        },
        "02982": {
            "GenartName": "Kaltabfrageadapter",
            "NormTermName": "Adapter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kaltabfrage"
        },
        "02983": {
            "GenartName": "Umrüstsatz, Scheinwerfer",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Umrüstung"
        },
        "02984": {
            "GenartName": "Versorgungsbatterie",
            "NormTermName": "Batterie",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Versorgungsbatterie"
        },
        "02985": {
            "GenartName": "Glühkerze, Standheizung",
            "NormTermName": "Glühkerze",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Standheizung"
        },
        "02986": {
            "GenartName": "Glühkerze, Standheizung",
            "NormTermName": "Glühkerze",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Standheizung"
        },
        "02987": {
            "GenartName": "Reinigungsset, Radnabe",
            "NormTermName": "Reinigungswerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Radnabe"
        },
        "02988": {
            "GenartName": "Adapter, Wischblatt",
            "NormTermName": "Adapter",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Wischblatt"
        },
        "02989": {
            "GenartName": "Ritzel, Zündverteiler",
            "NormTermName": "Ritzel",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteiler"
        },
        "02990": {
            "GenartName": "Abdeckblech, Staubschutz-Radlager",
            "NormTermName": "Blech",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Staubschutz-Radlager"
        },
        "02991": {
            "GenartName": "Glühlampe, Türsicherungsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Türsicherungsleuchte"
        },
        "02992": {
            "GenartName": "Glühlampe, Nebel-/Schlusslicht",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebel-/Schlusslicht"
        },
        "02993": {
            "GenartName": "Glühlampe, Türsicherungsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Türsicherungsleuchte"
        },
        "02994": {
            "GenartName": "Glühlampe, Nebel-/Schlusslicht",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebel-/Schlusslicht"
        },
        "02995": {
            "GenartName": "Distanzplatte, Zylinderkopf",
            "NormTermName": "Distanzplatte",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Zylinderkopf"
        },
        "02996": {
            "GenartName": "Lagerung, Hilfsrahmen/Aggregateträger",
            "NormTermName": "Lagerung",
            "AssGrpName": "Karosserie",
            "UsageName": "Hilfrahmen/Aggregateträger"
        },
        "02997": {
            "GenartName": "Glühlampe, Hauptlichtschalter",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptlichtschalter"
        },
        "02998": {
            "GenartName": "Glühlampe, Luftdüsenregulierung (Innenraumbelüftung)",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Luftdüsenregulierung"
        },
        "02999": {
            "GenartName": "Glühlampe, Klimaregulierung",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Klimaregulierung"
        },
        "03000": {
            "GenartName": "Glühlampe, Gebläseschalter",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Gebläseschalter"
        },
        "03001": {
            "GenartName": "Glühlampe, Automatikschaltkulisse",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Automatikschaltkulisse"
        },
        "03002": {
            "GenartName": "Glühlampe, Handschuhfachleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Handschuhfachleuchte"
        },
        "03003": {
            "GenartName": "Glühlampe, Gurtwarnung",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Gurtwarnung"
        },
        "03004": {
            "GenartName": "Glühlampe, Leseleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Leseleuchte"
        },
        "03005": {
            "GenartName": "Glühlampe, Einstiegsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Einstiegsleuchte"
        },
        "03006": {
            "GenartName": "Distanzscheibe, Bremswelle",
            "NormTermName": "Distanzscheibe",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremswelle"
        },
        "03007": {
            "GenartName": "Distanzscheibe, Gestängesteller",
            "NormTermName": "Distanzscheibe",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Gestängesteller"
        },
        "03008": {
            "GenartName": "Anschlagscheibe, Gestängesteller",
            "NormTermName": "Anschlagscheibe",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Gestängesteller"
        },
        "03009": {
            "GenartName": "Halteblech, Bremsbackenbolzen",
            "NormTermName": "Halteblech",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbackenbolzen"
        },
        "03010": {
            "GenartName": "Membran, Federspeicherzylinder",
            "NormTermName": "Membran",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Federspeicherzylinder"
        },
        "03011": {
            "GenartName": "Dichtring, Antriebswellenlagerung",
            "NormTermName": "Dichtring",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswellenlagerung"
        },
        "03012": {
            "GenartName": "Nachrüstsatz, Vorkatalysator",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Vorkatalysator"
        },
        "03013": {
            "GenartName": "Dichtring, Radnabe-Planetengetriebe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Radantrieb",
            "UsageName": "Radnabe-Planetengetriebe"
        },
        "03014": {
            "GenartName": "Reparatursatz, Antriebswellenlagerung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswellenlagerung"
        },
        "03015": {
            "GenartName": "Wellendichtring, Antriebswelle",
            "NormTermName": "Dichtring",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "03016": {
            "GenartName": "Dichtring, Achsschenkel",
            "NormTermName": "Dichtring",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsschenkel"
        },
        "03017": {
            "GenartName": "Dichtring, Traghülse-Reifendruckregelanlage",
            "NormTermName": "Dichtring",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Traghülse-Reifendruckregelanlage"
        },
        "03018": {
            "GenartName": "Dichtring, Verbindungsstück-Reifendruckregelanlage",
            "NormTermName": "Dichtring",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Verbindungsstück-Reifendruckregelanlage"
        },
        "03019": {
            "GenartName": "Lager, Achsschenkel",
            "NormTermName": "Lager",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsschenkel"
        },
        "03020": {
            "GenartName": "Verschlussscheibe, Achsschenkel",
            "NormTermName": "Verschlussscheibe",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsschenkel"
        },
        "03021": {
            "GenartName": "Kugelkopf, Zugstange-Luftfederventil",
            "NormTermName": "Kugelkopf",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Zugstange-Luftfederventil"
        },
        "03022": {
            "GenartName": "Kugelgelenk, Zugstange-Wegsensor",
            "NormTermName": "Gelenk",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Zugstange-Wegsensor"
        },
        "03023": {
            "GenartName": "Prüfanschluss, Druckminderventil",
            "NormTermName": "Prüfanschluss",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckminderventil"
        },
        "03024": {
            "GenartName": "Prüfanschluss, Luftfederbalg",
            "NormTermName": "Prüfanschluss",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederbalg"
        },
        "03025": {
            "GenartName": "Prüfanschluss, Relaisventil",
            "NormTermName": "Prüfanschluss",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Relaisventil"
        },
        "03026": {
            "GenartName": "Distanzring, Federsattel-Konsole",
            "NormTermName": "Distanzring",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federsattel-Konsole"
        },
        "03027": {
            "GenartName": "Distanzrohr, Federauge",
            "NormTermName": "Distanzrohr",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federauge"
        },
        "03028": {
            "GenartName": "Distanzrohr, Federbock",
            "NormTermName": "Distanzrohr",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbock"
        },
        "03029": {
            "GenartName": "Distanzrohr, Federbügel",
            "NormTermName": "Distanzrohr",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbügel"
        },
        "03030": {
            "GenartName": "Distanzrohr, Federklammer",
            "NormTermName": "Distanzrohr",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federklammer"
        },
        "03031": {
            "GenartName": "Distanzrohr, Federlasche",
            "NormTermName": "Distanzrohr",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federlasche"
        },
        "03032": {
            "GenartName": "Distanzrohr, Parabelfeder-Gummilager",
            "NormTermName": "Distanzrohr",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Parabelfeder-Gummilager"
        },
        "03033": {
            "GenartName": "Distanzrohr, Schwenkhebel-Federbock",
            "NormTermName": "Distanzrohr",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Schwenkhebel-Federbock"
        },
        "03034": {
            "GenartName": "Distanzrohr, Stoßdämpfer",
            "NormTermName": "Distanzrohr",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Stoßdämpfer"
        },
        "03035": {
            "GenartName": "Distanzrohr, Stoßdämpfer-Halter",
            "NormTermName": "Distanzrohr",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Stoßdämpfer-Halter"
        },
        "03036": {
            "GenartName": "Distanzscheibe, Federbock",
            "NormTermName": "Distanzscheibe",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbock"
        },
        "03037": {
            "GenartName": "Distanzscheibe, Federsattel",
            "NormTermName": "Distanzscheibe",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federsattel"
        },
        "03038": {
            "GenartName": "Achsfangseil",
            "NormTermName": "Seil",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Achsfangseil"
        },
        "03039": {
            "GenartName": "Bolzen, Achsfangseil-Lagerbock",
            "NormTermName": "Bolzen",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Achsfangseil-Lagerbock"
        },
        "03040": {
            "GenartName": "Buchse, Federauge",
            "NormTermName": "Buchse",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federauge"
        },
        "03041": {
            "GenartName": "Buchse, Federbock",
            "NormTermName": "Buchse",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbock"
        },
        "03042": {
            "GenartName": "Buchse, Führungsfeder",
            "NormTermName": "Buchse",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Führungsfeder"
        },
        "03043": {
            "GenartName": "Buchse, Stoßdämpfer",
            "NormTermName": "Buchse",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Stoßdämpfer"
        },
        "03044": {
            "GenartName": "Dichtring, Federsattel",
            "NormTermName": "Dichtring",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federsattel"
        },
        "03045": {
            "GenartName": "Druckscheibe, Federbock",
            "NormTermName": "Druckscheibe",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbock"
        },
        "03046": {
            "GenartName": "Spreizkeileinheit, Trommelbremse",
            "NormTermName": "Spreizkeileinheit",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Trommelbremse"
        },
        "03047": {
            "GenartName": "Stufenscheibe, Gestängesteller",
            "NormTermName": "Stufenscheibe",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Gestängesteller"
        },
        "03048": {
            "GenartName": "Reparatursatz, Federbolzen-Lasche",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Lasche an Schwinge Nachlaufachse"
        },
        "03049": {
            "GenartName": "Dichtring, Bremszylinder-Bremsbackenträger",
            "NormTermName": "Dichtring",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremszylinder-Bremsbackenträger"
        },
        "03050": {
            "GenartName": "Dichtung, Achskappe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achskappe"
        },
        "03051": {
            "GenartName": "Federbalg, Luftfederung-Achsanhebung",
            "NormTermName": "Federbalg",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederung-Achsanhebung"
        },
        "03052": {
            "GenartName": "Kugelpfanne, Zugstange-Luftfederventil",
            "NormTermName": "Kugelpfanne",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Zugstange-Luftfederventil"
        },
        "03053": {
            "GenartName": "Reparatursatz, Steckverbinder-Druckluftanlage",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Steckverbinder-Druckluftanlage"
        },
        "03054": {
            "GenartName": "Dichtung, Federsatteldeckel",
            "NormTermName": "Dichtung",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federsatteldeckel"
        },
        "03055": {
            "GenartName": "Federgehänge, Schwinge",
            "NormTermName": "Federgehänge",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Feder an Schwinge"
        },
        "03056": {
            "GenartName": "Federlager, Achskörper",
            "NormTermName": "Lager",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Feder an Achskörper"
        },
        "03057": {
            "GenartName": "Federlager, Dreieckslenker",
            "NormTermName": "Lager",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Feder an Dreieckslenker"
        },
        "03058": {
            "GenartName": "Federlager, Rahmen",
            "NormTermName": "Lager",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Feder an Rahmen"
        },
        "03059": {
            "GenartName": "Lager, Federauge",
            "NormTermName": "Lager",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federauge"
        },
        "03060": {
            "GenartName": "Lager, Parabelfeder",
            "NormTermName": "Lager",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Parabelfeder"
        },
        "03061": {
            "GenartName": "Lager, Schwenkhebel",
            "NormTermName": "Lager",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Schwenkhebel"
        },
        "03062": {
            "GenartName": "Puffer, Federauge",
            "NormTermName": "Puffer",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federauge"
        },
        "03063": {
            "GenartName": "Puffer, Federblatt",
            "NormTermName": "Puffer",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federblatt"
        },
        "03064": {
            "GenartName": "Puffer, Federplatte",
            "NormTermName": "Puffer",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federplatte"
        },
        "03065": {
            "GenartName": "Puffer, Schwenkhebel",
            "NormTermName": "Puffer",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Schwenkhebel"
        },
        "03066": {
            "GenartName": "Automatikgetriebe",
            "NormTermName": "Getriebe",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "03067": {
            "GenartName": "Ventileinheit, Hydraulikaggregat-Autom.Getr.",
            "NormTermName": "Ventileinheit",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Hydraulikaggregat-Autom.Getr."
        },
        "03068": {
            "GenartName": "Drehmomentwandler",
            "NormTermName": "Drehmomentwandler",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "03069": {
            "GenartName": "Automatikgetriebeöl",
            "NormTermName": "Öl",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "03070": {
            "GenartName": "Belaglamellensatz, Lamellenkupplung (Automatikgetriebe)",
            "NormTermName": "Belaglamellensatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Lamellenkupplung (Automatikgetriebe)"
        },
        "03071": {
            "GenartName": "Stahllamellensatz, Automatikgetriebe",
            "NormTermName": "Stahllamellensatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "03072": {
            "GenartName": "Mechatronik, Automatikgetriebe",
            "NormTermName": "Mechatronik",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "03073": {
            "GenartName": "Abstützscheibe, Ventilstößel",
            "NormTermName": "Abstützscheibe",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ventilstößel"
        },
        "03074": {
            "GenartName": "Montagewerkzeug, Trag-/Führungsgelenk",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "03075": {
            "GenartName": "Montagewerkzeug, Faltenbalg",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Universal-Achs-/Lenkmanschette"
        },
        "03077": {
            "GenartName": "Sensor, Batterietemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Startanlage",
            "UsageName": "Batterietemperatur"
        },
        "03078": {
            "GenartName": "Sensor, Batterietemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Batterietemperatur"
        },
        "03079": {
            "GenartName": "Startergenerator",
            "NormTermName": "Startergenerator",
            "AssGrpName": "Startergenerator",
            "UsageName": null
        },
        "03080": {
            "GenartName": "Reparatursatz, Lenkgetriebe",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkgetriebe"
        },
        "03081": {
            "GenartName": "Zubehörsatz, Lenkgetriebe",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkgetriebe"
        },
        "03082": {
            "GenartName": "Antenne",
            "NormTermName": "Antenne",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Autoradio"
        },
        "03083": {
            "GenartName": "Antennenkopf",
            "NormTermName": "Antennenkopf",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "03084": {
            "GenartName": "Antennenteleskop",
            "NormTermName": "Antennenteleskop",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "03085": {
            "GenartName": "Antennenkabel",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Antenne"
        },
        "03086": {
            "GenartName": "Antennenverstärker",
            "NormTermName": "Verstärker",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Antenne"
        },
        "03087": {
            "GenartName": "Navigationssystem",
            "NormTermName": "Navigationssystem",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "03088": {
            "GenartName": "Software, Navigationssystem",
            "NormTermName": "Software",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Navigationssystem"
        },
        "03089": {
            "GenartName": "CD-ROM-Strassenkarte",
            "NormTermName": "Software",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "CD-ROM-Strassenkarte"
        },
        "03090": {
            "GenartName": "Audio-Verstärker",
            "NormTermName": "Verstärker",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Audio-Verstärker"
        },
        "03091": {
            "GenartName": "Lautsprechersystem",
            "NormTermName": "Lautsprechersystem",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "03092": {
            "GenartName": "Cassetten-Radio",
            "NormTermName": "Autoradio",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Radio-Cassette"
        },
        "03093": {
            "GenartName": "Minidisc-Radio",
            "NormTermName": "Autoradio",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Radio-Minidisc"
        },
        "03094": {
            "GenartName": "Radio/CD-Wechsler-Kombination",
            "NormTermName": "Radio/CD-Wechsler-Kombination",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "03095": {
            "GenartName": "Minidisc-Radio/CD-Wechsler-Kombination",
            "NormTermName": "Radio/CD-Wechsler-Kombination",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Radio-Minidisc"
        },
        "03096": {
            "GenartName": "Wasserpumpe + Zahnriemensatz",
            "NormTermName": "Wasserpumpen-/Zahnriemensatz",
            "AssGrpName": "Kühlung",
            "UsageName": null
        },
        "03097": {
            "GenartName": "Sensor, Längs-/Querbeschleunigung",
            "NormTermName": "Sensor",
            "AssGrpName": "Fahrdynamikregelung",
            "UsageName": "Längs-/Querbeschleunigung"
        },
        "03101": {
            "GenartName": "Zusatzblinkleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Zusatzblinkleuchte"
        },
        "03102": {
            "GenartName": "Kraftstoffwasserabscheider",
            "NormTermName": "Abscheider",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Wasser"
        },
        "03103": {
            "GenartName": "Lenkung",
            "NormTermName": "Grafik",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "03104": {
            "GenartName": "Radaufhängung",
            "NormTermName": "Grafik",
            "AssGrpName": "Radaufhängung",
            "UsageName": null
        },
        "03105": {
            "GenartName": "Ölwanne, Automatikgetriebe",
            "NormTermName": "Ölwanne",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "03106": {
            "GenartName": "Dichtung, Ölwanne-Automatikgetriebe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölwanne-Automatikgetriebe"
        },
        "03108": {
            "GenartName": "Dichtung, Waschwasserpumpe/Waschwasserbehälter",
            "NormTermName": "Dichtung",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasserpumpe/Waschwasserbehälter"
        },
        "03109": {
            "GenartName": "Dichtung, Einstiegsleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Einstiegsleuchte"
        },
        "03110": {
            "GenartName": "Dichtung, Schlussleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Schlussleuchte"
        },
        "03111": {
            "GenartName": "Dichtung, Nebelschlussleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelschlussleuchte"
        },
        "03112": {
            "GenartName": "Dichtung, Rückfahrleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Rückfahrleuchte"
        },
        "03113": {
            "GenartName": "Dichtung, Kennzeichenleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kennzeichenleuchte"
        },
        "03114": {
            "GenartName": "Dichtung, Heckleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Heckleuchte"
        },
        "03115": {
            "GenartName": "Dichtung, Innenraumleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Innenraumleuchte"
        },
        "03116": {
            "GenartName": "Dichtung, Seitenmarkierungsleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "03117": {
            "GenartName": "Dichtung, Kofferraumleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kofferraumleuchte"
        },
        "03118": {
            "GenartName": "Dichtung, Türsicherungsleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Türsicherungsleuchte"
        },
        "03119": {
            "GenartName": "Dichtung, Motorraumleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Motorraumleuchte"
        },
        "03120": {
            "GenartName": "Dichtung, Parkleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Parkleuchte"
        },
        "03121": {
            "GenartName": "Dichtung, Tagfahrleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Tagfahrleuchte"
        },
        "03122": {
            "GenartName": "Dichtung, Umrissleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Umrissleuchte"
        },
        "03123": {
            "GenartName": "Lagersatz, Schaltgetriebe",
            "NormTermName": "Lagersatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "03124": {
            "GenartName": "Dichtung, Kennzeichenleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Kennzeichenleuchte"
        },
        "03125": {
            "GenartName": "Dichtung, Heckleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Heckleuchte"
        },
        "03126": {
            "GenartName": "Dichtung, Rückfahrleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Rückfahrleuchte"
        },
        "03127": {
            "GenartName": "Dichtung, Türsicherungsleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Türsicherungsleuchte"
        },
        "03128": {
            "GenartName": "Dichtung, Umrissleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Umrissleuchte"
        },
        "03129": {
            "GenartName": "Dichtung, Schlussleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Schlussleuchte"
        },
        "03130": {
            "GenartName": "Dichtung, Tagfahrleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Tagfahrleuchte"
        },
        "03131": {
            "GenartName": "Dichtung, Begrenzungsleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Begrenzungsleuchte"
        },
        "03132": {
            "GenartName": "Dichtung, Innenraumleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Innenraumleuchte"
        },
        "03133": {
            "GenartName": "Dichtung, Nebelschlussleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelschlussleuchte"
        },
        "03134": {
            "GenartName": "Dichtung, Parkleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Parkleuchte"
        },
        "03135": {
            "GenartName": "Dichtung, Rundumkennleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Signalanlage",
            "UsageName": "Rundumkennleuchte"
        },
        "03136": {
            "GenartName": "Dichtung, Zusatzbremsleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Signalanlage",
            "UsageName": "Zusatzbremsleuchte"
        },
        "03137": {
            "GenartName": "Dichtung, Bremsleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Signalanlage",
            "UsageName": "Bremsleuchte"
        },
        "03139": {
            "GenartName": "Dichtung, Zusatzbremsleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Zusatzbremsleuchte"
        },
        "03140": {
            "GenartName": "Dichtung, Blinkleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blinkleuchte"
        },
        "03141": {
            "GenartName": "Dichtung, Zusatzblinkleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Zusatzblinkleuchte"
        },
        "03142": {
            "GenartName": "Dichtung, Bremsleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Bremsleuchte"
        },
        "03143": {
            "GenartName": "Dichtung, Rundumkennleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Rundumkennleuchte"
        },
        "03144": {
            "GenartName": "Dichtung, Blitz-Kennleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blitz-Kennleuchte"
        },
        "03145": {
            "GenartName": "Ausgleichsbehälter, Hydrauliköl-Servolenkung",
            "NormTermName": "Behälter",
            "AssGrpName": "Lenkung",
            "UsageName": "Hydrauliköl"
        },
        "03146": {
            "GenartName": "Wechselventil, Bremsdruck",
            "NormTermName": "Ventil",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsdruck"
        },
        "03147": {
            "GenartName": "Wechselventil, Kupplungsbetätigung",
            "NormTermName": "Ventil",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsbetätigung"
        },
        "03148": {
            "GenartName": "Polieranlage",
            "NormTermName": "Polieranlage",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "03149": {
            "GenartName": "Schleuderstrahlanlage",
            "NormTermName": "Strahlanlage",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Schleuderstrahl"
        },
        "03150": {
            "GenartName": "Strahlkabine",
            "NormTermName": "Strahlanlage",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Strahlkabine"
        },
        "03151": {
            "GenartName": "Öl-/Fettabscheider",
            "NormTermName": "Abscheider",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Öl/Fett"
        },
        "03153": {
            "GenartName": "Montagesatz, Abgaskrümmer",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgaskrümmer"
        },
        "03154": {
            "GenartName": "Weitwinkellinse",
            "NormTermName": "Linse",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Weitwinkel"
        },
        "03155": {
            "GenartName": "Spiegelkopf, Caravanspiegel",
            "NormTermName": "Spiegelkopf",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Caravanspiegel"
        },
        "03156": {
            "GenartName": "Spannband, Caravanspiegel",
            "NormTermName": "Spannband",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Caravanspiegel"
        },
        "03157": {
            "GenartName": "Spannhaken, Caravanspiegel",
            "NormTermName": "Haken",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Caravanspiegel"
        },
        "03158": {
            "GenartName": "Saugnapf, Befestigung-Caravanspiegel",
            "NormTermName": "Saugnapf",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Befestigung-Caravanspiegel"
        },
        "03159": {
            "GenartName": "Klemmstück, Caravanspiegel",
            "NormTermName": "Klemmstück",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Caravanspiegel"
        },
        "03160": {
            "GenartName": "Befestigungswinkel, Caravanspiegel",
            "NormTermName": "Befestigungswinkel",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Caravanspiegel"
        },
        "03161": {
            "GenartName": "Schutzfolie",
            "NormTermName": "Folie",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Schutzfolie"
        },
        "03162": {
            "GenartName": "Kinder-Sicherheitskopfstütze",
            "NormTermName": "Stütze",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Kinder-Sicherheitskopfstütze"
        },
        "03163": {
            "GenartName": "Heckträger, Universal",
            "NormTermName": "Träger",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Heckträger"
        },
        "03164": {
            "GenartName": "Crimpverbinder",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Crimpverbindung"
        },
        "03165": {
            "GenartName": "Lötverbinder",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Lötverbindung"
        },
        "03166": {
            "GenartName": "Quetschverbinder",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Quetschverbindung"
        },
        "03167": {
            "GenartName": "Steckgehäuse",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Steckgehäuse"
        },
        "03168": {
            "GenartName": "Buchse, Lenkstockhebel",
            "NormTermName": "Buchse",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkstockhebel"
        },
        "03169": {
            "GenartName": "Steinschlagschutz, Lenker",
            "NormTermName": "Steinschlagschutz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Lenker"
        },
        "03170": {
            "GenartName": "Formblech, Federauge",
            "NormTermName": "Blech",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Formblech an Federauge"
        },
        "03171": {
            "GenartName": "Formblech, Federbock",
            "NormTermName": "Blech",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Formblech-Federbock"
        },
        "03172": {
            "GenartName": "Puffer, Abrollkolben-Luftfederbalg",
            "NormTermName": "Puffer",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Abrollkolben-Luftfederbalg"
        },
        "03173": {
            "GenartName": "Keilschraube, Federbolzen",
            "NormTermName": "Schraube",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbolzensicherung"
        },
        "03174": {
            "GenartName": "Blinkleuchtensatz",
            "NormTermName": "Leuchtensatz",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinkleuchte"
        },
        "03175": {
            "GenartName": "Hauptscheinwerfersatz",
            "NormTermName": "Scheinwerfersatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "03176": {
            "GenartName": "Heckleuchtensatz",
            "NormTermName": "Leuchtensatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Heckleuchte"
        },
        "03177": {
            "GenartName": "Lagerbuchse, Federgehänge-Federbock",
            "NormTermName": "Buchse",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federgehänge-Federbock"
        },
        "03178": {
            "GenartName": "Lasche, Federbock",
            "NormTermName": "Lasche",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbock"
        },
        "03179": {
            "GenartName": "Lasche, Schwinge",
            "NormTermName": "Lasche",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Feder an Schwinge"
        },
        "03180": {
            "GenartName": "Bolzen, Ausrückgabel",
            "NormTermName": "Bolzen",
            "AssGrpName": "Kupplung",
            "UsageName": "Ausrückgabel"
        },
        "03181": {
            "GenartName": "Schraube, Druckplatte",
            "NormTermName": "Schraube",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsdruckplatte"
        },
        "03182": {
            "GenartName": "Distanzring, Drehstab",
            "NormTermName": "Distanzring",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Drehstab"
        },
        "03183": {
            "GenartName": "Distanzrohr, Drehstab",
            "NormTermName": "Distanzrohr",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Drehstab"
        },
        "03184": {
            "GenartName": "Distanzscheibe, Drehstab",
            "NormTermName": "Distanzscheibe",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Drehstab"
        },
        "03185": {
            "GenartName": "Distanzscheibe, Verbindungsstange",
            "NormTermName": "Distanzscheibe",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Verbindungsstange"
        },
        "03186": {
            "GenartName": "Dichtring, Drehstab",
            "NormTermName": "Dichtring",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Drehstab"
        },
        "03187": {
            "GenartName": "Gelenklager, Drehstab",
            "NormTermName": "Gelenklager",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Drehstab"
        },
        "03188": {
            "GenartName": "Gelenklager, Verbindungsstange",
            "NormTermName": "Gelenklager",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Verbindungsstange"
        },
        "03189": {
            "GenartName": "Buchse, Verbindungsstange",
            "NormTermName": "Buchse",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Verbindungsstange"
        },
        "03190": {
            "GenartName": "Anschlag-/Befestigungspuffer",
            "NormTermName": "Puffer",
            "AssGrpName": "Normteile",
            "UsageName": "Anschlag-/Befestigungspuffer"
        },
        "03191": {
            "GenartName": "Flexrohr, Abgasanlage",
            "NormTermName": "Rohr",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Flexrohr"
        },
        "03192": {
            "GenartName": "Adapter, Bremsleitung",
            "NormTermName": "Adapter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsleitung"
        },
        "03193": {
            "GenartName": "Glühlampe, Blink-/Begrenzungsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Blink-/Begrenzungsleuchte"
        },
        "03194": {
            "GenartName": "Schweißtrennmittel",
            "NormTermName": "Schweißtrennmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "03195": {
            "GenartName": "Enteiser",
            "NormTermName": "Enteiser",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "03196": {
            "GenartName": "Starthilfespray",
            "NormTermName": "Starthilfespray",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "03197": {
            "GenartName": "Elektroschutzmittel",
            "NormTermName": "Isolationsmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Elektroschutz"
        },
        "03200": {
            "GenartName": "Steckverteiler",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Steckverteiler"
        },
        "03201": {
            "GenartName": "Kettenspray",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kettenspray"
        },
        "03202": {
            "GenartName": "Kugelgelenk, Achsstrebe",
            "NormTermName": "Gelenk",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsstrebe"
        },
        "03203": {
            "GenartName": "Reparatursatz, Zentralgelenk-Dreieckslenker",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Zentralgelenk-Dreieckslenker"
        },
        "03204": {
            "GenartName": "Zentralgelenk, Dreieckslenker",
            "NormTermName": "Gelenk",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Zentralgelenk-Dreieckslenker"
        },
        "03206": {
            "GenartName": "Verschleißplatte, Blattfeder",
            "NormTermName": "Verschleißplatte",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Blattfeder"
        },
        "03207": {
            "GenartName": "Laufring, Schwungrad",
            "NormTermName": "Laufring",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Schwungrad"
        },
        "03208": {
            "GenartName": "Ventilfedersatz",
            "NormTermName": "Federsatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ventil"
        },
        "03209": {
            "GenartName": "Taxi-Kennleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Taxi-Kennleuchte"
        },
        "03210": {
            "GenartName": "Taxi-Kennleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Taxi-Kennleuchte"
        },
        "03211": {
            "GenartName": "Glühlampe, Warnleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Warnleuchte"
        },
        "03212": {
            "GenartName": "Glühlampe, Schalter",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Schalter"
        },
        "03213": {
            "GenartName": "Riemenscheibe, Kurbelwelle",
            "NormTermName": "Riemenscheibe",
            "AssGrpName": "Riementrieb",
            "UsageName": "Kurbelwelle"
        },
        "03214": {
            "GenartName": "Zentrierring, Federbügel",
            "NormTermName": "Zentrierring",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbügel"
        },
        "03215": {
            "GenartName": "Lager, Federbock",
            "NormTermName": "Lager",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbock"
        },
        "03216": {
            "GenartName": "Reparatursatz, Fahrerhausstabilisator",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhausstabilisator"
        },
        "03217": {
            "GenartName": "Schraube, Zahnkranzträger-Schwungrad",
            "NormTermName": "Schraube",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Zahnkranzträger-Schwungrad"
        },
        "03219": {
            "GenartName": "Kühlmittelflansch",
            "NormTermName": "Flansch",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelkreislauf"
        },
        "03220": {
            "GenartName": "Griff, Motorhaubenentriegelung",
            "NormTermName": "Griff",
            "AssGrpName": "Schließanlage",
            "UsageName": "Motorhaubenentriegelung"
        },
        "03221": {
            "GenartName": "Entriegelungswerkzeug",
            "NormTermName": "Entriegelungswerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "03222": {
            "GenartName": "Crimpzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Crimpverbindung"
        },
        "03223": {
            "GenartName": "Crimpzangeneinsatz-Set",
            "NormTermName": "Einsatz-Set",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Crimpzange"
        },
        "03224": {
            "GenartName": "Motoröl",
            "NormTermName": "Öl",
            "AssGrpName": "Schmierung",
            "UsageName": "Motor"
        },
        "03225": {
            "GenartName": "Schaltgetriebeöl",
            "NormTermName": "Öl",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "03226": {
            "GenartName": "Achsgetriebeöl",
            "NormTermName": "Öl",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Achsgetriebe"
        },
        "03227": {
            "GenartName": "Spannschraube, Generatorhalter",
            "NormTermName": "Schraube",
            "AssGrpName": "Generator",
            "UsageName": "Generatorhalter"
        },
        "03228": {
            "GenartName": "Querlenkerabstützung",
            "NormTermName": "Strebe",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Abstützung Querlenker"
        },
        "03229": {
            "GenartName": "Stange/Strebe, Stabilisator",
            "NormTermName": "Stange/Strebe",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisator"
        },
        "03230": {
            "GenartName": "Stange/Strebe, Radaufhängung",
            "NormTermName": "Stange/Strebe",
            "AssGrpName": "Radaufhängung",
            "UsageName": null
        },
        "03231": {
            "GenartName": "Kolbenring",
            "NormTermName": "Kolbenring",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kolben"
        },
        "03232": {
            "GenartName": "Tagfahrleuchtensatz",
            "NormTermName": "Leuchtensatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Tagfahrleuchte"
        },
        "03233": {
            "GenartName": "Tagfahrleuchtensatz",
            "NormTermName": "Leuchtensatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Tagfahrleuchte"
        },
        "03234": {
            "GenartName": "Zusatzblinkleuchtensatz",
            "NormTermName": "Leuchtensatz",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Zusatzblinkleuchte"
        },
        "03235": {
            "GenartName": "Blinkleuchtensatz",
            "NormTermName": "Leuchtensatz",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blinkleuchte"
        },
        "03236": {
            "GenartName": "Spurstangensatz",
            "NormTermName": "Stangensatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstange"
        },
        "03238": {
            "GenartName": "Halter, Querlenkerlagerung",
            "NormTermName": "Halter",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Querlenkerlagerung"
        },
        "03239": {
            "GenartName": "Befestigungsbolzen, Achsträger",
            "NormTermName": "Bolzen",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsträger"
        },
        "03240": {
            "GenartName": "Befestigungsbolzen, Querlenker",
            "NormTermName": "Bolzen",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Querlenker"
        },
        "03241": {
            "GenartName": "Reparatursatz, Rampenspiegel",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Rampenspiegel"
        },
        "03242": {
            "GenartName": "Reparatursatz, Fahrerhauslagerung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhauslagerung"
        },
        "03243": {
            "GenartName": "Buchse, Fahrerhauslagerung",
            "NormTermName": "Buchse",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhauslagerung"
        },
        "03244": {
            "GenartName": "Arbeitszylinder, Motorbremse",
            "NormTermName": "Zylinder",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Motorbremse"
        },
        "03246": {
            "GenartName": "Entlüfterschraube/-ventil, Kühler",
            "NormTermName": "Schraube",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlerentlüftung"
        },
        "03247": {
            "GenartName": "Lüfter, Getriebekühlung",
            "NormTermName": "Lüfter",
            "AssGrpName": "Kühlung",
            "UsageName": "Getriebekühlung"
        },
        "03248": {
            "GenartName": "Dichtung, Kühlmittelflansch",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelflansch"
        },
        "03249": {
            "GenartName": "Nachrüstsatz, Ruß-/Partikelfilter",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Ruß"
        },
        "03250": {
            "GenartName": "Montagesatz, Ruß-/Partikelfilter",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Ruß"
        },
        "03251": {
            "GenartName": "Türfeststeller",
            "NormTermName": "Türfeststeller",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "03252": {
            "GenartName": "Lenkwinkelsensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkwinkelposition"
        },
        "03254": {
            "GenartName": "Spiegelglas, Weitwinkelspiegel",
            "NormTermName": "Spiegelglas",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Weitwinkelspiegel"
        },
        "03255": {
            "GenartName": "Spiegelglas, Rampenspiegel",
            "NormTermName": "Spiegelglas",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Rampenspiegel"
        },
        "03256": {
            "GenartName": "Abdeckung, Weitwinkelspiegel",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Weitwinkelspiegel"
        },
        "03257": {
            "GenartName": "Abdeckung, Außenspiegel",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Außenspiegel"
        },
        "03258": {
            "GenartName": "Rahmen, Weitwinkelspiegel",
            "NormTermName": "Rahmen",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Weitwinkelspiegel"
        },
        "03259": {
            "GenartName": "Rahmen, Rampenspiegel",
            "NormTermName": "Rahmen",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Rampenspiegel"
        },
        "03260": {
            "GenartName": "Rahmen, Außenspiegel",
            "NormTermName": "Rahmen",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Außenspiegel"
        },
        "03261": {
            "GenartName": "Heizfolie, Weitwinkelspiegel",
            "NormTermName": "Folie",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Weitwinkelspiegel"
        },
        "03262": {
            "GenartName": "Anlaufscheibe",
            "NormTermName": "Anlaufscheibe",
            "AssGrpName": null,
            "UsageName": null
        },
        "03263": {
            "GenartName": "Ausrückgabel",
            "NormTermName": "Ausrückgabel",
            "AssGrpName": null,
            "UsageName": null
        },
        "03264": {
            "GenartName": "Bolzen",
            "NormTermName": "Bolzen",
            "AssGrpName": null,
            "UsageName": null
        },
        "03265": {
            "GenartName": "Buchse",
            "NormTermName": "Buchse",
            "AssGrpName": null,
            "UsageName": null
        },
        "03266": {
            "GenartName": "Anschweißbuchse",
            "NormTermName": "Anschweißbuchse",
            "AssGrpName": null,
            "UsageName": null
        },
        "03267": {
            "GenartName": "Anschweißring",
            "NormTermName": "Anschweißring",
            "AssGrpName": null,
            "UsageName": null
        },
        "03268": {
            "GenartName": "Bügel",
            "NormTermName": "Bügel",
            "AssGrpName": null,
            "UsageName": null
        },
        "03269": {
            "GenartName": "Dichtscheibe",
            "NormTermName": "Dichtscheibe",
            "AssGrpName": null,
            "UsageName": null
        },
        "03270": {
            "GenartName": "Dichtungssatz",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": null,
            "UsageName": null
        },
        "03271": {
            "GenartName": "Distanzrohr",
            "NormTermName": "Distanzrohr",
            "AssGrpName": null,
            "UsageName": null
        },
        "03272": {
            "GenartName": "Distanzscheibe",
            "NormTermName": "Distanzscheibe",
            "AssGrpName": null,
            "UsageName": null
        },
        "03273": {
            "GenartName": "Druckring",
            "NormTermName": "Druckring",
            "AssGrpName": null,
            "UsageName": null
        },
        "03274": {
            "GenartName": "Druckscheibe",
            "NormTermName": "Druckscheibe",
            "AssGrpName": null,
            "UsageName": null
        },
        "03275": {
            "GenartName": "Druckstück",
            "NormTermName": "Druckstück",
            "AssGrpName": null,
            "UsageName": null
        },
        "03276": {
            "GenartName": "Formblech",
            "NormTermName": "Blech",
            "AssGrpName": null,
            "UsageName": "Formblech"
        },
        "03277": {
            "GenartName": "Gasfeder",
            "NormTermName": "Gasfeder",
            "AssGrpName": null,
            "UsageName": null
        },
        "03278": {
            "GenartName": "Kugelgelenk",
            "NormTermName": "Gelenk",
            "AssGrpName": null,
            "UsageName": "Kugelgelenk"
        },
        "03279": {
            "GenartName": "Gelenklager",
            "NormTermName": "Gelenklager",
            "AssGrpName": null,
            "UsageName": null
        },
        "03280": {
            "GenartName": "Lager",
            "NormTermName": "Lager",
            "AssGrpName": null,
            "UsageName": null
        },
        "03281": {
            "GenartName": "Gleitstück",
            "NormTermName": "Gleitstück",
            "AssGrpName": null,
            "UsageName": null
        },
        "03282": {
            "GenartName": "Gummiring",
            "NormTermName": "Dichtung/Dichtring",
            "AssGrpName": null,
            "UsageName": null
        },
        "03283": {
            "GenartName": "Gummirolle",
            "NormTermName": "Gummirolle",
            "AssGrpName": null,
            "UsageName": null
        },
        "03284": {
            "GenartName": "Halteblech",
            "NormTermName": "Halteblech",
            "AssGrpName": null,
            "UsageName": null
        },
        "03285": {
            "GenartName": "Haltebügel",
            "NormTermName": "Haltebügel",
            "AssGrpName": null,
            "UsageName": null
        },
        "03286": {
            "GenartName": "Kerbstift",
            "NormTermName": "Stift",
            "AssGrpName": null,
            "UsageName": "Kerbstift"
        },
        "03287": {
            "GenartName": "Kugelbuchse",
            "NormTermName": "Buchse",
            "AssGrpName": null,
            "UsageName": "Kugelbuchse"
        },
        "03288": {
            "GenartName": "Lagerschale",
            "NormTermName": "Lagerschale",
            "AssGrpName": null,
            "UsageName": null
        },
        "03289": {
            "GenartName": "Membran",
            "NormTermName": "Membran",
            "AssGrpName": null,
            "UsageName": null
        },
        "03290": {
            "GenartName": "Achsmutter",
            "NormTermName": "Mutter",
            "AssGrpName": null,
            "UsageName": "Achsmutter"
        },
        "03291": {
            "GenartName": "Pendelarm",
            "NormTermName": "Pendelarm",
            "AssGrpName": null,
            "UsageName": null
        },
        "03292": {
            "GenartName": "Reparatursatz, Bremsbackenlagerung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbackenlagerung"
        },
        "03293": {
            "GenartName": "Stutzen",
            "NormTermName": "Stutzen",
            "AssGrpName": null,
            "UsageName": null
        },
        "03294": {
            "GenartName": "Schwingungsdämpfer",
            "NormTermName": "Dämpfer",
            "AssGrpName": null,
            "UsageName": "Schwingungsdämpfer"
        },
        "03295": {
            "GenartName": "Toter-Winkel-Spiegel, Fahrerhaus",
            "NormTermName": "Spiegel",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Toter-Winkel-Spiegel"
        },
        "03296": {
            "GenartName": "Toter-Winkel-Spiegel",
            "NormTermName": "Spiegel",
            "AssGrpName": "Karosserie",
            "UsageName": "Toter-Winkel-Spiegel"
        },
        "03297": {
            "GenartName": "Stoßring",
            "NormTermName": "Stoßring",
            "AssGrpName": null,
            "UsageName": null
        },
        "03298": {
            "GenartName": "Schaltzylinder",
            "NormTermName": "Zylinder",
            "AssGrpName": null,
            "UsageName": "Schaltzylinder"
        },
        "03299": {
            "GenartName": "Flügelrad, Wasserpumpe",
            "NormTermName": "Flügelrad",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "03300": {
            "GenartName": "Blattfederanschlag, Zusatzfeder",
            "NormTermName": "Blattfederanschlag",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Zusatzfeder"
        },
        "03301": {
            "GenartName": "Wasserpumpenwelle",
            "NormTermName": "Welle",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "03302": {
            "GenartName": "Zwischenlager, Antriebswelle",
            "NormTermName": "Lager",
            "AssGrpName": "Radantrieb",
            "UsageName": "Zwischenlager-Antriebswelle"
        },
        "03303": {
            "GenartName": "Relais, Frontscheibenheizung",
            "NormTermName": "Relais",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Frontscheibenheizung"
        },
        "03304": {
            "GenartName": "Lager, Lüfterwelle-Motorkühlung",
            "NormTermName": "Lager",
            "AssGrpName": "Kühlung",
            "UsageName": "Lüfterwelle-Motorkühlung"
        },
        "03305": {
            "GenartName": "Dichtring, Ölkühler",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölkühler"
        },
        "03306": {
            "GenartName": "Elektromotor, Ausstellfenster",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Ausstellfenster"
        },
        "03307": {
            "GenartName": "Schraube, Lagerbock-Kurbelwelle",
            "NormTermName": "Schraube",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Lagerbock-Kurbelwelle"
        },
        "03308": {
            "GenartName": "Abdeckung, Zahnriemen",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Riementrieb",
            "UsageName": "Zahnriemen"
        },
        "03309": {
            "GenartName": "Prüfgerät, Sensorring",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Sensorring"
        },
        "03310": {
            "GenartName": "Prüfgerät, Stoßdämpfer",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Stoßdämpfer"
        },
        "03311": {
            "GenartName": "Steuergerät, Kurvenscheinwerfer",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kurvenscheinwerfer"
        },
        "03312": {
            "GenartName": "Steuergerät, Kurvenscheinwerfer",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Kurvenscheinwerfer"
        },
        "03313": {
            "GenartName": "Zylinderlaufbuchse, Druckluftkompressor",
            "NormTermName": "Buchse",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "03314": {
            "GenartName": "Ladeluftschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lader"
        },
        "03315": {
            "GenartName": "Ventilplatte, Druckluftkompressor",
            "NormTermName": "Ventilplatte",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "03316": {
            "GenartName": "Sicherungshalter",
            "NormTermName": "Halter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Sicherung"
        },
        "03317": {
            "GenartName": "Sicherungsautomat",
            "NormTermName": "Sicherungsautomat",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "03318": {
            "GenartName": "Kabelverbindungsdose",
            "NormTermName": "Kabelverbindungsdose",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "03319": {
            "GenartName": "Steckeinsatz, Kabelverbindungsdose",
            "NormTermName": "Einsatz",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kabelverbindungsdose"
        },
        "03320": {
            "GenartName": "Zubehörsatz, Schlepphebel",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Schlepphebel"
        },
        "03321": {
            "GenartName": "Rollensatz, Zahnriemen",
            "NormTermName": "Rollensatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Zahnriemen"
        },
        "03322": {
            "GenartName": "Rollensatz, Keilrippenriemen",
            "NormTermName": "Rollensatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilrippenriemen"
        },
        "03323": {
            "GenartName": "Deckel, Ölfiltergehäuse",
            "NormTermName": "Deckel",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölfiltergehäuse"
        },
        "03324": {
            "GenartName": "Ölabscheider, Kurbelgehäuseentlüftung",
            "NormTermName": "Abscheider",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Ölabscheider (Kurbelgehäuse)"
        },
        "03325": {
            "GenartName": "Kugelpfanne, Ventilstößel",
            "NormTermName": "Kugelpfanne",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ventilstößel"
        },
        "03326": {
            "GenartName": "Sortiment, Glühlampen",
            "NormTermName": "Sortiment",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Glühlampen"
        },
        "03327": {
            "GenartName": "Heckleuchtensatz",
            "NormTermName": "Leuchtensatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Heckleuchte"
        },
        "03328": {
            "GenartName": "Hauptscheinwerfersatz",
            "NormTermName": "Scheinwerfersatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "03329": {
            "GenartName": "Fernscheinwerfersatz",
            "NormTermName": "Scheinwerfersatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Fernscheinwerfer"
        },
        "03330": {
            "GenartName": "Fernscheinwerfersatz",
            "NormTermName": "Scheinwerfersatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernscheinwerfer"
        },
        "03331": {
            "GenartName": "Nebelscheinwerfersatz",
            "NormTermName": "Scheinwerfersatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "03332": {
            "GenartName": "Nebelscheinwerfersatz",
            "NormTermName": "Scheinwerfersatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelscheinwerfer"
        },
        "03333": {
            "GenartName": "Scheinwerfereinsatz, Arbeitsscheinwerfer",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "03334": {
            "GenartName": "Scheinwerfereinsatz, Fernscheinwerfer",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Fernscheinwerfer"
        },
        "03335": {
            "GenartName": "Scheinwerfereinsatz, Hauptscheinwerfer",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "03336": {
            "GenartName": "Scheinwerfereinsatz, Nebelscheinwerfer",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelscheinwerfer"
        },
        "03337": {
            "GenartName": "Scheinwerfereinsatz, Suchscheinwerfer",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Suchscheinwerfer"
        },
        "03338": {
            "GenartName": "Zusatzblinkleuchtensatz",
            "NormTermName": "Leuchtensatz",
            "AssGrpName": "Signalanlage",
            "UsageName": "Zusatzblinkleuchte"
        },
        "03339": {
            "GenartName": "Servicerücksteller",
            "NormTermName": "Service-Gerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "03340": {
            "GenartName": "Zusatzsteuergerät, Luftfederung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Zusatzsteuergerät-Luftfederung"
        },
        "03341": {
            "GenartName": "Druckplatte, Rückstellwerkzeug-Bremssattelkolben",
            "NormTermName": "Druckplatte",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rückstellwerkzeug-Bremssattelkolben"
        },
        "03342": {
            "GenartName": "Reinigungsscheibe, Reinigungsset-Radnabe",
            "NormTermName": "Reinigungsscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Reinigungsset-Radnabe"
        },
        "03343": {
            "GenartName": "Sensorkopf, Prüfgerät-Bremsflüssigkeit",
            "NormTermName": "Sensorkopf",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Bremsflüssigkeit"
        },
        "03344": {
            "GenartName": "Spindel, Rückstellwerkzeug-Bremssattelkolben",
            "NormTermName": "Spindel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rückstellwerkzeug-Bremssattelkolben"
        },
        "03345": {
            "GenartName": "Riemenscheibe, Wasserpumpe",
            "NormTermName": "Riemenscheibe",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "03346": {
            "GenartName": "Wellendichtring",
            "NormTermName": "Wellendichtring",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "03347": {
            "GenartName": "Bremsband, Automatikgetriebe",
            "NormTermName": "Bremsband",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "03348": {
            "GenartName": "Dichtung, Stecker",
            "NormTermName": "Dichtung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Stecker"
        },
        "03349": {
            "GenartName": "Kabelverschraubung",
            "NormTermName": "Verschraubung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kabelverschraubung"
        },
        "03350": {
            "GenartName": "Wellrohrverschraubung",
            "NormTermName": "Verschraubung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Wellrohrverschraubung"
        },
        "03351": {
            "GenartName": "Sicherungsbügel",
            "NormTermName": "Bügel",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Sicherungsbügel"
        },
        "03352": {
            "GenartName": "Adapter, Zigarettenanzünder",
            "NormTermName": "Adapter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Zigarettenanzünder"
        },
        "03353": {
            "GenartName": "Zentralhydrauliköl",
            "NormTermName": "Öl",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Zentralhydraulik"
        },
        "03354": {
            "GenartName": "Servolenkungsöl",
            "NormTermName": "Öl",
            "AssGrpName": "Lenkung",
            "UsageName": "Servolenkung"
        },
        "03356": {
            "GenartName": "Frostschutz",
            "NormTermName": "Frostschutz",
            "AssGrpName": "Kühlung",
            "UsageName": null
        },
        "03357": {
            "GenartName": "Bremsflüssigkeit",
            "NormTermName": "Öl",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "03358": {
            "GenartName": "Halter, Luftfiltergehäuse",
            "NormTermName": "Halter",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Luftfiltergehäuse"
        },
        "03359": {
            "GenartName": "Klemmschelle, Außenspiegel",
            "NormTermName": "Schelle",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Außenspiegel"
        },
        "03360": {
            "GenartName": "Buchse, Achsschenkelbolzen",
            "NormTermName": "Buchse",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsschenkelbolzen"
        },
        "03361": {
            "GenartName": "Reparatursatz, Radnabe",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radnabe"
        },
        "03362": {
            "GenartName": "Deckel, Kraftstofffilter",
            "NormTermName": "Deckel",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstofffilter"
        },
        "03363": {
            "GenartName": "Lagerung, Ausgleichsbehälter-Kühlmittel",
            "NormTermName": "Lagerung",
            "AssGrpName": "Kühlung",
            "UsageName": "Ausgleichsbehälter-Kühlmittel"
        },
        "03365": {
            "GenartName": "Schutzkappe/Faltenbalg, Stoßdämpfer",
            "NormTermName": "Schutzkappe/Faltenbalg",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Stoßdämpfer"
        },
        "03366": {
            "GenartName": "Lüfterblatt, Klimakondensatorlüfter",
            "NormTermName": "Lüfterblatt",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Klimakondensatorlüfter"
        },
        "03367": {
            "GenartName": "Schalter, Spiegelverstellung",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Spiegelverstellung"
        },
        "03368": {
            "GenartName": "Überspannungsschutzrelais, ABS",
            "NormTermName": "Relais",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Überspannungsschutzrelais-ABS"
        },
        "03369": {
            "GenartName": "Hochleistungs-Bremsscheibe",
            "NormTermName": "Bremsscheibe (Hochleistungsbremse)",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hochleistungsbremse"
        },
        "03370": {
            "GenartName": "Hochleistungs-Bremsensatz",
            "NormTermName": "Bremsensatz (Hochleistungsbremse)",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hochleistungsbremse"
        },
        "03371": {
            "GenartName": "Hochleistungs-Bremsbelagsatz",
            "NormTermName": "Bremsbelagsatz (Hochleistungsbremse)",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hochleistungsbremse"
        },
        "03372": {
            "GenartName": "Hochleistungs-Bremssattel",
            "NormTermName": "Bremssattel (Hochleistungsbremse)",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hochleistungsbremse"
        },
        "03373": {
            "GenartName": "Warnkontakt, Bremsbelagverschleiß (Hochleistungsbremse)",
            "NormTermName": "Kontakt (Hochleistungsbremse)",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hochleistungsbremse"
        },
        "03374": {
            "GenartName": "Hochleistungs-Bremsschlauch",
            "NormTermName": "Schlauchleitung (Hochleistungsbremse)",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremshydraulik-Hochleistungsbremse"
        },
        "03375": {
            "GenartName": "Halter, Bremssattel (Hochleistungsbremse)",
            "NormTermName": "Halter (Hochleistungsbremse)",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hochleistungsbremse"
        },
        "03376": {
            "GenartName": "Montagesatz, Bremssattel (Hochleistungsbremse)",
            "NormTermName": "Montagesatz (Hochleistungsbremse)",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hochleistungsbremse"
        },
        "03377": {
            "GenartName": "Bremsendeaktivierungswerkzeug",
            "NormTermName": "Service-Gerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsendeaktivierung"
        },
        "03378": {
            "GenartName": "Verschlussdeckel, Ausrücklager",
            "NormTermName": "Verschluss",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Ausrücklager"
        },
        "03379": {
            "GenartName": "Spiegelsystem",
            "NormTermName": "Spiegelsystem",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "03380": {
            "GenartName": "Faltenbalg, Spiegelsystem",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Karosserie",
            "UsageName": "Spiegelsystem"
        },
        "03381": {
            "GenartName": "Spiegelkopf, Spiegelsystem",
            "NormTermName": "Spiegelkopf",
            "AssGrpName": "Karosserie",
            "UsageName": "Spiegelsystem"
        },
        "03382": {
            "GenartName": "Glaseinheit, Spiegelkopf",
            "NormTermName": "Glaseinheit",
            "AssGrpName": "Karosserie",
            "UsageName": "Spiegelkopf"
        },
        "03384": {
            "GenartName": "Anschlussleitung, Außenspiegel",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Außenspiegel"
        },
        "03385": {
            "GenartName": "Anschlussleitung, Außenspiegel",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegel"
        },
        "03386": {
            "GenartName": "Abdeckung, Spiegelsystem",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Karosserie",
            "UsageName": "Spiegelsystem"
        },
        "03387": {
            "GenartName": "Trägerplatte, Spiegelsystem",
            "NormTermName": "Trägerplatte",
            "AssGrpName": "Karosserie",
            "UsageName": "Spiegelsystem"
        },
        "03388": {
            "GenartName": "Trägerplatte, Außenspiegel",
            "NormTermName": "Trägerplatte",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegel"
        },
        "03389": {
            "GenartName": "Adapter, Faltenbalg-Spiegelsystem",
            "NormTermName": "Adapter",
            "AssGrpName": "Karosserie",
            "UsageName": "Faltenbalg-Spiegelsystem"
        },
        "03390": {
            "GenartName": "Kabelsatz, Außenspiegel",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegel"
        },
        "03391": {
            "GenartName": "Kabelsatz, Außenspiegel",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Außenspiegel"
        },
        "03392": {
            "GenartName": "Spiegelglas, Glaseinheit",
            "NormTermName": "Spiegelglas",
            "AssGrpName": "Karosserie",
            "UsageName": "Glaseinheit"
        },
        "03393": {
            "GenartName": "Gehäuse, Außenspiegel",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegel"
        },
        "03394": {
            "GenartName": "Gehäuse, Außenspiegel",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Außenspiegel"
        },
        "03395": {
            "GenartName": "Montagesatz, Außenspiegel",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Außenspiegel"
        },
        "03396": {
            "GenartName": "Montagesatz, Außenspiegel",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegel"
        },
        "03397": {
            "GenartName": "Spiegelglas, Innenspiegel",
            "NormTermName": "Spiegelglas",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Innenspiegel"
        },
        "03398": {
            "GenartName": "Spiegelglas, Toter-Winkel-Spiegel",
            "NormTermName": "Spiegelglas",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Toter-Winkel-Spiegel"
        },
        "03399": {
            "GenartName": "Spiegelglas, Toter-Winkel-Spiegel",
            "NormTermName": "Spiegelglas",
            "AssGrpName": "Karosserie",
            "UsageName": "Toter-Winkel-Spiegel"
        },
        "03400": {
            "GenartName": "Sensor, Bremsbelagverschleiß",
            "NormTermName": "Sensor",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Belagverschleiß"
        },
        "03401": {
            "GenartName": "Schlauch, Luftversorgung",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Luftversorgung",
            "UsageName": null
        },
        "03402": {
            "GenartName": "Schlauch, Zylinderkopfhaubenentlüftung",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Zylinderkopfhaubenentlüftung"
        },
        "03403": {
            "GenartName": "Warnkontaktsatz, Bremsbelagverschleiß",
            "NormTermName": "Kontaktsatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Belagverschleiß"
        },
        "03404": {
            "GenartName": "Dichtungssatz, Lamellenventil",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Lamellenventil"
        },
        "03405": {
            "GenartName": "Bremsensatz, Scheibenbremse",
            "NormTermName": "Bremsensatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Scheibenbremse"
        },
        "03406": {
            "GenartName": "Lagerung, Radlagergehäuse",
            "NormTermName": "Lagerung",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radlagergehäuse"
        },
        "03407": {
            "GenartName": "Filter, Kraftstoff-Fördereinheit",
            "NormTermName": "Filter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstofffördereinheit"
        },
        "03408": {
            "GenartName": "Drahtbürste, Batteriepol-/Klemmenreinigung",
            "NormTermName": "Drahtbürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Batteriepol-/Klemmenreinigung"
        },
        "03409": {
            "GenartName": "Batteriesäureprüfer",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Batteriesäure"
        },
        "03410": {
            "GenartName": "Druck-/Vakuumpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Druck/Unterdruck"
        },
        "03411": {
            "GenartName": "Kartusche, Heißkleber",
            "NormTermName": "Kartusche",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Heißkleber"
        },
        "03412": {
            "GenartName": "Frostschutzprüfer",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Kühlmittel"
        },
        "03413": {
            "GenartName": "Prüfgerät, Glühkerze",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Glühkerze"
        },
        "03415": {
            "GenartName": "Heißluftföhn",
            "NormTermName": "Heißluftföhn",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "03416": {
            "GenartName": "Marderschutz",
            "NormTermName": "Marderschutz",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "03417": {
            "GenartName": "Messbecher",
            "NormTermName": "Messbecher",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "03418": {
            "GenartName": "Reparatursatz, Feststellbremshebel (Bremssattel)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremshebel (Bremssattel)"
        },
        "03419": {
            "GenartName": "Ausrückgabel, Kupplung",
            "NormTermName": "Ausrückgabel",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "03420": {
            "GenartName": "Sicherungskasten",
            "NormTermName": "Kasten",
            "AssGrpName": "Bordnetz",
            "UsageName": "Sicherung"
        },
        "03421": {
            "GenartName": "Verteilergetriebeöl",
            "NormTermName": "Öl",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Verteilergetriebe"
        },
        "03422": {
            "GenartName": "Lenkgetriebeöl",
            "NormTermName": "Öl",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkgetriebe"
        },
        "03423": {
            "GenartName": "Wellendichtring, Wasserpumpenwelle",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpenwelle"
        },
        "03424": {
            "GenartName": "Wellendichtring, Lenkgetriebe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkgetriebe"
        },
        "03425": {
            "GenartName": "Wellendichtring, Tachoantrieb",
            "NormTermName": "Dichtring",
            "AssGrpName": "Instrumente",
            "UsageName": "Tachoantrieb"
        },
        "03427": {
            "GenartName": "Wellendichtring, Vorgelegewelle",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Vorgelegewelle"
        },
        "03428": {
            "GenartName": "Reparatursatz, Scheibeneinkleben",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Scheibeneinbau"
        },
        "03429": {
            "GenartName": "Stützring, Federbeinstützlager",
            "NormTermName": "Stützring",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbeinstützlager"
        },
        "03430": {
            "GenartName": "Schalter, Nebelschlussleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelschlussleuchte"
        },
        "03431": {
            "GenartName": "Schalter, Nebelschlussleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelschlussleuchte"
        },
        "03432": {
            "GenartName": "Zylinderlaufbuchsensatz",
            "NormTermName": "Buchsensatz",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Zylinderlaufbuchse"
        },
        "03433": {
            "GenartName": "Leerkupplung, Kupplungskopf",
            "NormTermName": "Leerkupplung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Kupplungskopf"
        },
        "03434": {
            "GenartName": "Kontrollgerät, Batteriespannung",
            "NormTermName": "Kontrollgerät",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Batteriespannung"
        },
        "03435": {
            "GenartName": "Vorschalldämpfer",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Vorschalldämpfer"
        },
        "03436": {
            "GenartName": "Mittelschalldämpfer",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Mittelschalldämpfer"
        },
        "03437": {
            "GenartName": "Endschalldämpfer",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Endschalldämpfer"
        },
        "03438": {
            "GenartName": "Dichtverschluss, Kabelverschraubung",
            "NormTermName": "Verschluss",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kabelverschraubung"
        },
        "03439": {
            "GenartName": "Anti-Statik-Band",
            "NormTermName": "Anti-Statik-Band",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Anti-Statik"
        },
        "03440": {
            "GenartName": "Reifenreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Reifen"
        },
        "03441": {
            "GenartName": "Lederpflegemittel",
            "NormTermName": "Pflegemittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Leder"
        },
        "03442": {
            "GenartName": "Lederreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Leder"
        },
        "03443": {
            "GenartName": "Lufterfrischer",
            "NormTermName": "Lufterfrischer",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "03444": {
            "GenartName": "Gewindebohrer",
            "NormTermName": "Gewindebohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "03445": {
            "GenartName": "Schneideisenhalter",
            "NormTermName": "Schneideisenhalter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "03446": {
            "GenartName": "Schneideisen",
            "NormTermName": "Schneideisen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "03447": {
            "GenartName": "Windeisen",
            "NormTermName": "Windeisen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "03448": {
            "GenartName": "Gewindeschneidwerkzeugsatz",
            "NormTermName": "Gewindeschneidwerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "03449": {
            "GenartName": "Hitzeschutzblech",
            "NormTermName": "Hitzeschutzblech",
            "AssGrpName": null,
            "UsageName": null
        },
        "03450": {
            "GenartName": "Diode",
            "NormTermName": "Diode",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "03451": {
            "GenartName": "Lichtscheibe, Seitenmarkierungsleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "03452": {
            "GenartName": "Lichtscheibe, Seitenmarkierungsleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "03453": {
            "GenartName": "Blende, Nebelschlussleuchte",
            "NormTermName": "Blende",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelschlussleuchte"
        },
        "03454": {
            "GenartName": "Zugentlastung, Elektroleitung",
            "NormTermName": "Zugentlastung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Elektroleitung"
        },
        "03455": {
            "GenartName": "Drehknopf, Sitzlehnenverstellung",
            "NormTermName": "Drehknopf",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Sitzlehnenverstellung"
        },
        "03456": {
            "GenartName": "Seitenmarkierungsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "03457": {
            "GenartName": "Kabelsatz, Heckleuchte",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Heckleuchte"
        },
        "03458": {
            "GenartName": "Lichtscheibe, Umrissleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Umrissleuchte"
        },
        "03459": {
            "GenartName": "Reiniger, Vergaser",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Vergaser"
        },
        "03460": {
            "GenartName": "Lichtscheibe, Umrissleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Umrissleuchte"
        },
        "03461": {
            "GenartName": "Reiniger, Benzineinspritzsystem",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Benzineinspritzsystem"
        },
        "03462": {
            "GenartName": "Reiniger, Dieseleinspritzsystem",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Dieseleinspritzsystem"
        },
        "03463": {
            "GenartName": "Torsionsdämpfer, Kupplung",
            "NormTermName": "Torsionsdämpfer",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplung"
        },
        "03464": {
            "GenartName": "Seilzug, Sitzlehnenverstellung",
            "NormTermName": "Seilzug",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Sitzlehnenverstellung"
        },
        "03465": {
            "GenartName": "Starterbatterie",
            "NormTermName": "Batterie",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Starterbatterie"
        },
        "03466": {
            "GenartName": "Reparaturrohr, Katalysator",
            "NormTermName": "Rohr",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Katalysator"
        },
        "03467": {
            "GenartName": "Motoröladditiv",
            "NormTermName": "Zusatzmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Motoröl"
        },
        "03468": {
            "GenartName": "Getriebeöladditiv",
            "NormTermName": "Zusatzmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Getriebeöl"
        },
        "03469": {
            "GenartName": "Hydrauliköladditiv",
            "NormTermName": "Zusatzmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Hydrauliköl"
        },
        "03470": {
            "GenartName": "Kraftstoffadditiv",
            "NormTermName": "Zusatzmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kraftstoff"
        },
        "03471": {
            "GenartName": "Reiniger, Kühlsystem",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kühlmittelkreislauf"
        },
        "03472": {
            "GenartName": "Fahrzeuglack",
            "NormTermName": "Lack",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Fahrzeuglack"
        },
        "03473": {
            "GenartName": "Bremssattellack",
            "NormTermName": "Lack",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Bremssattel"
        },
        "03474": {
            "GenartName": "Effektlack",
            "NormTermName": "Lack",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Effektlack"
        },
        "03475": {
            "GenartName": "Klarlack",
            "NormTermName": "Lack",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Klarlack"
        },
        "03476": {
            "GenartName": "RAL-Lack",
            "NormTermName": "Lack",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "RAL"
        },
        "03477": {
            "GenartName": "Kunststofflack",
            "NormTermName": "Lack",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kunststofflack"
        },
        "03478": {
            "GenartName": "Motor-/Schalldämpferlack",
            "NormTermName": "Lack",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Motor-/Schalldämpferlack"
        },
        "03479": {
            "GenartName": "Dichtung, Luftfiltergehäuse",
            "NormTermName": "Dichtung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Luftfiltergehäuse"
        },
        "03480": {
            "GenartName": "Signalsystem, Einsatzfahrzeug",
            "NormTermName": "Signalsystem",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Einsatzfahrzeug"
        },
        "03481": {
            "GenartName": "Reparatursatz, Wasserpumpe",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "03482": {
            "GenartName": "Entlüftungsschlauch, Ausgleichsbehälter",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Kühlung",
            "UsageName": "Entlüftung-Ausgleichsbehälter"
        },
        "03483": {
            "GenartName": "Leseleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Leseleuchte"
        },
        "03484": {
            "GenartName": "Glühlampe, Leseleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Leseleuchte"
        },
        "03485": {
            "GenartName": "Leseleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Leseleuchte"
        },
        "03486": {
            "GenartName": "Reparatursatz, Arbeitszylinder",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Arbeitszylinder"
        },
        "03487": {
            "GenartName": "Wischgummi",
            "NormTermName": "Wischgummi",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": null
        },
        "03488": {
            "GenartName": "Relais",
            "NormTermName": "Relais",
            "AssGrpName": null,
            "UsageName": null
        },
        "03490": {
            "GenartName": "Steckverbinder",
            "NormTermName": "Verbindungselement",
            "AssGrpName": null,
            "UsageName": "Steckverbinder"
        },
        "03491": {
            "GenartName": "Elektroleitung",
            "NormTermName": "Elektroleitung",
            "AssGrpName": null,
            "UsageName": null
        },
        "03492": {
            "GenartName": "Leitungsverbinder",
            "NormTermName": "Verbindungselement",
            "AssGrpName": null,
            "UsageName": "Leitungsverbinder"
        },
        "03493": {
            "GenartName": "Sicherung",
            "NormTermName": "Sicherung",
            "AssGrpName": null,
            "UsageName": null
        },
        "03494": {
            "GenartName": "Fanfare",
            "NormTermName": "Fanfare",
            "AssGrpName": null,
            "UsageName": null
        },
        "03495": {
            "GenartName": "Steckdose",
            "NormTermName": "Steckdose",
            "AssGrpName": null,
            "UsageName": null
        },
        "03496": {
            "GenartName": "Stecker",
            "NormTermName": "Stecker",
            "AssGrpName": null,
            "UsageName": null
        },
        "03497": {
            "GenartName": "Hubmagnet",
            "NormTermName": "Hubmagnet",
            "AssGrpName": null,
            "UsageName": null
        },
        "03498": {
            "GenartName": "Seilzugsatz, Feststellbremse",
            "NormTermName": "Seilzugsatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremse"
        },
        "03499": {
            "GenartName": "Lichtscheibe, Hauptscheinwerfer",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "03500": {
            "GenartName": "Luftionisator",
            "NormTermName": "Luftreiniger",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Luftreinigung"
        },
        "03501": {
            "GenartName": "Elektromotor, Verdeckklappe",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Verdeckklappe"
        },
        "03502": {
            "GenartName": "Ölfilter, Differential",
            "NormTermName": "Filter",
            "AssGrpName": "Schmierung",
            "UsageName": "Differential"
        },
        "03503": {
            "GenartName": "Ölfilter, Retarder",
            "NormTermName": "Filter",
            "AssGrpName": "Schmierung",
            "UsageName": "Retarder"
        },
        "03504": {
            "GenartName": "Elektromotor",
            "NormTermName": "Elektromotor",
            "AssGrpName": null,
            "UsageName": "Elektromotor"
        },
        "03506": {
            "GenartName": "Zugfeder, Spannrolle (Zahnriemen)",
            "NormTermName": "Feder",
            "AssGrpName": "Riementrieb",
            "UsageName": "Spannrolle (Zahnriemen)"
        },
        "03507": {
            "GenartName": "Dichtung, AGR-Ventil",
            "NormTermName": "Dichtung",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "AGR-Ventil"
        },
        "03508": {
            "GenartName": "Seitenreflektor",
            "NormTermName": "Reflektor",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Seitenreflektor"
        },
        "03509": {
            "GenartName": "Montagesatz, Längswellengelenk",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Längswellengelenk"
        },
        "03510": {
            "GenartName": "Montagesatz, Stabilisatorstange/-Strebe",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisatorstange/-Strebe"
        },
        "03511": {
            "GenartName": "Montagesatz, Spurstangenkopf",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstangenkopf"
        },
        "03512": {
            "GenartName": "Montagesatz, Lagerhalter-Bremsnockenwelle",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Lagerhalter-Bremsnockenwelle"
        },
        "03513": {
            "GenartName": "Puffer, Ölwannenbefestigung",
            "NormTermName": "Puffer",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölwannenbefestigung"
        },
        "03514": {
            "GenartName": "Distanzscheibe, Achsschenkelbolzen",
            "NormTermName": "Distanzscheibe",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsschenkelbolzen"
        },
        "03515": {
            "GenartName": "Zugfeder, Spannrolle (Keilriemen)",
            "NormTermName": "Feder",
            "AssGrpName": "Riementrieb",
            "UsageName": "Spannrolle (Keilriemen)"
        },
        "03516": {
            "GenartName": "Zugfeder, Spannrolle (Keilrippenriemen)",
            "NormTermName": "Feder",
            "AssGrpName": "Riementrieb",
            "UsageName": "Spannrolle (Keilrippenriemen)"
        },
        "03517": {
            "GenartName": "Mittel-/Endschalldämpfer",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Mittel-/Endschalldämpfer"
        },
        "03520": {
            "GenartName": "Lager, Ausgleichsgetriebewelle",
            "NormTermName": "Lager",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Ausgleichswelle"
        },
        "03521": {
            "GenartName": "Lager, Antriebswelle",
            "NormTermName": "Lager",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Antriebswelle"
        },
        "03522": {
            "GenartName": "Radnabe",
            "NormTermName": "Grafik",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radnabe"
        },
        "03523": {
            "GenartName": "Achsführung",
            "NormTermName": "Grafik",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsführung"
        },
        "03524": {
            "GenartName": "Radbremse",
            "NormTermName": "Grafik",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Radbremse"
        },
        "03525": {
            "GenartName": "Federlagerung",
            "NormTermName": "Grafik",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federlagerung"
        },
        "03526": {
            "GenartName": "Federanschlag",
            "NormTermName": "Grafik",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federanschlag"
        },
        "03527": {
            "GenartName": "Blattfederung",
            "NormTermName": "Grafik",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Blattfederung"
        },
        "03528": {
            "GenartName": "Antriebswelle",
            "NormTermName": "Grafik",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "03529": {
            "GenartName": "Bremsbacke",
            "NormTermName": "Grafik",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbacke"
        },
        "03530": {
            "GenartName": "Bremswelle",
            "NormTermName": "Grafik",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremswelle"
        },
        "03531": {
            "GenartName": "Bremsscheibe",
            "NormTermName": "Grafik",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsscheibe"
        },
        "03532": {
            "GenartName": "Bremsbackenlagerung",
            "NormTermName": "Grafik",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbackenlagerung"
        },
        "03533": {
            "GenartName": "Lenkstange",
            "NormTermName": "Grafik",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkstange"
        },
        "03534": {
            "GenartName": "Luftfederung",
            "NormTermName": "Grafik",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Luftfederung"
        },
        "03535": {
            "GenartName": "Spurstange",
            "NormTermName": "Grafik",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstange"
        },
        "03536": {
            "GenartName": "Katalysator, Universal",
            "NormTermName": "Katalysator",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": null
        },
        "03537": {
            "GenartName": "Achsschenkellagerung",
            "NormTermName": "Grafik",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsschenkellagerung"
        },
        "03538": {
            "GenartName": "Stabilisatorlagerung",
            "NormTermName": "Grafik",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisatorlagerung"
        },
        "03539": {
            "GenartName": "Stoßdämpfer",
            "NormTermName": "Grafik",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "03540": {
            "GenartName": "Gestängesteller",
            "NormTermName": "Grafik",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "03541": {
            "GenartName": "Kraftstoffpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Kraftstoff"
        },
        "03542": {
            "GenartName": "Pedalbelag, Fahrpedal",
            "NormTermName": "Pedalbelag",
            "AssGrpName": "Luftversorgung",
            "UsageName": null
        },
        "03543": {
            "GenartName": "Luftfilter, Turbolader",
            "NormTermName": "Filter",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Turbolader"
        },
        "03544": {
            "GenartName": "Keilrippenriemen",
            "NormTermName": "Grafik",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilrippenriemen"
        },
        "03545": {
            "GenartName": "Steuergerät, Elektrolüfter (Motorkühlung)",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Kühlung",
            "UsageName": "Elektrolüfter (Motorkühlung)"
        },
        "03547": {
            "GenartName": "Stellelement, Umschaltklappe (Saugrohr)",
            "NormTermName": "Stellelement",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Umschaltklappe (Saugrohr)"
        },
        "03548": {
            "GenartName": "Stellelement, Drallklappen (Saugrohr)",
            "NormTermName": "Stellelement",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Drallklappen (Saugrohr)"
        },
        "03550": {
            "GenartName": "Stößel, Unterdruckpumpe (Bremsanlage)",
            "NormTermName": "Stößel",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruckpumpe (Bremsanlage)"
        },
        "03551": {
            "GenartName": "Umschaltventil, Umschaltklappe (Saugrohr)",
            "NormTermName": "Ventil",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Umschaltklappe (Saugrohr)"
        },
        "03552": {
            "GenartName": "Umschaltventil, Abgasklappe",
            "NormTermName": "Ventil",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasklappe"
        },
        "03553": {
            "GenartName": "Druckwandler, Turbolader",
            "NormTermName": "Druckwandler",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Turbolader"
        },
        "03554": {
            "GenartName": "Umschaltventil, Lüfterjalousie",
            "NormTermName": "Ventil",
            "AssGrpName": "Kühlung",
            "UsageName": "Lüfterjalousie"
        },
        "03555": {
            "GenartName": "Umschaltventil, Motorlagerung",
            "NormTermName": "Ventil",
            "AssGrpName": "Motoraufhängung",
            "UsageName": "Motorlagerung"
        },
        "03556": {
            "GenartName": "Umschaltventil, Differentialsperre",
            "NormTermName": "Ventil",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differentialsperre"
        },
        "03557": {
            "GenartName": "Umschaltventil, Lüftungsklappen",
            "NormTermName": "Ventil",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Lüftungsklappen"
        },
        "03558": {
            "GenartName": "Buchse, Schaltstange",
            "NormTermName": "Buchse",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Schaltstange"
        },
        "03559": {
            "GenartName": "Abdeckkappe, Türaußengriff",
            "NormTermName": "Kappe",
            "AssGrpName": "Schließanlage",
            "UsageName": "Türaußengriff"
        },
        "03560": {
            "GenartName": "Dichtring, Ölsieb",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölsieb"
        },
        "03561": {
            "GenartName": "Manschette, Lenksäule",
            "NormTermName": "Manschette",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenksäule"
        },
        "03562": {
            "GenartName": "Lenkgetriebe",
            "NormTermName": "Grafik",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkgetriebe"
        },
        "03563": {
            "GenartName": "Fensterheber",
            "NormTermName": "Grafik",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Fensterheber"
        },
        "03564": {
            "GenartName": "Rampenspiegel",
            "NormTermName": "Grafik",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Rampenspiegel"
        },
        "03565": {
            "GenartName": "Außenspiegel, Fahrerhaus",
            "NormTermName": "Grafik",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Außenspiegel (Fahrerhaus)"
        },
        "03566": {
            "GenartName": "Fahrerhauslagerung",
            "NormTermName": "Grafik",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhauslagerung"
        },
        "03567": {
            "GenartName": "Weitwinkelspiegel",
            "NormTermName": "Grafik",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Weitwinkelspiegel"
        },
        "03568": {
            "GenartName": "Keilriemen",
            "NormTermName": "Grafik",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilriemen"
        },
        "03569": {
            "GenartName": "Zahnriemen",
            "NormTermName": "Grafik",
            "AssGrpName": "Riementrieb",
            "UsageName": "Zahnriemen"
        },
        "03570": {
            "GenartName": "Montagesatz, Vorkatalysator",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Vorkatalysator"
        },
        "03615": {
            "GenartName": "Abdeckung, Schauloch (Bremsbelagverschleiß)",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Schauloch (Bremsbelagverschleiß)"
        },
        "03616": {
            "GenartName": "Dichtring, Bremsbackenrolle",
            "NormTermName": "Dichtring",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbackenrolle"
        },
        "03617": {
            "GenartName": "Stopfen, Achskappe",
            "NormTermName": "Stopfen",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achskappe"
        },
        "03618": {
            "GenartName": "Sicherungsblech, Achsmutter",
            "NormTermName": "Sicherungsblech",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsmutter"
        },
        "03619": {
            "GenartName": "Bremsträgerplatte",
            "NormTermName": "Trägerplatte",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsanlage"
        },
        "03620": {
            "GenartName": "Gasfeder, Frontklappe",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Frontklappe"
        },
        "03621": {
            "GenartName": "Gasfeder, Trittplatte",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Trittplatte"
        },
        "03622": {
            "GenartName": "Anschlagpuffer, Fahrerhausstabilisator",
            "NormTermName": "Puffer",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhausstabilisator"
        },
        "03623": {
            "GenartName": "Anschlagpuffer, Fahrerhauslagerbock",
            "NormTermName": "Puffer",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhauslagerbock"
        },
        "03624": {
            "GenartName": "Dichtung, Stabilisatorlagerung (Fahrerhaus)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Stabilisatorlagerung (Fahrerhaus)"
        },
        "03625": {
            "GenartName": "Fahrzeug-Kombinationslack",
            "NormTermName": "Lack",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Fahrzeug-Kombinationslack"
        },
        "03626": {
            "GenartName": "Dichtung, Stoßdämpferlagerung (Fahrerhaus)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Stoßdämpferlagerung"
        },
        "03627": {
            "GenartName": "Gelenklager, Fahrerhausstoßdämpfer",
            "NormTermName": "Gelenklager",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhausstoßdämpfer"
        },
        "03628": {
            "GenartName": "Distanzhülse, Stoßdämpferlagerung (Fahrerhaus)",
            "NormTermName": "Hülse",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Stoßdämpferlagerung"
        },
        "03629": {
            "GenartName": "Unterlegscheibe, Stabilisatorlagerung (Fahrerhaus)",
            "NormTermName": "Unterlegscheibe",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Stabilisatorlagerung (Fahrerhaus)"
        },
        "03630": {
            "GenartName": "Bundschraube, Gelenkwelle",
            "NormTermName": "Schraube",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwelle"
        },
        "03631": {
            "GenartName": "Abdeckung, Umlenk-/Führungsrolle (Keilrippenriemen)",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilrippenriemenumlenkung/-führung"
        },
        "03632": {
            "GenartName": "Halter, Heckleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Heckleuchte"
        },
        "03633": {
            "GenartName": "Halter, Heckleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Heckleuchte"
        },
        "03634": {
            "GenartName": "Kolben, Schaltzylinder",
            "NormTermName": "Kolben",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltzylinder"
        },
        "03635": {
            "GenartName": "Magnetventil, Schaltzylinder",
            "NormTermName": "Ventil",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltzylinder"
        },
        "03636": {
            "GenartName": "Prüfgerät, Radsensor",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Radsensor"
        },
        "03637": {
            "GenartName": "Kabelsatz, Prüfgerät-Radsensor",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Radsensor"
        },
        "03638": {
            "GenartName": "Dichtungssatz, Anschlussadapter (Entlüftungsgerät)",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Anschlussadapter (Entlüftungsgerät)"
        },
        "03639": {
            "GenartName": "Software, Schaltpläne",
            "NormTermName": "Software",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Schaltpläne"
        },
        "03640": {
            "GenartName": "Kraftstoffkühler",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoff"
        },
        "03641": {
            "GenartName": "Sensor, Waschwasserstand",
            "NormTermName": "Sensor",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasserstand"
        },
        "03642": {
            "GenartName": "Koffer-/Laderaumtasche",
            "NormTermName": "Tasche",
            "AssGrpName": "Zubehör",
            "UsageName": "Koffer-/Laderaum"
        },
        "03643": {
            "GenartName": "Koffer-/Laderaumtasche",
            "NormTermName": "Tasche",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Koffer-/Laderaum"
        },
        "03644": {
            "GenartName": "Gewindeeinsatz",
            "NormTermName": "Einsatz",
            "AssGrpName": null,
            "UsageName": null
        },
        "03645": {
            "GenartName": "Temperaturschalter, Kühlerlüfter",
            "NormTermName": "Schalter",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Motorkühlung"
        },
        "03646": {
            "GenartName": "Steuergerät, CAN Bus",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Bordnetz",
            "UsageName": "CAN Bus"
        },
        "03647": {
            "GenartName": "Relais, Rückfahrleuchte",
            "NormTermName": "Relais",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Rückfahrleuchte"
        },
        "03648": {
            "GenartName": "Ölfilter, Schaltgetriebe",
            "NormTermName": "Filter",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "03649": {
            "GenartName": "Sicherungsring, Bremsbackenrollenbolzen",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbackenrollenbolzen"
        },
        "03650": {
            "GenartName": "Sicherungsring, Bremswelle",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremswelle"
        },
        "03651": {
            "GenartName": "Sicherungsring, Achsschenkelbolzen",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsschenkelbolzen"
        },
        "03652": {
            "GenartName": "Federsplint, Radbolzen",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radbolzen"
        },
        "03653": {
            "GenartName": "Rep.Satz,Mehrwegeventil, Füll-/Entlüftungsgerät (Bremshydr.)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Mehrwegeventil, Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03655": {
            "GenartName": "Laufradsatz, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Laufradsatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03656": {
            "GenartName": "Antriebsfeder, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Feder",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03657": {
            "GenartName": "Hauptschalter, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Schalter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03658": {
            "GenartName": "Netzkabel, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03659": {
            "GenartName": "Sieb, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Sieb",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03660": {
            "GenartName": "Sauglanze, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Sauglanze",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03661": {
            "GenartName": "Dichtringsatz, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Dichtringsatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03662": {
            "GenartName": "Verschlussdeckel, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Verschluss",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03666": {
            "GenartName": "Druckregler, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Regler",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03667": {
            "GenartName": "Überdruckventil, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Ventil",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03668": {
            "GenartName": "Membran, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Membran",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03669": {
            "GenartName": "Manometer, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Manometer",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03670": {
            "GenartName": "Entlüftungsventil, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Ventil",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Entlüftung, Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03671": {
            "GenartName": "Absperrhahn, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Absperrhahn",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03672": {
            "GenartName": "Füllschlauch, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03673": {
            "GenartName": "Endschalter, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Schalter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Endabschaltung, Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "03674": {
            "GenartName": "Sonnenblende",
            "NormTermName": "Blende",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": "Sonnenblende"
        },
        "03675": {
            "GenartName": "Gelenkwelle, Achsantrieb",
            "NormTermName": "Welle",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwelle"
        },
        "03677": {
            "GenartName": "Stecker, Zündkerze",
            "NormTermName": "Stecker",
            "AssGrpName": "Zündanlage, Universal",
            "UsageName": "Zündkerze"
        },
        "03678": {
            "GenartName": "Stecker, Zündverteiler",
            "NormTermName": "Stecker",
            "AssGrpName": "Zündanlage, Universal",
            "UsageName": "Zündverteiler"
        },
        "03679": {
            "GenartName": "Stecker, Zündspule",
            "NormTermName": "Stecker",
            "AssGrpName": "Zündanlage, Universal",
            "UsageName": "Zündspule"
        },
        "03680": {
            "GenartName": "Glühlampe, Abbiegescheinwerfer",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Abbiegescheinwerfer"
        },
        "03681": {
            "GenartName": "Abbiegescheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Abbiegescheinwerfer"
        },
        "03682": {
            "GenartName": "Abbiegescheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Abbiegescheinwerfer"
        },
        "03683": {
            "GenartName": "Glühlampe, Abbiegescheinwerfer",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Abbiegescheinwerfer"
        },
        "03684": {
            "GenartName": "Bolzen",
            "NormTermName": "Bolzen",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "03685": {
            "GenartName": "Dichtring, Schaltgestänge",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgestänge"
        },
        "03686": {
            "GenartName": "Druckspeicher, Kupplung",
            "NormTermName": "Druckspeicher",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplung"
        },
        "03687": {
            "GenartName": "Distanzscheibe, Fahrerhausstabilisator",
            "NormTermName": "Distanzscheibe",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhausstabilisator"
        },
        "03689": {
            "GenartName": "Dichtring, Federbuchse (Federauge)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbuchse (Federauge)"
        },
        "03690": {
            "GenartName": "Mutter, Federträgerachse",
            "NormTermName": "Mutter",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federträgerachse"
        },
        "03692": {
            "GenartName": "Handschuhfach",
            "NormTermName": "Handschuhfach",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "03693": {
            "GenartName": "Aschenbecher",
            "NormTermName": "Aschenbecher",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "03694": {
            "GenartName": "Sonnenblende",
            "NormTermName": "Blende",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Sonnenblende"
        },
        "03695": {
            "GenartName": "Sicherheitsgurt",
            "NormTermName": "Gurt",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Sicherheitsgurt"
        },
        "03696": {
            "GenartName": "Türverkleidung",
            "NormTermName": "Verkleidung",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Türverkleidung"
        },
        "03697": {
            "GenartName": "Haltegriff, Innenausstattung",
            "NormTermName": "Griff",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Haltegriff"
        },
        "03698": {
            "GenartName": "Handbremshebel",
            "NormTermName": "Hebel",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Handbremshebel"
        },
        "03699": {
            "GenartName": "Abdeckung, Handbremshebel",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Handbremshebel"
        },
        "03700": {
            "GenartName": "Flansch, Differential",
            "NormTermName": "Flansch",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "03701": {
            "GenartName": "Kurbelgehäuse",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": null
        },
        "03702": {
            "GenartName": "Kurbelwelle",
            "NormTermName": "Welle",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwelle"
        },
        "03703": {
            "GenartName": "Abdeckung, Drehstab",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Drehstab"
        },
        "03704": {
            "GenartName": "Nadellager, Achskörper",
            "NormTermName": "Lager",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achskörper"
        },
        "03705": {
            "GenartName": "Traghebel, Drehstab",
            "NormTermName": "Hebel",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Traghebel (Drehstab)"
        },
        "03706": {
            "GenartName": "Antriebswelle, Ölpumpe",
            "NormTermName": "Welle",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "03707": {
            "GenartName": "Schalthebelknauf",
            "NormTermName": "Schalthebelknauf",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "03708": {
            "GenartName": "Synchronring, Schaltgetriebe",
            "NormTermName": "Synchronring",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "03709": {
            "GenartName": "Stehbolzen, Spannrolle",
            "NormTermName": "Bolzen",
            "AssGrpName": "Riementrieb",
            "UsageName": "Spannrolle"
        },
        "03710": {
            "GenartName": "Stehbolzen, Umlenk-/Führungsrolle",
            "NormTermName": "Bolzen",
            "AssGrpName": "Riementrieb",
            "UsageName": "Umlenk-/Führungsrolle"
        },
        "03711": {
            "GenartName": "Reparatursatz, Radsensor (Reifendruck-Kontrollsys.)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Räder",
            "UsageName": "Radsensor (Reifendruck-Kontrollsystem)"
        },
        "03712": {
            "GenartName": "Stehbolzen",
            "NormTermName": "Bolzen",
            "AssGrpName": "Normteile",
            "UsageName": "Stehbolzen"
        },
        "03713": {
            "GenartName": "Montagesatz, Stoßfänger",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Stoßfänger"
        },
        "03714": {
            "GenartName": "Montagesatz, Motorhaube",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorhaube"
        },
        "03715": {
            "GenartName": "Montagesatz, Frontverkleidung",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Frontverkleidung"
        },
        "03716": {
            "GenartName": "Montagesatz, Kotflügel",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Kotflügel"
        },
        "03717": {
            "GenartName": "Montagesatz, Kühlergitter",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Kühlergitter"
        },
        "03718": {
            "GenartName": "Montagesatz, Tür",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Tür"
        },
        "03719": {
            "GenartName": "Türgriff, Innenausstattung",
            "NormTermName": "Griff",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "03720": {
            "GenartName": "Kurvenscheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kurvenscheinwerfer"
        },
        "03721": {
            "GenartName": "Sensor, Xenonlicht (Leuchtweitenregulierung)",
            "NormTermName": "Sensor",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Leuchtweitenregulierung"
        },
        "03723": {
            "GenartName": "Lenkradfernbedienung, Navigationssystem/Autoradio",
            "NormTermName": "Fernbedienung",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Lenkradfernbedienung-Navigationssystem/Autoradio"
        },
        "03725": {
            "GenartName": "Monitor, Navigationssystem",
            "NormTermName": "Monitor",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Navigationssystem"
        },
        "03726": {
            "GenartName": "Dichtung, Turbineneinlass (Lader)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Turbineneinlass (Lader)"
        },
        "03727": {
            "GenartName": "Dichtung, Turbinenauslass",
            "NormTermName": "Dichtung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Turbinenauslass (Lader)"
        },
        "03728": {
            "GenartName": "Dichtung, Öleinlass (Lader)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Öleinlass (Lader)"
        },
        "03729": {
            "GenartName": "Dichtung, Ölauslass (Lader)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ölauslass (Lader)"
        },
        "03730": {
            "GenartName": "Dichtring, Ölauslass (Lader)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ölauslass (Lader)"
        },
        "03731": {
            "GenartName": "Multimedia Interface",
            "NormTermName": "Schnittstelle",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Multimedia"
        },
        "03732": {
            "GenartName": "Fernbedienung, Navigationssystem/Autoradio",
            "NormTermName": "Fernbedienung",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Navigationssystem/Autoradio"
        },
        "03733": {
            "GenartName": "DVD Player",
            "NormTermName": "DVD Player",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "03734": {
            "GenartName": "Deckenmonitor",
            "NormTermName": "Monitor",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Deckenmonitor"
        },
        "03735": {
            "GenartName": "Unterlegscheibe, Laderbefestigungsschraube",
            "NormTermName": "Unterlegscheibe",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Laderbefestigungsschraube"
        },
        "03736": {
            "GenartName": "Sicherungsscheibe, Lader",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Sicherungsscheibe (Lader)"
        },
        "03737": {
            "GenartName": "Klemmfeder, Abgasrohrbefestigung (Lader)",
            "NormTermName": "Feder",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Abgasrohrbefestigung"
        },
        "03738": {
            "GenartName": "Haltebügel, Lader",
            "NormTermName": "Haltebügel",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lader"
        },
        "03739": {
            "GenartName": "Ölleitung, Lader",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ölleitung (Lader)"
        },
        "03740": {
            "GenartName": "Hohlschraube, Lader",
            "NormTermName": "Schraube",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Hohlschraube (Lader)"
        },
        "03741": {
            "GenartName": "Halteschelle, Ladeluftschlauch",
            "NormTermName": "Schelle",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladeluftschlauch"
        },
        "03742": {
            "GenartName": "TV Empfänger",
            "NormTermName": "TV Empfänger",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "03743": {
            "GenartName": "Dichtring, Lader",
            "NormTermName": "Dichtring",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lader"
        },
        "03744": {
            "GenartName": "Dichtung, Wasseranschluss (Lader)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Wasseranschluss (Lader)"
        },
        "03745": {
            "GenartName": "Adapterkabel, Vorverstärker",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Vorverstärker"
        },
        "03746": {
            "GenartName": "Anschlusskabel, Monitor (Navigationssystem)",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Monitor-Navigationssystem"
        },
        "03747": {
            "GenartName": "Halter, Bremsflüssigkeitsbehälter (Füll-/Entlüftungsgerät)",
            "NormTermName": "Halter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät"
        },
        "03748": {
            "GenartName": "Adapterkabel, Lenkradfernbedienung",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Lenkradfernbedienung"
        },
        "03749": {
            "GenartName": "Service-Gerät, Elektronisches Bremsenwerkzeug",
            "NormTermName": "Service-Gerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Elektronisches Bremsenwerkzeug"
        },
        "03750": {
            "GenartName": "Software, Elektronisches Bremsenwerkzeug",
            "NormTermName": "Software",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Elektronisches Bremsenwerkzeug"
        },
        "03751": {
            "GenartName": "OBD-Kabel, Elektronisches Bremsenwerkzeug",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "OBD-Kabel (Elektronisches Bremsenwerkzeug)"
        },
        "03752": {
            "GenartName": "USB-Kabel, Elektronisches Bremsenwerkzeug",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "USB-Kabel (Elektronisches Bremsenwerkzeug)"
        },
        "03753": {
            "GenartName": "Halter, Monitor (Navigationssystem)",
            "NormTermName": "Halter",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Monitor-Navigationssystem"
        },
        "03754": {
            "GenartName": "Anschlusskabel, Multimedia Interface",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Multimedia Interface"
        },
        "03755": {
            "GenartName": "Abstreifer",
            "NormTermName": "Abstreifer",
            "AssGrpName": null,
            "UsageName": null
        },
        "03756": {
            "GenartName": "Reparatursatz, Drehzapfenlager",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Drehzapfenlager"
        },
        "03757": {
            "GenartName": "Verbindungsrohr, Heizungskanal",
            "NormTermName": "Rohr",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizungskanal"
        },
        "03758": {
            "GenartName": "Dichtung, Heizungsschlauch",
            "NormTermName": "Dichtung",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizungsschlauch"
        },
        "03759": {
            "GenartName": "Anschlagpuffer, Achsschenkel",
            "NormTermName": "Puffer",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Anschlagpuffer-Achsschenkel"
        },
        "03760": {
            "GenartName": "Halteklammer, Anschlagpuffer (Achsschenkel)",
            "NormTermName": "Halteklammer",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Anschlagpuffer-Achsschenkel"
        },
        "03761": {
            "GenartName": "Luftleitkasten, Ölkühler",
            "NormTermName": "Kasten",
            "AssGrpName": "Kühlung",
            "UsageName": "Luftleitkasten-Ölkühler"
        },
        "03762": {
            "GenartName": "Luftleitblech, Ölkühler",
            "NormTermName": "Luftleitblech",
            "AssGrpName": "Kühlung",
            "UsageName": "Ölkühler"
        },
        "03763": {
            "GenartName": "Unterlegscheibe",
            "NormTermName": "Unterlegscheibe",
            "AssGrpName": null,
            "UsageName": null
        },
        "03764": {
            "GenartName": "Federscheibe",
            "NormTermName": "Federscheibe",
            "AssGrpName": null,
            "UsageName": null
        },
        "03765": {
            "GenartName": "Diode",
            "NormTermName": "Diode",
            "AssGrpName": null,
            "UsageName": null
        },
        "03766": {
            "GenartName": "Klemmhebel",
            "NormTermName": "Hebel",
            "AssGrpName": null,
            "UsageName": null
        },
        "03767": {
            "GenartName": "Halteklammer",
            "NormTermName": "Halteklammer",
            "AssGrpName": null,
            "UsageName": null
        },
        "03768": {
            "GenartName": "Kerbnagel",
            "NormTermName": "Kerbnagel",
            "AssGrpName": null,
            "UsageName": null
        },
        "03769": {
            "GenartName": "Plombe, Versiegelung",
            "NormTermName": "Plombe",
            "AssGrpName": null,
            "UsageName": "Versiegelung"
        },
        "03770": {
            "GenartName": "Plombendraht",
            "NormTermName": "Plombendraht",
            "AssGrpName": null,
            "UsageName": null
        },
        "03771": {
            "GenartName": "Wellendichtring",
            "NormTermName": "Wellendichtring",
            "AssGrpName": null,
            "UsageName": null
        },
        "03772": {
            "GenartName": "Splint",
            "NormTermName": "Sicherungselement",
            "AssGrpName": null,
            "UsageName": "Sicherung"
        },
        "03773": {
            "GenartName": "Spannstift",
            "NormTermName": "Stift",
            "AssGrpName": null,
            "UsageName": "Spannstift"
        },
        "03774": {
            "GenartName": "Typschild",
            "NormTermName": "Typschild",
            "AssGrpName": null,
            "UsageName": null
        },
        "03775": {
            "GenartName": "Verbindungs-/Verteilerstück",
            "NormTermName": "Verbindungselement",
            "AssGrpName": null,
            "UsageName": null
        },
        "03776": {
            "GenartName": "Wälzlagerfett",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Wälzlagerfett"
        },
        "03777": {
            "GenartName": "Batteriepolfett",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Batteriepolfett"
        },
        "03778": {
            "GenartName": "Rückfahrwarner",
            "NormTermName": "Rückfahrwarner",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": null
        },
        "03779": {
            "GenartName": "Kurvenscheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Kurvenscheinwerfer"
        },
        "03780": {
            "GenartName": "Abbiegescheinwerfersatz",
            "NormTermName": "Scheinwerfersatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Abbiegescheinwerfer"
        },
        "03781": {
            "GenartName": "Abbiegescheinwerfersatz",
            "NormTermName": "Scheinwerfersatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Abbiegescheinwerfer"
        },
        "03782": {
            "GenartName": "Verbandkasten",
            "NormTermName": "Verbandkasten",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "03783": {
            "GenartName": "Notfalltasche/-koffer",
            "NormTermName": "Notfalltasche/-koffer",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "03784": {
            "GenartName": "Thermostat, Kühlmittel",
            "NormTermName": "Thermostat",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Kühlmittel"
        },
        "03785": {
            "GenartName": "Elektromotor, Verdeck",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Verdeck"
        },
        "03786": {
            "GenartName": "Steuergerät, Verdeck",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Verdeck"
        },
        "03787": {
            "GenartName": "Montagesatz, Radlager",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radlager"
        },
        "03788": {
            "GenartName": "Sensor, Wegstrecke",
            "NormTermName": "Sensor",
            "AssGrpName": "Instrumente",
            "UsageName": "Wegstrecke"
        },
        "03789": {
            "GenartName": "Scharnier, Tankklappe",
            "NormTermName": "Scharnier",
            "AssGrpName": "Karosserie",
            "UsageName": "Tankklappe"
        },
        "03790": {
            "GenartName": "Verschlussdeckel, Ausgleichsbehälter-Servolenkung",
            "NormTermName": "Verschluss",
            "AssGrpName": "Lenkung",
            "UsageName": "Ausgleichsbehälter-Servolenkung"
        },
        "03791": {
            "GenartName": "Sortiment, Halter-Abgasanlage",
            "NormTermName": "Sortiment",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Halter-Abgasanlage"
        },
        "03792": {
            "GenartName": "Spurverbreiterung",
            "NormTermName": "Distanzscheibe",
            "AssGrpName": "Räder",
            "UsageName": "Spurverbreiterung"
        },
        "03793": {
            "GenartName": "Batterieadapter",
            "NormTermName": "Adapter",
            "AssGrpName": "Startanlage",
            "UsageName": "Starterbatterie"
        },
        "03794": {
            "GenartName": "Motorvorwärmsystem",
            "NormTermName": "Motorvorwärmsystem",
            "AssGrpName": "Komfortsysteme",
            "UsageName": null
        },
        "03795": {
            "GenartName": "Heizelement, Motorvorwärmsystem",
            "NormTermName": "Heizelement",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Motorvorwärmsystem"
        },
        "03796": {
            "GenartName": "Kabelsatz, Motorvorwärmsystem",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Motorvorwärmsystem"
        },
        "03797": {
            "GenartName": "Kabelsatz, Innenraumheizlüfter (Motorvorwärmsystem)",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Innenraumheizlüfter (Motorvorwärmsystem)"
        },
        "03798": {
            "GenartName": "Fernstart-Steuereinheit, Motorvorwärmsystem",
            "NormTermName": "Fernstart-Steuereinheit",
            "AssGrpName": "Komfortsysteme",
            "UsageName": null
        },
        "03799": {
            "GenartName": "Batterieladegerät, Motorvorwärmsystem",
            "NormTermName": "Ladegerät",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Batterie"
        },
        "03800": {
            "GenartName": "Zeitschaltuhr, Motorvorwärmsystem",
            "NormTermName": "Zeitschaltuhr",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Motorvorwärmsystem"
        },
        "03801": {
            "GenartName": "Heizlüfter",
            "NormTermName": "Lüfter",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Heizlüfter"
        },
        "03802": {
            "GenartName": "Wasserumwälzpumpe, Motorvorwärmsystem",
            "NormTermName": "Pumpe",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Motorvorwärmsystem"
        },
        "03803": {
            "GenartName": "Hitzeschutzblech, Heizelement (Motorvorwärmsystem)",
            "NormTermName": "Hitzeschutzblech",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Heizelement (Motorvorwärmsystem)"
        },
        "03804": {
            "GenartName": "Montagesatz, Motorvorwärmsystem",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Motorvorwärmsystem"
        },
        "03805": {
            "GenartName": "Schraubensatz, Anschraubplatte (Anhängevorrichtung)",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Anschraubplatte (Anhängevorrichtung)"
        },
        "03806": {
            "GenartName": "Wellendichtring, Antriebswelle (Ölpumpe)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schmierung",
            "UsageName": "Antriebswelle (Ölpumpe)"
        },
        "03807": {
            "GenartName": "Wellendichtring, Ausrücklagerwelle",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kupplung",
            "UsageName": "Ausrücklagerwelle"
        },
        "03808": {
            "GenartName": "Wellendichtring, Schaltgetriebehauptwelle",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebehauptwelle"
        },
        "03809": {
            "GenartName": "Wellendichtring, Kompressorwelle",
            "NormTermName": "Dichtring",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kompressorwelle"
        },
        "03811": {
            "GenartName": "Wellendichtring, Servopumpe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Lenkung",
            "UsageName": "Servopumpe"
        },
        "03812": {
            "GenartName": "Dichtung, Federbeinstützlager",
            "NormTermName": "Dichtung",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbeinstützlager"
        },
        "03813": {
            "GenartName": "Stellelement, Exzenterwelle (variabler Ventilhub)",
            "NormTermName": "Stellelement",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Exzenterwelle (variabler Ventilhub)"
        },
        "03814": {
            "GenartName": "Dichtung, Stößelkammerdeckel",
            "NormTermName": "Dichtung",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Stößelkammerdeckel"
        },
        "03815": {
            "GenartName": "Dichtung, Gehäusedeckel (Kurbelgehäuse)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Gehäusedeckel, Kurbelgehäuse"
        },
        "03816": {
            "GenartName": "Dichtung, Ladedruckregelventil",
            "NormTermName": "Dichtung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladedruckregelventil"
        },
        "03817": {
            "GenartName": "Rückfahrkamera, Einparkhilfe",
            "NormTermName": "Kamera",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Einparkhilfe"
        },
        "03818": {
            "GenartName": "Rückfahrkamera, Einparkhilfe",
            "NormTermName": "Kamera",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Einparkhilfe"
        },
        "03819": {
            "GenartName": "Monitor, Einparkhilfe",
            "NormTermName": "Monitor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Einparkhilfe"
        },
        "03820": {
            "GenartName": "Monitor, Einparkhilfe",
            "NormTermName": "Monitor",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Einparkhilfe"
        },
        "03821": {
            "GenartName": "Reparatursatz",
            "NormTermName": "Reparatursatz",
            "AssGrpName": null,
            "UsageName": null
        },
        "03822": {
            "GenartName": "Reifenventilaktivator",
            "NormTermName": "Service-Gerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Reifenventilaktivator"
        },
        "03823": {
            "GenartName": "Prüfset, Schwungrad",
            "NormTermName": "Prüfgerätesatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schwungrad"
        },
        "03824": {
            "GenartName": "Montagesatz, Weitwinkelspiegel",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Weitwinkelspiegel"
        },
        "03825": {
            "GenartName": "Kupplungsbetätigungssystem",
            "NormTermName": "Kupplungsbetätigungssystem",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "03826": {
            "GenartName": "Steuerventil, Nockenwellenverstellung",
            "NormTermName": "Ventil",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwellenverstellung"
        },
        "03827": {
            "GenartName": "Steuerdose, Lader",
            "NormTermName": "Steuerdose",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lader"
        },
        "03828": {
            "GenartName": "Gehäuse, Begrenzungsleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Begrenzungsleuchte"
        },
        "03829": {
            "GenartName": "Gehäuse, Begrenzungsleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Begrenzungsleuchte"
        },
        "03830": {
            "GenartName": "Abdeckung, Außenspiegelhalter",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegelhalter"
        },
        "03831": {
            "GenartName": "Anschlusskabel, Anhängerstabilisierungssystem",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Anhängerstabilisierungssystem"
        },
        "03832": {
            "GenartName": "Kabelsatz, Heckleuchte",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Heckleuchte"
        },
        "03833": {
            "GenartName": "Kabelsatz, Signalsystem (Einsatzfahrzeug)",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Signalsystem (Einsatzfahrzeug)"
        },
        "03834": {
            "GenartName": "Kabelsatz, Blitz-Kennleuchte",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blitz-Kennleuchte"
        },
        "03835": {
            "GenartName": "Klemmstück",
            "NormTermName": "Klemmstück",
            "AssGrpName": null,
            "UsageName": null
        },
        "03836": {
            "GenartName": "Luxmeter",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Luxmeter"
        },
        "03837": {
            "GenartName": "Prüfschirm, Prüfgerät-Scheinwerfereinstellung",
            "NormTermName": "Prüfschirm",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Scheinwerfereinstellung"
        },
        "03838": {
            "GenartName": "Rollensatz, Prüfgerät-Scheinwerfereinstellung",
            "NormTermName": "Rollensatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Scheinwerfereinstellung"
        },
        "03839": {
            "GenartName": "Führungsschiene, Prüfgerät-Scheinwerfereinstellung",
            "NormTermName": "Führungsschiene",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Scheinwerfereinstellung"
        },
        "03840": {
            "GenartName": "Justiergerät, Prüfgerät-Scheinwerfereinstellung",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Scheinwerfereinstellung"
        },
        "03841": {
            "GenartName": "Optikkasten, Prüfgerät-Scheinwerfereinstellung",
            "NormTermName": "Optikkasten",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Scheinwerfereinstellung"
        },
        "03842": {
            "GenartName": "Handrad, Prüfgerät-Scheinwerfereinstellung",
            "NormTermName": "Handrad",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Scheinwerfereinstellung"
        },
        "03843": {
            "GenartName": "Hydraulikfiltersatz, Automatikgetriebe",
            "NormTermName": "Hydraulikfiltersatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "03844": {
            "GenartName": "Dichtung, Faltdach",
            "NormTermName": "Dichtung",
            "AssGrpName": "Karosserie",
            "UsageName": "Faltdach"
        },
        "03845": {
            "GenartName": "Riemenscheibe, Hydraulikpumpe (Niveauregulierung)",
            "NormTermName": "Riemenscheibe",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Hydraulikpumpe (Niveauregulierung)"
        },
        "03846": {
            "GenartName": "Rollenführung, Schiebetür",
            "NormTermName": "Führung",
            "AssGrpName": "Karosserie",
            "UsageName": "Rollenführung"
        },
        "03847": {
            "GenartName": "Montagesatz, Spannrolle (Zahnriemen)",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Spannrolle (Zahnriemen)"
        },
        "03848": {
            "GenartName": "Montagesatz, Spannrolle (Keilrippenriemen)",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Spannarm-Keilrippenriemen"
        },
        "03849": {
            "GenartName": "Montagesatz, Umlenk-/Führungsrolle (Zahnriemen)",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Umlenk-/Führungsrolle (Zahnriemen)"
        },
        "03850": {
            "GenartName": "Montagesatz, Umlenk-/Führungsrolle (Keilrippenriemen)",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Umlenk-/Führungsrolle (Keilrippenriemen)"
        },
        "03852": {
            "GenartName": "Kühlernetz, Ladeluftkühler",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Kühler-Ladeluftkühlung"
        },
        "03853": {
            "GenartName": "Glühlampe, Tagfahrleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Tagfahrleuchte"
        },
        "03854": {
            "GenartName": "Glühlampe, Tagfahrleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Tagfahrleuchte"
        },
        "03855": {
            "GenartName": "Harnstofffilter",
            "NormTermName": "Filter",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstoff"
        },
        "03856": {
            "GenartName": "Software, Technische Daten",
            "NormTermName": "Software",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Technische Daten"
        },
        "03857": {
            "GenartName": "Antriebsstange, Wischergestänge",
            "NormTermName": "Stange",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Antriebsstange"
        },
        "03858": {
            "GenartName": "Luftleitblech, Motor",
            "NormTermName": "Luftleitblech",
            "AssGrpName": "Kühlung",
            "UsageName": "Motor"
        },
        "03859": {
            "GenartName": "Bremsensatz, Trommelbremse",
            "NormTermName": "Bremsensatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Trommelbremse"
        },
        "03860": {
            "GenartName": "Öldruckschalter, Servolenkung",
            "NormTermName": "Schalter",
            "AssGrpName": "Lenkung",
            "UsageName": "Öldruck-Servolenkung"
        },
        "03861": {
            "GenartName": "Innenspiegel",
            "NormTermName": "Spiegel",
            "AssGrpName": "Karosserie",
            "UsageName": "Innen"
        },
        "03862": {
            "GenartName": "Innenspiegel",
            "NormTermName": "Spiegel",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Innen"
        },
        "03864": {
            "GenartName": "Bedieneinheit, Signalsystem (Einsatzfahrzeug)",
            "NormTermName": "Bedieneinheit",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Signalsystem (Einsatzfahrzeug)"
        },
        "03865": {
            "GenartName": "Einbaurahmen, Bedieneinheit-Signalanlage (Einsatzfahrzeug)",
            "NormTermName": "Rahmen",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Bedieneinheit (Einsatzfahrzeug)"
        },
        "03866": {
            "GenartName": "Antenne, Eigendiagnose-Gerät",
            "NormTermName": "Antenne",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Eigendiagnose-Gerät"
        },
        "03867": {
            "GenartName": "Stromkabel, Eigendiagnose-Gerät",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Eigendiagnose-Gerät"
        },
        "03868": {
            "GenartName": "OBD Verlängerungskabel, Eigendiagnose-Gerät",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "OBD Verlängerungskabel (Eigendiagnose-Gerät)"
        },
        "03869": {
            "GenartName": "Adapterkabel, Eigendiagnose-Gerät",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Eigendiagnose-Gerät"
        },
        "03870": {
            "GenartName": "Kondenswasserablaufschlauch, Innenraumluftfiltergehäuse",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Kondenswasserablaufschlauch, Innenraumluftfiltergehäuse"
        },
        "03871": {
            "GenartName": "Lagerung, Motor",
            "NormTermName": "Grafik",
            "AssGrpName": "Motoraufhängung",
            "UsageName": null
        },
        "03872": {
            "GenartName": "Lagerung, Automatikgetriebe",
            "NormTermName": "Grafik",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "03873": {
            "GenartName": "Lagerung, Schaltgetriebe",
            "NormTermName": "Grafik",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "03874": {
            "GenartName": "Wellendichtringsatz, Motor",
            "NormTermName": "Wellendichtringsatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Motor"
        },
        "03875": {
            "GenartName": "Halter, Schaltgetriebeaufhängung",
            "NormTermName": "Halter",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeaufhängung"
        },
        "03876": {
            "GenartName": "Halter, Automatikgetriebeaufhängung",
            "NormTermName": "Halter",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Getriebeaufhängung"
        },
        "03877": {
            "GenartName": "Prüfgerät, Anhängersteckdose",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Anhängersteckdose"
        },
        "03878": {
            "GenartName": "Wellendichtringsatz, Kupplung",
            "NormTermName": "Wellendichtringsatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplung"
        },
        "03879": {
            "GenartName": "Gehäuse, Wasserpumpe",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "03880": {
            "GenartName": "Pleuel, Druckluftkompressor",
            "NormTermName": "Pleuel",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "03881": {
            "GenartName": "Clip, Zier-/Schutzleiste",
            "NormTermName": "Clip",
            "AssGrpName": "Karosserie",
            "UsageName": "Zier-/Schutzleiste"
        },
        "03882": {
            "GenartName": "Dichtungsentferner",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Dichtflächen"
        },
        "03883": {
            "GenartName": "Sportluftfilter",
            "NormTermName": "Filter",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Sportluftfilter"
        },
        "03884": {
            "GenartName": "Sportluftfiltersystem",
            "NormTermName": "Sportluftfiltersystem",
            "AssGrpName": "Luftversorgung",
            "UsageName": null
        },
        "03886": {
            "GenartName": "Ventil, Kurbelgehäuseentlüftung",
            "NormTermName": "Ventil",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "03887": {
            "GenartName": "Airbag",
            "NormTermName": "Airbag",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": null
        },
        "03888": {
            "GenartName": "Dichtungssatz, Hydraulikpumpe (Niveauregulierung)",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Hydraulikpumpe (Niveauregulierung)"
        },
        "03889": {
            "GenartName": "Abdeckung, Einspritzdüse",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzdüse"
        },
        "03890": {
            "GenartName": "Abstellvorrichtung, Einspritzanlage",
            "NormTermName": "Abstellvorrichtung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": null
        },
        "03891": {
            "GenartName": "Dichtring, Einspritzventil",
            "NormTermName": "Dichtung/Dichtring",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzventil"
        },
        "03892": {
            "GenartName": "Dichtung, Düsenhalter",
            "NormTermName": "Dichtung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Düsenhalter"
        },
        "03893": {
            "GenartName": "Dichtung, Einspritzpumpe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpe"
        },
        "03894": {
            "GenartName": "Dichtungssatz, Einspritzdüse",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzdüse"
        },
        "03895": {
            "GenartName": "Einspritzanlage",
            "NormTermName": "Einspritzanlage",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": null
        },
        "03897": {
            "GenartName": "Druckspeicher",
            "NormTermName": "Druckspeicher",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": null
        },
        "03898": {
            "GenartName": "Düsenhalter",
            "NormTermName": "Halter",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzdüse"
        },
        "03899": {
            "GenartName": "Düsenkörper",
            "NormTermName": "Düsenkörper",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": null
        },
        "03900": {
            "GenartName": "Düsennadel",
            "NormTermName": "Düsennadel",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": null
        },
        "03901": {
            "GenartName": "Düsenstock",
            "NormTermName": "Düsenstock",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": null
        },
        "03902": {
            "GenartName": "Einspritzdüse",
            "NormTermName": "Düse",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzdüse"
        },
        "03903": {
            "GenartName": "Einspritzeinheit, Zentraleinspritzung",
            "NormTermName": "Einspritzeinheit",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Zentraleinspritzung"
        },
        "03904": {
            "GenartName": "Einspritzpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpe"
        },
        "03905": {
            "GenartName": "Einspritzventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzventil"
        },
        "03906": {
            "GenartName": "Einstellpotentiometer, Leerlaufgemisch",
            "NormTermName": "Potentiometer",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Leerlaufgemisch"
        },
        "03907": {
            "GenartName": "Elektromotor, Gebläse Steuergerätebox",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Gebläse Steuergerätebox"
        },
        "03908": {
            "GenartName": "Fahrpedalsatz",
            "NormTermName": "Sensorsatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Fahrpedalstellung"
        },
        "03909": {
            "GenartName": "Feder, Gasgestänge",
            "NormTermName": "Feder",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Gasgestänge"
        },
        "03910": {
            "GenartName": "Flansch, Zentraleinspritzung",
            "NormTermName": "Flansch",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Zentraleinspritzung"
        },
        "03911": {
            "GenartName": "Gaszug",
            "NormTermName": "Seilzug",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Gaszug"
        },
        "03912": {
            "GenartName": "Gestängedämpfer, Einspritzanlage",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Gasgestänge"
        },
        "03913": {
            "GenartName": "Gestängedämpfer, Drosselklappe",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Gestänge"
        },
        "03914": {
            "GenartName": "Halter, Einspritzventil",
            "NormTermName": "Halter",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzventil"
        },
        "03915": {
            "GenartName": "Heizflansch, Ansaugluftvorwärmung",
            "NormTermName": "Flansch",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Ansaugluftvorwärmung"
        },
        "03916": {
            "GenartName": "Hochdruckleitung, Einspritzanlage",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": null
        },
        "03917": {
            "GenartName": "Hochdruckleitungssatz, Einspritzanlage",
            "NormTermName": "Rohrleitungssatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": null
        },
        "03918": {
            "GenartName": "Hochdruckpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Hochdruckpumpe"
        },
        "03919": {
            "GenartName": "Kaltstartregelungssystem, Euro2-Umrüstung",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Leerlaufdrehzahlanhebung für Euro2"
        },
        "03920": {
            "GenartName": "Kaltstartventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kaltstartsteuerung"
        },
        "03921": {
            "GenartName": "Klopfsensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Motorklopfen"
        },
        "03922": {
            "GenartName": "Lambdasonde",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Lambdawert"
        },
        "03923": {
            "GenartName": "Lambdasonde",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Lambdawert"
        },
        "03924": {
            "GenartName": "Lambdasondensatz",
            "NormTermName": "Sensorsatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Lambdawert"
        },
        "03925": {
            "GenartName": "Luftdrucksensor, Höhenanpassung",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Luftdruck Höhenanpassung"
        },
        "03926": {
            "GenartName": "Luftmassenmesser",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Luftmasse"
        },
        "03927": {
            "GenartName": "Luftmengenmesser",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Luftmenge"
        },
        "03928": {
            "GenartName": "Manschette, Kraftstoffpumpe",
            "NormTermName": "Manschette",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "03929": {
            "GenartName": "Mengenteiler, Einspritzanlage",
            "NormTermName": "Mengenteiler",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": null
        },
        "03930": {
            "GenartName": "Pumpe-Düse-Einheit",
            "NormTermName": "Pumpe-Düse-Einheit",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": null
        },
        "03931": {
            "GenartName": "Relais, Schubabschaltung",
            "NormTermName": "Relais",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Schubabschaltung"
        },
        "03932": {
            "GenartName": "Reparatursatz, Common-Rail-System",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Common-Rail-System"
        },
        "03933": {
            "GenartName": "Reparatursatz, Einspritzdüse",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzdüse"
        },
        "03934": {
            "GenartName": "Reparatursatz, Pumpe-Düse-Einheit",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Pumpe-Düse-Einheit"
        },
        "03935": {
            "GenartName": "Schalter, Bremsbetätigung (Motorsteuerung)",
            "NormTermName": "Schalter",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Bremsbetätigung (Motorsteuerung)"
        },
        "03936": {
            "GenartName": "Schalter, Kupplungsbetätigung (Motorsteuerung)",
            "NormTermName": "Schalter",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kupplungsbetätigung (Motorsteuerung)"
        },
        "03937": {
            "GenartName": "Schlauch, Leckkraftstoff",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Leckkraftstoff"
        },
        "03938": {
            "GenartName": "Sensor, Abgastemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Abgastemperatur"
        },
        "03939": {
            "GenartName": "Sensor, Ansauglufttemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Ansauglufttemperatur"
        },
        "03940": {
            "GenartName": "Sensor, Drosselklappenstellung",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Drosselklappe"
        },
        "03941": {
            "GenartName": "Sensor, Fahrpedalstellung",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Fahrpedalstellung"
        },
        "03942": {
            "GenartName": "Sensor, Kraftstoffdruck",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kraftstoffdruck"
        },
        "03943": {
            "GenartName": "Sensor, Kraftstofftemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kraftstofftemperatur"
        },
        "03944": {
            "GenartName": "Sensor, Kühlmitteltemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kühlmitteltemperatur"
        },
        "03945": {
            "GenartName": "Sensor, Ladedruck",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Ladedruck"
        },
        "03946": {
            "GenartName": "Sensor, Nockenwellenposition",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Nockenwelle"
        },
        "03947": {
            "GenartName": "Sensor, Saugrohrdruck",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Saugrohr"
        },
        "03948": {
            "GenartName": "Spannmutter, Düsenhalter",
            "NormTermName": "Mutter",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Düsenhalter"
        },
        "03949": {
            "GenartName": "Stecker, Einspritzventil",
            "NormTermName": "Stecker",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzventil"
        },
        "03950": {
            "GenartName": "Stellelement, Drosselklappe",
            "NormTermName": "Stellelement",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Drosselklappe"
        },
        "03951": {
            "GenartName": "Stellelement, Einspritzpumpe",
            "NormTermName": "Stellelement",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpe"
        },
        "03952": {
            "GenartName": "Steuergerät, Einspritzanlage",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": null
        },
        "03953": {
            "GenartName": "Steuergerät, Klopfsensor",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Klopfsensor"
        },
        "03954": {
            "GenartName": "Steuergerät, Kraftstoffeinspritzung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kraftstoffeinspritzung"
        },
        "03955": {
            "GenartName": "Steuergerät, Lambdaregelung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Lambdaregelung"
        },
        "03956": {
            "GenartName": "Steuergerät, Motormanagement",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Motormanagement"
        },
        "03957": {
            "GenartName": "Temperaturschalter, Kaltstartanreicherung",
            "NormTermName": "Schalter",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Warmlaufphase"
        },
        "03958": {
            "GenartName": "Unterdruckschalter, Ansaugkrümmerbelüftung",
            "NormTermName": "Schalter",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Ansaugkrümmerbelüftung"
        },
        "03959": {
            "GenartName": "Ventil, Einspritzanlage",
            "NormTermName": "Ventil",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzanlage"
        },
        "03960": {
            "GenartName": "Ventil, Kraftstofffilter",
            "NormTermName": "Ventil",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstofffilter"
        },
        "03961": {
            "GenartName": "Ventil, Schubabschaltung",
            "NormTermName": "Ventil",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Schubabschaltung"
        },
        "03962": {
            "GenartName": "Ventileinheit, Common-Rail-System",
            "NormTermName": "Ventileinheit",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Common-Rail-System"
        },
        "03963": {
            "GenartName": "Verschlusskappe, Leckkraftstoff",
            "NormTermName": "Kappe",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Leckkraftstoff"
        },
        "03964": {
            "GenartName": "Verteilerrohr, Kraftstoff",
            "NormTermName": "Rohr",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Verteilerrohr"
        },
        "03965": {
            "GenartName": "Vorwiderstand, Einspritzventil",
            "NormTermName": "Widerstand",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzventil"
        },
        "03966": {
            "GenartName": "Wärmeschutzscheibe, Einspritzanlage",
            "NormTermName": "Schutzscheibe",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Wärmeschutz"
        },
        "03967": {
            "GenartName": "Warmlaufregler",
            "NormTermName": "Regler",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Warmlaufphase"
        },
        "03968": {
            "GenartName": "Wellendichtring, Einspritzpumpe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpe"
        },
        "03970": {
            "GenartName": "Steckerhülse, Zündkabel",
            "NormTermName": "Hülse",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündkabel"
        },
        "03971": {
            "GenartName": "Flexrohr, Abgasanlage",
            "NormTermName": "Rohr",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Flexrohr"
        },
        "03972": {
            "GenartName": "Steuergerät, Batteriemanagement",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Bordnetz",
            "UsageName": "Batteriemanagement"
        },
        "03973": {
            "GenartName": "Lichtscheibe, Zusatzblinkleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Zusatzblinkleuchte"
        },
        "03974": {
            "GenartName": "Dichtringsatz, Magnetventil",
            "NormTermName": "Dichtringsatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Magnetventil"
        },
        "03976": {
            "GenartName": "Ventilkappe",
            "NormTermName": "Kappe",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Einlass-/Auslassventil"
        },
        "03977": {
            "GenartName": "Buchse, Zylinderkopfhaube",
            "NormTermName": "Buchse",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Zylinderkopfhaube"
        },
        "03978": {
            "GenartName": "Schwungradgehäuse",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Schwungrad"
        },
        "03979": {
            "GenartName": "Dichtring, Abgaskrümmer",
            "NormTermName": "Dichtring",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Abgaskrümmer"
        },
        "03980": {
            "GenartName": "Dichtring, Ladeluftschlauch",
            "NormTermName": "Dichtring",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladeluftschlauch"
        },
        "03981": {
            "GenartName": "Schraube, Abgaskrümmer",
            "NormTermName": "Schraube",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgaskrümmer"
        },
        "03983": {
            "GenartName": "Gabelkopf, Kupplungsverstärker (Schubstange)",
            "NormTermName": "Gabelkopf",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Kupplungsverstärker (Schubstange)"
        },
        "03984": {
            "GenartName": "Bolzen, Gabelkopf (Kupplungsverstärker)",
            "NormTermName": "Bolzen",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Gabelkopf (Kupplungsverstärker)"
        },
        "03985": {
            "GenartName": "Schraubensatz, Schwungrad",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Schwungrad"
        },
        "03986": {
            "GenartName": "Dichtung, Kupplungsgehäuse",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsgehäuse"
        },
        "03987": {
            "GenartName": "Zahnrad, Wasserpumpenwelle",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpenwelle"
        },
        "03988": {
            "GenartName": "Dichtring, Kühlmittelrohrleitung",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelleitung"
        },
        "03989": {
            "GenartName": "Deckel, Kupplungsgehäuse",
            "NormTermName": "Deckel",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsgehäuse"
        },
        "03990": {
            "GenartName": "Dichtung, Schaltgehäuse-Getriebe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgehäuse-Getriebe"
        },
        "03991": {
            "GenartName": "Ölpumpe, Schaltgetriebe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "03992": {
            "GenartName": "Mitnehmerscheibe, Antriebsvorrichtung-Einspritzpumpe",
            "NormTermName": "Mitnehmerscheibe",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Antriebsvorrichtung-Einspritzpumpe"
        },
        "03993": {
            "GenartName": "Hülse, Antriebsvorrichtung-Einspritzpumpe",
            "NormTermName": "Hülse",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Antriebsvorrichtung-Einspritzpumpe"
        },
        "03994": {
            "GenartName": "Mutter, Antriebsflansch-Einspritzpumpe",
            "NormTermName": "Mutter",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Antriebsflansch-Einspritzpumpe"
        },
        "03995": {
            "GenartName": "Antriebsflansch, Gelenkwelle",
            "NormTermName": "Flansch",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwelle"
        },
        "03996": {
            "GenartName": "Druckregelventil, Common-Rail-System",
            "NormTermName": "Ventil",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Common-Rail-System"
        },
        "03997": {
            "GenartName": "Dichtkappe, Zugstangengabel-Niveauausgleichsventil",
            "NormTermName": "Kappe",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Zugstangengabel-Niveauausgleichsventil"
        },
        "03998": {
            "GenartName": "Faltenbalg, Sicherungsstift-Schaltstangenarm",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Sicherungsstift-Schaltstangenarm"
        },
        "03999": {
            "GenartName": "Sperrzylinder, Schaltgetriebe",
            "NormTermName": "Zylinder",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Sperrzylinder"
        },
        "04000": {
            "GenartName": "Führungshülse, Radnabe",
            "NormTermName": "Hülse",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Führungshülse-Radnabe"
        },
        "04001": {
            "GenartName": "Achsstern, Ausgleichskegelrad-Differential",
            "NormTermName": "Achsstern",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Ausgleichskegelrad-Differential"
        },
        "04002": {
            "GenartName": "Druckscheibe, Ausgleichskegelrad-Differential",
            "NormTermName": "Druckscheibe",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Ausgleichskegelrad-Differential"
        },
        "04003": {
            "GenartName": "Dichtring, Verschlussstopfen-Servopumpe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Lenkung",
            "UsageName": "Verschlussstopfen-Servopumpe"
        },
        "04004": {
            "GenartName": "Distanzring, Hauptwelle-Außenplanetengetriebe",
            "NormTermName": "Distanzring",
            "AssGrpName": "Radantrieb",
            "UsageName": "Hauptwelle-Außenplanetengetriebe"
        },
        "04005": {
            "GenartName": "Distanzring, Ölverteilerwelle-Außenplanetengetriebe",
            "NormTermName": "Distanzring",
            "AssGrpName": "Radantrieb",
            "UsageName": "Ölverteilerwelle-Außenplanetengetriebe"
        },
        "04006": {
            "GenartName": "Synchronring, Hauptwelle-Außenplanetengetriebe",
            "NormTermName": "Synchronring",
            "AssGrpName": "Radantrieb",
            "UsageName": "Hauptwelle-Außenplanetengetriebe"
        },
        "04007": {
            "GenartName": "Bolzen, Synchronkörper-Außenplanetengetriebe",
            "NormTermName": "Bolzen",
            "AssGrpName": "Radantrieb",
            "UsageName": "Synchronkörper-Außenplanetengetriebe"
        },
        "04008": {
            "GenartName": "Lagerbuchse, Gangrad-Außenplanetengetriebe",
            "NormTermName": "Buchse",
            "AssGrpName": "Radantrieb",
            "UsageName": "Gangrad-Außenplanetengetriebe"
        },
        "04009": {
            "GenartName": "Druckscheibe, Abtriebswelle-Außenplanetengetriebe",
            "NormTermName": "Druckscheibe",
            "AssGrpName": "Radantrieb",
            "UsageName": "Abtriebswelle-Außenplanetengetriebe"
        },
        "04010": {
            "GenartName": "Synchronring, Abtriebswelle-Außenplanetengetriebe",
            "NormTermName": "Synchronring",
            "AssGrpName": "Radantrieb",
            "UsageName": "Abtriebswelle-Außenplanetengetriebe"
        },
        "04011": {
            "GenartName": "Sicherungsscheibe, Antriebsflanschbefestigung-Differential",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Antriebsflanschbefestigung-Differential"
        },
        "04012": {
            "GenartName": "Einstiegsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Einstiegsholm"
        },
        "04013": {
            "GenartName": "Batterieaufnahme",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Batterieaufnahme"
        },
        "04014": {
            "GenartName": "Dichtring, Verschlussdeckel-Ausgleichsbehälter",
            "NormTermName": "Dichtring",
            "AssGrpName": "Lenkung",
            "UsageName": "Verschlussdeckel-Ausgleichsbehälter"
        },
        "04015": {
            "GenartName": "Befestigungselement, Filtereinsatz-Servolenkung",
            "NormTermName": "Halter",
            "AssGrpName": "Lenkung",
            "UsageName": "Filtereinsatz-Servolenkung"
        },
        "04016": {
            "GenartName": "Hubschwingenrolle, Liftachse",
            "NormTermName": "Rolle",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Liftachse"
        },
        "04017": {
            "GenartName": "Reparatursatz, Relaisventil",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Relaisventil"
        },
        "04018": {
            "GenartName": "Reparatursatz, Luftfederventil",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederventil"
        },
        "04019": {
            "GenartName": "Dichtring, Fahrerhausstabilisator",
            "NormTermName": "Dichtring",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhausstabilisator"
        },
        "04020": {
            "GenartName": "Schraube, Fahrerhausstabilisator",
            "NormTermName": "Schraube",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhausstabilisator"
        },
        "04021": {
            "GenartName": "Reparatursatz, Schaltzylinder",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltzylinder"
        },
        "04022": {
            "GenartName": "Reparatursatz, Differential",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "04023": {
            "GenartName": "Ausgleichskegelradsatz, Differential",
            "NormTermName": "Ausgleichskegelradsatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "04024": {
            "GenartName": "Reparatursatz, Schaltventil-Schaltzylinder",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltventil-Schaltzylinder"
        },
        "04025": {
            "GenartName": "Reparatursatz, Druckregler-Druckluftanlage",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckregler"
        },
        "04026": {
            "GenartName": "Reparatursatz, Drucksteuerventil",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Drucksteuerventil"
        },
        "04027": {
            "GenartName": "Reparatursatz, Bremsventil-Betriebsbremse",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Bremsventil-Betriebsbremse"
        },
        "04028": {
            "GenartName": "Reparatursatz, Bremsventil-Feststellbremse",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Bremsventil-Feststellbremse"
        },
        "04029": {
            "GenartName": "Reparatursatz, Last-/Leerventil",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Last-/Leerventil"
        },
        "04030": {
            "GenartName": "Reparatursatz, Federlager-Rahmen",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federlagerung"
        },
        "04031": {
            "GenartName": "Reparatursatz, Hubzylinder-Liftachse",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Hubzylinder-Liftachse"
        },
        "04032": {
            "GenartName": "Kühler, Abgasrückführung",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Abgasrückführung",
            "UsageName": null
        },
        "04034": {
            "GenartName": "Feder, Kupplungspedal",
            "NormTermName": "Feder",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungspedal"
        },
        "04035": {
            "GenartName": "Kappe, Radmutter",
            "NormTermName": "Kappe",
            "AssGrpName": "Räder",
            "UsageName": "Radmutter"
        },
        "04036": {
            "GenartName": "Zugfeder, Schalthebel",
            "NormTermName": "Feder",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schalthebel"
        },
        "04037": {
            "GenartName": "Feder, Öldruckventil-Ölpumpe",
            "NormTermName": "Feder",
            "AssGrpName": "Schmierung",
            "UsageName": "Öldruckventil-Ölpumpe"
        },
        "04038": {
            "GenartName": "Reparatursatz, Trilex",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Räder",
            "UsageName": "Trilex"
        },
        "04039": {
            "GenartName": "Spreizniet",
            "NormTermName": "Niet",
            "AssGrpName": "Normteile",
            "UsageName": "Spreizniet"
        },
        "04040": {
            "GenartName": "Schlauchleitung, Lufttrockner",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Lufttrockner"
        },
        "04042": {
            "GenartName": "Halter, Batteriekastendeckel",
            "NormTermName": "Halter",
            "AssGrpName": "Startanlage",
            "UsageName": "Batteriekastendeckel"
        },
        "04043": {
            "GenartName": "Schlauchleitung, Fahrerhauskippvorrichtung",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhauskippvorrichtung"
        },
        "04044": {
            "GenartName": "Ölrohr, Ölverteilerwelle-Außenplanetengetriebe",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Ölverteilerwelle-Außenplanetengetriebe"
        },
        "04045": {
            "GenartName": "Druckleitung, Druckluftkompressor",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "04046": {
            "GenartName": "Schlauchleitung, Motorbremse",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Motorbremse"
        },
        "04047": {
            "GenartName": "Zugstangengabel, Niveauausgleichsventil",
            "NormTermName": "Gabelkopf",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Niveauausgleichsventil"
        },
        "04048": {
            "GenartName": "Bolzen, Zugstangengabel-Niveauausgleichsventil",
            "NormTermName": "Bolzen",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Zugstangengabel-Niveauausgleichsventil"
        },
        "04049": {
            "GenartName": "Reparatursatz, Düsenhalter",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Düsenhalter"
        },
        "04050": {
            "GenartName": "Hülse, Düsenhalter",
            "NormTermName": "Hülse",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Düsenhalter"
        },
        "04051": {
            "GenartName": "Dichtring, Düsenhalter",
            "NormTermName": "Dichtring",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Düsenhalter"
        },
        "04052": {
            "GenartName": "Spreizanker, Kabelbinder",
            "NormTermName": "Anker",
            "AssGrpName": "Normteile",
            "UsageName": "Kabelbefestigung"
        },
        "04053": {
            "GenartName": "Klebeplatte, Kabelbinder",
            "NormTermName": "Klebeplatte",
            "AssGrpName": "Normteile",
            "UsageName": "Kabelbefestigung"
        },
        "04054": {
            "GenartName": "Flexible Kupplungsmuffe",
            "NormTermName": "Kupplungsmuffe",
            "AssGrpName": "Riementrieb",
            "UsageName": null
        },
        "04055": {
            "GenartName": "Filter-Satz",
            "NormTermName": "Filter-Satz",
            "AssGrpName": "Service/Wartung",
            "UsageName": null
        },
        "04056": {
            "GenartName": "Klemmschraube, Trag-/Führungsgelenk",
            "NormTermName": "Schraube",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "04057": {
            "GenartName": "Klemmschraubensatz, Trag-/Führungsgelenk",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "04058": {
            "GenartName": "Verlängerungskabel, Lambdasonde",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Lambdasonde-Verlängerungskabel"
        },
        "04059": {
            "GenartName": "Flexible Kupplungsmuffe-Satz",
            "NormTermName": "Kupplungsmuffe-Satz",
            "AssGrpName": "Riementrieb",
            "UsageName": null
        },
        "04060": {
            "GenartName": "Montagewerkzeug, Zahnriemen",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zahnriemen"
        },
        "04061": {
            "GenartName": "Montagewerkzeug, Keilrippenriemen",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Keilrippenriemen"
        },
        "04062": {
            "GenartName": "Freilauf, Klimakompressor",
            "NormTermName": "Freilauf",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kompressor"
        },
        "04063": {
            "GenartName": "Deckel, Batteriekasten",
            "NormTermName": "Deckel",
            "AssGrpName": "Startanlage",
            "UsageName": "Batteriekasten"
        },
        "04064": {
            "GenartName": "Reparatursatz, Klimaanlage",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": null
        },
        "04065": {
            "GenartName": "Lagerbuchse, Ölpumpe",
            "NormTermName": "Buchse",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "04066": {
            "GenartName": "Reparatursatz, Kippzylinder",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Kippzylinder"
        },
        "04067": {
            "GenartName": "Reparatursatz, Kipppumpe",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Kipppumpe"
        },
        "04068": {
            "GenartName": "Elektromotor, Pumpenaggregat-Hydraulik",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Hydraulische Anlage",
            "UsageName": "Pumpenaggregat-Hydraulik"
        },
        "04069": {
            "GenartName": "Kolben, Druckluftkompressor",
            "NormTermName": "Kolben",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "04070": {
            "GenartName": "Abrollkolben, Luftfederbalg",
            "NormTermName": "Kolben",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Luftfederbalg"
        },
        "04071": {
            "GenartName": "Kolbenringsatz, Kompressor",
            "NormTermName": "Ringsatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Kompressorkolben"
        },
        "04072": {
            "GenartName": "Halter, Kotflügel",
            "NormTermName": "Halter",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Kotflügel"
        },
        "04073": {
            "GenartName": "Sonnenblende",
            "NormTermName": "Blende",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Sonnenblende"
        },
        "04074": {
            "GenartName": "Montagesatz, Sonnenblende",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Sonnenblende"
        },
        "04075": {
            "GenartName": "Scheinwerferschutzsatz",
            "NormTermName": "Scheinwerferschutzsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": null
        },
        "04076": {
            "GenartName": "Dichtring, Hubschwinge-Liftachse",
            "NormTermName": "Dichtring",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Hubschwinge-Liftachse"
        },
        "04077": {
            "GenartName": "Zahnrad, Druckluftkompressor",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "04078": {
            "GenartName": "Regenklappe, Endrohr",
            "NormTermName": "Klappe",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Endrohr"
        },
        "04079": {
            "GenartName": "Ausgleichskegelrad, Differential",
            "NormTermName": "Ausgleichskegelrad",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "04080": {
            "GenartName": "Antriebswelle, Verteilergetriebe",
            "NormTermName": "Welle",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Verteilergetriebe"
        },
        "04081": {
            "GenartName": "Teller-/Kegelradsatz",
            "NormTermName": "Teller-/Kegelradsatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": null
        },
        "04082": {
            "GenartName": "Hohlrad, Außenplanetengetriebe",
            "NormTermName": "Hohlrad",
            "AssGrpName": "Radantrieb",
            "UsageName": "Außenplanetengetriebe"
        },
        "04083": {
            "GenartName": "Seilzug, Lenksäulenverstellung",
            "NormTermName": "Seilzug",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenksäulenverstellung"
        },
        "04084": {
            "GenartName": "Hülse, Halteband-Druckluftbehälter",
            "NormTermName": "Hülse",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Halteband-Druckluftbehälter"
        },
        "04085": {
            "GenartName": "Peilstab, Hydrauliköl",
            "NormTermName": "Peilstab",
            "AssGrpName": "Lenkung",
            "UsageName": "Hydraulik"
        },
        "04086": {
            "GenartName": "Hülse, Halteband-Kraftstoffbehälter",
            "NormTermName": "Hülse",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Halteband-Kraftstoffbehälter"
        },
        "04087": {
            "GenartName": "Mutter, Halteband-Kraftstoffbehälter",
            "NormTermName": "Mutter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Halteband-Kraftstoffbehälter"
        },
        "04088": {
            "GenartName": "Schraube, Halteband-Kraftstoffbehälter",
            "NormTermName": "Schraube",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Halteband-Kraftstoffbehälter"
        },
        "04089": {
            "GenartName": "Türverlängerung",
            "NormTermName": "Türverlängerung",
            "AssGrpName": "Fahrerhaus",
            "UsageName": null
        },
        "04090": {
            "GenartName": "Windabweiser",
            "NormTermName": "Windabweiser",
            "AssGrpName": "Fahrerhaus",
            "UsageName": null
        },
        "04091": {
            "GenartName": "Gehäuse, Differential",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "04092": {
            "GenartName": "Hohlrad, Schaltgetriebe",
            "NormTermName": "Hohlrad",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "04093": {
            "GenartName": "Vorgelegewelle, Schaltgetriebe",
            "NormTermName": "Welle",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Vorgelegewelle"
        },
        "04095": {
            "GenartName": "Lager, Wähl-/ Schaltstange",
            "NormTermName": "Lager",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Wähl-/Schaltstange"
        },
        "04096": {
            "GenartName": "Antriebswelle, Ölpumpe",
            "NormTermName": "Welle",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Ölpumpe"
        },
        "04097": {
            "GenartName": "Planetenradsatz",
            "NormTermName": "Planetenradsatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "04098": {
            "GenartName": "Getriebeeingangswelle, Schaltgetriebe",
            "NormTermName": "Welle",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeeingangswelle"
        },
        "04099": {
            "GenartName": "Lager, Schaltgetriebe",
            "NormTermName": "Lager",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "04100": {
            "GenartName": "Zahnrad, Getriebeeingangswelle",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeeingangswelle"
        },
        "04101": {
            "GenartName": "Schaltmuffe, Schaltgetriebe",
            "NormTermName": "Schaltmuffe",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "04102": {
            "GenartName": "Synchronkörper, Schaltgetriebe",
            "NormTermName": "Synchronkörper",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "04103": {
            "GenartName": "Feder, Synchronrad-Antriebswelle",
            "NormTermName": "Feder",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Synchronrad-Antriebswelle"
        },
        "04104": {
            "GenartName": "Synchronkegel, Gangrad",
            "NormTermName": "Synchronkegel",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "04105": {
            "GenartName": "Dichtung, Heizflansch-Ansaugluftvorwärmung",
            "NormTermName": "Dichtung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Heizflansch-Ansaugluftvorwärmung"
        },
        "04106": {
            "GenartName": "Nockenwellenversteller",
            "NormTermName": "Nockenwellenversteller",
            "AssGrpName": "Motorsteuerung",
            "UsageName": null
        },
        "04107": {
            "GenartName": "Stellelement",
            "NormTermName": "Stellelement",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "04109": {
            "GenartName": "Adapter, Stellelement",
            "NormTermName": "Adapter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Stellelement"
        },
        "04110": {
            "GenartName": "Start-Knopf",
            "NormTermName": "Start-Knopf",
            "AssGrpName": "Startanlage",
            "UsageName": null
        },
        "04111": {
            "GenartName": "Start-/Stopp-Knopf",
            "NormTermName": "Start-/Stopp-Knopf",
            "AssGrpName": "Startanlage",
            "UsageName": null
        },
        "04112": {
            "GenartName": "Drehzahlsensor, Schaltgetriebe",
            "NormTermName": "Sensor",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Drehzahl"
        },
        "04113": {
            "GenartName": "Drehzahlsensor, Automatikgetriebe",
            "NormTermName": "Sensor",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Drehzahl"
        },
        "04114": {
            "GenartName": "Schneekette",
            "NormTermName": "Schneekette",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04116": {
            "GenartName": "Gehäuse, Luftmengenmesser",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Luftmengenmesser"
        },
        "04117": {
            "GenartName": "Ausrücksatz, Kupplungsbetätigung",
            "NormTermName": "Ausrücksatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsbetätigung"
        },
        "04118": {
            "GenartName": "Luftfederbein",
            "NormTermName": "Luftfederbein",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "04119": {
            "GenartName": "Luftfeder, Fahrwerk",
            "NormTermName": "Luftfeder",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Fahrwerk"
        },
        "04120": {
            "GenartName": "Krümmerkatalysator",
            "NormTermName": "Katalysator",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Krümmerkatalysator"
        },
        "04121": {
            "GenartName": "Fahrradhalter, Heckträger",
            "NormTermName": "Fahrradhalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Heckträger"
        },
        "04122": {
            "GenartName": "Fahrradhalter, Dachträger",
            "NormTermName": "Fahrradhalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "04123": {
            "GenartName": "Kennzeichenhalter",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Nummernschild"
        },
        "04124": {
            "GenartName": "Skihalter, Dachträger",
            "NormTermName": "Skihalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "04125": {
            "GenartName": "Skihalter, Heckträger",
            "NormTermName": "Skihalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Heckträger"
        },
        "04126": {
            "GenartName": "Zier-/Schutzleiste, Stoßfänger",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Stoßfänger"
        },
        "04127": {
            "GenartName": "Zier-/Schutzleiste, Tür",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Tür"
        },
        "04128": {
            "GenartName": "Absperrgitter, Koffer-/Laderaum",
            "NormTermName": "Gitter",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Koffer-/Laderaum"
        },
        "04130": {
            "GenartName": "Türverkleidung",
            "NormTermName": "Verkleidung",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": "Türverkleidung"
        },
        "04131": {
            "GenartName": "Schalthebelknauf",
            "NormTermName": "Schalthebelknauf",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04132": {
            "GenartName": "Kugel",
            "NormTermName": "Kugel",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "04133": {
            "GenartName": "Lagersatz, Differential",
            "NormTermName": "Lagersatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "04134": {
            "GenartName": "Sammelschiene",
            "NormTermName": "Sammelschiene",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "04135": {
            "GenartName": "Zündgerät, Gasentladungslampe",
            "NormTermName": "Zündgerät",
            "AssGrpName": "Beleuchtung",
            "UsageName": null
        },
        "04136": {
            "GenartName": "Dichtring, Ansaugschlauch-Luftfiltergehäuse",
            "NormTermName": "Dichtring",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ansaugschlauch-Luftfiltergehäuse"
        },
        "04137": {
            "GenartName": "Dichtung, Kühlerlüfterhalter",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlerlüfterhalter"
        },
        "04138": {
            "GenartName": "Distanzring, Getriebeeingangswelle",
            "NormTermName": "Distanzring",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeeingangswelle"
        },
        "04139": {
            "GenartName": "Sicherungsring, Synchronkörper",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Sicherungsring"
        },
        "04140": {
            "GenartName": "Kappe, Schalthebelknauf",
            "NormTermName": "Kappe",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schalthebelknauf"
        },
        "04141": {
            "GenartName": "Einstellwelle, Gestängesteller",
            "NormTermName": "Welle",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Gestängesteller"
        },
        "04142": {
            "GenartName": "Mutter, Federbügel",
            "NormTermName": "Mutter",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbügel"
        },
        "04143": {
            "GenartName": "Sensor, Saugrohrumschaltklappe",
            "NormTermName": "Sensor",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Saugrohrumschaltklappe"
        },
        "04144": {
            "GenartName": "Drucksensor, Bremskraftverstärker",
            "NormTermName": "Sensor",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremskraftverstärker"
        },
        "04145": {
            "GenartName": "Vorwiderstand, Elektromotor (Kühlerlüfter)",
            "NormTermName": "Widerstand",
            "AssGrpName": "Kühlung",
            "UsageName": "Elektromotor (Kühlerlüfter)"
        },
        "04146": {
            "GenartName": "Reparatursatz, Unterdruckpumpe (Bremsanlage)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruckpumpe (Bremsanlage)"
        },
        "04147": {
            "GenartName": "Frostschutz-/ Batteriesäureprüfgerät (Refraktometer)",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Frostschutz/Batteriesäure"
        },
        "04148": {
            "GenartName": "Gelenk, Schaltstange",
            "NormTermName": "Gelenk",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltstange"
        },
        "04149": {
            "GenartName": "Bremsschlauchsatz",
            "NormTermName": "Schlauchleitungssatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "04150": {
            "GenartName": "Sensor, Schaltmodul",
            "NormTermName": "Sensor",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltstellung"
        },
        "04151": {
            "GenartName": "Kenteken",
            "NormTermName": "Kenteken",
            "AssGrpName": null,
            "UsageName": null
        },
        "04153": {
            "GenartName": "Konturmarkierung",
            "NormTermName": "Konturmarkierung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": null
        },
        "04154": {
            "GenartName": "Lampenträger, Seitenmarkierungsleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "04155": {
            "GenartName": "Lampenträger, Seitenmarkierungsleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "04156": {
            "GenartName": "Dichtung, Seitenmarkierungsleuchte",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "04157": {
            "GenartName": "Lampenträger, Umrissleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Umrissleuchte"
        },
        "04158": {
            "GenartName": "Lampenträger, Umrissleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Umrissleuchte"
        },
        "04160": {
            "GenartName": "Zeitrelais",
            "NormTermName": "Relais",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Zeitfunktion"
        },
        "04161": {
            "GenartName": "Batterietrageband",
            "NormTermName": "Band",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Batterie"
        },
        "04162": {
            "GenartName": "Lötwerkzeugsatz, Batteriepol",
            "NormTermName": "Lötwerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Batteriepol"
        },
        "04163": {
            "GenartName": "Spannungshalter, Bordnetz",
            "NormTermName": "Spannungshalter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bordnetz"
        },
        "04164": {
            "GenartName": "Gaswarngerät",
            "NormTermName": "Warngerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Gaskonzentration"
        },
        "04166": {
            "GenartName": "Batterie-Zellenfüller",
            "NormTermName": "Batterie-Zellenfüller",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04167": {
            "GenartName": "Sortiment, Einstellscheibe-Ventilspiel",
            "NormTermName": "Sortiment",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ventilspiel"
        },
        "04168": {
            "GenartName": "Geflechtschlauch",
            "NormTermName": "Geflechtschlauch",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "04169": {
            "GenartName": "Abisolierzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "abisolieren"
        },
        "04170": {
            "GenartName": "Gaslötbrenner",
            "NormTermName": "Gaslötbrenner",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04171": {
            "GenartName": "OBD-Kabel, Eigendiagnose-Gerät",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "OBD-Kabel, Eigendiagnose-Gerät"
        },
        "04172": {
            "GenartName": "Wasserpumpe + Keilrippenriemensatz",
            "NormTermName": "Wasserpumpen-/Keilrippenriemensatz",
            "AssGrpName": "Kühlung",
            "UsageName": null
        },
        "04173": {
            "GenartName": "Montagewerkzeugsatz, Riementrieb",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04174": {
            "GenartName": "Halter, Hilfsrahmen/Aggregateträger",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie",
            "UsageName": "Hilfrahmen/Aggregateträger"
        },
        "04175": {
            "GenartName": "Sensor, Öltemperatur/-druck",
            "NormTermName": "Sensor",
            "AssGrpName": "Instrumente",
            "UsageName": "Öltemperatur/-druck"
        },
        "04176": {
            "GenartName": "Dichtring, Motorölstandsensor",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schmierung",
            "UsageName": "Motorölstandsensor"
        },
        "04177": {
            "GenartName": "Halter, AGR-Ventil",
            "NormTermName": "Halter",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "AGR-Ventil"
        },
        "04178": {
            "GenartName": "Dichtung, Halter AGR-Ventil",
            "NormTermName": "Dichtung",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "Halter AGR-Ventil"
        },
        "04179": {
            "GenartName": "Rohrleitung, AGR-Ventil",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "AGR-Ventil"
        },
        "04180": {
            "GenartName": "Dichtung, Leitung AGR-Ventil",
            "NormTermName": "Dichtung",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "Leitung AGR-Ventil"
        },
        "04181": {
            "GenartName": "Dockingstation, Eigendiagnose-Gerät",
            "NormTermName": "Dockingstation",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Eigendiagnose-Gerät"
        },
        "04182": {
            "GenartName": "CAN Bus-Kabel, Eigendiagnose-Gerät",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "CAN Bus, Eigendiagnose-Gerät"
        },
        "04183": {
            "GenartName": "USB-Kabel, Eigendiagnose-Gerät",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "USB-Kabel, Eigendiagnose-Gerät"
        },
        "04184": {
            "GenartName": "Software Update, Systemdiagnose",
            "NormTermName": "Software",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Software Update, Systemdiagnose"
        },
        "04185": {
            "GenartName": "CD-ROM-Handbuch, Eigendiagnose-Gerät",
            "NormTermName": "Software",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Eigendiagnose-Gerät"
        },
        "04186": {
            "GenartName": "Aufbewahrungstasche, Eigendiagnose-Gerät",
            "NormTermName": "Tasche",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Eigendiagnose-Gerät"
        },
        "04187": {
            "GenartName": "Spiralschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Spiralschlauch"
        },
        "04188": {
            "GenartName": "Kabelschneider",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kabelschneider"
        },
        "04189": {
            "GenartName": "Anhängekupplungsträger, Universal",
            "NormTermName": "Träger",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsträger"
        },
        "04190": {
            "GenartName": "Kabelbinderzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kabelbinder"
        },
        "04191": {
            "GenartName": "Kabeleinziehhilfe",
            "NormTermName": "Kabeleinziehhilfe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04192": {
            "GenartName": "Universalschere",
            "NormTermName": "Universalschere",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04193": {
            "GenartName": "Dreidornzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Dreidornzange"
        },
        "04194": {
            "GenartName": "Reinigungskontakt",
            "NormTermName": "Reinigungskontakt",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04195": {
            "GenartName": "Reinigungskontakt-Set",
            "NormTermName": "Reinigungskontakt-Set",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04196": {
            "GenartName": "Batteriepol-/Klemmenfräser",
            "NormTermName": "Batteriepol-/Klemmenfräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04197": {
            "GenartName": "Membrandruckschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Membrandruckschalter"
        },
        "04198": {
            "GenartName": "AGR-Modul",
            "NormTermName": "AGR-Modul",
            "AssGrpName": "Abgasrückführung",
            "UsageName": null
        },
        "04199": {
            "GenartName": "Halter, Radzierblende",
            "NormTermName": "Halter",
            "AssGrpName": "Räder",
            "UsageName": "Radzierblende"
        },
        "04200": {
            "GenartName": "Zahnrad, Einspritzpumpe",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpe"
        },
        "04201": {
            "GenartName": "Nabe, Wasserpumpe",
            "NormTermName": "Nabe",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "04202": {
            "GenartName": "Schwingungsdämpfer, Schaltgestänge",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgestänge"
        },
        "04203": {
            "GenartName": "Seilzug, Klappenentriegelung-Staukasten",
            "NormTermName": "Seilzug",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Klappenentriegelung-Staukasten"
        },
        "04204": {
            "GenartName": "Riemenscheibe, Servopumpe",
            "NormTermName": "Riemenscheibe",
            "AssGrpName": "Lenkung",
            "UsageName": "Servopumpe"
        },
        "04205": {
            "GenartName": "Lagerbuchse, Hubschwinge-Liftachse",
            "NormTermName": "Buchse",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Hubschwinge-Liftachse"
        },
        "04206": {
            "GenartName": "Lagerung, Servopumpe",
            "NormTermName": "Lagerung",
            "AssGrpName": "Lenkung",
            "UsageName": "Servopumpe"
        },
        "04207": {
            "GenartName": "Dichtung, Thermostat-Ölkühlung",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schmierung",
            "UsageName": "Thermostat-Ölkühlung"
        },
        "04208": {
            "GenartName": "Hochleistungs-Bremsschlauchsatz",
            "NormTermName": "Schlauchleitungssatz (Hochleistungsbremse)",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremshydraulik-Hochleistungsbremse"
        },
        "04209": {
            "GenartName": "Frontspiegel, Fahrerhaus",
            "NormTermName": "Spiegel",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Frontspiegel"
        },
        "04210": {
            "GenartName": "Spiegelglas, Frontspiegel",
            "NormTermName": "Spiegelglas",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Frontspiegel"
        },
        "04211": {
            "GenartName": "Dichtung, Motorhaube",
            "NormTermName": "Dichtung",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorhaube"
        },
        "04212": {
            "GenartName": "Radauswuchtmaschine",
            "NormTermName": "Radauswuchtmaschine",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Rad"
        },
        "04213": {
            "GenartName": "Ladeleitungskabel, Anhängevorrichtung",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Ladeleitung"
        },
        "04214": {
            "GenartName": "Transportbox, Anhängekupplungsträger",
            "NormTermName": "Transportbox",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsträger"
        },
        "04215": {
            "GenartName": "Gepäckkorb, Anhängekupplungsträger",
            "NormTermName": "Gepäckkorb",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsträger"
        },
        "04216": {
            "GenartName": "Fahrradhalter, Anhängekupplungsträger",
            "NormTermName": "Fahrradhalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsträger"
        },
        "04217": {
            "GenartName": "Einführhilfe, Steckkontakt",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steckkontakt"
        },
        "04218": {
            "GenartName": "Ventil, Verdichter-Bypassklappe",
            "NormTermName": "Ventil",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Verdichter-Bypassklappe"
        },
        "04219": {
            "GenartName": "Crimpzangengriff",
            "NormTermName": "Griff",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Crimpzange"
        },
        "04220": {
            "GenartName": "Armaturenbrett",
            "NormTermName": "Armaturenbrett",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "04221": {
            "GenartName": "Emblem",
            "NormTermName": "Emblem",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04222": {
            "GenartName": "Aufkleber",
            "NormTermName": "Aufkleber",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04223": {
            "GenartName": "Sitzpolster",
            "NormTermName": "Sitzpolster",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "04224": {
            "GenartName": "Sitzpolster-Satz",
            "NormTermName": "Sitzpolster-Satz",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "04225": {
            "GenartName": "Dachinnenauskleidung",
            "NormTermName": "Dachinnenauskleidung",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "04226": {
            "GenartName": "Mittelkonsole",
            "NormTermName": "Mittelkonsole",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "04227": {
            "GenartName": "Blumenvase, Armaturenbrett",
            "NormTermName": "Blumenvase",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Armaturenbrett"
        },
        "04228": {
            "GenartName": "Handförderpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Handförderpumpe"
        },
        "04229": {
            "GenartName": "Zentralmagnet, Nockenwellenverstellung",
            "NormTermName": "Zentralmagnet",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwellenverstellung"
        },
        "04230": {
            "GenartName": "Zentralventil, Nockenwellenverstellung",
            "NormTermName": "Ventil",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Zentralventil-Nockenwellenverstellung"
        },
        "04231": {
            "GenartName": "Anhängekupplungsträger",
            "NormTermName": "Träger",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Anhängekupplungsträger"
        },
        "04232": {
            "GenartName": "Fahrradhalter, Anhängekupplungsträger",
            "NormTermName": "Fahrradhalter",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Anhängekupplungsträger"
        },
        "04234": {
            "GenartName": "Leitungsadapter, Elektrosatz",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Elektrosatz"
        },
        "04235": {
            "GenartName": "Adapter, Steckdose",
            "NormTermName": "Adapter",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Steckdose"
        },
        "04236": {
            "GenartName": "Halter, Steuergerät-Elektrosatz",
            "NormTermName": "Halter",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Steuergerät"
        },
        "04237": {
            "GenartName": "Manschette, Trag-/Führungsgelenk",
            "NormTermName": "Manschette",
            "AssGrpName": "Radaufhängung, Universal",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "04238": {
            "GenartName": "Manschette, Spurstangenkopf",
            "NormTermName": "Manschette",
            "AssGrpName": "Lenkung, Universal",
            "UsageName": "Spurstangenkopf"
        },
        "04239": {
            "GenartName": "Scharnier, Ausstellfenster",
            "NormTermName": "Scharnier",
            "AssGrpName": "Karosserie",
            "UsageName": "Ausstellfenster"
        },
        "04240": {
            "GenartName": "Schlüsselanhänger",
            "NormTermName": "Schlüsselanhänger",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04241": {
            "GenartName": "Formwerkzeug, Abgasrohr",
            "NormTermName": "Werkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Formwerkzeug-Abgasrohr"
        },
        "04242": {
            "GenartName": "Anzeigeskala, Zündung",
            "NormTermName": "Anzeige",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündeinstellung"
        },
        "04243": {
            "GenartName": "Halter, Fahrpedal",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie",
            "UsageName": "Fahrpedal"
        },
        "04244": {
            "GenartName": "Führung, Verriegelungsknopf",
            "NormTermName": "Führung",
            "AssGrpName": "Schließanlage",
            "UsageName": "Verriegelungsknopf"
        },
        "04245": {
            "GenartName": "Heckklappenemblem",
            "NormTermName": "Emblem",
            "AssGrpName": "Karosserie",
            "UsageName": "Heckklappe"
        },
        "04246": {
            "GenartName": "Emblem, Radzierblende",
            "NormTermName": "Emblem",
            "AssGrpName": "Räder",
            "UsageName": "Radzierblende"
        },
        "04247": {
            "GenartName": "Zentrierschraube, Riemenscheibe-Kurbelwelle",
            "NormTermName": "Schraube",
            "AssGrpName": "Riementrieb",
            "UsageName": "Riemenscheibe-Kurbelwelle"
        },
        "04248": {
            "GenartName": "Unterlegscheibe, Riemenscheibe-Kurbelwelle",
            "NormTermName": "Unterlegscheibe",
            "AssGrpName": "Riementrieb",
            "UsageName": "Riemenscheibe-Kurbelwelle"
        },
        "04249": {
            "GenartName": "Schraube, Zahnrad-Kurbelwelle",
            "NormTermName": "Schraube",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Zahnrad-Kurbelwelle"
        },
        "04252": {
            "GenartName": "Schraubensatz, Riemenscheibe-Kurbelwelle",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Riemenscheibe-Kurbelwelle"
        },
        "04253": {
            "GenartName": "Einspritzventil, Harnstoffeinspritzung",
            "NormTermName": "Ventil",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstoffeinspritzung"
        },
        "04254": {
            "GenartName": "Fördermodul, Harnstoffeinspritzung",
            "NormTermName": "Pumpenmodul",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstoffeinspritzung"
        },
        "04255": {
            "GenartName": "Dosiermodul, Harnstoffeinspritzung",
            "NormTermName": "Dosiermodul",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstoffeinspritzung"
        },
        "04256": {
            "GenartName": "Motorabdeckung",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Karosserie",
            "UsageName": "Motor"
        },
        "04257": {
            "GenartName": "Abdeckkappe, Federträgerachse",
            "NormTermName": "Kappe",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federträgerachse"
        },
        "04258": {
            "GenartName": "Distanzring, Faltenbalg-Lenkung",
            "NormTermName": "Distanzring",
            "AssGrpName": "Lenkung",
            "UsageName": "Faltenbalg-Lenkung"
        },
        "04259": {
            "GenartName": "Verschlussdeckel, Nockenwelle",
            "NormTermName": "Verschluss",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwelle"
        },
        "04260": {
            "GenartName": "Druckstück, Achsschenkelbolzen",
            "NormTermName": "Druckstück",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsschenkelbolzen"
        },
        "04261": {
            "GenartName": "Anschlagpuffer, Motorabdeckung",
            "NormTermName": "Puffer",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorabdeckung"
        },
        "04262": {
            "GenartName": "Verschluss, Zugsattelzapfen",
            "NormTermName": "Verschluss",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Zugsattelzapfen"
        },
        "04263": {
            "GenartName": "Ladekabel, Zigarettenanzünder",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Ladekabel-Zigarettenanzünder"
        },
        "04264": {
            "GenartName": "Dichtung, Hauptscheinwerferkappe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerferkappe"
        },
        "04265": {
            "GenartName": "Reifen",
            "NormTermName": "Reifen",
            "AssGrpName": "Räder, Universal",
            "UsageName": null
        },
        "04266": {
            "GenartName": "Lüfterring",
            "NormTermName": "Lüfterring",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühler"
        },
        "04268": {
            "GenartName": "Aufbewahrungskoffer, Eigendiagnose-Gerät",
            "NormTermName": "Koffer",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Eigendiagnose-Gerät"
        },
        "04269": {
            "GenartName": "Filter, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Filter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "04270": {
            "GenartName": "Schalter, Tankklappenentriegelung",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Tankklappenentriegelung"
        },
        "04271": {
            "GenartName": "Deckel, Ölsieb",
            "NormTermName": "Deckel",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölsieb"
        },
        "04272": {
            "GenartName": "Sensor, Abgasdruck",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Abgasdruck"
        },
        "04273": {
            "GenartName": "Schalter, Standlicht",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Standlicht"
        },
        "04274": {
            "GenartName": "Mutter, Trag-/Führungsgelenk",
            "NormTermName": "Mutter",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "04276": {
            "GenartName": "Ventilschlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ventil"
        },
        "04277": {
            "GenartName": "Lamellenkamm",
            "NormTermName": "Lamellenkamm",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04278": {
            "GenartName": "Lecksuche-Set, Klimaanlage",
            "NormTermName": "Lecksuche-Set",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Klimaanlage"
        },
        "04279": {
            "GenartName": "Schlauchschneider",
            "NormTermName": "Schlauchschneider",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04280": {
            "GenartName": "Dichtringsatz, Klimaanlage",
            "NormTermName": "Dichtringsatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Klimaanlage"
        },
        "04281": {
            "GenartName": "Klinge, Rohrschneider",
            "NormTermName": "Klinge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrschneider"
        },
        "04282": {
            "GenartName": "Einschweißgewinde, Lambdasonde",
            "NormTermName": "Einschweißgewinde",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasrohr"
        },
        "04283": {
            "GenartName": "Schraube, Kipphebelwelle",
            "NormTermName": "Schraube",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Kipphebelwelle"
        },
        "04284": {
            "GenartName": "Reparatursatz, Planetengetriebe-Starter",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Startanlage",
            "UsageName": "Planetengetriebe-Starter"
        },
        "04285": {
            "GenartName": "Reparatursatz, Hohlrad-Starter (Planetengetriebe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Startanlage",
            "UsageName": "Hohlrad-Starter (Planetengetriebe)"
        },
        "04286": {
            "GenartName": "Knickschutz, Wendelschlauch",
            "NormTermName": "Knickschutz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Wendelschlauch"
        },
        "04287": {
            "GenartName": "Zugring, Entwässerungsventil",
            "NormTermName": "Zugring",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Entwässerungsventil"
        },
        "04288": {
            "GenartName": "Reduzierstück, Druckluftleitung",
            "NormTermName": "Reduzierstück",
            "AssGrpName": "Normteile",
            "UsageName": "Druckluftleitung"
        },
        "04292": {
            "GenartName": "Hebel, Bremssattel-Feststellbremse",
            "NormTermName": "Hebel",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel-Feststellbremse"
        },
        "04293": {
            "GenartName": "Diesel Selbstzünder (Starthilfe)",
            "NormTermName": "Diesel Selbstzünder",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Starthilfe"
        },
        "04294": {
            "GenartName": "Steckschlüssel",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04295": {
            "GenartName": "Greifzange, Sicherung",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Sicherung"
        },
        "04296": {
            "GenartName": "EBS-Verbindungskabel",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Elektronisches Bremssystem"
        },
        "04298": {
            "GenartName": "Dichtung, Leerlaufregelventil-Luftversorgung",
            "NormTermName": "Dichtung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Leerlaufregelventil"
        },
        "04299": {
            "GenartName": "Schlauch, Batterieentlüftung",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Startanlage",
            "UsageName": "Starterbatterie"
        },
        "04300": {
            "GenartName": "Kettenschloss, Steuerkette",
            "NormTermName": "Schloß",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkette"
        },
        "04301": {
            "GenartName": "Schaltgetriebe",
            "NormTermName": "Getriebe",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "04303": {
            "GenartName": "ABS-Kontrollleuchte",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "ABS-Kontrollleuchte"
        },
        "04304": {
            "GenartName": "Glühlampe, Fahrtenschreiberbeleuchtung",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Fahrtenschreiber"
        },
        "04305": {
            "GenartName": "Glühlampe, Airbagkontrollleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Instrumente",
            "UsageName": "Airbagkontrollleuchte"
        },
        "04306": {
            "GenartName": "Glühlampe, Zündschlüsselbeleuchtung",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Zündschlüsselbeleuchtung"
        },
        "04308": {
            "GenartName": "Fremdstartpunkt",
            "NormTermName": "Fremdstartpunkt",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "04309": {
            "GenartName": "Klimaanlagenreinigungsset",
            "NormTermName": "Klimaanlagenreinigungsset",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Klimaanlage"
        },
        "04310": {
            "GenartName": "Dichtstoff, Klimaanlage",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Klimaanlage"
        },
        "04311": {
            "GenartName": "Ventilkern, Serviceventil",
            "NormTermName": "Ventilkern",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Serviceventil"
        },
        "04312": {
            "GenartName": "Lichtscheibe, ABS-Kontrollleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Instrumente",
            "UsageName": "ABS-Kontrollleuchte"
        },
        "04313": {
            "GenartName": "Montagesatz, Frontschaden",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Frontschaden"
        },
        "04314": {
            "GenartName": "Schubumluftventil, Lader",
            "NormTermName": "Ventil",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladedruckregelung (Schubumluft)"
        },
        "04315": {
            "GenartName": "Dichtung, Düsenschacht",
            "NormTermName": "Dichtung",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Düsenschacht"
        },
        "04316": {
            "GenartName": "Dichtring, Düsenschacht",
            "NormTermName": "Dichtring",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Düsenschacht"
        },
        "04317": {
            "GenartName": "Dichtring, Kerzenschacht",
            "NormTermName": "Dichtring",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Kerzenschacht"
        },
        "04318": {
            "GenartName": "Wechselrichter",
            "NormTermName": "Wechselrichter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "04319": {
            "GenartName": "NOx-Sensor, Harnstoffeinspritzung",
            "NormTermName": "Sensor",
            "AssGrpName": "Abgasanlage",
            "UsageName": "NOx-Wert (Harnstoffeinspritzung)"
        },
        "04320": {
            "GenartName": "Reifenmontiermaschine",
            "NormTermName": "Reifenmontiermaschine",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04321": {
            "GenartName": "Reparatursatz, Kabelsatz",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kabelsatz"
        },
        "04322": {
            "GenartName": "Lagersitz, Gelenkwellenmittellager",
            "NormTermName": "Lagersitz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwellenmittellager"
        },
        "04323": {
            "GenartName": "Halterung, Gelenkwellenmittellager",
            "NormTermName": "Halterung",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwellenmittellager"
        },
        "04324": {
            "GenartName": "Dichtung, Flansch-Zentrifugalreiniger",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schmierung",
            "UsageName": "Zentrifugalreiniger"
        },
        "04325": {
            "GenartName": "Membran, Kurbelgehäuseentlüftung",
            "NormTermName": "Membran",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "04326": {
            "GenartName": "Welle, Zentrifugalreiniger",
            "NormTermName": "Welle",
            "AssGrpName": "Schmierung",
            "UsageName": "Zentrifugalreiniger"
        },
        "04327": {
            "GenartName": "Einstellschraube, Hauptscheinwerfer",
            "NormTermName": "Schraube",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "04328": {
            "GenartName": "Schraube, Zahnrad-Nockenwelle",
            "NormTermName": "Schraube",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Zahnrad-Nockenwelle"
        },
        "04329": {
            "GenartName": "Abgasrohr, Universal",
            "NormTermName": "Rohr",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": null
        },
        "04330": {
            "GenartName": "Absaugpumpe, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Pumpe",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "04331": {
            "GenartName": "Honbürste, Bremssattelreinigung",
            "NormTermName": "Honbürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04332": {
            "GenartName": "Kupplungsbremse",
            "NormTermName": "Kupplungsbremse",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "04333": {
            "GenartName": "Ventilkolben, Ölkühler",
            "NormTermName": "Ventilkolben",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölkühler"
        },
        "04334": {
            "GenartName": "Halter, Kraftstoffleitung-Einspritzpumpe",
            "NormTermName": "Halter",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kraftstoffleitung-Einspritzpumpe"
        },
        "04335": {
            "GenartName": "Kappe, Schaltgehäuse-Getriebe",
            "NormTermName": "Kappe",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgehäuse-Getriebe"
        },
        "04336": {
            "GenartName": "Manschette, Gelenkwelle",
            "NormTermName": "Manschette",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwelle"
        },
        "04337": {
            "GenartName": "Mitnehmerscheibe, Wasserpumpe",
            "NormTermName": "Mitnehmerscheibe",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "04338": {
            "GenartName": "Kipphebelbrücke",
            "NormTermName": "Kipphebelbrücke",
            "AssGrpName": "Motorsteuerung",
            "UsageName": null
        },
        "04339": {
            "GenartName": "Nachrüstsatz, Katalysator/Rußpartikelfilter (Kombisystem)",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Katalysator/Rußpartikelfilter"
        },
        "04340": {
            "GenartName": "Halteklammer, Druckscheibe-Lagerbock",
            "NormTermName": "Halteklammer",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Druckscheibe-Lagerbock"
        },
        "04341": {
            "GenartName": "Einschweißzapfen, Gelenkwelle",
            "NormTermName": "Einschweißzapfen",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwelle"
        },
        "04342": {
            "GenartName": "Ventil, Schalthebel",
            "NormTermName": "Ventil",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schalthebel"
        },
        "04343": {
            "GenartName": "Schraube, Gehäusedeckel-Ölpumpe",
            "NormTermName": "Schraube",
            "AssGrpName": "Schmierung",
            "UsageName": "Gehäusedeckel-Ölpumpe"
        },
        "04344": {
            "GenartName": "Halteklammer, Öldüse",
            "NormTermName": "Halteklammer",
            "AssGrpName": "Schmierung",
            "UsageName": "Öldüse"
        },
        "04345": {
            "GenartName": "Lagerring, Gelenkwellenmittellager",
            "NormTermName": "Lagerring",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwellenmittellager"
        },
        "04346": {
            "GenartName": "Steuerkolben, Konstantdrossel (Motorbremse)",
            "NormTermName": "Steuerkolben",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Konstantdrossel (Motorbremse)"
        },
        "04347": {
            "GenartName": "Membran, Kolben-Kupplungsbremse",
            "NormTermName": "Membran",
            "AssGrpName": "Kupplung",
            "UsageName": "Kolben-Kupplungsbremse"
        },
        "04348": {
            "GenartName": "Montagewerkzeug, Generatorfreilauf",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Generatorfreilauf"
        },
        "04349": {
            "GenartName": "Lüfter, Motorkühlung",
            "NormTermName": "Lüfter",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Motorkühlung"
        },
        "04350": {
            "GenartName": "Innenraumgebläse",
            "NormTermName": "Gebläse",
            "AssGrpName": "Heizung/Lüftung, Universal",
            "UsageName": "Innenraumluft"
        },
        "04351": {
            "GenartName": "Auffahrschiene, Anhängekupplungsträger",
            "NormTermName": "Auffahrschiene",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsträger"
        },
        "04352": {
            "GenartName": "Transportplattform, Anhängekupplungsträger",
            "NormTermName": "Transportplattform",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsträger"
        },
        "04353": {
            "GenartName": "Aufbewahrungstasche, Anhängekupplungsträger",
            "NormTermName": "Tasche",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsträger"
        },
        "04354": {
            "GenartName": "Wandhalterung, Anhängekupplungsträger",
            "NormTermName": "Halterung",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsträger"
        },
        "04355": {
            "GenartName": "Verlängerungsschiene, Anhängekupplungsträger",
            "NormTermName": "Verlängerungsschiene",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsträger"
        },
        "04356": {
            "GenartName": "Hitzeschutz, Anhängekupplungsträger",
            "NormTermName": "Hitzeschutz",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsträger"
        },
        "04357": {
            "GenartName": "Sortiment, Abgasanlage-Montage",
            "NormTermName": "Sortiment",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Abgasanlage-Montage"
        },
        "04358": {
            "GenartName": "Reparatursatz, Abgasrohr",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Abgasrohr"
        },
        "04359": {
            "GenartName": "Flansch, Abgasrohr",
            "NormTermName": "Flansch",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Abgasrohr"
        },
        "04360": {
            "GenartName": "Verschlussmutter, CO-Entnahmerohr",
            "NormTermName": "Mutter",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "CO-Entnahmerohr"
        },
        "04361": {
            "GenartName": "Entnahmerohr, CO-Messung",
            "NormTermName": "Rohr",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "CO-Messung"
        },
        "04362": {
            "GenartName": "Nachrüstsatz, Gasanlage",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Gasanlage"
        },
        "04363": {
            "GenartName": "Wassersensor, Kraftstoffanlage",
            "NormTermName": "Sensor",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Wassersensor-Kraftstoffanlage"
        },
        "04365": {
            "GenartName": "Kraftstoffvorwärmer",
            "NormTermName": "Kraftstoffvorwärmer",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": null
        },
        "04366": {
            "GenartName": "Befestigungselement, Abdeckung-Außenspiegel",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie",
            "UsageName": "Abdeckung-Außenspiegel"
        },
        "04367": {
            "GenartName": "Trägerplatte, Stellelement-Außenspiegel",
            "NormTermName": "Trägerplatte",
            "AssGrpName": "Karosserie",
            "UsageName": "Stellelement-Außenspiegel"
        },
        "04368": {
            "GenartName": "Stopfen, Abdeckung-Außenspiegelhalter",
            "NormTermName": "Stopfen",
            "AssGrpName": "Karosserie",
            "UsageName": "Abdeckung-Außenspiegelhalter"
        },
        "04369": {
            "GenartName": "Umfeldleuchte, Außenspiegel",
            "NormTermName": "Leuchte",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegel"
        },
        "04370": {
            "GenartName": "Seitenwand",
            "NormTermName": "Seitenwand",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": null
        },
        "04371": {
            "GenartName": "Kugelzapfen, Außenspiegel",
            "NormTermName": "Kugelzapfen",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegel"
        },
        "04372": {
            "GenartName": "Gleitscheibe, Außenspiegel",
            "NormTermName": "Gleitscheibe",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegel"
        },
        "04373": {
            "GenartName": "Zubehörsatz, Bremsscheibe",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsscheibe"
        },
        "04374": {
            "GenartName": "Gabelkopf, Federspeicherzylinder",
            "NormTermName": "Gabelkopf",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Federspeicherzylinder"
        },
        "04375": {
            "GenartName": "Halter, Stellelement-Leuchtweitenregulierung",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Stellelement-Leuchtweitenregulierung"
        },
        "04376": {
            "GenartName": "Antriebswelle, Servopumpe",
            "NormTermName": "Welle",
            "AssGrpName": "Lenkung",
            "UsageName": "Servopumpe"
        },
        "04377": {
            "GenartName": "Abdeckkappe, Schalter-Sitzverstellung",
            "NormTermName": "Kappe",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Schalter-Sitzverstellung"
        },
        "04378": {
            "GenartName": "Abdeckkappe, Schalter-Sitzlehnenverstellung",
            "NormTermName": "Kappe",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Schalter-Sitzlehnenverstellung"
        },
        "04379": {
            "GenartName": "Belaglamelle, Lamellenkupplung (Automatikgetriebe)",
            "NormTermName": "Belaglamelle",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Lamellenkupplung (Automatikgetriebe)"
        },
        "04380": {
            "GenartName": "Abdeckung, Batteriepolklemme",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Batteriepolklemme"
        },
        "04381": {
            "GenartName": "Tankeinheit, Harnstoffeinspritzung",
            "NormTermName": "Tankeinheit",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "04382": {
            "GenartName": "Steuergerät, Harnstoffeinspritzung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstoffeinspritzung"
        },
        "04383": {
            "GenartName": "Kontrollanzeige, Einparkhilfe",
            "NormTermName": "Anzeige",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Einparkhilfe"
        },
        "04384": {
            "GenartName": "Kontrollanzeige, Einparkhilfe",
            "NormTermName": "Anzeige",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Einparkhilfe"
        },
        "04385": {
            "GenartName": "Zubehörsatz",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": null,
            "UsageName": null
        },
        "04386": {
            "GenartName": "Montagesatz, Sensor-Einparkhilfe",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sensor-Einparkhilfe"
        },
        "04387": {
            "GenartName": "Montagesatz, Sensor-Einparkhilfe",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Sensor-Einparkhilfe"
        },
        "04388": {
            "GenartName": "Seilzug, Türentriegelung",
            "NormTermName": "Seilzug",
            "AssGrpName": "Schließanlage",
            "UsageName": "Türentriegelung"
        },
        "04389": {
            "GenartName": "Gestänge, Türentriegelung",
            "NormTermName": "Gestänge",
            "AssGrpName": "Schließanlage",
            "UsageName": "Türentriegelung"
        },
        "04390": {
            "GenartName": "Dichtungssatz, Einspritzpumpe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpe"
        },
        "04391": {
            "GenartName": "Dichtungssatz, Fliehkraftregler-Einspritzpumpe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Fliehkraftregler-Einspritzpumpe"
        },
        "04393": {
            "GenartName": "Filter, Innenraumluft",
            "NormTermName": "Filter",
            "AssGrpName": "Heizung/Lüftung, Universal",
            "UsageName": "Innenraumluft"
        },
        "04394": {
            "GenartName": "Regler, Innenraumgebläse",
            "NormTermName": "Regler",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumgebläse"
        },
        "04395": {
            "GenartName": "Getränkehalter",
            "NormTermName": "Halter",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Getränkehalter"
        },
        "04396": {
            "GenartName": "Wandhalterung, Kabelspule",
            "NormTermName": "Halterung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Kabelspule"
        },
        "04397": {
            "GenartName": "Dichtungssatz, Zentrifugalreiniger",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Zentrifugalreiniger"
        },
        "04398": {
            "GenartName": "Abdeckung, Kippzylinder-Fahrerhaus",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Kippzylinder"
        },
        "04399": {
            "GenartName": "Manschette, Nachsteller-Trommelbremse",
            "NormTermName": "Manschette",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Nachsteller-Trommelbremse"
        },
        "04400": {
            "GenartName": "Reifen",
            "NormTermName": "Reifen",
            "AssGrpName": "Räder",
            "UsageName": null
        },
        "04401": {
            "GenartName": "Ölkühler, Schaltgetriebe",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Hydrauliköl"
        },
        "04402": {
            "GenartName": "Sensor, Harnstoffvorrat",
            "NormTermName": "Sensor",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstoffvorrat"
        },
        "04403": {
            "GenartName": "Abdeckung, Frischluftkanal (Innenraumluft)",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Karosserie",
            "UsageName": "Frischluftkanal (Innenraumluft)"
        },
        "04404": {
            "GenartName": "Vorschalldämpfer, Universal",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Vorschalldämpfer"
        },
        "04405": {
            "GenartName": "Mittelschalldämpfer, Universal",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Mittelschalldämpfer"
        },
        "04406": {
            "GenartName": "Endschalldämpfer, Universal",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Endschalldämpfer"
        },
        "04407": {
            "GenartName": "Montagewerkzeugsatz, Generatorfreilauf",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Generatorfreilauf"
        },
        "04408": {
            "GenartName": "Relais, Kühlmittelwarnanzeige",
            "NormTermName": "Relais",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelwarnanzeige"
        },
        "04409": {
            "GenartName": "Buchse, Kippzylinder-Fahrerhaus",
            "NormTermName": "Buchse",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Kippzylinder"
        },
        "04410": {
            "GenartName": "Halter, Stoßfänger",
            "NormTermName": "Halter",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Stoßfänger-Fahrerhaus"
        },
        "04411": {
            "GenartName": "Ski-/Snowboardhalter, Anhängekupplungsträger",
            "NormTermName": "Ski-/Snowboardhalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsträger"
        },
        "04412": {
            "GenartName": "Distanzhülse, Abgasanlage",
            "NormTermName": "Hülse",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Distanzhülse"
        },
        "04413": {
            "GenartName": "Triggerzange, Eigendiagnosegerät",
            "NormTermName": "Triggerzange",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Eigendiagnose-Gerät"
        },
        "04414": {
            "GenartName": "Messleitung, Multimeter",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Multimeter"
        },
        "04415": {
            "GenartName": "Oszilloskop",
            "NormTermName": "Oszilloskop",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04416": {
            "GenartName": "Messleitung, Oszilloskop",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Oszilloskop"
        },
        "04417": {
            "GenartName": "Elektrosatz, Anhängevorrichtung",
            "NormTermName": "Elektrosatz",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": null
        },
        "04418": {
            "GenartName": "Zündkerzenrohr",
            "NormTermName": "Rohr",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Zündkerze-Zylinderkopf"
        },
        "04419": {
            "GenartName": "Montagesatz, Trag-/Führungsgelenk",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "04420": {
            "GenartName": "Spannungsbegrenzer, Bordnetz",
            "NormTermName": "Spannungsbegrenzer",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Bordnetz"
        },
        "04422": {
            "GenartName": "Interface, Geschwindigkeitsregelanlage",
            "NormTermName": "Schnittstelle",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": "Geschwindigkeitsregelanlage"
        },
        "04423": {
            "GenartName": "Blinkmodul",
            "NormTermName": "Blinkmodul",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": null
        },
        "04424": {
            "GenartName": "Steuergerät, Beleuchtung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": null
        },
        "04425": {
            "GenartName": "Sitzheizung, Universal",
            "NormTermName": "Sitzheizung",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04426": {
            "GenartName": "Steuergerät, Gefahrenbremslicht",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Gefahrenbremslicht"
        },
        "04427": {
            "GenartName": "Sitzkühlung, Universal",
            "NormTermName": "Sitzkühlung",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04428": {
            "GenartName": "Nass-/Trockensauger",
            "NormTermName": "Nass-/Trockensauger",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04429": {
            "GenartName": "Spurwechselwarnleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Karosserie",
            "UsageName": "Spurwechselassistent"
        },
        "04430": {
            "GenartName": "Lager, Ausgleichswelle",
            "NormTermName": "Lager",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Ausgleichswelle"
        },
        "04431": {
            "GenartName": "Aräometer, Frostschutzprüfer",
            "NormTermName": "Aräometer",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Frostschutzprüfer"
        },
        "04432": {
            "GenartName": "Anschlussset, Leuchte",
            "NormTermName": "Anschlussset",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Leuchten"
        },
        "04433": {
            "GenartName": "Netzgerät",
            "NormTermName": "Netzgerät",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "04434": {
            "GenartName": "Netzkabel",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Netzkabel"
        },
        "04435": {
            "GenartName": "Prüfset, Säure/Frostschutz",
            "NormTermName": "Prüfset",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Säure/Frostschutz"
        },
        "04436": {
            "GenartName": "Überspannungsschutzgerät, Batterie",
            "NormTermName": "Überspannungsschutzgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04437": {
            "GenartName": "Flaschendruckminderer",
            "NormTermName": "Druckminderer",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Gasflasche"
        },
        "04438": {
            "GenartName": "Spannungsprüfer",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Spannung"
        },
        "04440": {
            "GenartName": "Ölwannentrenn- und Reinigungsset",
            "NormTermName": "Ölwannentrenn- und Reinigungsset",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04441": {
            "GenartName": "Ölwannenschlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ölwanne"
        },
        "04442": {
            "GenartName": "Ölwannenschlüsselsatz",
            "NormTermName": "Schlüsselsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ölwanne"
        },
        "04443": {
            "GenartName": "Steckschlüsseleinsatz, Motorhalter",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Motorhalter"
        },
        "04444": {
            "GenartName": "Schlüssel, Zahnriemenspannung",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zahnriemenspannung"
        },
        "04445": {
            "GenartName": "Haltewerkzeug, Riemenscheibe",
            "NormTermName": "Haltewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Riemenscheibe"
        },
        "04446": {
            "GenartName": "Montagewerkzeug, Lüfter (Flüssigkeitsreibungskupplung)",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kühlerlüfter"
        },
        "04447": {
            "GenartName": "Auszieher, Gleitschienenbolzen",
            "NormTermName": "Auszieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Gleitschienenbolzen"
        },
        "04448": {
            "GenartName": "Montagewerkzeug, Nockenwellenrad",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Nockenwellenrad"
        },
        "04449": {
            "GenartName": "Hebebühne",
            "NormTermName": "Hebebühne",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04450": {
            "GenartName": "Montagevorrichtung, Frontverkleidung",
            "NormTermName": "Montagevorrichtung",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Frontverkleidung"
        },
        "04451": {
            "GenartName": "Abzieher, Nockenwellenrad",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwellenrad"
        },
        "04452": {
            "GenartName": "Abziehersatz, Nockenwellenrad",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwellenrad"
        },
        "04453": {
            "GenartName": "Arretierwerkzeug, Steuerzeiten",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Steuerzeiten"
        },
        "04454": {
            "GenartName": "Arretierwerkzeugsatz, Steuerzeiten",
            "NormTermName": "Arretierwerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Steuerzeiten"
        },
        "04455": {
            "GenartName": "Arretierdorn, Kurbelwelle",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kurbelwelle"
        },
        "04456": {
            "GenartName": "Montagewerkzeug, Nockenwelle",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwelle"
        },
        "04457": {
            "GenartName": "Arretierwerkzeugsatz, Nockenwelle",
            "NormTermName": "Arretierwerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwelle"
        },
        "04458": {
            "GenartName": "Werkzeugsatz, Steuerzeiten",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Steuerzeiten"
        },
        "04459": {
            "GenartName": "Montagewerkzeugsatz, Wasserpumpe",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Wasserpumpe"
        },
        "04460": {
            "GenartName": "Abzieher, Wasserpumpenzahnrad",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Wasserpumpenzahnrad"
        },
        "04461": {
            "GenartName": "Montagewerkzeug, Wasserpumpe",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Wasserpumpe"
        },
        "04462": {
            "GenartName": "Arretierwerkzeug, Kurbelwelle",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kurbelwelle"
        },
        "04463": {
            "GenartName": "Einstellwerkzeugsatz, Steuerzeiten",
            "NormTermName": "Einstellwerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Steuerzeiten"
        },
        "04464": {
            "GenartName": "Justierlineal, Nockenwelle",
            "NormTermName": "Lineal",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwelle-Justierung"
        },
        "04465": {
            "GenartName": "Arretierwerkzeug, Nockenwelle",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwelle"
        },
        "04466": {
            "GenartName": "Montagewerkzeug, Halter-Abgasanlage",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Halter-Abgasanlage"
        },
        "04467": {
            "GenartName": "Arretierdorn, Nockenwelle",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwelle"
        },
        "04468": {
            "GenartName": "Arretierwerkzeug, Zahnriemenrad-Kurbelwelle",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zahnriemenrad-Kurbelwelle"
        },
        "04469": {
            "GenartName": "Gasfeder, Tür",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Tür"
        },
        "04470": {
            "GenartName": "Lenkgehäuse",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkgetriebe"
        },
        "04471": {
            "GenartName": "Zahnstange, Lenkgetriebe",
            "NormTermName": "Zahnstange",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkgetriebe"
        },
        "04472": {
            "GenartName": "Halter, Messuhr",
            "NormTermName": "Halter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Messuhr"
        },
        "04473": {
            "GenartName": "Arretierwerkzeug, Zahnriemenspannrolle",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zahnriemenspannrolle"
        },
        "04474": {
            "GenartName": "Justierlehre, Nockenwelle",
            "NormTermName": "Lehre",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwelle-Justierung"
        },
        "04475": {
            "GenartName": "Justierdorn, Einspritzpumpe",
            "NormTermName": "Justierdorn",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzpumpe"
        },
        "04476": {
            "GenartName": "Justierdorn, Kurbelwelle",
            "NormTermName": "Justierdorn",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kurbelwelle"
        },
        "04477": {
            "GenartName": "Arretierlehre, Nockenwelle",
            "NormTermName": "Lehre",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwelle-Arretierung"
        },
        "04478": {
            "GenartName": "Arretierlehre, Phasenversteller-Nockenwelle",
            "NormTermName": "Lehre",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Phasenversteller-Arretierung"
        },
        "04479": {
            "GenartName": "Arretierdorn, Zahnriemenspanner",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zahnriemenspanner"
        },
        "04480": {
            "GenartName": "Arretierdorn, Keilrippenriemenspanner",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Keilrippenriemenspanner"
        },
        "04481": {
            "GenartName": "Einstelllineal, Nockenwelle",
            "NormTermName": "Lineal",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwelle-Steuerzeiten"
        },
        "04482": {
            "GenartName": "Schlüssel, Keilriemenspannung",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Keilriemenspannung"
        },
        "04483": {
            "GenartName": "Montagewerkzeugsatz, Einlass-/Auslassventil",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einlass-/Auslassventil"
        },
        "04484": {
            "GenartName": "Zange, Ventilschaftdichtung",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ventilschaftdichtung"
        },
        "04485": {
            "GenartName": "Haltewerkzeug, Kurbelwelle",
            "NormTermName": "Haltewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kurbelwelle"
        },
        "04486": {
            "GenartName": "Arretierwerkzeug, Schwungrad",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schwungrad"
        },
        "04487": {
            "GenartName": "Auszieher, Wellendichtring",
            "NormTermName": "Auszieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Wellendichtring"
        },
        "04488": {
            "GenartName": "Eindrückersatz, Wellendichtring",
            "NormTermName": "Eindrückersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Wellendichtring"
        },
        "04489": {
            "GenartName": "Steckschlüsseleinsatz, Zylinderkopfschraube",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zylinderkopfschraube"
        },
        "04490": {
            "GenartName": "Kolbenringspannband",
            "NormTermName": "Spannband",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kolbenring"
        },
        "04491": {
            "GenartName": "Montagewerkzeugsatz, Generatorfreilauf",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Generatorfreilauf"
        },
        "04492": {
            "GenartName": "Entriegelungswerkzeug, Klimaanlagen-/Kraftstoffleitung",
            "NormTermName": "Entriegelungswerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Klimaanlagen-/Kraftstoffleitung"
        },
        "04493": {
            "GenartName": "Demontagewerkzeug, Zündschloss",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zündschloss"
        },
        "04494": {
            "GenartName": "Demontagewerkzeug, Kombi-Instrument",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kombi-Instrument"
        },
        "04495": {
            "GenartName": "Entriegelungswerkzeug, Airbag",
            "NormTermName": "Entriegelungswerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Airbag"
        },
        "04496": {
            "GenartName": "Gewindebohrer, Glühkerze",
            "NormTermName": "Gewindebohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glühkerze"
        },
        "04497": {
            "GenartName": "Zange, Zündkerzenstecker",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zündkerzenstecker"
        },
        "04498": {
            "GenartName": "Montagewerkzeug, Zündspule",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zündspule"
        },
        "04499": {
            "GenartName": "Zündkerzenschlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zündkerze"
        },
        "04500": {
            "GenartName": "Schlüssel, Turbolader/Partikelfilter",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Turbolader/Partikelfilter"
        },
        "04501": {
            "GenartName": "Gewindeschneidwerkzeugsatz, Lambdasonde",
            "NormTermName": "Gewindeschneidwerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lambdasonde"
        },
        "04502": {
            "GenartName": "Steckschlüsseleinsatz, Lambdasonde",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lambdasonde"
        },
        "04503": {
            "GenartName": "Montagewerkzeugsatz, Tankgeber/Kraftstoffpumpe",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Tankgeber/Kraftstoffpumpe"
        },
        "04504": {
            "GenartName": "Zange, Schlauchschelle",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlauchschelle"
        },
        "04505": {
            "GenartName": "Entriegelungswerkzeugsatz, Kraftstoffleitung",
            "NormTermName": "Entriegelungswerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kraftstoffleitung"
        },
        "04506": {
            "GenartName": "Kraftstofffilterschlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kraftstofffilter"
        },
        "04507": {
            "GenartName": "Montagewerkzeug, Kraftstofffilter",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kraftstofffilter"
        },
        "04508": {
            "GenartName": "Prüfgerät, Kraftstoffsystemdruck",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kraftstoffsystemdruck"
        },
        "04509": {
            "GenartName": "Prüfgerät, Common-Rail-Injektor",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Common-Rail-Injektor"
        },
        "04510": {
            "GenartName": "Einstellwerkzeugsatz, Einspritzpumpe",
            "NormTermName": "Einstellwerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzpumpe"
        },
        "04511": {
            "GenartName": "Arretierdorn, Einspritzpumpe",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzpumpe"
        },
        "04512": {
            "GenartName": "Arretierdorn, Schwungrad",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Schwungrad"
        },
        "04513": {
            "GenartName": "Justierschraube, Einspritzpumpe",
            "NormTermName": "Justierschraube",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpe"
        },
        "04514": {
            "GenartName": "Abzieher, Einspritzpumpenzahnrad",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzpumpenzahnrad"
        },
        "04515": {
            "GenartName": "Abzieher, Pumpe-Düse-Einheit",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Pumpe-Düse-Einheit"
        },
        "04516": {
            "GenartName": "Montagewerkzeugsatz, Pumpe-Düse-Einheit",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Pumpe-Düse-Einheit"
        },
        "04517": {
            "GenartName": "Demontagewerkzeug, Common-Rail-Injektor",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Common-Rail-Injektor"
        },
        "04518": {
            "GenartName": "Steckschlüsseleinsatz, Injektorhalteschraube",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Injektorhalteschraube"
        },
        "04519": {
            "GenartName": "Demontagewerkzeugsatz, Common-Rail-Injektor",
            "NormTermName": "Demontagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Common-Rail-Injektor"
        },
        "04520": {
            "GenartName": "Adapter, Demontagewerkzeug (CR-Injektor)",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Demontagewerkzeug (CR-Injektor)"
        },
        "04521": {
            "GenartName": "Justierwerkzeug, Common-Rail-Injektor",
            "NormTermName": "Justierwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Common-Rail-Injektor"
        },
        "04522": {
            "GenartName": "Steckschlüsseleinsatz, Common-Rail-Injektor",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Common-Rail-Injektor"
        },
        "04523": {
            "GenartName": "Reinigungs-/Fräswerkzeugsatz, CR-Injektorschacht",
            "NormTermName": "Reinigungs-/Fräswerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "CR-Injektorschacht"
        },
        "04524": {
            "GenartName": "Steckschlüsseleinsatz, Einspritzdüse",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzdüse"
        },
        "04525": {
            "GenartName": "Steckschlüsseleinsatz, Vorkammer",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Vorkammer"
        },
        "04526": {
            "GenartName": "Zange, Glühkerzenstecker",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerzenstecker"
        },
        "04527": {
            "GenartName": "Gelenk-Steckschlüsseleinsatz, Glühkerze",
            "NormTermName": "Gelenk-Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerze"
        },
        "04528": {
            "GenartName": "Steckschlüsseleinsatz-Set, Glühkerze",
            "NormTermName": "Steckschlüsseleinsatz-Set",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerze"
        },
        "04529": {
            "GenartName": "Steckschlüsseleinsatz, Glühkerze",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glühkerze"
        },
        "04530": {
            "GenartName": "Abzieher, Glühkerze",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerze"
        },
        "04531": {
            "GenartName": "Lösezange, Glühkerzenkontaktstift",
            "NormTermName": "Lösezange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glühkerzenkontaktstift"
        },
        "04532": {
            "GenartName": "Demontagewerkzeugsatz, Glühkerze",
            "NormTermName": "Demontagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerze"
        },
        "04533": {
            "GenartName": "Demontagewerkzeugsatz, Glühkerze",
            "NormTermName": "Demontagewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glühkerze"
        },
        "04534": {
            "GenartName": "Ausziehersatz, Glühkerzenstift",
            "NormTermName": "Ausziehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glühkerzenstift"
        },
        "04535": {
            "GenartName": "Reibahle, Glühkerze",
            "NormTermName": "Reibahle",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerze"
        },
        "04536": {
            "GenartName": "Gewindeschneidwerkzeugsatz, Glühkerze",
            "NormTermName": "Gewindeschneidwerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glühkerze"
        },
        "04537": {
            "GenartName": "Gewindeschneidwerkzeugsatz, Glühkerze",
            "NormTermName": "Gewindeschneidwerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerze"
        },
        "04538": {
            "GenartName": "Auszieher, Injektordichtring",
            "NormTermName": "Auszieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Injektordichtring"
        },
        "04539": {
            "GenartName": "Zentrierdorn, Kupplung",
            "NormTermName": "Zentrierdorn",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kupplung"
        },
        "04540": {
            "GenartName": "Montagewerkzeugsatz, Kupplungsdruckplatte",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kupplungsdruckplatte"
        },
        "04541": {
            "GenartName": "Steckschlüsseleinsatz, Kupplungsdruckplatte",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kupplungsdruckplatte"
        },
        "04542": {
            "GenartName": "Zentrierwerkzeugsatz, Kupplung",
            "NormTermName": "Zentrierwerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplung"
        },
        "04543": {
            "GenartName": "Steckschlüsseleinsatz, Ölablassschraube",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ölablassschraube"
        },
        "04544": {
            "GenartName": "Steckschlüsseleinsatz, Torsionsstab",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Torsionsstab"
        },
        "04545": {
            "GenartName": "Reparatursatz, Ölablassschraubengewinde",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ölablassschraubengewinde"
        },
        "04546": {
            "GenartName": "Service-Messstab, Ölwechsel",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ölwechsel"
        },
        "04547": {
            "GenartName": "Steckschlüsseleinsatz-Set, Ölablassschraube",
            "NormTermName": "Steckschlüsseleinsatz-Set",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ölablassschraube"
        },
        "04548": {
            "GenartName": "Ölablassschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ölablassschlauch"
        },
        "04549": {
            "GenartName": "Abzieher, Lenkrad-Distanzhülse",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Lenkrad-Distanzhülse"
        },
        "04550": {
            "GenartName": "Abzieher, Kugelgelenk",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kugelgelenk"
        },
        "04551": {
            "GenartName": "Abzieher, Kugelgelenk",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kugelgelenk"
        },
        "04552": {
            "GenartName": "Ausdrücker, Querlenkerklemmschraube",
            "NormTermName": "Ausdrücker",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Querlenkerklemmschraube"
        },
        "04553": {
            "GenartName": "Ausdrücker, Kugelgelenk",
            "NormTermName": "Ausdrücker",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kugelgelenk"
        },
        "04554": {
            "GenartName": "Federspanner, Fahrwerksfeder",
            "NormTermName": "Federspanner",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Fahrwerksfeder"
        },
        "04555": {
            "GenartName": "Aufspreizer, Trag-/Führungsgelenkaufnahme",
            "NormTermName": "Aufspreizer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Trag-/Führungsgelenkaufnahme"
        },
        "04556": {
            "GenartName": "Montagewerkzeugsatz, Silentlager",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Silentlager"
        },
        "04557": {
            "GenartName": "Montagewerkzeugsatz, Trag-/Führungsgelenk",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "04558": {
            "GenartName": "Montagewerkzeugsatz, Stabilisator-Kugelgelenk",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Stabilisator-Kugelgelenk"
        },
        "04559": {
            "GenartName": "Steckschlüsseleinsatz, Traggelenk-Nutmutter",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Traggelenk-Nutmutter"
        },
        "04560": {
            "GenartName": "Montagewerkzeugsatz, Silentlager",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Silentlager"
        },
        "04561": {
            "GenartName": "Abzieher, Antriebswellengelenk",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Antriebswellengelenk"
        },
        "04562": {
            "GenartName": "Zange, Antriebswellengelenk-Sicherung",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Antriebswellengelenk-Sicherung"
        },
        "04563": {
            "GenartName": "Zange, Gelenkmanschetten-Schelle",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gelenkmanschetten-Schelle"
        },
        "04564": {
            "GenartName": "Schlagabzieher",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagabzieher"
        },
        "04565": {
            "GenartName": "Seegerringzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Seegerringzange"
        },
        "04566": {
            "GenartName": "Abzieher, Radnabe",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radnabe"
        },
        "04567": {
            "GenartName": "Montagewerkzeugsatz, Radnabe/Radlager",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radnabe/Radlager"
        },
        "04568": {
            "GenartName": "Steckschlüsseleinsatz, Radnabe/Radlager",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radnabe/Radlager"
        },
        "04569": {
            "GenartName": "Abzieher, Radlagerinnenring",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Radlagerinnenring"
        },
        "04570": {
            "GenartName": "Steckschlüssel, Entlüfterschraube/-ventil",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Entlüfterschraube/-ventil"
        },
        "04571": {
            "GenartName": "Steckschlüsseleinsatz, Bremssattel",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremssattel"
        },
        "04572": {
            "GenartName": "Dreh-/Rückstellwerkzeug, Bremssattelkolben",
            "NormTermName": "Dreh-/Rückstellwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremssattelkolben"
        },
        "04573": {
            "GenartName": "Reparatursatz, Bremssattel-Führungsgewinde",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremssattel-Führungsgewinde"
        },
        "04574": {
            "GenartName": "Montagewerkzeug, Bremsbacken-Haltefeder",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremsbacken-Haltefeder"
        },
        "04575": {
            "GenartName": "Reibahle, ABS-Sensoraufnahme",
            "NormTermName": "Reibahle",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "ABS-Sensoraufnahme"
        },
        "04576": {
            "GenartName": "Montagewerkzeug, Stahlklammer-Abgasanlage",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Stahlklammer-Abgasanlage"
        },
        "04577": {
            "GenartName": "Zange, Schlauchschelle",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Schlauchschelle"
        },
        "04578": {
            "GenartName": "Doppel-Ringschlüssel",
            "NormTermName": "Doppel-Ringschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04579": {
            "GenartName": "Doppel-Ringschlüsselsatz",
            "NormTermName": "Doppel-Ringschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04580": {
            "GenartName": "Abziehersatz",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04581": {
            "GenartName": "Steckschlüsseleinsatz, Radmutter/-Bolzen",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Radmutter/-Bolzen"
        },
        "04582": {
            "GenartName": "Zange, Gelenkmanschetten-Schelle",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Gelenkmanschetten-Schelle"
        },
        "04583": {
            "GenartName": "Arretierdornsatz, Hilfsrahmen/Aggregateträger",
            "NormTermName": "Arretierdornsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Hilfrahmen/Aggregateträger"
        },
        "04584": {
            "GenartName": "Montagewerkzeug, Keilrippenriemen",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Keilrippenriemen"
        },
        "04585": {
            "GenartName": "Reinigungs-/Fräswerkzeugsatz, CR-Injektorschacht",
            "NormTermName": "Reinigungs-/Fräswerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "CR-Injektorschacht"
        },
        "04586": {
            "GenartName": "Steckschlüsseleinsatz, Lambdasonde",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Lambdasonde"
        },
        "04587": {
            "GenartName": "Schlagauszieher, Wellendichtring",
            "NormTermName": "Schlagauszieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Wellendichtring"
        },
        "04588": {
            "GenartName": "Lenkungssatz",
            "NormTermName": "Lenkungssatz",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "04590": {
            "GenartName": "Verbindungskabel, Steckdose",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Steckdose"
        },
        "04593": {
            "GenartName": "Adapter, Wendelschlauch",
            "NormTermName": "Adapter",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Wendelschlauch"
        },
        "04594": {
            "GenartName": "Sprühpistole, Klimaanlagenreiniger/-desinfizierer",
            "NormTermName": "Sprühpistole",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Klimaanlage"
        },
        "04595": {
            "GenartName": "Signalverstärker, Geschwindigkeitsregelanlage",
            "NormTermName": "Signalverstärker",
            "AssGrpName": "Geschwindigkeitsregelanlage, Universal",
            "UsageName": "Geschwindigkeitsregelanlage"
        },
        "04596": {
            "GenartName": "Bewegungssensor, Alarmanlage",
            "NormTermName": "Sensor",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Alarmanlage"
        },
        "04597": {
            "GenartName": "Kontaktschalter, Alarmanlage",
            "NormTermName": "Schalter",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Alarmanlage"
        },
        "04598": {
            "GenartName": "Gaswarngerät, Alarmanlage",
            "NormTermName": "Warngerät",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Alarmanlage"
        },
        "04599": {
            "GenartName": "Verlängerungskabel, Sensor-Einparkhilfe",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Einparkhilfe"
        },
        "04600": {
            "GenartName": "Stecker, Hauptscheinwerfer",
            "NormTermName": "Stecker",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "04601": {
            "GenartName": "Stecker, Nebelscheinwerfer",
            "NormTermName": "Stecker",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelscheinwerfer"
        },
        "04602": {
            "GenartName": "Einsatz, Stecker",
            "NormTermName": "Einsatz",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Stecker"
        },
        "04603": {
            "GenartName": "Einsatz, Steckdose",
            "NormTermName": "Einsatz",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Steckdose"
        },
        "04605": {
            "GenartName": "Tellerscheibe",
            "NormTermName": "Tellerscheibe",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "04606": {
            "GenartName": "Blindniet",
            "NormTermName": "Niet",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "04607": {
            "GenartName": "Karabinerhaken",
            "NormTermName": "Haken",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04609": {
            "GenartName": "Set, Schraubendreher-Einsatz (Bits)",
            "NormTermName": "Set",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schraubendreher"
        },
        "04610": {
            "GenartName": "Halteclipsatz, Innenraumverkleidung",
            "NormTermName": "Halteclipsatz",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": "Innenraumverkleidung"
        },
        "04611": {
            "GenartName": "Halteclipsatz, Karosserie",
            "NormTermName": "Halteclipsatz",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Karosserie"
        },
        "04612": {
            "GenartName": "Sortiment, Klemmschelle",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Klemmschelle"
        },
        "04613": {
            "GenartName": "Gewindeeinsatz",
            "NormTermName": "Gewindeeinsatz",
            "AssGrpName": "Normteile",
            "UsageName": "Gewinde"
        },
        "04614": {
            "GenartName": "Werkzeugsortiment, Gewindeeinsatz",
            "NormTermName": "Sortiment",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gewindeeinsatz"
        },
        "04615": {
            "GenartName": "Sortiment, Wärmeschrumpfschlauch",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Wärmeschrumpfschlauch"
        },
        "04616": {
            "GenartName": "Sortiment, Crimpverbinder",
            "NormTermName": "Sortiment",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Crimpverbindung"
        },
        "04617": {
            "GenartName": "Sortiment, Steckverbinder",
            "NormTermName": "Sortiment",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Steckverbinder"
        },
        "04618": {
            "GenartName": "Bohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bohrmaschine"
        },
        "04619": {
            "GenartName": "Schäkel",
            "NormTermName": "Schäkel",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04620": {
            "GenartName": "Haken",
            "NormTermName": "Haken",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Haken"
        },
        "04621": {
            "GenartName": "Montagesatz, Federbeinstützlager",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Federbeinstützlager"
        },
        "04622": {
            "GenartName": "Montagewerkzeugsatz, Radnabe/Radlager",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Radnabe/Radlager"
        },
        "04623": {
            "GenartName": "Zubehörsatz, Bremsbelagsatz-Scheibenfeststellbremse",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbelagsatz-Scheibenfeststellbremse"
        },
        "04624": {
            "GenartName": "Motor-/Unterfahrschutz",
            "NormTermName": "Motor-/Unterfahrschutz",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "04625": {
            "GenartName": "Gelenkwellen-Ausdrücker/Radnaben-Abziehflansch",
            "NormTermName": "Ausdrücker/Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gelenkwelle/Radnabe"
        },
        "04626": {
            "GenartName": "Reinigungswerkzeug, Bremsleitung",
            "NormTermName": "Reinigungswerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsleitung"
        },
        "04627": {
            "GenartName": "Lösewerkzeugsatz, Felgenschloss",
            "NormTermName": "Lösewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Felgenschloss"
        },
        "04628": {
            "GenartName": "Prüfset, Kühlsystemdruck",
            "NormTermName": "Prüfset",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Kühlsystemdruck"
        },
        "04629": {
            "GenartName": "Vakuum-Befüllgerät, Kühlsystem",
            "NormTermName": "Befüllgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Kühlsystem"
        },
        "04630": {
            "GenartName": "Prüfgerät, Zylinderkopf-Dichtigkeit",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Zylinderkopf-Dichtigkeit"
        },
        "04631": {
            "GenartName": "Meißel",
            "NormTermName": "Meißel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04632": {
            "GenartName": "Regler, Leuchtweitenregulierung",
            "NormTermName": "Regler",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Leuchtweitenregulierung"
        },
        "04633": {
            "GenartName": "Abgasrückführung",
            "NormTermName": "Grafik",
            "AssGrpName": "Abgasrückführung",
            "UsageName": null
        },
        "04634": {
            "GenartName": "Dichtungssatz, AGR-System",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "Abgasrückführung"
        },
        "04635": {
            "GenartName": "Thermostat, Kaltstartanreicherung",
            "NormTermName": "Thermostat",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Warmlaufphase"
        },
        "04636": {
            "GenartName": "Verbindungskabel, Starterbatterie",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Startanlage, Universal",
            "UsageName": "Starterbatterie"
        },
        "04637": {
            "GenartName": "Relais, Steuergerät-Motormanagement",
            "NormTermName": "Relais",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Motormanagement"
        },
        "04638": {
            "GenartName": "Prüfspitze, Oszilloskop",
            "NormTermName": "Prüfspitze",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Oszilloskop"
        },
        "04639": {
            "GenartName": "Prüfspitze, Multimeter",
            "NormTermName": "Prüfspitze",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Multimeter"
        },
        "04640": {
            "GenartName": "Triggerzange, Oszilloskop",
            "NormTermName": "Triggerzange",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Oszilloskop"
        },
        "04641": {
            "GenartName": "Messzange, Oszilloskop",
            "NormTermName": "Messzange",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Oszilloskop"
        },
        "04642": {
            "GenartName": "Massekabel, Oszilloskop",
            "NormTermName": "Massekabel",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Oszilloskop"
        },
        "04643": {
            "GenartName": "Anschlussadapter, Oszilloskop",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Oszilloskop"
        },
        "04644": {
            "GenartName": "Montagesatz, Spann-/Umlenkrolle (Zahnriemen)",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Spann-/Umlenkrolle (Zahnriemen)"
        },
        "04645": {
            "GenartName": "Montagesatz, Spann-/Umlenkrolle (Keilrippenriemen)",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Spann-/Umlenkrolle (Keilrippenriemen)"
        },
        "04646": {
            "GenartName": "Lösezange, Türgriff-/Fensterkurbelsicherung",
            "NormTermName": "Lösezange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Türgriff-/Fensterkurbelsicherung"
        },
        "04647": {
            "GenartName": "Lösewerkzeugsatz, Türverkleidung",
            "NormTermName": "Lösewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Türverkleidung"
        },
        "04648": {
            "GenartName": "Montagekeil",
            "NormTermName": "Montagekeil",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04649": {
            "GenartName": "Demontagewerkzeug, Halteclip-Karosserie",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Halteclip-Karosserie"
        },
        "04650": {
            "GenartName": "Montagekeilsatz",
            "NormTermName": "Montagekeilsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04651": {
            "GenartName": "Steckschlüssel, Außenspiegel",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Außenspiegel"
        },
        "04652": {
            "GenartName": "Montagewerkzeug, Kotflügel",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kotflügel"
        },
        "04653": {
            "GenartName": "Bohrlehrensatz, Stehbolzen",
            "NormTermName": "Lehrensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bohrlehrensatz-Stehbolzen"
        },
        "04654": {
            "GenartName": "Dämpferlagerung, Fahrerhaus",
            "NormTermName": "Lagerung",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Dämpfer-Fahrerhauslagerung"
        },
        "04655": {
            "GenartName": "Anschlussleitung, Leuchte",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Leuchten"
        },
        "04656": {
            "GenartName": "Füll-/Entnahmepumpe, Universal",
            "NormTermName": "Pumpe",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Füll-/Entnahmepumpe"
        },
        "04657": {
            "GenartName": "Montagewerkzeug, Türschloss/Schließzylindergehäuse",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Türschloss/Schließzylindergehäuse"
        },
        "04658": {
            "GenartName": "Montagehebel, Reifen",
            "NormTermName": "Hebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Montage Reifen"
        },
        "04659": {
            "GenartName": "Entriegelungswerkzeug, Flach-/Rundstecker",
            "NormTermName": "Entriegelungswerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Flach-/Rundstecker"
        },
        "04660": {
            "GenartName": "Zange, Schutzkappe (Radschraube-/mutter)",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schutzkappe (Radschraube-/mutter)"
        },
        "04661": {
            "GenartName": "Arretierdornsatz, Keilrippenriemen-/Kettenspanner",
            "NormTermName": "Arretierdornsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Keilrippenriemen-/Kettenspanner"
        },
        "04662": {
            "GenartName": "Stößel, Hochdruckpumpe",
            "NormTermName": "Stößel",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Hochdruckpumpe"
        },
        "04663": {
            "GenartName": "Kältemittelfilter, Klimaanlage",
            "NormTermName": "Filter",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Kältemittel-Klimaanlage"
        },
        "04664": {
            "GenartName": "Abdeckkappe, Serviceventil",
            "NormTermName": "Kappe",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Serviceventil"
        },
        "04665": {
            "GenartName": "Thermostat, Kühlmittel",
            "NormTermName": "Grafik",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittel"
        },
        "04666": {
            "GenartName": "Schalter, Verdeck",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Verdeck"
        },
        "04667": {
            "GenartName": "Reparaturrohr, Mittelschalldämpfer",
            "NormTermName": "Rohr",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Mittelschalldämpfer"
        },
        "04668": {
            "GenartName": "Reparatursatz, Kolben/Zylinderlaufbuchse",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kolben/Zylinderlaufbuchse"
        },
        "04669": {
            "GenartName": "Reparatursatz, Abgasrohr",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasrohr"
        },
        "04670": {
            "GenartName": "Schutzkappe, Generatoranschluss",
            "NormTermName": "Kappe",
            "AssGrpName": "Generator, Universal",
            "UsageName": "Generatoranschluss"
        },
        "04671": {
            "GenartName": "Wandhalterung, Kraftstoffleitung",
            "NormTermName": "Halterung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Kraftstoffleitung"
        },
        "04672": {
            "GenartName": "Drucker, Prüfgerät-Bremsflüssigkeit",
            "NormTermName": "Drucker",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Bremsflüssigkeit"
        },
        "04673": {
            "GenartName": "Papierrolle, Prüfgerät-Bremsflüssigkeit",
            "NormTermName": "Rolle",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Bremsflüssigkeit"
        },
        "04674": {
            "GenartName": "Druckerhalter, Prüfgerät-Bremsflüssigkeit",
            "NormTermName": "Halter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Drucker, Prüfgerät-Bremsflüssigkeit"
        },
        "04675": {
            "GenartName": "Reparatursatz, Anschlusskabel (Prüfgerät-Bremsflüssigkeit)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Bremsflüssigkeit"
        },
        "04676": {
            "GenartName": "Anschlussleitung, Seitenmarkierungsleuchte",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "04677": {
            "GenartName": "Anschlussset, Seitenmarkierungsleuchte",
            "NormTermName": "Anschlussset",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "04678": {
            "GenartName": "Fußraumschale",
            "NormTermName": "Schale",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Fußraum"
        },
        "04679": {
            "GenartName": "Fußraumschale",
            "NormTermName": "Schale",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": "Fußraum"
        },
        "04680": {
            "GenartName": "Wischblatt, Universal",
            "NormTermName": "Wischblatt",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": null
        },
        "04681": {
            "GenartName": "Ölkühler, Retarder",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "04682": {
            "GenartName": "Teilesatz, Inspektion",
            "NormTermName": "Teilesatz",
            "AssGrpName": "Service/Wartung",
            "UsageName": "Inspektion"
        },
        "04683": {
            "GenartName": "Lautsprecher",
            "NormTermName": "Lautsprecher",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": null
        },
        "04684": {
            "GenartName": "Lautsprecher",
            "NormTermName": "Lautsprecher",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "04685": {
            "GenartName": "Leuchtstoffröhre, Handleuchte",
            "NormTermName": "Leuchtstoffröhre",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Handleuchte"
        },
        "04686": {
            "GenartName": "Mitnehmerscheibe, Magnetkupplung-Kompressor",
            "NormTermName": "Mitnehmerscheibe",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Magnetkupplung-Kompressor"
        },
        "04687": {
            "GenartName": "Abdeckung, Ausrücklagerwelle-Kupplung",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Kupplung",
            "UsageName": "Ausrücklagerwelle"
        },
        "04688": {
            "GenartName": "Ausrücklagerwelle, Kupplung",
            "NormTermName": "Welle",
            "AssGrpName": "Kupplung",
            "UsageName": "Ausrücklager"
        },
        "04689": {
            "GenartName": "Feder, Kupplungshebel",
            "NormTermName": "Feder",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsbetätigung"
        },
        "04690": {
            "GenartName": "Sensor, Kupplungsverstärker",
            "NormTermName": "Sensor",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Kupplungsverstärker"
        },
        "04691": {
            "GenartName": "Ring-/Gabelschlüssel",
            "NormTermName": "Ring-/Gabelschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04692": {
            "GenartName": "Rollgabelschlüssel",
            "NormTermName": "Rollgabelschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04693": {
            "GenartName": "Stabmagnet",
            "NormTermName": "Stabmagnet",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04694": {
            "GenartName": "Schraubendreher",
            "NormTermName": "Schraubendreher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04695": {
            "GenartName": "Schraubendrehersatz",
            "NormTermName": "Schraubendrehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04696": {
            "GenartName": "Seitenschneider",
            "NormTermName": "Seitenschneider",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04697": {
            "GenartName": "Universalzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Universal"
        },
        "04698": {
            "GenartName": "Flachrundzange",
            "NormTermName": "Flachrundzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04699": {
            "GenartName": "Feststellzange",
            "NormTermName": "Feststellzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04700": {
            "GenartName": "Kombinationszange",
            "NormTermName": "Kombinationszange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04701": {
            "GenartName": "Schraubstock",
            "NormTermName": "Schraubstock",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04702": {
            "GenartName": "Schraubzwinge",
            "NormTermName": "Schraubzwinge",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04703": {
            "GenartName": "Messschieber",
            "NormTermName": "Messschieber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04704": {
            "GenartName": "Feile",
            "NormTermName": "Feile",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04705": {
            "GenartName": "Hammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04706": {
            "GenartName": "Körner",
            "NormTermName": "Körner",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04707": {
            "GenartName": "Hochdruckreiniger",
            "NormTermName": "Hochdruckreiniger",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04708": {
            "GenartName": "Blindnietzange",
            "NormTermName": "Blindnietzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04709": {
            "GenartName": "Druckluftreifenprüfer/-füller",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Druckluft"
        },
        "04711": {
            "GenartName": "Schleifpapier",
            "NormTermName": "Schleifpapier",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04712": {
            "GenartName": "Cutter",
            "NormTermName": "Cutter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04713": {
            "GenartName": "Bohrkrone",
            "NormTermName": "Bohrkrone",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bohrmaschine"
        },
        "04714": {
            "GenartName": "Säge",
            "NormTermName": "Säge",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04715": {
            "GenartName": "Akkuschrauber",
            "NormTermName": "Akkuschrauber",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Akku-Betrieb"
        },
        "04717": {
            "GenartName": "Bohrfutter",
            "NormTermName": "Bohrfutter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bohrmaschine"
        },
        "04718": {
            "GenartName": "Trennscheibe, Winkelschleifer",
            "NormTermName": "Trennscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "04719": {
            "GenartName": "Schleifscheibe, Winkelschleifer",
            "NormTermName": "Schleifscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "04720": {
            "GenartName": "Trichter",
            "NormTermName": "Trichter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Trichter"
        },
        "04721": {
            "GenartName": "Ölfilterband",
            "NormTermName": "Ölfilterband",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ölfilter"
        },
        "04722": {
            "GenartName": "Zündkerzenschlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zündkerzenschlüssel"
        },
        "04723": {
            "GenartName": "Kühlschlauchklemme",
            "NormTermName": "Klemmwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kühlschlauch"
        },
        "04725": {
            "GenartName": "Sägeblatt",
            "NormTermName": "Sägeblatt",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04726": {
            "GenartName": "Gehäuse, Seitenmarkierungsleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "04727": {
            "GenartName": "Gehäuse, Seitenmarkierungsleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Seitenmarkierungsleuchte-Universal"
        },
        "04728": {
            "GenartName": "Warnmarkierung",
            "NormTermName": "Warnmarkierung",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": null
        },
        "04729": {
            "GenartName": "Warnmarkierungssatz",
            "NormTermName": "Warnmarkierungssatz",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": null
        },
        "04730": {
            "GenartName": "Zusatzblinkleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Signalanlage",
            "UsageName": "Zusatzblinkleuchte"
        },
        "04731": {
            "GenartName": "Koffer-/Laderaumschale",
            "NormTermName": "Schale",
            "AssGrpName": "Zubehör",
            "UsageName": "Koffer-/Laderaum"
        },
        "04732": {
            "GenartName": "Universal-Koffer-/Laderaumschale",
            "NormTermName": "Schale",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Koffer-/Laderaum"
        },
        "04733": {
            "GenartName": "Öl",
            "NormTermName": "Öl",
            "AssGrpName": null,
            "UsageName": null
        },
        "04734": {
            "GenartName": "Schraube, Ölwanne",
            "NormTermName": "Schraube",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölwanne"
        },
        "04735": {
            "GenartName": "Montagesatz, Federbein",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbein"
        },
        "04736": {
            "GenartName": "Seilzug, Kaltstartsteuerung",
            "NormTermName": "Seilzug",
            "AssGrpName": "Vergaser",
            "UsageName": "Kaltstartsteuerung"
        },
        "04737": {
            "GenartName": "Auswuchtgewicht",
            "NormTermName": "Auswuchtgewicht",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Rad"
        },
        "04738": {
            "GenartName": "Druckrolle, Ausrückgabel",
            "NormTermName": "Rolle",
            "AssGrpName": "Kupplung",
            "UsageName": "Ausrückgabel"
        },
        "04739": {
            "GenartName": "Spannhülse, Ausrückgabel",
            "NormTermName": "Hülse",
            "AssGrpName": "Kupplung",
            "UsageName": "Ausrückgabel"
        },
        "04740": {
            "GenartName": "Schraube, Kupplungshebel",
            "NormTermName": "Schraube",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungshebel"
        },
        "04741": {
            "GenartName": "Schraube, Ausrücklagerwelle",
            "NormTermName": "Schraube",
            "AssGrpName": "Kupplung",
            "UsageName": "Ausrücklagerwelle"
        },
        "04742": {
            "GenartName": "Diagnosegerät, Klimakompressor",
            "NormTermName": "Diagnosegerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Klimakompressor"
        },
        "04743": {
            "GenartName": "Steckgehäuse, Automatikgetriebe-Steuereinheit",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe-Steuereinheit"
        },
        "04744": {
            "GenartName": "Kraft-Stecknuss",
            "NormTermName": "Steckeinsatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Schlagschrauber"
        },
        "04748": {
            "GenartName": "Schneidzange",
            "NormTermName": "Schneidzange",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04751": {
            "GenartName": "Schleifhülse, Stabschleifer",
            "NormTermName": "Schleifhülse",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Stabschleifer"
        },
        "04752": {
            "GenartName": "Schleifscheibe, Stabschleifer",
            "NormTermName": "Schleifscheibe",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Stabschleifer"
        },
        "04753": {
            "GenartName": "Meißel, Meißelhammer",
            "NormTermName": "Meißel",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Meißelhammer"
        },
        "04754": {
            "GenartName": "Schleifstift, Stabschleifer",
            "NormTermName": "Schleifstift",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Stabschleifer"
        },
        "04755": {
            "GenartName": "Stützteller, Stabschleifer",
            "NormTermName": "Stützteller",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Stabschleifer"
        },
        "04756": {
            "GenartName": "Werkzeugwagen",
            "NormTermName": "Werkzeugwagen",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Werkzeug"
        },
        "04757": {
            "GenartName": "Werkzeugkoffer",
            "NormTermName": "Koffer",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Werkzeug"
        },
        "04758": {
            "GenartName": "Sandstrahlpistole",
            "NormTermName": "Sandstrahlpistole",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": null
        },
        "04759": {
            "GenartName": "Verbinder, Druckluftleitung",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Druckluftleitung"
        },
        "04760": {
            "GenartName": "Schutzgasschweißgerät",
            "NormTermName": "Schweißgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Schutzgasschweißgerät"
        },
        "04761": {
            "GenartName": "Druckluftkompressor",
            "NormTermName": "Kompressor",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Druckluftkompressor"
        },
        "04763": {
            "GenartName": "Lackierpistole",
            "NormTermName": "Lackierpistole",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": null
        },
        "04764": {
            "GenartName": "Kabelspule",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Kabelspule"
        },
        "04765": {
            "GenartName": "Universalkanne",
            "NormTermName": "Behälter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Universalkanne"
        },
        "04766": {
            "GenartName": "Druckstücksatz, Ein-/Auspresswerkzeug",
            "NormTermName": "Druckstücksatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Ein-/Auspresswerkzeug"
        },
        "04768": {
            "GenartName": "Riemenscheibensatz, Kurbelwelle",
            "NormTermName": "Riemenscheibensatz",
            "AssGrpName": "Riementrieb",
            "UsageName": "Kurbelwelle"
        },
        "04770": {
            "GenartName": "Lamellenschleifer",
            "NormTermName": "Lamellenschleifer",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04771": {
            "GenartName": "Anschlussschlauch, Bremsdruckprüfgerät",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Bremsdruckprüfgerät"
        },
        "04772": {
            "GenartName": "Ausdrehwerkzeug, Gewindeeinsatz",
            "NormTermName": "Ausdrehwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gewindeeinsatz"
        },
        "04773": {
            "GenartName": "Sitzschonbezug",
            "NormTermName": "Sitzschonbezug",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04774": {
            "GenartName": "Luftpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Luftpumpe"
        },
        "04775": {
            "GenartName": "Dichtung, Kraftstoffbehälterverschluss",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Kraftstoffbehälterverschluss"
        },
        "04776": {
            "GenartName": "Zierblende, Räder",
            "NormTermName": "Blende",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Radabdeckung"
        },
        "04777": {
            "GenartName": "Verriegelungsknopf",
            "NormTermName": "Knopf",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Verriegelungsknopf"
        },
        "04778": {
            "GenartName": "Fensterkurbel",
            "NormTermName": "Kurbel",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": null
        },
        "04779": {
            "GenartName": "Achsgetriebeöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Achsgetriebe"
        },
        "04780": {
            "GenartName": "Eiskratzer",
            "NormTermName": "Eiskratzer",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04781": {
            "GenartName": "Batteriesäure",
            "NormTermName": "Batteriesäure",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "04782": {
            "GenartName": "Adapter, Sicherung",
            "NormTermName": "Adapter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Sicherung"
        },
        "04783": {
            "GenartName": "Reservekanister",
            "NormTermName": "Behälter",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Reservekanister"
        },
        "04784": {
            "GenartName": "Kontaktspray",
            "NormTermName": "Kontaktspray",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "04785": {
            "GenartName": "Destilliertes Wasser",
            "NormTermName": "Destilliertes Wasser",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "04786": {
            "GenartName": "Feuerlöscher",
            "NormTermName": "Feuerlöscher",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04787": {
            "GenartName": "Schlauch, Reifen",
            "NormTermName": "Schlauch",
            "AssGrpName": "Räder, Universal",
            "UsageName": null
        },
        "04788": {
            "GenartName": "Zier-/Schutzleiste, Dach",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": null
        },
        "04789": {
            "GenartName": "Schwamm",
            "NormTermName": "Schwamm",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04790": {
            "GenartName": "Sensor, Batteriemanagement",
            "NormTermName": "Sensor",
            "AssGrpName": "Bordnetz",
            "UsageName": "Batteriemanagement"
        },
        "04791": {
            "GenartName": "Lenkradbezug",
            "NormTermName": "Lenkradbezug",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04792": {
            "GenartName": "Werkzeugtasche",
            "NormTermName": "Tasche",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Werkzeug"
        },
        "04793": {
            "GenartName": "Schutzhandschuh",
            "NormTermName": "Schutzhandschuh",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Arbeitskleidung"
        },
        "04794": {
            "GenartName": "Maßband",
            "NormTermName": "Maßband",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04795": {
            "GenartName": "Kindersitz",
            "NormTermName": "Kindersitz",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04796": {
            "GenartName": "Abdeckung, Reifenventil",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Reifenventil"
        },
        "04797": {
            "GenartName": "Lenkhilfe (Lenkradknopf/-gabel)",
            "NormTermName": "Lenkhilfe",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Lenkhilfe (Lenkradknopf/-gabel)"
        },
        "04798": {
            "GenartName": "Lack-Polier-Set",
            "NormTermName": "Lack-Polier-Set",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "04799": {
            "GenartName": "Aufsatz-Set, Gaslötbrenner",
            "NormTermName": "Aufsatz-Set",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gaslötbrenner"
        },
        "04800": {
            "GenartName": "Parkwarntafel",
            "NormTermName": "Warnmarkierung",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Parkwarntafel"
        },
        "04801": {
            "GenartName": "Relaissockel",
            "NormTermName": "Relaissockel",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Relais"
        },
        "04802": {
            "GenartName": "Pinzette",
            "NormTermName": "Pinzette",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "04803": {
            "GenartName": "Spanngurt",
            "NormTermName": "Gurt",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Spanngurt"
        },
        "04804": {
            "GenartName": "Hals-/Nackenkissen",
            "NormTermName": "Hals-/Nackenkissen",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "04805": {
            "GenartName": "Schalthebelverkleidung",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Schalthebel"
        },
        "04806": {
            "GenartName": "Abdeckung, Abschlepphaken",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Abschlepphaken"
        },
        "04807": {
            "GenartName": "Reparaturrohr, Ruß-/Partikelfilter",
            "NormTermName": "Rohr",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Ruß-/Partikelfilter"
        },
        "04808": {
            "GenartName": "Druckwandler, Saugrohr",
            "NormTermName": "Druckwandler",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Saugrohr"
        },
        "04809": {
            "GenartName": "Reparatursatz, Kabelsatz",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Kabelsatz"
        },
        "04810": {
            "GenartName": "Reparatursatz, Kabelsatz",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Kabelsatz"
        },
        "04811": {
            "GenartName": "Hydrauliköl, Verdecksystem",
            "NormTermName": "Öl",
            "AssGrpName": "Karosserie",
            "UsageName": "Verdecksystem"
        },
        "04812": {
            "GenartName": "Öl, Nebenantrieb",
            "NormTermName": "Öl",
            "AssGrpName": "Nebenantrieb",
            "UsageName": null
        },
        "04813": {
            "GenartName": "Sensor, Exzenterwelle (variabler Ventilhub)",
            "NormTermName": "Sensor",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Exzenterwelle (variabler Ventilhub)"
        },
        "04814": {
            "GenartName": "Head Up Display",
            "NormTermName": "Head Up Display",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": null
        },
        "04815": {
            "GenartName": "Fahrlichtassistenzsystem",
            "NormTermName": "Fahrlichtassistenzsystem",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": null
        },
        "04816": {
            "GenartName": "Spurwarnsystem",
            "NormTermName": "Spurwarnsystem",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": null
        },
        "04817": {
            "GenartName": "Heckscheibenrahmen",
            "NormTermName": "Rahmen",
            "AssGrpName": "Karosserie",
            "UsageName": "Heckscheibenrahmen"
        },
        "04818": {
            "GenartName": "Frontscheibenrahmensatz",
            "NormTermName": "Rahmensatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Frontscheibenrahmen"
        },
        "04819": {
            "GenartName": "Heckscheibenrahmensatz",
            "NormTermName": "Rahmensatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Heckscheibenrahmen"
        },
        "04820": {
            "GenartName": "Halteclipsatz, Scheibeneinbau",
            "NormTermName": "Halteclipsatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Scheibeneinbau"
        },
        "04821": {
            "GenartName": "Befestigungssatz, Scheibeneinbau",
            "NormTermName": "Haltersatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Scheibeneinbau"
        },
        "04822": {
            "GenartName": "Arretierdorn, Ausgleichswelle",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ausgleichswelle"
        },
        "04823": {
            "GenartName": "Abzieher, Riemenscheibe",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Riemenscheibe"
        },
        "04824": {
            "GenartName": "Gewindebohrer, Lambdasonde",
            "NormTermName": "Gewindebohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lambdasonde"
        },
        "04825": {
            "GenartName": "Lagerbock, Ausrückgabel",
            "NormTermName": "Lagerbock",
            "AssGrpName": "Kupplung",
            "UsageName": "Ausrückgabel"
        },
        "04826": {
            "GenartName": "Wickelfeder, Airbag",
            "NormTermName": "Wickelfeder",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Airbag"
        },
        "04827": {
            "GenartName": "Buchse, Lenkhebel",
            "NormTermName": "Buchse",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkhebel"
        },
        "04828": {
            "GenartName": "Öldüse, Steuerkette",
            "NormTermName": "Düse",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkettenschmierung"
        },
        "04829": {
            "GenartName": "Magnetventil, Retarder",
            "NormTermName": "Ventil",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "04830": {
            "GenartName": "Montagesatz, Ventil-Fahrerhauslagerung",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Ventil-Fahrerhauslagerung"
        },
        "04831": {
            "GenartName": "Lagerung, Differential",
            "NormTermName": "Lagerung",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "04832": {
            "GenartName": "Nabe, Lüfterrad-Motorkühlung",
            "NormTermName": "Nabe",
            "AssGrpName": "Kühlung",
            "UsageName": "Lüfterrad-Motorkühlung"
        },
        "04833": {
            "GenartName": "Drehmomentschlüssel",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Anzugsdrehmoment"
        },
        "04834": {
            "GenartName": "Ausdrücker, Querlenkerlagerbuchse",
            "NormTermName": "Ausdrücker",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Querlenker"
        },
        "04835": {
            "GenartName": "Nabe, Zahnrad-Nockenwelle",
            "NormTermName": "Nabe",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Zahnrad-Nockenwelle"
        },
        "04836": {
            "GenartName": "Adapter, Druckschalter",
            "NormTermName": "Adapter",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckschalter"
        },
        "04837": {
            "GenartName": "Ölschlauch, Ausrücklager",
            "NormTermName": "Schlauch",
            "AssGrpName": "Kupplung",
            "UsageName": "Ausrücklagerschmierung"
        },
        "04838": {
            "GenartName": "Reparatursatz, Gelenkwellenmittellager",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwellenmittellager"
        },
        "04839": {
            "GenartName": "Schraube, Lagerung-Achskörper",
            "NormTermName": "Schraube",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Lagerung, Achskörper"
        },
        "04840": {
            "GenartName": "Schraube, Querlenker",
            "NormTermName": "Schraube",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Querlenker"
        },
        "04841": {
            "GenartName": "Führungshülse, Lagerung-Achskörper",
            "NormTermName": "Hülse",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achskörper"
        },
        "04842": {
            "GenartName": "Lagersatz, Stabilisator",
            "NormTermName": "Lagersatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisator"
        },
        "04843": {
            "GenartName": "Lagersatz, Achskörper",
            "NormTermName": "Lagersatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achskörper"
        },
        "04844": {
            "GenartName": "Halter, Sensor-Einparkhilfe",
            "NormTermName": "Halter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sensor-Einparkhilfe"
        },
        "04845": {
            "GenartName": "Halter, Sensor-Einparkhilfe",
            "NormTermName": "Halter",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Sensor-Einparkhilfe"
        },
        "04846": {
            "GenartName": "Distanzstück, Scheibeneinbau",
            "NormTermName": "Distanzstück",
            "AssGrpName": "Karosserie",
            "UsageName": "Scheibeneinbau"
        },
        "04847": {
            "GenartName": "Anti-Beschlagsensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Frontscheibe"
        },
        "04848": {
            "GenartName": "Dichtung, Wasserkasten",
            "NormTermName": "Dichtung",
            "AssGrpName": "Karosserie",
            "UsageName": "Wasserkasten"
        },
        "04849": {
            "GenartName": "Harnstoffschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstoff"
        },
        "04850": {
            "GenartName": "Verschluss, Tankeinheit (Harnstoffeinspritzung)",
            "NormTermName": "Verschluss",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Tankeinheit (Harnstoffeinspritzung)"
        },
        "04851": {
            "GenartName": "Heckscheibenemblem",
            "NormTermName": "Emblem",
            "AssGrpName": "Karosserie",
            "UsageName": "Heckscheibe"
        },
        "04852": {
            "GenartName": "Einfüllstutzen, Tankeinheit (Harnstoffeinspritzung)",
            "NormTermName": "Einfüllstutzen",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Tankeinheit (Harnstoffeinspritzung)"
        },
        "04853": {
            "GenartName": "Schlauchverbinder, Harnstoffschlauch",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstoffschlauch"
        },
        "04855": {
            "GenartName": "Optikkörper, Regensensor",
            "NormTermName": "Optikkörper",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Regensensor"
        },
        "04856": {
            "GenartName": "Dichtungssatz, Heckscheibe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Heckscheibe"
        },
        "04857": {
            "GenartName": "Nietensatz, Scheibeneinbau",
            "NormTermName": "Nietensatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Scheibeneinbau"
        },
        "04858": {
            "GenartName": "Positionsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Positionsleuchte"
        },
        "04859": {
            "GenartName": "Positionsleuchte",
            "NormTermName": "Leuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Positionsleuchte"
        },
        "04860": {
            "GenartName": "Drehmomentschlüssel, Zündkerze",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Anzugsdrehmoment-Zündkerze"
        },
        "04861": {
            "GenartName": "Drehmomentschlüssel, Zündkerze",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Anzugsdrehmoment-Zündkerze"
        },
        "04862": {
            "GenartName": "Montagewerkzeugsatz, Einlass-/Auslassventil",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Einlass-/Auslassventil"
        },
        "04863": {
            "GenartName": "Zange, Ventilschaftdichtung",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ventilschaftdichtung"
        },
        "04864": {
            "GenartName": "Einschleifwerkzeugsatz, Ventilsitz",
            "NormTermName": "Einschleifwerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ventilsitz"
        },
        "04865": {
            "GenartName": "Einschleifwerkzeugsatz, Ventilsitz",
            "NormTermName": "Einschleifwerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ventilsitz"
        },
        "04866": {
            "GenartName": "Ausbohrset, Injektor-Halteschrauben",
            "NormTermName": "Ausbohrset",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Injektor-Halteschrauben"
        },
        "04867": {
            "GenartName": "Ausbohrset, Injektor-Halteschrauben",
            "NormTermName": "Ausbohrset",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Injektor-Halteschrauben"
        },
        "04868": {
            "GenartName": "Justierlehre, Nockenwelle",
            "NormTermName": "Lehre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Nockenwelle-Justierung"
        },
        "04869": {
            "GenartName": "Additiv, Turbolader (Erstbefüllung)",
            "NormTermName": "Zusatzmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Turbolader-Erstbefüllung"
        },
        "04870": {
            "GenartName": "Abziehersatz, Riemenscheibe",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Riemenscheibe"
        },
        "04871": {
            "GenartName": "Abziehersatz, Riemenscheibe",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Riemenscheibe"
        },
        "04872": {
            "GenartName": "Schraubensatz, Zylinderkopfhaube",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Zylinderkopfhaube"
        },
        "04873": {
            "GenartName": "Montagevorrichtung, Motor-/Getriebeeinheit",
            "NormTermName": "Montagevorrichtung",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Motor-/Getriebeeinheit"
        },
        "04874": {
            "GenartName": "Durchdrehvorrichtung, Motor",
            "NormTermName": "Durchdrehvorrichtung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Motor"
        },
        "04875": {
            "GenartName": "Durchdrehvorrichtung, Motor",
            "NormTermName": "Durchdrehvorrichtung",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Motor"
        },
        "04876": {
            "GenartName": "Arretierwerkzeug, Zahnriemenspannrolle",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zahnriemenspannrolle"
        },
        "04877": {
            "GenartName": "Halteleiste, Wasserkastenabdeckung",
            "NormTermName": "Halteleiste",
            "AssGrpName": "Karosserie",
            "UsageName": "Frontscheibe"
        },
        "04878": {
            "GenartName": "Fräßvorrichtung, Kurbelwelle",
            "NormTermName": "Fräßvorrichtung",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kurbelwelle"
        },
        "04879": {
            "GenartName": "Federspanner, Fahrwerksfeder",
            "NormTermName": "Federspanner",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Fahrwerksfeder"
        },
        "04880": {
            "GenartName": "Einstellwerkzeug, Sturz-/Spureinstellung",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Sturz-/Spureinstellung"
        },
        "04881": {
            "GenartName": "Einstellwerkzeug, Sturz-/Spureinstellung",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Sturz-/Spureinstellung"
        },
        "04882": {
            "GenartName": "Abzieher, Wischarm",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Wischarm"
        },
        "04883": {
            "GenartName": "Abzieher, Wischarm",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Wischarm"
        },
        "04884": {
            "GenartName": "Diagnosegerät, Kurzschlussursache",
            "NormTermName": "Diagnosegerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Kurzschlussursache"
        },
        "04885": {
            "GenartName": "Fettspray",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Fettspray"
        },
        "04886": {
            "GenartName": "Dichtringsortiment, Einspritzventil",
            "NormTermName": "Sortiment",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Dichtring-Einspritzventil"
        },
        "04887": {
            "GenartName": "Konservierungswachs",
            "NormTermName": "Konservierungsmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Konservierungswachs"
        },
        "04888": {
            "GenartName": "Dichtringsortiment, Einspritzventil",
            "NormTermName": "Sortiment",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Dichtring-Einspritzventil"
        },
        "04889": {
            "GenartName": "Prüfgerät, Abgasturbolader",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Abgasturbolader"
        },
        "04890": {
            "GenartName": "Öl-Einfülltrichter",
            "NormTermName": "Trichter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Öl-Einfülltrichter"
        },
        "04891": {
            "GenartName": "Adapter, Fettkartuschenwiederbefüllung",
            "NormTermName": "Adapter",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Fettkartuschenwiederbefüllung"
        },
        "04892": {
            "GenartName": "Adapter, Fettkartusche",
            "NormTermName": "Adapter",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Fettkartusche"
        },
        "04893": {
            "GenartName": "Adaptersatz, Fettkartusche",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Fettkartusche"
        },
        "04894": {
            "GenartName": "Partikelsensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Partikel"
        },
        "04895": {
            "GenartName": "Schmierfettpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Schmierfettpumpe"
        },
        "04896": {
            "GenartName": "Fettfolgekolben, Schmierfettpumpe",
            "NormTermName": "Kolben",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Schmierfettpumpe"
        },
        "04897": {
            "GenartName": "Deckel, Gebinde (Schmierfettpumpe)",
            "NormTermName": "Deckel",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Gebinde (Schmierfettpumpe)"
        },
        "04898": {
            "GenartName": "Kurbelwelle, Druckluftkompressor",
            "NormTermName": "Welle",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "04899": {
            "GenartName": "Anschlussleitung, Hochdruckpumpe",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Hochdruckpumpe"
        },
        "04900": {
            "GenartName": "Anschlussleitung, Einspritzventil",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzventil"
        },
        "04901": {
            "GenartName": "Anschlussleitung, Ventileinheit (Rail)",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Ventileinheit (Rail)"
        },
        "04902": {
            "GenartName": "Seilzug, Spannarm-Keilriemen",
            "NormTermName": "Seilzug",
            "AssGrpName": "Riementrieb",
            "UsageName": "Riemenspanner"
        },
        "04903": {
            "GenartName": "4-Kant-Schlüssel, Kombi-Instrument",
            "NormTermName": "4-Kant-Schlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kombi-Instrument"
        },
        "04904": {
            "GenartName": "Zahnrad, Vorgelegewelle",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Vorgelegewelle"
        },
        "04905": {
            "GenartName": "Zahnrad, Hauptwelle",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Hauptwelle"
        },
        "04906": {
            "GenartName": "Magnetventil, Klimaanlage",
            "NormTermName": "Ventil",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Klimaanlage"
        },
        "04907": {
            "GenartName": "Werkzeugkasten",
            "NormTermName": "Werkzeugkasten",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Werkzeug"
        },
        "04908": {
            "GenartName": "Haltersatz, Werkzeugkasten",
            "NormTermName": "Haltersatz",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Werkzeugkasten"
        },
        "04909": {
            "GenartName": "Papierrolle, Prüfgerät-Batterie",
            "NormTermName": "Rolle",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Batterie"
        },
        "04910": {
            "GenartName": "Zierblende, Schlussleuchte",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Schlussleuchte"
        },
        "04911": {
            "GenartName": "Zierblende, Blinkleuchte",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Blinkleuchte"
        },
        "04912": {
            "GenartName": "Zierblende, Rückfahrleuchte",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Rückfahrleuchte"
        },
        "04913": {
            "GenartName": "Rücklaufleitung, Stoßdämpfer",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Stoßdämpfer"
        },
        "04914": {
            "GenartName": "Adapter, Crimpzange",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Crimpzange"
        },
        "04915": {
            "GenartName": "Steckgehäusesatz",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Steckgehäusesatz"
        },
        "04916": {
            "GenartName": "Lecksuchgerät, Klimaanlage",
            "NormTermName": "Lecksuche",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Klimaanlage"
        },
        "04917": {
            "GenartName": "Druckluft-Öler",
            "NormTermName": "Druckluft-Öler",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Druckluft"
        },
        "04918": {
            "GenartName": "Verschlussschraube, Getriebegehäuse",
            "NormTermName": "Schraube",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Getriebegehäuse"
        },
        "04919": {
            "GenartName": "Halter, Ölwannenbefestigung",
            "NormTermName": "Halter",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölwanne-Automatikgetriebe"
        },
        "04920": {
            "GenartName": "Torsionsdämpfer, Schwungrad",
            "NormTermName": "Torsionsdämpfer",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Schwungrad"
        },
        "04921": {
            "GenartName": "Teilesatz, Automatikgetriebe-Ölwechsel",
            "NormTermName": "Teilesatz",
            "AssGrpName": "Service/Wartung",
            "UsageName": "Automatikgetriebe-Ölwechsel"
        },
        "04922": {
            "GenartName": "Gehäuse, Hydraulikfilter-Automatikgetriebe",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Hydraulikfilter"
        },
        "04924": {
            "GenartName": "Magnet, Ölwanne-Automatikgetriebe",
            "NormTermName": "Magnet",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölwanne-Automatikgetriebe"
        },
        "04925": {
            "GenartName": "Sortiment, Kühlerkleinteile",
            "NormTermName": "Sortiment",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Kühlerkleinteile"
        },
        "04926": {
            "GenartName": "Lager, Automatikgetriebe",
            "NormTermName": "Lager",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "04927": {
            "GenartName": "Winkelkorrekturring, Sensor (Einparkhilfe)",
            "NormTermName": "Winkelkorrekturring",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sensor-Einparkhilfe"
        },
        "04928": {
            "GenartName": "Winkelkorrekturring, Sensor (Einparkhilfe)",
            "NormTermName": "Winkelkorrekturring",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Sensor-Einparkhilfe"
        },
        "04929": {
            "GenartName": "Achszapfen, Achskörper",
            "NormTermName": "Achszapfen",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achskörper"
        },
        "04930": {
            "GenartName": "Reparatursatz, Achszapfen (Achskörper)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achszapfen (Achskörper)"
        },
        "04931": {
            "GenartName": "Montagewerkzeugsatz, Achszapfen (Achskörper)",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Achszapfen (Achskörper)"
        },
        "04932": {
            "GenartName": "Stellelement, Feststellbremse Bremssattel",
            "NormTermName": "Stellelement",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremse Bremssattel"
        },
        "04933": {
            "GenartName": "Adapterkabel, Steuerklappe-Luftversorgung",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Steuerklappe-Luftversorgung"
        },
        "04934": {
            "GenartName": "Verstärkungssatz, Blattfeder",
            "NormTermName": "Verstärkungssatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Blattfeder"
        },
        "04935": {
            "GenartName": "Niedertemperaturkühler, Ladeluftkühler",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladeluftkühler"
        },
        "04936": {
            "GenartName": "Fahrradhalter, Innenraum",
            "NormTermName": "Fahrradhalter",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Innenraum"
        },
        "04937": {
            "GenartName": "Fahrradhalter, Innenraum",
            "NormTermName": "Fahrradhalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Innenraum"
        },
        "04938": {
            "GenartName": "Ballonheber",
            "NormTermName": "Ballonheber",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04940": {
            "GenartName": "Unterfahrschutz, Anhängevorrichtung",
            "NormTermName": "Unterfahrschutz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "04941": {
            "GenartName": "Hitzeschutzblech, Zündspule",
            "NormTermName": "Hitzeschutzblech",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündspule"
        },
        "04942": {
            "GenartName": "Sattelkupplung",
            "NormTermName": "Sattelkupplung",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "04943": {
            "GenartName": "Verschleißring, Sattelkupplung",
            "NormTermName": "Verschleißring",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Sattelkupplung"
        },
        "04944": {
            "GenartName": "Lagerbolzensatz, Sattelkupplung",
            "NormTermName": "Bolzensatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Sattelkupplung-Lagerung"
        },
        "04945": {
            "GenartName": "Reparatursatz, Sattelkupplung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Sattelkupplung"
        },
        "04946": {
            "GenartName": "Schraube, Wasserpumpe",
            "NormTermName": "Schraube",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "04947": {
            "GenartName": "Zündleitungssatz",
            "NormTermName": "Elektroleitungssatz",
            "AssGrpName": "Zündanlage, Universal",
            "UsageName": null
        },
        "04948": {
            "GenartName": "Dichtringsatz, Einspritzventil",
            "NormTermName": "Dichtringsatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzventil"
        },
        "04949": {
            "GenartName": "Dichtring, Zylinderlaufbuchse",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Zylinderlaufbuchse"
        },
        "04950": {
            "GenartName": "Verbindungskabel, Anhänger",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Anhänger"
        },
        "04951": {
            "GenartName": "Verlängerungskabel, Anhängersteckdose",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Anhängersteckdose"
        },
        "04952": {
            "GenartName": "Adapterkabel, Anhängersteckdose",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Anhängersteckdose"
        },
        "04953": {
            "GenartName": "Anhängersteckdose",
            "NormTermName": "Steckdose",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Anhänger"
        },
        "04954": {
            "GenartName": "Sortiment, Quetschverbinder",
            "NormTermName": "Sortiment",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Quetschverbindung"
        },
        "04955": {
            "GenartName": "Sortiment, Steckgehäuse",
            "NormTermName": "Sortiment",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Steckgehäuse"
        },
        "04956": {
            "GenartName": "Sortiment, Schlauchverbinder",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Schlauchverbinder"
        },
        "04957": {
            "GenartName": "Sortiment, Sicherungen",
            "NormTermName": "Sortiment",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Sicherung"
        },
        "04958": {
            "GenartName": "Sortiment, Sicherungsautomaten",
            "NormTermName": "Sortiment",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Sicherungsautomaten"
        },
        "04959": {
            "GenartName": "Sortiment, Dicht-/Schutzstopfen",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Dicht-/Schutzstopfen"
        },
        "04960": {
            "GenartName": "Druckleitung, Drucksensor (Ruß-/Partikelfilter)",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Drucksensor (Ruß-/Partikelfilter)"
        },
        "04961": {
            "GenartName": "Versorgungsbatterie",
            "NormTermName": "Batterie",
            "AssGrpName": "Bordnetz",
            "UsageName": "Versorgungsbatterie"
        },
        "04963": {
            "GenartName": "Fahrerassistenzsystem",
            "NormTermName": "Fahrerassistenzsystem",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": null
        },
        "04964": {
            "GenartName": "Türwarner",
            "NormTermName": "Türwarner",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": null
        },
        "04965": {
            "GenartName": "NOx-Sensor, NOx-Katalysator",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "NOx-Katalysator"
        },
        "04966": {
            "GenartName": "Reifendruck-Kontrollsystem",
            "NormTermName": "Reifendruck-Kontrollsystem",
            "AssGrpName": "Räder",
            "UsageName": null
        },
        "04967": {
            "GenartName": "Multifunktionsrelais",
            "NormTermName": "Relais",
            "AssGrpName": "Bordnetz",
            "UsageName": "Multifunktion"
        },
        "04969": {
            "GenartName": "Schraubensatz, Generatorregler",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Generator",
            "UsageName": "Generatorregler"
        },
        "04970": {
            "GenartName": "Schraubensatz, Generator",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "04971": {
            "GenartName": "Reparatursatz, Generatorstator",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Generator",
            "UsageName": "Generatorstator"
        },
        "04972": {
            "GenartName": "Reparatursatz, Generatorgleichrichter",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Generator",
            "UsageName": "Generatorgleichrichter"
        },
        "04973": {
            "GenartName": "Rumpfgruppe, Lader",
            "NormTermName": "Rumpfgruppe",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lader"
        },
        "04974": {
            "GenartName": "Reparatursatz, Lader",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lader"
        },
        "04975": {
            "GenartName": "Lagersatz, Automatikgetriebe",
            "NormTermName": "Lagersatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "04976": {
            "GenartName": "Messleitungssatz",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04977": {
            "GenartName": "SRS-Stecker, Prüfwiderstand",
            "NormTermName": "Stecker",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfwiderstand"
        },
        "04978": {
            "GenartName": "Polaritätstester",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "04979": {
            "GenartName": "Seilzug, Automatikgetriebe",
            "NormTermName": "Seilzug",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Schaltung"
        },
        "04980": {
            "GenartName": "Lager, Verteilergetriebe",
            "NormTermName": "Lager",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Verteilergetriebe"
        },
        "04981": {
            "GenartName": "Lager, Differential",
            "NormTermName": "Lager",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "04982": {
            "GenartName": "Reparatursatz, Generatorklemme",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Generator",
            "UsageName": "Generatorklemme"
        },
        "04983": {
            "GenartName": "Reparatursatz, Starterklemme",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Startanlage",
            "UsageName": "Starterklemme"
        },
        "04984": {
            "GenartName": "Reparatursatz, Einrückachse (Starter)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Startanlage",
            "UsageName": "Einrückachse"
        },
        "04985": {
            "GenartName": "Reparatursatz, Antriebswelle (Starter)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Startanlage",
            "UsageName": "Antriebswelle"
        },
        "04986": {
            "GenartName": "Montagesatz, Generatorläufer",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Generator",
            "UsageName": "Generatorläufer"
        },
        "04987": {
            "GenartName": "Befestigungssatz, Riemenscheibe (Generator)",
            "NormTermName": "Befestigungssatz",
            "AssGrpName": "Generator",
            "UsageName": "Riemenscheibe"
        },
        "04988": {
            "GenartName": "Lagerinnenring",
            "NormTermName": "Lagerinnenring",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "04989": {
            "GenartName": "Isolierscheibe, Generatorklemme",
            "NormTermName": "Isolator",
            "AssGrpName": "Generator",
            "UsageName": "Generatorklemme"
        },
        "04990": {
            "GenartName": "Kappe, Kabelverschraubung",
            "NormTermName": "Kappe",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kabelverschraubung"
        },
        "04991": {
            "GenartName": "Feststellzange, Entlüfterschraube/-ventil",
            "NormTermName": "Feststellzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Entlüfterschraube/-ventil"
        },
        "04992": {
            "GenartName": "Buchseneinsatz, Gehäuse",
            "NormTermName": "Buchseneinsatz",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "04993": {
            "GenartName": "Steckereinsatz, Gehäuse",
            "NormTermName": "Steckereinsatz",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "04994": {
            "GenartName": "Gehäuse",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "04995": {
            "GenartName": "Tuning-Modul, Motorleistungssteigerung",
            "NormTermName": "Tuning-Modul",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Motorleistungssteigerung"
        },
        "04996": {
            "GenartName": "Kappe, Starterrelais",
            "NormTermName": "Kappe",
            "AssGrpName": "Startanlage",
            "UsageName": "Relais"
        },
        "04997": {
            "GenartName": "Hohlrad, Planetengetriebe (Starter)",
            "NormTermName": "Hohlrad",
            "AssGrpName": "Startanlage",
            "UsageName": "Planetengetriebe-Starter"
        },
        "04998": {
            "GenartName": "Befestigungselement, Motorabdeckung",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorabdeckung"
        },
        "04999": {
            "GenartName": "Distanzhülse, Radlager",
            "NormTermName": "Hülse",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radlager"
        },
        "05000": {
            "GenartName": "Verschlussdeckel, Lenkhebelwelle",
            "NormTermName": "Verschluss",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkhebelwelle"
        },
        "05001": {
            "GenartName": "Schraube, Einspritzdüsenhalter",
            "NormTermName": "Schraube",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzdüsenhalter"
        },
        "05002": {
            "GenartName": "Gleitscheibe, Lenkhebelwelle",
            "NormTermName": "Gleitscheibe",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkhebelwelle"
        },
        "05003": {
            "GenartName": "Anbauteilesatz, Thermostatgehäuse",
            "NormTermName": "Anbauteilesatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Thermostatgehäuse"
        },
        "05004": {
            "GenartName": "Distanzhülse, Federbeinstützlager",
            "NormTermName": "Hülse",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Federbeinstützlager"
        },
        "05005": {
            "GenartName": "Lager, Wasserpumpenwelle",
            "NormTermName": "Lager",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpenwelle"
        },
        "05006": {
            "GenartName": "Dichtung, Abdeckung-Bremsnockenwelle",
            "NormTermName": "Dichtung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsnockenwelle"
        },
        "05007": {
            "GenartName": "Abdeckung, Bremsnockenwelle",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsnockenwelle"
        },
        "05008": {
            "GenartName": "Manschette, Bremsnockenwelle",
            "NormTermName": "Manschette",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsnockenwelle"
        },
        "05009": {
            "GenartName": "Arbeitszylinder, Sitzverstellung",
            "NormTermName": "Zylinder",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sitzverstellung"
        },
        "05010": {
            "GenartName": "Dichtung, Ladeluftkühler",
            "NormTermName": "Dichtung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladeluftkühler"
        },
        "05011": {
            "GenartName": "Schraubensatz, Zahnräder (Steuerkette)",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Zahnräder (Steuerkette)"
        },
        "05012": {
            "GenartName": "Lagerbuchse, Blattfeder-Motorhaube",
            "NormTermName": "Buchse",
            "AssGrpName": "Karosserie",
            "UsageName": "Blattfeder"
        },
        "05013": {
            "GenartName": "Zahnradsatz, Motorsteuerung",
            "NormTermName": "Zahnradsatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": null
        },
        "05015": {
            "GenartName": "Adapter, Öldruckschalter",
            "NormTermName": "Adapter",
            "AssGrpName": "Schmierung",
            "UsageName": "Öldruckschalter"
        },
        "05016": {
            "GenartName": "Adapter, Bremslichtschalter",
            "NormTermName": "Adapter",
            "AssGrpName": "Signalanlage",
            "UsageName": "Bremslichtschalter"
        },
        "05017": {
            "GenartName": "Kurbelwellenlagersatz",
            "NormTermName": "Lagerschalensatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwelle"
        },
        "05018": {
            "GenartName": "Schleifring, Generator",
            "NormTermName": "Schleifring",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "05019": {
            "GenartName": "Anhängerstecker",
            "NormTermName": "Stecker",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Anhänger"
        },
        "05020": {
            "GenartName": "Zündkerze",
            "NormTermName": "Zündkerze",
            "AssGrpName": "Zündanlage, Universal",
            "UsageName": null
        },
        "05021": {
            "GenartName": "Ladekantenschutz",
            "NormTermName": "Ladekantenschutz",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "05022": {
            "GenartName": "Staubschutzkappe, Bremssattelkolben",
            "NormTermName": "Kappe",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattelkolben"
        },
        "05023": {
            "GenartName": "Montagehülsensatz, Bremsscheibe/-trommel",
            "NormTermName": "Montagehülsensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsscheibe/-trommel"
        },
        "05024": {
            "GenartName": "Anti-Quietsch-Folie, Bremsbelag (Rückenplatte)",
            "NormTermName": "Folie",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremsbelag (Rückenplatte)"
        },
        "05025": {
            "GenartName": "Windabweiser",
            "NormTermName": "Windabweiser",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "05026": {
            "GenartName": "Sicherungshalter",
            "NormTermName": "Halter",
            "AssGrpName": "Bordnetz",
            "UsageName": "Sicherung"
        },
        "05027": {
            "GenartName": "Dichtring, Steuerkettenspanner",
            "NormTermName": "Dichtring",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkettenspanner"
        },
        "05028": {
            "GenartName": "Dichtung, Sekundärluftventil",
            "NormTermName": "Dichtung",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": "Sekundärluftventil"
        },
        "05029": {
            "GenartName": "Gasfeder, Einlegeboden (Koffer-/Laderaum)",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Einlegeboden (Koffer-/Laderaum)"
        },
        "05030": {
            "GenartName": "Umrüstsatz, Kupplung",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "05031": {
            "GenartName": "Kühler, Antriebsbatterie",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Antriebsbatterie"
        },
        "05032": {
            "GenartName": "Gasfeder, Ladeklappe (Heckklappe)",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Ladeklappe (Heckklappe)"
        },
        "05033": {
            "GenartName": "Montagewerkzeugsatz, Steckgehäuse",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steckgehäuse"
        },
        "05034": {
            "GenartName": "Halter, Warnmarkierung",
            "NormTermName": "Halter",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Warnmarkierung"
        },
        "05035": {
            "GenartName": "Halter, Parkwarntafel",
            "NormTermName": "Halter",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Parkwarntafel"
        },
        "05036": {
            "GenartName": "Dichtung, Öldruckhalteventil",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schmierung",
            "UsageName": "Öldruckhalteventil"
        },
        "05037": {
            "GenartName": "Unterlegplatte, Antriebswellenflansch",
            "NormTermName": "Unterlegplatte",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswellenflansch"
        },
        "05039": {
            "GenartName": "Hydraulikfilter, Lamellenkupplung-Allradantrieb",
            "NormTermName": "Hydraulikfilter",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Lamellenkupplung-Allradantrieb"
        },
        "05040": {
            "GenartName": "Harnstoff",
            "NormTermName": "Harnstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Harnstoff"
        },
        "05041": {
            "GenartName": "Verschlussriegel, Zugsattelzapfen",
            "NormTermName": "Verschlussriegel",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Zugsattelzapfen"
        },
        "05042": {
            "GenartName": "Verschlusshaken, Zugsattelzapfen",
            "NormTermName": "Haken",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Zugsattelzapfen"
        },
        "05043": {
            "GenartName": "Dichtung, Anhängersteckdose",
            "NormTermName": "Dichtung",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Anhängersteckdose"
        },
        "05044": {
            "GenartName": "Halteblech, Kabelverbindungsdose",
            "NormTermName": "Halteblech",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Kabelverbindungsdose"
        },
        "05045": {
            "GenartName": "Lagerbuchse, Lagerbock-Sattelkupplung",
            "NormTermName": "Buchse",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Lagerbock-Sattelkupplung"
        },
        "05046": {
            "GenartName": "Sortiment, Kabeldurchführungstülle",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Kabelschutz"
        },
        "05048": {
            "GenartName": "Halter, Trittbrett",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie",
            "UsageName": "Trittbrett"
        },
        "05049": {
            "GenartName": "Verkleidung, Trittbrett",
            "NormTermName": "Verkleidung",
            "AssGrpName": "Karosserie",
            "UsageName": "Trittbrett"
        },
        "05050": {
            "GenartName": "Lagerbock, Stoßdämpferlagerung (Fahrerhaus)",
            "NormTermName": "Lagerbock",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Stoßdämpferlagerung"
        },
        "05051": {
            "GenartName": "Steckwelle, Differential",
            "NormTermName": "Steckwelle",
            "AssGrpName": "Radantrieb",
            "UsageName": "Differential"
        },
        "05052": {
            "GenartName": "Kappe, Stoßdämpferlagerung (Fahrerhaus)",
            "NormTermName": "Kappe",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Stoßdämpferlagerung"
        },
        "05053": {
            "GenartName": "Deckel Luftfiltergehäuse",
            "NormTermName": "Deckel",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Luftfiltergehäuse"
        },
        "05054": {
            "GenartName": "Dichtring",
            "NormTermName": "Dichtring",
            "AssGrpName": null,
            "UsageName": null
        },
        "05055": {
            "GenartName": "Deckel, Ölkühler",
            "NormTermName": "Deckel",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölkühler"
        },
        "05056": {
            "GenartName": "Seilzug, Tankdeckel",
            "NormTermName": "Seilzug",
            "AssGrpName": "Karosserie",
            "UsageName": "Tankdeckel"
        },
        "05057": {
            "GenartName": "Seilzug, Tankdeckel",
            "NormTermName": "Seilzug",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Tankdeckel"
        },
        "05058": {
            "GenartName": "Bolzen, Planetengetriebe",
            "NormTermName": "Bolzen",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Planetengetriebe"
        },
        "05059": {
            "GenartName": "Kabeldurchführungstülle",
            "NormTermName": "Tülle",
            "AssGrpName": "Normteile",
            "UsageName": "Kabelschutz"
        },
        "05060": {
            "GenartName": "Halteclipsatz, Innenraumverkleidung",
            "NormTermName": "Halteclipsatz",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Innenraumverkleidung"
        },
        "05061": {
            "GenartName": "Schalthebel",
            "NormTermName": "Hebel",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltung"
        },
        "05062": {
            "GenartName": "Antriebswelle, Retarder",
            "NormTermName": "Welle",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "05063": {
            "GenartName": "Ausgleichsbehälter, Kupplung",
            "NormTermName": "Behälter",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplung"
        },
        "05064": {
            "GenartName": "Spannband, Kotflügel",
            "NormTermName": "Spannband",
            "AssGrpName": "Karosserie",
            "UsageName": "Kotflügel"
        },
        "05065": {
            "GenartName": "Steuergerät, Einparkhilfe-Abschaltung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Einparkhilfe-Abschaltung"
        },
        "05066": {
            "GenartName": "Lagerung, Kotflügelhalterung",
            "NormTermName": "Lagerung",
            "AssGrpName": "Karosserie",
            "UsageName": "Kotflügelhalterung"
        },
        "05067": {
            "GenartName": "Programmier-/ Diagnosegerät, Reifendruck-Kontrollsystem",
            "NormTermName": "Programmier-/ Diagnosegerät",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Reifendruckkontrollsystem"
        },
        "05068": {
            "GenartName": "Reparatursatz, Retarder",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "05069": {
            "GenartName": "Druckspeicher, Retarder",
            "NormTermName": "Retarder",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "05070": {
            "GenartName": "Prüf-/ Messadapter, CAN Bus System",
            "NormTermName": "Prüf-/ Messadapter",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": null
        },
        "05071": {
            "GenartName": "Lampenfassung, elektrische Universalteile",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "05072": {
            "GenartName": "Luftfilter, Retarder",
            "NormTermName": "Filter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "05073": {
            "GenartName": "Schlauchleitung, Retarder",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "05074": {
            "GenartName": "Kolben, Retarder",
            "NormTermName": "Kolben",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "05075": {
            "GenartName": "Rohr, Retarder",
            "NormTermName": "Rohr",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "05076": {
            "GenartName": "Nutmutter, Redarder",
            "NormTermName": "Mutter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "05077": {
            "GenartName": "Verschlussschraube, Retarder",
            "NormTermName": "Schraube",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "05078": {
            "GenartName": "Mitnehmer, Retarder",
            "NormTermName": "Mitnehmer",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "05079": {
            "GenartName": "Fangband, Gelenkwelle",
            "NormTermName": "Band",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Gelenkwelle"
        },
        "05080": {
            "GenartName": "Nadelrolle",
            "NormTermName": "Nadelrolle",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "05081": {
            "GenartName": "Sicherungsscheibe, Achsausgleich",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsausgleich"
        },
        "05082": {
            "GenartName": "Planetenrad, Außenplanetengetriebe",
            "NormTermName": "Planetenrad",
            "AssGrpName": "Radantrieb",
            "UsageName": "Außenplanetengetriebe"
        },
        "05083": {
            "GenartName": "Sonnenrad, Außenplanetengetriebe",
            "NormTermName": "Sonnenrad",
            "AssGrpName": "Radantrieb",
            "UsageName": "Außenplanetengetriebe"
        },
        "05084": {
            "GenartName": "Planetenradträger",
            "NormTermName": "Träger",
            "AssGrpName": "Radantrieb",
            "UsageName": "Außenplanetengetriebe"
        },
        "05085": {
            "GenartName": "Druckscheibe, Außenplanetengetriebe",
            "NormTermName": "Druckscheibe",
            "AssGrpName": "Radantrieb",
            "UsageName": "Außenplanetengetriebe"
        },
        "05086": {
            "GenartName": "Trag-/Führungsgelenk, Trittbrett (Fahrerhauseinstieg)",
            "NormTermName": "Gelenk",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Trittbrett"
        },
        "05087": {
            "GenartName": "Planetenradsatz, Außenplanetengetriebe",
            "NormTermName": "Planetenradsatz",
            "AssGrpName": "Radantrieb",
            "UsageName": "Außenplanetengetriebe"
        },
        "05088": {
            "GenartName": "Buchse, Achsstern (Ausgleichskegelrad-Differential)",
            "NormTermName": "Buchse",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Ausgleichskegelrad-Differential"
        },
        "05089": {
            "GenartName": "Mutter, Achsstern (Ausgleichskegelrad-Differential)",
            "NormTermName": "Mutter",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Ausgleichskegelrad-Differential"
        },
        "05090": {
            "GenartName": "Zuggriff, Zugsattelzapfen",
            "NormTermName": "Griff",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Zugsattelzapfen"
        },
        "05091": {
            "GenartName": "Kabel, Leuchte (Sonnenblende)",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Leuchte (Sonnenblende)"
        },
        "05093": {
            "GenartName": "Abdeckung, Mittelkonsole",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Mittelkonsole"
        },
        "05094": {
            "GenartName": "Begrenzungsstab",
            "NormTermName": "Begrenzungsstab",
            "AssGrpName": "Karosserie",
            "UsageName": "Rangierhilfe"
        },
        "05095": {
            "GenartName": "Begrenzungsstab",
            "NormTermName": "Begrenzungsstab",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Rangierhilfe"
        },
        "05096": {
            "GenartName": "Halteseil, Reserveradhalter",
            "NormTermName": "Halteseil",
            "AssGrpName": "Karosserie",
            "UsageName": "Reserveradhalter"
        },
        "05097": {
            "GenartName": "Sperrhebel, Zugsattelzapfen",
            "NormTermName": "Sperrhebel",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Zugsattelzapfen"
        },
        "05098": {
            "GenartName": "Sortiment, Sicherungshalter",
            "NormTermName": "Sortiment",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Sicherungshalter"
        },
        "05099": {
            "GenartName": "Kupplungsmaul, Anhängevorrichtung",
            "NormTermName": "Kupplungsmaul",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": null
        },
        "05100": {
            "GenartName": "Schutzkappe, Sicherungshalter",
            "NormTermName": "Schutzkappe/Faltenbalg",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Sicherungshalter"
        },
        "05101": {
            "GenartName": "Schutzhülle, Eigendiagnose-Gerät",
            "NormTermName": "Schutzhülle",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Eigendiagnose-Gerät"
        },
        "05102": {
            "GenartName": "Bördelplatte, Luftfederbalg",
            "NormTermName": "Bördelplatte",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Luftfederbalg"
        },
        "05103": {
            "GenartName": "Schalter, Feststellbremsbetätigung",
            "NormTermName": "Schalter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremsbetätigung"
        },
        "05104": {
            "GenartName": "Zugfeder, Sattelkupplung",
            "NormTermName": "Feder",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Sattelkupplung"
        },
        "05105": {
            "GenartName": "Seilzug, Fahrerhauskippvorrichtung",
            "NormTermName": "Seilzug",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhauskippvorrichtung"
        },
        "05106": {
            "GenartName": "Frontklappenschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Frontklappe"
        },
        "05107": {
            "GenartName": "Seitenscheibenrahmen",
            "NormTermName": "Rahmen",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhaus"
        },
        "05108": {
            "GenartName": "Klappe, Staufach",
            "NormTermName": "Staufach",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Staufach"
        },
        "05109": {
            "GenartName": "Scharnier, Staufachklappe",
            "NormTermName": "Scharnier",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Staufachklappe"
        },
        "05110": {
            "GenartName": "Schraubensatz, Längswellengelenk",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Längswellengelenk"
        },
        "05111": {
            "GenartName": "Halter, Sonnenblende",
            "NormTermName": "Halter",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Sonnenblende"
        },
        "05112": {
            "GenartName": "Dichtring, Achsschenkel (Federbock)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Achsschenkel (Federbock)"
        },
        "05113": {
            "GenartName": "Achsschenkel, Federbock",
            "NormTermName": "Achsschenkel",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbock"
        },
        "05114": {
            "GenartName": "Deckel, Radnabe",
            "NormTermName": "Deckel",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Radnabe"
        },
        "05115": {
            "GenartName": "Reparatursatz, Achsschenkel (Federbock)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Achsschenkel (Federbock)"
        },
        "05116": {
            "GenartName": "Anschlagpuffer, Federbock",
            "NormTermName": "Puffer",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbock"
        },
        "05117": {
            "GenartName": "Mutter, Achsschenkelbolzen (Federbock)",
            "NormTermName": "Mutter",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Achsschenkel (Federbock)"
        },
        "05118": {
            "GenartName": "Mutter, Stabilisator",
            "NormTermName": "Mutter",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisator"
        },
        "05119": {
            "GenartName": "Sitzgestellverkleidung",
            "NormTermName": "Verkleidung",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Sitzgestell"
        },
        "05120": {
            "GenartName": "Halter, Sitzgestell",
            "NormTermName": "Halter",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Sitzgestell"
        },
        "05121": {
            "GenartName": "Ölfang, Differential",
            "NormTermName": "Ölfang",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "05122": {
            "GenartName": "Einstellring, Differential",
            "NormTermName": "Einstellring",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "05123": {
            "GenartName": "Harnstofffilter",
            "NormTermName": "Filter",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Harnstoff"
        },
        "05124": {
            "GenartName": "Entstörkondensator",
            "NormTermName": "Kondensator",
            "AssGrpName": null,
            "UsageName": "Entstörung"
        },
        "05125": {
            "GenartName": "Federeinheit, Generator",
            "NormTermName": "Federeinheit",
            "AssGrpName": "Generator",
            "UsageName": "Generator"
        },
        "05126": {
            "GenartName": "Feder, Maulkupplung",
            "NormTermName": "Feder",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Maulkupplung"
        },
        "05127": {
            "GenartName": "Haltestift, Maulkupplung",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Maulkupplung"
        },
        "05128": {
            "GenartName": "Sicherungsbolzen, Maulkupplung",
            "NormTermName": "Bolzen",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Maulkupplung"
        },
        "05129": {
            "GenartName": "Federschwinge, Maulkupplung",
            "NormTermName": "Federschwinge",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Maulkupplung"
        },
        "05130": {
            "GenartName": "Halter, Kraftstofffilter",
            "NormTermName": "Halter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstofffilter"
        },
        "05131": {
            "GenartName": "Ventil, Reifendruck-Kontrollsystem",
            "NormTermName": "Ventil",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "05132": {
            "GenartName": "Radsensor, Reifendruck-Kontrollsystem",
            "NormTermName": "Sensor",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "05133": {
            "GenartName": "Reparatursatz, Ventil (Reifendruck-Kontrollsys.)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Ventil (Reifendruck-Kontrollsys.)"
        },
        "05134": {
            "GenartName": "Lagerung, Rohrleitung",
            "NormTermName": "Lagerung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Rohrleitung-Druckluftanlage"
        },
        "05135": {
            "GenartName": "Schraubensatz, Lagerbock-Kurbelwelle",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Lagerbock-Kurbelwelle"
        },
        "05136": {
            "GenartName": "A-Säule",
            "NormTermName": "A-Säule",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhaus"
        },
        "05137": {
            "GenartName": "Gewindehülse, Luftfederbalg",
            "NormTermName": "Hülse",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Luftfederbalg"
        },
        "05138": {
            "GenartName": "Ventil, Stellelement (Drosselklappe)",
            "NormTermName": "Ventil",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Stellelement (Drosselklappe)"
        },
        "05139": {
            "GenartName": "Montagewerkzeug, Einspritzventil",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Einspritzventil"
        },
        "05140": {
            "GenartName": "Demontagewerkzeug, Einspritzventil",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Einspritzventil"
        },
        "05141": {
            "GenartName": "Dichtung, Bremswelle",
            "NormTermName": "Dichtung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremswelle"
        },
        "05142": {
            "GenartName": "Luftdüse, Armaturenbrett",
            "NormTermName": "Düse",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Armaturenbrett"
        },
        "05143": {
            "GenartName": "Luftdüse, Armaturenbrett",
            "NormTermName": "Düse",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": "Armaturenbrett"
        },
        "05144": {
            "GenartName": "Ventilstößelsatz",
            "NormTermName": "Ventilstößelsatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": null
        },
        "05145": {
            "GenartName": "Adapterkabel, Elektrofahrzeug",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Elektrofahrzeug"
        },
        "05146": {
            "GenartName": "Ladekabel, Elektrofahrzeug",
            "NormTermName": "Ladekabel",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Elektrofahrzeug"
        },
        "05147": {
            "GenartName": "Sicherungsscheibe, Motorlagerung",
            "NormTermName": "Sicherungsscheibe",
            "AssGrpName": "Motoraufhängung",
            "UsageName": "Motorlagerung"
        },
        "05148": {
            "GenartName": "Kupplung, Differentialsperre",
            "NormTermName": "Kupplung",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differentialsperre"
        },
        "05149": {
            "GenartName": "Kette, Bremssattel",
            "NormTermName": "Kette",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "05150": {
            "GenartName": "Lager, Bremssattel",
            "NormTermName": "Lager",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "05151": {
            "GenartName": "Sicherungsblech, Bremssattel",
            "NormTermName": "Sicherungsblech",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "05152": {
            "GenartName": "Bremssattelbrücke",
            "NormTermName": "Bremssattelbrücke",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "05153": {
            "GenartName": "Schutzrohr, Bremswelle",
            "NormTermName": "Rohr",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremswelle"
        },
        "05154": {
            "GenartName": "Rückstellwelle, Bremssattel",
            "NormTermName": "Welle",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "05155": {
            "GenartName": "Halter, Raddrehzahlsensor",
            "NormTermName": "Halter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Raddrehzahlsensor"
        },
        "05156": {
            "GenartName": "Hebel, Bremssattel-Druckluftbremsanlage",
            "NormTermName": "Hebel",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel"
        },
        "05157": {
            "GenartName": "Schutzhülle, Warnleuchte",
            "NormTermName": "Schutzhülle",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Warnleuchte"
        },
        "05158": {
            "GenartName": "Hitzeschutz, Rohrleitung",
            "NormTermName": "Hitzeschutz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Rohrleitung-Druckluftanlage"
        },
        "05159": {
            "GenartName": "Kugelgelenk, Gestänge-Einspritzpumpe",
            "NormTermName": "Gelenk",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Gestänge-Einspritzpumpe"
        },
        "05160": {
            "GenartName": "Reparatursatz, Kurbelgehäuseentlüftung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Hauptbremszylinder"
        },
        "05161": {
            "GenartName": "Rohrleitung, Öleinfüllstutzen",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Öleinfüllstutzen"
        },
        "05162": {
            "GenartName": "Tülle, Kabelverbindungsdose",
            "NormTermName": "Tülle",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kabelverbindungsdose"
        },
        "05163": {
            "GenartName": "ABS-Verbindungskabel",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "ABS"
        },
        "05164": {
            "GenartName": "Griff, Wendelschlauch",
            "NormTermName": "Griff",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Wendelschlauch"
        },
        "05165": {
            "GenartName": "Griff, Wendelschlauch",
            "NormTermName": "Griff",
            "AssGrpName": "Druckluftanlage, Universal",
            "UsageName": "Wendelschlauch"
        },
        "05166": {
            "GenartName": "Anschraubplatte, Anhängevorrichtung",
            "NormTermName": "Anschraubplatte",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": null
        },
        "05167": {
            "GenartName": "Batterieadapter",
            "NormTermName": "Adapter",
            "AssGrpName": "Startanlage, Universal",
            "UsageName": "Starterbatterie"
        },
        "05168": {
            "GenartName": "Batteriepolstern",
            "NormTermName": "Batteriepolstern",
            "AssGrpName": "Startanlage, Universal",
            "UsageName": "Batteriepol"
        },
        "05169": {
            "GenartName": "Überspannungsschutzgerät, Bordnetz",
            "NormTermName": "Überspannungsschutzgerät",
            "AssGrpName": "Bordnetz",
            "UsageName": null
        },
        "05170": {
            "GenartName": "Kupplungsscheibensatz",
            "NormTermName": "Kupplungsscheibensatz",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "05171": {
            "GenartName": "Schneekette",
            "NormTermName": "Schneekette",
            "AssGrpName": "Zubehör",
            "UsageName": null
        },
        "05172": {
            "GenartName": "Reinigung Ruß-/Partikelfilter",
            "NormTermName": "Reinigung Ruß-/Partikelfilter",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "05173": {
            "GenartName": "Druckluftschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Druckluft"
        },
        "05174": {
            "GenartName": "Fernbedienung, Prüfgerät-Anhängersteckdose",
            "NormTermName": "Fernbedienung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Anhängersteckdose"
        },
        "05175": {
            "GenartName": "Überspannungsschutzgerät, Bordnetz",
            "NormTermName": "Überspannungsschutzgerät",
            "AssGrpName": "Bordnetz, Universal",
            "UsageName": null
        },
        "05176": {
            "GenartName": "Dichtungssatz, Unterdruckpumpe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruckpumpe"
        },
        "05177": {
            "GenartName": "Crimpzangeneinsatz",
            "NormTermName": "Einsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Crimpzange"
        },
        "05178": {
            "GenartName": "Crimpzangen-Set",
            "NormTermName": "Set",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Crimpverbindung"
        },
        "05179": {
            "GenartName": "Quetschzangen-Set",
            "NormTermName": "Set",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Quetschverbindung"
        },
        "05180": {
            "GenartName": "Umfeldleuchte",
            "NormTermName": "Umfeldleuchte",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": null
        },
        "05181": {
            "GenartName": "Montage-/Demontagewerkzeug, Kunststoffsockellampe",
            "NormTermName": "Montage-/Demontagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05182": {
            "GenartName": "Deaktivierungsmodul, elektronische Dämpferregulierung",
            "NormTermName": "Deaktivierungsmodul",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Elektronische Dämpferregulierung"
        },
        "05183": {
            "GenartName": "Prüfgerät, Sicherung",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Sicherung"
        },
        "05184": {
            "GenartName": "Schlauch, Batterieentlüftung",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Startanlage, Universal",
            "UsageName": "Starterbatterie"
        },
        "05185": {
            "GenartName": "Abfüllhahn, Batteriesäure",
            "NormTermName": "Ventil",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Batteriesäure"
        },
        "05186": {
            "GenartName": "Sortiment, Kabelschutz",
            "NormTermName": "Sortiment",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kabelschutz"
        },
        "05187": {
            "GenartName": "Dongle, Eigendiagnose-Gerät",
            "NormTermName": "Stecker",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Systemtest"
        },
        "05188": {
            "GenartName": "Säulen-Hebebühne",
            "NormTermName": "Säulen-Hebebühne",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05189": {
            "GenartName": "Scheren-Hebebühne",
            "NormTermName": "Scheren-Hebebühne",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05190": {
            "GenartName": "Stempel-Hebebühne",
            "NormTermName": "Stempel-Hebebühne",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05191": {
            "GenartName": "Radgreifer",
            "NormTermName": "Radgreifer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05192": {
            "GenartName": "Säulen-Hebeanlage",
            "NormTermName": "Säulen-Hebeanlage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05193": {
            "GenartName": "Stempel-Hebeanlage",
            "NormTermName": "Stempel-Hebeanlage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05194": {
            "GenartName": "Achsheber",
            "NormTermName": "Achsheber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05195": {
            "GenartName": "Getriebeheber",
            "NormTermName": "Getriebeheber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05196": {
            "GenartName": "Grubenheber",
            "NormTermName": "Grubenheber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05197": {
            "GenartName": "Hubtisch",
            "NormTermName": "Hubtisch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05198": {
            "GenartName": "Gasfeder, Aschenbecherklappe",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Aschenbecherklappe"
        },
        "05199": {
            "GenartName": "Drucksensor, Fahrdynamikregelung",
            "NormTermName": "Sensor",
            "AssGrpName": "Fahrdynamikregelung",
            "UsageName": "Drucksensor (Fahrdynamikregelung)"
        },
        "05200": {
            "GenartName": "Ölfilter, Turbolader",
            "NormTermName": "Filter",
            "AssGrpName": "Schmierung",
            "UsageName": "Turbolader"
        },
        "05201": {
            "GenartName": "Radheber",
            "NormTermName": "Radheber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05202": {
            "GenartName": "Rangierheber",
            "NormTermName": "Rangierheber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05203": {
            "GenartName": "Unterstellheber",
            "NormTermName": "Unterstellheber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05204": {
            "GenartName": "Ölpumpe, Automatikgetriebe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "05205": {
            "GenartName": "Stickstoffgenerator",
            "NormTermName": "Stickstoffgenerator",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reifentechnik"
        },
        "05206": {
            "GenartName": "Spannvorrichtung, Radauswuchtmaschine",
            "NormTermName": "Spannvorrichtung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reifentechnik"
        },
        "05207": {
            "GenartName": "Zentriervorrichtung, Radauswuchtmaschine",
            "NormTermName": "Zentriervorrichtung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reifentechnik"
        },
        "05208": {
            "GenartName": "Druckflansch, Radauswuchtmaschine",
            "NormTermName": "Flansch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reifentechnik"
        },
        "05209": {
            "GenartName": "Spannmutter, Radauswuchtmaschine",
            "NormTermName": "Mutter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reifentechnik"
        },
        "05210": {
            "GenartName": "Werkzeugsatz, Reifendruck-Kontrollsystem",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "05211": {
            "GenartName": "Videoendoskop",
            "NormTermName": "Videoendoskop",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05212": {
            "GenartName": "EBS-Verbindungskabel",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Druckluftanlage, Universal",
            "UsageName": "Elektronisches Bremssystem"
        },
        "05213": {
            "GenartName": "Entlüfterschraube/-ventil, Bremssattel",
            "NormTermName": "Schraube",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel (Bremssystementlüftung)"
        },
        "05214": {
            "GenartName": "Entlüfterschraube/-ventil, Radbremszylinder",
            "NormTermName": "Schraube",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Radbremszylinder (Bremssystementlüftung)"
        },
        "05215": {
            "GenartName": "Adapterkabel, Schlussleuchte",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Schlussleuchte"
        },
        "05216": {
            "GenartName": "Ventil, Getriebeölkühler",
            "NormTermName": "Ventil",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Getriebekühlung"
        },
        "05217": {
            "GenartName": "Ventil, Getriebeölkühler",
            "NormTermName": "Ventil",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebekühlung"
        },
        "05218": {
            "GenartName": "Reparaturkabel",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kabelreparatur"
        },
        "05219": {
            "GenartName": "Fahrwerkvermessungssystem",
            "NormTermName": "Fahrwerkvermessungssystem",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrzeugvermessungstechnik"
        },
        "05220": {
            "GenartName": "Drehuntersatz, Fahrwerkvermessung",
            "NormTermName": "Drehuntersatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrzeugvermessungstechnik"
        },
        "05221": {
            "GenartName": "Schiebeuntersatz, Fahrwerkvermessung",
            "NormTermName": "Schiebeuntersatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrzeugvermessungstechnik"
        },
        "05222": {
            "GenartName": "Radklammer, Fahrwerkvermessung",
            "NormTermName": "Radklammer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrzeugvermessungstechnik"
        },
        "05223": {
            "GenartName": "Lenkradwaage",
            "NormTermName": "Lenkradwaage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrzeugvermessungstechnik"
        },
        "05224": {
            "GenartName": "Adapterkabel, Schlussleuchte",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Schlussleuchte"
        },
        "05225": {
            "GenartName": "Neigungsmessgerät, Fahrwerkvermessung",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrzeugvermessungstechnik"
        },
        "05226": {
            "GenartName": "Abdeckung, Bremsprüfstand",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsprüfstand"
        },
        "05227": {
            "GenartName": "Einbaurahmen, Bremsprüfstand",
            "NormTermName": "Einbaurahmen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsprüfstand"
        },
        "05228": {
            "GenartName": "Einbauwanne, Bremsprüfstand",
            "NormTermName": "Einbauwanne",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsprüfstand"
        },
        "05229": {
            "GenartName": "Rollensatz, Bremsprüfstand",
            "NormTermName": "Rollensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsprüfstand"
        },
        "05230": {
            "GenartName": "Grubensicherung, Bremsprüfstand",
            "NormTermName": "Sicherheitssystem",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsprüfstand"
        },
        "05231": {
            "GenartName": "Druckumsetzer, Bremsprüfstand",
            "NormTermName": "Druckumsetzer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsprüfstand"
        },
        "05232": {
            "GenartName": "Anzeige, Bremsprüfstand",
            "NormTermName": "Anzeige",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsprüfstand"
        },
        "05233": {
            "GenartName": "Abdeckung, Leistungsprüfstand",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Leistungsprüfstand"
        },
        "05234": {
            "GenartName": "Einbaurahmen, Leistungsprüfstand",
            "NormTermName": "Einbaurahmen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Leistungsprüfstand"
        },
        "05235": {
            "GenartName": "Einbauwanne, Leistungsprüfstand",
            "NormTermName": "Einbauwanne",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Leistungsprüfstand"
        },
        "05236": {
            "GenartName": "Rollensatz, Leistungsprüfstand",
            "NormTermName": "Rollensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Leistungsprüfstand"
        },
        "05237": {
            "GenartName": "Geschwindigkeitsprüfstand",
            "NormTermName": "Prüfstand",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Geschwindigkeit"
        },
        "05238": {
            "GenartName": "Abdeckung, Geschwindigkeitsprüfstand",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Geschwindigkeitsprüfstand"
        },
        "05239": {
            "GenartName": "Einbaurahmen, Geschwindigkeitsprüfstand",
            "NormTermName": "Einbaurahmen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Geschwindigkeitsprüfstand"
        },
        "05240": {
            "GenartName": "Einbauwanne, Geschwindigkeitsprüfstand",
            "NormTermName": "Einbauwanne",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Geschwindigkeitsprüfstand"
        },
        "05241": {
            "GenartName": "Rollensatz, Geschwindigkeitsprüfstand",
            "NormTermName": "Rollensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Geschwindigkeitsprüfstand"
        },
        "05242": {
            "GenartName": "Achs-/Gelenkspieltester",
            "NormTermName": "Achs-/Gelenkspieltester",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05243": {
            "GenartName": "Achslastwaage",
            "NormTermName": "Achslastwaage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrzeugprüftechnik"
        },
        "05244": {
            "GenartName": "Fahrwerktester",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrwerk"
        },
        "05245": {
            "GenartName": "Spurtester",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Spureinstellung"
        },
        "05246": {
            "GenartName": "Senk-/ Hebeschwelle, Bremsprüfstand",
            "NormTermName": "Senk-/ Hebeschwelle",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsenprüfstand"
        },
        "05247": {
            "GenartName": "Hubvorrichtung, Bremsprüfstand",
            "NormTermName": "Hubvorrichtung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsenprüfstand"
        },
        "05248": {
            "GenartName": "Niederspannvorrichtung, Bremsprüfstand",
            "NormTermName": "Niederspannvorrichtung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsprüfstand"
        },
        "05249": {
            "GenartName": "Abdeckung, Stempel-Hebebühne",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Stempel-Hebebühne"
        },
        "05250": {
            "GenartName": "Einbaurahmen, Stempel-Hebebühne",
            "NormTermName": "Einbaurahmen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Stempel-Hebebühne"
        },
        "05251": {
            "GenartName": "Einbauwanne, Stempel-Hebebühne",
            "NormTermName": "Einbauwanne",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Stempel-Hebebühne"
        },
        "05252": {
            "GenartName": "Kontaktträger",
            "NormTermName": "Kontaktträger",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "05253": {
            "GenartName": "Sensor, Heckklappenstellung",
            "NormTermName": "Sensor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Heckklappenstellung"
        },
        "05254": {
            "GenartName": "Abgasschlauch, Abgasabsaugung",
            "NormTermName": "Schlauch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasabsaugung"
        },
        "05255": {
            "GenartName": "Abgastrichter, Abgasabsaugung",
            "NormTermName": "Trichter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasabsaugung"
        },
        "05256": {
            "GenartName": "Flansch, Verteilergetriebe",
            "NormTermName": "Flansch",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Verteilergetriebe"
        },
        "05257": {
            "GenartName": "Absaugarm, Abgasabsaugung",
            "NormTermName": "Absaugarm",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05258": {
            "GenartName": "Saugschlitzkanal",
            "NormTermName": "Saugschlitzkanal",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasabsaugung"
        },
        "05259": {
            "GenartName": "Saugschlitzkanalsystem",
            "NormTermName": "Saugschlitzkanalsystem",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasabsaugung"
        },
        "05260": {
            "GenartName": "Schlauchaufroller, Abgasabsaugung",
            "NormTermName": "Schlauchaufroller",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasabsaugung"
        },
        "05261": {
            "GenartName": "Schlauchwagen, Abgasabsaugung",
            "NormTermName": "Schlauchwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasabsaugung"
        },
        "05263": {
            "GenartName": "Einzelplatz-Absaugeinheit",
            "NormTermName": "Absaugeinheit",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasabsaugung"
        },
        "05264": {
            "GenartName": "Ventilator, Abgasabsaugung",
            "NormTermName": "Ventilator",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasabsaugung"
        },
        "05265": {
            "GenartName": "Balancer, Abgasabsaugung",
            "NormTermName": "Balancer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasabsaugung"
        },
        "05266": {
            "GenartName": "Wickelfalzrohr, Abgasabsaugung",
            "NormTermName": "Rohr",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasabsauganlage"
        },
        "05267": {
            "GenartName": "Anbauelement/Verbinder, Wickelfalzrohr",
            "NormTermName": "Anbauelement/Verbinder",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Wickelfalzrohr"
        },
        "05268": {
            "GenartName": "Anbauelement/Verbinder, Ventilator",
            "NormTermName": "Anbauelement/Verbinder",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Ventilator"
        },
        "05269": {
            "GenartName": "Anbauelement/Verbinder, Saugschlitzkanal",
            "NormTermName": "Anbauelement/Verbinder",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Saugschlitzkanal"
        },
        "05270": {
            "GenartName": "Anbauelement/Verbinder, Abgasschlauch",
            "NormTermName": "Anbauelement/Verbinder",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasschlauch"
        },
        "05271": {
            "GenartName": "Unterflur-Absaugeinheit",
            "NormTermName": "Unterflur-Absaugeinheit",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasabsaugung"
        },
        "05272": {
            "GenartName": "Anbauelement/Verbinder, Unterflur-Absaugeinheit",
            "NormTermName": "Anbauelement/Verbinder",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Unterflur-Absaugeinheit"
        },
        "05273": {
            "GenartName": "Batterielader/-starter",
            "NormTermName": "Batterielader/-starter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05274": {
            "GenartName": "Batteriestarter",
            "NormTermName": "Batteriestarter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05275": {
            "GenartName": "AU-Absaugeinheit",
            "NormTermName": "Absaugeinheit",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "AU"
        },
        "05276": {
            "GenartName": "Thermostat, Kühlung-Abgasrückführung",
            "NormTermName": "Thermostat",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "Abgasrückführung"
        },
        "05277": {
            "GenartName": "Filtertrockner, Klimatechnik",
            "NormTermName": "Trockner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimatechnik"
        },
        "05278": {
            "GenartName": "Altölbehälter, Klimatechnik",
            "NormTermName": "Altölbehälter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimatechnik"
        },
        "05279": {
            "GenartName": "Frischölbehälter, Klimatechnik",
            "NormTermName": "Frischölbehälter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimatechnik"
        },
        "05280": {
            "GenartName": "Kältemittelflasche, Klimatechnik",
            "NormTermName": "Kältemittelflasche",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimatechnik"
        },
        "05281": {
            "GenartName": "Vakuumschlauch, Klimatechnik",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimatechnik"
        },
        "05282": {
            "GenartName": "Spülset, Klimaanlage",
            "NormTermName": "Spülset",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimatechnik"
        },
        "05283": {
            "GenartName": "Spülbehälter, Klimatechnik",
            "NormTermName": "Spülbehälter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimatechnik"
        },
        "05284": {
            "GenartName": "Prüfgerät, Abgasdruck",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasdruck"
        },
        "05285": {
            "GenartName": "Prüfgerät, Harnstoffeinspritzung",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Harnstoffeinspritzung"
        },
        "05286": {
            "GenartName": "Amperemeter",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Strommessung"
        },
        "05287": {
            "GenartName": "Prüfgerät, Getriebeöl",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Getriebeöl"
        },
        "05288": {
            "GenartName": "Messgerät, Kältemittel",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Kältemittel"
        },
        "05289": {
            "GenartName": "Prüfgerät, Kraftstoffvorförderpumpe",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Kraftstoffvorförderpumpe"
        },
        "05290": {
            "GenartName": "Geräuschsuchgerät",
            "NormTermName": "Geräuschsuchgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05291": {
            "GenartName": "Stethoskop",
            "NormTermName": "Stethoskop",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05292": {
            "GenartName": "Trübungsmessgerät",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05293": {
            "GenartName": "Messgerät, Schließkraft",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schließkraft"
        },
        "05294": {
            "GenartName": "Prüfset, Kraftstoffidentifikation",
            "NormTermName": "Prüfset",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Kraftstoff"
        },
        "05295": {
            "GenartName": "Messgerät, Profiltiefe",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Profiltiefe"
        },
        "05296": {
            "GenartName": "Messgerät, Schallpegel",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schallpegel"
        },
        "05297": {
            "GenartName": "Anti-Quietsch-Folie, Bremsbelag (Rückenplatte)",
            "NormTermName": "Folie",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbelag (Rückenplatte)"
        },
        "05299": {
            "GenartName": "Filterdruckregler, Drucklufttechnik",
            "NormTermName": "Filterdruckregler",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05300": {
            "GenartName": "Wartungseinheit, Drucklufttechnik",
            "NormTermName": "Wartungseinheit",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05301": {
            "GenartName": "Druckminderer, Drucklufttechnik",
            "NormTermName": "Druckminderer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05302": {
            "GenartName": "Druckbehälter, Kompressor",
            "NormTermName": "Behälter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05303": {
            "GenartName": "Filter, Drucklufttechnik",
            "NormTermName": "Filter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05304": {
            "GenartName": "Schalldämmhaube, Kompressor",
            "NormTermName": "Schalldämmhaube",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckluftkompressor"
        },
        "05305": {
            "GenartName": "Kondensatableiter, Drucklufttechnik",
            "NormTermName": "Kondensableiter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05306": {
            "GenartName": "Öl-Wasser-Trenner",
            "NormTermName": "Öl-Wasser-Trenner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05307": {
            "GenartName": "Kältetrockner",
            "NormTermName": "Kältetrockner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05308": {
            "GenartName": "Fußbremszylinder",
            "NormTermName": "Zylinder",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Fußbremszylinder (Zweirad)"
        },
        "05309": {
            "GenartName": "Handbremszylinder",
            "NormTermName": "Zylinder",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Handbremszylinder (Zweirad)"
        },
        "05310": {
            "GenartName": "Hohlschraube, Bremsleitung",
            "NormTermName": "Schraube",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsleitung"
        },
        "05311": {
            "GenartName": "Adapter, Bremssattel",
            "NormTermName": "Adapter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel (Zweirad)"
        },
        "05312": {
            "GenartName": "Arbeitsscheinwerfer",
            "NormTermName": "Scheinwerfer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Arbeitsplatzausleuchtung"
        },
        "05313": {
            "GenartName": "Stablampe",
            "NormTermName": "Stablampe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05314": {
            "GenartName": "Schraubensatz, Bremsscheibe",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsscheibe"
        },
        "05315": {
            "GenartName": "Ölauffanggerät",
            "NormTermName": "Ölauffanggerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05316": {
            "GenartName": "Ölabsauggerät",
            "NormTermName": "Ölabsauggerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05317": {
            "GenartName": "Ölauffang-/Absauggerät",
            "NormTermName": "Ölauffang-/Absauggerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05318": {
            "GenartName": "Kraftstoffabsauggerät",
            "NormTermName": "Kraftstoffabsauggerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05319": {
            "GenartName": "Spülsystem, Getriebe",
            "NormTermName": "Spülsystem",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Getriebe"
        },
        "05320": {
            "GenartName": "Spülsystem, Klimaanlage",
            "NormTermName": "Spülsystem",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimaanlage"
        },
        "05321": {
            "GenartName": "Bremsleitungstülle",
            "NormTermName": "Tülle",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsleitung (Zweirad)"
        },
        "05322": {
            "GenartName": "Spülsystem, Lenkung",
            "NormTermName": "Spülsystem",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lenkhydraulik"
        },
        "05323": {
            "GenartName": "Zugelement, Karosserietechnik",
            "NormTermName": "Zugelement",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserietechnik"
        },
        "05324": {
            "GenartName": "Klemmhülse, Bremsleitung",
            "NormTermName": "Hülse",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsleitung (Zweirad)"
        },
        "05325": {
            "GenartName": "Ausbeulanlage",
            "NormTermName": "Ausbeulanlage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05326": {
            "GenartName": "Verteilerstück, Bremsleitung",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsleitung-Verteilerstück (Zweirad)"
        },
        "05327": {
            "GenartName": "Karosserie-Richtbank",
            "NormTermName": "Karosserie-Richtbank",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserietechnik"
        },
        "05328": {
            "GenartName": "Karosserie-Richtsatz",
            "NormTermName": "Karosserie-Richtsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserietechnik"
        },
        "05329": {
            "GenartName": "Sandstrahlgerät",
            "NormTermName": "Sandstrahlgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserietechnik"
        },
        "05330": {
            "GenartName": "Trockeneisstrahlgerät",
            "NormTermName": "Trockeneisstrahlgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserietechnik"
        },
        "05331": {
            "GenartName": "Zugturm",
            "NormTermName": "Zugturm",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserietechnik"
        },
        "05332": {
            "GenartName": "Montagegrube",
            "NormTermName": "Montagegrube",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05333": {
            "GenartName": "Abdeckung, Montagegrube",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05334": {
            "GenartName": "Einfassung, Montagegrube",
            "NormTermName": "Einfassung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Montagegrube"
        },
        "05335": {
            "GenartName": "Schalldämmhaube, Ventilator - Abgasabsaugung",
            "NormTermName": "Schalldämmhaube",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Ventilator - Abgasabsaugung"
        },
        "05336": {
            "GenartName": "Horizontalausleger, Abgasabsaugung",
            "NormTermName": "Ausleger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasschlauch"
        },
        "05338": {
            "GenartName": "Lötpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lötverbindung"
        },
        "05339": {
            "GenartName": "Trockner, Lackiertechnik",
            "NormTermName": "Trockner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lackiertechnik"
        },
        "05340": {
            "GenartName": "Schienensystem, Trockner",
            "NormTermName": "Schienensystem",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Trockner"
        },
        "05341": {
            "GenartName": "Lackiertisch",
            "NormTermName": "Lackiertisch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lackiertechnik"
        },
        "05342": {
            "GenartName": "Lackierständer",
            "NormTermName": "Lackierständer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05343": {
            "GenartName": "Tropf-Station, Lackiertechnik",
            "NormTermName": "Tropf-Station",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lackiertechnik"
        },
        "05344": {
            "GenartName": "Auffangwanne",
            "NormTermName": "Auffangwanne",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05345": {
            "GenartName": "Radwaschmaschine",
            "NormTermName": "Radwaschmaschine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05346": {
            "GenartName": "Fußrastenanlage",
            "NormTermName": "Fußrastenanlage",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Zweirad"
        },
        "05347": {
            "GenartName": "Federspannpresse",
            "NormTermName": "Federspannpresse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05348": {
            "GenartName": "Achsschenkelbolzenpresse",
            "NormTermName": "Achsschenkelbolzenpresse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkstattpressen"
        },
        "05349": {
            "GenartName": "Kehrmaschine",
            "NormTermName": "Kehrmaschine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05350": {
            "GenartName": "Gelenksatz, Fußraste",
            "NormTermName": "Gelenksatz",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Fußraste (Zweirad)"
        },
        "05351": {
            "GenartName": "Besen",
            "NormTermName": "Besen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05352": {
            "GenartName": "Schneefräse",
            "NormTermName": "Schneefräse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05353": {
            "GenartName": "Fußrastensatz",
            "NormTermName": "Fußrastensatz",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Zweirad"
        },
        "05354": {
            "GenartName": "Dampfreiniger",
            "NormTermName": "Dampfreiniger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reinigungsgeräte/Waschanlagen"
        },
        "05355": {
            "GenartName": "Fersenschutz, Fußrastenanlage",
            "NormTermName": "Fersenschutz",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Fußrastenanlage (Zweirad)"
        },
        "05356": {
            "GenartName": "Dampfbügelstation",
            "NormTermName": "Dampfbügelstation",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05357": {
            "GenartName": "Ultraschallreiniger",
            "NormTermName": "Ultraschallreiniger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reinigungsgeräte/Waschanlagen"
        },
        "05358": {
            "GenartName": "Plasmaschneider",
            "NormTermName": "Plasmaschneider",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schneid-/Fügetechnik"
        },
        "05359": {
            "GenartName": "Belaglamellensatz, Kupplung",
            "NormTermName": "Belaglamellensatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Zweirad"
        },
        "05360": {
            "GenartName": "Fasswagen",
            "NormTermName": "Fasswagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Versorgungssysteme für diverse Medien"
        },
        "05361": {
            "GenartName": "Sprühextraktionsgerät",
            "NormTermName": "Sprühextraktionsgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reinigungsgeräte/Waschanlagen"
        },
        "05362": {
            "GenartName": "Kupplungsfedernsatz",
            "NormTermName": "Federsatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsscheibe"
        },
        "05363": {
            "GenartName": "Stahllamellensatz, Kupplung",
            "NormTermName": "Stahllamellensatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Zweirad"
        },
        "05364": {
            "GenartName": "Lenker",
            "NormTermName": "Lenker",
            "AssGrpName": "Lenkung",
            "UsageName": "Zweirad"
        },
        "05365": {
            "GenartName": "Absperrhahn, Drucklufttechnik",
            "NormTermName": "Absperrhahn",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05368": {
            "GenartName": "Blindkappe, Drucklufttechnik",
            "NormTermName": "Kappe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05369": {
            "GenartName": "Blindstopfen, Drucklufttechnik",
            "NormTermName": "Stopfen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05370": {
            "GenartName": "Rohrleitungsdose, Drucklufttechnik",
            "NormTermName": "Rohrleitungsdose",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05371": {
            "GenartName": "Klemmring, Drucklufttechnik",
            "NormTermName": "Klemmring",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05372": {
            "GenartName": "Verbindungs-/Verteilerstück, Drucklufttechnik",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05373": {
            "GenartName": "Reduzierstück, Drucklufttechnik",
            "NormTermName": "Reduzierstück",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05374": {
            "GenartName": "Stützhülse, Drucklufttechnik",
            "NormTermName": "Hülse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05375": {
            "GenartName": "Muffe, Drucklufttechnik",
            "NormTermName": "Muffe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05376": {
            "GenartName": "Schelle, Drucklufttechnik",
            "NormTermName": "Schelle",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05377": {
            "GenartName": "Luftabgang, Drucklufttechnik",
            "NormTermName": "Luftabgang",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05378": {
            "GenartName": "Abdeckung, Stempel-Hebeanlage",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05379": {
            "GenartName": "Einbaurahmen, Stempel-Hebeanlage",
            "NormTermName": "Einbaurahmen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05380": {
            "GenartName": "Einbauwanne, Stempel-Hebeanlage",
            "NormTermName": "Einbauwanne",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "05381": {
            "GenartName": "Lenkerenden",
            "NormTermName": "Lenkerenden",
            "AssGrpName": "Lenkung",
            "UsageName": "Zweirad"
        },
        "05382": {
            "GenartName": "Lenkrohr",
            "NormTermName": "Lenkrohr",
            "AssGrpName": "Lenkung",
            "UsageName": "Zweirad"
        },
        "05383": {
            "GenartName": "Lenkeraufnahme",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenker (Zweirad)"
        },
        "05384": {
            "GenartName": "Distanzstück, Lenker",
            "NormTermName": "Distanzstück",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenker (Zweirad)"
        },
        "05385": {
            "GenartName": "Federzug, Drucklufttechnik",
            "NormTermName": "Federzug",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "05386": {
            "GenartName": "Schlauchleitung, Druckluftkompressor",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckluftkompressor"
        },
        "05387": {
            "GenartName": "Adapter, Servicestutzen-Klimaservicegerät",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimatechnik"
        },
        "05388": {
            "GenartName": "Adaptersatz, Servicestutzen-Klimaservicegerät",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimatechnik"
        },
        "05389": {
            "GenartName": "Adapter, Kältemittelflasche",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Kältemittelflasche"
        },
        "05390": {
            "GenartName": "Dichtung, Adapter-Kältemittelflasche",
            "NormTermName": "Dichtung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Adapter-Kältemittelflasche"
        },
        "05391": {
            "GenartName": "Verschlusskappe, Serviceventil",
            "NormTermName": "Kappe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimatechnik"
        },
        "05392": {
            "GenartName": "Verschlussstopfen, Klimatechnik",
            "NormTermName": "Stopfen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimatechnik"
        },
        "05393": {
            "GenartName": "Hochdruckleitung, Klimaanlage",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "KFZ-Klimaanlage (Hochdruckleitung)"
        },
        "05394": {
            "GenartName": "Niederdruckleitung, Klimaanlage",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "KFZ-Klimaanlage (Niederdruckleitung)"
        },
        "05395": {
            "GenartName": "Messgerät, Drehmoment",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drehmoment"
        },
        "05396": {
            "GenartName": "Messgerät, Drehzahl",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drehzahl"
        },
        "05397": {
            "GenartName": "Hygrometer",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Feuchtigkeit"
        },
        "05398": {
            "GenartName": "Messgerät, Kraftstoffverbrauch",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Kraftstoffverbrauch"
        },
        "05399": {
            "GenartName": "Messgerät, Lack (Härtegrad)",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lackhärte"
        },
        "05400": {
            "GenartName": "Messgerät, Lack (Schichtdicke)",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lackdicke"
        },
        "05401": {
            "GenartName": "Spiegelendoskop",
            "NormTermName": "Spiegelendoskop",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05402": {
            "GenartName": "Lenkrohraufnahme",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkrohr (Zweirad)"
        },
        "05403": {
            "GenartName": "Messgerät, Tankinhalt",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Tankinhalt"
        },
        "05404": {
            "GenartName": "Tanksonde",
            "NormTermName": "Sonde",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Tankinhalt"
        },
        "05405": {
            "GenartName": "Messgerät, Bremsverzögerung",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsverzögerung"
        },
        "05406": {
            "GenartName": "Hecktieferlegung",
            "NormTermName": "Hecktieferlegung",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Zweirad"
        },
        "05407": {
            "GenartName": "Heckhöherlegung",
            "NormTermName": "Heckhöherlegung",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Zweirad"
        },
        "05408": {
            "GenartName": "Kettenschutz",
            "NormTermName": "Kettenschutz",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Zweirad"
        },
        "05409": {
            "GenartName": "Kupplungshebel",
            "NormTermName": "Hebel",
            "AssGrpName": "Kupplung",
            "UsageName": "Zweirad"
        },
        "05410": {
            "GenartName": "Bremshebel, Lenker",
            "NormTermName": "Hebel",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Zweirad"
        },
        "05411": {
            "GenartName": "Prüfgerät, Steuerdruck",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Steuerdruck"
        },
        "05412": {
            "GenartName": "Kraftmessgerät",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Kraftmessung"
        },
        "05413": {
            "GenartName": "Kennzeichenhalter",
            "NormTermName": "Halter",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Nummernschild (Zweirad)"
        },
        "05414": {
            "GenartName": "Prüfspitzensatz",
            "NormTermName": "Prüfspitzensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05415": {
            "GenartName": "Bunsenbrenner",
            "NormTermName": "Bunsenbrenner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05416": {
            "GenartName": "Spülsystem, Achskörper",
            "NormTermName": "Spülsystem",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Achskörper"
        },
        "05417": {
            "GenartName": "Karosserievermessungssystem",
            "NormTermName": "Karosserievermessungssystem",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserietechnik"
        },
        "05418": {
            "GenartName": "Trockensauger",
            "NormTermName": "Sauger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Trockensauger"
        },
        "05419": {
            "GenartName": "Nass-/Trockensauger",
            "NormTermName": "Sauger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Nass-/Trockensauger"
        },
        "05420": {
            "GenartName": "Bürstsauger",
            "NormTermName": "Sauger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bürstsauger"
        },
        "05421": {
            "GenartName": "Sicherheitssauger",
            "NormTermName": "Sauger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Sicherheitssauger"
        },
        "05422": {
            "GenartName": "Industriesauger",
            "NormTermName": "Sauger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Industriesauger"
        },
        "05423": {
            "GenartName": "Dampfsauger",
            "NormTermName": "Sauger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Dampfsauger"
        },
        "05424": {
            "GenartName": "Waschsauger",
            "NormTermName": "Sauger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Waschsauger"
        },
        "05425": {
            "GenartName": "Reinigungsroboter",
            "NormTermName": "Reinigungsroboter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05426": {
            "GenartName": "Lötgerät",
            "NormTermName": "Lötgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schneid-/Fügetechnik"
        },
        "05427": {
            "GenartName": "Ölkanister",
            "NormTermName": "Behälter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Öl/Fett"
        },
        "05428": {
            "GenartName": "Schleifstaubabsauganlage",
            "NormTermName": "Schleifstaubabsauganlage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lackiertechnik"
        },
        "05429": {
            "GenartName": "Schweißrauchabsauganlage",
            "NormTermName": "Schweißrauchabsauganlage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserietechnik"
        },
        "05430": {
            "GenartName": "Fasspalette",
            "NormTermName": "Fasspalette",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abfüllplätze/Auffangwannen"
        },
        "05431": {
            "GenartName": "Spanngurt, Auffangwanne",
            "NormTermName": "Spannband",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Auffangwanne"
        },
        "05432": {
            "GenartName": "Aufsatz, Auffangwanne",
            "NormTermName": "Aufsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Auffangwanne"
        },
        "05433": {
            "GenartName": "Gitterrost, Auffangwanne",
            "NormTermName": "Gitter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Auffangwanne"
        },
        "05434": {
            "GenartName": "Kannenhalter, Abfüllplätze/Auffangwannen",
            "NormTermName": "Halter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abfüllplätze/Auffangwannen"
        },
        "05435": {
            "GenartName": "Abfüllbock",
            "NormTermName": "Abfüllbock",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05436": {
            "GenartName": "Vorratstank",
            "NormTermName": "Tank",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Vorratstank"
        },
        "05437": {
            "GenartName": "Flächenschutzsystem",
            "NormTermName": "Flächenschutzsystem",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abfüllplätze/Auffangwannen"
        },
        "05438": {
            "GenartName": "Entsorgungstank",
            "NormTermName": "Tank",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Entsorgungstank"
        },
        "05439": {
            "GenartName": "Gefahrstofflagerstation",
            "NormTermName": "Gefahrstofflagerstation",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abfüllplätze/Auffangwannen"
        },
        "05440": {
            "GenartName": "Gitterrost, Gefahrstofflagerstation",
            "NormTermName": "Gitter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Gefahrstofflagerstation"
        },
        "05441": {
            "GenartName": "Haube, Gefahrstofflagerstation",
            "NormTermName": "Haube",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Gefahrstofflagerstation"
        },
        "05442": {
            "GenartName": "Verbinder, Flächenschutzsystem",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Flächenschutzsystem"
        },
        "05443": {
            "GenartName": "Auffahrrampe, Flächenschutzsystem",
            "NormTermName": "Auffahrrampe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Flächenschutzsystem"
        },
        "05444": {
            "GenartName": "Schmierstofftank",
            "NormTermName": "Tank",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schmierstofftank"
        },
        "05445": {
            "GenartName": "Saugrohr, Schmierstofftank",
            "NormTermName": "Saugrohr",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schmierstofftank"
        },
        "05446": {
            "GenartName": "Ölspender",
            "NormTermName": "Ölspender",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Mobile Spender/Fasswagen"
        },
        "05447": {
            "GenartName": "Schmierstoffspender",
            "NormTermName": "Schmierstoffspender",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Mobile Spender/Fasswagen"
        },
        "05448": {
            "GenartName": "Kontrollgerät, Tachograf",
            "NormTermName": "Kontrollgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Tachograf"
        },
        "05449": {
            "GenartName": "Energieampel",
            "NormTermName": "Energieampel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05450": {
            "GenartName": "Saugrohr, Vorratstank",
            "NormTermName": "Saugrohr",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Vorratstank"
        },
        "05451": {
            "GenartName": "Saugrohr, Entsorgungstank",
            "NormTermName": "Saugrohr",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Entsorgungstank"
        },
        "05452": {
            "GenartName": "Unterstellbock",
            "NormTermName": "Unterstellbock",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05453": {
            "GenartName": "Sammler, Abgasanlage",
            "NormTermName": "Sammler",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Zweirad"
        },
        "05454": {
            "GenartName": "Ansaugschlauch, Hochdruckreiniger",
            "NormTermName": "Schlauch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hochdruckreiniger"
        },
        "05455": {
            "GenartName": "Seitenständer",
            "NormTermName": "Seitenständer",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Zweirad"
        },
        "05456": {
            "GenartName": "Bürste, Hochdruckreiniger",
            "NormTermName": "Bürste",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hochdruckreiniger"
        },
        "05457": {
            "GenartName": "Hauptständer",
            "NormTermName": "Hauptständer",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Zweirad"
        },
        "05458": {
            "GenartName": "Flächenreiniger, Hochdruckreinigung",
            "NormTermName": "Flächenreiniger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hochdruckreiniger"
        },
        "05459": {
            "GenartName": "Verlängerungsschlauch, Hochdruckreiniger",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hochdruckreiniger"
        },
        "05460": {
            "GenartName": "Sprühpistole, Hochdruckreiniger",
            "NormTermName": "Sprühpistole",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hochdruckreiniger"
        },
        "05461": {
            "GenartName": "Dämpfereinsatz, Endschalldämpfer",
            "NormTermName": "Einsatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Endschalldämpfer (Zweirad)"
        },
        "05462": {
            "GenartName": "Strahlrohr, Hochdruckreiniger",
            "NormTermName": "Strahlrohr",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hochdruckreiniger"
        },
        "05463": {
            "GenartName": "Spiegel",
            "NormTermName": "Spiegel",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Zweirad"
        },
        "05464": {
            "GenartName": "Filter, Hochdruckreiniger",
            "NormTermName": "Filter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hochdruckreiniger"
        },
        "05465": {
            "GenartName": "Adapter, Hochdruckreiniger",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hochdruckreiniger"
        },
        "05466": {
            "GenartName": "Verlängerung, Strahlrohr-Hochdruckreiniger",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Strahlrohr-Hochdruckreiniger"
        },
        "05467": {
            "GenartName": "Düse, Flächenreiniger-Hochdruckreinigung",
            "NormTermName": "Düse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Flächenreiniger - Hochdruckreiniger"
        },
        "05468": {
            "GenartName": "Tankautomat",
            "NormTermName": "Tankautomat",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Versorgungssysteme für diverse Medien"
        },
        "05469": {
            "GenartName": "Zapfsäule",
            "NormTermName": "Zapfsäule",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Versorgungssysteme für diverse Medien"
        },
        "05470": {
            "GenartName": "Federmast, Zapfsäule",
            "NormTermName": "Federmast",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Zapfsäule"
        },
        "05471": {
            "GenartName": "Gabelbrücke",
            "NormTermName": "Gabelbrücke",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Zweirad"
        },
        "05472": {
            "GenartName": "Achsaufnahme, Vorderrad",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Achse (Zweirad)"
        },
        "05473": {
            "GenartName": "Gabelfeder",
            "NormTermName": "Feder",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Gabel (Zweirad)"
        },
        "05474": {
            "GenartName": "Standrohr",
            "NormTermName": "Standrohr",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Zweirad"
        },
        "05475": {
            "GenartName": "Tauchrohr",
            "NormTermName": "Tauchrohr",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Zweirad"
        },
        "05476": {
            "GenartName": "Stoßdämpferfeder",
            "NormTermName": "Feder",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Stoßdämpfer (Zweirad)"
        },
        "05477": {
            "GenartName": "Kette",
            "NormTermName": "Kette",
            "AssGrpName": "Radantrieb",
            "UsageName": "Zweirad"
        },
        "05478": {
            "GenartName": "Kettensatz",
            "NormTermName": "Kettensatz",
            "AssGrpName": "Radantrieb",
            "UsageName": "Zweirad"
        },
        "05479": {
            "GenartName": "Kettenrad",
            "NormTermName": "Kettenrad",
            "AssGrpName": "Radantrieb",
            "UsageName": "Zweirad"
        },
        "05480": {
            "GenartName": "Kettenritzel",
            "NormTermName": "Kettenritzel",
            "AssGrpName": "Radantrieb",
            "UsageName": "Zweirad"
        },
        "05481": {
            "GenartName": "Kettenschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Radantrieb",
            "UsageName": "Kette (Zweirad)"
        },
        "05482": {
            "GenartName": "Antriebszahnriemen (Zweirad)",
            "NormTermName": "Riemen",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebszahnriemen (Zweirad)"
        },
        "05483": {
            "GenartName": "Antriebskeilriemen, Variomatik",
            "NormTermName": "Riemen",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebskeilriemen, Variomatik (Zweirad)"
        },
        "05484": {
            "GenartName": "Trittbrett",
            "NormTermName": "Trittbrett",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Zweirad"
        },
        "05485": {
            "GenartName": "Lenkkopflager",
            "NormTermName": "Lager",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Lenkkopf (Zweirad)"
        },
        "05486": {
            "GenartName": "Spiegelglas",
            "NormTermName": "Spiegelglas",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Zweirad"
        },
        "05487": {
            "GenartName": "Differentialgetriebe",
            "NormTermName": "Getriebe",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "05488": {
            "GenartName": "Leuchteneinsatz, Bremsleuchte",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Bremsleuchte"
        },
        "05489": {
            "GenartName": "Leuchteneinsatz, Heckleuchte",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Heckleuchte"
        },
        "05490": {
            "GenartName": "Leuchteneinsatz, Blinkleuchte",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Blinkleuchte"
        },
        "05491": {
            "GenartName": "Leuchteneinsatz, Park-/Positionsleuchte",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Park-/Positionsleuchte"
        },
        "05492": {
            "GenartName": "Leuchteneinsatz, Nebelschlussleuchte",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelschlussleuchte"
        },
        "05493": {
            "GenartName": "Leuchteneinsatz, Bremsleuchte",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Bremsleuchte"
        },
        "05494": {
            "GenartName": "Leuchteneinsatz, Heckleuchte",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Heckleuchte"
        },
        "05495": {
            "GenartName": "Leuchteneinsatz, Blinkleuchte",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Blinkleuchte"
        },
        "05496": {
            "GenartName": "Leuchteneinsatz, Park-/Positionsleuchte",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Park-/Positionsleuchte"
        },
        "05497": {
            "GenartName": "Leuchteneinsatz, Nebelschlussleuchte",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelschlussleuchte"
        },
        "05498": {
            "GenartName": "Lagerung, Druckluftkompressor",
            "NormTermName": "Lagerung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "05499": {
            "GenartName": "Gebläse, Antriebsbatterie",
            "NormTermName": "Gebläse",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Antriebsbatterie"
        },
        "05500": {
            "GenartName": "Dichtringsatz, Stellelement (Exzenterwelle)",
            "NormTermName": "Dichtringsatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Stellelement (Exzenterwelle)"
        },
        "05501": {
            "GenartName": "Federring, Abgasanlage",
            "NormTermName": "Federring",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasanlage-Montage"
        },
        "05502": {
            "GenartName": "Hydrostatischer Fahrantrieb",
            "NormTermName": "Hydrostatischer Fahrantrieb",
            "AssGrpName": "Achsantrieb",
            "UsageName": null
        },
        "05503": {
            "GenartName": "Befestigungselement, Unterdruckleitung",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Unterdruckleitung"
        },
        "05504": {
            "GenartName": "Befestigungselement, Elektroleitung",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Elektroleitung"
        },
        "05505": {
            "GenartName": "Drechselbeitel",
            "NormTermName": "Beitel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drechselbeitel"
        },
        "05506": {
            "GenartName": "Kantenbeitel",
            "NormTermName": "Beitel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kantenbeitel"
        },
        "05507": {
            "GenartName": "Stechbeitel",
            "NormTermName": "Beitel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stechbeitel"
        },
        "05508": {
            "GenartName": "Innenabzieher",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Innenabzieher"
        },
        "05509": {
            "GenartName": "Hohlbeitel",
            "NormTermName": "Beitel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Hohlbeitel"
        },
        "05510": {
            "GenartName": "Außenabzieher",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Außenabzieher"
        },
        "05511": {
            "GenartName": "Innen-/Außenabzieher",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Innen-/Außenabzieher"
        },
        "05512": {
            "GenartName": "Bohrerbit",
            "NormTermName": "Bit",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bohrerbit"
        },
        "05513": {
            "GenartName": "Gewindebohrerbit",
            "NormTermName": "Bit",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gewindebohrerbit"
        },
        "05514": {
            "GenartName": "Schrauberbit",
            "NormTermName": "Bit",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schrauberbit"
        },
        "05515": {
            "GenartName": "Adapter, Entlüftungsschlauch (Bremssystementlüftung)",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Entlüftungsschlauch (Bremssystementlüftung)"
        },
        "05517": {
            "GenartName": "Drahtbürste",
            "NormTermName": "Bürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drahtbürste"
        },
        "05518": {
            "GenartName": "Schleifbürste",
            "NormTermName": "Bürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schleifbürste"
        },
        "05519": {
            "GenartName": "Drehmomentvervielfältiger",
            "NormTermName": "Drehmomentvervielfältiger",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05520": {
            "GenartName": "Oberfräse",
            "NormTermName": "Fräsmaschine",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Oberfräse"
        },
        "05521": {
            "GenartName": "Flachdübelfräse",
            "NormTermName": "Fräsmaschine",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Flachdübelfräse"
        },
        "05522": {
            "GenartName": "Kantenfräse",
            "NormTermName": "Fräsmaschine",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kantenfräse"
        },
        "05523": {
            "GenartName": "Multifunktionsfräse",
            "NormTermName": "Fräsmaschine",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Multifunktionsfräse"
        },
        "05524": {
            "GenartName": "Feinschlicht-Feile",
            "NormTermName": "Feile",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Feinschlicht-Feile"
        },
        "05525": {
            "GenartName": "Doppelschlicht-Feile",
            "NormTermName": "Feile",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Doppelschlicht-Feile"
        },
        "05526": {
            "GenartName": "Schlicht-Feile",
            "NormTermName": "Feile",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlicht-Feile"
        },
        "05527": {
            "GenartName": "Halbschlicht-Feile",
            "NormTermName": "Feile",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Halbschlicht-Feile"
        },
        "05528": {
            "GenartName": "Schrupp-Feile",
            "NormTermName": "Feile",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schrupp-Feile"
        },
        "05529": {
            "GenartName": "Grob-Feile",
            "NormTermName": "Feile",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Grob-Feile"
        },
        "05530": {
            "GenartName": "Gewindefeile",
            "NormTermName": "Feile",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gewindefeile"
        },
        "05531": {
            "GenartName": "Feilenblatt",
            "NormTermName": "Feilenblatt",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05532": {
            "GenartName": "Feilenheft",
            "NormTermName": "Feilenheft",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05533": {
            "GenartName": "Zapfenfräser",
            "NormTermName": "Fräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zapfenfräser"
        },
        "05534": {
            "GenartName": "Scheibenfräser",
            "NormTermName": "Fräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Scheibenfräser"
        },
        "05535": {
            "GenartName": "Halbrundprofilfräser",
            "NormTermName": "Fräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Halbrundprofilfräser"
        },
        "05536": {
            "GenartName": "Prismenfräser",
            "NormTermName": "Fräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Prismenfräser"
        },
        "05537": {
            "GenartName": "Walzenstirnfräser",
            "NormTermName": "Fräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Walzenstirnfräser"
        },
        "05538": {
            "GenartName": "Winkelfräser",
            "NormTermName": "Fräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelfräser"
        },
        "05539": {
            "GenartName": "Schlitzfräser",
            "NormTermName": "Fräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlitzfräser"
        },
        "05540": {
            "GenartName": "T-Nuten-Fräser",
            "NormTermName": "Fräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "T-Nuten-Fräser"
        },
        "05541": {
            "GenartName": "Schaftfräser",
            "NormTermName": "Fräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schaftfräser"
        },
        "05542": {
            "GenartName": "Langlochfräser",
            "NormTermName": "Fräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Langlochfräser"
        },
        "05543": {
            "GenartName": "Walzenfräser",
            "NormTermName": "Fräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Walzenfräser"
        },
        "05544": {
            "GenartName": "Vorschlaghammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Vorschlaghammer"
        },
        "05545": {
            "GenartName": "Schlosserhammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlosserhammer"
        },
        "05546": {
            "GenartName": "Schonhammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schonhammer"
        },
        "05547": {
            "GenartName": "Ausbeulhammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ausbeulhammer"
        },
        "05548": {
            "GenartName": "Gummihammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gummihammer"
        },
        "05549": {
            "GenartName": "Holzhammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Holzhammer"
        },
        "05550": {
            "GenartName": "Fäustel",
            "NormTermName": "Hammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Fäustel"
        },
        "05551": {
            "GenartName": "Hammerkopf",
            "NormTermName": "Hammerkopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05552": {
            "GenartName": "Hammerstiel",
            "NormTermName": "Hammerstiel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05553": {
            "GenartName": "Hammerkeil",
            "NormTermName": "Hammerkeil",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05554": {
            "GenartName": "Schmelzschweißgerät",
            "NormTermName": "Schweißgerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schmelzschweißgerät"
        },
        "05555": {
            "GenartName": "Heißluftgebläse",
            "NormTermName": "Heißluftgebläse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05556": {
            "GenartName": "Hobel",
            "NormTermName": "Hobel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05557": {
            "GenartName": "Reißnadel",
            "NormTermName": "Reißnadel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05558": {
            "GenartName": "Meißelschaber",
            "NormTermName": "Meißelschaber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05559": {
            "GenartName": "Kabelmesser",
            "NormTermName": "Messer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kabelmesser"
        },
        "05560": {
            "GenartName": "Multifunktionsmesser",
            "NormTermName": "Messer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Multifunktionsmesser"
        },
        "05561": {
            "GenartName": "Winkelmesser",
            "NormTermName": "Messlehre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelmesser"
        },
        "05562": {
            "GenartName": "Lineal",
            "NormTermName": "Lineal",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05563": {
            "GenartName": "Wasserwaage",
            "NormTermName": "Waage",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Wasserwaage"
        },
        "05564": {
            "GenartName": "Gliedermaßstab",
            "NormTermName": "Messlehre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gliedermaßstab"
        },
        "05565": {
            "GenartName": "Nivelliergerät",
            "NormTermName": "Messlehre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Nivelliergerät"
        },
        "05566": {
            "GenartName": "Mutternsprenger",
            "NormTermName": "Mutternsprenger",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05567": {
            "GenartName": "Nagler",
            "NormTermName": "Nagler",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05569": {
            "GenartName": "Lackierpinsel",
            "NormTermName": "Pinsel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lackierpinsel"
        },
        "05570": {
            "GenartName": "Reinigungspinsel",
            "NormTermName": "Pinsel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Reinigungspinsel"
        },
        "05571": {
            "GenartName": "Rührwerk",
            "NormTermName": "Rührwerk",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05572": {
            "GenartName": "Fuchsschwanz",
            "NormTermName": "Säge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Fuchsschwanz"
        },
        "05573": {
            "GenartName": "Handkreissäge",
            "NormTermName": "Säge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Handkreissäge"
        },
        "05574": {
            "GenartName": "Handbandsäge",
            "NormTermName": "Säge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Handbandsäge"
        },
        "05575": {
            "GenartName": "Handbügelsäge",
            "NormTermName": "Säge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Handbügelsäge"
        },
        "05576": {
            "GenartName": "Japansäge",
            "NormTermName": "Säge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Japansäge"
        },
        "05577": {
            "GenartName": "Kettensäge",
            "NormTermName": "Säge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kettensäge"
        },
        "05578": {
            "GenartName": "Lochsäge",
            "NormTermName": "Säge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lochsäge"
        },
        "05580": {
            "GenartName": "Schaumstoffsäge",
            "NormTermName": "Säge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schaumstoffsäge"
        },
        "05582": {
            "GenartName": "Tauchsäge",
            "NormTermName": "Säge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Tauchsäge"
        },
        "05583": {
            "GenartName": "Trockenschneider",
            "NormTermName": "Säge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Trockenschneider"
        },
        "05586": {
            "GenartName": "Drahtseilschere",
            "NormTermName": "Schere",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drahtseilschere"
        },
        "05587": {
            "GenartName": "Gehrungsschere",
            "NormTermName": "Schere",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gehrungsschere"
        },
        "05588": {
            "GenartName": "Kabelschere",
            "NormTermName": "Schere",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kabelschere"
        },
        "05589": {
            "GenartName": "Kombischere",
            "NormTermName": "Schere",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kombischere"
        },
        "05592": {
            "GenartName": "Bolzenausdreher",
            "NormTermName": "Bolzenausdreher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05593": {
            "GenartName": "Schraubenausdreher",
            "NormTermName": "Schraubenausdreher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05594": {
            "GenartName": "Doppel-Gelenkschlüssel",
            "NormTermName": "Schraubenschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gelenkschlüssel"
        },
        "05595": {
            "GenartName": "Gabelschlüssel",
            "NormTermName": "Schraubenschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gabelschlüssel"
        },
        "05596": {
            "GenartName": "Doppel-Gabelschlüssel",
            "NormTermName": "Schraubenschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Doppelgabelschlüssel"
        },
        "05597": {
            "GenartName": "Ringschlüssel",
            "NormTermName": "Schraubenschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ringschlüssel"
        },
        "05598": {
            "GenartName": "Schlagringschlüssel",
            "NormTermName": "Schraubenschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagringschlüssel"
        },
        "05599": {
            "GenartName": "Zugringschlüssel",
            "NormTermName": "Schraubenschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zugringschlüssel"
        },
        "05600": {
            "GenartName": "Starter-/Blockschlüssel",
            "NormTermName": "Schraubenschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Starter-/Blockschlüssel"
        },
        "05601": {
            "GenartName": "Spachtel",
            "NormTermName": "Spachtel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05602": {
            "GenartName": "Schaber",
            "NormTermName": "Schaber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05603": {
            "GenartName": "Splinttreiber",
            "NormTermName": "Splinttreiber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05604": {
            "GenartName": "Durchtreiber",
            "NormTermName": "Durchtreiber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05605": {
            "GenartName": "Henkellocheisen",
            "NormTermName": "Henkellocheisen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05606": {
            "GenartName": "Rohrsteckschlüssel",
            "NormTermName": "Rohrsteckschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05607": {
            "GenartName": "Kegelsenker",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kegelsenker"
        },
        "05608": {
            "GenartName": "Querlochsenker",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Querlochsenker"
        },
        "05609": {
            "GenartName": "Blechschälbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Blechschälbohrer"
        },
        "05610": {
            "GenartName": "Karosseriebohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Karosseriebohrer"
        },
        "05611": {
            "GenartName": "Doppelendbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Doppelendbohrer"
        },
        "05612": {
            "GenartName": "Holzspiralbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Spiralbohrer"
        },
        "05613": {
            "GenartName": "Maschinenholzbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Maschinenholzbohrer"
        },
        "05614": {
            "GenartName": "Aufstecksenker",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Aufstecksenker"
        },
        "05615": {
            "GenartName": "Holzschlangenbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlangenbohrer"
        },
        "05616": {
            "GenartName": "Forstnerbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Forstnerbohrer"
        },
        "05617": {
            "GenartName": "Bohrkopf",
            "NormTermName": "Bohrkopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05618": {
            "GenartName": "Werkzeugmodul",
            "NormTermName": "Werkzeugmodul",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05619": {
            "GenartName": "Ausklinkzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ausklinkzange"
        },
        "05620": {
            "GenartName": "Bolzenschneider",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bolzenschneider"
        },
        "05621": {
            "GenartName": "Drahtwirbelzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drahtwirbelzange"
        },
        "05622": {
            "GenartName": "Kneifzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kneifzange"
        },
        "05623": {
            "GenartName": "Kolbenringzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kolbenringzange"
        },
        "05624": {
            "GenartName": "Lochzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lochzange"
        },
        "05625": {
            "GenartName": "Schlagbohrmaschine",
            "NormTermName": "Schlagbohrmaschine",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05627": {
            "GenartName": "Kreuzschlüssel",
            "NormTermName": "Kreuzschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05628": {
            "GenartName": "Lötkolben",
            "NormTermName": "Lötkolben",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05629": {
            "GenartName": "Verlängerung, Steckschlüssel",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steckschlüssel"
        },
        "05630": {
            "GenartName": "Schaft, Steckschlüssel",
            "NormTermName": "Schaft",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steckschlüssel"
        },
        "05631": {
            "GenartName": "Gelenkstück, Steckschlüssel",
            "NormTermName": "Gelenkstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steckschlüssel"
        },
        "05632": {
            "GenartName": "Anschlussstutzen, Mengenteiler (Einspritzanlage)",
            "NormTermName": "Stutzen",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Mengenteiler (Einspritzanlage)"
        },
        "05633": {
            "GenartName": "Öldüse, Kolbenbodenkühlung",
            "NormTermName": "Düse",
            "AssGrpName": "Schmierung",
            "UsageName": "Kolbenbodenkühlung"
        },
        "05634": {
            "GenartName": "Hilfsrahmen/Aggregateträger",
            "NormTermName": "Rahmen",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Aufnahme Aggregate"
        },
        "05635": {
            "GenartName": "Gasfeder, Geräteraumklappe",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Geräteraumklappe"
        },
        "05636": {
            "GenartName": "Ausbeulwerkzeug",
            "NormTermName": "Ausbeulwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05637": {
            "GenartName": "Freilaufknarre",
            "NormTermName": "Knarre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Freilaufknarre"
        },
        "05638": {
            "GenartName": "Umschaltknarre",
            "NormTermName": "Knarre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Umschaltknarre"
        },
        "05639": {
            "GenartName": "Schruppmaschine",
            "NormTermName": "Schruppmaschine",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05640": {
            "GenartName": "Rahmen, Schalthebelverkleidung",
            "NormTermName": "Rahmen",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schalthebelverkleidung"
        },
        "05641": {
            "GenartName": "Dichtung, Stellelement (Exzenterwelle)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Stellelement (Exzenterwelle)"
        },
        "05642": {
            "GenartName": "Halter, Sonnenblende",
            "NormTermName": "Halter",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Sonnenblende"
        },
        "05643": {
            "GenartName": "Halter, Umschaltventil (Differentialsperre)",
            "NormTermName": "Halter",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Umschaltventil (Differentialsperre)"
        },
        "05644": {
            "GenartName": "Dichtung, Zündverteiler",
            "NormTermName": "Dichtung",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteiler"
        },
        "05645": {
            "GenartName": "Fahrzeugabdeckung",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Zubehör",
            "UsageName": "Fahrzeug"
        },
        "05646": {
            "GenartName": "Fahrzeugabdeckung",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Fahrzeug"
        },
        "05647": {
            "GenartName": "Spannbügel, Zylinderkopfhaube",
            "NormTermName": "Spannbügel",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Zylinderkopfhaube"
        },
        "05648": {
            "GenartName": "Hohlschraube, Öleinfüllstutzen",
            "NormTermName": "Schraube",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Öleinfüllstutzen"
        },
        "05649": {
            "GenartName": "Gehäuse, Lüfter (Motorkühlung)",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Kühlung",
            "UsageName": "Lüfter (Motorkühlung)"
        },
        "05650": {
            "GenartName": "Deckel, Lüftergehäuse (Motorkühlung)",
            "NormTermName": "Deckel",
            "AssGrpName": "Kühlung",
            "UsageName": "Lüftergehäuse (Motorkühlung)"
        },
        "05651": {
            "GenartName": "Betätigungsstange, Kraftstoffhahn",
            "NormTermName": "Stange",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffhahn"
        },
        "05652": {
            "GenartName": "Kraftstoffhahn",
            "NormTermName": "Kraftstoffhahn",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": null
        },
        "05653": {
            "GenartName": "Motormontagebock",
            "NormTermName": "Motormontagebock",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05654": {
            "GenartName": "Relais, Kraftstoffvorwärmer",
            "NormTermName": "Relais",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffvorwärmer"
        },
        "05655": {
            "GenartName": "Regelventil, Kraftstoffmenge (Common-Rail-System)",
            "NormTermName": "Ventil",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kraftstoffmenge (Common-Rail-System)"
        },
        "05656": {
            "GenartName": "Druckbegrenzungsventil, Common-Rail-System",
            "NormTermName": "Ventil",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Common-Rail-System (Druckbegrenzung)"
        },
        "05657": {
            "GenartName": "Scharnierlochbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Scharnierlochbohrer"
        },
        "05658": {
            "GenartName": "Scheibenschneider",
            "NormTermName": "Scheibenschneider",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05659": {
            "GenartName": "Flachfräsbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Flachfräsbohrer"
        },
        "05660": {
            "GenartName": "Schalungs-/Installationsbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schalungs-/Installationsbohrer"
        },
        "05661": {
            "GenartName": "Betonbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Betonbohrer"
        },
        "05662": {
            "GenartName": "Steinbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steinbohrer"
        },
        "05663": {
            "GenartName": "Fliesenbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Fliesenbohrer"
        },
        "05664": {
            "GenartName": "Mehrzweckbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Mehrzweckbohrer"
        },
        "05665": {
            "GenartName": "Monierzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Monierzange"
        },
        "05666": {
            "GenartName": "Rohr-/Wasserpumpenzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohr-/Wasserpumpenzange"
        },
        "05667": {
            "GenartName": "Schlauchklemmenzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlauchklemmenzange"
        },
        "05668": {
            "GenartName": "Scherengelenkzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Scherengelenkzange"
        },
        "05669": {
            "GenartName": "Vornschneider",
            "NormTermName": "Vornschneider",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05670": {
            "GenartName": "Verbindungsstück, Bremsleitung",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremsleitung"
        },
        "05671": {
            "GenartName": "Wuchtgewichtezange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Wuchtgewichtezange"
        },
        "05672": {
            "GenartName": "Sicherungsringzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Sicherungsringzange"
        },
        "05673": {
            "GenartName": "Speicherkarte",
            "NormTermName": "Datenspeicher",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Speicherkarte"
        },
        "05674": {
            "GenartName": "Speicherkartenlesegerät",
            "NormTermName": "Lesegerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Speicherkarte"
        },
        "05675": {
            "GenartName": "Membranfeder, Kupplung",
            "NormTermName": "Membranfeder",
            "AssGrpName": "Kupplung",
            "UsageName": "Zweirad"
        },
        "05676": {
            "GenartName": "Zündleitung",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Zündanlage, Universal",
            "UsageName": null
        },
        "05677": {
            "GenartName": "Versteifungsscheibe, Kupplung",
            "NormTermName": "Versteifungsscheibe",
            "AssGrpName": "Kupplung",
            "UsageName": "Zweirad"
        },
        "05678": {
            "GenartName": "Lackierkabine",
            "NormTermName": "Lackierkabine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05679": {
            "GenartName": "Fließbecher",
            "NormTermName": "Fließbecher",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lackiertechnik"
        },
        "05680": {
            "GenartName": "Dosiermodul, Ruß-/Partikelfilterregeneration",
            "NormTermName": "Dosiermodul",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Ruß-/Partikelfilterregeneration"
        },
        "05681": {
            "GenartName": "Einspritzeinheit, Ruß-/Partikelfilterregeneration",
            "NormTermName": "Einspritzeinheit",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Ruß-/Partikelfilterregeneration"
        },
        "05682": {
            "GenartName": "Schaltwegverkürzung",
            "NormTermName": "Schaltwegverkürzung",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "05683": {
            "GenartName": "Kupplungsaktuator",
            "NormTermName": "Kupplungsaktuator",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "05684": {
            "GenartName": "Relais, Kühlerlüfter",
            "NormTermName": "Relais",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlerlüfter"
        },
        "05685": {
            "GenartName": "Öl, Lamellenkupplung-Allradantrieb",
            "NormTermName": "Öl",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Lamellenkupplung-Allradantrieb"
        },
        "05686": {
            "GenartName": "Öl, Lamellenkupplung-Allradantrieb",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Lamellenkupplung-Allradantrieb"
        },
        "05687": {
            "GenartName": "Leiter, Dachträger (Heckklappe)",
            "NormTermName": "Leiter",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Dachträger"
        },
        "05688": {
            "GenartName": "Relais, Niveauregulierung",
            "NormTermName": "Relais",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Niveauregulierung"
        },
        "05689": {
            "GenartName": "Anpressplatte, Kupplung",
            "NormTermName": "Anpressplatte",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "05690": {
            "GenartName": "Entriegelungswerkzeugsatz",
            "NormTermName": "Sortiment",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05691": {
            "GenartName": "Felge",
            "NormTermName": "Felge",
            "AssGrpName": "Räder, Universal",
            "UsageName": null
        },
        "05692": {
            "GenartName": "Komplettrad",
            "NormTermName": "Komplettrad",
            "AssGrpName": "Räder",
            "UsageName": null
        },
        "05693": {
            "GenartName": "Komplettrad",
            "NormTermName": "Komplettrad",
            "AssGrpName": "Räder, Universal",
            "UsageName": null
        },
        "05694": {
            "GenartName": "Radbolzen",
            "NormTermName": "Bolzen",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Rad"
        },
        "05695": {
            "GenartName": "Radschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Räder, Universal",
            "UsageName": null
        },
        "05696": {
            "GenartName": "Radmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Rad"
        },
        "05697": {
            "GenartName": "Kappe, Radmutter",
            "NormTermName": "Kappe",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Radmutter"
        },
        "05698": {
            "GenartName": "Halter, Radzierblende",
            "NormTermName": "Halter",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Radzierblende"
        },
        "05699": {
            "GenartName": "Schalter, Liftachsbetätigung",
            "NormTermName": "Schalter",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Liftachsbetätigung"
        },
        "05700": {
            "GenartName": "Amperezange",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Strommessung"
        },
        "05701": {
            "GenartName": "Schweißgerät",
            "NormTermName": "Schweißgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schneid-/Fügetechnik"
        },
        "05702": {
            "GenartName": "Drahtvorschubgerät",
            "NormTermName": "Drahtvorschubgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schneid-/Fügetechnik"
        },
        "05703": {
            "GenartName": "Bedieneinheit, Hebebühne",
            "NormTermName": "Bedieneinheit",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebebühne"
        },
        "05704": {
            "GenartName": "Auffahrrampe, Hebebühne",
            "NormTermName": "Auffahrrampe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebebühne"
        },
        "05705": {
            "GenartName": "Auffahrklappe, Hebebühne",
            "NormTermName": "Auffahrklappe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebebühne"
        },
        "05706": {
            "GenartName": "Säulenverlängerung, Hebebühne",
            "NormTermName": "Säulenverlängerung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebebühne"
        },
        "05707": {
            "GenartName": "Bodenniveauausgleich, Hebebühne",
            "NormTermName": "Bodenniveauausgleich",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebebühne"
        },
        "05708": {
            "GenartName": "Schlauchleitung, Ruß-/Partikelfilterregeneration",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Ruß-/Partikelfilterregeneration"
        },
        "05709": {
            "GenartName": "Schraube, Starter",
            "NormTermName": "Schraube",
            "AssGrpName": "Startanlage",
            "UsageName": "Starter"
        },
        "05710": {
            "GenartName": "Zylindersatz, Motor",
            "NormTermName": "Zylindersatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Motor"
        },
        "05711": {
            "GenartName": "Gehäusedeckel, Ölpumpe",
            "NormTermName": "Deckel",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "05713": {
            "GenartName": "Kunststoffschere",
            "NormTermName": "Schere",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kunststoff"
        },
        "05714": {
            "GenartName": "Satinierer",
            "NormTermName": "Satinierer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05715": {
            "GenartName": "Anschlussflansch, Ölkühler",
            "NormTermName": "Flansch",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölkühler"
        },
        "05717": {
            "GenartName": "Abdeckung, Keilriemen",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilriemen"
        },
        "05718": {
            "GenartName": "Dämpfereinsatz, Sportschalldämpfer",
            "NormTermName": "Einsatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Sportschalldämpfer"
        },
        "05719": {
            "GenartName": "Absperrklappe, Heizungsregulierung",
            "NormTermName": "Klappe",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizungsregulierung"
        },
        "05720": {
            "GenartName": "Bedienstift, Touchscreen",
            "NormTermName": "Bedienstift",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Touchscreen"
        },
        "05721": {
            "GenartName": "Elektroleitung, Schweißgerät",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schweißgerät"
        },
        "05722": {
            "GenartName": "Steuergerät, Batteriemanagement",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Bordnetz, Universal",
            "UsageName": "Batteriemanagement"
        },
        "05723": {
            "GenartName": "ABS-Kontrollleuchte",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "ABS-Kontrollleuchte"
        },
        "05724": {
            "GenartName": "Anschlussset, Seitenmarkierungsleuchte",
            "NormTermName": "Anschlussset",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "05725": {
            "GenartName": "Zierblende, Schlussleuchte",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Schlussleuchte"
        },
        "05726": {
            "GenartName": "Zierblende, Blinkleuchte",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Blinkleuchte"
        },
        "05727": {
            "GenartName": "Zierblende, Rückfahrleuchte",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Rückfahrleuchte"
        },
        "05728": {
            "GenartName": "Blende, Nebelschlussleuchte",
            "NormTermName": "Blende",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelschlussleuchte"
        },
        "05729": {
            "GenartName": "Lampenträger, Begrenzungsleuchte",
            "NormTermName": "Lampenträger",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Begrenzungsleuchte"
        },
        "05731": {
            "GenartName": "Achsmessstütze, Hebebühne",
            "NormTermName": "Achsmessstütze",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebebühne"
        },
        "05732": {
            "GenartName": "Aufnahmeerweiterung, Hebebühne",
            "NormTermName": "Aufnahmeerweiterung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebebühne"
        },
        "05733": {
            "GenartName": "Anbauelement, Hebebühne",
            "NormTermName": "Anbauelement",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebebühne"
        },
        "05734": {
            "GenartName": "Werkzeug-Ablageschale",
            "NormTermName": "Ablageschale",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Werkzeug"
        },
        "05735": {
            "GenartName": "Erweiterungssatz, Lecksuche-Set (Klimaanlage)",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lecksuche-Set (Klimaanlage)"
        },
        "05736": {
            "GenartName": "Pedalwegsensor, Kupplungspedal",
            "NormTermName": "Sensor",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungspedal"
        },
        "05737": {
            "GenartName": "Reparatursatz, Schaltgetriebe",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "05738": {
            "GenartName": "Einbaurahmen, Scheren-Hebebühne",
            "NormTermName": "Einbaurahmen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebebühne"
        },
        "05739": {
            "GenartName": "Einbaurahmen, Säulen-Hebebühne",
            "NormTermName": "Einbaurahmen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Säulen-Hebebühne"
        },
        "05740": {
            "GenartName": "Fahrwerkvermessungssatz, Hebebühne",
            "NormTermName": "Fahrwerkvermessungssatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrzeugvermessungstechnik"
        },
        "05741": {
            "GenartName": "Rahmenkopf",
            "NormTermName": "Rahmenkopf",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "05742": {
            "GenartName": "Anschlagpuffer, Achskörper",
            "NormTermName": "Puffer",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achskörper"
        },
        "05743": {
            "GenartName": "Haltefeder, Ausrücklager",
            "NormTermName": "Feder",
            "AssGrpName": "Kupplung",
            "UsageName": "Ausrücklager"
        },
        "05744": {
            "GenartName": "Stiftschraube, Drehstab",
            "NormTermName": "Schraube",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Drehstab"
        },
        "05745": {
            "GenartName": "Kupplungshebel",
            "NormTermName": "Hebel",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "05746": {
            "GenartName": "Führung, Schaltstange",
            "NormTermName": "Führung",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltstange"
        },
        "05747": {
            "GenartName": "Faltenbalg, Schaltstange",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltstange"
        },
        "05748": {
            "GenartName": "Staubschutzkappe, Gabel",
            "NormTermName": "Kappe",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Gabel (Zweirad)"
        },
        "05749": {
            "GenartName": "Dichtring, Gabel",
            "NormTermName": "Dichtring",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Gabel (Zweirad)"
        },
        "05750": {
            "GenartName": "Sensor, Luftgüte",
            "NormTermName": "Sensor",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Luftgüte"
        },
        "05751": {
            "GenartName": "Schraube, Schaltgabel",
            "NormTermName": "Schraube",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgabel"
        },
        "05752": {
            "GenartName": "Führung, Schalthebel",
            "NormTermName": "Führung",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schalthebel"
        },
        "05753": {
            "GenartName": "Nachlaufschale, Achskörper",
            "NormTermName": "Nachlaufschale",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achskörper"
        },
        "05754": {
            "GenartName": "Halteklammer, Anschlagpuffer (Achskörper)",
            "NormTermName": "Halteklammer",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Anschlagpuffer (Achskörper)"
        },
        "05755": {
            "GenartName": "Deckel, Rahmenkopf",
            "NormTermName": "Deckel",
            "AssGrpName": "Karosserie",
            "UsageName": "Rahmenkopf"
        },
        "05756": {
            "GenartName": "Buchse, Spurstangenkopf (Lenkungsdämpferbefestigung)",
            "NormTermName": "Buchse",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstangenkopf (Lenkungsdämpferbefestigung)"
        },
        "05757": {
            "GenartName": "Leuchteneinsatz, Brems-/ Schlussleuchte",
            "NormTermName": "Einsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Brems-/Schlusslicht"
        },
        "05759": {
            "GenartName": "Wendelschlauch",
            "NormTermName": "Wendelschlauch",
            "AssGrpName": "Druckluftanlage, Universal",
            "UsageName": null
        },
        "05760": {
            "GenartName": "Halter, Zusatzwasserpumpe",
            "NormTermName": "Halter",
            "AssGrpName": "Kühlung",
            "UsageName": "Zusatzwasserpumpe"
        },
        "05761": {
            "GenartName": "Ladekabel, Elektrofahrzeug",
            "NormTermName": "Ladekabel",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Elektrofahrzeug"
        },
        "05762": {
            "GenartName": "Universalbehälter",
            "NormTermName": "Behälter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lager- und Betriebsausstattung"
        },
        "05763": {
            "GenartName": "Steuergerät, Kraftstoffpumpe",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "05764": {
            "GenartName": "Reparatursatz, Waschwasserleitung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": "Waschwasserleitung"
        },
        "05765": {
            "GenartName": "Kompressor, Druckluftanlage",
            "NormTermName": "Kompressor",
            "AssGrpName": "Druckluftanlage, Universal",
            "UsageName": "Druckluft"
        },
        "05766": {
            "GenartName": "Ventil, Druckluftanlage",
            "NormTermName": "Ventil",
            "AssGrpName": "Druckluftanlage, Universal",
            "UsageName": "Druckluft"
        },
        "05767": {
            "GenartName": "Display, Eigendiagnose-Gerät",
            "NormTermName": "Display",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Eigendiagnose-Gerät"
        },
        "05768": {
            "GenartName": "Ablageschale, Stempel-Hebebühne",
            "NormTermName": "Ablageschale",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Stempel-Hebebühne"
        },
        "05769": {
            "GenartName": "Feldsonde, Oszilloskop",
            "NormTermName": "Sonde",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Oszilloskop"
        },
        "05770": {
            "GenartName": "Lochbeitel",
            "NormTermName": "Beitel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05771": {
            "GenartName": "Flachsenker",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05772": {
            "GenartName": "Stufenbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stufenbohrer"
        },
        "05773": {
            "GenartName": "Zapfenschneider",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zapfenschneider"
        },
        "05774": {
            "GenartName": "Metallspiralbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Metallspiralbohrer"
        },
        "05775": {
            "GenartName": "Drillbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drillbohrer"
        },
        "05776": {
            "GenartName": "Druckregulierdüse",
            "NormTermName": "Düse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Druckregulierdüse, Drucklufttechnik"
        },
        "05777": {
            "GenartName": "Standarddüse",
            "NormTermName": "Düse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Standarddüse, Drucklufttechnik"
        },
        "05778": {
            "GenartName": "Latthammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Latthammer"
        },
        "05779": {
            "GenartName": "Maurerhammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Maurerhammer"
        },
        "05780": {
            "GenartName": "Lötpistole",
            "NormTermName": "Lötpistole",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05781": {
            "GenartName": "Lötspitze",
            "NormTermName": "Lötspitze",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05783": {
            "GenartName": "Schutzbacken, Schraubstock",
            "NormTermName": "Schutzbacken",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schraubstock"
        },
        "05784": {
            "GenartName": "Drehteller, Schraubstock",
            "NormTermName": "Drehteller",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schraubstock"
        },
        "05785": {
            "GenartName": "Sitzgestell",
            "NormTermName": "Sitzgestell",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "05786": {
            "GenartName": "Sortiment, Abgasdrucksensor",
            "NormTermName": "Sortiment",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Abgasdrucksensor"
        },
        "05787": {
            "GenartName": "Sortiment, Abgasdrucksensor",
            "NormTermName": "Sortiment",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Abgasdrucksensor"
        },
        "05788": {
            "GenartName": "Abschlagdorn, Nockenwellenzahnrad",
            "NormTermName": "Abschlagdorn",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwellenzahnrad"
        },
        "05789": {
            "GenartName": "Abstützvorrichtung, Getriebeheber",
            "NormTermName": "Abstützvorrichtung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Getriebeheber"
        },
        "05790": {
            "GenartName": "Achsmutterschlüssel",
            "NormTermName": "Achsmutter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Achsmutter"
        },
        "05791": {
            "GenartName": "Aufpressdornsatz, Ventilschaftdichtung",
            "NormTermName": "Aufpressdornsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ventilschaftdichtung"
        },
        "05792": {
            "GenartName": "Halter, Messuhr",
            "NormTermName": "Halter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Messuhr"
        },
        "05793": {
            "GenartName": "Druckplattensatz, Federspanner",
            "NormTermName": "Druckplattensatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federspanner"
        },
        "05794": {
            "GenartName": "Einsatz-Halter, Steckschlüssel",
            "NormTermName": "Halter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steckschlüsseleinsatz"
        },
        "05795": {
            "GenartName": "Schneidrad, Rohrschneider",
            "NormTermName": "Schneidrad",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrschneider"
        },
        "05796": {
            "GenartName": "Fräswerkzeugsatz, Glühkerze",
            "NormTermName": "Fräswerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerze"
        },
        "05797": {
            "GenartName": "Drehmomentbegrenzer",
            "NormTermName": "Drehmomentbegrenzer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05798": {
            "GenartName": "Gewindeschneidwerkzeugsatz, Zündkerze",
            "NormTermName": "Gewindeschneidwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zündkerze"
        },
        "05799": {
            "GenartName": "Ausziehersatz, Glühkerzenstift",
            "NormTermName": "Ausziehersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerzenstift"
        },
        "05801": {
            "GenartName": "Kotflügelschoner",
            "NormTermName": "Kotflügelschoner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05802": {
            "GenartName": "Abzieher, Lenkstockhebel",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Lenkstockhebel"
        },
        "05803": {
            "GenartName": "Honwerkzeug",
            "NormTermName": "Hohnwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05804": {
            "GenartName": "Bremsleitungs-Schlüssel",
            "NormTermName": "Doppel-Ringschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsleitung"
        },
        "05805": {
            "GenartName": "Honstein-Satz",
            "NormTermName": "Honstein-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05806": {
            "GenartName": "Messschieber, Trommelbremse",
            "NormTermName": "Messschieber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Trommelbremse"
        },
        "05807": {
            "GenartName": "Nachstellhebel, Bremsbacke",
            "NormTermName": "Nachstellhebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsbacke"
        },
        "05808": {
            "GenartName": "Schleifbandträger",
            "NormTermName": "Schleifbandträger",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05809": {
            "GenartName": "Demontagewerkzeug, Bremsleuchte",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsleuchte"
        },
        "05810": {
            "GenartName": "Lösehebel, Türverkleidung",
            "NormTermName": "Lösehebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Türverkleidung"
        },
        "05811": {
            "GenartName": "Abzieher, Lenkrad",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lenkrad"
        },
        "05812": {
            "GenartName": "Entriegelungswerkzeug, Lenkschlossabdeckung",
            "NormTermName": "Entriegelungswerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lenkschloss"
        },
        "05813": {
            "GenartName": "Entriegelungswerkzeug, Airbag",
            "NormTermName": "Entriegelungswerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Airbag"
        },
        "05814": {
            "GenartName": "Entriegelungswerkzeugsatz, Radio",
            "NormTermName": "Entriegelungswerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Radio-CD"
        },
        "05816": {
            "GenartName": "Schneiddraht-Set, Scheibendemontage",
            "NormTermName": "Schneidedraht-Set",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Scheibendemontage"
        },
        "05817": {
            "GenartName": "Winkelschlüssel, Schrauberbit",
            "NormTermName": "Winkelschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05818": {
            "GenartName": "Lösezange, Türverkleidung",
            "NormTermName": "Lösezange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Türverkleidung"
        },
        "05819": {
            "GenartName": "Entriegelungswerkzeugsatz, Zündschloss",
            "NormTermName": "Entriegelungswerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zündschloss"
        },
        "05820": {
            "GenartName": "Abzieher, Lenkrad-Distanzhülse",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lenkrad-Distanzhülse"
        },
        "05821": {
            "GenartName": "Einstellschlüssel, Frontscheinwerfer",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Frontscheinwerfer"
        },
        "05822": {
            "GenartName": "Einstellwerkzeug, Scheibenwaschwasserdüse",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Scheibenwaschwasserdüse"
        },
        "05823": {
            "GenartName": "Montagewerkzeug, Scheibendichtung",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Scheibendichtung"
        },
        "05824": {
            "GenartName": "Zange, Polsterklammern",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Polsterklammern"
        },
        "05825": {
            "GenartName": "Lösewerkzeug, Türgriff-/Fensterkurbelsicherung",
            "NormTermName": "Lösewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Türgriff-/Fensterkurbelsicherung"
        },
        "05826": {
            "GenartName": "Fühlerlehre",
            "NormTermName": "Lehre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05827": {
            "GenartName": "Krallengreifer",
            "NormTermName": "Krallengreifer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05828": {
            "GenartName": "Gewinderollensatz",
            "NormTermName": "Gewinderollensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05829": {
            "GenartName": "Magnetisier-/ Entmagnetisier-Block",
            "NormTermName": "Magnetisier-/ Entmagnetisier-Block",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05830": {
            "GenartName": "Bügelmessschraube",
            "NormTermName": "Bügelmessschraube",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05831": {
            "GenartName": "Pinzettensatz",
            "NormTermName": "Pinzettensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05832": {
            "GenartName": "Schraubwerkzeug-Set",
            "NormTermName": "Schraubwerkzeug-Set",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05833": {
            "GenartName": "Montagehebel",
            "NormTermName": "Montagehebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05834": {
            "GenartName": "Werkstattspiegel",
            "NormTermName": "Werkstattspiegel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05835": {
            "GenartName": "Trennsteg, Schubfach (Werkzeugwagen)",
            "NormTermName": "Trennsteg",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schubfach (Werkzeugwagen)"
        },
        "05836": {
            "GenartName": "Aufbewahrungskasten, Haarlineal",
            "NormTermName": "Aufbewahrungskasten",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Haarlineal"
        },
        "05837": {
            "GenartName": "Haken-Werkzeug-Satz",
            "NormTermName": "Montagehilfe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Hakensatz"
        },
        "05838": {
            "GenartName": "Montagehebel-Satz",
            "NormTermName": "Montagehebel-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05839": {
            "GenartName": "Magnetheber",
            "NormTermName": "Magnetheber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05840": {
            "GenartName": "Steckschlüsselsatz",
            "NormTermName": "Steckschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "05841": {
            "GenartName": "Zangensatz, Sicherungsringe",
            "NormTermName": "Zangensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Sicherungsring"
        },
        "05842": {
            "GenartName": "Montagewerkzeugsatz, Scheibendichtung",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Scheibendichtung"
        },
        "05843": {
            "GenartName": "Arretierdorn, Nockenwelle",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Nockenwelle"
        },
        "05844": {
            "GenartName": "Arretierwerkzeug, Nockenwelle",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Nockenwelle"
        },
        "05845": {
            "GenartName": "Zange, Zündkerzenstecker",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zündkerzenstecker"
        },
        "05846": {
            "GenartName": "Prüfwerkzeug, Bremsleitung",
            "NormTermName": "Prüfwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsleitung"
        },
        "05847": {
            "GenartName": "Adapter, Bördelgerät",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bördelgerät"
        },
        "05848": {
            "GenartName": "Adapter, Schlagabzieher",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagabzieher"
        },
        "05849": {
            "GenartName": "Adapter, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "05850": {
            "GenartName": "Adaptersatz, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "05851": {
            "GenartName": "Demontagewerkzeug, Dichtring",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Dichtring"
        },
        "05852": {
            "GenartName": "Spindel, Abzieher",
            "NormTermName": "Spindel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abzieher"
        },
        "05853": {
            "GenartName": "Spindelsatz, Abzieher",
            "NormTermName": "Spindelsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abzieher"
        },
        "05854": {
            "GenartName": "Montagewerkzeug, Lüfter (Flüssigkeitsreibungskupplung)",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Lüfter (Flüssigkeitsreibungskupplung)"
        },
        "05855": {
            "GenartName": "Schutzeinlagensatz, Druckplatte-Federspanner",
            "NormTermName": "Schutzmittel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federspanner"
        },
        "05856": {
            "GenartName": "Druckplattensatz, Federspanner",
            "NormTermName": "Druckplattensatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federspanner"
        },
        "05857": {
            "GenartName": "Zange, Glühlampe",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glühlampe"
        },
        "05858": {
            "GenartName": "Ausdrückersatz, Kugelgelenk",
            "NormTermName": "Ausdrückersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kugelgelenk"
        },
        "05859": {
            "GenartName": "Abzieher, Lenkstockhebel",
            "NormTermName": "Abzieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Lenkstockhebel"
        },
        "05860": {
            "GenartName": "Abzieher, Einspritzpumpenzahnrad",
            "NormTermName": "Abzieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzpumpenzahnrad"
        },
        "05861": {
            "GenartName": "Auszieher, Gleitschienenbolzen",
            "NormTermName": "Auszieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Gleitschienenbolzen"
        },
        "05862": {
            "GenartName": "Abzieher, Zahnrad-Kurbelwelle",
            "NormTermName": "Abzieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zahnrad-Kurbelwelle"
        },
        "05863": {
            "GenartName": "Montagevorrichtung, Motor-/Getriebeeinheit",
            "NormTermName": "Montagevorrichtung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Motor-/Getriebeeinheit"
        },
        "05864": {
            "GenartName": "Adapter, Montagevorrichtung (Motor-/Getriebeeinheit)",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Montagevorrichtung (Motor-/Getriebeeinheit)"
        },
        "05865": {
            "GenartName": "Arretierdorn, Kurbelwelle",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kurbelwelle"
        },
        "05866": {
            "GenartName": "Arretierdorn, Schwungrad",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Schwungrad"
        },
        "05867": {
            "GenartName": "Arretierwerkzeug, Kurbelwelle",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kurbelwelle"
        },
        "05868": {
            "GenartName": "Arretierwerkzeugsatz, Steuerzeiten",
            "NormTermName": "Arretierwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Steuerzeiten"
        },
        "05869": {
            "GenartName": "Zange, Glühkerzenstecker",
            "NormTermName": "Zange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Glühkerzenstecker"
        },
        "05870": {
            "GenartName": "Kraftstofffilterschlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kraftstofffilter"
        },
        "05871": {
            "GenartName": "Adapter, Kühlsystemdruckprüfset",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kühlsystemdruckprüfset"
        },
        "05872": {
            "GenartName": "Schlauchverbindersatz, Drucksensor (Ruß-/Partikelfilter)",
            "NormTermName": "Verbindungselementsatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Drucksensor (Ruß-/Partikelfilter)"
        },
        "05873": {
            "GenartName": "Gabelöl",
            "NormTermName": "Öl",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Gabelöl (Zweirad)"
        },
        "05874": {
            "GenartName": "Montagering, Staubschutzkappe (Bremssattelkolben)",
            "NormTermName": "Montage-/Demontagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Staubschutzkappe (Bremssattelkolben)"
        },
        "05875": {
            "GenartName": "Knieunterlage",
            "NormTermName": "Knieunterlage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05876": {
            "GenartName": "Befestigungssockel, Kabelbinder",
            "NormTermName": "Befestigungssockel",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "05877": {
            "GenartName": "Leitlack",
            "NormTermName": "Leitlack",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05878": {
            "GenartName": "Verschlussschraube, Drehmomentwandler",
            "NormTermName": "Schraube",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Drehmomentwandler"
        },
        "05879": {
            "GenartName": "Dichtring, Wellrohr",
            "NormTermName": "Dichtring",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Wellrohr"
        },
        "05880": {
            "GenartName": "Lichtscheibe, ABS-Kontrollleuchte",
            "NormTermName": "Lichtscheibe",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "ABS-Kontrollleuchte"
        },
        "05881": {
            "GenartName": "OBD-Adapter, Reifendruck-Kontrollsystem",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "05882": {
            "GenartName": "Drallklappensatz, Saugrohr",
            "NormTermName": "Drallklappensatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Saugrohr"
        },
        "05883": {
            "GenartName": "Schalter, Heckklappenentriegelung",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Heckklappenentriegelung"
        },
        "05884": {
            "GenartName": "Schalter, Coming-Home Funktion",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Coming-Home Funktion"
        },
        "05885": {
            "GenartName": "Blinddeckel, Schalter",
            "NormTermName": "Deckel",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Schalter"
        },
        "05886": {
            "GenartName": "Aufnahme, Hubtisch",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hubtisch"
        },
        "05887": {
            "GenartName": "Rohrschneider, Abgasanlage",
            "NormTermName": "Rohrschneider",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Abgasrohr"
        },
        "05888": {
            "GenartName": "Schneidkette, Rohrschneider-Abgasanlage",
            "NormTermName": "Schneidkette",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Rohrschneider-Abgasanlage"
        },
        "05889": {
            "GenartName": "Aufweiter, Abgasrohr",
            "NormTermName": "Aufweiter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Abgasrohr"
        },
        "05890": {
            "GenartName": "Trennwerkzeugsatz, Abgasrohr",
            "NormTermName": "Trennwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Abgasrohr"
        },
        "05891": {
            "GenartName": "Schneidrad, Rohrschneider-Abgasanlage",
            "NormTermName": "Schneidrad",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Rohrschneider-Abgasanlage"
        },
        "05892": {
            "GenartName": "Aufweitsatz, Abgasrohr",
            "NormTermName": "Aufweitsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Abgasrohr"
        },
        "05893": {
            "GenartName": "Adapter, Federspanner",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federspanner"
        },
        "05894": {
            "GenartName": "Halterung, Federspanner",
            "NormTermName": "Halterung",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federspanner"
        },
        "05895": {
            "GenartName": "Adapter, Druckplatte-Federspanner",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Druckplatte-Federspanner"
        },
        "05896": {
            "GenartName": "Zapfenschlüsselsatz, Federbein",
            "NormTermName": "Zapfenschlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federbein"
        },
        "05897": {
            "GenartName": "Haltewerkzeug, Federbein",
            "NormTermName": "Haltewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federbein"
        },
        "05898": {
            "GenartName": "Spreizhebel, Trag-/Führungsgelenkaufnahme",
            "NormTermName": "Spreizhebel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Trag-/Führungsgelenkaufnahme"
        },
        "05899": {
            "GenartName": "Spreizsteckschlüsseleinsatz, Trag-/Führungsgelenkaufnahme",
            "NormTermName": "Spreizsteckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Trag-/Führungsgelenkaufnahme"
        },
        "05900": {
            "GenartName": "Ölfilterzange",
            "NormTermName": "Zange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ölfilter"
        },
        "05901": {
            "GenartName": "Service-Messstab, Ölwechsel",
            "NormTermName": "Messgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ölwechsel"
        },
        "05902": {
            "GenartName": "Druckleitung, Drucksensor (Ruß-/Partikelfilter)",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Drucksensor (Ruß-/Partikelfilter)"
        },
        "05903": {
            "GenartName": "Schlauchverbindersatz, Drucksensor (Ruß-/Partikelfilter)",
            "NormTermName": "Verbindungselementsatz",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Drucksensor (Ruß-/Partikelfilter)"
        },
        "05904": {
            "GenartName": "Abdeckhaube Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "05905": {
            "GenartName": "Break-out Box",
            "NormTermName": "Break-out Box",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "05906": {
            "GenartName": "Adapter, Break-out Box",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Break-out Box"
        },
        "05907": {
            "GenartName": "Einschweißgewinde, Drucksensor (Ruß-/Partikelfilter)",
            "NormTermName": "Einschweißgewinde",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Drucksensor (Ruß-/Partikelfilter)"
        },
        "05908": {
            "GenartName": "Einschweißgewinde, Abgastemperatursensor",
            "NormTermName": "Einschweißgewinde",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Abgastemperatursensor"
        },
        "05909": {
            "GenartName": "Druckstück, Getriebelager",
            "NormTermName": "Druckstück",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montage Getriebelager"
        },
        "05910": {
            "GenartName": "Druckplatte, Getriebelager",
            "NormTermName": "Druckplatte",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montage Getriebelager"
        },
        "05911": {
            "GenartName": "Führungsbolzen, Schaltung",
            "NormTermName": "Führungsbolzen",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "05912": {
            "GenartName": "Reparatursatz, Lichtwellenleiter",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Lichtwellenleiter"
        },
        "05913": {
            "GenartName": "Gehäuse, Zusatzblinkleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Signalanlage",
            "UsageName": "Zusatzblinkleuchte"
        },
        "05914": {
            "GenartName": "Gehäuse, Zusatzblinkleuchte",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Zusatzblinkleuchte"
        },
        "05915": {
            "GenartName": "Gitter, Rundumkennleuchte",
            "NormTermName": "Gitter",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Rundumkennleuchte"
        },
        "05916": {
            "GenartName": "Rahmen, Innenraumleuchte",
            "NormTermName": "Rahmen",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Innenraumleuchte"
        },
        "05917": {
            "GenartName": "Rahmen, Innenraumleuchte",
            "NormTermName": "Rahmen",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Innenraumleuchte"
        },
        "05918": {
            "GenartName": "Befüllgerät, Getriebeöl",
            "NormTermName": "Befüllgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Getriebeöl"
        },
        "05919": {
            "GenartName": "Schalter, Abblendlicht",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Abblendlicht"
        },
        "05920": {
            "GenartName": "Schalter, Allradantrieb",
            "NormTermName": "Schalter",
            "AssGrpName": "Achsantrieb, Universal",
            "UsageName": "Allradantrieb"
        },
        "05921": {
            "GenartName": "Schalter, Arbeitsscheinwerfer",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "05922": {
            "GenartName": "Schalter, Begrenzungsleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Begrenzungsleuchte"
        },
        "05923": {
            "GenartName": "Schalter, Fernscheinwerfer",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Fernscheinwerfer"
        },
        "05924": {
            "GenartName": "Schalter, Feststellbremsbetätigung",
            "NormTermName": "Schalter",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Feststellbremsbetätigung"
        },
        "05925": {
            "GenartName": "Schalter, Hupe",
            "NormTermName": "Schalter",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Hupe"
        },
        "05926": {
            "GenartName": "Schalter, Horn",
            "NormTermName": "Schalter",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Horn"
        },
        "05927": {
            "GenartName": "Schalter, Wisch-Wasch-Intervall",
            "NormTermName": "Schalter",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": "Wisch-Wasch-Intervall"
        },
        "05928": {
            "GenartName": "Schalter, Parkleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Parkleuchte"
        },
        "05930": {
            "GenartName": "Schalter, Standlicht",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Standlicht"
        },
        "05931": {
            "GenartName": "Start-/Stop-Knopf",
            "NormTermName": "Start-/Stop-Knopf",
            "AssGrpName": "Startanlage, Universal",
            "UsageName": null
        },
        "05932": {
            "GenartName": "Lagerschale, Luftfederbalgträger",
            "NormTermName": "Lagerschale",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederbalgträger"
        },
        "05934": {
            "GenartName": "Bolzen, Achsstrebe",
            "NormTermName": "Bolzen",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsstrebe"
        },
        "05935": {
            "GenartName": "Getriebeausgangswelle, Schaltgetriebe",
            "NormTermName": "Welle",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "05936": {
            "GenartName": "Ventilreiniger",
            "NormTermName": "Ventilreiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05937": {
            "GenartName": "Markierfarbe",
            "NormTermName": "Markierfarbe",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05938": {
            "GenartName": "Reiniger, Luftmassenmesser",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Luftmassenmesser"
        },
        "05939": {
            "GenartName": "Stell-/Steuerelement, Feststellbremse",
            "NormTermName": "Stell-/Steuerelement",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremse"
        },
        "05940": {
            "GenartName": "Einstellmutter, Kupplungsseilzug",
            "NormTermName": "Mutter",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsseilzug"
        },
        "05941": {
            "GenartName": "Führung, Kupplungsseilzug",
            "NormTermName": "Führung",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsseilzug"
        },
        "05942": {
            "GenartName": "Silikonentferner",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Silikonentferner"
        },
        "05943": {
            "GenartName": "Sicherungsbolzen, Kupplungsseilzug",
            "NormTermName": "Bolzen",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsseilzug"
        },
        "05944": {
            "GenartName": "Handreinigungstücher",
            "NormTermName": "Handreinigungstücher",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05945": {
            "GenartName": "Felgenlack",
            "NormTermName": "Lack",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Felgen"
        },
        "05946": {
            "GenartName": "Kupplungsspitzendrehmomentbegrenzer",
            "NormTermName": "Drehmomentbegrenzer",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsspitzendrehmomentbegrenzer"
        },
        "05947": {
            "GenartName": "Reiniger, Rissprüfmittel-Farbeindringverfahren",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Rissprüfmittel-Farbeindringverfahren"
        },
        "05948": {
            "GenartName": "Eindringmittel, Rissprüfmittel-Farbeindringverfahren",
            "NormTermName": "Eindringmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Rissprüfmittel-Farbeindringverfahren"
        },
        "05949": {
            "GenartName": "Entwickler, Rissprüfmittel-Farbeindringverfahren",
            "NormTermName": "Entwickler",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Rissprüfmittel-Farbeindringverfahren"
        },
        "05950": {
            "GenartName": "Langzeitfett",
            "NormTermName": "Langzeitfett",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05951": {
            "GenartName": "Haftfett",
            "NormTermName": "Haftfett",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05952": {
            "GenartName": "Dichtung, Kupplungsseilzug (Führung)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsseilzug (Führung)"
        },
        "05953": {
            "GenartName": "Bohr-/ Schneidöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Bohren/Schneiden"
        },
        "05954": {
            "GenartName": "Elektronikreiniger",
            "NormTermName": "Elektronikreiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05955": {
            "GenartName": "Industriereiniger",
            "NormTermName": "Industriereiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05956": {
            "GenartName": "Reinigungskonzentrat",
            "NormTermName": "Reinigungskonzentrat",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05957": {
            "GenartName": "Trockenschmierstoff",
            "NormTermName": "Trockenschmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05958": {
            "GenartName": "Hochdruckfett",
            "NormTermName": "Hochdruckfett",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05959": {
            "GenartName": "Zahnradschmiermittel",
            "NormTermName": "Zahnradschmiermittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05960": {
            "GenartName": "Rohrreiniger",
            "NormTermName": "Rohrreiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05961": {
            "GenartName": "Edelstahlreiniger",
            "NormTermName": "Edelstahlreiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05962": {
            "GenartName": "Korrosionsschutzöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Korrosionsschutz"
        },
        "05963": {
            "GenartName": "Schlossschmierstoff",
            "NormTermName": "Schlossschmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05964": {
            "GenartName": "Multifunktionsöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Multifunktionsöl"
        },
        "05965": {
            "GenartName": "Bohr-/ Schneidpaste",
            "NormTermName": "Bohr-/ Schneidpaste",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05966": {
            "GenartName": "Entwässerungsmittel",
            "NormTermName": "Entwässerungsmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05967": {
            "GenartName": "Farb-/ Lackentferner",
            "NormTermName": "Farb-/ Lackentferner",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05968": {
            "GenartName": "Beizpaste",
            "NormTermName": "Beizpaste",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05969": {
            "GenartName": "Keilriemenspray",
            "NormTermName": "Keilriemenspray",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "05970": {
            "GenartName": "Niet",
            "NormTermName": "Niete",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "05971": {
            "GenartName": "Stift",
            "NormTermName": "Stift",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "05972": {
            "GenartName": "Halteklammer, Handbremsstange",
            "NormTermName": "Halteklammer",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Handbremsstange"
        },
        "05973": {
            "GenartName": "Schlauchschelle",
            "NormTermName": "Schelle",
            "AssGrpName": "Normteile",
            "UsageName": "Schlauch"
        },
        "05974": {
            "GenartName": "Montagesatz, Heizklappenzug",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizklappenzug"
        },
        "05975": {
            "GenartName": "Faltenbalg, Heizklappenzug",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizklappenzug"
        },
        "05976": {
            "GenartName": "Zwischenstück, Heizklappenzug-Heizklappenhebel",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizklappenzug-Heizklappenhebel"
        },
        "05977": {
            "GenartName": "Manschette, Achsrohr",
            "NormTermName": "Manschette",
            "AssGrpName": "Radantrieb",
            "UsageName": "Achsrohr"
        },
        "05978": {
            "GenartName": "Mutter, Radnabe",
            "NormTermName": "Mutter",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Radnabe"
        },
        "05979": {
            "GenartName": "Stopfen, Bremsträgerplatte",
            "NormTermName": "Stopfen",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsträgerplatte"
        },
        "05980": {
            "GenartName": "Schutzschlauch, Gaszug",
            "NormTermName": "Schlauch",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Gaszug"
        },
        "05981": {
            "GenartName": "Isolierbuchse, Lenkrad",
            "NormTermName": "Isolierbuchse",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkrad"
        },
        "05982": {
            "GenartName": "Sortiment, Schlauchschelle",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Schlauchschelle"
        },
        "05983": {
            "GenartName": "Rückstellring, Blinkerschalter/-hebel",
            "NormTermName": "Rückstellring",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinkerschalter/-hebel"
        },
        "05984": {
            "GenartName": "Lager, Lenksäule",
            "NormTermName": "Lager",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenksäule"
        },
        "05985": {
            "GenartName": "Stößel, Kraftstoffpumpe",
            "NormTermName": "Stößel",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "05986": {
            "GenartName": "Stützring, Lenksäule",
            "NormTermName": "Stützring",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenksäule"
        },
        "05987": {
            "GenartName": "Führungsring, Stabilisatorkoppelstange",
            "NormTermName": "Führungsring",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisatorkoppelstange"
        },
        "05988": {
            "GenartName": "Aufnahmenippel, Gaszug",
            "NormTermName": "Nippel",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Aufnahmenippel, Gaszug"
        },
        "05989": {
            "GenartName": "Führungsrohr, Motorhaubenzug",
            "NormTermName": "Führungsrohr",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorhaubenzug"
        },
        "05990": {
            "GenartName": "Spannseil, Faltdach",
            "NormTermName": "Spannseil",
            "AssGrpName": "Karosserie",
            "UsageName": "Faltdach"
        },
        "05991": {
            "GenartName": "Seilzug, Schiebedach",
            "NormTermName": "Seilzug",
            "AssGrpName": "Karosserie",
            "UsageName": "Schiebedach"
        },
        "05992": {
            "GenartName": "Griff, Handbremshebel",
            "NormTermName": "Griff",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Handbremshebel"
        },
        "05993": {
            "GenartName": "Bolzen, Bremshebel-Feststellbremse",
            "NormTermName": "Bolzen",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremshebel-Feststellbremse"
        },
        "05994": {
            "GenartName": "Zugfeder, Bremspedal",
            "NormTermName": "Feder",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremspedal"
        },
        "05995": {
            "GenartName": "Reparatursatz, Fahrpedal",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Fahrpedal"
        },
        "05996": {
            "GenartName": "Reparatursatz, Lenksäule",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenksäule"
        },
        "05997": {
            "GenartName": "Brems-/Kupplungspedaleinheit",
            "NormTermName": "Pedaleinheit",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Brems-/Kupplungspedaleinheit"
        },
        "05998": {
            "GenartName": "Prüfspray, Rauchwarnmelder",
            "NormTermName": "Prüfspray",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Rauchwarnmelder"
        },
        "05999": {
            "GenartName": "Haken",
            "NormTermName": "Haken",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "06000": {
            "GenartName": "Schäkel",
            "NormTermName": "Schäkel",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "06001": {
            "GenartName": "Seilzugverkürzungssatz",
            "NormTermName": "Seilzugverkürzungssatz",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "06002": {
            "GenartName": "Pedalbelagsatz",
            "NormTermName": "Pedalbelagsatz",
            "AssGrpName": "Zubehör",
            "UsageName": null
        },
        "06004": {
            "GenartName": "Drehverschluss",
            "NormTermName": "Drehverschluss",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "06005": {
            "GenartName": "Mutter, Drehverschluss",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Drehverschluss"
        },
        "06006": {
            "GenartName": "Kühltasche",
            "NormTermName": "Kühltasche",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "06007": {
            "GenartName": "Elektromotor, Lenkgetriebe",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkgetriebe"
        },
        "06008": {
            "GenartName": "Befestigungstülle",
            "NormTermName": "Befestigungstülle",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "06009": {
            "GenartName": "Werkstattpresse",
            "NormTermName": "Werkstattpresse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06010": {
            "GenartName": "Aufnahmeplatte, Getriebeheber",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Getriebeheber"
        },
        "06011": {
            "GenartName": "Software, Schweißgerät",
            "NormTermName": "Software",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schweißgerät"
        },
        "06012": {
            "GenartName": "Schweißarm, Schweißgerät",
            "NormTermName": "Schweißarm",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schweißgerät"
        },
        "06013": {
            "GenartName": "Elektrode, Schweißgerät",
            "NormTermName": "Elektrode",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schweißgerät"
        },
        "06014": {
            "GenartName": "Elektrodensatz, Schweißgerät",
            "NormTermName": "Elektrodensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schweißgerät"
        },
        "06015": {
            "GenartName": "Fahrwagen, Batterielader/-starter",
            "NormTermName": "Fahrwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Batterielader-/starter"
        },
        "06016": {
            "GenartName": "Halterung, Batterielader/-starter",
            "NormTermName": "Halterung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Batterielader-/starter"
        },
        "06017": {
            "GenartName": "Farbband, Prüfgerät-Batterie",
            "NormTermName": "Farbband",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Prüfgerät-Batterie"
        },
        "06018": {
            "GenartName": "Schalter, Heckscheibenheizung",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Heckscheibenheizung"
        },
        "06019": {
            "GenartName": "Schalter, Frontscheibenheizung",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Frontscheibenheizung"
        },
        "06020": {
            "GenartName": "Schalter, elektronische Dämpferregulierung",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Elektronische Dämpferregulierung"
        },
        "06021": {
            "GenartName": "Schalter, Differentialsperre",
            "NormTermName": "Schalter",
            "AssGrpName": "Achsantrieb, Universal",
            "UsageName": "Differentialsperre"
        },
        "06022": {
            "GenartName": "Schalter, Innenraumgebläse",
            "NormTermName": "Schalter",
            "AssGrpName": "Heizung/Lüftung, Universal",
            "UsageName": "Innenraumgebläse"
        },
        "06023": {
            "GenartName": "Schalter, Heben/Senken (Anbaugeräte)",
            "NormTermName": "Schalter",
            "AssGrpName": "Nebenantrieb, Universal",
            "UsageName": "Heben/Senken (Anbaugeräte)"
        },
        "06024": {
            "GenartName": "Schalter, Neutralstellung",
            "NormTermName": "Schalter",
            "AssGrpName": "Nebenantrieb, Universal",
            "UsageName": "Neutralstellung"
        },
        "06025": {
            "GenartName": "Schalter, Scheibenwischerintervall",
            "NormTermName": "Schalter",
            "AssGrpName": "Scheinwerferreinigung, Universal",
            "UsageName": "Scheibenwischer"
        },
        "06026": {
            "GenartName": "Schalter, Waschwasseranlage",
            "NormTermName": "Schalter",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": "Waschwasser"
        },
        "06027": {
            "GenartName": "Halteklammer",
            "NormTermName": "Halteklammer",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "06028": {
            "GenartName": "Schiebedach",
            "NormTermName": "Schiebedach",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "06029": {
            "GenartName": "Break-out Box",
            "NormTermName": "Break-out Box",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "06030": {
            "GenartName": "Adapter, Break-out Box",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Break-out Box"
        },
        "06031": {
            "GenartName": "Sechskantschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Sechskant"
        },
        "06032": {
            "GenartName": "Vierkantschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Vierkant"
        },
        "06033": {
            "GenartName": "Linsensenkkopfschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Linsensenkkopf"
        },
        "06034": {
            "GenartName": "Linsenkopfschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Linsenkopf"
        },
        "06035": {
            "GenartName": "Zylinderschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Zylinderschraube"
        },
        "06037": {
            "GenartName": "Senkkopfschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Senkkopf"
        },
        "06038": {
            "GenartName": "Hammerkopfschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Hammerkopf"
        },
        "06039": {
            "GenartName": "Tellerschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Tellerschraube"
        },
        "06040": {
            "GenartName": "Rändelschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Rändelschraube"
        },
        "06041": {
            "GenartName": "Flachkopfschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Flachkopfschraube"
        },
        "06042": {
            "GenartName": "Dreikantschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Dreikantschraube"
        },
        "06043": {
            "GenartName": "Flügelschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Flügelschraube"
        },
        "06044": {
            "GenartName": "Schaftschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Schaftschraube"
        },
        "06045": {
            "GenartName": "Flachrundschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Flachrundschraube"
        },
        "06046": {
            "GenartName": "Kreuzlochschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Kreuzlochschraube"
        },
        "06047": {
            "GenartName": "Ski-/Snowboardhalter, Dachträger",
            "NormTermName": "Ski-/Snowboardhalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "06048": {
            "GenartName": "Nummernschildschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Nummernschild"
        },
        "06049": {
            "GenartName": "Snowboardhalter, Dachträger",
            "NormTermName": "Snowboardhalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "06050": {
            "GenartName": "Ringschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Ringschraube"
        },
        "06051": {
            "GenartName": "Blechschraube",
            "NormTermName": "Schraube",
            "AssGrpName": "Normteile",
            "UsageName": "Blechschraube"
        },
        "06052": {
            "GenartName": "Kreuzlochmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Kreuzlochmutter"
        },
        "06053": {
            "GenartName": "Nutmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Nutmutter"
        },
        "06054": {
            "GenartName": "Bügelmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Bügelmutter"
        },
        "06055": {
            "GenartName": "Flügelmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Flügelmutter"
        },
        "06056": {
            "GenartName": "Hutmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Hutmuttern"
        },
        "06057": {
            "GenartName": "Sechskantmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Sechskantmutter"
        },
        "06058": {
            "GenartName": "Vierkantmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Vierkantmutter"
        },
        "06059": {
            "GenartName": "Skihalter, Anhängekupplungsträger",
            "NormTermName": "Skihalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsträger"
        },
        "06060": {
            "GenartName": "Sicherungsmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Sicherungsmutter"
        },
        "06061": {
            "GenartName": "Schlitzmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Schlitzmutter"
        },
        "06062": {
            "GenartName": "Spannschlossmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Spannschlossmutter"
        },
        "06063": {
            "GenartName": "Blechmutter",
            "NormTermName": "Mutter",
            "AssGrpName": "Normteile",
            "UsageName": "Blechmutter"
        },
        "06064": {
            "GenartName": "Dachträgerstange",
            "NormTermName": "Stange",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "06065": {
            "GenartName": "Dachträgerfuß",
            "NormTermName": "Fuß",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "06066": {
            "GenartName": "Leiterhalter, Dachträger",
            "NormTermName": "Leiterhalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "06067": {
            "GenartName": "Rohrbox, Dachträger",
            "NormTermName": "Rohrbox",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "06068": {
            "GenartName": "Vollniet",
            "NormTermName": "Niet",
            "AssGrpName": "Normteile",
            "UsageName": "Vollniet"
        },
        "06069": {
            "GenartName": "Hohlniet",
            "NormTermName": "Niet",
            "AssGrpName": "Normteile",
            "UsageName": "Hohlniet"
        },
        "06070": {
            "GenartName": "Halbhohlniet",
            "NormTermName": "Niet",
            "AssGrpName": "Normteile",
            "UsageName": "Halbhohlniet"
        },
        "06071": {
            "GenartName": "Rohrniet",
            "NormTermName": "Niet",
            "AssGrpName": "Normteile",
            "UsageName": "Rohrniet"
        },
        "06072": {
            "GenartName": "Dachbox",
            "NormTermName": "Dachbox",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": null
        },
        "06073": {
            "GenartName": "Schutzhülle, Dachbox",
            "NormTermName": "Schutzhülle",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachbox"
        },
        "06074": {
            "GenartName": "Skihalter, Dachbox",
            "NormTermName": "Skihalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachbox"
        },
        "06075": {
            "GenartName": "Leuchte, Dachbox",
            "NormTermName": "Leuchte",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachbox"
        },
        "06077": {
            "GenartName": "Gepäckkorb, Dachträger",
            "NormTermName": "Gepäckkorb",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "06078": {
            "GenartName": "Gepäcknetz, Gepäckkorb",
            "NormTermName": "Gepäcknetz",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Gepäckkorb"
        },
        "06079": {
            "GenartName": "Halteclip, Innenraumverkleidung",
            "NormTermName": "Clip",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Innenraumverkleidung"
        },
        "06080": {
            "GenartName": "Clip, Zier-/Schutzleiste",
            "NormTermName": "Clip",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Zier-/Schutzleiste"
        },
        "06081": {
            "GenartName": "Halteclip, Scheibeneinbau",
            "NormTermName": "Clip",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Scheibeneinbau"
        },
        "06082": {
            "GenartName": "Gepäcktasche, Gepäckkorb",
            "NormTermName": "Gepäcktasche",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Gepäckkorb"
        },
        "06083": {
            "GenartName": "Halterung, Tablet",
            "NormTermName": "Halterung",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Tablet"
        },
        "06084": {
            "GenartName": "Thermo-Batteriehülle",
            "NormTermName": "Schutzhülle",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Batterie"
        },
        "06085": {
            "GenartName": "Abschleppstange",
            "NormTermName": "Stange",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Abschlepphilfe"
        },
        "06086": {
            "GenartName": "Adapter, Steckachse (Fahrradhalter)",
            "NormTermName": "Adapter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Steckachse (Fahrradhalter)"
        },
        "06087": {
            "GenartName": "Adapter, Dachträger",
            "NormTermName": "Adapter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "06088": {
            "GenartName": "Akkupack",
            "NormTermName": "Batterie",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Ladekabel"
        },
        "06089": {
            "GenartName": "Anti-Beschlag-Tuch",
            "NormTermName": "Anti-Beschlag-Tuch",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "06090": {
            "GenartName": "Bootshalter, Dachträger",
            "NormTermName": "Bootshalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "06091": {
            "GenartName": "Frontscheibenabdeckung",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Frontscheibe"
        },
        "06093": {
            "GenartName": "Kamera",
            "NormTermName": "Kamera",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "06094": {
            "GenartName": "Gasfeder, Absperrgitter (Koffer-/Laderaum)",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Zubehör",
            "UsageName": "Absperrgitter (Koffer-/Laderaum)"
        },
        "06095": {
            "GenartName": "Ladegerät, Mobile Kommunikation",
            "NormTermName": "Ladegerät",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Mobile Kommunikation"
        },
        "06096": {
            "GenartName": "Luftentfeuchter",
            "NormTermName": "Luftentfeuchter",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "06097": {
            "GenartName": "Reifenständer",
            "NormTermName": "Reifenständer",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Reifenlagerung"
        },
        "06098": {
            "GenartName": "Schneebesen",
            "NormTermName": "Schneebesen",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "06099": {
            "GenartName": "Schneeschaufel",
            "NormTermName": "Schneeschaufel",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "06100": {
            "GenartName": "Schutzschlauch, Marderschutz",
            "NormTermName": "Schutzschlauch",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Marderschutz"
        },
        "06101": {
            "GenartName": "Verteiler, Zigarettenanzünder",
            "NormTermName": "Verteiler",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Zigarettenanzünder"
        },
        "06102": {
            "GenartName": "Druckwandler, Stellelement (Drosselklappe)",
            "NormTermName": "Druckwandler",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Stellelement (Drosselklappe)"
        },
        "06103": {
            "GenartName": "Aufnahme, Karosserie-Richtbank",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserie-Richtbank"
        },
        "06104": {
            "GenartName": "Fahrzeugschlitten, Karosserie-Richtbank",
            "NormTermName": "Fahrzeugschlitten",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserie-Richtbank"
        },
        "06105": {
            "GenartName": "Ketten-/Klemmsatz, Karosserie-Richtbank",
            "NormTermName": "Ketten-/Klemmsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserie-Richtbank"
        },
        "06106": {
            "GenartName": "Seilwinde, Karosserie-Richtbank",
            "NormTermName": "Seilwinde",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserie-Richtbank"
        },
        "06107": {
            "GenartName": "Druckkolben, Karosserie-Richtbank",
            "NormTermName": "Druckkolben",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserie-Richtbank"
        },
        "06108": {
            "GenartName": "Aufsatz, Druckkolben (Karosserie-Richtbank)",
            "NormTermName": "Aufsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckkolben (Karosserie-Richtbank)"
        },
        "06109": {
            "GenartName": "Verbindungselement, Druckkolben (Karosserie-Richtbank)",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckkolben (Karosserie-Richtbank)"
        },
        "06110": {
            "GenartName": "Schutzkappe, Druckkolben (Karosserie-Richtbank)",
            "NormTermName": "Kappe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckkolben (Karosserie-Richtbank)"
        },
        "06111": {
            "GenartName": "Halterung, Druckkolben (Karosserie-Richtbank)",
            "NormTermName": "Halterung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckkolben (Karosserie-Richtbank)"
        },
        "06112": {
            "GenartName": "Anzeige, Richtturm (Karosserie-Richtbank)",
            "NormTermName": "Anzeige",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Richtturm (Karosserie-Richtbank)"
        },
        "06113": {
            "GenartName": "Befestigungssatz, Richtturm (Karosserie-Richtbank)",
            "NormTermName": "Befestigungssatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Richtturm (Karosserie-Richtbank)"
        },
        "06114": {
            "GenartName": "Verlängerung, Druckkolben (Karosserie-Richtbank)",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckkolben (Karosserie-Richtbank)"
        },
        "06115": {
            "GenartName": "Verbinder, Druckkolbenverlängerungsrohr",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckkolbenverlängerungsrohr"
        },
        "06116": {
            "GenartName": "Abdeckkappe, Steckgehäuse",
            "NormTermName": "Kappe",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Steckgehäuse"
        },
        "06117": {
            "GenartName": "Halter, Kontaktträger",
            "NormTermName": "Halter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kontaktträger"
        },
        "06118": {
            "GenartName": "Sekundärverriegelung, Steckgehäuse",
            "NormTermName": "Verriegelung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Steckgehäuse"
        },
        "06119": {
            "GenartName": "Abdeckung, Kabelverbindungsdose",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kabelverbindungsdose"
        },
        "06120": {
            "GenartName": "Abdeckung, Steckdose",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Steckdose"
        },
        "06121": {
            "GenartName": "Halter, Sicherungsdose",
            "NormTermName": "Halter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Sicherungsdose"
        },
        "06122": {
            "GenartName": "Anschraubplatte, Sicherungshalter",
            "NormTermName": "Anschraubplatte",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Sicherungshalter"
        },
        "06123": {
            "GenartName": "Brücke, Sicherungshalter",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Sicherungshalter"
        },
        "06124": {
            "GenartName": "Kabelendtülle",
            "NormTermName": "Kabelendtülle",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "06125": {
            "GenartName": "Griff, Steckgehäuse",
            "NormTermName": "Griff",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Steckgehäuse"
        },
        "06126": {
            "GenartName": "Schwingungsdämpfer, Heckleuchte",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Heckleuchte"
        },
        "06127": {
            "GenartName": "Unterlage, Seitenmarkierungsleuchte",
            "NormTermName": "Unterlage",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "06128": {
            "GenartName": "Unterlage, Begrenzungsleuchte",
            "NormTermName": "Unterlage",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Begrenzungsleuchte"
        },
        "06129": {
            "GenartName": "Sortiment, Lötverbinder",
            "NormTermName": "Sortiment",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Lötverbinder"
        },
        "06130": {
            "GenartName": "Frontschutzbügel",
            "NormTermName": "Bügel",
            "AssGrpName": "Karosserie",
            "UsageName": "Frontschutzbügel"
        },
        "06131": {
            "GenartName": "Überrollbügel",
            "NormTermName": "Bügel",
            "AssGrpName": "Karosserie",
            "UsageName": "Überrollbügel"
        },
        "06132": {
            "GenartName": "Adapter, Lochsäge",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lochsäge"
        },
        "06134": {
            "GenartName": "Haltersatz, Messzielmarken (Karosserievermessungssystem)",
            "NormTermName": "Haltersatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Messzielmarken"
        },
        "06135": {
            "GenartName": "Nachrüstsatz, Motor-Sound-System",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Zubehör",
            "UsageName": "Motor-Sound-System"
        },
        "06136": {
            "GenartName": "Anhängekupplungsgepäckkorb",
            "NormTermName": "Gepäckkorb",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängevorrichtung"
        },
        "06137": {
            "GenartName": "Drucksensor, Oszilloskop",
            "NormTermName": "Sensor",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Oszilloskop"
        },
        "06138": {
            "GenartName": "Kettensatz, Ölpumpenantrieb",
            "NormTermName": "Kettensatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "06140": {
            "GenartName": "Sortiment, Ruß-/Partikelfilter-Reparatur",
            "NormTermName": "Sortiment",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Ruß-/Partikelfilter"
        },
        "06141": {
            "GenartName": "Schweißschutzschraube, Einschweißgewinde-Abgastemp. Sensor",
            "NormTermName": "Schraube",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Einschweißgewinde-Abgastemperatursensor"
        },
        "06142": {
            "GenartName": "Schweißschutzkappe, Einschweißgewinde-Abgastemperatursensor",
            "NormTermName": "Kappe",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Einschweißgewinde-Abgastemperatursensor"
        },
        "06143": {
            "GenartName": "Verbindungsschiene, Sicherungsdose",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Sicherungsdose"
        },
        "06144": {
            "GenartName": "Zusatzölpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Schmierung",
            "UsageName": "Zusatzpumpe"
        },
        "06145": {
            "GenartName": "Schalter, Lenksäulenverstellung",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Lenksäulenverstellung"
        },
        "06146": {
            "GenartName": "Lampenfassung, Blinkleuchte",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Blinkleuchte"
        },
        "06147": {
            "GenartName": "Dichtungssatz, Achskörperlager",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achskörperlager"
        },
        "06148": {
            "GenartName": "Reparatursatz, Überdruckventil (Druckluftkompressor)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Überdruckventil (Druckluftkompressor)"
        },
        "06149": {
            "GenartName": "Manschette, Trag-/Führungsgelenk",
            "NormTermName": "Manschette",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "06150": {
            "GenartName": "Elektroleitung, Elektromotor-Lenkgetriebe",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Lenkung",
            "UsageName": "Elektromotor-Lenkgetriebe"
        },
        "06151": {
            "GenartName": "Verschlussschraube, Steuerkettenspanner",
            "NormTermName": "Schraube",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkettenspanner"
        },
        "06152": {
            "GenartName": "Messvorrichtung, Steuerkettenspannung",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Steuerkettenspannung"
        },
        "06153": {
            "GenartName": "Messvorrichtung, Steuerkettenspannung",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steuerkettenspannung"
        },
        "06154": {
            "GenartName": "Anschlussleitung, Nockenwellensensor",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Nockenwellensensor"
        },
        "06155": {
            "GenartName": "Federteller, Lamellenkupplung",
            "NormTermName": "Federteller",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Lamellenkupplung"
        },
        "06156": {
            "GenartName": "Gleitschienenbolzen, Steuerkette",
            "NormTermName": "Bolzen",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkette"
        },
        "06157": {
            "GenartName": "Halter, Steuerkettenspanner",
            "NormTermName": "Halter",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkettenspanner"
        },
        "06158": {
            "GenartName": "Prüfplatte, Achs-/Gelenkspieltester",
            "NormTermName": "Prüfplatte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Achs-/Gelenkspieltester"
        },
        "06159": {
            "GenartName": "Steuereinheit, Unterdruck",
            "NormTermName": "Steuereinheit",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruck"
        },
        "06160": {
            "GenartName": "Dichtung, Zündspule",
            "NormTermName": "Dichtung",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündspule"
        },
        "06161": {
            "GenartName": "Marderschutz",
            "NormTermName": "Marderschutz",
            "AssGrpName": "Zubehör",
            "UsageName": null
        },
        "06162": {
            "GenartName": "Schalter, Einparkhilfe",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Einparkhilfe"
        },
        "06163": {
            "GenartName": "Schalter, ESP",
            "NormTermName": "Schalter",
            "AssGrpName": "Fahrdynamikregelung",
            "UsageName": "ESP"
        },
        "06164": {
            "GenartName": "Schalter, Heckklappe",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Heckklappe"
        },
        "06165": {
            "GenartName": "Schalter, Spurwechselassistent",
            "NormTermName": "Schalter",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Spurwechselassistent"
        },
        "06166": {
            "GenartName": "Multifunktionsschalter",
            "NormTermName": "Schalter",
            "AssGrpName": "Bordnetz",
            "UsageName": "Multifunktion"
        },
        "06167": {
            "GenartName": "Pleuellagersatz",
            "NormTermName": "Lagersatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Pleuel"
        },
        "06168": {
            "GenartName": "Anlaufscheibensatz, Nockenwelle",
            "NormTermName": "Anlaufscheibensatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwelle"
        },
        "06169": {
            "GenartName": "Anlaufscheibensatz, Kurbelwelle",
            "NormTermName": "Anlaufscheibensatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwelle"
        },
        "06170": {
            "GenartName": "Flachzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Flachzange"
        },
        "06171": {
            "GenartName": "Rundzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rundzange"
        },
        "06174": {
            "GenartName": "Halter, Ausgleichsbehälter-Kühlmittel",
            "NormTermName": "Halter",
            "AssGrpName": "Kühlung",
            "UsageName": "Ausgleichsbehälter-Kühlmittel"
        },
        "06175": {
            "GenartName": "Set, Rohr-/Wasserpumpenzange",
            "NormTermName": "Zangensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohr-/Wasserpumpenzange"
        },
        "06176": {
            "GenartName": "Abisolierwerkzeug",
            "NormTermName": "Abisolierwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "abisolieren"
        },
        "06177": {
            "GenartName": "Klinge, Kabelschneider",
            "NormTermName": "Klinge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kabelschneider"
        },
        "06178": {
            "GenartName": "Klinge, Kabelmesser",
            "NormTermName": "Klinge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kabelmesser"
        },
        "06179": {
            "GenartName": "Mittenschneider",
            "NormTermName": "Mittenschneider",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06180": {
            "GenartName": "Werkzeuglochtafel",
            "NormTermName": "Lochtafel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeug"
        },
        "06181": {
            "GenartName": "Positionierhilfe, Crimpzange",
            "NormTermName": "Montagehilfe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Crimpzange"
        },
        "06182": {
            "GenartName": "Testständer, Werkzeug",
            "NormTermName": "Testständer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeug"
        },
        "06183": {
            "GenartName": "Drehständer, Werkzeug",
            "NormTermName": "Drehständer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeug"
        },
        "06184": {
            "GenartName": "Zahlteller",
            "NormTermName": "Zahlteller",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06185": {
            "GenartName": "Längenanschlag, Abisolierzange",
            "NormTermName": "Längenanschlag",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abisolierzange"
        },
        "06186": {
            "GenartName": "Fliesenlochzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Fliesenlochzange"
        },
        "06187": {
            "GenartName": "Bestückungszange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06188": {
            "GenartName": "Armaturenzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Armaturen"
        },
        "06189": {
            "GenartName": "Zangenschlüssel",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zangenschlüssel"
        },
        "06190": {
            "GenartName": "Präsentationsständer",
            "NormTermName": "Präsentationsständer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06191": {
            "GenartName": "Messerkopf, Bolzenschneider",
            "NormTermName": "Messerkopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bolzenschneider"
        },
        "06192": {
            "GenartName": "Greifeinsätze, Schlauchklemmenzange",
            "NormTermName": "Greifeinsätze",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlauchklemmenzange"
        },
        "06193": {
            "GenartName": "Glaskröselzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glaskröselzange"
        },
        "06194": {
            "GenartName": "Schaltschrankschlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schaltschrank"
        },
        "06195": {
            "GenartName": "Justierzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Justierzange"
        },
        "06196": {
            "GenartName": "Profil-Verbundzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Profil-Verbundzange"
        },
        "06197": {
            "GenartName": "Anhängekupplungsski-/Snowboardhalter",
            "NormTermName": "Ski-/Snowboardhalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängevorrichtung"
        },
        "06198": {
            "GenartName": "Klingensatz, Abisolierzange",
            "NormTermName": "Klingensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abisolierzange"
        },
        "06199": {
            "GenartName": "Lochplattenwand",
            "NormTermName": "Lochplattenwand",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Verkaufshilfe"
        },
        "06200": {
            "GenartName": "Haken, Lochplattenwand",
            "NormTermName": "Haken",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Verkaufshilfe"
        },
        "06201": {
            "GenartName": "Magnetstreifen, Lochplattenwand",
            "NormTermName": "Magnetstreifen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Verkaufshilfe"
        },
        "06202": {
            "GenartName": "Halter, Lochplattenwand",
            "NormTermName": "Halter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Verkaufshilfe"
        },
        "06203": {
            "GenartName": "Zangen-Set",
            "NormTermName": "Set",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Zangen"
        },
        "06204": {
            "GenartName": "Be-/Entladungshilfe, Dachträger",
            "NormTermName": "Be-/Entladungshilfe",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "06205": {
            "GenartName": "Werkzeugleuchte",
            "NormTermName": "Werkzeugleuchte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06206": {
            "GenartName": "Boardhalter, Dachträger",
            "NormTermName": "Boardhalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "06207": {
            "GenartName": "Treppe, Montagegrube",
            "NormTermName": "Treppe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Montagegrube"
        },
        "06208": {
            "GenartName": "Absicherung, Montagegrube",
            "NormTermName": "Sicherung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Montagegrube"
        },
        "06209": {
            "GenartName": "Fernbedienung, Säulen-Hebeanlage",
            "NormTermName": "Fernbedienung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Säulen-Hebeanlage"
        },
        "06210": {
            "GenartName": "Trockner, Radheber",
            "NormTermName": "Trockner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Radheber"
        },
        "06211": {
            "GenartName": "Lagerung, Druckbehälter-Kompressor",
            "NormTermName": "Lagerung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckluftbehälter"
        },
        "06212": {
            "GenartName": "Montagevorrichtung, Fahrzeugsitz",
            "NormTermName": "Montagevorrichtung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrzeugsitz"
        },
        "06213": {
            "GenartName": "Software, Drucklufttechnik",
            "NormTermName": "Software",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "06214": {
            "GenartName": "Sensor, Stempel-Hebebühne",
            "NormTermName": "Sensor",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Stempel-Hebebühne"
        },
        "06215": {
            "GenartName": "Schalldämpfer, Kompressorentlastungsluft",
            "NormTermName": "Schalldämpfer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Kompressorentlastungsluft"
        },
        "06216": {
            "GenartName": "Druckplatte, Werkstattpresse",
            "NormTermName": "Druckplatte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkstattpressen"
        },
        "06217": {
            "GenartName": "Transportwagen, Lastaufnahmemittel",
            "NormTermName": "Transportwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lastaufnahmemittel"
        },
        "06218": {
            "GenartName": "Spindel, Unterstellbock",
            "NormTermName": "Spindel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Unterstellbock"
        },
        "06219": {
            "GenartName": "Standsäule, Hebebühnenbedieneinheit",
            "NormTermName": "Standsäule",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebebühnenbedieneinheit"
        },
        "06220": {
            "GenartName": "Verlängerung, Rangierheber",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Rangierheber"
        },
        "06221": {
            "GenartName": "Aufnahme, Radheber",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Radheber"
        },
        "06222": {
            "GenartName": "Winde, Werkstattpresse",
            "NormTermName": "Winde",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkstattpresse"
        },
        "06223": {
            "GenartName": "Wärmetauscher, Druckluftkompressor",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckluftkompressor"
        },
        "06224": {
            "GenartName": "Dachträger",
            "NormTermName": "Träger",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachträger"
        },
        "06225": {
            "GenartName": "Dachlastenträger",
            "NormTermName": "Träger",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Dachlastenträger"
        },
        "06226": {
            "GenartName": "Erweiterungssatz, Anhängekupplungsfahrradträger",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsfahrradträger"
        },
        "06227": {
            "GenartName": "Demontagewerkzeug, Einspritzventil",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzventil"
        },
        "06228": {
            "GenartName": "Polierwatte",
            "NormTermName": "Polierwatte",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06229": {
            "GenartName": "Wandhalterung, Handreiniger",
            "NormTermName": "Halterung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Handreiniger"
        },
        "06230": {
            "GenartName": "Spender, Handreiniger",
            "NormTermName": "Spender",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Handreiniger"
        },
        "06231": {
            "GenartName": "Justierlehre, Trommelbremse",
            "NormTermName": "Lehre",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Trommelbremse"
        },
        "06232": {
            "GenartName": "Reparatursatz, Bremssattel-Führungsgewinde",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremssattel-Führungsgewinde"
        },
        "06233": {
            "GenartName": "Haken, Bremssattel (Bremsbelagwechsel)",
            "NormTermName": "Haken",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Haken, Bremssattel (Bremsbelagwechsel)"
        },
        "06234": {
            "GenartName": "Steckschlüssel, Entlüfterschraube/-ventil",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Entlüfterschraube/-ventil"
        },
        "06235": {
            "GenartName": "Steckschlüssel-Satz, Entlüfterschraube/-ventil",
            "NormTermName": "Steckschlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Entlüfterschraube/-ventil"
        },
        "06236": {
            "GenartName": "Reifenträgerwagen",
            "NormTermName": "Fahrwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reifen"
        },
        "06237": {
            "GenartName": "Messlehre, Scheibenbremsbelag",
            "NormTermName": "Messlehre",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Scheibenbremsbelag"
        },
        "06238": {
            "GenartName": "Montagewerkzeug, Bremsfeder (Feststellbremse)",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Feststellbremse"
        },
        "06239": {
            "GenartName": "Werkzeuggürtel",
            "NormTermName": "Werkzeuggürtel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06240": {
            "GenartName": "Steckschlüsseleinsatz, Zylinderkopfschraube",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zylinderkopfschraube"
        },
        "06241": {
            "GenartName": "Ölfilterschlüsselsatz",
            "NormTermName": "Ölfilterschlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06242": {
            "GenartName": "Materialheber",
            "NormTermName": "Materialheber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "06243": {
            "GenartName": "Leitungssatz, Heckleuchtenumrüstung",
            "NormTermName": "Elektroleitungssatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Heckleuchtenumrüstung"
        },
        "06244": {
            "GenartName": "Reparaturhalter, Abgasanlage",
            "NormTermName": "Halter",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Reparaturhalter"
        },
        "06245": {
            "GenartName": "Erweiterungssatz, Heckklappenfahrradträger",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Heckklappenfahrradträger"
        },
        "06246": {
            "GenartName": "Fahrradträger, Anhängekupplung",
            "NormTermName": "Träger",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsfahrradträger"
        },
        "06247": {
            "GenartName": "Fahrradträger, Heckklappe",
            "NormTermName": "Träger",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Heckklappe"
        },
        "06248": {
            "GenartName": "Kabelschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Kabelschloss"
        },
        "06249": {
            "GenartName": "Scanner, Karosserievermessungssystem",
            "NormTermName": "Scanner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserievermessungssystem"
        },
        "06250": {
            "GenartName": "Ablage, Scanner (Karosserievermessungssystem)",
            "NormTermName": "Ablage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Scanner (Karosserievermessungssystem)"
        },
        "06251": {
            "GenartName": "Stativ, Scanner (Karosserievermessungssystem)",
            "NormTermName": "Stativ",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Scanner (Karosserievermessungssystem)"
        },
        "06252": {
            "GenartName": "Verlängerung, Messzielmarken (Karosserievermessungssystem)",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Messzielmarken"
        },
        "06253": {
            "GenartName": "Messzielmarkensatz",
            "NormTermName": "Messzielmarken",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserievermessungssystem"
        },
        "06254": {
            "GenartName": "Gewicht, Messzielmarken (Karosserievermessungssystem)",
            "NormTermName": "Gewicht",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Messzielmarken"
        },
        "06255": {
            "GenartName": "Aufnahme, Unterstellheber",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Unterstellheber"
        },
        "06256": {
            "GenartName": "Unterlegkeil, Karosserie-Richtbank",
            "NormTermName": "Unterlegkeil",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserie-Richtbank"
        },
        "06257": {
            "GenartName": "Grubenkopf",
            "NormTermName": "Grubenkopf",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06258": {
            "GenartName": "Haltevorrichtung, Fahrerhaus",
            "NormTermName": "Haltewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06259": {
            "GenartName": "Steuereinheit, Drucklufttechnik",
            "NormTermName": "Steuereinheit",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "06260": {
            "GenartName": "Ventil, Drucklufttechnik",
            "NormTermName": "Ventil",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "06261": {
            "GenartName": "Standheizung, Druckluftkompressor",
            "NormTermName": "Standheizung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckluftkompressor"
        },
        "06262": {
            "GenartName": "Abstützvorrichtung, Grubenheber",
            "NormTermName": "Abstützvorrichtung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Grubenheber"
        },
        "06263": {
            "GenartName": "Regalstopper, Lochplattenwand",
            "NormTermName": "Regalstopper",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lochplattenwand"
        },
        "06264": {
            "GenartName": "Regalboden, Lochplattenwand",
            "NormTermName": "Regalboden",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lochplattenwand"
        },
        "06265": {
            "GenartName": "Vitrine, Lochplattenwand",
            "NormTermName": "Vitrine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lochplattenwand"
        },
        "06266": {
            "GenartName": "Schloss, Lochplattenwand-Haken",
            "NormTermName": "Schloss",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lochplattenwand-Haken"
        },
        "06267": {
            "GenartName": "Klinge, Abisolierwerkzeug",
            "NormTermName": "Klinge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abisolierwerkzeug"
        },
        "06268": {
            "GenartName": "Weberzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Weberzange"
        },
        "06269": {
            "GenartName": "Ersatzspitzen, Sicherungsringzange",
            "NormTermName": "Ersatzspitzen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Sicherungsringzange"
        },
        "06270": {
            "GenartName": "Sicherungsringwerkzeug",
            "NormTermName": "Sicherungsringwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06271": {
            "GenartName": "Karosseriezange",
            "NormTermName": "Karosseriezange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06272": {
            "GenartName": "Töpferzange",
            "NormTermName": "Töpferzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06273": {
            "GenartName": "Konuszange",
            "NormTermName": "Konuszange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06274": {
            "GenartName": "Schraubzange",
            "NormTermName": "Schraubzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06275": {
            "GenartName": "Vierdornzange",
            "NormTermName": "Vierdornzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06276": {
            "GenartName": "Werkzeugsatz, Solar-Steckverbinder",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Solar-Steckverbinder"
        },
        "06277": {
            "GenartName": "Kompressionswerkzeug, Steckverbinder",
            "NormTermName": "Kompressionswerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06278": {
            "GenartName": "Anlegewerkzeug",
            "NormTermName": "Anlegewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06279": {
            "GenartName": "Wechselmagazin, Crimpzange",
            "NormTermName": "Wechselmagazin",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Crimpzange"
        },
        "06280": {
            "GenartName": "Reparatursatz, Kabelschere",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06281": {
            "GenartName": "Glasflachzange",
            "NormTermName": "Glasflachzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06282": {
            "GenartName": "Glasbrechzange",
            "NormTermName": "Glasbrechzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06283": {
            "GenartName": "Klinge, Blechknabber",
            "NormTermName": "Klinge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Blechknabber"
        },
        "06284": {
            "GenartName": "Abdecktuch",
            "NormTermName": "Abdecktuch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06285": {
            "GenartName": "Klemme, Abdecktuch",
            "NormTermName": "Klemme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06286": {
            "GenartName": "Ölstandsensor, Drucklufttechnik",
            "NormTermName": "Sensor",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "06287": {
            "GenartName": "Stopper-Satz, Schlauch-/Rohrleitung",
            "NormTermName": "Stopper-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Leitung"
        },
        "06288": {
            "GenartName": "Temperaturfühler, Multimeter",
            "NormTermName": "Sensor",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Multimeter"
        },
        "06289": {
            "GenartName": "Aufsatz-Set, Heißluftföhn",
            "NormTermName": "Aufsatz-Set",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Heißluftföhn"
        },
        "06290": {
            "GenartName": "Ein-/Auspresswerkzeug-Satz",
            "NormTermName": "Ein-/Auspresswerkzeug-Satz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06291": {
            "GenartName": "Stopper, Schlauch-/Rohrleitung",
            "NormTermName": "Stopper",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Leitung"
        },
        "06292": {
            "GenartName": "Grundlastwechselschaltung, Druckluftkompressor",
            "NormTermName": "Grundlastwechselschaltung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckluftkompressor"
        },
        "06293": {
            "GenartName": "Verbundsteuerung, Druckluftkompressor",
            "NormTermName": "Verbundsteuerung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckluftkompressor"
        },
        "06294": {
            "GenartName": "Eichung, Druckluftsysteme",
            "NormTermName": "Eichung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Dienstleistung Druckluftsysteme"
        },
        "06295": {
            "GenartName": "Kraftmessgerät, Hebetechnik",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "06296": {
            "GenartName": "Kondensatabscheider, Hebetechnik",
            "NormTermName": "Abscheider",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hebetechnik"
        },
        "06297": {
            "GenartName": "Pumpensumpf, Einbauwanne (Stempel-Hebebühne)",
            "NormTermName": "Pumpensumpf",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Einbauwanne, Stempel-Hebebühne"
        },
        "06298": {
            "GenartName": "Motorbrücke",
            "NormTermName": "Träger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Motor"
        },
        "06299": {
            "GenartName": "Sicherheitsbegrenzung, Grubenheber",
            "NormTermName": "Sicherheitsbegrenzung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Grubenheber"
        },
        "06300": {
            "GenartName": "Wegeventilblock, Lenkung",
            "NormTermName": "Wegeventilblock",
            "AssGrpName": "Lenkung",
            "UsageName": null
        },
        "06301": {
            "GenartName": "Aufnahme, Zugelement (Karosserietechnik)",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserietechnik"
        },
        "06302": {
            "GenartName": "Zugelemente-Satz, Karosserietechnik",
            "NormTermName": "Zugelemente-Satz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserietechnik"
        },
        "06303": {
            "GenartName": "Unterstellblock",
            "NormTermName": "Unterstellblock",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06304": {
            "GenartName": "Karosserie-Querträger (Karosserievermessungssystem)",
            "NormTermName": "Karosserie-Querträger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserievermessungssystem"
        },
        "06305": {
            "GenartName": "Radstand, Karosserietechnik",
            "NormTermName": "Radstand",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserietechnik"
        },
        "06306": {
            "GenartName": "Gasfeder, Sitzhöhenverstellung",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Sitzhöhenverstellung"
        },
        "06307": {
            "GenartName": "Gasfeder, Sitzlehnenverstellung",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Sitzlehnenverstellung"
        },
        "06308": {
            "GenartName": "Lagerung, Fahrzeugrahmen",
            "NormTermName": "Lagerung",
            "AssGrpName": "Karosserie",
            "UsageName": "Fahrzeugrahmen"
        },
        "06309": {
            "GenartName": "Federsplint, Kraftstofffilter (Thermostat)",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstofffilter (Thermostat)"
        },
        "06310": {
            "GenartName": "Gehäuse, Ölkühler",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölkühler"
        },
        "06311": {
            "GenartName": "Halteband, Laderaumabdeckung",
            "NormTermName": "Band",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Laderaumabdeckung"
        },
        "06312": {
            "GenartName": "Haltefeder, Kühlmittelflansch-Verschlussstopfen",
            "NormTermName": "Feder",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelflansch-Verschlussstopfen"
        },
        "06313": {
            "GenartName": "Schalter, Sitzverstellung",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sitzverstellung"
        },
        "06314": {
            "GenartName": "Schutzdeckel, Verteilergetriebe",
            "NormTermName": "Deckel",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Verteilergetriebe"
        },
        "06315": {
            "GenartName": "Einschweißgewinde, NOx Sensor",
            "NormTermName": "Einschweißgewinde",
            "AssGrpName": "Abgasanlage",
            "UsageName": "NOx Sensor"
        },
        "06316": {
            "GenartName": "Öl, Klimaanlage",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Klimaanlage"
        },
        "06317": {
            "GenartName": "Verkleidung, Lenksäule",
            "NormTermName": "Verkleidung",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenksäule"
        },
        "06318": {
            "GenartName": "Dichtung, Kraftstoffbehälter",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffbehälter"
        },
        "06319": {
            "GenartName": "Halteklammer, Heizungsschlauch",
            "NormTermName": "Halteklammer",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizungsschlauch"
        },
        "06321": {
            "GenartName": "Schalter, Automatische Vibration (Anbaugeräte)",
            "NormTermName": "Schalter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Automatische Vibration (Anbaugeräte)"
        },
        "06322": {
            "GenartName": "Reifen-Dichtungs-Set",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Reifen-Dichtungs-Set"
        },
        "06323": {
            "GenartName": "Dichtmittel, Reifen-Dichtungs-Set",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Reifen-Dichtungs-Set"
        },
        "06324": {
            "GenartName": "Füllschlauch-Set, Reifen-Dichtungs-Set",
            "NormTermName": "Füllschlauch-Set",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Reifen-Dichtungs-Set"
        },
        "06325": {
            "GenartName": "Dichtmittel-Set, Reifen-Dichtungs-Set",
            "NormTermName": "Dichtstoff-Set",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Reifen-Dichtungs-Set"
        },
        "06327": {
            "GenartName": "Reparatursatz, Hauptscheinwerfer",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "06328": {
            "GenartName": "Reparatursatz, Fensterheber",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Fensterheber"
        },
        "06329": {
            "GenartName": "Fahrradträger, Heckklappe",
            "NormTermName": "Träger",
            "AssGrpName": "Transportvorrichtung",
            "UsageName": "Heckklappe"
        },
        "06330": {
            "GenartName": "Reinigung SCR-Katalysator",
            "NormTermName": "Reinigung SCR-Katalysator",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "06331": {
            "GenartName": "Reinigung Katalysator",
            "NormTermName": "Reinigung Katalysator",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "06332": {
            "GenartName": "Aufsatz, Werkzeugwagen",
            "NormTermName": "Aufsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06333": {
            "GenartName": "Anti-Rutsch-Matte, Schublade (Werkzeugwagen)",
            "NormTermName": "Matte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schublade (Werkzeugwagen)"
        },
        "06334": {
            "GenartName": "Rollensatz, Werkzeugwagen",
            "NormTermName": "Rollensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06335": {
            "GenartName": "Rolle, Werkzeugwagen",
            "NormTermName": "Rolle",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06336": {
            "GenartName": "Anhängekasten, Werkzeugwagen",
            "NormTermName": "Kasten",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06337": {
            "GenartName": "Dosenhalter, Werkzeugwagen",
            "NormTermName": "Halter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06338": {
            "GenartName": "Papierrollenhalter, Werkzeugwagen",
            "NormTermName": "Papierrollenhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06339": {
            "GenartName": "Werkzeugkasten",
            "NormTermName": "Kasten",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeug"
        },
        "06340": {
            "GenartName": "Kabelreparatursatz, Wischermotor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Wischermotor"
        },
        "06341": {
            "GenartName": "Kabelreparatursatz, Lambdasonde",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Lambdasonde"
        },
        "06342": {
            "GenartName": "Kabelreparatursatz, Luftmassenmesser",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Luftmassenmesser"
        },
        "06343": {
            "GenartName": "Kabelreparatursatz, Blinkleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Signalanlage",
            "UsageName": "Blinkleuchte"
        },
        "06344": {
            "GenartName": "Kabelreparatursatz, Raddrehzahlsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Raddrehzahl"
        },
        "06345": {
            "GenartName": "Kabelreparatursatz, Seitenmarkierungsleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "06346": {
            "GenartName": "Kabelreparatursatz, Heckleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Heckleuchte"
        },
        "06347": {
            "GenartName": "Kabelreparatursatz, Hauptscheinwerfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "06348": {
            "GenartName": "Kabelreparatursatz, Zentralelektrik",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz",
            "UsageName": "Zentralelektrik"
        },
        "06349": {
            "GenartName": "Kabelreparatursatz, AGR-Ventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "AGR-Ventil"
        },
        "06350": {
            "GenartName": "Kabelreparatursatz, Einspritzventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzventil"
        },
        "06351": {
            "GenartName": "Kabelreparatursatz, Saugrohrdrucksensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Saugrohrdrucksensor"
        },
        "06352": {
            "GenartName": "Kabelreparatursatz, Begrenzungsleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Begrenzungsleuchte"
        },
        "06353": {
            "GenartName": "Kabelreparatursatz, Schalter (Rückfahrleuchte)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Schalter (Rückfahrleuchte)"
        },
        "06354": {
            "GenartName": "Kabelreparatursatz, Wischermotor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": "Wischermotor"
        },
        "06355": {
            "GenartName": "Kabelreparatursatz, Lambdasonde",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Lambdasonde"
        },
        "06356": {
            "GenartName": "Kabelreparatursatz, Luftmassenmesser",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Luftmassenmesser"
        },
        "06357": {
            "GenartName": "Kabelreparatursatz, Blinkleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Blinkleuchte"
        },
        "06358": {
            "GenartName": "Kabelreparatursatz, Raddrehzahlsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Raddrehzahlsensor"
        },
        "06359": {
            "GenartName": "Kabelreparatursatz, Seitenmarkierungsleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "06360": {
            "GenartName": "Kabelreparatursatz, Heckleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Heckleuchte"
        },
        "06361": {
            "GenartName": "Kabelreparatursatz, Hauptscheinwerfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "06362": {
            "GenartName": "Kabelreparatursatz, Zentralelektrik",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz, Universal",
            "UsageName": "Zentralelektrik"
        },
        "06363": {
            "GenartName": "Kabelreparatursatz, AGR-Ventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasrückführung, Universal",
            "UsageName": "AGR-Ventil"
        },
        "06364": {
            "GenartName": "Kabelreparatursatz, Einspritzventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Einspritzventil"
        },
        "06365": {
            "GenartName": "Kabelreparatursatz, Saugrohrdrucksensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Saugrohrdrucksensor"
        },
        "06366": {
            "GenartName": "Kabelreparatursatz, Begrenzungsleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Begrenzungsleuchte"
        },
        "06367": {
            "GenartName": "Kabelreparatursatz, Schalter (Rückfahrleuchte)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Schalter (Rückfahrleuchte)"
        },
        "06368": {
            "GenartName": "Schalter, Ausfahren (Hydraulikzylinder)",
            "NormTermName": "Schalter",
            "AssGrpName": "Nebenantrieb",
            "UsageName": "Ausfahren (Hydraulikzylinder)"
        },
        "06369": {
            "GenartName": "Schalter, Heben (Hubwerk)",
            "NormTermName": "Schalter",
            "AssGrpName": "Nebenantrieb",
            "UsageName": "Heben (Hubwerk)"
        },
        "06370": {
            "GenartName": "Schalter, Senken (Hubwerk)",
            "NormTermName": "Schalter",
            "AssGrpName": "Nebenantrieb",
            "UsageName": "Senken (Hubwerk)"
        },
        "06371": {
            "GenartName": "Schalter, Einfahren (Hydraulikzylinder)",
            "NormTermName": "Schalter",
            "AssGrpName": "Nebenantrieb",
            "UsageName": "Einfahren (Hydraulikzylinder)"
        },
        "06372": {
            "GenartName": "Schalter, Heben/Senken (Anbauteile)",
            "NormTermName": "Schalter",
            "AssGrpName": "Nebenantrieb",
            "UsageName": "Heben/Senken (Anbaugeräte)"
        },
        "06373": {
            "GenartName": "Schalter, Ein-/Ausschalten (Anbaugeräte)",
            "NormTermName": "Schalter",
            "AssGrpName": "Nebenantrieb",
            "UsageName": "Ein-/Ausschalten (Anbaugeräte)"
        },
        "06374": {
            "GenartName": "Ankerschiene, Ladungssicherung",
            "NormTermName": "Ankerschiene",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "06375": {
            "GenartName": "Balkenschuh, Ankerschiene",
            "NormTermName": "Balkenschuh",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Ankerschiene"
        },
        "06376": {
            "GenartName": "Desinfektionsmittel",
            "NormTermName": "Desinfektionsmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06377": {
            "GenartName": "Bithalter",
            "NormTermName": "Halter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bohrerbit"
        },
        "06378": {
            "GenartName": "Vergrößerungsadapter, Knarre",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Knarre"
        },
        "06379": {
            "GenartName": "Reduzieradapter, Knarre",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06380": {
            "GenartName": "Schutzhelm",
            "NormTermName": "Schutzhelm",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06381": {
            "GenartName": "Prüfgerät, Schutzhandschuhe",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schutzhandschuhe"
        },
        "06382": {
            "GenartName": "Überzieh-Handschuhe",
            "NormTermName": "Handschuhe",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Arbeitskleidung"
        },
        "06383": {
            "GenartName": "Unterzieh-Handschuhe",
            "NormTermName": "Handschuhe",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Schutzhandschuhe"
        },
        "06384": {
            "GenartName": "Aufbewahrungstasche, Elektriker-Schutzhandschuhe",
            "NormTermName": "Tasche",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Elektriker-Schutzhandschuhe"
        },
        "06385": {
            "GenartName": "Gesichtsschutzschirm, Schutzhelm",
            "NormTermName": "Gesichtsschutzschirm",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Schutzhelm"
        },
        "06386": {
            "GenartName": "Tragetasche, Schutzhelm",
            "NormTermName": "Tasche",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schutzhelm"
        },
        "06387": {
            "GenartName": "Absperrband",
            "NormTermName": "Absperrband",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06388": {
            "GenartName": "Warnschild",
            "NormTermName": "Warnschild",
            "AssGrpName": "Normteile",
            "UsageName": "Elektrische Spannung"
        },
        "06389": {
            "GenartName": "Verbotsschild",
            "NormTermName": "Verbotsschild",
            "AssGrpName": "Normteile",
            "UsageName": "Schalten Verboten"
        },
        "06390": {
            "GenartName": "Plombenzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Plombe"
        },
        "06391": {
            "GenartName": "Wasserumwälzpumpe, Standheizung",
            "NormTermName": "Pumpe",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Standheizung"
        },
        "06393": {
            "GenartName": "Mitnehmerscheibe, Servopumpe",
            "NormTermName": "Mitnehmerscheibe",
            "AssGrpName": "Lenkung",
            "UsageName": "Servopumpe"
        },
        "06394": {
            "GenartName": "Steckschlüssel, Lambdasonde",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Lambdasonde"
        },
        "06395": {
            "GenartName": "Zange, Abgasrohrschelle",
            "NormTermName": "Zange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Abgasrohrschelle"
        },
        "06396": {
            "GenartName": "Reinigungswerkzeug, Abgasrückführungsgehäuse",
            "NormTermName": "Reinigungswerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Abgasrückführungsgehäuse"
        },
        "06397": {
            "GenartName": "Kraft-Stecknuss, Antriebswelle",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Antriebswelle"
        },
        "06398": {
            "GenartName": "Spanner, Schlauchschelle",
            "NormTermName": "Spanner",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Schlauchschelle"
        },
        "06399": {
            "GenartName": "Feststellzange, Schlauchschelle",
            "NormTermName": "Feststellzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlauchschelle"
        },
        "06400": {
            "GenartName": "Zange, Schnellkupplung-Kraftstoffleitung",
            "NormTermName": "Zange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Schnellkupplung-Kraftstoffleitung"
        },
        "06401": {
            "GenartName": "Antriebsflansch, Riemenscheibe",
            "NormTermName": "Flansch",
            "AssGrpName": "Riementrieb",
            "UsageName": "Riemenscheibe"
        },
        "06402": {
            "GenartName": "Stellelementsatz, Einspritzpumpe",
            "NormTermName": "Stellelementsatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpe"
        },
        "06403": {
            "GenartName": "Stellelement, Kühler-Abgasrückführung",
            "NormTermName": "Stellelement",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "Kühler-Abgasrückführung"
        },
        "06404": {
            "GenartName": "Werkstatt-Servicewagen",
            "NormTermName": "Werkstatt-Servicewagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06405": {
            "GenartName": "Plattformwagen",
            "NormTermName": "Plattformwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06406": {
            "GenartName": "Arbeitsplattform",
            "NormTermName": "Arbeitsplattform",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06407": {
            "GenartName": "Montagetritt",
            "NormTermName": "Montagetritt",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06408": {
            "GenartName": "Scheiben-Ablagetisch",
            "NormTermName": "Scheiben-Ablagetisch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06409": {
            "GenartName": "Farbmischbecher",
            "NormTermName": "Farbmischbecher",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06410": {
            "GenartName": "Papierrollenwagen",
            "NormTermName": "Papierrollenwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06411": {
            "GenartName": "Gitterbox",
            "NormTermName": "Gitterbox",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06412": {
            "GenartName": "Transportkarre",
            "NormTermName": "Transportkarre",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06413": {
            "GenartName": "Flaschenwagen",
            "NormTermName": "Flaschenwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06414": {
            "GenartName": "Lenkradschoner",
            "NormTermName": "Lenkradschoner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06415": {
            "GenartName": "Schalthebelschoner",
            "NormTermName": "Schalthebelschoner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06416": {
            "GenartName": "Handbremshebelschoner",
            "NormTermName": "Handbremshebelschoner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06417": {
            "GenartName": "Sitzschoner",
            "NormTermName": "Sitzschoner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06418": {
            "GenartName": "Fußraumschoner",
            "NormTermName": "Fußraumschoner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06419": {
            "GenartName": "Schoner-Satz, Innenraum",
            "NormTermName": "Schoner-Satz, Innenraum",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06420": {
            "GenartName": "Reifentasche",
            "NormTermName": "Reifentasche",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06421": {
            "GenartName": "Beutel, Radschrauben",
            "NormTermName": "Beutel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Radschrauben"
        },
        "06422": {
            "GenartName": "Fahrzeugfrontschoner",
            "NormTermName": "Fahrzeugfrontschoner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06423": {
            "GenartName": "Mechaniker-Schutzmatte",
            "NormTermName": "Mechaniker-Schutzmatte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06424": {
            "GenartName": "Werkstattliege",
            "NormTermName": "Werkstattliege",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06425": {
            "GenartName": "Werkstatthocker",
            "NormTermName": "Werkstatthocker",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06426": {
            "GenartName": "Rangierrollwagen",
            "NormTermName": "Rangierrollwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06427": {
            "GenartName": "Rangierhilfe",
            "NormTermName": "Rangierhilfe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06428": {
            "GenartName": "Aufbewahrungsgestell, Rangierhilfe",
            "NormTermName": "Aufbewahrungsgestell",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Rangierhilfe"
        },
        "06429": {
            "GenartName": "Unterleg-Keil",
            "NormTermName": "Unterleg-Keil",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06430": {
            "GenartName": "Auffahrrampe",
            "NormTermName": "Auffahrrampe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06431": {
            "GenartName": "Unterstellstütze",
            "NormTermName": "Unterstellstütze",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06432": {
            "GenartName": "Werkstattkran",
            "NormTermName": "Werkstattkran",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06433": {
            "GenartName": "Motor-Stütztraverse",
            "NormTermName": "Motor-Stütztraverse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06434": {
            "GenartName": "Lastenpositionier-Vorrichtung, Werkstattkran",
            "NormTermName": "Lastenpositionier-Vorrichtung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkstattkran"
        },
        "06435": {
            "GenartName": "Montagevorrichtung, Universal",
            "NormTermName": "Montagevorrichtung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Universal"
        },
        "06436": {
            "GenartName": "Reinigungsstation",
            "NormTermName": "Reinigungsstation",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06437": {
            "GenartName": "Befüllschlauch, Getriebe",
            "NormTermName": "Schlauch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Getriebe"
        },
        "06438": {
            "GenartName": "Befülladapter-Satz, Getriebe",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06439": {
            "GenartName": "Befüllschlauch, Lamellenkupplung-Allradantrieb",
            "NormTermName": "Schlauch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lamellenkupplung-Allradantrieb"
        },
        "06440": {
            "GenartName": "Öl, Druckluftwerkzeuge",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Drucklufttechnik"
        },
        "06441": {
            "GenartName": "Ölkanne",
            "NormTermName": "Ölkanne",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06442": {
            "GenartName": "Ölstift",
            "NormTermName": "Ölstift",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06443": {
            "GenartName": "Adaptersatz, Fettpresse",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fettpresse"
        },
        "06444": {
            "GenartName": "Schlauch, Fettpresse",
            "NormTermName": "Schlauch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fettpresse"
        },
        "06445": {
            "GenartName": "Düse, Fettpresse",
            "NormTermName": "Düse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fettpresse"
        },
        "06446": {
            "GenartName": "Düsenverlängerung, Fettpresse",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fettpressendüse"
        },
        "06447": {
            "GenartName": "Befüllschlauch, Füll-/ Entnahmepumpe",
            "NormTermName": "Schlauch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Füll-/Entnahmepumpe"
        },
        "06448": {
            "GenartName": "Messbecher-Satz",
            "NormTermName": "Messbecher-Satz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06449": {
            "GenartName": "Trichter-Satz",
            "NormTermName": "Trichter-Satz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06450": {
            "GenartName": "Wandhalterung, Putz-Papierrolle",
            "NormTermName": "Halterung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Putz-Papierrolle"
        },
        "06451": {
            "GenartName": "Bodenständer, Putz-Papierrolle",
            "NormTermName": "Ständer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Putz-Papierrolle"
        },
        "06452": {
            "GenartName": "Magnetschale",
            "NormTermName": "Magnetschale",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06453": {
            "GenartName": "Magnethalter",
            "NormTermName": "Magnethalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06454": {
            "GenartName": "Fasstrichter",
            "NormTermName": "Fasstrichter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06455": {
            "GenartName": "Fasspumpe",
            "NormTermName": "Fasspumpe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06456": {
            "GenartName": "Druckluftpistolen-Satz",
            "NormTermName": "Druckluftpistolen-Satz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06457": {
            "GenartName": "Schutzschild, Druckluftpistole",
            "NormTermName": "Schutzschild",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckluftpistole"
        },
        "06458": {
            "GenartName": "Lösehebel, Radzierblende",
            "NormTermName": "Lösehebel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Radzierblende"
        },
        "06459": {
            "GenartName": "Drehmomentbegrenzer-Satz",
            "NormTermName": "Drehmomentbegrenzer-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06460": {
            "GenartName": "Kraft-Stecknuss-Satz",
            "NormTermName": "Kraft-Stecknuss-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06461": {
            "GenartName": "Gegenhalter, Reifendruck-Kontrollsystem",
            "NormTermName": "Gegenhalter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "06462": {
            "GenartName": "Entlüfter-Satz, Reifendruck-Kontrollsystem",
            "NormTermName": "Entlüfter-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "06463": {
            "GenartName": "Montagewerkzeug, Ventildichtung (Reifendruck-Kontrollsystem)",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventildichtung (Reifendruck-Kontrollsystem)"
        },
        "06464": {
            "GenartName": "Ventileinsatz",
            "NormTermName": "Ventileinsatz",
            "AssGrpName": "Räder, Universal",
            "UsageName": null
        },
        "06465": {
            "GenartName": "Sortiment, Reifendruck-Kontrollsystem",
            "NormTermName": "Sortiment",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "06466": {
            "GenartName": "Gewindestrehler",
            "NormTermName": "Gewindestrehler",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06467": {
            "GenartName": "Prüfgerät, Reifendruck",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Reifendruck"
        },
        "06468": {
            "GenartName": "Prüfgerät, Reifendruck/ Profiltiefe",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Reifendruck/ Profiltiefe"
        },
        "06469": {
            "GenartName": "Füllnippel, Druckluftreifenprüfer/-füller",
            "NormTermName": "Nippel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckluftreifenprüfer/-füller"
        },
        "06470": {
            "GenartName": "Wulstniederhalter, Reifenmontiermaschine",
            "NormTermName": "Wulstniederhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reifenmontiermaschine"
        },
        "06471": {
            "GenartName": "Gewindeschneidwerkzeug, Reifenventil",
            "NormTermName": "Gewindeschneidwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Reifenventil"
        },
        "06472": {
            "GenartName": "Stecknuss, Reifenventildemontage/-montage",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Reifenventileinsatz"
        },
        "06473": {
            "GenartName": "Schraubendreher, Reifenventileinsatz",
            "NormTermName": "Schraubendreher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Reifenventileinsatz"
        },
        "06474": {
            "GenartName": "Einziehhebel, Reifenventil",
            "NormTermName": "Hebel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Reifenventil"
        },
        "06475": {
            "GenartName": "Ventileinzieher, Reifen",
            "NormTermName": "Ventileinzieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Reifen"
        },
        "06476": {
            "GenartName": "Demontagewerkzeug, Klebegewicht",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Klebegewicht"
        },
        "06477": {
            "GenartName": "Schere, Klebegewicht",
            "NormTermName": "Schere",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Klebegewicht"
        },
        "06478": {
            "GenartName": "Demontagewerkzeugsatz, Klebegewicht",
            "NormTermName": "Demontagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Klebegewicht"
        },
        "06479": {
            "GenartName": "Pinsel, Reifenmontagepaste",
            "NormTermName": "Pinsel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Reifenmontagepaste"
        },
        "06480": {
            "GenartName": "Reifenmontagepaste",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Reifenmontage"
        },
        "06481": {
            "GenartName": "Signierkreide",
            "NormTermName": "Signierkreide",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06482": {
            "GenartName": "Reifenvulkanisier-Satz",
            "NormTermName": "Reifenvulkanisier-Satz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06483": {
            "GenartName": "Stahlcordfräser",
            "NormTermName": "Stahlcordfräser",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06484": {
            "GenartName": "Steckschlüsseleinsatz, Ölablassschraube",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ölablassschraube"
        },
        "06485": {
            "GenartName": "Demontagewerkzeugsatz, Bremsleuchte",
            "NormTermName": "Demontagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremsleuchte"
        },
        "06486": {
            "GenartName": "Kunststoffreparatursatz",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kunststoffreparatur"
        },
        "06487": {
            "GenartName": "Rohrsteckschlüssel, Stoßfänger",
            "NormTermName": "Rohrsteckschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Stoßfänger"
        },
        "06488": {
            "GenartName": "Kunststoffreparaturklammer",
            "NormTermName": "Kunststoffreparaturklammer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06489": {
            "GenartName": "Lötkolben, Kunststoffreparatur",
            "NormTermName": "Lötkolben",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kunststoffreparatur"
        },
        "06490": {
            "GenartName": "Bewehrungsmatte, Kunststoffreparatur",
            "NormTermName": "Bewehrungsmatte",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kunststoffreparatur"
        },
        "06491": {
            "GenartName": "Zapfenschlüssel, Sensor-Einparkhilfe",
            "NormTermName": "Zapfenschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Sensor-Einparkhilfe"
        },
        "06492": {
            "GenartName": "Lochstanzen-Satz, Sensor-Einparkhilfe",
            "NormTermName": "Lochstanzen-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Sensor-Einparkhilfe"
        },
        "06493": {
            "GenartName": "Induktions-Heizpistole",
            "NormTermName": "Induktions-Heizpistole",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06494": {
            "GenartName": "Induktions-Spule, Heizpistole",
            "NormTermName": "Induktions-Spule",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Induktions-Heizpistole"
        },
        "06495": {
            "GenartName": "Induktions-Kissen, Heizpistole",
            "NormTermName": "Induktions-Kissen",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Induktions-Heizpistole"
        },
        "06496": {
            "GenartName": "Induktions-Heizpistolen-Satz",
            "NormTermName": "Induktions-Heizpistolen-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06497": {
            "GenartName": "Saugersatz, Zierleistenmontage",
            "NormTermName": "Saugersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zierleistenmontage"
        },
        "06498": {
            "GenartName": "Steckschlüssel, Türscharnier",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Türscharnier"
        },
        "06499": {
            "GenartName": "Nutmutternschlüssel",
            "NormTermName": "Nutmutternschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06500": {
            "GenartName": "Austreibersatz, Türscharnier-Spannstift",
            "NormTermName": "Austreibersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Türscharnier-Spannstift"
        },
        "06501": {
            "GenartName": "Türrichteisen",
            "NormTermName": "Türrichteisen",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06502": {
            "GenartName": "Druckkissen",
            "NormTermName": "Druckkissen",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06503": {
            "GenartName": "Druckkissensatz",
            "NormTermName": "Druckkissensatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06504": {
            "GenartName": "Öffnungsdraht, Fahrzeugtür",
            "NormTermName": "Öffnungsdraht",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Fahrzeugtür"
        },
        "06505": {
            "GenartName": "Öffnungsblech, Fahrzeugtür",
            "NormTermName": "Öffnungsblech",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Fahrzeugtür"
        },
        "06506": {
            "GenartName": "Öffner-Satz, Fahrzeugtür",
            "NormTermName": "Öffner-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Fahrzeugtür"
        },
        "06507": {
            "GenartName": "Zange, Außenspiegel",
            "NormTermName": "Zange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Außenspiegel"
        },
        "06508": {
            "GenartName": "Meißelsatz",
            "NormTermName": "Meißelsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06510": {
            "GenartName": "Kühlschmiermittelstift",
            "NormTermName": "Kühlschmiermittelstift",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06511": {
            "GenartName": "Schweißpunktfräser",
            "NormTermName": "Fräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ausbohren Schweißpunkte"
        },
        "06512": {
            "GenartName": "Schweißpunktbohrer",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ausbohren Schweißpunkte"
        },
        "06513": {
            "GenartName": "Gleithammer",
            "NormTermName": "Gleithammer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06514": {
            "GenartName": "Gleithammer-Satz",
            "NormTermName": "Gleithammer-Satz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06515": {
            "GenartName": "Kantenschlageisen",
            "NormTermName": "Kantenschlageisen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06518": {
            "GenartName": "Nachrüstsatz, Schweißgerät",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schweißgerät"
        },
        "06519": {
            "GenartName": "Verbrauchsmaterial, Schweißgerät",
            "NormTermName": "Verbrauchsmaterial",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schweißgerät"
        },
        "06520": {
            "GenartName": "Spannelement, Karosserietechnik",
            "NormTermName": "Spannelement",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserietechnik"
        },
        "06521": {
            "GenartName": "Greifbacke, Spannelement",
            "NormTermName": "Greifbacke",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Spannelement-Karosserietechnik"
        },
        "06522": {
            "GenartName": "Ausbeulhammer-Satz",
            "NormTermName": "Ausbeulhammer-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06523": {
            "GenartName": "Ausbeuleisen",
            "NormTermName": "Ausbeuleisen",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06524": {
            "GenartName": "Ausbeuleisen-Satz",
            "NormTermName": "Ausbeuleisen-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06525": {
            "GenartName": "Rückschlagdorn",
            "NormTermName": "Rückschlagdorn",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06526": {
            "GenartName": "Rückschlagdorn-Satz",
            "NormTermName": "Rückschlagdorn-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06527": {
            "GenartName": "Ausbeulhebel",
            "NormTermName": "Ausbeulhebel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06528": {
            "GenartName": "Ausbeulhebel-Satz",
            "NormTermName": "Ausbeulhebel-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06529": {
            "GenartName": "Dellenspiegel",
            "NormTermName": "Dellenspiegel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06530": {
            "GenartName": "Klebestück, Ausbeulwerkzeug (Dellenreparatur)",
            "NormTermName": "Klebestück",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ausbeulwerkzeug"
        },
        "06531": {
            "GenartName": "Klebestück-Satz, Ausbeulwerkzeug (Dellenreparatur)",
            "NormTermName": "Klebestück-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ausbeulwerkzeug"
        },
        "06532": {
            "GenartName": "Aluminiumhammer",
            "NormTermName": "Aluminiumhammer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06533": {
            "GenartName": "Riffelhammer",
            "NormTermName": "Riffelhammer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06534": {
            "GenartName": "Schlichthammer",
            "NormTermName": "Schlichthammer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06535": {
            "GenartName": "Spitzhammer",
            "NormTermName": "Spitzhammer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06536": {
            "GenartName": "Flachspitzhammer",
            "NormTermName": "Flachspitzhammer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06537": {
            "GenartName": "Wölbungshammer",
            "NormTermName": "Wölbungshammer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06538": {
            "GenartName": "Kotflügel-Ausbeul-Hammer",
            "NormTermName": "Kotflügel-Ausbeul-Hammer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06539": {
            "GenartName": "Ausbeul-Satz",
            "NormTermName": "Ausbeul-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06540": {
            "GenartName": "Konturhebel",
            "NormTermName": "Konturhebel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06541": {
            "GenartName": "Ausbeullöffel",
            "NormTermName": "Ausbeullöffel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06542": {
            "GenartName": "Falz-Richtwerkzeug",
            "NormTermName": "Falz-Richtwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06543": {
            "GenartName": "Umschlagwerkzeug",
            "NormTermName": "Umschlagwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06544": {
            "GenartName": "Profilrichtblock",
            "NormTermName": "Profilrichtblock",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06545": {
            "GenartName": "Wischarm-Satz, Scheibenreinigung",
            "NormTermName": "Wischarm-Satz",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Scheibenreinigungsanlage"
        },
        "06546": {
            "GenartName": "Falzzange",
            "NormTermName": "Falzzange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06547": {
            "GenartName": "Absetzzange",
            "NormTermName": "Absetzzange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06548": {
            "GenartName": "Handtafelschere",
            "NormTermName": "Handtafelschere",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06549": {
            "GenartName": "Karosserieklemmen-Satz",
            "NormTermName": "Karosserieklemmen-Satz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06550": {
            "GenartName": "Halter, Feststellzange",
            "NormTermName": "Halter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Feststellzange"
        },
        "06551": {
            "GenartName": "Blindnietzangen-Satz",
            "NormTermName": "Blindnietzangen-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06552": {
            "GenartName": "Sortiment, Blindniet",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Blindniet"
        },
        "06553": {
            "GenartName": "Sortiment, Gewindeniet",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Gewindeniet"
        },
        "06554": {
            "GenartName": "Kappe",
            "NormTermName": "Kappe",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Kopfschutz"
        },
        "06555": {
            "GenartName": "Schutzhelm, Schweißarbeiten",
            "NormTermName": "Schutzhelm",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Schweißarbeiten"
        },
        "06556": {
            "GenartName": "Schutzbrille",
            "NormTermName": "Schutzbrille",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Augenschutz"
        },
        "06557": {
            "GenartName": "Ohrstöpsel",
            "NormTermName": "Ohrstöpsel",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Gehörschutz"
        },
        "06558": {
            "GenartName": "Kapselgehörschutz",
            "NormTermName": "Kapselgehörschutz",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Gehörschutz"
        },
        "06559": {
            "GenartName": "Schweißerjacke",
            "NormTermName": "Schweißerjacke",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06560": {
            "GenartName": "Schweißer-Armstulpen",
            "NormTermName": "Schweißer-Armstulpen",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Schweißarbeiten"
        },
        "06561": {
            "GenartName": "Schweißer-Schutzhandschuhe",
            "NormTermName": "Schutzhandschuh",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Schweißarbeiten"
        },
        "06562": {
            "GenartName": "Elektriker-Schutzhandschuhe",
            "NormTermName": "Elektriker-Schutzhandschuhe",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06563": {
            "GenartName": "Flammschutzmatte",
            "NormTermName": "Flammschutzmatte",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06564": {
            "GenartName": "Flammschutzmatten-Satz",
            "NormTermName": "Flammschutzmatten-Satz",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06565": {
            "GenartName": "Schweißerschürze",
            "NormTermName": "Schweißerschürze",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Schweißarbeiten"
        },
        "06566": {
            "GenartName": "Schweißerschutzplane",
            "NormTermName": "Schweißerschutzplane",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Schweißarbeiten"
        },
        "06567": {
            "GenartName": "Schweißerschutzplanen-Satz",
            "NormTermName": "Schweißerschutzplanen-Satz",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Schweißarbeiten"
        },
        "06568": {
            "GenartName": "Sicherheitsschuhe",
            "NormTermName": "Sicherheitsschuhe",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06569": {
            "GenartName": "Schutzschuhe",
            "NormTermName": "Schutzschuhe",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06570": {
            "GenartName": "Berufsschuhe",
            "NormTermName": "Berufsschuhe",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06571": {
            "GenartName": "Mundschutzmaske",
            "NormTermName": "Mundschutzmaske",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06572": {
            "GenartName": "Atemschutzmaske",
            "NormTermName": "Atemschutzmaske",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06573": {
            "GenartName": "Filter, Atemschutzmaske",
            "NormTermName": "Filter",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Atemschutzmaske"
        },
        "06574": {
            "GenartName": "Feststellzangen-Satz",
            "NormTermName": "Feststellzangen-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06575": {
            "GenartName": "Karosserieschutzmatte",
            "NormTermName": "Karosserieschutzmatte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06577": {
            "GenartName": "Lötholz-Satz",
            "NormTermName": "Lötholz-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06578": {
            "GenartName": "Schleifklotz",
            "NormTermName": "Schleifklotz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06579": {
            "GenartName": "Radierteller",
            "NormTermName": "Radierteller",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06581": {
            "GenartName": "Topfbürste, Winkelschleifer",
            "NormTermName": "Topfbürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "06582": {
            "GenartName": "Pinselbürste, Bohrmaschine",
            "NormTermName": "Pinselbürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bohrmaschine"
        },
        "06583": {
            "GenartName": "Bürstenscheibe, Bohrmaschine",
            "NormTermName": "Bürstenscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bohrmaschine"
        },
        "06584": {
            "GenartName": "Rundbürste, Winkelschleifer",
            "NormTermName": "Rundbürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "06585": {
            "GenartName": "Demontagewerkzeug, Kombi-Instrument",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kombi-Instrument"
        },
        "06586": {
            "GenartName": "Demontagewerkzeugsatz, Airbag",
            "NormTermName": "Demontagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Airbag"
        },
        "06587": {
            "GenartName": "Montagewerkzeug, Türschloss/Schließzylindergehäuse",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Türschloss/Schließzylindergehäuse"
        },
        "06588": {
            "GenartName": "Montage-/Demontagewerkzeug-Satz, Radio",
            "NormTermName": "Montage-/Demontagewerkzeug-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radio"
        },
        "06589": {
            "GenartName": "Lösezange, Kunststoffspreiznieten",
            "NormTermName": "Lösezange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kunststoffspreiznieten"
        },
        "06590": {
            "GenartName": "Lösezangensatz, Kunststoffspreiznieten",
            "NormTermName": "Lösezangensatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kunststoffspreiznieten"
        },
        "06591": {
            "GenartName": "Konturen-Lehre",
            "NormTermName": "Konturen-Lehre",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06592": {
            "GenartName": "Fühlerlehre, Spaltmaß",
            "NormTermName": "Lehre",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Karosserie-Spaltmaß"
        },
        "06593": {
            "GenartName": "Fühlerlehrensatz, Spaltmaß",
            "NormTermName": "Lehrensatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Karosserie-Spaltmaß"
        },
        "06594": {
            "GenartName": "Thermomanagementmodul",
            "NormTermName": "Thermomanagementmodul",
            "AssGrpName": "Kühlung",
            "UsageName": null
        },
        "06595": {
            "GenartName": "Rahmen, Schweißerschutzplane",
            "NormTermName": "Rahmen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schweißerschutzplane"
        },
        "06596": {
            "GenartName": "Drehmoment-Schraubendreher",
            "NormTermName": "Schraubendreher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmoment"
        },
        "06597": {
            "GenartName": "Schraube, Einspritzventilhalter",
            "NormTermName": "Schraube",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzventilhalter"
        },
        "06598": {
            "GenartName": "Drehmomentbegrenzer, Zündkerze",
            "NormTermName": "Drehmomentbegrenzer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zündkerze"
        },
        "06599": {
            "GenartName": "Drehwinkelmessscheibe",
            "NormTermName": "Drehwinkelmessscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06600": {
            "GenartName": "Umsteckvierkant, Drehmomentschlüssel",
            "NormTermName": "Umsteckvierkant",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "06601": {
            "GenartName": "Durchsteckvierkant, Drehmomentschlüssel",
            "NormTermName": "Durchsteckvierkant",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "06602": {
            "GenartName": "Drehmomentschlüssel-Satz",
            "NormTermName": "Drehmomentschlüssel-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06603": {
            "GenartName": "Anschlussstutzen, Fördermodulkühlung (Harnstoffeinspritzung)",
            "NormTermName": "Stutzen",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Fördermodulkühlung (Harnstoffeinspritzung)"
        },
        "06604": {
            "GenartName": "Ventilsatz, Fördermodul (Harnstoffeinspritzung)",
            "NormTermName": "Ventilsatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Fördermodul (Harnstoffeinspritzung)"
        },
        "06605": {
            "GenartName": "Hebelarmverlängerung, Drehmomentschlüssel",
            "NormTermName": "Hebelarmverlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "06606": {
            "GenartName": "Ansaugstutzen, Fördermodul (Harnstoffeinspritzung)",
            "NormTermName": "Stutzen",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Fördermodul (Harnstoffeinspritzung)"
        },
        "06607": {
            "GenartName": "Rücklaufstutzen, Fördermodul (Harnstoffeinspritzung)",
            "NormTermName": "Stutzen",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Fördermodul-Rücklauf (Harnstoffeinspritzung)"
        },
        "06608": {
            "GenartName": "Einsteck-Ringschlüssel, Drehmomentschlüssel",
            "NormTermName": "Einsteck-Ringschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "06610": {
            "GenartName": "Einsteck-Gabelschlüssel, Drehmomentschlüssel",
            "NormTermName": "Einsteck-Gabelschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "06611": {
            "GenartName": "Einsteck-Vierkantschlüssel, Drehmomentschlüssel",
            "NormTermName": "Einsteck-Vierkantschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "06612": {
            "GenartName": "Einsteck-Umschaltratschenkopf, Drehmomentschlüssel",
            "NormTermName": "Einsteck-Umschaltratschenkopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "06613": {
            "GenartName": "Einsteck-Bithalter, Drehmomentschlüssel",
            "NormTermName": "Einsteck-Bithalter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "06614": {
            "GenartName": "Einsteck-Vergrößerungsadapter, Drehmomentschlüssel",
            "NormTermName": "Einsteck-Vergrößerungsadapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "06615": {
            "GenartName": "Einsteck-Anschweißstück, Drehmomentschlüssel",
            "NormTermName": "Einsteck-Anschweißstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "06616": {
            "GenartName": "Einsteck-Verkleinerungsadapter, Drehmomentschlüssel",
            "NormTermName": "Einsteck-Verkleinerungsadapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "06617": {
            "GenartName": "Vierkantadapter-Bit",
            "NormTermName": "Vierkantadapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schrauberbit"
        },
        "06618": {
            "GenartName": "Vierkantadapter-Bitsatz",
            "NormTermName": "Vierkantadapter-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schrauberbit"
        },
        "06619": {
            "GenartName": "Pumpe, Fördermodul (Harnstoffeinspritzung)",
            "NormTermName": "Pumpe",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Fördermodul (Harnstoffeinspritzung)"
        },
        "06620": {
            "GenartName": "Beheizung, Tankeinheit (Harnstoffeinspritzung)",
            "NormTermName": "Heizung",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Tankeinheit (Harnstoffeinspritzung)"
        },
        "06621": {
            "GenartName": "Montagesatz, Fördermodul (Harnstoffeinspritzung)",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Fördermodul (Harnstoffeinspritzung)"
        },
        "06622": {
            "GenartName": "Montagesatz, Dosiermodul (Harnstoffeinspritzung)",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Dosiermodul (Harnstoffeinspritzung)"
        },
        "06623": {
            "GenartName": "Bit-Schraubendreher",
            "NormTermName": "Bit-Schraubendreher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06624": {
            "GenartName": "Bit-Umschaltknarre",
            "NormTermName": "Bit-Umschaltknarre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06625": {
            "GenartName": "Schrauberbit-Satz",
            "NormTermName": "Schrauberbit-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06626": {
            "GenartName": "Bit-Doppelratschenringschlüssel",
            "NormTermName": "Bit-Ratschenringschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06627": {
            "GenartName": "Winkelschraubendrehersatz",
            "NormTermName": "Winkelschraubendreher-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06628": {
            "GenartName": "Schraubendrehergriff, Doppelklinge (Umsteckschraubendreher)",
            "NormTermName": "Schraubendrehergriff",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Doppelklinge (Umsteckschraubendreher)"
        },
        "06629": {
            "GenartName": "Doppelklinge, Umsteckschraubendreher",
            "NormTermName": "Doppelklinge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Umsteckschraubendreher"
        },
        "06630": {
            "GenartName": "Doppelklingensatz, Umsteckschraubendreher",
            "NormTermName": "Doppelklingensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Umsteckschraubendreher"
        },
        "06631": {
            "GenartName": "Bit-Schraubendrehersatz",
            "NormTermName": "Bit-Schraubendrehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06633": {
            "GenartName": "Schlagschraubendreher-Satz",
            "NormTermName": "Schlagschraubendreher-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06636": {
            "GenartName": "Gewindeaufrauhzange",
            "NormTermName": "Gewindeaufrauhzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06637": {
            "GenartName": "Ratschen-Ringgabelschlüssel",
            "NormTermName": "Ratschen-Ringgabelschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06638": {
            "GenartName": "Ratschen-Ringgabelschlüsselsatz",
            "NormTermName": "Ratschen-Ringgabelschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06639": {
            "GenartName": "Rollgabelschlüsselsatz",
            "NormTermName": "Rollgabelschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06640": {
            "GenartName": "Schlaggabelschlüssel",
            "NormTermName": "Schlaggabelschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06641": {
            "GenartName": "Doppel-Gabelschlüsselsatz",
            "NormTermName": "Doppel-Gabelschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06642": {
            "GenartName": "Verlängerung, Zugringschlüssel",
            "NormTermName": "Zugringschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06643": {
            "GenartName": "Ratschen-Ringschlüssel",
            "NormTermName": "Ratschen-Ringschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06644": {
            "GenartName": "Ratschen-Ringschlüsselsatz",
            "NormTermName": "Ratschen-Ringschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06645": {
            "GenartName": "Ratschen-Doppelringschlüssel",
            "NormTermName": "Ratschen-Doppelringschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06646": {
            "GenartName": "Ratschen-Doppelringschlüsselsatz",
            "NormTermName": "Ratschen-Doppelringschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06647": {
            "GenartName": "Starter-/Blockschlüsselsatz",
            "NormTermName": "Starter-/Blockschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06648": {
            "GenartName": "Doppel-Ringschlüsselsatz, Entlüfterschraube/-ventil",
            "NormTermName": "Doppel-Ringschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Entlüfterschraube/-ventil"
        },
        "06649": {
            "GenartName": "Doppel-Gelenkschlüsselsatz",
            "NormTermName": "Doppel-Gelenkschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06650": {
            "GenartName": "Stift, Rohrsteckschlüssel",
            "NormTermName": "Stift",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrsteckschlüssel"
        },
        "06651": {
            "GenartName": "Radmutternschlüsselsatz",
            "NormTermName": "Radmutternschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06652": {
            "GenartName": "Hakenschlüssel",
            "NormTermName": "Hakenschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06653": {
            "GenartName": "Zweilochmutterndreher",
            "NormTermName": "Zweilochmutterndreher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06654": {
            "GenartName": "Kelle",
            "NormTermName": "Kelle",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "06655": {
            "GenartName": "Reibebrett",
            "NormTermName": "Reibebrett",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "06656": {
            "GenartName": "Glättekelle",
            "NormTermName": "Glättekelle",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "06657": {
            "GenartName": "Mörtelkübel",
            "NormTermName": "Mörtelkübel",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "06658": {
            "GenartName": "Eimer",
            "NormTermName": "Eimer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06659": {
            "GenartName": "Gipsbecher",
            "NormTermName": "Gipsbecher",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "06660": {
            "GenartName": "Vergrößerungs-/Reduzieradapter-Satz, Knarre",
            "NormTermName": "Vergrößerungs-/Reduzieradapter-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Knarre"
        },
        "06661": {
            "GenartName": "Hülse, Steckschlüsseleinsatz (Radschraube)",
            "NormTermName": "Hülse",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Steckschlüsseleinsatz (Radschraube)"
        },
        "06662": {
            "GenartName": "Vierkant-Steckgriff",
            "NormTermName": "Vierkant-Steckgriff",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06663": {
            "GenartName": "Verlängerungssatz, Steckschlüssel",
            "NormTermName": "Verlängerungssatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steckschlüssel"
        },
        "06665": {
            "GenartName": "Vierkant-Schraubendreher",
            "NormTermName": "Vierkant-Schraubendreher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06666": {
            "GenartName": "Umschaltknarren-Satz",
            "NormTermName": "Umschaltknarren-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06667": {
            "GenartName": "Werkzeugsatz",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06668": {
            "GenartName": "Vierkant-Kurbel",
            "NormTermName": "Vierkant-Kurbel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06669": {
            "GenartName": "Steckschlüsselsatz, Zündkerze",
            "NormTermName": "Steckschlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zündkerze"
        },
        "06670": {
            "GenartName": "Innenauszieher",
            "NormTermName": "Innenauszieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06671": {
            "GenartName": "Gegenhalter, Innenauszieher",
            "NormTermName": "Gegenhalter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Innenauszieher"
        },
        "06672": {
            "GenartName": "Innenausziehersatz",
            "NormTermName": "Innenausziehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06673": {
            "GenartName": "Abziehersatz, Kugellager",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kugellager"
        },
        "06674": {
            "GenartName": "Hakensatz, Kugellagerabzieher",
            "NormTermName": "Hakensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kugellagerabzieher"
        },
        "06675": {
            "GenartName": "Trennmesser",
            "NormTermName": "Trennmesser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06676": {
            "GenartName": "Abziehvorrichtung, Trennmesser",
            "NormTermName": "Abziehvorrichtung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Trennmesser"
        },
        "06677": {
            "GenartName": "Trennmesser-Satz",
            "NormTermName": "Trennmesser-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06678": {
            "GenartName": "Spannbügel, Abzieher",
            "NormTermName": "Spannbügel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abzieher"
        },
        "06679": {
            "GenartName": "Haken, Abzieher",
            "NormTermName": "Haken",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abzieher"
        },
        "06680": {
            "GenartName": "Hakensatz, Abzieher",
            "NormTermName": "Hakensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abzieher"
        },
        "06681": {
            "GenartName": "Abzieher, Nockenwellenrad",
            "NormTermName": "Abzieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Nockenwellenrad"
        },
        "06682": {
            "GenartName": "Abziehersatz, Wischarm",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Wischarm"
        },
        "06683": {
            "GenartName": "Abzieher, Bremsgestängesteller",
            "NormTermName": "Abzieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremsgestängesteller"
        },
        "06684": {
            "GenartName": "Halter, Bügelmessschraube",
            "NormTermName": "Halter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bügelmessschraube"
        },
        "06685": {
            "GenartName": "Messschieber, Bremstrommel",
            "NormTermName": "Messschieber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremstrommel"
        },
        "06686": {
            "GenartName": "Videoendoskop-Satz",
            "NormTermName": "Videoendoskop-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06687": {
            "GenartName": "Kamerasonde, Videoendoskop",
            "NormTermName": "Sonde",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Videoendoskop"
        },
        "06688": {
            "GenartName": "Kabelverlängerung, Kamerasonde (Videoendoskop)",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kamerasonde (Videoendoskop)"
        },
        "06689": {
            "GenartName": "Führungskugel, Kamerasonde (Videoendoskop)",
            "NormTermName": "Führungskugel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kamerasonde (Videoendoskop)"
        },
        "06690": {
            "GenartName": "Magnetaufsatz, Kamerasonde (Videoendoskop)",
            "NormTermName": "Magnetaufsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kamerasonde (Videoendoskop)"
        },
        "06691": {
            "GenartName": "Bürstenaufsatz, Kamerasonde (Videoendoskop)",
            "NormTermName": "Bürstenaufsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kamerasonde (Videoendoskop)"
        },
        "06692": {
            "GenartName": "Hakenaufsatz, Kamerasonde (Videoendoskop)",
            "NormTermName": "Hakenaufsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kamerasonde (Videoendoskop)"
        },
        "06693": {
            "GenartName": "Vorsatzspiegel, Kamerasonde (Videoendoskop)",
            "NormTermName": "Vorsatzspiegel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kamerasonde (Videoendoskop)"
        },
        "06694": {
            "GenartName": "Software, Videoendoskop",
            "NormTermName": "Software",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Videoendoskop"
        },
        "06695": {
            "GenartName": "Schreinerhammer",
            "NormTermName": "Schreinerhammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06696": {
            "GenartName": "Aufsatz, Fäustel",
            "NormTermName": "Aufsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Fäustel"
        },
        "06697": {
            "GenartName": "Klauenhammer",
            "NormTermName": "Klauenhammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06698": {
            "GenartName": "Schweißerhammer",
            "NormTermName": "Schweißerhammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06699": {
            "GenartName": "Axt",
            "NormTermName": "Axt",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06700": {
            "GenartName": "Beil",
            "NormTermName": "Beil",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06701": {
            "GenartName": "Spitzhacke",
            "NormTermName": "Spitzhacke",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06702": {
            "GenartName": "Kreuzhacke",
            "NormTermName": "Kreuzhacke",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06703": {
            "GenartName": "Spalthammer",
            "NormTermName": "Spalthammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06704": {
            "GenartName": "Metallspiralbohrer-Satz",
            "NormTermName": "Metallspiralbohrer-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06705": {
            "GenartName": "Gewindebohrer-Satz",
            "NormTermName": "Gewindebohrer-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06706": {
            "GenartName": "Blechschälbohrer-Satz",
            "NormTermName": "Blechschälbohrer-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06707": {
            "GenartName": "Stufenbohrer-Satz",
            "NormTermName": "Stufenbohrer-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06708": {
            "GenartName": "Kegelsenker-Satz",
            "NormTermName": "Kegelsenker-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06709": {
            "GenartName": "Schweißpunktfräser-Satz",
            "NormTermName": "Schweißpunktfräser-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06710": {
            "GenartName": "Schweißpunktbohrer-Satz",
            "NormTermName": "Schweißpunktbohrer-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06711": {
            "GenartName": "Fräsbohrer",
            "NormTermName": "Fräsbohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06712": {
            "GenartName": "Ratschenhalter, Gewindebohrer",
            "NormTermName": "Halter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gewindebohrer"
        },
        "06713": {
            "GenartName": "Adaptersatz, Schneideisen",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schneideisen"
        },
        "06714": {
            "GenartName": "Verlängerung, Gewindebohrer",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gewindebohrer"
        },
        "06715": {
            "GenartName": "Zentrierbohrer, Lochsäge",
            "NormTermName": "Zentrierbohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lochsäge"
        },
        "06716": {
            "GenartName": "Verlängerung, Lochsäge",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lochsäge"
        },
        "06717": {
            "GenartName": "Lochsägen-Satz",
            "NormTermName": "Lochsägen-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06718": {
            "GenartName": "Schraublochstanze",
            "NormTermName": "Schraublochstanze",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06719": {
            "GenartName": "Sägeblatt, Stichsäge",
            "NormTermName": "Sägeblatt",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Stichsäge"
        },
        "06720": {
            "GenartName": "Werkbank",
            "NormTermName": "Werkbank",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06721": {
            "GenartName": "Arbeitstisch",
            "NormTermName": "Arbeitstisch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06722": {
            "GenartName": "Haken, Werkzeuglochtafel",
            "NormTermName": "Haken",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06723": {
            "GenartName": "Doppelhaken, Werkzeuglochtafel",
            "NormTermName": "Doppelhaken",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06724": {
            "GenartName": "Klemme, Werkzeuglochtafel",
            "NormTermName": "Klemme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06725": {
            "GenartName": "Rundhalter, Werkzeuglochtafel",
            "NormTermName": "Rundhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06726": {
            "GenartName": "Doppelklemme, Werkzeuglochtafel",
            "NormTermName": "Doppelklemme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06727": {
            "GenartName": "Dreifachhaken, Werkzeuglochtafel",
            "NormTermName": "Dreifachhaken",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06728": {
            "GenartName": "Kabelhalter, Werkzeuglochtafel",
            "NormTermName": "Kabelhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06729": {
            "GenartName": "Schraubendreherhalter",
            "NormTermName": "Schraubendreherhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schraubendreher"
        },
        "06730": {
            "GenartName": "Ablageboden, Werkzeuglochtafel",
            "NormTermName": "Ablageboden",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06731": {
            "GenartName": "Sechsfachhaken, Werkzeuglochtafel",
            "NormTermName": "Sechsfachhaken",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06732": {
            "GenartName": "Bohrer-/Innensechskanthalter, Werkzeuglochtafel",
            "NormTermName": "Bohrer-/Innensechskanthalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06733": {
            "GenartName": "Lagersichtkastenhalter, Werkzeuglochtafel",
            "NormTermName": "Lagersichtkastenhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06734": {
            "GenartName": "Sägenhalter, Werkzeuglochtafel",
            "NormTermName": "Sägenhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06735": {
            "GenartName": "Grundplatte, Werkzeuglochtafel",
            "NormTermName": "Grundplatte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06736": {
            "GenartName": "Schraubenschlüsselhalter, Werkzeuglochtafel",
            "NormTermName": "Schraubenschlüsselhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06737": {
            "GenartName": "Rohrhalter, Werkzeuglochtafel",
            "NormTermName": "Rohrhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06738": {
            "GenartName": "Runddosenhalter, Werkzeuglochtafel",
            "NormTermName": "Runddosenhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06739": {
            "GenartName": "Universal-Werkzeugaufnahme, Werkzeuglochtafel",
            "NormTermName": "Universal-Werkzeugaufnahme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06740": {
            "GenartName": "Steckschlüsseleinsatzhalter",
            "NormTermName": "Steckschlüsseleinsatzhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Steckschlüsseleinsatz"
        },
        "06741": {
            "GenartName": "Schlauchhalter, Werkzeuglochtafel",
            "NormTermName": "Schlauchhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06742": {
            "GenartName": "Papierrollenhalter, Werkzeuglochtafel",
            "NormTermName": "Papierrollenhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06743": {
            "GenartName": "Prospekthalter, Werkzeuglochtafel",
            "NormTermName": "Prospekthalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06744": {
            "GenartName": "Zeichnungshalter, Werkzeuglochtafel",
            "NormTermName": "Zeichnungshalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06745": {
            "GenartName": "Magnethalter, Werkzeuglochtafel",
            "NormTermName": "Magnethalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06746": {
            "GenartName": "Bithalter, Werkzeuglochtafel",
            "NormTermName": "Bithalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeuglochtafel"
        },
        "06747": {
            "GenartName": "Klappmesser",
            "NormTermName": "Klappmesser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06748": {
            "GenartName": "Klappmesser-Satz",
            "NormTermName": "Klappmesser-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06749": {
            "GenartName": "Abbrechklingensatz, Cutter",
            "NormTermName": "Abbrechklingensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Cutter"
        },
        "06750": {
            "GenartName": "Trapezklingensatz, Cutter",
            "NormTermName": "Trapezklingensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Cutter"
        },
        "06751": {
            "GenartName": "Hakenklingensatz, Cutter",
            "NormTermName": "Hakenklingensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Cutter"
        },
        "06752": {
            "GenartName": "Blechscheren-Satz",
            "NormTermName": "Blechscheren-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06753": {
            "GenartName": "Handschutzgrriff, Meißel",
            "NormTermName": "Handschutzgrriff",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Meißel"
        },
        "06754": {
            "GenartName": "Durchtreiber-Satz",
            "NormTermName": "Durchtreiber-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06755": {
            "GenartName": "Splinttreiber-Satz",
            "NormTermName": "Splinttreiber-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06756": {
            "GenartName": "Splinttreiber, Bremsbelag",
            "NormTermName": "Splinttreiber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsbelag"
        },
        "06757": {
            "GenartName": "Vorstecher",
            "NormTermName": "Vorstecher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06758": {
            "GenartName": "Splint + Durchtreiber-Satz",
            "NormTermName": "Splint/Durchtreiber-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06759": {
            "GenartName": "Meißel + Durchtreiber-Satz",
            "NormTermName": "Meißel/Durchtreiber-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06760": {
            "GenartName": "Prägestempel-Satz",
            "NormTermName": "Prägestempel-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06761": {
            "GenartName": "Haken-Werkzeug",
            "NormTermName": "Haken-Werkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06762": {
            "GenartName": "Henkellocheisen-Satz",
            "NormTermName": "Henkellocheisen-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06763": {
            "GenartName": "Rundlocheisen",
            "NormTermName": "Rundlocheisen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06764": {
            "GenartName": "Ring-Stanzeisen-Satz",
            "NormTermName": "Ring-Stanzeisen-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06765": {
            "GenartName": "Rundlocheisen-Satz",
            "NormTermName": "Rundlocheisen-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06766": {
            "GenartName": "Nietzieher",
            "NormTermName": "Nietzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06767": {
            "GenartName": "Nietzieher-Satz",
            "NormTermName": "Nietzieher-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06768": {
            "GenartName": "Nietkopfsetzer",
            "NormTermName": "Nietkopfsetzer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06769": {
            "GenartName": "Nietkopfsetzer-Satz",
            "NormTermName": "Nietkopfsetzer-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06770": {
            "GenartName": "Nietzieher/Nietkopfsetzer",
            "NormTermName": "Nietzieher/Nietkopfsetzer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06771": {
            "GenartName": "Sicherheits-Gummistiefel",
            "NormTermName": "Sicherheits-Gummistiefel",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06772": {
            "GenartName": "T-Shirt",
            "NormTermName": "T-Shirt",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06773": {
            "GenartName": "Polo-Shirt",
            "NormTermName": "Polo-Shirt",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06774": {
            "GenartName": "Hemd",
            "NormTermName": "Hemd",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06775": {
            "GenartName": "Arbeitsjacke",
            "NormTermName": "Arbeitsjacke",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06776": {
            "GenartName": "Arbeitshose",
            "NormTermName": "Arbeitshose",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06777": {
            "GenartName": "Overall",
            "NormTermName": "Overall",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06778": {
            "GenartName": "Arbeitsweste",
            "NormTermName": "Arbeitsweste",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "06779": {
            "GenartName": "Einteilersatz, Schublade (Werkzeugwagen)",
            "NormTermName": "Einteilersatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schublade (Werkzeugwagen)"
        },
        "06780": {
            "GenartName": "Einlegefach, Schublade (Werkzeugwagen)",
            "NormTermName": "Einlegefach",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schublade (Werkzeugwagen)"
        },
        "06781": {
            "GenartName": "Lochtafel, Werkzeugwagen",
            "NormTermName": "Lochtafel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06782": {
            "GenartName": "Arbeitsplatte, Werkzeugwagenaufsatz",
            "NormTermName": "Arbeitsplatte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagenaufsatz"
        },
        "06783": {
            "GenartName": "Ablagefach, Werkzeugwagen",
            "NormTermName": "Ablagefach",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06784": {
            "GenartName": "Arbeitstablett, Werkzeugwagen",
            "NormTermName": "Arbeitstablett",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06785": {
            "GenartName": "Montagewerkzeugsatz, Drehstab",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Drehstab"
        },
        "06786": {
            "GenartName": "Fahrgestell, Werkzeugwagenaufsatz",
            "NormTermName": "Fahrgestell",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagenaufsatz"
        },
        "06787": {
            "GenartName": "Saugheber, Werkzeugwagen",
            "NormTermName": "Saugheber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06788": {
            "GenartName": "Endbeschlag, Ankerschiene",
            "NormTermName": "Endbeschlag",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Ankerschiene"
        },
        "06789": {
            "GenartName": "Endstück, Ankerschiene",
            "NormTermName": "Endstück",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Ankerschiene"
        },
        "06790": {
            "GenartName": "Endstück, Sperrbalken",
            "NormTermName": "Endstück",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Sperrbalken"
        },
        "06791": {
            "GenartName": "Stirnlampe",
            "NormTermName": "Stirnlampe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06792": {
            "GenartName": "Endstück, Zurrschiene",
            "NormTermName": "Endstück",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Zurrschiene"
        },
        "06793": {
            "GenartName": "Flachhaken",
            "NormTermName": "Flachhaken",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "06794": {
            "GenartName": "Stativ, Arbeitsscheinwerfer",
            "NormTermName": "Stativ",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "06795": {
            "GenartName": "Klemme, Arbeitsscheinwerfer",
            "NormTermName": "Klemme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Arbeitsscheinwerfer"
        },
        "06796": {
            "GenartName": "Rollensatz, Arbeitsscheinwerferstativ",
            "NormTermName": "Rollensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Arbeitsscheinwerferstativ"
        },
        "06797": {
            "GenartName": "Notfallleuchte",
            "NormTermName": "Notfallleuchte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06798": {
            "GenartName": "Werkzeugschrank",
            "NormTermName": "Werkzeugschrank",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06799": {
            "GenartName": "Garderobenschrank",
            "NormTermName": "Garderobenschrank",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06800": {
            "GenartName": "Gabelkopflasthaken",
            "NormTermName": "Gabelkopflasthaken",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "06801": {
            "GenartName": "Zugringschlüssel-Satz",
            "NormTermName": "Zugringschlüssel-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06802": {
            "GenartName": "Zuggabelschlüssel",
            "NormTermName": "Zuggabelschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06803": {
            "GenartName": "Gegenplatte, Zurrmulde",
            "NormTermName": "Gegenplatte",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Zurrmulde"
        },
        "06804": {
            "GenartName": "Zurrmulde",
            "NormTermName": "Zurrmulde",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "06805": {
            "GenartName": "Zurrring",
            "NormTermName": "Zurrring",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "06806": {
            "GenartName": "Spannband",
            "NormTermName": "Spannband",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "06807": {
            "GenartName": "Kupplungsschlüssel, Feuerwehrkupplung",
            "NormTermName": "Kupplungsschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Feuerwehrkupplung"
        },
        "06808": {
            "GenartName": "Schieberklaue",
            "NormTermName": "Schieberklaue",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06809": {
            "GenartName": "Handradschlüssel",
            "NormTermName": "Handradschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06810": {
            "GenartName": "Ventilhaken",
            "NormTermName": "Ventilhaken",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06811": {
            "GenartName": "Fassschlüssel",
            "NormTermName": "Fassschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06812": {
            "GenartName": "Kanaldeckelhaken",
            "NormTermName": "Kanaldeckelhaken",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06813": {
            "GenartName": "Durchsteckvierkant, Knarre",
            "NormTermName": "Durchsteckvierkant",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Knarre"
        },
        "06814": {
            "GenartName": "Haltewerkzeugsatz, Kurbelwelle",
            "NormTermName": "Haltewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kurbelwelle"
        },
        "06815": {
            "GenartName": "Verlängerung, Kurbelwellen-Haltewerkzeug",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kurbelwellen-Haltewerkzeug"
        },
        "06816": {
            "GenartName": "Führungshülse, Kurbelwellen-Haltewerkzeug",
            "NormTermName": "Hülse",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kurbelwellen-Haltewerkzeug"
        },
        "06817": {
            "GenartName": "Montageständer, Zylinderkopf",
            "NormTermName": "Ständer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Zylinderkopf"
        },
        "06818": {
            "GenartName": "Ausziehersatz, Motor-/Getriebeführungshülse",
            "NormTermName": "Ausziehersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Motor-/Getriebeführungshülse"
        },
        "06819": {
            "GenartName": "Ausdrehwerkzeug, Stehbolzen",
            "NormTermName": "Ausdrehwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Stehbolzen"
        },
        "06820": {
            "GenartName": "Ausdrehwerkzeugsatz, Stehbolzen",
            "NormTermName": "Ausdrehwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Stehbolzen"
        },
        "06821": {
            "GenartName": "Bolzenausdreher-Satz",
            "NormTermName": "Bolzenausdreher-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06822": {
            "GenartName": "Mutternsprenger-Satz",
            "NormTermName": "Mutternsprenger-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06823": {
            "GenartName": "Spannbandsatz",
            "NormTermName": "Spannbandsatz",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "06824": {
            "GenartName": "Hebeband",
            "NormTermName": "Hebeband",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "06825": {
            "GenartName": "Hinweisschild",
            "NormTermName": "Hinweisschild",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "06826": {
            "GenartName": "Ablage, Zylinderkopf-Komponenten",
            "NormTermName": "Ablage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Zylinderkopf-Komponenten"
        },
        "06827": {
            "GenartName": "Druckluftadapter, Zünd-/Glühkerzenbohrung",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zünd-/Glühkerzenbohrung"
        },
        "06828": {
            "GenartName": "Niederhalter, Tassenstößel",
            "NormTermName": "Niederhalter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Tassenstößel"
        },
        "06829": {
            "GenartName": "Montagewerkzeug, Ventilfeder",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventilfeder"
        },
        "06830": {
            "GenartName": "Kantenschutz, Spanngurt",
            "NormTermName": "Kantenschutz",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Spanngurt"
        },
        "06831": {
            "GenartName": "Klemmschloss, Spanngurt",
            "NormTermName": "Klemmschloss",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Spanngurt"
        },
        "06832": {
            "GenartName": "Druckstücksatz, Ventilfeder-Montagewerkzeug",
            "NormTermName": "Druckstücksatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventilfeder-Montagewerkzeug"
        },
        "06833": {
            "GenartName": "Druckstück, Ventilfeder-Montagewerkzeug",
            "NormTermName": "Druckstück",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventilfeder-Montagewerkzeug"
        },
        "06834": {
            "GenartName": "Ladebalken",
            "NormTermName": "Ladebalken",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "06835": {
            "GenartName": "Montagewerkzeugsatz, Ventilfeder",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventilfeder"
        },
        "06836": {
            "GenartName": "Zange, Ventil-Distanzplättchen",
            "NormTermName": "Zange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventil-Distanzplättchen"
        },
        "06837": {
            "GenartName": "Aufpressdornsatz, Ventilschaftdichtung",
            "NormTermName": "Aufpressdornsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventilschaftdichtung"
        },
        "06838": {
            "GenartName": "Aufpressdorn, Ventilschaftdichtung",
            "NormTermName": "Aufpressdorn",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventilschaftdichtung"
        },
        "06839": {
            "GenartName": "Schlagauszieher, Ventilschaftdichtung",
            "NormTermName": "Auszieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventilschaftdichtung"
        },
        "06840": {
            "GenartName": "Ladungssicherungsnetz",
            "NormTermName": "Ladungssicherungsnetz",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "06841": {
            "GenartName": "Werkzeugsatz, Ventilschaftdichtung",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventilschaftdichtung"
        },
        "06842": {
            "GenartName": "Klingensatz, Schaber",
            "NormTermName": "Klingensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schaber"
        },
        "06843": {
            "GenartName": "Schlagausziehersatz, Wellendichtring",
            "NormTermName": "Schlagausziehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Wellendichtring"
        },
        "06844": {
            "GenartName": "Ausziehersatz, Wellendichtring",
            "NormTermName": "Ausziehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Wellendichtring"
        },
        "06845": {
            "GenartName": "Fräswerkzeugsatz, Ventilsitz",
            "NormTermName": "Fräswerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventilsitz"
        },
        "06846": {
            "GenartName": "Montagewerkzeug, Ventilkeil",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventilkeil"
        },
        "06847": {
            "GenartName": "Kolbenringspannband-Satz",
            "NormTermName": "Spannbandsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kolbenring"
        },
        "06848": {
            "GenartName": "Haltewerkzeugsatz, Zylinderlaufbuchse",
            "NormTermName": "Haltewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zylinderlaufbuchse"
        },
        "06849": {
            "GenartName": "Arretierwerkzeugsatz, Zylinderlaufbuchse",
            "NormTermName": "Arretierwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zylinderlaufbuchse"
        },
        "06850": {
            "GenartName": "Prüfgerät, Vergaser-Synchronisierung",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Vergaser-Synchronisierung"
        },
        "06851": {
            "GenartName": "Motorradwippe",
            "NormTermName": "Motorradwippe",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "06852": {
            "GenartName": "Prüfgerät, Öldruck",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Öldruck"
        },
        "06853": {
            "GenartName": "Prüfgerät, Kraftstoffsystemdruck",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kraftstoffsystemdruck"
        },
        "06854": {
            "GenartName": "Zangensatz, Schlauchschelle",
            "NormTermName": "Zangensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlauchschelle"
        },
        "06855": {
            "GenartName": "Lösezange, Schlauchleitung",
            "NormTermName": "Lösezange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlauchleitung"
        },
        "06856": {
            "GenartName": "Schlüssel, Turbolader",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Turbolader"
        },
        "06857": {
            "GenartName": "Adapter, Turbolader (Öldruckprüfgerät)",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Turbolader (Öldruckprüfgerät)"
        },
        "06858": {
            "GenartName": "Adapter, Turbolader-Ladeluft-System",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Turbolader-Ladeluft-System"
        },
        "06859": {
            "GenartName": "Stopfen, Turbolader-Ladeluft-System",
            "NormTermName": "Stopfen",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Turbolader-Ladeluft-System"
        },
        "06860": {
            "GenartName": "Adaptersatz, Turbolader-Ladeluft-System Prüfung",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Turbolader-Ladeluft-System Prüfung"
        },
        "06861": {
            "GenartName": "Arretierwerkzeugsatz, Nockenwelle",
            "NormTermName": "Arretierwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Nockenwelle"
        },
        "06862": {
            "GenartName": "Arretierlehre, Phasenversteller-Nockenwelle",
            "NormTermName": "Arretierlehre",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Phasenversteller-Arretierung"
        },
        "06863": {
            "GenartName": "Schlüssel, Zahnriemenspannrolle",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zahnriemenspannrolle"
        },
        "06864": {
            "GenartName": "Arretierdorn, Steuerkettenspanner",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Steuerkettenspanner"
        },
        "06865": {
            "GenartName": "Ösenschraube, Spanngurtring",
            "NormTermName": "Schraube",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Spanngurtring"
        },
        "06866": {
            "GenartName": "Justierdorn, Einspritzpumpe",
            "NormTermName": "Justierdorn",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzpumpe"
        },
        "06867": {
            "GenartName": "Ratschenlastspanner, Verzurrkette",
            "NormTermName": "Ratschenlastspanner",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Verzurrkette"
        },
        "06868": {
            "GenartName": "Ring, Zurrmulde",
            "NormTermName": "Ring",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Zurrmulde"
        },
        "06869": {
            "GenartName": "Ringmutter, Zurrmulde",
            "NormTermName": "Ringmutter",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Zurrmulde"
        },
        "06870": {
            "GenartName": "Rundschlinge",
            "NormTermName": "Rundschlinge",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "06871": {
            "GenartName": "Schutzschlauch, Spanngurt",
            "NormTermName": "Schutzschlauch",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Spanngurt"
        },
        "06872": {
            "GenartName": "Filter, Unterdruckleitung",
            "NormTermName": "Filter",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Unterdruckleitung"
        },
        "06873": {
            "GenartName": "Absperraufroller",
            "NormTermName": "Absperraufroller",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06875": {
            "GenartName": "Steckschlüsseleinsatz, Injektorleitung",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Injektorleitung"
        },
        "06876": {
            "GenartName": "Steckschlüsseleinsatz, Einspritzdüse",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzdüse"
        },
        "06877": {
            "GenartName": "Steckschlüsseleinsatz-Set, Einspritzdüse",
            "NormTermName": "Steckschlüsseleinsatz-Set",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzdüse"
        },
        "06878": {
            "GenartName": "Steckschlüsseleinsatz, Vorkammer",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Vorkammer"
        },
        "06879": {
            "GenartName": "Schlagausziehersatz, Vorkammer",
            "NormTermName": "Schlagausziehersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Vorkammer"
        },
        "06880": {
            "GenartName": "Erweiterungssatz, Elektrosatz (Anhängevorrichtung)",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Elektrosatz (Anhängevorrichtung)"
        },
        "06881": {
            "GenartName": "Erweiterungssatz, Elektrosatz (Anhängevorrichtung)",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Elektrosatz (Anhängevorrichtung)"
        },
        "06882": {
            "GenartName": "Prüfsortiment, Kraftstoff-Rücklaufmenge",
            "NormTermName": "Prüfsortiment",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kraftstoff-Rücklaufmenge"
        },
        "06883": {
            "GenartName": "Messrohr, Kraftstoff-Rücklaufmengenmessung",
            "NormTermName": "Messrohr",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kraftstoff-Rücklaufmengenmessung"
        },
        "06884": {
            "GenartName": "Demontagewerkzeug, Common-Rail-Injektor",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Common-Rail-Injektor"
        },
        "06885": {
            "GenartName": "Demontagewerkzeugsatz, Common-Rail-Injektor",
            "NormTermName": "Demontagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Common-Rail-Injektor"
        },
        "06886": {
            "GenartName": "Reinigungs-/Fräswerkzeug, CR-Injektorschacht",
            "NormTermName": "Reinigungs-/Fräswerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "CR-Injektorschacht"
        },
        "06887": {
            "GenartName": "Reinigungsbürste",
            "NormTermName": "Reinigungsbürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06888": {
            "GenartName": "Reinigungsbürsten-Satz",
            "NormTermName": "Reinigungsbürsten-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06889": {
            "GenartName": "Abziehersatz, Einspritzpumpenzahnrad",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzpumpenzahnrad"
        },
        "06890": {
            "GenartName": "Justierschraube, Einspritzpumpe",
            "NormTermName": "Justierschraube",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzpumpe"
        },
        "06891": {
            "GenartName": "Arretierdorn, Einspritzpumpe",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzpumpe"
        },
        "06892": {
            "GenartName": "Arretierdornsatz, Einspritzpumpe",
            "NormTermName": "Arretierdornsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzpumpe"
        },
        "06893": {
            "GenartName": "Einstelllehre, Pumpe-Düse-Einheit",
            "NormTermName": "Lehre",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Pumpe-Düse-Einheit"
        },
        "06894": {
            "GenartName": "Arretierwerkzeug, Einspritzpumpe",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzpumpe"
        },
        "06895": {
            "GenartName": "Einstellwerkzeugsatz, Einspritzpumpe",
            "NormTermName": "Einstellwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzpumpe"
        },
        "06896": {
            "GenartName": "Ausdrückpistole",
            "NormTermName": "Ausdrückpistole",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ausdrücken von Kartuschen/Schlauchbeutel"
        },
        "06897": {
            "GenartName": "Akkupack, Ausdrückpistole",
            "NormTermName": "Akkupack",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ausdrückpistole"
        },
        "06898": {
            "GenartName": "Ladegerät, Ausdrückpistole",
            "NormTermName": "Ladegerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ausdrückpistole"
        },
        "06899": {
            "GenartName": "Ausdrückkopf, Ausdrückpistole",
            "NormTermName": "Ausdrückkopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ausdrückpistole"
        },
        "06900": {
            "GenartName": "Überwurfmutter, Ausdrückpistole",
            "NormTermName": "Überwurfmutter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ausdrückpistole"
        },
        "06901": {
            "GenartName": "Dosierpinsel, Montagepaste",
            "NormTermName": "Dosierpinsel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Montagepaste"
        },
        "06902": {
            "GenartName": "Kabelschutzband",
            "NormTermName": "Kabelschutzband",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "06903": {
            "GenartName": "Druckluftspray",
            "NormTermName": "Druckluftspray",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06904": {
            "GenartName": "Grafitspray",
            "NormTermName": "Grafitspray",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06905": {
            "GenartName": "Keramikpastenspray",
            "NormTermName": "Keramikpastenspray",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06906": {
            "GenartName": "Mischrohr, Kartuschen",
            "NormTermName": "Mischrohr",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kartuschen"
        },
        "06907": {
            "GenartName": "Dichtungsklebstoff",
            "NormTermName": "Dichtungsklebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06908": {
            "GenartName": "Klebepad",
            "NormTermName": "Klebepad",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06909": {
            "GenartName": "Ölfleckentferner",
            "NormTermName": "Ölfleckentferner",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06910": {
            "GenartName": "Bindemittel",
            "NormTermName": "Bindemittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06911": {
            "GenartName": "Einstellwerkzeugsatz, Steuerzeiten",
            "NormTermName": "Einstellwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Steuerzeiten"
        },
        "06912": {
            "GenartName": "Schneiddraht, Scheibendemontage",
            "NormTermName": "Schneidedraht",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Scheibendemontage"
        },
        "06913": {
            "GenartName": "Seilfett",
            "NormTermName": "Seilfett",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06914": {
            "GenartName": "PTFE-Spray",
            "NormTermName": "PTFE-Spray",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06915": {
            "GenartName": "Haft-/Reinigungsmittel",
            "NormTermName": "Haft-/Reinigungsmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06916": {
            "GenartName": "Universal-Klebstoffset",
            "NormTermName": "Universal-Klebstoffset",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "06917": {
            "GenartName": "Verriegelung, Werkzeugwagen",
            "NormTermName": "Verriegelung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06918": {
            "GenartName": "Schublade, Werkzeugwagen",
            "NormTermName": "Schublade",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06920": {
            "GenartName": "Reparatursatz, Umschaltknarre",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Umschaltknarre"
        },
        "06922": {
            "GenartName": "Adapter, Schneideisen",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schneideisen"
        },
        "06923": {
            "GenartName": "Abrollgriff, Lenkradschoner",
            "NormTermName": "Abrollgriff",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lenkradschoner"
        },
        "06924": {
            "GenartName": "Balken-Zuggerät",
            "NormTermName": "Balken-Zuggerät",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "06925": {
            "GenartName": "Abziehglocke, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Abziehglocke",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radlager-Montagewerkzeug"
        },
        "06926": {
            "GenartName": "Abzieher, Kugellager",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kugellager"
        },
        "06927": {
            "GenartName": "Adapter, Öl-Einfülltrichter",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Öl-Einfülltrichter"
        },
        "06928": {
            "GenartName": "Eintreiber, Radlager",
            "NormTermName": "Eintreiber",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radlager"
        },
        "06929": {
            "GenartName": "Verlängerung, Kraft-Stecknuss",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kraft-Stecknuss"
        },
        "06930": {
            "GenartName": "Kopf, Ausbeulhammer",
            "NormTermName": "Kopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ausbeulhammer"
        },
        "06931": {
            "GenartName": "Ankernietknopf, Nietzange",
            "NormTermName": "Ankernietknopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Nietzange"
        },
        "06932": {
            "GenartName": "Ankernietzange",
            "NormTermName": "Ankernietzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06933": {
            "GenartName": "Anschlagwinkel",
            "NormTermName": "Anschlagwinkel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06934": {
            "GenartName": "Karosserieklemme",
            "NormTermName": "Karosserieklemme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06935": {
            "GenartName": "Polsterklammern",
            "NormTermName": "Polsterklammern",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "06936": {
            "GenartName": "Traverse, Abzieher",
            "NormTermName": "Traverse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abzieher"
        },
        "06937": {
            "GenartName": "Druckstück, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Druckstück",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "06938": {
            "GenartName": "Düsenkopf, Bunsenbrenner",
            "NormTermName": "Düsenkopf",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bunsenbrenner"
        },
        "06939": {
            "GenartName": "Dichtsitzfräser",
            "NormTermName": "Dichtsitzfräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06940": {
            "GenartName": "Biegeeisen",
            "NormTermName": "Biegeeisen",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "06941": {
            "GenartName": "Bohrlehre",
            "NormTermName": "Bohrlehre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06942": {
            "GenartName": "Werkzeughalter, Werkzeugschrank",
            "NormTermName": "Werkzeughalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugschrank"
        },
        "06943": {
            "GenartName": "Schlagausziehersatz, Bremsscheibe",
            "NormTermName": "Schlagausziehersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremsscheibe"
        },
        "06944": {
            "GenartName": "Schabeisen, Bremsscheibe",
            "NormTermName": "Schabeisen",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremsscheibe"
        },
        "06945": {
            "GenartName": "Saugheber",
            "NormTermName": "Saugheber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "06946": {
            "GenartName": "Tür, Werkzeugwagen",
            "NormTermName": "Tür",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06947": {
            "GenartName": "Mundstück-Satz, Nietzange",
            "NormTermName": "Mundstück-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Nietzange"
        },
        "06948": {
            "GenartName": "Arbeitsplatte, Werkzeugwagen",
            "NormTermName": "Arbeitsplatte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06950": {
            "GenartName": "Kette, Feststellzange",
            "NormTermName": "Kette",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Feststellzange"
        },
        "06951": {
            "GenartName": "Spitzenschneider",
            "NormTermName": "Spitzenschneider",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06952": {
            "GenartName": "Fangkette, Kugelgelenkausdrücker",
            "NormTermName": "Fangkette",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Kugelgelenkausdrücker"
        },
        "06953": {
            "GenartName": "Druckplatte, Federspanner",
            "NormTermName": "Druckplatte",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federspanner"
        },
        "06954": {
            "GenartName": "Regalboden, Werkzeugwagen",
            "NormTermName": "Regalboden",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06955": {
            "GenartName": "Ziehmesser, Scheibendemontage",
            "NormTermName": "Ziehmesser",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Scheibendemontage"
        },
        "06956": {
            "GenartName": "Ziehmesser-Satz, Scheibendemontage",
            "NormTermName": "Ziehmesser-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Scheibendemontage"
        },
        "06957": {
            "GenartName": "Klinge, Ziehmesser (Scheibendemontage)",
            "NormTermName": "Klinge",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ziehmesser (Scheibendemontage)"
        },
        "06958": {
            "GenartName": "Papierkorb, Werkzeugwagen",
            "NormTermName": "Papierkorb",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06959": {
            "GenartName": "Unterlegscheibe, Schraubstock",
            "NormTermName": "Unterlegscheibe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schraubstock"
        },
        "06960": {
            "GenartName": "Scheibendemontage-Set",
            "NormTermName": "Scheibendemontage-Set",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06961": {
            "GenartName": "Auflagenteller, Rangierheber",
            "NormTermName": "Auflagenteller",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Rangierheber"
        },
        "06962": {
            "GenartName": "Haltezange, Lötlitzen",
            "NormTermName": "Haltezange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lötlitzen"
        },
        "06963": {
            "GenartName": "Bowdenzug, Schlauchschellenzange",
            "NormTermName": "Bowdenzug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlauchschelle"
        },
        "06964": {
            "GenartName": "Rohrzange",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohre/ Leitungen"
        },
        "06965": {
            "GenartName": "Schutztasche, Abdecktuch",
            "NormTermName": "Tasche",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Abdecktuch"
        },
        "06966": {
            "GenartName": "Steckgriff, Einsteck-Vierkant",
            "NormTermName": "Griff",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Einsteck-Vierkant"
        },
        "06967": {
            "GenartName": "Ring-Stanzeisen",
            "NormTermName": "Ring-Stanzeisen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06968": {
            "GenartName": "Nageleisen",
            "NormTermName": "Nageleisen",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "06969": {
            "GenartName": "Sauger, Zierleistenmontage",
            "NormTermName": "Sauger",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zierleistenmontage"
        },
        "06970": {
            "GenartName": "Zuggriff, Ziehmesser (Scheibendemontage)",
            "NormTermName": "Zuggriff",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ziehmesser (Scheibendemontage)"
        },
        "06971": {
            "GenartName": "Langmesser-Satz, Scheibendemontage",
            "NormTermName": "Langmesser-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Scheibendemontage"
        },
        "06972": {
            "GenartName": "Feilen-Satz",
            "NormTermName": "Feilen-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06973": {
            "GenartName": "Einstellwerkzeug, Drehmomentschlüssel",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "06974": {
            "GenartName": "Gewindebolzen, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Gewindebolzen",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "06975": {
            "GenartName": "Gegenhalter, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Gegenhalter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "06976": {
            "GenartName": "Druckstücksatz, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Druckstücksatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "06977": {
            "GenartName": "Spindelhülse, Abzieher",
            "NormTermName": "Spindelhülse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abzieher"
        },
        "06978": {
            "GenartName": "Unterlegscheibe, Abzieher",
            "NormTermName": "Unterlegscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abzieher"
        },
        "06979": {
            "GenartName": "Kugellager, Abzieher",
            "NormTermName": "Lager",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abzieher"
        },
        "06980": {
            "GenartName": "Schneidwerkzeug, Ölfilter",
            "NormTermName": "Schneidwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ölfilter"
        },
        "06981": {
            "GenartName": "Ölpeilstab",
            "NormTermName": "Ölpeilstab",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "06982": {
            "GenartName": "Schlagauszieher, Common-Rail-Injektor",
            "NormTermName": "Schlagauszieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Common-Rail-Injektor"
        },
        "06983": {
            "GenartName": "Adapter, Schlagauszieher (Common-Rail-Injektor)",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Schlagauszieher (Common-Rail-Injektor)"
        },
        "06984": {
            "GenartName": "Gehrungswinkel",
            "NormTermName": "Gehrungswinkel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06985": {
            "GenartName": "Zirkel",
            "NormTermName": "Zirkel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06986": {
            "GenartName": "Lehre, Schraubenschlüsselprofil",
            "NormTermName": "Lehre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schraubenschlüsselprofil"
        },
        "06987": {
            "GenartName": "Schweißnahtlehre",
            "NormTermName": "Schweißnahtlehre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06988": {
            "GenartName": "Gewindelehre",
            "NormTermName": "Gewindelehre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06989": {
            "GenartName": "Höhenreißer",
            "NormTermName": "Höhenreißer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "06990": {
            "GenartName": "Schubladenblende, Werkzeugwagen",
            "NormTermName": "Blende",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schublade (Werkzeugwagen)"
        },
        "06991": {
            "GenartName": "Druckstück, Ein-/Auspresswerkzeug",
            "NormTermName": "Druckstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ein-/Auspresswerkzeug"
        },
        "06992": {
            "GenartName": "Hydraulikzylinder, Kugelgelenkausdrücker",
            "NormTermName": "Hydraulikzylinder",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kugelgelenkausdrücker"
        },
        "06993": {
            "GenartName": "Zentrierhülse, Kupplung",
            "NormTermName": "Hülse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplung"
        },
        "06994": {
            "GenartName": "Griff, Werkzeugwagen",
            "NormTermName": "Griff",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "06995": {
            "GenartName": "Videograbber",
            "NormTermName": "Videograbber",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "06996": {
            "GenartName": "Hydraulikzylinder, Abzieher-Spindel",
            "NormTermName": "Hydraulikzylinder",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abzieher-Spindel"
        },
        "06997": {
            "GenartName": "Zugring, Spindel",
            "NormTermName": "Zugring",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Spindel"
        },
        "06999": {
            "GenartName": "Lötschwamm",
            "NormTermName": "Lötschwamm",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07000": {
            "GenartName": "Führungsschiene, Werkzeugwagenschublade",
            "NormTermName": "Führungsschiene",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagenschublade"
        },
        "07001": {
            "GenartName": "Reinigungswerkzeug, Schmiernippel",
            "NormTermName": "Reinigungswerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Schmiernippel"
        },
        "07002": {
            "GenartName": "Verlängerung, Trennmesser-Abziehvorrichtung",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Trennmesser-Abziehvorrichtung"
        },
        "07003": {
            "GenartName": "Schloss, Werkzeugwagen",
            "NormTermName": "Schloss",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "07004": {
            "GenartName": "Unterlage, Werkstattausrüstung",
            "NormTermName": "Unterlage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkstattpresse"
        },
        "07005": {
            "GenartName": "Gummiprofil, Werkzeugwagen",
            "NormTermName": "Gummiprofil",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "07006": {
            "GenartName": "Haltekette, Werkzeugwagenfach",
            "NormTermName": "Kette",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagenfach"
        },
        "07007": {
            "GenartName": "Deckel, Werkzeugwagen",
            "NormTermName": "Deckel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "07008": {
            "GenartName": "Rohrsäule, Werkzeugwagenfächer",
            "NormTermName": "Rohrsäule",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagenfächer"
        },
        "07009": {
            "GenartName": "Flanschtreiber",
            "NormTermName": "Flanschtreiber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07010": {
            "GenartName": "Flanschtreiber-Satz",
            "NormTermName": "Flanschtreiber-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07011": {
            "GenartName": "Adapter, Wischarmabzieher",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Wischarmabzieher"
        },
        "07012": {
            "GenartName": "Druckstückadapter, Ein-/Auspresswerkzeug",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ein-/Auspresswerkzeug"
        },
        "07013": {
            "GenartName": "Adapter, Gleithammer",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gleithammer"
        },
        "07014": {
            "GenartName": "Verlängerung, Kugellagerabzieher",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kugellagerabzieher"
        },
        "07015": {
            "GenartName": "Jalousie, Werkzeugwagen",
            "NormTermName": "Jalousie",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "07016": {
            "GenartName": "Zugstange, Kugellagerabzieher",
            "NormTermName": "Zugstange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kugellagerabzieher"
        },
        "07017": {
            "GenartName": "Mutternsatz, Zugstange (Kugellagerabzieher)",
            "NormTermName": "Mutternsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zugstange (Kugellagerabzieher)"
        },
        "07018": {
            "GenartName": "Schutzmatte, Werkzeugwagen",
            "NormTermName": "Matte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "07019": {
            "GenartName": "Unterlegscheibensatz, Zugstange (Kugellagerabzieher)",
            "NormTermName": "Unterlegscheibensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zugstange (Kugellagerabzieher)"
        },
        "07020": {
            "GenartName": "Verlängerung, Abzieher-Spindel",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abzieher-Spindel"
        },
        "07021": {
            "GenartName": "Mutter, Montagewerkzeug Silentlager",
            "NormTermName": "Mutter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Silentlager"
        },
        "07022": {
            "GenartName": "Distanzstück, Montagewerkzeug Silentlager",
            "NormTermName": "Distanzstück",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Silentlager"
        },
        "07023": {
            "GenartName": "Druckstücksatz, Montagewerkzeug Trag-/Führungsgelenk",
            "NormTermName": "Druckstücksatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Trag-/Führungsgelenk"
        },
        "07024": {
            "GenartName": "Adapter, Montagewerkzeug Trag-/Führungsgelenk",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Trag-/Führungsgelenk"
        },
        "07025": {
            "GenartName": "Arbeitskittel",
            "NormTermName": "Arbeitskittel",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "07026": {
            "GenartName": "Fußbetteinlage",
            "NormTermName": "Fußbetteinlage",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "07027": {
            "GenartName": "Reinigungsscheibe, Ölwanne",
            "NormTermName": "Reinigungsscheibe",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ölwanne"
        },
        "07028": {
            "GenartName": "Reinigungsscheibensatz, Ölwanne",
            "NormTermName": "Reinigungsscheibensatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ölwanne"
        },
        "07029": {
            "GenartName": "Halter, Ölwannenreinigungsscheibe",
            "NormTermName": "Halter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ölwannenreinigungsscheibe"
        },
        "07030": {
            "GenartName": "Führungsstift, Ölwannentrennmesser",
            "NormTermName": "Stift",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ölwannentrennmesser"
        },
        "07031": {
            "GenartName": "Exzenterwerkzeug, Spurstangenkugelgelenk",
            "NormTermName": "Exzenterwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Spurstangenkugelgelenk"
        },
        "07032": {
            "GenartName": "Hydraulikzylinder, Werkstattpresse",
            "NormTermName": "Hydraulikzylinder",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkstattpresse"
        },
        "07033": {
            "GenartName": "Arbeitsschutzanzug",
            "NormTermName": "Arbeitsschutzanzug",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "07034": {
            "GenartName": "Farbfiltersieb",
            "NormTermName": "Farbfiltersieb",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07035": {
            "GenartName": "Lupe",
            "NormTermName": "Lupe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07036": {
            "GenartName": "Sperrstück, Werkzeugwagenverriegelung",
            "NormTermName": "Sperrstück",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagenverriegelung"
        },
        "07037": {
            "GenartName": "Lötwerkzeugsatz",
            "NormTermName": "Lötwerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07038": {
            "GenartName": "Aufsatz, Lötkolben",
            "NormTermName": "Aufsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lötkolben"
        },
        "07039": {
            "GenartName": "Drahtspule, Lötkolben",
            "NormTermName": "Spule",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lötkolben"
        },
        "07040": {
            "GenartName": "Lötholz",
            "NormTermName": "Lötholz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07041": {
            "GenartName": "Sperrbolzen, Werkzeugwagenschublade",
            "NormTermName": "Sperrbolzen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagenschublade"
        },
        "07042": {
            "GenartName": "Fräser-Satz",
            "NormTermName": "Fräser-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07043": {
            "GenartName": "Werkstatt-Auftragstasche",
            "NormTermName": "Werkstatt-Auftragstasche",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07045": {
            "GenartName": "Zier-/Schutzleiste, Schubladenblende (Werkzeugwagen)",
            "NormTermName": "Zier-/Schutzleiste",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schubladenblende (Werkzeugwagen)"
        },
        "07046": {
            "GenartName": "Eckenabschlusskappe, Werkzeugwagen",
            "NormTermName": "Kappe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Eckenabschluss"
        },
        "07047": {
            "GenartName": "Nachstellhebel, Bremsbacke",
            "NormTermName": "Nachstellhebel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremsbacke"
        },
        "07048": {
            "GenartName": "Montagewerkzeug, Zahnriemen",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zahnriemen"
        },
        "07049": {
            "GenartName": "Montagewerkzeug, Bremsbacken-Feder",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremsbacken-Feder"
        },
        "07050": {
            "GenartName": "Bremssattel-Schlüssel",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremssattelbefestigungs-Schrauben"
        },
        "07051": {
            "GenartName": "Arretierdorn, Steuerkettenspanner",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Steuerkettenspanner"
        },
        "07052": {
            "GenartName": "Reibahle, ABS-Sensoraufnahme",
            "NormTermName": "Reibahle",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "ABS-Sensoraufnahme"
        },
        "07053": {
            "GenartName": "Zapfenschlüssel, Federbein",
            "NormTermName": "Zapfenschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federbein"
        },
        "07054": {
            "GenartName": "Druckplatte, Federspanner",
            "NormTermName": "Druckplatte",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federspanner"
        },
        "07055": {
            "GenartName": "Schutzeinlagensatz, Druckplatte-Federspanner",
            "NormTermName": "Schutzmittel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federspanner"
        },
        "07056": {
            "GenartName": "Lösehebel, Türverkleidung",
            "NormTermName": "Lösehebel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Türverkleidung"
        },
        "07057": {
            "GenartName": "O-Ring, Kraft-Stecknuss",
            "NormTermName": "Ring",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kraft-Stecknuss"
        },
        "07058": {
            "GenartName": "Zündkerzenbürste",
            "NormTermName": "Bürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zündkerze"
        },
        "07059": {
            "GenartName": "Vulkanisiernadel",
            "NormTermName": "Vulkanisiernadel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07060": {
            "GenartName": "Vulkanisierraspel",
            "NormTermName": "Vulkanisierraspel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07061": {
            "GenartName": "Vulkanisierfaden",
            "NormTermName": "Vulkanisierfaden",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07062": {
            "GenartName": "Schlüssel, Zahnriemenspannrolle",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zahnriemenspannrolle"
        },
        "07063": {
            "GenartName": "Steckschlüsseleinsatz, Turbolader",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Turbolader"
        },
        "07064": {
            "GenartName": "Steckschlüsseleinsatz, Turbolader",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Turbolader"
        },
        "07065": {
            "GenartName": "Druckluftadapter, Steuerzeiten (variabler Ventilhub)",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Verstelleinheit (variabler Ventilhub)"
        },
        "07066": {
            "GenartName": "Druckluftadapter, Steuerzeiten (variabler Ventilhub)",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Verstelleinheit (variabler Ventilhub)"
        },
        "07067": {
            "GenartName": "Montagevorrichtung, Verstelleinheit (variabler Ventilhub)",
            "NormTermName": "Montagevorrichtung",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Verstelleinheit (variabler Ventilhub)"
        },
        "07068": {
            "GenartName": "Kettenspanner, Steuerkette",
            "NormTermName": "Spanner",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Steuerkette"
        },
        "07070": {
            "GenartName": "Adapter, Arretierwerkzeug (Steuerzeiten)",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Arretierwerkzeug (Steuerzeiten)"
        },
        "07071": {
            "GenartName": "Adapter, Arretierwerkzeug (Steuerzeiten)",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Arretierwerkzeug (Steuerzeiten)"
        },
        "07072": {
            "GenartName": "Distanzstück, Arretierwerkzeug (Steuerzeiten)",
            "NormTermName": "Distanzstück",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Arretierwerkzeug (Steuerzeiten)"
        },
        "07073": {
            "GenartName": "Distanzstück, Arretierwerkzeug (Steuerzeiten)",
            "NormTermName": "Distanzstück",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Arretierwerkzeug (Steuerzeiten)"
        },
        "07074": {
            "GenartName": "Adaptersatz, Arretierwerkzeug (Steuerzeiten)",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Arretierwerkzeug (Steuerzeiten)"
        },
        "07075": {
            "GenartName": "Adaptersatz, Arretierwerkzeug (Steuerzeiten)",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Arretierwerkzeug (Steuerzeiten)"
        },
        "07076": {
            "GenartName": "Schraubendreher, Batterieverschlussstopfen",
            "NormTermName": "Schraubendreher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Batterieverschlussstopfen"
        },
        "07077": {
            "GenartName": "Dichtsitzfräser, Glühkerze",
            "NormTermName": "Dichtsitzfräser",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Glühkerze"
        },
        "07078": {
            "GenartName": "Adaptersatz, Vakuum-Befüllgerät (Kühlsystem)",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Vakuum-Befüllgerät (Kühlsystem)"
        },
        "07079": {
            "GenartName": "Bügel-Gehörschutz",
            "NormTermName": "Bügel-Gehörschutz",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "07080": {
            "GenartName": "Ohrstöpsel, Bügel-Gehörschutz",
            "NormTermName": "Ohrstöpsel",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Bügel-Gehörschutz"
        },
        "07081": {
            "GenartName": "Montagewerkzeugsatz, Bremsenservice",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremsenservice"
        },
        "07082": {
            "GenartName": "Klingensatz, Klappmesser",
            "NormTermName": "Klingensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Klappmesser"
        },
        "07083": {
            "GenartName": "Sortiment, Gewindereparatur",
            "NormTermName": "Sortiment",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gewindereparatur"
        },
        "07084": {
            "GenartName": "Glühlampe, Werkstattspiegel",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Werkstattspiegel"
        },
        "07085": {
            "GenartName": "Ladegerät, Handleuchte",
            "NormTermName": "Ladegerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Handleuchte"
        },
        "07086": {
            "GenartName": "Akku, Handleuchte",
            "NormTermName": "Akkupack",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Handleuchte"
        },
        "07087": {
            "GenartName": "Rollensatz, Werkstattliege",
            "NormTermName": "Rollensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkstattliege"
        },
        "07088": {
            "GenartName": "Schutzkappensatz, Schmiernippel",
            "NormTermName": "Schutzkappensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schmiernippel"
        },
        "07089": {
            "GenartName": "Ersatz-Ölfilterband",
            "NormTermName": "Ersatz-Ölfilterband",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07091": {
            "GenartName": "Arretierschraube, Steuerzeiten",
            "NormTermName": "Arretierschraube",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Steuerzeiten"
        },
        "07092": {
            "GenartName": "Lösehebel-Satz, Türverkleidung",
            "NormTermName": "Lösehebel-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Türverkleidung"
        },
        "07093": {
            "GenartName": "Arretierschraube, Steuerzeiten",
            "NormTermName": "Arretierschraube",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Steuerzeiten"
        },
        "07094": {
            "GenartName": "Batterie-Tragegriff",
            "NormTermName": "Griff",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Batterie"
        },
        "07095": {
            "GenartName": "Einschleifwerkzeug, Ventilsitz",
            "NormTermName": "Einschleifwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventilsitz"
        },
        "07096": {
            "GenartName": "Einstellwerkzeug, Zahnriemenspannung",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zahnriemenspannung"
        },
        "07097": {
            "GenartName": "Arbeitsplatte, Werkbank",
            "NormTermName": "Arbeitsplatte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkbank"
        },
        "07098": {
            "GenartName": "Multifunktionswerkzeug (Multi-Tool)",
            "NormTermName": "Multifunktionswerkzeug (Multi-Tool)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07099": {
            "GenartName": "Einstellwerkzeug, Zahnriemenspannung",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zahnriemenspannung"
        },
        "07100": {
            "GenartName": "Kartuschenschlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kartuschen"
        },
        "07101": {
            "GenartName": "Spannratsche",
            "NormTermName": "Spannratsche",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07102": {
            "GenartName": "Adapter, Werkzeugmodul",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugmodul"
        },
        "07103": {
            "GenartName": "Spannbackensatz, Bördelgerät",
            "NormTermName": "Spannbackensatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bördelgerät"
        },
        "07104": {
            "GenartName": "Rollensatz, Rangierheber",
            "NormTermName": "Rollensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Rangierheber"
        },
        "07105": {
            "GenartName": "Hydraulikeinheit, Rangierheber",
            "NormTermName": "Hydraulikeinheit",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Rangierheber"
        },
        "07106": {
            "GenartName": "Auflage, Unterstellbock",
            "NormTermName": "Auflage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Unterstellbock"
        },
        "07107": {
            "GenartName": "Messgerät, Drehwinkelmessung",
            "NormTermName": "Messgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drehwinkelmessung"
        },
        "07108": {
            "GenartName": "Zentriereinsatz, Druckplatte-Federspanner",
            "NormTermName": "Zentriereinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Druckplatte-Federspanner"
        },
        "07109": {
            "GenartName": "Zentriereinsatz, Druckplatte-Federspanner",
            "NormTermName": "Zentriereinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Druckplatte-Federspanner"
        },
        "07110": {
            "GenartName": "Abdeckscheibe, Exzenterschleifer",
            "NormTermName": "Abdeckscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Exzenterschleifer"
        },
        "07111": {
            "GenartName": "Stützteller, Exzenterschleifer",
            "NormTermName": "Stützteller",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Exzenterschleifer"
        },
        "07112": {
            "GenartName": "Absaugschlauch, Exzenterschleifer",
            "NormTermName": "Schlauch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Exzenterschleifer"
        },
        "07113": {
            "GenartName": "Betätigungshebel, Schlagschrauber",
            "NormTermName": "Betätigungshebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07114": {
            "GenartName": "Antriebssatz, Ratschenschrauber",
            "NormTermName": "Antriebssatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Ratschenschrauber"
        },
        "07115": {
            "GenartName": "Buchse, Ratschenschrauber",
            "NormTermName": "Buchse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Ratschenschrauber"
        },
        "07116": {
            "GenartName": "Antriebskopf, Ratschenschrauber",
            "NormTermName": "Antriebskopf",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Ratschenschrauber"
        },
        "07117": {
            "GenartName": "Ausblasstift",
            "NormTermName": "Ausblasstift",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07118": {
            "GenartName": "Distanzring, Druckplatte-Federspanner",
            "NormTermName": "Distanzring",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Druckplatte-Federspanner"
        },
        "07119": {
            "GenartName": "Auslassfiltersatz, Druckluftwerkzeug",
            "NormTermName": "Filter-Satz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckluftwerkzeug"
        },
        "07120": {
            "GenartName": "Distanzring, Druckplatte-Federspanner",
            "NormTermName": "Distanzring",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Druckplatte-Federspanner"
        },
        "07121": {
            "GenartName": "Zubehörsatz, Federspanner",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federspanner"
        },
        "07122": {
            "GenartName": "Schraubensatz, Druckluftwerkzeug",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckluftwerkzeug"
        },
        "07123": {
            "GenartName": "Spannvorrichtung, Federspanner",
            "NormTermName": "Spannvorrichtung",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federspanner"
        },
        "07124": {
            "GenartName": "Buchse, Druckluftwerkzeug",
            "NormTermName": "Buchse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Druckluftwerkzeug"
        },
        "07125": {
            "GenartName": "Spannteller, Federspanner",
            "NormTermName": "Spannteller",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federspanner"
        },
        "07126": {
            "GenartName": "Steckschlüsselsatz, Federbeinstützlager",
            "NormTermName": "Steckschlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federbeinstützlager"
        },
        "07127": {
            "GenartName": "Haken-Werkzeug",
            "NormTermName": "Haken-Werkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "07128": {
            "GenartName": "Montage-/Demontagewerkzeug, Radio",
            "NormTermName": "Montage-/Demontagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radio"
        },
        "07129": {
            "GenartName": "Montage-/Demontagewerkzeug, Radio",
            "NormTermName": "Montage-/Demontagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radio"
        },
        "07130": {
            "GenartName": "Montage-/Demontagewerkzeug-Satz, Radio",
            "NormTermName": "Montage-/Demontagewerkzeug-Satz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radio"
        },
        "07131": {
            "GenartName": "Bürstenband, Bandschleifer",
            "NormTermName": "Bürstenband",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bandschleifer"
        },
        "07132": {
            "GenartName": "Dichtungssatz, Schlagschrauber",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07133": {
            "GenartName": "Distanzscheibe, Schlagschrauber",
            "NormTermName": "Distanzscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07134": {
            "GenartName": "Gehäuse, Schlagschrauber",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07135": {
            "GenartName": "Gehäuseschraubensatz, Schlagschrauber",
            "NormTermName": "Gehäuseschraubensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07136": {
            "GenartName": "Gehäuseabdeckung, Schlagschrauber",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07137": {
            "GenartName": "Lager, Ratschenschrauber",
            "NormTermName": "Lager",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07138": {
            "GenartName": "Kugelsatz, Schlagschrauber",
            "NormTermName": "Kugelsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07139": {
            "GenartName": "Lamellensatz, Ratschenschrauber",
            "NormTermName": "Lamellensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07141": {
            "GenartName": "Luftauslass, Schlagschrauber",
            "NormTermName": "Luftauslass",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07142": {
            "GenartName": "Lufteinlass, Schlagschrauber",
            "NormTermName": "Lufteinlass",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07143": {
            "GenartName": "Lufteinlass-Satz, Schlagschrauber",
            "NormTermName": "Lufteinlass-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07144": {
            "GenartName": "Gehäuse, Ratschenschrauber",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07145": {
            "GenartName": "Schlagwerk, Schlagschrauber",
            "NormTermName": "Schlagwerk",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07146": {
            "GenartName": "Schlagwerk-Buchse, Schlagschrauber",
            "NormTermName": "Schlagwerk-Buchse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07147": {
            "GenartName": "Schlagwerk-Korb, Schlagschrauber",
            "NormTermName": "Schlagwerk-Korb",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07148": {
            "GenartName": "Kurbelwelle, Ratschenschrauber",
            "NormTermName": "Welle",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07149": {
            "GenartName": "Laufbandsatz, Bandschleifer",
            "NormTermName": "Laufbandsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bandschleifer"
        },
        "07150": {
            "GenartName": "Laufrolle, Bandschleifer",
            "NormTermName": "Laufrolle",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bandschleifer"
        },
        "07151": {
            "GenartName": "Reparatursatz, Ratschenschrauber",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07152": {
            "GenartName": "Rotor, Ratschenschrauber",
            "NormTermName": "Rotor",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07153": {
            "GenartName": "Rotorbuchse, Ratschenschrauber",
            "NormTermName": "Buchse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07154": {
            "GenartName": "Griff, Ratschenschrauber",
            "NormTermName": "Griff",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07155": {
            "GenartName": "Mutter, Ratschenschrauber",
            "NormTermName": "Mutter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07156": {
            "GenartName": "Steckschlüsseleinsatz, Lenkstockhebel",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Lenkstockhebel"
        },
        "07157": {
            "GenartName": "Schleifscheibensatz, Exzenterschleifer",
            "NormTermName": "Schleifscheibensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Exzenterschleifer"
        },
        "07158": {
            "GenartName": "Schmiernippel, Schlagschrauber",
            "NormTermName": "Nippel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07159": {
            "GenartName": "Schmiernippelsatz, Schlagschrauber",
            "NormTermName": "Nippelsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07160": {
            "GenartName": "Trennscheibensatz, Winkelschleifer",
            "NormTermName": "Trennscheibensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "07161": {
            "GenartName": "Umschalthebel, Schlagschrauber",
            "NormTermName": "Umschalthebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07162": {
            "GenartName": "Umschalthebel-Satz, Schlagschrauber",
            "NormTermName": "Umschalthebel-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07163": {
            "GenartName": "Ventilsatz, Bandschleifer",
            "NormTermName": "Ventilsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bandschleifer"
        },
        "07164": {
            "GenartName": "Sägeblattsatz, Stichsäge",
            "NormTermName": "Sägeblattsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stichsäge"
        },
        "07165": {
            "GenartName": "Vierkant, Schlagschrauber",
            "NormTermName": "Vierkant",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07166": {
            "GenartName": "Motorengehäuse, Ratschenschrauber",
            "NormTermName": "Motorengehäuse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07167": {
            "GenartName": "Nadelsatz, Nadelentroster",
            "NormTermName": "Nadelsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Nadelentroster"
        },
        "07168": {
            "GenartName": "Zahnkranz, Ratschenschrauber",
            "NormTermName": "Zahnkranz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07169": {
            "GenartName": "Zylinder, Schlagschrauber",
            "NormTermName": "Zylinder",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07170": {
            "GenartName": "Zylindersatz, Schlagschrauber",
            "NormTermName": "Zylindersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07171": {
            "GenartName": "Luftleitblechsatz, Schlagschrauber",
            "NormTermName": "Luftleitblechsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07172": {
            "GenartName": "Feder, Schlagschrauber",
            "NormTermName": "Feder",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07173": {
            "GenartName": "Federsatz, Schlagschrauber",
            "NormTermName": "Federsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07174": {
            "GenartName": "Federstiftsatz, Schlagschrauber",
            "NormTermName": "Federstiftsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07175": {
            "GenartName": "Federstift, Schlagschrauber",
            "NormTermName": "Federstift",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07176": {
            "GenartName": "Luftleitblech, Schlagschrauber",
            "NormTermName": "Luftleitblech",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07177": {
            "GenartName": "Dichtung, Schlagschrauber",
            "NormTermName": "Dichtung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07178": {
            "GenartName": "Anzeige, Drehmomentschlüssel",
            "NormTermName": "Anzeige",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "07179": {
            "GenartName": "Druckluftzufuhr, Schlagschrauber",
            "NormTermName": "Druckluftzufuhr",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07180": {
            "GenartName": "Klingensatz, Blechknabber",
            "NormTermName": "Klingensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Blechknabber"
        },
        "07181": {
            "GenartName": "Reparatursatz, Blechknabber",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Blechknabber"
        },
        "07182": {
            "GenartName": "Betätigungshebel, Ratschenschrauber",
            "NormTermName": "Betätigungshebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07183": {
            "GenartName": "Schraubensatz, Schlagschrauber",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07184": {
            "GenartName": "Schloss, Drehmomentschlüssel",
            "NormTermName": "Schloss",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "07185": {
            "GenartName": "Schalldämpfer, Schlagschrauber",
            "NormTermName": "Schalldämpfer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07186": {
            "GenartName": "Distanzscheibensatz, Schlagschrauber",
            "NormTermName": "Distanzscheibensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07187": {
            "GenartName": "Sicherungsringsatz",
            "NormTermName": "Sicherungsringsatz",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "07188": {
            "GenartName": "Staubbeutelsatz, Exzenterschleifer",
            "NormTermName": "Staubbeutelsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Exzenterschleifer"
        },
        "07189": {
            "GenartName": "Steuerventil, Schlagschrauber",
            "NormTermName": "Ventil",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07190": {
            "GenartName": "Reparatursatz, Drehmomentschlüssel",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "07191": {
            "GenartName": "Ventilschraube, Ratschenschrauber",
            "NormTermName": "Schraube",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07192": {
            "GenartName": "Griff, Drehmomentschlüssel",
            "NormTermName": "Griff",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "07193": {
            "GenartName": "Verriegelungsbuchse, Schlagschrauber",
            "NormTermName": "Buchse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07194": {
            "GenartName": "Scherstift, Drehmomentvervielfältiger",
            "NormTermName": "Stift",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentvervielfältiger"
        },
        "07195": {
            "GenartName": "Spindel, Wischarm",
            "NormTermName": "Spindel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Wischarmabzieher"
        },
        "07196": {
            "GenartName": "Induktions-Spulensatz, Heizpistole",
            "NormTermName": "Induktions-Spulensatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Induktions-Heizpistole"
        },
        "07197": {
            "GenartName": "Zugglocke, Wischarmabzieher",
            "NormTermName": "Zugglocke",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Wischarmabzieher"
        },
        "07198": {
            "GenartName": "Druckstück, Montagewerkzeug Trag-/Führungsgelenk",
            "NormTermName": "Druckstück",
            "AssGrpName": "Startanlage, Universal",
            "UsageName": "Montagewerkzeug Trag-/Führungsgelenk"
        },
        "07199": {
            "GenartName": "Auspressvorrichtung, Antriebswelle",
            "NormTermName": "Auspressvorrichtung",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Antriebswelle"
        },
        "07200": {
            "GenartName": "Gegenhalter, Innen-/Außenabzieher",
            "NormTermName": "Gegenhalter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Innen-/Außenabzieher"
        },
        "07201": {
            "GenartName": "Abzieher, Kühlerlüfter",
            "NormTermName": "Abzieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kühlerlüfter"
        },
        "07202": {
            "GenartName": "Montagewerkzeug, Trag-/Führungsgelenk",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "07203": {
            "GenartName": "Montagewerkzeugsatz, Trag-/Führungsgelenk",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "07204": {
            "GenartName": "Abdeckung, Handleuchte",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Handleuchte"
        },
        "07205": {
            "GenartName": "Spindel, Montagewerkzeug Silentlager",
            "NormTermName": "Spindel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Silentlager"
        },
        "07206": {
            "GenartName": "Zentrierscheibe, Montagewerkzeug Silentlager",
            "NormTermName": "Zentrierscheibe",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Silentlager"
        },
        "07207": {
            "GenartName": "Ausdrückschraube, Antriebswelle",
            "NormTermName": "Ausdrückschraube",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Antriebswelle"
        },
        "07208": {
            "GenartName": "Zentrierhülse, Montagewerkzeug Silentlager",
            "NormTermName": "Hülse",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Silentlager"
        },
        "07209": {
            "GenartName": "Achsmutterschlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Achsmutter"
        },
        "07210": {
            "GenartName": "Mutter, Bolzenausdreher",
            "NormTermName": "Mutter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bolzenausdreher"
        },
        "07211": {
            "GenartName": "Trenn-/Montagegabel",
            "NormTermName": "Trenn-/Montagegabel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07212": {
            "GenartName": "Feder, Vornschneider",
            "NormTermName": "Feder",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Vornschneider"
        },
        "07213": {
            "GenartName": "Montagewerkzeugsatz, Kupplung/Schwungrad",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kupplung/Schwungrad"
        },
        "07214": {
            "GenartName": "Schutzleiste, Werkzeugwagen",
            "NormTermName": "Schutzleiste",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "07215": {
            "GenartName": "Zentrierwerkzeugsatz, Radmontage",
            "NormTermName": "Zentrierwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radmontage"
        },
        "07216": {
            "GenartName": "Zentrierwerkzeugsatz, Radmontage",
            "NormTermName": "Zentrierwerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radmontage"
        },
        "07217": {
            "GenartName": "Schlagkopf, Wuchtgewichtezange",
            "NormTermName": "Schlagkopf",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Wuchtgewichtezange"
        },
        "07218": {
            "GenartName": "Adapter, Montagewerkzeug Silentlager",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Silentlager"
        },
        "07219": {
            "GenartName": "Adapter, Montagewerkzeug Silentlager",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Montagewerkzeug Silentlager"
        },
        "07220": {
            "GenartName": "Zentrierwerkzeug, Radmontage",
            "NormTermName": "Zentrierwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radmontage"
        },
        "07221": {
            "GenartName": "Anbohrhülse, Bolzenausdreher",
            "NormTermName": "Anbohrhülse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bolzenausdreher"
        },
        "07222": {
            "GenartName": "Zentrierwerkzeug, Radmontage",
            "NormTermName": "Zentrierwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radmontage"
        },
        "07223": {
            "GenartName": "Werkzeug-Wandhalterung",
            "NormTermName": "Werkzeug-Wandhalterung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07224": {
            "GenartName": "Radmutternschlüssel",
            "NormTermName": "Radmutternschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07225": {
            "GenartName": "Software, Drehmoment-Messgerät",
            "NormTermName": "Software",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drehmoment-Messgerät"
        },
        "07226": {
            "GenartName": "Druckstück, Montagewerkzeug Silentlager",
            "NormTermName": "Druckstück",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Silentlager"
        },
        "07227": {
            "GenartName": "Druckscheibe, Montagewerkzeug Silentlager",
            "NormTermName": "Druckscheibe",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Silentlager"
        },
        "07228": {
            "GenartName": "Griff, Blindnietzange",
            "NormTermName": "Griff",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Blindnietzange"
        },
        "07229": {
            "GenartName": "Klingensatz, Ziehmesser (Scheibendemontage)",
            "NormTermName": "Klingensatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ziehmesser (Scheibendemontage)"
        },
        "07230": {
            "GenartName": "Kohlebürstensatz, Schlagschrauber",
            "NormTermName": "Kohlebürstensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07231": {
            "GenartName": "Bohrkronensatz, Schweißpunktfräser",
            "NormTermName": "Bohrkronensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schweißpunktfräser"
        },
        "07232": {
            "GenartName": "Ersatzspitze, Schweißpunktfräser",
            "NormTermName": "Ersatzspitze",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schweißpunktfräser"
        },
        "07233": {
            "GenartName": "Reparatursatz, Drehmomentvervielfältiger",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentvervielfältiger"
        },
        "07234": {
            "GenartName": "Reparatursatz, Federspanner",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federspanner"
        },
        "07235": {
            "GenartName": "Abstützvorrichtung, Drehmomentvervielfältiger",
            "NormTermName": "Abstützvorrichtung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentvervielfältiger"
        },
        "07236": {
            "GenartName": "Feder, Blechschere",
            "NormTermName": "Feder",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Blechschere"
        },
        "07237": {
            "GenartName": "Reparatursatz, Drehmomentschlüsselschloss",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüsselschloss"
        },
        "07238": {
            "GenartName": "Reparatursatz, Absetzzange",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Absetzzange"
        },
        "07239": {
            "GenartName": "Futterbackensatz, Blindnietzange",
            "NormTermName": "Futterbackensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Blindnietzange"
        },
        "07240": {
            "GenartName": "Gehäuse, Montagewerkzeug Silentlager",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Silentlager"
        },
        "07241": {
            "GenartName": "Spindel, Auspressvorrichtung Antriebswelle",
            "NormTermName": "Spindel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Auspressvorrichtung (Antriebswelle)"
        },
        "07242": {
            "GenartName": "Feilenhalter",
            "NormTermName": "Feilenhalter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07243": {
            "GenartName": "Kraft-Steckschlüsseleinsatz, Bremsscheibe",
            "NormTermName": "Kraft-Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremsscheibe"
        },
        "07244": {
            "GenartName": "Kraft-Steckschlüsseleinsatz, Bremsscheibe",
            "NormTermName": "Kraft-Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremsscheibe"
        },
        "07245": {
            "GenartName": "Griff, Pumpsprühflasche",
            "NormTermName": "Griff",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Pumpsprühflasche"
        },
        "07246": {
            "GenartName": "Saugrohr, Pumpsprühflasche",
            "NormTermName": "Saugrohr",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Pumpsprühflasche"
        },
        "07247": {
            "GenartName": "Saugrohrspitze, Pumpsprühflasche",
            "NormTermName": "Saugrohrspitze",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Pumpsprühflasche"
        },
        "07248": {
            "GenartName": "Zugbolzen, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Bolzen",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "07249": {
            "GenartName": "Zentrierhülse, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Hülse",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "07250": {
            "GenartName": "Schutzbacken, Rohrzange",
            "NormTermName": "Schutzbacken",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrzange"
        },
        "07251": {
            "GenartName": "Adapter, Kühlsystemdruckprüfset",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kühlsystemdruckprüfset"
        },
        "07252": {
            "GenartName": "Montagewerkzeug, Reifendruck-Kontrollsystem",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Reifendruckkontrollsystem"
        },
        "07253": {
            "GenartName": "Reduzierstück, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Reduzierstück",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "07254": {
            "GenartName": "Öldienstschlüssel",
            "NormTermName": "Öldienstschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07255": {
            "GenartName": "Steckschlüsseleinsatz, Radlagerkappe",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radlagerkappe"
        },
        "07256": {
            "GenartName": "Ohrstöpsel, Stethoskop",
            "NormTermName": "Ohrstöpsel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Stethoskop"
        },
        "07257": {
            "GenartName": "Ölfilterschlüssel, Direktschaltgetriebe",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Direktschaltgetriebe"
        },
        "07258": {
            "GenartName": "Buchse, Rohrsäule (Werkzeugwagen)",
            "NormTermName": "Buchse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Rohrsäule (Werkzeugwagen)"
        },
        "07259": {
            "GenartName": "Abziehersatz, Radlagerinnenring",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radlagerinnenring"
        },
        "07260": {
            "GenartName": "Zapfenschlüssel, Düsenhalter-Spannmutter",
            "NormTermName": "Zapfenschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Düsenhalter-Spannmutter"
        },
        "07261": {
            "GenartName": "Zapfenschlüssel, Düsenhalter-Spannmutter",
            "NormTermName": "Zapfenschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Düsenhalter-Spannmutter"
        },
        "07262": {
            "GenartName": "Betätigungsschalter, Ratschenschrauber",
            "NormTermName": "Betätigungsschalter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07263": {
            "GenartName": "Dichtringsatz, Ratschenschrauber",
            "NormTermName": "Dichtringsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07264": {
            "GenartName": "Luftauslass, Ratschenschrauber",
            "NormTermName": "Luftauslass",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07265": {
            "GenartName": "Lufteinlass-Satz, Ratschenschrauber",
            "NormTermName": "Lufteinlass-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07266": {
            "GenartName": "Umschalter-Satz, Ratschenschrauber",
            "NormTermName": "Umschalter-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07267": {
            "GenartName": "Ventileinheit, Ratschenschrauber",
            "NormTermName": "Ventileinheit",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07268": {
            "GenartName": "Vierkant, Ratschenschrauber",
            "NormTermName": "Vierkant",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschenschrauber"
        },
        "07269": {
            "GenartName": "Dichtring, Exzenterschleifer",
            "NormTermName": "Dichtring",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Exzenterschleifer"
        },
        "07270": {
            "GenartName": "Reparaturkabelsatz",
            "NormTermName": "Reparaturkabelsatz",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kabelsatzreparatur"
        },
        "07271": {
            "GenartName": "Adapter, Antennenkabel",
            "NormTermName": "Adapter",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Antennenkabel"
        },
        "07272": {
            "GenartName": "Adapter, Antennenkabel",
            "NormTermName": "Adapter",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Antennenkabel"
        },
        "07273": {
            "GenartName": "Reparatursatz, Druckleitung (Ruß-/Partikelfilter)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Druckleitung (Ruß-/Partikelfilter)"
        },
        "07274": {
            "GenartName": "Reparatursatz, Druckleitung (Ruß-/Partikelfilter)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Druckleitung (Ruß-/Partikelfilter)"
        },
        "07275": {
            "GenartName": "Reparatursatz, Unterdruckleitung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Normteile",
            "UsageName": "Unterdruckleitung"
        },
        "07277": {
            "GenartName": "Betätigungshebel, Exzenterschleifer",
            "NormTermName": "Betätigungshebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Exzenterschleifer"
        },
        "07278": {
            "GenartName": "Antriebskopf, Exzenterschleifer",
            "NormTermName": "Antriebskopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Exzenterschleifer"
        },
        "07279": {
            "GenartName": "Lagersatz, Exzenterschleifer",
            "NormTermName": "Lagersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Exzenterschleifer"
        },
        "07280": {
            "GenartName": "Lager, Exzenterschleifer",
            "NormTermName": "Lager",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Exzenterschleifer"
        },
        "07281": {
            "GenartName": "Lamellensatz, Exzenterschleifer",
            "NormTermName": "Lamellensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Exzenterschleifer"
        },
        "07282": {
            "GenartName": "Kabelreparatursatz, Verdeckmotor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Verdeckmotor"
        },
        "07283": {
            "GenartName": "Kabelreparatursatz, Verdeckmotor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Verdeckmotor"
        },
        "07284": {
            "GenartName": "Kabelreparatursatz, Kühlmitteltemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmitteltemperatursensor"
        },
        "07285": {
            "GenartName": "Kabelreparatursatz, Kühlmitteltemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Kühlmitteltemperatursensor"
        },
        "07286": {
            "GenartName": "Kabelreparatursatz, Kennzeichenleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kennzeichenleuchte"
        },
        "07287": {
            "GenartName": "Kabelreparatursatz, Kennzeichenleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Kennzeichenleuchte"
        },
        "07288": {
            "GenartName": "Kabelreparatursatz, Zündspule",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündspule"
        },
        "07289": {
            "GenartName": "Kabelreparatursatz, Zündspule",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Zündanlage, Universal",
            "UsageName": "Zündspule"
        },
        "07290": {
            "GenartName": "Kabelreparatursatz, Stellelement-Leuchtweitenregulierung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Stellelement-Leuchtweitenregulierung"
        },
        "07291": {
            "GenartName": "Kabelreparatursatz, Stellelement-Leuchtweitenregulierung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Stellelement-Leuchtweitenregulierung"
        },
        "07292": {
            "GenartName": "Kabelreparatursatz, Glühkerze",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Glühanlage",
            "UsageName": "Glühkerze"
        },
        "07293": {
            "GenartName": "Kabelreparatursatz, Glühkerze",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Glühanlage, Universal",
            "UsageName": "Glühkerze"
        },
        "07294": {
            "GenartName": "Drehwinkelmessgerät",
            "NormTermName": "Drehwinkelmessgerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07295": {
            "GenartName": "Entfernungsmessgerät",
            "NormTermName": "Entfernungsmessgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07296": {
            "GenartName": "Maßstab",
            "NormTermName": "Maßstab",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07297": {
            "GenartName": "Bit, Ölablassschraube",
            "NormTermName": "Bit",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ölablassschraube"
        },
        "07298": {
            "GenartName": "Bohrlochbürste",
            "NormTermName": "Bohrlochbürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07299": {
            "GenartName": "Feilenbürste",
            "NormTermName": "Feilenbürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07300": {
            "GenartName": "Drehmomentadapter",
            "NormTermName": "Drehmomentadapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07301": {
            "GenartName": "Bohrkrone, Schweißpunktfräser",
            "NormTermName": "Bohrkrone",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schweißpunktfräser"
        },
        "07302": {
            "GenartName": "Kabelreparatursatz, Innenraumheizlüfter (Motorvorwärmsystem)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Innenraumheizlüfter (Motorvorwärmsystem)"
        },
        "07303": {
            "GenartName": "Kabelreparatursatz, Innenraumheizlüfter (Motorvorwärmsystem)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": null
        },
        "07304": {
            "GenartName": "Kabelreparatursatz, Generator",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Generator",
            "UsageName": "Generator"
        },
        "07305": {
            "GenartName": "Kabelreparatursatz, Generator",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Generator, Universal",
            "UsageName": "Generator"
        },
        "07306": {
            "GenartName": "Kabelreparatursatz, Druckschalter (Klimaanlage)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Druckschalter (Klimaanlage)"
        },
        "07307": {
            "GenartName": "Kabelreparatursatz, Druckschalter (Klimaanlage)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Druckschalter (Klimaanlage)"
        },
        "07308": {
            "GenartName": "Kabelreparatursatz, Sensor-Einparkhilfe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sensor-Einparkhilfe"
        },
        "07309": {
            "GenartName": "Kabelreparatursatz, Sensor-Einparkhilfe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Sensor-Einparkhilfe"
        },
        "07310": {
            "GenartName": "Kabelreparatursatz, Common-Rail-System",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Common-Rail-System"
        },
        "07311": {
            "GenartName": "Kabelreparatursatz, Common-Rail-System",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Common-Rail-System"
        },
        "07312": {
            "GenartName": "Kabelreparatursatz, Fensterheber",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Fensterheber"
        },
        "07313": {
            "GenartName": "Kabelreparatursatz, Fensterheber",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Fensterheber"
        },
        "07314": {
            "GenartName": "Kabelreparatursatz, Kraftstoffpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "07315": {
            "GenartName": "Kabelreparatursatz, Kraftstoffpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Kraftstoffpumpe"
        },
        "07316": {
            "GenartName": "Kabelreparatursatz, Tankgeber",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Tankgeber"
        },
        "07317": {
            "GenartName": "Kabelreparatursatz, Tankgeber",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Tankgeber"
        },
        "07318": {
            "GenartName": "Kabelreparatursatz, Anhängersteckdose",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Anhängersteckdose"
        },
        "07319": {
            "GenartName": "Kabelreparatursatz, Anhängerstecker",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Anhängerstecker"
        },
        "07320": {
            "GenartName": "Kabelreparatursatz, Sensor (Saugrohrdruck)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Sensor (Saugrohrdruck)"
        },
        "07321": {
            "GenartName": "Kabelreparatursatz, Sensor (Saugrohrdruck)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung, Universal",
            "UsageName": "Sensor (Saugrohrdruck)"
        },
        "07322": {
            "GenartName": "Lamellensatz, Stichsäge",
            "NormTermName": "Lamellensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stichsäge"
        },
        "07323": {
            "GenartName": "Lager, Stichsäge",
            "NormTermName": "Lager",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stichsäge"
        },
        "07324": {
            "GenartName": "Betätigungshebel, Stichsäge",
            "NormTermName": "Betätigungshebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stichsäge"
        },
        "07325": {
            "GenartName": "Rotor, Stichsäge",
            "NormTermName": "Rotor",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stichsäge"
        },
        "07326": {
            "GenartName": "Ventileinheit, Stichsäge",
            "NormTermName": "Ventileinheit",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stichsäge"
        },
        "07327": {
            "GenartName": "Reparatursatz, Stichsäge",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stichsäge"
        },
        "07328": {
            "GenartName": "Luftauslass, Stichsäge",
            "NormTermName": "Luftauslass",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stichsäge"
        },
        "07329": {
            "GenartName": "Achsmutterschlüssel-Satz",
            "NormTermName": "Schlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Achsmutter"
        },
        "07330": {
            "GenartName": "Achsmutterschlüssel-Satz",
            "NormTermName": "Schlüsselsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Achsmutter"
        },
        "07331": {
            "GenartName": "Anschlussstutzen, Bremsdruckprüfgerät",
            "NormTermName": "Anschlussstutzen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsdruckprüfgerät"
        },
        "07332": {
            "GenartName": "Materialcontainer",
            "NormTermName": "Materialcontainer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07333": {
            "GenartName": "Auslaufrohr",
            "NormTermName": "Auslaufrohr",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07334": {
            "GenartName": "Kühlwasserkanne",
            "NormTermName": "Kühlwasserkanne",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07335": {
            "GenartName": "Messeimer",
            "NormTermName": "Messeimer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07336": {
            "GenartName": "Pumpzerstäuber",
            "NormTermName": "Pumpzerstäuber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07337": {
            "GenartName": "Füllstandsanzeige, Vorratstank",
            "NormTermName": "Anzeige",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Vorratstank"
        },
        "07338": {
            "GenartName": "Füllstandsanzeige, Entsorgungstank",
            "NormTermName": "Anzeige",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Entsorgungstank"
        },
        "07339": {
            "GenartName": "Domdeckel, Vorratstank",
            "NormTermName": "Deckel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Vorratstank"
        },
        "07340": {
            "GenartName": "Einfüllschale, Vorratstank",
            "NormTermName": "Einfüllschale",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Vorratstank"
        },
        "07341": {
            "GenartName": "Ölkannenstation",
            "NormTermName": "Ölkannenstation",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07343": {
            "GenartName": "Messuhr, Ölkannenstation",
            "NormTermName": "Messuhr",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Ölkannenstation"
        },
        "07344": {
            "GenartName": "Schrank, Schlauchaufroller",
            "NormTermName": "Schrank",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schlauchaufroller"
        },
        "07345": {
            "GenartName": "Fasswagen-Set",
            "NormTermName": "Fasswagen-Set",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07346": {
            "GenartName": "Ölabgabestation (Fassabgabe)",
            "NormTermName": "Ölabgabestation",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07347": {
            "GenartName": "Schlauchaufroller",
            "NormTermName": "Schlauchaufroller",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07348": {
            "GenartName": "Abfüllplatz",
            "NormTermName": "Abfüllplatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07349": {
            "GenartName": "Fassauflage, Regal",
            "NormTermName": "Fassauflage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Regal"
        },
        "07350": {
            "GenartName": "Auffangwanne, Regalebene",
            "NormTermName": "Auffangwanne",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Regalebene"
        },
        "07351": {
            "GenartName": "Armatur",
            "NormTermName": "Armatur",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "07352": {
            "GenartName": "Fasskarre",
            "NormTermName": "Fasskarre",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07353": {
            "GenartName": "Fasshubwagen",
            "NormTermName": "Fasshubwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07354": {
            "GenartName": "Fass-Füllstandsanzeige",
            "NormTermName": "Fass-Füllstandsanzeige",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07355": {
            "GenartName": "Fasszange",
            "NormTermName": "Fasszange",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07356": {
            "GenartName": "Niveauschalter, Fasspumpe",
            "NormTermName": "Niveauschalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fasspumpe"
        },
        "07357": {
            "GenartName": "Universalspritzflasche",
            "NormTermName": "Universalspritzflasche",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07358": {
            "GenartName": "Wasserkanister",
            "NormTermName": "Wasserkanister",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07359": {
            "GenartName": "Öl-Abgabeterminal",
            "NormTermName": "Öl-Abgabeterminal",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07360": {
            "GenartName": "Ölabgabestation (Tankaufbau)",
            "NormTermName": "Ölabgabestation",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Ölabgabestation (Tankaufbau)"
        },
        "07361": {
            "GenartName": "Spannfutter, Stabschleifer",
            "NormTermName": "Spannfutter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stabschleifer"
        },
        "07362": {
            "GenartName": "Bohrfutterschlüssel",
            "NormTermName": "Bohrfutterschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07363": {
            "GenartName": "Schleifstein",
            "NormTermName": "Schleifstein",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07365": {
            "GenartName": "Schleifband, Bandschleifer",
            "NormTermName": "Schleifband",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bandschleifer"
        },
        "07366": {
            "GenartName": "Schleifscheibensatz, Winkelschleifer",
            "NormTermName": "Schleifscheibensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "07367": {
            "GenartName": "Kabelaufroller",
            "NormTermName": "Aufroller",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Elektroleitung"
        },
        "07368": {
            "GenartName": "Deckel, Unterdruckpumpe",
            "NormTermName": "Deckel",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruckpumpe"
        },
        "07369": {
            "GenartName": "Zange, Kettenschloss",
            "NormTermName": "Zange",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Kettenschloss"
        },
        "07370": {
            "GenartName": "Kettentrenn-/Nietgerät",
            "NormTermName": "Kettentrenn-/Nietgerät",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07371": {
            "GenartName": "Kettentrenn-/Nietgeräte-Satz",
            "NormTermName": "Kettentrenn-/Nietgeräte-Satz",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07372": {
            "GenartName": "Kettentrenngerät",
            "NormTermName": "Kettentrenngerät",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07373": {
            "GenartName": "Kettenhalter",
            "NormTermName": "Kettenhalter",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07374": {
            "GenartName": "Kettenbürste",
            "NormTermName": "Kettenbürste",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07375": {
            "GenartName": "Kettenlehre",
            "NormTermName": "Lehre",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Kette (Zweirad)"
        },
        "07376": {
            "GenartName": "Prüfgerät, Kettenflucht",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Kettenflucht"
        },
        "07377": {
            "GenartName": "Messgerät, Kettenverschleiß",
            "NormTermName": "Messgerät",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Kettenverschleiß"
        },
        "07378": {
            "GenartName": "Behälter, Bremsflüssigkeitsbefüllung",
            "NormTermName": "Behälter",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Bremsflüssigkeit"
        },
        "07379": {
            "GenartName": "Adapter, Behälter (Bremsflüssigkeitsbefüllung)",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Behälter (Bremsflüssigkeitsbefüllung)"
        },
        "07380": {
            "GenartName": "Austreiber, Lenkkopflager",
            "NormTermName": "Austreiber",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Lenkkopflager"
        },
        "07381": {
            "GenartName": "Locheisensatz, Lenkerenden",
            "NormTermName": "Locheisensatz",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Lenkerenden"
        },
        "07382": {
            "GenartName": "Lenkkopflagerschlüssel",
            "NormTermName": "Lenkkopflagerschlüssel",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07383": {
            "GenartName": "Federwaage, Lenkkopflagervorspannung",
            "NormTermName": "Waage",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Lenkkopflagervorspannung"
        },
        "07384": {
            "GenartName": "Montagewerkzeugsatz, Lenkkopflager",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Lenkkopflager"
        },
        "07385": {
            "GenartName": "Abzieher, Anlasserfreilauf",
            "NormTermName": "Abzieher",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Anlasserfreilauf"
        },
        "07386": {
            "GenartName": "Speichenschlüssel",
            "NormTermName": "Speichenschlüssel",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07387": {
            "GenartName": "Speichenschlüsselsatz",
            "NormTermName": "Speichenschlüsselsatz",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07388": {
            "GenartName": "Drehmoment-Speichenschlüsselsatz",
            "NormTermName": "Drehmoment-Speichenschlüsselsatz",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07389": {
            "GenartName": "Drehmoment-Speichenschlüssel",
            "NormTermName": "Drehmoment-Speichenschlüssel",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07390": {
            "GenartName": "Einschlagwerkzeug, Steckgewichte",
            "NormTermName": "Einschlagwerkzeug",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Steckgewichte"
        },
        "07391": {
            "GenartName": "Reifenmontagezange",
            "NormTermName": "Reifenmontagezange",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07392": {
            "GenartName": "Steckschlüsseleinsatz, Abgastemperatursensor",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Abgastemperatursensor"
        },
        "07393": {
            "GenartName": "Steckschlüsseleinsatz, Abgastemperatursensor",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Abgastemperatursensor"
        },
        "07394": {
            "GenartName": "Schutzschlauch, Verzurrkette",
            "NormTermName": "Schutzschlauch",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Verzurrkette"
        },
        "07395": {
            "GenartName": "Spanngurtaufroller",
            "NormTermName": "Spanngurtaufroller",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "07396": {
            "GenartName": "Ersatzakku, Akkuschrauber",
            "NormTermName": "Akkupack",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Akkuschrauber"
        },
        "07397": {
            "GenartName": "Filter, Entlüftung (Kraftstoffbehälter)",
            "NormTermName": "Filter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Entlüftung"
        },
        "07398": {
            "GenartName": "Filter, Entlüftung (Lenkgetriebehydraulikbehälter)",
            "NormTermName": "Filter",
            "AssGrpName": "Lenkung",
            "UsageName": "Entlüftung"
        },
        "07399": {
            "GenartName": "Filter, Entlüftung (Kupplungshydraulikbehälter)",
            "NormTermName": "Filter",
            "AssGrpName": "Kupplung",
            "UsageName": "Entlüftung"
        },
        "07400": {
            "GenartName": "Filter, Entlüftung (Bremshydraulikbehälter)",
            "NormTermName": "Filter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Entlüftung"
        },
        "07401": {
            "GenartName": "Membran, Stethoskop",
            "NormTermName": "Membran",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Stethoskop"
        },
        "07402": {
            "GenartName": "Gegenhalter, Steuerkette",
            "NormTermName": "Gegenhalter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Steuerkette"
        },
        "07403": {
            "GenartName": "Gegenhalter, Steuerkette",
            "NormTermName": "Gegenhalter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Steuerkette"
        },
        "07404": {
            "GenartName": "Reaktionsflüssigkeit, Zylinderkopf-Dichtigkeit",
            "NormTermName": "Reaktionsflüssigkeit",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Zylinderkopf-Dichtigkeit"
        },
        "07405": {
            "GenartName": "Pipette",
            "NormTermName": "Pipette",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07406": {
            "GenartName": "Adapter, Kamerasonde (Videoendoskop)",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kamerasonde (Videoendoskop)"
        },
        "07407": {
            "GenartName": "Einstellwerkzeug, Wischarm",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Wischarm"
        },
        "07408": {
            "GenartName": "Pressrahmen, Montagewerkzeug Silentlager",
            "NormTermName": "Pressrahmen",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Silentlager"
        },
        "07409": {
            "GenartName": "Folienradierer-Satz, Multi-Schleifer",
            "NormTermName": "Folienradierer-Satz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Multi-Schleifer"
        },
        "07410": {
            "GenartName": "Verbindungs-/Verteilerstücksatz, Drucklufttechnik",
            "NormTermName": "Verbindungselementsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "07411": {
            "GenartName": "Schleifbandsatz, Bandschleifer",
            "NormTermName": "Schleifbandsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bandschleifer"
        },
        "07412": {
            "GenartName": "Wartungsservice, Drucklufttechnik",
            "NormTermName": "Wartungsservice",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "07413": {
            "GenartName": "Elektromotor, Heckklappe",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Heckklappe"
        },
        "07414": {
            "GenartName": "Elektromotor, Motorhaube",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Motorhaube"
        },
        "07415": {
            "GenartName": "Elektromotor, Tür",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Tür"
        },
        "07416": {
            "GenartName": "Sperrbalken/-latte, Ladungssicherung",
            "NormTermName": "Sperrbalken/-latte",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Ladungssicherung"
        },
        "07417": {
            "GenartName": "Wirbelhaken",
            "NormTermName": "Haken",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Zurrgurt"
        },
        "07418": {
            "GenartName": "Haken, Spanngurt",
            "NormTermName": "Haken",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Spanngurt"
        },
        "07419": {
            "GenartName": "Zurrschiene, Ladungssicherung",
            "NormTermName": "Zurrschiene",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "07420": {
            "GenartName": "Standschiene, Ladungssicherung",
            "NormTermName": "Standschiene",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "07421": {
            "GenartName": "Verbinder, Verzurrkette",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Verzurrkette"
        },
        "07422": {
            "GenartName": "Verkürzungshaken, Verzurrkette",
            "NormTermName": "Haken",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Verzurrkette"
        },
        "07423": {
            "GenartName": "Verkürzungsklaue, Verzurrkette",
            "NormTermName": "Klaue",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Verzurrkette"
        },
        "07424": {
            "GenartName": "Verzurrkette",
            "NormTermName": "Verzurrkette",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "07425": {
            "GenartName": "Vorderradbügel, Standschiene",
            "NormTermName": "Bügel",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Standschiene"
        },
        "07426": {
            "GenartName": "Zurrbügel",
            "NormTermName": "Bügel",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Zurrbügel"
        },
        "07427": {
            "GenartName": "Zurrkasten",
            "NormTermName": "Zurrkasten",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "07428": {
            "GenartName": "Anti-Rutsch-Beschichtung",
            "NormTermName": "Anti-Rutsch-Beschichtung",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07429": {
            "GenartName": "Auffahrschiene",
            "NormTermName": "Auffahrschiene",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "07430": {
            "GenartName": "Spanngurtsatz",
            "NormTermName": "Gurtsatz",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Spanngurtsatz"
        },
        "07431": {
            "GenartName": "Zurrkettenanhänger",
            "NormTermName": "Verzurrkettenanhänger",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "07432": {
            "GenartName": "Beschlag, Zurrschiene",
            "NormTermName": "Beschlag",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Zurrschiene"
        },
        "07433": {
            "GenartName": "Dreigliedstück, Zurrkette",
            "NormTermName": "Dreigliedstück",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Verzurrkette"
        },
        "07434": {
            "GenartName": "Ratsche, Spanngurt",
            "NormTermName": "Spannratsche",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Spanngurt"
        },
        "07435": {
            "GenartName": "Sortiment, Reparaturkabel",
            "NormTermName": "Sortiment",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Reparaturkabel"
        },
        "07436": {
            "GenartName": "Schleifscheibensatz, Multi-Schleifer",
            "NormTermName": "Schleifscheibensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Multi-Schleifer"
        },
        "07437": {
            "GenartName": "Aufsatz-Set, Polieranlage",
            "NormTermName": "Aufsatz-Set",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Polieranlage"
        },
        "07438": {
            "GenartName": "Reparatursatz, Schlauchschellenzange",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlauchschellenzange"
        },
        "07439": {
            "GenartName": "Stützring, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Stützring",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "07440": {
            "GenartName": "Stützringsatz, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Stützringsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "07441": {
            "GenartName": "Reduzierring, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Reduzierring",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "07442": {
            "GenartName": "Einklemmschutz, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Einklemmschutz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "07443": {
            "GenartName": "Pumpe, Werkstattpresse",
            "NormTermName": "Pumpe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkstattpresse"
        },
        "07444": {
            "GenartName": "Sicherungselement, Gabelkopflasthaken",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Gabelkopflasthaken"
        },
        "07445": {
            "GenartName": "Rollensatz, Werkzeugwagenaufsatz",
            "NormTermName": "Rollensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeug-Box-Modul"
        },
        "07446": {
            "GenartName": "Werkzeug-Box-Modul",
            "NormTermName": "Werkzeug-Box-Modul",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07447": {
            "GenartName": "Arbeitsplatte, Werkzeug-Box-Modul",
            "NormTermName": "Arbeitsplatte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeug-Box-Modul"
        },
        "07448": {
            "GenartName": "Entnahmesicherung, Werkzeug-Box-Modul",
            "NormTermName": "Entnahmesicherung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeug-Box-Modul"
        },
        "07449": {
            "GenartName": "Einteilersatz, Schublade (Werkzeug-Box-Modul)",
            "NormTermName": "Einteilersatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeug-Box-Modul"
        },
        "07450": {
            "GenartName": "Fahrgestell, Werkzeugkoffer",
            "NormTermName": "Fahrgestell",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugkoffer"
        },
        "07451": {
            "GenartName": "Zurrpunkt, Zurrschiene",
            "NormTermName": "Zurrpunkt",
            "AssGrpName": "Ladungssicherung",
            "UsageName": "Zurrschiene"
        },
        "07452": {
            "GenartName": "Haltewerkzeug, Mutter",
            "NormTermName": "Haltewerkzeug",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Mutter"
        },
        "07453": {
            "GenartName": "Schmiedezange",
            "NormTermName": "Schmiedezange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07454": {
            "GenartName": "Aufsatz, Schlosserhammer",
            "NormTermName": "Aufsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlosserhammer"
        },
        "07455": {
            "GenartName": "Dekorring, Heckleuchte",
            "NormTermName": "Dekorring",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Heckleuchte"
        },
        "07456": {
            "GenartName": "Kontrollgerät, Beleuchtung",
            "NormTermName": "Kontrollgerät",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Beleuchtung"
        },
        "07457": {
            "GenartName": "Befestigungsschiene, Heckleuchte",
            "NormTermName": "Befestigungsschiene",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Heckleuchte"
        },
        "07458": {
            "GenartName": "Befestigungsschiene/-rohr, Innenraumleuchte",
            "NormTermName": "Befestigungsschiene/-rohr",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Innenraumleuchte"
        },
        "07459": {
            "GenartName": "Standmatte",
            "NormTermName": "Standmatte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07460": {
            "GenartName": "Sicherungsaufsteckgriff",
            "NormTermName": "Sicherungsaufsteckgriff",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07461": {
            "GenartName": "Kabel-Spreizkeil",
            "NormTermName": "Kabel-Spreizkeil",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07462": {
            "GenartName": "Rohr-Kalibrierwerkzeug",
            "NormTermName": "Rohr-Kalibrierwerkzeug",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07463": {
            "GenartName": "Kunststoffscherensatz",
            "NormTermName": "Kunststoffscherensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07464": {
            "GenartName": "Bördelgeräte-Satz",
            "NormTermName": "Bördelgeräte-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07465": {
            "GenartName": "Schraube, Ölwanne-Automatikgetriebe",
            "NormTermName": "Schraube",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölwanne-Automatikgetriebe"
        },
        "07466": {
            "GenartName": "Armaturenschlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Armaturen"
        },
        "07467": {
            "GenartName": "Schlüssel, Standhahnmutter",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Standhahnmutter"
        },
        "07468": {
            "GenartName": "Gurtrohrzange",
            "NormTermName": "Gurtrohrzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07469": {
            "GenartName": "Ersatzgurt, Gurtrohrzange",
            "NormTermName": "Ersatzgurt",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gurtrohrzange"
        },
        "07470": {
            "GenartName": "Rohrbiegegerät",
            "NormTermName": "Rohrbiegegerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07471": {
            "GenartName": "Untergestell, Rohrbiegegerät",
            "NormTermName": "Untergestell",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrbiegegerät"
        },
        "07472": {
            "GenartName": "Biegeform, Rohrbiegewerkzeug",
            "NormTermName": "Biegeform",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrbiegewerkzeug"
        },
        "07473": {
            "GenartName": "Biegeform, Rohrbiegegerät",
            "NormTermName": "Biegeform",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrbiegegerät"
        },
        "07474": {
            "GenartName": "Saugnapf, Einschleifwerkzeug",
            "NormTermName": "Saugnapf",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einschleifwerkzeug"
        },
        "07475": {
            "GenartName": "Reinigungstücher",
            "NormTermName": "Reinigungstücher",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07477": {
            "GenartName": "Stützteller, Poliermaschine",
            "NormTermName": "Stützteller",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Poliermaschine"
        },
        "07478": {
            "GenartName": "Unterdruckpumpe, Bremsanlage",
            "NormTermName": "Pumpe",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Unterdruck"
        },
        "07479": {
            "GenartName": "Wasserstoffperoxidlösung",
            "NormTermName": "Wasserstoffperoxidlösung",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07480": {
            "GenartName": "Flockungsmittel",
            "NormTermName": "Flockungsmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07481": {
            "GenartName": "Fahrzeug-Waschbürste",
            "NormTermName": "Waschbürste",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrzeugwäsche"
        },
        "07482": {
            "GenartName": "Stützrad",
            "NormTermName": "Stützrad",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07483": {
            "GenartName": "Klemmbügel, Stützrad/Abstellstütze",
            "NormTermName": "Bügel",
            "AssGrpName": "Anhänger",
            "UsageName": "Stützrad/Abstellstütze"
        },
        "07484": {
            "GenartName": "Klemmschelle, Stützrad/Abstellstütze",
            "NormTermName": "Schelle",
            "AssGrpName": "Anhänger",
            "UsageName": "Stützrad/Abstellstütze"
        },
        "07485": {
            "GenartName": "Aufsatz, Poliermaschine",
            "NormTermName": "Aufsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Poliermaschine"
        },
        "07486": {
            "GenartName": "Durchrutschsicherung, Stützrad",
            "NormTermName": "Durchrutschsicherung",
            "AssGrpName": "Anhänger",
            "UsageName": "Stützrad"
        },
        "07487": {
            "GenartName": "Rundrohrklemme, Stützrad",
            "NormTermName": "Rundrohrklemme",
            "AssGrpName": "Anhänger",
            "UsageName": "Stützrad"
        },
        "07488": {
            "GenartName": "Chrompolitur",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Chrompolitur"
        },
        "07489": {
            "GenartName": "Natronlauge",
            "NormTermName": "Natronlauge",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07490": {
            "GenartName": "Rundrohrklemmensatz, Stützrad",
            "NormTermName": "Rundrohrklemmensatz",
            "AssGrpName": "Anhänger",
            "UsageName": "Stützrad"
        },
        "07491": {
            "GenartName": "Rangiergriff, Stützrad",
            "NormTermName": "Rangiergriff",
            "AssGrpName": "Anhänger",
            "UsageName": "Stützrad"
        },
        "07492": {
            "GenartName": "Stützradhalter",
            "NormTermName": "Halter",
            "AssGrpName": "Anhänger",
            "UsageName": "Stützrad/Abstellstütze"
        },
        "07493": {
            "GenartName": "Traverse, Stützradhalter",
            "NormTermName": "Traverse",
            "AssGrpName": "Anhänger",
            "UsageName": "Stützradhalter"
        },
        "07494": {
            "GenartName": "Karosseriefeile",
            "NormTermName": "Karosseriefeile",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07495": {
            "GenartName": "Loch-/Absetzzange",
            "NormTermName": "Loch-/Absetzzange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07496": {
            "GenartName": "Strebe, Stützrad",
            "NormTermName": "Strebe",
            "AssGrpName": "Anhänger",
            "UsageName": "Stützrad"
        },
        "07497": {
            "GenartName": "Ersatzrad, Stützrad",
            "NormTermName": "Ersatzrad",
            "AssGrpName": "Anhänger",
            "UsageName": "Stützrad"
        },
        "07498": {
            "GenartName": "Werkzeug-Rucksack",
            "NormTermName": "Werkzeug-Rucksack",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07500": {
            "GenartName": "Werkzeughalfter",
            "NormTermName": "Werkzeughalfter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07501": {
            "GenartName": "Knieschoner",
            "NormTermName": "Knieschoner",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "07502": {
            "GenartName": "Steckachse, Stützrad",
            "NormTermName": "Steckachse",
            "AssGrpName": "Anhänger",
            "UsageName": "Stützrad"
        },
        "07503": {
            "GenartName": "Knebel, Klemmbügel (Stützrad)",
            "NormTermName": "Knebel",
            "AssGrpName": "Anhänger",
            "UsageName": "Klemmbügel (Stützrad)"
        },
        "07504": {
            "GenartName": "Handkurbel, Stützrad/Abstellstütze",
            "NormTermName": "Kurbel",
            "AssGrpName": "Anhänger",
            "UsageName": "Stützrad/Abstellstütze"
        },
        "07505": {
            "GenartName": "Trocknerkonzentrat",
            "NormTermName": "Trocknerkonzentrat",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07506": {
            "GenartName": "Aufbereitungs-Set, Scheinwerfer",
            "NormTermName": "Aufbereitungs-Set",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Scheinwerfer"
        },
        "07507": {
            "GenartName": "Anti-Beschlag-Spray",
            "NormTermName": "Anti-Beschlag-Spray",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07508": {
            "GenartName": "Waschhandschuh",
            "NormTermName": "Waschhandschuh",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07509": {
            "GenartName": "Scheinwerferpolitur",
            "NormTermName": "Scheinwerferpolitur",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07510": {
            "GenartName": "Scheinwerferversiegelung",
            "NormTermName": "Scheinwerferversiegelung",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07511": {
            "GenartName": "Poliertuch",
            "NormTermName": "Poliertuch",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07512": {
            "GenartName": "Lackversiegelung",
            "NormTermName": "Lackversiegelung",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07513": {
            "GenartName": "Verdeck-/Textilimprägnierung",
            "NormTermName": "Verdeck-/Textilimprägnierung",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07514": {
            "GenartName": "Dosierflasche",
            "NormTermName": "Dosierflasche",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07515": {
            "GenartName": "Fahrzeug-Wasserabzieher",
            "NormTermName": "Wasserabzieher",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07516": {
            "GenartName": "Reinigungs-Knetmasse",
            "NormTermName": "Reinigungs-Knetmasse",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07517": {
            "GenartName": "Scheibenwischer-Schutzhülle",
            "NormTermName": "Schutzhülle",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07518": {
            "GenartName": "Harzentferner",
            "NormTermName": "Harzentferner",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07519": {
            "GenartName": "Öffnungshilfe",
            "NormTermName": "Öffnungshilfe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07520": {
            "GenartName": "Reinigigungskonzentrat",
            "NormTermName": "Reinigerkonzentrat",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07521": {
            "GenartName": "Griff, Handkurbel (Stützrad)",
            "NormTermName": "Griff",
            "AssGrpName": "Anhänger",
            "UsageName": "Handkurbel (Stützrad)"
        },
        "07522": {
            "GenartName": "Halter, Handkurbel (Stützrad)",
            "NormTermName": "Halter",
            "AssGrpName": "Anhänger",
            "UsageName": "Handkurbel (Stützrad)"
        },
        "07523": {
            "GenartName": "Klemmprofil, Staubox",
            "NormTermName": "Klemmprofil",
            "AssGrpName": "Anhänger",
            "UsageName": "Staubox"
        },
        "07524": {
            "GenartName": "Abstellstütze, Anhänger",
            "NormTermName": "Abstellstütze",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07525": {
            "GenartName": "Flanschplatte, Abstellstütze",
            "NormTermName": "Flanschplatte",
            "AssGrpName": "Anhänger",
            "UsageName": "Abstellstütze"
        },
        "07526": {
            "GenartName": "Abstützfuß, Abstellstütze",
            "NormTermName": "Abstützfuß",
            "AssGrpName": "Anhänger",
            "UsageName": "Abstellstütze"
        },
        "07527": {
            "GenartName": "Abstützfußsatz, Abstellstütze",
            "NormTermName": "Abstützfußsatz",
            "AssGrpName": "Anhänger",
            "UsageName": "Abstellstütze"
        },
        "07528": {
            "GenartName": "Adaptersatz, Abstützfuß",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Anhänger",
            "UsageName": "Abstützfuß"
        },
        "07529": {
            "GenartName": "Hubstützensystem",
            "NormTermName": "Hubstützensystem",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07530": {
            "GenartName": "Stützlastwaage",
            "NormTermName": "Stützlastwaage",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07531": {
            "GenartName": "Spindelmutter, Abstellstütze",
            "NormTermName": "Spindelmutter",
            "AssGrpName": "Anhänger",
            "UsageName": "Abstellstütze"
        },
        "07532": {
            "GenartName": "Montageschiene, Abstellstütze",
            "NormTermName": "Montageschiene",
            "AssGrpName": "Anhänger",
            "UsageName": "Abstellstütze"
        },
        "07533": {
            "GenartName": "Winkelhebelverschluss",
            "NormTermName": "Winkelhebelverschluss",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07534": {
            "GenartName": "Kastenverschluss",
            "NormTermName": "Kastenverschluss",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07535": {
            "GenartName": "Pendelverschluss",
            "NormTermName": "Pendelverschluss",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07536": {
            "GenartName": "Pendelzapfen, Pendelverschluss",
            "NormTermName": "Pendelzapfen",
            "AssGrpName": "Anhänger",
            "UsageName": "Pendelverschluss"
        },
        "07537": {
            "GenartName": "Pendelbolzen, Pendelverschluss",
            "NormTermName": "Pendelbolzen",
            "AssGrpName": "Anhänger",
            "UsageName": "Pendelverschluss"
        },
        "07538": {
            "GenartName": "Exzenterverschluss",
            "NormTermName": "Exzenterverschluss",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07539": {
            "GenartName": "Spannverschluss",
            "NormTermName": "Spannverschluss",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07540": {
            "GenartName": "Riegelspannverschluss",
            "NormTermName": "Riegelspannverschluss",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07541": {
            "GenartName": "Gegenhalter, Verschluss",
            "NormTermName": "Gegenhalter",
            "AssGrpName": "Anhänger",
            "UsageName": "Verschluss"
        },
        "07542": {
            "GenartName": "Haltenocken, Exzenterverschluss",
            "NormTermName": "Haltenocken",
            "AssGrpName": "Anhänger",
            "UsageName": "Exzenterverschluss"
        },
        "07543": {
            "GenartName": "Einfassverschluss",
            "NormTermName": "Einfassverschluss",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07544": {
            "GenartName": "Langwegverschluss",
            "NormTermName": "Langwegverschluss",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07545": {
            "GenartName": "Drehstangenverschlusssatz",
            "NormTermName": "Drehstangenverschlusssatz",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07546": {
            "GenartName": "Rohrbiegewerkzeug-Satz",
            "NormTermName": "Rohrbiegewerkzeug-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07547": {
            "GenartName": "Biegehebel, Rohrbiegewerkzeug",
            "NormTermName": "Biegehebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrbiegewerkzeug"
        },
        "07548": {
            "GenartName": "Werkzeugaufnahme, Rohrbiegewerkzeug",
            "NormTermName": "Werkzeugaufnahme",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrbiegewerkzeug"
        },
        "07549": {
            "GenartName": "Untergestell, Rohrbiegewerkzeug",
            "NormTermName": "Untergestell",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrbiegewerkzeug"
        },
        "07550": {
            "GenartName": "Druckrolle, Rohrbiegewerkzeug",
            "NormTermName": "Rolle",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrbiegewerkzeug"
        },
        "07551": {
            "GenartName": "Hydraulikaggregat, Rohrbiegegerät",
            "NormTermName": "Hydraulikaggregat",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrbiegegerät"
        },
        "07552": {
            "GenartName": "Türscharnier",
            "NormTermName": "Scharnier",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhängertür"
        },
        "07553": {
            "GenartName": "Drehstangenverschluss",
            "NormTermName": "Drehstangenverschluss",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07554": {
            "GenartName": "Spannzapfen, Drehstangenverschluss",
            "NormTermName": "Spannzapfen",
            "AssGrpName": "Anhänger",
            "UsageName": "Drehstangenverschluss"
        },
        "07555": {
            "GenartName": "Anschlagring, Drehstangenverschluss",
            "NormTermName": "Anschlagring",
            "AssGrpName": "Anhänger",
            "UsageName": "Drehstangenverschluss"
        },
        "07556": {
            "GenartName": "Stangenführung, Drehstangenverschluss",
            "NormTermName": "Stangenführung",
            "AssGrpName": "Anhänger",
            "UsageName": "Drehstangenverschluss"
        },
        "07557": {
            "GenartName": "Führungsunterlage, Drehstangenverschluss",
            "NormTermName": "Führungsunterlage",
            "AssGrpName": "Anhänger",
            "UsageName": "Drehstangenverschluss"
        },
        "07558": {
            "GenartName": "Trennwandhalter",
            "NormTermName": "Halter",
            "AssGrpName": "Anhänger",
            "UsageName": "Trennwand"
        },
        "07559": {
            "GenartName": "Rohrendstück, Boxenstange",
            "NormTermName": "Rohrendstück",
            "AssGrpName": "Anhänger",
            "UsageName": "Boxenstange"
        },
        "07560": {
            "GenartName": "Zapfen, Rohrendstück",
            "NormTermName": "Zapfen",
            "AssGrpName": "Anhänger",
            "UsageName": "Rohrendstück"
        },
        "07561": {
            "GenartName": "Reifenreparatur-Spray",
            "NormTermName": "Dichtstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Reifenreparatur"
        },
        "07562": {
            "GenartName": "Haken, Rohrendstück",
            "NormTermName": "Haken",
            "AssGrpName": "Anhänger",
            "UsageName": "Rohrendstück"
        },
        "07563": {
            "GenartName": "Anbindegriff",
            "NormTermName": "Anbindegriff",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07564": {
            "GenartName": "Panikverschluss",
            "NormTermName": "Panikverschluss",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07565": {
            "GenartName": "Halter, Seitenwand",
            "NormTermName": "Halter",
            "AssGrpName": "Anhänger",
            "UsageName": "Seitenwand"
        },
        "07566": {
            "GenartName": "Halter, Mittelwand",
            "NormTermName": "Halter",
            "AssGrpName": "Anhänger",
            "UsageName": "Mittelwand"
        },
        "07567": {
            "GenartName": "Bodenlüfter",
            "NormTermName": "Bodenlüfter",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07568": {
            "GenartName": "Spaten-/Stielhalter",
            "NormTermName": "Halter",
            "AssGrpName": "Anhänger",
            "UsageName": "Spaten/Stiel"
        },
        "07569": {
            "GenartName": "Türfeststeller",
            "NormTermName": "Türfeststeller",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07570": {
            "GenartName": "Halter, Türfeststeller",
            "NormTermName": "Halter",
            "AssGrpName": "Anhänger",
            "UsageName": "Türfeststeller"
        },
        "07571": {
            "GenartName": "Schutzeinfassung, Türfeststeller",
            "NormTermName": "Schutzeinfassung",
            "AssGrpName": "Anhänger",
            "UsageName": "Türfeststeller"
        },
        "07572": {
            "GenartName": "Gehäuse, Türfeststeller",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Anhänger",
            "UsageName": "Türfeststeller"
        },
        "07573": {
            "GenartName": "Zapfen, Türfeststeller",
            "NormTermName": "Zapfen",
            "AssGrpName": "Anhänger",
            "UsageName": "Türfeststeller"
        },
        "07574": {
            "GenartName": "Klemmpuffer, Türfeststeller",
            "NormTermName": "Puffer",
            "AssGrpName": "Anhänger",
            "UsageName": "Türfeststeller"
        },
        "07575": {
            "GenartName": "Haubenhalter",
            "NormTermName": "Haubenhalter",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07576": {
            "GenartName": "Rungenhalter",
            "NormTermName": "Rungenhalter",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07577": {
            "GenartName": "Federriegel",
            "NormTermName": "Federriegel",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07578": {
            "GenartName": "Bordwandausleger",
            "NormTermName": "Bordwandausleger",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07579": {
            "GenartName": "Trittbrett, Anhänger",
            "NormTermName": "Trittbrett",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07580": {
            "GenartName": "Kugelkupplung",
            "NormTermName": "Kugelkupplung",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07581": {
            "GenartName": "Fernbedienung, Ladebordwand",
            "NormTermName": "Fernbedienung",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Ladebordwand"
        },
        "07582": {
            "GenartName": "Reibbelag, Kugelkupplung",
            "NormTermName": "Reibbelag",
            "AssGrpName": "Anhänger",
            "UsageName": "Kugelkupplung"
        },
        "07583": {
            "GenartName": "Zugöse",
            "NormTermName": "Zugöse",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07584": {
            "GenartName": "Diskusschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Diskusschloss"
        },
        "07585": {
            "GenartName": "Diebstahlschutz, Kugelkupplung (Anhänger)",
            "NormTermName": "Diebstahlschutz",
            "AssGrpName": "Anhänger",
            "UsageName": "Kugelkupplung (Anhänger)"
        },
        "07586": {
            "GenartName": "Rohrklappstecker",
            "NormTermName": "Rohrklappstecker",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07587": {
            "GenartName": "Klappsplint, Anhänger",
            "NormTermName": "Klappsplint",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07588": {
            "GenartName": "Bodenrolle, Ladebordwand",
            "NormTermName": "Rolle",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Ladebordwand"
        },
        "07589": {
            "GenartName": "Unterschraubbock, Scharnier",
            "NormTermName": "Unterschraubbock",
            "AssGrpName": "Anhänger",
            "UsageName": "Scharnier"
        },
        "07590": {
            "GenartName": "Scharniersatz, Anhänger",
            "NormTermName": "Scharniersatz",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07591": {
            "GenartName": "Bedieneinheit, Ladebordwand",
            "NormTermName": "Bedieneinheit",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Ladebordwand"
        },
        "07592": {
            "GenartName": "Halter, Bedieneinheit (Ladebordwand)",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Bedieneinheit (Ladebordwand)"
        },
        "07593": {
            "GenartName": "Fußschalter, Ladebordwand",
            "NormTermName": "Schalter",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Ladebordwand"
        },
        "07594": {
            "GenartName": "Druckstück, Fußschalter (Ladebordwand)",
            "NormTermName": "Druckstück",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Fußschalter (Ladebordwand)"
        },
        "07595": {
            "GenartName": "Fußschaltergehäuse, Ladebordwand",
            "NormTermName": "Fußschaltergehäuse",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Ladebordwand"
        },
        "07596": {
            "GenartName": "Scharnier, Bordwand",
            "NormTermName": "Scharnier",
            "AssGrpName": "Anhänger",
            "UsageName": "Bordwand"
        },
        "07597": {
            "GenartName": "Handgriff",
            "NormTermName": "Griff",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07598": {
            "GenartName": "Empfänger, Funksteuerung (Ladebordwand)",
            "NormTermName": "Empfänger",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Funksteuerung (Ladebordwand)"
        },
        "07599": {
            "GenartName": "Buchse, Bodenrolle (Ladebordwand)",
            "NormTermName": "Buchse",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Bodenrolle (Ladebordwand)"
        },
        "07600": {
            "GenartName": "Scharnierlager, Anhänger",
            "NormTermName": "Scharnierlager",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07601": {
            "GenartName": "Scharnier, Anhänger",
            "NormTermName": "Scharnier",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07602": {
            "GenartName": "Scharniersatz, Kofferdeckel",
            "NormTermName": "Scharniersatz",
            "AssGrpName": "Anhänger",
            "UsageName": "Kofferdeckel"
        },
        "07603": {
            "GenartName": "Bordwandprofil",
            "NormTermName": "Bordwandprofil",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07604": {
            "GenartName": "Einfassprofil",
            "NormTermName": "Einfassprofil",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07605": {
            "GenartName": "Eckprofil",
            "NormTermName": "Eckprofil",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07606": {
            "GenartName": "Einstecklatte, Bordwandprofil",
            "NormTermName": "Einstecklatte",
            "AssGrpName": "Anhänger",
            "UsageName": "Bordwandprofil"
        },
        "07607": {
            "GenartName": "Schutzkappe, Bordwandprofil",
            "NormTermName": "Kappe",
            "AssGrpName": "Anhänger",
            "UsageName": "Bordwandprofil"
        },
        "07608": {
            "GenartName": "Verschlussstopfen, Bordwandprofil",
            "NormTermName": "Stopfen",
            "AssGrpName": "Anhänger",
            "UsageName": "Bordwandprofil"
        },
        "07609": {
            "GenartName": "Stoßdämpfer, Anhänger",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07610": {
            "GenartName": "Halter, Stoßdämpfer (Anhänger)",
            "NormTermName": "Halter",
            "AssGrpName": "Anhänger",
            "UsageName": "Stoßdämpfer (Anhänger)"
        },
        "07611": {
            "GenartName": "Kotflügel, Anhänger",
            "NormTermName": "Kotflügel",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07612": {
            "GenartName": "Kederprofil, Kotflügel (Anhänger)",
            "NormTermName": "Kederprofil",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07613": {
            "GenartName": "Tritteinlage, Kotflügel (Anhänger)",
            "NormTermName": "Tritteinlage",
            "AssGrpName": "Anhänger",
            "UsageName": "Kotflügel (Anhänger)"
        },
        "07614": {
            "GenartName": "Zugösenschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Anhänger",
            "UsageName": "Zugösenschloss"
        },
        "07615": {
            "GenartName": "Bügelschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "07616": {
            "GenartName": "Steckschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Anhänger",
            "UsageName": "Steckschloss"
        },
        "07617": {
            "GenartName": "Kastenschloss, Kugelkupplung",
            "NormTermName": "Schloss",
            "AssGrpName": "Anhänger",
            "UsageName": "Kugelkupplung"
        },
        "07618": {
            "GenartName": "Kupplungsschloss, Anhänger",
            "NormTermName": "Schloss",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07619": {
            "GenartName": "Radkralle",
            "NormTermName": "Radkralle",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07620": {
            "GenartName": "Drehstangenschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Anhänger",
            "UsageName": "Drehstangenschloss"
        },
        "07621": {
            "GenartName": "Sicherungsbolzen, Abstellstütze",
            "NormTermName": "Bolzen",
            "AssGrpName": "Anhänger",
            "UsageName": "Abstellstütze"
        },
        "07622": {
            "GenartName": "Halter, Ersatzrad",
            "NormTermName": "Halter",
            "AssGrpName": "Anhänger",
            "UsageName": "Ersatzrad"
        },
        "07623": {
            "GenartName": "Reserveradhülle",
            "NormTermName": "Reserveradhülle",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07624": {
            "GenartName": "Sortiment, Glühlampen",
            "NormTermName": "Sortiment",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Glühlampen"
        },
        "07625": {
            "GenartName": "Felgenversiegelung",
            "NormTermName": "Felgenversiegelung",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07626": {
            "GenartName": "Abdeckung, Kugelkupplung/Deichsel",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Anhänger",
            "UsageName": "Kugelkupplung/Deichsel"
        },
        "07627": {
            "GenartName": "Geschwindigkeitsschild",
            "NormTermName": "Geschwindigkeitsschild",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "07628": {
            "GenartName": "Typschild",
            "NormTermName": "Typschild",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "07629": {
            "GenartName": "Laufachse",
            "NormTermName": "Laufachse",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07630": {
            "GenartName": "Waschpolitur",
            "NormTermName": "Waschpolitur",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "07631": {
            "GenartName": "Bremsachse",
            "NormTermName": "Bremsachse",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07632": {
            "GenartName": "Deichsel",
            "NormTermName": "Deichsel",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07633": {
            "GenartName": "Zwischenstück, Deichsel",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Anhänger",
            "UsageName": "Deichsel"
        },
        "07634": {
            "GenartName": "Klemmschalensatz, Deichsel",
            "NormTermName": "Klemmschalensatz",
            "AssGrpName": "Anhänger",
            "UsageName": "Deichsel"
        },
        "07635": {
            "GenartName": "Deichselholm, V-Deichsel",
            "NormTermName": "Deichselholm",
            "AssGrpName": "Anhänger",
            "UsageName": "V-Deichsel"
        },
        "07636": {
            "GenartName": "Deichselholmsatz, V-Deichsel",
            "NormTermName": "Deichselholmsatz",
            "AssGrpName": "Anhänger",
            "UsageName": "V-Deichsel"
        },
        "07637": {
            "GenartName": "Deichselholmanschluss",
            "NormTermName": "Deichselholmanschluss",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07638": {
            "GenartName": "Kettenöl",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Kettenöl"
        },
        "07639": {
            "GenartName": "Winkelhalter, Deichselholm",
            "NormTermName": "Winkelhalter",
            "AssGrpName": "Anhänger",
            "UsageName": "Deichselholm"
        },
        "07640": {
            "GenartName": "Deichselstütze",
            "NormTermName": "Deichselstütze",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07641": {
            "GenartName": "Auflaufeinrichtung",
            "NormTermName": "Auflaufeinrichtung",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "07642": {
            "GenartName": "Führung, Abreißseil",
            "NormTermName": "Führung",
            "AssGrpName": "Anhänger",
            "UsageName": "Abreißseil"
        },
        "07643": {
            "GenartName": "Abreißseil, Auflaufeinrichtung",
            "NormTermName": "Abreißseil",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufeinrichtung"
        },
        "07644": {
            "GenartName": "Distanzstück, Auflaufeinrichtung",
            "NormTermName": "Distanzstück",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufeinrichtung"
        },
        "07645": {
            "GenartName": "Auflagebock, Deichsel",
            "NormTermName": "Auflagebock",
            "AssGrpName": "Anhänger",
            "UsageName": "Deichsel"
        },
        "07646": {
            "GenartName": "Dämpfer, Auflaufeinrichtung",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufeinrichtung"
        },
        "07647": {
            "GenartName": "Handbremshebel, Anhänger",
            "NormTermName": "Hebel",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07648": {
            "GenartName": "Griff, Handbremshebel (Anhänger)",
            "NormTermName": "Griff",
            "AssGrpName": "Anhänger",
            "UsageName": "Handbremshebel (Anhänger)"
        },
        "07649": {
            "GenartName": "Federspeicher, Auflaufeinrichtung (Anhänger)",
            "NormTermName": "Federspeicher",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufeinrichtung (Anhänger)"
        },
        "07650": {
            "GenartName": "Gasfeder, Handbremshebel",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Anhänger",
            "UsageName": "Handbremshebel"
        },
        "07651": {
            "GenartName": "Faltenbalg, Auflaufeinrichtung",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufeinrichtung"
        },
        "07652": {
            "GenartName": "Zugstange, Auflaufeinrichtung",
            "NormTermName": "Zugstange",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufeinrichtung"
        },
        "07653": {
            "GenartName": "Lagerbuchse, Zugstange",
            "NormTermName": "Buchse",
            "AssGrpName": "Anhänger",
            "UsageName": "Zugstange"
        },
        "07654": {
            "GenartName": "Lagerschraube, Auflaufeinrichtung/Deichsel",
            "NormTermName": "Schraube",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufeinrichtung/Deichsel"
        },
        "07655": {
            "GenartName": "Feststellknebel, Auflaufeinrichtung",
            "NormTermName": "Knebel",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufeinrichtung"
        },
        "07656": {
            "GenartName": "Bowdenzug, Auflaufeinrichtung",
            "NormTermName": "Bowdenzug",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufeinrichtung"
        },
        "07657": {
            "GenartName": "Seilzug, Auflaufbremse",
            "NormTermName": "Seilzug",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufbremse"
        },
        "07658": {
            "GenartName": "Bremsseil, Anhänger",
            "NormTermName": "Seilzug",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07659": {
            "GenartName": "Halter, Bremsseil",
            "NormTermName": "Halter",
            "AssGrpName": "Anhänger",
            "UsageName": "Bremsseil"
        },
        "07660": {
            "GenartName": "Aufnahme, Bremsseil/Bremsgestänge",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Anhänger",
            "UsageName": "Bremsseil/Bremsgestänge"
        },
        "07661": {
            "GenartName": "Einhängeöse, Bremsseil",
            "NormTermName": "Einhängeöse",
            "AssGrpName": "Anhänger",
            "UsageName": "Bremsseil"
        },
        "07662": {
            "GenartName": "Bremsseilanschluss, Anhänger",
            "NormTermName": "Bremsseilanschluss",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07663": {
            "GenartName": "Zugbügel, Bremsseil",
            "NormTermName": "Bügel",
            "AssGrpName": "Anhänger",
            "UsageName": "Bremsseil"
        },
        "07664": {
            "GenartName": "Bremsgestänge, Anhänger",
            "NormTermName": "Bremsgestänge",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07665": {
            "GenartName": "Spannschloss, Bremsgestänge",
            "NormTermName": "Spannschloss",
            "AssGrpName": "Anhänger",
            "UsageName": "Bremsgestänge"
        },
        "07666": {
            "GenartName": "Widerlager, Tandemanhänger",
            "NormTermName": "Widerlager",
            "AssGrpName": "Anhänger",
            "UsageName": "Tandemanhänger"
        },
        "07667": {
            "GenartName": "Systemkanister",
            "NormTermName": "Systemkanister",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07668": {
            "GenartName": "Dispenser, Systemkanister",
            "NormTermName": "Dispenser",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Systemkanister"
        },
        "07669": {
            "GenartName": "Anschlagwinkel, Auflaufeinrichtung",
            "NormTermName": "Anschlagwinkel",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufeinrichtung"
        },
        "07670": {
            "GenartName": "Anschlag, Auflaufeinrichtung",
            "NormTermName": "Anschlag",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufeinrichtung"
        },
        "07671": {
            "GenartName": "Anzugring, Zugstange (Auflaufeinrichtung)",
            "NormTermName": "Anzugring",
            "AssGrpName": "Anhänger",
            "UsageName": "Zugstange (Auflaufeinrichtung)"
        },
        "07672": {
            "GenartName": "Zugstangenreparatursatz",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Anhänger",
            "UsageName": "Zugstange"
        },
        "07673": {
            "GenartName": "Kupplungskugel, Anhängevorrichtung",
            "NormTermName": "Kupplungskugel",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Anhängevorrichtung"
        },
        "07674": {
            "GenartName": "Anschlag-/Rammschutz, Kugelkupplung",
            "NormTermName": "Anschlag-/Rammschutz",
            "AssGrpName": "Anhänger",
            "UsageName": "Kugelkupplung"
        },
        "07675": {
            "GenartName": "Halter, Bremsgestänge",
            "NormTermName": "Halter",
            "AssGrpName": "Anhänger",
            "UsageName": "Bremsgestänge"
        },
        "07676": {
            "GenartName": "Adapter, Bremsgestänge",
            "NormTermName": "Adapter",
            "AssGrpName": "Anhänger",
            "UsageName": "Bremsgestänge"
        },
        "07677": {
            "GenartName": "Mutter, Bremsgestänge (Befestigung)",
            "NormTermName": "Mutter",
            "AssGrpName": "Anhänger",
            "UsageName": "Bremsgestänge (Befestigung)"
        },
        "07678": {
            "GenartName": "Spannmutter, Bremsgestänge",
            "NormTermName": "Mutter",
            "AssGrpName": "Anhänger",
            "UsageName": "Bremsgestänge"
        },
        "07679": {
            "GenartName": "Bremsgestängesatz, Einachsanhänger",
            "NormTermName": "Bremsgestängesatz",
            "AssGrpName": "Anhänger",
            "UsageName": "Einachsanhänger"
        },
        "07680": {
            "GenartName": "Bremsgestängesatz, Tandemanhänger",
            "NormTermName": "Bremsgestängesatz",
            "AssGrpName": "Anhänger",
            "UsageName": "Tandemanhänger"
        },
        "07681": {
            "GenartName": "Steuerstange, Auflaufeinrichtung",
            "NormTermName": "Steuerstange",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufeinrichtung"
        },
        "07682": {
            "GenartName": "Verstellstange, Auflaufeinrichtung",
            "NormTermName": "Verstellstange",
            "AssGrpName": "Anhänger",
            "UsageName": "Auflaufeinrichtung"
        },
        "07683": {
            "GenartName": "Radnabe, Anhänger",
            "NormTermName": "Radnabe",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07684": {
            "GenartName": "Bremstrommel, Anhänger",
            "NormTermName": "Bremstrommel",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07685": {
            "GenartName": "Bremsbackensatz, Anhänger",
            "NormTermName": "Bremsbackensatz",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07686": {
            "GenartName": "Spreizschloss, Trommelbremse (Anhänger)",
            "NormTermName": "Spreizschloss",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07687": {
            "GenartName": "Federsatz, Bremsbacken (Trommelbremse-Anhänger)",
            "NormTermName": "Federsatz",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07688": {
            "GenartName": "Nachsteller-Satz, Trommelbremse (Anhänger)",
            "NormTermName": "Nachsteller-Satz",
            "AssGrpName": "Anhänger",
            "UsageName": "Trommelbremse (Anhänger)"
        },
        "07689": {
            "GenartName": "Rückmatikhebel, Trommelbremse (Anhänger)",
            "NormTermName": "Rückmatikhebel",
            "AssGrpName": "Anhänger",
            "UsageName": "Trommelbremse (Anhänger)"
        },
        "07690": {
            "GenartName": "Blattfeder, Nachsteller-Trommelbremse (Anhänger)",
            "NormTermName": "Blattfeder",
            "AssGrpName": "Anhänger",
            "UsageName": "Nachsteller-Trommelbremse (Anhänger)"
        },
        "07691": {
            "GenartName": "Druckbügel, Nachsteller-Trommelbremse (Anhänger)",
            "NormTermName": "Bügel",
            "AssGrpName": "Anhänger",
            "UsageName": "Nachsteller-Trommelbremse (Anhänger)"
        },
        "07692": {
            "GenartName": "Radlager, Anhänger",
            "NormTermName": "Lager",
            "AssGrpName": "Anhänger",
            "UsageName": "Radnabe"
        },
        "07693": {
            "GenartName": "Schutzkappe, Kugelkopf (Anhängevorrichtung)",
            "NormTermName": "Kappe",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Kugelkopf (Anhängevorrichtung)"
        },
        "07694": {
            "GenartName": "Wellendichtring, Radlager (Anhänger)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Anhänger",
            "UsageName": "Radlager (Anhänger)"
        },
        "07695": {
            "GenartName": "Mutter, Radlager (Anhänger)",
            "NormTermName": "Mutter",
            "AssGrpName": "Anhänger",
            "UsageName": "Radlager (Anhänger)"
        },
        "07696": {
            "GenartName": "Radlagersatz, Anhänger",
            "NormTermName": "Lagersatz",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "07697": {
            "GenartName": "Kappe, Radlager (Anhänger)",
            "NormTermName": "Kappe",
            "AssGrpName": "Anhänger",
            "UsageName": "Radlager (Anhänger)"
        },
        "07698": {
            "GenartName": "Stützlastschild",
            "NormTermName": "Stützlastschild",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "07699": {
            "GenartName": "Zugstangendämpfung",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Anhänger",
            "UsageName": "Zugstange"
        },
        "07700": {
            "GenartName": "Bremsgestänge-Ausgleichsprofil, Einachsanhänger",
            "NormTermName": "Ausgleichsprofil",
            "AssGrpName": "Anhänger",
            "UsageName": "Einachsanhänger"
        },
        "07701": {
            "GenartName": "Bremsgestänge-Ausgleichsprofil, Tandemanhänger",
            "NormTermName": "Ausgleichsprofil",
            "AssGrpName": "Anhänger",
            "UsageName": "Tandemanhänger"
        },
        "07702": {
            "GenartName": "Kabelreparatursatz, Ansauglufttemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Ansauglufttemperatursensor"
        },
        "07703": {
            "GenartName": "Servolenkungsöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Servolenkung"
        },
        "07704": {
            "GenartName": "Kabelreparatursatz, Nockenwellensensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Nockenwellensensor"
        },
        "07705": {
            "GenartName": "Kabelreparatursatz, Kurbelwellenpositionssensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kurbelwellenpositionssensor"
        },
        "07706": {
            "GenartName": "Kabelreparatursatz, Außentemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Instrumente",
            "UsageName": "Außentemperatursensor"
        },
        "07707": {
            "GenartName": "Kabelreparatursatz, Leerlaufregelventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Leerlaufregelventil"
        },
        "07708": {
            "GenartName": "Kabelreparatursatz, Klopfsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Klopfsensor"
        },
        "07709": {
            "GenartName": "Keilriemen",
            "NormTermName": "Riemen",
            "AssGrpName": "Riementrieb, Universal",
            "UsageName": "Keilriemen"
        },
        "07710": {
            "GenartName": "Keilrippenriemen",
            "NormTermName": "Riemen",
            "AssGrpName": "Riementrieb, Universal",
            "UsageName": "Keilrippenriemen"
        },
        "07711": {
            "GenartName": "Keilriemensatz",
            "NormTermName": "Riemensatz",
            "AssGrpName": "Riementrieb, Universal",
            "UsageName": "Keilriemen"
        },
        "07712": {
            "GenartName": "Keilrippenriemensatz",
            "NormTermName": "Riemensatz",
            "AssGrpName": "Riementrieb, Universal",
            "UsageName": "Keilrippenriemen"
        },
        "07713": {
            "GenartName": "Gleitschienenhalter, Ladebordwand",
            "NormTermName": "Gleitschienenhalter",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Ladebordwand"
        },
        "07715": {
            "GenartName": "Schlauchklemmenzangen-Set",
            "NormTermName": "Zangen-Set",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlauchklemmenzangen-Set"
        },
        "07716": {
            "GenartName": "Abschlagdorn, Nockenwellenzahnrad",
            "NormTermName": "Abschlagdorn",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Nockenwellenzahnrad"
        },
        "07717": {
            "GenartName": "Abzieher, Zündkerzenstecker",
            "NormTermName": "Abzieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zündkerzenstecker"
        },
        "07718": {
            "GenartName": "Abzieher, Zündspulenstecker",
            "NormTermName": "Abzieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zündspulenstecker"
        },
        "07719": {
            "GenartName": "Abziehersatz, Zündspulenstecker",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zündspulenstecker"
        },
        "07720": {
            "GenartName": "Abziehhaken, Frontgrill",
            "NormTermName": "Abziehhaken",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Frontgrill"
        },
        "07721": {
            "GenartName": "Airbrushpistole",
            "NormTermName": "Airbrushpistole",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07722": {
            "GenartName": "Ladegerät (Universal)",
            "NormTermName": "Ladegerät",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "07723": {
            "GenartName": "Ölverteiler, Lagerleiter (Kurbelwelle)",
            "NormTermName": "Ölverteiler",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Lagerleiter (Kurbelwelle)"
        },
        "07724": {
            "GenartName": "Arretierschraube, Zahnstangen-Mittelstellung (Lenkung)",
            "NormTermName": "Arretierschraube",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zahnstangen-Mittelstellung"
        },
        "07725": {
            "GenartName": "Aufsatz, Heißluftföhn",
            "NormTermName": "Aufsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Heißluftföhn"
        },
        "07726": {
            "GenartName": "Aufsteckratsche",
            "NormTermName": "Aufsteckratsche",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07727": {
            "GenartName": "Ausziehersatz, Einspritzdüse",
            "NormTermName": "Ausziehersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzdüse"
        },
        "07728": {
            "GenartName": "Steinbohrersatz",
            "NormTermName": "Bohrersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steinbohrersatz"
        },
        "07729": {
            "GenartName": "Bördelgerät, Radlauf",
            "NormTermName": "Bördelgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radlauf"
        },
        "07730": {
            "GenartName": "Brecheisen",
            "NormTermName": "Brecheisen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07731": {
            "GenartName": "Ventilsitzring",
            "NormTermName": "Sitzring",
            "AssGrpName": "Zylinderkopf, Universal",
            "UsageName": "Ventil"
        },
        "07732": {
            "GenartName": "Steckschlüsseleinsatz, Bremssattel",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremssattel"
        },
        "07733": {
            "GenartName": "Ventilkeil",
            "NormTermName": "Ventilkeil",
            "AssGrpName": "Motorsteuerung, Universal",
            "UsageName": "Ventil"
        },
        "07734": {
            "GenartName": "Einstellscheibe, Ventilspiel",
            "NormTermName": "Einstellscheibe",
            "AssGrpName": "Motorsteuerung, Universal",
            "UsageName": "Ventilspiel"
        },
        "07735": {
            "GenartName": "Prüfgerät, Common-Rail-Hochdruckpumpe",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Common-Rail-Hochdruckpumpe"
        },
        "07736": {
            "GenartName": "Ventilführung",
            "NormTermName": "Führung",
            "AssGrpName": "Zylinderkopf, Universal",
            "UsageName": "Ventilführung"
        },
        "07737": {
            "GenartName": "Diagrammblätter, Kompressionsdruckschreiber",
            "NormTermName": "Diagrammblätter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kompressionsdruckschreiber"
        },
        "07738": {
            "GenartName": "Füllgerät, Dieselkraftstofffilter",
            "NormTermName": "Füllgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Dieselkraftstofffilter"
        },
        "07739": {
            "GenartName": "Dokumentenhalter, Werkzeugwagen",
            "NormTermName": "Dokumentenhalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "07741": {
            "GenartName": "Druckstücksatz, Montagewerkzeug Silentlager",
            "NormTermName": "Druckstücksatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Silentlager"
        },
        "07742": {
            "GenartName": "Dichtring, Ventilschaft",
            "NormTermName": "Dichtring",
            "AssGrpName": "Zylinderkopf, Universal",
            "UsageName": "Ventilschaft"
        },
        "07743": {
            "GenartName": "Trenn-/Montagegabelsatz",
            "NormTermName": "Trenn-/Montagegabelsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07744": {
            "GenartName": "Gasfeder, Aufstelldach",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Aufstelldach"
        },
        "07745": {
            "GenartName": "Gasfeder, Bett (Aufstelldach)",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Bett (Aufstelldach)"
        },
        "07746": {
            "GenartName": "Umsteckschraubendreher",
            "NormTermName": "Umsteckschraubendreher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07747": {
            "GenartName": "Druckluftkamm",
            "NormTermName": "Druckluftkamm",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07748": {
            "GenartName": "Düsensatz, Lackierpistole",
            "NormTermName": "Düsensatz",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Lackierpistole"
        },
        "07749": {
            "GenartName": "Eindrehwerkzeug, Gewindeeinsatz",
            "NormTermName": "Eindrehwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gewindeeinsatz"
        },
        "07750": {
            "GenartName": "Einsteck-Schlüsselsatz, Drehmomentschlüssel",
            "NormTermName": "Schlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "07751": {
            "GenartName": "Aufkleber, Rücksendung",
            "NormTermName": "Aufkleber",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Rücksendung"
        },
        "07752": {
            "GenartName": "Einstellgerät, Sturzeinstellung",
            "NormTermName": "Einstellgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Sturzeinstellung"
        },
        "07753": {
            "GenartName": "Hammer-Satz",
            "NormTermName": "Hammer-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07754": {
            "GenartName": "Kartuschenspitzenzange",
            "NormTermName": "Kartuschenspitzenzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07755": {
            "GenartName": "Klauenschlüssel",
            "NormTermName": "Klauenschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07756": {
            "GenartName": "Magnet, Steckschlüsseleinsatz",
            "NormTermName": "Magnet",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steckschlüsseleinsatz"
        },
        "07757": {
            "GenartName": "Rohrsteckschlüssel-Satz",
            "NormTermName": "Rohrsteckschlüssel-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07758": {
            "GenartName": "Verlängerung, Ring-/Gabelschlüssel",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ring-/Gabelschlüssel"
        },
        "07759": {
            "GenartName": "Zündkerzenlehre",
            "NormTermName": "Zündkerzenlehre",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07760": {
            "GenartName": "Multifunktionsschlüssel",
            "NormTermName": "Multifunktionsschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07761": {
            "GenartName": "Gegenhalteschlüssel, Lüfter (Flüssigkeitsreibungskupplung)",
            "NormTermName": "Gegenhalteschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Lüfter (Flüssigkeitsreibungskupplung)"
        },
        "07762": {
            "GenartName": "Gegenhalteschlüssel, Zahnriemenrad",
            "NormTermName": "Gegenhalteschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zahnriemenrad"
        },
        "07763": {
            "GenartName": "Gewebeschere",
            "NormTermName": "Gewebeschere",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07764": {
            "GenartName": "Kabeltrommel, Werkzeugwagen",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagenanbau"
        },
        "07765": {
            "GenartName": "Bodenrollensatz, Ladebordwand",
            "NormTermName": "Rollensatz",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Ladebordwand"
        },
        "07766": {
            "GenartName": "Buchse",
            "NormTermName": "Buchse",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "07767": {
            "GenartName": "Ring, Bodenrolle (Ladebordwand)",
            "NormTermName": "Ring",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Bodenrolle (Ladebordwand)"
        },
        "07768": {
            "GenartName": "Plattformspitze, Ladebordwand",
            "NormTermName": "Plattformspitze",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Ladebordwand"
        },
        "07769": {
            "GenartName": "Deckel, Bedieneinheit (Ladebordwand)",
            "NormTermName": "Deckel",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Bedieneinheit (Ladebordwand)"
        },
        "07770": {
            "GenartName": "Hydraulikzylinder, Ladebordwand",
            "NormTermName": "Hydraulikzylinder",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Ladebordwand"
        },
        "07771": {
            "GenartName": "Faltenbalg, Hydraulikzylinder (Ladebordwand)",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Karosserie",
            "UsageName": "Hydraulikzylinder (Ladebordwand)"
        },
        "07772": {
            "GenartName": "Lagerbolzen, Bodenrolle (Ladebordwand)",
            "NormTermName": "Bolzen",
            "AssGrpName": "Karosserie",
            "UsageName": "Bodenrolle (Ladebordwand)"
        },
        "07773": {
            "GenartName": "Magnetventil, Ladebordwand",
            "NormTermName": "Ventil",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Ladebordwand"
        },
        "07774": {
            "GenartName": "Halterung, Fernbedienung, Ladebordwand",
            "NormTermName": "Halterung",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Fernbedienung, Ladebordwand"
        },
        "07775": {
            "GenartName": "Hydraulikaggregat, Ladebordwand",
            "NormTermName": "Hydraulikaggregat",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Ladebordwand"
        },
        "07776": {
            "GenartName": "Pumpe, Hydraulikaggregat (Ladebordwand)",
            "NormTermName": "Pumpe",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Hydraulikaggregat (Ladebordwand)"
        },
        "07777": {
            "GenartName": "Ansaugschlauch, Aggregatepumpe (Ladebordwand)",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Aggregatepumpe (Ladebordwand)"
        },
        "07778": {
            "GenartName": "Abrollsicherung, Ladebordwand",
            "NormTermName": "Abrollsicherung",
            "AssGrpName": "Karosserie",
            "UsageName": "Ladebordwand"
        },
        "07779": {
            "GenartName": "Deckel, Hydraulikaggregat (Ladebordwand)",
            "NormTermName": "Deckel",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Hydraulikaggregat (Ladebordwand)"
        },
        "07780": {
            "GenartName": "Verschluss, Deckel (Hydraulikaggregat)",
            "NormTermName": "Verschluss",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Deckel (Hydraulikaggregat)"
        },
        "07781": {
            "GenartName": "Halterung, Anhängersteckdose",
            "NormTermName": "Halterung",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Anhängersteckdose"
        },
        "07782": {
            "GenartName": "Verschluss-Set, Ladebordwand",
            "NormTermName": "Verschluss-Set",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Ladebordwand"
        },
        "07783": {
            "GenartName": "Gewindebohrer, Zündkerze",
            "NormTermName": "Gewindebohrer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zündkerze"
        },
        "07784": {
            "GenartName": "Halter, Lackierpistole",
            "NormTermName": "Halter",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Lackierpistole"
        },
        "07785": {
            "GenartName": "Füllschlauch, Harnstofflösung",
            "NormTermName": "Füllschlauch",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Harnstofflösung"
        },
        "07786": {
            "GenartName": "Filterschlüssel, Harnstoff",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Harnstoff"
        },
        "07787": {
            "GenartName": "Lösehebel, Heckklappenverkleidung",
            "NormTermName": "Lösehebel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Heckklappenverkleidung"
        },
        "07788": {
            "GenartName": "Kehlnahtbürste",
            "NormTermName": "Kehlnahtbürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07789": {
            "GenartName": "Klebschlaghammer",
            "NormTermName": "Klebschlaghammer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07790": {
            "GenartName": "Klebschlaghammer-Satz",
            "NormTermName": "Klebschlaghammer-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07791": {
            "GenartName": "Kotflügel-Auszieher",
            "NormTermName": "Kotflügel-Auszieher",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07792": {
            "GenartName": "Kupplungs-/Polradhalter",
            "NormTermName": "Kupplungs-/Polradhalter",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07793": {
            "GenartName": "Kupferhammer",
            "NormTermName": "Kupferhammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07794": {
            "GenartName": "Druckluft-Mini-Öler",
            "NormTermName": "Druckluft-Mini-Öler",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07795": {
            "GenartName": "Abzieher, Batteriepolklemme",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Batteriepolklemme"
        },
        "07796": {
            "GenartName": "Prüf-Zündkerze",
            "NormTermName": "Prüf-Zündkerze",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07797": {
            "GenartName": "Reibahle, Lambdasondenaufnahme",
            "NormTermName": "Reibahle",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lambdasondenaufnahme"
        },
        "07798": {
            "GenartName": "Reibahlensatz, Glühkerze",
            "NormTermName": "Reibahlensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glühkerze"
        },
        "07799": {
            "GenartName": "Schneiddraht-Zuggriff",
            "NormTermName": "Schneiddraht-Zuggriff",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07800": {
            "GenartName": "Schneiddraht-Gegenhalter",
            "NormTermName": "Schneiddraht-Gegenhalter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07801": {
            "GenartName": "Schlagauszieher Radnabe",
            "NormTermName": "Schlagauszieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radnabe"
        },
        "07802": {
            "GenartName": "Radnabenschleifer",
            "NormTermName": "Radnabenschleifer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07803": {
            "GenartName": "Lösewerkzeug, Regen-/Lichtsensor",
            "NormTermName": "Lösewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Regen-/Lichtsensor"
        },
        "07804": {
            "GenartName": "Reinigungsbürstensatz, Lackierpistole",
            "NormTermName": "Bürstensatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Lackierpistole"
        },
        "07805": {
            "GenartName": "Prüfgerät, Relais",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Relais"
        },
        "07806": {
            "GenartName": "Prüfgerät, Relaisbuchse",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Relaisbuchse"
        },
        "07807": {
            "GenartName": "Zange, Relais",
            "NormTermName": "Zange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Relais"
        },
        "07808": {
            "GenartName": "Doppel-Ringschlüssel, Einspritzleitung",
            "NormTermName": "Doppel-Ringschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzleitung"
        },
        "07809": {
            "GenartName": "Wasserschieber",
            "NormTermName": "Wasserschieber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07810": {
            "GenartName": "Einschweißgewinde, NOx Sensor",
            "NormTermName": "Einschweißgewinde",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "NOx Sensor"
        },
        "07811": {
            "GenartName": "Einschweißgewinde, Lambdasonde",
            "NormTermName": "Einschweißgewinde",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Abgasrohr"
        },
        "07812": {
            "GenartName": "Zange, Innenspiegel",
            "NormTermName": "Zange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Innenspiegel"
        },
        "07813": {
            "GenartName": "Prüfgerät, Zigarettenanzünder",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zigarettenanzünder"
        },
        "07814": {
            "GenartName": "Zweilochschlüssel, Winkelschleifer",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "07815": {
            "GenartName": "Kompressionsdruckschreiber",
            "NormTermName": "Kompressionsdruckschreiber",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07816": {
            "GenartName": "Ventil, Wasserpumpe",
            "NormTermName": "Ventil",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "07817": {
            "GenartName": "Motorhauben- /Heckklappenstütze",
            "NormTermName": "Motorhauben- /Heckklappenstütze",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07818": {
            "GenartName": "Einsatz, Steckachse (Motorrad)",
            "NormTermName": "Einsatz",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Steckachse (Motorrad)"
        },
        "07819": {
            "GenartName": "Lösezange, Nietenkopf (Nietenknacker)",
            "NormTermName": "Lösezange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Spreizniet"
        },
        "07820": {
            "GenartName": "Fangseil, Federspanner",
            "NormTermName": "Fangseil",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federspanner"
        },
        "07821": {
            "GenartName": "Werkzeugsatz, Schwingenlager",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Schwingenlager"
        },
        "07823": {
            "GenartName": "Maschinengewindebohrer",
            "NormTermName": "Maschinengewindebohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07824": {
            "GenartName": "Ölfilterkette",
            "NormTermName": "Ölfilterkette",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07825": {
            "GenartName": "Ölfilterspinne",
            "NormTermName": "Ölfilterspinne",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07826": {
            "GenartName": "Ölpeilstab-Satz",
            "NormTermName": "Ölpeilstab-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07827": {
            "GenartName": "Polradabzieher",
            "NormTermName": "Polradabzieher",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07828": {
            "GenartName": "Polradabzieher-Satz",
            "NormTermName": "Polradabzieher-Satz",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "07829": {
            "GenartName": "Scheibenaustrenngerät",
            "NormTermName": "Scheibenaustrenngerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07830": {
            "GenartName": "Schneiddraht-Durchstoßklinge",
            "NormTermName": "Schneiddraht-Durchstoßklinge",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07831": {
            "GenartName": "Schnellspannmutter, Winkelschleifer",
            "NormTermName": "Mutter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "07832": {
            "GenartName": "Kreissschneider, Stichsäge",
            "NormTermName": "Kreissschneider",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stichsäge"
        },
        "07833": {
            "GenartName": "Winkeltisch, Stichsäge",
            "NormTermName": "Winkeltisch",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stichsäge"
        },
        "07834": {
            "GenartName": "Laufsohle, Stichsäge",
            "NormTermName": "Laufsohle",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stichsäge"
        },
        "07836": {
            "GenartName": "Sägeblatt, Multifunktionswerkzeug (Oszillierer)",
            "NormTermName": "Sägeblatt",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Multifunktionswerkzeug (Oszillierer)"
        },
        "07837": {
            "GenartName": "Sägeblattsatz, Multifunktionswerkzeug (Oszillierer)",
            "NormTermName": "Sägeblattsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Multifunktionswerkzeug (Oszillierer)"
        },
        "07838": {
            "GenartName": "Sensor, Leuchtweitenregulierung",
            "NormTermName": "Sensor",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Leuchtweitenregulierung"
        },
        "07839": {
            "GenartName": "Sägeblatt, Säbelsäge",
            "NormTermName": "Sägeblatt",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Säbelsäge"
        },
        "07840": {
            "GenartName": "Sägeblattsatz, Säbelsäge",
            "NormTermName": "Sägeblattsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Säbelsäge"
        },
        "07841": {
            "GenartName": "Schonbacken, Armaturenzange",
            "NormTermName": "Schonbacken",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Armaturenzange"
        },
        "07842": {
            "GenartName": "Aufnahmeflansch, Winkelschleifer",
            "NormTermName": "Flansch",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "07843": {
            "GenartName": "Griff, Winkelschleifer",
            "NormTermName": "Griff",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "07844": {
            "GenartName": "Spaten",
            "NormTermName": "Spaten",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "07845": {
            "GenartName": "Tiefenmessschieber",
            "NormTermName": "Tiefenmessschieber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07846": {
            "GenartName": "Teppichschere",
            "NormTermName": "Teppichschere",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07847": {
            "GenartName": "Stoßpresse",
            "NormTermName": "Stoßpresse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07848": {
            "GenartName": "Steckschlüsselsatz, Lambdasonde",
            "NormTermName": "Steckschlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Lambdasonde"
        },
        "07849": {
            "GenartName": "Spülflüssigkeit, Zylinderkopf-Dichtigkeit",
            "NormTermName": "Spülflüssigkeit",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zylinderkopf-Dichtigkeit"
        },
        "07850": {
            "GenartName": "Bremshebel, Fußrastenanlage",
            "NormTermName": "Hebel",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Fußrastenanlage (Zweirad)"
        },
        "07851": {
            "GenartName": "Schalthebel, Fußrastenanlage",
            "NormTermName": "Hebel",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Fußrastenanlage (Zweirad)"
        },
        "07853": {
            "GenartName": "Hydraulikzylindersatz, Abzieher-Spindel",
            "NormTermName": "Hydraulikzylindersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abzieher-Spindel"
        },
        "07854": {
            "GenartName": "Sortiment, Abzweigverbinder",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Abzweigverbinder"
        },
        "07855": {
            "GenartName": "Sortiment, Sicherungsring",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Sicherungsring"
        },
        "07856": {
            "GenartName": "Sortiment, Schmiernippel",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Schmiernippel"
        },
        "07857": {
            "GenartName": "Sortiment, Splinte",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Splint"
        },
        "07858": {
            "GenartName": "Sortiment, Spannstift",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Spannstift"
        },
        "07859": {
            "GenartName": "Sortiment, Sicherungsbolzen",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Sicherungsbolzen"
        },
        "07860": {
            "GenartName": "Sortiment, Sicherungselement",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Sicherungselement"
        },
        "07861": {
            "GenartName": "Steuergerät, Anhängevorrichtung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Anhängevorrichtung"
        },
        "07862": {
            "GenartName": "Ölabstreifring, Zylinderlaufbuchse",
            "NormTermName": "Ölabstreifring",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Zylinderlaufbuchse"
        },
        "07863": {
            "GenartName": "Radio",
            "NormTermName": "Radio",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07864": {
            "GenartName": "Bit-Adapter, Ratschen-Ringschlüssel",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschen-Ringschlüssel"
        },
        "07865": {
            "GenartName": "Doppel-Schleifmaschine",
            "NormTermName": "Doppel-Schleifmaschine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07866": {
            "GenartName": "Druckluft-Manometer",
            "NormTermName": "Manometer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drucklufttechnik"
        },
        "07867": {
            "GenartName": "Montagewerkzeugsatz, Direktschaltgetriebe",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Direktschaltgetriebe"
        },
        "07868": {
            "GenartName": "Armaturen-Schutztafelsatz, Scheibendemontage",
            "NormTermName": "Schutztafelsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Scheibendemontage"
        },
        "07870": {
            "GenartName": "Steckschlüsselsatz, Zylinderkopfschraube",
            "NormTermName": "Steckschlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zylinderkopfschraube"
        },
        "07871": {
            "GenartName": "Adapter-Satz, Prüfgerät-Kraftstoffsystemdruck",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Prüfgerät, Kraftstoffsystemdruck"
        },
        "07872": {
            "GenartName": "Adaptersatz, Motorprüfgerät",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Motorprüfgerät"
        },
        "07873": {
            "GenartName": "Anschweißteile, Karosserie-Spotter",
            "NormTermName": "Anschweißteile",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserie-Spotter"
        },
        "07874": {
            "GenartName": "Elektronik-Detektor",
            "NormTermName": "Elektronik-Detektor",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07875": {
            "GenartName": "Fluchtungsmessgerät, Riementrieb",
            "NormTermName": "Fluchtungsmessgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Riementrieb"
        },
        "07876": {
            "GenartName": "Steckschlüsselsatz, Antriebswelle",
            "NormTermName": "Steckschlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Antriebswelle"
        },
        "07877": {
            "GenartName": "Leuchte, Montagegrube",
            "NormTermName": "Leuchte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Montagegrube"
        },
        "07878": {
            "GenartName": "Haken, Lochtafel (Werkzeugwagen)",
            "NormTermName": "Haken",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lochtafel (Werkzeugwagen)"
        },
        "07879": {
            "GenartName": "Karosserie-Spotter-Satz",
            "NormTermName": "Karosserie-Spotter-Satz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07880": {
            "GenartName": "Schlauchpresse",
            "NormTermName": "Schlauchpresse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07881": {
            "GenartName": "Ratschenschlüssel, Klimaanlage",
            "NormTermName": "Ratschenschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Klimaanlage"
        },
        "07882": {
            "GenartName": "Werkzeugsatz, Klimaanlage",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Klimaanlage"
        },
        "07883": {
            "GenartName": "Spezial-Steckschlüsseleinsatz, ABS-Rotor",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "ABS-Rotor"
        },
        "07884": {
            "GenartName": "Lösewerkzeug, Schlauchschelle",
            "NormTermName": "Lösewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlauchschelle"
        },
        "07885": {
            "GenartName": "Hitzeschutzblech",
            "NormTermName": "Hitzeschutzblech",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Hitzeschutz"
        },
        "07886": {
            "GenartName": "Kernbohrer",
            "NormTermName": "Kernbohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07887": {
            "GenartName": "Endrohrblende",
            "NormTermName": "Blende",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Endrohr"
        },
        "07888": {
            "GenartName": "Kernbohrer-Satz",
            "NormTermName": "Kernbohrer-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07889": {
            "GenartName": "Halter, Abgasanlage",
            "NormTermName": "Halter",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": null
        },
        "07890": {
            "GenartName": "Reparatursatz, Reifen",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reifen"
        },
        "07891": {
            "GenartName": "Eindrückersatz, Wellendichtring",
            "NormTermName": "Eindrückersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Wellendichtring"
        },
        "07893": {
            "GenartName": "Magnetständerbohrmaschine",
            "NormTermName": "Magnetständerbohrmaschine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07894": {
            "GenartName": "Schlagschlüssel, Bremssattelverschraubung",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremssattelverschraubung"
        },
        "07895": {
            "GenartName": "Werkzeugkasten, Werkzeugwagen",
            "NormTermName": "Werkzeugkasten",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "07896": {
            "GenartName": "Teleskop-Messlehre",
            "NormTermName": "Messlehre",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Karosserie-Spaltmaß"
        },
        "07897": {
            "GenartName": "Demontagewerkzeug, Türgriff",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Türgriff"
        },
        "07898": {
            "GenartName": "Montage-/Demontagewerkzeug, Türscharnier",
            "NormTermName": "Montage-/Demontagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Türscharnier"
        },
        "07899": {
            "GenartName": "Zapfenschlüssel, Kugellager-Nutmutter",
            "NormTermName": "Zapfenschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kugellager-Nutmutter"
        },
        "07900": {
            "GenartName": "Zapfenschlüsselsatz, Kugellager-Nutmutter",
            "NormTermName": "Zapfenschlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kugellager-Nutmutter"
        },
        "07901": {
            "GenartName": "Steckschlüssel, Radkapsel (NFZ-Achse)",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radkapsel (NFZ-Achse)"
        },
        "07902": {
            "GenartName": "Schlagschlüssel, Radkapsel (NFZ-Achse)",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radkapsel (NFZ-Achse)"
        },
        "07903": {
            "GenartName": "Lösewerkzeugsatz, Einspritzdüse (Expansionsventil)",
            "NormTermName": "Lösewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzdüse (Expansionsventil)"
        },
        "07904": {
            "GenartName": "Montagevorrichtung, Steuerkette",
            "NormTermName": "Montagevorrichtung",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Steuerkette"
        },
        "07905": {
            "GenartName": "Werkzeugsatz, Steuerkette",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Steuerkette"
        },
        "07906": {
            "GenartName": "Sortiment, Kraftstoffleitungs-Reparatur",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Kraftstoffleitung"
        },
        "07907": {
            "GenartName": "Steckschlüsseleinsatz, Stahlventilmontage (Ventil-Eindreher)",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Stahlventilmontage (Stahlventil-Eindreher)"
        },
        "07908": {
            "GenartName": "Steckschlüsseleinsatz, Achsschenkel (Aufspreizer)",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Achsschenkel"
        },
        "07909": {
            "GenartName": "Gummi-Richtwerkzeug",
            "NormTermName": "Gummi-Richtwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07910": {
            "GenartName": "Montagehilfe, Zündkerze",
            "NormTermName": "Montagehilfe",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zündkerze"
        },
        "07911": {
            "GenartName": "Montagehülse, Zündschloss",
            "NormTermName": "Montagehülse",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zündschloss"
        },
        "07912": {
            "GenartName": "Prüfgerät, Funkschlüssel",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Funkschlüssel"
        },
        "07913": {
            "GenartName": "Schraubendreher, Außenspiegel",
            "NormTermName": "Schraubendreher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Außenspiegel"
        },
        "07914": {
            "GenartName": "Schraubendreher, Lenkstockverkleidung",
            "NormTermName": "Schraubendreher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Lenkstockverkleidung"
        },
        "07915": {
            "GenartName": "Schlagauszieher, Trocknerpatrone (Klimaanlage)",
            "NormTermName": "Schlagauszieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Trocknerpatrone (Klimaanlage)"
        },
        "07916": {
            "GenartName": "Schlagwerkzeugsatz",
            "NormTermName": "Schlagwerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07917": {
            "GenartName": "Schruppscheibensatz, Winkelschleifer",
            "NormTermName": "Schruppscheibensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "07919": {
            "GenartName": "Pressrahmen, universal",
            "NormTermName": "Pressrahmen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07920": {
            "GenartName": "Nadelsatz, Heckschloss-Demontage",
            "NormTermName": "Nadelsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Heckschloss-Demontage"
        },
        "07921": {
            "GenartName": "Schlüsselsatz, Zahnriemenspannung",
            "NormTermName": "Schlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zahnriemenspannung"
        },
        "07922": {
            "GenartName": "Steckschlüsseleinsatz, Einspritzleitung",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzleitung"
        },
        "07923": {
            "GenartName": "Spann-Keil-Satz, Abgasanlage",
            "NormTermName": "Spann-Keil-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Abgasanlage"
        },
        "07924": {
            "GenartName": "Steckschlüsseleinsatz, Rückfahrschalter",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Rückfahrschalter"
        },
        "07925": {
            "GenartName": "Verstellzange, Spurstange",
            "NormTermName": "Zange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Spurstange"
        },
        "07926": {
            "GenartName": "Spurstangen-Axialgelenkschlüssel",
            "NormTermName": "Spurstangen-Axialgelenkschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07927": {
            "GenartName": "Montagewerkzeugsatz, Federbein",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federbein"
        },
        "07928": {
            "GenartName": "Führungsrohr, Zapfenschlüssel",
            "NormTermName": "Führungsrohr",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zapfenschlüssel"
        },
        "07929": {
            "GenartName": "Universal-Achshebel",
            "NormTermName": "Universal-Achshebel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "07930": {
            "GenartName": "Ultraschall-Lecksuchgerät",
            "NormTermName": "Ultraschall-Lecksuchgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "07931": {
            "GenartName": "Diagnosesatz, Tank-Ersatz",
            "NormTermName": "Diagnosesatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Tank-Ersatz"
        },
        "07932": {
            "GenartName": "Ölfilterschlüssel, Direktschaltgetriebe",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Direktschaltgetriebe"
        },
        "07933": {
            "GenartName": "Adaptersatz, Ratschen-Ringschlüssel",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschen-Ringschlüssel"
        },
        "07934": {
            "GenartName": "Elektrowerkzeug-Set",
            "NormTermName": "Elektrowerkzeug-Set",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07935": {
            "GenartName": "Steckschlüssel, Kontrollschalter-Differentialsperre",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kontrollschalter-Differentialsperre"
        },
        "07936": {
            "GenartName": "Werkzeugsatz, Spring Lock",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Spring Lock"
        },
        "07937": {
            "GenartName": "Steckschlüsseleinsatz, Federbolzen",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federbolzen"
        },
        "07938": {
            "GenartName": "Adapter, Ratschen-Ringschlüssel",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steckschlüssel"
        },
        "07939": {
            "GenartName": "Spann- und Einstellvorrichtung, Federbein",
            "NormTermName": "Spann- und Einstellvorrichtung",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federbein"
        },
        "07940": {
            "GenartName": "Spannschraube, Bördelgerät",
            "NormTermName": "Spannschraube",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bördelgerät"
        },
        "07941": {
            "GenartName": "Spindelschraube, Bördelgerät",
            "NormTermName": "Spindelschraube",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bördelgerät"
        },
        "07942": {
            "GenartName": "Reparatursatz, Werkstattspiegel",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Werkstattspiegel"
        },
        "07943": {
            "GenartName": "Werkstattspiegelsatz",
            "NormTermName": "Werkstattspiegelsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07944": {
            "GenartName": "Regulator, Schlagschrauber",
            "NormTermName": "Regulator",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07945": {
            "GenartName": "Haltering, Schlagwerkgehäuse (Schlagschrauber)",
            "NormTermName": "Haltering",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagwerkgehäuse (Schlagschrauber)"
        },
        "07946": {
            "GenartName": "Griff, Schlagschrauber",
            "NormTermName": "Griff",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07947": {
            "GenartName": "Halteblech, Schlagwerkgehäuse (Schlagschrauber)",
            "NormTermName": "Halteblech",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagwerkgehäuse (Schlagschrauber)"
        },
        "07948": {
            "GenartName": "Lagerplatte, Schlagschrauber",
            "NormTermName": "Lagerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "07949": {
            "GenartName": "Oszillations-Einheit, Scheibenaustrenngerät",
            "NormTermName": "Oszillations-Einheit",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Scheibenaustrenngerät"
        },
        "07950": {
            "GenartName": "Bürsten-/Düsen-Set, Saug-/Blaspistole",
            "NormTermName": "Bürsten-/Düsen-Set",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Saug-/Blaspistole"
        },
        "07951": {
            "GenartName": "Schlauchsatz, Saug-/Blaspistole",
            "NormTermName": "Schlauchleitungssatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Saug-/Blaspistole"
        },
        "07952": {
            "GenartName": "Deflektor, Saug-/Blaspistole",
            "NormTermName": "Deflektor",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Saug-/Blaspistole"
        },
        "07953": {
            "GenartName": "Steckschlüsseleinsatz, Ratschen-Ringschlüssel",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ratschen-Ringschlüssel"
        },
        "07954": {
            "GenartName": "Werkzeugsatz Anhängekupplungsmontage",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Anhängekupplungsmontage"
        },
        "07955": {
            "GenartName": "Lehre, Schneidenwinkel",
            "NormTermName": "Lehre",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schneidenwinkel"
        },
        "07956": {
            "GenartName": "Spaltkeil",
            "NormTermName": "Spaltkeil",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07957": {
            "GenartName": "Keiltreibhammer",
            "NormTermName": "Keiltreibhammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07958": {
            "GenartName": "Zug-/Wagnermesser",
            "NormTermName": "Zug-/Wagnermesser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07959": {
            "GenartName": "Einstiel-Satz",
            "NormTermName": "Einstiel-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07960": {
            "GenartName": "Sichel",
            "NormTermName": "Sichel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07961": {
            "GenartName": "Gertel",
            "NormTermName": "Gertel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07962": {
            "GenartName": "Sappie",
            "NormTermName": "Sappie",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07963": {
            "GenartName": "Fällheber",
            "NormTermName": "Fällheber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Holzrückwerkzeug"
        },
        "07964": {
            "GenartName": "Packzange",
            "NormTermName": "Packzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Holzrückwerkzeug"
        },
        "07965": {
            "GenartName": "Wendehaken",
            "NormTermName": "Wendehaken",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Holzrückwerkzeug"
        },
        "07966": {
            "GenartName": "Ziehhaken",
            "NormTermName": "Ziehhaken",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Holzrückwerkzeug"
        },
        "07967": {
            "GenartName": "Packhaken",
            "NormTermName": "Packhaken",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Holzrückwerkzeug"
        },
        "07968": {
            "GenartName": "Rindenschäler",
            "NormTermName": "Rindenschäler",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07969": {
            "GenartName": "Exzenter-Winkelbiegegerät",
            "NormTermName": "Exzenter-Winkelbiegegerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07970": {
            "GenartName": "Biegeform, Exzenter-Winkelbiegegerät",
            "NormTermName": "Biegeform",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Exzenter-Winkelbiegegerät"
        },
        "07971": {
            "GenartName": "Plattenhammer",
            "NormTermName": "Plattenhammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07972": {
            "GenartName": "Glaserhammer",
            "NormTermName": "Glaserhammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07973": {
            "GenartName": "Kesselsteinhammer",
            "NormTermName": "Kesselsteinhammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07974": {
            "GenartName": "Bleihammer",
            "NormTermName": "Bleihammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07975": {
            "GenartName": "Kaltschrotmeißel",
            "NormTermName": "Kaltschrotmeißel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07976": {
            "GenartName": "Warmschrotmeißel",
            "NormTermName": "Warmschrotmeißel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07977": {
            "GenartName": "Durchtreiber-Hammer",
            "NormTermName": "Durchtreiber-Hammer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07978": {
            "GenartName": "Nietzieher/Nietkopfsetzer-Satz",
            "NormTermName": "Nietzieher/Nietkopfsetzer-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "07979": {
            "GenartName": "Gelenkstücksatz, Steckschlüssel",
            "NormTermName": "Gelenkstücksatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steckschlüssel"
        },
        "07980": {
            "GenartName": "Spannkörper, Stabschleifer",
            "NormTermName": "Spannkörper",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stabschleifer"
        },
        "07981": {
            "GenartName": "Ölfilterschlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ölfilter"
        },
        "07982": {
            "GenartName": "Öl-/Kraftstofffilterschlüssel",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Öl-/Kraftstofffilterschlüssel"
        },
        "07983": {
            "GenartName": "Montagehülsensatz, Bremsscheibe/-trommel",
            "NormTermName": "Montagehülsensatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremsscheibe/-trommel"
        },
        "07984": {
            "GenartName": "Montagehülse, Bremsscheibe/-trommel",
            "NormTermName": "Montagehülse",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremsscheibe/-trommel"
        },
        "07985": {
            "GenartName": "Adapterring, Radnabe",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radnabe"
        },
        "07986": {
            "GenartName": "Federspannersatz, Fahrwerksfeder",
            "NormTermName": "Federspannersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Fahrwerksfeder"
        },
        "07987": {
            "GenartName": "Schutzeinlage, Druckplatte-Federspanner",
            "NormTermName": "Schutzeinlage",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federspanner"
        },
        "07988": {
            "GenartName": "Schutzeinlage, Druckplatte-Federspanner",
            "NormTermName": "Schutzeinlage",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federspanner"
        },
        "07989": {
            "GenartName": "Karbonabstreifring, Zylinderlaufbuchse",
            "NormTermName": "Karbonabstreifring",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Zylinderlaufbuchse"
        },
        "07990": {
            "GenartName": "Steuergerät, Lenkstockschalter",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Instrumente",
            "UsageName": "Lenkstockschalter"
        },
        "07991": {
            "GenartName": "Adapter, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "07992": {
            "GenartName": "Reparatursatz, Fensterheber",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Fensterheber"
        },
        "07993": {
            "GenartName": "Reparatursatz, Hauptscheinwerfer",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Hauptscheinwerfer"
        },
        "07994": {
            "GenartName": "Halter, Stoßfänger",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Stoßfänger"
        },
        "07995": {
            "GenartName": "Halter, Seitenmarkierungsleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "07996": {
            "GenartName": "Halter, Seitenmarkierungsleuchte",
            "NormTermName": "Halter",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "07997": {
            "GenartName": "Schaltkulisse",
            "NormTermName": "Schaltkulisse",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "07998": {
            "GenartName": "Reparatursatz, Schaltkulisse",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltkulisse"
        },
        "07999": {
            "GenartName": "Auffanggurt",
            "NormTermName": "Auffanggurt",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08000": {
            "GenartName": "Haltegurt, Arbeitsplatzpositionierung",
            "NormTermName": "Haltegurt",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Arbeitsplatzpositionierung"
        },
        "08001": {
            "GenartName": "Kabelsatz, Innenraumgebläseschalter",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Heizung/Lüftung, Universal",
            "UsageName": "Innenraumgebläseschalter"
        },
        "08002": {
            "GenartName": "Schraubensatz, Lagerbock-Nockenwelle",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Lagerbock-Nockenwelle"
        },
        "08003": {
            "GenartName": "Kabelreparatursatz, Nebelscheinwerfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelscheinwerfer"
        },
        "08004": {
            "GenartName": "Kabelreparatursatz, Nebelscheinwerfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Nebelscheinwerfer"
        },
        "08005": {
            "GenartName": "Kabelreparatursatz, Bremsflüssigkeitsvorratssensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsflüssigkeitsvorratsensor"
        },
        "08006": {
            "GenartName": "Kabelreparatursatz, Bremsflüssigkeitsvorratssensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremsflüssigkeitsvorratsensor"
        },
        "08007": {
            "GenartName": "Abdeckung, Lenkrad",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkrad"
        },
        "08008": {
            "GenartName": "Lagerung, Kurbelgehäuseentlüftungsventil",
            "NormTermName": "Lagerung",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäuseentlüftungsventil"
        },
        "08009": {
            "GenartName": "Dichtring, Kühlmittelschlauch",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelschlauch"
        },
        "08010": {
            "GenartName": "Öldüse, Nockenschmierung",
            "NormTermName": "Düse",
            "AssGrpName": "Schmierung",
            "UsageName": "Nockenschmierung"
        },
        "08011": {
            "GenartName": "Ölpeilstab, Automatikgetriebe",
            "NormTermName": "Peilstab",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "08012": {
            "GenartName": "Ölpeilstab, Schaltgetriebe",
            "NormTermName": "Peilstab",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "08013": {
            "GenartName": "Sicherungsstift, Verschlussstück-Automatikgetr.-Ölpeilstab",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "08014": {
            "GenartName": "Schraube, Motorlagerhalterung",
            "NormTermName": "Schraube",
            "AssGrpName": "Motoraufhängung",
            "UsageName": "Motorlagerhalterung"
        },
        "08015": {
            "GenartName": "Abziehersatz, Nockenwellennabe",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwellennabe"
        },
        "08016": {
            "GenartName": "Abziehersatz, Nockenwellennabe",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Nockenwellennabe"
        },
        "08017": {
            "GenartName": "Werkzeugsatz, Steuerkette",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Steuerkette"
        },
        "08018": {
            "GenartName": "Arretierwerkzeug, Wasserpumpe",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Wasserpumpe"
        },
        "08019": {
            "GenartName": "Gegenhalteschlüssel, Wasserpumpe",
            "NormTermName": "Gegenhalteschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Wasserpumpe"
        },
        "08020": {
            "GenartName": "Kabelreparatursatz, Einspritzpumpenabstellventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpenabstellventil"
        },
        "08021": {
            "GenartName": "Kabelreparatursatz, Heckklappe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz, Universal",
            "UsageName": "Heckklappe"
        },
        "08022": {
            "GenartName": "Kabelreparatursatz, Tür",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz, Universal",
            "UsageName": "Tür"
        },
        "08023": {
            "GenartName": "Kabelreparatursatz, Kofferraumdeckel",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz, Universal",
            "UsageName": "Kofferraumdeckel"
        },
        "08024": {
            "GenartName": "Drucksensor, Hauptbremszylinder",
            "NormTermName": "Sensor",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Hauptbremszylinder"
        },
        "08025": {
            "GenartName": "Heckblende",
            "NormTermName": "Blende",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Heckleuchte"
        },
        "08026": {
            "GenartName": "Umrissleuchtensatz",
            "NormTermName": "Leuchtensatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Umrissleuchte"
        },
        "08027": {
            "GenartName": "Kabelreparatursatz, Steuergerät (Heizung/Lüftung)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Steuergerät (Heizung/Lüftung)"
        },
        "08028": {
            "GenartName": "Kabelreparatursatz, Steuergerät (Heizung/Lüftung)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung, Universal",
            "UsageName": "Steuergerät (Heizung/Lüftung)"
        },
        "08029": {
            "GenartName": "Kabelreparatursatz, Abgasdrucksensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Abgasdrucksensor"
        },
        "08030": {
            "GenartName": "Kabelreparatursatz, Abgasdrucksensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Abgasdrucksensor"
        },
        "08031": {
            "GenartName": "Erweiterungssatz, Arretierwerkzeug (Steuerzeiten)",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Arretierwerkzeug (Steuerzeiten)"
        },
        "08032": {
            "GenartName": "Erweiterungssatz, Riemenscheibenabzieher",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Riemenscheibenabzieher"
        },
        "08033": {
            "GenartName": "Einziehwerkzeugsatz, Antriebswelle",
            "NormTermName": "Einziehwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Antriebswelle"
        },
        "08034": {
            "GenartName": "Gewindeschneidwerkzeugsatz, Antriebswelle",
            "NormTermName": "Gewindeschneidwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Antriebswelle"
        },
        "08035": {
            "GenartName": "Halterung, Batterieladegerät",
            "NormTermName": "Halterung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Batterieladegerät"
        },
        "08036": {
            "GenartName": "Absperrpfosten-Set",
            "NormTermName": "Absperrpfosten-Set",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08037": {
            "GenartName": "Adapter, Fahrwagen (Batterielader/-starter)",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrwagen, Batterielader/-starter"
        },
        "08038": {
            "GenartName": "Klemmzange, Starthilfegerät",
            "NormTermName": "Zange",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Starthilfegerät"
        },
        "08039": {
            "GenartName": "Auspresswerkzeug, Rad (NFZ)",
            "NormTermName": "Auspresswerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Rad"
        },
        "08040": {
            "GenartName": "Batterie, Starthilfegerät",
            "NormTermName": "Batterie",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Starthilfegerät"
        },
        "08041": {
            "GenartName": "Schutzhülle, Batterieladegerät",
            "NormTermName": "Schutzhülle",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Batterieladegerät"
        },
        "08042": {
            "GenartName": "Gewindebolzen, Einziehwerkzeug (Antriebswelle)",
            "NormTermName": "Bolzen",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einziehwerkzeug (Antriebswelle)"
        },
        "08043": {
            "GenartName": "Drucker, Prüfgerät-Batterie",
            "NormTermName": "Drucker",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Prüfgerät-Batterie"
        },
        "08044": {
            "GenartName": "Bürostuhl",
            "NormTermName": "Bürostuhl",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Betriebseinrichtung"
        },
        "08045": {
            "GenartName": "Ladeampel, Batterieladegerät",
            "NormTermName": "Ladeampel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Batterieladegerät"
        },
        "08046": {
            "GenartName": "Verlängerungskabel, Batterieladegerät",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Batterieladegerät"
        },
        "08047": {
            "GenartName": "Spannungsstabilisator, Start-/Stopp-System",
            "NormTermName": "Regler",
            "AssGrpName": "Startanlage",
            "UsageName": "Start-/Stopp-System"
        },
        "08048": {
            "GenartName": "Einziehwerkzeugsatz, Radbolzen",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radbolzen"
        },
        "08049": {
            "GenartName": "Adapter, Einziehwerkzeug (Radbolzen)",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radbolzeneinziehhilfe"
        },
        "08050": {
            "GenartName": "Niederhalter, Kraftstofffilter",
            "NormTermName": "Niederhalter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kraftstofffilter"
        },
        "08051": {
            "GenartName": "Felgen-Demontagewerkzeug",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Felge"
        },
        "08052": {
            "GenartName": "Gegenplatte, Felgen-Demontagewerkzeug",
            "NormTermName": "Gegenplatte",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Felgen-Demontagewerkzeug"
        },
        "08053": {
            "GenartName": "Haken, Felgen-Demontagewerkzeug",
            "NormTermName": "Haken",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Felgen-Demontagewerkzeug"
        },
        "08054": {
            "GenartName": "Gegenhalter, Einziehwerkzeug (Radbolzen)",
            "NormTermName": "Gegenhalter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einziehwerkzeug (Radbolzen)"
        },
        "08055": {
            "GenartName": "Sortiment, Reparatur-Klimaleitung",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Klimaleitung"
        },
        "08056": {
            "GenartName": "Zentrierlager, Einziehwerkzeug (Radbolzen)",
            "NormTermName": "Zentriervorrichtung",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einziehwerkzeug (Radbolzen)"
        },
        "08057": {
            "GenartName": "Spannhülse, Einziehwerkzeug (Antriebswelle)",
            "NormTermName": "Hülse",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einziehwerkzeug (Antriebswelle)"
        },
        "08058": {
            "GenartName": "Spannmutter, Einziehwerkzeug (Radbolzen)",
            "NormTermName": "Mutter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einziehwerkzeug (Radbolzen)"
        },
        "08059": {
            "GenartName": "Leitkegel",
            "NormTermName": "Leitkegel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08060": {
            "GenartName": "Ventilator",
            "NormTermName": "Ventilator",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08061": {
            "GenartName": "Auffahrrampensatz",
            "NormTermName": "Auffahrrampensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08062": {
            "GenartName": "Dellenreparatursatz",
            "NormTermName": "Dellenreparatursatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08063": {
            "GenartName": "Demontagewerkzeug, Heckleuchte",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Heckleuchte"
        },
        "08064": {
            "GenartName": "Sortiment, O-Ring",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "O-Ring"
        },
        "08065": {
            "GenartName": "Schnellkontaktkabel, Batterieladegerät",
            "NormTermName": "Schnellkontaktkabel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Batterieladegerät"
        },
        "08066": {
            "GenartName": "Zangenkabel, Batterieladegerät",
            "NormTermName": "Zangenkabel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Batterieladegerät"
        },
        "08067": {
            "GenartName": "Lösewerkzeugsatz, Spurstangenkugelgelenk",
            "NormTermName": "Lösewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Spurstangenkugelgelenk"
        },
        "08068": {
            "GenartName": "Universal-Ablagebock",
            "NormTermName": "Universal-Ablagebock",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08069": {
            "GenartName": "Plüschtier",
            "NormTermName": "Plüschtier",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08070": {
            "GenartName": "Arretierdorn, Wasserpumpe",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Wasserpumpe"
        },
        "08071": {
            "GenartName": "Arretierdorn, Wasserpumpe",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Wasserpumpe"
        },
        "08072": {
            "GenartName": "Federsicherung, Winkelhebelverschluss",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Anhänger",
            "UsageName": "Winkelhebelverschluss"
        },
        "08073": {
            "GenartName": "Verschlussöse, Winkelhebelverschluss",
            "NormTermName": "Verschlussöse",
            "AssGrpName": "Anhänger",
            "UsageName": "Winkelhebelverschluss"
        },
        "08074": {
            "GenartName": "Federsicherung, Pendelverschluss",
            "NormTermName": "Sicherungselement",
            "AssGrpName": "Anhänger",
            "UsageName": "Pendelverschluss"
        },
        "08075": {
            "GenartName": "Sicherungsseil, Winkelhebelverschluss",
            "NormTermName": "Sicherungsseil",
            "AssGrpName": "Anhänger",
            "UsageName": "Winkelhebelverschluss"
        },
        "08076": {
            "GenartName": "Fugengummi",
            "NormTermName": "Fugengummi",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "08077": {
            "GenartName": "Fugenschwamm",
            "NormTermName": "Fugenschwamm",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "08078": {
            "GenartName": "Sandsieb",
            "NormTermName": "Sieb",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "08079": {
            "GenartName": "Senklot",
            "NormTermName": "Senklot",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "08080": {
            "GenartName": "Markierungsschnur",
            "NormTermName": "Markierungsschnur",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "08081": {
            "GenartName": "Markier-Pulver",
            "NormTermName": "Markier-Pulver",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "08082": {
            "GenartName": "Bleistift",
            "NormTermName": "Bleistift",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08083": {
            "GenartName": "Putzhaken",
            "NormTermName": "Putzhaken",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "08084": {
            "GenartName": "Schaufel",
            "NormTermName": "Schaufel",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "08085": {
            "GenartName": "Kehrblech",
            "NormTermName": "Kehrblech",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08086": {
            "GenartName": "Besen-/Schaufelstiel",
            "NormTermName": "Besen-/Schaufelstiel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08087": {
            "GenartName": "Rechen",
            "NormTermName": "Rechen",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "08088": {
            "GenartName": "Kraftstoffsensor, Ethanol-Benzin-Verhältnis",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Ethanol-Benzin-Verhältnis"
        },
        "08089": {
            "GenartName": "Schlauch, Wärmetauscher (Heizung)",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Heizung/Lüftung, Universal",
            "UsageName": "Heizungskühler"
        },
        "08090": {
            "GenartName": "Schlauchleitungssatz, Leckkraftstoff",
            "NormTermName": "Schlauchleitungssatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Leckkraftstoff"
        },
        "08091": {
            "GenartName": "Zusatzheizung, Fahrerhaus",
            "NormTermName": "Zusatzheizung",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhaus"
        },
        "08092": {
            "GenartName": "Unterfahrschutz",
            "NormTermName": "Unterfahrschutz",
            "AssGrpName": "Karosserie",
            "UsageName": "NKW"
        },
        "08093": {
            "GenartName": "Feder, Motorhaubenschlossaufstellung",
            "NormTermName": "Feder",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorhaubenschlossaufstellung"
        },
        "08094": {
            "GenartName": "Kettenreiniger",
            "NormTermName": "Kettenreiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "08095": {
            "GenartName": "Freileitungsschneider",
            "NormTermName": "Freileitungsschneider",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08096": {
            "GenartName": "Werkzeug-Halteschlaufe",
            "NormTermName": "Halteschlaufe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Werkzeug"
        },
        "08097": {
            "GenartName": "Werkzeug-Fangseil",
            "NormTermName": "Fangseil",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Werkzeug"
        },
        "08098": {
            "GenartName": "Klinge, Kabelschere",
            "NormTermName": "Klinge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kabelschere"
        },
        "08099": {
            "GenartName": "Klingensatz, Kabelschere",
            "NormTermName": "Klingensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kabelschere"
        },
        "08100": {
            "GenartName": "Feder, Abisolierzange",
            "NormTermName": "Feder",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abisolierzange"
        },
        "08101": {
            "GenartName": "Messerkopf, Drahtseilschere",
            "NormTermName": "Messerkopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drahtseilschere"
        },
        "08102": {
            "GenartName": "Mattenschneider",
            "NormTermName": "Mattenschneider",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "08103": {
            "GenartName": "Messerkopf, Mattenschneider",
            "NormTermName": "Messerkopf",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": "Mattenschneider"
        },
        "08104": {
            "GenartName": "Fliesenbrechzange",
            "NormTermName": "Fliesenbrechzange",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "08105": {
            "GenartName": "Backe, Fliesenbrechzange",
            "NormTermName": "Backe",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": "Fliesenbrechzange"
        },
        "08106": {
            "GenartName": "Streichmaß",
            "NormTermName": "Streichmaß",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08107": {
            "GenartName": "Gewindeschneidmutter",
            "NormTermName": "Gewindeschneidmutter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08108": {
            "GenartName": "Abzieher, Schwingungsdämpfer (Riementrieb)",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schwingungsdämpfer (Riementrieb)"
        },
        "08109": {
            "GenartName": "Sicherungshaken, Motorhaubenschloss",
            "NormTermName": "Sicherungshaken",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorhaubenschloss"
        },
        "08110": {
            "GenartName": "Abmantelungswerkzeug",
            "NormTermName": "Abmantelungswerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08111": {
            "GenartName": "Kalibrierdorn, Kompressionswerkzeug-Steckverbinder",
            "NormTermName": "Kalibrierdorn",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kompressionswerkzeug, Steckverbinder"
        },
        "08112": {
            "GenartName": "Werkzeug-Fangseil-Set",
            "NormTermName": "Werkzeug-Fangseil-Set",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Werkzeug"
        },
        "08113": {
            "GenartName": "Zangenschlüssel-Satz",
            "NormTermName": "Zangensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zangenschlüssel"
        },
        "08114": {
            "GenartName": "Matrize, Profil-Verbundzange",
            "NormTermName": "Matrize",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Profil-Verbundzange"
        },
        "08115": {
            "GenartName": "Einsätze, Schlauchschellenzange",
            "NormTermName": "Einsätze",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlauchschellenzange"
        },
        "08116": {
            "GenartName": "Adapterplatte, Fußrastenanlage",
            "NormTermName": "Adapterplatte",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Fußrastenanlage (Zweirad)"
        },
        "08117": {
            "GenartName": "Schaltstangenverlängerung, Fußrastenanlage",
            "NormTermName": "Schaltstangenverlängerung",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Fußrastenanlage (Zweirad)"
        },
        "08118": {
            "GenartName": "Schaumstoffeinlage, Sortimentskasten",
            "NormTermName": "Schaumstoffeinlage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Sortimentskasten"
        },
        "08119": {
            "GenartName": "Reibrad, Wasserpumpe",
            "NormTermName": "Reibrad",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "08120": {
            "GenartName": "Kabelreparatursatz, Temperaturschalter-Kühlerlüfter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Temperaturschalter-Kühlerlüfter"
        },
        "08121": {
            "GenartName": "Kabelreparatursatz, Temperaturschalter-Kühlerlüfter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Temperaturschalter-Kühlerlüfter"
        },
        "08122": {
            "GenartName": "Kabelreparatursatz, Tagfahrleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Tagfahrleuchte"
        },
        "08123": {
            "GenartName": "Kabelreparatursatz, Tagfahrleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Tagfahrleuchte"
        },
        "08124": {
            "GenartName": "Kabelreparatursatz, Sensor (Batteriemanagement)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz",
            "UsageName": "Sensor (Batteriemanagement)"
        },
        "08125": {
            "GenartName": "Kabelreparatursatz, Sensor (Batteriemanagement)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz, Universal",
            "UsageName": "Sensor (Batteriemanagement)"
        },
        "08126": {
            "GenartName": "Kabelreparatursatz, Außentemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Außentemperatursensor"
        },
        "08127": {
            "GenartName": "Schaltstange, Fußrastenanlage",
            "NormTermName": "Schaltstange",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Fußrastenanlage (Zweirad)"
        },
        "08128": {
            "GenartName": "Entlüfternippel, Auffangbehälter-Bremssystementlüftung",
            "NormTermName": "Nippel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Auffangbehälter-Bremssystementlüftung"
        },
        "08129": {
            "GenartName": "Entlüfternippelsatz, Auffangbehälter-Bremssystementlüftung",
            "NormTermName": "Nippelsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Auffangbehälter-Bremssystementlüftung"
        },
        "08130": {
            "GenartName": "Adapterplatte, Fußbremszylinder",
            "NormTermName": "Adapterplatte",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Fußbremszylinder"
        },
        "08131": {
            "GenartName": "Fußrastenstopfen",
            "NormTermName": "Stopfen",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Fußraste (Zweirad)"
        },
        "08132": {
            "GenartName": "Mitnehmer, Schalt-/Bremshebel (Fußrastenanlage)",
            "NormTermName": "Mitnehmer",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Schalt-/Bremshebel (Fußrastenanlage)"
        },
        "08133": {
            "GenartName": "Kugelkopf, Schaltstange (Fußrastenanlage)",
            "NormTermName": "Kugelkopf",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Schaltstange (Fußrastenanlage)"
        },
        "08134": {
            "GenartName": "Distanzhülse, Klemmschelle (Standrohr/Lenkrohraufnahme)",
            "NormTermName": "Hülse",
            "AssGrpName": "Lenkung",
            "UsageName": "Klemmschelle (Standrohr/Lenkrohraufnahme/Zweirad)"
        },
        "08135": {
            "GenartName": "Klemmschelle, Standrohr (Lenkrohraufnahme)",
            "NormTermName": "Schelle",
            "AssGrpName": "Lenkung",
            "UsageName": "Standrohr (Lenkrohraufnahme/Zweirad)"
        },
        "08136": {
            "GenartName": "Montagesatz, Bremsscheibe",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsscheibe (Zweirad)"
        },
        "08137": {
            "GenartName": "Adapterhalter, Bremsflüssigkeitsbehälter",
            "NormTermName": "Adapterhalter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsflüssigkeitsbehälter (Zweirad)"
        },
        "08138": {
            "GenartName": "Kabelsatz, Innenraumgebläseschalter",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumgebläseschalter"
        },
        "08139": {
            "GenartName": "Haltersatz, Hydraulikzylinder (Werkstattpresse)",
            "NormTermName": "Haltersatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hydraulikzylinder"
        },
        "08140": {
            "GenartName": "Putz-Papierrolle",
            "NormTermName": "Putz-Papierrolle",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08141": {
            "GenartName": "Schrubber",
            "NormTermName": "Schrubber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08142": {
            "GenartName": "Handfeger",
            "NormTermName": "Handfeger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08143": {
            "GenartName": "Kehrgarnitur",
            "NormTermName": "Kehrgarnitur",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08144": {
            "GenartName": "Leiter",
            "NormTermName": "Leiter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08145": {
            "GenartName": "Reparatursatz, Automatikgetriebe",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "08146": {
            "GenartName": "Getriebereiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Getriebe"
        },
        "08147": {
            "GenartName": "Gehäuse, Luftfilter",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Luftfilter"
        },
        "08148": {
            "GenartName": "Additiv, Ölverluststop",
            "NormTermName": "Zusatzmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Ölverluststop"
        },
        "08149": {
            "GenartName": "Hydrostößeladditiv",
            "NormTermName": "Zusatzmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Hydrostößel"
        },
        "08150": {
            "GenartName": "Additiv, Ruß-/Partikelfilterregeneration",
            "NormTermName": "Zusatzmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Ruß-/Partikelfilterregeneration"
        },
        "08151": {
            "GenartName": "Drosselklappenreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Drosselklappe"
        },
        "08152": {
            "GenartName": "Entlüftungswerkzeugsatz, Kraftstoffsystem",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kraftstoffsystem"
        },
        "08153": {
            "GenartName": "Ausziehersatz, Einspritzdüse",
            "NormTermName": "Ausziehersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzdüse"
        },
        "08154": {
            "GenartName": "Erweiterungssatz, Einspritzdüsenauszieher",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzdüsenauszieher"
        },
        "08155": {
            "GenartName": "Antriebssatz, Einspritzdüsenauszieher",
            "NormTermName": "Antriebssatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzdüsenauszieher"
        },
        "08156": {
            "GenartName": "Hydraulikpumpe",
            "NormTermName": "Hydraulikpumpe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08157": {
            "GenartName": "Schlagauszieher, Common-Rail-Injektor",
            "NormTermName": "Schlagauszieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Common-Rail-Injektor"
        },
        "08158": {
            "GenartName": "Schlagausziehersatz, Vorkammer",
            "NormTermName": "Schlagausziehersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Vorkammer"
        },
        "08159": {
            "GenartName": "Schlagauszieher, Vorkammer",
            "NormTermName": "Schlagauszieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Vorkammer"
        },
        "08160": {
            "GenartName": "Reinigungs-/Fräswerkzeug, CR-Injektorschacht",
            "NormTermName": "Reinigungs-/Fräswerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "CR-Injektorschacht"
        },
        "08161": {
            "GenartName": "Grundplatte, Fußrastenanlage",
            "NormTermName": "Grundplatte",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Fußrastenanlage (Zweirad)"
        },
        "08162": {
            "GenartName": "Ausziehersatz, Injektorhülse",
            "NormTermName": "Ausziehersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Injektorhülse"
        },
        "08163": {
            "GenartName": "Verlängerung, Reinigungs-/Fräswerkzeug (CR-Injektorschacht)",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Reinigungs-/Fräswerkzeug (CR-Injektorschacht)"
        },
        "08164": {
            "GenartName": "Abzieher, Zündspulenstecker",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zündspulenstecker"
        },
        "08165": {
            "GenartName": "Abziehersatz, Zündspulenstecker",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zündspulenstecker"
        },
        "08166": {
            "GenartName": "Abzieher, Zündspuleneinheit",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zündspuleneinheit"
        },
        "08167": {
            "GenartName": "Abzieher, Zündspuleneinheit",
            "NormTermName": "Abzieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zündspuleneinheit"
        },
        "08168": {
            "GenartName": "Reibkopf, Reinigungs-/Fräswerkzeugsatz (CR-Injektorschacht)",
            "NormTermName": "Reibkopf",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Reinigungs-/Fräswerkzeugsatz (CR-Injektorschacht"
        },
        "08169": {
            "GenartName": "Entriegelungswerkzeug, Kraftstoffleitung",
            "NormTermName": "Entriegelungswerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kraftstoffleitung"
        },
        "08170": {
            "GenartName": "Erweiterungssatz, Glühkerzen-Demontagewerkzeug",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerzen-Demontagewerkzeug"
        },
        "08171": {
            "GenartName": "Druckluftadapter, Injektorbohrung",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Injektorbohrung"
        },
        "08172": {
            "GenartName": "Führungsbuchsen Satz, Glühkerzendemontagewerkzeug",
            "NormTermName": "Führungsbuchsensatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerzendemontagewerkzeug"
        },
        "08173": {
            "GenartName": "Reibahlensatz, Glühkerze",
            "NormTermName": "Reibahlensatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerze"
        },
        "08174": {
            "GenartName": "Luftfiltersatz",
            "NormTermName": "Filter-Satz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Luft"
        },
        "08175": {
            "GenartName": "Filtersatz, Innenraumluft",
            "NormTermName": "Filter-Satz",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumluft"
        },
        "08176": {
            "GenartName": "Filtersatz, Innenraumluft",
            "NormTermName": "Filter-Satz",
            "AssGrpName": "Heizung/Lüftung, Universal",
            "UsageName": "Innenraumluft"
        },
        "08177": {
            "GenartName": "Kraftstofffiltersatz",
            "NormTermName": "Filter-Satz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoff"
        },
        "08178": {
            "GenartName": "Kraftstofffiltersatz",
            "NormTermName": "Filter-Satz",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Kraftstoff"
        },
        "08179": {
            "GenartName": "Motorrad-Radfreiheber",
            "NormTermName": "Motorrad-Radfreiheber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08180": {
            "GenartName": "Schleppertrennbock",
            "NormTermName": "Schleppertrennbock",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08181": {
            "GenartName": "Montagehilfe, Reifenmontiermaschine",
            "NormTermName": "Montagehilfe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reifenmontiermaschine"
        },
        "08182": {
            "GenartName": "Andrückteller, Radauswuchtmaschine",
            "NormTermName": "Andrückteller",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Radauswuchtmaschine"
        },
        "08183": {
            "GenartName": "Reifenabdrücker",
            "NormTermName": "Reifenabdrücker",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08184": {
            "GenartName": "Luftkanone",
            "NormTermName": "Luftkanone",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08185": {
            "GenartName": "Reifenfüllkäfig",
            "NormTermName": "Reifenfüllkäfig",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08186": {
            "GenartName": "Stromerzeuger",
            "NormTermName": "Stromerzeuger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08187": {
            "GenartName": "Steckdosenleiste",
            "NormTermName": "Steckdosenleiste",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08188": {
            "GenartName": "Scheuersaugmaschine",
            "NormTermName": "Scheuersaugmaschine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08189": {
            "GenartName": "Teilewaschautomat",
            "NormTermName": "Teilewaschautomat",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08190": {
            "GenartName": "Wasserstrahlschneidgerät",
            "NormTermName": "Wasserstrahlschneidgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08191": {
            "GenartName": "Gerüst",
            "NormTermName": "Gerüst",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08192": {
            "GenartName": "Punktstrahlgerät",
            "NormTermName": "Punktstrahlgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08193": {
            "GenartName": "Abdrehmaschine, Bremsscheibe",
            "NormTermName": "Drehmaschine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsscheibe"
        },
        "08194": {
            "GenartName": "Felgen-Aufbereitungsanlage",
            "NormTermName": "Aufbereitungsanlage",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Felgen"
        },
        "08195": {
            "GenartName": "Abdrehmaschine, Bremsbelag",
            "NormTermName": "Drehmaschine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Bremsbelag"
        },
        "08196": {
            "GenartName": "Felgenschutz, Reifenmontage",
            "NormTermName": "Felgenschutz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reifenmontage"
        },
        "08197": {
            "GenartName": "Abkantbank",
            "NormTermName": "Abkantbank",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08198": {
            "GenartName": "Drehmaschine",
            "NormTermName": "Drehmaschine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08199": {
            "GenartName": "Tischwagen",
            "NormTermName": "Tischwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08200": {
            "GenartName": "Laserschneidsystem",
            "NormTermName": "Laserschneidsystem",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08201": {
            "GenartName": "Steuergerät, Toter-Winkel-Erkennung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Toter-Winkel-Erkennung"
        },
        "08202": {
            "GenartName": "Warmlufterzeuger",
            "NormTermName": "Warmlufterzeuger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08203": {
            "GenartName": "Elektroheizgerät",
            "NormTermName": "Elektroheizgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08204": {
            "GenartName": "Steuergerät, Spurwechselassistent",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Spurwechselassistent"
        },
        "08205": {
            "GenartName": "Augenspülflasche",
            "NormTermName": "Augenspülflasche",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08206": {
            "GenartName": "Pflasterspender",
            "NormTermName": "Pflasterspender",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08207": {
            "GenartName": "Sprühpflaster",
            "NormTermName": "Sprühpflaster",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08208": {
            "GenartName": "Sicherheitsnotdusche",
            "NormTermName": "Sicherheitsnotdusche",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08209": {
            "GenartName": "Verband-/Medikamentenschrank",
            "NormTermName": "Schrank",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Verbände/Medikamente"
        },
        "08210": {
            "GenartName": "Krankentrage",
            "NormTermName": "Krankentrage",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08211": {
            "GenartName": "Flanschsatz, Abgasrohr",
            "NormTermName": "Flanschsatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasrohr"
        },
        "08212": {
            "GenartName": "Flanschsatz, Abgasrohr",
            "NormTermName": "Flanschsatz",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Abgasrohr"
        },
        "08213": {
            "GenartName": "Wärmebildkamera",
            "NormTermName": "Wärmebildkamera",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08214": {
            "GenartName": "Stufenschlüssel",
            "NormTermName": "Stufenschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08216": {
            "GenartName": "Pullover",
            "NormTermName": "Pullover",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08217": {
            "GenartName": "Mütze",
            "NormTermName": "Mütze",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08218": {
            "GenartName": "Augen-Notfallstation",
            "NormTermName": "Augen-Notfallstation",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08219": {
            "GenartName": "Wandhalterung, Feuerlöscher",
            "NormTermName": "Halterung",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Feuerlöscher"
        },
        "08220": {
            "GenartName": "KFZ-Halterung, Feuerlöscher",
            "NormTermName": "Halterung",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Feuerlöscher"
        },
        "08221": {
            "GenartName": "Schutzhaube, Feuerlöscher",
            "NormTermName": "Schutzhaube",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Feuerlöscher"
        },
        "08222": {
            "GenartName": "Löschdecke",
            "NormTermName": "Löschdecke",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08223": {
            "GenartName": "Erste-Hilfe-Station",
            "NormTermName": "Erste-Hilfe-Station",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08224": {
            "GenartName": "Erste-Hilfe-Koffer",
            "NormTermName": "Erste-Hilfe-Koffer",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08225": {
            "GenartName": "Befestigungssatz, Ölkühler",
            "NormTermName": "Befestigungssatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölkühler"
        },
        "08226": {
            "GenartName": "Glühlampe, Aschenbecher",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Aschenbecher"
        },
        "08227": {
            "GenartName": "Laufring, Differentialsteckwelle",
            "NormTermName": "Laufring",
            "AssGrpName": "Radantrieb",
            "UsageName": "Differentialsteckwelle"
        },
        "08228": {
            "GenartName": "Halterung, Ölkühler (Lenkung)",
            "NormTermName": "Halterung",
            "AssGrpName": "Lenkung",
            "UsageName": "Ölkühler"
        },
        "08229": {
            "GenartName": "Reparaturhülse, Kurbelwelle",
            "NormTermName": "Reparaturhülse",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwelle"
        },
        "08230": {
            "GenartName": "Scheibe, Ventilfedersitz",
            "NormTermName": "Scheibe",
            "AssGrpName": "Motorsteuerung",
            "UsageName": null
        },
        "08231": {
            "GenartName": "Ölkühlerleitung, Lenkung",
            "NormTermName": "Ölkühlerleitung",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkung"
        },
        "08232": {
            "GenartName": "Froststopfen-Set",
            "NormTermName": "Froststopfen-Set",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": null
        },
        "08233": {
            "GenartName": "Verbindungselement, Ölkühlerschlauch (Automatikgetriebe)",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölkühlerschlauch (Automatikgetriebe)"
        },
        "08234": {
            "GenartName": "Dichtungssatz, Lenkhebel",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkhebel"
        },
        "08235": {
            "GenartName": "Umrüstsatz, elektronische Zündung",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Zündanlage",
            "UsageName": "elektronische Zündung"
        },
        "08236": {
            "GenartName": "Startsicherheitsschalter, Automatikgetriebe",
            "NormTermName": "Schalter",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "08237": {
            "GenartName": "Kohlebürstensatz, Generator",
            "NormTermName": "Kohlebürstensatz",
            "AssGrpName": "Generator",
            "UsageName": "Generator"
        },
        "08238": {
            "GenartName": "Kühler-Set, Motorkühlung",
            "NormTermName": "Kühler-Set",
            "AssGrpName": "Kühlung",
            "UsageName": "Motorkühlung"
        },
        "08239": {
            "GenartName": "Reparaturhülse, Drehmomentwandler",
            "NormTermName": "Hülse",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Drehmomentwandler"
        },
        "08240": {
            "GenartName": "Kabelreparatursatz, Öldrucksensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Instrumente",
            "UsageName": "Öldrucksensor"
        },
        "08241": {
            "GenartName": "Prüfgerät, Tankdeckel",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Tankdeckel"
        },
        "08242": {
            "GenartName": "Adapter, Tankdeckelprüfgerät",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Tankdeckelprüfgerät"
        },
        "08243": {
            "GenartName": "Reparaturhülse, Getriebeeingangswelle (Schaltgetriebe)",
            "NormTermName": "Reparaturhülse",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeeingangswelle"
        },
        "08244": {
            "GenartName": "Reparaturhülse, Schwingungsdämpfer (Riementrieb)",
            "NormTermName": "Reparaturhülse",
            "AssGrpName": "Riementrieb",
            "UsageName": "Schwingungsdämpfer (Riementrieb)"
        },
        "08245": {
            "GenartName": "Dichtring, Drehmomentwandler",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Drehmomentwandler"
        },
        "08246": {
            "GenartName": "Schutzkappe, Zündspuleneinheit",
            "NormTermName": "Kappe",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündspuleneinheit"
        },
        "08247": {
            "GenartName": "Reparatursatz, Hydraulikpumpe (Lenkung)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Hydraulikpumpe (Lenkung)"
        },
        "08248": {
            "GenartName": "Lager, Differentialsteckwelle",
            "NormTermName": "Lager",
            "AssGrpName": "Radantrieb",
            "UsageName": "Differentialsteckwelle"
        },
        "08249": {
            "GenartName": "Reparaturhülse, Differentialsteckwelle",
            "NormTermName": "Reparaturhülse",
            "AssGrpName": "Radantrieb",
            "UsageName": "Differentialsteckwelle"
        },
        "08250": {
            "GenartName": "Lagersatz, Differentialsteckwelle",
            "NormTermName": "Lagersatz",
            "AssGrpName": "Radantrieb",
            "UsageName": "Differentialsteckwelle"
        },
        "08251": {
            "GenartName": "Nachsteller-Satz, Trommelbremse",
            "NormTermName": "Nachsteller-Satz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Trommelbremse"
        },
        "08252": {
            "GenartName": "Umplatzierungs-Set, Ölfilter",
            "NormTermName": "Umplatzierungs-Set",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölfilter"
        },
        "08253": {
            "GenartName": "Dichtung, Katalysator",
            "NormTermName": "Dichtung",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Katalysator"
        },
        "08254": {
            "GenartName": "Reparatursatz, Ölpumpe",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "08255": {
            "GenartName": "Dichtring, Schaltstange",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Schaltstange"
        },
        "08256": {
            "GenartName": "Sicherheitsschalter, Kraftstoffpumpe",
            "NormTermName": "Schalter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "08257": {
            "GenartName": "Dichtung, Drosselklappenstutzen",
            "NormTermName": "Dichtung",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Drosselklappenstutzen"
        },
        "08258": {
            "GenartName": "Kabelreparatursatz, Drosselklappensensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Drosselklappensensor"
        },
        "08259": {
            "GenartName": "Lampenfassung, Anzeige-Instrument",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Instrumente",
            "UsageName": "Anzeige-Instrument"
        },
        "08260": {
            "GenartName": "Stecker, Hauptlichtschalter",
            "NormTermName": "Stecker",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptlichtschalter"
        },
        "08261": {
            "GenartName": "Lampenfassung, Park-/Positionsleuchte",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Park-/Positionsleuchte"
        },
        "08262": {
            "GenartName": "Glühlampe, Uhr",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Instrumente",
            "UsageName": "Uhr"
        },
        "08263": {
            "GenartName": "Montagesatz, Tankgeber/Kraftstoffpumpe",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Tankgeber/Kraftstoffpumpe"
        },
        "08264": {
            "GenartName": "Reparaturhülse, Getriebeausgangswelle (Automatikgetriebe)",
            "NormTermName": "Reparaturhülse",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Getriebeausgangswelle (Automatikgetriebe)"
        },
        "08265": {
            "GenartName": "Reparaturhülse, Getriebeausgangswelle (Verteilergetriebe)",
            "NormTermName": "Reparaturhülse",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Getriebeausgangswelle (Verteilergetriebe)"
        },
        "08266": {
            "GenartName": "Dichtung, Lenkhebel",
            "NormTermName": "Dichtung",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkhebel"
        },
        "08267": {
            "GenartName": "Verschlussring, Kraftstoffpumpe",
            "NormTermName": "Verschlussring",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "08268": {
            "GenartName": "Nippel, Lenkungsschlauch",
            "NormTermName": "Nippel",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkungsschlauch"
        },
        "08269": {
            "GenartName": "Reparaturhülse, Ölpumpe (Automatikgetriebe)",
            "NormTermName": "Reparaturhülse",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölpumpe (Automatikgetriebe)"
        },
        "08270": {
            "GenartName": "Reparaturhülse, Schaltstange (Automatikgetriebe)",
            "NormTermName": "Reparaturhülse",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Schaltstange (Automatikgetriebe)"
        },
        "08271": {
            "GenartName": "Glühlampe, Radio-Display",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Radio-Display"
        },
        "08272": {
            "GenartName": "Kolbensatz",
            "NormTermName": "Kolbensatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": null
        },
        "08273": {
            "GenartName": "Mutter, Kipphebel",
            "NormTermName": "Mutter",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Kipphebel"
        },
        "08274": {
            "GenartName": "Verbindungskabelsatz, Anhänger",
            "NormTermName": "Elektroleitungssatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Anhänger"
        },
        "08275": {
            "GenartName": "Magnetschalter, Kickdown (Automatikgetriebe)",
            "NormTermName": "Schalter",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Kickdown (Automatikgetriebe)"
        },
        "08276": {
            "GenartName": "Dichtring, Kältemittelleitung",
            "NormTermName": "Dichtring",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kältemittelleitung"
        },
        "08277": {
            "GenartName": "Seilzug, Nachsteller-Trommelbremse",
            "NormTermName": "Seilzug",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Seilzug, Nachsteller-Trommelbremse"
        },
        "08278": {
            "GenartName": "Seilzugführung, Nachsteller-Trommelbremse",
            "NormTermName": "Führung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Nachsteller-Trommelbremse"
        },
        "08279": {
            "GenartName": "Montagesatz, Schwingungsdämpfer (Zahnriemen)",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Schwingungsdämpfer (Zahnriemen)"
        },
        "08280": {
            "GenartName": "Fliehgewicht, Zündverteiler",
            "NormTermName": "Fliehgewicht",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteiler"
        },
        "08281": {
            "GenartName": "Reparaturhülsensatz, Getriebeausgangswelle (Schaltgetriebe)",
            "NormTermName": "Reparaturhülsensatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeausgangswelle (Schaltgetriebe)"
        },
        "08282": {
            "GenartName": "Lager, Zahnrad (Automatikgetriebe)",
            "NormTermName": "Lager",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Zahnrad (Automatikgetriebe)"
        },
        "08283": {
            "GenartName": "Reparatursatz, Arbeitszylinder (Servolenkung)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Arbeitszylinder (Servolenkung)"
        },
        "08284": {
            "GenartName": "Lager, Vorgelegewelle (Schaltgetriebe)",
            "NormTermName": "Lager",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Vorgelegewelle"
        },
        "08285": {
            "GenartName": "Haltering, Antriebswelle (Ölpumpe)",
            "NormTermName": "Haltering",
            "AssGrpName": "Schmierung",
            "UsageName": "Antriebswelle (Ölpumpe)"
        },
        "08286": {
            "GenartName": "Ausgleichsscheibe, Sturz-/Spureinstellung",
            "NormTermName": "Scheibe",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Sturz-/Spureinstellung"
        },
        "08287": {
            "GenartName": "Lager, Getriebeausgangswelle (Schaltgetriebe)",
            "NormTermName": "Lager",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeausgangswelle (Schaltgetriebe)"
        },
        "08288": {
            "GenartName": "Dichtring, Ausgleichswelle",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Ausgleichswelle"
        },
        "08289": {
            "GenartName": "Tankgeber",
            "NormTermName": "Tankgeber",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": null
        },
        "08290": {
            "GenartName": "Lager, Getriebeeingangswelle (Schaltgetriebe)",
            "NormTermName": "Lager",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeeingangswelle"
        },
        "08291": {
            "GenartName": "Drosselventil, Kältemittelkreislauf",
            "NormTermName": "Ventil",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kältemittelkreislauf"
        },
        "08292": {
            "GenartName": "Relais, Kraftstoffeinspritzung",
            "NormTermName": "Relais",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kraftstoffeinspritzung"
        },
        "08293": {
            "GenartName": "Nockenwellenlagersatz",
            "NormTermName": "Lagersatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwelle"
        },
        "08294": {
            "GenartName": "Einstellscheibensatz, Differential",
            "NormTermName": "Scheibensatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "08295": {
            "GenartName": "Schraube, Nachsteller-Trommelbremse",
            "NormTermName": "Schraube",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Nachsteller-Trommelbremse"
        },
        "08296": {
            "GenartName": "Dichtring, Servokolben",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Servokolben (Automatikgetriebe)"
        },
        "08297": {
            "GenartName": "Relais, Schiebedach",
            "NormTermName": "Relais",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Schiebedach"
        },
        "08298": {
            "GenartName": "Dichtungssatz, Einspritzanlage",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzanlage"
        },
        "08299": {
            "GenartName": "Laufring,Getriebeausgangswelle (Schaltgetriebe)",
            "NormTermName": "Laufring",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeausgangswelle (Schaltgetriebe)"
        },
        "08300": {
            "GenartName": "Widerstand, Generator",
            "NormTermName": "Widerstand",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "08301": {
            "GenartName": "Lager, Lenkgetriebe",
            "NormTermName": "Lager",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkgetriebe"
        },
        "08302": {
            "GenartName": "Laufring, Differentialsteckwellenlager",
            "NormTermName": "Laufring",
            "AssGrpName": "Radantrieb",
            "UsageName": "Differentialsteckwellenlager"
        },
        "08303": {
            "GenartName": "Dichtung, Ölpumpenabdeckung",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpenabdeckung"
        },
        "08304": {
            "GenartName": "Hohlrad, Automatikgetriebe",
            "NormTermName": "Hohlrad",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "08305": {
            "GenartName": "Kondensator, Generator",
            "NormTermName": "Kondensator",
            "AssGrpName": "Generator",
            "UsageName": null
        },
        "08306": {
            "GenartName": "Wahlschalter, Kraftstoffbehälter",
            "NormTermName": "Schalter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffbehälter"
        },
        "08307": {
            "GenartName": "Kabelsatz, Klimakompressor",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kompressor"
        },
        "08308": {
            "GenartName": "Relaissockel",
            "NormTermName": "Relaissockel",
            "AssGrpName": "Bordnetz",
            "UsageName": "Relais"
        },
        "08309": {
            "GenartName": "Kohlebürstensatz, Starter",
            "NormTermName": "Kohlebürstensatz",
            "AssGrpName": "Startanlage",
            "UsageName": "Starter"
        },
        "08310": {
            "GenartName": "Sieb, Kraftstoffpumpe",
            "NormTermName": "Sieb",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "08311": {
            "GenartName": "Glühlampe, Anzeige (Feststellbremse)",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Anzeige (Feststellbremse)"
        },
        "08312": {
            "GenartName": "Schalter, Abblendlicht",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Abblendlicht"
        },
        "08313": {
            "GenartName": "Dichtungssatz, Servopumpe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Servopumpe"
        },
        "08314": {
            "GenartName": "Kabelreparatursatz, Zünd-/Startschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Startanlage",
            "UsageName": "Zünd-/Startschalter"
        },
        "08315": {
            "GenartName": "Kipphebelsatz, Motorsteuerung",
            "NormTermName": "Hebelsatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": null
        },
        "08316": {
            "GenartName": "Reparaturhülse, Steuergehäusedeckel",
            "NormTermName": "Reparaturhülse",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuergehäusedeckel"
        },
        "08317": {
            "GenartName": "Kabelreparatursatz, Steuergerät (Zündanlage)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Zündanlage",
            "UsageName": "Steuergerät (Zündanlage)"
        },
        "08318": {
            "GenartName": "Führungsschiene, Stößelstange",
            "NormTermName": "Führungsschiene",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Stößelstange"
        },
        "08319": {
            "GenartName": "Rammschutzpuffer",
            "NormTermName": "Puffer",
            "AssGrpName": "Karosserie",
            "UsageName": "Rammschutz"
        },
        "08320": {
            "GenartName": "Aufstiegsleiter",
            "NormTermName": "Leiter",
            "AssGrpName": "Karosserie",
            "UsageName": "Aufstiegsleiter"
        },
        "08321": {
            "GenartName": "Bandschleifer (Druckluft)",
            "NormTermName": "Bandschleifer (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08322": {
            "GenartName": "Blechknabber (Druckluft)",
            "NormTermName": "Blechknabber (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08323": {
            "GenartName": "Nietsetzgerät (Druckluft)",
            "NormTermName": "Nietsetzgerät (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08324": {
            "GenartName": "Bohrmaschine (Druckluft)",
            "NormTermName": "Bohrmaschine (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08325": {
            "GenartName": "Dreieckschleifer (Druckluft)",
            "NormTermName": "Dreieckschleifer (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08326": {
            "GenartName": "Exzenterschleifer (Druckluft)",
            "NormTermName": "Exzenterschleifer (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08327": {
            "GenartName": "Fettpresse (Druckluft)",
            "NormTermName": "Fettpresse (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08328": {
            "GenartName": "Fettsprühgerät (Druckluft)",
            "NormTermName": "Fettsprühgerät (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08329": {
            "GenartName": "Schlagschrauber (Druckluft)",
            "NormTermName": "Schlagschrauber (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08330": {
            "GenartName": "Schlagschrauber-Satz (Druckluft)",
            "NormTermName": "Schlagschrauber-Satz (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08331": {
            "GenartName": "Schwingschleifer (Druckluft)",
            "NormTermName": "Schwingschleifer (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08332": {
            "GenartName": "Exzenterschleifer-Satz (Druckluft)",
            "NormTermName": "Exzenterschleifer-Satz (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08333": {
            "GenartName": "Kartuschenpresse (Druckluft)",
            "NormTermName": "Kartuschenpresse (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08334": {
            "GenartName": "Oszillierer (Druckluft)",
            "NormTermName": "Oszillierer (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08335": {
            "GenartName": "Multi-Schleifer (Druckluft)",
            "NormTermName": "Multi-Schleifer (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08336": {
            "GenartName": "Nadelentroster (Druckluft)",
            "NormTermName": "Nadelentroster (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08337": {
            "GenartName": "Pistolenschleifer (Druckluft)",
            "NormTermName": "Pistolenschleifer (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08338": {
            "GenartName": "Poliermaschine (Druckluft)",
            "NormTermName": "Poliermaschine (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08339": {
            "GenartName": "Ratschenschrauber (Druckluft)",
            "NormTermName": "Ratschenschrauber (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08340": {
            "GenartName": "Säbelsäge (Druckluft)",
            "NormTermName": "Säbelsäge (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08341": {
            "GenartName": "Saug-/Blaspistole (Druckluft)",
            "NormTermName": "Saug-/Blaspistole (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08342": {
            "GenartName": "Impuls-Schaumkanone (Druckluft)",
            "NormTermName": "Impuls-Schaumkanone (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08343": {
            "GenartName": "Stab-Trennschleifer (Druckluft)",
            "NormTermName": "Stab-Trennschleifer (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08344": {
            "GenartName": "Stichsäge (Druckluft)",
            "NormTermName": "Stichsäge (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08345": {
            "GenartName": "Winkelbohrmaschine (Druckluft)",
            "NormTermName": "Winkelbohrmaschine (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08346": {
            "GenartName": "Winkelschleifer (Druckluft)",
            "NormTermName": "Winkelschleifer (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08347": {
            "GenartName": "Meißelhammer (Druckluft)",
            "NormTermName": "Meißelhammer (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08348": {
            "GenartName": "Meißelhammer-Satz (Druckluft)",
            "NormTermName": "Meißelhammer-Satz (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08349": {
            "GenartName": "Stabschleifer (Druckluft)",
            "NormTermName": "Stabschleifer (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08350": {
            "GenartName": "Bandschleifer (Akku)",
            "NormTermName": "Bandschleifer (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08351": {
            "GenartName": "Blechknabber (Akku)",
            "NormTermName": "Blechknabber (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08352": {
            "GenartName": "Bohrhammer (Akku)",
            "NormTermName": "Bohrhammer (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08353": {
            "GenartName": "Dreieckschleifer (Akku)",
            "NormTermName": "Dreieckschleifer (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08354": {
            "GenartName": "Exzenterschleifer (Akku)",
            "NormTermName": "Exzenterschleifer (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08355": {
            "GenartName": "Fettpresse (Akku)",
            "NormTermName": "Fettpresse (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08356": {
            "GenartName": "Heißklebepistole (Akku)",
            "NormTermName": "Heißklebepistole (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08357": {
            "GenartName": "Kartuschenpresse (Akku)",
            "NormTermName": "Kartuschenpresse (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08358": {
            "GenartName": "Oszillierer (Akku)",
            "NormTermName": "Oszillierer (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08359": {
            "GenartName": "Multi-Schleifer (Akku)",
            "NormTermName": "Multi-Schleifer (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08360": {
            "GenartName": "Pistolenschleifer (Akku)",
            "NormTermName": "Pistolenschleifer (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08361": {
            "GenartName": "Poliermaschine (Akku)",
            "NormTermName": "Poliermaschine (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08362": {
            "GenartName": "Ratschenschrauber (Akku)",
            "NormTermName": "Ratschenschrauber (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08363": {
            "GenartName": "Säbelsäge (Akku)",
            "NormTermName": "Säbelsäge (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08364": {
            "GenartName": "Schlagbohrschrauber (Akku)",
            "NormTermName": "Schlagbohrschrauber (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08365": {
            "GenartName": "Schlagschrauber (Akku)",
            "NormTermName": "Schlagschrauber (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08366": {
            "GenartName": "Schlagschrauber-Satz (Akku)",
            "NormTermName": "Schlagschrauber-Satz (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08367": {
            "GenartName": "Schwingschleifer (Akku)",
            "NormTermName": "Schwingschleifer (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08368": {
            "GenartName": "Stabschleifer (Akku)",
            "NormTermName": "Stabschleifer (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08369": {
            "GenartName": "Stichsäge (Akku)",
            "NormTermName": "Stichsäge (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08370": {
            "GenartName": "Winkelbohrmaschine (Akku)",
            "NormTermName": "Winkelbohrmaschine (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08371": {
            "GenartName": "Winkelschleifer (Akku)",
            "NormTermName": "Winkelschleifer (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08372": {
            "GenartName": "Bandschleifer (Netzgebunden)",
            "NormTermName": "Bandschleifer (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08373": {
            "GenartName": "Blechknabber (Netzgebunden)",
            "NormTermName": "Blechknabber (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08374": {
            "GenartName": "Verteilergetriebe",
            "NormTermName": "Getriebe",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Verteilergetriebe"
        },
        "08375": {
            "GenartName": "Bohrmaschine (Netzgebunden)",
            "NormTermName": "Bohrmaschine (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08376": {
            "GenartName": "Dreieckschleifer (Netzgebunden)",
            "NormTermName": "Dreieckschleifer (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08377": {
            "GenartName": "Exzenterschleifer (Netzgebunden)",
            "NormTermName": "Exzenterschleifer (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08378": {
            "GenartName": "Heißklebepistole (Netzgebunden)",
            "NormTermName": "Heißklebepistole (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08379": {
            "GenartName": "Oszillierer (Netzgebunden)",
            "NormTermName": "Oszillierer (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08380": {
            "GenartName": "Nadelentroster (Netzgebunden)",
            "NormTermName": "Nadelentroster (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08381": {
            "GenartName": "Poliermaschine (Netzgebunden)",
            "NormTermName": "Poliermaschine (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08382": {
            "GenartName": "Säbelsäge (Netzgebunden)",
            "NormTermName": "Säbelsäge (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08383": {
            "GenartName": "Schlagschrauber (Netzgebunden)",
            "NormTermName": "Schlagschrauber (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08384": {
            "GenartName": "Schlagschrauber-Satz (Netzgebunden)",
            "NormTermName": "Schlagschrauber-Satz (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08385": {
            "GenartName": "Stichsäge (Netzgebunden)",
            "NormTermName": "Stichsäge (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08386": {
            "GenartName": "Winkelbohrmaschine (Netzgebunden)",
            "NormTermName": "Winkelbohrmaschine (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08387": {
            "GenartName": "Reduzierrohr, Abgasanlage",
            "NormTermName": "Reduzierrohr",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasanlage"
        },
        "08388": {
            "GenartName": "Bohrhammer (Netzgebunden)",
            "NormTermName": "Bohrhammer (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08389": {
            "GenartName": "Hand-Schlagschraubendreher",
            "NormTermName": "Hand-Schlagschraubendreher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08390": {
            "GenartName": "Hand-Schlagschraubendrehersatz",
            "NormTermName": "Hand-Schlagschraubendrehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08391": {
            "GenartName": "Schwingschleifer (Netzgebunden)",
            "NormTermName": "Schwingschleifer (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08392": {
            "GenartName": "Stabschleifer (Netzgebunden)",
            "NormTermName": "Stabschleifer (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08393": {
            "GenartName": "Winkelschleifer (Netzgebunden)",
            "NormTermName": "Winkelschleifer (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08394": {
            "GenartName": "Bandfeile (Akku)",
            "NormTermName": "Bandfeile (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08395": {
            "GenartName": "Bandfeile (Druckluft)",
            "NormTermName": "Bandfeile (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08396": {
            "GenartName": "Bandfeile (Netzgebunden)",
            "NormTermName": "Bandfeile (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08397": {
            "GenartName": "Hand-Blechschere",
            "NormTermName": "Hand-Blechschere",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08398": {
            "GenartName": "Blechschere (Netzgebunden)",
            "NormTermName": "Blechschere (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08399": {
            "GenartName": "Magnetständer-Kernbohrmaschine (Netzgebunden)",
            "NormTermName": "Magnetständer-Kernbohrmaschine (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08400": {
            "GenartName": "Magnetständer-Kernbohrmaschine (Akku)",
            "NormTermName": "Magnetständer-Kernbohrmaschine (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08401": {
            "GenartName": "Reinigungspistole (Druckluft)",
            "NormTermName": "Reinigungspistole (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08402": {
            "GenartName": "Blechschere (Druckluft)",
            "NormTermName": "Blechschere (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08403": {
            "GenartName": "Blechschere (Akku)",
            "NormTermName": "Blechschere (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08404": {
            "GenartName": "Bohrmaschine (Akku)",
            "NormTermName": "Bohrmaschine (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08405": {
            "GenartName": "Drehschlagschrauber (Akku)",
            "NormTermName": "Drehschlagschrauber (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08406": {
            "GenartName": "Meißelhammer (Akku)",
            "NormTermName": "Meißelhammer (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08407": {
            "GenartName": "Meißelhammer (Netzgebunden)",
            "NormTermName": "Meißelhammer (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08408": {
            "GenartName": "Meißelhammer-Satz (Akku)",
            "NormTermName": "Meißelhammer-Satz (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08409": {
            "GenartName": "Meißelhammer-Satz (Netzgebunden)",
            "NormTermName": "Meißelhammer-Satz (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08410": {
            "GenartName": "Nietsetzgerät (Akku)",
            "NormTermName": "Nietsetzgerät (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08411": {
            "GenartName": "Nietsetzgerät (Netzgebunden)",
            "NormTermName": "Nietsetzgerät (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08412": {
            "GenartName": "Hauptscheinwerfermodul",
            "NormTermName": "Hauptscheinwerfermodul",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "08413": {
            "GenartName": "Lüfter, Hauptscheinwerfer",
            "NormTermName": "Lüfter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "08414": {
            "GenartName": "Steuergerät, Hauptscheinwerfer",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer"
        },
        "08415": {
            "GenartName": "Ringöse, Kraftstoff-Rücklaufmengenmessung",
            "NormTermName": "Ringöse",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kraftstoff-Rücklaufmengenmessung"
        },
        "08416": {
            "GenartName": "Stützring, Einspritzdüsenausziehersatz",
            "NormTermName": "Stützring",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzdüsenausziehersatz"
        },
        "08417": {
            "GenartName": "Testpapier, Prüfgerät (Harnstoffeinspritzung)",
            "NormTermName": "Testpapier",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Prüfgerät (Harnstoffeinspritzung)"
        },
        "08418": {
            "GenartName": "Kalibriergewicht, Prüfgerät (Harnstoffeinspritzung)",
            "NormTermName": "Gewicht",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Prüfgerät (Harnstoffeinspritzung)"
        },
        "08419": {
            "GenartName": "Halteseil, Prüfgerät (Harnstoffeinspritzung)",
            "NormTermName": "Halteseil",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Prüfgerät (Harnstoffeinspritzung)"
        },
        "08420": {
            "GenartName": "Monteurhilfe, Klimaservicegerät",
            "NormTermName": "Monteurhilfe, Klimaservicegerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08421": {
            "GenartName": "Monteurhilfe-Set, Klimaservicegerät",
            "NormTermName": "Monteurhilfe-Set, Klimaservicegerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08422": {
            "GenartName": "Auslaufhahn",
            "NormTermName": "Auslaufhahn",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08423": {
            "GenartName": "Kugelauslaufhahn",
            "NormTermName": "Kugelauslaufhahn",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08424": {
            "GenartName": "Handhebelfettpresse",
            "NormTermName": "Handhebelfettpresse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08425": {
            "GenartName": "Spülsystem, Einspritzanlage",
            "NormTermName": "Spülsystem",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Einspritzanlage"
        },
        "08426": {
            "GenartName": "Hydraulikpumpe, Nebenantrieb",
            "NormTermName": "Pumpe",
            "AssGrpName": "Nebenantrieb",
            "UsageName": "Nebenantrieb"
        },
        "08427": {
            "GenartName": "Nebenantrieb",
            "NormTermName": "Nebenantrieb",
            "AssGrpName": "Nebenantrieb",
            "UsageName": null
        },
        "08428": {
            "GenartName": "Steuergerät, Retarder",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "08429": {
            "GenartName": "Kabelreparatursatz, Kofferraumdeckel",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz",
            "UsageName": "Kofferraumdeckel"
        },
        "08430": {
            "GenartName": "Kabelreparatursatz, Tür",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz",
            "UsageName": "Tür"
        },
        "08431": {
            "GenartName": "Kabelreparatursatz, Heckklappe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz",
            "UsageName": "Heckklappe"
        },
        "08432": {
            "GenartName": "Schwingungsdämpfer, Kurbelwelle",
            "NormTermName": "Dämpfer",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwelle"
        },
        "08433": {
            "GenartName": "Generalschlüsselsatz, Anhängekupplung",
            "NormTermName": "Schlüsselsatz",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplung"
        },
        "08434": {
            "GenartName": "Programmiergerät, Elektrosatz (Anhängevorrichtung)",
            "NormTermName": "Programmiergerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Elektrosatz (Anhängevorrichtung)"
        },
        "08435": {
            "GenartName": "Druckstück, Ventilfeder-Montagewerkzeug",
            "NormTermName": "Druckstück",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ventilfeder-Montagewerkzeug"
        },
        "08436": {
            "GenartName": "Spindel, Abzieher",
            "NormTermName": "Spindel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Abzieher"
        },
        "08437": {
            "GenartName": "Druckluftadapter, Zünd-/Glühkerzenbohrung",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zünd-/Glühkerzenbohrung"
        },
        "08438": {
            "GenartName": "Ventilsatz, Reifendruck-Kontrollsystem",
            "NormTermName": "Ventilsatz",
            "AssGrpName": "Räder",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "08439": {
            "GenartName": "Reparaturhülse, Nockenwelle",
            "NormTermName": "Reparaturhülse",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwelle"
        },
        "08440": {
            "GenartName": "Arretierdorn, Ausgleichswelle",
            "NormTermName": "Arretierdorn",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Ausgleichswelle"
        },
        "08441": {
            "GenartName": "Trenn-/Montagegabel",
            "NormTermName": "Trenn-/Montagegabel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08442": {
            "GenartName": "Adapter, Schlagabzieher",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Schlagabzieher"
        },
        "08443": {
            "GenartName": "Montagewerkzeug, Ventilfeder",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ventilfeder"
        },
        "08444": {
            "GenartName": "Erweiterungssatz, Arretierwerkzeug (Steuerzeiten)",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Arretierwerkzeug (Steuerzeiten)"
        },
        "08445": {
            "GenartName": "Durchdrehschlüssel, Riemenscheibe",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Riemenscheibe"
        },
        "08446": {
            "GenartName": "Haltewerkzeugsatz, Riemenscheibe",
            "NormTermName": "Haltewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Riemenscheibe"
        },
        "08447": {
            "GenartName": "Abziehersatz, Nockenwellenzahnrad",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zahnrad-Nockenwelle"
        },
        "08448": {
            "GenartName": "Stirnlochschlüssel",
            "NormTermName": "Stirnlochschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08449": {
            "GenartName": "Befülladapter, Getriebe",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Getriebe"
        },
        "08450": {
            "GenartName": "Massekabel, Zündverteiler",
            "NormTermName": "Massekabel",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteiler"
        },
        "08451": {
            "GenartName": "Kondensator, Radio",
            "NormTermName": "Kondensator",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Radio"
        },
        "08452": {
            "GenartName": "Befestigungssatz, Blattfeder",
            "NormTermName": "Befestigungssatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Blattfeder"
        },
        "08453": {
            "GenartName": "Kraft-Stecknuss-Satz, Spurverbreiterung",
            "NormTermName": "Kraft-Stecknuss-Satz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Spurverbreiterung"
        },
        "08454": {
            "GenartName": "Ventilkernsatz, Serviceventil",
            "NormTermName": "Ventilkernsatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Serviceventil"
        },
        "08455": {
            "GenartName": "Innenraumreiniger, Ultraschallvernebler",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Ultraschallvernebler"
        },
        "08456": {
            "GenartName": "Reiniger, Lecksuchadditive",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Lecksuchadditive"
        },
        "08457": {
            "GenartName": "Lecksuchspray",
            "NormTermName": "Lecksuchspray",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "08458": {
            "GenartName": "Kühlerschlauchsatz",
            "NormTermName": "Schlauchleitungssatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühler"
        },
        "08459": {
            "GenartName": "Stellelement, Turbolader",
            "NormTermName": "Stellelement",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Turbolader"
        },
        "08460": {
            "GenartName": "Gegenhalteschlüssel, Lüfter (Flüssigkeitsreibungskupplung)",
            "NormTermName": "Gegenhalteschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Lüfter (Flüssigkeitsreibungskupplung)"
        },
        "08461": {
            "GenartName": "Schweißerschutzwand",
            "NormTermName": "Schweißerschutzwand",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08462": {
            "GenartName": "Schweißerhaube",
            "NormTermName": "Schweißerhaube",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08463": {
            "GenartName": "Freisicht-Handschild",
            "NormTermName": "Freisicht-Handschild",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08464": {
            "GenartName": "Füllschlauch-Set, Klimaservicegerät",
            "NormTermName": "Füllschlauch-Set",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimaservicegerät"
        },
        "08465": {
            "GenartName": "Ventil, Hydraulikaggregat-Autom.Getr.",
            "NormTermName": "Ventil",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Hydraulikaggregat-Autom.Getr."
        },
        "08466": {
            "GenartName": "Ölkühlerleitung, Automatikgetriebe",
            "NormTermName": "Ölkühlerleitung",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "08467": {
            "GenartName": "Dichtung, Klimakompressor",
            "NormTermName": "Dichtung",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Klimakompressor"
        },
        "08468": {
            "GenartName": "Adapter, Federspanner",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federspanner"
        },
        "08469": {
            "GenartName": "Federvorspannwerkzeug, Fahrwerksfeder",
            "NormTermName": "Federvorspannwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Fahrwerksfeder"
        },
        "08470": {
            "GenartName": "Erweiterungssatz, Federvorspannwerkzeug",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federvorspannwerkzeug"
        },
        "08471": {
            "GenartName": "Erweiterungssatz, Aufspreizer (Trag-/Führungsgelenkaufnahme)",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Aufspreizer (Trag-/Führungsgelenkaufnahme)"
        },
        "08472": {
            "GenartName": "Aufspreizer, Trag-/Führungsgelenkaufnahme",
            "NormTermName": "Aufspreizer",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Trag-/Führungsgelenkaufnahme"
        },
        "08473": {
            "GenartName": "Druckschraube, Aufspreizer (Trag-/Führungsgelenkaufnahme)",
            "NormTermName": "Druckschraube",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Aufspreizer (Trag-/Führungsgelenkaufnahme)"
        },
        "08474": {
            "GenartName": "Niederhalter, Stoßdämpfer",
            "NormTermName": "Niederhalter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Stoßdämpfer"
        },
        "08475": {
            "GenartName": "Adapterring, Druckplatte-Federspanner",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Druckplatte-Federspanner"
        },
        "08476": {
            "GenartName": "Spannbock, Federbein",
            "NormTermName": "Spannbock",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federbein"
        },
        "08477": {
            "GenartName": "Werkzeugsatz, Stoßdämpfer-Kolbenstange",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Stoßdämpfer-Kolbenstange"
        },
        "08478": {
            "GenartName": "Steckschlüsseleinsatz, Stoßdämpfer-Kolbenstange",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Stoßdämpfer-Kolbenstange"
        },
        "08479": {
            "GenartName": "Steckschlüsseleinsatz, Stoßdämpfer-Kolbenstange",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Stoßdämpfer-Kolbenstange"
        },
        "08480": {
            "GenartName": "Sortiment, Kabelbinder",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Kabelbinder"
        },
        "08481": {
            "GenartName": "Lampenschrank",
            "NormTermName": "Schrank",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Glühlampen"
        },
        "08482": {
            "GenartName": "Kabelreparatursatz, Drallklappenstellelement (Saugrohr)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Drallklappen (Saugrohr)"
        },
        "08483": {
            "GenartName": "Kabelreparatursatz, Druckregelventil (Common-Rail-System)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Druckregelventil (Common-Rail-System)"
        },
        "08484": {
            "GenartName": "Strümpfe",
            "NormTermName": "Strümpfe",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08485": {
            "GenartName": "Unterhemd",
            "NormTermName": "Unterhemd",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08486": {
            "GenartName": "Aufbewahrung, Gehörschutz",
            "NormTermName": "Aufbewahrung",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Gehörschutz"
        },
        "08487": {
            "GenartName": "Atemschutzsystem",
            "NormTermName": "Atemschutzsystem",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08488": {
            "GenartName": "Aufbewahrung, Löschdecke",
            "NormTermName": "Aufbewahrung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Löschdecke"
        },
        "08489": {
            "GenartName": "Nachfüllset, Verbandkasten",
            "NormTermName": "Nachfüllset",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Verbandkasten"
        },
        "08490": {
            "GenartName": "Verbandtuch",
            "NormTermName": "Verbandtuch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08491": {
            "GenartName": "Logistikbox",
            "NormTermName": "Logistikbox",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08492": {
            "GenartName": "Gabelhubwagen",
            "NormTermName": "Gabelhubwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08493": {
            "GenartName": "Plantafel",
            "NormTermName": "Plantafel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08494": {
            "GenartName": "Klemmbrett",
            "NormTermName": "Klemmbrett",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08495": {
            "GenartName": "Schlüsselkasten",
            "NormTermName": "Schlüsselkasten",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08496": {
            "GenartName": "Bewegungsmelder",
            "NormTermName": "Bewegungsmelder",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08497": {
            "GenartName": "Rauchwarnmelder",
            "NormTermName": "Rauchwarnmelder",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08498": {
            "GenartName": "Schreibtisch",
            "NormTermName": "Schreibtisch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08499": {
            "GenartName": "Überwachungsspiegel",
            "NormTermName": "Überwachungsspiegel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08500": {
            "GenartName": "Starter, Leuchtstoffröhre",
            "NormTermName": "Starter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Leuchtstoffröhre"
        },
        "08501": {
            "GenartName": "Leuchtmittel",
            "NormTermName": "Leuchtmittel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08502": {
            "GenartName": "Außenstrahler",
            "NormTermName": "Außenstrahler",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08503": {
            "GenartName": "Werkstatttor",
            "NormTermName": "Werkstatttor",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08504": {
            "GenartName": "Klimagerät",
            "NormTermName": "Klimagerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08505": {
            "GenartName": "Tastatur",
            "NormTermName": "Tastatur",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08506": {
            "GenartName": "Telefon",
            "NormTermName": "Telefon",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08507": {
            "GenartName": "Walzendreher, Glühkerze",
            "NormTermName": "Walzendreher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Glühkerze"
        },
        "08508": {
            "GenartName": "Montagewerkzeug, Wellendichtring (Getriebe)",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Wellendichtring (Getriebe)"
        },
        "08509": {
            "GenartName": "Auszieher, Getriebelager",
            "NormTermName": "Auszieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Getriebelager"
        },
        "08510": {
            "GenartName": "Montagewerkzeugsatz, Getriebelager",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Getriebelager"
        },
        "08511": {
            "GenartName": "Reinigungs-/Fräswerkzeug, Vorkammer-Injektorschacht",
            "NormTermName": "Reinigungs-/Fräswerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Vorkammer-Injektorschacht"
        },
        "08512": {
            "GenartName": "Adapter, Druckplatte-Federspanner",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Druckplatte-Federspanner"
        },
        "08513": {
            "GenartName": "Druckstempelsatz, Ausdrücker-Querlenkerklemmschraube",
            "NormTermName": "Druckstempelsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ausdrücker-Querlenkerklemmschraube"
        },
        "08514": {
            "GenartName": "Druckstempel, Ausdrücker-Querlenkerklemmschraube",
            "NormTermName": "Druckstempel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ausdrücker-Querlenkerklemmschraube"
        },
        "08515": {
            "GenartName": "Zugschlüssel, Druckstück-Querlenkerklemmschraubenausdrücker",
            "NormTermName": "Zugschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ausdrücker-Querlenkerklemmschraube"
        },
        "08516": {
            "GenartName": "Ausschlagwerkzeug, Gelenkzapfen (Querlenkerstrebe)",
            "NormTermName": "Ausschlagwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Gelenkzapfen (Querlenkerstrebe)"
        },
        "08517": {
            "GenartName": "Grundwerkzeug, Kugelgelenkabdrücker",
            "NormTermName": "Grundwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kugelgelenkabdrücker"
        },
        "08518": {
            "GenartName": "Gabel, Kugelgelenkabdrücker",
            "NormTermName": "Gabel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kugelgelenkabdrücker"
        },
        "08519": {
            "GenartName": "Verlängerung, Kugelgelenkabdrücker",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kugelgelenkabdrücker"
        },
        "08520": {
            "GenartName": "Gabel, Kugelgelenkabdrücker",
            "NormTermName": "Gabel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kugelgelenkabdrücker"
        },
        "08521": {
            "GenartName": "Bügelhaken, Schlagabzieher",
            "NormTermName": "Haken",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagabzieher"
        },
        "08522": {
            "GenartName": "Adapter, Spurstangenverstellzange",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Spurstangenverstellzange"
        },
        "08523": {
            "GenartName": "Arretierwerkzeug, Zahnstange (Lenkung)",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zahnstange (Lenkung)"
        },
        "08524": {
            "GenartName": "Aufschlagdorn, Lenkrad-Distanzhülse",
            "NormTermName": "Aufschlagdorn",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Lenkrad-Distanzhülse"
        },
        "08525": {
            "GenartName": "Zapfenschlüssel, Traggelenk",
            "NormTermName": "Zapfenschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Traggelenk"
        },
        "08526": {
            "GenartName": "Druck-/Stützhülsensatz, Ein-/Auspresswerkzeugsatz",
            "NormTermName": "Druck-/Stützhülsensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ein-/Auspresswerkzeug"
        },
        "08527": {
            "GenartName": "Ausbohrwerkzeugsatz, Querlenkerklemmschraube",
            "NormTermName": "Ausbohrwerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Querlenkerklemmschraube"
        },
        "08528": {
            "GenartName": "Säureneutralisationsmittel",
            "NormTermName": "Säureneutralisationsmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "08529": {
            "GenartName": "Stützbolzensatz, Düsenauszieher",
            "NormTermName": "Stützbolzensatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Düsenauszieher"
        },
        "08530": {
            "GenartName": "Füll-/Entnahmepumpe, Kraftstoff",
            "NormTermName": "Pumpe",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kraftstoff"
        },
        "08531": {
            "GenartName": "Steckschlüsseleinsatz, Glühkerze",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerze"
        },
        "08532": {
            "GenartName": "Haltewerkzeug, Riemenscheibe",
            "NormTermName": "Haltewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Riemenscheibe"
        },
        "08533": {
            "GenartName": "Ölfilterschlüsselsatz",
            "NormTermName": "Ölfilterschlüsselsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08534": {
            "GenartName": "Kabelreparatursatz, Luftfederungsniveausensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Luftfederungsniveausensor"
        },
        "08535": {
            "GenartName": "Kabelreparatursatz, Luftfederungsniveausensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Federung/Dämpfung, Universal",
            "UsageName": "Luftfederungsniveausensor"
        },
        "08536": {
            "GenartName": "Kabelreparatursatz, Innenraumgebläseschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumgebläseschalter"
        },
        "08537": {
            "GenartName": "Kabelreparatursatz, Innenraumgebläseschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung, Universal",
            "UsageName": "Innenraumgebläseschalter"
        },
        "08538": {
            "GenartName": "Gegenhalter, Stoßdämpfer-Kolbenstange",
            "NormTermName": "Gegenhalter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Stoßdämpfer-Kolbenstange"
        },
        "08539": {
            "GenartName": "Zapfenschlüsselsatz, Stoßdämpfer-Kolbenstange",
            "NormTermName": "Zapfenschlüsselsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Stoßdämpfer-Kolbenstange"
        },
        "08540": {
            "GenartName": "Zapfenschlüssel, Stoßdämpfer-Kolbenstange",
            "NormTermName": "Zapfenschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Stoßdämpfer-Kolbenstange"
        },
        "08541": {
            "GenartName": "Zugschlüssel, Stoßdämpfer-Kolbenstange",
            "NormTermName": "Zugschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Stoßdämpfer-Kolbenstange"
        },
        "08542": {
            "GenartName": "Domlager-Steckschlüssel",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Domlager"
        },
        "08543": {
            "GenartName": "Spannzylinder, Federspanner",
            "NormTermName": "Spannzylinder",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federspanner"
        },
        "08544": {
            "GenartName": "Steckschlüssel, Stoßdämpfer-Patronenbefestigung",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Stoßdämpfer-Patronenbefestigung"
        },
        "08545": {
            "GenartName": "Einstellplatte, Spann- und Einstellvorrichtung (Federbein)",
            "NormTermName": "Einstellplatte",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Spann- und Einstellvorrichtung (Federbein)"
        },
        "08546": {
            "GenartName": "Erweiterungssatz, Spann- und Einstellvorrichtung (Federbein)",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Spann- und Einstellvorrichtung (Federbein)"
        },
        "08547": {
            "GenartName": "Zentrierringsatz, Montagewerkzeug Radnabe/Radlager",
            "NormTermName": "Zentrierringsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Radnabe/Radlager"
        },
        "08548": {
            "GenartName": "Zentrierring, Montagewerkzeug Radnabe/Radlager",
            "NormTermName": "Zentrierring",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug Radnabe/Radlager"
        },
        "08549": {
            "GenartName": "Antriebswellendruckstück",
            "NormTermName": "Antriebswellendruckstück",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08550": {
            "GenartName": "Streuwagen",
            "NormTermName": "Streuwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08551": {
            "GenartName": "Abdeckung, Streuwagen",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Streuwagen"
        },
        "08552": {
            "GenartName": "Abfalleimer",
            "NormTermName": "Abfalleimer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08553": {
            "GenartName": "Müllbeutel",
            "NormTermName": "Müllbeutel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08554": {
            "GenartName": "Absperrkette",
            "NormTermName": "Absperrkette",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08555": {
            "GenartName": "Kühler, Elektromotor",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Elektromotor"
        },
        "08557": {
            "GenartName": "Wärmeerzeuger, Antriebsbatterie",
            "NormTermName": "Wärmeerzeuger",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Antriebsbatterie"
        },
        "08558": {
            "GenartName": "Antriebsbatterie",
            "NormTermName": "Batterie",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Hybrid-/Elektroantrieb"
        },
        "08559": {
            "GenartName": "Wärmeerzeuger, Innenraumheizung",
            "NormTermName": "Wärmeerzeuger",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Innenraumheizung"
        },
        "08560": {
            "GenartName": "Kühler, Wasserstoff",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Wasserstoff"
        },
        "08561": {
            "GenartName": "Öl, Doppelkupplungsgetriebe (DSG)",
            "NormTermName": "Öl",
            "AssGrpName": "Doppelkupplungsgetriebe (DSG)",
            "UsageName": "Doppelkupplungsgetriebe (DSG)"
        },
        "08562": {
            "GenartName": "Öl, Stufenlosgetriebe (CVT)",
            "NormTermName": "Öl",
            "AssGrpName": "Stufenlosgetriebe (CVT)",
            "UsageName": "Stufenlosgetriebe (CVT)"
        },
        "08563": {
            "GenartName": "Öl, Hybridgetriebe (DHT)",
            "NormTermName": "Öl",
            "AssGrpName": "Hybridgetriebe (DHT)",
            "UsageName": "Hybridgetriebe (DHT)"
        },
        "08564": {
            "GenartName": "Akkubesen",
            "NormTermName": "Akkubesen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08565": {
            "GenartName": "Akku-Fensterreiniger",
            "NormTermName": "Akku-Fensterreiniger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08566": {
            "GenartName": "Handkreissäge (Akku)",
            "NormTermName": "Handkreissäge (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08567": {
            "GenartName": "Doppel-Schlauchklemme (ASS-Klemme)",
            "NormTermName": "Doppel-Schlauchklemme (ASS-Klemme)",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08568": {
            "GenartName": "Aufbewahrungsbox, Drehmomentschlüssel",
            "NormTermName": "Aufbewahrungsbox",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "08569": {
            "GenartName": "Nockenwellenverstellersatz",
            "NormTermName": "Nockenwellenverstellersatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": null
        },
        "08570": {
            "GenartName": "Steuergehäusedeckel",
            "NormTermName": "Deckel",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuergehäuse"
        },
        "08571": {
            "GenartName": "Handtrockner",
            "NormTermName": "Handtrockner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08572": {
            "GenartName": "Handtuchrolle",
            "NormTermName": "Handtuchrolle",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08573": {
            "GenartName": "Harnstoff-Befüllgerät",
            "NormTermName": "Füllgerät",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Harnstoff"
        },
        "08574": {
            "GenartName": "Flachkrampe",
            "NormTermName": "Flachkrampe",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08575": {
            "GenartName": "Blindnietmutter",
            "NormTermName": "Blindnietmutter",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08576": {
            "GenartName": "Chemieschrank",
            "NormTermName": "Chemieschrank",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08577": {
            "GenartName": "Ersatzbeutel, Sandstrahlpistole",
            "NormTermName": "Ersatzbeutel",
            "AssGrpName": "Verarbeitungsgeräte",
            "UsageName": "Sandstrahlpistole"
        },
        "08578": {
            "GenartName": "Montagesatz, Haltefeder-Ausrücklager",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Haltefeder-Ausrücklager"
        },
        "08579": {
            "GenartName": "Abfallgreifer",
            "NormTermName": "Abfallgreifer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08580": {
            "GenartName": "Achstraverse, Rangierheber",
            "NormTermName": "Achstraverse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Rangierheber"
        },
        "08581": {
            "GenartName": "Verbandbuch",
            "NormTermName": "Verbandbuch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08582": {
            "GenartName": "Gliederkette",
            "NormTermName": "Gliederkette",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08583": {
            "GenartName": "Hausnummer",
            "NormTermName": "Hausnummer",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08584": {
            "GenartName": "Heckenschere",
            "NormTermName": "Heckenschere",
            "AssGrpName": "Gartenwerkzeuge",
            "UsageName": null
        },
        "08585": {
            "GenartName": "Kappe, Nummernschildschraube",
            "NormTermName": "Kappe",
            "AssGrpName": "Normteile",
            "UsageName": "Nummernschildschraube"
        },
        "08586": {
            "GenartName": "Kausche",
            "NormTermName": "Kausche",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08587": {
            "GenartName": "Kleiderbügel",
            "NormTermName": "Kleiderbügel",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08588": {
            "GenartName": "Werkstattauftrag",
            "NormTermName": "Werkstattauftrag",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08589": {
            "GenartName": "Werbeschild",
            "NormTermName": "Werbeschild",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08590": {
            "GenartName": "Verkaufsschild",
            "NormTermName": "Verkaufsschild",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08591": {
            "GenartName": "Toilettenpapier",
            "NormTermName": "Toilettenpapier",
            "AssGrpName": "Hygieneartikel",
            "UsageName": null
        },
        "08592": {
            "GenartName": "Toilettenreiniger",
            "NormTermName": "Toilettenreiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "08593": {
            "GenartName": "Tankhandschuh-Spender",
            "NormTermName": "Tankhandschuh-Spender",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08594": {
            "GenartName": "Streusalz",
            "NormTermName": "Streusalz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "08595": {
            "GenartName": "Streusplitt",
            "NormTermName": "Streusplitt",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "08596": {
            "GenartName": "Absperrpfosten",
            "NormTermName": "Absperrpfosten",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08597": {
            "GenartName": "Kabelreparatursatz, Drallklappenstellelement (Saugrohr)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung, Universal",
            "UsageName": "Drallklappen (Saugrohr)"
        },
        "08598": {
            "GenartName": "Kabelreparatursatz, Druckregelventil (Common-Rail-System)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Druckregelventil (Common-Rail-System)"
        },
        "08599": {
            "GenartName": "Zierschraube",
            "NormTermName": "Zierschraube",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08600": {
            "GenartName": "Würgeklemme",
            "NormTermName": "Würgeklemme",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08601": {
            "GenartName": "Wellenmutter",
            "NormTermName": "Wellenmutter",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08602": {
            "GenartName": "Spannschloss",
            "NormTermName": "Spannschloss",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08603": {
            "GenartName": "Seilendverschluss",
            "NormTermName": "Seilendverschluss",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08604": {
            "GenartName": "Schiebestück",
            "NormTermName": "Schiebestück",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08605": {
            "GenartName": "Nutring",
            "NormTermName": "Nutring",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08606": {
            "GenartName": "Nilosring",
            "NormTermName": "Nilosring",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08607": {
            "GenartName": "Nietunterlage",
            "NormTermName": "Nietunterlage",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08608": {
            "GenartName": "Nasenscheibe",
            "NormTermName": "Nasenscheibe",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08609": {
            "GenartName": "Drahtseilklemme",
            "NormTermName": "Drahtseilklemme",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08610": {
            "GenartName": "Drahthaken",
            "NormTermName": "Drahthaken",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "08611": {
            "GenartName": "Rettungsdecke",
            "NormTermName": "Rettungsdecke",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08612": {
            "GenartName": "Kabelreparatursatz, Bremsleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Bremsleuchte"
        },
        "08613": {
            "GenartName": "Gamaschen",
            "NormTermName": "Gamaschen",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08614": {
            "GenartName": "Saugnapf, Verkaufsschild",
            "NormTermName": "Saugnapf",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08615": {
            "GenartName": "Kabelreparatursatz, Kofferraumleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Kofferraumleuchte"
        },
        "08616": {
            "GenartName": "Andrückrolle",
            "NormTermName": "Andrückrolle",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08617": {
            "GenartName": "Anroller",
            "NormTermName": "Anroller",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08618": {
            "GenartName": "Aufbewahrungsbox, Schlüsselanhänger",
            "NormTermName": "Aufbewahrungsbox",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schlüsselanhänger"
        },
        "08619": {
            "GenartName": "Autogenschlauch",
            "NormTermName": "Autogenschlauch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08620": {
            "GenartName": "Netzteil, Notebook",
            "NormTermName": "Netzteil",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Notebook"
        },
        "08621": {
            "GenartName": "Glasreparaturbohrer",
            "NormTermName": "Glasreparaturbohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08622": {
            "GenartName": "Drahtseil, Seilwinde",
            "NormTermName": "Drahtseil",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Seilwinde"
        },
        "08623": {
            "GenartName": "Bohrstaubfänger",
            "NormTermName": "Bohrstaubfänger",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08624": {
            "GenartName": "Eimeraufsteller",
            "NormTermName": "Eimeraufsteller",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08625": {
            "GenartName": "Dichtung, Druckminderer-Manometer",
            "NormTermName": "Dichtung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08626": {
            "GenartName": "Toilettenpapierspender",
            "NormTermName": "Toilettenpapierspender",
            "AssGrpName": "Hygieneartikel",
            "UsageName": null
        },
        "08627": {
            "GenartName": "Feuchtigkeitsmesser",
            "NormTermName": "Feuchtigkeitsmesser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08628": {
            "GenartName": "Folienreiniger",
            "NormTermName": "Folienreiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "08629": {
            "GenartName": "Füllventil, Reifenfüllmesser",
            "NormTermName": "Füllventil",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08630": {
            "GenartName": "Hohlmesser",
            "NormTermName": "Hohlmesser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08631": {
            "GenartName": "Kleinschleifmop",
            "NormTermName": "Kleinschleifmop",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08632": {
            "GenartName": "Kontrollspiegel, Glasreparatur",
            "NormTermName": "Kontrollspiegel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08633": {
            "GenartName": "Konturscheibe",
            "NormTermName": "Konturscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08634": {
            "GenartName": "Kreidehalter",
            "NormTermName": "Kreidehalter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08635": {
            "GenartName": "Lackfräse",
            "NormTermName": "Lackfräse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08636": {
            "GenartName": "Lötfett",
            "NormTermName": "Lötfett",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "08637": {
            "GenartName": "Lötwasser",
            "NormTermName": "Lötwasser",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "08638": {
            "GenartName": "Lötwasserpinsel",
            "NormTermName": "Lötwasserpinsel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08639": {
            "GenartName": "Nagellochreparaturkörper",
            "NormTermName": "Nagellochreparaturkörper",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08640": {
            "GenartName": "Pinselkopf",
            "NormTermName": "Pinselkopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08641": {
            "GenartName": "Reifenprofilschneider",
            "NormTermName": "Reifenprofilschneider",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08642": {
            "GenartName": "Reparatursatz, Gewinde",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08643": {
            "GenartName": "Salmiaksteine",
            "NormTermName": "Salmiaksteine",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08644": {
            "GenartName": "Schleifrolle",
            "NormTermName": "Schleifrolle",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08645": {
            "GenartName": "Schnurläufer",
            "NormTermName": "Schnurläufer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08646": {
            "GenartName": "Spannfutter, Gewindebohrer",
            "NormTermName": "Spannfutter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08647": {
            "GenartName": "Sprühkopf, Nachfüllflasche",
            "NormTermName": "Sprühkopf",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08648": {
            "GenartName": "Sprühsonde, Hohlraumversiegelung",
            "NormTermName": "Sprühsonde",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08649": {
            "GenartName": "Sprühsonde, Klimareinigungspistole",
            "NormTermName": "Sprühsonde",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08650": {
            "GenartName": "Stabilisator, Endoskopsonde",
            "NormTermName": "Stabilisator",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08651": {
            "GenartName": "Statikmischer",
            "NormTermName": "Statikmischer",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "08652": {
            "GenartName": "Stecknippel",
            "NormTermName": "Stecknippel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08653": {
            "GenartName": "Stiftrauer",
            "NormTermName": "Stiftrauer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08654": {
            "GenartName": "Tackerklammern",
            "NormTermName": "Tackerklammern",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08655": {
            "GenartName": "Tischbohrmaschine",
            "NormTermName": "Tischbohrmaschine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08656": {
            "GenartName": "Federvorspannwerkzeug, Gabelfedern",
            "NormTermName": "Federvorspannwerkzeug",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "08657": {
            "GenartName": "Vliesrolle, Schleiftechnik",
            "NormTermName": "Vliesrolle",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08658": {
            "GenartName": "Vorsprühgerät",
            "NormTermName": "Vorsprühgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08659": {
            "GenartName": "Zubehörsatz, Stabschleifer",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08660": {
            "GenartName": "Kronenbohrer",
            "NormTermName": "Kronenbohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08661": {
            "GenartName": "Absauggerät, Kraftstoffdämpfe",
            "NormTermName": "Absauggerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08662": {
            "GenartName": "Adapter, Fasscontainer",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08663": {
            "GenartName": "Betankungssystem",
            "NormTermName": "Betankungssystem",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08664": {
            "GenartName": "Beutel, Trockensauger",
            "NormTermName": "Beutel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08665": {
            "GenartName": "Druckgasdose, AU-Testgerät",
            "NormTermName": "Druckgasdose",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "08666": {
            "GenartName": "Dokumentenrolle",
            "NormTermName": "Dokumentenrolle",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08667": {
            "GenartName": "Indexkarte, Plantafel",
            "NormTermName": "Indexkarte, Plantafel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Plantafel"
        },
        "08668": {
            "GenartName": "Leitzahl-Spiegelanhänger",
            "NormTermName": "Leitzahl-Spiegelanhänger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08669": {
            "GenartName": "Leitzahlträger-Dachaufsteller",
            "NormTermName": "Leitzahlträger-Dachaufsteller",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08670": {
            "GenartName": "Monitor",
            "NormTermName": "Monitor",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08671": {
            "GenartName": "Wischbezug",
            "NormTermName": "Wischbezug",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08672": {
            "GenartName": "Bodenwischer",
            "NormTermName": "Bodenwischer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08673": {
            "GenartName": "Ersatzradwinde",
            "NormTermName": "Ersatzradwinde",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08674": {
            "GenartName": "Notebook",
            "NormTermName": "Notebook",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08675": {
            "GenartName": "Fahrwagen, Spannstütze",
            "NormTermName": "Fahrwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Spannstütze"
        },
        "08676": {
            "GenartName": "Filter, Nass-/Trockensauger",
            "NormTermName": "Filter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Nass-/Trockensauger"
        },
        "08677": {
            "GenartName": "Preisblatthalter",
            "NormTermName": "Preisblatthalter",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08678": {
            "GenartName": "Putzkissen",
            "NormTermName": "Putzkissen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08679": {
            "GenartName": "Putzlappen",
            "NormTermName": "Putzlappen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08680": {
            "GenartName": "Ölwechsel-Anhänger",
            "NormTermName": "Ölwechsel-Anhänger",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08681": {
            "GenartName": "Einlagerungsformular, Räder/Reifen",
            "NormTermName": "Einlagerungsformular, Räder/Reifen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08682": {
            "GenartName": "Ventilanschluss, Kältemittelflasche",
            "NormTermName": "Ventilanschluss",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08683": {
            "GenartName": "Reifenaufkleber",
            "NormTermName": "Reifenaufkleber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08684": {
            "GenartName": "Reifenlabel",
            "NormTermName": "Reifenlabel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08685": {
            "GenartName": "Reifenlabelstempel",
            "NormTermName": "Reifenlabelstempel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08686": {
            "GenartName": "Wandhalterung, Gasflaschen",
            "NormTermName": "Wandhalterung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08687": {
            "GenartName": "Flaschenzug",
            "NormTermName": "Flaschenzug",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08688": {
            "GenartName": "Funkenschutzglas, Doppel-Schleifmaschine",
            "NormTermName": "Funkenschutzglas",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08689": {
            "GenartName": "Wandhalterung, Schlauchleitung",
            "NormTermName": "Wandhalterung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08690": {
            "GenartName": "Schlüsselanhänger-Set",
            "NormTermName": "Schlüsselanhänger-Set",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08691": {
            "GenartName": "Schutzkappe, Manometer",
            "NormTermName": "Schutzkappe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08692": {
            "GenartName": "Handdesinfektionsmittel-Spender",
            "NormTermName": "Handdesinfektionsmittel-Spender",
            "AssGrpName": "Hygieneartikel",
            "UsageName": null
        },
        "08693": {
            "GenartName": "Handtuchspender",
            "NormTermName": "Handtuchspender",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08694": {
            "GenartName": "Pressdornsatz, Werkstattpresse",
            "NormTermName": "Pressdornsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08695": {
            "GenartName": "Telefon-/PDA-Halter",
            "NormTermName": "Telefon-/PDA-Halter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08696": {
            "GenartName": "Reifencontainer",
            "NormTermName": "Reifencontainer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08697": {
            "GenartName": "Wasserschlauch",
            "NormTermName": "Wasserschlauch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08698": {
            "GenartName": "Werkstattkarten",
            "NormTermName": "Werkstattkarten",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08699": {
            "GenartName": "Drucker",
            "NormTermName": "Drucker",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08700": {
            "GenartName": "Reifenprüfwanne",
            "NormTermName": "Reifenprüfwanne",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08701": {
            "GenartName": "Hautschutzplan",
            "NormTermName": "Hautschutzplan",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08702": {
            "GenartName": "Reifenpflaster",
            "NormTermName": "Reifenpflaster",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08703": {
            "GenartName": "Rettungsschild",
            "NormTermName": "Rettungsschild",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08704": {
            "GenartName": "Reifenreparaturkörper",
            "NormTermName": "Reifenreparaturkörper",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08705": {
            "GenartName": "Unterhose",
            "NormTermName": "Unterhose",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08706": {
            "GenartName": "Kompressionsdruckprüfer",
            "NormTermName": "Kompressionsdruckprüfer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08707": {
            "GenartName": "Heizgeräteprüfstand",
            "NormTermName": "Heizgeräteprüfstand",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08708": {
            "GenartName": "Teleskopstange, Endoskop",
            "NormTermName": "Teleskopstange",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08709": {
            "GenartName": "Gepäckspanner",
            "NormTermName": "Gepäckspanner",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": null
        },
        "08710": {
            "GenartName": "Saugschlauch, Trockensauger",
            "NormTermName": "Saugschlauch",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08711": {
            "GenartName": "Saugsonde, Ölabsauggerät",
            "NormTermName": "Saugsonde",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08712": {
            "GenartName": "Scheibenschaberkopf",
            "NormTermName": "Scheibenschaberkopf",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08713": {
            "GenartName": "Scheiben-Flächentrockner",
            "NormTermName": "Scheiben-Flächentrockner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08714": {
            "GenartName": "Schneiddüse, Plasmaschneider",
            "NormTermName": "Schneiddüse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Plasmaschneider"
        },
        "08715": {
            "GenartName": "Schnellkupplung, Klimaservicegerät",
            "NormTermName": "Schnellkupplung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08716": {
            "GenartName": "Schreibmappe",
            "NormTermName": "Schreibmappe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08717": {
            "GenartName": "Schweißgarnitur",
            "NormTermName": "Schweißgarnitur",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08718": {
            "GenartName": "Schweißkabelkupplung",
            "NormTermName": "Schweißkabelkupplung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08719": {
            "GenartName": "Lagersichtkasten",
            "NormTermName": "Lagersichtkasten",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08720": {
            "GenartName": "Strahlmittel, Sandstrahlpistole",
            "NormTermName": "Strahlmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "08721": {
            "GenartName": "Stromdüse, Schweißgerät",
            "NormTermName": "Stromdüse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schweißgerät"
        },
        "08722": {
            "GenartName": "Kranarm, Radheber",
            "NormTermName": "Kranarm, Radheber",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08723": {
            "GenartName": "Leitersprosse",
            "NormTermName": "Leitersprosse",
            "AssGrpName": "Fahrerhaus, Universal",
            "UsageName": null
        },
        "08724": {
            "GenartName": "Kraftstoffdiebstahlsicherung",
            "NormTermName": "Kraftstoffdiebstahlsicherung",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": null
        },
        "08725": {
            "GenartName": "Reparatursatz, Kupplungskopf",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Kupplungskopf"
        },
        "08726": {
            "GenartName": "Teleskopverlängerung, Flächenwascher",
            "NormTermName": "Teleskopverlängerung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Flächenwascher"
        },
        "08727": {
            "GenartName": "Feuchttuchspender",
            "NormTermName": "Feuchttuchspender",
            "AssGrpName": "Hygieneartikel",
            "UsageName": null
        },
        "08728": {
            "GenartName": "Fußmattenklopfer",
            "NormTermName": "Fußmattenklopfer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08729": {
            "GenartName": "Thermobonrolle",
            "NormTermName": "Thermobonrolle",
            "AssGrpName": "Bürobedarf",
            "UsageName": null
        },
        "08730": {
            "GenartName": "Wandhalterung, Gasflasche",
            "NormTermName": "Wandhalterung, Gasflasche",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08731": {
            "GenartName": "Wandhalterung, Hochdruckreiniger",
            "NormTermName": "Wandhalterung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hochdruckreiniger"
        },
        "08732": {
            "GenartName": "Filtersatz, Hochdruckreiniger",
            "NormTermName": "Filter-Satz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hochdruckreiniger"
        },
        "08733": {
            "GenartName": "Motorrad-Tankschoner",
            "NormTermName": "Motorrad-Tankschoner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08734": {
            "GenartName": "Sensor, Druckluftanlage",
            "NormTermName": "Sensor",
            "AssGrpName": "Druckluftanlage, Universal",
            "UsageName": "Druckluftanlage"
        },
        "08735": {
            "GenartName": "Wandhalterung, Bürsten/Hochdruckaufsätze",
            "NormTermName": "Wandhalterung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08736": {
            "GenartName": "Papierhandtücher",
            "NormTermName": "Papierhandtücher",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08737": {
            "GenartName": "Ersatzglas, Freisicht-Handschild",
            "NormTermName": "Ersatzglas",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08738": {
            "GenartName": "Zapfenbrecher",
            "NormTermName": "Zapfenbrecher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08739": {
            "GenartName": "Fassdeckelmatte",
            "NormTermName": "Fassdeckelmatte",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08740": {
            "GenartName": "Zubehörsatz, Plasmaschneider",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08741": {
            "GenartName": "Umrüstsatz, Bremszylinder",
            "NormTermName": "Umrüstsatz, Bremszylinder",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": null
        },
        "08742": {
            "GenartName": "Luftfederpresse",
            "NormTermName": "Luftfederpresse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08743": {
            "GenartName": "Drehmomentschlüssel-Tresor, Werkzeugwagen",
            "NormTermName": "Drehmomentschlüssel-Tresor",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "08744": {
            "GenartName": "Steigrohr, ASD-Befüllstation",
            "NormTermName": "Steigrohr",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "08745": {
            "GenartName": "Reparatursatz, Lagerbock-Nockenwelle",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Lagerbock-Nockenwelle"
        },
        "08746": {
            "GenartName": "Lagerbock, Nockenwelle",
            "NormTermName": "Lagerbock",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwelle"
        },
        "08747": {
            "GenartName": "Luftkanal",
            "NormTermName": "Luftkanal",
            "AssGrpName": "Luftversorgung",
            "UsageName": null
        },
        "08748": {
            "GenartName": "Ölabscheider, Druckluftanlage",
            "NormTermName": "Abscheider",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Öl"
        },
        "08749": {
            "GenartName": "Zahnrad, Rückwärtsgang (Rücklaufrad)",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Rückwärtsgang (Rücklaufrad)"
        },
        "08750": {
            "GenartName": "Ventil, Sitzhöhenverstellung",
            "NormTermName": "Ventil",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sitzhöhenverstellung"
        },
        "08751": {
            "GenartName": "Luftfederbalg, Sitzhöhenverstellung",
            "NormTermName": "Federbalg",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sitzhöhenverstellung"
        },
        "08752": {
            "GenartName": "Reparatursatz, Kupplungsbetätigung (Übertragungsteile)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungsbetätigung (Übertragungsteile)"
        },
        "08753": {
            "GenartName": "Welle, Nebenantrieb",
            "NormTermName": "Welle",
            "AssGrpName": "Nebenantrieb",
            "UsageName": "Nebenantrieb"
        },
        "08754": {
            "GenartName": "Halteschiene, Türscheibe",
            "NormTermName": "Halteschiene",
            "AssGrpName": "Karosserie",
            "UsageName": "Türscheibe"
        },
        "08755": {
            "GenartName": "Riemenscheibe, Druckluftkompressor",
            "NormTermName": "Riemenscheibe",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "08756": {
            "GenartName": "Halter, Lenkhebelwelle",
            "NormTermName": "Halter",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkhebelwelle"
        },
        "08757": {
            "GenartName": "Hohlwelle, Federbock",
            "NormTermName": "Welle",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbock"
        },
        "08758": {
            "GenartName": "Ritzel, Geschwindigkeitsmesser",
            "NormTermName": "Ritzel",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Geschwindigkeit"
        },
        "08759": {
            "GenartName": "Zahnrad, Servopumpe",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Lenkung",
            "UsageName": "Hydraulikpumpe (Lenkung)"
        },
        "08760": {
            "GenartName": "Druckventil, Einspritzpumpe",
            "NormTermName": "Ventil",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Einspritzpumpe"
        },
        "08761": {
            "GenartName": "Halteclipsatz, Karosserie",
            "NormTermName": "Halteclipsatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Karosserie"
        },
        "08762": {
            "GenartName": "Druckstücksatz, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Druckstücksatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Montagewerkzeug (Radnabe/Radlager)"
        },
        "08763": {
            "GenartName": "Abzieher, Radlagerinnenring",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radlagerinnenring"
        },
        "08764": {
            "GenartName": "Steckschlüsseleinsatz, Achsschenkel (Aufspreizer)",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Achsschenkel"
        },
        "08765": {
            "GenartName": "Spindel-Schnellspannmutter-Satz",
            "NormTermName": "Spindel-Schnellspannmutter-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08766": {
            "GenartName": "Spindel-Schnellspannmutter",
            "NormTermName": "Spindel-Schnellspannmutter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Spindel"
        },
        "08767": {
            "GenartName": "Sortiment, Blindniet",
            "NormTermName": "Sortiment",
            "AssGrpName": "Karosserie",
            "UsageName": "Blindniet"
        },
        "08768": {
            "GenartName": "Zapfenschlüsselsatz, Federbein",
            "NormTermName": "Zapfenschlüsselsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federbein"
        },
        "08769": {
            "GenartName": "Steckschlüsseleinsatz, Steuerventil (Nockenwellenversteller)",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08770": {
            "GenartName": "Zugstangenpaar, Pressrahmen (Umrüstung)",
            "NormTermName": "Zugstangenpaar",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08771": {
            "GenartName": "Pressrahmensatz",
            "NormTermName": "Pressrahmensatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08772": {
            "GenartName": "Adapter, Schlauchkupplung",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08773": {
            "GenartName": "Adapter, Aufnahme",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08774": {
            "GenartName": "Ein-/Auspresswerkzeug-Satz",
            "NormTermName": "Ein-/Auspresswerkzeug-Satz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08775": {
            "GenartName": "Montagesatz, Traggelenk",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08776": {
            "GenartName": "Pressrahmen, Traggelenk",
            "NormTermName": "Pressrahmen",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08777": {
            "GenartName": "Schlagdorn, Silentlager",
            "NormTermName": "Schlagdorn",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Silentlager"
        },
        "08778": {
            "GenartName": "Druckstücksatz, Montagewerkzeug Silentlager",
            "NormTermName": "Druckstücksatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Silentlager"
        },
        "08779": {
            "GenartName": "Montagesatz, Querlenkergummilager",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08780": {
            "GenartName": "Aufnahme, Pressrahmen",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08781": {
            "GenartName": "Adapter, Schlagauszieher (Common-Rail-Injektor)",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Schlagauszieher (Common-Rail-Injektor)"
        },
        "08782": {
            "GenartName": "Bördelgerät, Bremsleitung",
            "NormTermName": "Bördelgerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsleitung"
        },
        "08784": {
            "GenartName": "Kabelreparatursatz, Kontaktschalter-Heckklappe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz",
            "UsageName": "Kontaktschalter-Heckklappe"
        },
        "08785": {
            "GenartName": "Kabelreparatursatz, Kontaktschalter-Heckklappe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz, Universal",
            "UsageName": "Kontaktschalter-Heckklappe"
        },
        "08786": {
            "GenartName": "Kabelreparatursatz, Bremsleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Signalanlage",
            "UsageName": "Bremsleuchte"
        },
        "08787": {
            "GenartName": "Kabelreparatursatz, Kofferraumleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Kofferraumleuchte"
        },
        "08788": {
            "GenartName": "Kabelreparatursatz, Glühlampe-Fernscheinwerfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Glühlampe-Fernscheinwerfer"
        },
        "08789": {
            "GenartName": "Kabelreparatursatz, Glühlampe-Fernscheinwerfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Glühlampe-Fernscheinwerfer"
        },
        "08790": {
            "GenartName": "Kabelreparatursatz, Glühlampe-Hauptscheinwerfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Glühlampe-Hauptscheinwerfer"
        },
        "08791": {
            "GenartName": "Kabelreparatursatz, Glühlampe-Hauptscheinwerfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Glühlampe-Hauptscheinwerfer"
        },
        "08792": {
            "GenartName": "Kabelreparatursatz, Glühlampe-Nebelscheinwerfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Glühlampe-Nebelscheinwerfer"
        },
        "08793": {
            "GenartName": "Kabelreparatursatz, Glühlampe-Nebelscheinwerfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Glühlampe-Nebelscheinwerfer"
        },
        "08794": {
            "GenartName": "Aufnahmering, Schnellspannmutter",
            "NormTermName": "Aufnahmering",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Spindel"
        },
        "08795": {
            "GenartName": "Adapter, Schnellspannmutter",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Spindel"
        },
        "08796": {
            "GenartName": "Erweiterungssatz, Ein-/Auspresswerkzeug",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Silentlager"
        },
        "08797": {
            "GenartName": "Spannbackensatz, Ein-/Auspresswerkzeug",
            "NormTermName": "Spannbackensatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Silentlager"
        },
        "08798": {
            "GenartName": "Spannscheibe, Silentlagerwerkzeug",
            "NormTermName": "Spannscheibe",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Silentlager"
        },
        "08799": {
            "GenartName": "Druckstück, Ein-/Auspresswerkzeug",
            "NormTermName": "Druckstück",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Silentlager"
        },
        "08800": {
            "GenartName": "Druckstücksatz, Bördelgerät",
            "NormTermName": "Druckstücksatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsleitung"
        },
        "08801": {
            "GenartName": "Druckschraube, Bördelgerät",
            "NormTermName": "Druckschraube",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bördelgerät"
        },
        "08802": {
            "GenartName": "Messschieber, Bremsscheibendurchmesser",
            "NormTermName": "Messschieber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsscheibe"
        },
        "08803": {
            "GenartName": "Steckschlüsseleinsatz, Feststellbremse",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Feststellbremse"
        },
        "08804": {
            "GenartName": "Adapter, Bremssattelkolben-Rückstellwerkzeug",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremssattelkolben-Rückstellwerkzeug"
        },
        "08805": {
            "GenartName": "Erweiterungssatz, Bördelgerät",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsleitung"
        },
        "08806": {
            "GenartName": "Schälwerkzeug, Bremsleitung",
            "NormTermName": "Schälwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsleitung"
        },
        "08807": {
            "GenartName": "Reibahlensatz, ABS-Sensoraufnahme",
            "NormTermName": "Reibahlensatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "ABS-Sensoraufnahme"
        },
        "08808": {
            "GenartName": "Rückstellwerkzeug, Kupplung",
            "NormTermName": "Rückstellwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kupplung"
        },
        "08809": {
            "GenartName": "Zentrierdornsatz, Kupplung",
            "NormTermName": "Zentrierdornsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kupplungsscheibe"
        },
        "08810": {
            "GenartName": "Grundwerkzeugsatz, Doppelkupplungswerkzeug",
            "NormTermName": "Grundwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Doppelkupplungsgetriebe (DSG)"
        },
        "08811": {
            "GenartName": "Erweiterungssatz, Doppelkupplungswerkzeug",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Doppelkupplungsgetriebe (DSG)"
        },
        "08812": {
            "GenartName": "Nachrüstsatz, Doppelkupplungswerkzeug",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Doppelkupplungsgetriebe (DSG)"
        },
        "08813": {
            "GenartName": "Duftmarkenentferner, Marderschutz",
            "NormTermName": "Duftmarkenentferner",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Marderschutz"
        },
        "08814": {
            "GenartName": "Erweiterungssatz, Marderschutz",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Marderschutz"
        },
        "08815": {
            "GenartName": "Befestigungswinkel, Marderschutz",
            "NormTermName": "Befestigungswinkel",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Marderschutz"
        },
        "08816": {
            "GenartName": "Kontaktschalter, Motorhaube (Marderschutz)",
            "NormTermName": "Schalter",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Motorhaube (Marderschutz)"
        },
        "08817": {
            "GenartName": "Adapter, Schlagauszieher",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzdüse"
        },
        "08818": {
            "GenartName": "Aufnahme, Schlagauszieher",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzdüse"
        },
        "08819": {
            "GenartName": "Zentrierscheibe, Kupplung",
            "NormTermName": "Zentrierscheibe",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kupplung"
        },
        "08820": {
            "GenartName": "Lösezange, Kupplungsgeberzylinder",
            "NormTermName": "Lösezange",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kupplung"
        },
        "08821": {
            "GenartName": "Abzieher, Ausrückwelle",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplung"
        },
        "08822": {
            "GenartName": "Montagewerkzeug, Ausrücklager",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kupplung"
        },
        "08823": {
            "GenartName": "Zentrierhülsensatz, Kupplungszentrierwerkzeug",
            "NormTermName": "Zentrierhülsensatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kupplung"
        },
        "08824": {
            "GenartName": "Zentrierstück, Kupplungszentrierwerkzeug",
            "NormTermName": "Zentrierstück",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kupplung"
        },
        "08825": {
            "GenartName": "Gewindebolzen, Kupplungszentrierwerkzeug",
            "NormTermName": "Gewindebolzen",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kupplung"
        },
        "08826": {
            "GenartName": "Grundplatte, Kupplungsspannvorrichtung",
            "NormTermName": "Grundplatte",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kupplung"
        },
        "08827": {
            "GenartName": "Rotor, Retarder",
            "NormTermName": "Rotor",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "08828": {
            "GenartName": "Gewicht, Schlagauszieher",
            "NormTermName": "Gewicht",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08829": {
            "GenartName": "Ausgleichsbehälter, Frostschutz-Druckluftanlage",
            "NormTermName": "Behälter",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Frostschutz-Druckluftanlage"
        },
        "08830": {
            "GenartName": "Rücklaufachse, Ölpumpe",
            "NormTermName": "Rücklaufachse",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Ölpumpe"
        },
        "08831": {
            "GenartName": "Hydraulikpumpe, Kühlerlüfter",
            "NormTermName": "Pumpe",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlerlüfter"
        },
        "08832": {
            "GenartName": "Hydraulikmotor, Kühlerlüfter",
            "NormTermName": "Hydraulikmotor",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlerlüfter"
        },
        "08833": {
            "GenartName": "Nabe, Vorgelegewelle",
            "NormTermName": "Nabe",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Vorgelegewelle"
        },
        "08834": {
            "GenartName": "Ventilblock, Schaltgetriebe",
            "NormTermName": "Ventilblock",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "08835": {
            "GenartName": "Steuereinheit, Schaltgetriebe",
            "NormTermName": "Steuereinheit",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "08836": {
            "GenartName": "Gelenk-Hakenschlüssel",
            "NormTermName": "Gelenk-Hakenschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08837": {
            "GenartName": "Gelenk-Stirnlochschlüssel",
            "NormTermName": "Gelenk-Stirnlochschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08838": {
            "GenartName": "Prismenplatte, Werkstattpresse",
            "NormTermName": "Prismenplatte",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Werkstattpressen"
        },
        "08839": {
            "GenartName": "Rückfahrhilfe",
            "NormTermName": "Rückfahrhilfe",
            "AssGrpName": "Komfortsysteme",
            "UsageName": null
        },
        "08840": {
            "GenartName": "Rückfahrhilfe",
            "NormTermName": "Rückfahrhilfe",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": null
        },
        "08841": {
            "GenartName": "Klammer, Schlagauszieher",
            "NormTermName": "Klammer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Schlagauszieher"
        },
        "08842": {
            "GenartName": "Ausdrückwerkzeug, Steckhülse",
            "NormTermName": "Ausdrückwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08843": {
            "GenartName": "Ausdrückwerkzeug, Steckhülse",
            "NormTermName": "Ausdrückwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08844": {
            "GenartName": "Ausdrückwerkzeugsatz, Steckhülse",
            "NormTermName": "Ausdrückwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08845": {
            "GenartName": "Schabersatz",
            "NormTermName": "Schabersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08846": {
            "GenartName": "Schraubendrehersatz, Batterieverschlussstopfen",
            "NormTermName": "Schraubendrehersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Batterieverschlussstopfen"
        },
        "08847": {
            "GenartName": "Demontagewerkzeugsatz, Airbag",
            "NormTermName": "Demontagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Airbag"
        },
        "08848": {
            "GenartName": "Nutmutternschlüsselsatz",
            "NormTermName": "Nutmutternschlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08849": {
            "GenartName": "Demontagewerkzeugsatz, Zündschloss",
            "NormTermName": "Demontagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zündschloss"
        },
        "08850": {
            "GenartName": "Montagehülse, Zündschloss",
            "NormTermName": "Montagehülse",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zündschloss"
        },
        "08851": {
            "GenartName": "Montagehülse, Zündschlossrosette",
            "NormTermName": "Montagehülse",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zündschloss"
        },
        "08852": {
            "GenartName": "Entriegelungswerkzeug, Lenkschlossabdeckung",
            "NormTermName": "Entriegelungswerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Lenkschloss"
        },
        "08853": {
            "GenartName": "Einstellwerkzeug, Fensterheber",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Fensterheber"
        },
        "08854": {
            "GenartName": "Verlängerungssatz, Messuhr",
            "NormTermName": "Verlängerungssatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Messuhr"
        },
        "08855": {
            "GenartName": "Bohrungsmesswerkzeug",
            "NormTermName": "Bohrungsmesswerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08856": {
            "GenartName": "Gelenkgriff",
            "NormTermName": "Gelenkgriff",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08857": {
            "GenartName": "Einweghandschuhe",
            "NormTermName": "Einweghandschuhe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schutzhandschuhe"
        },
        "08858": {
            "GenartName": "Kupplungskorb-Haltewerkzeug",
            "NormTermName": "Haltewerkzeug",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Kupplung"
        },
        "08859": {
            "GenartName": "Auffangwanne, Ölfassaufsatz",
            "NormTermName": "Auffangwanne",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08860": {
            "GenartName": "Sprühflasche, Druckluft",
            "NormTermName": "Sprühflasche",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08861": {
            "GenartName": "Einstellwerkzeug, Nockenwelle",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwelle"
        },
        "08862": {
            "GenartName": "Einstellwerkzeugsatz, Nockenwelle",
            "NormTermName": "Einstellwerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwelle"
        },
        "08863": {
            "GenartName": "Montagewerkzeugsatz, Nockenwelle",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwelle"
        },
        "08864": {
            "GenartName": "Arretierwerkzeug, Nockenwellenrad",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Nockenwellenrad"
        },
        "08865": {
            "GenartName": "Arretierwerkzeugsatz, Nockenwellenrad",
            "NormTermName": "Arretierwerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Nockenwellenrad"
        },
        "08866": {
            "GenartName": "Demontagewerkzeug, AGR-Ventil",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abgasrückführung"
        },
        "08867": {
            "GenartName": "Blindnietadapter, Bohrmaschine",
            "NormTermName": "Blindnietadapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bohrmaschine"
        },
        "08868": {
            "GenartName": "Dämmstoffmesser",
            "NormTermName": "Dämmstoffmesser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08869": {
            "GenartName": "Körnersatz",
            "NormTermName": "Körnersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08870": {
            "GenartName": "Klinge, Rohrentgrater",
            "NormTermName": "Klinge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrentgrater"
        },
        "08871": {
            "GenartName": "Adapter, Kompressionsdruckprüfer",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kompressionsdruckprüfer"
        },
        "08872": {
            "GenartName": "Zapfenpaar, Stirnlochschlüssel",
            "NormTermName": "Zapfenpaar",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Stirnlochschlüssel"
        },
        "08873": {
            "GenartName": "Drahtseilwinde",
            "NormTermName": "Drahtseilwinde",
            "AssGrpName": "Anhänger",
            "UsageName": null
        },
        "08875": {
            "GenartName": "Aufsteckschiene, Steckschlüsseleinsatz",
            "NormTermName": "Aufsteckschiene",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steckschlüsseleinsatz"
        },
        "08876": {
            "GenartName": "Adapter, Common-Rail-Injektor-Prüfgerät",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Common-Rail-Injektor-Prüfgerät"
        },
        "08877": {
            "GenartName": "Steuerkettentrenn-/Nietgerät",
            "NormTermName": "Steuerkettentrenn-/Nietgerät",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08878": {
            "GenartName": "Adapter, Steuerkettentrenn-/Nietgerät",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08879": {
            "GenartName": "Gummiring, Kühlsystemdruckprüfset",
            "NormTermName": "Gummiring",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kühlsystemdruckprüfset"
        },
        "08880": {
            "GenartName": "Glühlampe, Spannungsprüfer",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Spannungspüfer"
        },
        "08881": {
            "GenartName": "Kraft-Stecknuss, Antriebswelle",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Antriebswelle"
        },
        "08882": {
            "GenartName": "Kupplungs-/Variomatikwerkzeug",
            "NormTermName": "Kupplungs-/Variomatikwerkzeug",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "08883": {
            "GenartName": "Demontagewerkzeug, Türgriff",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Türgriff"
        },
        "08884": {
            "GenartName": "Montagebolzensatz, Bremssattel",
            "NormTermName": "Montagebolzensatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremssattel"
        },
        "08885": {
            "GenartName": "Hitzeschutzband",
            "NormTermName": "Hitzeschutzband",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": null
        },
        "08886": {
            "GenartName": "Hitzeschutzschlauch",
            "NormTermName": "Hitzeschutzschlauch",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": null
        },
        "08887": {
            "GenartName": "Lösewerkzeugsatz, Felgenschloss",
            "NormTermName": "Lösewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Felgenschloss"
        },
        "08888": {
            "GenartName": "Messrohrsatz, Kraftstoff-Rücklaufmengenmessung",
            "NormTermName": "Messrohrsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kraftstoff-Rücklaufmengenmessung"
        },
        "08889": {
            "GenartName": "Haltewerkzeugsatz, Riemenscheibe",
            "NormTermName": "Haltewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08890": {
            "GenartName": "Druckstück, Doppelkupplungswerkzeug",
            "NormTermName": "Druckstück",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08891": {
            "GenartName": "Adapter, Schlauchverbinder",
            "NormTermName": "Adapter",
            "AssGrpName": "Normteile",
            "UsageName": "Schlauchverbinder"
        },
        "08892": {
            "GenartName": "Tragetasche",
            "NormTermName": "Tragetasche",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08893": {
            "GenartName": "Einkaufswagen-Coin",
            "NormTermName": "Einkaufswagen-Coin",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08894": {
            "GenartName": "Feuerzeug",
            "NormTermName": "Feuerzeug",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08895": {
            "GenartName": "Flaschenöffner",
            "NormTermName": "Flaschenöffner",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08896": {
            "GenartName": "Fußball",
            "NormTermName": "Fußball",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08897": {
            "GenartName": "Kaffeetasse",
            "NormTermName": "Kaffeetasse",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08898": {
            "GenartName": "Kugelschreiber",
            "NormTermName": "Kugelschreiber",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08899": {
            "GenartName": "Poster",
            "NormTermName": "Poster",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08900": {
            "GenartName": "Schreibunterlage",
            "NormTermName": "Schreibunterlage",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08901": {
            "GenartName": "Fahne",
            "NormTermName": "Fahne",
            "AssGrpName": "Werbeartikel",
            "UsageName": null
        },
        "08902": {
            "GenartName": "Halterung, Feststellbremse",
            "NormTermName": "Halterung",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremse"
        },
        "08903": {
            "GenartName": "Drucksensor, Bremskraftverstärker",
            "NormTermName": "Sensor",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremskraftverstärker"
        },
        "08904": {
            "GenartName": "Wärmeisolierung, Hitzeschutzblech",
            "NormTermName": "Wärmeisolierung",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Hitzeschutzblech"
        },
        "08905": {
            "GenartName": "Kabelreparatursatz, Kühlerlüfter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlerlüfter"
        },
        "08906": {
            "GenartName": "Kabelreparatursatz, Kühlerlüfter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Kühlerlüfter"
        },
        "08907": {
            "GenartName": "Dichtungssatz, Lenkgetriebe-Eingangswelle",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkgetriebe-Eingangswelle"
        },
        "08908": {
            "GenartName": "Kabelreparatursatz, Fernlicht-/Abblendlichtschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fernlicht-/Abblendlichtschalter"
        },
        "08909": {
            "GenartName": "Kabelreparatursatz, Geschwindigkeitssensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Instrumente",
            "UsageName": "Geschwindigkeitssensor"
        },
        "08910": {
            "GenartName": "Kabelreparatursatz, Relais-Hupe/Horn",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Signalanlage",
            "UsageName": "Relais-Hupe/Horn"
        },
        "08911": {
            "GenartName": "Reparaturhülse, Getriebeausgangswelle (Schaltgetriebe)",
            "NormTermName": "Reparaturhülse",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeausgangswelle (Schaltgetriebe)"
        },
        "08912": {
            "GenartName": "Kantenschutz-/Dichtgummilöser",
            "NormTermName": "Kantenschutz-/Dichtgummilöser",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08913": {
            "GenartName": "Tankgeberschlüssel",
            "NormTermName": "Tankgeberschlüssel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "08914": {
            "GenartName": "Lager-Eintreib-Satz",
            "NormTermName": "Lager-Eintreib-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08915": {
            "GenartName": "Lager, Kompressorwelle",
            "NormTermName": "Lager",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kompressorwelle"
        },
        "08916": {
            "GenartName": "Kabelreparatursatz, Lenkstockschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Instrumente",
            "UsageName": "Lenkstockschalter"
        },
        "08917": {
            "GenartName": "Ausdrückscheibe, Antriebswelle",
            "NormTermName": "Ausdrückscheibe",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Antriebswelle"
        },
        "08919": {
            "GenartName": "Reparatursatz, Scheibenreinigungsanlage",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Scheibenreinigungsanlage"
        },
        "08921": {
            "GenartName": "Scheibe, Schweißerhelm",
            "NormTermName": "Scheibe",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "08922": {
            "GenartName": "Abzieher, Zahnriemenrad",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zahnriemenrad"
        },
        "08923": {
            "GenartName": "Anschleifvorrichtung, Bohrer",
            "NormTermName": "Anschleifvorrichtung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08924": {
            "GenartName": "Aufnahmeteller, Fliesenbohrer",
            "NormTermName": "Aufnahmeteller",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Fliesenbohrer"
        },
        "08925": {
            "GenartName": "Feinbohrersatz",
            "NormTermName": "Feinbohrersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08926": {
            "GenartName": "Holzbohrersatz",
            "NormTermName": "Holzbohrersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08927": {
            "GenartName": "Multibohrersatz",
            "NormTermName": "Multibohrersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08928": {
            "GenartName": "Holzschlangenbohrersatz",
            "NormTermName": "Holzschlangenbohrersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlangenbohrer"
        },
        "08929": {
            "GenartName": "Haltezange, Kupplung",
            "NormTermName": "Haltezange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kupplung"
        },
        "08930": {
            "GenartName": "Lösezange, Schalldämpfergummi",
            "NormTermName": "Lösezange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abgasanlage"
        },
        "08931": {
            "GenartName": "Bördelzange",
            "NormTermName": "Bördelzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Karosserie"
        },
        "08932": {
            "GenartName": "Spezialkleber-Set",
            "NormTermName": "Spezialkleber-Set",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "08933": {
            "GenartName": "Schlüssel, CNG-Flaschenventil",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Gasflasche"
        },
        "08934": {
            "GenartName": "Muttern-/Kappenschlüssel-Satz",
            "NormTermName": "Muttern-/Kappenschlüssel-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08935": {
            "GenartName": "Einschlagzahlen",
            "NormTermName": "Einschlagzahlen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08936": {
            "GenartName": "Rückstellwerkzeug, Kupplung",
            "NormTermName": "Rückstellwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kupplung"
        },
        "08937": {
            "GenartName": "Sägeketten-Schärfgerät",
            "NormTermName": "Schärfgerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Sägeketten"
        },
        "08938": {
            "GenartName": "Ventileinziehersatz, Reifen",
            "NormTermName": "Ventileinziehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Reifenventil"
        },
        "08939": {
            "GenartName": "Reflektorstreifen, Drehzahlmesser",
            "NormTermName": "Reflektorstreifen",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Drehzahlmesser"
        },
        "08940": {
            "GenartName": "Entriegelungswerkzeug",
            "NormTermName": "Entriegelungswerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08941": {
            "GenartName": "Entriegelungswerkzeugsatz",
            "NormTermName": "Entriegelungswerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "08942": {
            "GenartName": "Werkzeugsatz, Längslenkergummi",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Lenker"
        },
        "08943": {
            "GenartName": "Werkzeugsatz, Längslenkerbuchse",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Lenker"
        },
        "08944": {
            "GenartName": "Achsschenkelbolzensatz",
            "NormTermName": "Bolzensatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achsschenkel"
        },
        "08945": {
            "GenartName": "Reparaturhülse, Schaltstange (Schaltgetriebe)",
            "NormTermName": "Reparaturhülse",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltstange (Schaltgetriebe)"
        },
        "08946": {
            "GenartName": "Kollektorlager, Generator",
            "NormTermName": "Lager",
            "AssGrpName": "Generator",
            "UsageName": "Kollektorlager-Generator"
        },
        "08947": {
            "GenartName": "Kabelreparatursatz, Drehzahlsensor (Automatikgetriebe)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Drehzahlsensor (Automatikgetriebe)"
        },
        "08948": {
            "GenartName": "Kabelreparatursatz, Drehzahlsensor (Schaltgetriebe)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Drehzahlsensor (Schaltgetriebe)"
        },
        "08949": {
            "GenartName": "Reparaturhülse, Getriebeeingangswelle (Verteilergetriebe)",
            "NormTermName": "Reparaturhülse",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Getriebeeingangswelle (Verteilergetriebe)"
        },
        "08950": {
            "GenartName": "Umschaltventil, Kraftstoffbehälter",
            "NormTermName": "Ventil",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffbehälter"
        },
        "08951": {
            "GenartName": "Ausrichtwerkzeug, Schwingungsdämpfer (Kurbelwelle)",
            "NormTermName": "Ausrichtwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kurbelwelle"
        },
        "08952": {
            "GenartName": "Kabelreparatursatz, Nebelschlussleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebelschlussleuchte"
        },
        "08953": {
            "GenartName": "Ausdrücker, Querlenkerlagerbuchse",
            "NormTermName": "Ausdrücker",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Querlenker"
        },
        "08954": {
            "GenartName": "Kabelreparatursatz, Nebellichtschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Nebellichtschalter"
        },
        "08955": {
            "GenartName": "Kabelreparatursatz, Hauptlicht-/Nebellichtschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptlicht-/Nebellichtschalter"
        },
        "08956": {
            "GenartName": "Schnitzwerkzeugsatz",
            "NormTermName": "Schnitzwerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08957": {
            "GenartName": "Kabelreparatursatz, Magnetkupplung-Klimakompressor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Magnetkupplung-Klimakompressor"
        },
        "08958": {
            "GenartName": "Kabelreparatursatz, Relais-Magnetkupplung (Klimakompressor)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Relais-Magnetkupplung (Klimakompressor)"
        },
        "08959": {
            "GenartName": "Glühlampe, Sonnenblendenleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Sonnenblendenleuchte"
        },
        "08960": {
            "GenartName": "Einstellwerkzeug, Ausgleichswelle",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ausgleichswelle"
        },
        "08961": {
            "GenartName": "Arretierwerkzeug, Ausgleichswelle",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ausgleichswelle"
        },
        "08962": {
            "GenartName": "Druckluftmontagegerät, Achsmanschette",
            "NormTermName": "Druckluftmontagegerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Achsmanschette"
        },
        "08963": {
            "GenartName": "Kabelreparatursatz, Kraftstoffpumpenrelais",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpenrelais"
        },
        "08964": {
            "GenartName": "Kabelreparatursatz, Innenraumgebläsemotor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumgebläsemotor"
        },
        "08965": {
            "GenartName": "Justierwerkzeug, Gaspedal",
            "NormTermName": "Justierwerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08966": {
            "GenartName": "Demontagewerkzeugsatz, Heckleuchte",
            "NormTermName": "Demontagewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Heckleuchte"
        },
        "08967": {
            "GenartName": "Eintreibwerkzeugsatz, Gabeldichtring",
            "NormTermName": "Eintreibwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Zweirad"
        },
        "08968": {
            "GenartName": "Demontagewerkzeug, Steckverbinder",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steckverbinder"
        },
        "08969": {
            "GenartName": "Glühlampe, Motorkontrollleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Instrumente",
            "UsageName": "Motorkontrollleuchte"
        },
        "08970": {
            "GenartName": "Halter, Zündspule",
            "NormTermName": "Halter",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündspule"
        },
        "08971": {
            "GenartName": "Kabelreparatursatz, Innenraumgebläserelais",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Innenraumgebläserelais"
        },
        "08972": {
            "GenartName": "Ausrichtwerkzeug, Vorderachse",
            "NormTermName": "Ausrichtwerkzeug",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Zweirad"
        },
        "08973": {
            "GenartName": "Demontagewerkzeug, Zigarettenanzünderbuchse",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Zigarettenanzünder"
        },
        "08974": {
            "GenartName": "Reparatursatz, Reinigungspistole (Druckluft)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08975": {
            "GenartName": "Ersatzdüse, Sprühflasche",
            "NormTermName": "Ersatzdüse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Druckluft"
        },
        "08976": {
            "GenartName": "Reparatureinsatz, Entlüftungsventil",
            "NormTermName": "Reparatureinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremssattel (Bremssystementlüftung)"
        },
        "08977": {
            "GenartName": "Bowdenzugöler",
            "NormTermName": "Bowdenzugöler",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08978": {
            "GenartName": "Kabelreparatursatz, Kühlerlüfterrelais",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlerlüfterrelais"
        },
        "08979": {
            "GenartName": "Sanitär-Installationsschlüssel",
            "NormTermName": "Sanitär-Installationsschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08980": {
            "GenartName": "Relais, Scheibenwischerintervall",
            "NormTermName": "Relais",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Scheibenwischerintervall"
        },
        "08981": {
            "GenartName": "Sanitär-Installationsschlüsselsatz",
            "NormTermName": "Sanitär-Installationsschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "08982": {
            "GenartName": "Dichtring, Ölpeilstab-Führung (Automatikgetriebe)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölpeilstab-Führung (Automatikgetriebe)"
        },
        "08983": {
            "GenartName": "Relais, Tagfahrlicht",
            "NormTermName": "Relais",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Tagfahrlicht"
        },
        "08984": {
            "GenartName": "Ausgleichsscheibe, Nachlauf-/Spureinstellung",
            "NormTermName": "Scheibe",
            "AssGrpName": "Lenkung",
            "UsageName": "Nachlauf-/Spureinstellung"
        },
        "08985": {
            "GenartName": "Dichtungssatz, Servolenkungsventil",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Servolenkungsventil"
        },
        "08986": {
            "GenartName": "Dichtungssatz, Getriebeausgangswelle (Schaltgetriebe)",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeausgangswelle (Schaltgetriebe)"
        },
        "08987": {
            "GenartName": "Relais, Luftmassenmesser",
            "NormTermName": "Relais",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Luftmassenmesser"
        },
        "08988": {
            "GenartName": "Dichtung, Ölpumpe-Automatikgetriebe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölpumpe-Automatikgetriebe"
        },
        "08989": {
            "GenartName": "Schraube, Tellerrad-Differential",
            "NormTermName": "Schraube",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Tellerrad-Differential"
        },
        "08990": {
            "GenartName": "Kabelreparatursatz, Waschwasserpumpe-Scheibenreinigung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasserpumpe-Scheibenreinigung"
        },
        "08991": {
            "GenartName": "Kabelreparatursatz, Zigarettenanzünder",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Zigarettenanzünder"
        },
        "08992": {
            "GenartName": "Kabelreparatursatz, Umschaltventil-Kraftstoffbehälter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Umschaltventil-Kraftstoffbehälter"
        },
        "08993": {
            "GenartName": "Relais, Parkleuchte",
            "NormTermName": "Relais",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Parkleuchte"
        },
        "08994": {
            "GenartName": "Kabelreparatursatz, Stellelement-Zentralverriegelung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage",
            "UsageName": "Stellelement-Zentralverriegelung"
        },
        "08995": {
            "GenartName": "Druckplatte, Rückstellwerkzeug-Bremssattelkolben",
            "NormTermName": "Druckplatte",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Rückstellwerkzeug-Bremssattelkolben"
        },
        "08996": {
            "GenartName": "Spreizeinsatz-Set, Federbein",
            "NormTermName": "Spreizeinsatz-Set",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federbein"
        },
        "08997": {
            "GenartName": "Spreizeinsatz, Federbein",
            "NormTermName": "Spreizeinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federbein"
        },
        "08998": {
            "GenartName": "Dichtsitzfräser, Einspritzdüse",
            "NormTermName": "Dichtsitzfräser",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzdüse"
        },
        "08999": {
            "GenartName": "Adapter, Hydraulikpumpe",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Hydraulikpumpe"
        },
        "09000": {
            "GenartName": "Schlauch, Hydraulikpumpe",
            "NormTermName": "Schlauch",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Hydraulikpumpe"
        },
        "09001": {
            "GenartName": "Manschettensatz, Gelenkwelle",
            "NormTermName": "Manschettensatz",
            "AssGrpName": "Achsantrieb, Universal",
            "UsageName": "Gelenkwelle"
        },
        "09002": {
            "GenartName": "Montagewerkzeugsatz, Keilrippenriemen",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Keilrippenriemen"
        },
        "09003": {
            "GenartName": "Gabelsatz, Kugelgelenkabdrücker",
            "NormTermName": "Gabelsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kugelgelenkabdrücker"
        },
        "09004": {
            "GenartName": "Achsmutterneinsatz-Set, Rad",
            "NormTermName": "Achsmutterneinsatz-Set",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Radmontage"
        },
        "09005": {
            "GenartName": "Acrylglas-Einsatz, Verkaufswand",
            "NormTermName": "Einsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Verkaufswand"
        },
        "09006": {
            "GenartName": "Gehrungslade",
            "NormTermName": "Gehrungslade",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09007": {
            "GenartName": "Einziehwerkzeugsatz, Antriebswelle",
            "NormTermName": "Einziehwerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Antriebswelle"
        },
        "09008": {
            "GenartName": "Steckschlüsseleinsatz, Radmutter/-Bolzen",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radmutter/-Bolzen"
        },
        "09009": {
            "GenartName": "Steckschlüsseleinsatz, Einspritzleitung",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzleitung"
        },
        "09010": {
            "GenartName": "Abzieher, Spindelmutter",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "09011": {
            "GenartName": "Arbeitsschutz-Set",
            "NormTermName": "Arbeitsschutz-Set",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "09012": {
            "GenartName": "Messlatte, Riementrieb",
            "NormTermName": "Messlatte",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Riementrieb"
        },
        "09013": {
            "GenartName": "Sortiment, Clips",
            "NormTermName": "Sortiment",
            "AssGrpName": "Karosserie",
            "UsageName": "Halteclip-Karosserie"
        },
        "09014": {
            "GenartName": "Anschlussstück, Einspritzdüsenprüfgerät",
            "NormTermName": "Anschlussstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Einspritzdüsenprüfgerät"
        },
        "09015": {
            "GenartName": "Behälter, Einspritzdüsenprüfgerät",
            "NormTermName": "Behälter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Einspritzdüsenprüfgerät"
        },
        "09016": {
            "GenartName": "Sensor, Niveauregulierung",
            "NormTermName": "Sensor",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Niveauregulierung"
        },
        "09017": {
            "GenartName": "Dichtung, Getriebeausgangswelle (Schaltgetriebe)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeausgangswelle (Schaltgetriebe)"
        },
        "09018": {
            "GenartName": "Wellendichtringsatz, Kurbelwelle",
            "NormTermName": "Dichtringsatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwelle"
        },
        "09019": {
            "GenartName": "Dichtung, Getriebeausgangswelle (Automatikgetriebe)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Getriebeausgangswelle (Automatikgetriebe)"
        },
        "09020": {
            "GenartName": "Dichtung, Getriebeeingangswelle (Schaltgetriebe)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeeingangswelle (Schaltgetriebe)"
        },
        "09021": {
            "GenartName": "Lager, Getriebeausgangswelle (Verteilergetriebe)",
            "NormTermName": "Lager",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Getriebeausgangswelle (Verteilergetriebe)"
        },
        "09022": {
            "GenartName": "Sensor, Ladelufttemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Ladelufttemperatur"
        },
        "09023": {
            "GenartName": "Dichtung, Getriebeausgangswelle (Verteilergetriebe)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Getriebeausgangswelle (Verteilergetriebe)"
        },
        "09024": {
            "GenartName": "Düsenlehren-Satz, Kraftstoffdüse",
            "NormTermName": "Düsenlehren-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kraftstoffdüse"
        },
        "09025": {
            "GenartName": "Montagekeilsatz",
            "NormTermName": "Montagekeilsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "09026": {
            "GenartName": "Gabelschlüsselsatz",
            "NormTermName": "Gabelschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09027": {
            "GenartName": "Kabelreparatursatz, Vorwiderstand-Klimalüfter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Vorwiderstand-Klimalüfter"
        },
        "09028": {
            "GenartName": "Ausziehersatz, Zylinderlaufbuchse",
            "NormTermName": "Ausziehersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zylinderlaufbuchse"
        },
        "09029": {
            "GenartName": "Zugstück, Nietzange",
            "NormTermName": "Zugstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09030": {
            "GenartName": "Exzenterbuchse, Achsschenkel",
            "NormTermName": "Buchse",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Exzenterbuchse-Achsschenkel"
        },
        "09031": {
            "GenartName": "Differentialsperrenmodul",
            "NormTermName": "Differentialsperrenmodul",
            "AssGrpName": "Achsantrieb",
            "UsageName": null
        },
        "09032": {
            "GenartName": "Zierleistenkeil",
            "NormTermName": "Zierleistenkeil",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09033": {
            "GenartName": "Kabelreparatursatz, Generatorregler",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Generator",
            "UsageName": "Generatorregler"
        },
        "09034": {
            "GenartName": "Zierleistenkeilsatz",
            "NormTermName": "Zierleistenkeilsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09035": {
            "GenartName": "Lampenfassung, Seitenmarkierungsleuchte",
            "NormTermName": "Lampenfassung",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "09036": {
            "GenartName": "Führungsbuchse, Glühkerzendemontagewerkzeug",
            "NormTermName": "Führungsbuchse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glühkerzendemontagewerkzeug"
        },
        "09037": {
            "GenartName": "Drehzahlsensor, Getriebeausgangswelle (Automatikgetriebe)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Getriebeausgangswelle (Automatikgetriebe)"
        },
        "09038": {
            "GenartName": "Lager, Differentialgehäuse",
            "NormTermName": "Lager",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differentialgehäuse"
        },
        "09039": {
            "GenartName": "Dichtring, Schneckenwelle-Lenkgetriebe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Lenkung",
            "UsageName": "Schneckenwelle-Lenkgetriebe"
        },
        "09040": {
            "GenartName": "Ziehbolzen, Glühkerzendemontagewerkzeug",
            "NormTermName": "Ziehbolzen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glühkerzendemontagewerkzeug"
        },
        "09041": {
            "GenartName": "Kipphebelbolzen",
            "NormTermName": "Bolzen",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Kipphebelbolzen"
        },
        "09042": {
            "GenartName": "Relais, Zündanlage",
            "NormTermName": "Relais",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündanlage"
        },
        "09043": {
            "GenartName": "Kipphebelkugel",
            "NormTermName": "Kipphebelkugel",
            "AssGrpName": "Motorsteuerung",
            "UsageName": null
        },
        "09044": {
            "GenartName": "Sortiment, Isolierband",
            "NormTermName": "Sortiment",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "09045": {
            "GenartName": "Steckschlüsseleinsatz, Einspritzpumpe",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzpumpe"
        },
        "09046": {
            "GenartName": "Tankgeberschlüssel",
            "NormTermName": "Tankgeberschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Tankgeber"
        },
        "09048": {
            "GenartName": "Anzeige, Ladekontrollleuchte",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Ladekontrollleuchte"
        },
        "09049": {
            "GenartName": "Tankgeberschlüsselsatz",
            "NormTermName": "Tankgeberschlüsselsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Tankgeber"
        },
        "09050": {
            "GenartName": "Radsensorsatz, Reifendruck-Kontrollsystem",
            "NormTermName": "Sensorsatz",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "09051": {
            "GenartName": "Dichtung, Differentialgehäusedeckel",
            "NormTermName": "Dichtung",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differentialgehäusedeckel"
        },
        "09052": {
            "GenartName": "Stahlknebel, Schraubstock",
            "NormTermName": "Stahlknebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schraubstock"
        },
        "09053": {
            "GenartName": "Sturzkorrekturschraubensatz",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Sturzeinstellung"
        },
        "09054": {
            "GenartName": "Laufring, Differentialgehäuselager",
            "NormTermName": "Laufring",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differentialgehäuselager"
        },
        "09055": {
            "GenartName": "Zwischenwellenführung, Ölpumpe",
            "NormTermName": "Führung",
            "AssGrpName": "Schmierung",
            "UsageName": "Zwischenwellenführung-Ölpumpe"
        },
        "09056": {
            "GenartName": "Dichtungssatz, Getriebeausgangswelle (Automatikgetriebe)",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Getriebeausgangswelle (Automatikgetriebe)"
        },
        "09057": {
            "GenartName": "Dichtring, Ölpumpe-Automatikgetriebe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölpumpe-Automatikgetriebe"
        },
        "09058": {
            "GenartName": "Reparatursatz, Verdampfer-Klimaanlage",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Verdampfer-Klimaanlage"
        },
        "09059": {
            "GenartName": "Verschlussklemmensatz, Stahlleitung",
            "NormTermName": "Verschlussklemmensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09060": {
            "GenartName": "Schneidradachse, Rohrschneider",
            "NormTermName": "Schneidradachse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrschneider"
        },
        "09061": {
            "GenartName": "Lehrensatz, Spurstange",
            "NormTermName": "Lehrensatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Spurstange"
        },
        "09062": {
            "GenartName": "Steckschlüsseleinsatz, Spurstangenkugelkopf",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Spurstangenkugelkopf"
        },
        "09063": {
            "GenartName": "Abzieher, Spindelmutter",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09064": {
            "GenartName": "Sortiment, Spreiz-/Ankerdübel",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "09065": {
            "GenartName": "Saugnapf, Scheiben-Ablagetisch",
            "NormTermName": "Saugnapf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09066": {
            "GenartName": "Schlauch, Kühlsystemdruckprüfset",
            "NormTermName": "Schlauch",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kühlsystemdruckprüfset"
        },
        "09067": {
            "GenartName": "Sortiment, Sechskantmuttern",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "09068": {
            "GenartName": "Sortiment, Karabinerhaken",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "09069": {
            "GenartName": "Sortiment, Schraubhaken",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "09070": {
            "GenartName": "Schraubensicherungsdraht",
            "NormTermName": "Schraubensicherungsdraht",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "09071": {
            "GenartName": "Aufnahme, Schleifpad",
            "NormTermName": "Aufnahme",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09072": {
            "GenartName": "Montagewerkzeugsatz, Bremskolben",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Bremssattelkolben"
        },
        "09073": {
            "GenartName": "Diamantbohrkronen-Satz",
            "NormTermName": "Diamantbohrkronen-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09074": {
            "GenartName": "Massekabel, Zündspule",
            "NormTermName": "Massekabel",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündspule"
        },
        "09075": {
            "GenartName": "Reparatursatz, Gelenkgriff",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gelenkgriff"
        },
        "09076": {
            "GenartName": "Reparatursatz, Nadelentroster (Druckluft)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Nadelentroster"
        },
        "09077": {
            "GenartName": "Entlüftungswerkzeugsatz, Kraftstoffsystem",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kraftstoffsystem"
        },
        "09078": {
            "GenartName": "Fühlerlehre",
            "NormTermName": "Lehre",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "09079": {
            "GenartName": "Einstelllehre, Kupplungsbetätigung",
            "NormTermName": "Lehre",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Grundeinstellung Kupplungsbetätigung"
        },
        "09080": {
            "GenartName": "Arretierwerkzeug, Einspritzpumpe",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzpumpe"
        },
        "09081": {
            "GenartName": "Relais, Rundumkennleuchte",
            "NormTermName": "Relais",
            "AssGrpName": "Signalanlage",
            "UsageName": "Rundumkennleuchte"
        },
        "09082": {
            "GenartName": "Behälter, Öl-Entlüftungsgerät",
            "NormTermName": "Behälter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09083": {
            "GenartName": "Dichtungssatz, Stößelstange",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Stößelstange"
        },
        "09084": {
            "GenartName": "Hülsensatz, Kabelverbinder-Lösewerkzeug",
            "NormTermName": "Hülsensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kabelverbinder"
        },
        "09085": {
            "GenartName": "Hydraulikzylinder, Werkstattkran",
            "NormTermName": "Hydraulikzylinder",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Werkstattkran"
        },
        "09086": {
            "GenartName": "Entlüftungswerkzeugsatz, Hauptbremszylinder",
            "NormTermName": "Werkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Hauptbremszylinder"
        },
        "09087": {
            "GenartName": "Ersatzklappe, Refraktometer",
            "NormTermName": "Ersatzklappe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Refraktometer"
        },
        "09088": {
            "GenartName": "Gabelkappenschlüssel",
            "NormTermName": "Gabelkappenschlüssel",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": null
        },
        "09089": {
            "GenartName": "Impulsgeberrad, Zündverteiler",
            "NormTermName": "Impulsgeberrad",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündverteiler"
        },
        "09090": {
            "GenartName": "Eintreiber, Gabelsimmerring",
            "NormTermName": "Eintreiber",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Gabel (Zweirad)"
        },
        "09091": {
            "GenartName": "Zahnradsatz, Fensterhebermotor",
            "NormTermName": "Zahnradsatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Fensterhebermotor"
        },
        "09092": {
            "GenartName": "Rolle, Werkstattliege",
            "NormTermName": "Rolle",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkstattliege"
        },
        "09093": {
            "GenartName": "Ausgleichsscheibe, Schwungrad",
            "NormTermName": "Scheibe",
            "AssGrpName": "Kupplung",
            "UsageName": "Schwungrad"
        },
        "09094": {
            "GenartName": "Drehstahlsatz, Drehmaschine",
            "NormTermName": "Drehstahlsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmaschine"
        },
        "09095": {
            "GenartName": "Sortiment, Federkeil",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "09096": {
            "GenartName": "Nachfüllsortiment, Tacker",
            "NormTermName": "Nachfüllsortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Tacker"
        },
        "09097": {
            "GenartName": "Sortiment, Zug-/Druckfedern",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "09098": {
            "GenartName": "Rolle, Werkstatthocker",
            "NormTermName": "Rolle",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkstatthocker"
        },
        "09099": {
            "GenartName": "Sonde, Lecksuchgerät (Klimaanlage)",
            "NormTermName": "Sonde",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimaanlage"
        },
        "09100": {
            "GenartName": "Kabelreparatursatz, Hydraulikpumpe-Lenkung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Hydraulikpumpe-Lenkung"
        },
        "09101": {
            "GenartName": "Ersatzspitze, Körner",
            "NormTermName": "Ersatzspitze",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Körner"
        },
        "09102": {
            "GenartName": "Ersatzspitze, Sandstrahlpistole",
            "NormTermName": "Ersatzspitze",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Sandstrahlpistole"
        },
        "09103": {
            "GenartName": "Sortiment, Ösendruckknopf",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Ösenzange"
        },
        "09104": {
            "GenartName": "Sortiment, Krokodilklemme",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "09105": {
            "GenartName": "Kabelreparatursatz, Sensor-Luftgüte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Sensor-Luftgüte"
        },
        "09106": {
            "GenartName": "Steckschlüsselsatz, Getriebeflansch",
            "NormTermName": "Steckschlüsselsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Getriebeflansch"
        },
        "09107": {
            "GenartName": "Kabelreparatursatz, Sirene-Alarmanlage",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage",
            "UsageName": "Sirene-Alarmanlage"
        },
        "09108": {
            "GenartName": "Kabelreparatursatz, Sensor-Luftgüte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Sensor-Luftgüte"
        },
        "09109": {
            "GenartName": "Kabelreparatursatz, Sirene-Alarmanlage",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": null
        },
        "09110": {
            "GenartName": "Kabelreparatursatz, Kontaktschalter-Motorhaube",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Kontaktschalter-Motorhaube"
        },
        "09111": {
            "GenartName": "Kabelreparatursatz, Kontaktschalter-Motorhaube",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Kontaktschalter-Motorhaube"
        },
        "09112": {
            "GenartName": "Scheibenaustrennschnur",
            "NormTermName": "Scheibenaustrennschnur",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Scheibendemontage"
        },
        "09113": {
            "GenartName": "Kabelreparatursatz, Längs-/Querbeschleunigungssensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Fahrdynamikregelung",
            "UsageName": "Längs-/Querbeschleunigungssensor"
        },
        "09114": {
            "GenartName": "Kabelreparatursatz, Längs-/Querbeschleunigungssensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Fahrdynamikregelung, Universal",
            "UsageName": "Längs-/Querbeschleunigungssensor"
        },
        "09115": {
            "GenartName": "Rolle, Rangierheber",
            "NormTermName": "Rolle",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rangierheber"
        },
        "09116": {
            "GenartName": "Sortiment, Ösensatz",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": "Ösenzange"
        },
        "09117": {
            "GenartName": "Kabelreparatursatz, Kühlmittelregelventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Kühlmittelregelventil"
        },
        "09118": {
            "GenartName": "Kabelreparatursatz, Kühlmittelregelventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung, Universal",
            "UsageName": "Kühlmittelregelventil"
        },
        "09119": {
            "GenartName": "Kabelreparatursatz, Hydraulikpumpe-Lenkung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Lenkung, Universal",
            "UsageName": "Hydraulikpumpe-Lenkung"
        },
        "09120": {
            "GenartName": "Kabelreparatursatz, Lenkung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenkung"
        },
        "09121": {
            "GenartName": "Kabelreparatursatz, Lenkung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Lenkung, Universal",
            "UsageName": "Lenkung"
        },
        "09122": {
            "GenartName": "Einziehdraht",
            "NormTermName": "Einziehdraht",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "09123": {
            "GenartName": "Schneidkluppensatz",
            "NormTermName": "Schneidkluppensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09124": {
            "GenartName": "Hebel, Querlenker",
            "NormTermName": "Hebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Querlenker"
        },
        "09125": {
            "GenartName": "Montagewerkzeugsatz, Kurbelwellendichtring/Führungslager",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kurbelwellendichtring/Führungslager"
        },
        "09126": {
            "GenartName": "Montagewerkzeug, Radialdichtring",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kurbelwellendichtring"
        },
        "09127": {
            "GenartName": "Montagewerkzeug, Radialdichtring",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09128": {
            "GenartName": "Ölspritze",
            "NormTermName": "Ölspritze",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09129": {
            "GenartName": "Kopfband, Schutzhelm",
            "NormTermName": "Kopfband",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Schutzhelm"
        },
        "09130": {
            "GenartName": "Rolle, Getriebeheber",
            "NormTermName": "Rolle",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Getriebeheber"
        },
        "09131": {
            "GenartName": "Sortiment, Federring",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "09132": {
            "GenartName": "Spann- und Einstellvorrichtung, Federbein",
            "NormTermName": "Spann- und Einstellvorrichtung",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federbein"
        },
        "09133": {
            "GenartName": "Fettsignierstifte",
            "NormTermName": "Fettsignierstifte",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09134": {
            "GenartName": "Montagehebel, Getriebe",
            "NormTermName": "Montagehebel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Getriebe"
        },
        "09135": {
            "GenartName": "CVT-Antriebsriemen",
            "NormTermName": "Riemen",
            "AssGrpName": "Riementrieb, Universal",
            "UsageName": "CVT"
        },
        "09136": {
            "GenartName": "Messschieber, Hubkolbenhöhe",
            "NormTermName": "Messschieber",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "09137": {
            "GenartName": "Druckstück, Wischarm",
            "NormTermName": "Druckstück",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Wischarm"
        },
        "09138": {
            "GenartName": "Ablagefachsatz, Werkzeugwagen",
            "NormTermName": "Ablagefachsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "09139": {
            "GenartName": "Halter, Generator-Antriebsflansch",
            "NormTermName": "Halter",
            "AssGrpName": "Spezialwerkzeuge, Zweirad-Universal",
            "UsageName": "Generator"
        },
        "09140": {
            "GenartName": "Reparatursatz, Wischarm",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Wischarm"
        },
        "09141": {
            "GenartName": "Kompressionsdruckprüfset",
            "NormTermName": "Kompressionsdruckprüfset",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Motor"
        },
        "09142": {
            "GenartName": "Bohrkronensatz",
            "NormTermName": "Bohrkronensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bohrmaschine"
        },
        "09143": {
            "GenartName": "Fliesenheber",
            "NormTermName": "Fliesenheber",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "09144": {
            "GenartName": "Dichtung, Füll-/ Entnahmepumpe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09145": {
            "GenartName": "Kaltgerätesteckdose, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Kaltgerätesteckdose",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "09146": {
            "GenartName": "Folientastatur, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Folientastatur",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "09147": {
            "GenartName": "Platine, Füll-/Entlüftungsgerät (Bremshydraulik)",
            "NormTermName": "Platine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Füll-/Entlüftungsgerät (Bremshydraulik)"
        },
        "09148": {
            "GenartName": "Erweiterungssatz, Montagewerkzeug (Kupplung)",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kupplung"
        },
        "09149": {
            "GenartName": "Gelenk-Steckschlüsseleinsatz, Getriebeölschraube",
            "NormTermName": "Gelenk-Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Getriebeöl"
        },
        "09150": {
            "GenartName": "Einstellplatte, Spann- und Einstellvorrichtung (Federbein)",
            "NormTermName": "Einstellplatte",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Spann- und Einstellvorrichtung (Federbein)"
        },
        "09151": {
            "GenartName": "Zugglocke, Wischarmabzieher",
            "NormTermName": "Zugglocke",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Wischarmabzieher"
        },
        "09152": {
            "GenartName": "Erweiterungssatz, Spann- und Einstellvorrichtung (Federbein)",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Spann- und Einstellvorrichtung (Federbein)"
        },
        "09153": {
            "GenartName": "Grundkörper, Kupplungszentrierwerkzeug",
            "NormTermName": "Grundkörper",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kupplung"
        },
        "09154": {
            "GenartName": "Adapter, Kupplungszentrierwerkzeug",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kupplung"
        },
        "09155": {
            "GenartName": "Führungsbolzen, Ein-/Auspresswerkzeug",
            "NormTermName": "Führungsbolzen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ein-/Auspresswerkzeug"
        },
        "09156": {
            "GenartName": "Auszieher, Radnabe",
            "NormTermName": "Auszieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radnabe"
        },
        "09157": {
            "GenartName": "Ausziehersatz, Radnabe",
            "NormTermName": "Ausziehersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radnabe"
        },
        "09158": {
            "GenartName": "Spreizeinsatz, Federbein",
            "NormTermName": "Spreizeinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federbein"
        },
        "09159": {
            "GenartName": "Erweiterungssatz, Spreizeinsatz (Federbein)",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federbein"
        },
        "09160": {
            "GenartName": "Druckschraube, Spreizeinsatz (Federbein)",
            "NormTermName": "Druckschraube",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federbein"
        },
        "09161": {
            "GenartName": "Erweiterungssatz, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radnabe/Radlager"
        },
        "09162": {
            "GenartName": "Distanzplatte, Ausziehersatz",
            "NormTermName": "Distanzplatte",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "09163": {
            "GenartName": "Druckschraube, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Druckschraube",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radnabe/Radlager"
        },
        "09164": {
            "GenartName": "Spannbackenpaar, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Spannbackenpaar",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radnabe/Radlager"
        },
        "09165": {
            "GenartName": "Adapterplatte, Montagewerkzeug (Radnabe/Radlager)",
            "NormTermName": "Adapterplatte",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radnabe/Radlager"
        },
        "09166": {
            "GenartName": "Adaptersatz, Schlagabzieher",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Schlagabzieher"
        },
        "09167": {
            "GenartName": "Lochscheibe, Abzieher",
            "NormTermName": "Lochscheibe",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Abzieher"
        },
        "09168": {
            "GenartName": "Haltevorrichtung, Radnabe",
            "NormTermName": "Haltevorrichtung",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radnabe"
        },
        "09169": {
            "GenartName": "Wandhalterung, Drehmomentbegrenzer",
            "NormTermName": "Wandhalterung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentbegrenzer"
        },
        "09170": {
            "GenartName": "Steckschlüsselsatz, Ausdrehwerkzeug",
            "NormTermName": "Steckschlüsselsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Radmutter/-Bolzen"
        },
        "09171": {
            "GenartName": "Steckschlüsseleinsatz, Ausdrehwerkzeug",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Radmutter/-Bolzen"
        },
        "09172": {
            "GenartName": "Abgreifklemme, Prüfgerät",
            "NormTermName": "Abgreifklemme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Prüfgerät-KFZ (Elektrik)"
        },
        "09173": {
            "GenartName": "Adapter, Bürstenband",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Multi-Schleifer"
        },
        "09174": {
            "GenartName": "Adapter, Einzel-Eliminatorscheibe",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Multi-Schleifer"
        },
        "09175": {
            "GenartName": "Adapter, Doppel-Eliminatorscheibe",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Multi-Schleifer"
        },
        "09176": {
            "GenartName": "Dosierhandpupme",
            "NormTermName": "Dosierhandpupme",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09177": {
            "GenartName": "Adapter, Einziehwerkzeug (Antriebswelle)",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einziehwerkzeug (Antriebswelle)"
        },
        "09178": {
            "GenartName": "Erweiterungssatz, Einziehwerkzeug (Antriebswelle)",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einziehwerkzeug (Antriebswelle)"
        },
        "09179": {
            "GenartName": "Steckschlüssel, Differentialflansch",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Differentialflansch"
        },
        "09180": {
            "GenartName": "Steckschlüssel, Getriebeflansch",
            "NormTermName": "Steckschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Getriebeflansch"
        },
        "09181": {
            "GenartName": "Zapfenschlüssel, Radlager",
            "NormTermName": "Zapfenschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radlager"
        },
        "09182": {
            "GenartName": "Zapfenschlüssel, Differentialflansch",
            "NormTermName": "Zapfenschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Differentialflansch"
        },
        "09183": {
            "GenartName": "Zapfenschlüssel, Getriebeflansch",
            "NormTermName": "Zapfenschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Getriebeflansch"
        },
        "09184": {
            "GenartName": "Zapfenschlüssel, Wellenflansch",
            "NormTermName": "Zapfenschlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Hinterachse"
        },
        "09185": {
            "GenartName": "Halterung, Notebook",
            "NormTermName": "Halterung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Notebook"
        },
        "09186": {
            "GenartName": "Halteclip, Türverkleidung",
            "NormTermName": "Clip",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Türverkleidung"
        },
        "09187": {
            "GenartName": "Halteclip, Bodenbelagsauskleidung (Teppich)",
            "NormTermName": "Clip",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Bodenbelagsauskleidung (Teppich)"
        },
        "09188": {
            "GenartName": "Haltefeder, Fahrzeugsäulenverkleidung",
            "NormTermName": "Clip",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Fahrzeugsäulenverkleidung"
        },
        "09189": {
            "GenartName": "Halteclip, Koffer-/Laderaumverkleidung",
            "NormTermName": "Clip",
            "AssGrpName": "Karosserie",
            "UsageName": "Koffer-/Laderaumverkleidung"
        },
        "09190": {
            "GenartName": "Halteclip, Motorhaubendämmung",
            "NormTermName": "Clip",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorhaubendämmung"
        },
        "09191": {
            "GenartName": "Halteclip, Unterbodenverkleidung",
            "NormTermName": "Clip",
            "AssGrpName": "Karosserie",
            "UsageName": "Unterbodenverkleidung"
        },
        "09192": {
            "GenartName": "Kabelreparatursatz, Waschwasserpumpe-Scheibenreinigung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": "Waschwasserpumpe-Scheibenreinigung"
        },
        "09193": {
            "GenartName": "Kabelreparatursatz, Fahrpedalsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Fahrpedalsensor"
        },
        "09194": {
            "GenartName": "Kabelreparatursatz, Fahrpedalsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Fahrpedalsensor"
        },
        "09195": {
            "GenartName": "Pumpeneinheit, Druckluftsprühflasche",
            "NormTermName": "Pumpeneinheit",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Druckluftsprühflasche"
        },
        "09196": {
            "GenartName": "Lösewerkzeug, Druckluft-Schnellverbinder",
            "NormTermName": "Lösewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Druckluft-Schnellverbinder"
        },
        "09197": {
            "GenartName": "Schutzhülse, Reifenmontagehebel",
            "NormTermName": "Schutzhülse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Reifenmontagehebel"
        },
        "09198": {
            "GenartName": "Gelenkstück, Schlagschrauber",
            "NormTermName": "Gelenkstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "09199": {
            "GenartName": "Reduzieradapter, Schlagschrauber",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "09200": {
            "GenartName": "Lamellensatz, Schlagschrauber",
            "NormTermName": "Lamellensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "09201": {
            "GenartName": "Halter, Schlagschrauber",
            "NormTermName": "Halter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "09202": {
            "GenartName": "Lamellensatz, Stabschleifer",
            "NormTermName": "Lamellensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stabschleifer"
        },
        "09203": {
            "GenartName": "Betätigungshebel, Stabschleifer",
            "NormTermName": "Betätigungshebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stabschleifer"
        },
        "09204": {
            "GenartName": "Betätigungshebel, Winkelschleifer",
            "NormTermName": "Betätigungshebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "09205": {
            "GenartName": "Messersatz, Scheibenaustrenngerät",
            "NormTermName": "Messersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Scheibenaustrenngerät"
        },
        "09206": {
            "GenartName": "Venturidüse, Druckluftpistole",
            "NormTermName": "Düse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Druckluftpistole"
        },
        "09207": {
            "GenartName": "Kopf, Reifenabdrücker",
            "NormTermName": "Kopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Reifenabdrücker"
        },
        "09208": {
            "GenartName": "Kopfführung, Reifenabdrücker",
            "NormTermName": "Führung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Reifenabdrücker"
        },
        "09209": {
            "GenartName": "Strahlmittel, Sodastrahlpistole",
            "NormTermName": "Strahlmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Sodastrahlpistole"
        },
        "09210": {
            "GenartName": "Planetengetriebe, Schlagschrauber",
            "NormTermName": "Planetengetriebe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "09211": {
            "GenartName": "Wartungsservice, Drehmomentschlüssel",
            "NormTermName": "Wartungsservice",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drehmomentschlüssel"
        },
        "09212": {
            "GenartName": "Sortiment, Ölablassschraube",
            "NormTermName": "Sortiment",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölablassschraube"
        },
        "09213": {
            "GenartName": "Kabelreparatursatz, Druckwandler",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "Druckwandler"
        },
        "09214": {
            "GenartName": "Kabelreparatursatz, Druckwandler",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasrückführung, Universal",
            "UsageName": "Druckwandler"
        },
        "09215": {
            "GenartName": "Reparatursatz, Wählwelle (Automatikgetriebe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Wählwelle"
        },
        "09216": {
            "GenartName": "Abstandshalter",
            "NormTermName": "Abstandshalter",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "09217": {
            "GenartName": "Heizungsschlauch",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizungskühler"
        },
        "09218": {
            "GenartName": "Schaltzylinder (Schaltgetriebe)",
            "NormTermName": "Zylinder",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "09219": {
            "GenartName": "Schalter, Einparkhilfe",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Einparkhilfe"
        },
        "09220": {
            "GenartName": "Ansaugschnorchel",
            "NormTermName": "Schnorchel",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ansaugluft"
        },
        "09221": {
            "GenartName": "Schalter, Bremsbetätigung (GRA)",
            "NormTermName": "Schalter",
            "AssGrpName": "Geschwindigkeitsregelanlage",
            "UsageName": "Bremsbetätigung (GRA)"
        },
        "09222": {
            "GenartName": "Montage-Hebehilfe",
            "NormTermName": "Montage-Hebehilfe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09223": {
            "GenartName": "Schwenklager, Montage-Hebehilfe",
            "NormTermName": "Schwenklager",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Montage-Hebehilfe"
        },
        "09224": {
            "GenartName": "Betätigungsschalter, Multi-Schleifer",
            "NormTermName": "Betätigungsschalter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Multi-Schleifer"
        },
        "09225": {
            "GenartName": "Antriebskopf, Tiefenschleifer",
            "NormTermName": "Antriebskopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Tiefenschleifer"
        },
        "09226": {
            "GenartName": "Betätigungshebel, Tiefenschleifer",
            "NormTermName": "Betätigungshebel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Tiefenschleifer"
        },
        "09227": {
            "GenartName": "Stellelement, Verteilergetriebe",
            "NormTermName": "Stellelement",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Verteilergetriebe"
        },
        "09228": {
            "GenartName": "Spanngreifer, Federspanner",
            "NormTermName": "Spanngreifer",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federspanner"
        },
        "09229": {
            "GenartName": "Dichtringsatz, Schlagschrauber",
            "NormTermName": "Dichtringsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "09230": {
            "GenartName": "Endplatte, Schlagschrauber",
            "NormTermName": "Endplatte",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "09231": {
            "GenartName": "Befestigungsmutter, Spannfutter (Stabschleifer)",
            "NormTermName": "Mutter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Spannfutter (Stabschleifer)"
        },
        "09232": {
            "GenartName": "Drehring, Schlagschrauber",
            "NormTermName": "Drehring",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "09233": {
            "GenartName": "Befestigungssatz, Tiefenschleifer",
            "NormTermName": "Befestigungssatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Tiefenschleifer"
        },
        "09234": {
            "GenartName": "Verbindungsmutter, Stabschleifer",
            "NormTermName": "Mutter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stabschleifer"
        },
        "09235": {
            "GenartName": "Kegelradgetriebe, Stabschleifer",
            "NormTermName": "Getriebe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stabschleifer"
        },
        "09236": {
            "GenartName": "Befestigungssatz, Stab-Trennschleifer",
            "NormTermName": "Befestigungssatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stab-Trennschleifer"
        },
        "09237": {
            "GenartName": "Sortiment, Abstandshalter",
            "NormTermName": "Sortiment",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Abstandshalter"
        },
        "09238": {
            "GenartName": "Kabelreparatursatz, Kondensationssensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kondensationssensor"
        },
        "09239": {
            "GenartName": "Kabelreparatursatz, Kondensationssensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Kondensationssensor"
        },
        "09240": {
            "GenartName": "Kabelreparatursatz, Drucksensor (Hydraulikaggregat)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Drucksensor (Hydraulikaggregat)"
        },
        "09241": {
            "GenartName": "Kabelreparatursatz, Drucksensor (Hydraulikaggregat)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Drucksensor (Hydraulikaggregat)"
        },
        "09242": {
            "GenartName": "Kabelreparatursatz, Stoßdämpfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Stoßdämpfer"
        },
        "09243": {
            "GenartName": "Kabelreparatursatz, Stoßdämpfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Federung/Dämpfung, Universal",
            "UsageName": "Stoßdämpfer"
        },
        "09244": {
            "GenartName": "Befestigungsmutter, Tiefenschleifer",
            "NormTermName": "Mutter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Tiefenschleifer"
        },
        "09245": {
            "GenartName": "Kegelradgetriebe, Tiefenschleifer",
            "NormTermName": "Getriebe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Tiefenschleifer"
        },
        "09246": {
            "GenartName": "Anschlussrohr, Tiefenschleifer",
            "NormTermName": "Anschlussrohr",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Tiefenschleifer"
        },
        "09247": {
            "GenartName": "Lamellensatz, Poliermaschine",
            "NormTermName": "Lamellensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Poliermaschine"
        },
        "09248": {
            "GenartName": "Planetengetriebe, Poliermaschine",
            "NormTermName": "Planetengetriebe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Poliermaschine"
        },
        "09249": {
            "GenartName": "Sperrknopfsatz, Tiefenschleifer",
            "NormTermName": "Sperrknopfsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Tiefenschleifer"
        },
        "09250": {
            "GenartName": "Abdeckscheibe, Tiefenschleifer",
            "NormTermName": "Abdeckscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Tiefenschleifer"
        },
        "09251": {
            "GenartName": "Lamellensatz, Tiefenschleifer",
            "NormTermName": "Lamellensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Tiefenschleifer"
        },
        "09252": {
            "GenartName": "Lagerhalter, Schlagschrauber",
            "NormTermName": "Halter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "09253": {
            "GenartName": "Scheibensatz, Schlagschrauber",
            "NormTermName": "Scheibensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber"
        },
        "09254": {
            "GenartName": "Motorscheibensatz, Schlagschrauber",
            "NormTermName": "Scheibensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlagschrauber-Motor"
        },
        "09255": {
            "GenartName": "Scheibenarretierung, Winkelschleifer",
            "NormTermName": "Scheibenarretierung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "09256": {
            "GenartName": "Ansaugleitung, Stabschleifer",
            "NormTermName": "Ansaugleitung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stabschleifer"
        },
        "09257": {
            "GenartName": "Befestigungsmutternsatz, Winkelschleifer",
            "NormTermName": "Mutternsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "09258": {
            "GenartName": "Reiniger, Turbolader",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Turbolader"
        },
        "09259": {
            "GenartName": "Montagesatz, Axialgelenk (Spurstange)",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Axialgelenk"
        },
        "09260": {
            "GenartName": "Montagesatz, Spurstange",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Spurstange"
        },
        "09261": {
            "GenartName": "Montagesatz, Achskörper",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Achskörper"
        },
        "09262": {
            "GenartName": "Montagesatz, Lagerung-Lenker",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Lagerung-Lenker"
        },
        "09263": {
            "GenartName": "Sortiment, Kabelreparatursatz",
            "NormTermName": "Sortiment",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kabelreparatursatz"
        },
        "09264": {
            "GenartName": "Kabelreparatursatz, Ladedruckregelventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladedruckregelventil"
        },
        "09265": {
            "GenartName": "Kabelreparatursatz, Ladedruckregelventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung, Universal",
            "UsageName": "Ladedruckregelventil"
        },
        "09266": {
            "GenartName": "Dichtungssatz, Kompressionsdruckprüfer",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kompressionsdruckprüfer"
        },
        "09267": {
            "GenartName": "Lösewerkzeug, Stoßdämpfer",
            "NormTermName": "Lösewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Stoßdämpfer"
        },
        "09268": {
            "GenartName": "Ansaugbalg, Prüfgerät-Zylinderkopfdichtigkeit",
            "NormTermName": "Balg",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Prüfgerät-Zylinderkopfdichtigkeit"
        },
        "09269": {
            "GenartName": "Abstützplatte, Ein-/Auspresswerkzeug-Satz",
            "NormTermName": "Abstützplatte",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ein-/Auspresswerkzeug-Satz"
        },
        "09270": {
            "GenartName": "Hülse, Ein-/Auspresswerkzeug-Satz",
            "NormTermName": "Hülse",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ein-/Auspresswerkzeug-Satz"
        },
        "09271": {
            "GenartName": "Aufsatz, Schaber",
            "NormTermName": "Aufsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schaber"
        },
        "09272": {
            "GenartName": "Haltewerkzeugsatz, Kurbelwelle",
            "NormTermName": "Haltewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kurbelwelle"
        },
        "09273": {
            "GenartName": "Reparatursatz, Steinschlag (Scheibe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steinschlag (Scheibe)"
        },
        "09274": {
            "GenartName": "Lösewerkzeug, Verschlussdeckel-Kühler",
            "NormTermName": "Lösewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Verschlussdeckel-Kühler"
        },
        "09275": {
            "GenartName": "Druckschraubensatz, Montagewerkzeug (Antriebswellengelenk)",
            "NormTermName": "Druckschraubensatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Montagewerkzeug (Antriebswellengelenk)"
        },
        "09276": {
            "GenartName": "Halterung, Pressrahmen-Traggelenk",
            "NormTermName": "Halterung",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Pressrahmen-Traggelenk"
        },
        "09277": {
            "GenartName": "Montagewerkzeugsatz, Bremssattel",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremssattel"
        },
        "09278": {
            "GenartName": "Lösewerkzeugsatz, Druckluft-Schnellverbinder",
            "NormTermName": "Lösewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Druckluft-Schnellverbinder"
        },
        "09279": {
            "GenartName": "Pressrahmen, Traggelenk",
            "NormTermName": "Pressrahmen",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "09280": {
            "GenartName": "Prüfgerätesatz, Kühlflüssigkeitsfluss (Wasserpumpe)",
            "NormTermName": "Prüfgerätesatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kühlflüssigkeitsfluss (Wasserpumpe)"
        },
        "09281": {
            "GenartName": "Schlauch, Prüfgerätesatz-Kühlflüssigkeitsfluss",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Prüfgerätesatz-Kühlflüssigkeitsfluss (Wassepumpe)"
        },
        "09282": {
            "GenartName": "Schraubhilfe",
            "NormTermName": "Schraubhilfe",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09283": {
            "GenartName": "Schweißpistole, Dellenreparatur (Netzgebunden)",
            "NormTermName": "Schweißpistole (Netzgebunden)",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Dellenreparatur (Netzgebunden)"
        },
        "09284": {
            "GenartName": "Gegenhalter, Schweißpunkt-Bohrmaschine",
            "NormTermName": "Gegenhalter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schweißpunkt-Bohrmaschine"
        },
        "09285": {
            "GenartName": "Aufnahmesatz, Wagenheber",
            "NormTermName": "Aufnahmesatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Wagenheber"
        },
        "09286": {
            "GenartName": "Anschlussstutzen, Prüfgerät-Kraftstoffsystemdruck",
            "NormTermName": "Stutzen",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Prüfgerät-Kraftstoffsystemdruck"
        },
        "09287": {
            "GenartName": "Spurstangen-Axialgelenkschlüsselsatz",
            "NormTermName": "Spurstangen-Axialgelenkschlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "09288": {
            "GenartName": "Behälter, Prüfgerät-Kraftstoffsystemdruck",
            "NormTermName": "Behälter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Prüfgerät-Kraftstoffsystemdruck"
        },
        "09289": {
            "GenartName": "Blattbohrer, Rohrleitung (Sanitär)",
            "NormTermName": "Bohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrleitung (Sanitär)"
        },
        "09290": {
            "GenartName": "Adapter, Öldruckprüfgerät",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Öldruckprüfgerät"
        },
        "09291": {
            "GenartName": "Spannvorrichtung, Kupplungsdruckplatte",
            "NormTermName": "Spannvorrichtung",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kupplungsdruckplatte"
        },
        "09292": {
            "GenartName": "Kabelreparatursatz, Nockenwellenversteller",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Motorsteuerung, Universal",
            "UsageName": "Nockenwellenversteller"
        },
        "09293": {
            "GenartName": "Kabelreparatursatz, Nockenwellenversteller",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwellenversteller"
        },
        "09294": {
            "GenartName": "Spül-/Reinigungspistole, Kühlsystem",
            "NormTermName": "Spül-/Reinigungspistole",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Kühlsystem"
        },
        "09295": {
            "GenartName": "Stufenplatte, Ausdrückersatz-Kugelgelenk",
            "NormTermName": "Stufenplatte",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ausdrückersatz-Kugelgelenk"
        },
        "09296": {
            "GenartName": "Heizung, Lufttrockner",
            "NormTermName": "Heizung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Lufttrockner"
        },
        "09297": {
            "GenartName": "Druckwandler, Kühlmittelregelventil",
            "NormTermName": "Druckwandler",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Kühlmittelregelventil"
        },
        "09298": {
            "GenartName": "Kabelreparatursatz, Ladedrucksensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Ladedrucksensor"
        },
        "09299": {
            "GenartName": "Kabelreparatursatz, Ladedrucksensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Ladedrucksensor"
        },
        "09300": {
            "GenartName": "Kabelreparatursatz, Zusatzwasserpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Zusatzwasserpumpe"
        },
        "09301": {
            "GenartName": "Kabelreparatursatz, Zusatzwasserpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Zusatzwasserpumpe"
        },
        "09302": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Motor-Sound-System",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Zubehör",
            "UsageName": "Steuergerät-Motor-Sound-System"
        },
        "09303": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Motor-Sound-System",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Steuergerät-Motor-Sound-System"
        },
        "09304": {
            "GenartName": "Leerlaufschalter, Automatikgetriebe",
            "NormTermName": "Schalter",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Leerlauf"
        },
        "09305": {
            "GenartName": "Leerlaufschalter, Schaltgetriebe",
            "NormTermName": "Schalter",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Leerlauf"
        },
        "09306": {
            "GenartName": "Flansch, Sensor-Nockenwellenposition",
            "NormTermName": "Flansch",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Sensor-Nockenwellenposition"
        },
        "09307": {
            "GenartName": "Schlag-/Lösehebel, Riemenscheibenschraube",
            "NormTermName": "Schlag-/Lösehebel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Riemenscheibenschraube"
        },
        "09308": {
            "GenartName": "Karosseriedach",
            "NormTermName": "Karosseriedach",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "09309": {
            "GenartName": "Zusatzwasserpumpe (Heizwasserkreislauf)",
            "NormTermName": "Pumpe",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Zusatzpumpe"
        },
        "09310": {
            "GenartName": "Reparatursatz, ABS-Sensor",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "ABS-Sensor"
        },
        "09311": {
            "GenartName": "Kabelreparatursatz, Wasserpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "09312": {
            "GenartName": "Kabelreparatursatz, Wasserpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Wasserpumpe"
        },
        "09313": {
            "GenartName": "Kabelreparatursatz, Drucksensor-Sekundärluftpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": "Drucksensor-Sekundärluftpumpe"
        },
        "09314": {
            "GenartName": "Kabelreparatursatz, Drucksensor-Sekundärluftpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sekundärlufteinblasung, Universal",
            "UsageName": "Drucksensor-Sekundärluftpumpe"
        },
        "09315": {
            "GenartName": "Kabelreparatursatz, Sperrventil-Zylinderabschaltung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Sperrventil-Zylinderabschaltung"
        },
        "09316": {
            "GenartName": "Kabelreparatursatz, Sperrventil-Zylinderabschaltung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Sperrventil-Zylinderabschaltung"
        },
        "09317": {
            "GenartName": "Kabelreparatursatz, Drucksensor-Bremskraftverstärker",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Drucksensor-Bremskraftverstärker"
        },
        "09318": {
            "GenartName": "Kabelreparatursatz, Drucksensor-Bremskraftverstärker",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Drucksensor-Bremskraftverstärker"
        },
        "09319": {
            "GenartName": "Kabelreparatursatz, Wasserabscheider (Kraftstofffilter)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Wasserabscheider (Kraftstofffilter)"
        },
        "09320": {
            "GenartName": "Kabelreparatursatz, Wasserabscheider (Kraftstofffilter)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Wasserabscheider (Kraftstofffilter)"
        },
        "09321": {
            "GenartName": "Kabelreparatursatz, Stellmotor-Abgasklappe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Stellmotor-Abgasklappe"
        },
        "09322": {
            "GenartName": "Kabelreparatursatz, Stellmotor-Abgasklappe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Stellmotor-Abgasklappe"
        },
        "09323": {
            "GenartName": "Kabelreparatursatz, Drehzahlsensor-Abgasturbolader",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Drehzahlsensor-Abgasturbolader"
        },
        "09324": {
            "GenartName": "Kabelreparatursatz, Drehzahlsensor-Abgasturbolader",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung, Universal",
            "UsageName": "Drehzahlsensor-Abgasturbolader"
        },
        "09325": {
            "GenartName": "Kabelreparatursatz, Getriebeschalter-Gangerkennung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeschalter-Gangerkennung"
        },
        "09326": {
            "GenartName": "Kabelreparatursatz, Getriebeschalter-Gangerkennung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schaltgetriebe, Universal",
            "UsageName": "Getriebeschalter-Gangerkennung"
        },
        "09327": {
            "GenartName": "Kabelreparatursatz, Schalter-Anhängersteckdose",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Schalter-Anhängersteckdose"
        },
        "09328": {
            "GenartName": "Kabelreparatursatz, Schalter-Anhängersteckdose",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Schalter-Anhängersteckdose"
        },
        "09329": {
            "GenartName": "Kabelreparatursatz, Harnstofftank-Heizelement",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstofftank-Heizelement"
        },
        "09330": {
            "GenartName": "Kabelreparatursatz, Harnstofftank-Heizelement",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Harnstofftank-Heizelement"
        },
        "09331": {
            "GenartName": "Kabelreparatursatz, Sensor-Kraftstoffregler",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Sensor-Kraftstoffregler"
        },
        "09332": {
            "GenartName": "Kabelreparatursatz, Sensor-Kraftstoffregler",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Sensor-Kraftstoffregler"
        },
        "09333": {
            "GenartName": "Kalibriersatz, Fahrerassistenzsystem",
            "NormTermName": "Kalibriersatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrerassistenzsystem"
        },
        "09334": {
            "GenartName": "Kalibriertafel, Fahrerassistenzsystem",
            "NormTermName": "Kalibriertafel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrerassistenzsystem"
        },
        "09335": {
            "GenartName": "Hybridklebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Hybridklebstoff"
        },
        "09336": {
            "GenartName": "Kabelreparatursatz, Drucksensor-Aktivkohlebehälter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Drucksensor-Aktivkohlebehälter"
        },
        "09337": {
            "GenartName": "Kabelreparatursatz, Drucksensor-Aktivkohlebehälter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Drucksensor-Aktivkohlebehälter"
        },
        "09338": {
            "GenartName": "Kabelreparatursatz, Heizgerät-Standheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Heizgerät-Standheizung"
        },
        "09339": {
            "GenartName": "Kabelreparatursatz, Heizgerät-Standheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Heizgerät-Standheizung"
        },
        "09341": {
            "GenartName": "Kabelreparatursatz, Luftdrucksensor-Höhenanpassung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Luftdrucksensor-Höhenanpassung"
        },
        "09342": {
            "GenartName": "Kabelreparatursatz, Luftdrucksensor-Höhenanpassung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Luftdrucksensor-Höhenanpassung"
        },
        "09343": {
            "GenartName": "Lagerbuchse, Trag-/Führungsgelenk",
            "NormTermName": "Buchse",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Trag-/Führungsgelenk"
        },
        "09344": {
            "GenartName": "Kabelreparatursatz, Sensor-Drallklappenposition (Saugrohr)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Sensor-Drallklappenposition (Saugrohr)"
        },
        "09345": {
            "GenartName": "Kabelreparatursatz, Sensor-Drallklappenposition (Saugrohr)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung, Universal",
            "UsageName": "Sensor-Drallklappenposition (Saugrohr)"
        },
        "09346": {
            "GenartName": "Reparatursatz, Saugrohrmodul",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Saugrohrmodul"
        },
        "09347": {
            "GenartName": "Zubehörsatz, Federspanner",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Federspanner"
        },
        "09348": {
            "GenartName": "Spindel, Pressrahmen-Traggelenk",
            "NormTermName": "Spindel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Pressrahmen-Traggelenk"
        },
        "09349": {
            "GenartName": "Antriebssatz, Universal",
            "NormTermName": "Antriebssatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "09350": {
            "GenartName": "Erweiterungssatz, Auspressvorrichtung-Antriebswelle",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Auspressvorrichtung-Antriebswelle"
        },
        "09351": {
            "GenartName": "Sicherungsringwerkzeug",
            "NormTermName": "Sicherungsringwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "09352": {
            "GenartName": "Einschweißgewinde, Partikelsensor",
            "NormTermName": "Einschweißgewinde",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Partikelsensor"
        },
        "09353": {
            "GenartName": "Einschweißgewinde, Partikelsensor",
            "NormTermName": "Einschweißgewinde",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Partikelsensor"
        },
        "09354": {
            "GenartName": "Kabelreparatursatz, Tankklappe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Tankklappe"
        },
        "09355": {
            "GenartName": "Kabelreparatursatz, Tankklappe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Tankklappe"
        },
        "09356": {
            "GenartName": "Kippvorrichtungssatz, Fahrerhaus",
            "NormTermName": "Kippvorrichtungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Fahrerhaus"
        },
        "09357": {
            "GenartName": "Kippvorrichtung, Fahrerhaus",
            "NormTermName": "Kippvorrichtung",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Fahrerhaus"
        },
        "09358": {
            "GenartName": "Kabelreparatursatz, Stellelement-Ladeklappenverriegelung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage",
            "UsageName": "Stellelement-Ladeklappenverriegelung"
        },
        "09359": {
            "GenartName": "Kabelreparatursatz, Stellelement-Ladeklappenverriegelung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Stellelement-Ladeklappenverriegelung"
        },
        "09360": {
            "GenartName": "Kabelreparatursatz, Expansionsventil-Wärmepumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Expansionsventil-Wärmepumpe"
        },
        "09361": {
            "GenartName": "Montagewerkzeug, Relaisventil-Dichtung",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremsanlage"
        },
        "09362": {
            "GenartName": "Montagewerkzeugsatz, Hubzylinder-Dichtung",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zylinderlaufbuchse"
        },
        "09363": {
            "GenartName": "Kabelreparatursatz, Expansionsventil-Wärmepumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Expansionsventil-Wärmepumpe"
        },
        "09364": {
            "GenartName": "Montagewerkzeug, Hubzylinder-Dichtung",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zylinderlaufbuchse"
        },
        "09365": {
            "GenartName": "Kabelreparatursatz, Kraftstofftemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kraftstofftemperatursensor"
        },
        "09366": {
            "GenartName": "Hebel, Dichtringmontage",
            "NormTermName": "Hebel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zylinderkopf-Dichtigkeit"
        },
        "09367": {
            "GenartName": "Kabelreparatursatz, Kraftstofftemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Kraftstofftemperatursensor"
        },
        "09368": {
            "GenartName": "Abziehersatz, Differential-Hauptwelle",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Differential"
        },
        "09369": {
            "GenartName": "Halter, Kraft-Stecknuss",
            "NormTermName": "Halter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09370": {
            "GenartName": "Montagewerkzeug, Getriebelager",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Getriebelager"
        },
        "09371": {
            "GenartName": "Schlüssel, Magnetventil",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Klimaanlage"
        },
        "09372": {
            "GenartName": "Brücke, Montagegrube",
            "NormTermName": "Brücke",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Montagegrube"
        },
        "09373": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Heckklappenöffnung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Steuergerät-Heckklappenöffnung"
        },
        "09374": {
            "GenartName": "Befestigungsschraube, Abzieher",
            "NormTermName": "Befestigungsschraube",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09375": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Heckklappenöffnung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Steuergerät-Heckklappenöffnung"
        },
        "09376": {
            "GenartName": "Verlängerung, Spiralbohrer",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09377": {
            "GenartName": "Kabelreparatursatz, Partikelsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Partikelsensor"
        },
        "09378": {
            "GenartName": "Kabelreparatursatz, Partikelsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Partikelsensor"
        },
        "09379": {
            "GenartName": "Kabelreparatursatz, Lenksäulensperre",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenksäulensperre"
        },
        "09380": {
            "GenartName": "Kabelreparatursatz, Lenksäulensperre",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Lenkung, Universal",
            "UsageName": "Lenksäulensperre"
        },
        "09381": {
            "GenartName": "Kabelreparatursatz, Bremslichtschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Signalanlage",
            "UsageName": "Bremslichtschalter"
        },
        "09382": {
            "GenartName": "Kabelreparatursatz, Bremslichtschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Bremslichtschalter"
        },
        "09383": {
            "GenartName": "Kabelreparatursatz, Kraftstoffdrucksensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kraftstoffdrucksensor"
        },
        "09384": {
            "GenartName": "Kabelreparatursatz, Kraftstoffdrucksensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Kraftstoffdrucksensor"
        },
        "09385": {
            "GenartName": "Anschlussleitung, Öldruckschalter",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Schmierung",
            "UsageName": "Öldruckschalter"
        },
        "09386": {
            "GenartName": "Anschlussleitung, Öldruckschalter",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Schmierung, Universal",
            "UsageName": "Öldruckschalter"
        },
        "09387": {
            "GenartName": "Kabelreparatursatz, Thermostat",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Thermostat"
        },
        "09388": {
            "GenartName": "Kabelreparatursatz, Thermostat",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Thermostat"
        },
        "09389": {
            "GenartName": "Kabelreparatursatz, Abgastemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgastemperatursensor"
        },
        "09390": {
            "GenartName": "Kabelreparatursatz, Abgastemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Abgastemperatursensor"
        },
        "09391": {
            "GenartName": "Kabelreparatursatz, Absperrventil-Kältemittel (Wärmepumpe)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Absperrventil-Kältemittel (Wärmepumpe)"
        },
        "09392": {
            "GenartName": "Kabelreparatursatz, Absperrventil-Kältemittel (Wärmepumpe)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Absperrventil-Kältemittel (Wärmepumpe)"
        },
        "09393": {
            "GenartName": "Kabelreparatursatz, Sensormodul-Wärmepumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Sensormodul-Wärmepumpe"
        },
        "09394": {
            "GenartName": "Kabelreparatursatz, Sensormodul-Wärmepumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Sensormodul-Wärmepumpe"
        },
        "09395": {
            "GenartName": "Kabelreparatursatz, Stellelement-Ladesteckdose",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Stellelement-Ladesteckdose"
        },
        "09396": {
            "GenartName": "Kabelreparatursatz, Stellelement-Ladesteckdose",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Hybrid-/Elektroantrieb, Universal",
            "UsageName": "Stellelement-Ladesteckdose"
        },
        "09397": {
            "GenartName": "Kabelreparatursatz, Differenzdrucksensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Differenzdrucksensor"
        },
        "09398": {
            "GenartName": "Kabelreparatursatz, Differenzdrucksensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Differenzdrucksensor"
        },
        "09399": {
            "GenartName": "Montage-/Demontagesatz, Blattfedern",
            "NormTermName": "Montage-/Demontagesatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Blattfeder"
        },
        "09400": {
            "GenartName": "Montage-/Demontagesatz, Achsschenkelbolzen",
            "NormTermName": "Montage-/Demontagesatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Achsschenkelbolzen"
        },
        "09401": {
            "GenartName": "Montage-/Demontagesatz, Blattfedern",
            "NormTermName": "Montage-/Demontagesatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Blattfeder"
        },
        "09402": {
            "GenartName": "Montage-/Demontagesatz, Achsschenkelbolzen",
            "NormTermName": "Montage-/Demontagesatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Achsschenkelbolzen"
        },
        "09403": {
            "GenartName": "Lösewerkzeug, Spurstange",
            "NormTermName": "Lösewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Spurstange"
        },
        "09404": {
            "GenartName": "WiFi-Sender, Videoendoskop",
            "NormTermName": "WiFi-Sender",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Videoendoskop"
        },
        "09405": {
            "GenartName": "Schablonensatz, Lackiertechnik",
            "NormTermName": "Schablonensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lackiertechnik"
        },
        "09406": {
            "GenartName": "Montagewerkzeug, Achsbuchsen",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Achse"
        },
        "09407": {
            "GenartName": "Montagewerkzeugsatz, Achsbuchsen",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Achse"
        },
        "09408": {
            "GenartName": "Montagewerkzeug, Kurbelwellendichtring/Führungslager",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kurbelwellendichtring/Führungslager"
        },
        "09409": {
            "GenartName": "Abziehersatz, Rollenlager",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Rollenlager"
        },
        "09410": {
            "GenartName": "Abziehersatz, Getriebewelle",
            "NormTermName": "Abziehersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Getriebewelle"
        },
        "09411": {
            "GenartName": "Reibahlen-Adapter, Glühkerze",
            "NormTermName": "Reibahlen-Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glühkerze"
        },
        "09412": {
            "GenartName": "Montagewerkzeug, Pumpe-Düse-Einheit",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Pumpe-Düse-Einheit"
        },
        "09413": {
            "GenartName": "Abzieher, Ausgleichswelle",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ausgleichswelle"
        },
        "09414": {
            "GenartName": "Gabelschneidkopf",
            "NormTermName": "Gabelschneidkopf",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09415": {
            "GenartName": "Aluminium-Lötset",
            "NormTermName": "Aluminium-Lötset",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09416": {
            "GenartName": "Zugglocke, Bremsbackenbolzen",
            "NormTermName": "Zugglocke",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsbackenbolzen"
        },
        "09417": {
            "GenartName": "Zugglocke, Bremsbackenbolzen",
            "NormTermName": "Zugglocke",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremsbackenbolzen"
        },
        "09418": {
            "GenartName": "Hydraulischer-Abziehersatz, Bremsbackenbolzen",
            "NormTermName": "Hydraulischer-Abziehersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsbackenbolzen"
        },
        "09419": {
            "GenartName": "Hydraulischer-Abziehersatz, Bremsbackenbolzen",
            "NormTermName": "Hydraulischer-Abziehersatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremsbackenbolzen"
        },
        "09420": {
            "GenartName": "Schlauchverbinder, Kühlerschlauch",
            "NormTermName": "Schlauchverbinder",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Kühlerschlauch"
        },
        "09421": {
            "GenartName": "Schutzschlauch, Fahrwerksfeder",
            "NormTermName": "Schutzschlauch",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Fahrwerksfeder"
        },
        "09422": {
            "GenartName": "Druckschraubensatz, Montagewerkzeug (Antriebswellengelenk)",
            "NormTermName": "Druckschraubensatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug (Antriebswellengelenk)"
        },
        "09423": {
            "GenartName": "Druckschraube, Montagewerkzeug (Antriebswellengelenk)",
            "NormTermName": "Druckschraube",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug (Antriebswellengelenk)"
        },
        "09424": {
            "GenartName": "Steckschlüsseleinsatz, Antriebswelle",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Antriebswelle"
        },
        "09425": {
            "GenartName": "Steckschlüsseleinsatz, Radlager",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radlager"
        },
        "09426": {
            "GenartName": "Zugstange, Pressrahmen (Montagewerkzeug Silentlager)",
            "NormTermName": "Zugstange",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Pressrahmen (Montagewerkzeug Silentlager)"
        },
        "09427": {
            "GenartName": "Grundplatte, Pressrahmen (Montagewerkzeug Silentlager)",
            "NormTermName": "Grundplatte",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Pressrahmen (Montagewerkzeug Silentlager)"
        },
        "09428": {
            "GenartName": "Steckschlüsseleinsatz, Öldruckschalter",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Öldruckschalter"
        },
        "09429": {
            "GenartName": "Auszieher, Öldüse-Kolbenbodenkühlung",
            "NormTermName": "Auszieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Öldüse-Kolbenbodenkühlung"
        },
        "09430": {
            "GenartName": "Feststellzange, Druckluftleitung",
            "NormTermName": "Feststellzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Druckluftleitung"
        },
        "09431": {
            "GenartName": "Ratschen-Doppelringschlüssel, Glühkerze",
            "NormTermName": "Ratschen-Doppelringschlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glühkerze"
        },
        "09432": {
            "GenartName": "Steckschlüsseleinsatz, Kurbelwellen-Riemenscheibenschraube",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kurbelwellen-Riemenscheibenschraube"
        },
        "09433": {
            "GenartName": "Steckschlüsseleinsatz, Motorölstandsensor",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Motorölstandsensor"
        },
        "09434": {
            "GenartName": "Distanzring, Achsschenkelbolzenpresse",
            "NormTermName": "Distanzring",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Achsschenkelbolzenpresse"
        },
        "09435": {
            "GenartName": "Druckstücksatz, Achsschenkelbolzenpresse",
            "NormTermName": "Druckstücksatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Achsschenkelbolzenpresse"
        },
        "09436": {
            "GenartName": "Druckstück, Achsschenkelbolzenpresse",
            "NormTermName": "Druckstück",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Achsschenkelbolzenpresse"
        },
        "09437": {
            "GenartName": "Stützring, Achsschenkelbolzenpresse",
            "NormTermName": "Stützring",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Achsschenkelbolzenpresse"
        },
        "09438": {
            "GenartName": "Demontagewerkzeug, Achsschenkelbolzen",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Achsschenkelbolzen"
        },
        "09439": {
            "GenartName": "Adapter, Demontagewerkzeug-Achsschenkelbolzen",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Demontagewerkzeug-Achsschenkelbolzen"
        },
        "09440": {
            "GenartName": "Montage-/Demontagewerkzeugsatz, Zuggabellager",
            "NormTermName": "Montage-/Demontagewerkzeug-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Zuggabellager"
        },
        "09441": {
            "GenartName": "Abzieher, Radnabe",
            "NormTermName": "Abzieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radnabe"
        },
        "09442": {
            "GenartName": "Grundrahmen, Abzieher-Radnabe",
            "NormTermName": "Rahmen",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Abzieher-Radnabe"
        },
        "09443": {
            "GenartName": "Zapfenschlüsselsatz, Kugellager-Nutmutter",
            "NormTermName": "Zapfenschlüsselsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kugellager-Nutmutter"
        },
        "09444": {
            "GenartName": "Steckschlüsseleinsatz, Stoßdämpfer-Befestigungsschraube",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Stoßdämpfer-Befestigungsschraube"
        },
        "09445": {
            "GenartName": "Eindrücker, Wellendichtring",
            "NormTermName": "Eindrücker",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Wellendichtring"
        },
        "09446": {
            "GenartName": "Druckring, Eindrücker-Wellendichtring",
            "NormTermName": "Druckring",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Eindrücker-Wellendichtring"
        },
        "09447": {
            "GenartName": "Montagewerkzeugsatz, Kupplungsdruckplatte",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kupplungsdruckplatte"
        },
        "09448": {
            "GenartName": "Adapter, Montagewerkzeug-Kupplung/Schwungrad",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Montagewerkzeug-Kupplung/Schwungrad"
        },
        "09449": {
            "GenartName": "Erweiterungssatz, Montagewerkzeug-Kupplung/Druckplatte",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Montagewerkzeug-Kupplung/Druckplatte"
        },
        "09450": {
            "GenartName": "Bügel, Montagewerkzeug-Kupplung",
            "NormTermName": "Bügel",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Montagewerkzeug-Kupplung"
        },
        "09451": {
            "GenartName": "Schlagschlüsselsatz, Bremssattelverschraubung",
            "NormTermName": "Schlüsselsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremssattelverschraubung"
        },
        "09452": {
            "GenartName": "Schlagschlüssel, Bremssattelverschraubung",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremssattelverschraubung"
        },
        "09453": {
            "GenartName": "Schlagschlüssel, Längswellenverschraubung",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Längswellenverschraubung"
        },
        "09454": {
            "GenartName": "Druckstücksatz, Montagewerkzeug Trag-/Führungsgelenk",
            "NormTermName": "Druckstücksatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Montagewerkzeug Trag-/Führungsgelenk"
        },
        "09455": {
            "GenartName": "Ausdrücker, Kugelgelenk",
            "NormTermName": "Ausdrücker",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kugelgelenk"
        },
        "09456": {
            "GenartName": "Anschlussleitung, Stellmagnet-Nockenwellenverstellung",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Stellmagnet-Nockenwellenverstellung"
        },
        "09457": {
            "GenartName": "Anschlussleitung, Stellmagnet-Nockenwellenverstellung",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Motorsteuerung, Universal",
            "UsageName": "Stellmagnet-Nockenwellenverstellung"
        },
        "09458": {
            "GenartName": "Pressrahmen, Montagewerkzeug Silentlager",
            "NormTermName": "Pressrahmen",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Montagewerkzeug Silentlager"
        },
        "09459": {
            "GenartName": "Erweiterungssatz, Einspritzdüsenauszieher",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Einspritzdüsenauszieher"
        },
        "09460": {
            "GenartName": "Lösezange, Schlauchleitung",
            "NormTermName": "Lösezange",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Schlauchleitung"
        },
        "09461": {
            "GenartName": "Gewindebohrer, Düsenhalterbefestigung",
            "NormTermName": "Gewindebohrer",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Düsenhalterbefestigung"
        },
        "09462": {
            "GenartName": "Zentrierwerkzeug, Kupplungsscheibe",
            "NormTermName": "Zentrierwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kupplungsscheibe"
        },
        "09463": {
            "GenartName": "Abzieher, Ausrückwelle",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kupplung"
        },
        "09464": {
            "GenartName": "Zentrierstift, Kernbohrmaschine",
            "NormTermName": "Zentrierstift",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kernbohrmaschine"
        },
        "09465": {
            "GenartName": "Winkeladapter, Akkuschrauber",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Akkuschrauber"
        },
        "09466": {
            "GenartName": "Wendeschneidplattensatz, Fräswerkzeug",
            "NormTermName": "Wendeschneidplattensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Fräswerkzeug"
        },
        "09467": {
            "GenartName": "Wendeplattenfräser",
            "NormTermName": "Fräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Wendeplattenfräser"
        },
        "09468": {
            "GenartName": "Kalibriervorrichtung, Fahrerassistenzsystem",
            "NormTermName": "Kalibriervorrichtung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fahrerassistenzsystem"
        },
        "09469": {
            "GenartName": "Vorsatzhalter, Bandschleifer",
            "NormTermName": "Halter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bandschleifer"
        },
        "09470": {
            "GenartName": "Verlängerung, Multi-Schleifer",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Multi-Schleifer"
        },
        "09471": {
            "GenartName": "Verlängerung, Stabschleifer",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stabschleifer"
        },
        "09472": {
            "GenartName": "Untergestell, Handkreissäge",
            "NormTermName": "Untergestell",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Handkreissäge"
        },
        "09473": {
            "GenartName": "Trockenbauschrauber (Netzgebunden)",
            "NormTermName": "Trockenbauschrauber (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09474": {
            "GenartName": "Trockenbauschrauber (Akku)",
            "NormTermName": "Trockenbauschrauber (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09475": {
            "GenartName": "Tischkreissäge (Netzgebunden)",
            "NormTermName": "Tischkreissäge (Netzgebunden)",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09476": {
            "GenartName": "Tiefenbegrenzer, Sägekette",
            "NormTermName": "Tiefenbegrenzer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Sägeketten"
        },
        "09477": {
            "GenartName": "Tauchpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09478": {
            "GenartName": "Tackernägel",
            "NormTermName": "Tackernägel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09479": {
            "GenartName": "Staubschutzfilter, Winkelschleifer",
            "NormTermName": "Filter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Staubschutz"
        },
        "09480": {
            "GenartName": "Späneabsauganlage, Holzbearbeitung",
            "NormTermName": "Absauggerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Holzspäneabsaugung"
        },
        "09481": {
            "GenartName": "Schutztasche, Tuning-Modul (Motorleistungssteigerung)",
            "NormTermName": "Schutztasche",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Tuning-Modul (Motorleistungssteigerung)"
        },
        "09482": {
            "GenartName": "Schutzhaube, Winkelschleifer",
            "NormTermName": "Schutzhaube",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "09483": {
            "GenartName": "Reduzieradapter, Bohrfutter",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bohrfutter"
        },
        "09484": {
            "GenartName": "Adapter, Maschinengewindebohrer",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Maschinengewindebohrer"
        },
        "09485": {
            "GenartName": "Anschlussset, Schnellkupplung",
            "NormTermName": "Anschlussset",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schnellkupplung"
        },
        "09486": {
            "GenartName": "Auswerferstift, Kernbohrmaschine",
            "NormTermName": "Auswerferstift",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kernbohrmaschine"
        },
        "09487": {
            "GenartName": "Bandsäge (Akku)",
            "NormTermName": "Bandsäge (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09488": {
            "GenartName": "Betonbohrersatz",
            "NormTermName": "Bohrersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Betonbohrer"
        },
        "09489": {
            "GenartName": "Haltefeder, Meißelhammer (Druckluft)",
            "NormTermName": "Feder",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Meißelhammer (Druckluft)"
        },
        "09490": {
            "GenartName": "Filter, Späneabsauganlage-Holzbearbeitung",
            "NormTermName": "Filter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Späneabsauganlage-Holzbearbeitung"
        },
        "09491": {
            "GenartName": "Fließbohrer",
            "NormTermName": "Fließbohrer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09492": {
            "GenartName": "Freisprecheinrichtung",
            "NormTermName": "Freisprecheinrichtung",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "09493": {
            "GenartName": "Tiefbrunnenpumpe",
            "NormTermName": "Tiefbrunnenpumpe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09494": {
            "GenartName": "Gehäuse, Tuning-Modul (Motorleistungssteigerung)",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Tuning-Modul (Motorleistungssteigerung)"
        },
        "09495": {
            "GenartName": "Hauptplatine, Tuning-Modul (Motorleistungssteigerung)",
            "NormTermName": "Platine",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Tuning-Modul (Motorleistungssteigerung)"
        },
        "09496": {
            "GenartName": "Hauswasserwerk",
            "NormTermName": "Hauswasserwerk",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09497": {
            "GenartName": "Klinge, Hobel",
            "NormTermName": "Klinge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Hobel"
        },
        "09498": {
            "GenartName": "Leitungssatz, Tuning-Modul (Motorleistungssteigerung)",
            "NormTermName": "Elektroleitungssatz",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Tuning-Modul (Motorleistungssteigerung)"
        },
        "09499": {
            "GenartName": "Kappsäge (Netzgebunden)",
            "NormTermName": "Säge",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09500": {
            "GenartName": "Kehlnahtschleifer (Netzgebunden)",
            "NormTermName": "Kehlnahtschleifer (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09501": {
            "GenartName": "Kehlnahtschleifer (Akku)",
            "NormTermName": "Kehlnahtschleifer (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09502": {
            "GenartName": "Klebestick, Heißklebepistole",
            "NormTermName": "Klebestick",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Heißklebepistole"
        },
        "09503": {
            "GenartName": "Lamellenschleifscheibe, Winkelschleifer",
            "NormTermName": "Schleifscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Winkelschleifer"
        },
        "09504": {
            "GenartName": "Langhalsschleifer (Netzgebunden)",
            "NormTermName": "Langhalsschleifer (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09505": {
            "GenartName": "Pumpe, Lamellenkupplung-Allradantrieb",
            "NormTermName": "Pumpe",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Lamellenkupplung-Allradantrieb"
        },
        "09506": {
            "GenartName": "Linienlaser",
            "NormTermName": "Linienlaser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09507": {
            "GenartName": "Ultraschallvernebler",
            "NormTermName": "Ultraschallvernebler",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Luftreinigung"
        },
        "09508": {
            "GenartName": "Schlauchsatz, Ultraschallvernebler",
            "NormTermName": "Schlauchleitungssatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Ultraschallvernebler"
        },
        "09509": {
            "GenartName": "Ozongenerator",
            "NormTermName": "Ozongenerator",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Luftreinigung"
        },
        "09510": {
            "GenartName": "Einfüllkit, UV-Additiv",
            "NormTermName": "Einfüllkit",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09511": {
            "GenartName": "Spüladapterset, Klimaanlage",
            "NormTermName": "Spüladapterset",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimatechnik"
        },
        "09512": {
            "GenartName": "Dichtmittelabscheider, Klimaservicegerät",
            "NormTermName": "Dichtmittelabscheider",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimatechnik"
        },
        "09513": {
            "GenartName": "Prüfgerät, Kompressor-Öl/Kältemittel",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimaanlage"
        },
        "09514": {
            "GenartName": "Montagewerkzeug, Bremssattel",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremssattel"
        },
        "09515": {
            "GenartName": "Fußpumpe",
            "NormTermName": "Fußpumpe",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "09516": {
            "GenartName": "Fußpumpe",
            "NormTermName": "Fußpumpe",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "09517": {
            "GenartName": "Aufweitzange",
            "NormTermName": "Aufweitzange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09518": {
            "GenartName": "Bördelkonus",
            "NormTermName": "Bördelkonus",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09519": {
            "GenartName": "Bördelleiste",
            "NormTermName": "Bördelleiste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09520": {
            "GenartName": "Montagewerkzeugsatz, Bremsbacken",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremsbacken"
        },
        "09521": {
            "GenartName": "Rückstellwerkzeug, Trommelbremse",
            "NormTermName": "Rückstellwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Trommelbremse"
        },
        "09522": {
            "GenartName": "Einstellwerkzeug, Trommelbremse",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Trommelbremse"
        },
        "09523": {
            "GenartName": "Entriegelungswerkzeug, Bremspedal",
            "NormTermName": "Entriegelungswerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremspedal"
        },
        "09524": {
            "GenartName": "Biegewerkzeugsatz, Bremsleitung",
            "NormTermName": "Biegewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsleitung"
        },
        "09525": {
            "GenartName": "Biegewerkzeug, Bremsleitung",
            "NormTermName": "Biegewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsleitung"
        },
        "09526": {
            "GenartName": "Entriegelungszange, Bremspedal",
            "NormTermName": "Entriegelungszange",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Bremspedal"
        },
        "09527": {
            "GenartName": "Gabel",
            "NormTermName": "Gabel",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "09528": {
            "GenartName": "Haue",
            "NormTermName": "Haue",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "09529": {
            "GenartName": "Reinigungsstift, Gewinde",
            "NormTermName": "Reinigungsstift",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gewinde"
        },
        "09530": {
            "GenartName": "Lösewerkzeug, Haltegriff",
            "NormTermName": "Lösewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Haltegriff"
        },
        "09531": {
            "GenartName": "Montagehülse, Kolbenring",
            "NormTermName": "Montagehülse",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Kolbenring"
        },
        "09532": {
            "GenartName": "Montagewerkzeugsatz, Dreieckslenker",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Dreieckslenker"
        },
        "09533": {
            "GenartName": "Montagewerkzeug, Dreieckslenker",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Dreieckslenker"
        },
        "09534": {
            "GenartName": "Montage-/Demontagehülse, Bremssattel",
            "NormTermName": "Montage-/Demontagehülse",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremssattel"
        },
        "09535": {
            "GenartName": "Abzieher, Riemenscheibe",
            "NormTermName": "Abzieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Riemenscheibe"
        },
        "09536": {
            "GenartName": "Arretierwerkzeug, Schwungrad",
            "NormTermName": "Arretierwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Schwungrad"
        },
        "09537": {
            "GenartName": "Montage-/Demontagewerkzeug, Radbolzen",
            "NormTermName": "Montage-/Demontagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radbolzen"
        },
        "09538": {
            "GenartName": "Montage-/Demontagewerkzeug-Satz, Radbolzen",
            "NormTermName": "Montage-/Demontagewerkzeug-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Radbolzen"
        },
        "09539": {
            "GenartName": "Montage-/Demontagewerkzeug, Bremsscheibe",
            "NormTermName": "Montage-/Demontagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremsscheibe"
        },
        "09540": {
            "GenartName": "Zierleistenheber",
            "NormTermName": "Zierleistenheber",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09541": {
            "GenartName": "Druckflasche, Lecksuche (Klimaanlage)",
            "NormTermName": "Druckflasche",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimaanlage"
        },
        "09542": {
            "GenartName": "Befülladapter, Druckluftbremsanlage",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "09543": {
            "GenartName": "Befülladapter, Druckluftbremsanlage",
            "NormTermName": "Adapter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "09544": {
            "GenartName": "Adapter, Meißelhammer",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Meißelhammer"
        },
        "09545": {
            "GenartName": "Montagewerkzeugsatz, Motor-/Getriebeeinheit",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Motor-/Getriebeeinheit"
        },
        "09546": {
            "GenartName": "Sprühsonde, Ruß-/Partikelfilter",
            "NormTermName": "Sprühsonde",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ruß-/Partikelfilter"
        },
        "09547": {
            "GenartName": "Sprühlanze, Ruß-/Partikelfilter",
            "NormTermName": "Sprühlanze",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Ruß-/Partikelfilter"
        },
        "09548": {
            "GenartName": "Dosierpumpe",
            "NormTermName": "Dosierpumpe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09549": {
            "GenartName": "Leckwarngerät",
            "NormTermName": "Leckwarngerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09550": {
            "GenartName": "Kartuschenpresse",
            "NormTermName": "Kartuschenpresse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09551": {
            "GenartName": "Kartuschenrohr",
            "NormTermName": "Kartuschenrohr",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09552": {
            "GenartName": "Sortiment, Befestigungssockel-Kabelbinder",
            "NormTermName": "Sortiment",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "09553": {
            "GenartName": "Werkstatt-Fahrwagen",
            "NormTermName": "Werkstatt-Fahrwagen",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09554": {
            "GenartName": "Rechner",
            "NormTermName": "Rechner",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09555": {
            "GenartName": "Maus, Rechner",
            "NormTermName": "Maus",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Rechner"
        },
        "09556": {
            "GenartName": "Abgasmessmodul",
            "NormTermName": "Abgasmessmodul",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09557": {
            "GenartName": "Messmodul, Drehzahl",
            "NormTermName": "Messmodul",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09558": {
            "GenartName": "Diagnosemodul, Steuergerät",
            "NormTermName": "Diagnosemodul",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09559": {
            "GenartName": "Absauggerät, Kältemittel",
            "NormTermName": "Absauggerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimaanlage"
        },
        "09560": {
            "GenartName": "Messmodul, Systemanalyse",
            "NormTermName": "Messmodul",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09561": {
            "GenartName": "Prüfgerät, Kältemittel",
            "NormTermName": "Prüfgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimaanlage"
        },
        "09562": {
            "GenartName": "Montagewerkzeugsatz, Längs-/Querlenkerbuchse",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Längs-/Querlenkerbuchse"
        },
        "09563": {
            "GenartName": "Stoßdämpferöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Stoßdämpfer"
        },
        "09564": {
            "GenartName": "Luftfilterreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Luftfilter"
        },
        "09565": {
            "GenartName": "Spindelöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Spindel"
        },
        "09566": {
            "GenartName": "Autoshampoo",
            "NormTermName": "Autoshampoo",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09567": {
            "GenartName": "Fettlöser",
            "NormTermName": "Fettlöser",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09568": {
            "GenartName": "Klettband",
            "NormTermName": "Klettband",
            "AssGrpName": "Normteile",
            "UsageName": null
        },
        "09569": {
            "GenartName": "Kabelreparatursatz, Heizelement",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": null
        },
        "09570": {
            "GenartName": "Kabelreparatursatz, Heizelement",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung, Universal",
            "UsageName": null
        },
        "09571": {
            "GenartName": "Kabelreparatursatz, Sensor-Drosselklappenstellung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": null
        },
        "09572": {
            "GenartName": "Kabelreparatursatz, Sensor-Drosselklappenstellung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": null
        },
        "09573": {
            "GenartName": "Kabelreparatursatz, Radarsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": null
        },
        "09574": {
            "GenartName": "Kabelreparatursatz, Radarsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": null
        },
        "09575": {
            "GenartName": "Software, Drehmomentschlüssel",
            "NormTermName": "Software",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Drehmomentschlüssel"
        },
        "09576": {
            "GenartName": "Bit, Klimaanlagenventil",
            "NormTermName": "Bit",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Klimaanlage"
        },
        "09577": {
            "GenartName": "Saugaufsatz, Reinigungspistole (Druckluft)",
            "NormTermName": "Saugaufsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Reinigungspistole (Druckluft)"
        },
        "09578": {
            "GenartName": "Haftteller, Multi-Schleifer",
            "NormTermName": "Haftteller",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Multi-Schleifer"
        },
        "09579": {
            "GenartName": "Ersatz-Schleifscheibensatz, Multi-Schleifer",
            "NormTermName": "Ersatz-Schleifscheibensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Multi-Schleifer"
        },
        "09580": {
            "GenartName": "Hakensatz, Bremssattel",
            "NormTermName": "Hakensatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremssattel"
        },
        "09581": {
            "GenartName": "Haltewerkzeug, Glüh-/Zündkerze",
            "NormTermName": "Haltewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Glüh-/Zündkerze"
        },
        "09582": {
            "GenartName": "Montage-Hebehilfe, Bremssattel",
            "NormTermName": "Montage-Hebehilfe",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Bremssattel"
        },
        "09583": {
            "GenartName": "Spezial-Steckschlüsseleinsatz, Ölablassschraube",
            "NormTermName": "Steckschlüsseleinsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ölablassschraube"
        },
        "09586": {
            "GenartName": "Schlagauszieher, Radlager",
            "NormTermName": "Schlagauszieher",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radlager"
        },
        "09587": {
            "GenartName": "Befestigungssockel, Kabelstrang",
            "NormTermName": "Befestigungssockel",
            "AssGrpName": "Normteile",
            "UsageName": "Kabelbefestigung"
        },
        "09588": {
            "GenartName": "Reparatursatz, Nockenwellenverstellung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwellenverstellung"
        },
        "09589": {
            "GenartName": "Schlauchverbinder, Kühlerschlauch",
            "NormTermName": "Schlauchverbinder",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlerschlauch"
        },
        "09590": {
            "GenartName": "Kabelreparatursatz, Motorölstandsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Motorölstandsensor"
        },
        "09591": {
            "GenartName": "Kabelreparatursatz, Motorölstandsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schmierung, Universal",
            "UsageName": "Motorölstandsensor"
        },
        "09592": {
            "GenartName": "Kabelreparatursatz, Wegstreckensensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Instrumente",
            "UsageName": "Wegstreckensensor"
        },
        "09593": {
            "GenartName": "Kabelreparatursatz, Wegstreckensensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Wegstreckensensor"
        },
        "09594": {
            "GenartName": "Kabelreparatursatz, Funkuhrempfänger",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Instrumente",
            "UsageName": "Funkuhrempfänger"
        },
        "09595": {
            "GenartName": "Kabelreparatursatz, Funkuhrempfänger",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Funkuhrempfänger"
        },
        "09596": {
            "GenartName": "Kabelreparatursatz, Nehmerzylinder",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Nehmerzylinder"
        },
        "09597": {
            "GenartName": "Kabelreparatursatz, Nehmerzylinder",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kupplung, Universal",
            "UsageName": "Nehmerzylinder"
        },
        "09598": {
            "GenartName": "Kabelreparatursatz, Bewegungssensor-Stoßdämpfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Bewegungssensor-Stoßdämpfer"
        },
        "09599": {
            "GenartName": "Kabelreparatursatz, Bewegungssensor-Stoßdämpfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Federung/Dämpfung, Universal",
            "UsageName": "Bewegungssensor-Stoßdämpfer"
        },
        "09600": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Kraftstoffpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Steuergerät-Kraftstoffpumpe"
        },
        "09601": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Kraftstoffpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Steuergerät-Kraftstoffpumpe"
        },
        "09602": {
            "GenartName": "Kabelreparatursatz, NOx-Sensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "NOx-Sensor"
        },
        "09603": {
            "GenartName": "Kabelreparatursatz, NOx-Sensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "NOx-Sensor"
        },
        "09604": {
            "GenartName": "Kabelreparatursatz, Geberzylinder",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Geberzylinder"
        },
        "09605": {
            "GenartName": "Kabelreparatursatz, Geberzylinder",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kupplung, Universal",
            "UsageName": "Geberzylinder"
        },
        "09606": {
            "GenartName": "Nachrüstsatz, SCR-Katalysator",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "SCR-Katalysator"
        },
        "09607": {
            "GenartName": "Kabelreparatursatz, Bremsbelag-Verschleißanzeige",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsbelag-Verschleißanzeige"
        },
        "09608": {
            "GenartName": "Kabelreparatursatz, Bremsbelag-Verschleißanzeige",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremsbelag-Verschleißanzeige"
        },
        "09609": {
            "GenartName": "Druckwandler, Abgasklappensteuerung",
            "NormTermName": "Druckwandler",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasklappensteuerung"
        },
        "09610": {
            "GenartName": "Kabelreparatursatz, Druckwandler-Turbolader",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Druckwandler-Turbolader"
        },
        "09611": {
            "GenartName": "Kabelreparatursatz, Druckwandler-Turbolader",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung, Universal",
            "UsageName": "Druckwandler-Turbolader"
        },
        "09612": {
            "GenartName": "Kabelreparatursatz, Zentralmagnet-Nockenwellenverstellung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Zentralmagnet-Nockenwellenverstellung"
        },
        "09613": {
            "GenartName": "Kabelreparatursatz, Zentralmagnet-Nockenwellenverstellung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Motorsteuerung, Universal",
            "UsageName": "Zentralmagnet-Nockenwellenverstellung"
        },
        "09614": {
            "GenartName": "Dichtungssatz, Kurbelwelle",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelwelle"
        },
        "09615": {
            "GenartName": "Nachrüstsatz, Toter-Winkel-Assistent",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": null
        },
        "09616": {
            "GenartName": "Nachrüstsatz, Toter-Winkel-Assistent",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": null
        },
        "09617": {
            "GenartName": "Absaugsatz, Elektrowerkzeug",
            "NormTermName": "Absaugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Elektrowerkzeug"
        },
        "09618": {
            "GenartName": "Lackadditiv",
            "NormTermName": "Zusatzmittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Lack"
        },
        "09619": {
            "GenartName": "Aufnahmeschaft, Poliermaschine",
            "NormTermName": "Aufnahmeschaft",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Poliermaschine"
        },
        "09620": {
            "GenartName": "Tauchpumpe, Schmutzwasser",
            "NormTermName": "Pumpe",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schmutzwasser"
        },
        "09621": {
            "GenartName": "Software, Motorleistungssteigerung",
            "NormTermName": "Software",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Motorleistungssteigerung"
        },
        "09622": {
            "GenartName": "Diamantblech",
            "NormTermName": "Diamantblech",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09623": {
            "GenartName": "Maschinengewindebohrer-Satz",
            "NormTermName": "Maschinengewindebohrer-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Maschinengewindebohrer"
        },
        "09624": {
            "GenartName": "Universalentgrater",
            "NormTermName": "Entgrater",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09625": {
            "GenartName": "Klingensatz, Universalentgrater",
            "NormTermName": "Klingensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Universalentgrater"
        },
        "09626": {
            "GenartName": "Elektrowerkzeug-Set (Akku)",
            "NormTermName": "Elektrowerkzeug-Set (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09627": {
            "GenartName": "Elektrowerkzeug-Set (Netzgebunden)",
            "NormTermName": "Elektrowerkzeug-Set (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09628": {
            "GenartName": "Feinschleifronde",
            "NormTermName": "Feinschleifronde",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09629": {
            "GenartName": "Nadelfeilen-Satz",
            "NormTermName": "Nadelfeilen-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09630": {
            "GenartName": "Filter, Hauswasserwerk",
            "NormTermName": "Filter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hauswasserwerk"
        },
        "09631": {
            "GenartName": "Fräs-/Schleifmotor, Oberfräse",
            "NormTermName": "Fräs-/Schleifmotor",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Oberfräse"
        },
        "09632": {
            "GenartName": "Reduzieradapter, Spannzange-Elektrowerkzeug",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Spannzange-Elektrowerkzeug"
        },
        "09633": {
            "GenartName": "Raspelfeile",
            "NormTermName": "Feile",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09634": {
            "GenartName": "Reduzierring, Sägeblatt-Handkreissäge",
            "NormTermName": "Reduzierring",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Sägeblatt-Handkreissäge"
        },
        "09635": {
            "GenartName": "Sägeblatt, Handkreissäge",
            "NormTermName": "Sägeblatt",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Handkreissäge"
        },
        "09636": {
            "GenartName": "Laubbläser (Akku)",
            "NormTermName": "Laubbläser",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09637": {
            "GenartName": "Frässtern, Renovierungsfräse",
            "NormTermName": "Frässtern",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": "Renovierungsfräse"
        },
        "09638": {
            "GenartName": "Renovierungsfräse (Netzgebunden)",
            "NormTermName": "Renovierungsfräse",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": "Renovierungsfräse"
        },
        "09639": {
            "GenartName": "Renovierungsschleifer (Netzgebunden)",
            "NormTermName": "Renovierungsschleifer",
            "AssGrpName": "Bauwerkzeuge",
            "UsageName": null
        },
        "09640": {
            "GenartName": "Gewindeformer",
            "NormTermName": "Gewindeformer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09641": {
            "GenartName": "Kopierflansch, Oberfräse",
            "NormTermName": "Kopierflansch",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Oberfräse"
        },
        "09642": {
            "GenartName": "Schlauchkupplung, Hauswasserwerk",
            "NormTermName": "Schlauchkupplung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hauswasserwerk"
        },
        "09643": {
            "GenartName": "Trockenlaufschutz, Hauswasserwerk",
            "NormTermName": "Trockenlaufschutz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Hauswasserwerk"
        },
        "09644": {
            "GenartName": "Schlauch, Prüfgerät-Kraftstoffsystemdruck",
            "NormTermName": "Schlauch",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Prüfgerät-Kraftstoffsystemdruck"
        },
        "09645": {
            "GenartName": "Zapfenschlüsselsatz, Düsenhalter-Spannmutter",
            "NormTermName": "Zapfenschlüsselsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Düsenhalter-Spannmutter"
        },
        "09646": {
            "GenartName": "Arretierwerkzeugsatz, Kurbelwelle",
            "NormTermName": "Arretierwerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Riemenscheibe-Kurbelwelle"
        },
        "09647": {
            "GenartName": "Entlüftungswerkzeug, Kraftstoffsystem",
            "NormTermName": "Entlüftungswerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kraftstoffsystem"
        },
        "09648": {
            "GenartName": "Zange, Abstandshalter",
            "NormTermName": "Zange",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abstandshalter"
        },
        "09649": {
            "GenartName": "Reparatursatz, Kraftstofffiltergehäuse",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstofffiltergehäuse"
        },
        "09650": {
            "GenartName": "Kabelmantelsäge",
            "NormTermName": "Säge",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09651": {
            "GenartName": "Ventilfräser, Wasserhahn",
            "NormTermName": "Ventilfräser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ventilsitz"
        },
        "09652": {
            "GenartName": "Ventilfräser-Satz, Wasserhahn",
            "NormTermName": "Ventilfräser-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ventilsitz"
        },
        "09653": {
            "GenartName": "Armaturen-Schutztafel, Scheibendemontage",
            "NormTermName": "Schutztafel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09654": {
            "GenartName": "Montage-/Demontagewerkzeug, Einspritzdüse",
            "NormTermName": "Montage-/Demontagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzdüse"
        },
        "09655": {
            "GenartName": "Einstellwerkzeug, Einspritzdüse",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Einspritzdüse"
        },
        "09656": {
            "GenartName": "Montagehilfe, Kupplung",
            "NormTermName": "Montagehilfe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09657": {
            "GenartName": "Montagehilfesatz, Kupplung",
            "NormTermName": "Montagehilfesatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09658": {
            "GenartName": "Reduzierringsatz, Trennscheibe-Winkelschleifer",
            "NormTermName": "Reduzierringsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Trennscheibe-Winkelschleifer"
        },
        "09659": {
            "GenartName": "Maschinenständer",
            "NormTermName": "Maschinenständer",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09660": {
            "GenartName": "Nadelraspel",
            "NormTermName": "Nadelraspel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09661": {
            "GenartName": "Sägeblatt, Handbandsäge",
            "NormTermName": "Sägeblatt",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Handbandsäge"
        },
        "09662": {
            "GenartName": "Nagel-Set, Druckluftnagler",
            "NormTermName": "Nagel-Set",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Druckluftnagler"
        },
        "09663": {
            "GenartName": "Mauernutfräse",
            "NormTermName": "Fräsmaschine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09664": {
            "GenartName": "Ölablasskupplung",
            "NormTermName": "Kupplung",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ventilschraube"
        },
        "09665": {
            "GenartName": "Ölablass-Set",
            "NormTermName": "Ölablass-Set",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "09666": {
            "GenartName": "Schleifstiftsatz",
            "NormTermName": "Schleifstiftsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Stabschleifer"
        },
        "09667": {
            "GenartName": "Kabelreparatursatz, Wasserumwälzpumpe-Standheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Standheizung"
        },
        "09668": {
            "GenartName": "Kabelreparatursatz, Wasserumwälzpumpe-Standheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Standheizung"
        },
        "09669": {
            "GenartName": "Kabelreparatursatz, Hupe/Horn",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Signalanlage",
            "UsageName": "Hupe"
        },
        "09670": {
            "GenartName": "Kabelreparatursatz, Hupe/Horn",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Hupe"
        },
        "09671": {
            "GenartName": "Kabelreparatursatz, Heizung-Kurbelgehäuseentlüftung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "09672": {
            "GenartName": "Kabelreparatursatz, Heizung-Kurbelgehäuseentlüftung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kurbelgehäuse, Universal",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "09673": {
            "GenartName": "Kabelreparatursatz, Stellelement-Feststellbremse",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremssattel-Feststellbremse"
        },
        "09674": {
            "GenartName": "Kabelreparatursatz, Stellelement-Feststellbremse",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremssattel-Feststellbremse"
        },
        "09675": {
            "GenartName": "Kabelreparatursatz, Waschwasserpumpe-Scheinwerferreinigung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": "Waschwasserpumpe-Scheinwerferreinigung"
        },
        "09676": {
            "GenartName": "Kabelreparatursatz, Waschwasserpumpe-Scheinwerferreinigung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Scheinwerferreinigung, Universal",
            "UsageName": "Waschwasserpumpe-Scheinwerferreinigung"
        },
        "09677": {
            "GenartName": "Kabelreparatursatz, Sensor-Waschwasserstand",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasserstand"
        },
        "09678": {
            "GenartName": "Kabelreparatursatz, Sensor-Waschwasserstand",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": "Waschwasserstand"
        },
        "09679": {
            "GenartName": "Kabelreparatursatz, Tankentlüftungsventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Tankentlüftung"
        },
        "09680": {
            "GenartName": "Kabelreparatursatz, Tankentlüftungsventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Tankentlüftung"
        },
        "09681": {
            "GenartName": "Kabelreparatursatz, Waschwasserdüse-Scheinwerferreinigung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": "Waschwasserdüse-Scheinwerferreinigung"
        },
        "09682": {
            "GenartName": "Kabelreparatursatz, Waschwasserdüse-Scheinwerferreinigung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Scheinwerferreinigung, Universal",
            "UsageName": "Waschwasserdüse-Scheinwerferreinigung"
        },
        "09683": {
            "GenartName": "Kabelreparatursatz, Hydraulikpumpe-Bremsanlage",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremshydraulik"
        },
        "09684": {
            "GenartName": "Kabelreparatursatz, Hydraulikpumpe-Bremsanlage",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremshydraulik"
        },
        "09685": {
            "GenartName": "Kabelreparatursatz, Umschaltventil-Motorlagerung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Motoraufhängung",
            "UsageName": "Motorlagerung"
        },
        "09686": {
            "GenartName": "Kabelreparatursatz, Umschaltventil-Motorlagerung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Motoraufhängung, Universal",
            "UsageName": "Motorlagerung"
        },
        "09687": {
            "GenartName": "Kabelreparatursatz, Sensor-Nockenwellenposition",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Nockenwelle"
        },
        "09688": {
            "GenartName": "Kabelreparatursatz, Sensor-Nockenwellenposition",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Nockenwelle"
        },
        "09689": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Frontscheibenheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Frontscheibenheizung"
        },
        "09690": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Frontscheibenheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Frontscheibenheizung"
        },
        "09691": {
            "GenartName": "Kabelreparatursatz, Magnetschalter-Kickdown",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Magnetschalter"
        },
        "09692": {
            "GenartName": "Kabelreparatursatz, Magnetschalter-Kickdown",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Automatikgetriebe, Universal",
            "UsageName": "Magnetschalter"
        },
        "09693": {
            "GenartName": "Kabelreparatursatz, Druckwandler-Drosselklappe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Stellelement (Drosselklappe)"
        },
        "09694": {
            "GenartName": "Kabelreparatursatz, Druckwandler-Drosselklappe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Stellelement (Drosselklappe)"
        },
        "09695": {
            "GenartName": "Kabelreparatursatz, Kraftstoffregenerierungsventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Aktivkohlebehälter"
        },
        "09696": {
            "GenartName": "Kabelreparatursatz, Kraftstoffregenerierungsventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Aktivkohlebehälter"
        },
        "09697": {
            "GenartName": "Kabelreparatursatz, Unterdruckpumpe-Bremsanlage",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruck"
        },
        "09698": {
            "GenartName": "Kabelreparatursatz, Unterdruckpumpe-Bremsanlage",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Unterdruck"
        },
        "09699": {
            "GenartName": "Kabelreparatursatz, Sekundärluftpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": "Sekundärluft"
        },
        "09700": {
            "GenartName": "Kabelreparatursatz, Sekundärluftpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sekundärlufteinblasung, Universal",
            "UsageName": "Sekundärluft"
        },
        "09701": {
            "GenartName": "Kabelreparatursatz, Druckwandler-Schaltsaugrohr",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Umschaltklappe (Saugrohr)"
        },
        "09702": {
            "GenartName": "Kabelreparatursatz, Druckwandler-Schaltsaugrohr",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung, Universal",
            "UsageName": "Umschaltklappe (Saugrohr)"
        },
        "09703": {
            "GenartName": "Kabelreparatursatz, Elektromotor-Windschott",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Windschott"
        },
        "09704": {
            "GenartName": "Kabelreparatursatz, Elektromotor-Windschott",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Windschott"
        },
        "09705": {
            "GenartName": "Kabelreparatursatz, Kompressor-Dämpferregulierung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Elektronische Dämpferregulierung"
        },
        "09706": {
            "GenartName": "Kabelreparatursatz, Kompressor-Dämpferregulierung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Federung/Dämpfung, Universal",
            "UsageName": "Elektronische Dämpferregulierung"
        },
        "09707": {
            "GenartName": "Montagewerkzeugsatz, Zahnrad-Kurbelwelle",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zahnrad-Kurbelwelle"
        },
        "09708": {
            "GenartName": "Mikromotorhandstück",
            "NormTermName": "Mikromotorhandstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09709": {
            "GenartName": "Ölablass-Ventilschraube",
            "NormTermName": "Ölablass-Ventilschraube",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ölablassschlauch"
        },
        "09710": {
            "GenartName": "Handpolierfilz",
            "NormTermName": "Handpolierfilz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Metall"
        },
        "09711": {
            "GenartName": "Riffelfeile",
            "NormTermName": "Feile",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Riffelfeile"
        },
        "09712": {
            "GenartName": "Schleifscheibe, Doppel-Schleifmaschine",
            "NormTermName": "Schleifscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Doppel-Schleifmaschine"
        },
        "09713": {
            "GenartName": "Schärffeile",
            "NormTermName": "Feile",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09714": {
            "GenartName": "Schleifbandarm, Bandfeile",
            "NormTermName": "Schleifbandarm",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bandfeile"
        },
        "09715": {
            "GenartName": "Schlauchfeinfilter, Drucklufttechnik",
            "NormTermName": "Schlauchfeinfilter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drucklufttechnik"
        },
        "09716": {
            "GenartName": "Wendemesser",
            "NormTermName": "Wendemesser",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09717": {
            "GenartName": "Staubbeutel, Exzenterschleifer",
            "NormTermName": "Staubbeutel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Exzenterschleifer"
        },
        "09718": {
            "GenartName": "Staubbeutel, Säge",
            "NormTermName": "Staubbeutel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Säge"
        },
        "09719": {
            "GenartName": "Staubbeutel, Hobel",
            "NormTermName": "Staubbeutel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Hobel"
        },
        "09720": {
            "GenartName": "Späneabsaugstutzen",
            "NormTermName": "Stutzen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Späneabsaugung"
        },
        "09721": {
            "GenartName": "Schnellwechseleinsatz, Bohrfutter (Kernbohrmaschine)",
            "NormTermName": "Schnellwechseleinsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bohrfutter (Kernbohrmaschine)"
        },
        "09722": {
            "GenartName": "Scherblatt, Heckenschere",
            "NormTermName": "Scherblatt",
            "AssGrpName": "Gartenwerkzeuge",
            "UsageName": "Heckenschere"
        },
        "09723": {
            "GenartName": "Harnstoff",
            "NormTermName": "Harnstoff",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstoff"
        },
        "09724": {
            "GenartName": "Druckrohrstutzen, Einspritzdüse",
            "NormTermName": "Druckrohrstutzen",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzdüse"
        },
        "09725": {
            "GenartName": "Überwurfschraube, Druckrohrstutzen-Einspritzdüse",
            "NormTermName": "Überwurfschraube",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzdüse"
        },
        "09726": {
            "GenartName": "Polierpaste",
            "NormTermName": "Polierpaste",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Karosserie"
        },
        "09727": {
            "GenartName": "Haftpolierscheibe",
            "NormTermName": "Haftpolierscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09728": {
            "GenartName": "Kegeldorn, Kernbohrmaschine",
            "NormTermName": "Kegeldorn",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Kernbohrmaschine"
        },
        "09729": {
            "GenartName": "Kreisschneideeinrichtung, Bandsäge",
            "NormTermName": "Kreisschneideeinrichtung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bandsäge"
        },
        "09730": {
            "GenartName": "Schnellspannadapter, Lochsäge",
            "NormTermName": "Schnellspannadapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Lochsäge"
        },
        "09731": {
            "GenartName": "Vliesscheibe, Schleiftechnik",
            "NormTermName": "Vliesscheibe",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09732": {
            "GenartName": "Ölnebelgerät",
            "NormTermName": "Ölnebelgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Druckluftanlage"
        },
        "09733": {
            "GenartName": "Polierscheibensatz",
            "NormTermName": "Polierscheibensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Poliermaschine"
        },
        "09734": {
            "GenartName": "Rohrbandschleifer (Akku)",
            "NormTermName": "Rohrbandschleifer (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09735": {
            "GenartName": "Rohrbandschleifer (Netzgebunden)",
            "NormTermName": "Rohrbandschleifer (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09736": {
            "GenartName": "Saugdüse, Trockensauger",
            "NormTermName": "Saugdüse",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Trockensauger"
        },
        "09737": {
            "GenartName": "Rohrstutzen, Drucklufttechnik",
            "NormTermName": "Rohrstutzen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Drucklufttechnik"
        },
        "09738": {
            "GenartName": "Saugschlauch, Späneabsauganlage",
            "NormTermName": "Saugschlauch",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Späneabsaugung"
        },
        "09739": {
            "GenartName": "Schlauchverbindersatz, Späneabsauganlage",
            "NormTermName": "Schlauchverbindersatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Späneabsaugung"
        },
        "09740": {
            "GenartName": "Schleifband, Doppel-Schleifmaschine",
            "NormTermName": "Schleifband",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Doppel-Schleifmaschine"
        },
        "09741": {
            "GenartName": "Thermodruckerpapier, Klimaservicegerät",
            "NormTermName": "Thermodruckerpapier",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimaservicegerät"
        },
        "09742": {
            "GenartName": "Heizbandsatz, Klimaservicegerät",
            "NormTermName": "Heizbandsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimaservicegerät"
        },
        "09743": {
            "GenartName": "Servicefilter, Klimaservicegerät",
            "NormTermName": "Servicefilter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimaservicegerät"
        },
        "09744": {
            "GenartName": "Vakuumpumpensatz",
            "NormTermName": "Pumpensatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09745": {
            "GenartName": "USB-Stick",
            "NormTermName": "USB-Stick",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09746": {
            "GenartName": "Diagnosegerät, Abgasnachbehandlung",
            "NormTermName": "Diagnosegerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Abgasnachbehandlung"
        },
        "09747": {
            "GenartName": "Aufnahmeflansch, Drehmaschine",
            "NormTermName": "Aufnahmeflansch",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Drehmaschine"
        },
        "09748": {
            "GenartName": "Aufnahmeflansch-Set, Drehmaschine",
            "NormTermName": "Aufnahmeflansch-Set",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Drehmaschine"
        },
        "09749": {
            "GenartName": "Demontagewerkzeug, Antriebswelle",
            "NormTermName": "Demontagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Antriebswelle"
        },
        "09750": {
            "GenartName": "Gewindereparatursatz, Radbolzen",
            "NormTermName": "Gewindereparatursatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radbolzen"
        },
        "09751": {
            "GenartName": "Gewindereparatur, Radbolzen",
            "NormTermName": "Gewindereparatur",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radbolzen"
        },
        "09752": {
            "GenartName": "Gewindereparatur, Radbolzen",
            "NormTermName": "Gewindereparatur",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Radbolzen"
        },
        "09753": {
            "GenartName": "Gewindereparatursatz, Radbolzen",
            "NormTermName": "Gewindereparatursatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Radbolzen"
        },
        "09754": {
            "GenartName": "Auspressvorrichtungssatz, Antriebswelle",
            "NormTermName": "Auspressvorrichtungssatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Antriebswelle"
        },
        "09755": {
            "GenartName": "Trennwerkzeug, Radmontage",
            "NormTermName": "Trennwerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radmontage"
        },
        "09756": {
            "GenartName": "Trennwerkzeugsatz, Radmontage",
            "NormTermName": "Trennwerkzeugsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Radmontage"
        },
        "09757": {
            "GenartName": "Trennwerkzeug, Radmontage",
            "NormTermName": "Trennwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radmontage"
        },
        "09758": {
            "GenartName": "Trennwerkzeugsatz, Radmontage",
            "NormTermName": "Trennwerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Radmontage"
        },
        "09759": {
            "GenartName": "Adapterplatte, Zylinderlaufbuchse",
            "NormTermName": "Adapterplatte",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Zylinderlaufbuchse"
        },
        "09760": {
            "GenartName": "Haltering, Abzieher",
            "NormTermName": "Haltering",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Differential-Hauptwelle"
        },
        "09761": {
            "GenartName": "Entriegelungswerkzeug, Doppelkupplungsgetriebe (DSG)",
            "NormTermName": "Entriegelungswerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Doppelkupplungsgetriebe (DSG)"
        },
        "09762": {
            "GenartName": "Adapter, Rohrentgrater",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Rohrentgrater"
        },
        "09763": {
            "GenartName": "Montagewerkzeugsatz, Achskörperlagerung",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": null
        },
        "09764": {
            "GenartName": "Montagewerkzeugsatz, Steuergehäusedeckel",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Steuergehäusedeckel"
        },
        "09765": {
            "GenartName": "Handstück, Biegewellensystem",
            "NormTermName": "Handstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Biegewellensystem"
        },
        "09766": {
            "GenartName": "Tuning-Modul-Satz, Motorleistungssteigerung",
            "NormTermName": "Tuning-Modul-Satz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Motorleistungssteigerung"
        },
        "09767": {
            "GenartName": "Motor, Biegewellensystem",
            "NormTermName": "Motor",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Biegewellensystem"
        },
        "09768": {
            "GenartName": "Pumpenmontagesatz, Hauswasserwerk",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Pumpe-Hauswasserwerk"
        },
        "09769": {
            "GenartName": "Tacker (Akku)",
            "NormTermName": "Tacker (Akku)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09770": {
            "GenartName": "Tacker (Netzgebunden)",
            "NormTermName": "Tacker (Netzgebunden)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09771": {
            "GenartName": "Tacker (Druckluft)",
            "NormTermName": "Tacker (Druckluft)",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09772": {
            "GenartName": "Fußpedal, Biegewellensystem",
            "NormTermName": "Pedal",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Biegewellensystem"
        },
        "09773": {
            "GenartName": "Biegewellenhülse, Biegewellensystem",
            "NormTermName": "Hülse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Biegewellensystem"
        },
        "09774": {
            "GenartName": "Biegewelle, Biegewellensystem",
            "NormTermName": "Welle",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Biegewellensystem"
        },
        "09775": {
            "GenartName": "Kohlebürste, Biegewellensystem (Motor)",
            "NormTermName": "Kohlebürste",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Biegewellensystem (Motor)"
        },
        "09776": {
            "GenartName": "Lager, Biegewellensystem (Motor)",
            "NormTermName": "Lager",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Biegewellensystem (Motor)"
        },
        "09777": {
            "GenartName": "Rotor, Biegewellensystem (Motor)",
            "NormTermName": "Rotor",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Biegewellensystem (Motor)"
        },
        "09778": {
            "GenartName": "Schauglas, Kältemittelanalyse (Klimaservicegerät)",
            "NormTermName": "Schauglas",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Kältemittelanalyse (Klimaservicegerät)"
        },
        "09779": {
            "GenartName": "Nockenwellenträger",
            "NormTermName": "Nockenwellenträger",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwelle"
        },
        "09780": {
            "GenartName": "Pneumatiköl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09781": {
            "GenartName": "Turbinenöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09782": {
            "GenartName": "Isolieröl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09783": {
            "GenartName": "Schleiföl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09784": {
            "GenartName": "Kühlschmiermittel",
            "NormTermName": "Kühlschmiermittel",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09785": {
            "GenartName": "Gleitbahnöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Drehmaschine"
        },
        "09786": {
            "GenartName": "Umformöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09787": {
            "GenartName": "Honöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09788": {
            "GenartName": "Härteöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09789": {
            "GenartName": "Wärmeträgeröl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09790": {
            "GenartName": "Filter, Unterdruckleitung",
            "NormTermName": "Filter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruckleitung"
        },
        "09791": {
            "GenartName": "Weißöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09792": {
            "GenartName": "Schalöl",
            "NormTermName": "Öl",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09793": {
            "GenartName": "Reinigungsadditiv, Wärmeträgeranlage",
            "NormTermName": "Reinigungsadditiv",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09794": {
            "GenartName": "Kabelreparatursatz, Außenspiegelheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Außenspiegelheizung"
        },
        "09795": {
            "GenartName": "Kabelreparatursatz, Außenspiegelheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Außenspiegelheizung"
        },
        "09796": {
            "GenartName": "Mischerschutz",
            "NormTermName": "Mischerschutz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "09797": {
            "GenartName": "Kraftstoffadditiv",
            "NormTermName": "Zusatzmittel",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoff"
        },
        "09798": {
            "GenartName": "Sensor, Toter-Winkel-Erkennung",
            "NormTermName": "Sensor",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Toter-Winkel-Erkennung"
        },
        "09799": {
            "GenartName": "Kabelsatz, Toter-Winkel-Sensor",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Toter-Winkel-Sensor"
        },
        "09800": {
            "GenartName": "Steuergerät, Blinkererkennung",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Blinkererkennung"
        },
        "09801": {
            "GenartName": "Kamera, Toter-Winkel-Assistent",
            "NormTermName": "Kamera",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Toter-Winkel-Assistent"
        },
        "09802": {
            "GenartName": "Steuergerät, Toter-Winkel-Assistent",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Toter-Winkel-Assistent"
        },
        "09803": {
            "GenartName": "Erweiterungssatz, Ausbohrwerkzeug-Querlenkerklemmschraube",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Querlenkerklemmschraube"
        },
        "09804": {
            "GenartName": "Lösezange, Injektordichtung",
            "NormTermName": "Lösezange",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": null
        },
        "09805": {
            "GenartName": "Umrüstsatz, Federspanner",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Federspanner"
        },
        "09806": {
            "GenartName": "Kabelreparatursatz, Klimakompressor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Klimakompressor"
        },
        "09807": {
            "GenartName": "Kabelreparatursatz, Klimakompressor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Klimakompressor"
        },
        "09808": {
            "GenartName": "Kabelreparatursatz, Motorlagerung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Motoraufhängung",
            "UsageName": "Motorlagerung"
        },
        "09809": {
            "GenartName": "Kabelreparatursatz, Motorlagerung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Motoraufhängung, Universal",
            "UsageName": "Motorlagerung"
        },
        "09810": {
            "GenartName": "Reparatursatz, Hauptscheinwerfer (Halter)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Hauptscheinwerfer (Halter)"
        },
        "09811": {
            "GenartName": "SCR-Katalysator",
            "NormTermName": "SCR-Katalysator",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "09812": {
            "GenartName": "Reparaturrohr, Abgasrohr",
            "NormTermName": "Reparaturrohr",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasrohr"
        },
        "09813": {
            "GenartName": "Lamellenkupplung-Allradantrieb",
            "NormTermName": "Lamellenkupplung-Allradantrieb",
            "AssGrpName": "Achsantrieb",
            "UsageName": null
        },
        "09814": {
            "GenartName": "Sensor, Lamellenkupplung-Allradantrieb",
            "NormTermName": "Sensor",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Lamellenkupplung-Allradantrieb"
        },
        "09815": {
            "GenartName": "Reibwertmessgerät",
            "NormTermName": "Reibwertmessgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09816": {
            "GenartName": "Niederhalter, Lenker-Radaufhängung",
            "NormTermName": "Niederhalter",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": null
        },
        "09817": {
            "GenartName": "Antriebskette, Automatikgetriebe",
            "NormTermName": "Kette",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "09818": {
            "GenartName": "Lochstanzen-Satz, Sensor-Einparkhilfe",
            "NormTermName": "Lochstanzen-Satz",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Sensor-Einparkhilfe"
        },
        "09819": {
            "GenartName": "Messer, Schlauchabschneider",
            "NormTermName": "Messer",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schlauch"
        },
        "09820": {
            "GenartName": "Spirale, Rohrreinigungsgerät",
            "NormTermName": "Spirale",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09821": {
            "GenartName": "Kabelreparatursatz, Niveausensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Niveausensor"
        },
        "09822": {
            "GenartName": "Kabelreparatursatz, Niveausensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Radaufhängung, Universal",
            "UsageName": "Niveausensor"
        },
        "09823": {
            "GenartName": "Kabelreparatursatz, Stellmotor-Heizklappen",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Stellmotor-Heizklappen"
        },
        "09824": {
            "GenartName": "Kabelreparatursatz, Stellmotor-Heizklappen",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung, Universal",
            "UsageName": "Stellmotor-Heizklappen"
        },
        "09825": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Hinterachslenkung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Steuergerät-Hinterachslenkung"
        },
        "09826": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Hinterachslenkung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Lenkung, Universal",
            "UsageName": "Steuergerät-Hinterachslenkung"
        },
        "09827": {
            "GenartName": "Kabelreparatursatz, Lenksäule",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Lenksäule"
        },
        "09828": {
            "GenartName": "Kabelreparatursatz, Lenksäule",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Lenkung, Universal",
            "UsageName": "Lenksäule"
        },
        "09829": {
            "GenartName": "Kabelreparatursatz, Bremspedalwegsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremspedalwegsensor"
        },
        "09830": {
            "GenartName": "Kabelreparatursatz, Bremspedalwegsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremspedalwegsensor"
        },
        "09831": {
            "GenartName": "Ersatzrad, Werkstatt-Trolley",
            "NormTermName": "Ersatzrad",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkstatt-Trolley"
        },
        "09832": {
            "GenartName": "Kabelreparatursatz, Antennenverstärker",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Antennenverstärker"
        },
        "09833": {
            "GenartName": "Kabelreparatursatz, Antennenverstärker",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Antennenverstärker"
        },
        "09834": {
            "GenartName": "Kabelreparatursatz, Regensensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Regensensor"
        },
        "09835": {
            "GenartName": "Kabelreparatursatz, Regensensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Regensensor"
        },
        "09836": {
            "GenartName": "Kabelreparatursatz, Regen-/Lichtsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Regen-/Lichtsensor"
        },
        "09837": {
            "GenartName": "Kabelreparatursatz, Regen-/Lichtsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Regen-/Lichtsensor"
        },
        "09838": {
            "GenartName": "Kabelreparatursatz, Schalter-Handschuhfach",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Schalter-Handschuhfach"
        },
        "09839": {
            "GenartName": "Kabelreparatursatz, Schalter-Handschuhfach",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": "Schalter-Handschuhfach"
        },
        "09840": {
            "GenartName": "Kabelreparatursatz, Sitzbelegungsmatte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Sitzbelegungsmatte"
        },
        "09841": {
            "GenartName": "Kabelreparatursatz, Sitzbelegungsmatte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Sitzbelegungsmatte"
        },
        "09842": {
            "GenartName": "Kabelreparatursatz, Schalter-Fußfeststellbremse",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Schalter-Fußfeststellbremse"
        },
        "09843": {
            "GenartName": "Kabelreparatursatz, Schalter-Fußfeststellbremse",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Schalter-Fußfeststellbremse"
        },
        "09844": {
            "GenartName": "Kabelreparatursatz, Analoguhr",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Instrumente",
            "UsageName": "Analoguhr"
        },
        "09845": {
            "GenartName": "Kabelreparatursatz, Analoguhr",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Instrumente, Universal",
            "UsageName": "Analoguhr"
        },
        "09846": {
            "GenartName": "Kabelreparatursatz, Sonnenblendenleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Sonnenblendenleuchte"
        },
        "09847": {
            "GenartName": "Kabelreparatursatz, Sonnenblendenleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": "Sonnenblendenleuchte"
        },
        "09848": {
            "GenartName": "Kabelreparatursatz, Schloss-Anhängerkugelkopf",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Schloss-Anhängerkugelkopf"
        },
        "09849": {
            "GenartName": "Kabelreparatursatz, Schloss-Anhängerkugelkopf",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Schloss-Anhängerkugelkopf"
        },
        "09850": {
            "GenartName": "Kabelreparatursatz, Innenraumleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Innenraumleuchte"
        },
        "09851": {
            "GenartName": "Kabelreparatursatz, Innenraumleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Innenraumleuchte"
        },
        "09852": {
            "GenartName": "Kabelreparatursatz, Heckklappengriff",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage",
            "UsageName": "Heckklappengriff"
        },
        "09853": {
            "GenartName": "Kabelreparatursatz, Heckklappengriff",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Heckklappengriff"
        },
        "09854": {
            "GenartName": "Kabelreparatursatz, Kofferraumdeckelgriff",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage",
            "UsageName": "Kofferraumdeckelgriff"
        },
        "09855": {
            "GenartName": "Kabelreparatursatz, Kofferraumdeckelgriff",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Kofferraumdeckelgriff"
        },
        "09856": {
            "GenartName": "Kabelreparatursatz, Zusatzbremsleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Signalanlage",
            "UsageName": "Zusatzbremsleuchte"
        },
        "09857": {
            "GenartName": "Kabelreparatursatz, Zusatzbremsleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Signalanlage, Universal",
            "UsageName": "Zusatzbremsleuchte"
        },
        "09858": {
            "GenartName": "Kabelreparatursatz, Lenkradfernbedienung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Lenkradfernbedienung"
        },
        "09859": {
            "GenartName": "Kabelreparatursatz, Lenkradfernbedienung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Lenkradfernbedienung"
        },
        "09860": {
            "GenartName": "Kabelreparatursatz, Lautsprecher",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Lautsprecher"
        },
        "09861": {
            "GenartName": "Kabelreparatursatz, Lautsprecher",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Lautsprecher"
        },
        "09862": {
            "GenartName": "Kabelreparatursatz, Handschuhfachleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Handschuhfachleuchte"
        },
        "09863": {
            "GenartName": "Kabelreparatursatz, Handschuhfachleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Handschuhfachleuchte"
        },
        "09864": {
            "GenartName": "Taxameter",
            "NormTermName": "Taxameter",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "09865": {
            "GenartName": "Taxameter",
            "NormTermName": "Taxameter",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": null
        },
        "09866": {
            "GenartName": "Spiegeltaxameter",
            "NormTermName": "Spiegeltaxameter",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "09867": {
            "GenartName": "Spiegeltaxameter",
            "NormTermName": "Spiegeltaxameter",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": null
        },
        "09868": {
            "GenartName": "Kabelreparatursatz, Spiegeltaxameter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "09869": {
            "GenartName": "Kabelreparatursatz, Spiegeltaxameter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": null
        },
        "09870": {
            "GenartName": "Kabelreparatursatz, Innenspiegel",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Innenspiegel"
        },
        "09871": {
            "GenartName": "Kabelreparatursatz, Innenspiegel",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Innenspiegel"
        },
        "09872": {
            "GenartName": "Kabelreparatursatz, Antenne",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Antenne"
        },
        "09873": {
            "GenartName": "Kabelreparatursatz, Antenne",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Antenne"
        },
        "09874": {
            "GenartName": "Kabelreparatursatz, Kamera",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Kamera"
        },
        "09875": {
            "GenartName": "Kabelreparatursatz, Kamera",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Kamera"
        },
        "09876": {
            "GenartName": "Kabelreparatursatz, Schließzylinder-Zündschloss",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage",
            "UsageName": "Schließzylinder-Zündschloss"
        },
        "09877": {
            "GenartName": "Kabelreparatursatz, Schließzylinder-Zündschloss",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Schließzylinder-Zündschloss"
        },
        "09878": {
            "GenartName": "Additivpumpe",
            "NormTermName": "Pumpe",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Additiv"
        },
        "09879": {
            "GenartName": "Abgassensoren-Set",
            "NormTermName": "Abgassensoren-Set",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": null
        },
        "09880": {
            "GenartName": "Kabelreparatursatz, Schließzylinder-Türschloss",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage",
            "UsageName": "Schließzylinder-Türschloss"
        },
        "09881": {
            "GenartName": "Kabelreparatursatz, Schließzylinder-Türschloss",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Schließzylinder-Türschloss"
        },
        "09882": {
            "GenartName": "Kabelreparatursatz, Verdeckschloss",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Verdeckschloss"
        },
        "09883": {
            "GenartName": "Kabelreparatursatz, Verdeckschloss",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Verdeckschloss"
        },
        "09884": {
            "GenartName": "Kabelreparatursatz, Fußraumleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fußraumleuchte"
        },
        "09885": {
            "GenartName": "Kabelreparatursatz, Fußraumleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Fußraumleuchte"
        },
        "09886": {
            "GenartName": "Kabelreparatursatz, Aschenbecherbeleuchtung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Aschenbecherbeleuchtung"
        },
        "09887": {
            "GenartName": "Kabelreparatursatz, Aschenbecherbeleuchtung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Aschenbecherbeleuchtung"
        },
        "09888": {
            "GenartName": "Dichtung, Nockenwellenverstellung",
            "NormTermName": "Dichtung",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwellenverstellung"
        },
        "09889": {
            "GenartName": "Dichtungssatz, Kurbelgehäuseentlüftung",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "09890": {
            "GenartName": "Reparatursatz, AGR-System",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "AGR-System"
        },
        "09891": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Glühzeit",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Glühanlage",
            "UsageName": "Steuergerät-Glühzeit"
        },
        "09892": {
            "GenartName": "Bedienungsanleitung",
            "NormTermName": "Bedienungsanleitung",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "09893": {
            "GenartName": "Serviceheft",
            "NormTermName": "Serviceheft",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "09894": {
            "GenartName": "Schweißerschutzvorhang",
            "NormTermName": "Schweißerschutzvorhang",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "09895": {
            "GenartName": "Gestell, Schweißerschutzvorhang",
            "NormTermName": "Gestell",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Schweißerschutzvorhang"
        },
        "09896": {
            "GenartName": "Schweißerschutzdecke, Heckklappe",
            "NormTermName": "Decke",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Heckklappe"
        },
        "09897": {
            "GenartName": "Schweißerschutzdecke, Radabdeckung",
            "NormTermName": "Decke",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Radabdeckung"
        },
        "09898": {
            "GenartName": "Schweißerschutzdecke, Scheibe",
            "NormTermName": "Decke",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Scheibe"
        },
        "09899": {
            "GenartName": "Schweißerschutzdecke, Tür",
            "NormTermName": "Decke",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": "Tür"
        },
        "09900": {
            "GenartName": "Schweißerkissen",
            "NormTermName": "Schweißerkissen",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "09901": {
            "GenartName": "Schweißer-Spritzschutzdecke",
            "NormTermName": "Schweißer-Spritzschutzdecke",
            "AssGrpName": "Schutzkleidung/-ausrüstung",
            "UsageName": null
        },
        "09902": {
            "GenartName": "Kabelreparatursatz, Scheibenheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Scheibenheizung"
        },
        "09903": {
            "GenartName": "Kabelreparatursatz, Scheibenheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Scheibenheizung"
        },
        "09904": {
            "GenartName": "Kabelreparatursatz, Scheibenantenne",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Scheibenantenne"
        },
        "09905": {
            "GenartName": "Kabelreparatursatz, Scheibenantenne",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Scheibenantenne"
        },
        "09906": {
            "GenartName": "Kabelreparatursatz, Druckwandler",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": "Sekundärluft"
        },
        "09907": {
            "GenartName": "Kabelreparatursatz, Druckwandler",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sekundärlufteinblasung, Universal",
            "UsageName": "Sekundärluft"
        },
        "09908": {
            "GenartName": "Autoschlüssel",
            "NormTermName": "Autoschlüssel",
            "AssGrpName": "Schließanlage",
            "UsageName": null
        },
        "09909": {
            "GenartName": "Gehäuse, Autoschlüssel",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Schließanlage",
            "UsageName": "Autoschlüssel"
        },
        "09910": {
            "GenartName": "Rohrreinigungsgerät",
            "NormTermName": "Rohrreinigungsgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "09911": {
            "GenartName": "Abstech-/Anfasgrundgerät",
            "NormTermName": "Abstech-/Anfasgrundgerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09912": {
            "GenartName": "Spannbackensatz, Anfasgrundgerät",
            "NormTermName": "Spannbackensatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Anfasgrundgerät"
        },
        "09913": {
            "GenartName": "Abstechpistole",
            "NormTermName": "Abstechpistole",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "09914": {
            "GenartName": "Montageschlüssel, WC-Sitz",
            "NormTermName": "Schlüssel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "WC-Sitz"
        },
        "09915": {
            "GenartName": "Montagewerkzeug, Abflussventil",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abflussventil"
        },
        "09916": {
            "GenartName": "Adapter, Montagewerkzeug-Abflussventil",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Montagewerkzeug-Abflussventil"
        },
        "09917": {
            "GenartName": "Montagewerkzeugsatz, Abflussventil",
            "NormTermName": "Montagewerkzeugsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Abflussventil"
        },
        "09918": {
            "GenartName": "Adapter, Fettpresse",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Fettpresse"
        },
        "09919": {
            "GenartName": "Schutzhülle, Schlagschrauber",
            "NormTermName": "Schutzhülle",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schlagschrauber"
        },
        "09920": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Motorlager",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Motoraufhängung",
            "UsageName": "Steuergerät-Motorlager"
        },
        "09921": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Motorlager",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Motoraufhängung, Universal",
            "UsageName": "Steuergerät-Motorlager"
        },
        "09922": {
            "GenartName": "Kabelreparatursatz, Steuergerät Keyless-System",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Steuergerät Keyless-System"
        },
        "09923": {
            "GenartName": "Kabelreparatursatz, Steuergerät Keyless-System",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Steuergerät Keyless-System"
        },
        "09924": {
            "GenartName": "Kabelreparatursatz, Bordnetzsteuergerät",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz",
            "UsageName": "Bordnetzsteuergerät"
        },
        "09925": {
            "GenartName": "Kabelreparatursatz, Bordnetzsteuergerät",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz, Universal",
            "UsageName": "Bordnetzsteuergerät"
        },
        "09926": {
            "GenartName": "Schraubensatz, Ölwanne-Automatikgetriebe",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölwanne-Automatikgetriebe"
        },
        "09927": {
            "GenartName": "Kabelreparatursatz, Sitzheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Sitzheizung"
        },
        "09928": {
            "GenartName": "Kabelreparatursatz, Sitzheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Sitzheizung"
        },
        "09929": {
            "GenartName": "Schutzkappe, Batteriepol",
            "NormTermName": "Kappe",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Batteriepol"
        },
        "09930": {
            "GenartName": "Kabelreparatursatz, Empfangsantenne-Reifendruckkontrolle",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Räder",
            "UsageName": "Empfangsantenne-Reifendruckkontrolle"
        },
        "09931": {
            "GenartName": "Kabelreparatursatz, Empfangsantenne-Reifendruckkontrolle",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Empfangsantenne-Reifendruckkontrolle"
        },
        "60000": {
            "GenartName": "Laufrolle, Seitenplane",
            "NormTermName": "Laufrolle",
            "AssGrpName": "Anhänger",
            "UsageName": "Seitenplane"
        },
        "60001": {
            "GenartName": "Reparatursatz, AGR-Ventil",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "AGR-Ventil"
        },
        "60002": {
            "GenartName": "Reparatursatz, AGR-Modul",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "AGR-Modul"
        },
        "60003": {
            "GenartName": "Adapterringsatz, Bremsscheibenbefestigung-Schlagprüfung",
            "NormTermName": "Adapterringsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Bremsscheibenbefestigung-Schlagprüfung"
        },
        "60004": {
            "GenartName": "Klemmschraube, Seilzug-Vergaser",
            "NormTermName": "Schraube",
            "AssGrpName": "Vergaser",
            "UsageName": "Seilzug-Vergaser"
        },
        "60005": {
            "GenartName": "Klemmschraube, Seilzug-Vergaser",
            "NormTermName": "Schraube",
            "AssGrpName": "Vergaser, Universal",
            "UsageName": "Seilzug-Vergaser"
        },
        "60006": {
            "GenartName": "Kolben + Pleuel, Druckluftkompressor",
            "NormTermName": "Kolben + Pleuel",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "60007": {
            "GenartName": "Adapter, Druckluftkompressor",
            "NormTermName": "Adapter",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "60008": {
            "GenartName": "Druckregler, Druckluftkompressor",
            "NormTermName": "Regler",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "60009": {
            "GenartName": "Zubehörsatz, Bremsbacken",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremsbacken"
        },
        "60010": {
            "GenartName": "Aufbewahrungsbox, Feuerlöscher",
            "NormTermName": "Aufbewahrungsbox",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Feuerlöscher"
        },
        "60011": {
            "GenartName": "Zylinderkopfdichtung, Druckluftkompressor",
            "NormTermName": "Dichtung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "60012": {
            "GenartName": "Zylinderkopfdichtungssatz, Druckluftkompressor",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "60013": {
            "GenartName": "Lagerbuchse, Retarder",
            "NormTermName": "Buchse",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Retarder"
        },
        "60014": {
            "GenartName": "Manschette, Schaltzylinder",
            "NormTermName": "Manschette",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltzylinder"
        },
        "60015": {
            "GenartName": "Sensor, Fahrerhauskippvorrichtung",
            "NormTermName": "Sensor",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhauskippvorrichtung"
        },
        "60016": {
            "GenartName": "Sensor, Fahrerhausverriegelung",
            "NormTermName": "Sensor",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhausverriegelung"
        },
        "60017": {
            "GenartName": "Griff, Staukasten",
            "NormTermName": "Griff",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Staukasten"
        },
        "60018": {
            "GenartName": "Tankgeber",
            "NormTermName": "Tankgeber",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": null
        },
        "60019": {
            "GenartName": "Schaltgabel",
            "NormTermName": "Schaltgabel",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": null
        },
        "60020": {
            "GenartName": "Kabelreparatursatz, Öldruckschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Öldruckschalter"
        },
        "60021": {
            "GenartName": "Kabelreparatursatz, Öldruckschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schmierung, Universal",
            "UsageName": "Öldruckschalter"
        },
        "60022": {
            "GenartName": "Kabelreparatursatz, Dosierpumpe-Standheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Dosierpumpe-Standheizung"
        },
        "60023": {
            "GenartName": "Kabelreparatursatz, Dosierpumpe-Standheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Dosierpumpe-Standheizung"
        },
        "60024": {
            "GenartName": "Kabelreparatursatz, Drehzahlsensor-Einspritzpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Drehzahlsensor-Einspritzpumpe"
        },
        "60025": {
            "GenartName": "Kabelreparatursatz, Drehzahlsensor-Einspritzpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Drehzahlsensor-Einspritzpumpe"
        },
        "60026": {
            "GenartName": "Kabelreparatursatz, Kaltstartventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kaltstartventil"
        },
        "60027": {
            "GenartName": "Kabelreparatursatz, Kaltstartventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Kaltstartventil"
        },
        "60028": {
            "GenartName": "Kabelreparatursatz, Ventil-Schubabschaltung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Ventil-Schubabschaltung"
        },
        "60029": {
            "GenartName": "Kabelreparatursatz, Ventil-Schubabschaltung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Ventil-Schubabschaltung"
        },
        "60030": {
            "GenartName": "Kabelreparatursatz, Sekundärluftventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": "Sekundärluftventil"
        },
        "60031": {
            "GenartName": "Kabelreparatursatz, Sekundärluftventil",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sekundärlufteinblasung, Universal",
            "UsageName": "Sekundärluftventil"
        },
        "60032": {
            "GenartName": "Kabelreparatursatz, Stellmotor-Frischluftklappen",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Stellmotor-Frischluftklappen"
        },
        "60033": {
            "GenartName": "Kabelreparatursatz, Stellmotor-Frischluftklappen",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung, Universal",
            "UsageName": "Stellmotor-Frischluftklappen"
        },
        "60034": {
            "GenartName": "Kabelreparatursatz, Schalter-Differentialsperre",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Schalter-Differentialsperre"
        },
        "60035": {
            "GenartName": "Kabelreparatursatz, Schalter-Differentialsperre",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Achsantrieb, Universal",
            "UsageName": "Schalter-Differentialsperre"
        },
        "60036": {
            "GenartName": "Kabelreparatursatz, Temperatursensor-Verdampfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Temperatursensor-Verdampfer"
        },
        "60037": {
            "GenartName": "Kabelreparatursatz, Temperatursensor-Verdampfer",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Temperatursensor-Verdampfer"
        },
        "60038": {
            "GenartName": "Kabelreparatursatz, Temperaturschalter-Klimalüfter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Temperaturschalter-Klimalüfter"
        },
        "60039": {
            "GenartName": "Kabelreparatursatz, Temperaturschalter-Klimalüfter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Temperaturschalter-Klimalüfter"
        },
        "60040": {
            "GenartName": "Drehmomentvervielfältiger, KW-Riemenscheibenschraube",
            "NormTermName": "Drehmomentvervielfältiger",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Kurbelwellen-Riemenscheibenschraube"
        },
        "60041": {
            "GenartName": "Klemmstück, Abgasanlage",
            "NormTermName": "Klemmstück",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": null
        },
        "60042": {
            "GenartName": "Seilzug, Umschaltsperre-Automatikgetriebe",
            "NormTermName": "Seilzug",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Umschaltsperre-Automatikgetriebe"
        },
        "60043": {
            "GenartName": "Federbalg, Luftfederbein",
            "NormTermName": "Federbalg",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Luftfederbein"
        },
        "60044": {
            "GenartName": "Kabelreparatursatz, Steuergerät (Garagentoröffner)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Zubehör",
            "UsageName": "Steuergerät (Garagentoröffner)"
        },
        "60045": {
            "GenartName": "Kabelreparatursatz, Steuergerät (Garagentoröffner)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Steuergerät (Garagentoröffner)"
        },
        "60046": {
            "GenartName": "Kabelreparatursatz, Steuergerät (Tank-Leckagediagnose)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Steuergerät (Tank-Leckagediagnose)"
        },
        "60047": {
            "GenartName": "Kabelreparatursatz, Steuergerät (Tank-Leckagediagnose)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Steuergerät (Tank-Leckagediagnose)"
        },
        "60048": {
            "GenartName": "Kabelreparatursatz, Steuergerät (Allradantrieb)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Steuergerät (Allradantrieb)"
        },
        "60049": {
            "GenartName": "Kabelreparatursatz, Steuergerät (Allradantrieb)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Achsantrieb, Universal",
            "UsageName": "Steuergerät (Allradantrieb)"
        },
        "60050": {
            "GenartName": "Kabelreparatursatz, Einspritzpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpe"
        },
        "60051": {
            "GenartName": "Kabelreparatursatz, Einspritzpumpe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Einspritzpumpe"
        },
        "60052": {
            "GenartName": "Reduzierstück, Flanschverbindung (Abgasanlage)",
            "NormTermName": "Reduzierstück",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Flanschverbindung (Abgasanlage)"
        },
        "60053": {
            "GenartName": "Reduzierstück, Flanschverbindung (Abgasanlage)",
            "NormTermName": "Reduzierstück",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Flanschverbindung (Abgasanlage)"
        },
        "60054": {
            "GenartName": "Kabelreparatursatz, Startautomatik",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Vergaser",
            "UsageName": "Startautomatik"
        },
        "60055": {
            "GenartName": "Kabelreparatursatz, Startautomatik",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Vergaser, Universal",
            "UsageName": "Startautomatik"
        },
        "60056": {
            "GenartName": "Kabelreparatursatz, Ventilumluftabschaltung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Vergaser",
            "UsageName": "Ventilumluftabschaltung"
        },
        "60057": {
            "GenartName": "Kabelreparatursatz, Ventilumluftabschaltung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Vergaser, Universal",
            "UsageName": "Ventilumluftabschaltung"
        },
        "60058": {
            "GenartName": "Anschlagpuffer, Schiebetür",
            "NormTermName": "Puffer",
            "AssGrpName": "Karosserie",
            "UsageName": "Schiebetür"
        },
        "60059": {
            "GenartName": "Klappe, Handschuhfach",
            "NormTermName": "Klappe",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Handschuhfach"
        },
        "60060": {
            "GenartName": "Relais, Druckluftkompressor (Luftfederung)",
            "NormTermName": "Relais",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor (Luftfederung)"
        },
        "60061": {
            "GenartName": "Sensor, Frontscheibe",
            "NormTermName": "Sensor",
            "AssGrpName": "Karosserie",
            "UsageName": "Frontscheibe"
        },
        "60062": {
            "GenartName": "Gleitbacke, Sitzverstellung",
            "NormTermName": "Gleitbacke",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Sitzverstellung"
        },
        "60063": {
            "GenartName": "Sensor, Türaußengriff",
            "NormTermName": "Sensor",
            "AssGrpName": "Schließanlage",
            "UsageName": "Türaußengriff"
        },
        "60064": {
            "GenartName": "Stellelement, Tankklappe",
            "NormTermName": "Stellelement",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Tankklappe"
        },
        "60065": {
            "GenartName": "Nachrüstsatz, Lachgaseinspritzung",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Lachgaseinspritzung"
        },
        "60066": {
            "GenartName": "Teilesatz, Lamellenkupplungs-Ölwechsel (Allradantrieb)",
            "NormTermName": "Teilesatz",
            "AssGrpName": "Service/Wartung",
            "UsageName": "Lamellenkupplungs-Ölwechsel (Allradantrieb)"
        },
        "60067": {
            "GenartName": "Steuerventil, Lamellenkupplung (Allradantrieb)",
            "NormTermName": "Ventil",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Lamellenkupplung (Allradantrieb)"
        },
        "60068": {
            "GenartName": "Kabelreparatursatz, Kickdownschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Kickdownschalter"
        },
        "60069": {
            "GenartName": "Kabelreparatursatz, Kickdownschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung, Universal",
            "UsageName": "Kickdownschalter"
        },
        "60070": {
            "GenartName": "Wildwanne",
            "NormTermName": "Wanne",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Wildwanne"
        },
        "60071": {
            "GenartName": "Deckel, Wildwanne",
            "NormTermName": "Deckel",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Wildwanne"
        },
        "60072": {
            "GenartName": "Wannenplane",
            "NormTermName": "Plane",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Wannenplane"
        },
        "60073": {
            "GenartName": "Nachrüstsatz, Gepäckkorbbeleuchtung (AHK-Träger)",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Gepäckkorbbeleuchtung (Anhängekupplungsträger)"
        },
        "60074": {
            "GenartName": "Absenkvorrichtung, Gepäckkorb (AHK-Träger)",
            "NormTermName": "Absenkvorrichtung",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Gepäckkorb (AHK-Träger)"
        },
        "60075": {
            "GenartName": "Seilzug, Tankklappenentriegelung",
            "NormTermName": "Seilzug",
            "AssGrpName": "Karosserie",
            "UsageName": "Tankklappenentriegelung"
        },
        "60076": {
            "GenartName": "Kabelsatz, Heckklappe",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Heckklappe"
        },
        "60077": {
            "GenartName": "Hygieneschutzfolie",
            "NormTermName": "Folie",
            "AssGrpName": "Zubehör",
            "UsageName": "Hygieneschutzfolie"
        },
        "60078": {
            "GenartName": "Hygieneschutzfolie",
            "NormTermName": "Folie",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Hygieneschutzfolie"
        },
        "60079": {
            "GenartName": "Reparatursatz, Mechatronik (Automatikgetriebe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Mechatronik (Automatikgetriebe)"
        },
        "60080": {
            "GenartName": "Ölauffangbehälter, Kurbelgehäuseentlüftung",
            "NormTermName": "Behälter",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäuseentlüftungsventil"
        },
        "60081": {
            "GenartName": "Hecktürschloss",
            "NormTermName": "Schloss",
            "AssGrpName": "Schließanlage",
            "UsageName": "Hecktür"
        },
        "60082": {
            "GenartName": "Gelpad, Optikkörper",
            "NormTermName": "Gelpad",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Optikkörper"
        },
        "60083": {
            "GenartName": "Kabelreparatursatz, Zündanlage",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Zündanlage",
            "UsageName": "Zündanlage"
        },
        "60084": {
            "GenartName": "Kabelreparatursatz, Zündanlage",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Zündanlage, Universal",
            "UsageName": "Zündanlage"
        },
        "60085": {
            "GenartName": "Kabelreparatursatz, Heckklappenschloss",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage",
            "UsageName": "Heckklappenschloss"
        },
        "60086": {
            "GenartName": "Kabelreparatursatz, Heckklappenschloss",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Heckklappenschloss"
        },
        "60087": {
            "GenartName": "Klemmstück, Zylinderkopfhaube",
            "NormTermName": "Klemmstück",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Zylinderkopfhaube"
        },
        "60088": {
            "GenartName": "Schließdorn, Türschloss",
            "NormTermName": "Schließdorn",
            "AssGrpName": "Schließanlage",
            "UsageName": "Türschloss"
        },
        "60089": {
            "GenartName": "Zahnriemen, Nebenantrieb",
            "NormTermName": "Riemen",
            "AssGrpName": "Riementrieb",
            "UsageName": "Nebenantrieb"
        },
        "60090": {
            "GenartName": "Glühlampe, Seitenmarkierungsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Seitenmarkierungsleuchte"
        },
        "60091": {
            "GenartName": "Magnetverschluss, Handschuhfachklappe",
            "NormTermName": "Verschluss",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Handschuhfachklappe"
        },
        "60092": {
            "GenartName": "Kabelreparatursatz, Rückfahrleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Rückfahrleuchte"
        },
        "60093": {
            "GenartName": "Kabelreparatursatz, Rückfahrleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Rückfahrleuchte"
        },
        "60094": {
            "GenartName": "Montagepaste, Dieselinjektor/Glühkerze",
            "NormTermName": "Montagehilfe",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Dieselinjektor/Glühkerze"
        },
        "60095": {
            "GenartName": "Anlenkerstrebe, Niveauregulierung",
            "NormTermName": "Strebe",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Niveauregulierung"
        },
        "60096": {
            "GenartName": "Dichtung, Nockenwellen-Verschlussdeckel",
            "NormTermName": "Dichtung",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwellen-Verschlussdeckel"
        },
        "60097": {
            "GenartName": "Kabelreparatursatz, Toter-Winkel-Sensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Toter-Winkel-Sensor"
        },
        "60098": {
            "GenartName": "Kabelreparatursatz, Toter-Winkel-Sensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Toter-Winkel-Sensor"
        },
        "60099": {
            "GenartName": "Kabelreparatursatz, Stellelement (Drosselklappe)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Stellelement (Drosselklappe)"
        },
        "60100": {
            "GenartName": "Kabelreparatursatz, Stellelement (Drosselklappe)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Stellelement (Drosselklappe)"
        },
        "60101": {
            "GenartName": "Kabelreparatursatz, Steuergerät (Batteriemanagement)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz",
            "UsageName": "Steuergerät (Batteriemanagement)"
        },
        "60102": {
            "GenartName": "Kabelreparatursatz, Steuergerät (Batteriemanagement)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Bordnetz, Universal",
            "UsageName": "Steuergerät (Batteriemanagement)"
        },
        "60103": {
            "GenartName": "Kabelreparatursatz, Kraftstofffilterheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstofffilterheizung"
        },
        "60104": {
            "GenartName": "Kabelreparatursatz, Kraftstofffilterheizung",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Kraftstofffilterheizung"
        },
        "60105": {
            "GenartName": "Kabelreparatursatz, Regelventil (Kraftstoffmenge)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Regelventil (Kraftstoffmenge)"
        },
        "60106": {
            "GenartName": "Kabelreparatursatz, Regelventil (Kraftstoffmenge)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Regelventil (Kraftstoffmenge)"
        },
        "60107": {
            "GenartName": "Kabelreparatursatz, Ladelufttemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Ladelufttemperatursensor"
        },
        "60108": {
            "GenartName": "Kabelreparatursatz, Ladelufttemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Ladelufttemperatursensor"
        },
        "60109": {
            "GenartName": "Kabelreparatursatz, Wassersensor (Kraftstoffanlage)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Wassersensor (Kraftstoffanlage)"
        },
        "60110": {
            "GenartName": "Kabelreparatursatz, Wassersensor (Kraftstoffanlage)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Wassersensor (Kraftstoffanlage)"
        },
        "60111": {
            "GenartName": "Kabelreparatursatz, Kupplungspedalwegsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungspedalwegsensor"
        },
        "60112": {
            "GenartName": "Kabelreparatursatz, Kupplungspedalwegsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kupplung, Universal",
            "UsageName": "Kupplungspedalwegsensor"
        },
        "60113": {
            "GenartName": "Motoröladditiv",
            "NormTermName": "Zusatzmittel",
            "AssGrpName": "Schmierung",
            "UsageName": "Motoröl"
        },
        "60114": {
            "GenartName": "Montagewerkzeug, Ladeluftschlauch-Halteschelle",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Ladeluftschlauch-Halteschelle"
        },
        "60115": {
            "GenartName": "Montagewerkzeug, Ladeluftschlauch-Halteschelle",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Ladeluftschlauch-Halteschelle"
        },
        "60116": {
            "GenartName": "Kabelreparatursatz, Stellelement (Ladedruckregler)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Stellelement (Ladedruckregler)"
        },
        "60117": {
            "GenartName": "Kabelreparatursatz, Stellelement (Ladedruckregler)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Stellelement (Ladedruckregler)"
        },
        "60118": {
            "GenartName": "Kabelreparatursatz, Umschaltventil (Bypassklappe/AGR-Kühler)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "Umschaltventil (Bypassklappe/AGR-Kühler)"
        },
        "60119": {
            "GenartName": "Kabelreparatursatz, Umschaltventil (Bypassklappe/AGR-Kühler)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasrückführung, Universal",
            "UsageName": "Umschaltventil (Bypassklappe/AGR-Kühler)"
        },
        "60120": {
            "GenartName": "Gasfeder, Tankdeckel",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Tankdeckel"
        },
        "60121": {
            "GenartName": "Camping-Vordach",
            "NormTermName": "Vordach",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Camping-Vordach"
        },
        "60122": {
            "GenartName": "Camping-Kocher",
            "NormTermName": "Kocher",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Camping-Kocher"
        },
        "60123": {
            "GenartName": "Führungsschiene, Schiebetür",
            "NormTermName": "Führungsschiene",
            "AssGrpName": "Karosserie",
            "UsageName": "Schiebetür"
        },
        "60124": {
            "GenartName": "Reparatursatz, Führungsschiene (Schiebetür)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Führungsschiene (Schiebetür)"
        },
        "60125": {
            "GenartName": "Dichtring, Klopfsensor",
            "NormTermName": "Dichtring",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Klopfsensor"
        },
        "60126": {
            "GenartName": "Sanitär-Rohrreinigungs-Satz",
            "NormTermName": "Sanitär-Rohrreinigungs-Satz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "60127": {
            "GenartName": "Sanitär-Schleuderkopf, Rohrreinigungsgerät",
            "NormTermName": "Sanitär-Schleuderkopf, Rohrreinigungsgerät",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "60128": {
            "GenartName": "Verlängerung, Schornsteinfegerwerkzeug",
            "NormTermName": "Verlängerung",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schornsteinfegerwerkzeug"
        },
        "60129": {
            "GenartName": "Handkurbel, Schornsteinfegerwerkzeug",
            "NormTermName": "Kurbel",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Schornsteinfegerwerkzeug"
        },
        "60130": {
            "GenartName": "Sanitär-Schneidbackensatz, Gewindeschneidmutter",
            "NormTermName": "Sanitär-Schneidbackensatz, Gewindeschneidmutter",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Gewindeschneidmutter"
        },
        "60132": {
            "GenartName": "Verteilereinheit, Klimaanlage",
            "NormTermName": "Verteilereinheit",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Verteilereinheit"
        },
        "60133": {
            "GenartName": "Zusatzwasserpumpe, Klimaanlage",
            "NormTermName": "Pumpe",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Zusatzpumpe"
        },
        "60134": {
            "GenartName": "Gaspumpe, EVAP-Aktivkohlebehälter (Tankentlüftung)",
            "NormTermName": "Gaspumpe",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "EVAP Aktivkohlebehälter (Tankentlüftung)"
        },
        "60135": {
            "GenartName": "Ventil, Kolbenbodenkühlung",
            "NormTermName": "Ventil",
            "AssGrpName": "Schmierung",
            "UsageName": "Kolbenbodenkühlung"
        },
        "60136": {
            "GenartName": "Klebstoff, Regensensor",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Regensensor"
        },
        "60137": {
            "GenartName": "Ölflasche, Rückstellmuster-Getriebeöl",
            "NormTermName": "Behälter",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Rückstellmuster-Getriebeöl"
        },
        "60138": {
            "GenartName": "Ölflaschensatz, Rückstellmuster-Getriebeöl",
            "NormTermName": "Behälter-Satz",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Rückstellmuster-Getriebeöl"
        },
        "60139": {
            "GenartName": "Reparatursatz, Lamellenkupplungspumpe (Allradantrieb)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Lamellenkupplungspumpe (Allradantrieb)"
        },
        "60140": {
            "GenartName": "Dichtring, Einspritzventil (Harnstoffeinspritzung)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Einspritzventil (Harnstoffeinspritzung)"
        },
        "60141": {
            "GenartName": "Montagesatz, Stoßfänger",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Karosserie, Universal",
            "UsageName": "Stoßfänger"
        },
        "60142": {
            "GenartName": "Abdeckung, Werkzeugwagen",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "60143": {
            "GenartName": "Ladestation, Elektrofahrzeug",
            "NormTermName": "Ladestation",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Elektrofahrzeug"
        },
        "60144": {
            "GenartName": "Ladekabelhalterung, Elektrofahrzeug",
            "NormTermName": "Halterung",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Elektrofahrzeug"
        },
        "60145": {
            "GenartName": "Säule, Ladestation (Elektrofahrzeug)",
            "NormTermName": "Säule",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Ladestation (Elektrofahrzeug)"
        },
        "60146": {
            "GenartName": "Stromzähler, Ladestation (Elektrofahrzeug)",
            "NormTermName": "Stromzähler",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Ladestation (Elektrofahrzeug)"
        },
        "60147": {
            "GenartName": "Aufbewahrungstasche, Ladekabel (Elektrofahrzeug)",
            "NormTermName": "Tasche",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Ladekabel (Elektrofahrzeug)"
        },
        "60148": {
            "GenartName": "Abdeckrahmen, Ladestation (Elektrofahrzeug)",
            "NormTermName": "Rahmen",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Ladestation (Elektrofahrzeug)"
        },
        "60149": {
            "GenartName": "Freischaltkarte, Ladestation (Elektrofahrzeug)",
            "NormTermName": "Freischaltkarte",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Ladestation (Elektrofahrzeug)"
        },
        "60150": {
            "GenartName": "Korpus, Werkzeugwagen",
            "NormTermName": "Korpus",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "60151": {
            "GenartName": "Kompressor, Aufladung",
            "NormTermName": "Kompressor",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Aufladung"
        },
        "60152": {
            "GenartName": "Adapterkabel, Lautsprecher",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Lautsprecher"
        },
        "60153": {
            "GenartName": "Fernbedienung, Autoradio",
            "NormTermName": "Fernbedienung",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Autoradio"
        },
        "60154": {
            "GenartName": "Reifendruck-Kontrollsystem",
            "NormTermName": "Reifendruck-Kontrollsystem",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Reifendruck-Kontrollsystem"
        },
        "60155": {
            "GenartName": "Verschlussschraube, Zylinderkopf",
            "NormTermName": "Schraube",
            "AssGrpName": "Zylinderkopf",
            "UsageName": "Verschluss"
        },
        "60156": {
            "GenartName": "Kabelsatz, Freisprechanlage (Radio)",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Freisprechanlage-Radio"
        },
        "60157": {
            "GenartName": "WiFi-Modem, Mobile Kommunikation",
            "NormTermName": "WiFi-Modem",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "WiFi-Modem"
        },
        "60158": {
            "GenartName": "Anzeige, Luftfiltersättigung",
            "NormTermName": "Anzeige",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Luftfiltersättigung"
        },
        "60159": {
            "GenartName": "Ölrohr, Schaltgetriebe",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "60160": {
            "GenartName": "Abtriebsflansch, Automatikgetriebe",
            "NormTermName": "Flansch",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Getriebeflansch"
        },
        "60161": {
            "GenartName": "Abtriebsflansch, Schaltgetriebe",
            "NormTermName": "Flansch",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeflansch"
        },
        "60162": {
            "GenartName": "Anzeige, Kraftstofffilter-Sättigung",
            "NormTermName": "Anzeige",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstofffilter-Sättigung"
        },
        "60163": {
            "GenartName": "Wassersensor, Kraftstoffanlage",
            "NormTermName": "Sensor",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Wassersensor-Kraftstoffanlage"
        },
        "60164": {
            "GenartName": "Adapterkabel, Sensor (Batteriemanagement)",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Bordnetz",
            "UsageName": "Sensor (Batteriemanagement)"
        },
        "60165": {
            "GenartName": "Adapterkabel, Sensor (Batteriemanagement)",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Bordnetz, Universal",
            "UsageName": "Sensor (Batteriemanagement)"
        },
        "60166": {
            "GenartName": "Wasserpumpe, Antriebsbatterie",
            "NormTermName": "Pumpe",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Antriebsbatterie"
        },
        "60167": {
            "GenartName": "Schraubensatz, Ölwanne",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölwanne"
        },
        "60168": {
            "GenartName": "Schraubensatz, Steuergehäuse",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuergehäuse"
        },
        "60169": {
            "GenartName": "Dichtungssatz, Mechatronik (Automatikgetriebe)",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Mechatronik (Automatikgetriebe)"
        },
        "60170": {
            "GenartName": "Wechselrichter",
            "NormTermName": "Wechselrichter",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": null
        },
        "60171": {
            "GenartName": "Gleichspannungswandler",
            "NormTermName": "Gleichspannungswandler",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": null
        },
        "60172": {
            "GenartName": "Ladegerät, Antriebsbatterie",
            "NormTermName": "Ladegerät",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Antriebsbatterie"
        },
        "60173": {
            "GenartName": "Steuergerät, Batteriemanagement",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Batteriemanagement"
        },
        "60174": {
            "GenartName": "Schließplatte, Türschloss",
            "NormTermName": "Schließplatte",
            "AssGrpName": "Schließanlage",
            "UsageName": "Türschloss"
        },
        "60175": {
            "GenartName": "Dichtung, Ladegerät (Antriebsbatterie)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Ladegerät (Antriebsbatterie)"
        },
        "60176": {
            "GenartName": "Federwegbegrenzer, Fahrwerksfeder",
            "NormTermName": "Federwegbegrenzer",
            "AssGrpName": "Federung/Dämpfung, Universal",
            "UsageName": "Fahrwerksfeder"
        },
        "60177": {
            "GenartName": "Adapter, Lautsprecher",
            "NormTermName": "Adapter",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Lautsprecher"
        },
        "60178": {
            "GenartName": "Schutzgitter, Lautsprecher",
            "NormTermName": "Schutzgitter",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Lautsprecher"
        },
        "60179": {
            "GenartName": "Lesegerät, SD-USB-BT (FM-Universal)",
            "NormTermName": "Lesegerät",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Lesegerät"
        },
        "60180": {
            "GenartName": "Schnittstelle, AUX-IN-USB (Autoradio)",
            "NormTermName": "Schnittstelle",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "AUX-IN-USB (Autoradio)"
        },
        "60181": {
            "GenartName": "Frequenzweiche, Lautsprecher",
            "NormTermName": "Frequenzweiche",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Lautsprecher"
        },
        "60182": {
            "GenartName": "Gehäuse, Tiefton (Lautsprecher)",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Tiefton (Lautsprecher)"
        },
        "60183": {
            "GenartName": "Ölfilter, Getriebe (E-Achse)",
            "NormTermName": "Filter",
            "AssGrpName": "Elektroantrieb",
            "UsageName": "Getriebe (E-Achse)"
        },
        "60184": {
            "GenartName": "Reparatursatz, Ruß-/Partikelfilter",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Ruß-/Partikelfilter"
        },
        "60185": {
            "GenartName": "Reparatursatz, Katalysator",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Katalysator"
        },
        "60186": {
            "GenartName": "Reparatursatz, Vorschalldämpfer",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Vorschalldämpfer"
        },
        "60187": {
            "GenartName": "Reparatursatz, Mittelschalldämpfer",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Mittelschalldämpfer"
        },
        "60188": {
            "GenartName": "Dichtring, Öldüse (Steuerkette)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Öldüse (Steuerkette)"
        },
        "60189": {
            "GenartName": "Adapterkabel, Thermostat",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Kühlung",
            "UsageName": "Thermostat"
        },
        "60190": {
            "GenartName": "Adapterkabel, Thermostat",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Thermostat"
        },
        "60191": {
            "GenartName": "Felgenreparatursatz",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Felgen"
        },
        "60192": {
            "GenartName": "Warngerät, Kindersitz",
            "NormTermName": "Warngerät",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Kindersitz"
        },
        "60193": {
            "GenartName": "Staubauswurfventil, Luftfilter",
            "NormTermName": "Ventil",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Staubauswurf (Luftfilter)"
        },
        "60194": {
            "GenartName": "Dichtung, Ölkühler (Automatikgetriebe)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölkühler (Automatikgetriebe)"
        },
        "60195": {
            "GenartName": "Filterkopf, Kraftstofffilter",
            "NormTermName": "Filterkopf",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstofffilter"
        },
        "60196": {
            "GenartName": "Drehmomentstütze",
            "NormTermName": "Drehmomentstütze",
            "AssGrpName": "Motoraufhängung",
            "UsageName": null
        },
        "60197": {
            "GenartName": "Rückfahrkamera-Satz",
            "NormTermName": "Rückfahrkamera-Satz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": null
        },
        "60198": {
            "GenartName": "Magnetventil, Automatikgetriebe",
            "NormTermName": "Ventil",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "60199": {
            "GenartName": "Magnetventilsatz, Automatikgetriebe",
            "NormTermName": "Ventilsatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "60200": {
            "GenartName": "Dichtung, Ruß-/Partikelfilter",
            "NormTermName": "Dichtung",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Ruß-/Partikelfilter"
        },
        "60201": {
            "GenartName": "Montagesatz, Einspritzventil",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzventil"
        },
        "60202": {
            "GenartName": "Abgasfunkensperre",
            "NormTermName": "Abgasfunkensperre",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "60203": {
            "GenartName": "Erweiterungs-Kit, Anhängevorrichtungselektrosatz",
            "NormTermName": "Erweiterungssatz",
            "AssGrpName": "Anhängevorrichtung",
            "UsageName": "Anhängevorrichtungselektrosatz"
        },
        "60204": {
            "GenartName": "Multimedia Interface",
            "NormTermName": "Schnittstelle",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Multimedia"
        },
        "60205": {
            "GenartName": "Anschlusskabel, Multimedia Interface",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Multimedia Interface"
        },
        "60206": {
            "GenartName": "Umrüstsatz, Glühlampe (Hauptscheinwerfer)",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Glühlampe-Hauptscheinwerfer"
        },
        "60207": {
            "GenartName": "Umrüstsatz, Glühlampe (Nebelscheinwerfer)",
            "NormTermName": "Umrüstsatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Glühlampe-Nebelscheinwerfer"
        },
        "60208": {
            "GenartName": "Steuerungsplatine, Starthilfegerät",
            "NormTermName": "Platine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Starthilfegerät"
        },
        "60209": {
            "GenartName": "Umpolrelais, Motordrehrichtung",
            "NormTermName": "Relais",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Motordrehrichtung"
        },
        "60210": {
            "GenartName": "DAB+ Adapter, Radio",
            "NormTermName": "DAB+ Adapter",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Radio"
        },
        "60211": {
            "GenartName": "Ersatzkabel, Batterie-Prüfgerät",
            "NormTermName": "Ersatzkabel",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Batterie-Prüfgerät"
        },
        "60212": {
            "GenartName": "Montage-/Demontagewerkzeug-Satz, Armaturenbrett",
            "NormTermName": "Montage-/Demontagewerkzeug-Satz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Armaturenbrett"
        },
        "60213": {
            "GenartName": "Blende, Radio",
            "NormTermName": "Blende",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Radio"
        },
        "60214": {
            "GenartName": "Staukasten",
            "NormTermName": "Staukasten",
            "AssGrpName": "Zubehör",
            "UsageName": null
        },
        "60215": {
            "GenartName": "Tresor",
            "NormTermName": "Tresor",
            "AssGrpName": "Zubehör",
            "UsageName": null
        },
        "60216": {
            "GenartName": "Staukasten",
            "NormTermName": "Staukasten",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "60217": {
            "GenartName": "Tresor",
            "NormTermName": "Tresor",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "60218": {
            "GenartName": "Gehäusedeckel, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Deckel",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60219": {
            "GenartName": "Wellendichtring, Getriebeausgangswelle (ASG)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeausgangswelle (ASG)"
        },
        "60220": {
            "GenartName": "Wellendichtring, Getriebeeingangswelle (ASG)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeeingangswelle (ASG)"
        },
        "60221": {
            "GenartName": "Wellendichtring, Getriebeflansch (ASG)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeflansch (ASG)"
        },
        "60222": {
            "GenartName": "Wellendichtring, Hauptwelle (ASG)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Hauptwelle (ASG)"
        },
        "60223": {
            "GenartName": "Wellendichtring, Schaltwelle (ASG)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Schaltwelle (ASG)"
        },
        "60224": {
            "GenartName": "Wellendichtring, Vorgelegewelle (ASG)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Vorgelegewelle (ASG)"
        },
        "60225": {
            "GenartName": "Dichtung, Gehäuse Automomatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Gehäuse Automomatisiertes Schaltgetriebe (ASG)"
        },
        "60226": {
            "GenartName": "Überwurfschraube, Servolenkung",
            "NormTermName": "Überwurfschraube",
            "AssGrpName": "Lenkung",
            "UsageName": "Servolenkung"
        },
        "60227": {
            "GenartName": "Dichtung, Getriebeausgangswelle (ASG)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeausgangswelle (ASG)"
        },
        "60228": {
            "GenartName": "Dichtung, Getriebeeingangswelle (ASG)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeeingangswelle (ASG)"
        },
        "60229": {
            "GenartName": "Dichtung, Getriebesteuergerät (ASG)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebesteuergerät (ASG)"
        },
        "60230": {
            "GenartName": "Dichtring, Ölfilter (ASG)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Ölfilter (ASG)"
        },
        "60231": {
            "GenartName": "Dichtring, Ölsieb (ASG)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Ölsieb (ASG)"
        },
        "60232": {
            "GenartName": "Dichtring, Ölpumpe (ASG)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Ölpumpe (ASG)"
        },
        "60233": {
            "GenartName": "Dichtring, Ölpeilstab-Führung (ASG)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Ölpeilstab-Führung (ASG)"
        },
        "60234": {
            "GenartName": "Dichtung, Ölpumpe (ASG)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Ölpumpe (ASG)"
        },
        "60235": {
            "GenartName": "Dichtung, Ölwanne (ASG)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Ölwanne (ASG)"
        },
        "60236": {
            "GenartName": "Dichtringsatz, Magnetventil (ASG)",
            "NormTermName": "Dichtringsatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Magnetventil (ASG)"
        },
        "60237": {
            "GenartName": "Dichtungssatz, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60238": {
            "GenartName": "Dichtungssatz, Getriebeausgangswelle (ASG)",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeausgangswelle (ASG)"
        },
        "60239": {
            "GenartName": "Dichtungssatz, Getriebeeingangswelle (ASG)",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeeingangswelle (ASG)"
        },
        "60240": {
            "GenartName": "Dichtungssatz, Getriebesteuergerät (ASG)",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebesteuergerät (ASG)"
        },
        "60241": {
            "GenartName": "Distanzring, Getriebewelle (ASG)",
            "NormTermName": "Distanzring",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebewelle (ASG)"
        },
        "60242": {
            "GenartName": "Ölfilter, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Filter",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60243": {
            "GenartName": "Abtriebsflansch, Achsantrieb (ASG)",
            "NormTermName": "Flansch",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Achsantrieb (ASG)"
        },
        "60244": {
            "GenartName": "Abtriebsflansch, Nebenantrieb (ASG)",
            "NormTermName": "Flansch",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Nebenantrieb (ASG)"
        },
        "60245": {
            "GenartName": "Drehzahlsensor, Wasserpumpe",
            "NormTermName": "Sensor",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "60246": {
            "GenartName": "Gehäuse, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60247": {
            "GenartName": "Kappe, Radschraube",
            "NormTermName": "Kappe",
            "AssGrpName": "Räder",
            "UsageName": "Radschraube"
        },
        "60248": {
            "GenartName": "Gehäuse, Kupplung (ASG)",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Kupplung (ASG)"
        },
        "60249": {
            "GenartName": "Gehäuse, Ölfilter (ASG)",
            "NormTermName": "Gehäuse",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Ölfilter (ASG)"
        },
        "60250": {
            "GenartName": "Kappe, Radschraube",
            "NormTermName": "Kappe",
            "AssGrpName": "Räder, Universal",
            "UsageName": "Radschraube"
        },
        "60251": {
            "GenartName": "Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Getriebe",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": null
        },
        "60252": {
            "GenartName": "Halter, Getriebeaufhängung (ASG)",
            "NormTermName": "Halter",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeaufhängung (ASG)"
        },
        "60253": {
            "GenartName": "Sensor, Abstandsregeltempomat",
            "NormTermName": "Sensor",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Abstandsregeltempomat"
        },
        "60254": {
            "GenartName": "Hohlrad, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Hohlrad",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60255": {
            "GenartName": "Gehäusedeckel, Hydraulikfilter (Automatikgetriebe)",
            "NormTermName": "Deckel",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Hydraulikfilter"
        },
        "60256": {
            "GenartName": "Dichtring, Gehäusedeckel-Hydraulikfilter (Automatikgetr.)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Hydraulikfilter"
        },
        "60257": {
            "GenartName": "Kabelreparatursatz, Drehzahlsensor (ASG)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Drehzahlsensor (ASG)"
        },
        "60258": {
            "GenartName": "Kabelreparatursatz, Getriebeschalter-Gangerkennung (ASG)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeschalter-Gangerkennung (ASG)"
        },
        "60259": {
            "GenartName": "Impulsgeberrad, Nockenwellenverstellung",
            "NormTermName": "Impulsgeberrad",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwellenverstellung"
        },
        "60260": {
            "GenartName": "Kabelreparatursatz, Getriebesteuergerät (ASG)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebesteuergerät (ASG)"
        },
        "60261": {
            "GenartName": "Lager, Vorgelegewelle (ASG)",
            "NormTermName": "Lager",
            "AssGrpName": "Automatikgetriebe, Universal",
            "UsageName": "Vorgelegewelle (ASG)"
        },
        "60262": {
            "GenartName": "Lager, Getriebeeingangswelle (ASG)",
            "NormTermName": "Lager",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeeingangswelle (ASG)"
        },
        "60263": {
            "GenartName": "Lager, Getriebeausgangswelle (ASG)",
            "NormTermName": "Lager",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeausgangswelle (ASG)"
        },
        "60264": {
            "GenartName": "Lagersatz, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Lagersatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60265": {
            "GenartName": "Lagerung, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Lagerung",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60266": {
            "GenartName": "Laufring, Getriebeausgangswelle (ASG)",
            "NormTermName": "Laufring",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeausgangswelle (ASG)"
        },
        "60267": {
            "GenartName": "Manschette, Schaltzylinder (ASG)",
            "NormTermName": "Manschette",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Schaltzylinder (ASG)"
        },
        "60268": {
            "GenartName": "Mutter, Getriebewelle (ASG)",
            "NormTermName": "Mutter",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebewelle (ASG)"
        },
        "60269": {
            "GenartName": "Öl, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Öl",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60270": {
            "GenartName": "Ölfiltersatz, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Filter-Satz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60271": {
            "GenartName": "Ölkühler, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Wärmetauscher",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60272": {
            "GenartName": "Ölkühlerleitung, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Ölkühlerleitung",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60273": {
            "GenartName": "Ölwanne, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Ölwanne",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60274": {
            "GenartName": "Ölpeilstab, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Peilstab",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60275": {
            "GenartName": "Planetenradsatz, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Planetenradsatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60276": {
            "GenartName": "Ölpumpe, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Pumpe",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60277": {
            "GenartName": "Reparatursatz, Getriebesteuergerät (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebesteuergerät (ASG)"
        },
        "60278": {
            "GenartName": "Reparatursatz, Schaltzylinder (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Schaltzylinder (ASG)"
        },
        "60279": {
            "GenartName": "Reparatursatz, Sperrzylinder (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Sperrzylinder (ASG)"
        },
        "60280": {
            "GenartName": "Reparatursatz, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60281": {
            "GenartName": "Reparatursatz, Getriebeausgangswelle (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeausgangswelle (ASG)"
        },
        "60282": {
            "GenartName": "Reparatursatz, Getriebeeingangswelle (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeeingangswelle (ASG)"
        },
        "60283": {
            "GenartName": "Reparatursatz, Hauptwelle (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Hauptwelle (ASG)"
        },
        "60284": {
            "GenartName": "Reparatursatz, Rückwärtsgang (Rücklaufrad-ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Rückwärtsgang (Rücklaufrad-ASG)"
        },
        "60285": {
            "GenartName": "Reparatursatz, Vorgelegewelle (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Vorgelegewelle (ASG)"
        },
        "60286": {
            "GenartName": "Ölrohr, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60287": {
            "GenartName": "Öldruckschalter, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Schalter",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60288": {
            "GenartName": "Schalter, Fahrstufe (ASG)",
            "NormTermName": "Schalter",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Fahrstufe (ASG)"
        },
        "60289": {
            "GenartName": "Leerlaufschalter, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Schalter",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60290": {
            "GenartName": "Schalter, Rückwärtsgang (ASG)",
            "NormTermName": "Schalter",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Rückwärtsgang (ASG)"
        },
        "60291": {
            "GenartName": "Schalter, Splitgetriebe (ASG)",
            "NormTermName": "Schalter",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Splitgetriebe (ASG)"
        },
        "60292": {
            "GenartName": "Schraube, Ölwanne (ASG)",
            "NormTermName": "Schraube",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Ölwanne (ASG)"
        },
        "60293": {
            "GenartName": "Montagewerkzeug, Ölwannendichtung",
            "NormTermName": "Montagewerkzeug",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Ölwannendichtung"
        },
        "60294": {
            "GenartName": "Verschlussschraube, Getriebegehäuse (ASG)",
            "NormTermName": "Schraube",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebegehäuse (ASG)"
        },
        "60295": {
            "GenartName": "Drehzahlsensor, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60296": {
            "GenartName": "Öldrucksensor, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60297": {
            "GenartName": "Öltemperatursensor, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60298": {
            "GenartName": "Sensor, Schaltmodul (ASG)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Schaltmodul (ASG)"
        },
        "60299": {
            "GenartName": "Wählhebelmodul, Getriebeschaltung (ASG)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeschaltung (ASG)"
        },
        "60300": {
            "GenartName": "Steuergerät, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60301": {
            "GenartName": "Synchronkegel, Gangrad (ASG)",
            "NormTermName": "Synchronkegel",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Gangrad (ASG)"
        },
        "60302": {
            "GenartName": "Synchronkörper, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Synchronkörper",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Schaltgetriebe (ASG)"
        },
        "60303": {
            "GenartName": "Synchronring, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Synchronring",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60304": {
            "GenartName": "Magnetventil, Schaltzylinder (ASG)",
            "NormTermName": "Ventil",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Schaltzylinder (ASG)"
        },
        "60305": {
            "GenartName": "Ventil, Getriebeölkühler (ASG)",
            "NormTermName": "Ventil",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeölkühler (ASG)"
        },
        "60306": {
            "GenartName": "Ventilblock, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Ventilblock",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60307": {
            "GenartName": "Antriebswelle, Ölpumpe (ASG)",
            "NormTermName": "Welle",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Ölpumpe (ASG)"
        },
        "60308": {
            "GenartName": "Getriebeausgangswelle, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Welle",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60309": {
            "GenartName": "Getriebeeingangswelle, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Welle",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60310": {
            "GenartName": "Hauptwelle, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Welle",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60311": {
            "GenartName": "Schaltwelle, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Welle",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60312": {
            "GenartName": "Umlenkwelle, Schaltung (ASG)",
            "NormTermName": "Welle",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Schaltung (ASG)"
        },
        "60313": {
            "GenartName": "Vorgelegewelle, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Welle",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60314": {
            "GenartName": "Zahnrad, Getriebeausgangswelle (ASG)",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeausgangswelle (ASG)"
        },
        "60315": {
            "GenartName": "Zahnrad, Getriebeeingangswelle (ASG)",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeeingangswelle (ASG)"
        },
        "60316": {
            "GenartName": "Zahnrad, Vorgelegewelle (ASG)",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Vorgelegewelle (ASG)"
        },
        "60317": {
            "GenartName": "Zahnrad, Hauptwelle (ASG)",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Hauptwelle (ASG)"
        },
        "60318": {
            "GenartName": "Zahnrad, Rückwärtsgang (Rücklaufrad-ASG)",
            "NormTermName": "Zahnrad",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Rückwärtsgang (Rücklaufrad-ASG)"
        },
        "60319": {
            "GenartName": "Schaltzylinder, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Zylinder",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60320": {
            "GenartName": "Sperrzylinder, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Zylinder",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60321": {
            "GenartName": "Unterdruckleitung, Ansaugkrümmer",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ansaugkrümmer"
        },
        "60322": {
            "GenartName": "Dichtung, Antennenfuß",
            "NormTermName": "Dichtung",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Antennenfuß"
        },
        "60323": {
            "GenartName": "Deckel, Schaltgetriebe",
            "NormTermName": "Deckel",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "60324": {
            "GenartName": "Deckel, Automatikgetriebe",
            "NormTermName": "Deckel",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "60325": {
            "GenartName": "Lenkradfernbedienung, Navigationssystem/Autoradio",
            "NormTermName": "Fernbedienung",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": "Lenkradfernbedienung-Navigationssystem/Autoradio"
        },
        "60326": {
            "GenartName": "Düsenkette, Scheibenreinigung",
            "NormTermName": "Düsenkette",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasser"
        },
        "60327": {
            "GenartName": "Resonator, Ladeluftkühlung",
            "NormTermName": "Resonator",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladeluftkühlung"
        },
        "60328": {
            "GenartName": "Innenraumreiniger/-desinfizierer",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Innenraum"
        },
        "60329": {
            "GenartName": "Sensor, Kurbelgehäusedruck",
            "NormTermName": "Sensor",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäusedruck"
        },
        "60330": {
            "GenartName": "Spule, Luftfederbein-Ventil",
            "NormTermName": "Spule",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Luftfederbein-Ventil"
        },
        "60331": {
            "GenartName": "Einfüllstutzen, Waschwasserbehälter",
            "NormTermName": "Stutzen",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasserbehälter"
        },
        "60332": {
            "GenartName": "Halter, Steuerketten-Gleitschiene",
            "NormTermName": "Halter",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerketten-Gleitschiene"
        },
        "60333": {
            "GenartName": "Planenseil",
            "NormTermName": "Planenseil",
            "AssGrpName": "Ladungssicherung",
            "UsageName": null
        },
        "60334": {
            "GenartName": "Sensor, Keyless-System",
            "NormTermName": "Sensor",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Keyless-System"
        },
        "60335": {
            "GenartName": "Sensor, Aufprallwarnung",
            "NormTermName": "Sensor",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Aufprallwarnung"
        },
        "60336": {
            "GenartName": "Bremsschlauch, Druckluftanlage",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Druckluftanlage, Universal",
            "UsageName": "Druckluftanlage"
        },
        "60337": {
            "GenartName": "Prüfanschluss",
            "NormTermName": "Prüfanschluss",
            "AssGrpName": "Druckluftanlage, Universal",
            "UsageName": null
        },
        "60338": {
            "GenartName": "Filtersatz, Kurbelgehäuseentlüftung",
            "NormTermName": "Filter-Satz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "60339": {
            "GenartName": "Anzeige, Hydraulikfilter-Sättigung",
            "NormTermName": "Anzeige",
            "AssGrpName": "Hydraulische Anlage",
            "UsageName": "Hydraulikfilter-Sättigung"
        },
        "60340": {
            "GenartName": "Anschlagpuffer, Sekundärluftpumpe",
            "NormTermName": "Puffer",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": "Sekundärluftpumpe"
        },
        "60341": {
            "GenartName": "Schleifring, Horn",
            "NormTermName": "Schleifring",
            "AssGrpName": "Signalanlage",
            "UsageName": "Horn"
        },
        "60342": {
            "GenartName": "Ventil, Kraftstoffdruckregler",
            "NormTermName": "Ventil",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffdruckregler"
        },
        "60343": {
            "GenartName": "Zusatzwasserpumpe, Turbolader",
            "NormTermName": "Pumpe",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Turbolader"
        },
        "60344": {
            "GenartName": "Zusatzwasserpumpe, Ladeluftkühler",
            "NormTermName": "Pumpe",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladeluftkühler"
        },
        "60347": {
            "GenartName": "Zusatzwasserpumpe, Zuheizer",
            "NormTermName": "Pumpe",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Zuheizer"
        },
        "60349": {
            "GenartName": "Glaspolitur",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Glaspolitur"
        },
        "60350": {
            "GenartName": "Innenraumreiniger",
            "NormTermName": "Reiniger",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Innenraum"
        },
        "60351": {
            "GenartName": "Schraubensatz, Torsionsdämpfer (Kupplung)",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Torsionsdämpfer"
        },
        "60352": {
            "GenartName": "Antriebswellensatz",
            "NormTermName": "Wellensatz",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "60353": {
            "GenartName": "Belagsatz, Fliehkraftkupplung",
            "NormTermName": "Belagsatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Zweirad"
        },
        "60354": {
            "GenartName": "Steckwellensatz, Differential",
            "NormTermName": "Steckwellensatz",
            "AssGrpName": "Radantrieb",
            "UsageName": "Differential"
        },
        "60355": {
            "GenartName": "Achsbremse, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Achsbremse",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60356": {
            "GenartName": "Wählhebel, Getriebeschaltung (ASG)",
            "NormTermName": "Wählhebel",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeschaltung (ASG)"
        },
        "60357": {
            "GenartName": "Kabelreparatursatz, Stellelement (Schalteinheit-ASG)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Stellelement (Schalteinheit-ASG)"
        },
        "60358": {
            "GenartName": "Stellelement, Schalteinheit (ASG)",
            "NormTermName": "Stellelement",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Schalteinheit (ASG)"
        },
        "60359": {
            "GenartName": "Reparatursatz, Achsbremse (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Achsbremse (ASG)"
        },
        "60360": {
            "GenartName": "Reparatursatz, Kupplungsgeberzylinder (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Kupplungsgeberzylinder (ASG)"
        },
        "60361": {
            "GenartName": "Steuergerät, Wählhebel (Getriebeschaltung-ASG)",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Wählhebel (Getriebeschaltung-ASG)"
        },
        "60362": {
            "GenartName": "Magnetventil, Kupplungsgeberzylinder (ASG)",
            "NormTermName": "Ventil",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Kupplungsgeberzylinder (ASG)"
        },
        "60363": {
            "GenartName": "Magnetventil, Nebenantrieb (ASG)",
            "NormTermName": "Ventil",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Nebenantrieb (ASG)"
        },
        "60364": {
            "GenartName": "Schalter, Nebenantrieb (ASG)",
            "NormTermName": "Schalter",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Nebenantrieb (ASG)"
        },
        "60365": {
            "GenartName": "Glühlampe, Fern-/Tagfahr-/Positionsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fern-/Tagfahr-/Positionsleuchte"
        },
        "60366": {
            "GenartName": "Glühlampe, Fern-/Tagfahrleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Fern-/Tagfahrleuchte"
        },
        "60367": {
            "GenartName": "Glühlampe, Tagfahr-/Positionsleuchte",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Tagfahr-/Positionsleuchte"
        },
        "60368": {
            "GenartName": "Halter, Druckluftkompressor",
            "NormTermName": "Halter",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor"
        },
        "60369": {
            "GenartName": "Magnetventil, Steuergerät (ASG)",
            "NormTermName": "Ventil",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Steuergerät (ASG)"
        },
        "60370": {
            "GenartName": "Welle, Rückwärtsgang (ASG)",
            "NormTermName": "Welle",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Rückwärtsgang (ASG)"
        },
        "60371": {
            "GenartName": "Kupplungsgeberzylinder, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Zylinder",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60372": {
            "GenartName": "Treibermodul, Beleuchtung",
            "NormTermName": "Treibermodul",
            "AssGrpName": "Beleuchtung",
            "UsageName": null
        },
        "60373": {
            "GenartName": "Kabelreparatursatz, Standlicht",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Standlicht"
        },
        "60374": {
            "GenartName": "Kabelreparatursatz, Standlicht",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Standlicht"
        },
        "60375": {
            "GenartName": "Kupplung, Nebenantrieb",
            "NormTermName": "Kupplung",
            "AssGrpName": "Nebenantrieb",
            "UsageName": "Nebenantrieb"
        },
        "60376": {
            "GenartName": "Torsionsdämpfer, Nebenantrieb",
            "NormTermName": "Torsionsdämpfer",
            "AssGrpName": "Nebenantrieb",
            "UsageName": "Nebenantrieb"
        },
        "60377": {
            "GenartName": "Drehmomentbegrenzer, Automatikgetriebe",
            "NormTermName": "Drehmomentbegrenzer",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": null
        },
        "60378": {
            "GenartName": "Trennelement, Starterbatterie",
            "NormTermName": "Trennelement",
            "AssGrpName": "Startanlage",
            "UsageName": "Starterbatterie"
        },
        "60379": {
            "GenartName": "Kabelreparatursatz, Innenraumgebläsemotor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung, Universal",
            "UsageName": "Innenraumgebläsemotor"
        },
        "60380": {
            "GenartName": "Kabelreparatursatz, Kühlmittelstandsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelstandsensor"
        },
        "60381": {
            "GenartName": "Kabelreparatursatz, Kühlmittelstandsensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Kühlmittelstandsensor"
        },
        "60382": {
            "GenartName": "Anschlussleitung, Umschaltventil (Kurbelgehäuseentlüftung)",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Umschaltventil (Kurbelgehäuseentlüftung)"
        },
        "60383": {
            "GenartName": "Kabelreparatursatz, Magnetventil (Differential)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Magnetventil (Differential)"
        },
        "60384": {
            "GenartName": "Kabelreparatursatz, Magnetventil (Differential)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Achsantrieb, Universal",
            "UsageName": "Magnetventil (Differential)"
        },
        "60385": {
            "GenartName": "Kabelreparatursatz, Kontaktschalter (Schiebedach)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Kontaktschalter (Schiebedach)"
        },
        "60386": {
            "GenartName": "Kabelreparatursatz, Kontaktschalter (Schiebedach)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Kontaktschalter (Schiebedach)"
        },
        "60387": {
            "GenartName": "Kabelreparatursatz, Druckluftkompressor (Allradsteuerung)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Druckluftkompressor (Allradsteuerung)"
        },
        "60388": {
            "GenartName": "Kabelreparatursatz, Druckluftkompressor (Allradsteuerung)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Druckluftanlage, Universal",
            "UsageName": "Druckluftkompressor (Allradsteuerung)"
        },
        "60389": {
            "GenartName": "Kabelreparatursatz, Einspritzventil (Harnstoffeinspritzung)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Einspritzventil (Harnstoffeinspritzung)"
        },
        "60390": {
            "GenartName": "Kabelreparatursatz, Einspritzventil (Harnstoffeinspritzung)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Einspritzventil (Harnstoffeinspritzung)"
        },
        "60391": {
            "GenartName": "Kabelreparatursatz, Waschwasserdüse (Scheibenreinigung)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Scheibenreinigung",
            "UsageName": "Waschwasserdüse (Scheibenreinigung)"
        },
        "60392": {
            "GenartName": "Kabelreparatursatz, Waschwasserdüse (Scheibenreinigung)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Scheibenreinigung, Universal",
            "UsageName": "Waschwasserdüse (Scheibenreinigung)"
        },
        "60393": {
            "GenartName": "Kabelreparatursatz, Einstiegsleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Einstiegsleuchte"
        },
        "60394": {
            "GenartName": "Kabelreparatursatz, Einstiegsleuchte",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Beleuchtung, Universal",
            "UsageName": "Einstiegsleuchte"
        },
        "60395": {
            "GenartName": "Ventil, Unterdruckdose (Zündverteiler)",
            "NormTermName": "Ventil",
            "AssGrpName": "Zündanlage",
            "UsageName": null
        },
        "60396": {
            "GenartName": "Kabelreparatursatz, Öltemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Öltemperatursensor"
        },
        "60397": {
            "GenartName": "Kabelreparatursatz, Öltemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schmierung, Universal",
            "UsageName": "Öltemperatursensor"
        },
        "60398": {
            "GenartName": "Kabelreparatursatz, Kontaktschalter (Türgriff)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage",
            "UsageName": "Kontaktschalter (Türgriff)"
        },
        "60399": {
            "GenartName": "Kabelreparatursatz, Kontaktschalter (Türgriff)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Kontaktschalter (Türgriff)"
        },
        "60400": {
            "GenartName": "Zuziehhilfe, Tür",
            "NormTermName": "Zuziehhilfe",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Tür"
        },
        "60401": {
            "GenartName": "Zuziehhilfe, Heckklappe",
            "NormTermName": "Zuziehhilfe",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Heckklappe"
        },
        "60402": {
            "GenartName": "Reparatursatz, Klimaanlage",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": null
        },
        "60403": {
            "GenartName": "Antriebsrad, Tachowelle",
            "NormTermName": "Antriebsrad",
            "AssGrpName": "Instrumente",
            "UsageName": "Tachowelle"
        },
        "60404": {
            "GenartName": "Verschlussplatte, Abgasanlage",
            "NormTermName": "Verschlussplatte",
            "AssGrpName": "Abgasanlage",
            "UsageName": null
        },
        "60405": {
            "GenartName": "Kappe, Ladedruckregelventil",
            "NormTermName": "Kappe",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladedruckregelventil"
        },
        "60406": {
            "GenartName": "Verschlussring, Ladedruckregelventil",
            "NormTermName": "Verschlussring",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladedruckregelventil"
        },
        "60407": {
            "GenartName": "Endstück, Druckdosen-Regelstange",
            "NormTermName": "Endstück",
            "AssGrpName": "Luftversorgung",
            "UsageName": null
        },
        "60408": {
            "GenartName": "Sitzring, Ladedruckregelventil",
            "NormTermName": "Sitzring",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladedruckregelventil"
        },
        "60409": {
            "GenartName": "Anzeige, Getrieböltemperatur",
            "NormTermName": "Anzeige",
            "AssGrpName": "Instrumente",
            "UsageName": "Getrieböltemperatur"
        },
        "60410": {
            "GenartName": "Adaptersatz, Öldruckregelventil",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Öldruckregelventil"
        },
        "60411": {
            "GenartName": "Verschlussstopfen, Unterdruckschlauch",
            "NormTermName": "Verschlussstopfen",
            "AssGrpName": "Luftversorgung, Universal",
            "UsageName": null
        },
        "60412": {
            "GenartName": "Sensor, Ladedruckregelventil",
            "NormTermName": "Sensor",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladedruckregelventil"
        },
        "60413": {
            "GenartName": "Halter, Zusatzinstrument",
            "NormTermName": "Halter",
            "AssGrpName": "Instrumente",
            "UsageName": null
        },
        "60414": {
            "GenartName": "Halter, Kraftstoffdruckregler",
            "NormTermName": "Halter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffdruckregler"
        },
        "60415": {
            "GenartName": "Adaptersatz, Kraftstoffdruckregler",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffdruckregler"
        },
        "60416": {
            "GenartName": "Membran, Kraftstoffdruckregler",
            "NormTermName": "Membran",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffdruckregler"
        },
        "60417": {
            "GenartName": "Adapter, Kraftstoffdruckregler",
            "NormTermName": "Adapter",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffdruckregler"
        },
        "60418": {
            "GenartName": "Kappe, Schubumluftventil",
            "NormTermName": "Kappe",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Schubumluftventil"
        },
        "60419": {
            "GenartName": "Verschlussstopfen, Schubumluftventil",
            "NormTermName": "Verschlussstopfen",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Schubumluftventil"
        },
        "60420": {
            "GenartName": "Halter, Ladedruckregelventil",
            "NormTermName": "Halter",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladedruckregelventil"
        },
        "60421": {
            "GenartName": "Adapter, Ladedrucksensor",
            "NormTermName": "Adapter",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ladedrucksensor"
        },
        "60422": {
            "GenartName": "Anti-Lag-Ventil",
            "NormTermName": "Ventil",
            "AssGrpName": "Luftversorgung",
            "UsageName": null
        },
        "60423": {
            "GenartName": "Flansch, Anti-Lag-Ventil",
            "NormTermName": "Flansch",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Anti-Lag-Ventil"
        },
        "60424": {
            "GenartName": "Deckel, Schubumluftventil",
            "NormTermName": "Deckel",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Schubumluftventil"
        },
        "60425": {
            "GenartName": "Reparatursatz, Kolben/Zylinder",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": "Kolben/Zylinder"
        },
        "60426": {
            "GenartName": "Ventilsatz, Einlass-/Auslassventil",
            "NormTermName": "Ventilsatz",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Einlass-/Auslassventil"
        },
        "60427": {
            "GenartName": "Kompressor-Öl",
            "NormTermName": "Öl",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kompressor-Öl"
        },
        "60428": {
            "GenartName": "Verschlussschraube, Ölfiltergehäuse (Deckel)",
            "NormTermName": "Schraube",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölfiltergehäuse"
        },
        "60429": {
            "GenartName": "Kabelreparatursatz, Elektromotor-Lenkgetriebe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Lenkung",
            "UsageName": "Elektromotor-Lenkgetriebe"
        },
        "60430": {
            "GenartName": "Kabelreparatursatz, Elektromotor-Lenkgetriebe",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Lenkung, Universal",
            "UsageName": "Elektromotor-Lenkgetriebe"
        },
        "60431": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Kühlerlüfter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung",
            "UsageName": "Steuergerät-Kühlerlüfter"
        },
        "60432": {
            "GenartName": "Kabelreparatursatz, Steuergerät-Kühlerlüfter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Kühlung, Universal",
            "UsageName": "Steuergerät-Kühlerlüfter"
        },
        "60433": {
            "GenartName": "Buchsensatz, Kupplung",
            "NormTermName": "Buchsensatz",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplung"
        },
        "60434": {
            "GenartName": "2-Takt Motoröl",
            "NormTermName": "Öl",
            "AssGrpName": "Schmierung",
            "UsageName": "Motor"
        },
        "60435": {
            "GenartName": "2-Takt Motoröl",
            "NormTermName": "Öl",
            "AssGrpName": "Schmierung, Universal",
            "UsageName": "Motor"
        },
        "60436": {
            "GenartName": "Sensor, Verdampfertemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Verdampfertemperatur"
        },
        "60437": {
            "GenartName": "Puffer, Seilzug (Feststellbremse)",
            "NormTermName": "Puffer",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Seilzug (Feststellbremse)"
        },
        "60438": {
            "GenartName": "Verbindungselement, Seilzug (Feststellbremse)",
            "NormTermName": "Verbindungselement",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Seilzug (Feststellbremse)"
        },
        "60439": {
            "GenartName": "Halteklammer, Seilzug (Feststellbremse)",
            "NormTermName": "Halteklammer",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Seilzug (Feststellbremse)"
        },
        "60440": {
            "GenartName": "Faltenbalg, Seilzug (Feststellbremse)",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Seilzug (Feststellbremse)"
        },
        "60441": {
            "GenartName": "Reparatursatz, Seilzug (Feststellbremse)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Seilzug (Feststellbremse)"
        },
        "60442": {
            "GenartName": "Relais, Sekundärluftpumpe",
            "NormTermName": "Relais",
            "AssGrpName": "Sekundärlufteinblasung",
            "UsageName": "Sekundärluftpumpe"
        },
        "60443": {
            "GenartName": "Drehzahlsensor, Einspritzpumpe",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpe"
        },
        "60444": {
            "GenartName": "Heizelement, Thermostatvorwärmung",
            "NormTermName": "Heizelement",
            "AssGrpName": "Kühlung",
            "UsageName": "Thermostatvorwärmung"
        },
        "60445": {
            "GenartName": "Drucksensor, Kühlmittel",
            "NormTermName": "Sensor",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittel"
        },
        "60446": {
            "GenartName": "Spritzversteller, Einspritzpumpe",
            "NormTermName": "Spritzversteller",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpe"
        },
        "60448": {
            "GenartName": "Ölflasche, Rückstellmuster-Motoröl",
            "NormTermName": "Behälter",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Rückstellmuster-Motoröl"
        },
        "60449": {
            "GenartName": "Ölflaschensatz, Rückstellmuster-Motoröl",
            "NormTermName": "Behälter-Satz",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Rückstellmuster-Motoröl"
        },
        "60450": {
            "GenartName": "Ölpumpensatz",
            "NormTermName": "Ölpumpensatz",
            "AssGrpName": "Schmierung",
            "UsageName": null
        },
        "60451": {
            "GenartName": "Kabelreparatursatz, Zünd-/Startschalter",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Startanlage, Universal",
            "UsageName": "Zünd-/Startschalter"
        },
        "60452": {
            "GenartName": "Drucksensor, Arbeitshydraulik",
            "NormTermName": "Sensor",
            "AssGrpName": "Hydraulische Anlage",
            "UsageName": "Arbeitshydraulik"
        },
        "60453": {
            "GenartName": "Führungsrohr, Seilzug (Feststellbremse)",
            "NormTermName": "Führungsrohr",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Seilzug (Feststellbremse)"
        },
        "60454": {
            "GenartName": "Reparatursatz, Getriebeeingangswelle (Schaltgetriebe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeeingangswelle (Schaltgetriebe)"
        },
        "60455": {
            "GenartName": "Reparatursatz, Hauptwelle (Schaltgetriebe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Hauptwelle (Schaltgetriebe)"
        },
        "60456": {
            "GenartName": "Reparatursatz, Abtriebsflansch (Schaltgetriebe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Abtriebsflansch (Schaltgetriebe)"
        },
        "60457": {
            "GenartName": "Reparatursatz, Synchronisierung (Schaltgetriebe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Synchronisierung (Schaltgetriebe)"
        },
        "60458": {
            "GenartName": "Reparatursatz, Ölpumpe (Schaltgetriebe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Ölpumpe (Schaltgetriebe)"
        },
        "60459": {
            "GenartName": "Reparatursatz, Vorgelegewelle (Schaltgetriebe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Vorgelegewelle (Schaltgetriebe)"
        },
        "60460": {
            "GenartName": "Reparatursatz, Abtriebsflansch (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Abtriebsflansch (ASG)"
        },
        "60461": {
            "GenartName": "Versteller, Bremshebel (Lenker)",
            "NormTermName": "Versteller",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremshebel (Zweirad-Lenker)"
        },
        "60462": {
            "GenartName": "Versteller, Kupplungshebel (Lenker)",
            "NormTermName": "Versteller",
            "AssGrpName": "Kupplung",
            "UsageName": "Kupplungshebel (Zweirad-Lenker)"
        },
        "60463": {
            "GenartName": "Reparatursatz, Synchronisierung (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Synchronisierung (ASG)"
        },
        "60464": {
            "GenartName": "Reparatursatz, Ölpumpe (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Ölpumpe (ASG)"
        },
        "60465": {
            "GenartName": "Drehwinkelsensor, Einspritzpumpe",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpe"
        },
        "60466": {
            "GenartName": "Sensor, Kompressionsdruck",
            "NormTermName": "Sensor",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Kompressionsdruck"
        },
        "60467": {
            "GenartName": "Sensor, Turbolader",
            "NormTermName": "Sensor",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Turbolader"
        },
        "60468": {
            "GenartName": "Sensor, Harnstofftemperatur",
            "NormTermName": "Sensor",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstofftemperatur"
        },
        "60469": {
            "GenartName": "Spannungsregler, Einspritzventil",
            "NormTermName": "Regler",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzventil"
        },
        "60470": {
            "GenartName": "Stellelement, Heizklappe",
            "NormTermName": "Stellelement",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Heizklappe"
        },
        "60471": {
            "GenartName": "Deckel, Harnstofffilter-Gehäuse",
            "NormTermName": "Deckel",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstofffilter-Gehäuse"
        },
        "60472": {
            "GenartName": "Motorhaubengriff",
            "NormTermName": "Griff",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorhaube"
        },
        "60473": {
            "GenartName": "Antennenfuß",
            "NormTermName": "Antennenfuß",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": null
        },
        "60474": {
            "GenartName": "Antennenfuß",
            "NormTermName": "Antennenfuß",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "60475": {
            "GenartName": "Sicherung",
            "NormTermName": "Sicherung",
            "AssGrpName": "Bordnetz",
            "UsageName": null
        },
        "60476": {
            "GenartName": "Anschlussleitung, Umschaltventil (Kurbelgehäuseentlüftung)",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Kurbelgehäuse, Universal",
            "UsageName": "Umschaltventil (Kurbelgehäuseentlüftung)"
        },
        "60477": {
            "GenartName": "Regensensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Komfortsysteme, Universal",
            "UsageName": "Regensensor"
        },
        "60478": {
            "GenartName": "Reparatursatz, Ölpumpe (Automatikgetriebe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölpumpe (Automatikgetriebe)"
        },
        "60479": {
            "GenartName": "Schalter, Handschuhfachleuchte",
            "NormTermName": "Schalter",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Handschuhfachleuchte"
        },
        "60480": {
            "GenartName": "Mechatronik, Automatisiertes Schaltgetriebe (ASG)",
            "NormTermName": "Mechatronik",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Automatisiertes Schaltgetriebe (ASG)"
        },
        "60481": {
            "GenartName": "Adapterkabel, Hochdruckpumpe",
            "NormTermName": "Adapterkabel",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Hochdruckpumpe"
        },
        "60482": {
            "GenartName": "Reparatursatz, Lamellen (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Lamellen (ASG)"
        },
        "60483": {
            "GenartName": "Reparatursatz, Nachschaltgruppe (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Nachschaltgruppe (ASG)"
        },
        "60484": {
            "GenartName": "Reparatursatz, Zahnrad (ASG)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Zahnrad (ASG)"
        },
        "60485": {
            "GenartName": "Werkbank, Antriebsbatterie",
            "NormTermName": "Werkbank",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Antriebsbatterie"
        },
        "60486": {
            "GenartName": "Hebegestell, Antriebsbatterie",
            "NormTermName": "Hebegestell",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Antriebsbatterie"
        },
        "60487": {
            "GenartName": "Mutter, Eingangswelle (Differential)",
            "NormTermName": "Mutter",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Eingangswelle (Differential)"
        },
        "60488": {
            "GenartName": "Anlaufscheibe, Automatikgetriebe",
            "NormTermName": "Anlaufscheibe",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "60489": {
            "GenartName": "Ölfang, Schaltgetriebe",
            "NormTermName": "Ölfang",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "60490": {
            "GenartName": "Spannhülse, Lager (Kegelrad)",
            "NormTermName": "Hülse",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Lager (Kegelrad)"
        },
        "60491": {
            "GenartName": "Freilauf, Drehmomentwandler",
            "NormTermName": "Freilauf",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Drehmomentwandler"
        },
        "60492": {
            "GenartName": "Sensorring, Schaltgetriebe",
            "NormTermName": "Sensorring",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "60493": {
            "GenartName": "Schaltarretierung, Schaltgetriebe",
            "NormTermName": "Schaltarretierung",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "60494": {
            "GenartName": "Anlaufscheibe, Differential",
            "NormTermName": "Anlaufscheibe",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "60495": {
            "GenartName": "Ölkühlerleitung, Schaltgetriebe",
            "NormTermName": "Ölkühlerleitung",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "60496": {
            "GenartName": "Reparatursatz, Zahnrad (Schaltgetriebe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Zahnrad (Schaltgetriebe)"
        },
        "60497": {
            "GenartName": "Reparatursatz, Nachschaltgruppe (Schaltgetriebe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Nachschaltgruppe (Schaltgetriebe)"
        },
        "60498": {
            "GenartName": "Sensor, Neutralstellung (Schaltgetriebe)",
            "NormTermName": "Sensor",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Neutralstellung"
        },
        "60499": {
            "GenartName": "Sensor, Rotorstellung (Elektromotor)",
            "NormTermName": "Sensor",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Elektromotor"
        },
        "60500": {
            "GenartName": "Sensor, Getriebeposition (Schaltgetriebe)",
            "NormTermName": "Sensor",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "60501": {
            "GenartName": "Sensor, Getriebeposition (Automatikgetriebe)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "60502": {
            "GenartName": "Steuergerät, Luftmassenmesser",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Luftmasse"
        },
        "60503": {
            "GenartName": "Steuergerät, Differentialsperre",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differentialsperre"
        },
        "60504": {
            "GenartName": "Leiterplatine, Mechatronik (Automatikgetriebe)",
            "NormTermName": "Platine",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Mechatronik (Automatikgetriebe)"
        },
        "60505": {
            "GenartName": "Leiterplatine, Mechatronik (ASG)",
            "NormTermName": "Platine",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Mechatronik (ASG)"
        },
        "60506": {
            "GenartName": "Wechselrichter/Gleichspannungswandler-Kombination",
            "NormTermName": "Wechselrichter/Gleichspannungswandler-Kombination",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": null
        },
        "60507": {
            "GenartName": "Batteriemodul, Antriebsbatterie",
            "NormTermName": "Batteriemodul",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Antriebsbatterie"
        },
        "60508": {
            "GenartName": "Schalter, Schaltzylinder (ASG)",
            "NormTermName": "Schalter",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Schaltzylinder (ASG)"
        },
        "60509": {
            "GenartName": "Schaltzylinder, Splitgetriebe (ASG)",
            "NormTermName": "Zylinder",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Splitgetriebe (ASG)"
        },
        "60510": {
            "GenartName": "Entkopplungsspanner, Keilrippenriemen",
            "NormTermName": "Spanner",
            "AssGrpName": "Riementrieb",
            "UsageName": "Keilrippenriemen"
        },
        "60511": {
            "GenartName": "Deichsel-Staukasten",
            "NormTermName": "Staukasten",
            "AssGrpName": "Anhänger",
            "UsageName": "Deichsel"
        },
        "60512": {
            "GenartName": "Stützplatte, Anhänger",
            "NormTermName": "Stützplatte",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhänger"
        },
        "60513": {
            "GenartName": "Solarsensor, Klimaanlage",
            "NormTermName": "Sensor",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Solarsensor"
        },
        "60514": {
            "GenartName": "Kohlebürstensatz, Elektromotor",
            "NormTermName": "Kohlebürstensatz",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Elektromotor"
        },
        "60515": {
            "GenartName": "Halter, Kohlebürsten (Elektromotor)",
            "NormTermName": "Halter",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": "Kohlebürsten (Elektromotor)"
        },
        "60516": {
            "GenartName": "Auffahrkeil",
            "NormTermName": "Auffahrkeil",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "60517": {
            "GenartName": "Auffahrkeil-Satz",
            "NormTermName": "Auffahrkeil-Satz",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "60518": {
            "GenartName": "Wohnwagenschutzhülle",
            "NormTermName": "Schutzhülle",
            "AssGrpName": "Anhänger",
            "UsageName": "Wohnwagen"
        },
        "60519": {
            "GenartName": "Caravanspiegel",
            "NormTermName": "Spiegel",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Caravanspiegel"
        },
        "60520": {
            "GenartName": "Scheibenversiegelung",
            "NormTermName": "Scheibenversiegelung",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "60521": {
            "GenartName": "Anti-Quietsch-Spray, Bremsbelag (Rückenplatte)",
            "NormTermName": "Anti-Quietsch-Spray",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Bremsbelag (Rückenplatte)"
        },
        "60522": {
            "GenartName": "Schaltkolben, Lamellenkupplung (Automatikgetriebe)",
            "NormTermName": "Kolben",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Lamellenkupplung (Automatikgetriebe)"
        },
        "60523": {
            "GenartName": "Ladeluftrohr",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lader"
        },
        "60524": {
            "GenartName": "Ausgleichswellenmodul",
            "NormTermName": "Ausgleichswellenmodul",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": null
        },
        "60525": {
            "GenartName": "Abschlepphaken",
            "NormTermName": "Haken",
            "AssGrpName": "Zubehör",
            "UsageName": null
        },
        "60526": {
            "GenartName": "Betätigungsmodul, Ventiltriebsystem (vollvariabel)",
            "NormTermName": "Betätigungsmodul",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Ventiltriebsystem (vollvariabel)"
        },
        "60527": {
            "GenartName": "Kurbel, Standbremse",
            "NormTermName": "Kurbel",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Feststellbremse"
        },
        "60528": {
            "GenartName": "Anzeige-/Bedieneinheit (Touchscreen)",
            "NormTermName": "Anzeige-/Bedieneinheit",
            "AssGrpName": "Kommunikations-/Informationssysteme",
            "UsageName": null
        },
        "60529": {
            "GenartName": "Anzeige-/Bedieneinheit (Touchscreen)",
            "NormTermName": "Anzeige-/Bedieneinheit",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": null
        },
        "60530": {
            "GenartName": "Fernbedienung, Handleuchte",
            "NormTermName": "Fernbedienung",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Handleuchte"
        },
        "60531": {
            "GenartName": "LAN-Kabel",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "60532": {
            "GenartName": "Sensor, Toter-Winkel-Erkennung",
            "NormTermName": "Sensor",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Toter-Winkel-Erkennung"
        },
        "60533": {
            "GenartName": "CVT-Antriebsriemen",
            "NormTermName": "Riemen",
            "AssGrpName": "Riementrieb",
            "UsageName": "CVT"
        },
        "60534": {
            "GenartName": "Gewindehülse, Blattfederlagerung",
            "NormTermName": "Hülse",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Blattfederlagerung"
        },
        "60535": {
            "GenartName": "Kontaktschalter, Motorhaube",
            "NormTermName": "Schalter",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Motorhaube"
        },
        "60536": {
            "GenartName": "Faltenbalg, Stabilisator",
            "NormTermName": "Faltenbalg",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Stabilisator"
        },
        "60537": {
            "GenartName": "Dichtungssatz, Steckverbinder-Druckluftanlage",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Steckverbinder-Druckluftanlage"
        },
        "60538": {
            "GenartName": "Zylinderkopfhaubendichtung, Druckluftkompressor",
            "NormTermName": "Dichtung",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Zylinderkopfhaube"
        },
        "60539": {
            "GenartName": "Zylinderkopfschraube, Druckluftkompressor",
            "NormTermName": "Schraube",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Zylinderkopf"
        },
        "60540": {
            "GenartName": "Zylinderkopfschraubensatz, Druckluftkompressor",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Zylinderkopf"
        },
        "60541": {
            "GenartName": "Lagerbuchse, Klimakondensator",
            "NormTermName": "Buchse",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Klimakondensator"
        },
        "60542": {
            "GenartName": "Kopfband, Stirnlampe",
            "NormTermName": "Band",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Stirnlampe"
        },
        "60543": {
            "GenartName": "Stellelement, Allradantrieb",
            "NormTermName": "Stellelement",
            "AssGrpName": "Radantrieb",
            "UsageName": "Allradantrieb"
        },
        "60544": {
            "GenartName": "Deckel, Ölabscheider (Kurbelgehäuseentlüftung)",
            "NormTermName": "Deckel",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "60545": {
            "GenartName": "Anhängerplane",
            "NormTermName": "Plane",
            "AssGrpName": "Anhänger",
            "UsageName": "Anhängerplane"
        },
        "60546": {
            "GenartName": "Reparatursatz, Ölabscheider (Kurbelgehäuseentlüftung)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "60547": {
            "GenartName": "Halter, ABS-Verbindungskabel",
            "NormTermName": "Halter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "ABS"
        },
        "60548": {
            "GenartName": "Dichtring, Servopumpe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Lenkung",
            "UsageName": "Servopumpe"
        },
        "60549": {
            "GenartName": "Kabelsatz, Automatikgetriebe",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "60550": {
            "GenartName": "Kabelsatz, Schaltgetriebe",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebe"
        },
        "60551": {
            "GenartName": "Wellendichtringsatz, Differential",
            "NormTermName": "Wellendichtringsatz",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Differential"
        },
        "60552": {
            "GenartName": "Luftfilter, Antriebsbatteriegehäuse",
            "NormTermName": "Filter",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Antriebsbatteriegehäuse"
        },
        "60553": {
            "GenartName": "Magnetventil, Harnstofftank",
            "NormTermName": "Ventil",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstofftank"
        },
        "60554": {
            "GenartName": "Verschlussdeckel, Steuerkettenspanner (Montageöffnung)",
            "NormTermName": "Verschluss",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Steuerkettenspanner"
        },
        "60555": {
            "GenartName": "Anschlagpuffer, Hecktür/-klappe",
            "NormTermName": "Puffer",
            "AssGrpName": "Karosserie",
            "UsageName": "Hecktür/-klappe"
        },
        "60556": {
            "GenartName": "Anschlag, Federbeinstützlager",
            "NormTermName": "Anschlag",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Federbeinstützlager"
        },
        "60557": {
            "GenartName": "Karosseriesteuergerät",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Bordnetz",
            "UsageName": null
        },
        "60558": {
            "GenartName": "Steuergerät, Allradantrieb",
            "NormTermName": "Steuergerät",
            "AssGrpName": "Achsantrieb",
            "UsageName": "Allradantrieb"
        },
        "60559": {
            "GenartName": "Ölleitung, Nockenwellenverstellung",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwellenverstellung"
        },
        "60560": {
            "GenartName": "Glühlampe",
            "NormTermName": "Glühlampe",
            "AssGrpName": "Elektrische Universalteile",
            "UsageName": null
        },
        "60561": {
            "GenartName": "Nietmaschine",
            "NormTermName": "Nietmaschine",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": null
        },
        "60562": {
            "GenartName": "Waschwasserpumpe, Sicherheitssystem",
            "NormTermName": "Pumpe",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Waschwasser"
        },
        "60563": {
            "GenartName": "Waschwasserpumpe, Komfortsystem",
            "NormTermName": "Pumpe",
            "AssGrpName": "Komfortsysteme",
            "UsageName": "Waschwasser"
        },
        "60564": {
            "GenartName": "Reibscheibe, Ölbadbremse",
            "NormTermName": "Reibscheibe",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Ölbadbremse"
        },
        "60565": {
            "GenartName": "Zwischenscheibe, Ölbadbremse",
            "NormTermName": "Zwischenscheibe",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Ölbadbremse"
        },
        "60566": {
            "GenartName": "Lagerungssatz, Lenker",
            "NormTermName": "Lagerungssatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": "Lenker"
        },
        "60567": {
            "GenartName": "Haltefeder, Kühlmittelrohrleitung",
            "NormTermName": "Feder",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittelleitung"
        },
        "60568": {
            "GenartName": "Abdeckung, Scheinwerferreinigungsanlage",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Scheinwerferreinigung",
            "UsageName": "Scheinwerferreinigungsanlage"
        },
        "60569": {
            "GenartName": "Halter, Kotflügel",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie",
            "UsageName": "Kotflügel"
        },
        "60570": {
            "GenartName": "Halter, Spoiler",
            "NormTermName": "Halter",
            "AssGrpName": "Karosserie",
            "UsageName": "Spoiler"
        },
        "60571": {
            "GenartName": "Schmutzfänger",
            "NormTermName": "Schmutzfänger",
            "AssGrpName": "Zubehör",
            "UsageName": null
        },
        "60572": {
            "GenartName": "Positionssensor, AGR-Ventil",
            "NormTermName": "Sensor",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "AGR-Ventil"
        },
        "60573": {
            "GenartName": "Elektromotor",
            "NormTermName": "Elektromotor",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Hybrid-/Elektroantrieb"
        },
        "60574": {
            "GenartName": "Montagesatz, SCR-Katalysator",
            "NormTermName": "Montagesatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "SCR-Katalysator"
        },
        "60575": {
            "GenartName": "Wasserpumpe, Kühlkreislauf elektrischer Antrieb",
            "NormTermName": "Pumpe",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Kühlkreislauf elektrischer Antrieb"
        },
        "60576": {
            "GenartName": "Additiv, Ruß-/Partikelfilterregeneration",
            "NormTermName": "Zusatzmittel",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Ruß-/Partikelfilterregeneration"
        },
        "60577": {
            "GenartName": "Anschlussstutzen, Kühlmittelleitung",
            "NormTermName": "Stutzen",
            "AssGrpName": "Kühlung",
            "UsageName": "Kühlmittel"
        },
        "60578": {
            "GenartName": "Sortiment, Spanfilter",
            "NormTermName": "Sortiment",
            "AssGrpName": "Klimaanlage, Universal",
            "UsageName": "Spanfilter"
        },
        "60579": {
            "GenartName": "Rettungshaken, Personenrettung (Hochvoltbereich)",
            "NormTermName": "Rettungshaken",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Personenrettung (Hochvoltbereich)"
        },
        "60580": {
            "GenartName": "Adaptersatz, Kupplungskugel (Anhängevorrichtung)",
            "NormTermName": "Adaptersatz",
            "AssGrpName": "Anhängevorrichtung, Universal",
            "UsageName": "Kupplungskugel (Anhängevorrichtung)"
        },
        "60581": {
            "GenartName": "Radhalter, Fahrradträger (Anhängekupplung)",
            "NormTermName": "Radhalter",
            "AssGrpName": "Transportvorrichtung, Universal",
            "UsageName": "Anhängekupplungsfahrradträger"
        },
        "60582": {
            "GenartName": "Haltefeder, Glühlampe (Hauptscheinwerfer)",
            "NormTermName": "Feder",
            "AssGrpName": "Beleuchtung",
            "UsageName": "Glühlampe (Hauptscheinwerfer)"
        },
        "60583": {
            "GenartName": "Halter, Kältemittelleitung",
            "NormTermName": "Halter",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Kältemittelleitung"
        },
        "60584": {
            "GenartName": "Thermostat, Kühlkreislauf elektrischer Antrieb",
            "NormTermName": "Thermostat",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Kühlkreislauf elektrischer Antrieb"
        },
        "60585": {
            "GenartName": "Halteclipsatz, Hitzeschutzblech",
            "NormTermName": "Halteclipsatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Hitzeschutzblech"
        },
        "60586": {
            "GenartName": "Speichensatz, Felge",
            "NormTermName": "Speichensatz",
            "AssGrpName": "Räder",
            "UsageName": "Felge"
        },
        "60587": {
            "GenartName": "Gasfeder, Seitenscheibe",
            "NormTermName": "Gasfeder",
            "AssGrpName": "Karosserie",
            "UsageName": "Seitenscheibe"
        },
        "60588": {
            "GenartName": "Trockner, Antriebsbatterie",
            "NormTermName": "Trockner",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Antriebsbatterie"
        },
        "60589": {
            "GenartName": "Lenkkopflagersatz",
            "NormTermName": "Lenkkopflagersatz",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Lenkkopf (Zweirad)"
        },
        "60590": {
            "GenartName": "Speiche, Felge",
            "NormTermName": "Speiche",
            "AssGrpName": "Räder",
            "UsageName": "Felge"
        },
        "60591": {
            "GenartName": "Nippel, Speiche",
            "NormTermName": "Nippel",
            "AssGrpName": "Räder",
            "UsageName": "Felge"
        },
        "60592": {
            "GenartName": "Vorderradgabel",
            "NormTermName": "Gabel",
            "AssGrpName": "Zweiradrahmen",
            "UsageName": "Gabel (Zweirad)"
        },
        "60593": {
            "GenartName": "Spülset, Klimaservicegerät",
            "NormTermName": "Spülset",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimaservicegerät"
        },
        "60594": {
            "GenartName": "WiFi-Adapter, Klimaservicegerät",
            "NormTermName": "WiFi-Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimaservicegerät"
        },
        "60595": {
            "GenartName": "Software Update, Klimaservicegerät",
            "NormTermName": "Software",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Klimaservicegerät"
        },
        "60596": {
            "GenartName": "Halter, Dichtmittelabscheider (Klimaservicegerät)",
            "NormTermName": "Halter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Dichtmittelabscheider (Klimaservicegerät)"
        },
        "60597": {
            "GenartName": "Filter, Dichtmittelabscheider (Klimaservicegerät)",
            "NormTermName": "Filter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Dichtmittelabscheider (Klimaservicegerät)"
        },
        "60598": {
            "GenartName": "Filter, Spülbehälter (Klimatechnik)",
            "NormTermName": "Filter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Spülbehälter (Klimatechnik)"
        },
        "60599": {
            "GenartName": "Befüllgerät, Kompressor-Öl",
            "NormTermName": "Befüllgerät",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Kompressor-Öl"
        },
        "60600": {
            "GenartName": "Testleck, Lecksuchgerät (Klimaanlage)",
            "NormTermName": "Testleck",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Lecksuchgerät (Klimaanlage)"
        },
        "60601": {
            "GenartName": "Schlauchsatz, Flaschendruckminderer",
            "NormTermName": "Schlauchsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Flaschendruckminderer"
        },
        "60602": {
            "GenartName": "Filter, Lecksuchgerät (Klimaanlage)",
            "NormTermName": "Filter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Lecksuchgerät (Klimaanlage)"
        },
        "60603": {
            "GenartName": "Kabelsatz, Glühkerze",
            "NormTermName": "Kabelsatz",
            "AssGrpName": "Glühanlage",
            "UsageName": "Glühkerze"
        },
        "60604": {
            "GenartName": "Tankklappe",
            "NormTermName": "Klappe",
            "AssGrpName": "Karosserie",
            "UsageName": null
        },
        "60605": {
            "GenartName": "Tankeinheit, Additiv (Ruß-/Partikelfilterregeneration)",
            "NormTermName": "Tankeinheit",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Ruß-/Partikelfilterregeneration"
        },
        "60606": {
            "GenartName": "Sensor, Neutralstellung (Automatikgetriebe)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Neutralstellung"
        },
        "60607": {
            "GenartName": "Dichtring, Kurbelgehäuseentlüftung",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "60608": {
            "GenartName": "Dichtringsatz, Kurbelgehäuseentlüftung",
            "NormTermName": "Dichtringsatz",
            "AssGrpName": "Kurbelgehäuse",
            "UsageName": "Kurbelgehäuseentlüftung"
        },
        "60609": {
            "GenartName": "Montagehilfe, Anti-Beschlagsensor",
            "NormTermName": "Montagehilfe",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Anti-Beschlagsensor"
        },
        "60610": {
            "GenartName": "Bremspedalgefühlssimulator",
            "NormTermName": "Bremspedalgefühlssimulator",
            "AssGrpName": "Bremsanlage",
            "UsageName": null
        },
        "60611": {
            "GenartName": "Dichtung, Hydraulikpumpe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Lenkung",
            "UsageName": "Hydraulikpumpe"
        },
        "60612": {
            "GenartName": "Dichtung, Pumpenaggregat-Hydraulik",
            "NormTermName": "Dichtung",
            "AssGrpName": "Hydraulische Anlage",
            "UsageName": "Pumpenaggregat-Hydraulik"
        },
        "60613": {
            "GenartName": "Dichtungssatz, Pumpenaggregat-Hydraulik",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Hydraulische Anlage",
            "UsageName": "Pumpenaggregat-Hydraulik"
        },
        "60614": {
            "GenartName": "Dichtung, Servopumpe",
            "NormTermName": "Dichtung",
            "AssGrpName": "Lenkung",
            "UsageName": "Servopumpe"
        },
        "60615": {
            "GenartName": "Sitzauflage",
            "NormTermName": "Auflage",
            "AssGrpName": "Innenausstattung, Universal",
            "UsageName": null
        },
        "60616": {
            "GenartName": "Dichtung, Tankeinheit (Harnstoffeinspritzung)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Tankeinheit (Harnstoffeinspritzung)"
        },
        "60617": {
            "GenartName": "Dichtring, Tankeinheit (Harnstoffeinspritzung)",
            "NormTermName": "Dichtring",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Tankeinheit (Harnstoffeinspritzung)"
        },
        "60618": {
            "GenartName": "Dichtungssatz, Tankeinheit (Harnstoffeinspritzung)",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Tankeinheit (Harnstoffeinspritzung)"
        },
        "60619": {
            "GenartName": "Dichtringsatz, Tankeinheit (Harnstoffeinspritzung)",
            "NormTermName": "Dichtringsatz",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Tankeinheit (Harnstoffeinspritzung)"
        },
        "60620": {
            "GenartName": "Bedieneinheit, Lenksäule",
            "NormTermName": "Bedieneinheit",
            "AssGrpName": "Bordnetz",
            "UsageName": "Lenksäule"
        },
        "60621": {
            "GenartName": "Exzenterwelle, Nockenwellenverstellung",
            "NormTermName": "Welle",
            "AssGrpName": "Motorsteuerung",
            "UsageName": "Nockenwellenverstellung"
        },
        "60622": {
            "GenartName": "Sensor, Ammoniakgehalt (Harnstoffeinspritzung)",
            "NormTermName": "Sensor",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Ammoniakgehalt (Harnstoffeinspritzung)"
        },
        "60623": {
            "GenartName": "Lagerung, Fahrerhaus",
            "NormTermName": "Lagerung",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhaus"
        },
        "60624": {
            "GenartName": "Lagerung, Fahrerhausstabilisator",
            "NormTermName": "Lagerung",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhausstabilisator"
        },
        "60625": {
            "GenartName": "Lagerungssatz, Fahrerhausstabilisator",
            "NormTermName": "Lagerungssatz",
            "AssGrpName": "Fahrerhaus",
            "UsageName": "Fahrerhausstabilisator"
        },
        "60626": {
            "GenartName": "Dichtungssatz, Elektromotor",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Elektromotor"
        },
        "60627": {
            "GenartName": "Filter, Kompressor (Druckluftbremsanlage)",
            "NormTermName": "Filter",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Druckluftkompressor (Bremsanlage)"
        },
        "60628": {
            "GenartName": "Filter, Entlüftung (Hydrauliktank)",
            "NormTermName": "Filter",
            "AssGrpName": "Hydraulische Anlage",
            "UsageName": "Entlüftung"
        },
        "60629": {
            "GenartName": "Filter, Getriebeölkühlung (Schaltgetriebe)",
            "NormTermName": "Filter",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Getriebeölkühlung"
        },
        "60630": {
            "GenartName": "Filter, Getriebeölkühlung (Automatikgetriebe)",
            "NormTermName": "Filter",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Getriebeölkühlung"
        },
        "60631": {
            "GenartName": "Filter, Getriebeölkühlung (ASG)",
            "NormTermName": "Filter",
            "AssGrpName": "Automatisiertes Schaltgetriebe",
            "UsageName": "Getriebeölkühlung"
        },
        "60632": {
            "GenartName": "Reparatursatz, Kupplung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "60633": {
            "GenartName": "Stift,Steuerkettentrenn-/Nietgerät",
            "NormTermName": "Stift",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Steuerkettentrenn-/Nietgerät"
        },
        "60634": {
            "GenartName": "Stift,Steuerkettentrenn-/Nietgerät",
            "NormTermName": "Stift",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Steuerkettentrenn-/Nietgerät"
        },
        "60635": {
            "GenartName": "Testpapier, Ölgüte",
            "NormTermName": "Testpapier",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Ölgüte"
        },
        "60636": {
            "GenartName": "Hydraulikleitungssatz, Automatikgetriebesteuerung",
            "NormTermName": "Rohrleitungssatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebesteuerung"
        },
        "60637": {
            "GenartName": "Hydraulikleitungssatz, Schaltgetriebesteuerung",
            "NormTermName": "Rohrleitungssatz",
            "AssGrpName": "Schaltgetriebe",
            "UsageName": "Schaltgetriebesteuerung"
        },
        "60638": {
            "GenartName": "Sensor, Harnstoffgüte",
            "NormTermName": "Sensor",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Harnstoffgüte"
        },
        "60639": {
            "GenartName": "Cartridgesatz, Vorderradgabel",
            "NormTermName": "Cartridgesatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Gabel (Zweirad)"
        },
        "60640": {
            "GenartName": "Halter, Einspritzdüse",
            "NormTermName": "Halter",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzdüse"
        },
        "60641": {
            "GenartName": "Anschlussleitung, Sicherungskasten (Antriebsbatterie)",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Sicherungskasten (Antriebsbatterie)"
        },
        "60642": {
            "GenartName": "Anschlussleitung, Batteriemodul (Antriebsbatterie)",
            "NormTermName": "Elektroleitung",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Batteriemodul (Antriebsbatterie)"
        },
        "60643": {
            "GenartName": "Zellkontaktierung, Batteriemodul (Antriebsbatterie)",
            "NormTermName": "Zellkontaktierung",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Batteriemodul (Antriebsbatterie)"
        },
        "60644": {
            "GenartName": "Abdeckung, Zellkontaktierung (Batteriemodul)",
            "NormTermName": "Abdeckung",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Zellkontaktierung (Batteriemodul)"
        },
        "60645": {
            "GenartName": "Unterdruckdose, Drallklappen (Ansaugbrücke)",
            "NormTermName": "Unterdruckdose",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Drallklappen (Ansaugbrücke)"
        },
        "60646": {
            "GenartName": "Pumpe, Luftversorgung",
            "NormTermName": "Pumpe",
            "AssGrpName": "Luftversorgung",
            "UsageName": null
        },
        "60647": {
            "GenartName": "Schraube, Bremsscheibe",
            "NormTermName": "Schraube",
            "AssGrpName": "Bremsanlage, Universal",
            "UsageName": "Bremsscheibe"
        },
        "60648": {
            "GenartName": "Dichtung, Lagerschilddeckel",
            "NormTermName": "Dichtung",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Lagerschilddeckel"
        },
        "60649": {
            "GenartName": "Dichtungssatz, Ölpumpe",
            "NormTermName": "Dichtungssatz",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "60650": {
            "GenartName": "Hydraulikleitungssatz, Federung",
            "NormTermName": "Rohrleitungssatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "60651": {
            "GenartName": "Kamerahalterung, Spurhalteassistent",
            "NormTermName": "Halterung",
            "AssGrpName": "Sicherheitssysteme",
            "UsageName": "Spurhalteassistent"
        },
        "60652": {
            "GenartName": "Magnet, Verriegelungssystem",
            "NormTermName": "Magnet",
            "AssGrpName": "Schließanlage, Universal",
            "UsageName": "Verriegelungssystem"
        },
        "60653": {
            "GenartName": "Erweiterungsmodul, Luftfederung",
            "NormTermName": "Erweiterungsmodul",
            "AssGrpName": "Druckluftanlage",
            "UsageName": "Luftfederung"
        },
        "60654": {
            "GenartName": "Bremsensatz, Druckluftbremsanlage",
            "NormTermName": "Bremsensatz",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Druckluftbremsanlage"
        },
        "60655": {
            "GenartName": "Federungssatz, Druckluftfederung",
            "NormTermName": "Federungssatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Druckluftfederung"
        },
        "60656": {
            "GenartName": "Schlauch, Radsensor (Reifendruck-Kontrollsystem)",
            "NormTermName": "Schlauch",
            "AssGrpName": "Räder",
            "UsageName": "Radsensor"
        },
        "60657": {
            "GenartName": "Gegengewicht, Radsensor (Reifendruck-Kontrollsystem)",
            "NormTermName": "Gegengewicht",
            "AssGrpName": "Räder",
            "UsageName": "Radsensor"
        },
        "60658": {
            "GenartName": "Ausziehhülse, Antriebswelle",
            "NormTermName": "Hülse",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Antriebswelle"
        },
        "60659": {
            "GenartName": "Spannhülse, Antriebswelle",
            "NormTermName": "Hülse",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Antriebswelle"
        },
        "60660": {
            "GenartName": "Scheibenklebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Karosserie",
            "UsageName": "Scheibeneinbau"
        },
        "60661": {
            "GenartName": "Rücklaufsperrventil, Ölfilter",
            "NormTermName": "Ventil",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölfilter"
        },
        "60662": {
            "GenartName": "Ausgleichswelle, Kurbeltrieb",
            "NormTermName": "Welle",
            "AssGrpName": "Kurbeltrieb",
            "UsageName": null
        },
        "60663": {
            "GenartName": "Bremsenservicepaste",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Bremsenservicepaste"
        },
        "60664": {
            "GenartName": "Dichtung, Wechselrichter",
            "NormTermName": "Dichtung",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Wechselrichter"
        },
        "60665": {
            "GenartName": "Sieb, Einfüllstutzen (Kraftstoffbehälter)",
            "NormTermName": "Sieb",
            "AssGrpName": "Kraftstoffförderanlage, Universal",
            "UsageName": "Einfüllstutzen (Kraftstoffbehälter)"
        },
        "60666": {
            "GenartName": "Riemenscheibe, Klimakompressor",
            "NormTermName": "Riemenscheibe",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Klimakompressor"
        },
        "60667": {
            "GenartName": "Dichtung, Statorgehäuse",
            "NormTermName": "Dichtung",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Statorgehäuse"
        },
        "60668": {
            "GenartName": "Wandhalterung, Wärmeschrumpfschlauch-Sortiment",
            "NormTermName": "Wandhalterung",
            "AssGrpName": "Normteile",
            "UsageName": "Wärmeschrumpfschlauch-Sortiment"
        },
        "60669": {
            "GenartName": "Reparatursatz, Hochdruckpumpe",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Hochdruckpumpe"
        },
        "60670": {
            "GenartName": "Dichtring, Thermostat",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kühlung",
            "UsageName": "Thermostat"
        },
        "60671": {
            "GenartName": "Dichtring, Thermostatgehäuse",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kühlung",
            "UsageName": "Thermostatgehäuse"
        },
        "60672": {
            "GenartName": "Dichtring, Ölfilter",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölfilter"
        },
        "60673": {
            "GenartName": "Dichtring, Kraftstoffilter",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstofffilter"
        },
        "60674": {
            "GenartName": "Dichtring, Einspritzpumpe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Einspritzpumpe"
        },
        "60675": {
            "GenartName": "Dichtring, Kraftstoffeinfüllstutzen",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffeinfüllstutzen"
        },
        "60676": {
            "GenartName": "Dichtring, Wasserpumpe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kühlung",
            "UsageName": "Wasserpumpe"
        },
        "60677": {
            "GenartName": "Dichtring, Drosselklappenstutzen",
            "NormTermName": "Dichtring",
            "AssGrpName": "Gemischaufbereitung",
            "UsageName": "Drosselklappenstutzen"
        },
        "60678": {
            "GenartName": "Dichtring, Ölpumpe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölpumpe"
        },
        "60679": {
            "GenartName": "Dichtring, Ölfiltergehäuse",
            "NormTermName": "Dichtring",
            "AssGrpName": "Schmierung",
            "UsageName": "Ölfiltergehäuse"
        },
        "60680": {
            "GenartName": "Dichtring, Kraftstoffpumpe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Kraftstoffförderanlage",
            "UsageName": "Kraftstoffpumpe"
        },
        "60681": {
            "GenartName": "Dichtring, Unterdruckpumpe",
            "NormTermName": "Dichtring",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Unterdruckpumpe"
        },
        "60682": {
            "GenartName": "Wasserpumpe, Wechselrichter",
            "NormTermName": "Pumpe",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Wechselrichter"
        },
        "60683": {
            "GenartName": "Papierkatalog",
            "NormTermName": "Papierkatalog",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "60684": {
            "GenartName": "Reibbelag, Kupplungsscheibe",
            "NormTermName": "Reibbelag",
            "AssGrpName": "Kupplung",
            "UsageName": null
        },
        "60685": {
            "GenartName": "Zubehörsatz, Nietmaschine",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Nietmaschine"
        },
        "60686": {
            "GenartName": "Adapter, Atemschutzmasken-Dichtsitzprüfung",
            "NormTermName": "Adapter",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Atemschutzmasken-Dichtsitzprüfung"
        },
        "60687": {
            "GenartName": "Führungsrohr, Ölwanne (Automatikgetriebe)",
            "NormTermName": "Führungsrohr",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölwanne (Automatikgetriebe)"
        },
        "60688": {
            "GenartName": "Überlaufrohr, Ölwanne (Automatikgetriebe)",
            "NormTermName": "Überlaufrohr",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölwanne (Automatikgetriebe)"
        },
        "60689": {
            "GenartName": "Nachrüstsatz, Achslastanzeige",
            "NormTermName": "Nachrüstsatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Achslastanzeige"
        },
        "60690": {
            "GenartName": "Reparatursatz, Getriebe (E-Achse)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Getriebe (E-Achse)"
        },
        "60691": {
            "GenartName": "Reparatursatz, E-Motor (E-Achse)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "E-Motor (E-Achse)"
        },
        "60692": {
            "GenartName": "Lager, Starterkupplung (Zweirad)",
            "NormTermName": "Lager",
            "AssGrpName": "Startanlage",
            "UsageName": "Starterkupplung (Zweirad)"
        },
        "60693": {
            "GenartName": "Montagefett, Elektrokomponenten",
            "NormTermName": "Schmierstoff",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Elektrokomponenten"
        },
        "60694": {
            "GenartName": "Wurzelschneider",
            "NormTermName": "Wurzelschneider",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "60695": {
            "GenartName": "Aufsatz, Muffenschweißgerät",
            "NormTermName": "Aufsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Muffenschweißgerät"
        },
        "60696": {
            "GenartName": "Zugbolzen, Auszieher (Gleitschienenbolzen)",
            "NormTermName": "Zugbolzen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Auszieher (Gleitschienenbolzen)"
        },
        "60697": {
            "GenartName": "Ausziehersatz, Gleitschienenbolzen",
            "NormTermName": "Ausziehersatz",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Gleitschienenbolzen"
        },
        "60698": {
            "GenartName": "Zugbolzen, Radmutter",
            "NormTermName": "Zugbolzen",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Radmutter"
        },
        "60699": {
            "GenartName": "Auszieher, Glühkerzenstift",
            "NormTermName": "Auszieher",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Glühkerzenstift"
        },
        "60700": {
            "GenartName": "Auszieher, Glühkerzenstift",
            "NormTermName": "Auszieher",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Glühkerzenstift"
        },
        "60701": {
            "GenartName": "Einsatz, Blechknabber",
            "NormTermName": "Einsatz",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Blechknabber"
        },
        "60702": {
            "GenartName": "Druckstück, Nietsetzgerät",
            "NormTermName": "Druckstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Nietsetzgerät"
        },
        "60703": {
            "GenartName": "Hülse, Nietsetzgerät",
            "NormTermName": "Hülse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Nietsetzgerät"
        },
        "60704": {
            "GenartName": "Führungsrohr, Nutmutternschlüssel",
            "NormTermName": "Führungsrohr",
            "AssGrpName": "Spezialwerkzeug, Universal",
            "UsageName": "Nutmutternschlüssel"
        },
        "60705": {
            "GenartName": "Schweißgerät-Set",
            "NormTermName": "Schweißgerät-Set",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schneid-/Fügetechnik"
        },
        "60706": {
            "GenartName": "Lagerungssatz, Radaufhängung",
            "NormTermName": "Lagerungssatz",
            "AssGrpName": "Radaufhängung",
            "UsageName": null
        },
        "60707": {
            "GenartName": "Dichtung, Getriebe (E-Achse)",
            "NormTermName": "Dichtung",
            "AssGrpName": "Elektroantrieb",
            "UsageName": "Getriebe (E-Achse)"
        },
        "60708": {
            "GenartName": "Lagerbuchsensatz, Blattfeder",
            "NormTermName": "Buchsensatz",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": "Blattfederlagerung"
        },
        "60709": {
            "GenartName": "Sicherungsclip, Abgasanlage",
            "NormTermName": "Clip",
            "AssGrpName": "Abgasanlage",
            "UsageName": "Abgasanlage"
        },
        "60710": {
            "GenartName": "Solarladeregler, Batterie (Solarmodul)",
            "NormTermName": "Laderegler",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": "Batterie (Solarmodul)"
        },
        "60711": {
            "GenartName": "Wagenheber",
            "NormTermName": "Wagenheber",
            "AssGrpName": "Zubehör",
            "UsageName": null
        },
        "60712": {
            "GenartName": "Wagenheber",
            "NormTermName": "Wagenheber",
            "AssGrpName": "Zubehör, Universal",
            "UsageName": null
        },
        "60713": {
            "GenartName": "Wasserkocher",
            "NormTermName": "Wasserkocher",
            "AssGrpName": "Zubehör",
            "UsageName": null
        },
        "60714": {
            "GenartName": "Espressomaschine",
            "NormTermName": "Espressomaschine",
            "AssGrpName": "Zubehör",
            "UsageName": null
        },
        "60715": {
            "GenartName": "Betätigungshebel, Bedieneinheit (Klimaanlage)",
            "NormTermName": "Betätigungshebel",
            "AssGrpName": "Klimaanlage",
            "UsageName": "Bedieneinheit (Klimaanlage)"
        },
        "60716": {
            "GenartName": "Kabelreparatursatz, Ansauglufttemperatursensor",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Gemischaufbereitung, Universal",
            "UsageName": "Ansauglufttemperatursensor"
        },
        "60717": {
            "GenartName": "Ölvolumenspeicher, Automatikgetriebe",
            "NormTermName": "Ölvolumenspeicher",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "60718": {
            "GenartName": "Reparatursatz, Ölvolumenspeicher (Automatikgetriebe)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Ölvolumenspeicher (Automatikgetriebe)"
        },
        "60719": {
            "GenartName": "Unterdruckleitung, Druckregelventil",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "Druckregelventil"
        },
        "60720": {
            "GenartName": "Steuerdruckleitung, Druckregelventil",
            "NormTermName": "Schlauchleitung",
            "AssGrpName": "Abgasrückführung",
            "UsageName": "Druckregelventil"
        },
        "60721": {
            "GenartName": "Stellelement, aktive Motorhaube",
            "NormTermName": "Stellelement",
            "AssGrpName": "Karosserie",
            "UsageName": "Motorhaube"
        },
        "60722": {
            "GenartName": "Mundstück, Nietzange",
            "NormTermName": "Mundstück",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": "Nietzange"
        },
        "60723": {
            "GenartName": "Regalboden, Schranksystem",
            "NormTermName": "Regalboden",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Schranksystem"
        },
        "60724": {
            "GenartName": "Einstellwerkzeug, Scheibenwaschwasserdüse",
            "NormTermName": "Einstellwerkzeug",
            "AssGrpName": "Werkzeug, fahrzeugspezifisch",
            "UsageName": "Scheibenwaschwasserdüse"
        },
        "60725": {
            "GenartName": "Fettpresse (mechanisch)",
            "NormTermName": "Fettpresse",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "60726": {
            "GenartName": "Seilzug, Werkzeugwagen",
            "NormTermName": "Seilzug",
            "AssGrpName": "Werkstattausrüstung",
            "UsageName": "Werkzeugwagen"
        },
        "60727": {
            "GenartName": "Handleuchten-Satz",
            "NormTermName": "Leuchtensatz",
            "AssGrpName": "Werkstattgeräte",
            "UsageName": "Handleuchte"
        },
        "60728": {
            "GenartName": "Kabelreparatursatz, Widerstand (Innenraumgebläse)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Heizung/Lüftung",
            "UsageName": "Widerstand (Innenraumgebläse)"
        },
        "60729": {
            "GenartName": "Kabelreparatursatz, Schiebetür",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Karosserie",
            "UsageName": "Schiebetür"
        },
        "60730": {
            "GenartName": "Steckdose",
            "NormTermName": "Steckdose",
            "AssGrpName": "Innenausstattung",
            "UsageName": null
        },
        "60731": {
            "GenartName": "Zubehörsatz, Soundverstärker",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Abgasanlage, Universal",
            "UsageName": "Soundverstärker"
        },
        "60732": {
            "GenartName": "Öltrennblech, Automatikgetriebe",
            "NormTermName": "Öltrennblech",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Automatikgetriebe"
        },
        "60734": {
            "GenartName": "Anschlussstutzen, Ansaugschlauch (Luftfilter)",
            "NormTermName": "Stutzen",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Ansaugschlauch"
        },
        "60735": {
            "GenartName": "Reparatursatz, Antriebswelle",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "60736": {
            "GenartName": "Reparatursatz, Stütze (Motorhalter)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Stütze"
        },
        "60737": {
            "GenartName": "Reparatursatz, Pendelstütze (Motorhalter)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Pendelstütze"
        },
        "60738": {
            "GenartName": "Reparatursatz, Parksperre",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Parksperre"
        },
        "60739": {
            "GenartName": "Reparatursatz, elektrische Kupplung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "elektrische Kupplung"
        },
        "60740": {
            "GenartName": "Reparatursatz, Schutzblech",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Schutzblech"
        },
        "60741": {
            "GenartName": "Schraubensatz, Schutzblech",
            "NormTermName": "Schraubensatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Schutzblech"
        },
        "60742": {
            "GenartName": "Reparatursatz, Servicedeckel",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Servicedeckel"
        },
        "60743": {
            "GenartName": "Reparatursatz, Kühlgehäusedeckel",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Kühlgehäusedeckel"
        },
        "60744": {
            "GenartName": "Reparatursatz, Kühlmittelleitung",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Kühlmittelleitung"
        },
        "60745": {
            "GenartName": "Reparatursatz, Motorhalter (E-Motor)",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Hybrid-/Elektroantrieb",
            "UsageName": "Motorhalter (E-Motor)"
        },
        "60746": {
            "GenartName": "Reflektorklebestreifen, Warnmarkierung",
            "NormTermName": "Reflektorklebestreifen",
            "AssGrpName": "Sicherheitssysteme, Universal",
            "UsageName": "Warnmarkierung"
        },
        "60747": {
            "GenartName": "Hydraulikleitung, Federung",
            "NormTermName": "Rohrleitung",
            "AssGrpName": "Federung/Dämpfung",
            "UsageName": null
        },
        "60748": {
            "GenartName": "Silikondichtstoff",
            "NormTermName": "Dichtung",
            "AssGrpName": "Chemische Produkte",
            "UsageName": "Silikondichtstoff"
        },
        "60749": {
            "GenartName": "Dichtung, Motoröltank",
            "NormTermName": "Dichtung",
            "AssGrpName": "Schmierung",
            "UsageName": "Motoröltank"
        },
        "60750": {
            "GenartName": "Fügeklebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "60751": {
            "GenartName": "Strukturklebstoff",
            "NormTermName": "Klebstoff",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "60752": {
            "GenartName": "Aktivator, Klebstoff",
            "NormTermName": "Aktivator",
            "AssGrpName": "Chemische Produkte",
            "UsageName": null
        },
        "60753": {
            "GenartName": "Kartuschenöffner",
            "NormTermName": "Kartuschenöffner",
            "AssGrpName": "Werkzeug, Universal",
            "UsageName": null
        },
        "60754": {
            "GenartName": "Antennenweiche",
            "NormTermName": "Antennenweiche",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Antenne"
        },
        "60755": {
            "GenartName": "Zubehörsatz, Antenne",
            "NormTermName": "Zubehörsatz",
            "AssGrpName": "Kommunikations-/Informationssysteme, Universal",
            "UsageName": "Antenne"
        },
        "60757": {
            "GenartName": "Kabelreparatursatz, Stellelement (Turbolader)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Stellelement (Turbolader)"
        },
        "60758": {
            "GenartName": "Kabelreparatursatz, Stellelement (Turbolader)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Luftversorgung, Universal",
            "UsageName": "Stellelement (Turbolader)"
        },
        "60759": {
            "GenartName": "Kühlmittelrohr, Lader",
            "NormTermName": "Kühlmittelrohr",
            "AssGrpName": "Luftversorgung",
            "UsageName": "Lader"
        },
        "60760": {
            "GenartName": "Zugösenbuchse, Deichsel",
            "NormTermName": "Buchse",
            "AssGrpName": "Anhänger",
            "UsageName": "Deichsel"
        },
        "60761": {
            "GenartName": "Antriebszwischenwelle",
            "NormTermName": "Welle",
            "AssGrpName": "Radantrieb",
            "UsageName": "Antriebswelle"
        },
        "60762": {
            "GenartName": "Hebel, Türentriegelung",
            "NormTermName": "Hebel",
            "AssGrpName": "Schließanlage",
            "UsageName": "Türentriegelung"
        },
        "60763": {
            "GenartName": "Exzenter, Türschloss",
            "NormTermName": "Exzenter",
            "AssGrpName": "Schließanlage",
            "UsageName": "Türschloss"
        },
        "60764": {
            "GenartName": "Reparatursatz, Türschloss",
            "NormTermName": "Reparatursatz",
            "AssGrpName": "Schließanlage",
            "UsageName": "Türschloss"
        },
        "60765": {
            "GenartName": "Rahmen, Türentriegelungshebel",
            "NormTermName": "Rahmen",
            "AssGrpName": "Innenausstattung",
            "UsageName": "Hebel, Türentriegelung"
        },
        "60766": {
            "GenartName": "Kabelreparatursatz, Magnetventile (Schaltschieberkasten)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Automatikgetriebe",
            "UsageName": "Magnetventile (Schaltschieberkasten)"
        },
        "60767": {
            "GenartName": "Kabelreparatursatz, Magnetventile (Schaltschieberkasten)",
            "NormTermName": "Kabelreparatursatz",
            "AssGrpName": "Automatikgetriebe, Universal",
            "UsageName": "Magnetventile (Schaltschieberkasten)"
        },
        "60768": {
            "GenartName": "Bremsbacke, Feststellbremse",
            "NormTermName": "Bremsbacke",
            "AssGrpName": "Bremsanlage",
            "UsageName": "Feststellbremse"
        }
    },
    "en": {
        "00001": {
            "GenartName": "Starter Battery",
            "NormTermName": "Battery",
            "AssGrpName": "Starter System",
            "UsageName": null
        },
        "00002": {
            "GenartName": "Starter",
            "NormTermName": "Starter",
            "AssGrpName": "Starter System",
            "UsageName": null
        },
        "00004": {
            "GenartName": "Alternator",
            "NormTermName": "Alternator",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "00005": {
            "GenartName": "Joint Kit, drive shaft",
            "NormTermName": "Joint Kit",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "00007": {
            "GenartName": "Oil Filter",
            "NormTermName": "Filter",
            "AssGrpName": "Lubrication",
            "UsageName": "Engine Oil"
        },
        "00008": {
            "GenartName": "Air Filter",
            "NormTermName": "Filter",
            "AssGrpName": "Air Supply",
            "UsageName": "Air"
        },
        "00009": {
            "GenartName": "Fuel Filter",
            "NormTermName": "Filter",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel"
        },
        "00010": {
            "GenartName": "V-Belt",
            "NormTermName": "Belt",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Belt"
        },
        "00012": {
            "GenartName": "Hydraulic Pump, steering system",
            "NormTermName": "Pump",
            "AssGrpName": "Steering",
            "UsageName": "Hydraulics"
        },
        "00013": {
            "GenartName": "Drive Shaft",
            "NormTermName": "Shaft",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "00014": {
            "GenartName": "Diffusing Lens, headlight",
            "NormTermName": "Diffusing Lens",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "00015": {
            "GenartName": "Diffusing Lens, searchlight",
            "NormTermName": "Diffusing Lens",
            "AssGrpName": "Lights",
            "UsageName": "Searchlight"
        },
        "00016": {
            "GenartName": "Diffusing Lens, spotlight",
            "NormTermName": "Diffusing Lens",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "00017": {
            "GenartName": "Exhaust Pipe",
            "NormTermName": "Tube",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "00019": {
            "GenartName": "Eyelid, front fog light",
            "NormTermName": "Cover",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "00020": {
            "GenartName": "Cover, bumper",
            "NormTermName": "Cover",
            "AssGrpName": "Body",
            "UsageName": "Bumper"
        },
        "00021": {
            "GenartName": "Flap, tow hook",
            "NormTermName": "Lid",
            "AssGrpName": "Body",
            "UsageName": "Tow Hook"
        },
        "00022": {
            "GenartName": "Cover, plenum chamber",
            "NormTermName": "Cover",
            "AssGrpName": "Body",
            "UsageName": "Plenum Chamber"
        },
        "00023": {
            "GenartName": "Cover, exterior mirror",
            "NormTermName": "Cover",
            "AssGrpName": "Body",
            "UsageName": "Exterior Mirror"
        },
        "00025": {
            "GenartName": "Cap, headlight",
            "NormTermName": "Cap",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "00026": {
            "GenartName": "Exhaust System",
            "NormTermName": "Exhaust System",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "00027": {
            "GenartName": "Gasket, exhaust manifold",
            "NormTermName": "Seal",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Exhaust Manifold"
        },
        "00028": {
            "GenartName": "Gasket Set, exhaust manifold",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Exhaust Manifold"
        },
        "00029": {
            "GenartName": "Valve, charcoal filter (tank ventilation)",
            "NormTermName": "Valve",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Charcoal Filter (tank ventilation)"
        },
        "00031": {
            "GenartName": "Idler Valve, carburettor",
            "NormTermName": "Valve",
            "AssGrpName": "Carburettor",
            "UsageName": "Idle"
        },
        "00032": {
            "GenartName": "Heat Shield, towbar",
            "NormTermName": "Shield",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Shielding"
        },
        "00033": {
            "GenartName": "Valve, fuel supply system",
            "NormTermName": "Valve",
            "AssGrpName": "Fuel Supply System",
            "UsageName": null
        },
        "00034": {
            "GenartName": "Stub Axle Pins",
            "NormTermName": "Bolt",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Steering Knuckle"
        },
        "00035": {
            "GenartName": "Adaptor Kit, speed warning system",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Speeding Alarm",
            "UsageName": null
        },
        "00036": {
            "GenartName": "Ammeter",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Ammeter"
        },
        "00037": {
            "GenartName": "Mounting Kit, shock absorber",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shock Absorber"
        },
        "00038": {
            "GenartName": "Pedestal, trailer hitch",
            "NormTermName": "Pedestal, trailer hitch",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "00039": {
            "GenartName": "Trailer Hitch",
            "NormTermName": "Trailer Hitch",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "00040": {
            "GenartName": "Gasket, intake/exhaust manifold",
            "NormTermName": "Seal",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Intake / Exhaust Manifold"
        },
        "00041": {
            "GenartName": "Gasket Set, intake/exhaust manifold",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Intake / Exhaust Manifold"
        },
        "00042": {
            "GenartName": "Gasket, intake manifold",
            "NormTermName": "Seal",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Intake Manifold"
        },
        "00043": {
            "GenartName": "Gasket Set, intake manifold",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Intake Manifold"
        },
        "00044": {
            "GenartName": "Gasket, intake manifold housing",
            "NormTermName": "Seal",
            "AssGrpName": "Air Supply",
            "UsageName": "Intake Manifold Housing"
        },
        "00045": {
            "GenartName": "Base Plate, trailer hitch",
            "NormTermName": "Base Plate",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "00046": {
            "GenartName": "Aerial",
            "NormTermName": "Aerial",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Car Radio"
        },
        "00047": {
            "GenartName": "Central Slave Cylinder, clutch",
            "NormTermName": "Central Slave Cylinder",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "00048": {
            "GenartName": "Clutch Release Bearing",
            "NormTermName": "Bearing",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Release Bearing"
        },
        "00049": {
            "GenartName": "Release Plate, clutch",
            "NormTermName": "Release Plate",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "00050": {
            "GenartName": "Exterior Mirror",
            "NormTermName": "Mirror",
            "AssGrpName": "Body",
            "UsageName": "Exterior Mirror"
        },
        "00051": {
            "GenartName": "Inner Tie Rod",
            "NormTermName": "Joint",
            "AssGrpName": "Steering",
            "UsageName": "Axle Joint"
        },
        "00052": {
            "GenartName": "Battery Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Battery Holder"
        },
        "00053": {
            "GenartName": "Control Unit, accident data recorder",
            "NormTermName": "Control Unit",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Accident Data Recorder"
        },
        "00054": {
            "GenartName": "Bolt, brake disc",
            "NormTermName": "Screw",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Disc"
        },
        "00055": {
            "GenartName": "Cap, brake fluid reservoir",
            "NormTermName": "Closure",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Fluid Reservoir"
        },
        "00056": {
            "GenartName": "Cap, coolant tank",
            "NormTermName": "Closure",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Tank"
        },
        "00057": {
            "GenartName": "Sealing Cap, washer fluid reservoir",
            "NormTermName": "Closure",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washer Fluid Reservoir"
        },
        "00060": {
            "GenartName": "Eyelid, headlight",
            "NormTermName": "Cover",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "00061": {
            "GenartName": "Flasher Unit",
            "NormTermName": "Relay",
            "AssGrpName": "Signal System",
            "UsageName": "Flasher Unit"
        },
        "00062": {
            "GenartName": "Direction Indicator",
            "NormTermName": "Light",
            "AssGrpName": "Signal System",
            "UsageName": "Direction Indicator"
        },
        "00063": {
            "GenartName": "Housing, direction indicator",
            "NormTermName": "Housing",
            "AssGrpName": "Signal System",
            "UsageName": "Direction Indicator"
        },
        "00064": {
            "GenartName": "Indicator Grille",
            "NormTermName": "Grille",
            "AssGrpName": "Signal System",
            "UsageName": "Direction Indicator"
        },
        "00066": {
            "GenartName": "Body Floor",
            "NormTermName": "Floor Panel",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "00068": {
            "GenartName": "Boat Carrier, roof rack",
            "NormTermName": "Boat Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Roof Rack"
        },
        "00069": {
            "GenartName": "Boat Holder, rear rack",
            "NormTermName": "Boat Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Rear Rack"
        },
        "00070": {
            "GenartName": "Brake Shoe Set",
            "NormTermName": "Brake Shoe Set",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "00071": {
            "GenartName": "Brake Fluid",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "00073": {
            "GenartName": "Brake Force Regulator",
            "NormTermName": "Regulator",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Force Regulator"
        },
        "00074": {
            "GenartName": "Brake Booster",
            "NormTermName": "Booster",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Booster"
        },
        "00076": {
            "GenartName": "Stop Light",
            "NormTermName": "Light",
            "AssGrpName": "Signal System",
            "UsageName": "Stop Light"
        },
        "00077": {
            "GenartName": "Housing, stop light",
            "NormTermName": "Housing",
            "AssGrpName": "Signal System",
            "UsageName": "Stop Light"
        },
        "00078": {
            "GenartName": "Brake Caliper",
            "NormTermName": "Brake Caliper",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "00079": {
            "GenartName": "Brake Caliper Axle Kit",
            "NormTermName": "Axle Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "00081": {
            "GenartName": "Brake Caliper Bracket Set",
            "NormTermName": "Holder Set",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "00082": {
            "GenartName": "Brake Disc",
            "NormTermName": "Brake Disc",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "00083": {
            "GenartName": "Brake Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Hydraulics"
        },
        "00084": {
            "GenartName": "Diffusing Lens,worklight",
            "NormTermName": "Diffusing Lens",
            "AssGrpName": "Lights",
            "UsageName": "Worklight"
        },
        "00085": {
            "GenartName": "Diffusing Lens, front fog light",
            "NormTermName": "Diffusing Lens",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "00086": {
            "GenartName": "Insert, worklight",
            "NormTermName": "Insert",
            "AssGrpName": "Lights",
            "UsageName": "Worklight"
        },
        "00087": {
            "GenartName": "Insert, spotlight",
            "NormTermName": "Insert",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "00088": {
            "GenartName": "Insert, headlight",
            "NormTermName": "Insert",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "00089": {
            "GenartName": "Insert, front fog light",
            "NormTermName": "Insert",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "00090": {
            "GenartName": "Insert, searchlight",
            "NormTermName": "Insert",
            "AssGrpName": "Lights",
            "UsageName": "Searchlight"
        },
        "00091": {
            "GenartName": "Lens, direction indicator",
            "NormTermName": "Lens",
            "AssGrpName": "Signal System",
            "UsageName": "Direction Indicator"
        },
        "00092": {
            "GenartName": "Lens, stop light",
            "NormTermName": "Lens",
            "AssGrpName": "Signal System",
            "UsageName": "Stop Light"
        },
        "00093": {
            "GenartName": "Lens, combination rear light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights",
            "UsageName": "Combination Rear Light"
        },
        "00094": {
            "GenartName": "Lens, licence plate light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights",
            "UsageName": "Licence Plate Light"
        },
        "00095": {
            "GenartName": "Lens, rear fog light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights",
            "UsageName": "Rear Fog Light"
        },
        "00096": {
            "GenartName": "Lens, reverse light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights",
            "UsageName": "Reverse Light"
        },
        "00097": {
            "GenartName": "Lens, tail light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights",
            "UsageName": "Tail Light"
        },
        "00098": {
            "GenartName": "Lens, rotating beacon",
            "NormTermName": "Lens",
            "AssGrpName": "Signal System",
            "UsageName": "Rotating Beacon"
        },
        "00099": {
            "GenartName": "Base, worklight",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Worklight"
        },
        "00100": {
            "GenartName": "Base, spotlight",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "00101": {
            "GenartName": "Base, headlight",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "00102": {
            "GenartName": "Holder, front fog light",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "00103": {
            "GenartName": "Base, searchlight",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Searchlight"
        },
        "00104": {
            "GenartName": "Bulb, worklight",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Worklight"
        },
        "00105": {
            "GenartName": "Bulb, direction indicator",
            "NormTermName": "Bulb",
            "AssGrpName": "Signal System",
            "UsageName": "Direction Indicator"
        },
        "00106": {
            "GenartName": "Bulb, spotlight",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "00107": {
            "GenartName": "Bulb, headlight",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "00108": {
            "GenartName": "Bulb, front fog light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "00109": {
            "GenartName": "Bulb, searchlight",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Searchlight"
        },
        "00110": {
            "GenartName": "Bulb, stop/tail light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Brake-/Stoplight"
        },
        "00111": {
            "GenartName": "Bulb, stop light",
            "NormTermName": "Bulb",
            "AssGrpName": "Signal System",
            "UsageName": "Stop Light"
        },
        "00112": {
            "GenartName": "Bulb, licence plate light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Licence Plate Light"
        },
        "00113": {
            "GenartName": "Bulb, rear fog light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Rear Fog Light"
        },
        "00114": {
            "GenartName": "Bulb, reverse light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Reverse Light"
        },
        "00115": {
            "GenartName": "Bulb, tail light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Tail Light"
        },
        "00116": {
            "GenartName": "Bulb, rotating beacon",
            "NormTermName": "Bulb",
            "AssGrpName": "Signal System",
            "UsageName": "Rotating Beacon"
        },
        "00117": {
            "GenartName": "Bulb, interior light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Interior Light"
        },
        "00118": {
            "GenartName": "Bulb, door light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Door Light"
        },
        "00119": {
            "GenartName": "Bulb, boot light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Boot Light"
        },
        "00120": {
            "GenartName": "Bulb, engine bay light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Light, engine bay"
        },
        "00121": {
            "GenartName": "Bulb, instrument lighting",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Instrument Lighting"
        },
        "00122": {
            "GenartName": "Holding Bracket, brake hose",
            "NormTermName": "Holder",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Hose"
        },
        "00123": {
            "GenartName": "Brake Drum",
            "NormTermName": "Brake Drum",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "00124": {
            "GenartName": "Cable Pull, parking brake",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake"
        },
        "00125": {
            "GenartName": "Cable Pull, service brake",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Braking System",
            "UsageName": "Service Brake"
        },
        "00126": {
            "GenartName": "Roof Box",
            "NormTermName": "Roof Box",
            "AssGrpName": "Carriers",
            "UsageName": null
        },
        "00127": {
            "GenartName": "Roof Rack",
            "NormTermName": "Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Roof Rack"
        },
        "00128": {
            "GenartName": "O-Ring, cylinder sleeve",
            "NormTermName": "Seal",
            "AssGrpName": "Crankcase",
            "UsageName": "Cylinder Sleeve"
        },
        "00130": {
            "GenartName": "Shock Absorber, steering",
            "NormTermName": "Damper",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "00131": {
            "GenartName": "Gasket, oil cooler",
            "NormTermName": "Seal",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Cooler"
        },
        "00132": {
            "GenartName": "Gasket, oil pump",
            "NormTermName": "Seal",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "00133": {
            "GenartName": "Seal, oil filter",
            "NormTermName": "Seal",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Filter"
        },
        "00134": {
            "GenartName": "Seal, oil strainer",
            "NormTermName": "Seal",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Strainer"
        },
        "00135": {
            "GenartName": "Seal Ring, oil drain plug",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Drain Plug"
        },
        "00136": {
            "GenartName": "Seal Ring, oil pressure switch",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pressure Switch"
        },
        "00137": {
            "GenartName": "Gasket, oil sump",
            "NormTermName": "Seal",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Sump"
        },
        "00138": {
            "GenartName": "Gasket, exhaust pipe",
            "NormTermName": "Seal",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Pipe"
        },
        "00139": {
            "GenartName": "Seal, thermostat",
            "NormTermName": "Seal",
            "AssGrpName": "Cooling System",
            "UsageName": "Thermostat"
        },
        "00141": {
            "GenartName": "Gasket, charger",
            "NormTermName": "Seal",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger"
        },
        "00143": {
            "GenartName": "Gasket, fuel pump",
            "NormTermName": "Seal",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "00144": {
            "GenartName": "Seal, fuel filter",
            "NormTermName": "Seal",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Filter"
        },
        "00146": {
            "GenartName": "Oil Seal, manual transmission",
            "NormTermName": "Seal",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "00147": {
            "GenartName": "Oil Seal, automatic transmission",
            "NormTermName": "Seal",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "00148": {
            "GenartName": "Seal, fuel sender unit",
            "NormTermName": "Seal",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Sender Unit, fuel tank"
        },
        "00149": {
            "GenartName": "Seal, fuel filler neck",
            "NormTermName": "Seal",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Filler Neck"
        },
        "00150": {
            "GenartName": "Gasket Kit, crankcase",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase"
        },
        "00151": {
            "GenartName": "O-Ring Set, cylinder sleeve",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Crankcase",
            "UsageName": "Cylinder Sleeve"
        },
        "00152": {
            "GenartName": "Gasket Set, charger",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger"
        },
        "00155": {
            "GenartName": "Clamping Ring, release plate",
            "NormTermName": "Wire Ring",
            "AssGrpName": "Clutch",
            "UsageName": "Release Plate"
        },
        "00156": {
            "GenartName": "Watt Linkage",
            "NormTermName": "Linkage",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Torsion Bar"
        },
        "00157": {
            "GenartName": "Revolution Counter",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "RPM"
        },
        "00158": {
            "GenartName": "Throttle Body",
            "NormTermName": "Fitting",
            "AssGrpName": "Air Supply",
            "UsageName": "Throttle Valve"
        },
        "00159": {
            "GenartName": "Gauge, boost pressure",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Boost Pressure"
        },
        "00160": {
            "GenartName": "Gauge, oil pressure",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Oil Pressure"
        },
        "00162": {
            "GenartName": "Sensor, oil pressure",
            "NormTermName": "Sensor",
            "AssGrpName": "Instruments",
            "UsageName": "Oil Pressure"
        },
        "00163": {
            "GenartName": "Regulating Valve, oil pressure",
            "NormTermName": "Valve",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pressure Control"
        },
        "00164": {
            "GenartName": "Regulating Valve, clutch master cylinder",
            "NormTermName": "Valve",
            "AssGrpName": "Clutch",
            "UsageName": "Master Cylinder"
        },
        "00165": {
            "GenartName": "Pressure Valve, drum brake",
            "NormTermName": "Valve",
            "AssGrpName": "Braking System",
            "UsageName": "Pressure Preservation"
        },
        "00166": {
            "GenartName": "Pressure Modulator, brake pressure",
            "NormTermName": "Pressure Modulator",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "00167": {
            "GenartName": "Brake Pressure Regulator",
            "NormTermName": "Regulator",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Pressure"
        },
        "00168": {
            "GenartName": "Control Valve, fuel pressure",
            "NormTermName": "Regulator",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pressure"
        },
        "00170": {
            "GenartName": "Pressure Converter",
            "NormTermName": "Pressure Converter",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": null
        },
        "00171": {
            "GenartName": "Washer-jet Pipe",
            "NormTermName": "Tube",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washer Nozzle"
        },
        "00176": {
            "GenartName": "Ball Stud, tie rod",
            "NormTermName": "Bolt",
            "AssGrpName": "Steering",
            "UsageName": "Track Rod Ball Joint"
        },
        "00177": {
            "GenartName": "Entry plate",
            "NormTermName": "Foot Board",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "00178": {
            "GenartName": "Entry light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Entry bar"
        },
        "00179": {
            "GenartName": "Electric Kit, trailer hitch",
            "NormTermName": "Electric Kit",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "00181": {
            "GenartName": "Exhaust Tip",
            "NormTermName": "Cover",
            "AssGrpName": "Exhaust System",
            "UsageName": "Tailpipe"
        },
        "00183": {
            "GenartName": "Expansion Valve, air conditioning",
            "NormTermName": "Valve",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Expansion"
        },
        "00184": {
            "GenartName": "Quick Repair Service, drive shaft",
            "NormTermName": "Quick Repair Service",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "00185": {
            "GenartName": "Quick Repair Service, universal joint",
            "NormTermName": "Quick Repair Service",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Joint"
        },
        "00186": {
            "GenartName": "Bicycle Holder, rear rack",
            "NormTermName": "Bicycle Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Rear Rack"
        },
        "00187": {
            "GenartName": "Bicycle Holder, roof rack",
            "NormTermName": "Bicycle Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Roof Rack"
        },
        "00188": {
            "GenartName": "Suspension Spring",
            "NormTermName": "Spring",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "00189": {
            "GenartName": "Suspension kit, springs",
            "NormTermName": "Spring Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring"
        },
        "00190": {
            "GenartName": "Suspension Kit, springs/shock absorbers",
            "NormTermName": "Suspension Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "00191": {
            "GenartName": "Bellow, steering",
            "NormTermName": "Bellow",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "00193": {
            "GenartName": "Bellow, drive shaft",
            "NormTermName": "Bellow",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "00194": {
            "GenartName": "Bellow Kit, drive shaft",
            "NormTermName": "Bellow Kit",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "00195": {
            "GenartName": "Bellow Kit, steering",
            "NormTermName": "Bellow Kit",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "00196": {
            "GenartName": "Trumpet Horn",
            "NormTermName": "Trumpet Horn",
            "AssGrpName": "Signal System",
            "UsageName": null
        },
        "00198": {
            "GenartName": "Suspension Strut",
            "NormTermName": "Suspension Strut",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "00200": {
            "GenartName": "Electric Motor, window regulator",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Window Regulator"
        },
        "00201": {
            "GenartName": "Remote Control, central locking",
            "NormTermName": "Remote Control",
            "AssGrpName": "Lock System",
            "UsageName": "Central Locking System"
        },
        "00202": {
            "GenartName": "Spotlight",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "00203": {
            "GenartName": "Windscreen Frame",
            "NormTermName": "Frame",
            "AssGrpName": "Body",
            "UsageName": "Windscreen Frame"
        },
        "00204": {
            "GenartName": "Seal Ring, exhaust pipe",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Pipe"
        },
        "00205": {
            "GenartName": "Gasket, carburettor flange",
            "NormTermName": "Seal",
            "AssGrpName": "Carburettor",
            "UsageName": "Carburettor Flange"
        },
        "00208": {
            "GenartName": "Front Cowling",
            "NormTermName": "Panelling",
            "AssGrpName": "Body",
            "UsageName": "Front Cowling"
        },
        "00209": {
            "GenartName": "Guide Arm, window cleaning",
            "NormTermName": "Guide Arm",
            "AssGrpName": "Window Cleaning",
            "UsageName": null
        },
        "00213": {
            "GenartName": "Guide Tube, clutch",
            "NormTermName": "Sleeve",
            "AssGrpName": "Clutch",
            "UsageName": "Guide Tube"
        },
        "00214": {
            "GenartName": "Floor Mat",
            "NormTermName": "Floor Mat",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "00215": {
            "GenartName": "Floor Mat Set",
            "NormTermName": "Floor Mat Set",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "00216": {
            "GenartName": "Shift Console, automatic transmission",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Shift Position"
        },
        "00217": {
            "GenartName": "Vapour Separator, carburettor",
            "NormTermName": "Trap",
            "AssGrpName": "Carburettor",
            "UsageName": "Vapour"
        },
        "00219": {
            "GenartName": "Gas Spring, boot-/cargo area",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Boot-/Cargo Area Lid"
        },
        "00220": {
            "GenartName": "Gas Spring, foldaway table",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Foldaway Table"
        },
        "00221": {
            "GenartName": "Gas Spring, glove compartment",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Glove Compartment"
        },
        "00222": {
            "GenartName": "Gas Spring, centre console",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Centre Console"
        },
        "00223": {
            "GenartName": "Gas Spring, telephone console",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Telephone Console"
        },
        "00224": {
            "GenartName": "Gas Spring, tool cabinet flap",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Toolbox Door"
        },
        "00225": {
            "GenartName": "Gas Spring, bunk",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Bunk"
        },
        "00226": {
            "GenartName": "Gas Spring, wing-door",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Wing-door"
        },
        "00227": {
            "GenartName": "Gas Spring, radiator emblem",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Radiator Emblem"
        },
        "00228": {
            "GenartName": "Gas Spring, pop-up headlight",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Pop-up Headlight"
        },
        "00229": {
            "GenartName": "Gas Spring, foot-operated parking brake",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Braking System",
            "UsageName": "Foot-operated Parking Brake"
        },
        "00230": {
            "GenartName": "Gas Spring, roll bar",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Rollbar"
        },
        "00231": {
            "GenartName": "Gas Spring, spare-wheel carrier",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Spare Tyre Bracket"
        },
        "00232": {
            "GenartName": "Gas Spring, overrun brake",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Braking System",
            "UsageName": "Overrun Brake"
        },
        "00234": {
            "GenartName": "Master Cylinder, clutch",
            "NormTermName": "Cylinder",
            "AssGrpName": "Clutch",
            "UsageName": "Master Cylinder"
        },
        "00236": {
            "GenartName": "Joint, drive shaft",
            "NormTermName": "Joint",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "00237": {
            "GenartName": "Joint Fork, tie rod",
            "NormTermName": "Joint Fork",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod"
        },
        "00238": {
            "GenartName": "Luggage Box, roof rack",
            "NormTermName": "Luggage Box",
            "AssGrpName": "Carriers",
            "UsageName": "Roof Rack"
        },
        "00239": {
            "GenartName": "Luggage Box, rear rack",
            "NormTermName": "Luggage Box",
            "AssGrpName": "Carriers",
            "UsageName": "Rear Rack"
        },
        "00240": {
            "GenartName": "Cruise Control",
            "NormTermName": "Control System",
            "AssGrpName": "Cruise Control",
            "UsageName": null
        },
        "00241": {
            "GenartName": "Speeding Alarm",
            "NormTermName": "Warning System",
            "AssGrpName": "Speeding Alarm",
            "UsageName": null
        },
        "00242": {
            "GenartName": "Wiper Gear, window cleaning",
            "NormTermName": "Transmission",
            "AssGrpName": "Window Cleaning",
            "UsageName": null
        },
        "00243": {
            "GenartName": "Glow Plug",
            "NormTermName": "Glow Plug",
            "AssGrpName": "Glow Ignition System",
            "UsageName": null
        },
        "00244": {
            "GenartName": "Ventilation Grille, bumper",
            "NormTermName": "Grille",
            "AssGrpName": "Body",
            "UsageName": "Bumper"
        },
        "00245": {
            "GenartName": "Support, radiator grille",
            "NormTermName": "Support",
            "AssGrpName": "Body",
            "UsageName": "Radiator Grille"
        },
        "00246": {
            "GenartName": "Steering Column Coupling",
            "NormTermName": "Flange",
            "AssGrpName": "Steering",
            "UsageName": "Steering Column"
        },
        "00247": {
            "GenartName": "Mounting, engine",
            "NormTermName": "Mounting",
            "AssGrpName": "Engine Mounting System",
            "UsageName": null
        },
        "00248": {
            "GenartName": "Mounting, automatic transmission",
            "NormTermName": "Mounting",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "00249": {
            "GenartName": "Mounting, manual transmission",
            "NormTermName": "Mounting",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "00251": {
            "GenartName": "Mounting, control/trailing arm",
            "NormTermName": "Mounting",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Control/Trailing Arm"
        },
        "00252": {
            "GenartName": "Rubber Buffer, muffler",
            "NormTermName": "Buffer",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "00253": {
            "GenartName": "Rubber Mount, muffler",
            "NormTermName": "Retaining Ring",
            "AssGrpName": "Exhaust System",
            "UsageName": "Muffler"
        },
        "00254": {
            "GenartName": "Clip, bumper",
            "NormTermName": "Clip",
            "AssGrpName": "Body",
            "UsageName": "Bumper"
        },
        "00255": {
            "GenartName": "Spring, brake shoes",
            "NormTermName": "Spring",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoes"
        },
        "00256": {
            "GenartName": "Spring, disc brake pad",
            "NormTermName": "Spring",
            "AssGrpName": "Braking System",
            "UsageName": "Disc Brake Pad"
        },
        "00257": {
            "GenartName": "Supporting Frame, window cleaning system",
            "NormTermName": "Frame",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Supporting Frame"
        },
        "00258": {
            "GenartName": "Brake Master Cylinder",
            "NormTermName": "Cylinder",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Master Cylinder"
        },
        "00259": {
            "GenartName": "Headlight",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "00261": {
            "GenartName": "Clutch Pressure Plate",
            "NormTermName": "Pressure Plate",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "00262": {
            "GenartName": "Clutch Disc",
            "NormTermName": "Clutch Disc",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "00264": {
            "GenartName": "Clutch Booster",
            "NormTermName": "Booster",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Clutch"
        },
        "00265": {
            "GenartName": "Main Shaft, manual transmission",
            "NormTermName": "Shaft",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Main Shaft"
        },
        "00266": {
            "GenartName": "Ball Head, gearshift linkage",
            "NormTermName": "Ball Head",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gearshift Linkage"
        },
        "00267": {
            "GenartName": "Control Unit, air suspension",
            "NormTermName": "Control Unit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Suspension"
        },
        "00268": {
            "GenartName": "Sensor, pneumatic suspension level",
            "NormTermName": "Sensor",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Suspension Level"
        },
        "00269": {
            "GenartName": "Directional Control Valve Block, air suspension",
            "NormTermName": "Directional Control Valve Block",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Suspension"
        },
        "00271": {
            "GenartName": "Buffer, engine mount",
            "NormTermName": "Damper",
            "AssGrpName": "Engine Mounting System",
            "UsageName": "Engine Mount"
        },
        "00273": {
            "GenartName": "Control/Trailing Arm, wheel suspension",
            "NormTermName": "Control/Trailing Arm",
            "AssGrpName": "Wheel Suspension",
            "UsageName": null
        },
        "00274": {
            "GenartName": "Stabiliser Bar, suspension",
            "NormTermName": "Stabiliser",
            "AssGrpName": "Wheel Suspension",
            "UsageName": null
        },
        "00276": {
            "GenartName": "Repair Kit, brake master cylinder",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Master Cylinder"
        },
        "00277": {
            "GenartName": "Wheel Brake Cylinder",
            "NormTermName": "Cylinder",
            "AssGrpName": "Braking System",
            "UsageName": "Wheel Brake Cylinder"
        },
        "00278": {
            "GenartName": "Repair Kit, wheel brake cylinder",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Wheel Brake Cylinder"
        },
        "00281": {
            "GenartName": "Brake Shoe",
            "NormTermName": "Brake Shoe",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "00282": {
            "GenartName": "Control Unit, brake / driving dynamics",
            "NormTermName": "Control Unit",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "00283": {
            "GenartName": "Pressure Regulator, drive dynamics",
            "NormTermName": "Regulator",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Drive Dynamics"
        },
        "00284": {
            "GenartName": "Tie Rod",
            "NormTermName": "Rod",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod"
        },
        "00285": {
            "GenartName": "Centre Rod Assembly",
            "NormTermName": "Rod",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "00286": {
            "GenartName": "Steering Gear",
            "NormTermName": "Transmission",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "00287": {
            "GenartName": "Control Unit, traction control system",
            "NormTermName": "Control Unit",
            "AssGrpName": "Drive Dynamics Control",
            "UsageName": "Traction Control System"
        },
        "00288": {
            "GenartName": "Alternator Regulator",
            "NormTermName": "Regulator",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "00289": {
            "GenartName": "Front Fog Light",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "00290": {
            "GenartName": "Tail Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Tail Light"
        },
        "00291": {
            "GenartName": "Rear Fog Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Rear Fog Light"
        },
        "00292": {
            "GenartName": "Reverse Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Reverse Light"
        },
        "00293": {
            "GenartName": "Licence Plate Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Licence Plate Light"
        },
        "00294": {
            "GenartName": "End Outline Marker Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "End Outline Marker Light"
        },
        "00295": {
            "GenartName": "Wiper Motor",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Windscreen Wiper"
        },
        "00297": {
            "GenartName": "Signal Horn",
            "NormTermName": "Signal Horn",
            "AssGrpName": "Signal System",
            "UsageName": null
        },
        "00298": {
            "GenartName": "Wiper Blade",
            "NormTermName": "Wiper Blade",
            "AssGrpName": "Window Cleaning",
            "UsageName": null
        },
        "00299": {
            "GenartName": "Wiper Blade Rubber",
            "NormTermName": "Wiper Blade Rubber",
            "AssGrpName": "Window Cleaning",
            "UsageName": null
        },
        "00300": {
            "GenartName": "Wiper Linkage",
            "NormTermName": "Linkage",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Wiper"
        },
        "00301": {
            "GenartName": "Wiper Arm, window cleaning",
            "NormTermName": "Wiper Arm",
            "AssGrpName": "Window Cleaning",
            "UsageName": null
        },
        "00302": {
            "GenartName": "Wiper Bearing",
            "NormTermName": "Bearing",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": "Wiper"
        },
        "00303": {
            "GenartName": "Washer Fluid Jet, window cleaning",
            "NormTermName": "Nozzle",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washing Water"
        },
        "00304": {
            "GenartName": "V-Belt Set",
            "NormTermName": "Belt Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Belt"
        },
        "00305": {
            "GenartName": "V-Ribbed Belt",
            "NormTermName": "Belt",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt"
        },
        "00306": {
            "GenartName": "Timing Belt",
            "NormTermName": "Belt",
            "AssGrpName": "Belt Drive",
            "UsageName": "Timing Belt"
        },
        "00307": {
            "GenartName": "Timing Belt Kit",
            "NormTermName": "Belt Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": "Timing Belt"
        },
        "00308": {
            "GenartName": "Tensioner Pulley, timing belt",
            "NormTermName": "Pulley",
            "AssGrpName": "Belt Drive",
            "UsageName": "Timing Belt Tension"
        },
        "00310": {
            "GenartName": "Tensioner Pulley, V-ribbed belt",
            "NormTermName": "Pulley",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt Tension"
        },
        "00312": {
            "GenartName": "Deflection/Guide Pulley, V-ribbed belt",
            "NormTermName": "Pulley",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt Guidance/Deflection"
        },
        "00313": {
            "GenartName": "Deflection/Guide Pulley, timing belt",
            "NormTermName": "Pulley",
            "AssGrpName": "Belt Drive",
            "UsageName": "Timing Belt Guidance/Deflection"
        },
        "00314": {
            "GenartName": "Gasket, water pump",
            "NormTermName": "Seal",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "00315": {
            "GenartName": "Gasket Set, water pump",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "00316": {
            "GenartName": "Thermostat, coolant",
            "NormTermName": "Thermostat",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant"
        },
        "00317": {
            "GenartName": "Gasket, thermostat housing",
            "NormTermName": "Seal",
            "AssGrpName": "Cooling System",
            "UsageName": "Thermostat Housing"
        },
        "00318": {
            "GenartName": "Gasket, cylinder head",
            "NormTermName": "Seal",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Engine Block"
        },
        "00319": {
            "GenartName": "Gasket Kit, cylinder head",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Cylinder Head",
            "UsageName": null
        },
        "00321": {
            "GenartName": "Gasket, cylinder head cover",
            "NormTermName": "Seal",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Cylinder Head Cover"
        },
        "00322": {
            "GenartName": "Seal Ring, valve stem",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Valve Stem"
        },
        "00323": {
            "GenartName": "Seal Set, valve stem",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Valve Stem"
        },
        "00324": {
            "GenartName": "Bellow, air suspension",
            "NormTermName": "Bellow",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Suspension"
        },
        "00325": {
            "GenartName": "Operating Device, air suspension",
            "NormTermName": "Operating Device",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Suspension"
        },
        "00326": {
            "GenartName": "Air Suspension Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Suspension"
        },
        "00328": {
            "GenartName": "Pressure Control Valve, lifting system",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Lift System"
        },
        "00331": {
            "GenartName": "Bushing, stabiliser bar",
            "NormTermName": "Bush",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser Mounting"
        },
        "00332": {
            "GenartName": "Compressor, compressed air system",
            "NormTermName": "Compressor",
            "AssGrpName": "Compressed-air System",
            "UsageName": null
        },
        "00333": {
            "GenartName": "Cylinder Head, air compressor",
            "NormTermName": "Cylinder Head",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "00334": {
            "GenartName": "Repair kit, air compressor",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "00335": {
            "GenartName": "Pressure Controller, compressed-air system",
            "NormTermName": "Regulator",
            "AssGrpName": "Compressed-air System",
            "UsageName": null
        },
        "00336": {
            "GenartName": "Antifreeze Pump, compressed-air system",
            "NormTermName": "Pump",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Antifreeze"
        },
        "00337": {
            "GenartName": "Water Separator, compressed-air system",
            "NormTermName": "Trap",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Water"
        },
        "00338": {
            "GenartName": "Air Filter, compressor",
            "NormTermName": "Filter",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air"
        },
        "00339": {
            "GenartName": "Air Dryer, compressed-air system",
            "NormTermName": "Dehumidifier",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air"
        },
        "00340": {
            "GenartName": "Air Dryer Cartridge, compressed-air system",
            "NormTermName": "Cartridge",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Dryer"
        },
        "00341": {
            "GenartName": "Multi-circuit Protection Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Multiple Circuit Protection"
        },
        "00342": {
            "GenartName": "Valve, compressed-air system",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": null
        },
        "00346": {
            "GenartName": "Air Tank, compressed-air system",
            "NormTermName": "Tank",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Compressed Air"
        },
        "00347": {
            "GenartName": "Brake Valve, service brake",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Service Brake"
        },
        "00348": {
            "GenartName": "Control Valve, trailer",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Trailer Control"
        },
        "00350": {
            "GenartName": "Pretensioning Cylinder",
            "NormTermName": "Cylinder",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Pretensioning Cylinder"
        },
        "00354": {
            "GenartName": "Spring-loaded Cylinder",
            "NormTermName": "Cylinder",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Spring Loaded Cylinder"
        },
        "00355": {
            "GenartName": "Brake Valve, parking brake",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Parking Brake"
        },
        "00356": {
            "GenartName": "Brake Valve, trailer",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Trailer Brake"
        },
        "00358": {
            "GenartName": "Overload Protection Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Overload Protection"
        },
        "00359": {
            "GenartName": "Diaphragm Brake Cylinder",
            "NormTermName": "Cylinder",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Diaphragm"
        },
        "00360": {
            "GenartName": "Piston Brake Cylinder",
            "NormTermName": "Cylinder",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Pistons"
        },
        "00361": {
            "GenartName": "Multi-function Brake Cylinder",
            "NormTermName": "Cylinder",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Combination"
        },
        "00362": {
            "GenartName": "Slave Cylinder",
            "NormTermName": "Cylinder",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Slave Cylinder"
        },
        "00363": {
            "GenartName": "Slave Cylinder, door",
            "NormTermName": "Cylinder",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Door"
        },
        "00365": {
            "GenartName": "Alarm Switch, differential pressure",
            "NormTermName": "Switch",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Differential Pressure Warning"
        },
        "00366": {
            "GenartName": "Directional Control Valve",
            "NormTermName": "Flow Control Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": null
        },
        "00367": {
            "GenartName": "Test Connection",
            "NormTermName": "Test Connection",
            "AssGrpName": "Compressed-air System",
            "UsageName": null
        },
        "00368": {
            "GenartName": "Spiral Hose",
            "NormTermName": "Spiral Hose",
            "AssGrpName": "Compressed-air System",
            "UsageName": null
        },
        "00369": {
            "GenartName": "Silencer, compressed-air system",
            "NormTermName": "Damper",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Silencer"
        },
        "00370": {
            "GenartName": "Coupling Head",
            "NormTermName": "Coupling Head",
            "AssGrpName": "Compressed-air System",
            "UsageName": null
        },
        "00371": {
            "GenartName": "Heater Flange, carburettor",
            "NormTermName": "Flange",
            "AssGrpName": "Carburettor",
            "UsageName": "Carburettor Heating"
        },
        "00372": {
            "GenartName": "Heater Flap Box",
            "NormTermName": "Van",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heater Flaps"
        },
        "00373": {
            "GenartName": "Connector, compressed air line",
            "NormTermName": "Connector",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Hoses, compressed air"
        },
        "00374": {
            "GenartName": "Tyre Inflation Connector",
            "NormTermName": "Tyre Inflation Connector",
            "AssGrpName": "Compressed-air System",
            "UsageName": null
        },
        "00377": {
            "GenartName": "Shut-Off Cock",
            "NormTermName": "Shut-Off Cock",
            "AssGrpName": "Compressed-air System",
            "UsageName": null
        },
        "00385": {
            "GenartName": "Suspension Strut, air suspension",
            "NormTermName": "Suspension Strut",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Suspension"
        },
        "00387": {
            "GenartName": "Vacuum Pump, braking system",
            "NormTermName": "Pump",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum"
        },
        "00388": {
            "GenartName": "Eyebolt, tie rod",
            "NormTermName": "Eyebolt",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod"
        },
        "00389": {
            "GenartName": "Rear Panel",
            "NormTermName": "Rear Panel",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "00390": {
            "GenartName": "Hatch Door",
            "NormTermName": "Door",
            "AssGrpName": "Body",
            "UsageName": "Hatch Door"
        },
        "00391": {
            "GenartName": "Combination Rear Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Combination Rear Light"
        },
        "00392": {
            "GenartName": "Housing, combination rear light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights",
            "UsageName": "Combination Rear Light"
        },
        "00394": {
            "GenartName": "Rear Rack",
            "NormTermName": "Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Rear Rack"
        },
        "00395": {
            "GenartName": "Axle Beam",
            "NormTermName": "Axle Beam",
            "AssGrpName": "Wheel Suspension",
            "UsageName": null
        },
        "00396": {
            "GenartName": "Expansion Tank, brake fluid",
            "NormTermName": "Tank",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Fluid"
        },
        "00397": {
            "GenartName": "Expansion Tank, coolant",
            "NormTermName": "Tank",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Water"
        },
        "00399": {
            "GenartName": "Bellow, brake caliper guide",
            "NormTermName": "Bellow",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper Guide"
        },
        "00400": {
            "GenartName": "Thrust Piece, disc brake pad",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Braking System",
            "UsageName": "Disc Brake Pad"
        },
        "00401": {
            "GenartName": "Seal Kit, brake caliper",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "00402": {
            "GenartName": "Brake Pad Set, disc brake",
            "NormTermName": "Brake Pad Set",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "00404": {
            "GenartName": "Clamping Sleeve, disc brake pad",
            "NormTermName": "Sleeve",
            "AssGrpName": "Braking System",
            "UsageName": "Disc Brake Pad"
        },
        "00405": {
            "GenartName": "Repair Kit, brake caliper",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "00406": {
            "GenartName": "Holder, brake disc pad",
            "NormTermName": "Holder",
            "AssGrpName": "Braking System",
            "UsageName": "Disc Brake Pad"
        },
        "00407": {
            "GenartName": "Warning Contact, brake pad wear",
            "NormTermName": "Contact",
            "AssGrpName": "Braking System",
            "UsageName": "Lining Wear"
        },
        "00408": {
            "GenartName": "Rivet, drum brake lining",
            "NormTermName": "Rivet",
            "AssGrpName": "Braking System",
            "UsageName": "Drum Brake Lining"
        },
        "00410": {
            "GenartName": "Brake Shaft, drum brake",
            "NormTermName": "Shaft",
            "AssGrpName": "Braking System",
            "UsageName": "Drum Brake"
        },
        "00412": {
            "GenartName": "Sensor, wheel speed",
            "NormTermName": "Sensor",
            "AssGrpName": "Braking System",
            "UsageName": "Wheel Speed"
        },
        "00414": {
            "GenartName": "Controller, leveling control",
            "NormTermName": "Regulator",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leveling Control"
        },
        "00415": {
            "GenartName": "Hydraulic Unit, braking system",
            "NormTermName": "Hydraulic Unit",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "00416": {
            "GenartName": "Hydraulic Filter, automatic transmission",
            "NormTermName": "Filter",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Hydraulic Oil"
        },
        "00417": {
            "GenartName": "Hydraulic Filter, steering system",
            "NormTermName": "Filter",
            "AssGrpName": "Steering",
            "UsageName": "Hydraulic Oil"
        },
        "00418": {
            "GenartName": "Hydraulic Filter, leveling control",
            "NormTermName": "Filter",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leveling Control"
        },
        "00421": {
            "GenartName": "Inner Wing Panel",
            "NormTermName": "Wing",
            "AssGrpName": "Body",
            "UsageName": "Inner Wing Panel"
        },
        "00423": {
            "GenartName": "Interior Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Interior Light"
        },
        "00424": {
            "GenartName": "Filter, interior air",
            "NormTermName": "Filter",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Air"
        },
        "00426": {
            "GenartName": "Cable Set, front fog light",
            "NormTermName": "Cable Set",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "00427": {
            "GenartName": "Cable Set, spotlight",
            "NormTermName": "Cable Set",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "00428": {
            "GenartName": "Permanent Positive Cable, trailer hitch",
            "NormTermName": "Cable Set",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Permanently Positive"
        },
        "00429": {
            "GenartName": "Catalytic Converter",
            "NormTermName": "Catalytic Converter",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "00430": {
            "GenartName": "Retrofit Kit, catalytic converter",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Catalytic Converter"
        },
        "00431": {
            "GenartName": "Housing, licence plate light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights",
            "UsageName": "Licence Plate Light"
        },
        "00432": {
            "GenartName": "Rocker Arm, engine timing",
            "NormTermName": "Lever",
            "AssGrpName": "Engine Timing",
            "UsageName": "Centre Pivot"
        },
        "00433": {
            "GenartName": "Rocker Arm Shaft, engine timing",
            "NormTermName": "Shaft",
            "AssGrpName": "Engine Timing",
            "UsageName": "Rocker Arm"
        },
        "00435": {
            "GenartName": "Strap Kit, bicycle rack",
            "NormTermName": "Belt Kit",
            "AssGrpName": "Carriers",
            "UsageName": "Bicycle Rack"
        },
        "00436": {
            "GenartName": "Retrofit Kit, air conditioning",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Air Conditioning",
            "UsageName": null
        },
        "00438": {
            "GenartName": "Knuckle Joint",
            "NormTermName": "Joint",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Knuckle Joint"
        },
        "00439": {
            "GenartName": "Body Floor, boot-/cargo area",
            "NormTermName": "Floor Panel",
            "AssGrpName": "Body",
            "UsageName": "Boot-/Cargo Area"
        },
        "00440": {
            "GenartName": "Boot-/Cargo Area Hatch",
            "NormTermName": "Lid",
            "AssGrpName": "Body",
            "UsageName": "Boot-/Cargo Area"
        },
        "00441": {
            "GenartName": "Boot Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Boot Light"
        },
        "00442": {
            "GenartName": "Boot Brace, towbar",
            "NormTermName": "Strut",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Boot"
        },
        "00443": {
            "GenartName": "Piston",
            "NormTermName": "Piston",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": null
        },
        "00444": {
            "GenartName": "Piston Ring Kit",
            "NormTermName": "Ring Kit",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Pistons"
        },
        "00445": {
            "GenartName": "Instrument Cluster",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Instrument Cluster"
        },
        "00446": {
            "GenartName": "Combination Valve, braking system",
            "NormTermName": "Valve",
            "AssGrpName": "Braking System",
            "UsageName": "Combination"
        },
        "00447": {
            "GenartName": "Compressor, air conditioning",
            "NormTermName": "Compressor",
            "AssGrpName": "Air Conditioning",
            "UsageName": null
        },
        "00448": {
            "GenartName": "Condenser, air conditioning",
            "NormTermName": "Condenser",
            "AssGrpName": "Air Conditioning",
            "UsageName": null
        },
        "00449": {
            "GenartName": "Wing",
            "NormTermName": "Wing",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "00450": {
            "GenartName": "Flaring, wing",
            "NormTermName": "Flaring",
            "AssGrpName": "Body",
            "UsageName": "Wing"
        },
        "00451": {
            "GenartName": "Flaring Kit, wing",
            "NormTermName": "Flaring Kit",
            "AssGrpName": "Body",
            "UsageName": "Wing"
        },
        "00453": {
            "GenartName": "Fuel Tank",
            "NormTermName": "Tank",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel"
        },
        "00454": {
            "GenartName": "Fuel Supply Module",
            "NormTermName": "Pump Module",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel"
        },
        "00455": {
            "GenartName": "Gasket, oil sump",
            "NormTermName": "Seal",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Sump"
        },
        "00456": {
            "GenartName": "Gasket Set, oil sump",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Sump"
        },
        "00457": {
            "GenartName": "Clutch Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Hydraulics"
        },
        "00458": {
            "GenartName": "Fuel Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel"
        },
        "00460": {
            "GenartName": "Pump, fuel pre-supply",
            "NormTermName": "Pump",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pre-supply"
        },
        "00461": {
            "GenartName": "Fuel Gauge",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Fuel Supply"
        },
        "00462": {
            "GenartName": "Joint, steering column",
            "NormTermName": "Joint",
            "AssGrpName": "Steering",
            "UsageName": "Steering Column"
        },
        "00464": {
            "GenartName": "Ball Plate, towbar",
            "NormTermName": "Ball Plate",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "00466": {
            "GenartName": "Tow Ball Bracket , towbar",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Ball Rod"
        },
        "00467": {
            "GenartName": "Heat Exchanger, interior heating",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Heating"
        },
        "00468": {
            "GenartName": "Charge Air Cooler",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Air Supply",
            "UsageName": "Charge Air Cooling"
        },
        "00469": {
            "GenartName": "Oil Cooler, engine oil",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Lubrication",
            "UsageName": "Engine Oil"
        },
        "00470": {
            "GenartName": "Radiator, engine cooling",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Cooling System",
            "UsageName": "Engine Cooling"
        },
        "00471": {
            "GenartName": "Evaporator, air conditioning",
            "NormTermName": "Evaporator",
            "AssGrpName": "Air Conditioning",
            "UsageName": null
        },
        "00472": {
            "GenartName": "Radiator Grille",
            "NormTermName": "Grille",
            "AssGrpName": "Body",
            "UsageName": "Radiator"
        },
        "00473": {
            "GenartName": "Holder, radiator grille",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Radiator Grille"
        },
        "00474": {
            "GenartName": "Frame, radiator grille",
            "NormTermName": "Frame",
            "AssGrpName": "Body",
            "UsageName": "Radiator Grille"
        },
        "00475": {
            "GenartName": "Radiator Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator"
        },
        "00476": {
            "GenartName": "Radiator Mounting",
            "NormTermName": "Rack",
            "AssGrpName": "Body",
            "UsageName": "Radiator Rail"
        },
        "00477": {
            "GenartName": "Coupling Ball, towing device",
            "NormTermName": "Coupling Ball",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "00478": {
            "GenartName": "Cable Pull, clutch control",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Control"
        },
        "00479": {
            "GenartName": "Clutch Kit",
            "NormTermName": "Clutch Kit",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "00481": {
            "GenartName": "Main Bearing Cap, crankshaft",
            "NormTermName": "Lid",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft Bearing"
        },
        "00482": {
            "GenartName": "Bracket, axle beam",
            "NormTermName": "Bracket",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Beam"
        },
        "00483": {
            "GenartName": "Bracket, engine mounting system",
            "NormTermName": "Bracket",
            "AssGrpName": "Engine Mounting System",
            "UsageName": null
        },
        "00486": {
            "GenartName": "Bulb Socket, searchlight",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Lights",
            "UsageName": "Searchlight"
        },
        "00487": {
            "GenartName": "Bulb Socket, worklight",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Lights",
            "UsageName": "Worklight"
        },
        "00488": {
            "GenartName": "Bulb Socket, spotlight",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "00489": {
            "GenartName": "Bulb Socket, headlight",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "00490": {
            "GenartName": "Bulb Socket, front fog light",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "00492": {
            "GenartName": "Bulb Holder, direction indicator",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Signal System",
            "UsageName": "Direction Indicator"
        },
        "00493": {
            "GenartName": "Bulb Holder, stop light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Signal System",
            "UsageName": "Stop Light"
        },
        "00494": {
            "GenartName": "Bulb Holder, combination rear light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights",
            "UsageName": "Combination Rear Light"
        },
        "00495": {
            "GenartName": "Bulb Holder, tail light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights",
            "UsageName": "Tail Light"
        },
        "00496": {
            "GenartName": "Bulb Holder, rotating beacon",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Signal System",
            "UsageName": "Rotating Beacon"
        },
        "00498": {
            "GenartName": "Bulb Holder, licence plate light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights",
            "UsageName": "Licence Plate Light"
        },
        "00499": {
            "GenartName": "Bulb Holder, rear fog light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights",
            "UsageName": "Rear Fog Light"
        },
        "00500": {
            "GenartName": "Bulb Holder, reverse light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights",
            "UsageName": "Reverse Light"
        },
        "00502": {
            "GenartName": "Steering Linkage",
            "NormTermName": "Linkage",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "00503": {
            "GenartName": "Steering Arm",
            "NormTermName": "Lever",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "00504": {
            "GenartName": "Steering Spindle",
            "NormTermName": "Spindle",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "00505": {
            "GenartName": "Pitman Arm",
            "NormTermName": "Lever",
            "AssGrpName": "Steering",
            "UsageName": "Steering Column"
        },
        "00506": {
            "GenartName": "Steering Shaft",
            "NormTermName": "Shaft",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "00507": {
            "GenartName": "Idler Arm",
            "NormTermName": "Lever",
            "AssGrpName": "Steering",
            "UsageName": "Intermediate Lever"
        },
        "00508": {
            "GenartName": "Fan, engine cooling",
            "NormTermName": "Fan",
            "AssGrpName": "Cooling System",
            "UsageName": "Engine Cooling"
        },
        "00509": {
            "GenartName": "Clutch, radiator fan",
            "NormTermName": "Coupling",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator Fan"
        },
        "00514": {
            "GenartName": "Gas Spring, bonnet",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Bonnet"
        },
        "00515": {
            "GenartName": "Brake Line",
            "NormTermName": "Pipe",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "00516": {
            "GenartName": "Clutch Lines",
            "NormTermName": "Pipe",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "00518": {
            "GenartName": "Mounting Kit, catalytic converter",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Catalytic Converter"
        },
        "00519": {
            "GenartName": "Mounting Kit, muffler",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Muffler"
        },
        "00522": {
            "GenartName": "Hinge, driver cab",
            "NormTermName": "Joint",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Suspension"
        },
        "00523": {
            "GenartName": "Tilt Cylinder, driver cab",
            "NormTermName": "Cylinder",
            "AssGrpName": "Driver Cab",
            "UsageName": "Centre Pivot"
        },
        "00525": {
            "GenartName": "Door Lock, driver cab",
            "NormTermName": "Locks",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab"
        },
        "00526": {
            "GenartName": "Spring, cab suspension",
            "NormTermName": "Spring",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Suspension"
        },
        "00527": {
            "GenartName": "Shock Absorber, cab suspension",
            "NormTermName": "Damper",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Suspension"
        },
        "00528": {
            "GenartName": "Bellow, driver cab suspension",
            "NormTermName": "Bellow",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Suspension"
        },
        "00529": {
            "GenartName": "Valve, driver cab suspension",
            "NormTermName": "Valve",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Suspension"
        },
        "00530": {
            "GenartName": "Door, body",
            "NormTermName": "Door",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "00531": {
            "GenartName": "Bonnet",
            "NormTermName": "Bonnet",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "00533": {
            "GenartName": "Holder, engine mounting system",
            "NormTermName": "Holder",
            "AssGrpName": "Engine Mounting System",
            "UsageName": null
        },
        "00534": {
            "GenartName": "Hydraulic Filter, clutch",
            "NormTermName": "Filter",
            "AssGrpName": "Clutch",
            "UsageName": "Hydraulic Oil"
        },
        "00535": {
            "GenartName": "Hydraulic Filter, brake fluid",
            "NormTermName": "Filter",
            "AssGrpName": "Braking System",
            "UsageName": "Hydraulic Oil"
        },
        "00536": {
            "GenartName": "Filter, operating hydraulics",
            "NormTermName": "Filter",
            "AssGrpName": "Hydraulic System",
            "UsageName": "Operating Hydraulics"
        },
        "00537": {
            "GenartName": "Filter, windscreen washer fluid",
            "NormTermName": "Filter",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washing Water"
        },
        "00538": {
            "GenartName": "Exhaust Gas Flap, engine brake",
            "NormTermName": "Exhaust Gas Flap",
            "AssGrpName": "Braking System",
            "UsageName": "Engine Brake"
        },
        "00540": {
            "GenartName": "Tensioner Lever, V-ribbed belt",
            "NormTermName": "Tensioner Arm",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt"
        },
        "00541": {
            "GenartName": "Belt Tensioner, V-ribbed belt",
            "NormTermName": "Tensioner",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt"
        },
        "00542": {
            "GenartName": "V-Ribbed Belt Set",
            "NormTermName": "Belt Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt"
        },
        "00544": {
            "GenartName": "Tensioner Lever, timing belt",
            "NormTermName": "Tensioner Arm",
            "AssGrpName": "Belt Drive",
            "UsageName": "Timing Belt"
        },
        "00545": {
            "GenartName": "Tensioner, timing belt",
            "NormTermName": "Tensioner",
            "AssGrpName": "Belt Drive",
            "UsageName": "Timing Belt"
        },
        "00546": {
            "GenartName": "Coolant Pipe",
            "NormTermName": "Pipe",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Water"
        },
        "00547": {
            "GenartName": "Seal, coolant pipe",
            "NormTermName": "Seal",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Pipe"
        },
        "00548": {
            "GenartName": "Cap, radiator",
            "NormTermName": "Closure",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator Cap"
        },
        "00549": {
            "GenartName": "Bolt, radiator cap",
            "NormTermName": "Screw",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator Cap"
        },
        "00550": {
            "GenartName": "High-pressure Switch, air conditioning",
            "NormTermName": "Switch",
            "AssGrpName": "Air Conditioning",
            "UsageName": "High Pressure"
        },
        "00551": {
            "GenartName": "Seal Ring, radiator cap bolt",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator Drain Screw"
        },
        "00552": {
            "GenartName": "Belt Pulley, radiator fan",
            "NormTermName": "Belt Pulley",
            "AssGrpName": "Cooling System",
            "UsageName": "Fan"
        },
        "00553": {
            "GenartName": "Holder, oil cooler",
            "NormTermName": "Holder",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Cooler"
        },
        "00554": {
            "GenartName": "Mounting Bracket, oil cooler",
            "NormTermName": "Bracket",
            "AssGrpName": "Body",
            "UsageName": "Oil Cooler"
        },
        "00555": {
            "GenartName": "Low-pressure Switch, air conditioning",
            "NormTermName": "Switch",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Low Pressure"
        },
        "00556": {
            "GenartName": "Oil Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Lubrication",
            "UsageName": "Engine Oil"
        },
        "00557": {
            "GenartName": "Gasket Set, oil cooler",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Cooler"
        },
        "00558": {
            "GenartName": "Mounting Kit, heat exchanger",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heat Exchanger"
        },
        "00559": {
            "GenartName": "Gasket, heater flap box",
            "NormTermName": "Seal",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heater Flap Box"
        },
        "00560": {
            "GenartName": "Full Gasket Kit, engine",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Crankcase",
            "UsageName": "Engine"
        },
        "00561": {
            "GenartName": "Finger Follower, engine timing",
            "NormTermName": "Lever",
            "AssGrpName": "Engine Timing",
            "UsageName": "End Pivot"
        },
        "00562": {
            "GenartName": "Push Rod",
            "NormTermName": "Rod",
            "AssGrpName": "Engine Timing",
            "UsageName": "Rocker Arm Actuation"
        },
        "00563": {
            "GenartName": "O-Ring, push rod tube",
            "NormTermName": "Seal",
            "AssGrpName": "Engine Timing",
            "UsageName": "Push Rod Tube"
        },
        "00564": {
            "GenartName": "Push Rod Tube",
            "NormTermName": "Tube",
            "AssGrpName": "Engine Timing",
            "UsageName": "Pushrod Protection"
        },
        "00566": {
            "GenartName": "Camshaft",
            "NormTermName": "Shaft",
            "AssGrpName": "Engine Timing",
            "UsageName": "Valve Control"
        },
        "00567": {
            "GenartName": "Camshaft Kit",
            "NormTermName": "Shaft Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": "Valve Control"
        },
        "00568": {
            "GenartName": "Gasket, timing case",
            "NormTermName": "Seal",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Case"
        },
        "00569": {
            "GenartName": "Gasket, timing case cover",
            "NormTermName": "Seal",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Case Cover"
        },
        "00570": {
            "GenartName": "Gasket Kit, timing case",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Case"
        },
        "00571": {
            "GenartName": "Tensioner, timing chain",
            "NormTermName": "Tensioner",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain"
        },
        "00572": {
            "GenartName": "Shaft Seal, crankshaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft"
        },
        "00574": {
            "GenartName": "Crankshaft Bearing",
            "NormTermName": "Bearing Shell",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft"
        },
        "00577": {
            "GenartName": "Flywheel",
            "NormTermName": "Flywheel",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": null
        },
        "00578": {
            "GenartName": "Ring Gear, flywheel",
            "NormTermName": "Ring Gear",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Flywheel"
        },
        "00579": {
            "GenartName": "Pilot Bearing, clutch",
            "NormTermName": "Bearing",
            "AssGrpName": "Clutch",
            "UsageName": "Guide"
        },
        "00580": {
            "GenartName": "Gear, balance shaft",
            "NormTermName": "Gear",
            "AssGrpName": "Engine Timing",
            "UsageName": "Balance Shaft"
        },
        "00581": {
            "GenartName": "Connecting Rod",
            "NormTermName": "Connecting Rod",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": null
        },
        "00582": {
            "GenartName": "Connecting Rod Bearing",
            "NormTermName": "Bearing Shell",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Connecting Rod"
        },
        "00583": {
            "GenartName": "Bearing Cap, connecting rod",
            "NormTermName": "Lid",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Connecting Rod Bearing"
        },
        "00584": {
            "GenartName": "Small End Bushes, connecting rod",
            "NormTermName": "Bush",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Connecting Rod Bearing"
        },
        "00585": {
            "GenartName": "Sensor, longitudinal acceleration",
            "NormTermName": "Sensor",
            "AssGrpName": "Drive Dynamics Control",
            "UsageName": "Longitudinal Acceleration"
        },
        "00586": {
            "GenartName": "Sensor, transverse acceleration",
            "NormTermName": "Sensor",
            "AssGrpName": "Drive Dynamics Control",
            "UsageName": "Transverse Acceleration"
        },
        "00587": {
            "GenartName": "Piston Pin, crankshaft drive",
            "NormTermName": "Bolt",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Pistons"
        },
        "00588": {
            "GenartName": "Circlip, piston pin",
            "NormTermName": "Fuse",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Piston Pin"
        },
        "00589": {
            "GenartName": "Cylinder Sleeve",
            "NormTermName": "Bush",
            "AssGrpName": "Crankcase",
            "UsageName": "Cylinder Sleeve"
        },
        "00590": {
            "GenartName": "Housing, oil filter",
            "NormTermName": "Housing",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Filter"
        },
        "00591": {
            "GenartName": "Gasket, oil filter housing",
            "NormTermName": "Seal",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Filter Housing"
        },
        "00592": {
            "GenartName": "Oil Sump",
            "NormTermName": "Oil Sump",
            "AssGrpName": "Lubrication",
            "UsageName": null
        },
        "00593": {
            "GenartName": "Screw Plug, oil sump",
            "NormTermName": "Screw",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Drain"
        },
        "00594": {
            "GenartName": "Suction Pipe, oil pump",
            "NormTermName": "Tube",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "00595": {
            "GenartName": "Strainer, oil pump suction pipe",
            "NormTermName": "Strainer",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump Suction Pipe"
        },
        "00596": {
            "GenartName": "Oil Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Lubrication",
            "UsageName": "Engine Oil"
        },
        "00597": {
            "GenartName": "Sealing Cap, oil filler neck",
            "NormTermName": "Closure",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Oil Filler Neck"
        },
        "00598": {
            "GenartName": "Seal, oil filler neck cap",
            "NormTermName": "Seal",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Oil Filler Neck"
        },
        "00599": {
            "GenartName": "Oil Dipstick",
            "NormTermName": "Dipstick",
            "AssGrpName": "Lubrication",
            "UsageName": null
        },
        "00600": {
            "GenartName": "Housing, fuel filter",
            "NormTermName": "Housing",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Filter"
        },
        "00601": {
            "GenartName": "Steel Strap, fuel tank",
            "NormTermName": "Strap",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank"
        },
        "00602": {
            "GenartName": "Cap, fuel tank",
            "NormTermName": "Closure",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank"
        },
        "00603": {
            "GenartName": "Fuel Drain Plug",
            "NormTermName": "Screw",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Drain"
        },
        "00604": {
            "GenartName": "Seal Ring, fuel drain plug",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Drain Plug"
        },
        "00605": {
            "GenartName": "Sender Unit, fuel tank",
            "NormTermName": "Sensor",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Supply"
        },
        "00606": {
            "GenartName": "Seal, fuel tank cap",
            "NormTermName": "Seal",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank Cap"
        },
        "00607": {
            "GenartName": "Filler Pipe, fuel tank",
            "NormTermName": "Filler Neck",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank"
        },
        "00608": {
            "GenartName": "Tilt Pump, driver cab",
            "NormTermName": "Pump",
            "AssGrpName": "Hydraulic System",
            "UsageName": "Driver Cab"
        },
        "00609": {
            "GenartName": "Fuel Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Fuel Supply System",
            "UsageName": null
        },
        "00610": {
            "GenartName": "Fuel Line",
            "NormTermName": "Pipe",
            "AssGrpName": "Fuel Supply System",
            "UsageName": null
        },
        "00611": {
            "GenartName": "Seal, fuel line",
            "NormTermName": "Seal",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Line"
        },
        "00612": {
            "GenartName": "Hose Connector, fuel hose",
            "NormTermName": "Connector",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Hose"
        },
        "00613": {
            "GenartName": "Repair Kit, fuel pump",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "00615": {
            "GenartName": "Repair Kit, hand feed pump",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Hand Feed Pump"
        },
        "00616": {
            "GenartName": "Inspection Glass, hand feed pump",
            "NormTermName": "Inspection Glass",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Hand Feed Pump"
        },
        "00618": {
            "GenartName": "Accelerator Cable",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Air Supply",
            "UsageName": "Throttle Valve"
        },
        "00619": {
            "GenartName": "Repair Kit, clutch release bearing",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Release Bearing"
        },
        "00620": {
            "GenartName": "Slave Cylinder, clutch",
            "NormTermName": "Cylinder",
            "AssGrpName": "Clutch",
            "UsageName": "Slave Cylinder"
        },
        "00621": {
            "GenartName": "Repair Kit, clutch master cylinder",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Clutch",
            "UsageName": "Master Cylinder"
        },
        "00622": {
            "GenartName": "Repair Kit, clutch slave cylinder",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Clutch",
            "UsageName": "Slave Cylinder"
        },
        "00623": {
            "GenartName": "Gasket Set, manual transmission",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "00624": {
            "GenartName": "Shaft Seal, manual transmission",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Shaft"
        },
        "00625": {
            "GenartName": "Gasket Set, automatic transmission",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "00626": {
            "GenartName": "Shaft Seal, automatic transmission",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Transmission Shaft"
        },
        "00627": {
            "GenartName": "Retarder",
            "NormTermName": "Retarder",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder Brake System"
        },
        "00628": {
            "GenartName": "Gasket, differential",
            "NormTermName": "Seal",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "00629": {
            "GenartName": "Gasket Set, differential",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "00630": {
            "GenartName": "Shaft Seal, differential",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "00631": {
            "GenartName": "Gasket, transfer case",
            "NormTermName": "Seal",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transfer Case"
        },
        "00632": {
            "GenartName": "Gasket Set, transfer case",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transfer Case"
        },
        "00633": {
            "GenartName": "Shaft Seal, transfer case",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transfer Gear Shaft"
        },
        "00634": {
            "GenartName": "Gasket, power take-off",
            "NormTermName": "Seal",
            "AssGrpName": "Power Take-Off",
            "UsageName": null
        },
        "00635": {
            "GenartName": "Gasket Set, power take-off",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Power Take-Off",
            "UsageName": null
        },
        "00636": {
            "GenartName": "Shaft Seal, power take-off",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Power Take-Off",
            "UsageName": null
        },
        "00637": {
            "GenartName": "Spring Pack",
            "NormTermName": "Spring Pack",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "00638": {
            "GenartName": "Spring Leaf",
            "NormTermName": "Spring Leaf",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "00639": {
            "GenartName": "Pipe",
            "NormTermName": "Pipe",
            "AssGrpName": "Compressed-air System",
            "UsageName": null
        },
        "00643": {
            "GenartName": "Repair Kit, spring bolt",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bolt"
        },
        "00644": {
            "GenartName": "Spring Bolt",
            "NormTermName": "Bolt",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bolt"
        },
        "00645": {
            "GenartName": "Bushing, leaf spring",
            "NormTermName": "Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leaf Spring Mounting"
        },
        "00646": {
            "GenartName": "Spring Shackle",
            "NormTermName": "Spring Shackle",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "00647": {
            "GenartName": "Bush, spring shackle",
            "NormTermName": "Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Shackle"
        },
        "00648": {
            "GenartName": "Spring Bracket",
            "NormTermName": "Spring Bracket",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "00649": {
            "GenartName": "Spring Clamp",
            "NormTermName": "Spring Clamp",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "00650": {
            "GenartName": "Centering Ring, leaf spring",
            "NormTermName": "Centering Ring",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leaf Spring"
        },
        "00651": {
            "GenartName": "Central Bolt",
            "NormTermName": "Bolt",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leaf Spring"
        },
        "00652": {
            "GenartName": "Repair Kit, kingpin",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Kingppin"
        },
        "00653": {
            "GenartName": "Wheel Hub",
            "NormTermName": "Wheel Hub",
            "AssGrpName": "Wheel Suspension",
            "UsageName": null
        },
        "00654": {
            "GenartName": "Wheel Bearing Kit",
            "NormTermName": "Bearing Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Hub"
        },
        "00655": {
            "GenartName": "Wheel Bearing",
            "NormTermName": "Bearing",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Hub"
        },
        "00656": {
            "GenartName": "Shaft Seal, wheel hub",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Hub"
        },
        "00657": {
            "GenartName": "Wheel Stud",
            "NormTermName": "Bolt",
            "AssGrpName": "Wheels",
            "UsageName": "Wheel"
        },
        "00658": {
            "GenartName": "Wheel Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Wheels",
            "UsageName": "Wheel"
        },
        "00659": {
            "GenartName": "Retaining Ring, wheel rim",
            "NormTermName": "Retainer Ring",
            "AssGrpName": "Wheels",
            "UsageName": "Rim"
        },
        "00660": {
            "GenartName": "Centering Ring, rim",
            "NormTermName": "Centering Ring",
            "AssGrpName": "Wheels",
            "UsageName": "Rim"
        },
        "00661": {
            "GenartName": "Rim",
            "NormTermName": "Rim",
            "AssGrpName": "Wheels",
            "UsageName": null
        },
        "00662": {
            "GenartName": "Repair Kit, brake booster",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Booster"
        },
        "00663": {
            "GenartName": "Valve, brake booster",
            "NormTermName": "Valve",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Booster"
        },
        "00664": {
            "GenartName": "Gasket, vacuum pump piston",
            "NormTermName": "Seal",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum Pump Piston"
        },
        "00665": {
            "GenartName": "Valve, vacuum pump",
            "NormTermName": "Valve",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum Pump"
        },
        "00666": {
            "GenartName": "Gasket, vacuum pump",
            "NormTermName": "Seal",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum Pump"
        },
        "00667": {
            "GenartName": "Vacuum Hose, braking system",
            "NormTermName": "Hose Line",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum"
        },
        "00668": {
            "GenartName": "Sensor, brake fluid level",
            "NormTermName": "Sensor",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Fluid Reserve"
        },
        "00669": {
            "GenartName": "Brake Line Set",
            "NormTermName": "Pipe Set",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "00671": {
            "GenartName": "Parking Socket",
            "NormTermName": "Socket",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "00672": {
            "GenartName": "Connecting Cable, ABS",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Braking System",
            "UsageName": "ABS"
        },
        "00673": {
            "GenartName": "Repair Kit, reversing lever",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Steering",
            "UsageName": "Bell Crank"
        },
        "00676": {
            "GenartName": "Joint, steering shaft",
            "NormTermName": "Joint",
            "AssGrpName": "Steering",
            "UsageName": "Steering Shaft"
        },
        "00677": {
            "GenartName": "Hydraulic Hose, steering system",
            "NormTermName": "Hose Line",
            "AssGrpName": "Steering",
            "UsageName": "Steering Hydraulics"
        },
        "00678": {
            "GenartName": "Control Unit, power steering",
            "NormTermName": "Control Unit",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering"
        },
        "00679": {
            "GenartName": "Valve, power steering",
            "NormTermName": "Valve",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering"
        },
        "00680": {
            "GenartName": "Gasket, steering gear",
            "NormTermName": "Seal",
            "AssGrpName": "Steering",
            "UsageName": "Steering Gear"
        },
        "00681": {
            "GenartName": "Gasket Set, steering gear",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Steering",
            "UsageName": "Steering Gear"
        },
        "00682": {
            "GenartName": "Solenoid Switch, starter",
            "NormTermName": "Switch",
            "AssGrpName": "Starter System",
            "UsageName": "Solenoid Switch"
        },
        "00683": {
            "GenartName": "Distributor, ignition",
            "NormTermName": "Distributor",
            "AssGrpName": "Ignition System",
            "UsageName": null
        },
        "00684": {
            "GenartName": "Ignition Cable",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Ignition System",
            "UsageName": null
        },
        "00685": {
            "GenartName": "Ignition Cable Kit",
            "NormTermName": "Electric Cable Set",
            "AssGrpName": "Ignition System",
            "UsageName": null
        },
        "00686": {
            "GenartName": "Spark Plug",
            "NormTermName": "Spark Plug",
            "AssGrpName": "Ignition System",
            "UsageName": null
        },
        "00688": {
            "GenartName": "Contact Breaker, distributor",
            "NormTermName": "Contact Breaker Set",
            "AssGrpName": "Ignition System",
            "UsageName": "Distributor"
        },
        "00689": {
            "GenartName": "Ignition Coil",
            "NormTermName": "Ignition Coil",
            "AssGrpName": "Ignition System",
            "UsageName": null
        },
        "00690": {
            "GenartName": "Capaciter, ignition",
            "NormTermName": "Condenser",
            "AssGrpName": "Ignition System",
            "UsageName": null
        },
        "00691": {
            "GenartName": "Rotor, distributor",
            "NormTermName": "Rotor, distributor",
            "AssGrpName": "Ignition System",
            "UsageName": "Distributor"
        },
        "00692": {
            "GenartName": "Distributor Cap",
            "NormTermName": "Cap",
            "AssGrpName": "Ignition System",
            "UsageName": "Distributor"
        },
        "00694": {
            "GenartName": "Holder, rotor",
            "NormTermName": "Holder",
            "AssGrpName": "Ignition System",
            "UsageName": "Rotor, distributor"
        },
        "00695": {
            "GenartName": "Plug, distributor",
            "NormTermName": "Plug",
            "AssGrpName": "Ignition System",
            "UsageName": "Distributor"
        },
        "00696": {
            "GenartName": "Plug, spark plug",
            "NormTermName": "Plug",
            "AssGrpName": "Ignition System",
            "UsageName": "Spark Plug"
        },
        "00698": {
            "GenartName": "Plug, coil",
            "NormTermName": "Plug",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition Coil"
        },
        "00699": {
            "GenartName": "Control Unit, glow time",
            "NormTermName": "Control Unit",
            "AssGrpName": "Glow Ignition System",
            "UsageName": "Glow Time"
        },
        "00700": {
            "GenartName": "Actuator, headlight levelling",
            "NormTermName": "Actuator",
            "AssGrpName": "Lights",
            "UsageName": "Headlight Levelling"
        },
        "00702": {
            "GenartName": "Reflector, headlight",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "00703": {
            "GenartName": "Frame, headlight",
            "NormTermName": "Frame",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "00704": {
            "GenartName": "Cable Set, headlight",
            "NormTermName": "Cable Set",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "00705": {
            "GenartName": "Housing, headlight",
            "NormTermName": "Housing",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "00706": {
            "GenartName": "Series Resistor, ignition system",
            "NormTermName": "Resistor",
            "AssGrpName": "Ignition System",
            "UsageName": "Series Resistor"
        },
        "00707": {
            "GenartName": "Glow Indicator",
            "NormTermName": "Glow Indicator",
            "AssGrpName": "Glow Ignition System",
            "UsageName": null
        },
        "00708": {
            "GenartName": "Retrofit Kit, quick-start glow plug system",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Glow Ignition System",
            "UsageName": "Quick Glow System"
        },
        "00709": {
            "GenartName": "Series Resistor, glow plug system",
            "NormTermName": "Resistor",
            "AssGrpName": "Glow Ignition System",
            "UsageName": "Series Resistor"
        },
        "00710": {
            "GenartName": "Reflector, front fog light",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "00712": {
            "GenartName": "Frame, front fog light",
            "NormTermName": "Frame",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "00713": {
            "GenartName": "Reflector, spotlight",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "00715": {
            "GenartName": "Frame, spotlight",
            "NormTermName": "Frame",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "00716": {
            "GenartName": "Housing, spotlight",
            "NormTermName": "Housing",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "00717": {
            "GenartName": "Searchlight",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights",
            "UsageName": "Searchlight"
        },
        "00718": {
            "GenartName": "Reflector, searchlight",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights",
            "UsageName": "Searchlight"
        },
        "00720": {
            "GenartName": "Frame, searchlight",
            "NormTermName": "Frame",
            "AssGrpName": "Lights",
            "UsageName": "Searchlight"
        },
        "00721": {
            "GenartName": "Cable Set, searchlight",
            "NormTermName": "Cable Set",
            "AssGrpName": "Lights",
            "UsageName": "Searchlight"
        },
        "00722": {
            "GenartName": "Cover, searchlight",
            "NormTermName": "Cover",
            "AssGrpName": "Lights",
            "UsageName": "Searchlight"
        },
        "00723": {
            "GenartName": "Housing, searchlight",
            "NormTermName": "Housing",
            "AssGrpName": "Lights",
            "UsageName": "Searchlight"
        },
        "00724": {
            "GenartName": "Worklight",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights",
            "UsageName": "Worklight"
        },
        "00725": {
            "GenartName": "Reflector, worklight",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights",
            "UsageName": "Worklight"
        },
        "00727": {
            "GenartName": "Frame, worklight",
            "NormTermName": "Frame",
            "AssGrpName": "Lights",
            "UsageName": "Worklight"
        },
        "00728": {
            "GenartName": "Cable Set, worklight",
            "NormTermName": "Cable Set",
            "AssGrpName": "Lights",
            "UsageName": "Worklight"
        },
        "00729": {
            "GenartName": "Cover, worklight",
            "NormTermName": "Cover",
            "AssGrpName": "Lights",
            "UsageName": "Worklight"
        },
        "00730": {
            "GenartName": "Housing, worklight",
            "NormTermName": "Housing",
            "AssGrpName": "Lights",
            "UsageName": "Worklight"
        },
        "00731": {
            "GenartName": "Bolt, exhaust system",
            "NormTermName": "Screw",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "00734": {
            "GenartName": "Mounting Kit, towbar",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "00737": {
            "GenartName": "Mounting Set, roof rack",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Carriers",
            "UsageName": "Roof Rack"
        },
        "00738": {
            "GenartName": "Mounting Kit, fuel tank",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank"
        },
        "00740": {
            "GenartName": "Hinge, bonnet",
            "NormTermName": "Hinge",
            "AssGrpName": "Body",
            "UsageName": "Bonnet"
        },
        "00743": {
            "GenartName": "Adjuster, drum brake",
            "NormTermName": "Adjuster",
            "AssGrpName": "Braking System",
            "UsageName": "Drum Brake"
        },
        "00744": {
            "GenartName": "Housing, rear fog light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights",
            "UsageName": "Rear Fog Light"
        },
        "00746": {
            "GenartName": "Licence Plate Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Licence Plate"
        },
        "00751": {
            "GenartName": "Control Unit, wipe-/wash interval",
            "NormTermName": "Regulator",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Wipe-/Wash Interval"
        },
        "00752": {
            "GenartName": "Control, instrument lighting",
            "NormTermName": "Regulator",
            "AssGrpName": "Lights",
            "UsageName": "Instrument Lighting"
        },
        "00753": {
            "GenartName": "Pulsation Damper, fuel supply system",
            "NormTermName": "Damper",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pulsation"
        },
        "00754": {
            "GenartName": "Cross Member",
            "NormTermName": "Rack",
            "AssGrpName": "Body",
            "UsageName": "Cross Member"
        },
        "00755": {
            "GenartName": "Wheelhouse",
            "NormTermName": "Wheelhouse",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "00757": {
            "GenartName": "Shaft Seal, camshaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft"
        },
        "00758": {
            "GenartName": "Vacuum Control Valve, EGR",
            "NormTermName": "Valve",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "Vacuum Control"
        },
        "00761": {
            "GenartName": "Boost Pressure Control Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Air Supply",
            "UsageName": "Boost Pressure Control"
        },
        "00762": {
            "GenartName": "Relay, ABS",
            "NormTermName": "Relay",
            "AssGrpName": "Braking System",
            "UsageName": "ABS"
        },
        "00763": {
            "GenartName": "Relay, immobilizer",
            "NormTermName": "Relay",
            "AssGrpName": "Starter System",
            "UsageName": "Starter Interlock"
        },
        "00764": {
            "GenartName": "Time-delay Relay, towing device",
            "NormTermName": "Relay",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Timer"
        },
        "00765": {
            "GenartName": "Spare Wheel Well",
            "NormTermName": "Floor Panel",
            "AssGrpName": "Body",
            "UsageName": "Spare Wheel Well"
        },
        "00768": {
            "GenartName": "Repair Kit, windscreen washer-fluid jet",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Windscreen Washer Fluid Jet"
        },
        "00769": {
            "GenartName": "Repair Kit, guide strut",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Guide Strut"
        },
        "00770": {
            "GenartName": "Repair Kit, tie rod",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod"
        },
        "00771": {
            "GenartName": "Repair Kit, wheel suspension",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": null
        },
        "00772": {
            "GenartName": "Repair Kit, control arm",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Control Arm"
        },
        "00773": {
            "GenartName": "Repair Kit, tie rod end",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod End"
        },
        "00774": {
            "GenartName": "Repair Kit, tie rod ball studs",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod Ball Studs"
        },
        "00775": {
            "GenartName": "Repair Kit, kingpin",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Steering",
            "UsageName": "Pivot Pin"
        },
        "00776": {
            "GenartName": "Repair Kit, steering knuckle",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Steering Knuckle"
        },
        "00780": {
            "GenartName": "Repair Kit, guide bolt",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Guide Bolt"
        },
        "00781": {
            "GenartName": "Repair Kit, automatic adjustment",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Automatic Adjustment"
        },
        "00782": {
            "GenartName": "Repair Kit, brake force regulator",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Force Regulator"
        },
        "00783": {
            "GenartName": "Repair Kit, expander",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Expander"
        },
        "00784": {
            "GenartName": "Taillight Cover",
            "NormTermName": "Cover",
            "AssGrpName": "Lights",
            "UsageName": "Combination Rear Light"
        },
        "00785": {
            "GenartName": "Holder, rear fog light",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Rear Fog Light"
        },
        "00786": {
            "GenartName": "Rotating Beacon",
            "NormTermName": "Light",
            "AssGrpName": "Signal System",
            "UsageName": "Rotating Beacon"
        },
        "00787": {
            "GenartName": "Bracket, wiper motor",
            "NormTermName": "Holder",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Wiper Motor"
        },
        "00788": {
            "GenartName": "Electric Motor, sunroof",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Sunroof"
        },
        "00789": {
            "GenartName": "Control Element, exterior mirror",
            "NormTermName": "Actuator",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Exterior Mirror"
        },
        "00790": {
            "GenartName": "Electric Motor, seat adjustment",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Seat Adjustment"
        },
        "00791": {
            "GenartName": "Actuator, central locking system",
            "NormTermName": "Actuator",
            "AssGrpName": "Lock System",
            "UsageName": "Central Locking System"
        },
        "00792": {
            "GenartName": "Electric Motor, radiator fan",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Cooling System",
            "UsageName": "Fan"
        },
        "00793": {
            "GenartName": "Electric Motor, interior blower",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Blower"
        },
        "00794": {
            "GenartName": "Washer Fluid Pump, window cleaning",
            "NormTermName": "Pump",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washing Water"
        },
        "00795": {
            "GenartName": "Washer Fluid Pump, headlight cleaning",
            "NormTermName": "Pump",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": "Washing Water"
        },
        "00796": {
            "GenartName": "Relay, wipe/wash interval",
            "NormTermName": "Relay",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Wipe-/Wash Interval"
        },
        "00797": {
            "GenartName": "Relay, cold start control",
            "NormTermName": "Relay",
            "AssGrpName": "Carburettor",
            "UsageName": "Cold-start Control"
        },
        "00799": {
            "GenartName": "Battery Relay",
            "NormTermName": "Relay",
            "AssGrpName": "Battery",
            "UsageName": null
        },
        "00800": {
            "GenartName": "Relay, main current",
            "NormTermName": "Relay",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Main Current"
        },
        "00802": {
            "GenartName": "Hazard Warning Light Relay",
            "NormTermName": "Relay",
            "AssGrpName": "Signal System",
            "UsageName": "Hazard Warning Light"
        },
        "00803": {
            "GenartName": "Relay, air conditioning",
            "NormTermName": "Relay",
            "AssGrpName": "Air Conditioning",
            "UsageName": null
        },
        "00804": {
            "GenartName": "Vacuum Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Vacuum"
        },
        "00805": {
            "GenartName": "Oil Pressure Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pressure"
        },
        "00806": {
            "GenartName": "Stop Light Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Signal System",
            "UsageName": "Stop Light"
        },
        "00807": {
            "GenartName": "Switch, reverse light",
            "NormTermName": "Switch",
            "AssGrpName": "Lights",
            "UsageName": "Reverse Light"
        },
        "00808": {
            "GenartName": "Switch, door contact",
            "NormTermName": "Switch",
            "AssGrpName": "Lights",
            "UsageName": "Door Light Contact"
        },
        "00809": {
            "GenartName": "Switch, headlight",
            "NormTermName": "Switch",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "00810": {
            "GenartName": "Switch, fog light",
            "NormTermName": "Switch",
            "AssGrpName": "Lights",
            "UsageName": "Fog Light"
        },
        "00811": {
            "GenartName": "Switch, interior light",
            "NormTermName": "Switch",
            "AssGrpName": "Lights",
            "UsageName": "Interior Light"
        },
        "00812": {
            "GenartName": "Direction Indicator Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Signal System",
            "UsageName": "Indicator"
        },
        "00813": {
            "GenartName": "Ignition Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Starter System",
            "UsageName": "Ignition / Starter"
        },
        "00814": {
            "GenartName": "Control Unit, interior light",
            "NormTermName": "Regulator",
            "AssGrpName": "Lights",
            "UsageName": "Interior Light"
        },
        "00815": {
            "GenartName": "Control, headlight levelling",
            "NormTermName": "Regulator",
            "AssGrpName": "Lights",
            "UsageName": "Headlight Levelling"
        },
        "00816": {
            "GenartName": "Hazard Warning Light Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Signal System",
            "UsageName": "Hazard Warning Light"
        },
        "00817": {
            "GenartName": "Switch, fuel pressure",
            "NormTermName": "Switch",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pressure"
        },
        "00818": {
            "GenartName": "Fuse",
            "NormTermName": "Fuse",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "00819": {
            "GenartName": "Fuse Kit",
            "NormTermName": "Fuse Kit",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "00820": {
            "GenartName": "Fuse Box",
            "NormTermName": "Fuse Box",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "00821": {
            "GenartName": "Electric Cable",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "00822": {
            "GenartName": "Cable Connector",
            "NormTermName": "Connector",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "00823": {
            "GenartName": "Cable Connector Kit",
            "NormTermName": "Connector Kit",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "00824": {
            "GenartName": "Plug",
            "NormTermName": "Plug",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "00825": {
            "GenartName": "Socket",
            "NormTermName": "Socket",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "00826": {
            "GenartName": "Tachograph",
            "NormTermName": "Tachograph",
            "AssGrpName": "Instruments, universal",
            "UsageName": null
        },
        "00827": {
            "GenartName": "Tachograph Disc",
            "NormTermName": "Tachograph Disc",
            "AssGrpName": "Instruments, universal",
            "UsageName": null
        },
        "00828": {
            "GenartName": "Relay, alarm system",
            "NormTermName": "Relay",
            "AssGrpName": "Safety Systems",
            "UsageName": "Alarm System"
        },
        "00829": {
            "GenartName": "Sensor, oil temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Temperature"
        },
        "00830": {
            "GenartName": "Sensor, coolant temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Temperature"
        },
        "00832": {
            "GenartName": "Sensor, interior temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Interior Temperature"
        },
        "00833": {
            "GenartName": "Sensor, crankshaft pulse",
            "NormTermName": "Sensor",
            "AssGrpName": "Ignition System",
            "UsageName": "Crankshaft"
        },
        "00834": {
            "GenartName": "Sensor, ignition pulse",
            "NormTermName": "Sensor",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition Pulse"
        },
        "00836": {
            "GenartName": "Wiper Blade, headlight cleaning",
            "NormTermName": "Wiper Blade",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": null
        },
        "00837": {
            "GenartName": "Washer Fluid Reservoir, window cleaning",
            "NormTermName": "Tank",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washing Water"
        },
        "00838": {
            "GenartName": "Pipe, window cleaning",
            "NormTermName": "Pipe",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washing Water"
        },
        "00839": {
            "GenartName": "Connector, washer-fluid pipe",
            "NormTermName": "Connector",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washer Fluid Pipe"
        },
        "00840": {
            "GenartName": "Valve, washer-fluid pipe",
            "NormTermName": "Valve",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washer Fluid Pipe"
        },
        "00841": {
            "GenartName": "Bellow, wiper linkage",
            "NormTermName": "Bellow",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Wiper Linkage"
        },
        "00842": {
            "GenartName": "Cap, wiper arm",
            "NormTermName": "Cap",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Wiper Arm"
        },
        "00843": {
            "GenartName": "Wiper Arm, headlight cleaning",
            "NormTermName": "Wiper Arm",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": null
        },
        "00844": {
            "GenartName": "Air Deflector, driver cab",
            "NormTermName": "Spoiler",
            "AssGrpName": "Driver Cab",
            "UsageName": "Air Deflector"
        },
        "00845": {
            "GenartName": "Spoiler",
            "NormTermName": "Spoiler",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "00846": {
            "GenartName": "Gas Spring, air deflector",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Driver Cab",
            "UsageName": "Air Deflector"
        },
        "00847": {
            "GenartName": "Ramp Mirror",
            "NormTermName": "Mirror",
            "AssGrpName": "Driver Cab",
            "UsageName": "Ramp"
        },
        "00848": {
            "GenartName": "Holder, exterior mirror",
            "NormTermName": "Holder",
            "AssGrpName": "Driver Cab",
            "UsageName": "Exterior Mirror"
        },
        "00849": {
            "GenartName": "Interior Mirror",
            "NormTermName": "Mirror",
            "AssGrpName": "Driver Cab",
            "UsageName": "Inside"
        },
        "00851": {
            "GenartName": "Dryer, air conditioning",
            "NormTermName": "Dehumidifier",
            "AssGrpName": "Air Conditioning",
            "UsageName": null
        },
        "00852": {
            "GenartName": "Rotary Sleeve Valve, compressed-air system",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Leveling Control"
        },
        "00853": {
            "GenartName": "Connecting Rod Bolt",
            "NormTermName": "Screw",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Connecting Rod"
        },
        "00854": {
            "GenartName": "Shock Absorber",
            "NormTermName": "Damper",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "00855": {
            "GenartName": "Housing, front fog light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "00856": {
            "GenartName": "Cover, spotlight",
            "NormTermName": "Cover",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "00857": {
            "GenartName": "Housing, tail light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights",
            "UsageName": "Tail Light"
        },
        "00858": {
            "GenartName": "Housing, reverse light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights",
            "UsageName": "Reverse Light"
        },
        "00859": {
            "GenartName": "Intake Manifold, air supply",
            "NormTermName": "Tube",
            "AssGrpName": "Air Supply",
            "UsageName": "Intake Manifold"
        },
        "00861": {
            "GenartName": "Roller, rear rack",
            "NormTermName": "Pulley",
            "AssGrpName": "Carriers",
            "UsageName": "Rear Rack"
        },
        "00862": {
            "GenartName": "Valve, fuel pump",
            "NormTermName": "Valve",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "00863": {
            "GenartName": "Valve, secondary air pump system",
            "NormTermName": "Valve",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": "Secondary Air Pump System"
        },
        "00864": {
            "GenartName": "Switch, door lock system",
            "NormTermName": "Switch",
            "AssGrpName": "Lock System",
            "UsageName": "Door Latch System"
        },
        "00865": {
            "GenartName": "Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "00870": {
            "GenartName": "RPM Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Instruments",
            "UsageName": "RPM"
        },
        "00871": {
            "GenartName": "Switch Housing",
            "NormTermName": "Housing",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Switch"
        },
        "00872": {
            "GenartName": "Switch Holder",
            "NormTermName": "Switch Holder",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "00873": {
            "GenartName": "Switch Handle",
            "NormTermName": "Handle",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Switch"
        },
        "00874": {
            "GenartName": "Switch Lever",
            "NormTermName": "Lever",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Switch"
        },
        "00875": {
            "GenartName": "Switch Cover",
            "NormTermName": "Cover",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Switch"
        },
        "00877": {
            "GenartName": "Wiper Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Window Cleaning",
            "UsageName": null
        },
        "00878": {
            "GenartName": "Switch, cruise control",
            "NormTermName": "Switch",
            "AssGrpName": "Cruise Control",
            "UsageName": null
        },
        "00879": {
            "GenartName": "Window Cleaning System",
            "NormTermName": "Wiper System",
            "AssGrpName": "Window Cleaning",
            "UsageName": null
        },
        "00882": {
            "GenartName": "Headlight Trim",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body",
            "UsageName": "Headlight"
        },
        "00887": {
            "GenartName": "Swirlpot, fuel pump",
            "NormTermName": "Swirl Pot",
            "AssGrpName": "Fuel Supply System",
            "UsageName": null
        },
        "00888": {
            "GenartName": "Lock Set, roof rack",
            "NormTermName": "Lock Set",
            "AssGrpName": "Carriers",
            "UsageName": "Roof Rack"
        },
        "00890": {
            "GenartName": "Bolt Kit, clutch",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "00896": {
            "GenartName": "Cap, wiper arm",
            "NormTermName": "Cap",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": "Wiper Arm"
        },
        "00900": {
            "GenartName": "Side Marker Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Side Lamp"
        },
        "00901": {
            "GenartName": "Quarter Panel",
            "NormTermName": "Quarter Panel",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "00902": {
            "GenartName": "Secondary Air System",
            "NormTermName": "Control System",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": null
        },
        "00903": {
            "GenartName": "Secondary Air Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": "Secondary Air"
        },
        "00904": {
            "GenartName": "Valve, secondary ventilation",
            "NormTermName": "Valve",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": "Secondary Air"
        },
        "00905": {
            "GenartName": "Strainer Insert",
            "NormTermName": "Insert",
            "AssGrpName": "Lubrication",
            "UsageName": "Strainer Disc"
        },
        "00906": {
            "GenartName": "Ski Holder, roof rack",
            "NormTermName": "Ski Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Roof Rack"
        },
        "00907": {
            "GenartName": "Ski Holder, rear rack",
            "NormTermName": "Ski Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Rear Rack"
        },
        "00908": {
            "GenartName": "Strap, roof rack",
            "NormTermName": "Belt",
            "AssGrpName": "Carriers",
            "UsageName": null
        },
        "00910": {
            "GenartName": "Bracket, roof rack strap",
            "NormTermName": "Holder",
            "AssGrpName": "Carriers",
            "UsageName": "Roof Rack Strap"
        },
        "00912": {
            "GenartName": "Sports Muffler Set",
            "NormTermName": "Exhaust System",
            "AssGrpName": "Exhaust System",
            "UsageName": "Sports Muffler"
        },
        "00913": {
            "GenartName": "Splash Wall",
            "NormTermName": "Splash Wall",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "00914": {
            "GenartName": "Tie Rod End",
            "NormTermName": "Joint",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod End"
        },
        "00915": {
            "GenartName": "Tie Rod Tube",
            "NormTermName": "Tube",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod"
        },
        "00917": {
            "GenartName": "Parking Heater",
            "NormTermName": "Parking Heater",
            "AssGrpName": "Comfort Systems",
            "UsageName": null
        },
        "00918": {
            "GenartName": "Plug Sleeve, ignition system",
            "NormTermName": "Sleeve",
            "AssGrpName": "Ignition System",
            "UsageName": "Plug"
        },
        "00919": {
            "GenartName": "Dust Cover Kit, shock absorber",
            "NormTermName": "Dust Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shock Absorber"
        },
        "00920": {
            "GenartName": "Actuator, seat adjustment",
            "NormTermName": "Actuator",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Seat Adjustment"
        },
        "00923": {
            "GenartName": "Control, seat height adjustment",
            "NormTermName": "Actuator",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Seat Height Adjustment"
        },
        "00924": {
            "GenartName": "Control, seat back adjustment",
            "NormTermName": "Actuator",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Seat Back Adjustment"
        },
        "00925": {
            "GenartName": "Control, head rest",
            "NormTermName": "Actuator",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Headrest"
        },
        "00926": {
            "GenartName": "Control, pop-up headlight",
            "NormTermName": "Actuator",
            "AssGrpName": "Lights",
            "UsageName": "Pop-up Headlight"
        },
        "00930": {
            "GenartName": "Control Unit, parking distance control",
            "NormTermName": "Control Unit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Distance Control"
        },
        "00932": {
            "GenartName": "Control Unit, ignition system",
            "NormTermName": "Control Unit",
            "AssGrpName": "Ignition System",
            "UsageName": null
        },
        "00935": {
            "GenartName": "Control Unit, carburettor",
            "NormTermName": "Control Unit",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "00937": {
            "GenartName": "Control Flap, air supply",
            "NormTermName": "Lid",
            "AssGrpName": "Air Supply",
            "UsageName": "Air Control"
        },
        "00938": {
            "GenartName": "Bumper",
            "NormTermName": "Bumper",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "00939": {
            "GenartName": "Mounting Bracket, bumper",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Bumper"
        },
        "00941": {
            "GenartName": "Bumper Bracket, towing device",
            "NormTermName": "Rack",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Bumper"
        },
        "00943": {
            "GenartName": "Bumper Cover, towing device",
            "NormTermName": "Cover",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Bumper"
        },
        "00945": {
            "GenartName": "Bumper Shield, towing device",
            "NormTermName": "Shield",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Bumper"
        },
        "00949": {
            "GenartName": "Support, towing device",
            "NormTermName": "Support",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "00950": {
            "GenartName": "Mounting, shock absorber",
            "NormTermName": "Mounting",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shock Absorber"
        },
        "00951": {
            "GenartName": "Surfboard Holder, roof rack",
            "NormTermName": "Surfboard Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Roof Rack"
        },
        "00952": {
            "GenartName": "Speedometer",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Speedometer"
        },
        "00955": {
            "GenartName": "Gauge, coolant temperature",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Coolant Temperature"
        },
        "00956": {
            "GenartName": "Gauge, oil temperature",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Oil Temperature"
        },
        "00957": {
            "GenartName": "Gauge, exterior temperature",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Exterior Temperature"
        },
        "00962": {
            "GenartName": "Gauge, exterior/interior temperature",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Exterior/Interior Temperature"
        },
        "00963": {
            "GenartName": "Thermometer, exterior/interior temperature",
            "NormTermName": "Thermometer",
            "AssGrpName": "Instruments",
            "UsageName": "Exterior/Interior Temperature"
        },
        "00964": {
            "GenartName": "Thermometer, exterior temperature",
            "NormTermName": "Thermometer",
            "AssGrpName": "Instruments",
            "UsageName": "Exterior Temperature"
        },
        "00965": {
            "GenartName": "Transport Platform, rear rack",
            "NormTermName": "Transport Platform",
            "AssGrpName": "Carriers",
            "UsageName": "Rear Rack"
        },
        "00966": {
            "GenartName": "Thermostat, oil cooling",
            "NormTermName": "Thermostat",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Cooling"
        },
        "00969": {
            "GenartName": "Step Board, trailer hitch",
            "NormTermName": "Step Board",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "00970": {
            "GenartName": "Foot Board Step, trailer hitch",
            "NormTermName": "Step, foot board",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "00971": {
            "GenartName": "Roof Pillar",
            "NormTermName": "Roof Pillar",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "00974": {
            "GenartName": "Valve, carburettor ambient air",
            "NormTermName": "Valve",
            "AssGrpName": "Carburettor",
            "UsageName": "Air Recirculation"
        },
        "00975": {
            "GenartName": "Accident Data Recorder",
            "NormTermName": "Data Memory",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Accident Data"
        },
        "00976": {
            "GenartName": "Vacuum Chamber, carburettor",
            "NormTermName": "Vacuum Cell",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "00977": {
            "GenartName": "Cylinder Head Cover",
            "NormTermName": "Cover",
            "AssGrpName": "Cylinder Head",
            "UsageName": null
        },
        "00979": {
            "GenartName": "Gasket Set, cylinder head cover",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Cylinder Head Cover"
        },
        "00981": {
            "GenartName": "Carburettor",
            "NormTermName": "Carburettor",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "00982": {
            "GenartName": "Screw Plug, brake master cylinder",
            "NormTermName": "Screw",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Master Cylinder Cover"
        },
        "00987": {
            "GenartName": "Voltmeter",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Voltmeter"
        },
        "00990": {
            "GenartName": "Jack Support Plate",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Jack"
        },
        "00992": {
            "GenartName": "Gasket, heat exchanger",
            "NormTermName": "Seal",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heat Exchanger"
        },
        "00993": {
            "GenartName": "Warm Air Deflector Plate",
            "NormTermName": "Shield",
            "AssGrpName": "Air Supply",
            "UsageName": "Warm Air Deflector Plate"
        },
        "00994": {
            "GenartName": "Pipe, heat exchanger",
            "NormTermName": "Pipe",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Warm Air"
        },
        "00995": {
            "GenartName": "Warning Triangle",
            "NormTermName": "Warning Triangle",
            "AssGrpName": "Safety Systems",
            "UsageName": null
        },
        "00996": {
            "GenartName": "Warning Light",
            "NormTermName": "Light",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Warning Light"
        },
        "00997": {
            "GenartName": "Safety Vest",
            "NormTermName": "Safety Vest",
            "AssGrpName": "Security Systems, universal",
            "UsageName": null
        },
        "00999": {
            "GenartName": "Water Recirculation Pump, parking heater",
            "NormTermName": "Pump",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Heater"
        },
        "01000": {
            "GenartName": "Central Locking System",
            "NormTermName": "Central Locking System",
            "AssGrpName": "Lock System",
            "UsageName": null
        },
        "01001": {
            "GenartName": "Clock",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Clock"
        },
        "01002": {
            "GenartName": "Trim/Protection Strip, bumper",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body",
            "UsageName": "Bumper"
        },
        "01003": {
            "GenartName": "Trim/Protection Strip, quarter panel",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body",
            "UsageName": "Quarter Panel"
        },
        "01004": {
            "GenartName": "Trim/Protection Strip, wing",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body",
            "UsageName": "Wing"
        },
        "01005": {
            "GenartName": "Trim/Protection Strip, door",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body",
            "UsageName": "Door"
        },
        "01006": {
            "GenartName": "Trim/Protection Strip, front fairing",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body",
            "UsageName": "Front Cowling"
        },
        "01007": {
            "GenartName": "Trim/Protection Strip Set, bumper",
            "NormTermName": "Trim/Protection Strip Set",
            "AssGrpName": "Body",
            "UsageName": "Bumper"
        },
        "01008": {
            "GenartName": "Membrane, vacuum pump",
            "NormTermName": "Diaphragm",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum Pump"
        },
        "01009": {
            "GenartName": "Bracket, brake caliper",
            "NormTermName": "Holder",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "01047": {
            "GenartName": "Oil Cooler, automatic transmission",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Hydraulic Oil"
        },
        "01048": {
            "GenartName": "Oil Cooler, steering system",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Steering",
            "UsageName": "Hydraulic Oil"
        },
        "01049": {
            "GenartName": "Corrugated Pipe, exhaust system",
            "NormTermName": "Tube",
            "AssGrpName": "Exhaust System",
            "UsageName": "Corrugated Pipe"
        },
        "01050": {
            "GenartName": "Pipe Connector, exhaust system",
            "NormTermName": "Pipe Connector",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "01055": {
            "GenartName": "Rubber Strip, exhaust system",
            "NormTermName": "Rubber Strip",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "01056": {
            "GenartName": "Clamping Piece, exhaust system",
            "NormTermName": "Clamping Piece",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "01057": {
            "GenartName": "Bracket, muffler",
            "NormTermName": "Holder",
            "AssGrpName": "Exhaust System",
            "UsageName": "Muffler"
        },
        "01058": {
            "GenartName": "Flange, exhaust pipe",
            "NormTermName": "Flange",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Pipe"
        },
        "01059": {
            "GenartName": "Mount, exhaust system",
            "NormTermName": "Holder",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "01060": {
            "GenartName": "Spring, exhaust pipe",
            "NormTermName": "Spring",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Pipe"
        },
        "01061": {
            "GenartName": "Spring, muffler",
            "NormTermName": "Spring",
            "AssGrpName": "Exhaust System",
            "UsageName": "Muffler"
        },
        "01062": {
            "GenartName": "Holder, exhaust pipe",
            "NormTermName": "Holder",
            "AssGrpName": "Exhaust System",
            "UsageName": "Pipe"
        },
        "01063": {
            "GenartName": "Wire Bracket, exhaust system",
            "NormTermName": "Wire Bracket",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "01064": {
            "GenartName": "Pressure Accumulator, braking system",
            "NormTermName": "Pressure Accumulator",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Pressure"
        },
        "01066": {
            "GenartName": "Gear, crankshaft",
            "NormTermName": "Gear",
            "AssGrpName": "Engine Timing",
            "UsageName": "Crankshaft"
        },
        "01067": {
            "GenartName": "Gear/Sprocket, camshaft",
            "NormTermName": "Gear",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft"
        },
        "01068": {
            "GenartName": "Gear, distributor shaft",
            "NormTermName": "Gear",
            "AssGrpName": "Engine Timing",
            "UsageName": "Distributor Shaft"
        },
        "01069": {
            "GenartName": "Gear, intermediate shaft",
            "NormTermName": "Gear",
            "AssGrpName": "Engine Timing",
            "UsageName": "Intermediate Shaft"
        },
        "01075": {
            "GenartName": "Guides, timing chain",
            "NormTermName": "Guide, timing chain",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain"
        },
        "01076": {
            "GenartName": "Cap, spotlight",
            "NormTermName": "Cap",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "01077": {
            "GenartName": "Cap, front fog light",
            "NormTermName": "Cap",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "01078": {
            "GenartName": "Cover, searchlight",
            "NormTermName": "Cap",
            "AssGrpName": "Lights",
            "UsageName": "Searchlight"
        },
        "01079": {
            "GenartName": "Cap, work light",
            "NormTermName": "Cap",
            "AssGrpName": "Lights",
            "UsageName": "Worklight"
        },
        "01080": {
            "GenartName": "Bushing, axle beam",
            "NormTermName": "Mounting",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Beam"
        },
        "01081": {
            "GenartName": "Flange, carburettor",
            "NormTermName": "Flange",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01085": {
            "GenartName": "Frame Side Rail",
            "NormTermName": "Rack",
            "AssGrpName": "Body",
            "UsageName": "Frame Side Rail"
        },
        "01087": {
            "GenartName": "Impact Absorber, bumper",
            "NormTermName": "Damper",
            "AssGrpName": "Body",
            "UsageName": "Bumper"
        },
        "01088": {
            "GenartName": "Radiator Emblem",
            "NormTermName": "Emblem",
            "AssGrpName": "Body",
            "UsageName": "Radiator Grille"
        },
        "01089": {
            "GenartName": "Gasket Set, carburettor flange",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01090": {
            "GenartName": "Repair Kit, carburettor",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01091": {
            "GenartName": "Bushing, brake shaft",
            "NormTermName": "Bush",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Camshaft Bearing"
        },
        "01092": {
            "GenartName": "Bulb, park-/position light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Park/End Outline Marker Light"
        },
        "01093": {
            "GenartName": "Reflex Reflector",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights",
            "UsageName": null
        },
        "01094": {
            "GenartName": "Alarm System",
            "NormTermName": "Warning System",
            "AssGrpName": "Safety Systems",
            "UsageName": "Theft Alarm"
        },
        "01095": {
            "GenartName": "Brake Shoe Bolt",
            "NormTermName": "Bolt",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoes"
        },
        "01096": {
            "GenartName": "Spring, adjuster",
            "NormTermName": "Spring",
            "AssGrpName": "Braking System",
            "UsageName": "Adjuster"
        },
        "01097": {
            "GenartName": "Repair Kit, stabiliser bush",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser Bush"
        },
        "01098": {
            "GenartName": "Cable Set, carburettor",
            "NormTermName": "Cable Set",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01099": {
            "GenartName": "Seal, headlight",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "01100": {
            "GenartName": "Seal, front fog light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "01101": {
            "GenartName": "Holder, brake line",
            "NormTermName": "Holder",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Line"
        },
        "01102": {
            "GenartName": "Seal Ring, cylinder head cover bolt",
            "NormTermName": "Seal",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Cylinder Head Cover Bolt"
        },
        "01103": {
            "GenartName": "Temperature Switch, radiator fan",
            "NormTermName": "Switch",
            "AssGrpName": "Cooling System",
            "UsageName": "Engine Cooling"
        },
        "01104": {
            "GenartName": "Thermal Switch, carburettor",
            "NormTermName": "Switch",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01105": {
            "GenartName": "Oil Pressure Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pressure"
        },
        "01108": {
            "GenartName": "Belt Pulley, alternator",
            "NormTermName": "Belt Pulley",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "01110": {
            "GenartName": "Fan, alternator",
            "NormTermName": "Fan",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "01111": {
            "GenartName": "Relay, fuel pump",
            "NormTermName": "Relay",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "01112": {
            "GenartName": "Mounting Kit, air conditioning",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Air Conditioning",
            "UsageName": null
        },
        "01113": {
            "GenartName": "Headlight Base",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Headlight"
        },
        "01114": {
            "GenartName": "Lamp Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Lights"
        },
        "01115": {
            "GenartName": "Hydraulic Pump, braking system",
            "NormTermName": "Pump",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Hydraulics"
        },
        "01116": {
            "GenartName": "Base, direction indicator",
            "NormTermName": "Holder",
            "AssGrpName": "Signal System",
            "UsageName": "Direction Indicator"
        },
        "01117": {
            "GenartName": "Holder, stop light",
            "NormTermName": "Holder",
            "AssGrpName": "Signal System",
            "UsageName": "Stop Light"
        },
        "01118": {
            "GenartName": "Base, reverse light",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Reverse Light"
        },
        "01122": {
            "GenartName": "Body",
            "NormTermName": "Body",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "01123": {
            "GenartName": "Timing Chain",
            "NormTermName": "Chain",
            "AssGrpName": "Engine Timing",
            "UsageName": null
        },
        "01125": {
            "GenartName": "Pressure Controller, fuel pump",
            "NormTermName": "Regulator",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "01126": {
            "GenartName": "Holding Bracket, fuel pump",
            "NormTermName": "Holder",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "01127": {
            "GenartName": "Mounting Kit, exhaust system",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "01128": {
            "GenartName": "Sports Muffler",
            "NormTermName": "Damper",
            "AssGrpName": "Exhaust System",
            "UsageName": "Sports Muffler"
        },
        "01129": {
            "GenartName": "Valve, exhaust gas recirculation",
            "NormTermName": "Valve",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": null
        },
        "01131": {
            "GenartName": "Door Open Warning Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Door"
        },
        "01132": {
            "GenartName": "Engine Bay Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Engine Compartment"
        },
        "01134": {
            "GenartName": "Pressure Converter, exhaust control",
            "NormTermName": "Pressure Converter",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "Exhaust Control"
        },
        "01136": {
            "GenartName": "Valve, secondary air intake suction",
            "NormTermName": "Valve",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": "Secondary Air Suction System"
        },
        "01137": {
            "GenartName": "Valve, EGR exhaust control",
            "NormTermName": "Valve",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "Exhaust Control"
        },
        "01145": {
            "GenartName": "EGR Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "Exhaust Gas Recirculation (EGR)"
        },
        "01146": {
            "GenartName": "Secondary Air Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": "Secondary Air System"
        },
        "01147": {
            "GenartName": "Tripod Hub, drive shaft",
            "NormTermName": "Tripod Joint",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "01149": {
            "GenartName": "Auxiliary Air Slide",
            "NormTermName": "Valve",
            "AssGrpName": "Air Supply",
            "UsageName": "Additional Air"
        },
        "01150": {
            "GenartName": "Speedometer Cable",
            "NormTermName": "Shaft",
            "AssGrpName": "Instruments",
            "UsageName": "Speedometer"
        },
        "01151": {
            "GenartName": "Carburettor Damper",
            "NormTermName": "Damper",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01152": {
            "GenartName": "Dashpot, carburettor",
            "NormTermName": "Damper",
            "AssGrpName": "Carburettor",
            "UsageName": "Throttle Valve"
        },
        "01153": {
            "GenartName": "Holder, fuel line",
            "NormTermName": "Holder",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Line"
        },
        "01156": {
            "GenartName": "Air Filter, compressor intake",
            "NormTermName": "Filter",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Compressor Intake Air"
        },
        "01158": {
            "GenartName": "Gasket Set, brake fluid reservoir",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Fluid Reservoir"
        },
        "01159": {
            "GenartName": "Steering Knuckle, wheel suspension",
            "NormTermName": "Steering Knuckle",
            "AssGrpName": "Wheel Suspension",
            "UsageName": null
        },
        "01162": {
            "GenartName": "Sheet, brake caliper piston",
            "NormTermName": "Shield",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper Piston"
        },
        "01164": {
            "GenartName": "Accessory Kit, disc brake pad",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Disc Brake Pad"
        },
        "01165": {
            "GenartName": "Guide Sleeve Kit, brake caliper",
            "NormTermName": "Sleeve Set",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "01166": {
            "GenartName": "Mounting, steering gear",
            "NormTermName": "Mounting",
            "AssGrpName": "Steering",
            "UsageName": "Steering Gear"
        },
        "01167": {
            "GenartName": "Charcoal Filter Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Charcoal Filter Valve"
        },
        "01168": {
            "GenartName": "Holder, tail light",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Tail Light"
        },
        "01169": {
            "GenartName": "Electric Motor, rotating beacon",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Signal System",
            "UsageName": "Rotating Beacon"
        },
        "01170": {
            "GenartName": "Reflector, rotating beacon",
            "NormTermName": "Reflector",
            "AssGrpName": "Signal System",
            "UsageName": "Rotating Beacon"
        },
        "01171": {
            "GenartName": "Holder, rotating beacon",
            "NormTermName": "Holder",
            "AssGrpName": "Signal System",
            "UsageName": "Rotating Beacon"
        },
        "01172": {
            "GenartName": "Bulb, clearance/end outline marker light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Clearance/End Outline Marker Light"
        },
        "01173": {
            "GenartName": "Park Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Park Light"
        },
        "01174": {
            "GenartName": "Lens, position light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights",
            "UsageName": "Position Light"
        },
        "01175": {
            "GenartName": "Lens, end outline marker light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights",
            "UsageName": "End Outline Marker Light"
        },
        "01176": {
            "GenartName": "Electric Motor, headlight cleaning",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": null
        },
        "01177": {
            "GenartName": "Logbook",
            "NormTermName": "Logbook",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "01180": {
            "GenartName": "Suspension Strut Support Mount",
            "NormTermName": "Bearing",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Suspension Strut"
        },
        "01182": {
            "GenartName": "Rubber Buffer, suspension",
            "NormTermName": "Buffer",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring"
        },
        "01184": {
            "GenartName": "Spring Mounting",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Spring Seat"
        },
        "01185": {
            "GenartName": "Suspension Kit, shock absorber",
            "NormTermName": "Damper Set",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shock Absorber"
        },
        "01186": {
            "GenartName": "Sensor, exterior temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Instruments",
            "UsageName": "Exterior Temperature"
        },
        "01187": {
            "GenartName": "Relay, intake manifold heating",
            "NormTermName": "Relay",
            "AssGrpName": "Carburettor",
            "UsageName": "Intake Manifold Preheating"
        },
        "01188": {
            "GenartName": "Control, cruise control system",
            "NormTermName": "Actuator",
            "AssGrpName": "Cruise Control",
            "UsageName": null
        },
        "01189": {
            "GenartName": "Sensor, speed",
            "NormTermName": "Sensor",
            "AssGrpName": "Instruments",
            "UsageName": "Speed"
        },
        "01190": {
            "GenartName": "Sensor, speed/RPM",
            "NormTermName": "Sensor",
            "AssGrpName": "Instruments",
            "UsageName": "Speed/RPM"
        },
        "01191": {
            "GenartName": "Windscreen",
            "NormTermName": "Windscreen",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "01192": {
            "GenartName": "Rear Window",
            "NormTermName": "Rear Window",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "01193": {
            "GenartName": "Door Window",
            "NormTermName": "Door Window",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "01194": {
            "GenartName": "Side Window",
            "NormTermName": "Side Window",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "01195": {
            "GenartName": "Holder, washer-fluid pipe",
            "NormTermName": "Holder",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washer Fluid Pipe"
        },
        "01196": {
            "GenartName": "Headlight Cleaning System",
            "NormTermName": "Wiper System",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": null
        },
        "01197": {
            "GenartName": "Sensor, odometer",
            "NormTermName": "Sensor",
            "AssGrpName": null,
            "UsageName": "Trip Recorder"
        },
        "01198": {
            "GenartName": "Licence Plate Light, towbar",
            "NormTermName": "Light",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Licence Plate Light"
        },
        "01199": {
            "GenartName": "Bumper Mounting Bracket, towing device",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Bumper"
        },
        "01200": {
            "GenartName": "Roof Rack Load Bar",
            "NormTermName": "Rod",
            "AssGrpName": "Carriers",
            "UsageName": "Roof Rack"
        },
        "01201": {
            "GenartName": "Roof Rack Foot",
            "NormTermName": "Base",
            "AssGrpName": "Carriers",
            "UsageName": "Roof Rack"
        },
        "01202": {
            "GenartName": "Stud Bolt Set, roof rack",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Carriers",
            "UsageName": "Roof Rack"
        },
        "01203": {
            "GenartName": "Adapter, rear rack",
            "NormTermName": "Adaptor",
            "AssGrpName": "Carriers",
            "UsageName": "Rear Rack"
        },
        "01204": {
            "GenartName": "Strap, ski rack",
            "NormTermName": "Strap",
            "AssGrpName": "Carriers",
            "UsageName": "Ski Rack"
        },
        "01205": {
            "GenartName": "Wheel Holder, bike rack",
            "NormTermName": "Wheel Holder, bicycle rack",
            "AssGrpName": "Carriers",
            "UsageName": "Bicycle Rack"
        },
        "01207": {
            "GenartName": "Gas Spring, convertible top",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Convertible Top"
        },
        "01208": {
            "GenartName": "Conversion Kit, air conditioning",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Air Conditioning",
            "UsageName": null
        },
        "01209": {
            "GenartName": "Parking Distance Control",
            "NormTermName": "Parking Distance Control",
            "AssGrpName": "Comfort Systems",
            "UsageName": null
        },
        "01210": {
            "GenartName": "Position Detection System",
            "NormTermName": "Position Detection System",
            "AssGrpName": "Safety Systems",
            "UsageName": null
        },
        "01211": {
            "GenartName": "Steering Column",
            "NormTermName": "Steering Column",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "01212": {
            "GenartName": "Relay, parking distance control",
            "NormTermName": "Relay",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Distance Control"
        },
        "01213": {
            "GenartName": "Repair Kit, inner tie rod",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Steering",
            "UsageName": "Axle Joint"
        },
        "01214": {
            "GenartName": "Control Valve, fuel pressure",
            "NormTermName": "Valve",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pressure"
        },
        "01216": {
            "GenartName": "Tappet",
            "NormTermName": "Tappet",
            "AssGrpName": "Engine Timing",
            "UsageName": null
        },
        "01217": {
            "GenartName": "Cylinder Head Bolt Set",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Cylinder Head",
            "UsageName": null
        },
        "01218": {
            "GenartName": "Switch Unit, ignition system",
            "NormTermName": "Control Unit",
            "AssGrpName": "Ignition System",
            "UsageName": null
        },
        "01219": {
            "GenartName": "Control Unit, braking system",
            "NormTermName": "Control Unit",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "01220": {
            "GenartName": "Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "01221": {
            "GenartName": "Spring Washer",
            "NormTermName": "Spring Washer",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "01222": {
            "GenartName": "Washer",
            "NormTermName": "Washer",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "01224": {
            "GenartName": "Pressure Accumulator, drive dynamics control",
            "NormTermName": "Pressure Accumulator",
            "AssGrpName": "Drive Dynamics Control",
            "UsageName": null
        },
        "01226": {
            "GenartName": "Clamping Sleeve, wheel speed sensor",
            "NormTermName": "Sleeve",
            "AssGrpName": "Braking System",
            "UsageName": "RPM Sensor"
        },
        "01231": {
            "GenartName": "Sensor, compressed-air system",
            "NormTermName": "Sensor",
            "AssGrpName": "Compressed-air System",
            "UsageName": null
        },
        "01232": {
            "GenartName": "Overvoltage Protector, alternator",
            "NormTermName": "Overvoltage Protector",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "01235": {
            "GenartName": "Mounting Kit, ignition control unit",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition Control Unit"
        },
        "01236": {
            "GenartName": "Magnetic Clutch, air conditioning compressor",
            "NormTermName": "Coupling",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Compressor"
        },
        "01237": {
            "GenartName": "Resonator, exhaust system",
            "NormTermName": "Resonator",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "01238": {
            "GenartName": "Bonnet Cable",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Body",
            "UsageName": "Bonnet"
        },
        "01239": {
            "GenartName": "Cable, heater flap",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heater Flaps"
        },
        "01240": {
            "GenartName": "Cable, starter",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Starter System",
            "UsageName": "Starter"
        },
        "01244": {
            "GenartName": "Relay, start repeater",
            "NormTermName": "Relay",
            "AssGrpName": "Starter System",
            "UsageName": "Starter Repeater"
        },
        "01245": {
            "GenartName": "Seal, windscreen",
            "NormTermName": "Seal",
            "AssGrpName": "Body",
            "UsageName": "Windscreen"
        },
        "01246": {
            "GenartName": "Seal, rear window",
            "NormTermName": "Seal",
            "AssGrpName": "Body",
            "UsageName": "Rear Window"
        },
        "01247": {
            "GenartName": "Seal, door window",
            "NormTermName": "Seal",
            "AssGrpName": "Body",
            "UsageName": "Door Window"
        },
        "01248": {
            "GenartName": "Seal, side window",
            "NormTermName": "Seal",
            "AssGrpName": "Body",
            "UsageName": "Side Window"
        },
        "01249": {
            "GenartName": "Trim/Protection Strip, windscreen",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body",
            "UsageName": "Windscreen"
        },
        "01250": {
            "GenartName": "Trim/Protection Strip, rear window",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body",
            "UsageName": "Rear Window"
        },
        "01251": {
            "GenartName": "Trim/Protection Strip, door window",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body",
            "UsageName": "Door Window"
        },
        "01252": {
            "GenartName": "Trim/Protection Strip, side window",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body",
            "UsageName": "Side Window"
        },
        "01253": {
            "GenartName": "Retaining Clip, windows",
            "NormTermName": "Clip",
            "AssGrpName": "Body",
            "UsageName": "Window Fitting"
        },
        "01254": {
            "GenartName": "Fan, A/C condenser",
            "NormTermName": "Fan",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Radiator"
        },
        "01255": {
            "GenartName": "Repair Kit, brake fluid reservoir",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Fluid Reservoir"
        },
        "01256": {
            "GenartName": "Soot/Particulate Filter, exhaust system",
            "NormTermName": "Filter",
            "AssGrpName": "Exhaust System",
            "UsageName": "Soot"
        },
        "01259": {
            "GenartName": "Door, driver cab",
            "NormTermName": "Door",
            "AssGrpName": "Driver Cab",
            "UsageName": null
        },
        "01260": {
            "GenartName": "Water Pump, engine cooling",
            "NormTermName": "Pump",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Water"
        },
        "01261": {
            "GenartName": "Relay, headlight cleaning",
            "NormTermName": "Relay",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": null
        },
        "01263": {
            "GenartName": "Cylinder Head",
            "NormTermName": "Cylinder Head",
            "AssGrpName": "Cylinder Head",
            "UsageName": null
        },
        "01264": {
            "GenartName": "Complete Engine",
            "NormTermName": "Complete Engine",
            "AssGrpName": "Service Exchange Engine",
            "UsageName": null
        },
        "01265": {
            "GenartName": "Partial Engine",
            "NormTermName": "Partial Engine",
            "AssGrpName": "Service Exchange Engine",
            "UsageName": null
        },
        "01266": {
            "GenartName": "Refrigerant Filter, air conditioning",
            "NormTermName": "Filter",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Refrigerant"
        },
        "01267": {
            "GenartName": "Relay, central locking system",
            "NormTermName": "Relay",
            "AssGrpName": "Lock System",
            "UsageName": "Central Locking System"
        },
        "01268": {
            "GenartName": "Spoiler, radiator grille",
            "NormTermName": "Spoiler",
            "AssGrpName": "Body",
            "UsageName": "Radiator Grille"
        },
        "01269": {
            "GenartName": "Intake Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Engine Timing",
            "UsageName": "Intake"
        },
        "01270": {
            "GenartName": "Exhaust Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Engine Timing",
            "UsageName": "Exhaust"
        },
        "01271": {
            "GenartName": "Valve keeper",
            "NormTermName": "Valve Keeper",
            "AssGrpName": "Engine Timing",
            "UsageName": "Valve"
        },
        "01272": {
            "GenartName": "Valve Retainer Caps",
            "NormTermName": "Valve Disc",
            "AssGrpName": "Engine Timing",
            "UsageName": null
        },
        "01273": {
            "GenartName": "Valve Spring",
            "NormTermName": "Spring",
            "AssGrpName": "Engine Timing",
            "UsageName": "Valve"
        },
        "01274": {
            "GenartName": "Valve Seat",
            "NormTermName": "Valve Seat",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Valve"
        },
        "01275": {
            "GenartName": "Rocker Arm Bearing",
            "NormTermName": "Bearing",
            "AssGrpName": "Engine Timing",
            "UsageName": "Rocker Arm"
        },
        "01276": {
            "GenartName": "Camshaft Bushes",
            "NormTermName": "Bearing",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft"
        },
        "01278": {
            "GenartName": "Control Unit, automatic transmission",
            "NormTermName": "Control Unit",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "01279": {
            "GenartName": "Washer Fluid Pipe, headlight cleaning",
            "NormTermName": "Pipe",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": "Washing Water"
        },
        "01280": {
            "GenartName": "Washer Fluid Jet, headlight cleaning",
            "NormTermName": "Nozzle",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": "Washing Water"
        },
        "01281": {
            "GenartName": "Repair Kit, axle beam",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Beam"
        },
        "01284": {
            "GenartName": "Charcoal Filter, tank ventilation",
            "NormTermName": "Filter",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Tank Ventilation"
        },
        "01285": {
            "GenartName": "Relay, safety belt alarm",
            "NormTermName": "Relay",
            "AssGrpName": "Safety Systems",
            "UsageName": "Safety Belt Alarm"
        },
        "01286": {
            "GenartName": "Relay, rear fog light",
            "NormTermName": "Relay",
            "AssGrpName": "Lights",
            "UsageName": "Rear Fog Light"
        },
        "01287": {
            "GenartName": "Relay, spotlight",
            "NormTermName": "Relay",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "01288": {
            "GenartName": "Sensor, coolant level",
            "NormTermName": "Sensor",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Level"
        },
        "01289": {
            "GenartName": "Sensor, engine oil level",
            "NormTermName": "Sensor",
            "AssGrpName": "Lubrication",
            "UsageName": "Engine Oil Level"
        },
        "01290": {
            "GenartName": "Relay, rear windscreen heating",
            "NormTermName": "Relay",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Rear Windscreen Heating"
        },
        "01291": {
            "GenartName": "Relay, radiator fan overrun",
            "NormTermName": "Relay",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator Fan Overrun"
        },
        "01292": {
            "GenartName": "Relay, window regulator",
            "NormTermName": "Relay",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Window Regulator"
        },
        "01293": {
            "GenartName": "Time Delay Relay, interior lighting",
            "NormTermName": "Relay",
            "AssGrpName": "Lights",
            "UsageName": "Interior Light"
        },
        "01294": {
            "GenartName": "Relay, front fog light",
            "NormTermName": "Relay",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "01295": {
            "GenartName": "Sensor, engine bay temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Cooling System",
            "UsageName": "Engine Bay Temperature"
        },
        "01298": {
            "GenartName": "Idle Control Valve, air supply",
            "NormTermName": "Valve",
            "AssGrpName": "Air Supply",
            "UsageName": "Idle"
        },
        "01299": {
            "GenartName": "Exterior Mirror, driver cab",
            "NormTermName": "Mirror",
            "AssGrpName": "Driver Cab",
            "UsageName": "Exterior Mirror"
        },
        "01300": {
            "GenartName": "Spring Seat",
            "NormTermName": "Spring Seat",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "01301": {
            "GenartName": "Washer Fluid Reservoir, headlight cleaning",
            "NormTermName": "Tank",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": "Washing Water"
        },
        "01303": {
            "GenartName": "Pressure Accumulator, fuel pressure",
            "NormTermName": "Pressure Accumulator",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pressure"
        },
        "01304": {
            "GenartName": "Regulating Valve, clutch master cylinder",
            "NormTermName": "Valve",
            "AssGrpName": "Clutch",
            "UsageName": "Slave Cylinder"
        },
        "01305": {
            "GenartName": "Control Valve, air intake",
            "NormTermName": "Valve",
            "AssGrpName": "Air Supply",
            "UsageName": "Air Control"
        },
        "01306": {
            "GenartName": "Valve, carburettor",
            "NormTermName": "Valve",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01307": {
            "GenartName": "Wiper Linkage, headlight cleaning",
            "NormTermName": "Linkage",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": "Wiper"
        },
        "01308": {
            "GenartName": "Secondary Air Pump Module",
            "NormTermName": "Pump Module",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": "Secondary Air"
        },
        "01309": {
            "GenartName": "Flange, fuel feed unit",
            "NormTermName": "Flange",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Supply Unit"
        },
        "01312": {
            "GenartName": "Pressure Limiting Valve, air suspension",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Suspension Pressure Limitation"
        },
        "01313": {
            "GenartName": "Quick Release Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Quick Release"
        },
        "01314": {
            "GenartName": "Pressure Control Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Pressure Control"
        },
        "01315": {
            "GenartName": "Push Button Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Pushbutton"
        },
        "01316": {
            "GenartName": "Load Sensitive Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Load/Empty"
        },
        "01317": {
            "GenartName": "Throttle Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Throttle"
        },
        "01318": {
            "GenartName": "Water Drain Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Water Drainage"
        },
        "01319": {
            "GenartName": "Multiport Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Multiway"
        },
        "01320": {
            "GenartName": "Breather Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Bleeding"
        },
        "01321": {
            "GenartName": "Pressure Limiting Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Pressure Limitation"
        },
        "01322": {
            "GenartName": "Pressure Control Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Pressure Preservation"
        },
        "01323": {
            "GenartName": "Solenoid Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Magnet"
        },
        "01324": {
            "GenartName": "Non-Return Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Return"
        },
        "01325": {
            "GenartName": "Overflow Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Overflow"
        },
        "01326": {
            "GenartName": "Rotary-knob Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Turn Knob"
        },
        "01327": {
            "GenartName": "Double Stop Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Double Shut-off Valve"
        },
        "01328": {
            "GenartName": "Adaptor Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Adaptation"
        },
        "01329": {
            "GenartName": "Relay Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Relay"
        },
        "01330": {
            "GenartName": "Splash Panel, brake disc",
            "NormTermName": "Shield",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Disc"
        },
        "01332": {
            "GenartName": "Immobilizer",
            "NormTermName": "Immobilizer",
            "AssGrpName": "Safety Systems",
            "UsageName": null
        },
        "01333": {
            "GenartName": "Stop Valve, immobilizer",
            "NormTermName": "Valve",
            "AssGrpName": "Safety Systems",
            "UsageName": "Immobilizer"
        },
        "01334": {
            "GenartName": "Mounting, stabiliser bar",
            "NormTermName": "Mounting",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser Bush"
        },
        "01335": {
            "GenartName": "Trim/Protection Strip Set",
            "NormTermName": "Trim/Protection Strip Set",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "01336": {
            "GenartName": "Brake Pad Set, disc parking brake",
            "NormTermName": "Brake Pad Set",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake"
        },
        "01337": {
            "GenartName": "Accessory Kit, parking brake shoes",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake Shoes"
        },
        "01338": {
            "GenartName": "Plug Connector",
            "NormTermName": "Grommet",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "01340": {
            "GenartName": "Line Filter, compressed-air system",
            "NormTermName": "Filter",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Line"
        },
        "01341": {
            "GenartName": "Control Unit, ALB",
            "NormTermName": "Regulator",
            "AssGrpName": "Compressed-air System",
            "UsageName": "ALB"
        },
        "01342": {
            "GenartName": "Relay, ALB",
            "NormTermName": "Relay",
            "AssGrpName": "Compressed-air System",
            "UsageName": "ALB"
        },
        "01343": {
            "GenartName": "Armrest",
            "NormTermName": "Backrest",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Arm"
        },
        "01344": {
            "GenartName": "Entry threshold",
            "NormTermName": "Entry threshold",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "01345": {
            "GenartName": "Sill Trim",
            "NormTermName": "Panelling",
            "AssGrpName": "Body",
            "UsageName": "Sill Box"
        },
        "01346": {
            "GenartName": "Cover, handle recess",
            "NormTermName": "Cover",
            "AssGrpName": "Body",
            "UsageName": "Handle Recess"
        },
        "01347": {
            "GenartName": "Cargo Area Cover",
            "NormTermName": "Cover",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Boot-/Cargo Area"
        },
        "01348": {
            "GenartName": "Surfboard Holder, rear rack",
            "NormTermName": "Surfboard Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Rear Rack"
        },
        "01349": {
            "GenartName": "Gasket Set, exhaust system",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "01350": {
            "GenartName": "Clearance Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Clearance Light"
        },
        "01351": {
            "GenartName": "Auxiliary water pump (cooling water circuit)",
            "NormTermName": "Pump",
            "AssGrpName": "Cooling System",
            "UsageName": "Auxiliary Pump"
        },
        "01353": {
            "GenartName": "Engine Compartment Silencing Material",
            "NormTermName": "Silencing Material",
            "AssGrpName": "Body",
            "UsageName": "Engine Compartment"
        },
        "01354": {
            "GenartName": "Adaptor Kit, logbook",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Logbook"
        },
        "01355": {
            "GenartName": "Hose Connector",
            "NormTermName": "Connector",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Hose"
        },
        "01356": {
            "GenartName": "Aerial Head",
            "NormTermName": "Aerial Head",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": null
        },
        "01357": {
            "GenartName": "Aerial Telescope",
            "NormTermName": "Aerial Telescope",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": null
        },
        "01358": {
            "GenartName": "Aerial Cable",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Aerial"
        },
        "01359": {
            "GenartName": "Aerial Booster",
            "NormTermName": "Booster",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Aerial"
        },
        "01360": {
            "GenartName": "Pressure Switch, air conditioning",
            "NormTermName": "Switch",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Pressure Switch"
        },
        "01361": {
            "GenartName": "Door Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Lock System",
            "UsageName": "Door"
        },
        "01362": {
            "GenartName": "Tailgate Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Lock System",
            "UsageName": "Tailgate"
        },
        "01363": {
            "GenartName": "Boot Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Lock System",
            "UsageName": "Boot Lid"
        },
        "01364": {
            "GenartName": "Lock Stop, boot lid",
            "NormTermName": "Stop",
            "AssGrpName": "Lock System",
            "UsageName": "Boot Lock"
        },
        "01365": {
            "GenartName": "Bonnet Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Lock System",
            "UsageName": "Bonnet"
        },
        "01366": {
            "GenartName": "Tank Cap Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Lock System",
            "UsageName": "Fuel Filler Cap"
        },
        "01367": {
            "GenartName": "Steering Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Lock System",
            "UsageName": "Steering Column"
        },
        "01368": {
            "GenartName": "Glove Compartment Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Lock System",
            "UsageName": "Glove Compartment"
        },
        "01369": {
            "GenartName": "Lock Body, steering lock",
            "NormTermName": "Lock Body",
            "AssGrpName": "Lock System",
            "UsageName": "Steering Column"
        },
        "01370": {
            "GenartName": "Airbag Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Lock System",
            "UsageName": "Airbag"
        },
        "01371": {
            "GenartName": "Lock, alarm system",
            "NormTermName": "Lock",
            "AssGrpName": "Lock System",
            "UsageName": "Alarm System"
        },
        "01372": {
            "GenartName": "Lock Set, locking system",
            "NormTermName": "Lock Set",
            "AssGrpName": "Lock System",
            "UsageName": null
        },
        "01373": {
            "GenartName": "Outer door handle",
            "NormTermName": "Handle",
            "AssGrpName": "Lock System",
            "UsageName": "Door"
        },
        "01374": {
            "GenartName": "Tailgate Handle",
            "NormTermName": "Handle",
            "AssGrpName": "Lock System",
            "UsageName": "Tailgate"
        },
        "01375": {
            "GenartName": "Handle, bootlid",
            "NormTermName": "Handle",
            "AssGrpName": "Lock System",
            "UsageName": "Boot Lid"
        },
        "01376": {
            "GenartName": "Door-handle Control",
            "NormTermName": "Handle",
            "AssGrpName": "Lock System",
            "UsageName": "Door"
        },
        "01377": {
            "GenartName": "Frame, outer door handle",
            "NormTermName": "Handle Frame",
            "AssGrpName": "Lock System",
            "UsageName": "Door"
        },
        "01378": {
            "GenartName": "Lock Cylinder",
            "NormTermName": "Lock Cylinder",
            "AssGrpName": "Lock System",
            "UsageName": null
        },
        "01379": {
            "GenartName": "Lock Cylinder Kit",
            "NormTermName": "Lock Cylinder Set",
            "AssGrpName": "Lock System",
            "UsageName": null
        },
        "01380": {
            "GenartName": "Lock Cylinder Housing",
            "NormTermName": "Lock Cylinder Housing",
            "AssGrpName": "Lock System",
            "UsageName": null
        },
        "01381": {
            "GenartName": "Bolt, wheel alignment",
            "NormTermName": "Screw",
            "AssGrpName": "Steering",
            "UsageName": "Wheel Alignment"
        },
        "01382": {
            "GenartName": "Fuel Feed Unit",
            "NormTermName": "Fuel Supply Unit",
            "AssGrpName": "Fuel Supply System",
            "UsageName": null
        },
        "01383": {
            "GenartName": "Level Control Switch, engine oil level",
            "NormTermName": "Switch",
            "AssGrpName": "Lubrication",
            "UsageName": "Engine Oil Level"
        },
        "01384": {
            "GenartName": "Control Unit, airbag",
            "NormTermName": "Control Unit",
            "AssGrpName": "Safety Systems",
            "UsageName": "Airbag"
        },
        "01385": {
            "GenartName": "Control Unit, heating/ventilation",
            "NormTermName": "Control Unit",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": null
        },
        "01386": {
            "GenartName": "Control Unit, seat adjustment",
            "NormTermName": "Control Unit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Seat Adjustment"
        },
        "01387": {
            "GenartName": "Control Unit, lights",
            "NormTermName": "Control Unit",
            "AssGrpName": "Lights",
            "UsageName": null
        },
        "01388": {
            "GenartName": "Coolant Filter",
            "NormTermName": "Filter",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant"
        },
        "01389": {
            "GenartName": "Timing Chain Kit",
            "NormTermName": "Timing Chain Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": null
        },
        "01390": {
            "GenartName": "Alternator Freewheel Clutch",
            "NormTermName": "Freewheel",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "01391": {
            "GenartName": "Adapter, lambda sensor",
            "NormTermName": "Adaptor",
            "AssGrpName": "Exhaust System",
            "UsageName": "Lambda Sensor"
        },
        "01392": {
            "GenartName": "Heat Shield",
            "NormTermName": "Heat Shield",
            "AssGrpName": "Exhaust System",
            "UsageName": "Heat Shield"
        },
        "01393": {
            "GenartName": "Clamping Piece Set, exhaust system",
            "NormTermName": "Clamping Piece Set",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Pipe"
        },
        "01395": {
            "GenartName": "Mounting Kit, exhaust pipe",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Pipe"
        },
        "01396": {
            "GenartName": "Screw Plug, Lambda sensor mount",
            "NormTermName": "Screw",
            "AssGrpName": "Exhaust System",
            "UsageName": "Lambda Sensor Holder"
        },
        "01397": {
            "GenartName": "Control Unit, central locking system",
            "NormTermName": "Control Unit",
            "AssGrpName": "Lock System",
            "UsageName": "Central Locking System"
        },
        "01398": {
            "GenartName": "Adapter Kit, cruise control",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Cruise Control",
            "UsageName": null
        },
        "01399": {
            "GenartName": "Frequency Module, cruise control system",
            "NormTermName": "Frequency Module",
            "AssGrpName": "Cruise Control",
            "UsageName": null
        },
        "01400": {
            "GenartName": "Service Hour Counter",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Service Hour Counter"
        },
        "01401": {
            "GenartName": "Tractormeter",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Tractormeter"
        },
        "01402": {
            "GenartName": "Sensor, RPM",
            "NormTermName": "Sensor",
            "AssGrpName": "Instruments",
            "UsageName": "RPM"
        },
        "01403": {
            "GenartName": "Series Resistor, instrument cluster",
            "NormTermName": "Resistor",
            "AssGrpName": "Instruments",
            "UsageName": "Instrument Cluster"
        },
        "01404": {
            "GenartName": "Software, logbook",
            "NormTermName": "Software",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Logbook"
        },
        "01405": {
            "GenartName": "Inclination Sensor, alarm system",
            "NormTermName": "Sensor",
            "AssGrpName": "Safety Systems",
            "UsageName": "Inclination Sensor"
        },
        "01406": {
            "GenartName": "Control Valve, central locking",
            "NormTermName": "Valve",
            "AssGrpName": "Lock System",
            "UsageName": "Central Locking Control"
        },
        "01407": {
            "GenartName": "Swirlpot Damper",
            "NormTermName": "Damper",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Swirl Pot"
        },
        "01408": {
            "GenartName": "Holder, swirlpot damper",
            "NormTermName": "Holder",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Swirl Pot Damping"
        },
        "01409": {
            "GenartName": "Level Control Switch, fuel",
            "NormTermName": "Switch",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Level"
        },
        "01410": {
            "GenartName": "Oscillator, fuel pump",
            "NormTermName": "Oscillator",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "01411": {
            "GenartName": "Breather Valve, fuel tank",
            "NormTermName": "Valve",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Filler/Bleeder"
        },
        "01412": {
            "GenartName": "Level Control Switch, coolant level indicator",
            "NormTermName": "Switch",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Level Gauge"
        },
        "01413": {
            "GenartName": "Control Unit, coolant level indicator",
            "NormTermName": "Control Unit",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Level Gauge"
        },
        "01414": {
            "GenartName": "Nozzle Needle, window cleaning",
            "NormTermName": "Nozzle Needle",
            "AssGrpName": "Window Cleaning",
            "UsageName": null
        },
        "01415": {
            "GenartName": "Intake Manifold Module",
            "NormTermName": "Intake Manifold Module",
            "AssGrpName": "Air Supply",
            "UsageName": null
        },
        "01416": {
            "GenartName": "Vacuum Pump, air conditioning",
            "NormTermName": "Pump",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Vacuum"
        },
        "01417": {
            "GenartName": "Vacuum Pump, central locking system",
            "NormTermName": "Pump",
            "AssGrpName": "Lock System",
            "UsageName": "Central Locking System"
        },
        "01418": {
            "GenartName": "Exhaust Gas Door",
            "NormTermName": "Exhaust Gas Flap",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "01419": {
            "GenartName": "Brake Shoe Set, parking brake",
            "NormTermName": "Brake Shoe Set",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake"
        },
        "01420": {
            "GenartName": "Suspension, propshaft",
            "NormTermName": "Mounting",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft"
        },
        "01421": {
            "GenartName": "Electric Kit, check control extension",
            "NormTermName": "Electric Kit",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Check Control"
        },
        "01422": {
            "GenartName": "Reinforcing Kit, body",
            "NormTermName": "Booster Kit",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Body Floor"
        },
        "01423": {
            "GenartName": "Mounting Kit, replacement system",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Swap System"
        },
        "01424": {
            "GenartName": "Mounting Kit, jaw coupling",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Jaw Coupling"
        },
        "01425": {
            "GenartName": "Replacement System, trailer hitch pedestal",
            "NormTermName": "Replacement System",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Trailer Hitch"
        },
        "01426": {
            "GenartName": "Mounting Bracket, jaw-ball coupling",
            "NormTermName": "Bracket",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Jaw/ Ball Coupling"
        },
        "01427": {
            "GenartName": "Joint, propshaft",
            "NormTermName": "Joint",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft"
        },
        "01428": {
            "GenartName": "Auxiliary Stop Light",
            "NormTermName": "Light",
            "AssGrpName": "Signal System",
            "UsageName": "Auxiliary Stop Light"
        },
        "01429": {
            "GenartName": "Control Unit, cruise control system",
            "NormTermName": "Control Unit",
            "AssGrpName": "Cruise Control",
            "UsageName": null
        },
        "01430": {
            "GenartName": "Jumper Cables",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Start Aid"
        },
        "01431": {
            "GenartName": "Ballast, gas discharge lamp",
            "NormTermName": "Control Unit",
            "AssGrpName": "Lights",
            "UsageName": "Gas Discharge Lamp"
        },
        "01432": {
            "GenartName": "Control unit, headlight levelling",
            "NormTermName": "Control Unit",
            "AssGrpName": "Lights",
            "UsageName": "Headlight Levelling"
        },
        "01433": {
            "GenartName": "Lights-on Reminder",
            "NormTermName": "Lights-On Reminder",
            "AssGrpName": "Lights",
            "UsageName": null
        },
        "01434": {
            "GenartName": "Tester, headlight aiming",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Headlamp Aiming"
        },
        "01435": {
            "GenartName": "Terminal, electric universal parts",
            "NormTermName": "Clamp",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "01436": {
            "GenartName": "Cover, wheels",
            "NormTermName": "Cover",
            "AssGrpName": "Wheels",
            "UsageName": "Wheel Cover"
        },
        "01437": {
            "GenartName": "Resistor",
            "NormTermName": "Resistor",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "01438": {
            "GenartName": "Screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "01439": {
            "GenartName": "Control Unit, parking heater",
            "NormTermName": "Control Unit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Heater"
        },
        "01440": {
            "GenartName": "Control Unit, belt feeder",
            "NormTermName": "Control Unit",
            "AssGrpName": "Safety Systems",
            "UsageName": "Belt Feeder"
        },
        "01441": {
            "GenartName": "Control Unit, air conditioning",
            "NormTermName": "Control Unit",
            "AssGrpName": "Air Conditioning",
            "UsageName": null
        },
        "01442": {
            "GenartName": "Refrigerant, air conditioning",
            "NormTermName": "Refrigerant",
            "AssGrpName": "Air Conditioning",
            "UsageName": null
        },
        "01443": {
            "GenartName": "Central Electric",
            "NormTermName": "Central Electrics",
            "AssGrpName": "Central Electrics",
            "UsageName": null
        },
        "01444": {
            "GenartName": "Cooler Box",
            "NormTermName": "Cool Box",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "01445": {
            "GenartName": "Cable Set",
            "NormTermName": "Electric Cable Set",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "01446": {
            "GenartName": "Cigarette Lighter",
            "NormTermName": "Cigarette Lighter",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "01447": {
            "GenartName": "Cover",
            "NormTermName": "Cover",
            "AssGrpName": null,
            "UsageName": null
        },
        "01448": {
            "GenartName": "Anchor",
            "NormTermName": "Rotor",
            "AssGrpName": null,
            "UsageName": null
        },
        "01449": {
            "GenartName": "Pick-up",
            "NormTermName": "Holder",
            "AssGrpName": null,
            "UsageName": null
        },
        "01450": {
            "GenartName": "Tank",
            "NormTermName": "Tank",
            "AssGrpName": null,
            "UsageName": null
        },
        "01451": {
            "GenartName": "Seal",
            "NormTermName": "Seal",
            "AssGrpName": null,
            "UsageName": null
        },
        "01452": {
            "GenartName": "Nozzle",
            "NormTermName": "Nozzle",
            "AssGrpName": null,
            "UsageName": null
        },
        "01453": {
            "GenartName": "Spring",
            "NormTermName": "Spring",
            "AssGrpName": null,
            "UsageName": null
        },
        "01454": {
            "GenartName": "Remote Control",
            "NormTermName": "Remote Control",
            "AssGrpName": null,
            "UsageName": null
        },
        "01455": {
            "GenartName": "Housing",
            "NormTermName": "Housing",
            "AssGrpName": null,
            "UsageName": null
        },
        "01456": {
            "GenartName": "Grille",
            "NormTermName": "Grille",
            "AssGrpName": null,
            "UsageName": null
        },
        "01457": {
            "GenartName": "Bulb",
            "NormTermName": "Bulb",
            "AssGrpName": null,
            "UsageName": null
        },
        "01458": {
            "GenartName": "Handle",
            "NormTermName": "Handle",
            "AssGrpName": null,
            "UsageName": null
        },
        "01459": {
            "GenartName": "Holder",
            "NormTermName": "Holder",
            "AssGrpName": null,
            "UsageName": null
        },
        "01460": {
            "GenartName": "Sleeve",
            "NormTermName": "Sleeve",
            "AssGrpName": null,
            "UsageName": null
        },
        "01461": {
            "GenartName": "Insulating Tape",
            "NormTermName": "Insulation Tape",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "01462": {
            "GenartName": "Cap",
            "NormTermName": "Cap",
            "AssGrpName": null,
            "UsageName": null
        },
        "01463": {
            "GenartName": "Knob",
            "NormTermName": "Knob",
            "AssGrpName": null,
            "UsageName": null
        },
        "01464": {
            "GenartName": "Control Unit",
            "NormTermName": "Tester",
            "AssGrpName": null,
            "UsageName": null
        },
        "01465": {
            "GenartName": "Bulb Socket",
            "NormTermName": "Bulb Socket",
            "AssGrpName": null,
            "UsageName": null
        },
        "01466": {
            "GenartName": "Lamp Base",
            "NormTermName": "Lamp Base",
            "AssGrpName": null,
            "UsageName": null
        },
        "01467": {
            "GenartName": "Light",
            "NormTermName": "Light",
            "AssGrpName": null,
            "UsageName": null
        },
        "01468": {
            "GenartName": "Light Transmitter",
            "NormTermName": "Light Transmitter",
            "AssGrpName": null,
            "UsageName": null
        },
        "01469": {
            "GenartName": "Lens",
            "NormTermName": "Lens",
            "AssGrpName": null,
            "UsageName": null
        },
        "01470": {
            "GenartName": "Lens",
            "NormTermName": "Lens",
            "AssGrpName": null,
            "UsageName": null
        },
        "01471": {
            "GenartName": "Measuring Device",
            "NormTermName": "Measuring Device",
            "AssGrpName": null,
            "UsageName": null
        },
        "01472": {
            "GenartName": "Microphone",
            "NormTermName": "Microphone",
            "AssGrpName": null,
            "UsageName": null
        },
        "01473": {
            "GenartName": "Nut",
            "NormTermName": "Nut",
            "AssGrpName": null,
            "UsageName": null
        },
        "01474": {
            "GenartName": "Rivet",
            "NormTermName": "Rivet",
            "AssGrpName": null,
            "UsageName": null
        },
        "01475": {
            "GenartName": "Testing Unit",
            "NormTermName": "Tester",
            "AssGrpName": null,
            "UsageName": null
        },
        "01476": {
            "GenartName": "Pump",
            "NormTermName": "Pump",
            "AssGrpName": null,
            "UsageName": null
        },
        "01477": {
            "GenartName": "Frame",
            "NormTermName": "Frame",
            "AssGrpName": null,
            "UsageName": null
        },
        "01478": {
            "GenartName": "Reflector",
            "NormTermName": "Reflector",
            "AssGrpName": null,
            "UsageName": null
        },
        "01479": {
            "GenartName": "Switch",
            "NormTermName": "Switch",
            "AssGrpName": null,
            "UsageName": null
        },
        "01480": {
            "GenartName": "Label",
            "NormTermName": "Label",
            "AssGrpName": null,
            "UsageName": null
        },
        "01481": {
            "GenartName": "Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": null,
            "UsageName": null
        },
        "01483": {
            "GenartName": "Key",
            "NormTermName": "Key",
            "AssGrpName": null,
            "UsageName": null
        },
        "01484": {
            "GenartName": "Screw",
            "NormTermName": "Screw",
            "AssGrpName": null,
            "UsageName": null
        },
        "01485": {
            "GenartName": "Sensor",
            "NormTermName": "Sensor",
            "AssGrpName": null,
            "UsageName": null
        },
        "01486": {
            "GenartName": "Securing Element",
            "NormTermName": "Securing Elements",
            "AssGrpName": null,
            "UsageName": null
        },
        "01487": {
            "GenartName": "Tightening Strap",
            "NormTermName": "Tightening Strap",
            "AssGrpName": null,
            "UsageName": null
        },
        "01488": {
            "GenartName": "Mirror Glass",
            "NormTermName": "Mirror Glass",
            "AssGrpName": null,
            "UsageName": null
        },
        "01489": {
            "GenartName": "Coil",
            "NormTermName": "Coil",
            "AssGrpName": null,
            "UsageName": null
        },
        "01490": {
            "GenartName": "Tripod",
            "NormTermName": "Tripod",
            "AssGrpName": null,
            "UsageName": null
        },
        "01491": {
            "GenartName": "Control Unit",
            "NormTermName": "Control Unit",
            "AssGrpName": null,
            "UsageName": null
        },
        "01492": {
            "GenartName": "Diffusing Lens",
            "NormTermName": "Diffusing Lens",
            "AssGrpName": null,
            "UsageName": null
        },
        "01493": {
            "GenartName": "Connector",
            "NormTermName": "Connector",
            "AssGrpName": null,
            "UsageName": "Connector"
        },
        "01494": {
            "GenartName": "Button",
            "NormTermName": "Button",
            "AssGrpName": null,
            "UsageName": null
        },
        "01495": {
            "GenartName": "Funnel",
            "NormTermName": "Funnel",
            "AssGrpName": null,
            "UsageName": null
        },
        "01496": {
            "GenartName": "Grommet",
            "NormTermName": "Grommet",
            "AssGrpName": null,
            "UsageName": null
        },
        "01497": {
            "GenartName": "Union Nut",
            "NormTermName": "Union Nut",
            "AssGrpName": null,
            "UsageName": null
        },
        "01498": {
            "GenartName": "Valve",
            "NormTermName": "Valve",
            "AssGrpName": null,
            "UsageName": null
        },
        "01499": {
            "GenartName": "Seal",
            "NormTermName": "Closure",
            "AssGrpName": null,
            "UsageName": null
        },
        "01500": {
            "GenartName": "Visor",
            "NormTermName": "Visor",
            "AssGrpName": null,
            "UsageName": null
        },
        "01501": {
            "GenartName": "Tools",
            "NormTermName": "Tools",
            "AssGrpName": null,
            "UsageName": null
        },
        "01502": {
            "GenartName": "Accessory Kit, brake shoes",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoes"
        },
        "01503": {
            "GenartName": "Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "01504": {
            "GenartName": "Clamping Clip",
            "NormTermName": "Clamp",
            "AssGrpName": "Standard Parts",
            "UsageName": "Clamping Clip"
        },
        "01505": {
            "GenartName": "Hose Connector",
            "NormTermName": "Connector",
            "AssGrpName": "Standard Parts",
            "UsageName": "Hose"
        },
        "01506": {
            "GenartName": "Boot-/Cargo Area Lid",
            "NormTermName": "Lid",
            "AssGrpName": "Body",
            "UsageName": "Boot-/Cargo Area Lid"
        },
        "01507": {
            "GenartName": "Suspension Strut Mounting",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Suspension Strut Mounting"
        },
        "01508": {
            "GenartName": "Cover, radiator grille",
            "NormTermName": "Cover",
            "AssGrpName": "Body",
            "UsageName": "Radiator Grille"
        },
        "01509": {
            "GenartName": "Mounting Set, bumper",
            "NormTermName": "Holder Set",
            "AssGrpName": "Body",
            "UsageName": "Bumper"
        },
        "01510": {
            "GenartName": "Foot/Running Board",
            "NormTermName": "Step Board",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "01511": {
            "GenartName": "Cover, light",
            "NormTermName": "Cover",
            "AssGrpName": "Body",
            "UsageName": "Headlight"
        },
        "01512": {
            "GenartName": "Trim/Protection Strip, radiator grille",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body",
            "UsageName": "Radiator Grille"
        },
        "01513": {
            "GenartName": "Sensor Set, xenon light conversion (headlight levelling)",
            "NormTermName": "Sensor Kit",
            "AssGrpName": "Lights",
            "UsageName": "Xenon Light Conversion, (headlight levelling)"
        },
        "01514": {
            "GenartName": "Cover, heater control",
            "NormTermName": "Cover",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heater Control"
        },
        "01515": {
            "GenartName": "Lid",
            "NormTermName": "Lid",
            "AssGrpName": null,
            "UsageName": null
        },
        "01516": {
            "GenartName": "Filter",
            "NormTermName": "Filter",
            "AssGrpName": null,
            "UsageName": null
        },
        "01517": {
            "GenartName": "Clip",
            "NormTermName": "Clip",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "01518": {
            "GenartName": "Angle Drive",
            "NormTermName": "Angle Drive",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "01519": {
            "GenartName": "Switch Element",
            "NormTermName": "Switch Element",
            "AssGrpName": null,
            "UsageName": null
        },
        "01520": {
            "GenartName": "Rotor, valve rotation",
            "NormTermName": "Rotor",
            "AssGrpName": "Engine Timing",
            "UsageName": "Valve Rotation"
        },
        "01521": {
            "GenartName": "Adjusting Disc, valve clearance",
            "NormTermName": "Adjusting Disc",
            "AssGrpName": "Engine Timing",
            "UsageName": "Valve Clearance"
        },
        "01522": {
            "GenartName": "Timing Case",
            "NormTermName": "Housing",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Case"
        },
        "01523": {
            "GenartName": "Thrust Washer, clutch",
            "NormTermName": "Thrust Washer",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "01524": {
            "GenartName": "Brake Pedal",
            "NormTermName": "Pedal",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "01525": {
            "GenartName": "Clutch Pedal",
            "NormTermName": "Pedal",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "01526": {
            "GenartName": "Accelerator Pedal",
            "NormTermName": "Pedal",
            "AssGrpName": "Air Supply",
            "UsageName": "Throttle Valve"
        },
        "01527": {
            "GenartName": "Pressure Converter",
            "NormTermName": "Pressure Converter",
            "AssGrpName": null,
            "UsageName": null
        },
        "01528": {
            "GenartName": "Carbon Brush, alternator",
            "NormTermName": "Carbon Brush",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "01529": {
            "GenartName": "Rectifier, alternator",
            "NormTermName": "Rectifier",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "01530": {
            "GenartName": "Pinion, starter",
            "NormTermName": "Pinion",
            "AssGrpName": "Starter System",
            "UsageName": "Starter"
        },
        "01531": {
            "GenartName": "Carbon Brush, starter",
            "NormTermName": "Carbon Brush",
            "AssGrpName": "Starter System",
            "UsageName": "Starter"
        },
        "01532": {
            "GenartName": "Relay, low beam",
            "NormTermName": "Relay",
            "AssGrpName": "Lights",
            "UsageName": "Low Beam"
        },
        "01533": {
            "GenartName": "Cylinder Head Bolt",
            "NormTermName": "Screw",
            "AssGrpName": "Cylinder Head",
            "UsageName": null
        },
        "01534": {
            "GenartName": "Washer, cylinder head bolt",
            "NormTermName": "Washer",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Cylinder Head Bolt"
        },
        "01535": {
            "GenartName": "Brake Lining, drum brake",
            "NormTermName": "Brake Shoe Lining",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "01537": {
            "GenartName": "Brake Lining Kit, drum brake",
            "NormTermName": "Brake Shoe Lining Kit",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "01538": {
            "GenartName": "Level Control Switch, windscreen washer tank",
            "NormTermName": "Switch",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Wash Water Reserve"
        },
        "01539": {
            "GenartName": "Seal, spark plug stem",
            "NormTermName": "Seal",
            "AssGrpName": "Ignition System",
            "UsageName": "Plug Stem Seal"
        },
        "01541": {
            "GenartName": "Shift Valve, automatic transmission",
            "NormTermName": "Valve",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Switch"
        },
        "01542": {
            "GenartName": "Mounting, tie rod",
            "NormTermName": "Mounting",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod"
        },
        "01543": {
            "GenartName": "Manifold, exhaust system",
            "NormTermName": "Manifold",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "01545": {
            "GenartName": "Rubber Buffer, engine mounting system",
            "NormTermName": "Damper",
            "AssGrpName": "Engine Mounting System",
            "UsageName": "Buffer"
        },
        "01546": {
            "GenartName": "Bolt, brake caliper",
            "NormTermName": "Screw",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "01547": {
            "GenartName": "Steering Wheel",
            "NormTermName": "Steering Wheel",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "01548": {
            "GenartName": "Tow Cable",
            "NormTermName": "Cable",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Towing Aid"
        },
        "01549": {
            "GenartName": "Barrier Mesh, boot-/cargo area",
            "NormTermName": "Grille",
            "AssGrpName": "Accessories",
            "UsageName": "Boot-/Cargo Area"
        },
        "01550": {
            "GenartName": "Radio Mute Switch, parking distance control",
            "NormTermName": "Mute Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Distance Control"
        },
        "01551": {
            "GenartName": "Hand Held Transmitter, alarm system",
            "NormTermName": "Hand-held Transmitter",
            "AssGrpName": "Safety Systems",
            "UsageName": "Alarm System"
        },
        "01552": {
            "GenartName": "Transmitter Housing, alarm system",
            "NormTermName": "Housing",
            "AssGrpName": "Safety Systems",
            "UsageName": "Alarm System"
        },
        "01553": {
            "GenartName": "Hand-held Transmitter Housing, central locking",
            "NormTermName": "Housing",
            "AssGrpName": "Lock System",
            "UsageName": "Hand-held Transmitter for central locking"
        },
        "01554": {
            "GenartName": "Extension Line, ultrasonic module",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Safety Systems",
            "UsageName": "Ultrasonic Module"
        },
        "01555": {
            "GenartName": "Housing, manual transmission",
            "NormTermName": "Housing",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "01558": {
            "GenartName": "Cover, front apron",
            "NormTermName": "Cover",
            "AssGrpName": "Body",
            "UsageName": "Front Apron"
        },
        "01559": {
            "GenartName": "Seal, wing",
            "NormTermName": "Seal",
            "AssGrpName": "Body",
            "UsageName": "Wing"
        },
        "01560": {
            "GenartName": "Liner, wheelhouse",
            "NormTermName": "Panelling",
            "AssGrpName": "Body",
            "UsageName": "Wheelhouse"
        },
        "01561": {
            "GenartName": "Window Regulator",
            "NormTermName": "Window Regulator",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "01563": {
            "GenartName": "Steering Column Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Instruments",
            "UsageName": "Steering Column"
        },
        "01564": {
            "GenartName": "Voltage Stabiliser, instrument cluster",
            "NormTermName": "Regulator",
            "AssGrpName": "Instruments",
            "UsageName": "Voltage Stabilizing"
        },
        "01565": {
            "GenartName": "Spring Socket",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Standard Parts",
            "UsageName": "Spring Plug"
        },
        "01566": {
            "GenartName": "Support, bumper",
            "NormTermName": "Rack",
            "AssGrpName": "Body",
            "UsageName": "Bumper Reinforcement"
        },
        "01567": {
            "GenartName": "Mounting, shock absorber",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Shock Absorber"
        },
        "01568": {
            "GenartName": "Door Hinge",
            "NormTermName": "Hinge",
            "AssGrpName": "Body",
            "UsageName": "Door"
        },
        "01569": {
            "GenartName": "Holder Set, radiator grille",
            "NormTermName": "Holder Set",
            "AssGrpName": "Body",
            "UsageName": "Radiator Grille"
        },
        "01570": {
            "GenartName": "Trim/Protection Strip Set, radiator grille",
            "NormTermName": "Trim/Protection Strip Set",
            "AssGrpName": "Body",
            "UsageName": "Radiator Grille"
        },
        "01571": {
            "GenartName": "Support, radiator fan",
            "NormTermName": "Holder",
            "AssGrpName": "Cooling System",
            "UsageName": "Fan"
        },
        "01572": {
            "GenartName": "Conversion Kit, bumper",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Body",
            "UsageName": "Bumper"
        },
        "01573": {
            "GenartName": "Warm Air Duct",
            "NormTermName": "Pipe",
            "AssGrpName": "Body",
            "UsageName": "Warm Air"
        },
        "01574": {
            "GenartName": "Holder Set, fuel tank",
            "NormTermName": "Holder Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank"
        },
        "01586": {
            "GenartName": "Suspension Cross Brace",
            "NormTermName": "Strut",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Suspension Strut"
        },
        "01587": {
            "GenartName": "Stabiliser Kit",
            "NormTermName": "Stabiliser Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": null
        },
        "01589": {
            "GenartName": "Thrust Washer, crankshaft",
            "NormTermName": "Spacer",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft"
        },
        "01590": {
            "GenartName": "Axle Nut, drive shaft",
            "NormTermName": "Nut",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "01591": {
            "GenartName": "Intake Hose, air filter",
            "NormTermName": "Hose Line",
            "AssGrpName": "Air Supply",
            "UsageName": "Intake Hose"
        },
        "01593": {
            "GenartName": "Screw Kit, suspension strut/wheel bearing housing",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Suspension Strut/Wheel Bearing Housing"
        },
        "01594": {
            "GenartName": "Bush, starter shaft",
            "NormTermName": "Bush",
            "AssGrpName": "Starter System",
            "UsageName": "Starter Shaft"
        },
        "01595": {
            "GenartName": "Bushing, selector/shift rod",
            "NormTermName": "Bush",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Selector-/Shift Rod"
        },
        "01596": {
            "GenartName": "Bush, steering arm shaft",
            "NormTermName": "Bush",
            "AssGrpName": "Steering",
            "UsageName": "Steering Arm Shaft"
        },
        "01597": {
            "GenartName": "Mounting Kit, control lever",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Control/Trailing Arm"
        },
        "01599": {
            "GenartName": "Sleeve, control arm mounting",
            "NormTermName": "Sleeve",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Control Arm Mounting"
        },
        "01600": {
            "GenartName": "Hose, crankcase ventilation",
            "NormTermName": "Hose Line",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase Ventilation"
        },
        "01601": {
            "GenartName": "Grease",
            "NormTermName": "Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Grease"
        },
        "01602": {
            "GenartName": "Antifreeze",
            "NormTermName": "Antifreeze",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "01603": {
            "GenartName": "Frost Plug",
            "NormTermName": "Plug",
            "AssGrpName": "Crankcase",
            "UsageName": "Frost Plug"
        },
        "01604": {
            "GenartName": "Tube, oil dipstick",
            "NormTermName": "Funnel",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Dipstick"
        },
        "01605": {
            "GenartName": "Flange Lid, manual transmission",
            "NormTermName": "Lid",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Joint Flange"
        },
        "01606": {
            "GenartName": "Flange Lid, automatic transmission",
            "NormTermName": "Lid",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Joint Flange"
        },
        "01607": {
            "GenartName": "Shaft Seal, manual transmission flange",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Joint Flange"
        },
        "01608": {
            "GenartName": "Shaft Seal, automatic transmission flange",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Joint Flange"
        },
        "01609": {
            "GenartName": "Repair Kit, manual transmission flange",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Joint Flange"
        },
        "01610": {
            "GenartName": "Repair Kit, automatic transmission flange",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Joint Flange"
        },
        "01611": {
            "GenartName": "Bolt, propshaft flange",
            "NormTermName": "Screw",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Propshaft"
        },
        "01613": {
            "GenartName": "Threaded Sleeve, suspension strut",
            "NormTermName": "Sleeve",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Threaded Sleeve"
        },
        "01614": {
            "GenartName": "Bushing, axle bracket",
            "NormTermName": "Mounting",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Bracket"
        },
        "01615": {
            "GenartName": "Mounting, automatic transmission support",
            "NormTermName": "Mounting",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Transmission Carrier"
        },
        "01616": {
            "GenartName": "Mounting, manual transmission support",
            "NormTermName": "Mounting",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Carrier"
        },
        "01617": {
            "GenartName": "Mounting, axle strut",
            "NormTermName": "Mounting",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Strut"
        },
        "01618": {
            "GenartName": "Mounting, panhard rod",
            "NormTermName": "Mounting",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Panhard Rod"
        },
        "01620": {
            "GenartName": "Hydraulic Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Hydraulic Oil"
        },
        "01621": {
            "GenartName": "Link, timing chain",
            "NormTermName": "Chain Link",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain"
        },
        "01622": {
            "GenartName": "Sprocket/Gear, oil pump",
            "NormTermName": "Gear",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "01624": {
            "GenartName": "Mounting Set, drive shaft bellow",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Bellow drive shaft"
        },
        "01625": {
            "GenartName": "Counter Nut, valve clearance adjusting screw",
            "NormTermName": "Nut",
            "AssGrpName": "Engine Timing",
            "UsageName": "Valve Clearance Adjusting Screw"
        },
        "01626": {
            "GenartName": "Rolling Bearing, suspension strut support mount",
            "NormTermName": "Bearing",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Suspension Strut Support Mount"
        },
        "01627": {
            "GenartName": "Flywheel Bolt",
            "NormTermName": "Screw",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Flywheel"
        },
        "01628": {
            "GenartName": "Pulley Bolt",
            "NormTermName": "Screw",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Belt Pulley"
        },
        "01629": {
            "GenartName": "Pedal Pad, brake pedal",
            "NormTermName": "Pedal Pad",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "01630": {
            "GenartName": "Pedal Pad, clutch pedal",
            "NormTermName": "Pedal Pad",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "01631": {
            "GenartName": "Connecting Rod Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Connecting Rod"
        },
        "01632": {
            "GenartName": "Repair Kit, suspension strut support mount",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Suspension Strut Support Mount"
        },
        "01633": {
            "GenartName": "Repair Kit, gear shift lever",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gear Lever"
        },
        "01634": {
            "GenartName": "Chain, intermediate shaft",
            "NormTermName": "Chain",
            "AssGrpName": "Engine Timing",
            "UsageName": "Intermediate Shaft"
        },
        "01635": {
            "GenartName": "Bolt, charger mounting",
            "NormTermName": "Screw",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger"
        },
        "01636": {
            "GenartName": "Baffle Plate, oil sump",
            "NormTermName": "Shield",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Sump"
        },
        "01637": {
            "GenartName": "Vibration Damper, V-ribbed belt",
            "NormTermName": "Damper",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt Tension"
        },
        "01639": {
            "GenartName": "Bolt, cylinder head cover",
            "NormTermName": "Screw",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Cylinder Head Cover"
        },
        "01640": {
            "GenartName": "Fitting, intake manifold",
            "NormTermName": "Fitting",
            "AssGrpName": "Air Supply",
            "UsageName": "Intake Manifold"
        },
        "01641": {
            "GenartName": "Deflection/Guide Pulley, V-belt",
            "NormTermName": "Pulley",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Belt Guide/Deflection"
        },
        "01642": {
            "GenartName": "Deflection Shaft, gearshift mechanism",
            "NormTermName": "Shaft",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Deflection Shaft"
        },
        "01644": {
            "GenartName": "Valve Guide",
            "NormTermName": "Guide",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Valve Guide"
        },
        "01645": {
            "GenartName": "Adjusting Screw, valve clearance",
            "NormTermName": "Screw",
            "AssGrpName": "Engine Timing",
            "UsageName": "Valve Clearance Adjusting Screw"
        },
        "01646": {
            "GenartName": "Locking Knob",
            "NormTermName": "Knob",
            "AssGrpName": "Lock System",
            "UsageName": "Locking Knob"
        },
        "01648": {
            "GenartName": "Pre-chamber",
            "NormTermName": "Pre-chamber",
            "AssGrpName": "Cylinder Head",
            "UsageName": null
        },
        "01650": {
            "GenartName": "Selector-/Shift Rod",
            "NormTermName": "Rod",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Selector-/Shift Rod"
        },
        "01651": {
            "GenartName": "Repair Kit, intermediate shaft",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": "Intermediate Shaft"
        },
        "01652": {
            "GenartName": "Shaft Seal, intermediate shaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Engine Timing",
            "UsageName": "Intermediate Shaft"
        },
        "01653": {
            "GenartName": "Gasket, intermediate shaft",
            "NormTermName": "Seal",
            "AssGrpName": "Engine Timing",
            "UsageName": "Intermediate Shaft"
        },
        "01654": {
            "GenartName": "Shaft Seal, wheel bearing",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Bearing"
        },
        "01655": {
            "GenartName": "Cap, wheel bearing",
            "NormTermName": "Cap",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Bearing"
        },
        "01656": {
            "GenartName": "Circlip",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Standard Parts",
            "UsageName": "Circlip"
        },
        "01657": {
            "GenartName": "Screw, stub axle",
            "NormTermName": "Screw",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stub Axle"
        },
        "01658": {
            "GenartName": "Split Pin",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Standard Parts",
            "UsageName": "Split Pin"
        },
        "01659": {
            "GenartName": "Locking Pin",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Standard Parts",
            "UsageName": "Locking Pin"
        },
        "01660": {
            "GenartName": "Fastening Sheet, brake shoes",
            "NormTermName": "Base Plate",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoes"
        },
        "01661": {
            "GenartName": "Cover Sheet, brake drum",
            "NormTermName": "Shield",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Drum"
        },
        "01662": {
            "GenartName": "Fastening Screw, steering column",
            "NormTermName": "Screw",
            "AssGrpName": "Steering",
            "UsageName": "Steering Column"
        },
        "01663": {
            "GenartName": "Sleeve, stabiliser mounting",
            "NormTermName": "Sleeve",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser Mounting"
        },
        "01664": {
            "GenartName": "Ring Gear, crankshaft",
            "NormTermName": "Ring",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft"
        },
        "01665": {
            "GenartName": "Adaptor, electric filament",
            "NormTermName": "Adaptor",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Electric Coiled Cable"
        },
        "01666": {
            "GenartName": "Mounting Kit, diaphragm brake cylinder",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Diaphragm Brake Cylinder"
        },
        "01667": {
            "GenartName": "Transmission Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Transmission Oil"
        },
        "01668": {
            "GenartName": "Actuator, air conditioning",
            "NormTermName": "Control Unit",
            "AssGrpName": "Air Conditioning",
            "UsageName": null
        },
        "01669": {
            "GenartName": "Control Element, heating/ventilation",
            "NormTermName": "Control Unit",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": null
        },
        "01670": {
            "GenartName": "Starter Bush, clutch housing",
            "NormTermName": "Bush",
            "AssGrpName": "Starter System",
            "UsageName": "Bell Housing"
        },
        "01671": {
            "GenartName": "Spring Clamp Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leaf Spring"
        },
        "01672": {
            "GenartName": "Bush, steering shaft",
            "NormTermName": "Bush",
            "AssGrpName": "Steering",
            "UsageName": "Steering Shaft"
        },
        "01676": {
            "GenartName": "Collector End Shield Bush, starter",
            "NormTermName": "Bush",
            "AssGrpName": "Starter System",
            "UsageName": "Collector End Shield"
        },
        "01677": {
            "GenartName": "Noise Reduction Mat",
            "NormTermName": "Floor Mat",
            "AssGrpName": "Chemical Products",
            "UsageName": "Noise Reduction"
        },
        "01678": {
            "GenartName": "Mounting, alternator",
            "NormTermName": "Mounting",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "01679": {
            "GenartName": "Seal, air compressor",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "01680": {
            "GenartName": "Seal, flywheel",
            "NormTermName": "Seal",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Flywheel"
        },
        "01684": {
            "GenartName": "Gasket Set, planetary gearbox",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Planetary Gearbox"
        },
        "01685": {
            "GenartName": "Thrust Washer, planetary gearbox",
            "NormTermName": "Thrust Washer",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Planetary Gearbox"
        },
        "01686": {
            "GenartName": "Toothed Disc, planetary gearbox",
            "NormTermName": "Toothed Disc",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Planetary Gearbox"
        },
        "01687": {
            "GenartName": "Seal, planetary gearbox",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Planetary Gearbox"
        },
        "01688": {
            "GenartName": "Seal, planetary gearbox",
            "NormTermName": "Seal",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Planetary Gearbox"
        },
        "01689": {
            "GenartName": "Gasket Set, hydraulic pump",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Steering",
            "UsageName": "Hydraulic Pump"
        },
        "01690": {
            "GenartName": "Gasket Set, wheel hub",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Hub"
        },
        "01691": {
            "GenartName": "Ring, wheel hub",
            "NormTermName": "Ring",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Hub"
        },
        "01692": {
            "GenartName": "Pressure Switch, interaxle differential lock",
            "NormTermName": "Switch",
            "AssGrpName": "Instruments",
            "UsageName": "Interaxle Differential Lock"
        },
        "01693": {
            "GenartName": "Pressure Disc, spring shackle",
            "NormTermName": "Pressure Disc",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Shackle"
        },
        "01694": {
            "GenartName": "Nozzle Needle, carburettor",
            "NormTermName": "Nozzle Needle",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01696": {
            "GenartName": "Coiled Cable",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Electric Coiled Cable"
        },
        "01697": {
            "GenartName": "Breather Hose, fuel tank",
            "NormTermName": "Hose Line",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Tank Ventilation"
        },
        "01698": {
            "GenartName": "Rubber Buffer, driver cab",
            "NormTermName": "Buffer",
            "AssGrpName": "Driver Cab",
            "UsageName": "Rubber Buffer"
        },
        "01699": {
            "GenartName": "Mounting, leaf spring",
            "NormTermName": "Mounting",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leaf Spring"
        },
        "01700": {
            "GenartName": "Housing Cover, crankcase",
            "NormTermName": "Lid",
            "AssGrpName": "Crankcase",
            "UsageName": "Housing / Flange"
        },
        "01701": {
            "GenartName": "Adjuster, braking system",
            "NormTermName": "Adjuster",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "01702": {
            "GenartName": "Threaded Bolt, charger",
            "NormTermName": "Bolt",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger"
        },
        "01703": {
            "GenartName": "Sliding Shoe, window regulator",
            "NormTermName": "Sliding Shoe",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Window Regulator"
        },
        "01704": {
            "GenartName": "Guide Lining, timing chain",
            "NormTermName": "Lining, timing chain guide",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain"
        },
        "01705": {
            "GenartName": "Seal, coolant pipe",
            "NormTermName": "Seal",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Coolant"
        },
        "01706": {
            "GenartName": "Pressure Disc, leaf spring",
            "NormTermName": "Pressure Disc",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leaf Spring"
        },
        "01707": {
            "GenartName": "Mounting, spring shackle",
            "NormTermName": "Mounting",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Shackle"
        },
        "01708": {
            "GenartName": "Buffer, bonnet",
            "NormTermName": "Buffer",
            "AssGrpName": "Body",
            "UsageName": "Bonnet"
        },
        "01709": {
            "GenartName": "Mounting, transfer case",
            "NormTermName": "Mounting",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transfer Case"
        },
        "01710": {
            "GenartName": "Rubber Buffer, air filter",
            "NormTermName": "Buffer",
            "AssGrpName": "Air Supply",
            "UsageName": "Air Filter Stop"
        },
        "01711": {
            "GenartName": "Holder, throttle control linkage",
            "NormTermName": "Holder",
            "AssGrpName": "Air Supply",
            "UsageName": "Throttle Control Linkage"
        },
        "01712": {
            "GenartName": "Chock Holders",
            "NormTermName": "Holder",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Wheel Chock"
        },
        "01713": {
            "GenartName": "Holder, starter cable",
            "NormTermName": "Holder",
            "AssGrpName": "Starter System",
            "UsageName": "Choke Cable"
        },
        "01714": {
            "GenartName": "Seal, boot-/cargo area lid",
            "NormTermName": "Seal",
            "AssGrpName": "Body",
            "UsageName": "Tailgate"
        },
        "01715": {
            "GenartName": "Mudflap",
            "NormTermName": "Mudflap",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "01716": {
            "GenartName": "Tyre Chocks",
            "NormTermName": "Wheel Chock",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "01717": {
            "GenartName": "Supplementary Spring",
            "NormTermName": "Spring",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Auxiliary Spring"
        },
        "01718": {
            "GenartName": "Pressure Accumulator, pneumatic suspension",
            "NormTermName": "Pressure Accumulator",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "01719": {
            "GenartName": "Bolt, wheel cover",
            "NormTermName": "Screw",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Hub"
        },
        "01720": {
            "GenartName": "Cable Tie",
            "NormTermName": "Connector",
            "AssGrpName": "Standard Parts",
            "UsageName": "Cable Tie"
        },
        "01721": {
            "GenartName": "Fixing Strap, compressed air tank",
            "NormTermName": "Strap",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Compressed Air Tank"
        },
        "01722": {
            "GenartName": "Gear Set, oil pump",
            "NormTermName": "Gear Set",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "01723": {
            "GenartName": "Terminal Board, Trilex",
            "NormTermName": "Terminal Board",
            "AssGrpName": "Wheels",
            "UsageName": "Trilex"
        },
        "01724": {
            "GenartName": "Seal, brake caliper piston",
            "NormTermName": "Seal",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper Piston"
        },
        "01725": {
            "GenartName": "Contact Sleeve",
            "NormTermName": "Bush",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Contact Jack"
        },
        "01726": {
            "GenartName": "Contact Pin",
            "NormTermName": "Pin",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Contact Pin"
        },
        "01727": {
            "GenartName": "Ball Socket",
            "NormTermName": "Ball Socket",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "01728": {
            "GenartName": "Ball Head",
            "NormTermName": "Ball Head",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "01729": {
            "GenartName": "Universal Joint",
            "NormTermName": "Joint",
            "AssGrpName": "Standard Parts",
            "UsageName": "Universal Joint"
        },
        "01730": {
            "GenartName": "Securing Bolt",
            "NormTermName": "Bolt",
            "AssGrpName": "Standard Parts",
            "UsageName": "Securing Bolt"
        },
        "01731": {
            "GenartName": "Castle Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Castle Nut"
        },
        "01732": {
            "GenartName": "Holder, brake shaft bearing",
            "NormTermName": "Holder",
            "AssGrpName": "Braking System",
            "UsageName": "Brakeshaft Bearing"
        },
        "01735": {
            "GenartName": "Solenoid Switch, rotating beacon",
            "NormTermName": "Switch",
            "AssGrpName": "Signal System",
            "UsageName": "Rotating Beacon Solenoid Switch"
        },
        "01736": {
            "GenartName": "Diaphragm, diaphragm brake cylinder",
            "NormTermName": "Diaphragm",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Brake Cylinder"
        },
        "01737": {
            "GenartName": "Valve, retarder brake system",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Retarder Brake System"
        },
        "01738": {
            "GenartName": "Nut, transmission main shaft",
            "NormTermName": "Nut",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Main Shaft"
        },
        "01739": {
            "GenartName": "Nut, main shaft",
            "NormTermName": "Nut",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Main Shaft"
        },
        "01740": {
            "GenartName": "Nut, bevel gear",
            "NormTermName": "Nut",
            "AssGrpName": "Axle Drive",
            "UsageName": "Bevel Gear"
        },
        "01741": {
            "GenartName": "Adjusting Bolt, braking system",
            "NormTermName": "Bolt",
            "AssGrpName": "Braking System",
            "UsageName": "Automatic Adjustment"
        },
        "01742": {
            "GenartName": "Hub, crankshaft",
            "NormTermName": "Hub",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft"
        },
        "01743": {
            "GenartName": "Oil Pressure Switch, automatic transmission",
            "NormTermName": "Switch",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil Pressure"
        },
        "01744": {
            "GenartName": "Seal Ring, brake shoe",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoes"
        },
        "01745": {
            "GenartName": "Seal Ring, wheel hub",
            "NormTermName": "Seal",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Hub"
        },
        "01747": {
            "GenartName": "Seal Ring, dryer",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Dryer"
        },
        "01748": {
            "GenartName": "Hydraulic Pump, leveling control",
            "NormTermName": "Pump",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leveling Control"
        },
        "01749": {
            "GenartName": "Protection Lid, wheel hub",
            "NormTermName": "Lid",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Hub"
        },
        "01750": {
            "GenartName": "Relay, glow plug system",
            "NormTermName": "Relay",
            "AssGrpName": "Glow Ignition System",
            "UsageName": "Preheating"
        },
        "01751": {
            "GenartName": "Repair Kit, automatic clutch adjustment",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Clutch",
            "UsageName": "Automatic Adjustment"
        },
        "01752": {
            "GenartName": "Adjusting Disc, steering spindle",
            "NormTermName": "Adjusting Disc",
            "AssGrpName": "Steering",
            "UsageName": "Steering Spindle"
        },
        "01753": {
            "GenartName": "Bearing, clutch lever",
            "NormTermName": "Bearing",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Control"
        },
        "01754": {
            "GenartName": "Gear Set, oil pump",
            "NormTermName": "Rotor Set",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "01755": {
            "GenartName": "Spring, throttle control linkage",
            "NormTermName": "Spring",
            "AssGrpName": "Carburettor",
            "UsageName": "Throttle Control Linkage"
        },
        "01756": {
            "GenartName": "Spring, throttle control linkage",
            "NormTermName": "Spring",
            "AssGrpName": "Air Supply",
            "UsageName": "Throttle Control Linkage"
        },
        "01757": {
            "GenartName": "Pressure Switch, axle load limitation",
            "NormTermName": "Switch",
            "AssGrpName": "Axle Drive",
            "UsageName": "Axle Load Limitation"
        },
        "01758": {
            "GenartName": "Pressure Switch, axle load control",
            "NormTermName": "Switch",
            "AssGrpName": "Axle Drive",
            "UsageName": "Axle Load Control"
        },
        "01759": {
            "GenartName": "Switch, worklight",
            "NormTermName": "Switch",
            "AssGrpName": "Lights",
            "UsageName": "Work Lamp"
        },
        "01760": {
            "GenartName": "Switch, differential lock",
            "NormTermName": "Switch",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential Lock"
        },
        "01761": {
            "GenartName": "Switch, window regulator",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Window Regulator"
        },
        "01762": {
            "GenartName": "Switch, 'cab tilted' display",
            "NormTermName": "Switch",
            "AssGrpName": "Instruments",
            "UsageName": "Driver Cab Tilting"
        },
        "01763": {
            "GenartName": "Switch, splitter gearbox",
            "NormTermName": "Switch",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Splitter Gearbox"
        },
        "01764": {
            "GenartName": "Switch, retarder",
            "NormTermName": "Switch",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "01765": {
            "GenartName": "Switch, all-wheel drive",
            "NormTermName": "Switch",
            "AssGrpName": "Axle Drive",
            "UsageName": "All-Wheel Drive"
        },
        "01766": {
            "GenartName": "Switch, handbrake warning light",
            "NormTermName": "Switch",
            "AssGrpName": "Instruments",
            "UsageName": "Handbrake Control Lamp"
        },
        "01767": {
            "GenartName": "Switch, horn",
            "NormTermName": "Switch",
            "AssGrpName": "Signal System",
            "UsageName": "Horn"
        },
        "01768": {
            "GenartName": "Switch, wipe interval control",
            "NormTermName": "Switch",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Intermittant Wiper Control"
        },
        "01769": {
            "GenartName": "Switch, power take-off",
            "NormTermName": "Switch",
            "AssGrpName": "Power Take-Off",
            "UsageName": null
        },
        "01770": {
            "GenartName": "Switch, back-up alarm",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Back-up Alarm"
        },
        "01771": {
            "GenartName": "Switch, rotating beacon",
            "NormTermName": "Switch",
            "AssGrpName": "Signal System",
            "UsageName": "Rotating Beacon"
        },
        "01772": {
            "GenartName": "Switch, seat heating",
            "NormTermName": "Switch",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Seat Heating"
        },
        "01773": {
            "GenartName": "Switch, parking heater",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Heater"
        },
        "01774": {
            "GenartName": "Switch, door lock",
            "NormTermName": "Switch",
            "AssGrpName": "Lock System",
            "UsageName": "Door Lock"
        },
        "01775": {
            "GenartName": "Gear Lever Gaiter",
            "NormTermName": "Bellow",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gear Lever"
        },
        "01776": {
            "GenartName": "Bush, shift rod",
            "NormTermName": "Bush",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shift Rod"
        },
        "01777": {
            "GenartName": "Seal, sunroof",
            "NormTermName": "Seal",
            "AssGrpName": "Body",
            "UsageName": "Sunroof"
        },
        "01778": {
            "GenartName": "Hose, transmission oil cooler",
            "NormTermName": "Hose Line",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Transmission Oil Cooler"
        },
        "01779": {
            "GenartName": "Hose, heat exchanger (heating)",
            "NormTermName": "Hose Line",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heat Exchanger"
        },
        "01780": {
            "GenartName": "Holding Clamp",
            "NormTermName": "Clamp",
            "AssGrpName": "Standard Parts",
            "UsageName": "Holding Clamp"
        },
        "01781": {
            "GenartName": "Grease Nipple",
            "NormTermName": "Nipple",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "01782": {
            "GenartName": "Lock Cylinder, ignition lock",
            "NormTermName": "Lock Cylinder",
            "AssGrpName": "Lock System",
            "UsageName": "Ignition Lock"
        },
        "01784": {
            "GenartName": "Grille, rotating beacon",
            "NormTermName": "Grille",
            "AssGrpName": "Signal System",
            "UsageName": "Rotating Beacon"
        },
        "01785": {
            "GenartName": "Float-needle Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Carburettor",
            "UsageName": "Float Needle"
        },
        "01786": {
            "GenartName": "Vibration Damper, propshaft",
            "NormTermName": "Vibration Damper",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Propshaft"
        },
        "01787": {
            "GenartName": "Cable Pull, manual transmission",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gearshift"
        },
        "01788": {
            "GenartName": "Nut, exhaust manifold",
            "NormTermName": "Nut",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Manifold"
        },
        "01789": {
            "GenartName": "Nut, intake manifold",
            "NormTermName": "Nut",
            "AssGrpName": "Air Supply",
            "UsageName": "Intake Manifold"
        },
        "01790": {
            "GenartName": "Crank, parking brake",
            "NormTermName": "Crank",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake"
        },
        "01791": {
            "GenartName": "Plug, coiled cable",
            "NormTermName": "Plug",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Electric Coiled Cable"
        },
        "01792": {
            "GenartName": "Thermostat Housing",
            "NormTermName": "Housing",
            "AssGrpName": "Cooling System",
            "UsageName": "Thermostat Housing"
        },
        "01793": {
            "GenartName": "Protecting Agent, camshaft",
            "NormTermName": "Protection Agent",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft"
        },
        "01794": {
            "GenartName": "Vacuum Chamber, transmission control",
            "NormTermName": "Vacuum Cell",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Control"
        },
        "01800": {
            "GenartName": "Screw Plug, crankcase",
            "NormTermName": "Screw",
            "AssGrpName": "Crankcase",
            "UsageName": null
        },
        "01801": {
            "GenartName": "Seal, gearshift linkage",
            "NormTermName": "Seal",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gearshift Linkage"
        },
        "01802": {
            "GenartName": "Angled Ball Joint",
            "NormTermName": "Joint",
            "AssGrpName": "Standard Parts",
            "UsageName": "Angled Ball Joint"
        },
        "01805": {
            "GenartName": "Centering Bush, propshaft",
            "NormTermName": "Bush",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft Centering"
        },
        "01806": {
            "GenartName": "Towbar Eye, trailer hitch",
            "NormTermName": "Tow Eye",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "01807": {
            "GenartName": "Towbar Bush, trailer hitch",
            "NormTermName": "Bush",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Tow-bar Eye"
        },
        "01808": {
            "GenartName": "Kingpin",
            "NormTermName": "Kingpin",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "01809": {
            "GenartName": "Intermediate Shaft Kit",
            "NormTermName": "Shaft Kit",
            "AssGrpName": "Crankcase",
            "UsageName": "Intermediate Shaft"
        },
        "01810": {
            "GenartName": "Ball Stud, clutch control",
            "NormTermName": "Bolt",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Control"
        },
        "01811": {
            "GenartName": "Silicon Lubricant",
            "NormTermName": "Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Silicone"
        },
        "01812": {
            "GenartName": "High Temperature Lubricant",
            "NormTermName": "Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": "High Temperature Lubricant"
        },
        "01813": {
            "GenartName": "Copper Grease",
            "NormTermName": "Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Copper Grease"
        },
        "01814": {
            "GenartName": "Molybdenum Grease",
            "NormTermName": "Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Molybdenum Grease"
        },
        "01815": {
            "GenartName": "Universal Lubricant",
            "NormTermName": "Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Universal"
        },
        "01816": {
            "GenartName": "Body Adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Body Parts"
        },
        "01817": {
            "GenartName": "Body Spray Bond",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Body Spray Bond"
        },
        "01818": {
            "GenartName": "Adhesive, plastic repair",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Synthetic Material Repair"
        },
        "01819": {
            "GenartName": "Window Adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Window Fitting"
        },
        "01820": {
            "GenartName": "Adhesive Tape",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Adhesive Tape"
        },
        "01821": {
            "GenartName": "Rubber Adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Rubber / Rubber Profiles"
        },
        "01822": {
            "GenartName": "Glass-metal Adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Glass / Metal"
        },
        "01823": {
            "GenartName": "Bush / Bearing Adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Bush / Bearing"
        },
        "01824": {
            "GenartName": "Threadlocker",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Threadlocker"
        },
        "01825": {
            "GenartName": "Universal Adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Universal"
        },
        "01826": {
            "GenartName": "Super Glue",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Super Glue"
        },
        "01827": {
            "GenartName": "Sealing Adhesive Spray",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Seals"
        },
        "01828": {
            "GenartName": "PVC Adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "PVC"
        },
        "01829": {
            "GenartName": "Epoxy Adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Epoxy"
        },
        "01830": {
            "GenartName": "Adhesive Set, rearview mirror",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Rear View Mirror"
        },
        "01831": {
            "GenartName": "Metal Adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Metal"
        },
        "01832": {
            "GenartName": "Synthetic Material Adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Plastic"
        },
        "01833": {
            "GenartName": "Repair Kit, rear windscreen heater wire",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Chemical Products",
            "UsageName": "Rear Windscreen Heater Wire"
        },
        "01834": {
            "GenartName": "Repair Kit, plastics repair",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Chemical Products",
            "UsageName": "Synthetic Material Repair"
        },
        "01835": {
            "GenartName": "Repair Kit, window adhesive",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Chemical Products",
            "UsageName": "Window Fitting"
        },
        "01836": {
            "GenartName": "Repair Kit, exhaust system",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Chemical Products",
            "UsageName": "Exhaust System"
        },
        "01837": {
            "GenartName": "Reinforcing Strip, plastics repair",
            "NormTermName": "Reinforcing Strip",
            "AssGrpName": "Chemical Products",
            "UsageName": "Synthetic Material Repair"
        },
        "01838": {
            "GenartName": "Activator, window adhesive",
            "NormTermName": "Activator",
            "AssGrpName": "Chemical Products",
            "UsageName": "Window Fitting"
        },
        "01839": {
            "GenartName": "Primer, window adhesive",
            "NormTermName": "Primer",
            "AssGrpName": "Chemical Products",
            "UsageName": "Window Fitting"
        },
        "01840": {
            "GenartName": "Applicator",
            "NormTermName": "Applicator",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Window Fitting"
        },
        "01841": {
            "GenartName": "Preheating Box, cartridges",
            "NormTermName": "Preheating Box",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Cartridge"
        },
        "01842": {
            "GenartName": "Manual Spray Gun",
            "NormTermName": "Manual Spray Gun",
            "AssGrpName": "Machining Equipment",
            "UsageName": null
        },
        "01843": {
            "GenartName": "Dosing Tip, cartridges",
            "NormTermName": "Mixing Element",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Cartridge"
        },
        "01844": {
            "GenartName": "Compressed Air Spray Gun",
            "NormTermName": "Compressed Air Spray Gun",
            "AssGrpName": "Machining Equipment",
            "UsageName": null
        },
        "01845": {
            "GenartName": "Sealing Substance",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "01846": {
            "GenartName": "Solid Seal",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Solid Seal"
        },
        "01847": {
            "GenartName": "Sealing Tape",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Sealing Tape"
        },
        "01848": {
            "GenartName": "Housing / Flange Sealing Substance",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Housing / Flange"
        },
        "01849": {
            "GenartName": "Radiator Sealing Compound",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Radiator"
        },
        "01850": {
            "GenartName": "Seal Paste, exhaust system",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Exhaust System"
        },
        "01851": {
            "GenartName": "Underseal",
            "NormTermName": "Underseal",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "01852": {
            "GenartName": "Stone Chip Protection",
            "NormTermName": "Stone Chip Protection",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "01853": {
            "GenartName": "Body Cavity Protection",
            "NormTermName": "Preservative",
            "AssGrpName": "Chemical Products",
            "UsageName": "Body Cavity"
        },
        "01854": {
            "GenartName": "Engine Bay Preservative",
            "NormTermName": "Preservative",
            "AssGrpName": "Chemical Products",
            "UsageName": "Engine Compartment"
        },
        "01855": {
            "GenartName": "Zinc Spray",
            "NormTermName": "Primer Coat",
            "AssGrpName": "Chemical Products",
            "UsageName": "Zinc-dust Primer"
        },
        "01856": {
            "GenartName": "Spray Gun, underseal",
            "NormTermName": "Spray Gun",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Underseal"
        },
        "01857": {
            "GenartName": "Spray Gun, pressure bottle",
            "NormTermName": "Spray Gun",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Pressure Bottle"
        },
        "01858": {
            "GenartName": "Nylon Probe",
            "NormTermName": "Probe",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Nylon Probe"
        },
        "01859": {
            "GenartName": "Hook Probe",
            "NormTermName": "Probe",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Hook Probe"
        },
        "01860": {
            "GenartName": "Barrel System, body cavity/underseal",
            "NormTermName": "Barrel System",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Body-cavity / Underbody Protection"
        },
        "01861": {
            "GenartName": "Anti-drone Mat",
            "NormTermName": "Floor Mat",
            "AssGrpName": "Chemical Products",
            "UsageName": "Noise Reduction"
        },
        "01862": {
            "GenartName": "Engine Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Engine Oil"
        },
        "01863": {
            "GenartName": "Antifreeze, window cleaning system",
            "NormTermName": "Antifreeze",
            "AssGrpName": "Chemical Products",
            "UsageName": "Window Cleaning System"
        },
        "01864": {
            "GenartName": "Cleaner, window cleaning system",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Window Cleaning System"
        },
        "01865": {
            "GenartName": "Rust Solvent",
            "NormTermName": "Rust Solvent",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "01866": {
            "GenartName": "Pump Spray Can",
            "NormTermName": "Pump Spray Can",
            "AssGrpName": "Machining Equipment",
            "UsageName": null
        },
        "01867": {
            "GenartName": "Filler",
            "NormTermName": "Filler",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "01868": {
            "GenartName": "Primer",
            "NormTermName": "Primer Coat",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "01869": {
            "GenartName": "Rust Protection Primer",
            "NormTermName": "Primer Coat",
            "AssGrpName": "Chemical Products",
            "UsageName": "Rust Protection"
        },
        "01870": {
            "GenartName": "Rust Converter",
            "NormTermName": "Rust Converter",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "01871": {
            "GenartName": "Paint",
            "NormTermName": "Paint",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "01872": {
            "GenartName": "Hardener, paint",
            "NormTermName": "Hardener",
            "AssGrpName": "Chemical Products",
            "UsageName": "Paint"
        },
        "01873": {
            "GenartName": "Hardener, filler paste",
            "NormTermName": "Hardener",
            "AssGrpName": "Chemical Products",
            "UsageName": "Filler Paste"
        },
        "01874": {
            "GenartName": "Thinner",
            "NormTermName": "Thinner",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "01875": {
            "GenartName": "Glass-fibre Filler",
            "NormTermName": "Filler Paste",
            "AssGrpName": "Chemical Products",
            "UsageName": "Glass-fibre Filler"
        },
        "01876": {
            "GenartName": "Metal Filler",
            "NormTermName": "Filler Paste",
            "AssGrpName": "Chemical Products",
            "UsageName": "Metal Filler"
        },
        "01877": {
            "GenartName": "Filler Paste",
            "NormTermName": "Filler Paste",
            "AssGrpName": "Chemical Products",
            "UsageName": "Stopper"
        },
        "01878": {
            "GenartName": "Spray Filler",
            "NormTermName": "Filler Paste",
            "AssGrpName": "Chemical Products",
            "UsageName": "Spray Filler"
        },
        "01879": {
            "GenartName": "Universal Filler",
            "NormTermName": "Filler Paste",
            "AssGrpName": "Chemical Products",
            "UsageName": "Universal"
        },
        "01880": {
            "GenartName": "Skin Care Products",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Skin Care"
        },
        "01881": {
            "GenartName": "Skin Protection Products",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Skin Protection"
        },
        "01882": {
            "GenartName": "Hand Cleaners",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Skin Cleaning"
        },
        "01883": {
            "GenartName": "Brake / Clutch Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Brakes/ Clutch"
        },
        "01884": {
            "GenartName": "Rim Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Rims"
        },
        "01885": {
            "GenartName": "Textile / Carpet Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Textile/Carpet"
        },
        "01886": {
            "GenartName": "Engine Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Engine"
        },
        "01887": {
            "GenartName": "Universal Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Universal"
        },
        "01888": {
            "GenartName": "Cold Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Cold Cleaner Compound"
        },
        "01889": {
            "GenartName": "Cleaner / Thinner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Cleaner / Thinner"
        },
        "01890": {
            "GenartName": "Fast Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "01891": {
            "GenartName": "Paint Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Paint"
        },
        "01892": {
            "GenartName": "Tar Remover",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Tar Remover"
        },
        "01893": {
            "GenartName": "Window Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Window Pane"
        },
        "01894": {
            "GenartName": "Synthetic Material Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Plastic"
        },
        "01895": {
            "GenartName": "Polish",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Paint Polish"
        },
        "01896": {
            "GenartName": "Air Conditioning Cleaner/-Disinfecter",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Air Conditioning"
        },
        "01897": {
            "GenartName": "Insect Remover",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Insect Remover"
        },
        "01898": {
            "GenartName": "Synthetic Material Care Products",
            "NormTermName": "Car Care Products",
            "AssGrpName": "Chemical Products",
            "UsageName": "Plastic"
        },
        "01899": {
            "GenartName": "Rubber Care Products",
            "NormTermName": "Car Care Products",
            "AssGrpName": "Chemical Products",
            "UsageName": "Elastomer"
        },
        "01900": {
            "GenartName": "Valve Grinding Paste",
            "NormTermName": "Abrasives",
            "AssGrpName": "Chemical Products",
            "UsageName": "Valve Grinding"
        },
        "01901": {
            "GenartName": "Engine Gloss Paint",
            "NormTermName": "Paint",
            "AssGrpName": "Chemical Products",
            "UsageName": "Engine Cleaning"
        },
        "01902": {
            "GenartName": "Thread Sealer Paste",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Thread"
        },
        "01903": {
            "GenartName": "Window Sealer Paste",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Window Fitting"
        },
        "01904": {
            "GenartName": "Seal Optimizer",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Seal Optimizing"
        },
        "01905": {
            "GenartName": "O-Ring Repair Set",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Chemical Products",
            "UsageName": "O-Ring"
        },
        "01906": {
            "GenartName": "Body Sealer Paste",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Seam Sealing"
        },
        "01907": {
            "GenartName": "Sealer Paste, border-fold sealing",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Border-fold Sealing"
        },
        "01908": {
            "GenartName": "Synthetic Material Primer",
            "NormTermName": "Primer",
            "AssGrpName": "Chemical Products",
            "UsageName": "Synthetic Material Repair"
        },
        "01911": {
            "GenartName": "Spacer Plate, towing device",
            "NormTermName": "Spacer Plate",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "01913": {
            "GenartName": "Regulator",
            "NormTermName": "Regulator",
            "AssGrpName": null,
            "UsageName": null
        },
        "01914": {
            "GenartName": "Mirror Glass, exterior mirror",
            "NormTermName": "Mirror Glass",
            "AssGrpName": "Body",
            "UsageName": "Exterior Mirror"
        },
        "01915": {
            "GenartName": "Mirror Glass, exterior mirror",
            "NormTermName": "Mirror Glass",
            "AssGrpName": "Driver Cab",
            "UsageName": "Exterior Mirror"
        },
        "01916": {
            "GenartName": "Stop Lever, carburettor",
            "NormTermName": "Lever",
            "AssGrpName": "Carburettor",
            "UsageName": "Stop Lever"
        },
        "01917": {
            "GenartName": "Actuator Capsule, carburettor",
            "NormTermName": "Vacuum Cell",
            "AssGrpName": "Carburettor",
            "UsageName": "Actuator Capsule"
        },
        "01918": {
            "GenartName": "Bender Spring, carburettor",
            "NormTermName": "Spring",
            "AssGrpName": "Carburettor",
            "UsageName": "Bending Spring"
        },
        "01919": {
            "GenartName": "Holder, cable pull",
            "NormTermName": "Holder",
            "AssGrpName": "Carburettor",
            "UsageName": "Cable Pull"
        },
        "01920": {
            "GenartName": "Bypass Heater",
            "NormTermName": "Heater",
            "AssGrpName": "Carburettor",
            "UsageName": "Bypass"
        },
        "01921": {
            "GenartName": "Cover, carburettor",
            "NormTermName": "Lid",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01922": {
            "GenartName": "Seal Ring, carburettor",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01923": {
            "GenartName": "Torsion Spring, carburettor",
            "NormTermName": "Spring",
            "AssGrpName": "Carburettor",
            "UsageName": "Torsion Spring"
        },
        "01924": {
            "GenartName": "Throttle Control Lever, carburettor",
            "NormTermName": "Lever",
            "AssGrpName": "Carburettor",
            "UsageName": "Throttle Control Lever"
        },
        "01925": {
            "GenartName": "Holder, throttle control lever",
            "NormTermName": "Holder",
            "AssGrpName": "Carburettor",
            "UsageName": "Throttle Control Lever"
        },
        "01926": {
            "GenartName": "Sensor, throttle position",
            "NormTermName": "Sensor",
            "AssGrpName": "Carburettor",
            "UsageName": "Throttle Valve"
        },
        "01927": {
            "GenartName": "Shaft, throttle valve",
            "NormTermName": "Shaft",
            "AssGrpName": "Carburettor",
            "UsageName": "Throttle Valve"
        },
        "01928": {
            "GenartName": "Actuator, throttle valve",
            "NormTermName": "Actuator",
            "AssGrpName": "Carburettor",
            "UsageName": "Throttle Valve"
        },
        "01929": {
            "GenartName": "Nozzle, carburettor",
            "NormTermName": "Nozzle",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01930": {
            "GenartName": "Actuator Support",
            "NormTermName": "Holder",
            "AssGrpName": "Carburettor",
            "UsageName": "Actuator"
        },
        "01931": {
            "GenartName": "Nozzle Sleeve, carburettor",
            "NormTermName": "Sleeve",
            "AssGrpName": "Carburettor",
            "UsageName": "Injector Nozzle"
        },
        "01932": {
            "GenartName": "Adjusting Screw, carburettor",
            "NormTermName": "Screw",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01933": {
            "GenartName": "Change-over Ventilation, carburettor",
            "NormTermName": "Ventilation",
            "AssGrpName": "Carburettor",
            "UsageName": "Change-over Ventilation"
        },
        "01935": {
            "GenartName": "Screw, mixture control",
            "NormTermName": "Screw",
            "AssGrpName": "Carburettor",
            "UsageName": "Mixture Control"
        },
        "01936": {
            "GenartName": "Support, carburettor",
            "NormTermName": "Holder",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01937": {
            "GenartName": "Handle Button, carburettor",
            "NormTermName": "Knob",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01938": {
            "GenartName": "Main Jet Cutoff, carburettor",
            "NormTermName": "Shutoff",
            "AssGrpName": "Carburettor",
            "UsageName": "Main Jet"
        },
        "01939": {
            "GenartName": "Lever, carburettor",
            "NormTermName": "Lever",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01940": {
            "GenartName": "Cap, carburettor",
            "NormTermName": "Cap",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01941": {
            "GenartName": "Jamming Roller, carburettor",
            "NormTermName": "Pulley",
            "AssGrpName": "Carburettor",
            "UsageName": "Clamp Roller"
        },
        "01942": {
            "GenartName": "Clamping Screw, carburettor",
            "NormTermName": "Screw",
            "AssGrpName": "Carburettor",
            "UsageName": "Clamping Screw"
        },
        "01943": {
            "GenartName": "Connection Piece, fuel line",
            "NormTermName": "Fitting",
            "AssGrpName": "Carburettor",
            "UsageName": "Fuel Line"
        },
        "01944": {
            "GenartName": "Fuel Strainer, carburettor",
            "NormTermName": "Strainer",
            "AssGrpName": "Carburettor",
            "UsageName": "Fuel"
        },
        "01945": {
            "GenartName": "Mounting Bolt, carburettor",
            "NormTermName": "Bolt",
            "AssGrpName": "Carburettor",
            "UsageName": "Bearing"
        },
        "01946": {
            "GenartName": "Idle Jet, carburettor",
            "NormTermName": "Nozzle",
            "AssGrpName": "Carburettor",
            "UsageName": "Idle"
        },
        "01947": {
            "GenartName": "Idle Air Control, carburettor",
            "NormTermName": "Regulator",
            "AssGrpName": "Carburettor",
            "UsageName": "Idle Air"
        },
        "01949": {
            "GenartName": "Oval-head Screw, carburettor",
            "NormTermName": "Screw",
            "AssGrpName": "Carburettor",
            "UsageName": "Oval-head Screw"
        },
        "01950": {
            "GenartName": "Choke Shaft, carburettor",
            "NormTermName": "Shaft",
            "AssGrpName": "Carburettor",
            "UsageName": "Choke"
        },
        "01951": {
            "GenartName": "Earth Cable, carburettor",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Carburettor",
            "UsageName": "Earth Cable"
        },
        "01952": {
            "GenartName": "Diaphragm Lid, carburettor",
            "NormTermName": "Lid",
            "AssGrpName": "Carburettor",
            "UsageName": "Diaphragm"
        },
        "01953": {
            "GenartName": "Diaphragm, carburettor",
            "NormTermName": "Diaphragm",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01954": {
            "GenartName": "Driving Lever, carburettor",
            "NormTermName": "Lever",
            "AssGrpName": "Carburettor",
            "UsageName": "Driving Lever"
        },
        "01955": {
            "GenartName": "Needle Control, carburettor",
            "NormTermName": "Needle Control",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01956": {
            "GenartName": "Heating, carburettor",
            "NormTermName": "Heater",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01957": {
            "GenartName": "Pulldown Cell, carburettor",
            "NormTermName": "Vacuum Cell",
            "AssGrpName": "Carburettor",
            "UsageName": "Pulldown"
        },
        "01958": {
            "GenartName": "Pulldown Diaphragm, carburettor",
            "NormTermName": "Diaphragm",
            "AssGrpName": "Carburettor",
            "UsageName": "Pulldown"
        },
        "01959": {
            "GenartName": "Accelerator Pump, carburettor",
            "NormTermName": "Pump",
            "AssGrpName": "Carburettor",
            "UsageName": "Accelerator Pump"
        },
        "01960": {
            "GenartName": "Cover, accelerator pump",
            "NormTermName": "Lid",
            "AssGrpName": "Carburettor",
            "UsageName": "Accelerator Pump"
        },
        "01961": {
            "GenartName": "Lever, accelerator pump",
            "NormTermName": "Lever",
            "AssGrpName": "Carburettor",
            "UsageName": "Accelerator Pump"
        },
        "01962": {
            "GenartName": "Piston, accelerator pump",
            "NormTermName": "Piston",
            "AssGrpName": "Carburettor",
            "UsageName": "Accelerator Pump"
        },
        "01963": {
            "GenartName": "Injection Tube, accelerator pump",
            "NormTermName": "Injector Tube",
            "AssGrpName": "Carburettor",
            "UsageName": "Accelerator Pump"
        },
        "01964": {
            "GenartName": "Connecting Rod, accelerator pump",
            "NormTermName": "Rod",
            "AssGrpName": "Carburettor",
            "UsageName": "Accelerator Pump"
        },
        "01965": {
            "GenartName": "Control Screw, carburettor",
            "NormTermName": "Screw",
            "AssGrpName": "Carburettor",
            "UsageName": "Control Screw"
        },
        "01966": {
            "GenartName": "Tube, carburettor",
            "NormTermName": "Tube",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01967": {
            "GenartName": "Float, carburettor",
            "NormTermName": "Float",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01968": {
            "GenartName": "Repair Kit, float-needle valve",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Carburettor",
            "UsageName": "Float Needle"
        },
        "01969": {
            "GenartName": "Wing, carburettor",
            "NormTermName": "Oscillator",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01970": {
            "GenartName": "Strainer, carburettor",
            "NormTermName": "Strainer",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01971": {
            "GenartName": "Starter Lid, carburettor",
            "NormTermName": "Lid",
            "AssGrpName": "Carburettor",
            "UsageName": "Starter"
        },
        "01972": {
            "GenartName": "Starter Case, carburettor",
            "NormTermName": "Housing",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01974": {
            "GenartName": "Starter Lever, carburettor",
            "NormTermName": "Lever",
            "AssGrpName": "Carburettor",
            "UsageName": "Starter"
        },
        "01975": {
            "GenartName": "Choke Lever, carburettor",
            "NormTermName": "Lever",
            "AssGrpName": "Carburettor",
            "UsageName": "Choke"
        },
        "01976": {
            "GenartName": "Choke Valve Shaft, carburettor",
            "NormTermName": "Shaft",
            "AssGrpName": "Carburettor",
            "UsageName": "Choke"
        },
        "01977": {
            "GenartName": "Starter Body, carburettor",
            "NormTermName": "Starter Housing",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01978": {
            "GenartName": "Starter Kit, carburettor",
            "NormTermName": "Starter Kit",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01979": {
            "GenartName": "Connecting Rod, starter",
            "NormTermName": "Rod",
            "AssGrpName": "Carburettor",
            "UsageName": "Starter"
        },
        "01980": {
            "GenartName": "Plug, carburettor",
            "NormTermName": "Lid",
            "AssGrpName": "Carburettor",
            "UsageName": "Plug"
        },
        "01981": {
            "GenartName": "Vacuum Regulator, carburettor",
            "NormTermName": "Regulator",
            "AssGrpName": "Carburettor",
            "UsageName": "Vacuum"
        },
        "01982": {
            "GenartName": "Connecting Rod, carburettor",
            "NormTermName": "Rod",
            "AssGrpName": "Carburettor",
            "UsageName": null
        },
        "01983": {
            "GenartName": "Sealing Plug, carburettor",
            "NormTermName": "Screw",
            "AssGrpName": "Carburettor",
            "UsageName": "Sealing Cap"
        },
        "01984": {
            "GenartName": "Choke Actuator, carburettor",
            "NormTermName": "Actuator",
            "AssGrpName": "Carburettor",
            "UsageName": "Choke"
        },
        "01986": {
            "GenartName": "Holder, choke actuator",
            "NormTermName": "Holder",
            "AssGrpName": "Carburettor",
            "UsageName": "Choke"
        },
        "01987": {
            "GenartName": "Auxiliary Starter, carburettor",
            "NormTermName": "Starter",
            "AssGrpName": "Carburettor",
            "UsageName": "Auxiliary Starter"
        },
        "01988": {
            "GenartName": "Pre-Atomizer, carburettor",
            "NormTermName": "Atomizer",
            "AssGrpName": "Carburettor",
            "UsageName": "Pre-atomizer"
        },
        "01989": {
            "GenartName": "Connection Piece, coolant line",
            "NormTermName": "Fitting",
            "AssGrpName": "Carburettor",
            "UsageName": "Coolant"
        },
        "01990": {
            "GenartName": "Angle Tube, carburettor",
            "NormTermName": "Tube",
            "AssGrpName": "Carburettor",
            "UsageName": "Angle"
        },
        "01991": {
            "GenartName": "Tension Spring, carburettor",
            "NormTermName": "Spring",
            "AssGrpName": "Carburettor",
            "UsageName": "Tension Spring"
        },
        "01992": {
            "GenartName": "Intermediate Flange, carburettor",
            "NormTermName": "Flange",
            "AssGrpName": "Carburettor",
            "UsageName": "Intermediate Flange"
        },
        "01993": {
            "GenartName": "Control Unit, idle-speed control",
            "NormTermName": "Control Unit",
            "AssGrpName": "Air Supply",
            "UsageName": "Idle Control"
        },
        "01995": {
            "GenartName": "ABS Tester",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "ABS"
        },
        "01996": {
            "GenartName": "Tester, pneumatic governors",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Pneumatic Governor Units"
        },
        "01997": {
            "GenartName": "Tester, engine",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Engine"
        },
        "01998": {
            "GenartName": "Self-diagnosis Equipment",
            "NormTermName": "Diagnosis Unit",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "01999": {
            "GenartName": "Tester, lambda control",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "02000": {
            "GenartName": "Tester, ignition system",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Ignition System"
        },
        "02001": {
            "GenartName": "Multimeter",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Multimeter"
        },
        "02002": {
            "GenartName": "Timing Light",
            "NormTermName": "Test Lamp",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Ignition Point"
        },
        "02003": {
            "GenartName": "Tester, pressure / vacuum",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Pressure / Vacuum"
        },
        "02004": {
            "GenartName": "Tester, pressure loss",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Pressure Loss"
        },
        "02005": {
            "GenartName": "RPM Sensor, engine tester",
            "NormTermName": "Sensor",
            "AssGrpName": "Workshop Devices",
            "UsageName": "RPM"
        },
        "02006": {
            "GenartName": "CO Measurement Unit",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Devices",
            "UsageName": "CO"
        },
        "02007": {
            "GenartName": "Exhaust Gas Analyzer",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Exhaust Emission"
        },
        "02008": {
            "GenartName": "Emission Diagnosis Equipment",
            "NormTermName": "Diagnosis Unit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Exhaust Emission"
        },
        "02009": {
            "GenartName": "Brake Tester",
            "NormTermName": "Test Bench",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake"
        },
        "02010": {
            "GenartName": "Software, safety check §29",
            "NormTermName": "Software",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Safety Check §29"
        },
        "02011": {
            "GenartName": "Measuring Device, pedal force",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Pedal Force"
        },
        "02012": {
            "GenartName": "Tester, brake pressure",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Pressure"
        },
        "02013": {
            "GenartName": "Filling/Bleeding Unit, brake hydraulics",
            "NormTermName": "Bleeding Unit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Hydraulics"
        },
        "02014": {
            "GenartName": "Collector Can, brake bleeding",
            "NormTermName": "Tank",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Fluid"
        },
        "02015": {
            "GenartName": "Dynamometer",
            "NormTermName": "Test Bench",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Power"
        },
        "02016": {
            "GenartName": "Inspection Lane",
            "NormTermName": "Inspection Lane",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "02017": {
            "GenartName": "Tester, axle geometry",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Axle Geometry"
        },
        "02018": {
            "GenartName": "Test Bench, starter/alternator",
            "NormTermName": "Test Bench",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Starter/Alternator"
        },
        "02019": {
            "GenartName": "Voltage/current measurement device",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Voltage / Current"
        },
        "02020": {
            "GenartName": "Tester, three-phase generator",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Three-Phase Generator"
        },
        "02021": {
            "GenartName": "Battery Charger",
            "NormTermName": "Charger",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Battery"
        },
        "02022": {
            "GenartName": "Tester, battery",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Battery"
        },
        "02023": {
            "GenartName": "Test Bench, diesel injection pump",
            "NormTermName": "Test Bench",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Injection Pump"
        },
        "02024": {
            "GenartName": "Measuring Device, diesel injection quantities",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Injector Nozzle"
        },
        "02025": {
            "GenartName": "Suction Device, injection nozzle tester",
            "NormTermName": "Siphon",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Injector Nozzle Tester"
        },
        "02026": {
            "GenartName": "Tester, injector nozzles",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Injector Nozzle Tester"
        },
        "02027": {
            "GenartName": "Jump Starter",
            "NormTermName": "Starting Aid",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Start Aid"
        },
        "02028": {
            "GenartName": "Service Unit, air conditioning",
            "NormTermName": "Service Unit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Air Conditioning"
        },
        "02031": {
            "GenartName": "Radio / Cassette",
            "NormTermName": "Car Radio",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Radio / Cassette Deck"
        },
        "02032": {
            "GenartName": "Radio / CD",
            "NormTermName": "Car Radio",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Radio CD"
        },
        "02033": {
            "GenartName": "Minidisc Radio",
            "NormTermName": "Car Radio",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Radio / Minidisc"
        },
        "02034": {
            "GenartName": "Radio / CD Changer Combination",
            "NormTermName": "Radio / CD-Changer Combination",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": null
        },
        "02035": {
            "GenartName": "Minidisc Radio / CD Changer Combination",
            "NormTermName": "Radio / CD-Changer Combination",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Radio / Minidisc"
        },
        "02036": {
            "GenartName": "Radio / Navigation System Combination",
            "NormTermName": "Radio/ Navigation System Combination",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": null
        },
        "02037": {
            "GenartName": "Loudspeaker System",
            "NormTermName": "Loudspeaker System",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": null
        },
        "02038": {
            "GenartName": "Audio Amplifier",
            "NormTermName": "Booster",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Audio Amplifier"
        },
        "02039": {
            "GenartName": "Navigation System",
            "NormTermName": "Navigation System",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": null
        },
        "02040": {
            "GenartName": "Software, navigation system",
            "NormTermName": "Software",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Navigation System"
        },
        "02041": {
            "GenartName": "CD-ROM Road Map",
            "NormTermName": "Software",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "CD-ROM Road Map"
        },
        "02044": {
            "GenartName": "Valve, ABS regulation",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "ABS Control"
        },
        "02045": {
            "GenartName": "Pressure Relief Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Pressure Relief"
        },
        "02047": {
            "GenartName": "Control Unit, air injection",
            "NormTermName": "Control Unit",
            "AssGrpName": "Air Supply",
            "UsageName": "Air Injection"
        },
        "02048": {
            "GenartName": "Seal Ring",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "02049": {
            "GenartName": "Spring",
            "NormTermName": "Spring",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "02050": {
            "GenartName": "Software, system diagnosis",
            "NormTermName": "Software",
            "AssGrpName": "Workshop Devices",
            "UsageName": "System Diagnosis"
        },
        "02051": {
            "GenartName": "Seal Ring Set",
            "NormTermName": "Seal Ring Set",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "02052": {
            "GenartName": "Valve, lifting axle control",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Lifting Axle Control"
        },
        "02053": {
            "GenartName": "Seal Ring / Spring Set",
            "NormTermName": "Seal Ring-/Spring Set",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "02054": {
            "GenartName": "Sensor, exterior temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Exterior Temperature"
        },
        "02055": {
            "GenartName": "Sensor, coolant temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Coolant Temperature"
        },
        "02056": {
            "GenartName": "Noise Reduction Set, engine compartment",
            "NormTermName": "Noise Reduction Set",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "02057": {
            "GenartName": "Door Seal",
            "NormTermName": "Seal",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "02058": {
            "GenartName": "Proportional Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "ASR/Speed Limiting"
        },
        "02060": {
            "GenartName": "Blower Switch, heating/ventilation",
            "NormTermName": "Switch",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Blower"
        },
        "02061": {
            "GenartName": "Vacuum Cell, ignition distributor",
            "NormTermName": "Vacuum Cell",
            "AssGrpName": "Ignition System",
            "UsageName": "Distributor"
        },
        "02062": {
            "GenartName": "Pedal Travel Sensor, brake pedal",
            "NormTermName": "Sensor",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Pedal"
        },
        "02063": {
            "GenartName": "Window Crank",
            "NormTermName": "Crank",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "02064": {
            "GenartName": "Axle Bolt, drive shaft",
            "NormTermName": "Screw",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "02065": {
            "GenartName": "Nut, stub axle",
            "NormTermName": "Nut",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stub Axle"
        },
        "02066": {
            "GenartName": "Drag Link End",
            "NormTermName": "Joint",
            "AssGrpName": "Steering",
            "UsageName": "Bullbar"
        },
        "02067": {
            "GenartName": "Repair Kit, stabiliser coupling rod",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser Coupling Rod"
        },
        "02068": {
            "GenartName": "Bushing, stabiliser coupling rod",
            "NormTermName": "Mounting",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser Coupling Rod"
        },
        "02069": {
            "GenartName": "Bracket, stabilizer mounting",
            "NormTermName": "Holder",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser Mounting"
        },
        "02070": {
            "GenartName": "Wheel Bolt",
            "NormTermName": "Screw",
            "AssGrpName": "Wheels",
            "UsageName": null
        },
        "02072": {
            "GenartName": "Valve, electronic leveling control",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Electronic Ride Height Control"
        },
        "02073": {
            "GenartName": "Coolant Control Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Coolant"
        },
        "02074": {
            "GenartName": "Pressure Control Valve, trailer",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Trailer Brake Pressure"
        },
        "02075": {
            "GenartName": "Redundancy Valve, brake air regulation",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Redundancy"
        },
        "02076": {
            "GenartName": "Trailer Modulator",
            "NormTermName": "Pressure Modulator",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Trailer Brake Pressure"
        },
        "02077": {
            "GenartName": "Axle Modulator",
            "NormTermName": "Pressure Modulator",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Brake Pressure"
        },
        "02078": {
            "GenartName": "Manometer",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Manometer"
        },
        "02079": {
            "GenartName": "Antifreeze, compressed-air system",
            "NormTermName": "Antifreeze",
            "AssGrpName": "Chemical Products",
            "UsageName": "Compressed Air System"
        },
        "02080": {
            "GenartName": "Quick Coupling",
            "NormTermName": "Coupling",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Brake Line"
        },
        "02081": {
            "GenartName": "Testing Unit",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "ATC / ABS / ASR / TCS"
        },
        "02082": {
            "GenartName": "Brake Hose, compressed-air system",
            "NormTermName": "Hose Line",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Brake Hose"
        },
        "02083": {
            "GenartName": "Test Hose, compressed-air system",
            "NormTermName": "Hose Line",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Test Hose"
        },
        "02085": {
            "GenartName": "Actuator, blending flap",
            "NormTermName": "Actuator",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Blending Flap"
        },
        "02086": {
            "GenartName": "Bulb Holder, end outline marker light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights",
            "UsageName": "End Outline Marker Light"
        },
        "02089": {
            "GenartName": "Headlight Base, lights",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "02092": {
            "GenartName": "Fan Wheel, engine cooling",
            "NormTermName": "Fan Wheel",
            "AssGrpName": "Cooling System",
            "UsageName": null
        },
        "02094": {
            "GenartName": "High Pressure Line, air conditioning",
            "NormTermName": "Hose Line",
            "AssGrpName": "Air Conditioning",
            "UsageName": "High Pressure"
        },
        "02095": {
            "GenartName": "Low Pressure Line, air conditioning",
            "NormTermName": "Hose Line",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Low Pressure"
        },
        "02096": {
            "GenartName": "High-/Low Pressure Line, air conditioning",
            "NormTermName": "Hose Line",
            "AssGrpName": "Air Conditioning",
            "UsageName": "High Pressure/ Low Pressure"
        },
        "02101": {
            "GenartName": "Air Preparation Unit",
            "NormTermName": "Air Processing Unit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Dryer/ Pressure Control"
        },
        "02103": {
            "GenartName": "Release Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Release"
        },
        "02105": {
            "GenartName": "Relay Valve, EBS",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Electronic Brake System"
        },
        "02108": {
            "GenartName": "Vibration Damper, propshaft",
            "NormTermName": "Vibration Damper",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft"
        },
        "02109": {
            "GenartName": "Bearing, propshaft centre bearing",
            "NormTermName": "Bearing",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft Centre Bearing"
        },
        "02110": {
            "GenartName": "Bearing",
            "NormTermName": "Bearing",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "02111": {
            "GenartName": "Shaft Seal, propshaft centre bearing",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft Centre Bearing"
        },
        "02112": {
            "GenartName": "Cover Ring, propshaft centre bearing",
            "NormTermName": "Covering Ring",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft Centre Bearing"
        },
        "02113": {
            "GenartName": "Protective Cover, propshaft centre bearing",
            "NormTermName": "Lid",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft Centre Bearing"
        },
        "02114": {
            "GenartName": "Gasket, crankcase ventilation",
            "NormTermName": "Seal",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase Ventilation"
        },
        "02115": {
            "GenartName": "Accessory Kit, rocker arm",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": "Rocker Arm"
        },
        "02117": {
            "GenartName": "Piston,timing chain tensioner",
            "NormTermName": "Piston",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain Tensioner"
        },
        "02118": {
            "GenartName": "Gear, timing chain deflector",
            "NormTermName": "Gear",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain Deflection"
        },
        "02119": {
            "GenartName": "Repair Kit, oil sump",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Sump"
        },
        "02122": {
            "GenartName": "Locking Clip",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Standard Parts",
            "UsageName": "Locking Clip"
        },
        "02123": {
            "GenartName": "Spacer Sleeve",
            "NormTermName": "Sleeve",
            "AssGrpName": "Standard Parts",
            "UsageName": "Spacing"
        },
        "02124": {
            "GenartName": "Conversion Kit, accident data recorder",
            "NormTermName": "Custom Set",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Accident Data Recorder"
        },
        "02126": {
            "GenartName": "Bulb, position/end outline marker light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Position/End Outline Marker Light"
        },
        "02127": {
            "GenartName": "Sleeve",
            "NormTermName": "Sleeve",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "02128": {
            "GenartName": "Sealing/Protective Cap",
            "NormTermName": "Cap",
            "AssGrpName": "Standard Parts",
            "UsageName": "Sealing Cap"
        },
        "02130": {
            "GenartName": "Storage Bag, detachable ball rod",
            "NormTermName": "Bag",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Ball Rod Preservation"
        },
        "02131": {
            "GenartName": "Intermediate Shaft",
            "NormTermName": "Shaft",
            "AssGrpName": "Crankcase",
            "UsageName": "Intermediate Shaft"
        },
        "02132": {
            "GenartName": "Mounting, radiator",
            "NormTermName": "Mounting",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator"
        },
        "02133": {
            "GenartName": "Seal Ring, thermal switch",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cooling System",
            "UsageName": "Thermal Switch"
        },
        "02134": {
            "GenartName": "Temperature Switch, automatic choke",
            "NormTermName": "Switch",
            "AssGrpName": "Carburettor",
            "UsageName": "Automatic Choke"
        },
        "02135": {
            "GenartName": "Repair Kit, water pump breather",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump Breather"
        },
        "02136": {
            "GenartName": "Temperature Switch, intake manifold preheating",
            "NormTermName": "Switch",
            "AssGrpName": "Carburettor",
            "UsageName": "Intake Manifold Preheating"
        },
        "02137": {
            "GenartName": "Seal, timing chain tensioner",
            "NormTermName": "Seal",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain Tensioner"
        },
        "02138": {
            "GenartName": "Tensioner Pulley, V-belt",
            "NormTermName": "Pulley",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Belt Tension"
        },
        "02139": {
            "GenartName": "Rotor, oil pump",
            "NormTermName": "Rotor",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "02140": {
            "GenartName": "Sleeve, oil pump rotor",
            "NormTermName": "Sleeve",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump Rotor"
        },
        "02142": {
            "GenartName": "Lid, tensioner pulley lever",
            "NormTermName": "Lid",
            "AssGrpName": "Belt Drive",
            "UsageName": "Tensioner Pulley Lever"
        },
        "02143": {
            "GenartName": "Bush, tensioner pulley lever",
            "NormTermName": "Bearing",
            "AssGrpName": "Belt Drive",
            "UsageName": "Tensioner Pulley Lever"
        },
        "02144": {
            "GenartName": "Bearing Journal, tensioner pulley lever",
            "NormTermName": "Bearing Journal",
            "AssGrpName": "Belt Drive",
            "UsageName": "Tensioner Pulley Lever"
        },
        "02145": {
            "GenartName": "Shaft Seal, oil pump",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "02146": {
            "GenartName": "Retaining Spring",
            "NormTermName": "Spring",
            "AssGrpName": "Standard Parts",
            "UsageName": "Retaining Spring"
        },
        "02147": {
            "GenartName": "Lubricating Pipe, cam lubrication",
            "NormTermName": "Pipe",
            "AssGrpName": "Lubrication",
            "UsageName": "Cam Lobe Lubrication"
        },
        "02148": {
            "GenartName": "Screw Plug, coolant line",
            "NormTermName": "Screw",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Pipe"
        },
        "02149": {
            "GenartName": "Sealing Plug, coolant flange",
            "NormTermName": "Closure",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Flange"
        },
        "02151": {
            "GenartName": "Series Resistor, fuel pump",
            "NormTermName": "Resistor",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "02152": {
            "GenartName": "Master / Slave Cylinder Kit, clutch",
            "NormTermName": "Cylinder Set",
            "AssGrpName": "Clutch",
            "UsageName": "Master/ Slave Cylinder"
        },
        "02153": {
            "GenartName": "Control Unit, belt tensioner",
            "NormTermName": "Control Unit",
            "AssGrpName": "Safety Systems",
            "UsageName": "Safety Belt Tensioner"
        },
        "02169": {
            "GenartName": "Guide Bolt, brake caliper",
            "NormTermName": "Bolt",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper Guide"
        },
        "02170": {
            "GenartName": "Guide Sleeve, brake caliper",
            "NormTermName": "Sleeve",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper Guide"
        },
        "02176": {
            "GenartName": "Wheel-brake Cylinder Kit",
            "NormTermName": "Cylinder Set",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "02177": {
            "GenartName": "Hydraulic Pump, drive dynamics control",
            "NormTermName": "Pump",
            "AssGrpName": "Drive Dynamics Control",
            "UsageName": null
        },
        "02178": {
            "GenartName": "Seal, direction indicator",
            "NormTermName": "Seal",
            "AssGrpName": "Signal System",
            "UsageName": "Direction Indicator"
        },
        "02179": {
            "GenartName": "Reflector, direction indicator",
            "NormTermName": "Reflector",
            "AssGrpName": "Signal System",
            "UsageName": "Direction Indicator"
        },
        "02180": {
            "GenartName": "Reflector, position/end outline marker light",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights",
            "UsageName": "Position/End Outline Marker Light"
        },
        "02181": {
            "GenartName": "Holder, carbon brushes",
            "NormTermName": "Holder",
            "AssGrpName": "Starter System",
            "UsageName": "Carbon Brushes"
        },
        "02182": {
            "GenartName": "Freewheel Gear, starter",
            "NormTermName": "Transmission",
            "AssGrpName": "Starter System",
            "UsageName": "Freewheel"
        },
        "02183": {
            "GenartName": "Multi-Plate Clutch, starter",
            "NormTermName": "Coupling",
            "AssGrpName": "Starter System",
            "UsageName": "Starter"
        },
        "02184": {
            "GenartName": "Drive Bearing, starter",
            "NormTermName": "Bearing",
            "AssGrpName": "Starter System",
            "UsageName": "Drive-end Bearing"
        },
        "02185": {
            "GenartName": "Commutator End Shield, starter",
            "NormTermName": "Bearing",
            "AssGrpName": "Starter System",
            "UsageName": "Commutator"
        },
        "02186": {
            "GenartName": "Field Winding, starter",
            "NormTermName": "Winding",
            "AssGrpName": "Starter System",
            "UsageName": "Starter Actuation"
        },
        "02187": {
            "GenartName": "Armature, starter",
            "NormTermName": "Rotor",
            "AssGrpName": "Starter System",
            "UsageName": null
        },
        "02188": {
            "GenartName": "Relay, starter",
            "NormTermName": "Relay",
            "AssGrpName": "Starter System",
            "UsageName": null
        },
        "02189": {
            "GenartName": "Holder, carbon brushes",
            "NormTermName": "Holder",
            "AssGrpName": "Alternator",
            "UsageName": "Carbon Brushes"
        },
        "02190": {
            "GenartName": "Drive Bearing, alternator",
            "NormTermName": "Bearing",
            "AssGrpName": "Alternator",
            "UsageName": "Drive-end Bearing"
        },
        "02191": {
            "GenartName": "Slip Ring Bearing, alternator",
            "NormTermName": "Bearing",
            "AssGrpName": "Alternator",
            "UsageName": "Slip Ring"
        },
        "02192": {
            "GenartName": "Stator, alternator",
            "NormTermName": "Stator",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "02193": {
            "GenartName": "Rotor, alternator",
            "NormTermName": "Rotor",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "02194": {
            "GenartName": "Diode Holder, alternator",
            "NormTermName": "Diode Holder",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "02195": {
            "GenartName": "Siren, alarm system",
            "NormTermName": "Siren",
            "AssGrpName": "Safety Systems",
            "UsageName": "Alarm System"
        },
        "02196": {
            "GenartName": "Siren, alarm system",
            "NormTermName": "Siren",
            "AssGrpName": "Lock System",
            "UsageName": "Alarm System"
        },
        "02197": {
            "GenartName": "Shift Selector Lever",
            "NormTermName": "Switch",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Shift Position"
        },
        "02198": {
            "GenartName": "Shift Selector Lever",
            "NormTermName": "Switch",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shift Position"
        },
        "02199": {
            "GenartName": "Potentiometer",
            "NormTermName": "Potentiometer",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "02200": {
            "GenartName": "Gauge, brake pressure",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Brake Pressure"
        },
        "02201": {
            "GenartName": "Sensor, oil temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Instruments",
            "UsageName": "Oil Temperature"
        },
        "02202": {
            "GenartName": "Angle Drive, speedometer cable",
            "NormTermName": "Angle Drive",
            "AssGrpName": "Instruments",
            "UsageName": "Speedometer"
        },
        "02203": {
            "GenartName": "Lid, gauge",
            "NormTermName": "Lid",
            "AssGrpName": "Instruments",
            "UsageName": null
        },
        "02204": {
            "GenartName": "Cockpit Housing",
            "NormTermName": "Housing",
            "AssGrpName": "Instruments",
            "UsageName": "Cockpit"
        },
        "02205": {
            "GenartName": "Signal Unit",
            "NormTermName": "Signal Unit",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "02206": {
            "GenartName": "Seal, headlight frame",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Headlight Frame"
        },
        "02207": {
            "GenartName": "Seal, end outline marker light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "End Outline Marker Light"
        },
        "02212": {
            "GenartName": "Control Unit, automatic clutch",
            "NormTermName": "Control Unit",
            "AssGrpName": "Clutch",
            "UsageName": "Automatic Clutch"
        },
        "02222": {
            "GenartName": "Valve, flame starter system",
            "NormTermName": "Valve",
            "AssGrpName": "Glow Ignition System",
            "UsageName": "Flame Start System"
        },
        "02223": {
            "GenartName": "Insulator, glow plug",
            "NormTermName": "Insulator",
            "AssGrpName": "Glow Ignition System",
            "UsageName": "Glow Plug"
        },
        "02229": {
            "GenartName": "Valve, tyre-pressure monitoring system",
            "NormTermName": "Valve",
            "AssGrpName": "Wheels",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "02230": {
            "GenartName": "Control Unit, tyre-pressure monitoring system",
            "NormTermName": "Control Unit",
            "AssGrpName": "Wheels",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "02231": {
            "GenartName": "Aerial, tyre-pressure monitoring system",
            "NormTermName": "Aerial",
            "AssGrpName": "Wheels",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "02232": {
            "GenartName": "Wheel Sensor, tyre-pressure monitoring system",
            "NormTermName": "Sensor",
            "AssGrpName": "Wheels",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "02233": {
            "GenartName": "Connector Pipe, tyre-pressure monitoring system",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Wheels",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "02234": {
            "GenartName": "Charger, charging (supercharged/turbocharged)",
            "NormTermName": "Charger",
            "AssGrpName": "Air Supply",
            "UsageName": null
        },
        "02235": {
            "GenartName": "Spindle Drive, parking brake caliper",
            "NormTermName": "Spindle",
            "AssGrpName": "Braking System",
            "UsageName": "Parking brake brake caliper"
        },
        "02236": {
            "GenartName": "Holder, brake fluid reservoir",
            "NormTermName": "Holder",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Fluid Reservoir"
        },
        "02238": {
            "GenartName": "Surge Brake",
            "NormTermName": "Brake Equipment Group",
            "AssGrpName": "Braking System",
            "UsageName": "Overrun Brake"
        },
        "02239": {
            "GenartName": "Socket Adapter",
            "NormTermName": "Adaptor",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Socket"
        },
        "02240": {
            "GenartName": "Control Unit, door closing aid",
            "NormTermName": "Control Unit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Door Latch Aid"
        },
        "02241": {
            "GenartName": "Spring Kit",
            "NormTermName": "Spring Kit",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "02242": {
            "GenartName": "Valve, hydraulic unit",
            "NormTermName": "Valve",
            "AssGrpName": "Braking System",
            "UsageName": "Hydraulic Unit"
        },
        "02243": {
            "GenartName": "Relay Set, rear fog light",
            "NormTermName": "Relay Set",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Rear Fog Light"
        },
        "02245": {
            "GenartName": "Holder, warning-contact cable",
            "NormTermName": "Holder",
            "AssGrpName": "Braking System",
            "UsageName": "Warning Contact Cable"
        },
        "02246": {
            "GenartName": "Guide, warning-contact cable",
            "NormTermName": "Guide",
            "AssGrpName": "Braking System",
            "UsageName": "Warning Contact Cable"
        },
        "02247": {
            "GenartName": "Cover, brake caliper",
            "NormTermName": "Shield",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "02248": {
            "GenartName": "Piston, brake caliper",
            "NormTermName": "Piston",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "02249": {
            "GenartName": "Rod, slave cylinder",
            "NormTermName": "Rod",
            "AssGrpName": "Clutch",
            "UsageName": "Slave Cylinder"
        },
        "02250": {
            "GenartName": "Rod, master cylinder",
            "NormTermName": "Rod",
            "AssGrpName": "Clutch",
            "UsageName": "Master Cylinder"
        },
        "02251": {
            "GenartName": "Rod, brake master cylinder",
            "NormTermName": "Rod",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Master Cylinder"
        },
        "02252": {
            "GenartName": "Rod, wheel brake cylinder",
            "NormTermName": "Rod",
            "AssGrpName": "Braking System",
            "UsageName": "Wheel Brake Cylinder"
        },
        "02253": {
            "GenartName": "Split Sleeve",
            "NormTermName": "Sleeve",
            "AssGrpName": "Standard Parts",
            "UsageName": "Clamp"
        },
        "02254": {
            "GenartName": "Sensor Ring, ABS",
            "NormTermName": "Sensor Ring",
            "AssGrpName": "Braking System",
            "UsageName": "ABS"
        },
        "02256": {
            "GenartName": "Bolts/Screws Assortment",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Screw"
        },
        "02257": {
            "GenartName": "Bolt and Nut Kit",
            "NormTermName": "Fastener Set",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "02258": {
            "GenartName": "Connection Piece, hose line",
            "NormTermName": "Fitting",
            "AssGrpName": "Standard Parts",
            "UsageName": "Hose"
        },
        "02259": {
            "GenartName": "Sealing-/Protection Plugs",
            "NormTermName": "Plug",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "02260": {
            "GenartName": "Test Connection, piping",
            "NormTermName": "Test Connection",
            "AssGrpName": "Standard Parts",
            "UsageName": "Pipe"
        },
        "02261": {
            "GenartName": "Connector, pipes",
            "NormTermName": "Connector",
            "AssGrpName": "Standard Parts",
            "UsageName": "Pipe"
        },
        "02262": {
            "GenartName": "Relay, horn/signal horn",
            "NormTermName": "Relay",
            "AssGrpName": "Signal System",
            "UsageName": "Horn"
        },
        "02263": {
            "GenartName": "Main Switch, battery",
            "NormTermName": "Switch",
            "AssGrpName": "Battery",
            "UsageName": null
        },
        "02264": {
            "GenartName": "Switch, preheating system",
            "NormTermName": "Switch",
            "AssGrpName": "Glow Ignition System",
            "UsageName": "Glow Time"
        },
        "02265": {
            "GenartName": "Engagement Lever, starter",
            "NormTermName": "Lever",
            "AssGrpName": "Starter System",
            "UsageName": "Lever"
        },
        "02266": {
            "GenartName": "Repair Kit, starter",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Starter System",
            "UsageName": null
        },
        "02267": {
            "GenartName": "Field Winding, alternator",
            "NormTermName": "Winding",
            "AssGrpName": "Alternator",
            "UsageName": "Magnetic Field"
        },
        "02268": {
            "GenartName": "Protective Cap, alternator",
            "NormTermName": "Cap",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "02269": {
            "GenartName": "Repair Set, alternator",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "02272": {
            "GenartName": "Sensor, fuel tank pressure",
            "NormTermName": "Sensor",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank"
        },
        "02273": {
            "GenartName": "Electric Motor",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "02275": {
            "GenartName": "Rain Sensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Rain Sensor"
        },
        "02276": {
            "GenartName": "Rain Sensor Retrofit Kit",
            "NormTermName": "Sensor Kit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Rain Sensor"
        },
        "02277": {
            "GenartName": "Control Unit, rain sensor",
            "NormTermName": "Control Unit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Rain Sensor"
        },
        "02278": {
            "GenartName": "Cable Set, rain sensor",
            "NormTermName": "Electric Cable Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Rain Sensor"
        },
        "02279": {
            "GenartName": "Cover, rain sensor",
            "NormTermName": "Cap",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Rain Sensor"
        },
        "02280": {
            "GenartName": "Temperature Switch, EGR",
            "NormTermName": "Switch",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": null
        },
        "02281": {
            "GenartName": "Connecting Cable, accident data recorder",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Accident Data Recorder"
        },
        "02282": {
            "GenartName": "Repair Kit, hydraulic unit",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Hydraulic Unit"
        },
        "02283": {
            "GenartName": "Repair Kit, distributor",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Ignition System",
            "UsageName": "Distributor"
        },
        "02284": {
            "GenartName": "Ignition Coil Unit",
            "NormTermName": "Ignition Coil Unit",
            "AssGrpName": "Ignition System",
            "UsageName": null
        },
        "02285": {
            "GenartName": "Dust Cover, distributor",
            "NormTermName": "Cap",
            "AssGrpName": "Ignition System",
            "UsageName": "Dust Cover"
        },
        "02286": {
            "GenartName": "Shield, distributor",
            "NormTermName": "Cover",
            "AssGrpName": "Ignition System",
            "UsageName": "Distributor"
        },
        "02288": {
            "GenartName": "Protective Cap, spark plug",
            "NormTermName": "Cap",
            "AssGrpName": "Ignition System",
            "UsageName": "Spark Plug Head"
        },
        "02289": {
            "GenartName": "Protective Cap, ignition coil plug",
            "NormTermName": "Cap",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition Coil Plug"
        },
        "02290": {
            "GenartName": "Protective Cap, distributor plug",
            "NormTermName": "Cap",
            "AssGrpName": "Ignition System",
            "UsageName": "Distributor Plug"
        },
        "02291": {
            "GenartName": "Magneto",
            "NormTermName": "Magneto",
            "AssGrpName": null,
            "UsageName": null
        },
        "02292": {
            "GenartName": "Contact Set, magneto",
            "NormTermName": "Contact Breaker Set",
            "AssGrpName": null,
            "UsageName": "Magneto"
        },
        "02293": {
            "GenartName": "Capaciter, magneto",
            "NormTermName": "Condenser",
            "AssGrpName": null,
            "UsageName": "Magneto"
        },
        "02294": {
            "GenartName": "Transistor, magneto",
            "NormTermName": "Control Unit",
            "AssGrpName": null,
            "UsageName": "Magneto"
        },
        "02295": {
            "GenartName": "Control Unit, EPS",
            "NormTermName": "Control Unit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "EPS"
        },
        "02296": {
            "GenartName": "Spring, brake caliper",
            "NormTermName": "Spring",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "02297": {
            "GenartName": "Stop Disc, brake master cylinder",
            "NormTermName": "Stop Plate",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Master Cylinder"
        },
        "02298": {
            "GenartName": "Valve Piston, brake booster",
            "NormTermName": "Valve Piston",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Booster"
        },
        "02299": {
            "GenartName": "Bellow, brake master cylinder",
            "NormTermName": "Bellow",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Master Cylinder"
        },
        "02300": {
            "GenartName": "Bellow, wheel brake cylinder",
            "NormTermName": "Bellow",
            "AssGrpName": "Braking System",
            "UsageName": "Wheel Brake Cylinder"
        },
        "02301": {
            "GenartName": "Bellow, master cylinder",
            "NormTermName": "Bellow",
            "AssGrpName": "Clutch",
            "UsageName": "Master Cylinder"
        },
        "02302": {
            "GenartName": "Bellow, slave cylinder",
            "NormTermName": "Bellow",
            "AssGrpName": "Clutch",
            "UsageName": "Slave Cylinder"
        },
        "02303": {
            "GenartName": "Vacuum Hose, brake booster",
            "NormTermName": "Hose Line",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Booster"
        },
        "02304": {
            "GenartName": "Springwasher",
            "NormTermName": "Springwasher",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "02305": {
            "GenartName": "Plug, brake fluid reservoir",
            "NormTermName": "Plug",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Fluid Reservoir"
        },
        "02306": {
            "GenartName": "Connection Piece, vacuum hose",
            "NormTermName": "Fitting",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Booster"
        },
        "02307": {
            "GenartName": "Seal, vacuum hose connector pipe",
            "NormTermName": "Seal",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum Connection Nozzle"
        },
        "02308": {
            "GenartName": "Pressure Switch, brake hydraulics",
            "NormTermName": "Switch",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Pressure"
        },
        "02309": {
            "GenartName": "Valve, vacuum hose",
            "NormTermName": "Valve",
            "AssGrpName": "Standard Parts",
            "UsageName": "Vacuum Line"
        },
        "02310": {
            "GenartName": "Union Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Union Nut"
        },
        "02311": {
            "GenartName": "Union Bolt",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Union Nut"
        },
        "02312": {
            "GenartName": "Screw Plug",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Sealing Cap"
        },
        "02313": {
            "GenartName": "Banjo Bolt",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Banjo Bolt"
        },
        "02314": {
            "GenartName": "Breather Screw/Valve",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Bleeding"
        },
        "02315": {
            "GenartName": "Adapter, brake line",
            "NormTermName": "Adaptor",
            "AssGrpName": "Standard Parts",
            "UsageName": "Brake Line"
        },
        "02316": {
            "GenartName": "Banjo Fitting, pipe",
            "NormTermName": "Fitting",
            "AssGrpName": "Standard Parts",
            "UsageName": "Banjo Fitting"
        },
        "02317": {
            "GenartName": "Assortment, parts magazine",
            "NormTermName": "Magazine",
            "AssGrpName": "Standard Parts",
            "UsageName": "Miscellaneous Parts"
        },
        "02318": {
            "GenartName": "Assortment, fittings",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Instruments"
        },
        "02319": {
            "GenartName": "Assortment, breather screws",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Breather Screws"
        },
        "02320": {
            "GenartName": "Assortment, fasteners",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Fasteners"
        },
        "02321": {
            "GenartName": "Preservative, brake parts",
            "NormTermName": "Preservative",
            "AssGrpName": "Chemical Products",
            "UsageName": "Brake"
        },
        "02322": {
            "GenartName": "Water Tank, radiator",
            "NormTermName": "Van",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator"
        },
        "02323": {
            "GenartName": "Cowling, radiator fan",
            "NormTermName": "Cover",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator"
        },
        "02325": {
            "GenartName": "Testing Unit, brake fluid",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Fluid"
        },
        "02326": {
            "GenartName": "Sampling Set, brake fluid",
            "NormTermName": "Sampling Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Fluid"
        },
        "02327": {
            "GenartName": "Testing Unit, braking system",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Braking System"
        },
        "02328": {
            "GenartName": "Tester Set, hydraulic / pneumatic pressure",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Tester, hydraulic / pneumatic pressure"
        },
        "02329": {
            "GenartName": "Extension Cable, pressure sensor",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Extension Cable, pressure sensor"
        },
        "02330": {
            "GenartName": "Clutch, test connection",
            "NormTermName": "Coupling",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Test Connection"
        },
        "02331": {
            "GenartName": "Adaptor Nipple, breather valve pressure sensor",
            "NormTermName": "Nipple",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Adaptor Nipple, pressure sensor"
        },
        "02332": {
            "GenartName": "Pedal Depressor",
            "NormTermName": "Clamping Tool",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Pedal, brake / clutch"
        },
        "02333": {
            "GenartName": "Tester Set, brake disc runout check",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake disc runout check"
        },
        "02334": {
            "GenartName": "Electric Cable, cigarette lighter connection",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Cigarette lighter connector"
        },
        "02335": {
            "GenartName": "Tester Set, brake fluid boiling point",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Tester, brake fluid boiling point"
        },
        "02336": {
            "GenartName": "Testing Unit, hydraulic / pneumatic pressure",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Tester, hydraulic / pneumatic pressure"
        },
        "02337": {
            "GenartName": "Extension Hose, pressure tester unit",
            "NormTermName": "Hose Line",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Pressure Tester"
        },
        "02338": {
            "GenartName": "Measurement Device, brake disc runout",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Disc Runout"
        },
        "02339": {
            "GenartName": "Siphon, brake fluid",
            "NormTermName": "Siphon",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Fluid Siphoning"
        },
        "02340": {
            "GenartName": "Suction Balloon, brake fluid",
            "NormTermName": "Gaiter",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Fluid Siphoning"
        },
        "02341": {
            "GenartName": "Bleeding Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake System Bleeding"
        },
        "02342": {
            "GenartName": "Connecting Hose, bleed unit, bleed valve",
            "NormTermName": "Hose Line",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Venting unit valve connector"
        },
        "02343": {
            "GenartName": "Filler Gun, brake fluid",
            "NormTermName": "Filler Gun",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Fluid"
        },
        "02344": {
            "GenartName": "Spout, brake fluid",
            "NormTermName": "Spout",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Fluid"
        },
        "02345": {
            "GenartName": "Bleeder Adaptor Set",
            "NormTermName": "Adaptor Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Coupling, bleeder unit expansion tank"
        },
        "02346": {
            "GenartName": "Spanner Set, ring / open ended",
            "NormTermName": "Spanner Set, ring / open ended",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bleeder Screw/Valve"
        },
        "02347": {
            "GenartName": "Double Ring Spanner, bleeder screw/valve",
            "NormTermName": "Spanner, double ring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bleeder Screw/Valve"
        },
        "02348": {
            "GenartName": "Filler Tap, brake fluid",
            "NormTermName": "Valve",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filler, brake fluid"
        },
        "02349": {
            "GenartName": "Disposal Unit, brake fluid",
            "NormTermName": "Disposal Unit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Fluid"
        },
        "02350": {
            "GenartName": "Connecting Hose, collection tank disposal unit",
            "NormTermName": "Pipe",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Disposal unit collecting container connector"
        },
        "02351": {
            "GenartName": "Pin Driver Set, brake pad retention pin",
            "NormTermName": "Split Pin Driver",
            "AssGrpName": "Tool, universal",
            "UsageName": "Retention Pin, brake pad"
        },
        "02352": {
            "GenartName": "Collection Tank, brake fluid",
            "NormTermName": "Tank",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Collecting Tank, brake fluid"
        },
        "02353": {
            "GenartName": "Impact Extractor, brake pad",
            "NormTermName": "Impact Extractor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Pad/Brake Lining"
        },
        "02354": {
            "GenartName": "Extractor Arm, impact extractor",
            "NormTermName": "Extractor Arm",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Extractor"
        },
        "02355": {
            "GenartName": "Reset Tool, brake caliper piston",
            "NormTermName": "Reset Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Reset Tool, brake caliper piston"
        },
        "02356": {
            "GenartName": "Turn / Reset Tool, brake caliper piston",
            "NormTermName": "Turn / Reset Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Piston Turning"
        },
        "02357": {
            "GenartName": "Turn/Reset Tool Set, brake caliper piston",
            "NormTermName": "Turn / Reset Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Piston Turning"
        },
        "02358": {
            "GenartName": "File, brake pad well cleaning",
            "NormTermName": "File",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Pad Well Cleaning"
        },
        "02359": {
            "GenartName": "Wire Brush, brake caliper cleaning",
            "NormTermName": "Wire Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Caliper Cleaning"
        },
        "02360": {
            "GenartName": "Grinding Pad",
            "NormTermName": "Grinding Pad",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "02361": {
            "GenartName": "Tensioner Spindle, grinding pad",
            "NormTermName": "Holding Spindle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Grinding Pad Holding Bracket"
        },
        "02362": {
            "GenartName": "Vernier Calliper, brake disc thickness",
            "NormTermName": "Vernier Caliper",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Disc Thickness"
        },
        "02363": {
            "GenartName": "Pliers, piston rotation",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Piston Rotation"
        },
        "02364": {
            "GenartName": "20° Piston Position Template",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": "20° Piston Adjustment"
        },
        "02365": {
            "GenartName": "Holder, brake disc runout dial gauge",
            "NormTermName": "Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Disc Runout Dial Gauge"
        },
        "02366": {
            "GenartName": "Dial Gauge",
            "NormTermName": "Dial Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "02367": {
            "GenartName": "Knife-edged Ruler, surface level test",
            "NormTermName": "Ruler",
            "AssGrpName": "Tool, universal",
            "UsageName": "Distortion Test"
        },
        "02368": {
            "GenartName": "Socket Wrench Insert",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "02369": {
            "GenartName": "Wrench, brake caliper",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bolts, brake caliper"
        },
        "02370": {
            "GenartName": "Extractor, caliper sliding sleeve",
            "NormTermName": "Extractor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Guide Sleeves, brake caliper"
        },
        "02371": {
            "GenartName": "Protective Cap Driver",
            "NormTermName": "Fitter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cover, guide sleeve"
        },
        "02372": {
            "GenartName": "Protective Cap Installer",
            "NormTermName": "Installer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cover, guide sleeve"
        },
        "02373": {
            "GenartName": "Square Key, brake shoe eccentric",
            "NormTermName": "Square Key",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Shoe Eccentric"
        },
        "02374": {
            "GenartName": "Pliers, brake springs",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Spring, brake drum"
        },
        "02375": {
            "GenartName": "Mounting Tool, wheel brake cylinder",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wheel Brake Cylinder"
        },
        "02376": {
            "GenartName": "Installation Tools, brake spring",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Spring, brake drum"
        },
        "02377": {
            "GenartName": "Installation Tool Set, brake drum",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drum Brake"
        },
        "02378": {
            "GenartName": "Pliers, brake cable spring",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable, parking brake"
        },
        "02379": {
            "GenartName": "Pipe Cutter",
            "NormTermName": "Pipe Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Lines"
        },
        "02380": {
            "GenartName": "Deburring Tool, pipes",
            "NormTermName": "Deburring Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Lines"
        },
        "02381": {
            "GenartName": "Flaring Tool",
            "NormTermName": "Flaring Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "02382": {
            "GenartName": "Pipe Bending Equipment",
            "NormTermName": "Pipe Bending Equipment",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "02383": {
            "GenartName": "Pliers, brake pipe",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Lines"
        },
        "02384": {
            "GenartName": "Spanner Set, brake pipes",
            "NormTermName": "Spanner Set, double ring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Lines"
        },
        "02385": {
            "GenartName": "Clamp, brake hose",
            "NormTermName": "Clamping Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Hose"
        },
        "02386": {
            "GenartName": "Paste, brake / clutch hydraulic parts",
            "NormTermName": "Preservative",
            "AssGrpName": "Chemical Products",
            "UsageName": "Brake Hydraulics"
        },
        "02387": {
            "GenartName": "Tool Board, brake tools",
            "NormTermName": "Mounting Board Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Tools"
        },
        "02388": {
            "GenartName": "Hook Set, tool pegboard",
            "NormTermName": "Hook Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Tool Board"
        },
        "02389": {
            "GenartName": "Special Hook, tool pegboard",
            "NormTermName": "Hook",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Tool Board"
        },
        "02390": {
            "GenartName": "Tools Silhouette Board",
            "NormTermName": "Foil",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Silhouette Board"
        },
        "02391": {
            "GenartName": "Series Resistor, blower",
            "NormTermName": "Resistor",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Fan"
        },
        "02394": {
            "GenartName": "Sensor, coolant temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Glow Ignition System",
            "UsageName": "Coolant Temperature"
        },
        "02395": {
            "GenartName": "Repair Kit, clutch booster",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Clutch Booster"
        },
        "02397": {
            "GenartName": "Vacuum Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Vacuum"
        },
        "02398": {
            "GenartName": "UV Lamp",
            "NormTermName": "Light",
            "AssGrpName": "Workshop Devices",
            "UsageName": "UV Light"
        },
        "02399": {
            "GenartName": "Filler Scale",
            "NormTermName": "Scale",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filler Scale"
        },
        "02400": {
            "GenartName": "Thermometer",
            "NormTermName": "Thermometer",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "02401": {
            "GenartName": "Suction Pump, compressor oil",
            "NormTermName": "Pump",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Oil, compressor"
        },
        "02402": {
            "GenartName": "Filler Cylinder",
            "NormTermName": "Cylinder",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filler Cylinder"
        },
        "02403": {
            "GenartName": "Manometer",
            "NormTermName": "Manometer",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "02404": {
            "GenartName": "Adaptor Set, air conditioning service unit",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Air Conditioning Service Unit"
        },
        "02405": {
            "GenartName": "Socket Wrench Insert, retrofit",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Retrofit Adaptor"
        },
        "02406": {
            "GenartName": "Filler Hose, air conditioning service unit",
            "NormTermName": "Hose Line",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Air Conditioning Service Unit"
        },
        "02407": {
            "GenartName": "Additive, leak location",
            "NormTermName": "Additive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Leak Location"
        },
        "02408": {
            "GenartName": "Injector Nozzle, expansion valve",
            "NormTermName": "Nozzle",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Expansion Valve"
        },
        "02409": {
            "GenartName": "Oil, compressor",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Oil, compressor"
        },
        "02410": {
            "GenartName": "Oil, vacuum pump",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Oil, vacuum pump"
        },
        "02411": {
            "GenartName": "Assortment, valves",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Valve"
        },
        "02412": {
            "GenartName": "Sensor, parking distance control",
            "NormTermName": "Sensor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Distance Control"
        },
        "02413": {
            "GenartName": "Cable Set, parking distance control",
            "NormTermName": "Electric Cable Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Distance Control"
        },
        "02414": {
            "GenartName": "Coding Plug, travel impulse count",
            "NormTermName": "Plug",
            "AssGrpName": "Cruise Control",
            "UsageName": "Travel Impulse Count Coding"
        },
        "02415": {
            "GenartName": "Switch, clutch control (cruise control)",
            "NormTermName": "Switch",
            "AssGrpName": "Cruise Control",
            "UsageName": "Clutch Control"
        },
        "02416": {
            "GenartName": "Cable Set, cruise control system",
            "NormTermName": "Electric Cable Set",
            "AssGrpName": "Cruise Control",
            "UsageName": null
        },
        "02417": {
            "GenartName": "Holding Bracket, cruise control unit",
            "NormTermName": "Holder",
            "AssGrpName": "Cruise Control",
            "UsageName": "Control Unit"
        },
        "02420": {
            "GenartName": "Mounting Kit, charger",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger"
        },
        "02422": {
            "GenartName": "Bearing Kit, torsion bar",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Torsion Bar"
        },
        "02424": {
            "GenartName": "Holder, dryer",
            "NormTermName": "Holder",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Dryer"
        },
        "02425": {
            "GenartName": "Control Unit Set",
            "NormTermName": "Control Unit Set",
            "AssGrpName": "Drive Dynamics Control",
            "UsageName": null
        },
        "02426": {
            "GenartName": "Mounting Kit, cruise control",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Cruise Control",
            "UsageName": null
        },
        "02427": {
            "GenartName": "Perforated Strip",
            "NormTermName": "Perforated Strip",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "02431": {
            "GenartName": "Sensor, cylinder head temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Instruments",
            "UsageName": "Cylinder Head Temperature"
        },
        "02432": {
            "GenartName": "Gauge, cylinder head temperature",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Cylinder Head Temperature"
        },
        "02433": {
            "GenartName": "Switch, door contact",
            "NormTermName": "Switch",
            "AssGrpName": "Lock System",
            "UsageName": "Door Light Contact"
        },
        "02434": {
            "GenartName": "Switch, door contact",
            "NormTermName": "Switch",
            "AssGrpName": "Safety Systems",
            "UsageName": "Door Light Contact"
        },
        "02435": {
            "GenartName": "Switch, door contact",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Door Light Contact"
        },
        "02436": {
            "GenartName": "Retrofit Kit, cabin air filter",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Cabin Air Filter"
        },
        "02437": {
            "GenartName": "Cable Pull, actuator",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Cruise Control",
            "UsageName": "Actuator"
        },
        "02440": {
            "GenartName": "Pressure Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Compressed-air System",
            "UsageName": null
        },
        "02441": {
            "GenartName": "Repair Kit, air dryer",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Dryer"
        },
        "02442": {
            "GenartName": "Connector Cable, electronic brake system",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Braking System",
            "UsageName": "Electronic Brake System"
        },
        "02443": {
            "GenartName": "Control Unit, door actuation",
            "NormTermName": "Control Unit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Door Actuation"
        },
        "02444": {
            "GenartName": "Boot Liner/cargo liner",
            "NormTermName": "Rubber Tub",
            "AssGrpName": "Accessories",
            "UsageName": "Boot-/Cargo Area"
        },
        "02445": {
            "GenartName": "Non-skid Mat",
            "NormTermName": "Floor Mat",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Non-skid Rubber Mat"
        },
        "02448": {
            "GenartName": "Non-skid Inset Mat",
            "NormTermName": "Floor Mat",
            "AssGrpName": "Accessories",
            "UsageName": "Non-skid Inset Mat"
        },
        "02449": {
            "GenartName": "Boot Liner/cargo liner",
            "NormTermName": "Rubber Tub",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Boot Liner/cargo liner"
        },
        "02450": {
            "GenartName": "Universal Non-skid Inset Mat",
            "NormTermName": "Floor Mat",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Universal Non-skid Inset Mat"
        },
        "02451": {
            "GenartName": "Off-Road Stash Box",
            "NormTermName": "Stash Box",
            "AssGrpName": "Accessories",
            "UsageName": "Off-Road Stash Box"
        },
        "02452": {
            "GenartName": "Gas Spring, steering column adjustment",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Steering",
            "UsageName": "Steering Column Adjustment"
        },
        "02453": {
            "GenartName": "Vibration Damper, timing belt",
            "NormTermName": "Damper",
            "AssGrpName": "Belt Drive",
            "UsageName": "Timing Belt Tension"
        },
        "02454": {
            "GenartName": "Gas Spring, rear window",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Rear Window"
        },
        "02455": {
            "GenartName": "Gas Spring, seat adjustment",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Seat Adjustment"
        },
        "02457": {
            "GenartName": "Control Unit, seat heating",
            "NormTermName": "Control Unit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Seat Heating"
        },
        "02458": {
            "GenartName": "Multi-function Gauge",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Multi-function"
        },
        "02459": {
            "GenartName": "Conversion Kit, cartridge filter",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Filter"
        },
        "02461": {
            "GenartName": "Control Unit, window regulator",
            "NormTermName": "Control Unit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Window Regulator"
        },
        "02462": {
            "GenartName": "Ball Joint",
            "NormTermName": "Joint",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Ball Joint"
        },
        "02463": {
            "GenartName": "Guide Rails Kit, timing chain",
            "NormTermName": "Timing-chain guides kit",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain"
        },
        "02464": {
            "GenartName": "Electric Cable, air suspension",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Suspension"
        },
        "02465": {
            "GenartName": "Squeeze Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable Connector"
        },
        "02466": {
            "GenartName": "Daytime Running Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Daytime Running Light"
        },
        "02467": {
            "GenartName": "Mounting Cone, bellow",
            "NormTermName": "Mounting Wedge",
            "AssGrpName": "Tool, universal",
            "UsageName": "Universal-axle-/Steering Bellow"
        },
        "02470": {
            "GenartName": "Secondary Air Filter",
            "NormTermName": "Filter",
            "AssGrpName": "Air Supply",
            "UsageName": "Secondary Air"
        },
        "02471": {
            "GenartName": "Oilfilter Spanner",
            "NormTermName": "Key",
            "AssGrpName": "Tool, universal",
            "UsageName": "Oil Filter"
        },
        "02472": {
            "GenartName": "Ball Joint, steering damper",
            "NormTermName": "Joint",
            "AssGrpName": "Steering",
            "UsageName": "Steering Damper"
        },
        "02473": {
            "GenartName": "Repair Kit, control/trailing arm",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Control/Trailing Arm"
        },
        "02474": {
            "GenartName": "Bend Headlight System",
            "NormTermName": "Headlight set",
            "AssGrpName": "Lights",
            "UsageName": "Bend Lighting"
        },
        "02475": {
            "GenartName": "Temperature Switch, coolant warning lamp",
            "NormTermName": "Switch",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Temperature Alarm"
        },
        "02476": {
            "GenartName": "Cover, exterior mirror",
            "NormTermName": "Cover",
            "AssGrpName": "Driver Cab",
            "UsageName": "Exterior Mirror"
        },
        "02478": {
            "GenartName": "Hand lamp",
            "NormTermName": "Light",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Hand Lamp"
        },
        "02479": {
            "GenartName": "Holder, hand lamp",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Hand Lamp"
        },
        "02480": {
            "GenartName": "Holder, reflex reflector",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Reflex Reflector"
        },
        "02481": {
            "GenartName": "Roller Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Roller/Drum"
        },
        "02482": {
            "GenartName": "Headlight",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "02483": {
            "GenartName": "Spotlight",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights, universal",
            "UsageName": "Spotlight"
        },
        "02484": {
            "GenartName": "Front Fog Light",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights, universal",
            "UsageName": "Front Fog Light"
        },
        "02485": {
            "GenartName": "Worklight",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights, universal",
            "UsageName": "Worklight"
        },
        "02486": {
            "GenartName": "Searchlight",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights, universal",
            "UsageName": "Searchlight"
        },
        "02487": {
            "GenartName": "Tail Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Tail Light"
        },
        "02488": {
            "GenartName": "Rear Fog Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Rear Fog Light"
        },
        "02489": {
            "GenartName": "Licence Plate Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Licence Plate Light"
        },
        "02490": {
            "GenartName": "End Outline Marker Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "End Outline Marker Light"
        },
        "02492": {
            "GenartName": "Combination Rear Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Combination Rear Light"
        },
        "02493": {
            "GenartName": "Reverse Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Reverse Light"
        },
        "02496": {
            "GenartName": "Door Open Warning Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Door"
        },
        "02497": {
            "GenartName": "Interior Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Interior Light"
        },
        "02498": {
            "GenartName": "Park Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Park Light"
        },
        "02500": {
            "GenartName": "Auxiliary Stop Light",
            "NormTermName": "Light",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Auxiliary Stop Light"
        },
        "02501": {
            "GenartName": "Reflex Reflector",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights, universal",
            "UsageName": null
        },
        "02502": {
            "GenartName": "Bulb, headlight",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "02503": {
            "GenartName": "Stop Light",
            "NormTermName": "Light",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Stop Light"
        },
        "02504": {
            "GenartName": "Direction Indicator",
            "NormTermName": "Light",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Direction Indicator"
        },
        "02505": {
            "GenartName": "Rotating Beacon",
            "NormTermName": "Light",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Rotating Beacon"
        },
        "02506": {
            "GenartName": "Base, worklight",
            "NormTermName": "Holder",
            "AssGrpName": "Lights, universal",
            "UsageName": "Worklight"
        },
        "02507": {
            "GenartName": "Holder, front fog light",
            "NormTermName": "Holder",
            "AssGrpName": "Lights, universal",
            "UsageName": "Front Fog Light"
        },
        "02508": {
            "GenartName": "Base, spotlight",
            "NormTermName": "Holder",
            "AssGrpName": "Lights, universal",
            "UsageName": "Spotlight"
        },
        "02509": {
            "GenartName": "Lights-on Reminder",
            "NormTermName": "Lights-On Reminder",
            "AssGrpName": "Lights, universal",
            "UsageName": null
        },
        "02510": {
            "GenartName": "Ballast, gas discharge lamp",
            "NormTermName": "Control Unit",
            "AssGrpName": "Lights, universal",
            "UsageName": "Gas Discharge Lamp"
        },
        "02511": {
            "GenartName": "Switch, door contact",
            "NormTermName": "Switch",
            "AssGrpName": "Lights, universal",
            "UsageName": "Door Light Contact"
        },
        "02512": {
            "GenartName": "Switch, headlight",
            "NormTermName": "Switch",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "02513": {
            "GenartName": "Accessory Kit, brake caliper",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "02514": {
            "GenartName": "Switch, reverse light",
            "NormTermName": "Switch",
            "AssGrpName": "Lights, universal",
            "UsageName": "Reverse Light"
        },
        "02515": {
            "GenartName": "Trumpet Horn",
            "NormTermName": "Trumpet Horn",
            "AssGrpName": "Signal System, universal",
            "UsageName": null
        },
        "02516": {
            "GenartName": "Signal Horn",
            "NormTermName": "Signal Horn",
            "AssGrpName": "Signal System, universal",
            "UsageName": null
        },
        "02517": {
            "GenartName": "Holder, rotating beacon",
            "NormTermName": "Holder",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Rotating Beacon"
        },
        "02518": {
            "GenartName": "Flasher Unit",
            "NormTermName": "Relay",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Flasher Unit"
        },
        "02519": {
            "GenartName": "Switch, stop light",
            "NormTermName": "Switch",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Stop Light"
        },
        "02520": {
            "GenartName": "Direction Indicator Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Indicator"
        },
        "02521": {
            "GenartName": "Hazard Warning Light Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Hazard Warning Light"
        },
        "02522": {
            "GenartName": "Slave Cylinder, power steering",
            "NormTermName": "Cylinder",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering"
        },
        "02523": {
            "GenartName": "Repair Kit, ball joint",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Ball Joint"
        },
        "02524": {
            "GenartName": "Securing Plate, ball joint",
            "NormTermName": "Tab Washer",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Ball Joint"
        },
        "02525": {
            "GenartName": "Clearance Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Clearance Light"
        },
        "02526": {
            "GenartName": "Strobe-type Beacon",
            "NormTermName": "Light",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Strobe-type Beacon"
        },
        "02528": {
            "GenartName": "Connector Chain, test connection",
            "NormTermName": "Connector",
            "AssGrpName": "Standard Parts",
            "UsageName": "Test Connection"
        },
        "02529": {
            "GenartName": "Accessory Kit, brake pad",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Pad/Brake Lining"
        },
        "02530": {
            "GenartName": "Frame, assortment box",
            "NormTermName": "Frame",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Assortment Box"
        },
        "02531": {
            "GenartName": "Assortment Box",
            "NormTermName": "Van",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Assortment Box"
        },
        "02532": {
            "GenartName": "Cover, test connection",
            "NormTermName": "Cap",
            "AssGrpName": "Standard Parts",
            "UsageName": "Test Connection"
        },
        "02534": {
            "GenartName": "Cap, Brake Fluid Reservoir (service)",
            "NormTermName": "Closure",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Fluid Reservoir (service)"
        },
        "02535": {
            "GenartName": "Brake Bleeder Adapter",
            "NormTermName": "Fitting",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Disposal unit collecting container connector"
        },
        "02536": {
            "GenartName": "Adaptor, brake caliper reset tool",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Reset Tool, brake caliper piston"
        },
        "02537": {
            "GenartName": "Clamping Claw, bordering tool",
            "NormTermName": "Clamping Claw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bordering Tool"
        },
        "02538": {
            "GenartName": "Thrust Piece, flaring tool",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bordering Tool"
        },
        "02541": {
            "GenartName": "Table Sheet",
            "NormTermName": "Shield",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Table Sheet"
        },
        "02543": {
            "GenartName": "Profile Sheet",
            "NormTermName": "Shield",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Profile Sheet"
        },
        "02544": {
            "GenartName": "Brake Line",
            "NormTermName": "Pipe",
            "AssGrpName": "Braking System, universal",
            "UsageName": null
        },
        "02545": {
            "GenartName": "Connection Piece, vacuum hose",
            "NormTermName": "Fitting",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Booster"
        },
        "02546": {
            "GenartName": "Pressure Switch, brake hydraulics",
            "NormTermName": "Switch",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Pressure"
        },
        "02547": {
            "GenartName": "Lens, park light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights",
            "UsageName": "Park Light"
        },
        "02549": {
            "GenartName": "Holder, brake fluid reservoir",
            "NormTermName": "Holder",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Fluid Reservoir"
        },
        "02550": {
            "GenartName": "Holder, brake line",
            "NormTermName": "Holder",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Line"
        },
        "02551": {
            "GenartName": "Holding Bracket, brake hose",
            "NormTermName": "Holder",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Hose"
        },
        "02552": {
            "GenartName": "Bolt, brake caliper",
            "NormTermName": "Screw",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Caliper"
        },
        "02553": {
            "GenartName": "Plug, brake fluid reservoir",
            "NormTermName": "Plug",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Fluid Reservoir"
        },
        "02554": {
            "GenartName": "Valve, brake booster",
            "NormTermName": "Valve",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Booster"
        },
        "02555": {
            "GenartName": "Cap, brake fluid reservoir",
            "NormTermName": "Closure",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Fluid Reservoir"
        },
        "02556": {
            "GenartName": "Seal, vacuum hose connector pipe",
            "NormTermName": "Seal",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Vacuum Connection Nozzle"
        },
        "02557": {
            "GenartName": "Test/Set Unit, clutch disc",
            "NormTermName": "Test/ Straightening Unit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch Disc"
        },
        "02558": {
            "GenartName": "Centering Tool, clutch disc",
            "NormTermName": "Centering Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch Disc"
        },
        "02559": {
            "GenartName": "Puller Set, inner bearing",
            "NormTermName": "Puller Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Inner Bearing"
        },
        "02560": {
            "GenartName": "Mounting Tools, transmission shaft",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Transmission Shaft"
        },
        "02561": {
            "GenartName": "Mounting Tool Set, crankshaft seal/pilot bearing",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Crankshaft Seal/Pilot Bearing"
        },
        "02562": {
            "GenartName": "Clamping Device, clutch pressure plate",
            "NormTermName": "Clamping Device",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch Pressure Plate"
        },
        "02563": {
            "GenartName": "Holding Bracket Set, clutch pressure plate",
            "NormTermName": "Holding Bracket Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch Pressure Plate"
        },
        "02564": {
            "GenartName": "Mounting Tool Set, clutch/flywheel",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch/ Flywheel"
        },
        "02565": {
            "GenartName": "Adjustment Gauge, clutch control",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": "Basic Setting, clutch control"
        },
        "02566": {
            "GenartName": "Fitter Mandrel, seal ring",
            "NormTermName": "Mounting Mandrel",
            "AssGrpName": "Tool, universal",
            "UsageName": "Seal Ring Press-in/ -out"
        },
        "02567": {
            "GenartName": "Stop Spanner, flywheel",
            "NormTermName": "Stop Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flywheel"
        },
        "02568": {
            "GenartName": "Clamping Ring, clutch pressure plate",
            "NormTermName": "Clamping Ring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch Pressure Plate Clamping"
        },
        "02569": {
            "GenartName": "Pilot Mandrel Set, differential",
            "NormTermName": "Pilot Mandrel Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Differential"
        },
        "02570": {
            "GenartName": "Extension, dial gauge",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Dial Gauge"
        },
        "02571": {
            "GenartName": "Feeler Pad, dial gauge",
            "NormTermName": "Feeler Pad",
            "AssGrpName": "Tool, universal",
            "UsageName": "Dial Gauge"
        },
        "02572": {
            "GenartName": "Expansion Mandrel, inner bearing",
            "NormTermName": "Expansion Mandrel",
            "AssGrpName": "Tool, universal",
            "UsageName": "Inner Bearing"
        },
        "02573": {
            "GenartName": "Spring Collet, inner bearing",
            "NormTermName": "Clamping Jaw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Inner Bearing"
        },
        "02574": {
            "GenartName": "Demounting Lever, crankshaft seal",
            "NormTermName": "Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Demounting Crankshaft Seal"
        },
        "02575": {
            "GenartName": "Puller Claw, crankshaft seal",
            "NormTermName": "Hook",
            "AssGrpName": "Tool, universal",
            "UsageName": "Demounting Crankshaft Seal"
        },
        "02576": {
            "GenartName": "Thrust Piece, pilot bearing",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pilot Bearing"
        },
        "02577": {
            "GenartName": "Puller, pilot bearing",
            "NormTermName": "Extractor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pilot Bearing"
        },
        "02578": {
            "GenartName": "Thrust Piece, crankshaft seal",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Tool, universal",
            "UsageName": "Crankshaft Seal"
        },
        "02579": {
            "GenartName": "Extension Lever, clutch/ flywheel",
            "NormTermName": "Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch/ Flywheel"
        },
        "02580": {
            "GenartName": "Ratchet, clutch/ flywheel",
            "NormTermName": "Ratchet",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch/ Flywheel"
        },
        "02581": {
            "GenartName": "Retaining Clamp, clutch clamping device",
            "NormTermName": "Retaining Clamp",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch Clamping Device"
        },
        "02582": {
            "GenartName": "Thrust Piece, clutch pressure plate",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch Pressure Plate"
        },
        "02583": {
            "GenartName": "Mounting Lever, clutch pressure plate",
            "NormTermName": "Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch Pressure Plate Mounting"
        },
        "02584": {
            "GenartName": "Pilot Mandrel, differential",
            "NormTermName": "Guide Mandrel",
            "AssGrpName": "Tool, universal",
            "UsageName": "Differential"
        },
        "02585": {
            "GenartName": "Appliance Battery",
            "NormTermName": "Battery",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Appliance Battery"
        },
        "02586": {
            "GenartName": "Vacuum Hose, braking system",
            "NormTermName": "Hose Line",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Vacuum"
        },
        "02587": {
            "GenartName": "Stop Disc, brake master cylinder",
            "NormTermName": "Stop Plate",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Master Cylinder"
        },
        "02588": {
            "GenartName": "Bellow, brake master cylinder",
            "NormTermName": "Bellow",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Master Cylinder"
        },
        "02589": {
            "GenartName": "Bellow, wheel brake cylinder",
            "NormTermName": "Bellow",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Wheel Brake Cylinder"
        },
        "02590": {
            "GenartName": "Base, rotating beacon",
            "NormTermName": "Base",
            "AssGrpName": "Signal System",
            "UsageName": "Rotating Beacon"
        },
        "02591": {
            "GenartName": "Cruise Control",
            "NormTermName": "Control System",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": null
        },
        "02593": {
            "GenartName": "Switch, cruise control",
            "NormTermName": "Switch",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": null
        },
        "02594": {
            "GenartName": "Control, cruise control system",
            "NormTermName": "Actuator",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": null
        },
        "02595": {
            "GenartName": "Adapter Kit, cruise control",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": null
        },
        "02596": {
            "GenartName": "Base",
            "NormTermName": "Base",
            "AssGrpName": null,
            "UsageName": null
        },
        "02597": {
            "GenartName": "Frequency Module, cruise control system",
            "NormTermName": "Frequency Module",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": null
        },
        "02598": {
            "GenartName": "Control Unit, cruise control system",
            "NormTermName": "Control Unit",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": null
        },
        "02599": {
            "GenartName": "Coding Plug, travel impulse count",
            "NormTermName": "Plug",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": "Travel Impulse Count Coding"
        },
        "02600": {
            "GenartName": "Switch, clutch control",
            "NormTermName": "Switch",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": "Clutch Control"
        },
        "02601": {
            "GenartName": "Cable Set, cruise control system",
            "NormTermName": "Electric Cable Set",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": null
        },
        "02602": {
            "GenartName": "Holding Bracket, cruise control unit",
            "NormTermName": "Holder",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": "Control Unit"
        },
        "02603": {
            "GenartName": "Mounting Kit, cruise control",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": null
        },
        "02604": {
            "GenartName": "Cable Pull, actuator",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": "Actuator"
        },
        "02606": {
            "GenartName": "Adaptor Kit, speed warning system",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Cruise Control Alarm, universal",
            "UsageName": null
        },
        "02607": {
            "GenartName": "Speeding Alarm",
            "NormTermName": "Warning System",
            "AssGrpName": "Cruise Control Alarm, universal",
            "UsageName": null
        },
        "02608": {
            "GenartName": "Ammeter",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Ammeter"
        },
        "02609": {
            "GenartName": "Revolution Counter",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "RPM"
        },
        "02610": {
            "GenartName": "Gauge, boost pressure",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Boost Pressure"
        },
        "02611": {
            "GenartName": "Gauge, oil pressure",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Oil Pressure"
        },
        "02612": {
            "GenartName": "Fuel Gauge",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Fuel Supply"
        },
        "02613": {
            "GenartName": "Speedometer",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Speedometer"
        },
        "02614": {
            "GenartName": "Gauge, coolant temperature",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Coolant Temperature"
        },
        "02615": {
            "GenartName": "Gauge, oil temperature",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Oil Temperature"
        },
        "02616": {
            "GenartName": "Gauge, exterior temperature",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Exterior Temperature"
        },
        "02617": {
            "GenartName": "Gauge, exterior/interior temperature",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Exterior/Interior Temperature"
        },
        "02618": {
            "GenartName": "Voltmeter",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Voltmeter"
        },
        "02619": {
            "GenartName": "Clock",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Clock"
        },
        "02620": {
            "GenartName": "Control Unit, parking distance control",
            "NormTermName": "Control Unit",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Distance Control"
        },
        "02621": {
            "GenartName": "Parking Distance Control",
            "NormTermName": "Parking Distance Control",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": null
        },
        "02622": {
            "GenartName": "Radio Mute Switch, parking distance control",
            "NormTermName": "Mute Switch",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Distance Control"
        },
        "02623": {
            "GenartName": "Sensor, parking distance control",
            "NormTermName": "Sensor",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Distance Control"
        },
        "02624": {
            "GenartName": "Cable Set, parking distance control",
            "NormTermName": "Electric Cable Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Distance Control"
        },
        "02625": {
            "GenartName": "Control Unit, accident data recorder",
            "NormTermName": "Control Unit",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Accident Data Recorder"
        },
        "02626": {
            "GenartName": "Accident Data Recorder",
            "NormTermName": "Data Memory",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Accident Data"
        },
        "02627": {
            "GenartName": "Radio / CD",
            "NormTermName": "Car Radio",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Radio CD"
        },
        "02628": {
            "GenartName": "Radio / Navigation System Combination",
            "NormTermName": "Radio/ Navigation System Combination",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "02629": {
            "GenartName": "Quill, cylinder head",
            "NormTermName": "Sleeve",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Cylinder Head Quill"
        },
        "02630": {
            "GenartName": "Conversion Kit, accident data recorder",
            "NormTermName": "Custom Set",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Accident Data Recorder"
        },
        "02631": {
            "GenartName": "Connecting Cable, accident data recorder",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Accident Data Recorder"
        },
        "02632": {
            "GenartName": "Fuel Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": null
        },
        "02633": {
            "GenartName": "Hose Connector, fuel hose",
            "NormTermName": "Connector",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Fuel Hose"
        },
        "02634": {
            "GenartName": "Bellow, master cylinder",
            "NormTermName": "Bellow",
            "AssGrpName": "Clutch, universal",
            "UsageName": "Master Cylinder"
        },
        "02635": {
            "GenartName": "Bellow, slave cylinder",
            "NormTermName": "Bellow",
            "AssGrpName": "Clutch, universal",
            "UsageName": "Slave Cylinder"
        },
        "02636": {
            "GenartName": "Puller, clutch",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch"
        },
        "02637": {
            "GenartName": "Puller, flywheel",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flywheel"
        },
        "02638": {
            "GenartName": "Centering Tool Set, support frame",
            "NormTermName": "Centering Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Support Frame"
        },
        "02639": {
            "GenartName": "Mounting Tool, quill",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Quill"
        },
        "02640": {
            "GenartName": "Bellow, steering",
            "NormTermName": "Bellow",
            "AssGrpName": "Steering, universal",
            "UsageName": null
        },
        "02641": {
            "GenartName": "Bellow Kit, steering",
            "NormTermName": "Bellow Kit",
            "AssGrpName": "Steering, universal",
            "UsageName": null
        },
        "02642": {
            "GenartName": "Hydraulic Filter, steering system",
            "NormTermName": "Filter",
            "AssGrpName": "Steering, universal",
            "UsageName": "Hydraulic Oil"
        },
        "02643": {
            "GenartName": "Mounting Device, engine/ transmission unit",
            "NormTermName": "Mounting Device",
            "AssGrpName": "Tool, universal",
            "UsageName": "Engine/ Transmission Unit"
        },
        "02644": {
            "GenartName": "Mounting Tool, exhaust system steel bracket",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Exhaust System Steel Bracket"
        },
        "02645": {
            "GenartName": "Retaining Tool Set, crankshaft",
            "NormTermName": "Retaining Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Crankshaft"
        },
        "02646": {
            "GenartName": "Test Unit, timing belt tension",
            "NormTermName": "Tester",
            "AssGrpName": "Tool, universal",
            "UsageName": "Timing Belt Tension"
        },
        "02647": {
            "GenartName": "Spanner, timing belt tension",
            "NormTermName": "Key",
            "AssGrpName": "Tool, universal",
            "UsageName": "Timing Belt Tension"
        },
        "02648": {
            "GenartName": "Banded V-Belts",
            "NormTermName": "Belt",
            "AssGrpName": "Belt Drive",
            "UsageName": "Banded V-Belts"
        },
        "02649": {
            "GenartName": "Bellow, drive shaft",
            "NormTermName": "Bellow",
            "AssGrpName": "Wheel Drive, universal",
            "UsageName": "Drive Shaft"
        },
        "02650": {
            "GenartName": "Mounting Set, drive shaft bellow",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Wheel Drive, universal",
            "UsageName": "Bellow drive shaft"
        },
        "02651": {
            "GenartName": "Remote Control, central locking",
            "NormTermName": "Remote Control",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Central Locking System"
        },
        "02652": {
            "GenartName": "Actuator, central locking system",
            "NormTermName": "Actuator",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Central Locking System"
        },
        "02653": {
            "GenartName": "Hand-held Transmitter Housing, central locking",
            "NormTermName": "Housing",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Hand-held Transmitter for central locking"
        },
        "02654": {
            "GenartName": "Siren, alarm system",
            "NormTermName": "Siren",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Alarm System"
        },
        "02655": {
            "GenartName": "Switch, door contact",
            "NormTermName": "Switch",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Door Light Contact"
        },
        "02656": {
            "GenartName": "Warning Triangle",
            "NormTermName": "Warning Triangle",
            "AssGrpName": "Security Systems, universal",
            "UsageName": null
        },
        "02658": {
            "GenartName": "Alarm System",
            "NormTermName": "Warning System",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Theft Alarm"
        },
        "02659": {
            "GenartName": "Inclination Sensor, alarm system",
            "NormTermName": "Sensor",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Inclination Sensor"
        },
        "02660": {
            "GenartName": "Hand Held Transmitter, alarm system",
            "NormTermName": "Hand-held Transmitter",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Alarm System"
        },
        "02661": {
            "GenartName": "Transmitter Housing, alarm system",
            "NormTermName": "Housing",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Alarm System"
        },
        "02662": {
            "GenartName": "Extension Line, ultrasonic module",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Ultrasonic Module"
        },
        "02663": {
            "GenartName": "Siren, alarm system",
            "NormTermName": "Siren",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Alarm System"
        },
        "02664": {
            "GenartName": "Switch, door contact",
            "NormTermName": "Switch",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Door Light Contact"
        },
        "02666": {
            "GenartName": "Seal, radiator",
            "NormTermName": "Seal",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator"
        },
        "02667": {
            "GenartName": "Frame, radiator",
            "NormTermName": "Frame",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator"
        },
        "02668": {
            "GenartName": "Cooler Module",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Cooling System",
            "UsageName": null
        },
        "02669": {
            "GenartName": "Interior Blower",
            "NormTermName": "Fan",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Air"
        },
        "02670": {
            "GenartName": "Daytime Running Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Daytime Running Light"
        },
        "02671": {
            "GenartName": "Bend Headlight System",
            "NormTermName": "Headlight set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Bend Lighting"
        },
        "02672": {
            "GenartName": "Base, rotating beacon",
            "NormTermName": "Base",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Rotating Beacon"
        },
        "02675": {
            "GenartName": "Thermometer, exterior/interior temperature",
            "NormTermName": "Thermometer",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Exterior/Interior Temperature"
        },
        "02676": {
            "GenartName": "Thermometer, exterior temperature",
            "NormTermName": "Thermometer",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Exterior Temperature"
        },
        "02679": {
            "GenartName": "Service Hour Counter",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Service Hour Counter"
        },
        "02680": {
            "GenartName": "Tractormeter",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Tractormeter"
        },
        "02681": {
            "GenartName": "Manometer",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Manometer"
        },
        "02682": {
            "GenartName": "Gauge, brake pressure",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Brake Pressure"
        },
        "02683": {
            "GenartName": "Gauge, cylinder head temperature",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Cylinder Head Temperature"
        },
        "02684": {
            "GenartName": "Multi-function Gauge",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Multi-function"
        },
        "02685": {
            "GenartName": "Armrest",
            "NormTermName": "Backrest",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": "Arm"
        },
        "02686": {
            "GenartName": "Floor Mat",
            "NormTermName": "Floor Mat",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": null
        },
        "02687": {
            "GenartName": "Floor Mat Set",
            "NormTermName": "Floor Mat Set",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": null
        },
        "02688": {
            "GenartName": "Gas Spring, telephone console",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": "Telephone Console"
        },
        "02689": {
            "GenartName": "Gas Spring, spare-wheel carrier",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Spare Tyre Bracket"
        },
        "02691": {
            "GenartName": "Cigarette Lighter",
            "NormTermName": "Cigarette Lighter",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": null
        },
        "02692": {
            "GenartName": "Core, radiator",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator Core"
        },
        "02697": {
            "GenartName": "Mounting Paste",
            "NormTermName": "Mounting Aid",
            "AssGrpName": "Chemical Products",
            "UsageName": "Mounting Paste"
        },
        "02698": {
            "GenartName": "Mounting Spray",
            "NormTermName": "Mounting Aid",
            "AssGrpName": "Chemical Products",
            "UsageName": "Mounting Spray"
        },
        "02699": {
            "GenartName": "Mounting Liquid",
            "NormTermName": "Mounting Aid",
            "AssGrpName": "Chemical Products",
            "UsageName": "Mounting Liquid"
        },
        "02700": {
            "GenartName": "Seal, oil dipstick",
            "NormTermName": "Seal",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Dipstick"
        },
        "02701": {
            "GenartName": "Handsender, central locking",
            "NormTermName": "Hand-held Transmitter",
            "AssGrpName": "Lock System",
            "UsageName": "Central Locking System"
        },
        "02702": {
            "GenartName": "Mounting Lever, brake shoe",
            "NormTermName": "Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Shoe Mounting"
        },
        "02704": {
            "GenartName": "Switch, rear window heating",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Rear Windscreen Heating"
        },
        "02705": {
            "GenartName": "Bush, rocker arm",
            "NormTermName": "Bush",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Rocker Arm"
        },
        "02706": {
            "GenartName": "Piston Holding Bracket, wheel brake cylinder",
            "NormTermName": "Holding Bracket",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wheel Brake Cylinder Piston"
        },
        "02707": {
            "GenartName": "Angled Screwdriver",
            "NormTermName": "Angle Screwdriver",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "02708": {
            "GenartName": "Handsender, central locking",
            "NormTermName": "Hand-held Transmitter",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Central Locking System"
        },
        "02709": {
            "GenartName": "Signal Unit",
            "NormTermName": "Signal Unit",
            "AssGrpName": "Signal System",
            "UsageName": null
        },
        "02711": {
            "GenartName": "Adaptor, wash waterpump for windscreen cleaning.",
            "NormTermName": "Adaptor",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Wash Waterpump, windscreen cleaning"
        },
        "02712": {
            "GenartName": "Adaptor, wash waterpump for headlight cleaning",
            "NormTermName": "Adaptor",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": "Wash Waterpump, headlight cleaning"
        },
        "02713": {
            "GenartName": "Reamer Set, ABS sensor mounting",
            "NormTermName": "Reamer Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "ABS Sensor Mounting"
        },
        "02714": {
            "GenartName": "Reamer Head, ABS sensor mounting",
            "NormTermName": "Reamer Head",
            "AssGrpName": "Tool, universal",
            "UsageName": "ABS Sensor Mounting"
        },
        "02715": {
            "GenartName": "Spring Retaining Pin, brake shoe",
            "NormTermName": "Pin",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoes"
        },
        "02716": {
            "GenartName": "Bush, brake shoe pin",
            "NormTermName": "Bush",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoe Pin"
        },
        "02717": {
            "GenartName": "Bush, brake shoe sleeve",
            "NormTermName": "Bush",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoe Sleeve"
        },
        "02718": {
            "GenartName": "Brake Shoe Sleeve",
            "NormTermName": "Pulley",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoes"
        },
        "02719": {
            "GenartName": "Pin, brake shoe sleeve",
            "NormTermName": "Bolt",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoe Sleeve"
        },
        "02720": {
            "GenartName": "Repair Kit, brake shoe sleeve",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoe Sleeve"
        },
        "02721": {
            "GenartName": "Repair Kit, adjuster",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Adjuster"
        },
        "02722": {
            "GenartName": "Repair Kit, brake camshaft",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Camshaft"
        },
        "02723": {
            "GenartName": "Wedge Drum Brake",
            "NormTermName": "Brake Equipment Group",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Wedge Drum Brake"
        },
        "02724": {
            "GenartName": "Brake Shoe Pin Set",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoes"
        },
        "02725": {
            "GenartName": "Chain, oil pump drive",
            "NormTermName": "Chain",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "02726": {
            "GenartName": "Chain Link, oil pump drive",
            "NormTermName": "Chain Link",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "02728": {
            "GenartName": "Repair Kit, flywheel",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Flywheel"
        },
        "02729": {
            "GenartName": "Repair Kit, v-ribbed belt tensioner",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt Tensioner"
        },
        "02730": {
            "GenartName": "Oil Baffle, crankshaft",
            "NormTermName": "Shield",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft"
        },
        "02731": {
            "GenartName": "Bush, parking brake shaft",
            "NormTermName": "Bush",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake Shaft"
        },
        "02732": {
            "GenartName": "Bearing, parking brake shaft",
            "NormTermName": "Bearing",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake Shaft"
        },
        "02733": {
            "GenartName": "Bearing, brake camshaft",
            "NormTermName": "Bearing",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Camshaft"
        },
        "02734": {
            "GenartName": "Buffer, brake force regulator",
            "NormTermName": "Buffer",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Force Regulator"
        },
        "02735": {
            "GenartName": "Tensioner Lever, V-belt",
            "NormTermName": "Tensioner Arm",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Belt"
        },
        "02736": {
            "GenartName": "Fastening Bolt, stabiliser bar",
            "NormTermName": "Bolt",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser"
        },
        "02737": {
            "GenartName": "Relay, interior blower",
            "NormTermName": "Relay",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Blower"
        },
        "02738": {
            "GenartName": "Bracket, gearshift linkage",
            "NormTermName": "Bracket",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gearshift Linkage"
        },
        "02739": {
            "GenartName": "Spacer Ring",
            "NormTermName": "Spacer Ring",
            "AssGrpName": "Standard Parts",
            "UsageName": "Spacing, alignment"
        },
        "02740": {
            "GenartName": "Expansion Set, parking assist early warning",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Assist Early Warning"
        },
        "02741": {
            "GenartName": "Assortment, pipe connectors",
            "NormTermName": "Assortment",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Pipe Connector"
        },
        "02742": {
            "GenartName": "Corrugated Pipe",
            "NormTermName": "Pipe",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Cable Protecting Sleeve"
        },
        "02743": {
            "GenartName": "Adapter, clutch master cylinder",
            "NormTermName": "Adaptor",
            "AssGrpName": "Clutch",
            "UsageName": "Master Cylinder"
        },
        "02744": {
            "GenartName": "Switch, interior light",
            "NormTermName": "Switch",
            "AssGrpName": "Lights, universal",
            "UsageName": "Interior Light"
        },
        "02745": {
            "GenartName": "Main Switch, battery",
            "NormTermName": "Switch",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Battery"
        },
        "02746": {
            "GenartName": "Wear Indicator, brake pad",
            "NormTermName": "Gauge",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Pad Wear"
        },
        "02747": {
            "GenartName": "Oil Scraper Plate, crankcase ventilation",
            "NormTermName": "Shield",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Crankcase Ventilation"
        },
        "02748": {
            "GenartName": "Relay, exterior mirror heating",
            "NormTermName": "Relay",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Exterior mirror Heating"
        },
        "02749": {
            "GenartName": "Repair Kit, parking brake shaft",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake Shaft"
        },
        "02750": {
            "GenartName": "Plug, rocker arm shaft mounting bore",
            "NormTermName": "Plug",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Centre Pivot Rocker Arm Shaft Mounting Bore"
        },
        "02751": {
            "GenartName": "Cover Ring, brake-shoe pin bore",
            "NormTermName": "Covering Ring",
            "AssGrpName": "Braking System",
            "UsageName": "Brake-shoe Pin Bore"
        },
        "02752": {
            "GenartName": "Seal Ring, hydraulic filter",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Hydraulic Filter"
        },
        "02753": {
            "GenartName": "Relay, cooler fan castor - intercooler",
            "NormTermName": "Relay",
            "AssGrpName": "Cooling System",
            "UsageName": "Charge Air Cooling"
        },
        "02754": {
            "GenartName": "Fastening Bolts",
            "NormTermName": "Bolt",
            "AssGrpName": "Standard Parts",
            "UsageName": "Fasteners"
        },
        "02755": {
            "GenartName": "Screw Plug, axle drive",
            "NormTermName": "Screw",
            "AssGrpName": "Axle Drive",
            "UsageName": "Oil Drain"
        },
        "02756": {
            "GenartName": "Seal, brake camshaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Camshaft"
        },
        "02757": {
            "GenartName": "Shaft Seal, parking brake shaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake Shaft"
        },
        "02758": {
            "GenartName": "Clamping Piece, v-ribbed belt tensioner",
            "NormTermName": "Clamping Piece",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt Tensioner"
        },
        "02760": {
            "GenartName": "Fifth Wheel, kingpin",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Tractor Kingpin"
        },
        "02761": {
            "GenartName": "Bearing, v-ribbed belt tensioner",
            "NormTermName": "Bearing",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt Tensioner"
        },
        "02762": {
            "GenartName": "Holder, brake camshaft seal ring",
            "NormTermName": "Holder",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Camshaft Seal"
        },
        "02763": {
            "GenartName": "Battery Terminal Clamp",
            "NormTermName": "Clamp",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Battery Terminal"
        },
        "02764": {
            "GenartName": "Selector Lever Module, transmission shift",
            "NormTermName": "Sensor",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Shifting"
        },
        "02766": {
            "GenartName": "Earth Strap",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Battery Earth"
        },
        "02767": {
            "GenartName": "Conversion Catalyst Converter",
            "NormTermName": "Catalytic Converter",
            "AssGrpName": "Exhaust System",
            "UsageName": "Conversion"
        },
        "02768": {
            "GenartName": "Battery Charger Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Battery Charging"
        },
        "02769": {
            "GenartName": "Bulb, worklight",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Worklight"
        },
        "02770": {
            "GenartName": "Bulb, direction indicator",
            "NormTermName": "Bulb",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Direction Indicator"
        },
        "02771": {
            "GenartName": "Bulb, stop/tail light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Brake-/Stoplight"
        },
        "02772": {
            "GenartName": "Connecting Tube, refrigerant line",
            "NormTermName": "Fitting",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Connector Refrigerant Line"
        },
        "02773": {
            "GenartName": "Bulb, stop light",
            "NormTermName": "Bulb",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Stop Light"
        },
        "02774": {
            "GenartName": "Bulb, spotlight",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Spotlight"
        },
        "02775": {
            "GenartName": "Bulb, interior light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Interior Light"
        },
        "02776": {
            "GenartName": "Bulb, instrument lighting",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Instrument Lighting"
        },
        "02777": {
            "GenartName": "Bulb, licence plate light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Licence Plate Light"
        },
        "02778": {
            "GenartName": "Bulb, boot light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Boot Light"
        },
        "02779": {
            "GenartName": "Bulb, engine bay light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Light, engine bay"
        },
        "02780": {
            "GenartName": "Bulb, front fog light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Front Fog Light"
        },
        "02781": {
            "GenartName": "Bulb, rear fog light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Rear Fog Light"
        },
        "02782": {
            "GenartName": "Bulb, park-/position light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Park/End Outline Marker Light"
        },
        "02783": {
            "GenartName": "Bulb, position/end outline marker light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Position/End Outline Marker Light"
        },
        "02784": {
            "GenartName": "Bulb, reverse light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Reverse Light"
        },
        "02785": {
            "GenartName": "Bulb, rotating beacon",
            "NormTermName": "Bulb",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Rotating Beacon"
        },
        "02786": {
            "GenartName": "Bulb, tail light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Tail Light"
        },
        "02787": {
            "GenartName": "Bulb, searchlight",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Searchlight"
        },
        "02788": {
            "GenartName": "Bulb, door light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Door Light"
        },
        "02789": {
            "GenartName": "Bulb, clearance/end outline marker light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Clearance/End Outline Marker Light"
        },
        "02790": {
            "GenartName": "Switch, mirror adjustment",
            "NormTermName": "Switch",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Mirror Adjustment"
        },
        "02791": {
            "GenartName": "Shelve/-system",
            "NormTermName": "Rack",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "02792": {
            "GenartName": "Conversion Kit, cold-start control",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Carburettor",
            "UsageName": "Cold-start Control"
        },
        "02793": {
            "GenartName": "Heatshrink Tube",
            "NormTermName": "Hose Line",
            "AssGrpName": "Standard Parts",
            "UsageName": "Heatshrink Tube"
        },
        "02794": {
            "GenartName": "Isolation Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Standard Parts",
            "UsageName": "Isolation"
        },
        "02797": {
            "GenartName": "Bulb, auxiliary stop light",
            "NormTermName": "Bulb",
            "AssGrpName": "Signal System",
            "UsageName": "Auxiliary Stop Light"
        },
        "02798": {
            "GenartName": "Bulb, auxiliary stop light",
            "NormTermName": "Bulb",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Auxiliary Stop Light"
        },
        "02799": {
            "GenartName": "Clamping Pliers, bellow",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bellow Clamping Clip"
        },
        "02800": {
            "GenartName": "Exterior Mirror, driver cab",
            "NormTermName": "Mirror",
            "AssGrpName": "Driver Cab, universal",
            "UsageName": "Exterior Mirror"
        },
        "02801": {
            "GenartName": "Exterior Mirror",
            "NormTermName": "Mirror",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Exterior Mirror"
        },
        "02802": {
            "GenartName": "Pressure Switch, air conditioning",
            "NormTermName": "Switch",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Pressure Switch"
        },
        "02803": {
            "GenartName": "Holder, exterior mirror",
            "NormTermName": "Holder",
            "AssGrpName": "Driver Cab, universal",
            "UsageName": "Exterior Mirror"
        },
        "02804": {
            "GenartName": "Housing, worklight",
            "NormTermName": "Housing",
            "AssGrpName": "Lights, universal",
            "UsageName": "Worklight"
        },
        "02805": {
            "GenartName": "Refrigerant, air conditioning",
            "NormTermName": "Refrigerant",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": null
        },
        "02806": {
            "GenartName": "Low-pressure Switch, air conditioning",
            "NormTermName": "Switch",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Low Pressure"
        },
        "02807": {
            "GenartName": "Switch, rotating beacon",
            "NormTermName": "Switch",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Rotating Beacon"
        },
        "02808": {
            "GenartName": "Headlight Cleaning System",
            "NormTermName": "Wiper System",
            "AssGrpName": "Headlight Cleaning, universal",
            "UsageName": null
        },
        "02809": {
            "GenartName": "Mirror Glass, exterior mirror",
            "NormTermName": "Mirror Glass",
            "AssGrpName": "Driver Cab, universal",
            "UsageName": "Exterior Mirror"
        },
        "02810": {
            "GenartName": "Mirror Glass, exterior mirror",
            "NormTermName": "Mirror Glass",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Exterior Mirror"
        },
        "02811": {
            "GenartName": "Diffusing Lens,worklight",
            "NormTermName": "Diffusing Lens",
            "AssGrpName": "Lights, universal",
            "UsageName": "Worklight"
        },
        "02812": {
            "GenartName": "Cover, rain sensor",
            "NormTermName": "Cap",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Rain Sensor"
        },
        "02813": {
            "GenartName": "Electric Motor, interior blower",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Heating/Ventilation, universal",
            "UsageName": "Interior Blower"
        },
        "02814": {
            "GenartName": "Electric Motor, rotating beacon",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Rotating Beacon"
        },
        "02815": {
            "GenartName": "Expansion Valve, air conditioning",
            "NormTermName": "Valve",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Expansion"
        },
        "02816": {
            "GenartName": "Housing, direction indicator",
            "NormTermName": "Housing",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Direction Indicator"
        },
        "02817": {
            "GenartName": "Housing, stop light",
            "NormTermName": "Housing",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Stop Light"
        },
        "02818": {
            "GenartName": "Housing, spotlight",
            "NormTermName": "Housing",
            "AssGrpName": "Lights, universal",
            "UsageName": "Spotlight"
        },
        "02819": {
            "GenartName": "Housing, headlight",
            "NormTermName": "Housing",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "02820": {
            "GenartName": "Housing, combination rear light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights, universal",
            "UsageName": "Combination Rear Light"
        },
        "02821": {
            "GenartName": "Housing, licence plate light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights, universal",
            "UsageName": "Licence Plate Light"
        },
        "02822": {
            "GenartName": "Housing, front fog light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights, universal",
            "UsageName": "Front Fog Light"
        },
        "02823": {
            "GenartName": "Housing, rear fog light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights, universal",
            "UsageName": "Rear Fog Light"
        },
        "02824": {
            "GenartName": "Housing, reverse light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights, universal",
            "UsageName": "Reverse Light"
        },
        "02825": {
            "GenartName": "Housing, tail light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights, universal",
            "UsageName": "Tail Light"
        },
        "02828": {
            "GenartName": "Steel Strap, fuel tank",
            "NormTermName": "Strap",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Fuel Tank"
        },
        "02829": {
            "GenartName": "Base, direction indicator",
            "NormTermName": "Holder",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Direction Indicator"
        },
        "02830": {
            "GenartName": "Holder, stop light",
            "NormTermName": "Holder",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Stop Light"
        },
        "02831": {
            "GenartName": "Base, headlight",
            "NormTermName": "Holder",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "02832": {
            "GenartName": "Holder, rear fog light",
            "NormTermName": "Holder",
            "AssGrpName": "Lights, universal",
            "UsageName": "Rear Fog Light"
        },
        "02833": {
            "GenartName": "Base, reverse light",
            "NormTermName": "Holder",
            "AssGrpName": "Lights, universal",
            "UsageName": "Reverse Light"
        },
        "02834": {
            "GenartName": "Holder, reflex reflector",
            "NormTermName": "Holder",
            "AssGrpName": "Lights, universal",
            "UsageName": "Reflex Reflector"
        },
        "02835": {
            "GenartName": "Holder, tail light",
            "NormTermName": "Holder",
            "AssGrpName": "Lights, universal",
            "UsageName": "Tail Light"
        },
        "02836": {
            "GenartName": "Holder, dryer",
            "NormTermName": "Holder",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Dryer"
        },
        "02837": {
            "GenartName": "High-/Low Pressure Line, air conditioning",
            "NormTermName": "Hose Line",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "High Pressure/ Low Pressure"
        },
        "02838": {
            "GenartName": "Cap, work light",
            "NormTermName": "Cap",
            "AssGrpName": "Lights, universal",
            "UsageName": "Worklight"
        },
        "02839": {
            "GenartName": "Cap, spotlight",
            "NormTermName": "Cap",
            "AssGrpName": "Lights, universal",
            "UsageName": "Spotlight"
        },
        "02840": {
            "GenartName": "Cap, headlight",
            "NormTermName": "Cap",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "02841": {
            "GenartName": "Cap, front fog light",
            "NormTermName": "Cap",
            "AssGrpName": "Lights, universal",
            "UsageName": "Front Fog Light"
        },
        "02842": {
            "GenartName": "Compressor, air conditioning",
            "NormTermName": "Compressor",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": null
        },
        "02843": {
            "GenartName": "Condenser, air conditioning",
            "NormTermName": "Condenser",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": null
        },
        "02845": {
            "GenartName": "Bulb Socket, worklight",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Lights, universal",
            "UsageName": "Worklight"
        },
        "02846": {
            "GenartName": "Bulb Socket, spotlight",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Lights, universal",
            "UsageName": "Spotlight"
        },
        "02847": {
            "GenartName": "Bulb Socket, headlight",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "02848": {
            "GenartName": "Bulb Socket, front fog light",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Lights, universal",
            "UsageName": "Front Fog Light"
        },
        "02849": {
            "GenartName": "Bulb Holder, direction indicator",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Direction Indicator"
        },
        "02850": {
            "GenartName": "Bulb Holder, stop light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Stop Light"
        },
        "02851": {
            "GenartName": "Bulb Holder, licence plate light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights, universal",
            "UsageName": "Licence Plate Light"
        },
        "02852": {
            "GenartName": "Bulb Holder, reverse light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights, universal",
            "UsageName": "Reverse Light"
        },
        "02853": {
            "GenartName": "Bulb Holder, tail light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights, universal",
            "UsageName": "Tail Light"
        },
        "02854": {
            "GenartName": "Steering Column Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Steering Column"
        },
        "02855": {
            "GenartName": "Lens, end outline marker light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights, universal",
            "UsageName": "End Outline Marker Light"
        },
        "02856": {
            "GenartName": "Lens, direction indicator",
            "NormTermName": "Lens",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Direction Indicator"
        },
        "02857": {
            "GenartName": "Lens, stop light",
            "NormTermName": "Lens",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Stop Light"
        },
        "02858": {
            "GenartName": "Lens, combination rear light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights, universal",
            "UsageName": "Combination Rear Light"
        },
        "02859": {
            "GenartName": "Lens, licence plate light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights, universal",
            "UsageName": "Licence Plate Light"
        },
        "02860": {
            "GenartName": "Lens, rear fog light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights, universal",
            "UsageName": "Rear Fog Light"
        },
        "02861": {
            "GenartName": "Lens, position light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights, universal",
            "UsageName": "Position Light"
        },
        "02862": {
            "GenartName": "Lens, reverse light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights, universal",
            "UsageName": "Reverse Light"
        },
        "02863": {
            "GenartName": "Lens, rotating beacon",
            "NormTermName": "Lens",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Rotating Beacon"
        },
        "02864": {
            "GenartName": "Lens, tail light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights, universal",
            "UsageName": "Tail Light"
        },
        "02865": {
            "GenartName": "Magnetic Clutch, air conditioning compressor",
            "NormTermName": "Coupling",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Compressor"
        },
        "02866": {
            "GenartName": "Mounting Kit, air conditioning",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": null
        },
        "02867": {
            "GenartName": "Retrofit Kit, air conditioning",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": null
        },
        "02868": {
            "GenartName": "Frame, worklight",
            "NormTermName": "Frame",
            "AssGrpName": "Lights, universal",
            "UsageName": "Worklight"
        },
        "02869": {
            "GenartName": "Frame, spotlight",
            "NormTermName": "Frame",
            "AssGrpName": "Lights, universal",
            "UsageName": "Spotlight"
        },
        "02870": {
            "GenartName": "Frame, headlight",
            "NormTermName": "Frame",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "02871": {
            "GenartName": "Frame, radiator grille",
            "NormTermName": "Frame",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Radiator Grille"
        },
        "02872": {
            "GenartName": "Frame, front fog light",
            "NormTermName": "Frame",
            "AssGrpName": "Lights, universal",
            "UsageName": "Front Fog Light"
        },
        "02873": {
            "GenartName": "Ramp Mirror",
            "NormTermName": "Mirror",
            "AssGrpName": "Driver Cab, universal",
            "UsageName": "Ramp"
        },
        "02874": {
            "GenartName": "Reflector, worklight",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights, universal",
            "UsageName": "Worklight"
        },
        "02875": {
            "GenartName": "Reflector, direction indicator",
            "NormTermName": "Reflector",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Direction Indicator"
        },
        "02876": {
            "GenartName": "Reflector, spotlight",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights, universal",
            "UsageName": "Spotlight"
        },
        "02877": {
            "GenartName": "Reflector, headlight",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "02878": {
            "GenartName": "Reflector, front fog light",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights, universal",
            "UsageName": "Front Fog Light"
        },
        "02879": {
            "GenartName": "Reflector, position/end outline marker light",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights, universal",
            "UsageName": "Position/End Outline Marker Light"
        },
        "02880": {
            "GenartName": "Reflector, rotating beacon",
            "NormTermName": "Reflector",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Rotating Beacon"
        },
        "02881": {
            "GenartName": "Reflector, searchlight",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights, universal",
            "UsageName": "Searchlight"
        },
        "02882": {
            "GenartName": "Control Unit, wipe-/wash interval",
            "NormTermName": "Regulator",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": "Wipe-/Wash Interval"
        },
        "02883": {
            "GenartName": "Relay, window regulator",
            "NormTermName": "Relay",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Window Regulator"
        },
        "02884": {
            "GenartName": "Relay, safety belt alarm",
            "NormTermName": "Relay",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Safety Belt Alarm"
        },
        "02885": {
            "GenartName": "Relay, rear windscreen heating",
            "NormTermName": "Relay",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Rear Windscreen Heating"
        },
        "02886": {
            "GenartName": "Relay, rear fog light",
            "NormTermName": "Relay",
            "AssGrpName": "Lights, universal",
            "UsageName": "Rear Fog Light"
        },
        "02887": {
            "GenartName": "Relay, headlight cleaning",
            "NormTermName": "Relay",
            "AssGrpName": "Headlight Cleaning, universal",
            "UsageName": null
        },
        "02888": {
            "GenartName": "Relay, wipe/wash interval",
            "NormTermName": "Relay",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": "Wipe-/Wash Interval"
        },
        "02889": {
            "GenartName": "Pipe Connector, exhaust system",
            "NormTermName": "Pipe Connector",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": null
        },
        "02890": {
            "GenartName": "Actuator, headlight levelling",
            "NormTermName": "Actuator",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight Levelling"
        },
        "02891": {
            "GenartName": "Sensor Set, xenon light conversion (headlight levelling)",
            "NormTermName": "Sensor Kit",
            "AssGrpName": "Lights, universal",
            "UsageName": "Xenon Light Conversion, (headlight levelling)"
        },
        "02892": {
            "GenartName": "Time-delay Relay, towing device",
            "NormTermName": "Relay",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Timer"
        },
        "02893": {
            "GenartName": "Diffusing Lens, spotlight",
            "NormTermName": "Diffusing Lens",
            "AssGrpName": "Lights, universal",
            "UsageName": "Spotlight"
        },
        "02894": {
            "GenartName": "Diffusing Lens, headlight",
            "NormTermName": "Diffusing Lens",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "02895": {
            "GenartName": "Diffusing Lens, front fog light",
            "NormTermName": "Diffusing Lens",
            "AssGrpName": "Lights, universal",
            "UsageName": "Front Fog Light"
        },
        "02896": {
            "GenartName": "Dryer, air conditioning",
            "NormTermName": "Dehumidifier",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": null
        },
        "02897": {
            "GenartName": "Conversion Kit, air conditioning",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": null
        },
        "02898": {
            "GenartName": "Evaporator, air conditioning",
            "NormTermName": "Evaporator",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": null
        },
        "02899": {
            "GenartName": "Cap, fuel tank",
            "NormTermName": "Closure",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Fuel Tank"
        },
        "02900": {
            "GenartName": "Washer Fluid Reservoir, window cleaning",
            "NormTermName": "Tank",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": "Washing Water"
        },
        "02901": {
            "GenartName": "Washer Fluid Jet, window cleaning",
            "NormTermName": "Nozzle",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": "Washing Water"
        },
        "02902": {
            "GenartName": "Washer Fluid Jet, headlight cleaning",
            "NormTermName": "Nozzle",
            "AssGrpName": "Headlight Cleaning, universal",
            "UsageName": "Washing Water"
        },
        "02903": {
            "GenartName": "Washer Fluid Pump, window cleaning",
            "NormTermName": "Pump",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": "Washing Water"
        },
        "02904": {
            "GenartName": "Washer Fluid Pump, headlight cleaning",
            "NormTermName": "Pump",
            "AssGrpName": "Headlight Cleaning, universal",
            "UsageName": "Washing Water"
        },
        "02905": {
            "GenartName": "Control/Trailing Arm Kit, wheel suspension",
            "NormTermName": "Control/Trailing Arm Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": null
        },
        "02906": {
            "GenartName": "Seal Ring, pre-chamber",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Pre-chamber"
        },
        "02907": {
            "GenartName": "Ignition Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Starter System, universal",
            "UsageName": "Ignition / Starter"
        },
        "02908": {
            "GenartName": "Lamp Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Lights"
        },
        "02909": {
            "GenartName": "Support, radiator fan",
            "NormTermName": "Holder",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Fan"
        },
        "02910": {
            "GenartName": "Belt Drive, rotating beacon",
            "NormTermName": "Belt",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Drive, rotating beacon"
        },
        "02911": {
            "GenartName": "Fuse Box",
            "NormTermName": "Van",
            "AssGrpName": "Electric Supply, universal",
            "UsageName": "Fuse"
        },
        "02913": {
            "GenartName": "Gasket Set, air conditioning compressor",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Compressor"
        },
        "02914": {
            "GenartName": "Coil, magnetic-clutch compressor",
            "NormTermName": "Coil",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Magnetic Clutch Compressor"
        },
        "02915": {
            "GenartName": "Bearing, compressor shaft",
            "NormTermName": "Bearing",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Compressor Shaft"
        },
        "02916": {
            "GenartName": "Connecting Flange, air conditioning compressor",
            "NormTermName": "Flange",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Compressor"
        },
        "02917": {
            "GenartName": "Sealing Cap, air conditioning compressor",
            "NormTermName": "Cap",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Compressor"
        },
        "02918": {
            "GenartName": "Temperature Switch, air conditioning fan",
            "NormTermName": "Switch",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Air Conditioning Fan"
        },
        "02919": {
            "GenartName": "Cylinder Head, air conditioning compressor",
            "NormTermName": "Cylinder Head",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Compressor"
        },
        "02920": {
            "GenartName": "Control Valve, air conditioning compressor",
            "NormTermName": "Valve",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Compressor"
        },
        "02921": {
            "GenartName": "Gasket Set, compressor control valve",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Compressor Control Valve"
        },
        "02922": {
            "GenartName": "Bolt, tensioner pulley",
            "NormTermName": "Screw",
            "AssGrpName": "Belt Drive",
            "UsageName": "Tensioner Pulley"
        },
        "02923": {
            "GenartName": "Spacer, compressor shaft",
            "NormTermName": "Spacer",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Compressor Shaft"
        },
        "02924": {
            "GenartName": "Adaptor, air conditioning compressor",
            "NormTermName": "Adaptor",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Compressor"
        },
        "02925": {
            "GenartName": "Adapter, air conditioning service unit",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Air Conditioning Service Unit"
        },
        "02926": {
            "GenartName": "Holder, air conditioning compressor",
            "NormTermName": "Holder",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Compressor"
        },
        "02927": {
            "GenartName": "Glow Plug, auxiliary heater",
            "NormTermName": "Glow Plug",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Coolant Water Pre-heating"
        },
        "02929": {
            "GenartName": "Pre-Catalytic Converter",
            "NormTermName": "Catalytic Converter",
            "AssGrpName": "Exhaust System",
            "UsageName": "Emission Pre-detoxification"
        },
        "02930": {
            "GenartName": "Chain Tensioner, oil pump drive",
            "NormTermName": "Tensioner",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump Drive Chain"
        },
        "02931": {
            "GenartName": "Rail, oil pump drive chain",
            "NormTermName": "Guide, timing chain",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump Drive Chain"
        },
        "02932": {
            "GenartName": "Conversion Kit, handbrake cable",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Handbrake Cable"
        },
        "02933": {
            "GenartName": "Filter, crankcase ventilation",
            "NormTermName": "Filter",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankcase Ventilation"
        },
        "02934": {
            "GenartName": "Tensioner, chain",
            "NormTermName": "Tensioner",
            "AssGrpName": "Lubrication",
            "UsageName": "Chain Tensioner"
        },
        "02935": {
            "GenartName": "Tensioner, chain",
            "NormTermName": "Tensioner",
            "AssGrpName": "Engine Timing",
            "UsageName": "Chain Tensioner"
        },
        "02936": {
            "GenartName": "Tensioner Guide, oil pump drive chain",
            "NormTermName": "Tensioner Guide",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump Drive Chain"
        },
        "02937": {
            "GenartName": "Tensioner Guide, timing chain",
            "NormTermName": "Tensioner Guide",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain"
        },
        "02938": {
            "GenartName": "Tensioner Guide Pad, timing chain",
            "NormTermName": "Pad, tensioner guide",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain"
        },
        "02939": {
            "GenartName": "Tensioner Guide Set, timing chain",
            "NormTermName": "Tensioner Guide Set",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain"
        },
        "02940": {
            "GenartName": "Tensioner Guide Pad, oil pump drive chain",
            "NormTermName": "Pad, tensioner guide",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump Drive Chain"
        },
        "02941": {
            "GenartName": "Tensioner Guide Set, oil pump drive chain",
            "NormTermName": "Tensioner Guide Set",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump Drive Chain"
        },
        "02942": {
            "GenartName": "Rail Pad, oil pump drive chain",
            "NormTermName": "Lining, timing chain guide",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump Drive Chain"
        },
        "02943": {
            "GenartName": "Rail Set, oil pump drive chain",
            "NormTermName": "Timing-chain guides kit",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump Drive Chain"
        },
        "02944": {
            "GenartName": "Fuel Filter",
            "NormTermName": "Filter",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Fuel"
        },
        "02945": {
            "GenartName": "Thrust Piece, intake/exhaust valve",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Engine Timing",
            "UsageName": "Intake/Exhaust Valve"
        },
        "02948": {
            "GenartName": "Tone Sequence Relay, loud horn",
            "NormTermName": "Relay",
            "AssGrpName": "Signal System",
            "UsageName": "Loud Horn"
        },
        "02949": {
            "GenartName": "Floor Display",
            "NormTermName": "Sales Display",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Floor"
        },
        "02950": {
            "GenartName": "Counter Display",
            "NormTermName": "Sales Display",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Counter"
        },
        "02951": {
            "GenartName": "Suction Fan, cabin air",
            "NormTermName": "Fan",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Suction, cabin air"
        },
        "02952": {
            "GenartName": "Switch Relay, headlight dimmer",
            "NormTermName": "Relay",
            "AssGrpName": "Lights",
            "UsageName": "Switch, headlight dimmer"
        },
        "02953": {
            "GenartName": "Transmission, sunroof electro-motor",
            "NormTermName": "Transmission",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Electro-Motor, sunroof"
        },
        "02955": {
            "GenartName": "Shock Absorber Conversion Kit, leveling control",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leveling Control"
        },
        "02956": {
            "GenartName": "Control Lamp",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Control Lamp"
        },
        "02957": {
            "GenartName": "Voltage Transformer",
            "NormTermName": "Voltage Transformer",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "02958": {
            "GenartName": "Heat Foil, exterior mirror",
            "NormTermName": "Foil",
            "AssGrpName": "Driver Cab",
            "UsageName": "Heatable exterior mirror"
        },
        "02959": {
            "GenartName": "Heat Foil, exterior mirror",
            "NormTermName": "Foil",
            "AssGrpName": "Driver Cab, universal",
            "UsageName": "Heatable exterior mirror"
        },
        "02960": {
            "GenartName": "Wide-Angle Mirror",
            "NormTermName": "Mirror",
            "AssGrpName": "Driver Cab",
            "UsageName": "Wide-angle"
        },
        "02961": {
            "GenartName": "Wide-Angle Mirror",
            "NormTermName": "Mirror",
            "AssGrpName": "Driver Cab, universal",
            "UsageName": "Wide-angle"
        },
        "02962": {
            "GenartName": "Switch, sunroof",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Sunroof"
        },
        "02963": {
            "GenartName": "Switch, sunroof",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Sunroof"
        },
        "02964": {
            "GenartName": "Switch, child safety lock (door)",
            "NormTermName": "Switch",
            "AssGrpName": "Lock System",
            "UsageName": "Child Lock/Door Lock"
        },
        "02965": {
            "GenartName": "Switch, engine brake",
            "NormTermName": "Switch",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Engine Brake"
        },
        "02966": {
            "GenartName": "Control Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Control Switch"
        },
        "02967": {
            "GenartName": "Camber Correction Screw",
            "NormTermName": "Screw",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Camber Adjustment"
        },
        "02968": {
            "GenartName": "Cover, tailgate",
            "NormTermName": "Cover",
            "AssGrpName": "Body",
            "UsageName": "Tailgate"
        },
        "02970": {
            "GenartName": "Exterior mirror Set",
            "NormTermName": "Mirror Set",
            "AssGrpName": "Body",
            "UsageName": "Exterior Mirror"
        },
        "02971": {
            "GenartName": "Capaciter, gate trigger voltage - airbag",
            "NormTermName": "Condenser",
            "AssGrpName": "Safety Systems",
            "UsageName": "Gate Trigger Voltage - airbag"
        },
        "02972": {
            "GenartName": "Transformer, gate trigger voltage - airbag",
            "NormTermName": "Voltage Transformer",
            "AssGrpName": "Safety Systems",
            "UsageName": "Gate Trigger Voltage - airbag"
        },
        "02973": {
            "GenartName": "Control Unit, seat user recognition",
            "NormTermName": "Control Unit",
            "AssGrpName": "Safety Systems",
            "UsageName": "Seat User Recognition"
        },
        "02974": {
            "GenartName": "Impeller, interior blower",
            "NormTermName": "Fan Wheel",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Blower"
        },
        "02975": {
            "GenartName": "Resistor, interior blower",
            "NormTermName": "Resistor",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Blower"
        },
        "02976": {
            "GenartName": "Holder, additional headlight",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Additional Headlight"
        },
        "02977": {
            "GenartName": "Holder Set, additional headlight",
            "NormTermName": "Holder Set",
            "AssGrpName": "Lights",
            "UsageName": "Additional Headlight"
        },
        "02978": {
            "GenartName": "Holder, additional headlight",
            "NormTermName": "Holder",
            "AssGrpName": "Lights, universal",
            "UsageName": "Additional Headlight"
        },
        "02979": {
            "GenartName": "Holder Set, additional headlight",
            "NormTermName": "Holder Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Additional Headlight"
        },
        "02980": {
            "GenartName": "Support Frame/Subframe",
            "NormTermName": "Frame",
            "AssGrpName": "Body",
            "UsageName": "Engine Support"
        },
        "02981": {
            "GenartName": "Radiator Grille Insert",
            "NormTermName": "Insert",
            "AssGrpName": "Body",
            "UsageName": "Radiator Grille"
        },
        "02982": {
            "GenartName": "Adaptor, cold check",
            "NormTermName": "Adaptor",
            "AssGrpName": "Lights",
            "UsageName": "Cold Check"
        },
        "02983": {
            "GenartName": "Conversion Kit, light",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Lights",
            "UsageName": "Conversion"
        },
        "02984": {
            "GenartName": "Deep Cycle Battery",
            "NormTermName": "Battery",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Deep Cycle Battery"
        },
        "02985": {
            "GenartName": "Glow Plug, parking heater",
            "NormTermName": "Glow Plug",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Heater"
        },
        "02986": {
            "GenartName": "Glow Plug, parking heater",
            "NormTermName": "Glow Plug",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Heater"
        },
        "02987": {
            "GenartName": "Cleaning Kit, wheel hub",
            "NormTermName": "Cleaning Tool Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wheel Hub"
        },
        "02988": {
            "GenartName": "Adapter, wiper blade",
            "NormTermName": "Adaptor",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Wiper Blade"
        },
        "02989": {
            "GenartName": "Pinion, distributor",
            "NormTermName": "Pinion",
            "AssGrpName": "Ignition System",
            "UsageName": "Distributor"
        },
        "02990": {
            "GenartName": "Cover Plate, dust-cover wheel bearing",
            "NormTermName": "Shield",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Dust Cover, wheel bearing"
        },
        "02991": {
            "GenartName": "Bulb, door open warning light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Door Open Warning Light"
        },
        "02992": {
            "GenartName": "Bulb, rear fog/tail light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Rear Fog/Tail Light"
        },
        "02993": {
            "GenartName": "Bulb, door open warning light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Door Open Warning Light"
        },
        "02994": {
            "GenartName": "Bulb, rear fog/tail light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Rear Fog/Tail Light"
        },
        "02995": {
            "GenartName": "Spacer Plate, cylinder head saver",
            "NormTermName": "Spacer Plate",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Cylinder Head"
        },
        "02996": {
            "GenartName": "Mounting, support frame/subframe",
            "NormTermName": "Mounting",
            "AssGrpName": "Body",
            "UsageName": "Support Frame/ Engine Carrier"
        },
        "02997": {
            "GenartName": "Bulb, headlight switch",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Headlight Switch"
        },
        "02998": {
            "GenartName": "Bulb, air injection control (cabin air)",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Air Jet Control"
        },
        "02999": {
            "GenartName": "Bulb, climate control",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Climate Control"
        },
        "03000": {
            "GenartName": "Bulb, blower switch",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Blower Switch"
        },
        "03001": {
            "GenartName": "Bulb, automatic shift console",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Automatic Shift Display"
        },
        "03002": {
            "GenartName": "Bulb, glove compartment light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Glove Compartment Light"
        },
        "03003": {
            "GenartName": "Bulb, safety belt alarm",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Safety Belt Alarm"
        },
        "03004": {
            "GenartName": "Bulb, reading light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Reading Light"
        },
        "03005": {
            "GenartName": "Bulb, door entry light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Entry light"
        },
        "03006": {
            "GenartName": "Spacer, brake shaft",
            "NormTermName": "Spacer",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shaft"
        },
        "03007": {
            "GenartName": "Spacer, adjuster",
            "NormTermName": "Spacer",
            "AssGrpName": "Braking System",
            "UsageName": "Adjuster"
        },
        "03008": {
            "GenartName": "Stop Plate, adjuster",
            "NormTermName": "Stop Plate",
            "AssGrpName": "Braking System",
            "UsageName": "Adjuster"
        },
        "03009": {
            "GenartName": "Retaining Plate, brake shoe pins",
            "NormTermName": "Retaining Plate",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoe Pin"
        },
        "03010": {
            "GenartName": "Membrane, spring-loaded cylinder",
            "NormTermName": "Diaphragm",
            "AssGrpName": "Braking System",
            "UsageName": "Spring Loaded Cylinder"
        },
        "03011": {
            "GenartName": "Seal Ring, propshaft mounting",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft Mounting"
        },
        "03012": {
            "GenartName": "Retrofit Kit, pre-catalytic converter",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Pre-Catalytic Converter"
        },
        "03013": {
            "GenartName": "Seal Ring, wheel hub planetary gear",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Wheel Hub Planetary Gear"
        },
        "03014": {
            "GenartName": "Repair Kit, propshaft mounting",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft Mounting"
        },
        "03015": {
            "GenartName": "Shaft Seal, drive shaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "03016": {
            "GenartName": "Seal Ring, steering knuckle",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Steering Knuckle"
        },
        "03017": {
            "GenartName": "Seal Ring, support sleeve for tyre pressure control",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Support Sleeve, tyre pressure control"
        },
        "03018": {
            "GenartName": "Seal Ring, connector for tyre pressure control",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Connector, tyre pressure control"
        },
        "03019": {
            "GenartName": "Bearing, steering knuckle",
            "NormTermName": "Bearing",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Steering Knuckle"
        },
        "03020": {
            "GenartName": "Lock Ring, steering knuckle",
            "NormTermName": "Lock Disc",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Steering Knuckle"
        },
        "03021": {
            "GenartName": "Ball Head, tie rod air spring valve",
            "NormTermName": "Ball Head",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Spring Valve, tie rod"
        },
        "03022": {
            "GenartName": "Ball Joint, tie rod travel sensor",
            "NormTermName": "Joint",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Travel Sensor, tie rod"
        },
        "03023": {
            "GenartName": "Test Connection, pressure reduction valve",
            "NormTermName": "Test Connection",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Pressure Reduction Valve"
        },
        "03024": {
            "GenartName": "Test Connection, air spring bellow",
            "NormTermName": "Test Connection",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Spring Bellow"
        },
        "03025": {
            "GenartName": "Test Connection, relay valve",
            "NormTermName": "Test Connection",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Relay Valve"
        },
        "03026": {
            "GenartName": "Spacer Ring, spring link console",
            "NormTermName": "Spacer Ring",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Link Console"
        },
        "03027": {
            "GenartName": "Spacer Bush, spring eye",
            "NormTermName": "Spacer Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Eye"
        },
        "03028": {
            "GenartName": "Spacer Bush, spring bracket",
            "NormTermName": "Spacer Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bracket"
        },
        "03029": {
            "GenartName": "Spacer Bush, spring clamp",
            "NormTermName": "Spacer Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Clamp"
        },
        "03030": {
            "GenartName": "Spacer Bush, spring clip",
            "NormTermName": "Spacer Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Clip"
        },
        "03031": {
            "GenartName": "Spacer Bush, spring shackle",
            "NormTermName": "Spacer Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Shackle"
        },
        "03032": {
            "GenartName": "Spacer Tube, parabolic spring rubber mount",
            "NormTermName": "Spacer Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Parabolic Spring Rubber Bush"
        },
        "03033": {
            "GenartName": "Spacer Bush, swivel lever spring bracket",
            "NormTermName": "Spacer Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bracket Swivel Lever"
        },
        "03034": {
            "GenartName": "Spacer Bush, shock absorber",
            "NormTermName": "Spacer Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shock Absorber"
        },
        "03035": {
            "GenartName": "Spacer Bush, shock absorber bracket",
            "NormTermName": "Spacer Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shock Absorber Holder"
        },
        "03036": {
            "GenartName": "Spacer, spring bracket",
            "NormTermName": "Spacer",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bracket"
        },
        "03037": {
            "GenartName": "Spacer, spring link",
            "NormTermName": "Spacer",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Link"
        },
        "03038": {
            "GenartName": "Cable, axle support",
            "NormTermName": "Cable",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Axle Support Cable"
        },
        "03039": {
            "GenartName": "Bolt, axle support cable mounting",
            "NormTermName": "Bolt",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Axle Support Cable Mounting Bracket"
        },
        "03040": {
            "GenartName": "Bush, spring eye",
            "NormTermName": "Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Eye"
        },
        "03041": {
            "GenartName": "Bush, spring bracket",
            "NormTermName": "Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bracket"
        },
        "03042": {
            "GenartName": "Bush, guide spring",
            "NormTermName": "Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Guide Spring"
        },
        "03043": {
            "GenartName": "Bush, shock absorber",
            "NormTermName": "Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shock Absorber"
        },
        "03044": {
            "GenartName": "Seal Ring, spring link",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Link"
        },
        "03045": {
            "GenartName": "Pressure Disc, spring bracket",
            "NormTermName": "Pressure Disc",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bracket"
        },
        "03046": {
            "GenartName": "Wedge Unit, drum brake",
            "NormTermName": "Wedge Unit",
            "AssGrpName": "Braking System",
            "UsageName": "Drum Brake"
        },
        "03047": {
            "GenartName": "Conical Pulley, adjuster",
            "NormTermName": "Conical Pulley",
            "AssGrpName": "Braking System",
            "UsageName": "Adjuster"
        },
        "03048": {
            "GenartName": "Repair Kit, spring pin shackle",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Control Arm Shackle, trailing axle"
        },
        "03049": {
            "GenartName": "Seal Ring, brake cylinder brake shoe support",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Cylinder Brake Shoe Carrier"
        },
        "03050": {
            "GenartName": "Seal, axle cap",
            "NormTermName": "Seal",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Cap"
        },
        "03051": {
            "GenartName": "Bellow, pneumatic suspension axle lift",
            "NormTermName": "Bellow",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Suspension Axle Lift"
        },
        "03052": {
            "GenartName": "Ball Socket, tie rod air spring valve",
            "NormTermName": "Ball Socket",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Spring Valve, tie rod"
        },
        "03053": {
            "GenartName": "Repair Kit, compressed-air system coupling",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Compressed-air System Coupling"
        },
        "03054": {
            "GenartName": "Seal, spring link",
            "NormTermName": "Seal",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Link Cover"
        },
        "03055": {
            "GenartName": "Suspension Pin, suspended fork",
            "NormTermName": "Spring Shackle",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shackle Spring"
        },
        "03056": {
            "GenartName": "Spring Mounting, axle beam",
            "NormTermName": "Bearing",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring at axle beam"
        },
        "03057": {
            "GenartName": "Spring Mounting, triangular control arm",
            "NormTermName": "Bearing",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring at triangular control arm"
        },
        "03058": {
            "GenartName": "Spring Mounting, frame",
            "NormTermName": "Bearing",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Frame Spring"
        },
        "03059": {
            "GenartName": "Bush, spring eye",
            "NormTermName": "Bearing",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Eye"
        },
        "03060": {
            "GenartName": "Bush, parabolic spring",
            "NormTermName": "Bearing",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Parabolic Spring"
        },
        "03061": {
            "GenartName": "Bearing, control arm",
            "NormTermName": "Bearing",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Control Arm"
        },
        "03062": {
            "GenartName": "Buffer, spring eye",
            "NormTermName": "Buffer",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Eye"
        },
        "03063": {
            "GenartName": "Buffer, spring leaf",
            "NormTermName": "Buffer",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Leaf"
        },
        "03064": {
            "GenartName": "Buffer, spring plate",
            "NormTermName": "Buffer",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Plate"
        },
        "03065": {
            "GenartName": "Buffer, control arm",
            "NormTermName": "Buffer",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Control Arm"
        },
        "03066": {
            "GenartName": "Automatic Transmission",
            "NormTermName": "Transmission",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "03067": {
            "GenartName": "Valve Unit, aut. transm. hydraulic unit",
            "NormTermName": "Valve",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Hydraulic Unit Automatic Transmission"
        },
        "03068": {
            "GenartName": "Torque Converter",
            "NormTermName": "Torque Converter",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "03069": {
            "GenartName": "Automatic Transmission Fluid",
            "NormTermName": "Oil",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "03070": {
            "GenartName": "Friction Plate Set, multi-plate clutch (automatic transm.)",
            "NormTermName": "Friction Multi-Plate Set",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Multi-Plate Clutch (automatic transmission)"
        },
        "03071": {
            "GenartName": "Steel Lining Disc Set, automatic transmission",
            "NormTermName": "Steel Lining Disc Set",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "03072": {
            "GenartName": "Mechatronics, automatic transmission",
            "NormTermName": "Mechatronics",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "03073": {
            "GenartName": "Support Plate, tappet",
            "NormTermName": "Support Plate",
            "AssGrpName": "Engine Timing",
            "UsageName": "Tappet"
        },
        "03074": {
            "GenartName": "Mounting Tool, ball joint",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ball Joint"
        },
        "03075": {
            "GenartName": "Mounting Tool, bellow",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Universal-axle-/Steering Bellow"
        },
        "03077": {
            "GenartName": "Sensor, battery temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Starter System",
            "UsageName": "Battery Temperature"
        },
        "03078": {
            "GenartName": "Sensor, battery temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Battery Temperature"
        },
        "03079": {
            "GenartName": "Alternator, starter",
            "NormTermName": "Alternator, starter",
            "AssGrpName": "Alternator, starter",
            "UsageName": null
        },
        "03080": {
            "GenartName": "Repair Kit, steering gear",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Steering",
            "UsageName": "Steering Gear"
        },
        "03081": {
            "GenartName": "Accessory Kit, steering gear",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Steering",
            "UsageName": "Steering Gear"
        },
        "03082": {
            "GenartName": "Aerial",
            "NormTermName": "Aerial",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Car Radio"
        },
        "03083": {
            "GenartName": "Aerial Head",
            "NormTermName": "Aerial Head",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "03084": {
            "GenartName": "Aerial Telescope",
            "NormTermName": "Aerial Telescope",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "03085": {
            "GenartName": "Aerial Cable",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Aerial"
        },
        "03086": {
            "GenartName": "Aerial Booster",
            "NormTermName": "Booster",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Aerial"
        },
        "03087": {
            "GenartName": "Navigation System",
            "NormTermName": "Navigation System",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "03088": {
            "GenartName": "Software, navigation system",
            "NormTermName": "Software",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Navigation System"
        },
        "03089": {
            "GenartName": "CD-ROM Road Map",
            "NormTermName": "Software",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "CD-ROM Road Map"
        },
        "03090": {
            "GenartName": "Audio Amplifier",
            "NormTermName": "Booster",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Audio Amplifier"
        },
        "03091": {
            "GenartName": "Loudspeaker System",
            "NormTermName": "Loudspeaker System",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "03092": {
            "GenartName": "Radio / Cassette",
            "NormTermName": "Car Radio",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Radio / Cassette Deck"
        },
        "03093": {
            "GenartName": "Minidisc Radio",
            "NormTermName": "Car Radio",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Radio / Minidisc"
        },
        "03094": {
            "GenartName": "Radio / CD Changer Combination",
            "NormTermName": "Radio / CD-Changer Combination",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "03095": {
            "GenartName": "Minidisc Radio / CD Changer Combination",
            "NormTermName": "Radio / CD-Changer Combination",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Radio / Minidisc"
        },
        "03096": {
            "GenartName": "Water Pump & Timing Belt Kit",
            "NormTermName": "Water Pump-/Timing Belt Kit",
            "AssGrpName": "Cooling System",
            "UsageName": null
        },
        "03097": {
            "GenartName": "Sensor, longitudinal-/lateral acceleration",
            "NormTermName": "Sensor",
            "AssGrpName": "Drive Dynamics Control",
            "UsageName": "Longitudinal-/Transverse Acceleration"
        },
        "03101": {
            "GenartName": "Auxiliary Direction Indicator",
            "NormTermName": "Light",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Auxiliary Direction Indicator"
        },
        "03102": {
            "GenartName": "Water Trap, fuel system",
            "NormTermName": "Trap",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Water"
        },
        "03103": {
            "GenartName": "Steering",
            "NormTermName": "Graphic",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "03104": {
            "GenartName": "Wheel Suspension",
            "NormTermName": "Graphic",
            "AssGrpName": "Wheel Suspension",
            "UsageName": null
        },
        "03105": {
            "GenartName": "Oil Sump, automatic transmission",
            "NormTermName": "Oil Sump",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "03106": {
            "GenartName": "Gasket, automatic transmission oil sump",
            "NormTermName": "Seal",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil sump, automatic transmission"
        },
        "03108": {
            "GenartName": "Gasket, washer fluid pump/washer fluid reservoir",
            "NormTermName": "Seal",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washer Fluid Pump/Washer Fluid Reservoir"
        },
        "03109": {
            "GenartName": "Seal, door entry light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Entry light"
        },
        "03110": {
            "GenartName": "Seal, tail light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Tail Light"
        },
        "03111": {
            "GenartName": "Seal, rear fog light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Rear Fog Light"
        },
        "03112": {
            "GenartName": "Seal, reverse light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Reverse Light"
        },
        "03113": {
            "GenartName": "Seal, licence plate light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Licence Plate Light"
        },
        "03114": {
            "GenartName": "Seal, combination rear light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Combination Rear Light"
        },
        "03115": {
            "GenartName": "Seal, interior light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Interior Light"
        },
        "03116": {
            "GenartName": "Seal, side marker light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Side Marker Light"
        },
        "03117": {
            "GenartName": "Seal, boot light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Boot Light"
        },
        "03118": {
            "GenartName": "Seal, door open warning light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Door Open Warning Light"
        },
        "03119": {
            "GenartName": "Seal, engine bay light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Light, engine bay"
        },
        "03120": {
            "GenartName": "Seal, park light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Park Light"
        },
        "03121": {
            "GenartName": "Seal, daytime running light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Daytime Running Light"
        },
        "03122": {
            "GenartName": "Seal, clearance light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Clearance Light"
        },
        "03123": {
            "GenartName": "Bearing Kit, manual transmission",
            "NormTermName": "Bearing Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "03124": {
            "GenartName": "Seal, licence plate light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights, universal",
            "UsageName": "Licence Plate Light"
        },
        "03125": {
            "GenartName": "Seal, combination rear light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights, universal",
            "UsageName": "Combination Rear Light"
        },
        "03126": {
            "GenartName": "Seal, reverse light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights, universal",
            "UsageName": "Reverse Light"
        },
        "03127": {
            "GenartName": "Seal, door open warning light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights, universal",
            "UsageName": "Door Open Warning Light"
        },
        "03128": {
            "GenartName": "Seal, clearance light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights, universal",
            "UsageName": "Clearance Light"
        },
        "03129": {
            "GenartName": "Seal, tail light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights, universal",
            "UsageName": "Tail Light"
        },
        "03130": {
            "GenartName": "Seal, daytime running light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights, universal",
            "UsageName": "Daytime Running Light"
        },
        "03131": {
            "GenartName": "Seal, end outline marker light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights, universal",
            "UsageName": "End Outline Marker Light"
        },
        "03132": {
            "GenartName": "Seal, interior light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights, universal",
            "UsageName": "Interior Light"
        },
        "03133": {
            "GenartName": "Seal, rear fog light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights, universal",
            "UsageName": "Rear Fog Light"
        },
        "03134": {
            "GenartName": "Seal, park light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights, universal",
            "UsageName": "Park Light"
        },
        "03135": {
            "GenartName": "Seal, rotating beacon",
            "NormTermName": "Seal",
            "AssGrpName": "Signal System",
            "UsageName": "Rotating Beacon"
        },
        "03136": {
            "GenartName": "Seal, auxiliary stop light",
            "NormTermName": "Seal",
            "AssGrpName": "Signal System",
            "UsageName": "Auxiliary Stop Light"
        },
        "03137": {
            "GenartName": "Seal,stop light",
            "NormTermName": "Seal",
            "AssGrpName": "Signal System",
            "UsageName": "Stop Light"
        },
        "03139": {
            "GenartName": "Seal, auxiliary stop light",
            "NormTermName": "Seal",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Auxiliary Stop Light"
        },
        "03140": {
            "GenartName": "Seal, direction indicator",
            "NormTermName": "Seal",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Direction Indicator"
        },
        "03141": {
            "GenartName": "Seal, auxiliary direction indicator",
            "NormTermName": "Seal",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Auxiliary Direction Indicator"
        },
        "03142": {
            "GenartName": "Seal,stop light",
            "NormTermName": "Seal",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Stop Light"
        },
        "03143": {
            "GenartName": "Seal, rotating beacon",
            "NormTermName": "Seal",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Rotating Beacon"
        },
        "03144": {
            "GenartName": "Seal, strobe-type beacon",
            "NormTermName": "Seal",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Strobe-type Beacon"
        },
        "03145": {
            "GenartName": "Expansion Tank, power steering hydraulic oil",
            "NormTermName": "Tank",
            "AssGrpName": "Steering",
            "UsageName": "Hydraulic Oil"
        },
        "03146": {
            "GenartName": "Shuttle Valve, brake pressure",
            "NormTermName": "Valve",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Pressure"
        },
        "03147": {
            "GenartName": "Shuttle Valve, clutch control",
            "NormTermName": "Valve",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Control"
        },
        "03148": {
            "GenartName": "Polisher",
            "NormTermName": "Polisher",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "03149": {
            "GenartName": "Tumble Blaster",
            "NormTermName": "Sandblaster",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Blast Jet"
        },
        "03150": {
            "GenartName": "Sandblast Cabinet",
            "NormTermName": "Sandblaster",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Sandblast Cabinet"
        },
        "03151": {
            "GenartName": "Oil/Grease Separator",
            "NormTermName": "Trap",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Oil/Grease"
        },
        "03153": {
            "GenartName": "Mounting Kit, exhaust manifold",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Manifold"
        },
        "03154": {
            "GenartName": "Wide-angle Lens",
            "NormTermName": "Lens",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Wide-angle"
        },
        "03155": {
            "GenartName": "Mirror Head, caravan mirror",
            "NormTermName": "Mirror Head",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Caravan Mirror"
        },
        "03156": {
            "GenartName": "Tension Strap, caravan mirror",
            "NormTermName": "Tightening Strap",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Caravan Mirror"
        },
        "03157": {
            "GenartName": "Tension Hook, caravan mirror",
            "NormTermName": "Hook",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Caravan Mirror"
        },
        "03158": {
            "GenartName": "Suction Disc, caravan mirror mounting",
            "NormTermName": "Suction Disc",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Caravan Mirror Mounting"
        },
        "03159": {
            "GenartName": "Clamping Piece, caravan mirror",
            "NormTermName": "Clamping Piece",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Caravan Mirror"
        },
        "03160": {
            "GenartName": "Angle Bracket, caravan mirror",
            "NormTermName": "Mounting Angle",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Caravan Mirror"
        },
        "03161": {
            "GenartName": "Protective Foil",
            "NormTermName": "Foil",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Protective Foil"
        },
        "03162": {
            "GenartName": "Child-/Safety Head Rest",
            "NormTermName": "Support",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Child-/Safety Head Rest"
        },
        "03163": {
            "GenartName": "Rear Rack, universal",
            "NormTermName": "Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Rear Rack"
        },
        "03164": {
            "GenartName": "Crimp Connector",
            "NormTermName": "Connector",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Crimp Connection"
        },
        "03165": {
            "GenartName": "Soldered Connector",
            "NormTermName": "Connector",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Soldered Connection"
        },
        "03166": {
            "GenartName": "Squeeze Connector",
            "NormTermName": "Connector",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Squeeze Connection"
        },
        "03167": {
            "GenartName": "Plug Housing",
            "NormTermName": "Connector",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Plug Housing"
        },
        "03168": {
            "GenartName": "Bush, steering control arm",
            "NormTermName": "Bush",
            "AssGrpName": "Steering",
            "UsageName": "Pitman Arm"
        },
        "03169": {
            "GenartName": "Stone Chip Protection, control arm",
            "NormTermName": "Stone Chip Protection",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Control/Trailing Arm"
        },
        "03170": {
            "GenartName": "Shape Plate, spring eye",
            "NormTermName": "Shield",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Form Plate at Spring Eye"
        },
        "03171": {
            "GenartName": "Shape Plate, spring bracket",
            "NormTermName": "Shield",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bracket Form Plate"
        },
        "03172": {
            "GenartName": "Buffer, air spring bellow roller piston",
            "NormTermName": "Buffer",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Air Gaiter Roller Piston"
        },
        "03173": {
            "GenartName": "Taper Screw, spring bolt",
            "NormTermName": "Screw",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bolt Lock"
        },
        "03174": {
            "GenartName": "Indicator Set",
            "NormTermName": "Light Set",
            "AssGrpName": "Signal System",
            "UsageName": "Direction Indicator"
        },
        "03175": {
            "GenartName": "Headlight Set",
            "NormTermName": "Headlight set",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "03176": {
            "GenartName": "Combination Rear Light Set",
            "NormTermName": "Light Set",
            "AssGrpName": "Lights",
            "UsageName": "Combination Rear Light"
        },
        "03177": {
            "GenartName": "Bushing, spring bracket shackle",
            "NormTermName": "Bush",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Shackle-Spring Bracket"
        },
        "03178": {
            "GenartName": "Shackle, spring bracket",
            "NormTermName": "Shackle",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bracket"
        },
        "03179": {
            "GenartName": "Shackle, swinging fork",
            "NormTermName": "Shackle",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shackle Spring"
        },
        "03180": {
            "GenartName": "Bolt, release fork",
            "NormTermName": "Bolt",
            "AssGrpName": "Clutch",
            "UsageName": "Release Fork"
        },
        "03181": {
            "GenartName": "Screw, pressure plate",
            "NormTermName": "Screw",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Pressure Plate"
        },
        "03182": {
            "GenartName": "Spacer Ring, torsion bar",
            "NormTermName": "Spacer Ring",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Torsion Bar"
        },
        "03183": {
            "GenartName": "Spacer Tube, torsion bar",
            "NormTermName": "Spacer Bush",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Torsion Bar"
        },
        "03184": {
            "GenartName": "Spacer Disc, torsion bar",
            "NormTermName": "Spacer",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Torsion Bar"
        },
        "03185": {
            "GenartName": "Spacer Disc, connector rod",
            "NormTermName": "Spacer",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Connector Rod"
        },
        "03186": {
            "GenartName": "Seal Ring, torsion bar",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Torsion Bar"
        },
        "03187": {
            "GenartName": "Joint Bearing, torsion bar",
            "NormTermName": "Joint Bearing",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Torsion Bar"
        },
        "03188": {
            "GenartName": "Joint Bearing, connector rod",
            "NormTermName": "Joint Bearing",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Connector Rod"
        },
        "03189": {
            "GenartName": "Bush, connecting rod",
            "NormTermName": "Bush",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Connector Rod"
        },
        "03190": {
            "GenartName": "Stop- /Mounting Buffer",
            "NormTermName": "Buffer",
            "AssGrpName": "Standard Parts",
            "UsageName": "Stop- /Mounting Buffer"
        },
        "03191": {
            "GenartName": "Flex Hose, exhaust system",
            "NormTermName": "Tube",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Flex Hose"
        },
        "03192": {
            "GenartName": "Adapter, brake line",
            "NormTermName": "Adaptor",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Line"
        },
        "03193": {
            "GenartName": "Bulb, direction indicator/end outline marker light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Direction Indicator/End Outline Marker Light"
        },
        "03194": {
            "GenartName": "Welding Release Agent",
            "NormTermName": "Welding Release Agent",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "03195": {
            "GenartName": "Defroster",
            "NormTermName": "Defroster",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "03196": {
            "GenartName": "Starter Spray",
            "NormTermName": "Starter Spray",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "03197": {
            "GenartName": "Electric Protective Agent",
            "NormTermName": "Insulation Agent",
            "AssGrpName": "Chemical Products",
            "UsageName": "Electric Protector"
        },
        "03200": {
            "GenartName": "Plug Distributor",
            "NormTermName": "Connector",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Plug Distributor"
        },
        "03201": {
            "GenartName": "Chain Spray",
            "NormTermName": "Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Chain Spray"
        },
        "03202": {
            "GenartName": "Ball Joint, axle strut",
            "NormTermName": "Joint",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Strut"
        },
        "03203": {
            "GenartName": "Repair Kit, central joint (triangular control arm)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Triangular Control Arm Central Joint"
        },
        "03204": {
            "GenartName": "Central Joint, triangular control arm",
            "NormTermName": "Joint",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Triangular Control Arm Central Joint"
        },
        "03206": {
            "GenartName": "Wear Plate, leaf spring",
            "NormTermName": "Wear Plate",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leaf Spring"
        },
        "03207": {
            "GenartName": "Ring, flywheel",
            "NormTermName": "Ring",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Flywheel"
        },
        "03208": {
            "GenartName": "Valve Spring Set",
            "NormTermName": "Spring Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": "Valve"
        },
        "03209": {
            "GenartName": "Taxi Toplight",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Taxi Toplight"
        },
        "03210": {
            "GenartName": "Taxi Toplight",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Taxi Toplight"
        },
        "03211": {
            "GenartName": "Bulb, warning light",
            "NormTermName": "Bulb",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Warning Light"
        },
        "03212": {
            "GenartName": "Bulb, switch",
            "NormTermName": "Bulb",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Switch"
        },
        "03213": {
            "GenartName": "Belt Pulley, crankshaft",
            "NormTermName": "Belt Pulley",
            "AssGrpName": "Belt Drive",
            "UsageName": "Crankshaft"
        },
        "03214": {
            "GenartName": "Centering Ring, spring U-bolt",
            "NormTermName": "Centering Ring",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Clamp"
        },
        "03215": {
            "GenartName": "Mounting, spring bracket",
            "NormTermName": "Bearing",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bracket"
        },
        "03216": {
            "GenartName": "Repair Kit, driver cab stabiliser",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Stabiliser"
        },
        "03217": {
            "GenartName": "Bolt, flywheel ring gear carrier",
            "NormTermName": "Screw",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Flywheel Ring Gear Carrier"
        },
        "03219": {
            "GenartName": "Coolant Flange",
            "NormTermName": "Flange",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Circulation"
        },
        "03220": {
            "GenartName": "Handle, bonnet release",
            "NormTermName": "Handle",
            "AssGrpName": "Lock System",
            "UsageName": "Bonnet Release"
        },
        "03221": {
            "GenartName": "Release Tools",
            "NormTermName": "Release Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "03222": {
            "GenartName": "Crimping Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Crimp Connection"
        },
        "03223": {
            "GenartName": "Crimping Pliers Insert Set",
            "NormTermName": "Insert Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Crimping Pliers"
        },
        "03224": {
            "GenartName": "Engine Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Lubrication",
            "UsageName": "Engine"
        },
        "03225": {
            "GenartName": "Manual Transmission Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "03226": {
            "GenartName": "Axle Gear Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Axle Drive",
            "UsageName": "Axle Gear"
        },
        "03227": {
            "GenartName": "Tensioner Bolt, alternator mounting",
            "NormTermName": "Screw",
            "AssGrpName": "Alternator",
            "UsageName": "Alternator Mounting"
        },
        "03228": {
            "GenartName": "Control Arm Reinforcement Brace",
            "NormTermName": "Strut",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Control Arm Reinforcement Brace"
        },
        "03229": {
            "GenartName": "Link/Coupling Rod, stabiliser bar",
            "NormTermName": "Rod/Strut",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser"
        },
        "03230": {
            "GenartName": "Rod/Strut, wheel suspension",
            "NormTermName": "Rod/Strut",
            "AssGrpName": "Wheel Suspension",
            "UsageName": null
        },
        "03231": {
            "GenartName": "Piston Ring",
            "NormTermName": "Piston Ring",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Pistons"
        },
        "03232": {
            "GenartName": "Daytime Running Light Set",
            "NormTermName": "Light Set",
            "AssGrpName": "Lights",
            "UsageName": "Daytime Running Light"
        },
        "03233": {
            "GenartName": "Daytime Running Light Set",
            "NormTermName": "Light Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Daytime Running Light"
        },
        "03234": {
            "GenartName": "Auxiliary Indicator Set",
            "NormTermName": "Light Set",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Auxiliary Direction Indicator"
        },
        "03235": {
            "GenartName": "Indicator Set",
            "NormTermName": "Light Set",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Direction Indicator"
        },
        "03236": {
            "GenartName": "Track Rod Set",
            "NormTermName": "Rod Set",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod"
        },
        "03238": {
            "GenartName": "Holder, control arm mounting",
            "NormTermName": "Holder",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Control Arm Mounting"
        },
        "03239": {
            "GenartName": "Fastening Bolt, axle bracket",
            "NormTermName": "Bolt",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Bracket"
        },
        "03240": {
            "GenartName": "Fastening Bolt, control arm",
            "NormTermName": "Bolt",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Control Arm"
        },
        "03241": {
            "GenartName": "Repair Kit, ramp mirror",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Driver Cab",
            "UsageName": "Ramp Mirror"
        },
        "03242": {
            "GenartName": "Repair Kit, driver cab suspension",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Suspension"
        },
        "03243": {
            "GenartName": "Bush, driver cab suspension",
            "NormTermName": "Bush",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Suspension"
        },
        "03244": {
            "GenartName": "Slave Cylinder, engine brake",
            "NormTermName": "Cylinder",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Engine Brake"
        },
        "03246": {
            "GenartName": "Breather Screw/Valve, radiator",
            "NormTermName": "Screw",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator Breather"
        },
        "03247": {
            "GenartName": "Fan, transmission cooling",
            "NormTermName": "Fan",
            "AssGrpName": "Cooling System",
            "UsageName": "Transmission Cooling"
        },
        "03248": {
            "GenartName": "Gasket, coolant flange",
            "NormTermName": "Seal",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Flange"
        },
        "03249": {
            "GenartName": "Retrofit Kit, soot/particulate filter",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Soot"
        },
        "03250": {
            "GenartName": "Mounting Kit, soot/particulate filter",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Soot"
        },
        "03251": {
            "GenartName": "Door Catch",
            "NormTermName": "Door Catch",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "03252": {
            "GenartName": "Steering Angle Sensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Steering",
            "UsageName": "Steering Angle Position"
        },
        "03254": {
            "GenartName": "Mirror Glass, wide angle mirror",
            "NormTermName": "Mirror Glass",
            "AssGrpName": "Driver Cab",
            "UsageName": "Wide-Angle Mirror"
        },
        "03255": {
            "GenartName": "Mirror Glass, ramp mirror",
            "NormTermName": "Mirror Glass",
            "AssGrpName": "Driver Cab",
            "UsageName": "Ramp Mirror"
        },
        "03256": {
            "GenartName": "Cover, wide angle mirror",
            "NormTermName": "Cover",
            "AssGrpName": "Driver Cab",
            "UsageName": "Wide-Angle Mirror"
        },
        "03257": {
            "GenartName": "Cover, exterior mirror",
            "NormTermName": "Cover",
            "AssGrpName": "Driver Cab",
            "UsageName": "Exterior Mirror"
        },
        "03258": {
            "GenartName": "Frame, wide-angle mirror",
            "NormTermName": "Frame",
            "AssGrpName": "Driver Cab",
            "UsageName": "Wide-Angle Mirror"
        },
        "03259": {
            "GenartName": "Frame, ramp mirror",
            "NormTermName": "Frame",
            "AssGrpName": "Driver Cab",
            "UsageName": "Ramp Mirror"
        },
        "03260": {
            "GenartName": "Frame, exterior mirror",
            "NormTermName": "Frame",
            "AssGrpName": "Driver Cab",
            "UsageName": "Exterior Mirror"
        },
        "03261": {
            "GenartName": "Heating Foil, wide angle mirror",
            "NormTermName": "Foil",
            "AssGrpName": "Driver Cab",
            "UsageName": "Wide-Angle Mirror"
        },
        "03262": {
            "GenartName": "Thrust Washer",
            "NormTermName": "Thrust Washer",
            "AssGrpName": null,
            "UsageName": null
        },
        "03263": {
            "GenartName": "Release Fork",
            "NormTermName": "Release Fork",
            "AssGrpName": null,
            "UsageName": null
        },
        "03264": {
            "GenartName": "Bolt",
            "NormTermName": "Bolt",
            "AssGrpName": null,
            "UsageName": null
        },
        "03265": {
            "GenartName": "Bush",
            "NormTermName": "Bush",
            "AssGrpName": null,
            "UsageName": null
        },
        "03266": {
            "GenartName": "Weld-on Bush",
            "NormTermName": "Weld-on Bush",
            "AssGrpName": null,
            "UsageName": null
        },
        "03267": {
            "GenartName": "Weld-on Ring",
            "NormTermName": "Weld-on Ring",
            "AssGrpName": null,
            "UsageName": null
        },
        "03268": {
            "GenartName": "Bracket",
            "NormTermName": "Bracket",
            "AssGrpName": null,
            "UsageName": null
        },
        "03269": {
            "GenartName": "Sealing Washer",
            "NormTermName": "Sealing Washer",
            "AssGrpName": null,
            "UsageName": null
        },
        "03270": {
            "GenartName": "Gasket Set",
            "NormTermName": "Gasket Set",
            "AssGrpName": null,
            "UsageName": null
        },
        "03271": {
            "GenartName": "Spacer Bush",
            "NormTermName": "Spacer Bush",
            "AssGrpName": null,
            "UsageName": null
        },
        "03272": {
            "GenartName": "Spacer",
            "NormTermName": "Spacer",
            "AssGrpName": null,
            "UsageName": null
        },
        "03273": {
            "GenartName": "Pressure Ring",
            "NormTermName": "Pressure Ring",
            "AssGrpName": null,
            "UsageName": null
        },
        "03274": {
            "GenartName": "Pressure Disc",
            "NormTermName": "Pressure Disc",
            "AssGrpName": null,
            "UsageName": null
        },
        "03275": {
            "GenartName": "Thrust Piece",
            "NormTermName": "Thrust Piece",
            "AssGrpName": null,
            "UsageName": null
        },
        "03276": {
            "GenartName": "Shaped Plate",
            "NormTermName": "Shield",
            "AssGrpName": null,
            "UsageName": "Shaped Plate"
        },
        "03277": {
            "GenartName": "Gas Spring",
            "NormTermName": "Gas Spring",
            "AssGrpName": null,
            "UsageName": null
        },
        "03278": {
            "GenartName": "Ball Joint",
            "NormTermName": "Joint",
            "AssGrpName": null,
            "UsageName": "Ball Joint"
        },
        "03279": {
            "GenartName": "Joint Bearing",
            "NormTermName": "Joint Bearing",
            "AssGrpName": null,
            "UsageName": null
        },
        "03280": {
            "GenartName": "Bearing",
            "NormTermName": "Bearing",
            "AssGrpName": null,
            "UsageName": null
        },
        "03281": {
            "GenartName": "Slide",
            "NormTermName": "Slide",
            "AssGrpName": null,
            "UsageName": null
        },
        "03282": {
            "GenartName": "Rubber Ring",
            "NormTermName": "Gasket/sealing ring",
            "AssGrpName": null,
            "UsageName": null
        },
        "03283": {
            "GenartName": "Rubber Pulley",
            "NormTermName": "Rubber Pulley",
            "AssGrpName": null,
            "UsageName": null
        },
        "03284": {
            "GenartName": "Retaining Plate",
            "NormTermName": "Retaining Plate",
            "AssGrpName": null,
            "UsageName": null
        },
        "03285": {
            "GenartName": "Retaining Clamp",
            "NormTermName": "Retaining Clamp",
            "AssGrpName": null,
            "UsageName": null
        },
        "03286": {
            "GenartName": "Grooved Pin",
            "NormTermName": "Pin",
            "AssGrpName": null,
            "UsageName": "Grooved Pin"
        },
        "03287": {
            "GenartName": "Spherical Bearing",
            "NormTermName": "Bush",
            "AssGrpName": null,
            "UsageName": "Spherical Bearing"
        },
        "03288": {
            "GenartName": "Bearing Shell",
            "NormTermName": "Bearing Shell",
            "AssGrpName": null,
            "UsageName": null
        },
        "03289": {
            "GenartName": "Membrane",
            "NormTermName": "Diaphragm",
            "AssGrpName": null,
            "UsageName": null
        },
        "03290": {
            "GenartName": "Axle Nut",
            "NormTermName": "Nut",
            "AssGrpName": null,
            "UsageName": "Axle Nut"
        },
        "03291": {
            "GenartName": "Oscillating Arm",
            "NormTermName": "Oscillating Arm",
            "AssGrpName": null,
            "UsageName": null
        },
        "03292": {
            "GenartName": "Repair Kit, brake shoe mounting",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoe Mounting"
        },
        "03293": {
            "GenartName": "Fitting",
            "NormTermName": "Fitting",
            "AssGrpName": null,
            "UsageName": null
        },
        "03294": {
            "GenartName": "Vibration Damper",
            "NormTermName": "Damper",
            "AssGrpName": null,
            "UsageName": "Vibration Damper"
        },
        "03295": {
            "GenartName": "Blind Spot Mirror, driver cab",
            "NormTermName": "Mirror",
            "AssGrpName": "Driver Cab",
            "UsageName": "Blind Spot Mirror"
        },
        "03296": {
            "GenartName": "Blind Spot Mirror",
            "NormTermName": "Mirror",
            "AssGrpName": "Body",
            "UsageName": "Blind Spot Mirror"
        },
        "03297": {
            "GenartName": "Impact Ring",
            "NormTermName": "Impact Ring",
            "AssGrpName": null,
            "UsageName": null
        },
        "03298": {
            "GenartName": "Shift Cylinder",
            "NormTermName": "Cylinder",
            "AssGrpName": null,
            "UsageName": "Shift Cylinder"
        },
        "03299": {
            "GenartName": "Impeller, water pump",
            "NormTermName": "Impeller",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "03300": {
            "GenartName": "Leave Spring Stop, auxiliary spring",
            "NormTermName": "Leaf Spring Stop",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Auxiliary Spring"
        },
        "03301": {
            "GenartName": "Water Pump Shaft",
            "NormTermName": "Shaft",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "03302": {
            "GenartName": "Intermediate Bearing, drive shaft",
            "NormTermName": "Bearing",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Intermediate Bearing, drive shaft"
        },
        "03303": {
            "GenartName": "Relay, windscreen heating",
            "NormTermName": "Relay",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Windscreen Heating"
        },
        "03304": {
            "GenartName": "Bearing, radiator fan shaft",
            "NormTermName": "Bearing",
            "AssGrpName": "Cooling System",
            "UsageName": "Fan Shaft, engine cooling"
        },
        "03305": {
            "GenartName": "Seal Ring, oil cooler",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Cooler"
        },
        "03306": {
            "GenartName": "Electric Motor, hinged window",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Hinged Window"
        },
        "03307": {
            "GenartName": "Bolt, crankshaft mounting bracket",
            "NormTermName": "Screw",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Mounting Bracket Crankshaft"
        },
        "03308": {
            "GenartName": "Cover, timing belt",
            "NormTermName": "Cover",
            "AssGrpName": "Belt Drive",
            "UsageName": "Timing Belt"
        },
        "03309": {
            "GenartName": "Test Unit, sensor ring",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Sensor Ring"
        },
        "03310": {
            "GenartName": "Test Unit, shock absorber",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Shock Absorber"
        },
        "03311": {
            "GenartName": "Control Unit, dynamic bending light",
            "NormTermName": "Control Unit",
            "AssGrpName": "Lights",
            "UsageName": "Dynamic Bending Light"
        },
        "03312": {
            "GenartName": "Control Unit, dynamic bending light",
            "NormTermName": "Control Unit",
            "AssGrpName": "Lights, universal",
            "UsageName": "Dynamic Bending Light"
        },
        "03313": {
            "GenartName": "Cylinder Sleeve, air compressor",
            "NormTermName": "Bush",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "03314": {
            "GenartName": "Charge Air Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger"
        },
        "03315": {
            "GenartName": "Valve Plate, air compressor",
            "NormTermName": "Valve Plate",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "03316": {
            "GenartName": "Fuse Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Fuse"
        },
        "03317": {
            "GenartName": "Automatic Circuit Breaker",
            "NormTermName": "Automatic Circuit Breaker",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "03318": {
            "GenartName": "Cable Junction Box",
            "NormTermName": "Cable Junction Box",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "03319": {
            "GenartName": "Plug Insert, cable junction box",
            "NormTermName": "Insert",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Cable Junction Box"
        },
        "03320": {
            "GenartName": "Accessory Kit, finger follower",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": "Finger Follower"
        },
        "03321": {
            "GenartName": "Pulley Kit, timing belt",
            "NormTermName": "Pulley Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": "Timing Belt"
        },
        "03322": {
            "GenartName": "Pulley Kit, V-ribbed belt",
            "NormTermName": "Pulley Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt"
        },
        "03323": {
            "GenartName": "Cap, oil filter housing",
            "NormTermName": "Lid",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Filter Housing"
        },
        "03324": {
            "GenartName": "Oil Separator, crankcase ventilation",
            "NormTermName": "Trap",
            "AssGrpName": "Crankcase",
            "UsageName": "Oil filter (crankcase)"
        },
        "03325": {
            "GenartName": "Ball Socket, tappet",
            "NormTermName": "Ball Socket",
            "AssGrpName": "Engine Timing",
            "UsageName": "Tappet"
        },
        "03326": {
            "GenartName": "Bulbs Assortment",
            "NormTermName": "Assortment",
            "AssGrpName": "Lights, universal",
            "UsageName": "Bulbs"
        },
        "03327": {
            "GenartName": "Combination Rear Light Set",
            "NormTermName": "Light Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Combination Rear Light"
        },
        "03328": {
            "GenartName": "Headlight Set",
            "NormTermName": "Headlight set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "03329": {
            "GenartName": "Spotlight Set",
            "NormTermName": "Headlight set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Spotlight"
        },
        "03330": {
            "GenartName": "Spotlight Set",
            "NormTermName": "Headlight set",
            "AssGrpName": "Lights",
            "UsageName": "Spotlight"
        },
        "03331": {
            "GenartName": "Fog Light Set",
            "NormTermName": "Headlight set",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "03332": {
            "GenartName": "Fog Light Set",
            "NormTermName": "Headlight set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Front Fog Light"
        },
        "03333": {
            "GenartName": "Insert, worklight",
            "NormTermName": "Insert",
            "AssGrpName": "Lights, universal",
            "UsageName": "Worklight"
        },
        "03334": {
            "GenartName": "Insert, spotlight",
            "NormTermName": "Insert",
            "AssGrpName": "Lights, universal",
            "UsageName": "Spotlight"
        },
        "03335": {
            "GenartName": "Insert, headlight",
            "NormTermName": "Insert",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "03336": {
            "GenartName": "Insert, front fog light",
            "NormTermName": "Insert",
            "AssGrpName": "Lights, universal",
            "UsageName": "Front Fog Light"
        },
        "03337": {
            "GenartName": "Insert, searchlight",
            "NormTermName": "Insert",
            "AssGrpName": "Lights, universal",
            "UsageName": "Searchlight"
        },
        "03338": {
            "GenartName": "Auxiliary Indicator Set",
            "NormTermName": "Light Set",
            "AssGrpName": "Signal System",
            "UsageName": "Auxiliary Direction Indicator"
        },
        "03339": {
            "GenartName": "Service Reset",
            "NormTermName": "Service Unit",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "03340": {
            "GenartName": "Auxiliary Control Unit, air suspension",
            "NormTermName": "Control Unit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Auxilary Control Unit Pneumatic Suspension"
        },
        "03341": {
            "GenartName": "Thrust Plate, brake caliper piston reset tool",
            "NormTermName": "Pressure Plate",
            "AssGrpName": "Tool, universal",
            "UsageName": "Reset Tool, brake caliper piston"
        },
        "03342": {
            "GenartName": "Cleaning Disc, wheel hub cleaning set",
            "NormTermName": "Cleaning Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cleaning Set, wheel hub"
        },
        "03343": {
            "GenartName": "Sensor Head, brake fluid tester",
            "NormTermName": "Sensor Head",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Test Unit, brake fluid"
        },
        "03344": {
            "GenartName": "Spindle, brake caliper piston reset tool",
            "NormTermName": "Spindle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Reset Tool, brake caliper piston"
        },
        "03345": {
            "GenartName": "Belt Pulley, water pump",
            "NormTermName": "Belt Pulley",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "03346": {
            "GenartName": "Shaft Seal",
            "NormTermName": "Shaft Seal",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "03347": {
            "GenartName": "Brake Band, automatic transmission",
            "NormTermName": "Brake Band",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "03348": {
            "GenartName": "Seal, plug",
            "NormTermName": "Seal",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Plug"
        },
        "03349": {
            "GenartName": "Screwed Cable Gland",
            "NormTermName": "Fastening",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Screwed Cable Gland"
        },
        "03350": {
            "GenartName": "Corrugated Pipe Fastening",
            "NormTermName": "Fastening",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Corrugated Pipe Fastening"
        },
        "03351": {
            "GenartName": "Safety Clamp",
            "NormTermName": "Bracket",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Safety Clamp"
        },
        "03352": {
            "GenartName": "Adaptor, cigarette lighter",
            "NormTermName": "Adaptor",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Cigarette Lighter"
        },
        "03353": {
            "GenartName": "Central Hydraulic Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Central Hydraulics"
        },
        "03354": {
            "GenartName": "Power Steering Fluid",
            "NormTermName": "Oil",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering"
        },
        "03356": {
            "GenartName": "Antifreeze",
            "NormTermName": "Antifreeze",
            "AssGrpName": "Cooling System",
            "UsageName": null
        },
        "03357": {
            "GenartName": "Brake Fluid",
            "NormTermName": "Oil",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "03358": {
            "GenartName": "Holder, air filter housing",
            "NormTermName": "Holder",
            "AssGrpName": "Air Supply",
            "UsageName": "Air Filter Housing"
        },
        "03359": {
            "GenartName": "Clamping Clip, exterior mirror",
            "NormTermName": "Clamp",
            "AssGrpName": "Driver Cab",
            "UsageName": "Exterior Mirror"
        },
        "03360": {
            "GenartName": "Bush, kingpin",
            "NormTermName": "Bush",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Kingppin"
        },
        "03361": {
            "GenartName": "Repair Kit, wheel hub",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Hub"
        },
        "03362": {
            "GenartName": "Cover, fuel filter",
            "NormTermName": "Lid",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Filter"
        },
        "03363": {
            "GenartName": "Mounting, coolant expansion tank",
            "NormTermName": "Mounting",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Expansion Tank"
        },
        "03365": {
            "GenartName": "Protective Cap/Bellow, shock absorber",
            "NormTermName": "Protective Cap/Bellow",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shock Absorber"
        },
        "03366": {
            "GenartName": "Fan Blade, aircon condenser fan",
            "NormTermName": "Fan Blade",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Aircon. Condenser Fan"
        },
        "03367": {
            "GenartName": "Switch, mirror adjustment",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Mirror Adjustment"
        },
        "03368": {
            "GenartName": "Overvoltage Protection Relay, ABS",
            "NormTermName": "Relay",
            "AssGrpName": "Braking System",
            "UsageName": "ABS Overvoltage Protection Relay"
        },
        "03369": {
            "GenartName": "High Performance Brake Disc",
            "NormTermName": "Brake Disc (high performance brakes)",
            "AssGrpName": "Braking System",
            "UsageName": "High Performance Brakes"
        },
        "03370": {
            "GenartName": "High Performance Brake Kit",
            "NormTermName": "Brake Set (high performance brakes)",
            "AssGrpName": "Braking System",
            "UsageName": "High Performance Brakes"
        },
        "03371": {
            "GenartName": "High Performance Brake Pad Set",
            "NormTermName": "Brake Pad Set (high performance brakes)",
            "AssGrpName": "Braking System",
            "UsageName": "High Performance Brakes"
        },
        "03372": {
            "GenartName": "High Performance Brake Caliper",
            "NormTermName": "Brake Caliper (high performance brakes)",
            "AssGrpName": "Braking System",
            "UsageName": "High Performance Brakes"
        },
        "03373": {
            "GenartName": "Warning Contact, brake pad wear (high performance brakes)",
            "NormTermName": "Contact (high performance brakes)",
            "AssGrpName": "Braking System",
            "UsageName": "High Performance Brakes"
        },
        "03374": {
            "GenartName": "High Performance Brake Hose",
            "NormTermName": "Hose (high performance brakes)",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Hydraulics, high performance brakes"
        },
        "03375": {
            "GenartName": "Holder, brake caliper (high performance brakes)",
            "NormTermName": "Holder (high performance brakes)",
            "AssGrpName": "Braking System",
            "UsageName": "High Performance Brakes"
        },
        "03376": {
            "GenartName": "Mounting Kit, high performance brake caliper",
            "NormTermName": "Mounting Set (high performance brakes)",
            "AssGrpName": "Braking System",
            "UsageName": "High Performance Brakes"
        },
        "03377": {
            "GenartName": "Brake Deactivation Tool",
            "NormTermName": "Service Unit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Deactivation"
        },
        "03378": {
            "GenartName": "Cover Plate, clutch release bearing",
            "NormTermName": "Closure",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Clutch Release Bearing"
        },
        "03379": {
            "GenartName": "Mirror System",
            "NormTermName": "Mirror System",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "03380": {
            "GenartName": "Bellow, mirror system",
            "NormTermName": "Bellow",
            "AssGrpName": "Body",
            "UsageName": "Mirror System"
        },
        "03381": {
            "GenartName": "Mirror Head, mirror system",
            "NormTermName": "Mirror Head",
            "AssGrpName": "Body",
            "UsageName": "Mirror System"
        },
        "03382": {
            "GenartName": "Glass Unit, mirror head",
            "NormTermName": "Glass Unit",
            "AssGrpName": "Body",
            "UsageName": "Mirror Head"
        },
        "03384": {
            "GenartName": "Connecting Cable, exterior mirror",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Driver Cab",
            "UsageName": "Exterior Mirror"
        },
        "03385": {
            "GenartName": "Connecting Cable, exterior mirror",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Body",
            "UsageName": "Exterior Mirror"
        },
        "03386": {
            "GenartName": "Cover, mirror system",
            "NormTermName": "Cover",
            "AssGrpName": "Body",
            "UsageName": "Mirror System"
        },
        "03387": {
            "GenartName": "Baseplate, mirror system",
            "NormTermName": "Baseplate",
            "AssGrpName": "Body",
            "UsageName": "Mirror System"
        },
        "03388": {
            "GenartName": "Baseplate, exterior mirror",
            "NormTermName": "Baseplate",
            "AssGrpName": "Body",
            "UsageName": "Exterior Mirror"
        },
        "03389": {
            "GenartName": "Adaptor, bellows mirror system",
            "NormTermName": "Adaptor",
            "AssGrpName": "Body",
            "UsageName": "Bellow Mirror System"
        },
        "03390": {
            "GenartName": "Cable Set, exterior mirror",
            "NormTermName": "Cable Set",
            "AssGrpName": "Body",
            "UsageName": "Exterior Mirror"
        },
        "03391": {
            "GenartName": "Cable Set, exterior mirror",
            "NormTermName": "Cable Set",
            "AssGrpName": "Driver Cab",
            "UsageName": "Exterior Mirror"
        },
        "03392": {
            "GenartName": "Mirror Glass, glass unit",
            "NormTermName": "Mirror Glass",
            "AssGrpName": "Body",
            "UsageName": "Glass Unit"
        },
        "03393": {
            "GenartName": "Housing, exterior mirror",
            "NormTermName": "Housing",
            "AssGrpName": "Body",
            "UsageName": "Exterior Mirror"
        },
        "03394": {
            "GenartName": "Housing, exterior mirror",
            "NormTermName": "Housing",
            "AssGrpName": "Driver Cab",
            "UsageName": "Exterior Mirror"
        },
        "03395": {
            "GenartName": "Mounting Kit, exterior mirror",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Driver Cab",
            "UsageName": "Exterior Mirror"
        },
        "03396": {
            "GenartName": "Mounting Kit, exterior mirror",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Body",
            "UsageName": "Exterior Mirror"
        },
        "03397": {
            "GenartName": "Mirror Glass, interior mirror",
            "NormTermName": "Mirror Glass",
            "AssGrpName": "Driver Cab",
            "UsageName": "Interior Mirror"
        },
        "03398": {
            "GenartName": "Mirror Glass, blind spot mirror",
            "NormTermName": "Mirror Glass",
            "AssGrpName": "Driver Cab",
            "UsageName": "Blind Spot Mirror"
        },
        "03399": {
            "GenartName": "Mirror Glass, blind spot mirror",
            "NormTermName": "Mirror Glass",
            "AssGrpName": "Body",
            "UsageName": "Blind Spot Mirror"
        },
        "03400": {
            "GenartName": "Sensor, brake pad wear",
            "NormTermName": "Sensor",
            "AssGrpName": "Braking System",
            "UsageName": "Lining Wear"
        },
        "03401": {
            "GenartName": "Hose, air supply",
            "NormTermName": "Hose Line",
            "AssGrpName": "Air Supply",
            "UsageName": null
        },
        "03402": {
            "GenartName": "Hose, cylinder head cover ventilation",
            "NormTermName": "Hose Line",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Cylinder Head Cover Ventilation"
        },
        "03403": {
            "GenartName": "Warning Contact Set, brake pad wear",
            "NormTermName": "Contact Breaker Set",
            "AssGrpName": "Braking System",
            "UsageName": "Lining Wear"
        },
        "03404": {
            "GenartName": "Seal Kit, multi-valve",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Multi-Valve"
        },
        "03405": {
            "GenartName": "Brake Kit, disc brake",
            "NormTermName": "Brake Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Disc Brake"
        },
        "03406": {
            "GenartName": "Mounting, wheel bearing housing",
            "NormTermName": "Mounting",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Bearing Housing"
        },
        "03407": {
            "GenartName": "Filter, fuel pump",
            "NormTermName": "Filter",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Supply Unit"
        },
        "03408": {
            "GenartName": "Wire Brush, battery terminal/clamp cleaning",
            "NormTermName": "Wire Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Battery Terminal/Clamp Cleaning"
        },
        "03409": {
            "GenartName": "Battery Acid Tester",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Battery Acid"
        },
        "03410": {
            "GenartName": "Pressure/Vacuum Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Pressure / Vacuum"
        },
        "03411": {
            "GenartName": "Cartridge, hot gun",
            "NormTermName": "Cartridge",
            "AssGrpName": "Chemical Products",
            "UsageName": "Hot Gun"
        },
        "03412": {
            "GenartName": "Antifreeze Tester",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Coolant"
        },
        "03413": {
            "GenartName": "Tester, glow plug",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Glow Plug"
        },
        "03415": {
            "GenartName": "Hot Air Dryer",
            "NormTermName": "Hot Air Dryer",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "03416": {
            "GenartName": "Marten Repellent",
            "NormTermName": "Marten Repellent",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "03417": {
            "GenartName": "Measuring Cup",
            "NormTermName": "Measuring Cup",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "03418": {
            "GenartName": "Repair Kit, parking brake lever (brake caliper)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake Handle [brake caliper]"
        },
        "03419": {
            "GenartName": "Release Fork, clutch",
            "NormTermName": "Release Fork",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "03420": {
            "GenartName": "Fuse Box",
            "NormTermName": "Van",
            "AssGrpName": "Central Electrics",
            "UsageName": "Fuse"
        },
        "03421": {
            "GenartName": "Transfer Case Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transfer Case"
        },
        "03422": {
            "GenartName": "Steering Gear Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Steering",
            "UsageName": "Steering Gear"
        },
        "03423": {
            "GenartName": "Shaft Seal, water pump shaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump Shaft"
        },
        "03424": {
            "GenartName": "Shaft Seal, steering gear",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Steering",
            "UsageName": "Steering Gear"
        },
        "03425": {
            "GenartName": "Shaft Seal, speedometer drive",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Instruments",
            "UsageName": "Speedometer Drive"
        },
        "03427": {
            "GenartName": "Shaft Seal, countershaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Countershaft"
        },
        "03428": {
            "GenartName": "Repair Kit, window adhesive",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Body",
            "UsageName": "Window Fitting"
        },
        "03429": {
            "GenartName": "Supporting Ring, suspension strut support mount",
            "NormTermName": "Supporting Ring",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Suspension Strut Support Mount"
        },
        "03430": {
            "GenartName": "Switch, rear fog light",
            "NormTermName": "Switch",
            "AssGrpName": "Lights",
            "UsageName": "Rear Fog Light"
        },
        "03431": {
            "GenartName": "Switch, rear fog light",
            "NormTermName": "Switch",
            "AssGrpName": "Lights, universal",
            "UsageName": "Rear Fog Light"
        },
        "03432": {
            "GenartName": "Cylinder Sleeve Kit",
            "NormTermName": "Sleeve Kit",
            "AssGrpName": "Crankcase",
            "UsageName": "Cylinder Sleeve"
        },
        "03433": {
            "GenartName": "Dummy Coupling, coupling head",
            "NormTermName": "Dummy Coupling",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Coupling Head"
        },
        "03434": {
            "GenartName": "Control Unit, battery voltage",
            "NormTermName": "Tester",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Battery Voltage"
        },
        "03435": {
            "GenartName": "Front Muffler",
            "NormTermName": "Damper",
            "AssGrpName": "Exhaust System",
            "UsageName": "Front Muffler"
        },
        "03436": {
            "GenartName": "Centre Muffler",
            "NormTermName": "Damper",
            "AssGrpName": "Exhaust System",
            "UsageName": "Centre Muffler"
        },
        "03437": {
            "GenartName": "Rear Muffler",
            "NormTermName": "Damper",
            "AssGrpName": "Exhaust System",
            "UsageName": "Rear Muffler"
        },
        "03438": {
            "GenartName": "Sealing Cap, screwed cable gland",
            "NormTermName": "Closure",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Screwed Cable Gland"
        },
        "03439": {
            "GenartName": "Anti-Static Band",
            "NormTermName": "Anti-Static Band",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Anti-Static"
        },
        "03440": {
            "GenartName": "Tyre Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Tyre"
        },
        "03441": {
            "GenartName": "Leather Care Lotion",
            "NormTermName": "Car Care Products",
            "AssGrpName": "Chemical Products",
            "UsageName": "Leather"
        },
        "03442": {
            "GenartName": "Leather Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Leather"
        },
        "03443": {
            "GenartName": "Air Freshener",
            "NormTermName": "Air Freshener",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "03444": {
            "GenartName": "Thread Tap",
            "NormTermName": "Thread Tap",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "03445": {
            "GenartName": "Die Stock",
            "NormTermName": "Die Stock",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "03446": {
            "GenartName": "Threading Die",
            "NormTermName": "Threading Die",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "03447": {
            "GenartName": "Tape Wrench",
            "NormTermName": "Tape Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "03448": {
            "GenartName": "Thread Cutter Set",
            "NormTermName": "Thread Cutter Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "03449": {
            "GenartName": "Heat Shield",
            "NormTermName": "Heat Shield",
            "AssGrpName": null,
            "UsageName": null
        },
        "03450": {
            "GenartName": "Diode",
            "NormTermName": "Diode",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "03451": {
            "GenartName": "Lens, side marker light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights",
            "UsageName": "Side Marker Light"
        },
        "03452": {
            "GenartName": "Lens, side marker light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights, universal",
            "UsageName": "Side Marker Light"
        },
        "03453": {
            "GenartName": "Eyelid, rear fog light",
            "NormTermName": "Cover",
            "AssGrpName": "Lights",
            "UsageName": "Rear Fog Light"
        },
        "03454": {
            "GenartName": "Strain Relief, electric cable",
            "NormTermName": "Strain Relief",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Electric Cable"
        },
        "03455": {
            "GenartName": "Turning Knob, seat back adjustment",
            "NormTermName": "Turn Knob",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Seat Back Adjustment"
        },
        "03456": {
            "GenartName": "Side Marker Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Side Marker Light"
        },
        "03457": {
            "GenartName": "Cable Set, combination rear light",
            "NormTermName": "Cable Set",
            "AssGrpName": "Lights",
            "UsageName": "Combination Rear Light"
        },
        "03458": {
            "GenartName": "Lens, clearance light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights",
            "UsageName": "Clearance Light"
        },
        "03459": {
            "GenartName": "Cleaner, carburettor",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Carburettor"
        },
        "03460": {
            "GenartName": "Lens, clearance light",
            "NormTermName": "Lens",
            "AssGrpName": "Lights, universal",
            "UsageName": "Clearance Light"
        },
        "03461": {
            "GenartName": "Cleaner, petrol injection system",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Petrol Injection System"
        },
        "03462": {
            "GenartName": "Cleaner, diesel injection system",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Diesel Injection System"
        },
        "03463": {
            "GenartName": "Torsion Damper, clutch",
            "NormTermName": "Torsion Damper",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch"
        },
        "03464": {
            "GenartName": "Cable Pull, seat back adjustment",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Seat Back Adjustment"
        },
        "03465": {
            "GenartName": "Starter Battery",
            "NormTermName": "Battery",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Starter Battery"
        },
        "03466": {
            "GenartName": "Repair Pipe, catalytic converter",
            "NormTermName": "Tube",
            "AssGrpName": "Exhaust System",
            "UsageName": "Catalytic Converter"
        },
        "03467": {
            "GenartName": "Engine Oil Additive",
            "NormTermName": "Additive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Engine Oil"
        },
        "03468": {
            "GenartName": "Transmission Oil Additive",
            "NormTermName": "Additive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Transmission Oil"
        },
        "03469": {
            "GenartName": "Hydraulic Oil Additive",
            "NormTermName": "Additive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Hydraulic Oil"
        },
        "03470": {
            "GenartName": "Fuel Additive",
            "NormTermName": "Additive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Fuel"
        },
        "03471": {
            "GenartName": "Cleaner, cooling system",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Coolant Circulation"
        },
        "03472": {
            "GenartName": "Vehicle Paint",
            "NormTermName": "Paint",
            "AssGrpName": "Chemical Products",
            "UsageName": "Vehicle Paint"
        },
        "03473": {
            "GenartName": "Brake Caliper Paint",
            "NormTermName": "Paint",
            "AssGrpName": "Chemical Products",
            "UsageName": "Brake Caliper"
        },
        "03474": {
            "GenartName": "Effect Paint",
            "NormTermName": "Paint",
            "AssGrpName": "Chemical Products",
            "UsageName": "Effect Paint"
        },
        "03475": {
            "GenartName": "Varnish",
            "NormTermName": "Paint",
            "AssGrpName": "Chemical Products",
            "UsageName": "Varnish"
        },
        "03476": {
            "GenartName": "RAL Colours",
            "NormTermName": "Paint",
            "AssGrpName": "Chemical Products",
            "UsageName": "RAL"
        },
        "03477": {
            "GenartName": "Synthetic Varnish",
            "NormTermName": "Paint",
            "AssGrpName": "Chemical Products",
            "UsageName": "Synthetic Varnish"
        },
        "03478": {
            "GenartName": "Engine-/Exhaust Paint",
            "NormTermName": "Paint",
            "AssGrpName": "Chemical Products",
            "UsageName": "Engine-/Exhaust Paint"
        },
        "03479": {
            "GenartName": "Seal, air filter housing",
            "NormTermName": "Seal",
            "AssGrpName": "Air Supply",
            "UsageName": "Air Filter Housing"
        },
        "03480": {
            "GenartName": "Signal System, emergency vehicle",
            "NormTermName": "Signal System",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Emergency Vehicle"
        },
        "03481": {
            "GenartName": "Repair Kit, water pump",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "03482": {
            "GenartName": "Breather Hose, expansion tank",
            "NormTermName": "Hose Line",
            "AssGrpName": "Cooling System",
            "UsageName": "Breather, expansion tank"
        },
        "03483": {
            "GenartName": "Reading Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Reading Light"
        },
        "03484": {
            "GenartName": "Bulb, reading light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Reading Light"
        },
        "03485": {
            "GenartName": "Reading Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Reading Light"
        },
        "03486": {
            "GenartName": "Repair Kit, slave cylinder",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Slave Cylinder"
        },
        "03487": {
            "GenartName": "Wiper Blade Rubber",
            "NormTermName": "Wiper Blade Rubber",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": null
        },
        "03488": {
            "GenartName": "Relay",
            "NormTermName": "Relay",
            "AssGrpName": null,
            "UsageName": null
        },
        "03490": {
            "GenartName": "Plug Connector",
            "NormTermName": "Connector",
            "AssGrpName": null,
            "UsageName": "Plug Connector"
        },
        "03491": {
            "GenartName": "Electric Cable",
            "NormTermName": "Electric Cable",
            "AssGrpName": null,
            "UsageName": null
        },
        "03492": {
            "GenartName": "Cable Connector",
            "NormTermName": "Connector",
            "AssGrpName": null,
            "UsageName": "Cable Connector"
        },
        "03493": {
            "GenartName": "Fuse",
            "NormTermName": "Fuse",
            "AssGrpName": null,
            "UsageName": null
        },
        "03494": {
            "GenartName": "Trumpet Horn",
            "NormTermName": "Trumpet Horn",
            "AssGrpName": null,
            "UsageName": null
        },
        "03495": {
            "GenartName": "Socket",
            "NormTermName": "Socket",
            "AssGrpName": null,
            "UsageName": null
        },
        "03496": {
            "GenartName": "Plug",
            "NormTermName": "Plug",
            "AssGrpName": null,
            "UsageName": null
        },
        "03497": {
            "GenartName": "Solenoid",
            "NormTermName": "Solenoid",
            "AssGrpName": null,
            "UsageName": null
        },
        "03498": {
            "GenartName": "Cable Pull Set, parking brake",
            "NormTermName": "Cable Pull Set",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake"
        },
        "03499": {
            "GenartName": "Light Glass, headlight",
            "NormTermName": "Lens",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "03500": {
            "GenartName": "Air Ioniser",
            "NormTermName": "Air Cleaner",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Air Cleaning"
        },
        "03501": {
            "GenartName": "Electric Motor, convertible top cover",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Convertible Top Cover"
        },
        "03502": {
            "GenartName": "Oil Filter, differential",
            "NormTermName": "Filter",
            "AssGrpName": "Lubrication",
            "UsageName": "Differential"
        },
        "03503": {
            "GenartName": "Oil Filter, retarder",
            "NormTermName": "Filter",
            "AssGrpName": "Lubrication",
            "UsageName": "Retarder"
        },
        "03504": {
            "GenartName": "Electric Motor",
            "NormTermName": "Electric Motor",
            "AssGrpName": null,
            "UsageName": "Electric Motor"
        },
        "03506": {
            "GenartName": "Tension Spring, tensioner pulley (timing belt)",
            "NormTermName": "Spring",
            "AssGrpName": "Belt Drive",
            "UsageName": "Tensioner Pulley (timing belt)"
        },
        "03507": {
            "GenartName": "Gasket, EGR valve",
            "NormTermName": "Seal",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "EGR Valve"
        },
        "03508": {
            "GenartName": "Side Reflector",
            "NormTermName": "Reflector",
            "AssGrpName": "Lights, universal",
            "UsageName": "Side Reflector"
        },
        "03509": {
            "GenartName": "Mounting Kit, propshaft joint",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft Joint"
        },
        "03510": {
            "GenartName": "Mounting Kit, stabiliser link/coupling rod",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser Link/Coupling Rod"
        },
        "03511": {
            "GenartName": "Mounting Kit, tie rod end",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod End"
        },
        "03512": {
            "GenartName": "Mounting Kit, bearing bracket brake camshaft",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Bearing Bracket - brake camshaft"
        },
        "03513": {
            "GenartName": "Buffer, oil sump mounting",
            "NormTermName": "Buffer",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil sump Fastening"
        },
        "03514": {
            "GenartName": "Spacer, kingpin",
            "NormTermName": "Spacer",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Kingppin"
        },
        "03515": {
            "GenartName": "Tension Spring, tensioner pulley (V-belt)",
            "NormTermName": "Spring",
            "AssGrpName": "Belt Drive",
            "UsageName": "Tensioner Pulley (V-belt)"
        },
        "03516": {
            "GenartName": "Tension Spring, tensioner pulley (V-ribbed belt)",
            "NormTermName": "Spring",
            "AssGrpName": "Belt Drive",
            "UsageName": "Tensioner Pulley (V-ribbed belt)"
        },
        "03517": {
            "GenartName": "Centre/Rear Muffler",
            "NormTermName": "Damper",
            "AssGrpName": "Exhaust System",
            "UsageName": "Centre/Rear Muffler"
        },
        "03520": {
            "GenartName": "Bearing, differential shaft",
            "NormTermName": "Bearing",
            "AssGrpName": "Axle Drive",
            "UsageName": "Balance Shaft"
        },
        "03521": {
            "GenartName": "Bearing, drive shaft",
            "NormTermName": "Bearing",
            "AssGrpName": "Axle Drive",
            "UsageName": "Drive Shaft"
        },
        "03522": {
            "GenartName": "Wheel Hub",
            "NormTermName": "Graphic",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Hub"
        },
        "03523": {
            "GenartName": "Axle Guide",
            "NormTermName": "Graphic",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Guide"
        },
        "03524": {
            "GenartName": "Wheel Brake",
            "NormTermName": "Graphic",
            "AssGrpName": "Braking System",
            "UsageName": "Wheel Brake"
        },
        "03525": {
            "GenartName": "Spring Mounting",
            "NormTermName": "Graphic",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Mounting"
        },
        "03526": {
            "GenartName": "Spring Stop",
            "NormTermName": "Graphic",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Stop"
        },
        "03527": {
            "GenartName": "Leaf Spring Suspension",
            "NormTermName": "Graphic",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leaf Spring Suspension"
        },
        "03528": {
            "GenartName": "Drive Shaft",
            "NormTermName": "Graphic",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "03529": {
            "GenartName": "Brake Shoe",
            "NormTermName": "Graphic",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoe"
        },
        "03530": {
            "GenartName": "Brake Shaft",
            "NormTermName": "Graphic",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shaft"
        },
        "03531": {
            "GenartName": "Brake Disc",
            "NormTermName": "Graphic",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Disc"
        },
        "03532": {
            "GenartName": "Brake Shoe Mounting",
            "NormTermName": "Graphic",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoe Mounting"
        },
        "03533": {
            "GenartName": "Centre Rod Assembly",
            "NormTermName": "Graphic",
            "AssGrpName": "Steering",
            "UsageName": "Steering Rod"
        },
        "03534": {
            "GenartName": "Air Suspension",
            "NormTermName": "Graphic",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Air Suspension"
        },
        "03535": {
            "GenartName": "Tie Rod",
            "NormTermName": "Graphic",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod"
        },
        "03536": {
            "GenartName": "Catalytic Converter, universal",
            "NormTermName": "Catalytic Converter",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": null
        },
        "03537": {
            "GenartName": "Stub Axle Mounting",
            "NormTermName": "Graphic",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stub Axle Mounting"
        },
        "03538": {
            "GenartName": "Stabiliser Mounting",
            "NormTermName": "Graphic",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser Mounting"
        },
        "03539": {
            "GenartName": "Shock Absorber",
            "NormTermName": "Graphic",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "03540": {
            "GenartName": "Slack Adjuster",
            "NormTermName": "Graphic",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "03541": {
            "GenartName": "Fuel Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Fuel"
        },
        "03542": {
            "GenartName": "Pedal Pad, accelerator pedal",
            "NormTermName": "Pedal Pad",
            "AssGrpName": "Air Supply",
            "UsageName": null
        },
        "03543": {
            "GenartName": "Air Filter, turbocharger",
            "NormTermName": "Filter",
            "AssGrpName": "Air Supply",
            "UsageName": "Turbocharger"
        },
        "03544": {
            "GenartName": "V-Ribbed Belt",
            "NormTermName": "Graphic",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt"
        },
        "03545": {
            "GenartName": "Control Unit, electric fan (engine cooling)",
            "NormTermName": "Control Unit",
            "AssGrpName": "Cooling System",
            "UsageName": "Electric Fan (engine cooling)"
        },
        "03547": {
            "GenartName": "Control, change-over cover (induction pipe)",
            "NormTermName": "Actuator",
            "AssGrpName": "Air Supply",
            "UsageName": "Change-over Cover (induction pipe)"
        },
        "03548": {
            "GenartName": "Control, swirl covers (induction pipe)",
            "NormTermName": "Actuator",
            "AssGrpName": "Air Supply",
            "UsageName": "Swirl Covers (induction pipe)"
        },
        "03550": {
            "GenartName": "Tappet, vacuum pump (braking system)",
            "NormTermName": "Tappet",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum Pump (braking system)"
        },
        "03551": {
            "GenartName": "Change-Over Valve, change-over flap (induction pipe)",
            "NormTermName": "Valve",
            "AssGrpName": "Air Supply",
            "UsageName": "Change-over Cover (induction pipe)"
        },
        "03552": {
            "GenartName": "Change-Over Valve, exhaust-gas door",
            "NormTermName": "Valve",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Gas Door"
        },
        "03553": {
            "GenartName": "Pressure converter, turbocharger",
            "NormTermName": "Pressure Converter",
            "AssGrpName": "Air Supply",
            "UsageName": "Turbocharger"
        },
        "03554": {
            "GenartName": "Change-Over Valve, ventilation blind",
            "NormTermName": "Valve",
            "AssGrpName": "Cooling System",
            "UsageName": "Ventilation Blind"
        },
        "03555": {
            "GenartName": "Change-Over Valve, engine mount",
            "NormTermName": "Valve",
            "AssGrpName": "Engine Mounting System",
            "UsageName": "Engine Mount"
        },
        "03556": {
            "GenartName": "Change-Over Valve, differential lock",
            "NormTermName": "Valve",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential Lock"
        },
        "03557": {
            "GenartName": "Change-Over Valve, ventilation covers",
            "NormTermName": "Valve",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Ventilation Covers"
        },
        "03558": {
            "GenartName": "Bush, shift rod",
            "NormTermName": "Bush",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Shift Rod"
        },
        "03559": {
            "GenartName": "Cover cap, outer door handle",
            "NormTermName": "Cap",
            "AssGrpName": "Lock System",
            "UsageName": "Outer door handle"
        },
        "03560": {
            "GenartName": "Seal Ring, oil screen",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil Strainer"
        },
        "03561": {
            "GenartName": "Bellow, steering column",
            "NormTermName": "Bellow",
            "AssGrpName": "Steering",
            "UsageName": "Steering Column"
        },
        "03562": {
            "GenartName": "Steering Gear",
            "NormTermName": "Graphic",
            "AssGrpName": "Steering",
            "UsageName": "Steering Gear"
        },
        "03563": {
            "GenartName": "Window Regulator",
            "NormTermName": "Graphic",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Window Regulator"
        },
        "03564": {
            "GenartName": "Ramp Mirror",
            "NormTermName": "Graphic",
            "AssGrpName": "Driver Cab",
            "UsageName": "Ramp Mirror"
        },
        "03565": {
            "GenartName": "Exterior Mirror, driver cab",
            "NormTermName": "Graphic",
            "AssGrpName": "Driver Cab",
            "UsageName": "Exterior Mirror (driver cab)"
        },
        "03566": {
            "GenartName": "Driver Cab Suspension",
            "NormTermName": "Graphic",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Suspension"
        },
        "03567": {
            "GenartName": "Wide-Angle Mirror",
            "NormTermName": "Graphic",
            "AssGrpName": "Driver Cab",
            "UsageName": "Wide-Angle Mirror"
        },
        "03568": {
            "GenartName": "V-Belt",
            "NormTermName": "Graphic",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Belt"
        },
        "03569": {
            "GenartName": "Timing Belt",
            "NormTermName": "Graphic",
            "AssGrpName": "Belt Drive",
            "UsageName": "Timing Belt"
        },
        "03570": {
            "GenartName": "Mounting Kit, pre-catalytic converter",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Pre-Catalytic Converter"
        },
        "03615": {
            "GenartName": "Cover, inspection hole (brake pad wear)",
            "NormTermName": "Cover",
            "AssGrpName": "Braking System",
            "UsageName": "Inspection Hole (brake pad wear)"
        },
        "03616": {
            "GenartName": "Seal Ring, brake shoe pulley",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shoe Sleeve"
        },
        "03617": {
            "GenartName": "Filler Cap, axle cap",
            "NormTermName": "Plug",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Cap"
        },
        "03618": {
            "GenartName": "Tab Washer, axle nut",
            "NormTermName": "Tab Washer",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Nut"
        },
        "03619": {
            "GenartName": "Brake Mounting Plate",
            "NormTermName": "Baseplate",
            "AssGrpName": "Braking System",
            "UsageName": "Braking System"
        },
        "03620": {
            "GenartName": "Gas Spring, front panel",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Driver Cab",
            "UsageName": "Front Panel"
        },
        "03621": {
            "GenartName": "Gas Spring, step plate",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Driver Cab",
            "UsageName": "Step Plate"
        },
        "03622": {
            "GenartName": "Bump Stop, driver cab stabiliser",
            "NormTermName": "Buffer",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Stabiliser"
        },
        "03623": {
            "GenartName": "Rubber Buffer, cab suspension block",
            "NormTermName": "Buffer",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Bracket"
        },
        "03624": {
            "GenartName": "Seal, stabiliser mounting (driver cab)",
            "NormTermName": "Seal",
            "AssGrpName": "Driver Cab",
            "UsageName": "Stabiliser Mounting (driver cab)"
        },
        "03625": {
            "GenartName": "Vehicle Combination Paint",
            "NormTermName": "Paint",
            "AssGrpName": "Chemical Products",
            "UsageName": "Vehicle Combination Paint"
        },
        "03626": {
            "GenartName": "Seal, shock-absorber mounting (driver cab)",
            "NormTermName": "Seal",
            "AssGrpName": "Driver Cab",
            "UsageName": "Shock-Absorber Mounting"
        },
        "03627": {
            "GenartName": "Joint Bearing, driver cab suspension",
            "NormTermName": "Joint Bearing",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Shock Absorber"
        },
        "03628": {
            "GenartName": "Spacer Sleeve, shock-absorber mounting (driver cab)",
            "NormTermName": "Sleeve",
            "AssGrpName": "Driver Cab",
            "UsageName": "Shock-Absorber Mounting"
        },
        "03629": {
            "GenartName": "Washer, stabiliser mounting (driver cab)",
            "NormTermName": "Washer",
            "AssGrpName": "Driver Cab",
            "UsageName": "Stabiliser Mounting (driver cab)"
        },
        "03630": {
            "GenartName": "Collar screw, propshaft",
            "NormTermName": "Screw",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft"
        },
        "03631": {
            "GenartName": "Cover, deflection/guide pulley (V-ribbed belt)",
            "NormTermName": "Cover",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt Guidance/Deflection"
        },
        "03632": {
            "GenartName": "Holder, combination rear light",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Combination Rear Light"
        },
        "03633": {
            "GenartName": "Holder, combination rear light",
            "NormTermName": "Holder",
            "AssGrpName": "Lights, universal",
            "UsageName": "Combination Rear Light"
        },
        "03634": {
            "GenartName": "Piston, shift cylinder",
            "NormTermName": "Piston",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shift Cylinder"
        },
        "03635": {
            "GenartName": "Solenoid Valve, shift cylinder",
            "NormTermName": "Valve",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shift Cylinder"
        },
        "03636": {
            "GenartName": "Tester, wheel sensor",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Test Unit, wheel sensor"
        },
        "03637": {
            "GenartName": "Cable Set, tester wheel sensor",
            "NormTermName": "Cable Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Test Unit, wheel sensor"
        },
        "03638": {
            "GenartName": "Gasket Set, connector adapter (bleeding unit)",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Connector adapter (venting unit)"
        },
        "03639": {
            "GenartName": "Software, wiring diagram",
            "NormTermName": "Software",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Wiring Diagrams"
        },
        "03640": {
            "GenartName": "Fuel Cooler",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel"
        },
        "03641": {
            "GenartName": "Sensor, washer fluid level",
            "NormTermName": "Sensor",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washer Fluid Level"
        },
        "03642": {
            "GenartName": "Boot-/Cargo Area Bag",
            "NormTermName": "Bag",
            "AssGrpName": "Accessories",
            "UsageName": "Boot-/Cargo Area"
        },
        "03643": {
            "GenartName": "Boot-/Cargo Area Bag",
            "NormTermName": "Bag",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Boot-/Cargo Area"
        },
        "03644": {
            "GenartName": "Thread Insert",
            "NormTermName": "Insert",
            "AssGrpName": null,
            "UsageName": null
        },
        "03645": {
            "GenartName": "Temperature Switch, radiator fan",
            "NormTermName": "Switch",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Engine Cooling"
        },
        "03646": {
            "GenartName": "Control Unit, CAN bus",
            "NormTermName": "Control Unit",
            "AssGrpName": "Central Electrics",
            "UsageName": "CAN Bus"
        },
        "03647": {
            "GenartName": "Relay, reverse light",
            "NormTermName": "Relay",
            "AssGrpName": "Lights",
            "UsageName": "Reverse Light"
        },
        "03648": {
            "GenartName": "Oil Filter, manual transmission",
            "NormTermName": "Filter",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "03649": {
            "GenartName": "Circlip, brake anchor pin",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Anchor Pin"
        },
        "03650": {
            "GenartName": "Circlip, brake shaft",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shaft"
        },
        "03651": {
            "GenartName": "Circlip, kingpin",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Kingppin"
        },
        "03652": {
            "GenartName": "Self-locking Pin, wheel bolt",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Bolt"
        },
        "03653": {
            "GenartName": "Repair Kit, multi-way valve, fill/bleeding unit (br. hydr.)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Multiway Valve, filling/bleeding unit (brake hydraulics)"
        },
        "03655": {
            "GenartName": "Runner Set, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Runner Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03656": {
            "GenartName": "Drive Spring, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Spring",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03657": {
            "GenartName": "Main Switch, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Switch",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03658": {
            "GenartName": "Power Cable, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03659": {
            "GenartName": "Strainer, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Strainer",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03660": {
            "GenartName": "Suction Lance, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Suction Lance",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03661": {
            "GenartName": "Seal Ring Set, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Seal Ring Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03662": {
            "GenartName": "Sealing Cap, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Closure",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03666": {
            "GenartName": "Pressure Regulator, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Regulator",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03667": {
            "GenartName": "Pressure Relief Valve, filling/bleeding unit (brake hydr.)",
            "NormTermName": "Valve",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03668": {
            "GenartName": "Membrane, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Diaphragm",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03669": {
            "GenartName": "Manometer, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Manometer",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03670": {
            "GenartName": "Breather Valve, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Valve",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Bleeding, filling/bleeding unit (brake hydraulics)"
        },
        "03671": {
            "GenartName": "Shut-off Cock, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Shut-Off Cock",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03672": {
            "GenartName": "Filler Hose, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Hose Line",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "03673": {
            "GenartName": "Limit Switch, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Switch",
            "AssGrpName": "Workshop Devices",
            "UsageName": "End Shut-Off, filling/bleeding unit (brake hydraulics)"
        },
        "03674": {
            "GenartName": "Sun Visor",
            "NormTermName": "Cover",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": "Sun Visor"
        },
        "03675": {
            "GenartName": "Propshaft, axle drive",
            "NormTermName": "Shaft",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft"
        },
        "03677": {
            "GenartName": "Plug, spark plug",
            "NormTermName": "Plug",
            "AssGrpName": "Ignition System, universal",
            "UsageName": "Spark Plug"
        },
        "03678": {
            "GenartName": "Plug, distributor",
            "NormTermName": "Plug",
            "AssGrpName": "Ignition System, universal",
            "UsageName": "Distributor"
        },
        "03679": {
            "GenartName": "Plug, coil",
            "NormTermName": "Plug",
            "AssGrpName": "Ignition System, universal",
            "UsageName": "Ignition Coil"
        },
        "03680": {
            "GenartName": "Bulb, cornering light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Cornering Light"
        },
        "03681": {
            "GenartName": "Cornering Light",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights",
            "UsageName": "Cornering Light"
        },
        "03682": {
            "GenartName": "Cornering Light",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights, universal",
            "UsageName": "Cornering Light"
        },
        "03683": {
            "GenartName": "Bulb, cornering light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Cornering Light"
        },
        "03684": {
            "GenartName": "Bolt",
            "NormTermName": "Bolt",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "03685": {
            "GenartName": "Seal Ring, gearshift linkage",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gearshift Linkage"
        },
        "03686": {
            "GenartName": "Pressure Accumulator, clutch",
            "NormTermName": "Pressure Accumulator",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch"
        },
        "03687": {
            "GenartName": "Spacer, driver cab stabiliser",
            "NormTermName": "Spacer",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Stabiliser"
        },
        "03689": {
            "GenartName": "Seal Ring, spring bush (spring eye)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bush (spring eye)"
        },
        "03690": {
            "GenartName": "Nut, spring support axle",
            "NormTermName": "Nut",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Support Axle"
        },
        "03692": {
            "GenartName": "Glove Compartment",
            "NormTermName": "Glove Compartment",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "03693": {
            "GenartName": "Ashtray",
            "NormTermName": "Ashtray",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "03694": {
            "GenartName": "Sun Visor",
            "NormTermName": "Cover",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Sun Visor"
        },
        "03695": {
            "GenartName": "Safety Belt",
            "NormTermName": "Belt",
            "AssGrpName": "Safety Systems",
            "UsageName": "Safety Belt"
        },
        "03696": {
            "GenartName": "Interior Door Panel",
            "NormTermName": "Panelling",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Interior Door Panel"
        },
        "03697": {
            "GenartName": "Grab Handle, interior trim",
            "NormTermName": "Handle",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Grab Handle"
        },
        "03698": {
            "GenartName": "Hand Brake Lever",
            "NormTermName": "Lever",
            "AssGrpName": "Braking System",
            "UsageName": "Hand Brake Lever"
        },
        "03699": {
            "GenartName": "Cover, hand brake lever",
            "NormTermName": "Cover",
            "AssGrpName": "Braking System",
            "UsageName": "Hand Brake Lever"
        },
        "03700": {
            "GenartName": "Flange, differential",
            "NormTermName": "Flange",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "03701": {
            "GenartName": "Crankcase",
            "NormTermName": "Housing",
            "AssGrpName": "Crankcase",
            "UsageName": null
        },
        "03702": {
            "GenartName": "Crankshaft",
            "NormTermName": "Shaft",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft"
        },
        "03703": {
            "GenartName": "Cover, torsion bar",
            "NormTermName": "Cover",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Torsion Bar"
        },
        "03704": {
            "GenartName": "Needle Bearing, axle beam",
            "NormTermName": "Bearing",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Beam"
        },
        "03705": {
            "GenartName": "Support Lever, torsion bar",
            "NormTermName": "Lever",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Support Lever (Torsion Bar)"
        },
        "03706": {
            "GenartName": "Drive Shaft, oil pump",
            "NormTermName": "Shaft",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "03707": {
            "GenartName": "Gear Knob",
            "NormTermName": "Gear Knob",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "03708": {
            "GenartName": "Synchronizer Ring, manual transmission",
            "NormTermName": "Synchronizer Ring",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "03709": {
            "GenartName": "Stud, tensioner pulley",
            "NormTermName": "Bolt",
            "AssGrpName": "Belt Drive",
            "UsageName": "Tensioner Pulley"
        },
        "03710": {
            "GenartName": "Stud, deflection-/guide pulley",
            "NormTermName": "Bolt",
            "AssGrpName": "Belt Drive",
            "UsageName": "Idler/Guide Pulley"
        },
        "03711": {
            "GenartName": "Repair Kit, wheel sensor (tyre-pressure monitoring system)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheels",
            "UsageName": "Wheel Sensor (tyre-pressure monitoring system)"
        },
        "03712": {
            "GenartName": "Stud",
            "NormTermName": "Bolt",
            "AssGrpName": "Standard Parts",
            "UsageName": "Stud"
        },
        "03713": {
            "GenartName": "Mounting Kit, bumper",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Body",
            "UsageName": "Bumper"
        },
        "03714": {
            "GenartName": "Mounting Kit, bonnet",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Body",
            "UsageName": "Bonnet"
        },
        "03715": {
            "GenartName": "Mounting Kit, front fairing",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Body",
            "UsageName": "Front Cowling"
        },
        "03716": {
            "GenartName": "Mounting Kit, mud guard",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Body",
            "UsageName": "Wing"
        },
        "03717": {
            "GenartName": "Mounting Kit, radiator grille",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Body",
            "UsageName": "Radiator Grille"
        },
        "03718": {
            "GenartName": "Mounting Kit, door",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Body",
            "UsageName": "Door"
        },
        "03719": {
            "GenartName": "Door Handle, interior",
            "NormTermName": "Handle",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "03720": {
            "GenartName": "Dynamic Bending Light",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights",
            "UsageName": "Dynamic Bending Light"
        },
        "03721": {
            "GenartName": "Sensor, Xenon light (headlight levelling)",
            "NormTermName": "Sensor",
            "AssGrpName": "Lights",
            "UsageName": "Headlight Levelling"
        },
        "03723": {
            "GenartName": "Steering Wheel Remote Control, navigation system / car radio",
            "NormTermName": "Remote Control",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Steering Wheel Remote Control- navigation system/radio"
        },
        "03725": {
            "GenartName": "Monitor, navigation system",
            "NormTermName": "Monitor",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Navigation System"
        },
        "03726": {
            "GenartName": "Seal, turbine inlet (charger)",
            "NormTermName": "Seal",
            "AssGrpName": "Air Supply",
            "UsageName": "Turbine Inlet (Charger)"
        },
        "03727": {
            "GenartName": "Seal, turbine outlet",
            "NormTermName": "Seal",
            "AssGrpName": "Air Supply",
            "UsageName": "Turbine Outlet (Charger)"
        },
        "03728": {
            "GenartName": "Seal, oil inlet (charger)",
            "NormTermName": "Seal",
            "AssGrpName": "Air Supply",
            "UsageName": "Oil Inlet (charger)"
        },
        "03729": {
            "GenartName": "Seal, oil outlet (charger)",
            "NormTermName": "Seal",
            "AssGrpName": "Air Supply",
            "UsageName": "Oil Outlet (charger)"
        },
        "03730": {
            "GenartName": "Seal Ring, oil outlet (charger)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Air Supply",
            "UsageName": "Oil Outlet (charger)"
        },
        "03731": {
            "GenartName": "Multimedia Interface",
            "NormTermName": "Interface",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Multimedia"
        },
        "03732": {
            "GenartName": "Remote Control, navigation system / car radio",
            "NormTermName": "Remote Control",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Navigation System/Car Radio"
        },
        "03733": {
            "GenartName": "DVD Player",
            "NormTermName": "DVD Player",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "03734": {
            "GenartName": "Roof Monitor",
            "NormTermName": "Monitor",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Roof Monitor"
        },
        "03735": {
            "GenartName": "Washer, charger mounting bolt",
            "NormTermName": "Washer",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger Mounting Bolt"
        },
        "03736": {
            "GenartName": "Lock Washer, charger",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Air Supply",
            "UsageName": "Lock Washer (Charger)"
        },
        "03737": {
            "GenartName": "Clamping Spring, exhaust pipe mounting (charger)",
            "NormTermName": "Spring",
            "AssGrpName": "Air Supply",
            "UsageName": "Exhaust Pipe Mounting"
        },
        "03738": {
            "GenartName": "Clamp, charger",
            "NormTermName": "Retaining Clamp",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger"
        },
        "03739": {
            "GenartName": "Oil Pipe, charger",
            "NormTermName": "Pipe",
            "AssGrpName": "Air Supply",
            "UsageName": "Oil Pipe (charger)"
        },
        "03740": {
            "GenartName": "Banjo Bolt, charger",
            "NormTermName": "Screw",
            "AssGrpName": "Air Supply",
            "UsageName": "Banjo Bolt (charger)"
        },
        "03741": {
            "GenartName": "Clamp, charge air hose",
            "NormTermName": "Clamp",
            "AssGrpName": "Air Supply",
            "UsageName": "Charge Air Hose"
        },
        "03742": {
            "GenartName": "TV Receiver",
            "NormTermName": "TV Receiver",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "03743": {
            "GenartName": "Seal Ring, charger",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger"
        },
        "03744": {
            "GenartName": "Seal, water connector (charger)",
            "NormTermName": "Seal",
            "AssGrpName": "Air Supply",
            "UsageName": "Water Connector (Charger)"
        },
        "03745": {
            "GenartName": "Adapter Cable, pre-amp",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Pre-Amp"
        },
        "03746": {
            "GenartName": "Connecting Cable, (navigation system)",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Monitor navigation system"
        },
        "03747": {
            "GenartName": "Clamp, brake fluid reservoir (filling/bleeding unit)",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit"
        },
        "03748": {
            "GenartName": "Adapter Cable, steering wheel remote control",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Steering Wheel Remote Control"
        },
        "03749": {
            "GenartName": "Service Unit, electronic brake tools",
            "NormTermName": "Service Unit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Electronic Brake Tools"
        },
        "03750": {
            "GenartName": "Software, electronic brake tools",
            "NormTermName": "Software",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Electronic Brake Tools"
        },
        "03751": {
            "GenartName": "OBD Cable, electronic brake tools",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "OBD Cable, (electronic brake tools)"
        },
        "03752": {
            "GenartName": "USB Cable, electronic brake tools",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "USB Cable (Electronic Brake Tools)"
        },
        "03753": {
            "GenartName": "Bracket, monitor (navigation system)",
            "NormTermName": "Holder",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Monitor navigation system"
        },
        "03754": {
            "GenartName": "Connecting Cable, multimedia interface",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Multimedia Interface"
        },
        "03755": {
            "GenartName": "Scraper",
            "NormTermName": "Scraper",
            "AssGrpName": null,
            "UsageName": null
        },
        "03756": {
            "GenartName": "Repair Kit, pivot shaft bearing",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Steering",
            "UsageName": "Pivot Shaft Bearing"
        },
        "03757": {
            "GenartName": "Connecting Pipe, heater duct",
            "NormTermName": "Tube",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heater Duct"
        },
        "03758": {
            "GenartName": "Seal, heater hose",
            "NormTermName": "Seal",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heater Hose"
        },
        "03759": {
            "GenartName": "Bump Stop, steering knuckle",
            "NormTermName": "Buffer",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Bump Stop, stub axle"
        },
        "03760": {
            "GenartName": "Holding Bracket, bump stop (steering knuckle)",
            "NormTermName": "Holding Bracket",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Bump Stop, stub axle"
        },
        "03761": {
            "GenartName": "Cooling Bin, oil cooler",
            "NormTermName": "Van",
            "AssGrpName": "Cooling System",
            "UsageName": "Air Bin, oil cooler"
        },
        "03762": {
            "GenartName": "Cooling Baffle, oil cooler",
            "NormTermName": "Cooling Baffle",
            "AssGrpName": "Cooling System",
            "UsageName": "Oil Cooler"
        },
        "03763": {
            "GenartName": "Washer",
            "NormTermName": "Washer",
            "AssGrpName": null,
            "UsageName": null
        },
        "03764": {
            "GenartName": "Spring Washer",
            "NormTermName": "Spring Washer",
            "AssGrpName": null,
            "UsageName": null
        },
        "03765": {
            "GenartName": "Diode",
            "NormTermName": "Diode",
            "AssGrpName": null,
            "UsageName": null
        },
        "03766": {
            "GenartName": "Clamping Lever",
            "NormTermName": "Lever",
            "AssGrpName": null,
            "UsageName": null
        },
        "03767": {
            "GenartName": "Holding Bracket",
            "NormTermName": "Holding Bracket",
            "AssGrpName": null,
            "UsageName": null
        },
        "03768": {
            "GenartName": "Drive Pin",
            "NormTermName": "Drive Pin",
            "AssGrpName": null,
            "UsageName": null
        },
        "03769": {
            "GenartName": "Lead Seal, sealing",
            "NormTermName": "Sealant",
            "AssGrpName": null,
            "UsageName": "Sealant"
        },
        "03770": {
            "GenartName": "Seal Wire",
            "NormTermName": "Sealant Wire",
            "AssGrpName": null,
            "UsageName": null
        },
        "03771": {
            "GenartName": "Shaft Seal",
            "NormTermName": "Shaft Seal",
            "AssGrpName": null,
            "UsageName": null
        },
        "03772": {
            "GenartName": "Split Pin",
            "NormTermName": "Securing Elements",
            "AssGrpName": null,
            "UsageName": "Fuse"
        },
        "03773": {
            "GenartName": "Roll Pin",
            "NormTermName": "Pin",
            "AssGrpName": null,
            "UsageName": "Roll Pin"
        },
        "03774": {
            "GenartName": "Type Label",
            "NormTermName": "Type Label",
            "AssGrpName": null,
            "UsageName": null
        },
        "03775": {
            "GenartName": "Connector/Distributor Piece",
            "NormTermName": "Connector",
            "AssGrpName": null,
            "UsageName": null
        },
        "03776": {
            "GenartName": "Anti-friction Bearing Grease",
            "NormTermName": "Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Anti-friction Bearing Grease"
        },
        "03777": {
            "GenartName": "Battery Terminal Grease",
            "NormTermName": "Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Battery Terminal Grease"
        },
        "03778": {
            "GenartName": "Back-up Alarm",
            "NormTermName": "Back-up Alarm",
            "AssGrpName": "Security Systems, universal",
            "UsageName": null
        },
        "03779": {
            "GenartName": "Dynamic Bending Light",
            "NormTermName": "Headlight",
            "AssGrpName": "Lights, universal",
            "UsageName": "Dynamic Bending Light"
        },
        "03780": {
            "GenartName": "Cornering Light Set",
            "NormTermName": "Headlight set",
            "AssGrpName": "Lights",
            "UsageName": "Cornering Light"
        },
        "03781": {
            "GenartName": "Cornering Light Set",
            "NormTermName": "Headlight set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Cornering Light"
        },
        "03782": {
            "GenartName": "First Aid Box",
            "NormTermName": "First Aid Box",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "03783": {
            "GenartName": "Emergency Bag/Case",
            "NormTermName": "Emergency Bag/Case",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "03784": {
            "GenartName": "Thermostat, coolant",
            "NormTermName": "Thermostat",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Coolant"
        },
        "03785": {
            "GenartName": "Electric Motor, convertible top",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Convertible Top"
        },
        "03786": {
            "GenartName": "Control Unit, convertible top",
            "NormTermName": "Control Unit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Convertible Top"
        },
        "03787": {
            "GenartName": "Mounting Kit, wheel bearing",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Bearing"
        },
        "03788": {
            "GenartName": "Sensor, odometer",
            "NormTermName": "Sensor",
            "AssGrpName": "Instruments",
            "UsageName": "Trip Recorder"
        },
        "03789": {
            "GenartName": "Hinge, fuel filler flap",
            "NormTermName": "Hinge",
            "AssGrpName": "Body",
            "UsageName": "Fuel Filler Flap"
        },
        "03790": {
            "GenartName": "Sealing Cap, expansion tank (power steering)",
            "NormTermName": "Closure",
            "AssGrpName": "Steering",
            "UsageName": "Expansion Tank, power steering"
        },
        "03791": {
            "GenartName": "Assortment, holder exhaust system",
            "NormTermName": "Assortment",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Mount Exhaust System"
        },
        "03792": {
            "GenartName": "Track Widening",
            "NormTermName": "Spacer",
            "AssGrpName": "Wheels",
            "UsageName": "Track Widening"
        },
        "03793": {
            "GenartName": "Battery Adapter",
            "NormTermName": "Adaptor",
            "AssGrpName": "Starter System",
            "UsageName": "Starter Battery"
        },
        "03794": {
            "GenartName": "Engine Preheating System",
            "NormTermName": "Engine Preheating System",
            "AssGrpName": "Comfort Systems",
            "UsageName": null
        },
        "03795": {
            "GenartName": "Heating Element, engine preheater system",
            "NormTermName": "Heating Element",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Engine Preheating System"
        },
        "03796": {
            "GenartName": "Cable Kit, engine preheating system",
            "NormTermName": "Cable Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Engine Preheating System"
        },
        "03797": {
            "GenartName": "Cable Set, interior heating fan, (engine preheating system)",
            "NormTermName": "Cable Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Interior Heating Fan, (engine preheating system)"
        },
        "03798": {
            "GenartName": "Remote Control Unit, engine preheater system",
            "NormTermName": "Remote Start Control Unit",
            "AssGrpName": "Comfort Systems",
            "UsageName": null
        },
        "03799": {
            "GenartName": "Battery Charger, engine preheater system",
            "NormTermName": "Charger",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Battery"
        },
        "03800": {
            "GenartName": "Timing Unit, engine preheater system",
            "NormTermName": "Timing Unit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Engine Preheating System"
        },
        "03801": {
            "GenartName": "Heater Fan",
            "NormTermName": "Fan",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Heater Fan"
        },
        "03802": {
            "GenartName": "Water Recirculaton Pump, engine preheater system",
            "NormTermName": "Pump",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Engine Preheating System"
        },
        "03803": {
            "GenartName": "Heat Shield, heating element (engine preheater system)",
            "NormTermName": "Heat Shield",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Heating Element (engine preheating system)"
        },
        "03804": {
            "GenartName": "Mounting Kit, engine preheating system",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Engine Preheating System"
        },
        "03805": {
            "GenartName": "Bolt Kit, bolted plate (towbar)",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Bolted Plate, (towbar)"
        },
        "03806": {
            "GenartName": "Shaft Seal, drive shaft (oil pump)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Lubrication",
            "UsageName": "Drive Shaft (oil pump)"
        },
        "03807": {
            "GenartName": "Shaft Seal, releaser shaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Clutch",
            "UsageName": "Releaser Shaft"
        },
        "03808": {
            "GenartName": "Shaft Seal, manual transmission main shaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission Main Shaft"
        },
        "03809": {
            "GenartName": "Shaft Seal, compressor shaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Compressor Shaft"
        },
        "03811": {
            "GenartName": "Shaft Seal, power steering pump",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering Pump"
        },
        "03812": {
            "GenartName": "Seal, suspension strut support mount",
            "NormTermName": "Seal",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Suspension Strut Support Mount"
        },
        "03813": {
            "GenartName": "Actuator, exentric shaft (variable valve lift)",
            "NormTermName": "Actuator",
            "AssGrpName": "Engine Timing",
            "UsageName": "Exenter Shaft (variable valve lift)"
        },
        "03814": {
            "GenartName": "Seal, tappet chamber cover",
            "NormTermName": "Seal",
            "AssGrpName": "Engine Timing",
            "UsageName": "Tappet Chamber Cover"
        },
        "03815": {
            "GenartName": "Gasket, housing cover (crankcase)",
            "NormTermName": "Seal",
            "AssGrpName": "Crankcase",
            "UsageName": "Housing Cover, crankcase"
        },
        "03816": {
            "GenartName": "Seal, turbo boost control valve",
            "NormTermName": "Seal",
            "AssGrpName": "Air Supply",
            "UsageName": "Boost Pressure Control Valve"
        },
        "03817": {
            "GenartName": "Reverse Camera, parking distance control",
            "NormTermName": "Camera",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Distance Control"
        },
        "03818": {
            "GenartName": "Reverse Camera, parking distance control",
            "NormTermName": "Camera",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Distance Control"
        },
        "03819": {
            "GenartName": "Screen, parking distance control",
            "NormTermName": "Monitor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Distance Control"
        },
        "03820": {
            "GenartName": "Screen, parking distance control",
            "NormTermName": "Monitor",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Distance Control"
        },
        "03821": {
            "GenartName": "Repair Kit",
            "NormTermName": "Repair Kit",
            "AssGrpName": null,
            "UsageName": null
        },
        "03822": {
            "GenartName": "Tyre Valve Activator",
            "NormTermName": "Service Unit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Tyre Valve Activator"
        },
        "03823": {
            "GenartName": "Testing Set, flywheel",
            "NormTermName": "Test Equipment Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flywheel"
        },
        "03824": {
            "GenartName": "Mounting Set, wide angle mirror",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Driver Cab",
            "UsageName": "Wide-Angle Mirror"
        },
        "03825": {
            "GenartName": "Clutch Actuation System",
            "NormTermName": "Clutch Actuation System",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "03826": {
            "GenartName": "Control Valve, camshaft adjustment",
            "NormTermName": "Valve",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Adjustment"
        },
        "03827": {
            "GenartName": "Control Box, charger",
            "NormTermName": "Control Box",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger"
        },
        "03828": {
            "GenartName": "Housing, end outline marker light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights",
            "UsageName": "End Outline Marker Light"
        },
        "03829": {
            "GenartName": "Housing, end outline marker light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights, universal",
            "UsageName": "End Outline Marker Light"
        },
        "03830": {
            "GenartName": "Cover, external mirror holder",
            "NormTermName": "Cover",
            "AssGrpName": "Body",
            "UsageName": "Exterior mirror Holder"
        },
        "03831": {
            "GenartName": "Connecting Cable, trailer stabilising system",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Trailer Stabilising System"
        },
        "03832": {
            "GenartName": "Cable Set, combination rear light",
            "NormTermName": "Cable Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Combination Rear Light"
        },
        "03833": {
            "GenartName": "Cable Set, signal system (emergency vehicle)",
            "NormTermName": "Cable Set",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Signal System (emergency vehicle)"
        },
        "03834": {
            "GenartName": "Cable Set, strobe-type beacon",
            "NormTermName": "Cable Set",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Strobe-type Beacon"
        },
        "03835": {
            "GenartName": "Clamping Piece",
            "NormTermName": "Clamping Piece",
            "AssGrpName": null,
            "UsageName": null
        },
        "03836": {
            "GenartName": "Incident Light Meter",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Incident Light Meter"
        },
        "03837": {
            "GenartName": "Tester Shield, headlight settings control unit",
            "NormTermName": "Tester Shield",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Control Unit Headlight Settings"
        },
        "03838": {
            "GenartName": "Roller Set, headlight settings control unit",
            "NormTermName": "Pulley Kit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Control Unit Headlight Settings"
        },
        "03839": {
            "GenartName": "Guide Rail, headlight settings testing unit",
            "NormTermName": "Guide Rail",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Control Unit Headlight Settings"
        },
        "03840": {
            "GenartName": "Adjuster, headlight settings testing unit",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Control Unit Headlight Settings"
        },
        "03841": {
            "GenartName": "Optic Box, headlight settings testing unit",
            "NormTermName": "Optic Box",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Control Unit Headlight Settings"
        },
        "03842": {
            "GenartName": "Knob, headlight settings testing unit",
            "NormTermName": "Hand Wheel",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Control Unit Headlight Settings"
        },
        "03843": {
            "GenartName": "Hydraulic Filter Set, automatic transmission",
            "NormTermName": "Hydraulic Filter Set",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "03844": {
            "GenartName": "Seal, folding roof",
            "NormTermName": "Seal",
            "AssGrpName": "Body",
            "UsageName": "Folding Roof"
        },
        "03845": {
            "GenartName": "Belt Pulley, hydraulic pump (leveling control)",
            "NormTermName": "Belt Pulley",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Hydraulic Pump (leveling control)"
        },
        "03846": {
            "GenartName": "Roller Guide, sliding door",
            "NormTermName": "Guide",
            "AssGrpName": "Body",
            "UsageName": "Roller Rails"
        },
        "03847": {
            "GenartName": "Mounting Set, tensioner pulley (timing belt)",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": "Tensioner Pulley (timing belt)"
        },
        "03848": {
            "GenartName": "Mounting Set, tensioner pulley (V-ribbed belt)",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt Tensioner"
        },
        "03849": {
            "GenartName": "Mounting Set, deflection-/guide pulley (timing belt)",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": "Deflection-/Guide Pulley (timing belt)"
        },
        "03850": {
            "GenartName": "Mounting Set, deflection-/guide pulley (V-ribbed belt)",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": "Deflection/Guide Pulley (V-ribbed belt)"
        },
        "03852": {
            "GenartName": "Radiator Core, charge air cooler",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Air Supply",
            "UsageName": "Radiator, intercooling"
        },
        "03853": {
            "GenartName": "Bulb, daytime running light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Daytime Running Light"
        },
        "03854": {
            "GenartName": "Bulb, daytime running light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights, universal",
            "UsageName": "Daytime Running Light"
        },
        "03855": {
            "GenartName": "Urea Filter",
            "NormTermName": "Filter",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea"
        },
        "03856": {
            "GenartName": "Software, technical data",
            "NormTermName": "Software",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Technical Data"
        },
        "03857": {
            "GenartName": "Drive Arm, wiper linkage",
            "NormTermName": "Rod",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Drive Arm"
        },
        "03858": {
            "GenartName": "Air Deflector, engine",
            "NormTermName": "Cooling Baffle",
            "AssGrpName": "Cooling System",
            "UsageName": "Engine"
        },
        "03859": {
            "GenartName": "Brake Kit, drum brake",
            "NormTermName": "Brake Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Drum Brake"
        },
        "03860": {
            "GenartName": "Oil Pressure Switch, power steering",
            "NormTermName": "Switch",
            "AssGrpName": "Steering",
            "UsageName": "Oil Pressure, power steering"
        },
        "03861": {
            "GenartName": "Interior Mirror",
            "NormTermName": "Mirror",
            "AssGrpName": "Body",
            "UsageName": "Inside"
        },
        "03862": {
            "GenartName": "Interior Mirror",
            "NormTermName": "Mirror",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Inside"
        },
        "03864": {
            "GenartName": "Operating Unit, signal system (emergency vehicle)",
            "NormTermName": "Operating Unit",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Signal System (emergency vehicle)"
        },
        "03865": {
            "GenartName": "Fitting Frame, signal system control unit (emergency vehicle",
            "NormTermName": "Frame",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Operating Unit, (emergency vehicle)"
        },
        "03866": {
            "GenartName": "Antenna, self-diagnosis unit",
            "NormTermName": "Aerial",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Self-diagnosis Unit"
        },
        "03867": {
            "GenartName": "Power Cable, self-diagnosis unit",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Self-diagnosis Unit"
        },
        "03868": {
            "GenartName": "OBD Extension Cable, self-diagnosis unit",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "OBD Extension Cable, self-diagnosis unit"
        },
        "03869": {
            "GenartName": "Adapter Cable, self-diagnosis unit",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Self-diagnosis Unit"
        },
        "03870": {
            "GenartName": "Condensed Water Drainage Hose, interior air filter housing",
            "NormTermName": "Hose Line",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Condensed Water Drainage Hose, interior air filter housing"
        },
        "03871": {
            "GenartName": "Mounting, engine",
            "NormTermName": "Graphic",
            "AssGrpName": "Engine Mounting System",
            "UsageName": null
        },
        "03872": {
            "GenartName": "Mounting, automatic transmission",
            "NormTermName": "Graphic",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "03873": {
            "GenartName": "Mounting, manual transmission",
            "NormTermName": "Graphic",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "03874": {
            "GenartName": "Shaft Seal Set, engine",
            "NormTermName": "Shaft Seal Set",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Engine"
        },
        "03875": {
            "GenartName": "Holder, manual transmission mounting",
            "NormTermName": "Holder",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Mounting"
        },
        "03876": {
            "GenartName": "Holder, automatic transmission mounting",
            "NormTermName": "Holder",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Transmission Mounting"
        },
        "03877": {
            "GenartName": "Testing Unit, towbar socket",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Towbar Socket"
        },
        "03878": {
            "GenartName": "Shaft Seal Set, clutch",
            "NormTermName": "Shaft Seal Set",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch"
        },
        "03879": {
            "GenartName": "Housing, water pump",
            "NormTermName": "Housing",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "03880": {
            "GenartName": "Connecting Rod, air compressor",
            "NormTermName": "Connecting Rod",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "03881": {
            "GenartName": "Clip, trim/protection strip",
            "NormTermName": "Clip",
            "AssGrpName": "Body",
            "UsageName": "Trim/Protection Strip"
        },
        "03882": {
            "GenartName": "Gasket Remover",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Gasket Seat"
        },
        "03883": {
            "GenartName": "Sports Air Filter",
            "NormTermName": "Filter",
            "AssGrpName": "Air Supply",
            "UsageName": "Sports Air Filter"
        },
        "03884": {
            "GenartName": "Air Intake System",
            "NormTermName": "Air Intake System",
            "AssGrpName": "Air Supply",
            "UsageName": null
        },
        "03886": {
            "GenartName": "Valve, crankcase ventilation",
            "NormTermName": "Valve",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase Ventilation"
        },
        "03887": {
            "GenartName": "Airbag",
            "NormTermName": "Airbag",
            "AssGrpName": "Safety Systems",
            "UsageName": null
        },
        "03888": {
            "GenartName": "Seal Kit, hydraulic pump (leveling control)",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Hydraulic Pump (leveling control)"
        },
        "03889": {
            "GenartName": "Cover, injector nozzle",
            "NormTermName": "Cover",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Nozzle"
        },
        "03890": {
            "GenartName": "Fuel Cut-off, injection system",
            "NormTermName": "Cut-Off",
            "AssGrpName": "Mixture Formation",
            "UsageName": null
        },
        "03891": {
            "GenartName": "Seal Ring, injector",
            "NormTermName": "Gasket/sealing ring",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector"
        },
        "03892": {
            "GenartName": "Seal, injector holder",
            "NormTermName": "Seal",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Holder"
        },
        "03893": {
            "GenartName": "Seal, injection pump",
            "NormTermName": "Seal",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump"
        },
        "03894": {
            "GenartName": "Seal Kit, injector nozzle",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Nozzle"
        },
        "03895": {
            "GenartName": "Injection System",
            "NormTermName": "Injection System",
            "AssGrpName": "Mixture Formation",
            "UsageName": null
        },
        "03897": {
            "GenartName": "Pressure Accumulator",
            "NormTermName": "Pressure Accumulator",
            "AssGrpName": "Mixture Formation",
            "UsageName": null
        },
        "03898": {
            "GenartName": "Injector Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Nozzle"
        },
        "03899": {
            "GenartName": "Nozzle Body",
            "NormTermName": "Nozzle Body",
            "AssGrpName": "Mixture Formation",
            "UsageName": null
        },
        "03900": {
            "GenartName": "Nozzle Needle",
            "NormTermName": "Nozzle Needle",
            "AssGrpName": "Mixture Formation",
            "UsageName": null
        },
        "03901": {
            "GenartName": "Nozzle and Holder Assembly",
            "NormTermName": "Nozzle and Holder Assembly",
            "AssGrpName": "Mixture Formation",
            "UsageName": null
        },
        "03902": {
            "GenartName": "Injector Nozzle",
            "NormTermName": "Nozzle",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Nozzle"
        },
        "03903": {
            "GenartName": "Injection Unit, central injection",
            "NormTermName": "Injection Unit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Central Injection"
        },
        "03904": {
            "GenartName": "Injection Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump"
        },
        "03905": {
            "GenartName": "Injector",
            "NormTermName": "Valve",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector"
        },
        "03906": {
            "GenartName": "Adjusting Potentiometer, idle mixture",
            "NormTermName": "Potentiometer",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Idle Mixture"
        },
        "03907": {
            "GenartName": "Electric Motor, blower control unit",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Controls Box, blower"
        },
        "03908": {
            "GenartName": "Accelerator Pedal Kit",
            "NormTermName": "Sensor Kit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Accelerator Pedal Position"
        },
        "03909": {
            "GenartName": "Spring, throttle control linkage",
            "NormTermName": "Spring",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Throttle Control Linkage"
        },
        "03910": {
            "GenartName": "Flange, central injection",
            "NormTermName": "Flange",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Central Injection"
        },
        "03911": {
            "GenartName": "Accelerator Cable",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Accelerator Cable"
        },
        "03912": {
            "GenartName": "Linkage Damper, injection system",
            "NormTermName": "Damper",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Throttle Control Linkage"
        },
        "03913": {
            "GenartName": "Linkage Shock Absorber, throttle valve",
            "NormTermName": "Damper",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Linkage"
        },
        "03914": {
            "GenartName": "Holder, injector",
            "NormTermName": "Holder",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector"
        },
        "03915": {
            "GenartName": "Heater Flange, intake-air preheater",
            "NormTermName": "Flange",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Intake-Air Preheating"
        },
        "03916": {
            "GenartName": "High Pressure Pipe, injection system",
            "NormTermName": "Pipe",
            "AssGrpName": "Mixture Formation",
            "UsageName": null
        },
        "03917": {
            "GenartName": "High Pressure Pipe Set, injection system",
            "NormTermName": "Pipe Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": null
        },
        "03918": {
            "GenartName": "High Pressure Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Mixture Formation",
            "UsageName": "High Pressure Pump"
        },
        "03919": {
            "GenartName": "Cold Start Regulation System, euro2 conversion",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Idle RPM Increase for Euro2"
        },
        "03920": {
            "GenartName": "Cold-start Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Cold-start Control"
        },
        "03921": {
            "GenartName": "Knock Sensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Engine Knock"
        },
        "03922": {
            "GenartName": "Lambda Sensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Lambda Value"
        },
        "03923": {
            "GenartName": "Lambda Sensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Lambda Value"
        },
        "03924": {
            "GenartName": "Lambda sensor Set",
            "NormTermName": "Sensor Kit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Lambda Value"
        },
        "03925": {
            "GenartName": "Air Pressure Sensor, altitude adaptation",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Air Pressure altitude adaptation"
        },
        "03926": {
            "GenartName": "Air Mass Sensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Air Mass"
        },
        "03927": {
            "GenartName": "Air Flow Sensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Air Flow"
        },
        "03928": {
            "GenartName": "Bellow, fuel pump",
            "NormTermName": "Bellow",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "03929": {
            "GenartName": "Fuel Distributor, injection system",
            "NormTermName": "Distributor",
            "AssGrpName": "Mixture Formation",
            "UsageName": null
        },
        "03930": {
            "GenartName": "Unit Injector (UI)",
            "NormTermName": "Unit Injector (UI)",
            "AssGrpName": "Mixture Formation",
            "UsageName": null
        },
        "03931": {
            "GenartName": "Relay, fuel cutoff",
            "NormTermName": "Relay",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Fuel Cutoff, trailing throttle"
        },
        "03932": {
            "GenartName": "Repair Kit, common rail system",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Common Rail System"
        },
        "03933": {
            "GenartName": "Repair Kit, injection nozzle",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Nozzle"
        },
        "03934": {
            "GenartName": "Repair Kit, unit injector",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Unit Injector (UI)"
        },
        "03935": {
            "GenartName": "Switch, brake actuation (engine timing)",
            "NormTermName": "Switch",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Brake Actuation (engine timing)"
        },
        "03936": {
            "GenartName": "Switch, clutch control (engine timing)",
            "NormTermName": "Switch",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Clutch Control (engine timing)"
        },
        "03937": {
            "GenartName": "Hose, fuel overflow",
            "NormTermName": "Hose Line",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Leak Fuel"
        },
        "03938": {
            "GenartName": "Sensor, exhaust gas temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Exhaust Gas Temperature"
        },
        "03939": {
            "GenartName": "Sensor, intake air temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Intake Air Temperature"
        },
        "03940": {
            "GenartName": "Sensor, throttle position",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Throttle Valve"
        },
        "03941": {
            "GenartName": "Sensor, accelerator pedal position",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Accelerator Pedal Position"
        },
        "03942": {
            "GenartName": "Sensor, fuel pressure",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Fuel Pressure"
        },
        "03943": {
            "GenartName": "Sensor, fuel temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Fuel Temperature"
        },
        "03944": {
            "GenartName": "Sensor, coolant temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Coolant Temperature"
        },
        "03945": {
            "GenartName": "Sensor, boost pressure",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Boost Pressure"
        },
        "03946": {
            "GenartName": "Sensor, camshaft position",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Camshaft"
        },
        "03947": {
            "GenartName": "Sensor, intake manifold pressure",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Intake Manifold"
        },
        "03948": {
            "GenartName": "Tensioning Nut, injector holder",
            "NormTermName": "Nut",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Holder"
        },
        "03949": {
            "GenartName": "Plug, injector",
            "NormTermName": "Plug",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector"
        },
        "03950": {
            "GenartName": "Acuator, throttle valve",
            "NormTermName": "Actuator",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Throttle Valve"
        },
        "03951": {
            "GenartName": "Actuator, injector pump",
            "NormTermName": "Actuator",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump"
        },
        "03952": {
            "GenartName": "Control Unit, injection system",
            "NormTermName": "Control Unit",
            "AssGrpName": "Mixture Formation",
            "UsageName": null
        },
        "03953": {
            "GenartName": "Control Unit, knock sensor",
            "NormTermName": "Control Unit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Knock Sensor"
        },
        "03954": {
            "GenartName": "Control Unit, fuel injection",
            "NormTermName": "Control Unit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Fuel Injection"
        },
        "03955": {
            "GenartName": "Control Unit, lambda control",
            "NormTermName": "Control Unit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Lambda Control"
        },
        "03956": {
            "GenartName": "Control Unit, engine management",
            "NormTermName": "Control Unit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Engine Management"
        },
        "03957": {
            "GenartName": "Temperature Switch, cold start system",
            "NormTermName": "Switch",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Warm-upPhase"
        },
        "03958": {
            "GenartName": "Vacuum Switch, intake manifold",
            "NormTermName": "Switch",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Intake Manifold Air"
        },
        "03959": {
            "GenartName": "Valve, injection system",
            "NormTermName": "Valve",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection System"
        },
        "03960": {
            "GenartName": "Valve, fuel filter",
            "NormTermName": "Valve",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Filter"
        },
        "03961": {
            "GenartName": "Valve, fuel cut-off",
            "NormTermName": "Valve",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Fuel Cutoff, trailing throttle"
        },
        "03962": {
            "GenartName": "Valve Unit, common rail system",
            "NormTermName": "Valve",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Common Rail System"
        },
        "03963": {
            "GenartName": "Sealing Cap, fuel overflow",
            "NormTermName": "Cap",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Leak Fuel"
        },
        "03964": {
            "GenartName": "Distributor Pipe, fuel",
            "NormTermName": "Tube",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Fuel Distributor Tube"
        },
        "03965": {
            "GenartName": "Series Resistor, injector",
            "NormTermName": "Resistor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector"
        },
        "03966": {
            "GenartName": "Heat Shield, injection system",
            "NormTermName": "Heat Shield",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Thermal Protection"
        },
        "03967": {
            "GenartName": "Warm-up Regulator",
            "NormTermName": "Regulator",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Warm-upPhase"
        },
        "03968": {
            "GenartName": "Shaft Seal, injector pump",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump"
        },
        "03970": {
            "GenartName": "Connector Sleeve, ignition cable",
            "NormTermName": "Sleeve",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition Cable"
        },
        "03971": {
            "GenartName": "Flex Hose, exhaust system",
            "NormTermName": "Tube",
            "AssGrpName": "Exhaust System",
            "UsageName": "Flex Hose"
        },
        "03972": {
            "GenartName": "Control Unit, battery management",
            "NormTermName": "Control Unit",
            "AssGrpName": "Central Electrics",
            "UsageName": "Battery Management"
        },
        "03973": {
            "GenartName": "Lens, auxiliary direction indicator",
            "NormTermName": "Lens",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Auxiliary Direction Indicator"
        },
        "03974": {
            "GenartName": "Seal Ring Set, solenoid valve",
            "NormTermName": "Seal Ring Set",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Solenoid Valve"
        },
        "03976": {
            "GenartName": "Valve Cap",
            "NormTermName": "Cap",
            "AssGrpName": "Engine Timing",
            "UsageName": "Intake/Exhaust Valve"
        },
        "03977": {
            "GenartName": "Bush, cylinder head cover",
            "NormTermName": "Bush",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Cylinder Head Cover"
        },
        "03978": {
            "GenartName": "Flywheel Housing",
            "NormTermName": "Housing",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Flywheel"
        },
        "03979": {
            "GenartName": "Seal Ring, exhaust manifold",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Exhaust Manifold"
        },
        "03980": {
            "GenartName": "Seal Ring, charge air hose",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Air Supply",
            "UsageName": "Charge Air Hose"
        },
        "03981": {
            "GenartName": "Bolt, exhaust manifold",
            "NormTermName": "Screw",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Manifold"
        },
        "03983": {
            "GenartName": "Fork Head, clutch booster (thrust rod)",
            "NormTermName": "Fork Head",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Clutch Booster (thrust rod)"
        },
        "03984": {
            "GenartName": "Bolt, fork head (clutch booster)",
            "NormTermName": "Bolt",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Fork Head (clutch booster)"
        },
        "03985": {
            "GenartName": "Screw Set, flywheel",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Flywheel"
        },
        "03986": {
            "GenartName": "Gasket, clutch housing",
            "NormTermName": "Seal",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Housing"
        },
        "03987": {
            "GenartName": "Gear, water pump shaft",
            "NormTermName": "Gear",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump Shaft"
        },
        "03988": {
            "GenartName": "Seal Ring, coolant pipe",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Pipe"
        },
        "03989": {
            "GenartName": "Cover, clutch housing",
            "NormTermName": "Lid",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Housing"
        },
        "03990": {
            "GenartName": "Gasket, manual transmission housing",
            "NormTermName": "Seal",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gearshift Housing"
        },
        "03991": {
            "GenartName": "Oil Pump, manual transmission",
            "NormTermName": "Pump",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "03992": {
            "GenartName": "Drive Plate, injector pump drive unit",
            "NormTermName": "Drive Plate",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Pump Drive Unit"
        },
        "03993": {
            "GenartName": "Sleeve, injector pump drive unit",
            "NormTermName": "Sleeve",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Pump Drive Unit"
        },
        "03994": {
            "GenartName": "Nut, injector pump drive flange",
            "NormTermName": "Nut",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Pump Drive Flange"
        },
        "03995": {
            "GenartName": "Drive Flange, propshaft",
            "NormTermName": "Flange",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft"
        },
        "03996": {
            "GenartName": "Pressure Control Valve, common rail system",
            "NormTermName": "Valve",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Common Rail System"
        },
        "03997": {
            "GenartName": "Sealing Cap, level compensation valve draw bar fork",
            "NormTermName": "Cap",
            "AssGrpName": "Driver Cab",
            "UsageName": "Draw Bar Fork Level Compensation Valve"
        },
        "03998": {
            "GenartName": "Bellow, shift rod arm safety pin",
            "NormTermName": "Bellow",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shift Rod Arm Safety Pin"
        },
        "03999": {
            "GenartName": "Anti-Rotation Actuator, manual transmission",
            "NormTermName": "Cylinder",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Anti-Rotation Actuator"
        },
        "04000": {
            "GenartName": "Guide Sleeve, wheel hub",
            "NormTermName": "Sleeve",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Hub Guide Sleeve"
        },
        "04001": {
            "GenartName": "Universal Joint, differential pinion gear",
            "NormTermName": "Universal Joint",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential Pinion Gear"
        },
        "04002": {
            "GenartName": "Thrust Washer, differential pinion",
            "NormTermName": "Pressure Disc",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential Pinion Gear"
        },
        "04003": {
            "GenartName": "Seal Ring, power steering pump blanking plug",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Steering",
            "UsageName": "Sealing Plug Servo Pump"
        },
        "04004": {
            "GenartName": "Spacer Ring, planetary gearbox main shaft",
            "NormTermName": "Spacer Ring",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Outer Planetary Gear Main Shaft"
        },
        "04005": {
            "GenartName": "Spacer Ring, planetary gearbox oil distributor shaft",
            "NormTermName": "Spacer Ring",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Outer Planetary Gear Oil Distributor Shaft"
        },
        "04006": {
            "GenartName": "Synchronizer Ring, outer planetary gear main shaft",
            "NormTermName": "Synchronizer Ring",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Outer Planetary Gear Main Shaft"
        },
        "04007": {
            "GenartName": "Bolts, outer planetary gear synchronizer body",
            "NormTermName": "Bolt",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Outer Planetary Gear Synhronizing Body"
        },
        "04008": {
            "GenartName": "Bushing, outer planetary gear gear wheel",
            "NormTermName": "Bush",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Outer PLanetary Gear Gear"
        },
        "04009": {
            "GenartName": "Thrust Washer, planetary gearbox output shaft",
            "NormTermName": "Pressure Disc",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Outer Planetary Gear Drive Shaft"
        },
        "04010": {
            "GenartName": "Synchronizer Ring, outer planetary gear output shaft",
            "NormTermName": "Synchronizer Ring",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Outer Planetary Gear Drive Shaft"
        },
        "04011": {
            "GenartName": "Lock Washer, differential drive flange fastening",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential Drive Flange Mounting"
        },
        "04012": {
            "GenartName": "Entry light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Entry bar"
        },
        "04013": {
            "GenartName": "Battery Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Battery Holder"
        },
        "04014": {
            "GenartName": "Seal Ring, expansion tank cap",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Steering",
            "UsageName": "Expansion Tank Sealing Cap"
        },
        "04015": {
            "GenartName": "Fastener, power steering filter insert",
            "NormTermName": "Holder",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering Filter Insert"
        },
        "04016": {
            "GenartName": "Lift Arm Bearing, lift axle",
            "NormTermName": "Pulley",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Lift Axle"
        },
        "04017": {
            "GenartName": "Repair Kit, relay valve",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Relay Valve"
        },
        "04018": {
            "GenartName": "Repair Kit, air spring valve",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Spring Valve"
        },
        "04019": {
            "GenartName": "Seal Ring, driver cab stabiliser",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Stabiliser"
        },
        "04020": {
            "GenartName": "Bolt, driver cab stabiliser",
            "NormTermName": "Screw",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Stabiliser"
        },
        "04021": {
            "GenartName": "Repair Kit, shift cylinder",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shift Cylinder"
        },
        "04022": {
            "GenartName": "Repair Kit, differential",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "04023": {
            "GenartName": "Pinion Set, differential",
            "NormTermName": "Pinion Gear Set",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "04024": {
            "GenartName": "Repai Kit, shift cylinder shift valve",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shift Cylinder Shift Valve"
        },
        "04025": {
            "GenartName": "Repair Kit, compressed air control unit",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Pressure Regulator"
        },
        "04026": {
            "GenartName": "Repair Kit, pressure control valve",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Pressure Control Valve"
        },
        "04027": {
            "GenartName": "Repair Kit, service brake brake valve",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Service Brake Brake Valve"
        },
        "04028": {
            "GenartName": "Repair Kit, parking brake brake valve",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Parking Brake Brake Valve"
        },
        "04029": {
            "GenartName": "Repair Kit, Load-/empty valve",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Load Sensitive Valve"
        },
        "04030": {
            "GenartName": "Repait Kit, spring bearing frame",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Mounting"
        },
        "04031": {
            "GenartName": "Repair Kit, lift arm tilt cylinder",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Lift Axle Lift Cylinder"
        },
        "04032": {
            "GenartName": "Cooler, exhaust gas recirculation",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": null
        },
        "04034": {
            "GenartName": "Spring, clutch pedal",
            "NormTermName": "Spring",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Pedal"
        },
        "04035": {
            "GenartName": "Cap, wheel nut",
            "NormTermName": "Cap",
            "AssGrpName": "Wheels",
            "UsageName": "Wheel Nut"
        },
        "04036": {
            "GenartName": "Tension Spring, gear lever",
            "NormTermName": "Spring",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gear Lever"
        },
        "04037": {
            "GenartName": "Spring, oil pressure valve oil pump",
            "NormTermName": "Spring",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump Oil Pressure Valve"
        },
        "04038": {
            "GenartName": "Repair Kit, Trilex",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheels",
            "UsageName": "Trilex"
        },
        "04039": {
            "GenartName": "Expanding Rivet",
            "NormTermName": "Rivet",
            "AssGrpName": "Standard Parts",
            "UsageName": "Expanding Rivet"
        },
        "04040": {
            "GenartName": "Hose Line, air dryer",
            "NormTermName": "Hose Line",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Dryer"
        },
        "04042": {
            "GenartName": "Holder, battery compartment cover",
            "NormTermName": "Holder",
            "AssGrpName": "Starter System",
            "UsageName": "Battery Compartment Cover"
        },
        "04043": {
            "GenartName": "Hose Line, driver cab tilt unit",
            "NormTermName": "Hose Line",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Tilt Unit"
        },
        "04044": {
            "GenartName": "Oil Pipe, oil distributor shaft planetary gearbox",
            "NormTermName": "Pipe",
            "AssGrpName": "Axle Drive",
            "UsageName": "Outer Planetary Gear Oil Distributor Shaft"
        },
        "04045": {
            "GenartName": "Pressure Hose, air compressor",
            "NormTermName": "Hose Line",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "04046": {
            "GenartName": "Hose Line, engine brake",
            "NormTermName": "Hose Line",
            "AssGrpName": "Braking System",
            "UsageName": "Engine Brake"
        },
        "04047": {
            "GenartName": "Draw Bar Fork, level compensation valve",
            "NormTermName": "Fork Head",
            "AssGrpName": "Driver Cab",
            "UsageName": "Level Compensation Valve"
        },
        "04048": {
            "GenartName": "Bolts, level compensation valve draw bar fork",
            "NormTermName": "Bolt",
            "AssGrpName": "Driver Cab",
            "UsageName": "Draw Bar Fork Level Compensation Valve"
        },
        "04049": {
            "GenartName": "Repair Kit, injector holder",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Holder"
        },
        "04050": {
            "GenartName": "Sleeve, nozzle holder",
            "NormTermName": "Sleeve",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Holder"
        },
        "04051": {
            "GenartName": "Seal Ring, nozzle holder",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Holder"
        },
        "04052": {
            "GenartName": "Split Anchor, cable tie",
            "NormTermName": "Rotor",
            "AssGrpName": "Standard Parts",
            "UsageName": "Cable Fixing"
        },
        "04053": {
            "GenartName": "Suction Plate, cable tie",
            "NormTermName": "Suction Plate",
            "AssGrpName": "Standard Parts",
            "UsageName": "Cable Fixing"
        },
        "04054": {
            "GenartName": "Flexible Coupling Sleeve",
            "NormTermName": "Coupling Sleeve",
            "AssGrpName": "Belt Drive",
            "UsageName": null
        },
        "04055": {
            "GenartName": "Filter Set",
            "NormTermName": "Filter Set",
            "AssGrpName": "Service/Maintenance",
            "UsageName": null
        },
        "04056": {
            "GenartName": "Clamping Screw, ball joint",
            "NormTermName": "Screw",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Ball Joint"
        },
        "04057": {
            "GenartName": "Clamping Screw Set, ball joint",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Ball Joint"
        },
        "04058": {
            "GenartName": "Extension Cable, lambda sensor",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Lambda sensor Extension Cable"
        },
        "04059": {
            "GenartName": "Flexible Coupling Sleeve Kit",
            "NormTermName": "Coupling Sleeve Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": null
        },
        "04060": {
            "GenartName": "Mounting Tools, timing belt",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Timing Belt"
        },
        "04061": {
            "GenartName": "Mounting Tools, V-ribbed belt",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "V-Ribbed Belt"
        },
        "04062": {
            "GenartName": "Freewheel, air conditioning compressor",
            "NormTermName": "Freewheel",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Compressor"
        },
        "04063": {
            "GenartName": "Cover, battery box",
            "NormTermName": "Lid",
            "AssGrpName": "Starter System",
            "UsageName": "Battery Box"
        },
        "04064": {
            "GenartName": "Repair Kit, air conditioning",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Air Conditioning",
            "UsageName": null
        },
        "04065": {
            "GenartName": "Bearing Bush, oil pump",
            "NormTermName": "Bush",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "04066": {
            "GenartName": "Repair Kit, tilt cylinder",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Driver Cab",
            "UsageName": "Tilt Cylinder"
        },
        "04067": {
            "GenartName": "Repair Kit, tilt pump",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Driver Cab",
            "UsageName": "Tilt Pump"
        },
        "04068": {
            "GenartName": "Electric Motor, hydraulic pump unit",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Hydraulic System",
            "UsageName": "Hydraulic Pump Unit"
        },
        "04069": {
            "GenartName": "Piston, air compressor",
            "NormTermName": "Piston",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "04070": {
            "GenartName": "Roller Piston, air spring bellow",
            "NormTermName": "Piston",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Air Spring Bellow"
        },
        "04071": {
            "GenartName": "Piston Ring Set, air compressor",
            "NormTermName": "Ring Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Compressor Piston"
        },
        "04072": {
            "GenartName": "Holder, wing",
            "NormTermName": "Holder",
            "AssGrpName": "Driver Cab",
            "UsageName": "Wing"
        },
        "04073": {
            "GenartName": "Sun Visor",
            "NormTermName": "Cover",
            "AssGrpName": "Driver Cab",
            "UsageName": "Sun Visor"
        },
        "04074": {
            "GenartName": "Mounting Kit, sun visor",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Driver Cab",
            "UsageName": "Sun Visor"
        },
        "04075": {
            "GenartName": "Headlight Protection Kit",
            "NormTermName": "Headlight Protection Kit",
            "AssGrpName": "Lights",
            "UsageName": null
        },
        "04076": {
            "GenartName": "Seal Ring, lift arm lift axle",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Lift Arm Lift Axle"
        },
        "04077": {
            "GenartName": "Gear, air compressor",
            "NormTermName": "Gear",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "04078": {
            "GenartName": "Rain Flap, tailpipe",
            "NormTermName": "Lid",
            "AssGrpName": "Exhaust System",
            "UsageName": "Tailpipe"
        },
        "04079": {
            "GenartName": "Bevel Gear, differential",
            "NormTermName": "Bevel Gear",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "04080": {
            "GenartName": "Drive Shaft, transfer case",
            "NormTermName": "Shaft",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transfer Case"
        },
        "04081": {
            "GenartName": "Crown Wheel/Pinion Kit",
            "NormTermName": "Crown Wheel/Pinion Kit",
            "AssGrpName": "Axle Drive",
            "UsageName": null
        },
        "04082": {
            "GenartName": "Ring Gear, outer planetary gear",
            "NormTermName": "Ring Gear",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Planetary Gearbox"
        },
        "04083": {
            "GenartName": "Cable Pull, steering column adjustment",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Steering",
            "UsageName": "Steering Column Adjustment"
        },
        "04084": {
            "GenartName": "Sleeve, compressed air tank strap",
            "NormTermName": "Sleeve",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Compressed Air Tank Strap"
        },
        "04085": {
            "GenartName": "Dipstick, hydraulic oil",
            "NormTermName": "Dipstick",
            "AssGrpName": "Steering",
            "UsageName": "Hydraulics"
        },
        "04086": {
            "GenartName": "Sleeve, fuel tank strap",
            "NormTermName": "Sleeve",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank Strap"
        },
        "04087": {
            "GenartName": "Nut, fuel tank strap",
            "NormTermName": "Nut",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank Strap"
        },
        "04088": {
            "GenartName": "Screw, fuel tank strap",
            "NormTermName": "Screw",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank Strap"
        },
        "04089": {
            "GenartName": "Door Extension",
            "NormTermName": "Door Extension",
            "AssGrpName": "Driver Cab",
            "UsageName": null
        },
        "04090": {
            "GenartName": "Wind Deflector",
            "NormTermName": "Wind Deflector",
            "AssGrpName": "Driver Cab",
            "UsageName": null
        },
        "04091": {
            "GenartName": "Housing, differential",
            "NormTermName": "Housing",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "04092": {
            "GenartName": "Ring Gear, manual transmission",
            "NormTermName": "Ring Gear",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "04093": {
            "GenartName": "Countershaft, manual transmission",
            "NormTermName": "Shaft",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Countershaft"
        },
        "04095": {
            "GenartName": "Bearing, selector-/shift rod",
            "NormTermName": "Bearing",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Selector-/Shift Rod"
        },
        "04096": {
            "GenartName": "Drive Shaft, oil pump",
            "NormTermName": "Shaft",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Oil Pump"
        },
        "04097": {
            "GenartName": "Planetary Gear Set",
            "NormTermName": "Planetary Gear Set",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "04098": {
            "GenartName": "Transmission Input Shaft, manual transmission",
            "NormTermName": "Shaft",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Input Shaft"
        },
        "04099": {
            "GenartName": "Bearing, manual transmission",
            "NormTermName": "Bearing",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "04100": {
            "GenartName": "Gear, transmission input shaft",
            "NormTermName": "Gear",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Input Shaft"
        },
        "04101": {
            "GenartName": "Gearshift Sleeve, manual transmission",
            "NormTermName": "Gearshift Sleeve",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "04102": {
            "GenartName": "Synchronizer Body, manual transmission",
            "NormTermName": "Synchronizer Body",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "04103": {
            "GenartName": "Spring, synchronizer gear drive shaft",
            "NormTermName": "Spring",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Synchronizer Gear Drive Shaft"
        },
        "04104": {
            "GenartName": "Synchronizer Cone, speed change gear",
            "NormTermName": "Synchronizer Cone",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "04105": {
            "GenartName": "Gasket, intake air preheating heater flange",
            "NormTermName": "Seal",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Heater Flange Intake Air Preheating"
        },
        "04106": {
            "GenartName": "Camshaft Adjuster",
            "NormTermName": "Camshaft Adjuster",
            "AssGrpName": "Engine Timing",
            "UsageName": null
        },
        "04107": {
            "GenartName": "Actuator",
            "NormTermName": "Actuator",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "04109": {
            "GenartName": "Adapter, actuator",
            "NormTermName": "Adaptor",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Actuator"
        },
        "04110": {
            "GenartName": "Start Button",
            "NormTermName": "Start Button",
            "AssGrpName": "Starter System",
            "UsageName": null
        },
        "04111": {
            "GenartName": "Start/Stop button",
            "NormTermName": "Start/Stop button",
            "AssGrpName": "Starter System",
            "UsageName": null
        },
        "04112": {
            "GenartName": "RPM Sensor, manual transmission",
            "NormTermName": "Sensor",
            "AssGrpName": "Manual Transmission",
            "UsageName": "RPM"
        },
        "04113": {
            "GenartName": "RPM Sensor, automatic transmission",
            "NormTermName": "Sensor",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "RPM"
        },
        "04114": {
            "GenartName": "Snow Chain",
            "NormTermName": "Snow Chain",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04116": {
            "GenartName": "Housing, air flow sensor",
            "NormTermName": "Housing",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Air Flow Sensor"
        },
        "04117": {
            "GenartName": "Release Set, clutch control",
            "NormTermName": "Release Set",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Control"
        },
        "04118": {
            "GenartName": "Air Spring Strut",
            "NormTermName": "Air Spring Strut",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "04119": {
            "GenartName": "Air Spring, suspension",
            "NormTermName": "Air Spring",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Suspension"
        },
        "04120": {
            "GenartName": "Manifold Catalytic Converter",
            "NormTermName": "Catalytic Converter",
            "AssGrpName": "Exhaust System",
            "UsageName": "Manifold Catalytic Converter"
        },
        "04121": {
            "GenartName": "Bicycle Holder, rear rack",
            "NormTermName": "Bicycle Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Rear Rack"
        },
        "04122": {
            "GenartName": "Bicycle Holder, roof rack",
            "NormTermName": "Bicycle Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "04123": {
            "GenartName": "Licence Plate Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Licence Plate"
        },
        "04124": {
            "GenartName": "Ski Holder, roof rack",
            "NormTermName": "Ski Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "04125": {
            "GenartName": "Ski Holder, rear rack",
            "NormTermName": "Ski Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Rear Rack"
        },
        "04126": {
            "GenartName": "Trim/Protection Strip, bumper",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Bumper"
        },
        "04127": {
            "GenartName": "Trim/Protection Strip, door",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Door"
        },
        "04128": {
            "GenartName": "Barrier Mesh, boot-/cargo area",
            "NormTermName": "Grille",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Boot-/Cargo Area"
        },
        "04130": {
            "GenartName": "Interior Door Panel",
            "NormTermName": "Panelling",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": "Interior Door Panel"
        },
        "04131": {
            "GenartName": "Gear Knob",
            "NormTermName": "Gear Knob",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04132": {
            "GenartName": "Ball",
            "NormTermName": "Ball",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "04133": {
            "GenartName": "Bearing Kit, differential",
            "NormTermName": "Bearing Kit",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "04134": {
            "GenartName": "Busbar",
            "NormTermName": "Busbar",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "04135": {
            "GenartName": "Ignitor, gas discharge lamp",
            "NormTermName": "Ignitor",
            "AssGrpName": "Lights",
            "UsageName": null
        },
        "04136": {
            "GenartName": "Seal Ring, air filter housing intake hose",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Air Supply",
            "UsageName": "Air Filter Housing Intake Hose"
        },
        "04137": {
            "GenartName": "Seal, radiator fan holder",
            "NormTermName": "Seal",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator Fan Holder"
        },
        "04138": {
            "GenartName": "Spacer, transmission input shaft",
            "NormTermName": "Spacer Ring",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Input Shaft"
        },
        "04139": {
            "GenartName": "Retainer Ring, synchronizer",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Circlip"
        },
        "04140": {
            "GenartName": "Cap, gear knob",
            "NormTermName": "Cap",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gear Knob"
        },
        "04141": {
            "GenartName": "Adjusting Shaft, slack adjuster",
            "NormTermName": "Shaft",
            "AssGrpName": "Braking System",
            "UsageName": "Adjuster"
        },
        "04142": {
            "GenartName": "Nut, spring clamp",
            "NormTermName": "Nut",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Clamp"
        },
        "04143": {
            "GenartName": "Sensor, suction pipe reverse flap",
            "NormTermName": "Sensor",
            "AssGrpName": "Air Supply",
            "UsageName": "Suction Pipe Reverse Flap"
        },
        "04144": {
            "GenartName": "Pressure Sensor, brake booster",
            "NormTermName": "Sensor",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Booster"
        },
        "04145": {
            "GenartName": "Series resistor, electric motor (radiator fan)",
            "NormTermName": "Resistor",
            "AssGrpName": "Cooling System",
            "UsageName": "Electric motor (radiator fan)"
        },
        "04146": {
            "GenartName": "Repair Set, vacuum pump (braking system)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum Pump (braking system)"
        },
        "04147": {
            "GenartName": "Antifreeze-/Battery Acid Testing Unit (Refractometer)",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Antifreeze/Battery Acid"
        },
        "04148": {
            "GenartName": "Joint, shift rod",
            "NormTermName": "Joint",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shift Rod"
        },
        "04149": {
            "GenartName": "Brake Hose Set",
            "NormTermName": "Hose Line Set",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "04150": {
            "GenartName": "Sensor, switch module",
            "NormTermName": "Sensor",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Switch Position"
        },
        "04151": {
            "GenartName": "Numberplate",
            "NormTermName": "Numberplate",
            "AssGrpName": null,
            "UsageName": null
        },
        "04153": {
            "GenartName": "Contour marking",
            "NormTermName": "Contour marking",
            "AssGrpName": "Lights, universal",
            "UsageName": null
        },
        "04154": {
            "GenartName": "Bulb Holder, side marker light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights",
            "UsageName": "Side Marker Light"
        },
        "04155": {
            "GenartName": "Bulb Holder, side marker light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights, universal",
            "UsageName": "Side Marker Light"
        },
        "04156": {
            "GenartName": "Seal, side marker light",
            "NormTermName": "Seal",
            "AssGrpName": "Lights, universal",
            "UsageName": "Side Marker Light"
        },
        "04157": {
            "GenartName": "Bulb Holder, clearance light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights, universal",
            "UsageName": "Clearance Light"
        },
        "04158": {
            "GenartName": "Bulb Holder, clearance light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights",
            "UsageName": "Clearance Light"
        },
        "04160": {
            "GenartName": "Time Relay",
            "NormTermName": "Relay",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Timer"
        },
        "04161": {
            "GenartName": "Battery Carrying Strap",
            "NormTermName": "Strap",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Battery"
        },
        "04162": {
            "GenartName": "Soldering Tool Set, battery terminal",
            "NormTermName": "Soldering Tool Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Battery Terminal"
        },
        "04163": {
            "GenartName": "Memory Saver",
            "NormTermName": "Memory Saver",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Vehicle Power Supply"
        },
        "04164": {
            "GenartName": "Gas Warning Unit",
            "NormTermName": "Detector",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Gas Concentration"
        },
        "04166": {
            "GenartName": "Battery Cell Filler",
            "NormTermName": "Battery Cell Filler",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04167": {
            "GenartName": "Assortment, valve clearance adjusting discs",
            "NormTermName": "Assortment",
            "AssGrpName": "Engine Timing",
            "UsageName": "Valve Clearance"
        },
        "04168": {
            "GenartName": "Mesh Hose",
            "NormTermName": "Mesh Hose",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "04169": {
            "GenartName": "Cable stripper",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "strip"
        },
        "04170": {
            "GenartName": "Gas Soldering Torch",
            "NormTermName": "Gas Soldering Torch",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04171": {
            "GenartName": "OBD Cable, self-diagnosis unit",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "OBD Cable, self-diagnosis unit"
        },
        "04172": {
            "GenartName": "Water Pump + V-Ribbed Belt Set",
            "NormTermName": "Water Pump/V-Ribbed Belt Set",
            "AssGrpName": "Cooling System",
            "UsageName": null
        },
        "04173": {
            "GenartName": "Mounting Tool Set, belt drive",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04174": {
            "GenartName": "Holder, support frame/subframe",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Support Frame/ Engine Carrier"
        },
        "04175": {
            "GenartName": "Sender Unit, oil temperature / pressure",
            "NormTermName": "Sensor",
            "AssGrpName": "Instruments",
            "UsageName": "Oil Temperature / Pressure"
        },
        "04176": {
            "GenartName": "Seal Ring, engine oil level sensor",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Lubrication",
            "UsageName": "Engine Oil Level Sensor"
        },
        "04177": {
            "GenartName": "Bracket, EGR valve",
            "NormTermName": "Holder",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "EGR Valve"
        },
        "04178": {
            "GenartName": "Gasket, EGR valve bracket",
            "NormTermName": "Seal",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "EGR Valve Bracket"
        },
        "04179": {
            "GenartName": "Pipe, EGR valve",
            "NormTermName": "Pipe",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "EGR Valve"
        },
        "04180": {
            "GenartName": "Gasket, EGR valve pipe",
            "NormTermName": "Seal",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "EGR Valve Pipe"
        },
        "04181": {
            "GenartName": "Docking Station, self-diagnosis unit",
            "NormTermName": "Docking Station",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Self-diagnosis Unit"
        },
        "04182": {
            "GenartName": "CAN Bus Cable, self-diagnosis unit",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "CAN Bus, self-diagnosis unit"
        },
        "04183": {
            "GenartName": "USB Cable, self-diagnosis unit",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "USB Cable, self-diagnosis unit"
        },
        "04184": {
            "GenartName": "Software Update, system diagnosis",
            "NormTermName": "Software",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Software Update, system diagnosis"
        },
        "04185": {
            "GenartName": "CD-ROM Manual, self-diagnosis unit",
            "NormTermName": "Software",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Self-diagnosis Unit"
        },
        "04186": {
            "GenartName": "Storage Bag, self-diagnosis unit",
            "NormTermName": "Bag",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Self-diagnosis Unit"
        },
        "04187": {
            "GenartName": "Spiral Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Spiral Hose"
        },
        "04188": {
            "GenartName": "Cable Cutter",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable Cutter"
        },
        "04189": {
            "GenartName": "Tow Bar Carrier, universal",
            "NormTermName": "Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Towbar Carrier"
        },
        "04190": {
            "GenartName": "Cable Tie Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable Tie"
        },
        "04191": {
            "GenartName": "Cable Puller",
            "NormTermName": "Cable Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04192": {
            "GenartName": "Universal Scissors",
            "NormTermName": "Universal Scissors",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04193": {
            "GenartName": "Sleeve Stretching Tool",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Sleeve Stretching Tool"
        },
        "04194": {
            "GenartName": "Cleansing Contact",
            "NormTermName": "Cleansing Contact",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04195": {
            "GenartName": "Cleansing Contact Set",
            "NormTermName": "Cleansing Contact Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04196": {
            "GenartName": "Battery Terminal/Clamp Milling Cutter",
            "NormTermName": "Battery Terminal/Clamp Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04197": {
            "GenartName": "Membrane Pressure Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Membrane Pressure Switch"
        },
        "04198": {
            "GenartName": "EGR Module",
            "NormTermName": "EGR Module",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": null
        },
        "04199": {
            "GenartName": "Holder, hubcap",
            "NormTermName": "Holder",
            "AssGrpName": "Wheels",
            "UsageName": "Hubcap"
        },
        "04200": {
            "GenartName": "Gear, injection pump",
            "NormTermName": "Gear",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump"
        },
        "04201": {
            "GenartName": "Hub, water pump",
            "NormTermName": "Hub",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "04202": {
            "GenartName": "Vibration Damper, gearshift linkage",
            "NormTermName": "Damper",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gearshift Linkage"
        },
        "04203": {
            "GenartName": "Cable Pull, stowage box flap opener",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Driver Cab",
            "UsageName": "Stowage box flap opener"
        },
        "04204": {
            "GenartName": "Belt Pulley, power steering pump",
            "NormTermName": "Belt Pulley",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering Pump"
        },
        "04205": {
            "GenartName": "Bushing, lift axle arm",
            "NormTermName": "Bush",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Lift Arm Lift Axle"
        },
        "04206": {
            "GenartName": "Mounting, power steering pump",
            "NormTermName": "Mounting",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering Pump"
        },
        "04207": {
            "GenartName": "Gasket, oil cooling thermostat",
            "NormTermName": "Seal",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Cooling Thermostat"
        },
        "04208": {
            "GenartName": "High Performance Brake Hose Set",
            "NormTermName": "Hose Set (high performance brakes)",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Hydraulics, high performance brakes"
        },
        "04209": {
            "GenartName": "Front Mirror, driver cab",
            "NormTermName": "Mirror",
            "AssGrpName": "Driver Cab",
            "UsageName": "Front Mirror"
        },
        "04210": {
            "GenartName": "Mirror Glass, front mirror",
            "NormTermName": "Mirror Glass",
            "AssGrpName": "Driver Cab",
            "UsageName": "Front Mirror"
        },
        "04211": {
            "GenartName": "Seal, bonnet",
            "NormTermName": "Seal",
            "AssGrpName": "Body",
            "UsageName": "Bonnet"
        },
        "04212": {
            "GenartName": "Wheel Balancing Machine",
            "NormTermName": "Wheel Balancing Machine",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Wheel"
        },
        "04213": {
            "GenartName": "Charger Cable, trailer coupling",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Charger Cable"
        },
        "04214": {
            "GenartName": "Transport Box, towbar carrier",
            "NormTermName": "Transport Box",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Towbar Carrier"
        },
        "04215": {
            "GenartName": "Luggage Rack, towbar carrier",
            "NormTermName": "Luggage Box",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Towbar Carrier"
        },
        "04216": {
            "GenartName": "Bike Rack, towbar carrier",
            "NormTermName": "Bicycle Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Towbar Carrier"
        },
        "04217": {
            "GenartName": "Insert Guide, plug",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Plug"
        },
        "04218": {
            "GenartName": "Valve, compressor bypass flap",
            "NormTermName": "Valve",
            "AssGrpName": "Air Supply",
            "UsageName": "Compressor Bypass Flap"
        },
        "04219": {
            "GenartName": "Crimping Pliers Handle",
            "NormTermName": "Handle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Crimping Pliers"
        },
        "04220": {
            "GenartName": "Dashboard",
            "NormTermName": "Dashboard",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "04221": {
            "GenartName": "Emblem",
            "NormTermName": "Emblem",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04222": {
            "GenartName": "Sticker",
            "NormTermName": "Sticker",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04223": {
            "GenartName": "Seat Squab",
            "NormTermName": "Seat Squab",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "04224": {
            "GenartName": "Seat Squab Set",
            "NormTermName": "Seat Squab Set",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "04225": {
            "GenartName": "Roof Inner Lining",
            "NormTermName": "Roof Inner Lining",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "04226": {
            "GenartName": "Centre Console",
            "NormTermName": "Centre Console",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "04227": {
            "GenartName": "Flower Vase, dashboard",
            "NormTermName": "Flower Vase",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Dashboard"
        },
        "04228": {
            "GenartName": "Hand Feed Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Hand Feed Pump"
        },
        "04229": {
            "GenartName": "Central Magnet, camshaft adjustment",
            "NormTermName": "Central Magnet",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Adjustment"
        },
        "04230": {
            "GenartName": "Central Valve, camshaft adjustment",
            "NormTermName": "Valve",
            "AssGrpName": "Engine Timing",
            "UsageName": "Central Valve - camshaft adjustment"
        },
        "04231": {
            "GenartName": "Towbar Carrier",
            "NormTermName": "Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Towbar Carrier"
        },
        "04232": {
            "GenartName": "Bike Rack, towbar carrier",
            "NormTermName": "Bicycle Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Towbar Carrier"
        },
        "04234": {
            "GenartName": "Cable Adapter, electro set",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Electric Kit"
        },
        "04235": {
            "GenartName": "Socket Adapter",
            "NormTermName": "Adaptor",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Socket"
        },
        "04236": {
            "GenartName": "Holder, control unit electric set",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Control Unit"
        },
        "04237": {
            "GenartName": "Bellow, ball joint",
            "NormTermName": "Bellow",
            "AssGrpName": "Wheel Suspension, Universal",
            "UsageName": "Ball Joint"
        },
        "04238": {
            "GenartName": "Bellow, tie rod end",
            "NormTermName": "Bellow",
            "AssGrpName": "Steering, universal",
            "UsageName": "Tie Rod End"
        },
        "04239": {
            "GenartName": "Hinge, hinged window",
            "NormTermName": "Hinge",
            "AssGrpName": "Body",
            "UsageName": "Hinged Window"
        },
        "04240": {
            "GenartName": "Key Ring",
            "NormTermName": "Key Ring",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04241": {
            "GenartName": "Shaper Tool, exhaust pipe",
            "NormTermName": "Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Exhaust Pipe Shaper Tool"
        },
        "04242": {
            "GenartName": "Scale, ignition timing",
            "NormTermName": "Gauge",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition Timing"
        },
        "04243": {
            "GenartName": "Mounting, accelerator pedal",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Accelerator Pedal"
        },
        "04244": {
            "GenartName": "Guide, locking knob",
            "NormTermName": "Guide",
            "AssGrpName": "Lock System",
            "UsageName": "Locking Knob"
        },
        "04245": {
            "GenartName": "Tailgate Emblem",
            "NormTermName": "Emblem",
            "AssGrpName": "Body",
            "UsageName": "Tailgate"
        },
        "04246": {
            "GenartName": "Emblem, hubcap",
            "NormTermName": "Emblem",
            "AssGrpName": "Wheels",
            "UsageName": "Hubcap"
        },
        "04247": {
            "GenartName": "Centering Bolt, crankshaft pulley",
            "NormTermName": "Screw",
            "AssGrpName": "Belt Drive",
            "UsageName": "Crankshaft Pulley"
        },
        "04248": {
            "GenartName": "Washer, crankshaft pulley",
            "NormTermName": "Washer",
            "AssGrpName": "Belt Drive",
            "UsageName": "Crankshaft Pulley"
        },
        "04249": {
            "GenartName": "Bolt, crankshaft gear",
            "NormTermName": "Screw",
            "AssGrpName": "Engine Timing",
            "UsageName": "Crankshaft Gear"
        },
        "04252": {
            "GenartName": "Bolt Set, crankshaft pulley",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": "Crankshaft Pulley"
        },
        "04253": {
            "GenartName": "Injector, urea injection",
            "NormTermName": "Valve",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea Injection"
        },
        "04254": {
            "GenartName": "Delivery Module, urea injection",
            "NormTermName": "Pump Module",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea Injection"
        },
        "04255": {
            "GenartName": "Dosing Module, urea injection",
            "NormTermName": "Dosage Module",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea Injection"
        },
        "04256": {
            "GenartName": "Engine Cover",
            "NormTermName": "Cover",
            "AssGrpName": "Body",
            "UsageName": "Engine"
        },
        "04257": {
            "GenartName": "Protecting Cap, spring support axle",
            "NormTermName": "Cap",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Support Axle"
        },
        "04258": {
            "GenartName": "Spacer Ring, steering bellow",
            "NormTermName": "Spacer Ring",
            "AssGrpName": "Steering",
            "UsageName": "Steering Bellow"
        },
        "04259": {
            "GenartName": "Locking Cover, camshaft",
            "NormTermName": "Closure",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft"
        },
        "04260": {
            "GenartName": "Thrust Piece, kingpin",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Kingppin"
        },
        "04261": {
            "GenartName": "Buffer, engine cover",
            "NormTermName": "Buffer",
            "AssGrpName": "Body",
            "UsageName": "Engine Cover"
        },
        "04262": {
            "GenartName": "Lock, kingpin",
            "NormTermName": "Closure",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Tractor Kingpin"
        },
        "04263": {
            "GenartName": "Charging Cable, cigarette lighter",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Cigarette Lighter Charging Cable"
        },
        "04264": {
            "GenartName": "Seal, headlight cap",
            "NormTermName": "Seal",
            "AssGrpName": "Lights",
            "UsageName": "Headlight Cap"
        },
        "04265": {
            "GenartName": "Tyre",
            "NormTermName": "Tyre",
            "AssGrpName": "Wheels, universal",
            "UsageName": null
        },
        "04266": {
            "GenartName": "Fan Ring",
            "NormTermName": "Fan Ring",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator"
        },
        "04268": {
            "GenartName": "Storage Case, self-diagnosis unit",
            "NormTermName": "Case",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Self-diagnosis Unit"
        },
        "04269": {
            "GenartName": "Filter, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Filter",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "04270": {
            "GenartName": "Switch, tank cap unlock",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Tank Cap Unlock"
        },
        "04271": {
            "GenartName": "Cover, oil sieve",
            "NormTermName": "Lid",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Strainer"
        },
        "04272": {
            "GenartName": "Sensor, exhaust pressure",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Exhaust Pressure"
        },
        "04273": {
            "GenartName": "Switch, parking light",
            "NormTermName": "Switch",
            "AssGrpName": "Lights",
            "UsageName": "Parking Light"
        },
        "04274": {
            "GenartName": "Nut, Supporting / Ball Joint",
            "NormTermName": "Nut",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Ball Joint"
        },
        "04276": {
            "GenartName": "Valve key",
            "NormTermName": "Key",
            "AssGrpName": "Tool, universal",
            "UsageName": "Valve"
        },
        "04277": {
            "GenartName": "Fin Comb",
            "NormTermName": "Fin Comb",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04278": {
            "GenartName": "Leak Search Set, Air Conditioning",
            "NormTermName": "Leak Search Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Air Conditioning"
        },
        "04279": {
            "GenartName": "Hose Cutter",
            "NormTermName": "Hose Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04280": {
            "GenartName": "Seal Ring Set, air conditioning",
            "NormTermName": "Seal Ring Set",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Air Conditioning"
        },
        "04281": {
            "GenartName": "Blade, Pipe Cutter",
            "NormTermName": "Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Cutter"
        },
        "04282": {
            "GenartName": "Weld-On Bung, lambda sensor",
            "NormTermName": "Weld-On Bung",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Pipe"
        },
        "04283": {
            "GenartName": "Bolt, rocker arm shaft",
            "NormTermName": "Screw",
            "AssGrpName": "Engine Timing",
            "UsageName": "Rocker Arm Shaft"
        },
        "04284": {
            "GenartName": "Repair Set, planetary gear starter",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Starter System",
            "UsageName": "Planetary Gear, Starter"
        },
        "04285": {
            "GenartName": "Repair Kit, internal gear (planetary drive)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Starter System",
            "UsageName": "Internal gear starter"
        },
        "04286": {
            "GenartName": "Anti-Kink, Spiral Hose",
            "NormTermName": "Anti-kink",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Spiral Hose"
        },
        "04287": {
            "GenartName": "Pull ring, drain valve",
            "NormTermName": "Pull ring",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Drain valve"
        },
        "04288": {
            "GenartName": "Reducer, air pipe",
            "NormTermName": "Reducer",
            "AssGrpName": "Standard Parts",
            "UsageName": "Hoses, compressed air"
        },
        "04292": {
            "GenartName": "Lever, parking brake caliper",
            "NormTermName": "Lever",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake Caliper"
        },
        "04293": {
            "GenartName": "Diesel Compression Ignition Engine (Start Help)",
            "NormTermName": "Diesel Compression Ignition Engine",
            "AssGrpName": "Chemical Products",
            "UsageName": "Start Aid"
        },
        "04294": {
            "GenartName": "Socket Wrench",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04295": {
            "GenartName": "Gripping Pliers, lock",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Fuse"
        },
        "04296": {
            "GenartName": "EBS Connection Cable",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Electronic Brake System"
        },
        "04298": {
            "GenartName": "Seal, idle speed control valve - air supply",
            "NormTermName": "Seal",
            "AssGrpName": "Air Supply",
            "UsageName": "Idle Speed Control Valve"
        },
        "04299": {
            "GenartName": "Hose, battery air vent",
            "NormTermName": "Hose Line",
            "AssGrpName": "Starter System",
            "UsageName": "Starter Battery"
        },
        "04300": {
            "GenartName": "Chain Lock, timing chain",
            "NormTermName": "Lock",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain"
        },
        "04301": {
            "GenartName": "Manual Transmission",
            "NormTermName": "Transmission",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "04303": {
            "GenartName": "ABS Control Light",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "ABS Control Light"
        },
        "04304": {
            "GenartName": "Bulb, tachograph lighting",
            "NormTermName": "Bulb",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Tachograph"
        },
        "04305": {
            "GenartName": "Bulb, airbag control lamp",
            "NormTermName": "Bulb",
            "AssGrpName": "Instruments",
            "UsageName": "Airbag Control Light"
        },
        "04306": {
            "GenartName": "Bulb, ignition key light",
            "NormTermName": "Bulb",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Ignition Key Lighting"
        },
        "04308": {
            "GenartName": "Jump Start Point",
            "NormTermName": "Jump Start Point",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "04309": {
            "GenartName": "Climate System Cleaning Set",
            "NormTermName": "Climate System Cleaning Set",
            "AssGrpName": "Chemical Products",
            "UsageName": "Air Conditioning"
        },
        "04310": {
            "GenartName": "Sealing Mass, climate system",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Air Conditioning"
        },
        "04311": {
            "GenartName": "Valve Core, service valve",
            "NormTermName": "Valve Core",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Service Valve"
        },
        "04312": {
            "GenartName": "Lens, ABS control lamp",
            "NormTermName": "Lens",
            "AssGrpName": "Instruments",
            "UsageName": "ABS Control Light"
        },
        "04313": {
            "GenartName": "Mounting Set, frontal damage",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Body",
            "UsageName": "Frontal Damage"
        },
        "04314": {
            "GenartName": "Recirculating Air Valve, charger",
            "NormTermName": "Valve",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger Pressure Control (diverter)"
        },
        "04315": {
            "GenartName": "Seal, injector shaft",
            "NormTermName": "Seal",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Injector Shaft"
        },
        "04316": {
            "GenartName": "Seal Ring, injector shaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Injector Shaft"
        },
        "04317": {
            "GenartName": "Seal Ring, spark plug shaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Spark Plug Shaft"
        },
        "04318": {
            "GenartName": "Inverter",
            "NormTermName": "Inverter",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "04319": {
            "GenartName": "NOx Sensor, urea injection",
            "NormTermName": "Sensor",
            "AssGrpName": "Exhaust System",
            "UsageName": "NOx Value (urea injection)"
        },
        "04320": {
            "GenartName": "Tyre Mounting Machine",
            "NormTermName": "Tyre Mounting Machine",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04321": {
            "GenartName": "Repair Kit, cable set",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Lights",
            "UsageName": "Cable Set"
        },
        "04322": {
            "GenartName": "Bearing Seat, propshaft centre bearing",
            "NormTermName": "Bearing Seat",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft Centre Bearing"
        },
        "04323": {
            "GenartName": "Holder, propshaft centre bearing",
            "NormTermName": "Bracket",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft Centre Bearing"
        },
        "04324": {
            "GenartName": "Gasket, centrifugal cleaner flange",
            "NormTermName": "Seal",
            "AssGrpName": "Lubrication",
            "UsageName": "Centrifugal Cleaner"
        },
        "04325": {
            "GenartName": "Membrane, crankcase ventilation",
            "NormTermName": "Diaphragm",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase Ventilation"
        },
        "04326": {
            "GenartName": "Shaft, centrifugal cleaner",
            "NormTermName": "Shaft",
            "AssGrpName": "Lubrication",
            "UsageName": "Centrifugal Cleaner"
        },
        "04327": {
            "GenartName": "Adjust Screw, headlight",
            "NormTermName": "Screw",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "04328": {
            "GenartName": "Screw, camshaft gear",
            "NormTermName": "Screw",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Gear"
        },
        "04329": {
            "GenartName": "Exhaust Pipe, universal",
            "NormTermName": "Tube",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": null
        },
        "04330": {
            "GenartName": "Suction Pump, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Pump",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "04331": {
            "GenartName": "Hone Brush, brake saddle cleaning",
            "NormTermName": "Hone Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04332": {
            "GenartName": "Clutch Brake",
            "NormTermName": "Clutch Brake",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "04333": {
            "GenartName": "Valve Piston, oil cooler",
            "NormTermName": "Valve Piston",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Cooler"
        },
        "04334": {
            "GenartName": "Holder, injector pump fuel line",
            "NormTermName": "Holder",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Pipe Fuel Line"
        },
        "04335": {
            "GenartName": "Cap, manual transmission housing",
            "NormTermName": "Cap",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gearshift Housing"
        },
        "04336": {
            "GenartName": "Bellow, propshaft",
            "NormTermName": "Bellow",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft"
        },
        "04337": {
            "GenartName": "Drive Plate, water pump",
            "NormTermName": "Drive Plate",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "04338": {
            "GenartName": "Rocker Arm Bridge",
            "NormTermName": "Rocker Arm Bridge",
            "AssGrpName": "Engine Timing",
            "UsageName": null
        },
        "04339": {
            "GenartName": "Retrofit Kit, catalytic conv./soot part. filter (combi-sys.)",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Catalytic Converter/Soot Particulate Filter"
        },
        "04340": {
            "GenartName": "Holding Bracket, pressure plate bearing block",
            "NormTermName": "Holding Bracket",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Bearing Block Pressure Plate"
        },
        "04341": {
            "GenartName": "Weld-in Pin, propshaft",
            "NormTermName": "Weld-in Pin",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft"
        },
        "04342": {
            "GenartName": "Valve, gear lever",
            "NormTermName": "Valve",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gear Lever"
        },
        "04343": {
            "GenartName": "Screw, oil pump housing lid",
            "NormTermName": "Screw",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump Housing Lid"
        },
        "04344": {
            "GenartName": "Holding Bracket, oil jet",
            "NormTermName": "Holding Bracket",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Jet"
        },
        "04345": {
            "GenartName": "Bearing Ring, propshaft centre bearing",
            "NormTermName": "Bearing Ring",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft Centre Bearing"
        },
        "04346": {
            "GenartName": "Control Piston, constantly open throttle (engine brake)",
            "NormTermName": "Control Piston",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Constantly Open Throttle (engine brake)"
        },
        "04347": {
            "GenartName": "Membrane, clutch brake piston",
            "NormTermName": "Diaphragm",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Brake Piston"
        },
        "04348": {
            "GenartName": "Mounting Tool, alternator freewheel clutch",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Alternator Freewheel Clutch"
        },
        "04349": {
            "GenartName": "Fan, engine cooling",
            "NormTermName": "Fan",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Engine Cooling"
        },
        "04350": {
            "GenartName": "Interior Blower",
            "NormTermName": "Fan",
            "AssGrpName": "Heating/Ventilation, universal",
            "UsageName": "Interior Air"
        },
        "04351": {
            "GenartName": "Access Rails, tow bar carrier",
            "NormTermName": "Access Rail",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Towbar Carrier"
        },
        "04352": {
            "GenartName": "Transport Platform, tow bar carrier",
            "NormTermName": "Transport Platform",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Towbar Carrier"
        },
        "04353": {
            "GenartName": "Storage Bag, tow bar carrier",
            "NormTermName": "Bag",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Towbar Carrier"
        },
        "04354": {
            "GenartName": "Wall Mounting, tow bar carrier",
            "NormTermName": "Bracket",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Towbar Carrier"
        },
        "04355": {
            "GenartName": "Extension Rails, tow bar carrier",
            "NormTermName": "Extension Rail",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Towbar Carrier"
        },
        "04356": {
            "GenartName": "Heat Shield, tow bar carrier",
            "NormTermName": "Heat Shield",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Towbar Carrier"
        },
        "04357": {
            "GenartName": "Assortment, exhaust system mounting",
            "NormTermName": "Assortment",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Exhaust System Mounting"
        },
        "04358": {
            "GenartName": "Repair Kit, exhaust pipe",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Exhaust Pipe"
        },
        "04359": {
            "GenartName": "Flange, exhaust pipe",
            "NormTermName": "Flange",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Exhaust Pipe"
        },
        "04360": {
            "GenartName": "Locking nut, CO2 withdrawal pipe",
            "NormTermName": "Nut",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "CO Sample Tube"
        },
        "04361": {
            "GenartName": "CO2 Exit Measurement",
            "NormTermName": "Tube",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "CO measuring"
        },
        "04362": {
            "GenartName": "Retrofit Kit, fuel system",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Gas System"
        },
        "04363": {
            "GenartName": "Water Sensor, fuel system",
            "NormTermName": "Sensor",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel System Water Sensor"
        },
        "04365": {
            "GenartName": "Fuel Preheater",
            "NormTermName": "Fuel Preheater",
            "AssGrpName": "Fuel Supply System",
            "UsageName": null
        },
        "04366": {
            "GenartName": "Fastening Element, exterior mirror cover",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Outer Mirror Cover"
        },
        "04367": {
            "GenartName": "Carrier Plate, exterior mirror adjuster",
            "NormTermName": "Baseplate",
            "AssGrpName": "Body",
            "UsageName": "Exterior Mirror Adjuster"
        },
        "04368": {
            "GenartName": "Plug, exterior mirror cover",
            "NormTermName": "Plug",
            "AssGrpName": "Body",
            "UsageName": "Outer Mirror Cover Holder"
        },
        "04369": {
            "GenartName": "Periphery Light, exterior mirror",
            "NormTermName": "Light",
            "AssGrpName": "Body",
            "UsageName": "Exterior Mirror"
        },
        "04370": {
            "GenartName": "Quarter Panel",
            "NormTermName": "Quarter Panel",
            "AssGrpName": "Body Parts, universal",
            "UsageName": null
        },
        "04371": {
            "GenartName": "Ball Stud, exterior mirror",
            "NormTermName": "Ball Stud",
            "AssGrpName": "Body",
            "UsageName": "Exterior Mirror"
        },
        "04372": {
            "GenartName": "Sliding Disc, exterior mirror",
            "NormTermName": "Sliding Disc",
            "AssGrpName": "Body",
            "UsageName": "Exterior Mirror"
        },
        "04373": {
            "GenartName": "Accessory Kit, brake disc",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Disc"
        },
        "04374": {
            "GenartName": "Clevis, spring brake cylinder",
            "NormTermName": "Fork Head",
            "AssGrpName": "Braking System",
            "UsageName": "Spring Loaded Cylinder"
        },
        "04375": {
            "GenartName": "Holder, beam hight control adjuster",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Actuator headlight levelling"
        },
        "04376": {
            "GenartName": "Drive Shaft, servo pump",
            "NormTermName": "Shaft",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering Pump"
        },
        "04377": {
            "GenartName": "Cover, seat adjustment switch",
            "NormTermName": "Cap",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Seat Adjustment Switch"
        },
        "04378": {
            "GenartName": "Cover, backrest adjustment switch",
            "NormTermName": "Cap",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Backrest Adjustment Switch"
        },
        "04379": {
            "GenartName": "Friction Plate, multi-plate clutch (automatic transmission)",
            "NormTermName": "Lining Disc",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Multi-Plate Clutch (automatic transmission)"
        },
        "04380": {
            "GenartName": "Cover, battery terminal clamp",
            "NormTermName": "Cover",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Battery Terminal Clamp"
        },
        "04381": {
            "GenartName": "Tank Unit, urea injection",
            "NormTermName": "Tank Unit",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "04382": {
            "GenartName": "Control Unit, urea injection",
            "NormTermName": "Control Unit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea Injection"
        },
        "04383": {
            "GenartName": "Control Display, parking distance control",
            "NormTermName": "Gauge",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Distance Control"
        },
        "04384": {
            "GenartName": "Control Display, parking distance control",
            "NormTermName": "Gauge",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Distance Control"
        },
        "04385": {
            "GenartName": "Accessory Kit",
            "NormTermName": "Accessory Kit",
            "AssGrpName": null,
            "UsageName": null
        },
        "04386": {
            "GenartName": "Mounting Kit, parking assist",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Assist Sensor"
        },
        "04387": {
            "GenartName": "Mounting Kit, parking assist",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Assist Sensor"
        },
        "04388": {
            "GenartName": "Cable Pull, door release",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Lock System",
            "UsageName": "Door Release"
        },
        "04389": {
            "GenartName": "Linkage, door release",
            "NormTermName": "Linkage",
            "AssGrpName": "Lock System",
            "UsageName": "Door Release"
        },
        "04390": {
            "GenartName": "Seal Kit, injector pump",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump"
        },
        "04391": {
            "GenartName": "Seal Kit, injector pump centrifugal governor",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Pump Centrifugal Governor"
        },
        "04393": {
            "GenartName": "Filter, interior air",
            "NormTermName": "Filter",
            "AssGrpName": "Heating/Ventilation, universal",
            "UsageName": "Interior Air"
        },
        "04394": {
            "GenartName": "Regulator, interior blower",
            "NormTermName": "Regulator",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Blower"
        },
        "04395": {
            "GenartName": "Cupholder",
            "NormTermName": "Holder",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Cupholder"
        },
        "04396": {
            "GenartName": "Wall Mounting, cable spool",
            "NormTermName": "Bracket",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Cable Spool"
        },
        "04397": {
            "GenartName": "Gasket Set, centrifugal cleaner",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Lubrication",
            "UsageName": "Centrifugal Cleaner"
        },
        "04398": {
            "GenartName": "Cover, driver cab tilt cylinder",
            "NormTermName": "Cover",
            "AssGrpName": "Driver Cab",
            "UsageName": "Tilt Cylinder"
        },
        "04399": {
            "GenartName": "Bellow, brake drum adjuster",
            "NormTermName": "Bellow",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Drum Adjuster"
        },
        "04400": {
            "GenartName": "Tyre",
            "NormTermName": "Tyre",
            "AssGrpName": "Wheels",
            "UsageName": null
        },
        "04401": {
            "GenartName": "Oil Cooler, manual transmission",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Hydraulic Oil"
        },
        "04402": {
            "GenartName": "Sensor, urea supply",
            "NormTermName": "Sensor",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea Supply"
        },
        "04403": {
            "GenartName": "Cover, fresh air conduit (passenger compartment air)",
            "NormTermName": "Cover",
            "AssGrpName": "Body",
            "UsageName": "Fresh Air Conduit (passenger compartment air)"
        },
        "04404": {
            "GenartName": "Front Muffler, universal",
            "NormTermName": "Damper",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Front Muffler"
        },
        "04405": {
            "GenartName": "Centre Muffler, universal",
            "NormTermName": "Damper",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Centre Muffler"
        },
        "04406": {
            "GenartName": "Rear Muffler, universal",
            "NormTermName": "Damper",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Rear Muffler"
        },
        "04407": {
            "GenartName": "Mounting Tool Kit, alternator freewheel clutch",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Alternator Freewheel Clutch"
        },
        "04408": {
            "GenartName": "Relay, coolant level warning light",
            "NormTermName": "Relay",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Level Warning Light"
        },
        "04409": {
            "GenartName": "Bush, driver cab tilt cylinder",
            "NormTermName": "Bush",
            "AssGrpName": "Driver Cab",
            "UsageName": "Tilt Cylinder"
        },
        "04410": {
            "GenartName": "Mounting Bracket, bumper",
            "NormTermName": "Holder",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Bumper"
        },
        "04411": {
            "GenartName": "Ski/Snowboard Holder, towbar coupling carrier",
            "NormTermName": "Ski/Snowboard Holder",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Towbar Carrier"
        },
        "04412": {
            "GenartName": "Spacer Sleeve, exhaust system",
            "NormTermName": "Sleeve",
            "AssGrpName": "Exhaust System",
            "UsageName": "Spacer Sleeve"
        },
        "04413": {
            "GenartName": "Trigger Clamp, self-diagnosis unit",
            "NormTermName": "Trigger Clamp",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Self-diagnosis Unit"
        },
        "04414": {
            "GenartName": "Multimeter Holding Wire",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Multimeter"
        },
        "04415": {
            "GenartName": "Oscilloscope",
            "NormTermName": "Oscilloscope",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04416": {
            "GenartName": "Oscilloscope Holding Wire",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Oscilloscope"
        },
        "04417": {
            "GenartName": "Electric Kit, trailer hitch",
            "NormTermName": "Electric Kit",
            "AssGrpName": "Towbar, universal",
            "UsageName": null
        },
        "04418": {
            "GenartName": "Spark Plug Pipe",
            "NormTermName": "Tube",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Spark plug cylinder head"
        },
        "04419": {
            "GenartName": "Mounting Kit, Ball Joint",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Ball Joint"
        },
        "04420": {
            "GenartName": "Voltage Limiter, electrical system",
            "NormTermName": "Voltage Limiter",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Vehicle Power Supply"
        },
        "04422": {
            "GenartName": "Interface, cruise control system",
            "NormTermName": "Interface",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": "Cruise Control System"
        },
        "04423": {
            "GenartName": "Indicator Module",
            "NormTermName": "Indicator Module",
            "AssGrpName": "Signal System, universal",
            "UsageName": null
        },
        "04424": {
            "GenartName": "Control Unit, lights",
            "NormTermName": "Control Unit",
            "AssGrpName": "Lights, universal",
            "UsageName": null
        },
        "04425": {
            "GenartName": "Seat Heating, Universal",
            "NormTermName": "Seat Heating",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04426": {
            "GenartName": "Control Unit, emergency stop light",
            "NormTermName": "Control Unit",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Emergency Brake Light"
        },
        "04427": {
            "GenartName": "Seat Cooling, Universal",
            "NormTermName": "Seat Cooling",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04428": {
            "GenartName": "Wet/Dry Vacuum Cleaner",
            "NormTermName": "Wet/Dry Vacuum Cleaner",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04429": {
            "GenartName": "Lane Change Warning Light",
            "NormTermName": "Light",
            "AssGrpName": "Body",
            "UsageName": "Lane Change Assistant"
        },
        "04430": {
            "GenartName": "Bearing, balance shaft",
            "NormTermName": "Bearing",
            "AssGrpName": "Crankcase",
            "UsageName": "Balance Shaft"
        },
        "04431": {
            "GenartName": "Hydrometer, antifreeze tester",
            "NormTermName": "Hydrometer",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Antifreeze Tester"
        },
        "04432": {
            "GenartName": "Connector Set, light",
            "NormTermName": "Connector Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Lights"
        },
        "04433": {
            "GenartName": "Power Supply",
            "NormTermName": "Power Supply",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "04434": {
            "GenartName": "Power Cable",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Power Cable"
        },
        "04435": {
            "GenartName": "Test Set, acid/antifreeze",
            "NormTermName": "Test Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Acid/Antifreeze"
        },
        "04436": {
            "GenartName": "Overvoltage Protector, battery",
            "NormTermName": "Overvoltage Protector",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04437": {
            "GenartName": "Cylinder Pressure Regulator",
            "NormTermName": "Pressure Regulator",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Gas Cylinder"
        },
        "04438": {
            "GenartName": "Voltage Detector",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Voltage"
        },
        "04440": {
            "GenartName": "Sump Separating and Cleaning Kit",
            "NormTermName": "Sump Separating and Cleaning Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04441": {
            "GenartName": "Sump Wrench",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Oil Sump"
        },
        "04442": {
            "GenartName": "Sump Wrench Set",
            "NormTermName": "Key Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Oil Sump"
        },
        "04443": {
            "GenartName": "Socket Wrench Insert, engine bracket",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Engine Bracket"
        },
        "04444": {
            "GenartName": "Spanner, timing belt tension",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Timing Belt Tension"
        },
        "04445": {
            "GenartName": "Holding Tool, belt pulley",
            "NormTermName": "Holding Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Belt Pulley"
        },
        "04446": {
            "GenartName": "Installation Tool, fan (fluid friction coupling)",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Radiator Fan"
        },
        "04447": {
            "GenartName": "Puller, guide rail pin",
            "NormTermName": "Extractor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Guide Rail Pin"
        },
        "04448": {
            "GenartName": "Installation Tool, gear/sprocket",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Camshaft Gear/Sprocket"
        },
        "04449": {
            "GenartName": "Vehicle Lift",
            "NormTermName": "Vehicle Lift",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04450": {
            "GenartName": "Mounting Device, front fairing",
            "NormTermName": "Mounting Device",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Front Cowling"
        },
        "04451": {
            "GenartName": "Puller, camshaft gear/sprocket",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft Gear/Sprocket"
        },
        "04452": {
            "GenartName": "Puller Set, camshaft gear/sprocket",
            "NormTermName": "Puller Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft Gear/Sprocket"
        },
        "04453": {
            "GenartName": "Retaining Tool, valve timing",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Valve Timing"
        },
        "04454": {
            "GenartName": "Retaining Tool Set, valve timing",
            "NormTermName": "Retaining Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Valve Timing"
        },
        "04455": {
            "GenartName": "Retaining Pin, crankshaft",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft"
        },
        "04456": {
            "GenartName": "Mounting Tool, camshaft",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft"
        },
        "04457": {
            "GenartName": "Retaining Tool Set, camshaft",
            "NormTermName": "Retaining Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft"
        },
        "04458": {
            "GenartName": "Tool Set, valve timing",
            "NormTermName": "Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Valve Timing"
        },
        "04459": {
            "GenartName": "Mounting Tool Set, water pump",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Water Pump"
        },
        "04460": {
            "GenartName": "Puller, water pump gear",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Water Pump Gear"
        },
        "04461": {
            "GenartName": "Mounting Tool, water pump",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Water Pump"
        },
        "04462": {
            "GenartName": "Retaining Tool, crankshaft",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft"
        },
        "04463": {
            "GenartName": "Adjustment Tool Set, valve timing",
            "NormTermName": "Adjustment Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Valve Timing"
        },
        "04464": {
            "GenartName": "Adjustment Ruler , camshaft",
            "NormTermName": "Ruler",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft Alignment"
        },
        "04465": {
            "GenartName": "Retaining Tool, camshaft",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft"
        },
        "04466": {
            "GenartName": "Mounting Tool, exhaust system holder",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Mount Exhaust System"
        },
        "04467": {
            "GenartName": "Retaining Pin, camshaft",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft"
        },
        "04468": {
            "GenartName": "Retaining Tool, crankshaft timing belt gear",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft Timing Belt Gear"
        },
        "04469": {
            "GenartName": "Gas Spring, door",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Door"
        },
        "04470": {
            "GenartName": "Steering Box",
            "NormTermName": "Housing",
            "AssGrpName": "Steering",
            "UsageName": "Steering Gear"
        },
        "04471": {
            "GenartName": "Rack & Pinion, steering gear",
            "NormTermName": "Rack & Pinion",
            "AssGrpName": "Steering",
            "UsageName": "Steering Gear"
        },
        "04472": {
            "GenartName": "Holder, dial gauge",
            "NormTermName": "Holder",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Dial Gauge"
        },
        "04473": {
            "GenartName": "Retaining Tool, timing belt tensioner pulley",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Timing Belt Tensioner Pulley"
        },
        "04474": {
            "GenartName": "Adjustment Gauge , camshaft",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft Alignment"
        },
        "04475": {
            "GenartName": "Adjustment Mandrel, injection pump",
            "NormTermName": "Adjustment Mandrel",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injection Pump"
        },
        "04476": {
            "GenartName": "Adjustment Mandrel, crankshaft",
            "NormTermName": "Adjustment Mandrel",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft"
        },
        "04477": {
            "GenartName": "Locking Gauge, camshaft",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft Retaining"
        },
        "04478": {
            "GenartName": "Locking Gauge, camshaft phase adjuster",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Phase Adjuster Retaining"
        },
        "04479": {
            "GenartName": "Retaining Pin, timing belt tensioner",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Timing Belt Tensioner"
        },
        "04480": {
            "GenartName": "Retaining Pin, v-ribbed belt tensioner",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "V-Ribbed Belt Tensioner"
        },
        "04481": {
            "GenartName": "Alignment Ruler, camshaft",
            "NormTermName": "Ruler",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft Valve Timing"
        },
        "04482": {
            "GenartName": "Spanner, v-belt tension",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "V-Belt Tension"
        },
        "04483": {
            "GenartName": "Mounting Tool Set, intake/exhaust valve",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Intake/Exhaust Valve"
        },
        "04484": {
            "GenartName": "Pliers, valve stem seal",
            "NormTermName": "Pliers",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Valve Stem Seal"
        },
        "04485": {
            "GenartName": "Holding Tool, crankshaft",
            "NormTermName": "Holding Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft"
        },
        "04486": {
            "GenartName": "Locking Tool, flywheel",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flywheel"
        },
        "04487": {
            "GenartName": "Puller, shaft seal",
            "NormTermName": "Extractor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Shaft Seal"
        },
        "04488": {
            "GenartName": "Installer Set, shaft seal",
            "NormTermName": "Installer Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Shaft Seal"
        },
        "04489": {
            "GenartName": "Socket Wrench Insert, cylinder head bolt",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Cylinder Head Bolt"
        },
        "04490": {
            "GenartName": "Piston Ring Compressor",
            "NormTermName": "Tightening Strap",
            "AssGrpName": "Tool, universal",
            "UsageName": "Piston Ring"
        },
        "04491": {
            "GenartName": "Mounting Tool Kit, alternator freewheel clutch",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Alternator Freewheel Clutch"
        },
        "04492": {
            "GenartName": "Release Tool, aircon-/fuel hose",
            "NormTermName": "Release Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Aircon-/Fuel Hose"
        },
        "04493": {
            "GenartName": "Disassembly Tool, ignition lock",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ignition Lock"
        },
        "04494": {
            "GenartName": "Disassembly Tool, instrument cluster",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Instrument Cluster"
        },
        "04495": {
            "GenartName": "Release Tool, airbag",
            "NormTermName": "Release Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Airbag"
        },
        "04496": {
            "GenartName": "Thread Tap, glow plug",
            "NormTermName": "Thread Tap",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glow Plug"
        },
        "04497": {
            "GenartName": "Pliers, spark plug socket",
            "NormTermName": "Pliers",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spark Plug Head"
        },
        "04498": {
            "GenartName": "Mounting Tool, ignition coil",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ignition Coil"
        },
        "04499": {
            "GenartName": "Spark Plug Spanner",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spark Plug"
        },
        "04500": {
            "GenartName": "Spanner, turbocharger/particulate filter",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Turbocharger/Particulate Filter"
        },
        "04501": {
            "GenartName": "Die Set, lambda sensor",
            "NormTermName": "Thread Cutter Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Lambda Sensor"
        },
        "04502": {
            "GenartName": "Socket Wrench Insert, lambda sensor",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Lambda Sensor"
        },
        "04503": {
            "GenartName": "Mounting Tool Set, tank sensor/fuel pump",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tank Sensor/Fuel Pump"
        },
        "04504": {
            "GenartName": "Pliers, hose clamp",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hose Clamp"
        },
        "04505": {
            "GenartName": "Release Tool Set, fuel line",
            "NormTermName": "Release Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Fuel Line"
        },
        "04506": {
            "GenartName": "Fuel Filter Spanner",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Fuel Filter"
        },
        "04507": {
            "GenartName": "Mounting Tool, fuel filter",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Fuel Filter"
        },
        "04508": {
            "GenartName": "Tester, fuel system pressure",
            "NormTermName": "Tester",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Fuel System Pressure"
        },
        "04509": {
            "GenartName": "Tester, common rail injector",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Common Rail Injector"
        },
        "04510": {
            "GenartName": "Release Tool Set, injector pump",
            "NormTermName": "Adjustment Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injection Pump"
        },
        "04511": {
            "GenartName": "Retaining Pin, injector pump",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injection Pump"
        },
        "04512": {
            "GenartName": "Retaining Pin, flywheel",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Flywheel"
        },
        "04513": {
            "GenartName": "Adjustment Screw, injector pump",
            "NormTermName": "Adjustment Screw",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump"
        },
        "04514": {
            "GenartName": "Puller, injector pump gear",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector Pump Gear"
        },
        "04515": {
            "GenartName": "Puller, unit injector",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Unit Injector (UI)"
        },
        "04516": {
            "GenartName": "Mounting Tool Set, unit injector",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Unit Injector (UI)"
        },
        "04517": {
            "GenartName": "Disassembly Tool, common rail injector",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Common Rail Injector"
        },
        "04518": {
            "GenartName": "Socket Wrench Insert, injector holder bolt",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector Holder Screw"
        },
        "04519": {
            "GenartName": "Disassembly Tool Set, common rail injector",
            "NormTermName": "Disassembly Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Common Rail Injector"
        },
        "04520": {
            "GenartName": "Adapter, CR injector disassembly tool",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Disassembly Tool, CR injector"
        },
        "04521": {
            "GenartName": "Adjustment Tool, common rail injector",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Common Rail Injector"
        },
        "04522": {
            "GenartName": "Socket Wrench Insert, common rail injector",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Common Rail Injector"
        },
        "04523": {
            "GenartName": "Cleaning/Milling Tool Set, CR injector seat",
            "NormTermName": "Cleaning/Milling Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "CR Injector Seat"
        },
        "04524": {
            "GenartName": "Socket Wrench Insert, injector nozzle",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector Nozzle"
        },
        "04525": {
            "GenartName": "Socket Wrench Insert, prechamber",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Pre-chamber"
        },
        "04526": {
            "GenartName": "Pliers, glow plug socket",
            "NormTermName": "Pliers",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow Plug Socket"
        },
        "04527": {
            "GenartName": "Joint Socket Wrench Insert, glow plug",
            "NormTermName": "Joint Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow Plug"
        },
        "04528": {
            "GenartName": "Set of Socket Wrench Inserts, glow plug",
            "NormTermName": "Set of Socket Wrench Inserts",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow Plug"
        },
        "04529": {
            "GenartName": "Socket Wrench Insert, glow plug",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glow Plug"
        },
        "04530": {
            "GenartName": "Puller, glow plug",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow Plug"
        },
        "04531": {
            "GenartName": "Release Pliers, glow plug contact pin",
            "NormTermName": "Release Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glow Plug Contact Pin"
        },
        "04532": {
            "GenartName": "Disassembly Tool Set, glow plug",
            "NormTermName": "Disassembly Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow Plug"
        },
        "04533": {
            "GenartName": "Disassembly Tool Set, glow plug",
            "NormTermName": "Disassembly Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glow Plug"
        },
        "04534": {
            "GenartName": "Puller Set, glow plug pin",
            "NormTermName": "Puller Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glow Plug Pin"
        },
        "04535": {
            "GenartName": "Reamer, glow plug",
            "NormTermName": "Reamer",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow Plug"
        },
        "04536": {
            "GenartName": "Die Set, glow plug",
            "NormTermName": "Thread Cutter Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glow Plug"
        },
        "04537": {
            "GenartName": "Die Set, glow plug",
            "NormTermName": "Thread Cutter Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow Plug"
        },
        "04538": {
            "GenartName": "Puller, injector seal",
            "NormTermName": "Extractor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Injector Seal Ring"
        },
        "04539": {
            "GenartName": "Centering Pin, clutch",
            "NormTermName": "Centering Arbor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Clutch"
        },
        "04540": {
            "GenartName": "Mounting Tool Set, clutch pressure plate",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Clutch Pressure Plate"
        },
        "04541": {
            "GenartName": "Socket Wrench Insert, clutch pressure plate",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Clutch Pressure Plate"
        },
        "04542": {
            "GenartName": "Centering Tool Set, clutch",
            "NormTermName": "Centering Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch"
        },
        "04543": {
            "GenartName": "Socket Wrench Insert, oil drain plug",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Oil Drain Plug"
        },
        "04544": {
            "GenartName": "Socket Wrench Insert, torsion bar",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Torsion Bar"
        },
        "04545": {
            "GenartName": "Repair Kit, oil drain plug thread",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Oil Drain Plug Thread"
        },
        "04546": {
            "GenartName": "Service Dipstick, oil change",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Oil Change"
        },
        "04547": {
            "GenartName": "Set of Socket Wrench Inserts, oil drain plug",
            "NormTermName": "Set of Socket Wrench Inserts",
            "AssGrpName": "Tool, universal",
            "UsageName": "Oil Drain Plug"
        },
        "04548": {
            "GenartName": "Oil Drain Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Oil Drain Hose"
        },
        "04549": {
            "GenartName": "Puller, steering wheel spacer sleeve",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Steering Wheel Spacer Sleeve"
        },
        "04550": {
            "GenartName": "Puller, ball joint",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ball Joint"
        },
        "04551": {
            "GenartName": "Puller, ball joint",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ball Joint"
        },
        "04552": {
            "GenartName": "Ejector, control arm clamping screw",
            "NormTermName": "Ejector",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Control Arm Clamping Screw"
        },
        "04553": {
            "GenartName": "Ejector, ball joint",
            "NormTermName": "Ejector",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ball Joint"
        },
        "04554": {
            "GenartName": "Spring Compressor, suspension spring",
            "NormTermName": "Spring Compressor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Suspension Spring"
        },
        "04555": {
            "GenartName": "Spreader, ball joint mounting",
            "NormTermName": "Spreader",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ball Joint Mounting"
        },
        "04556": {
            "GenartName": "Mounting Tool Set, silent bearing",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Silent Bearing"
        },
        "04557": {
            "GenartName": "Mounting Tool Set, ball joint",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ball Joint"
        },
        "04558": {
            "GenartName": "Mounting Tool Set, stabiliser ball joint",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Stabiliser Ball Joint"
        },
        "04559": {
            "GenartName": "Socket Wrench Insert, ball joint nut",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ball Joint Groove Nut"
        },
        "04560": {
            "GenartName": "Mounting Tool Set, silent bearing",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Silent Bearing"
        },
        "04561": {
            "GenartName": "Puller, drive shaft joint",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drive Shaft Joint"
        },
        "04562": {
            "GenartName": "Pliers, drive shaft joint retainer",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drive Shaft Joint Retainer"
        },
        "04563": {
            "GenartName": "Pliers, steering bellow clamp",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Steering Bellow Clamp"
        },
        "04564": {
            "GenartName": "Impact Puller",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Puller"
        },
        "04565": {
            "GenartName": "Circlip Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Circlip Pliers"
        },
        "04566": {
            "GenartName": "Puller, wheel hub",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Hub"
        },
        "04567": {
            "GenartName": "Mounting Tool Set, wheel hub/wheel bearing",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Hub/Wheel Bearing"
        },
        "04568": {
            "GenartName": "Socket Wrench Insert, wheel hub/bearing",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Hub/Wheel Bearing"
        },
        "04569": {
            "GenartName": "Puller, wheel bearing inner ring",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wheel Bearing Inner Ring"
        },
        "04570": {
            "GenartName": "Socket Wrench, bleeder screw/valve",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Bleeder Screw/Valve"
        },
        "04571": {
            "GenartName": "Socket Wrench Insert, brake caliper",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Caliper"
        },
        "04572": {
            "GenartName": "Turn / Reset Tool, brake caliper piston",
            "NormTermName": "Turn / Reset Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Caliper Piston"
        },
        "04573": {
            "GenartName": "Repair Set, brake caliper guide thread",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Caliper Guide Thread"
        },
        "04574": {
            "GenartName": "Mounting Tool, brake shoe holding spring",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Shoe Holding Spring"
        },
        "04575": {
            "GenartName": "Reamer, ABS sensor mounting",
            "NormTermName": "Reamer",
            "AssGrpName": "Tool, universal",
            "UsageName": "ABS Sensor Mounting"
        },
        "04576": {
            "GenartName": "Mounting Tool, exhaust system steel bracket",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Exhaust System Steel Bracket"
        },
        "04577": {
            "GenartName": "Pliers, hose clamp",
            "NormTermName": "Pliers",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Hose Clamp"
        },
        "04578": {
            "GenartName": "Double Ring Spanner",
            "NormTermName": "Spanner, double ring",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04579": {
            "GenartName": "Double Ring Spanner Set",
            "NormTermName": "Spanner Set, double ring",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04580": {
            "GenartName": "Puller Set",
            "NormTermName": "Puller Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04581": {
            "GenartName": "Socket Wrench Insert, wheel nut/bolt",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wheel Nut/Bolt"
        },
        "04582": {
            "GenartName": "Pliers, steering bellow clamp",
            "NormTermName": "Pliers",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Steering Bellow Clamp"
        },
        "04583": {
            "GenartName": "Retaining Pin Set, support frame/subframe",
            "NormTermName": "Retaining Pin Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Support Frame/ Engine Carrier"
        },
        "04584": {
            "GenartName": "Mounting Tools, V-ribbed belt",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "V-Ribbed Belt"
        },
        "04585": {
            "GenartName": "Cleaning/Milling Tool Set, CR injector seat",
            "NormTermName": "Cleaning/Milling Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "CR Injector Seat"
        },
        "04586": {
            "GenartName": "Socket Wrench Insert, lambda sensor",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Lambda Sensor"
        },
        "04587": {
            "GenartName": "Impact Puller, shaft seal",
            "NormTermName": "Impact Extractor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Shaft Seal"
        },
        "04588": {
            "GenartName": "Steering Set",
            "NormTermName": "Steering Set",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "04590": {
            "GenartName": "Connecting Cable, socket",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Socket"
        },
        "04593": {
            "GenartName": "Adapter, spiral hose",
            "NormTermName": "Adaptor",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Spiral Hose"
        },
        "04594": {
            "GenartName": "Spray Gun, air conditioning cleaner/-disinfectant",
            "NormTermName": "Spray Gun",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Air Conditioning"
        },
        "04595": {
            "GenartName": "Signal Amplifier, cruise control",
            "NormTermName": "Signal Amplifier",
            "AssGrpName": "Cruise Control, universal",
            "UsageName": "Cruise Control System"
        },
        "04596": {
            "GenartName": "Movement Sensor, alarm system",
            "NormTermName": "Sensor",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Alarm System"
        },
        "04597": {
            "GenartName": "Contact Switch, alarm system",
            "NormTermName": "Switch",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Alarm System"
        },
        "04598": {
            "GenartName": "Gas Warning Unit, alarm system",
            "NormTermName": "Detector",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Alarm System"
        },
        "04599": {
            "GenartName": "Extension Cable, parking assist",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Distance Control"
        },
        "04600": {
            "GenartName": "Plug, headlight",
            "NormTermName": "Plug",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "04601": {
            "GenartName": "Plug, front fog light",
            "NormTermName": "Plug",
            "AssGrpName": "Lights, universal",
            "UsageName": "Front Fog Light"
        },
        "04602": {
            "GenartName": "Insert, plug",
            "NormTermName": "Insert",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Plug"
        },
        "04603": {
            "GenartName": "Insert, socket",
            "NormTermName": "Insert",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Socket"
        },
        "04605": {
            "GenartName": "Cupped Washer",
            "NormTermName": "Cupped Washer",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "04606": {
            "GenartName": "Blind Rivet",
            "NormTermName": "Rivet",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "04607": {
            "GenartName": "Snap Hook",
            "NormTermName": "Hook",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04609": {
            "GenartName": "Kit, screwdriver bits",
            "NormTermName": "Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Screwdriver"
        },
        "04610": {
            "GenartName": "Retaining Clip Set, interior trim",
            "NormTermName": "Retaining Clip Set",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": "Interior Trim"
        },
        "04611": {
            "GenartName": "Retaining Clip Set, body",
            "NormTermName": "Retaining Clip Set",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Body"
        },
        "04612": {
            "GenartName": "Assortment, clamping clips",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Clamping Clip"
        },
        "04613": {
            "GenartName": "Thread Insert",
            "NormTermName": "Thread Insert",
            "AssGrpName": "Standard Parts",
            "UsageName": "Thread"
        },
        "04614": {
            "GenartName": "Tool Assortment, thread insert",
            "NormTermName": "Assortment",
            "AssGrpName": "Tool, universal",
            "UsageName": "Thread Insert"
        },
        "04615": {
            "GenartName": "Assortment, heat shrink tube",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Heatshrink Tube"
        },
        "04616": {
            "GenartName": "Assortment, crimp connectors",
            "NormTermName": "Assortment",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Crimp Connection"
        },
        "04617": {
            "GenartName": "Assortment, plug connectors",
            "NormTermName": "Assortment",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Plug Connector"
        },
        "04618": {
            "GenartName": "Drill Bit",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drill"
        },
        "04619": {
            "GenartName": "Shackle",
            "NormTermName": "Shackle",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04620": {
            "GenartName": "Hook",
            "NormTermName": "Hook",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Hook"
        },
        "04621": {
            "GenartName": "Mounting Set, suspension strut support mount",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Suspension Strut Support Mount"
        },
        "04622": {
            "GenartName": "Mounting Tool Set, wheel hub/wheel bearing",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wheel Hub/Wheel Bearing"
        },
        "04623": {
            "GenartName": "Accessory Set, brake pad set for disc parking brake",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake pad set for disc parking brake"
        },
        "04624": {
            "GenartName": "Engine Guard/Skid Plate",
            "NormTermName": "Engine Guard/Skid Plate",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "04625": {
            "GenartName": "Propshaft Ejector/Wheel Hub Puller Flange",
            "NormTermName": "Ejector/Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Propshaft/Wheel Hub"
        },
        "04626": {
            "GenartName": "Cleaning Equipment, brake line",
            "NormTermName": "Cleaning Equipment",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Line"
        },
        "04627": {
            "GenartName": "Wheel Lock Removal Kit",
            "NormTermName": "Removal Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wheel Lock"
        },
        "04628": {
            "GenartName": "Test Set, cooling system pressure",
            "NormTermName": "Test Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Cooling System Pressure"
        },
        "04629": {
            "GenartName": "Vacuum Filling Unit, cooling system",
            "NormTermName": "Filling Unit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Cooling System"
        },
        "04630": {
            "GenartName": "Test Unit, cylinder head leakage",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Cylinder Head Leakage"
        },
        "04631": {
            "GenartName": "Chisel",
            "NormTermName": "Chisel",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04632": {
            "GenartName": "Control, headlight levelling",
            "NormTermName": "Regulator",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight Levelling"
        },
        "04633": {
            "GenartName": "Exhaust Gas Recirculation",
            "NormTermName": "Graphic",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": null
        },
        "04634": {
            "GenartName": "Gasket Set, EGR system",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "Exhaust Gas Recirculation (EGR)"
        },
        "04635": {
            "GenartName": "Thermostat, cold start enrichment",
            "NormTermName": "Thermostat",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Warm-upPhase"
        },
        "04636": {
            "GenartName": "Connector Cable, starter battery",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Starter System, universal",
            "UsageName": "Starter Battery"
        },
        "04637": {
            "GenartName": "Relay, engine management control unit",
            "NormTermName": "Relay",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Engine Management"
        },
        "04638": {
            "GenartName": "Probe, oscilloscope",
            "NormTermName": "Probe",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Oscilloscope"
        },
        "04639": {
            "GenartName": "Probe, multimeter",
            "NormTermName": "Probe",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Multimeter"
        },
        "04640": {
            "GenartName": "Trigger Clamp, oscilloscope",
            "NormTermName": "Trigger Clamp",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Oscilloscope"
        },
        "04641": {
            "GenartName": "Amp Clamp, oscilloscope",
            "NormTermName": "Amp Clamp",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Oscilloscope"
        },
        "04642": {
            "GenartName": "Earth Cable, oscilloscope",
            "NormTermName": "Earth Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Oscilloscope"
        },
        "04643": {
            "GenartName": "Connect Adapter, oscilloscope",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Oscilloscope"
        },
        "04644": {
            "GenartName": "Mounting Set, tensioner-/return pulley (timing belt)",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": "Tensioner-/Return Pulley (timing belt)"
        },
        "04645": {
            "GenartName": "Mounting Set, tensioner-/return pulley (V-ribbed belt)",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Belt Drive",
            "UsageName": "Tensioner/Return Pulley (V-ribbed belt)"
        },
        "04646": {
            "GenartName": "Release Pliers, door handle-/window crank retainer",
            "NormTermName": "Release Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Door Grip-/window crank retainer"
        },
        "04647": {
            "GenartName": "Release Tool Set, door panels",
            "NormTermName": "Removal Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Interior Door Panel"
        },
        "04648": {
            "GenartName": "Mounting Wedge",
            "NormTermName": "Mounting Wedge",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04649": {
            "GenartName": "Disassembly Tool, body holding clip",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Body Holding Clip"
        },
        "04650": {
            "GenartName": "Mounting Wedge Set",
            "NormTermName": "Mounting Wedge Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04651": {
            "GenartName": "Socket Wrench, exterior mirror",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Exterior Mirror"
        },
        "04652": {
            "GenartName": "Mounting Tool, wing",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wing"
        },
        "04653": {
            "GenartName": "Drill Gauge Set, studs",
            "NormTermName": "Gauge Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Studs Drill Gauge Set"
        },
        "04654": {
            "GenartName": "Damper Mounting, driver cab",
            "NormTermName": "Mounting",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Mounting Damper"
        },
        "04655": {
            "GenartName": "Connecting Cable, light",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Lights, universal",
            "UsageName": "Lights"
        },
        "04656": {
            "GenartName": "Fill-/Extraction Pump, universal",
            "NormTermName": "Pump",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Fill-/Extractor Pump"
        },
        "04657": {
            "GenartName": "Mounting Tool, door lock/lock cylinder housing",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Door Lock/Lock Cylinder Housing"
        },
        "04658": {
            "GenartName": "Lever, tyre",
            "NormTermName": "Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tyre Mounting"
        },
        "04659": {
            "GenartName": "Release Tool, flat-/round plug",
            "NormTermName": "Release Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flat-/Round Plug"
        },
        "04660": {
            "GenartName": "Pliers, protective cap (wheel bolt/nut)",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Protective Cap (wheel bolt/nut)"
        },
        "04661": {
            "GenartName": "Retaining Pin Set, V-ribbed belt/chain tensioner",
            "NormTermName": "Retaining Pin Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "V-Ribbed Belt-/Chain Tensioner"
        },
        "04662": {
            "GenartName": "Plunger, high pressure pump",
            "NormTermName": "Tappet",
            "AssGrpName": "Mixture Formation",
            "UsageName": "High Pressure Pump"
        },
        "04663": {
            "GenartName": "Refrigerant Filter, air conditioning",
            "NormTermName": "Filter",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Air Conditioning Refrigerant"
        },
        "04664": {
            "GenartName": "Cover Cap, service valve",
            "NormTermName": "Cap",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Service Valve"
        },
        "04665": {
            "GenartName": "Thermostat, coolant",
            "NormTermName": "Graphic",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant"
        },
        "04666": {
            "GenartName": "Switch, convertible top",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Convertible Top"
        },
        "04667": {
            "GenartName": "Repair Pipe, centre muffler",
            "NormTermName": "Tube",
            "AssGrpName": "Exhaust System",
            "UsageName": "Centre Muffler"
        },
        "04668": {
            "GenartName": "Repair Set, piston/sleeve",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Piston/Sleeve"
        },
        "04669": {
            "GenartName": "Repair Kit, exhaust pipe",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Pipe"
        },
        "04670": {
            "GenartName": "Cover Cap, alternator connection",
            "NormTermName": "Cap",
            "AssGrpName": "Alternator, universal",
            "UsageName": "Alternator Connection"
        },
        "04671": {
            "GenartName": "Wall Mounting, fuel lines",
            "NormTermName": "Bracket",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Fuel Line"
        },
        "04672": {
            "GenartName": "Printer, brake fluid testing unit",
            "NormTermName": "Printer",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Test Unit, brake fluid"
        },
        "04673": {
            "GenartName": "Paper Roll, brake fluid testing unit",
            "NormTermName": "Pulley",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Test Unit, brake fluid"
        },
        "04674": {
            "GenartName": "Printer Holder, brake fluid testing unit",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Printer, brake fluid testing unit"
        },
        "04675": {
            "GenartName": "Repair Kit,connection cable (brake fluid test device)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Test Unit, brake fluid"
        },
        "04676": {
            "GenartName": "Connecting Cable, side marker light",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Lights",
            "UsageName": "Side Marker Light"
        },
        "04677": {
            "GenartName": "Connecting Set, side marker light",
            "NormTermName": "Connector Set",
            "AssGrpName": "Lights",
            "UsageName": "Side Marker Light"
        },
        "04678": {
            "GenartName": "Footwell Tray",
            "NormTermName": "Tray",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Footwell"
        },
        "04679": {
            "GenartName": "Footwell Tray",
            "NormTermName": "Tray",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": "Footwell"
        },
        "04680": {
            "GenartName": "Wiper Blade, universal",
            "NormTermName": "Wiper Blade",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": null
        },
        "04681": {
            "GenartName": "Oil Cooler, retarder",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "04682": {
            "GenartName": "Parts Set, maintenance service",
            "NormTermName": "Parts Set",
            "AssGrpName": "Service/Maintenance",
            "UsageName": "Maintenance Service"
        },
        "04683": {
            "GenartName": "Loudspeaker",
            "NormTermName": "Loudspeaker",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": null
        },
        "04684": {
            "GenartName": "Loudspeaker",
            "NormTermName": "Loudspeaker",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "04685": {
            "GenartName": "Fluorescent Tubes, hand light",
            "NormTermName": "Fluorescent Tubes",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Hand Lamp"
        },
        "04686": {
            "GenartName": "Drive Plate, magnetic clutch compressor",
            "NormTermName": "Drive Plate",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Magnetic Clutch Compressor"
        },
        "04687": {
            "GenartName": "Cover, clutch release bearing shaft",
            "NormTermName": "Cover",
            "AssGrpName": "Clutch",
            "UsageName": "Releaser Shaft"
        },
        "04688": {
            "GenartName": "Release Bearing Shaft, clutch",
            "NormTermName": "Shaft",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Release Bearing"
        },
        "04689": {
            "GenartName": "Spring, clutch lever",
            "NormTermName": "Spring",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Control"
        },
        "04690": {
            "GenartName": "Sensor, clutch booster",
            "NormTermName": "Sensor",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Clutch Booster"
        },
        "04691": {
            "GenartName": "Ring-/Open End Spanner",
            "NormTermName": "Ring-/Open End Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04692": {
            "GenartName": "Adjustable Spanner",
            "NormTermName": "Adjustable Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04693": {
            "GenartName": "Rod Magnet",
            "NormTermName": "Rod Magnet",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04694": {
            "GenartName": "Screwdriver",
            "NormTermName": "Screwdriver",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04695": {
            "GenartName": "Screwdriver Set",
            "NormTermName": "Screwdriver Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04696": {
            "GenartName": "Side Cutter",
            "NormTermName": "Side Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04697": {
            "GenartName": "Universal Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Universal"
        },
        "04698": {
            "GenartName": "Round Nose Pliers",
            "NormTermName": "Round Nose Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04699": {
            "GenartName": "Vise-grip Pliers",
            "NormTermName": "Vise-grip Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04700": {
            "GenartName": "Combination Pliers",
            "NormTermName": "Combination Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04701": {
            "GenartName": "Vice",
            "NormTermName": "Vice",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04702": {
            "GenartName": "Screw Clamp",
            "NormTermName": "Screw Clamp",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04703": {
            "GenartName": "Vernier Calliper",
            "NormTermName": "Vernier Caliper",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04704": {
            "GenartName": "File",
            "NormTermName": "File",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04705": {
            "GenartName": "Hammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04706": {
            "GenartName": "Centre Punch",
            "NormTermName": "Centre Punch",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04707": {
            "GenartName": "High Pressure Cleaner",
            "NormTermName": "High Pressure Cleaner",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04708": {
            "GenartName": "Riveting Pliers",
            "NormTermName": "Riveting Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04709": {
            "GenartName": "Compressed Air Tyre Gauge/-Filler",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Compressed Air"
        },
        "04711": {
            "GenartName": "Sandpaper",
            "NormTermName": "Sandpaper",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04712": {
            "GenartName": "Cutter",
            "NormTermName": "Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04713": {
            "GenartName": "Drill Bit",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drill"
        },
        "04714": {
            "GenartName": "Saw",
            "NormTermName": "Saw",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04715": {
            "GenartName": "Cordless Screwdriver",
            "NormTermName": "Cordless Screwdriver",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Accumulator Operation"
        },
        "04717": {
            "GenartName": "Drill Chuck",
            "NormTermName": "Drill Chuck",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Drill"
        },
        "04718": {
            "GenartName": "Cutting Disc, angle grinder",
            "NormTermName": "Cutting Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "04719": {
            "GenartName": "Grinding Disc, angle grinder",
            "NormTermName": "Grinding Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "04720": {
            "GenartName": "Funnel",
            "NormTermName": "Funnel",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Funnel"
        },
        "04721": {
            "GenartName": "Oil Filter Belt",
            "NormTermName": "Oil Filter Belt",
            "AssGrpName": "Tool, universal",
            "UsageName": "Oil Filter"
        },
        "04722": {
            "GenartName": "Spark Plug Spanner",
            "NormTermName": "Key",
            "AssGrpName": "Tool, universal",
            "UsageName": "Spark Plug Spanner"
        },
        "04723": {
            "GenartName": "Cooling Hose Clamp",
            "NormTermName": "Clamping Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cooling Hose"
        },
        "04725": {
            "GenartName": "Saw Blade",
            "NormTermName": "Saw Blade",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04726": {
            "GenartName": "Housing, side marker light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights",
            "UsageName": "Side Marker Light"
        },
        "04727": {
            "GenartName": "Housing, side marker light",
            "NormTermName": "Housing",
            "AssGrpName": "Lights, universal",
            "UsageName": "Universal Side Marker Light"
        },
        "04728": {
            "GenartName": "Warning Marking",
            "NormTermName": "Warning Marking",
            "AssGrpName": "Security Systems, universal",
            "UsageName": null
        },
        "04729": {
            "GenartName": "Warning Marking Set",
            "NormTermName": "Warning Marking Set",
            "AssGrpName": "Security Systems, universal",
            "UsageName": null
        },
        "04730": {
            "GenartName": "Auxiliary Direction Indicator",
            "NormTermName": "Light",
            "AssGrpName": "Signal System",
            "UsageName": "Auxiliary Direction Indicator"
        },
        "04731": {
            "GenartName": "Boot-/Cargo Area Tray",
            "NormTermName": "Tray",
            "AssGrpName": "Accessories",
            "UsageName": "Boot-/Cargo Area"
        },
        "04732": {
            "GenartName": "Universal Boot-/Cargo Area Tray",
            "NormTermName": "Tray",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Boot-/Cargo Area"
        },
        "04733": {
            "GenartName": "Oil",
            "NormTermName": "Oil",
            "AssGrpName": null,
            "UsageName": null
        },
        "04734": {
            "GenartName": "Bolt, oil sump",
            "NormTermName": "Screw",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Sump"
        },
        "04735": {
            "GenartName": "Mounting Set, suspension strut",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Suspension Strut"
        },
        "04736": {
            "GenartName": "Choke Cable, cold start control",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Carburettor",
            "UsageName": "Cold-start Control"
        },
        "04737": {
            "GenartName": "Balance Weight",
            "NormTermName": "Balance Weight",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Wheel"
        },
        "04738": {
            "GenartName": "Thrust Roller, release fork",
            "NormTermName": "Pulley",
            "AssGrpName": "Clutch",
            "UsageName": "Release Fork"
        },
        "04739": {
            "GenartName": "Clamping Sleeve, release fork",
            "NormTermName": "Sleeve",
            "AssGrpName": "Clutch",
            "UsageName": "Release Fork"
        },
        "04740": {
            "GenartName": "Screw, clutch lever",
            "NormTermName": "Screw",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Lever"
        },
        "04741": {
            "GenartName": "Screw, releaser shaft",
            "NormTermName": "Screw",
            "AssGrpName": "Clutch",
            "UsageName": "Releaser Shaft"
        },
        "04742": {
            "GenartName": "Diagnosis Unit, air conditioning compressor",
            "NormTermName": "Diagnosis Unit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Air Conditioning Compressor"
        },
        "04743": {
            "GenartName": "Plug Housing, automatic transmission control unit",
            "NormTermName": "Connector",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission Control Unit"
        },
        "04744": {
            "GenartName": "Power Socket",
            "NormTermName": "Socket Insert",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Impact Wrench"
        },
        "04748": {
            "GenartName": "Cutting Pliers",
            "NormTermName": "Cutting Pliers",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04751": {
            "GenartName": "Abrasive Sleeve, straight grinder",
            "NormTermName": "Abrasive Sleeve",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Straight Grinder"
        },
        "04752": {
            "GenartName": "Grinding Disc, straight grinder",
            "NormTermName": "Grinding Disc",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Straight Grinder"
        },
        "04753": {
            "GenartName": "Chisel, chisel hammer",
            "NormTermName": "Chisel",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Chisel Hammer"
        },
        "04754": {
            "GenartName": "Mounted Point, straight grinder",
            "NormTermName": "Mounted Point",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Straight Grinder"
        },
        "04755": {
            "GenartName": "Support Plate, straight grinder",
            "NormTermName": "Support Plate",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Straight Grinder"
        },
        "04756": {
            "GenartName": "Tool Trolley",
            "NormTermName": "Tool Trolley",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Tools"
        },
        "04757": {
            "GenartName": "Toolbox",
            "NormTermName": "Case",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Tools"
        },
        "04758": {
            "GenartName": "Sandblasting Gun",
            "NormTermName": "Sandblasting Gun",
            "AssGrpName": "Machining Equipment",
            "UsageName": null
        },
        "04759": {
            "GenartName": "Connector, compressed air line",
            "NormTermName": "Connector",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Hoses, compressed air"
        },
        "04760": {
            "GenartName": "Gas Shielded Welding Machine",
            "NormTermName": "Welding Machine",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Gas Shielded Welding Machine"
        },
        "04761": {
            "GenartName": "Air Compressor",
            "NormTermName": "Compressor",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Air Compressor"
        },
        "04763": {
            "GenartName": "Spray Gun",
            "NormTermName": "Spray Gun",
            "AssGrpName": "Machining Equipment",
            "UsageName": null
        },
        "04764": {
            "GenartName": "Cable Spool",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Cable Spool"
        },
        "04765": {
            "GenartName": "Universal Can",
            "NormTermName": "Tank",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Universal Can"
        },
        "04766": {
            "GenartName": "Thrust Piece Set, press in/out tool",
            "NormTermName": "Thrust Piece Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Press-in-/Extraction Tools"
        },
        "04768": {
            "GenartName": "Belt Pulley Set, crankshaft",
            "NormTermName": "Belt Pulley Set",
            "AssGrpName": "Belt Drive",
            "UsageName": "Crankshaft"
        },
        "04770": {
            "GenartName": "Flap Disc",
            "NormTermName": "Flap Wheel Grinder",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04771": {
            "GenartName": "Connecting Hose, brake pressure tester",
            "NormTermName": "Hose Line",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Brake Pressure Tester"
        },
        "04772": {
            "GenartName": "Extracting Tool, thread insert",
            "NormTermName": "Extracting Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Thread Insert"
        },
        "04773": {
            "GenartName": "Seat Cover",
            "NormTermName": "Seat Cover",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04774": {
            "GenartName": "Air Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Air Pump"
        },
        "04775": {
            "GenartName": "Seal, fuel tank cap",
            "NormTermName": "Seal",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Fuel Tank Cap"
        },
        "04776": {
            "GenartName": "Cover, wheels",
            "NormTermName": "Cover",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Wheel Cover"
        },
        "04777": {
            "GenartName": "Locking Knob",
            "NormTermName": "Knob",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Locking Knob"
        },
        "04778": {
            "GenartName": "Window Crank",
            "NormTermName": "Crank",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": null
        },
        "04779": {
            "GenartName": "Axle Gear Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Axle Gear"
        },
        "04780": {
            "GenartName": "Ice Scraper",
            "NormTermName": "Ice Scraper",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04781": {
            "GenartName": "Battery Acid",
            "NormTermName": "Battery Acid",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "04782": {
            "GenartName": "Adapter, fuse",
            "NormTermName": "Adaptor",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Fuse"
        },
        "04783": {
            "GenartName": "Spare Can",
            "NormTermName": "Tank",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Spare Can"
        },
        "04784": {
            "GenartName": "Contact Spray",
            "NormTermName": "Contact Spray",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "04785": {
            "GenartName": "Distilled Water",
            "NormTermName": "Distilled Water",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "04786": {
            "GenartName": "Fire Extinguisher",
            "NormTermName": "Fire Extinguisher",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04787": {
            "GenartName": "Hose, tyre",
            "NormTermName": "Hose",
            "AssGrpName": "Wheels, universal",
            "UsageName": null
        },
        "04788": {
            "GenartName": "Trim/Protection Strip, roof",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Body Parts, universal",
            "UsageName": null
        },
        "04789": {
            "GenartName": "Sponge",
            "NormTermName": "Sponge",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04790": {
            "GenartName": "Sensor, battery management",
            "NormTermName": "Sensor",
            "AssGrpName": "Central Electrics",
            "UsageName": "Battery Management"
        },
        "04791": {
            "GenartName": "Steering Wheel Cover",
            "NormTermName": "Steering Wheel Cover",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04792": {
            "GenartName": "Tool Bag",
            "NormTermName": "Bag",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Tools"
        },
        "04793": {
            "GenartName": "Protective Glove",
            "NormTermName": "Protective Glove",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Work Clothing"
        },
        "04794": {
            "GenartName": "Tape Measure",
            "NormTermName": "Tape Measure",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04795": {
            "GenartName": "Child Seat",
            "NormTermName": "Child Seat",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04796": {
            "GenartName": "Cap, tyre valve",
            "NormTermName": "Cover",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Tyre valve"
        },
        "04797": {
            "GenartName": "Steering Aid, (steering wheel knob/fork)",
            "NormTermName": "Steering Aid",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Steering Aid, (steering wheel knob/fork)"
        },
        "04798": {
            "GenartName": "Paint Polish Set",
            "NormTermName": "Paint Polish Set",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "04799": {
            "GenartName": "Attachment Set, gas soldering torch",
            "NormTermName": "Attachment Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Gas Soldering Torch"
        },
        "04800": {
            "GenartName": "Parking Warning Plate",
            "NormTermName": "Warning Marking",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Parking Warning Plate"
        },
        "04801": {
            "GenartName": "Relay Socket",
            "NormTermName": "Relay Socket",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Relay"
        },
        "04802": {
            "GenartName": "Tweezer",
            "NormTermName": "Tweezer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "04803": {
            "GenartName": "Tension Strap",
            "NormTermName": "Belt",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Tension Strap"
        },
        "04804": {
            "GenartName": "Neck Pillow",
            "NormTermName": "Neck Pillow",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "04805": {
            "GenartName": "Gear Lever Gaiter",
            "NormTermName": "Bellow",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Gear Lever"
        },
        "04806": {
            "GenartName": "Cover, tow hook",
            "NormTermName": "Cover",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Tow Hook"
        },
        "04807": {
            "GenartName": "Repair Pipe, soot/particulate filter",
            "NormTermName": "Tube",
            "AssGrpName": "Exhaust System",
            "UsageName": "Soot/Particulate Filter"
        },
        "04808": {
            "GenartName": "Pressure Transducer, suction pipe",
            "NormTermName": "Pressure Converter",
            "AssGrpName": "Air Supply",
            "UsageName": "Intake Manifold"
        },
        "04809": {
            "GenartName": "Repair Kit, cable set",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Lights, universal",
            "UsageName": "Cable Set"
        },
        "04810": {
            "GenartName": "Repair Kit, cable set",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Cable Set"
        },
        "04811": {
            "GenartName": "Hydraulic Oil, convertible top system",
            "NormTermName": "Oil",
            "AssGrpName": "Body",
            "UsageName": "Convertible Top System"
        },
        "04812": {
            "GenartName": "Oil, power take-off",
            "NormTermName": "Oil",
            "AssGrpName": "Power Take-Off",
            "UsageName": null
        },
        "04813": {
            "GenartName": "Sensor, eccentric shaft (variable valve lift)",
            "NormTermName": "Sensor",
            "AssGrpName": "Engine Timing",
            "UsageName": "Exenter Shaft (variable valve lift)"
        },
        "04814": {
            "GenartName": "Head Up Display",
            "NormTermName": "Head Up Display",
            "AssGrpName": "Instruments, universal",
            "UsageName": null
        },
        "04815": {
            "GenartName": "Headlight Assistance System",
            "NormTermName": "Headlight Assistance System",
            "AssGrpName": "Lights, universal",
            "UsageName": null
        },
        "04816": {
            "GenartName": "Lane Departure Warning System",
            "NormTermName": "Lane Departure Warning System",
            "AssGrpName": "Security Systems, universal",
            "UsageName": null
        },
        "04817": {
            "GenartName": "Rear Windscreen Frame",
            "NormTermName": "Frame",
            "AssGrpName": "Body",
            "UsageName": "Rear Windscreen Frame"
        },
        "04818": {
            "GenartName": "Windscreen Frame Set",
            "NormTermName": "Frame Set",
            "AssGrpName": "Body",
            "UsageName": "Windscreen Frame"
        },
        "04819": {
            "GenartName": "Rear Windscreen Frame Set",
            "NormTermName": "Frame Set",
            "AssGrpName": "Body",
            "UsageName": "Rear Windscreen Frame"
        },
        "04820": {
            "GenartName": "Retaining Clip Set, glass installation",
            "NormTermName": "Retaining Clip Set",
            "AssGrpName": "Body",
            "UsageName": "Window Fitting"
        },
        "04821": {
            "GenartName": "Fastening Set, glass installation",
            "NormTermName": "Holder Set",
            "AssGrpName": "Body",
            "UsageName": "Window Fitting"
        },
        "04822": {
            "GenartName": "Lock Mandrel, balancer shaft",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Balance Shaft"
        },
        "04823": {
            "GenartName": "Puller, belt pulley",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Belt Pulley"
        },
        "04824": {
            "GenartName": "Die Drill, lambda sensor",
            "NormTermName": "Thread Tap",
            "AssGrpName": "Tool, universal",
            "UsageName": "Lambda Sensor"
        },
        "04825": {
            "GenartName": "Bearing Bracket, release fork",
            "NormTermName": "Bracket",
            "AssGrpName": "Clutch",
            "UsageName": "Release Fork"
        },
        "04826": {
            "GenartName": "Clockspring, airbag",
            "NormTermName": "Clockspring",
            "AssGrpName": "Safety Systems",
            "UsageName": "Airbag"
        },
        "04827": {
            "GenartName": "Bush, steering arm",
            "NormTermName": "Bush",
            "AssGrpName": "Steering",
            "UsageName": "Steering Arm"
        },
        "04828": {
            "GenartName": "Oil Jet, timing chain",
            "NormTermName": "Nozzle",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain Lubrication"
        },
        "04829": {
            "GenartName": "Solenoid Valve, retarder",
            "NormTermName": "Valve",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "04830": {
            "GenartName": "Mounting Set, valve - driver cab suspension",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Driver Cab",
            "UsageName": "Valve - driver cab suspension"
        },
        "04831": {
            "GenartName": "Mounting, differential",
            "NormTermName": "Mounting",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "04832": {
            "GenartName": "Hub, engine cooling fan wheel",
            "NormTermName": "Hub",
            "AssGrpName": "Cooling System",
            "UsageName": "Engine cooling fan wheel"
        },
        "04833": {
            "GenartName": "Torque Wrench",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tightening Torque"
        },
        "04834": {
            "GenartName": "Ejector, control arm bushing",
            "NormTermName": "Ejector",
            "AssGrpName": "Tool, universal",
            "UsageName": "Control Arm"
        },
        "04835": {
            "GenartName": "Hub, camshaft gear",
            "NormTermName": "Hub",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Gear"
        },
        "04836": {
            "GenartName": "Adapter, pressure switch",
            "NormTermName": "Adaptor",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Pressure Switch"
        },
        "04837": {
            "GenartName": "Oil Hose, clutch release bearing",
            "NormTermName": "Hose",
            "AssGrpName": "Clutch",
            "UsageName": "Releaser Lubrication"
        },
        "04838": {
            "GenartName": "Repair Set, cardan shaft centre bearing",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft Centre Bearing"
        },
        "04839": {
            "GenartName": "Bolt, axle beam mounting",
            "NormTermName": "Screw",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Bushing, axle beam"
        },
        "04840": {
            "GenartName": "Bolt, control arm",
            "NormTermName": "Screw",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Control Arm"
        },
        "04841": {
            "GenartName": "Guide Sleeve, axle beam mounting",
            "NormTermName": "Sleeve",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Beam"
        },
        "04842": {
            "GenartName": "Mounting Kit, stabiliser bar",
            "NormTermName": "Bearing Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser"
        },
        "04843": {
            "GenartName": "Bearing Set, axle beam",
            "NormTermName": "Bearing Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Beam"
        },
        "04844": {
            "GenartName": "Holder, parking assist sensor",
            "NormTermName": "Holder",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Assist Sensor"
        },
        "04845": {
            "GenartName": "Holder, parking assist sensor",
            "NormTermName": "Holder",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Assist Sensor"
        },
        "04846": {
            "GenartName": "Spacer, glass fitting",
            "NormTermName": "Spacer",
            "AssGrpName": "Body",
            "UsageName": "Window Fitting"
        },
        "04847": {
            "GenartName": "Anti-fog Sensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Windscreen"
        },
        "04848": {
            "GenartName": "Seal, plenum chamber",
            "NormTermName": "Seal",
            "AssGrpName": "Body",
            "UsageName": "Plenum Chamber"
        },
        "04849": {
            "GenartName": "Urea Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea"
        },
        "04850": {
            "GenartName": "Sealing Cap, tank unit (Urea injection)",
            "NormTermName": "Closure",
            "AssGrpName": "Exhaust System",
            "UsageName": "Tank Unit (urea injection)"
        },
        "04851": {
            "GenartName": "Rear Window Decal",
            "NormTermName": "Emblem",
            "AssGrpName": "Body",
            "UsageName": "Rear Window"
        },
        "04852": {
            "GenartName": "Filler Pipe, tank unit (urea injection)",
            "NormTermName": "Filler Neck",
            "AssGrpName": "Exhaust System",
            "UsageName": "Tank Unit (urea injection)"
        },
        "04853": {
            "GenartName": "Hose Connector, urea hose",
            "NormTermName": "Connector",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea Hose"
        },
        "04855": {
            "GenartName": "Optical Element, rain sensor",
            "NormTermName": "Optical Element",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Rain Sensor"
        },
        "04856": {
            "GenartName": "Seal Set, rear window",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Body",
            "UsageName": "Rear Window"
        },
        "04857": {
            "GenartName": "Rivet Set, window fitting",
            "NormTermName": "Rivet Set",
            "AssGrpName": "Body",
            "UsageName": "Window Fitting"
        },
        "04858": {
            "GenartName": "Position Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights",
            "UsageName": "Position Light"
        },
        "04859": {
            "GenartName": "Position Light",
            "NormTermName": "Light",
            "AssGrpName": "Lights, universal",
            "UsageName": "Position Light"
        },
        "04860": {
            "GenartName": "Torque Wrench, spark plug",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": "Spark Plug Tightening Torque"
        },
        "04861": {
            "GenartName": "Torque Wrench, spark plug",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spark Plug Tightening Torque"
        },
        "04862": {
            "GenartName": "Mounting Tool Set, intake/exhaust valve",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Intake/Exhaust Valve"
        },
        "04863": {
            "GenartName": "Pliers, valve stem seal",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Valve Stem Seal"
        },
        "04864": {
            "GenartName": "Grinding Tool Set, valve seat",
            "NormTermName": "Grinding Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Valve Seat"
        },
        "04865": {
            "GenartName": "Grinding Tool Set, valve seat",
            "NormTermName": "Grinding Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Valve Seat"
        },
        "04866": {
            "GenartName": "Drill Out Set, injector holding screw",
            "NormTermName": "Drill Out Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Injector Holding Screw"
        },
        "04867": {
            "GenartName": "Drill Out Set, injector holding screw",
            "NormTermName": "Drill Out Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector Holding Screw"
        },
        "04868": {
            "GenartName": "Adjustment Gauge , camshaft",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": "Camshaft Alignment"
        },
        "04869": {
            "GenartName": "Additive, turbocharger (first fill)",
            "NormTermName": "Additive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Turbocharger First Fill"
        },
        "04870": {
            "GenartName": "Puller Set, belt pulley",
            "NormTermName": "Puller Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Belt Pulley"
        },
        "04871": {
            "GenartName": "Puller Set, belt pulley",
            "NormTermName": "Puller Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Belt Pulley"
        },
        "04872": {
            "GenartName": "Screw Set, cylinder head cover",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Cylinder Head Cover"
        },
        "04873": {
            "GenartName": "Mounting Device, engine/ transmission unit",
            "NormTermName": "Mounting Device",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Engine/ Transmission Unit"
        },
        "04874": {
            "GenartName": "Engine Cranking Device",
            "NormTermName": "Crank Device",
            "AssGrpName": "Tool, universal",
            "UsageName": "Engine"
        },
        "04875": {
            "GenartName": "Engine Cranking Device",
            "NormTermName": "Crank Device",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Engine"
        },
        "04876": {
            "GenartName": "Retaining Tool, timing belt tensioner pulley",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Timing Belt Tensioner Pulley"
        },
        "04877": {
            "GenartName": "Support Rail, water box cover",
            "NormTermName": "Support Rail",
            "AssGrpName": "Body",
            "UsageName": "Windscreen"
        },
        "04878": {
            "GenartName": "Grinding Machine, crankshaft",
            "NormTermName": "Grinding Machine",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft"
        },
        "04879": {
            "GenartName": "Spring Compressor, suspension spring",
            "NormTermName": "Spring Compressor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Suspension Spring"
        },
        "04880": {
            "GenartName": "Alignment Tools, caster/camber adjustment",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Caster/Camber Adjustment"
        },
        "04881": {
            "GenartName": "Alignment Tools, caster/camber adjustment",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Caster/Camber Adjustment"
        },
        "04882": {
            "GenartName": "Puller, wiper arm",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wiper Arm"
        },
        "04883": {
            "GenartName": "Puller, wiper arm",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wiper Arm"
        },
        "04884": {
            "GenartName": "Diagnosis Unit, short circuit cause",
            "NormTermName": "Diagnosis Unit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Short Circuit Cause"
        },
        "04885": {
            "GenartName": "Grease Spray",
            "NormTermName": "Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Grease Spray"
        },
        "04886": {
            "GenartName": "Seal Ring Assortment, injector",
            "NormTermName": "Assortment",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Seal Ring"
        },
        "04887": {
            "GenartName": "Conservation Wax",
            "NormTermName": "Preservative",
            "AssGrpName": "Chemical Products",
            "UsageName": "Conservation Wax"
        },
        "04888": {
            "GenartName": "Seal Ring Assortment, injector",
            "NormTermName": "Assortment",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Injector Seal Ring"
        },
        "04889": {
            "GenartName": "Testing Device, exhaust turbocharger",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Exhaust Turbocharger"
        },
        "04890": {
            "GenartName": "Oil Filler Funnel",
            "NormTermName": "Funnel",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Oil Filler Funnel"
        },
        "04891": {
            "GenartName": "Adapter, grease cartridge refill",
            "NormTermName": "Adaptor",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Grease Cartridge Refill"
        },
        "04892": {
            "GenartName": "Adapter, grease cartridge",
            "NormTermName": "Adaptor",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Grease Cartridge"
        },
        "04893": {
            "GenartName": "Adapter Set, grease cartridge",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Grease Cartridge"
        },
        "04894": {
            "GenartName": "Particle Sensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Particulate"
        },
        "04895": {
            "GenartName": "Grease Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Grease Pump"
        },
        "04896": {
            "GenartName": "Grease Sequence Piston, grease pump",
            "NormTermName": "Piston",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Grease Pump"
        },
        "04897": {
            "GenartName": "Container Lid, (grease pump)",
            "NormTermName": "Lid",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Container (grease pump)"
        },
        "04898": {
            "GenartName": "Crankshaft, air compressor",
            "NormTermName": "Shaft",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "04899": {
            "GenartName": "Connecting Cable, high pressure pump",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Mixture Formation",
            "UsageName": "High Pressure Pump"
        },
        "04900": {
            "GenartName": "Connecting Cable, injector",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector"
        },
        "04901": {
            "GenartName": "Connecting Cable, valve unit (rail)",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Valve Unit (Rail)"
        },
        "04902": {
            "GenartName": "Cable Pull, belt tensioner arm",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Belt Drive",
            "UsageName": "Belt Tensioner"
        },
        "04903": {
            "GenartName": "Square Key, instrument cluster",
            "NormTermName": "Square Key",
            "AssGrpName": "Tool, universal",
            "UsageName": "Instrument Cluster"
        },
        "04904": {
            "GenartName": "Gear, countershaft",
            "NormTermName": "Gear",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Countershaft"
        },
        "04905": {
            "GenartName": "Gear, main shaft",
            "NormTermName": "Gear",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Main Shaft"
        },
        "04906": {
            "GenartName": "Solenoid Valve, air conditioning",
            "NormTermName": "Valve",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Air Conditioning"
        },
        "04907": {
            "GenartName": "Tool Box",
            "NormTermName": "Tool Box",
            "AssGrpName": "Driver Cab",
            "UsageName": "Tools"
        },
        "04908": {
            "GenartName": "Holder Set, tool box",
            "NormTermName": "Holder Set",
            "AssGrpName": "Driver Cab",
            "UsageName": "Tool Box"
        },
        "04909": {
            "GenartName": "Paper Roll, battery tester",
            "NormTermName": "Pulley",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Battery Tester"
        },
        "04910": {
            "GenartName": "Trim Cover, tail light",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Lights",
            "UsageName": "Tail Light"
        },
        "04911": {
            "GenartName": "Trim Cover, direction indicator",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Lights",
            "UsageName": "Direction Indicator"
        },
        "04912": {
            "GenartName": "Trim Cover, reverse light",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Lights",
            "UsageName": "Reverse Light"
        },
        "04913": {
            "GenartName": "Return Pipe, shock absorber",
            "NormTermName": "Hose Line",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shock Absorber"
        },
        "04914": {
            "GenartName": "Adapter, crimping pliers",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Crimping Pliers"
        },
        "04915": {
            "GenartName": "Plug Housing Set",
            "NormTermName": "Connector",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Plug Housing Set"
        },
        "04916": {
            "GenartName": "Leakage Detector, air conditioning",
            "NormTermName": "Leak Location",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Air Conditioning"
        },
        "04917": {
            "GenartName": "Compressed Air Oiler",
            "NormTermName": "Compressed Air Oiler",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Compressed Air"
        },
        "04918": {
            "GenartName": "Screw Plug, transmission housing",
            "NormTermName": "Screw",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Transmission Housing"
        },
        "04919": {
            "GenartName": "Holder, oil sump fastening",
            "NormTermName": "Holder",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil sump, automatic transmission"
        },
        "04920": {
            "GenartName": "Torsional Damper, flywheel",
            "NormTermName": "Torsion Damper",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Flywheel"
        },
        "04921": {
            "GenartName": "Parts kit, automatic transmission oil change",
            "NormTermName": "Parts Set",
            "AssGrpName": "Service/Maintenance",
            "UsageName": "Automatic transmission oil change"
        },
        "04922": {
            "GenartName": "Housing, automatic transmission hydraulic filter",
            "NormTermName": "Housing",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Hydraulic Filter"
        },
        "04924": {
            "GenartName": "Magnet, automatic transmission oil sump",
            "NormTermName": "Magnet",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil sump, automatic transmission"
        },
        "04925": {
            "GenartName": "Assortment, radiator small parts",
            "NormTermName": "Assortment",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Radiator Small Parts"
        },
        "04926": {
            "GenartName": "Bearing, automatic transmission",
            "NormTermName": "Bearing",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "04927": {
            "GenartName": "Angle Correction Ring, sensor (parking distance control)",
            "NormTermName": "Angle Correction Ring",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Assist Sensor"
        },
        "04928": {
            "GenartName": "Angle Correction Ring, sensor (parking distance control)",
            "NormTermName": "Angle Correction Ring",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Assist Sensor"
        },
        "04929": {
            "GenartName": "Kingpin, axle beam",
            "NormTermName": "Kingpin",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Beam"
        },
        "04930": {
            "GenartName": "Repair Kit, kingpin (axle beam)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Kingpin (axle beam)"
        },
        "04931": {
            "GenartName": "Mounting Tool Set, kingpin (axle beam)",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Kingpin (axle beam)"
        },
        "04932": {
            "GenartName": "Control Element, parking brake caliper",
            "NormTermName": "Actuator",
            "AssGrpName": "Braking System",
            "UsageName": "Parking brake brake caliper"
        },
        "04933": {
            "GenartName": "Adapter Cable, air supply control flap",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Air Supply",
            "UsageName": "Air Supply Control Flap"
        },
        "04934": {
            "GenartName": "Reinforcement Set, leaf spring",
            "NormTermName": "Booster Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leaf Spring"
        },
        "04935": {
            "GenartName": "Low Temperature Cooler, charge air cooler",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Air Supply",
            "UsageName": "Charge Air Cooler"
        },
        "04936": {
            "GenartName": "Bike Holder, interior",
            "NormTermName": "Bicycle Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Interior"
        },
        "04937": {
            "GenartName": "Bike Holder, interior",
            "NormTermName": "Bicycle Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Interior"
        },
        "04938": {
            "GenartName": "Air Jack",
            "NormTermName": "Air Jack",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04940": {
            "GenartName": "Underrun Protection, towbar",
            "NormTermName": "Underrun Protection",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "04941": {
            "GenartName": "Heat Shield, ignition coil",
            "NormTermName": "Heat Shield",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition Coil"
        },
        "04942": {
            "GenartName": "Fifth Wheel Coupling",
            "NormTermName": "Fifth Wheel Coupling",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "04943": {
            "GenartName": "Wear Ring, fifth wheel coupling",
            "NormTermName": "Wear Ring",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Fifth Wheel Coupling"
        },
        "04944": {
            "GenartName": "Mounting Bolt Set, fifth wheel coupling",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Fifth Wheel Coupling Mounting"
        },
        "04945": {
            "GenartName": "Repair Set, fifth wheel coupling",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Fifth Wheel Coupling"
        },
        "04946": {
            "GenartName": "Screw, waterpump",
            "NormTermName": "Screw",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "04947": {
            "GenartName": "Ignition Cable Kit",
            "NormTermName": "Electric Cable Set",
            "AssGrpName": "Ignition System, universal",
            "UsageName": null
        },
        "04948": {
            "GenartName": "Seal Ring Set, injection valve",
            "NormTermName": "Seal Ring Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector"
        },
        "04949": {
            "GenartName": "Seal Ring, cylinder liner",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Crankcase",
            "UsageName": "Cylinder Sleeve"
        },
        "04950": {
            "GenartName": "Connector Cable, trailer",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Trailer"
        },
        "04951": {
            "GenartName": "Extension Cable, trailer socket",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Towbar Socket"
        },
        "04952": {
            "GenartName": "Adapter Cable, towbar socket",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Towbar Socket"
        },
        "04953": {
            "GenartName": "Towbar Socket",
            "NormTermName": "Socket",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Trailer"
        },
        "04954": {
            "GenartName": "Assortment, squeeze connectors",
            "NormTermName": "Assortment",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Squeeze Connection"
        },
        "04955": {
            "GenartName": "Assortment, plug housings",
            "NormTermName": "Assortment",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Plug Housing"
        },
        "04956": {
            "GenartName": "Assortment, hose connectors",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Hose Connector"
        },
        "04957": {
            "GenartName": "Assortment, fuses",
            "NormTermName": "Assortment",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Fuse"
        },
        "04958": {
            "GenartName": "Assortment, automatic circuit breakers",
            "NormTermName": "Assortment",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Automatic Circuit Breakers"
        },
        "04959": {
            "GenartName": "Assortment, protective plugs and caps",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Sealing-/Protection Plugs"
        },
        "04960": {
            "GenartName": "Pressure Pipe, pressure sensor (soot/particulate filter)",
            "NormTermName": "Pipe",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Pressure Sensor (soot/particulate filter)"
        },
        "04961": {
            "GenartName": "Deep Cycle Battery",
            "NormTermName": "Battery",
            "AssGrpName": "Central Electrics",
            "UsageName": "Deep Cycle Battery"
        },
        "04963": {
            "GenartName": "Driver Assistance System",
            "NormTermName": "Driver Assistance System",
            "AssGrpName": "Security Systems, universal",
            "UsageName": null
        },
        "04964": {
            "GenartName": "Door-open Alarm",
            "NormTermName": "Door-open Alarm",
            "AssGrpName": "Safety Systems",
            "UsageName": null
        },
        "04965": {
            "GenartName": "NOx Sensor, NOx catalytic converter",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "NOx Catalytic Converter"
        },
        "04966": {
            "GenartName": "Tyre-Pressure Monitoring System",
            "NormTermName": "Tyre-Pressure Monitoring System",
            "AssGrpName": "Wheels",
            "UsageName": null
        },
        "04967": {
            "GenartName": "Multifunctional Relay",
            "NormTermName": "Relay",
            "AssGrpName": "Central Electrics",
            "UsageName": "Multi-function"
        },
        "04969": {
            "GenartName": "Screw Set, alternator regulator",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Alternator",
            "UsageName": "Alternator Regulator"
        },
        "04970": {
            "GenartName": "Screw Set, alternator",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "04971": {
            "GenartName": "Repair Set, alternator stator",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Alternator",
            "UsageName": "Alternator Stator"
        },
        "04972": {
            "GenartName": "Repair Set, alternator rectifier",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Alternator",
            "UsageName": "Alternator Rectifier"
        },
        "04973": {
            "GenartName": "CHRA Cartridge, charger",
            "NormTermName": "CHRA Cartridge",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger"
        },
        "04974": {
            "GenartName": "Repair Kit, charger",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger"
        },
        "04975": {
            "GenartName": "Bearing Set, automatic transmission",
            "NormTermName": "Bearing Kit",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "04976": {
            "GenartName": "Test Lead Set",
            "NormTermName": "Tool Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04977": {
            "GenartName": "SRS Plug, test resistor",
            "NormTermName": "Plug",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Test Resistor"
        },
        "04978": {
            "GenartName": "Polarity Tester",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "04979": {
            "GenartName": "Cable Pull, automatic transmission",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Gearshift"
        },
        "04980": {
            "GenartName": "Bearing, transfer case",
            "NormTermName": "Bearing",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transfer Case"
        },
        "04981": {
            "GenartName": "Bearing, differential",
            "NormTermName": "Bearing",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "04982": {
            "GenartName": "Repair Set, alternator clamp",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Alternator",
            "UsageName": "Alternator Clamp"
        },
        "04983": {
            "GenartName": "Repair Set, starter clamp",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Starter System",
            "UsageName": "Starter Clamp"
        },
        "04984": {
            "GenartName": "Repair Set, engager (starter)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Starter System",
            "UsageName": "Engager"
        },
        "04985": {
            "GenartName": "Repair Kit, drive shaft (starter)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Starter System",
            "UsageName": "Drive Shaft"
        },
        "04986": {
            "GenartName": "Mounting Set, alternator rotor",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Alternator",
            "UsageName": "Alternator Rotor"
        },
        "04987": {
            "GenartName": "Fastening Set, belt pulley (alternator)",
            "NormTermName": "Fastening Set",
            "AssGrpName": "Alternator",
            "UsageName": "Belt Pulley"
        },
        "04988": {
            "GenartName": "Bearing Inner Ring",
            "NormTermName": "Bearing Inner Ring",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "04989": {
            "GenartName": "Isolator Disc, alternator clamp",
            "NormTermName": "Insulator",
            "AssGrpName": "Alternator",
            "UsageName": "Alternator Clamp"
        },
        "04990": {
            "GenartName": "Cap, cable gland",
            "NormTermName": "Cap",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Screwed Cable Gland"
        },
        "04991": {
            "GenartName": "Locking Pliers, bleeder screw/valve",
            "NormTermName": "Vise-grip Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bleeder Screw/Valve"
        },
        "04992": {
            "GenartName": "Socket Insert, housing",
            "NormTermName": "Bush Insert",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "04993": {
            "GenartName": "Plug Insert, housing",
            "NormTermName": "Plug Insert",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "04994": {
            "GenartName": "Housing",
            "NormTermName": "Housing",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "04995": {
            "GenartName": "Tuning Module, engine performance increase",
            "NormTermName": "Tuning Module",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Engine Power Increase"
        },
        "04996": {
            "GenartName": "Cap, starter relay",
            "NormTermName": "Cap",
            "AssGrpName": "Starter System",
            "UsageName": "Relay"
        },
        "04997": {
            "GenartName": "Ring Gear, planetary gear (starter)",
            "NormTermName": "Ring Gear",
            "AssGrpName": "Starter System",
            "UsageName": "Planetary Gear, Starter"
        },
        "04998": {
            "GenartName": "Fastening Element, engine cover",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Engine Cover"
        },
        "04999": {
            "GenartName": "Spacer Sleeve, wheel bearing",
            "NormTermName": "Sleeve",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Bearing"
        },
        "05000": {
            "GenartName": "Sealing Cover, steering arm shaft",
            "NormTermName": "Closure",
            "AssGrpName": "Steering",
            "UsageName": "Steering Arm Shaft"
        },
        "05001": {
            "GenartName": "Screw, injection nozzle holder",
            "NormTermName": "Screw",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Nozzle Holder"
        },
        "05002": {
            "GenartName": "Sliding Disc, steering arm shaft",
            "NormTermName": "Sliding Disc",
            "AssGrpName": "Steering",
            "UsageName": "Steering Arm Shaft"
        },
        "05003": {
            "GenartName": "Attachment Parts Set, thermostat housing",
            "NormTermName": "Custom Set",
            "AssGrpName": "Cooling System",
            "UsageName": "Thermostat Housing"
        },
        "05004": {
            "GenartName": "Spacer Sleeve, suspension strut support mount",
            "NormTermName": "Sleeve",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Suspension Strut Support Mount"
        },
        "05005": {
            "GenartName": "Bearing, water pump shaft",
            "NormTermName": "Bearing",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump Shaft"
        },
        "05006": {
            "GenartName": "Gasket, brake camshaft cover",
            "NormTermName": "Seal",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Camshaft"
        },
        "05007": {
            "GenartName": "Cover, brake camshaft",
            "NormTermName": "Cover",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Camshaft"
        },
        "05008": {
            "GenartName": "Bellow, brake camshaft",
            "NormTermName": "Bellow",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Camshaft"
        },
        "05009": {
            "GenartName": "Slave Cylinder, seat adjustment",
            "NormTermName": "Cylinder",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Seat Adjustment"
        },
        "05010": {
            "GenartName": "Gasket, charge air cooler",
            "NormTermName": "Seal",
            "AssGrpName": "Air Supply",
            "UsageName": "Charge Air Cooler"
        },
        "05011": {
            "GenartName": "Screw Set, gears (timing chain)",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": "Gears (timing chain)"
        },
        "05012": {
            "GenartName": "Bushing, bonnet leaf spring",
            "NormTermName": "Bush",
            "AssGrpName": "Body",
            "UsageName": "Leaf Spring"
        },
        "05013": {
            "GenartName": "Gear Set, engine timing",
            "NormTermName": "Gear Set",
            "AssGrpName": "Engine Timing",
            "UsageName": null
        },
        "05015": {
            "GenartName": "Adapter, oil pressure switch",
            "NormTermName": "Adaptor",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pressure Switch"
        },
        "05016": {
            "GenartName": "Adapter, stop light switch",
            "NormTermName": "Adaptor",
            "AssGrpName": "Signal System",
            "UsageName": "Stop Light Switch"
        },
        "05017": {
            "GenartName": "Crankshaft Bearing Set",
            "NormTermName": "Bearing Shell Set",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft"
        },
        "05018": {
            "GenartName": "Slip Ring, alternator",
            "NormTermName": "Slip Ring",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "05019": {
            "GenartName": "Trailer Plug",
            "NormTermName": "Plug",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Trailer"
        },
        "05020": {
            "GenartName": "Spark Plug",
            "NormTermName": "Spark Plug",
            "AssGrpName": "Ignition System, universal",
            "UsageName": null
        },
        "05021": {
            "GenartName": "Boot Sill Protector",
            "NormTermName": "Boot Sill Protector",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "05022": {
            "GenartName": "Dust Cover, brake caliper piston",
            "NormTermName": "Cap",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper Piston"
        },
        "05023": {
            "GenartName": "Mounting Sleeve Set, brake disc/-shoe",
            "NormTermName": "Mounting Sleeve Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Disc/Shoe"
        },
        "05024": {
            "GenartName": "Anti-Squeal Foil, brake pad (back plate)",
            "NormTermName": "Foil",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Pad (back plate)"
        },
        "05025": {
            "GenartName": "Wind Deflector",
            "NormTermName": "Wind Deflector",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "05026": {
            "GenartName": "Fuse Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Central Electrics",
            "UsageName": "Fuse"
        },
        "05027": {
            "GenartName": "Seal Ring, timing chain tensioner",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain Tensioner"
        },
        "05028": {
            "GenartName": "Seal, secondary air valve",
            "NormTermName": "Seal",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": "Secondary Air Valve"
        },
        "05029": {
            "GenartName": "Gas Spring, tray (boot/cargo bay)",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Tray (boot/cargo bay)"
        },
        "05030": {
            "GenartName": "Conversion Set, clutch",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "05031": {
            "GenartName": "Cooler, drive battery",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Traction Battery"
        },
        "05032": {
            "GenartName": "Gas Spring, tailboard (tailgate)",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Tailboard (tailgate)"
        },
        "05033": {
            "GenartName": "Mounting Tools Set, rack",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Plug Housing"
        },
        "05034": {
            "GenartName": "Holder, warning markings",
            "NormTermName": "Holder",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Warning Marking"
        },
        "05035": {
            "GenartName": "Holder, park warning plates",
            "NormTermName": "Holder",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Parking Warning Plate"
        },
        "05036": {
            "GenartName": "Seal Ring, oil pressure regulating valve",
            "NormTermName": "Seal",
            "AssGrpName": "Lubrication",
            "UsageName": "Regulating Valve, oil pressure"
        },
        "05037": {
            "GenartName": "Packing Plate, drive shaft flange",
            "NormTermName": "Packing Plate",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft Flange"
        },
        "05039": {
            "GenartName": "Hydraulic Filter, all-wheel-drive coupling",
            "NormTermName": "Hydraulic Filter",
            "AssGrpName": "Axle Drive",
            "UsageName": "All-Wheel-Drive Coupling"
        },
        "05040": {
            "GenartName": "Urea",
            "NormTermName": "Urea",
            "AssGrpName": "Chemical Products",
            "UsageName": "Urea"
        },
        "05041": {
            "GenartName": "Locking Bar, kingpin",
            "NormTermName": "Locking Bar",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Tractor Kingpin"
        },
        "05042": {
            "GenartName": "Lock Jaw, kingpin",
            "NormTermName": "Hook",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Tractor Kingpin"
        },
        "05043": {
            "GenartName": "Seal, trailer socket",
            "NormTermName": "Seal",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Towbar Socket"
        },
        "05044": {
            "GenartName": "Holding Plate, cable connection box",
            "NormTermName": "Retaining Plate",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Cable Junction Box"
        },
        "05045": {
            "GenartName": "Bushing, fifth wheel mounting bearers",
            "NormTermName": "Bush",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Fifth Wheel Coupling Bearing Bracket"
        },
        "05046": {
            "GenartName": "Assortment, cable entry grommets",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Cable Protecting Sleeve"
        },
        "05048": {
            "GenartName": "Holder, running board",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Running Board"
        },
        "05049": {
            "GenartName": "Cover, running board",
            "NormTermName": "Panelling",
            "AssGrpName": "Body",
            "UsageName": "Running Board"
        },
        "05050": {
            "GenartName": "Bearing Bracket, shock absorber mounting (driver cab)",
            "NormTermName": "Bracket",
            "AssGrpName": "Driver Cab",
            "UsageName": "Shock-Absorber Mounting"
        },
        "05051": {
            "GenartName": "Stub Axle, differential",
            "NormTermName": "Stub Axle",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Differential"
        },
        "05052": {
            "GenartName": "Cap, shock absorber mounting (driver cab)",
            "NormTermName": "Cap",
            "AssGrpName": "Driver Cab",
            "UsageName": "Shock-Absorber Mounting"
        },
        "05053": {
            "GenartName": "Air Filter Housing Cover",
            "NormTermName": "Lid",
            "AssGrpName": "Air Supply",
            "UsageName": "Air Filter Housing"
        },
        "05054": {
            "GenartName": "Seal Ring",
            "NormTermName": "Seal Ring",
            "AssGrpName": null,
            "UsageName": null
        },
        "05055": {
            "GenartName": "Cover, oil cooler",
            "NormTermName": "Lid",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Cooler"
        },
        "05056": {
            "GenartName": "Cable Pull, fuel filler cap/flap unit",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Body",
            "UsageName": "Fuel Filler Cap"
        },
        "05057": {
            "GenartName": "Cable Pull, fuel filler cap/flap unit",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Fuel Filler Cap"
        },
        "05058": {
            "GenartName": "Bolts, planetary gear",
            "NormTermName": "Bolt",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Planetary Gear"
        },
        "05059": {
            "GenartName": "Cable Entry Grommet",
            "NormTermName": "Grommet",
            "AssGrpName": "Standard Parts",
            "UsageName": "Cable Protecting Sleeve"
        },
        "05060": {
            "GenartName": "Retaining Clip Set, interior trim",
            "NormTermName": "Retaining Clip Set",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Interior Trim"
        },
        "05061": {
            "GenartName": "Gear Lever",
            "NormTermName": "Lever",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gearshift"
        },
        "05062": {
            "GenartName": "Drive Shaft, retarder",
            "NormTermName": "Shaft",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "05063": {
            "GenartName": "Compensation Tank, clutch",
            "NormTermName": "Tank",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch"
        },
        "05064": {
            "GenartName": "Tightening Strap, wing",
            "NormTermName": "Tightening Strap",
            "AssGrpName": "Body",
            "UsageName": "Wing"
        },
        "05065": {
            "GenartName": "Control Unit, park assistant turn-off",
            "NormTermName": "Control Unit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Assist Deactivation"
        },
        "05066": {
            "GenartName": "Mounting, wing holder",
            "NormTermName": "Mounting",
            "AssGrpName": "Body",
            "UsageName": "Wing Holder"
        },
        "05067": {
            "GenartName": "Programming/Diagnostics Device, tyre-pressure monitoring s.",
            "NormTermName": "Programming-/Diagnostic Unit",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "05068": {
            "GenartName": "Repair Set, retarder",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "05069": {
            "GenartName": "Pressure Accumulator, retarder",
            "NormTermName": "Retarder",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "05070": {
            "GenartName": "Test-/Measuring Adapter, CAN Bus System",
            "NormTermName": "Test-/Measuring Adapter",
            "AssGrpName": "Workshop Devices",
            "UsageName": null
        },
        "05071": {
            "GenartName": "Bulb Socket, electric universal parts",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "05072": {
            "GenartName": "Air Filter, retarder",
            "NormTermName": "Filter",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "05073": {
            "GenartName": "Hose Line, retarder",
            "NormTermName": "Hose Line",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "05074": {
            "GenartName": "Piston, retarder",
            "NormTermName": "Piston",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "05075": {
            "GenartName": "Pipe, retarder",
            "NormTermName": "Tube",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "05076": {
            "GenartName": "Groove Nut, retarder",
            "NormTermName": "Nut",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "05077": {
            "GenartName": "Sealing Plug, retarder",
            "NormTermName": "Screw",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "05078": {
            "GenartName": "Carrier, retarder",
            "NormTermName": "Carrier",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "05079": {
            "GenartName": "Retainer Strap, cardan shaft",
            "NormTermName": "Strap",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft"
        },
        "05080": {
            "GenartName": "Needle Rollers",
            "NormTermName": "Needle Rollers",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "05081": {
            "GenartName": "Lock Washer, axle compensation",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Compensation"
        },
        "05082": {
            "GenartName": "Planetary Wheel, outer planetary gear",
            "NormTermName": "Planetary Wheel",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Planetary Gearbox"
        },
        "05083": {
            "GenartName": "Sun Wheel, outer planetary gear",
            "NormTermName": "Sun Wheel",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Planetary Gearbox"
        },
        "05084": {
            "GenartName": "Planetary Gear Carrier",
            "NormTermName": "Rack",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Planetary Gearbox"
        },
        "05085": {
            "GenartName": "Pressure Plate, outer planetary gear",
            "NormTermName": "Pressure Disc",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Planetary Gearbox"
        },
        "05086": {
            "GenartName": "Ball Joint, stepboard (driver cab entry)",
            "NormTermName": "Joint",
            "AssGrpName": "Driver Cab",
            "UsageName": "Running Board"
        },
        "05087": {
            "GenartName": "Planetary Gear Set, outer planetary gear",
            "NormTermName": "Planetary Gear Set",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Planetary Gearbox"
        },
        "05088": {
            "GenartName": "Bush, axle spider (differential pinion gear)",
            "NormTermName": "Bush",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential Pinion Gear"
        },
        "05089": {
            "GenartName": "Nut, axle spider (differential pinion gear)",
            "NormTermName": "Nut",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential Pinion Gear"
        },
        "05090": {
            "GenartName": "Handle, king pin",
            "NormTermName": "Handle",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Tractor Kingpin"
        },
        "05091": {
            "GenartName": "Cable, sun visor light",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Sun visor"
        },
        "05093": {
            "GenartName": "Cover, centre console",
            "NormTermName": "Cover",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Centre Console"
        },
        "05094": {
            "GenartName": "Marker Pole",
            "NormTermName": "Marker Pole",
            "AssGrpName": "Body",
            "UsageName": "Vehicle Maneuvering"
        },
        "05095": {
            "GenartName": "Marker Pole",
            "NormTermName": "Marker Pole",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Vehicle Maneuvering"
        },
        "05096": {
            "GenartName": "Retaining Cable, spare wheel holder",
            "NormTermName": "Retaining Cable",
            "AssGrpName": "Body",
            "UsageName": "Spare Tyre Bracket"
        },
        "05097": {
            "GenartName": "Locking Lever, king pin",
            "NormTermName": "Locking Lever",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Tractor Kingpin"
        },
        "05098": {
            "GenartName": "Assortment, fuse holder",
            "NormTermName": "Assortment",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Fuse Holder"
        },
        "05099": {
            "GenartName": "Coupling Jaw, coupling device",
            "NormTermName": "Coupling Jaw",
            "AssGrpName": "Trailer Hitch",
            "UsageName": null
        },
        "05100": {
            "GenartName": "Protective Cap, fuse holder",
            "NormTermName": "Protective Cap/Bellow",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Fuse Holder"
        },
        "05101": {
            "GenartName": "Protective Sleeve, self-diagnosis unit",
            "NormTermName": "Protective Sleeve",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Self-diagnosis Unit"
        },
        "05102": {
            "GenartName": "Crimping Plate, air spring bellow",
            "NormTermName": "Crimping Plate",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Air Spring Bellow"
        },
        "05103": {
            "GenartName": "Switch, park brake actuation",
            "NormTermName": "Switch",
            "AssGrpName": "Braking System",
            "UsageName": "Detecting brake activation"
        },
        "05104": {
            "GenartName": "Tension Spring, fifth wheel",
            "NormTermName": "Spring",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Fifth Wheel Coupling"
        },
        "05105": {
            "GenartName": "Cable Pull, cab tilt device",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Tilt Unit"
        },
        "05106": {
            "GenartName": "Front Cover Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Driver Cab",
            "UsageName": "Front Panel"
        },
        "05107": {
            "GenartName": "Side Window Frame",
            "NormTermName": "Frame",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab"
        },
        "05108": {
            "GenartName": "Lid, storage compartment",
            "NormTermName": "Storage Compartment",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Storage Compartment"
        },
        "05109": {
            "GenartName": "Hinge, storage compartment lid",
            "NormTermName": "Hinge",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Storage Compartment Lid"
        },
        "05110": {
            "GenartName": "Screw Set, cardan shaft",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Axle Drive",
            "UsageName": "Propshaft Joint"
        },
        "05111": {
            "GenartName": "Holder, sun visor",
            "NormTermName": "Holder",
            "AssGrpName": "Driver Cab",
            "UsageName": "Sun Visor"
        },
        "05112": {
            "GenartName": "Seal Ring, steering knuckle (spring bracket)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Steering Knuckle (spring bracket)"
        },
        "05113": {
            "GenartName": "Steering Knuckle, spring bracket",
            "NormTermName": "Steering Knuckle",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bracket"
        },
        "05114": {
            "GenartName": "Cover, wheel hub",
            "NormTermName": "Lid",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Wheel Hub"
        },
        "05115": {
            "GenartName": "Repair Kit, steering knuckle (spring bracket)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Steering Knuckle (spring bracket)"
        },
        "05116": {
            "GenartName": "Stop Buffer, spring bracket",
            "NormTermName": "Buffer",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bracket"
        },
        "05117": {
            "GenartName": "Kingpin Nut, (spring bracket)",
            "NormTermName": "Nut",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Steering Knuckle (spring bracket)"
        },
        "05118": {
            "GenartName": "Nut, stabiliser bar",
            "NormTermName": "Nut",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser"
        },
        "05119": {
            "GenartName": "Seat Frame Covering",
            "NormTermName": "Panelling",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Seat Frame"
        },
        "05120": {
            "GenartName": "Holder, seat frame",
            "NormTermName": "Holder",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Seat Frame"
        },
        "05121": {
            "GenartName": "Oil Trap, differential",
            "NormTermName": "Oil Trap",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "05122": {
            "GenartName": "Adjustment Ring, differential",
            "NormTermName": "Adjustment Ring",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "05123": {
            "GenartName": "Urea Filter",
            "NormTermName": "Filter",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Urea"
        },
        "05124": {
            "GenartName": "Suppression Capacitor",
            "NormTermName": "Condenser",
            "AssGrpName": null,
            "UsageName": "Suppression"
        },
        "05125": {
            "GenartName": "Spring Unit, alternator",
            "NormTermName": "Spring Unit",
            "AssGrpName": "Alternator",
            "UsageName": "Alternator"
        },
        "05126": {
            "GenartName": "Spring, open-end coupling",
            "NormTermName": "Spring",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Jaw Coupling"
        },
        "05127": {
            "GenartName": "Holding Pin, open-end coupling",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Jaw Coupling"
        },
        "05128": {
            "GenartName": "Securing Bolt, open-end coupling",
            "NormTermName": "Bolt",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Jaw Coupling"
        },
        "05129": {
            "GenartName": "Spring Arm, open-end coupling",
            "NormTermName": "Spring Arm",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Jaw Coupling"
        },
        "05130": {
            "GenartName": "Holder, fuel filter",
            "NormTermName": "Holder",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Filter"
        },
        "05131": {
            "GenartName": "Valve, tyre-pressure monitoring system",
            "NormTermName": "Valve",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "05132": {
            "GenartName": "Wheel Sensor, tyre-pressure monitoring system",
            "NormTermName": "Sensor",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "05133": {
            "GenartName": "Repair Kit, valve (tyre-pressure monitoring system)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Valve (tyre-pressure monitoring system)"
        },
        "05134": {
            "GenartName": "Mounting, piping",
            "NormTermName": "Mounting",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Compressed Air System Piping"
        },
        "05135": {
            "GenartName": "Bolt Set, crankshaft brackets",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Mounting Bracket Crankshaft"
        },
        "05136": {
            "GenartName": "A-Pillar",
            "NormTermName": "A-Pillar",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab"
        },
        "05137": {
            "GenartName": "Threaded Sleeve, air spring bellow",
            "NormTermName": "Sleeve",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Air Spring Bellow"
        },
        "05138": {
            "GenartName": "Valve, adjustment element (throttle valve)",
            "NormTermName": "Valve",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Acuator (throttle valve)"
        },
        "05139": {
            "GenartName": "Mounting Tool, injector",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Injector"
        },
        "05140": {
            "GenartName": "Dismantling Tool, injector",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Injector"
        },
        "05141": {
            "GenartName": "Seal, brake shaft",
            "NormTermName": "Seal",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shaft"
        },
        "05142": {
            "GenartName": "Dashboard Air Nozzle",
            "NormTermName": "Nozzle",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Dashboard"
        },
        "05143": {
            "GenartName": "Dashboard Air Nozzle",
            "NormTermName": "Nozzle",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": "Dashboard"
        },
        "05144": {
            "GenartName": "Valve Tappet Set",
            "NormTermName": "Valve Tappet Set",
            "AssGrpName": "Engine Timing",
            "UsageName": null
        },
        "05145": {
            "GenartName": "Adapter Cable, electric vehicle",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Electric Vehicle"
        },
        "05146": {
            "GenartName": "Charging Cable, electric vehicle",
            "NormTermName": "Charging Cable",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Electric Vehicle"
        },
        "05147": {
            "GenartName": "Locking Washer, engine mount",
            "NormTermName": "Lock Washer",
            "AssGrpName": "Engine Mounting System",
            "UsageName": "Engine Mount"
        },
        "05148": {
            "GenartName": "Clutch, differential lock",
            "NormTermName": "Coupling",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential Lock"
        },
        "05149": {
            "GenartName": "Chain, brake caliper",
            "NormTermName": "Chain",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "05150": {
            "GenartName": "Bearing, brake caliper",
            "NormTermName": "Bearing",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "05151": {
            "GenartName": "Locking Plate, brake caliper",
            "NormTermName": "Tab Washer",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "05152": {
            "GenartName": "Brake Caliper Bridge",
            "NormTermName": "Brake Caliper Bridge",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "05153": {
            "GenartName": "Protective Pipe, brake shaft",
            "NormTermName": "Tube",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Shaft"
        },
        "05154": {
            "GenartName": "Adjuster Rod, brake caliper",
            "NormTermName": "Shaft",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "05155": {
            "GenartName": "Holder, wheel speed sensor",
            "NormTermName": "Holder",
            "AssGrpName": "Braking System",
            "UsageName": "Wheel Speed Sensor"
        },
        "05156": {
            "GenartName": "Lever, compressed air brake system caliper",
            "NormTermName": "Lever",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper"
        },
        "05157": {
            "GenartName": "Protective Cover, warning light",
            "NormTermName": "Protective Sleeve",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Warning Light"
        },
        "05158": {
            "GenartName": "Heat Shield, pipeline",
            "NormTermName": "Heat Shield",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Compressed Air System Piping"
        },
        "05159": {
            "GenartName": "Ball Joint, injector pump linkage",
            "NormTermName": "Joint",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Pump Linkage"
        },
        "05160": {
            "GenartName": "Repair Kit, crankcase ventilation",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Crankcase",
            "UsageName": "Brake Master Cylinder"
        },
        "05161": {
            "GenartName": "Pipe, oil filler neck",
            "NormTermName": "Pipe",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Oil Filler Neck"
        },
        "05162": {
            "GenartName": "Grommet, cable connector box",
            "NormTermName": "Grommet",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Cable Junction Box"
        },
        "05163": {
            "GenartName": "Connecting Cable, ABS",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Braking System, universal",
            "UsageName": "ABS"
        },
        "05164": {
            "GenartName": "Handle, spiral hose",
            "NormTermName": "Handle",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Spiral Hose"
        },
        "05165": {
            "GenartName": "Handle, spiral hose",
            "NormTermName": "Handle",
            "AssGrpName": "Compressed Air System, universal",
            "UsageName": "Spiral Hose"
        },
        "05166": {
            "GenartName": "Base Plate, trailer hitch",
            "NormTermName": "Base Plate",
            "AssGrpName": "Towbar, universal",
            "UsageName": null
        },
        "05167": {
            "GenartName": "Battery Adapter",
            "NormTermName": "Adaptor",
            "AssGrpName": "Starter System, universal",
            "UsageName": "Starter Battery"
        },
        "05168": {
            "GenartName": "Battery Terminal Shim (diameter extension)",
            "NormTermName": "Battery Terminal Shim (diameter extension)",
            "AssGrpName": "Starter System, universal",
            "UsageName": "Battery Terminal"
        },
        "05169": {
            "GenartName": "Surge Protection Unit, vehicle main current",
            "NormTermName": "Overvoltage Protector",
            "AssGrpName": "Central Electrics",
            "UsageName": null
        },
        "05170": {
            "GenartName": "Clutch Plate Set",
            "NormTermName": "Clutch Plate Set",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "05171": {
            "GenartName": "Snow Chain",
            "NormTermName": "Snow Chain",
            "AssGrpName": "Accessories",
            "UsageName": null
        },
        "05172": {
            "GenartName": "Soot/Particulate Filter Cleaning",
            "NormTermName": "Soot/Particulate Filter Cleaning",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "05173": {
            "GenartName": "Compressed Air Hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Compressed Air"
        },
        "05174": {
            "GenartName": "Remote Control, trailer socket test unit",
            "NormTermName": "Remote Control",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Trailer Socket Test Unit"
        },
        "05175": {
            "GenartName": "Surge Protection Unit, vehicle main current",
            "NormTermName": "Overvoltage Protector",
            "AssGrpName": "Electric Supply, universal",
            "UsageName": null
        },
        "05176": {
            "GenartName": "Seal Set, vacuum pump",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum Pump"
        },
        "05177": {
            "GenartName": "Crimping Pliers Insert",
            "NormTermName": "Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Crimping Pliers"
        },
        "05178": {
            "GenartName": "Crimping Pliers Set",
            "NormTermName": "Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Crimp Connection"
        },
        "05179": {
            "GenartName": "Squeeze Pliers Set",
            "NormTermName": "Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Squeeze Connection"
        },
        "05180": {
            "GenartName": "Surround Light",
            "NormTermName": "Surround Light",
            "AssGrpName": "Lights, universal",
            "UsageName": null
        },
        "05181": {
            "GenartName": "Mounting/Dismantling Tool, plastic socket bulb",
            "NormTermName": "Mounting/Dismantling Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05182": {
            "GenartName": "Deactivation Module, electronic damper control",
            "NormTermName": "Deactivation Module",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Electronic damping adjustment"
        },
        "05183": {
            "GenartName": "Fuse Tester",
            "NormTermName": "Tester",
            "AssGrpName": "Tool, universal",
            "UsageName": "Fuse"
        },
        "05184": {
            "GenartName": "Hose, battery air vent",
            "NormTermName": "Hose Line",
            "AssGrpName": "Starter System, universal",
            "UsageName": "Starter Battery"
        },
        "05185": {
            "GenartName": "Filling Tap, battery acid",
            "NormTermName": "Valve",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Battery Acid"
        },
        "05186": {
            "GenartName": "Assortment, cable protection",
            "NormTermName": "Assortment",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Cable Protecting Sleeve"
        },
        "05187": {
            "GenartName": "Dongle, self-diagnosis device",
            "NormTermName": "Plug",
            "AssGrpName": "Workshop Devices",
            "UsageName": "System Test"
        },
        "05188": {
            "GenartName": "Post Vehicle Lift",
            "NormTermName": "Post Vehicle Lift",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05189": {
            "GenartName": "Scissors Vehicle Lift",
            "NormTermName": "Scissors Vehicle Lift",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05190": {
            "GenartName": "Cylinder Vehicle Lift",
            "NormTermName": "Cylinder Vehicle Lift",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05191": {
            "GenartName": "Wheel Grabber",
            "NormTermName": "Wheel Grabber",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05192": {
            "GenartName": "Post Lift System",
            "NormTermName": "Post Lift System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05193": {
            "GenartName": "Cylinder Lift System",
            "NormTermName": "Cylinder Lift System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05194": {
            "GenartName": "Axle Jack",
            "NormTermName": "Axle Jack",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05195": {
            "GenartName": "Transmission Jack",
            "NormTermName": "Transmission Jack",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05196": {
            "GenartName": "Pit Jack",
            "NormTermName": "Pit Jack",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05197": {
            "GenartName": "Lift Table",
            "NormTermName": "Lift Table",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05198": {
            "GenartName": "Gas Spring, ashtray lid",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Ashtray Lid"
        },
        "05199": {
            "GenartName": "Pressure Sensor, drive dynamics control",
            "NormTermName": "Sensor",
            "AssGrpName": "Drive Dynamics Control",
            "UsageName": "Pressure Sensor (drive dynamics control)"
        },
        "05200": {
            "GenartName": "Oil Filter, turbocharger",
            "NormTermName": "Filter",
            "AssGrpName": "Lubrication",
            "UsageName": "Turbocharger"
        },
        "05201": {
            "GenartName": "Wheel Jack",
            "NormTermName": "Wheel Jack",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05202": {
            "GenartName": "Mobile Jack",
            "NormTermName": "Mobile Jack",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05203": {
            "GenartName": "Jack",
            "NormTermName": "Jack",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05204": {
            "GenartName": "Oil Pump, automatic transmission",
            "NormTermName": "Pump",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "05205": {
            "GenartName": "Nitrogen Generator",
            "NormTermName": "Nitrogen Generator",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tyre Technology"
        },
        "05206": {
            "GenartName": "Clamping Device, wheel balancing machine",
            "NormTermName": "Clamping Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tyre Technology"
        },
        "05207": {
            "GenartName": "Centering Device, wheel balancing machine",
            "NormTermName": "Centering Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tyre Technology"
        },
        "05208": {
            "GenartName": "Pressure Flange, wheel balancing machine",
            "NormTermName": "Flange",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tyre Technology"
        },
        "05209": {
            "GenartName": "Clamping Nut, wheel balancing machine",
            "NormTermName": "Nut",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tyre Technology"
        },
        "05210": {
            "GenartName": "Tool Set, tyre-pressure monitoring system",
            "NormTermName": "Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "05211": {
            "GenartName": "Video Endoscope",
            "NormTermName": "Video Endoscope",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05212": {
            "GenartName": "EBS Connection Cable",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Compressed Air System, universal",
            "UsageName": "Electronic Brake System"
        },
        "05213": {
            "GenartName": "Bleeder Screw/Valve, brake caliper",
            "NormTermName": "Screw",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper (brake system bleeding)"
        },
        "05214": {
            "GenartName": "Bleeder Screw/Valve, wheel brake cylinder",
            "NormTermName": "Screw",
            "AssGrpName": "Braking System",
            "UsageName": "Wheel Brake Cylinder (brake system bleeding)"
        },
        "05215": {
            "GenartName": "Adapter Cable, tail light",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Lights, universal",
            "UsageName": "Tail Light"
        },
        "05216": {
            "GenartName": "Valve, transmission oil cooler",
            "NormTermName": "Valve",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Transmission Cooling"
        },
        "05217": {
            "GenartName": "Valve, transmission oil cooler",
            "NormTermName": "Valve",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Cooling"
        },
        "05218": {
            "GenartName": "Repair Cable",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Cable Repair"
        },
        "05219": {
            "GenartName": "Wheel Alignment System",
            "NormTermName": "Wheel Alignment System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wheel Alignment Testing Systems"
        },
        "05220": {
            "GenartName": "Turntable, wheel alignment",
            "NormTermName": "Turntable",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wheel Alignment Testing Systems"
        },
        "05221": {
            "GenartName": "Sliding Plate, wheel alignment",
            "NormTermName": "Sliding Plate",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wheel Alignment Testing Systems"
        },
        "05222": {
            "GenartName": "Wheel Clamp, wheel alignment",
            "NormTermName": "Wheel Clamp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wheel Alignment Testing Systems"
        },
        "05223": {
            "GenartName": "Steering Wheel Scale",
            "NormTermName": "Steering Wheel Scale",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wheel Alignment Testing Systems"
        },
        "05224": {
            "GenartName": "Adapter Cable, tail light",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Lights",
            "UsageName": "Tail Light"
        },
        "05225": {
            "GenartName": "Inclination Measuring Device, wheel alignment",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wheel Alignment Testing Systems"
        },
        "05226": {
            "GenartName": "Cover, brake tester",
            "NormTermName": "Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake Tester"
        },
        "05227": {
            "GenartName": "Installation Frame, brake tester",
            "NormTermName": "Installation Frame",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake Tester"
        },
        "05228": {
            "GenartName": "Installation Cassette, brake tester",
            "NormTermName": "Installation Cassette",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake Tester"
        },
        "05229": {
            "GenartName": "Roller Set, brake tester",
            "NormTermName": "Pulley Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake Tester"
        },
        "05230": {
            "GenartName": "Pit Safety System, brake tester",
            "NormTermName": "Safety System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake Tester"
        },
        "05231": {
            "GenartName": "Pressure Converter, brake tester",
            "NormTermName": "Pressure Converter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake Tester"
        },
        "05232": {
            "GenartName": "Display, brake tester",
            "NormTermName": "Gauge",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake Tester"
        },
        "05233": {
            "GenartName": "Cover, dynamometer",
            "NormTermName": "Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Dynamometer"
        },
        "05234": {
            "GenartName": "Mounting Frame, dynamometer",
            "NormTermName": "Installation Frame",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Dynamometer"
        },
        "05235": {
            "GenartName": "Installation Cassette, dynamometer",
            "NormTermName": "Installation Cassette",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Dynamometer"
        },
        "05236": {
            "GenartName": "Roller Set, dynamometer",
            "NormTermName": "Pulley Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Dynamometer"
        },
        "05237": {
            "GenartName": "Speed Tester",
            "NormTermName": "Test Bench",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Speed"
        },
        "05238": {
            "GenartName": "Cover, speed tester",
            "NormTermName": "Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Speed Tester"
        },
        "05239": {
            "GenartName": "Mounting Frame, speed tester",
            "NormTermName": "Installation Frame",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Speed Tester"
        },
        "05240": {
            "GenartName": "Installation Cassette, speed tester",
            "NormTermName": "Installation Cassette",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Speed Tester"
        },
        "05241": {
            "GenartName": "Roller Set, speed tester",
            "NormTermName": "Pulley Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Speed Tester"
        },
        "05242": {
            "GenartName": "Axle/Joint Play Detector",
            "NormTermName": "Axle/Joint Play Detector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05243": {
            "GenartName": "Axle Load Scale",
            "NormTermName": "Axle Load Scale",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Testing Systems"
        },
        "05244": {
            "GenartName": "Suspension Tester",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Suspension"
        },
        "05245": {
            "GenartName": "Sideslip Tester",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wheel Alignment"
        },
        "05246": {
            "GenartName": "Lifting/Lowering Device, brake tester",
            "NormTermName": "Lifting-/Lowering Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brakes Tester"
        },
        "05247": {
            "GenartName": "Lifting Device, brake tester",
            "NormTermName": "Lifting Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brakes Tester"
        },
        "05248": {
            "GenartName": "Clamping Device, brake tester",
            "NormTermName": "Clamping Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake Tester"
        },
        "05249": {
            "GenartName": "Cover, cylinder vehicle lift",
            "NormTermName": "Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cylinder Vehicle Lift"
        },
        "05250": {
            "GenartName": "Installation Frame, cylinder vehicle lift",
            "NormTermName": "Installation Frame",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cylinder Vehicle Lift"
        },
        "05251": {
            "GenartName": "Installation Cassette, cyilnder lift",
            "NormTermName": "Installation Cassette",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cylinder Vehicle Lift"
        },
        "05252": {
            "GenartName": "Insulator",
            "NormTermName": "Insulator",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "05253": {
            "GenartName": "Sensor, tailgate position",
            "NormTermName": "Sensor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Tailgate position"
        },
        "05254": {
            "GenartName": "Exhaust Hose, exhaust extraction",
            "NormTermName": "Hose",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust fumes extraction"
        },
        "05255": {
            "GenartName": "Exhaust Funnel, exhaust extraction",
            "NormTermName": "Funnel",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust fumes extraction"
        },
        "05256": {
            "GenartName": "Flange, transfer case",
            "NormTermName": "Flange",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transfer Case"
        },
        "05257": {
            "GenartName": "Extraction Arm, exhaust extraction",
            "NormTermName": "Extraction Arm",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05258": {
            "GenartName": "Rail Unit",
            "NormTermName": "Rail Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust fumes extraction"
        },
        "05259": {
            "GenartName": "Rail System",
            "NormTermName": "Rail System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust fumes extraction"
        },
        "05260": {
            "GenartName": "Hose Reel, exhaust fumes extraction",
            "NormTermName": "Hose Reel",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust fumes extraction"
        },
        "05261": {
            "GenartName": "Hose Trolley, exhaust fumes extraction",
            "NormTermName": "Hose Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust fumes extraction"
        },
        "05263": {
            "GenartName": "Single Point Extraction Unit",
            "NormTermName": "Extraction Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust fumes extraction"
        },
        "05264": {
            "GenartName": "Fan, exhaust fumes extraction",
            "NormTermName": "Fan",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust fumes extraction"
        },
        "05265": {
            "GenartName": "Balancer, exhaust fumes extraction",
            "NormTermName": "Balancer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust fumes extraction"
        },
        "05266": {
            "GenartName": "Spiral Duct, exhaust fumes extraction",
            "NormTermName": "Tube",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust fumes extraction system"
        },
        "05267": {
            "GenartName": "Extension Unit/Connector, spiral duct",
            "NormTermName": "Extension Unit/Connector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Spiral Duct"
        },
        "05268": {
            "GenartName": "Extension Unit/Connector, fan",
            "NormTermName": "Extension Unit/Connector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Fan"
        },
        "05269": {
            "GenartName": "Extension Unit/Connector, rail unit",
            "NormTermName": "Extension Unit/Connector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Rail Unit"
        },
        "05270": {
            "GenartName": "Extension Unit/Connector, exhaust hose",
            "NormTermName": "Extension Unit/Connector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust Hose"
        },
        "05271": {
            "GenartName": "In-floor Extraction Unit",
            "NormTermName": "In-floor Extraction Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust fumes extraction"
        },
        "05272": {
            "GenartName": "Extension Unit/Connector, in-floor extraction unit",
            "NormTermName": "Extension Unit/Connector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "In-floor Extraction Unit"
        },
        "05273": {
            "GenartName": "Battery Charger/Starter",
            "NormTermName": "Battery Charger/Starter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05274": {
            "GenartName": "Battery Starter",
            "NormTermName": "Battery Starter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05275": {
            "GenartName": "AU Exhaust Fumes Extraction Unit",
            "NormTermName": "Extraction Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Emmission Test"
        },
        "05276": {
            "GenartName": "Thermostat, EGR cooling",
            "NormTermName": "Thermostat",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "Exhaust Gas Recirculation (EGR)"
        },
        "05277": {
            "GenartName": "Filter Dryer, A/C system",
            "NormTermName": "Dehumidifier",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Systems"
        },
        "05278": {
            "GenartName": "Waste Oil Container, A/C system",
            "NormTermName": "Used Oil Tank",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Systems"
        },
        "05279": {
            "GenartName": "Fresh Oil Container, A/C system",
            "NormTermName": "Fresh Oil Container",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Systems"
        },
        "05280": {
            "GenartName": "Refrigerant Cylinder, A/C system",
            "NormTermName": "Refrigerant Bottle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Systems"
        },
        "05281": {
            "GenartName": "Vacuum Hose, air conditioning",
            "NormTermName": "Hose Line",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Systems"
        },
        "05282": {
            "GenartName": "Flush Kit, air conditioning",
            "NormTermName": "Flush Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Systems"
        },
        "05283": {
            "GenartName": "Flush Tank, air conditioning",
            "NormTermName": "Flush Tank",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Systems"
        },
        "05284": {
            "GenartName": "Tester, exhaust pressure",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust Pressure"
        },
        "05285": {
            "GenartName": "Tester, urea injection",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Urea Injection"
        },
        "05286": {
            "GenartName": "Ammeter",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Current Measurement"
        },
        "05287": {
            "GenartName": "Tester, transmission oil",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Transmission Oil"
        },
        "05288": {
            "GenartName": "Measuring Device, refrigerant",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Refrigerant"
        },
        "05289": {
            "GenartName": "Tester, fuel feed pump",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Fuel Pre-feed Pump"
        },
        "05290": {
            "GenartName": "Noise Detector",
            "NormTermName": "Noise Detector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05291": {
            "GenartName": "Stethoscope",
            "NormTermName": "Stethoscope",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05292": {
            "GenartName": "Turbidimeter",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05293": {
            "GenartName": "Measuring Device, clamping force",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Clamping Force"
        },
        "05294": {
            "GenartName": "Tester Set, fuel identification",
            "NormTermName": "Test Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Fuel"
        },
        "05295": {
            "GenartName": "Measuring Device, tread depth",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tread Depth"
        },
        "05296": {
            "GenartName": "Measuring Device, noise level",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Noise Level"
        },
        "05297": {
            "GenartName": "Anti-Squeal Foil, brake pad (back plate)",
            "NormTermName": "Foil",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Pad (back plate)"
        },
        "05299": {
            "GenartName": "Filter Pressure Regulator, compressed air system",
            "NormTermName": "Filter Pressure Regulator",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05300": {
            "GenartName": "Maintenance Unit, compressed air system",
            "NormTermName": "Maintenance Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05301": {
            "GenartName": "Pressure Regulator, compressed air system",
            "NormTermName": "Pressure Regulator",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05302": {
            "GenartName": "Pressure Tank, air compressor",
            "NormTermName": "Tank",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05303": {
            "GenartName": "Filter, compressed air system",
            "NormTermName": "Filter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05304": {
            "GenartName": "Sound Insulation Cover, air compressor",
            "NormTermName": "Sound Insulation Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Compressor"
        },
        "05305": {
            "GenartName": "Condensate Drain, compressed air system",
            "NormTermName": "Condensate Trap",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05306": {
            "GenartName": "Oil-Water Separator",
            "NormTermName": "Oil-Water Separator",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05307": {
            "GenartName": "Refrigerant Dryer",
            "NormTermName": "Refrigerant Dryer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05308": {
            "GenartName": "Foot Brake Cylinder",
            "NormTermName": "Cylinder",
            "AssGrpName": "Braking System",
            "UsageName": "Foot Brake Cylinder (motorcycle)"
        },
        "05309": {
            "GenartName": "Hand Brake Cylinder",
            "NormTermName": "Cylinder",
            "AssGrpName": "Braking System",
            "UsageName": "Hand Brake Cylinder (motorcycle)"
        },
        "05310": {
            "GenartName": "Banjo Bolt, brake line",
            "NormTermName": "Screw",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Line"
        },
        "05311": {
            "GenartName": "Adapter, brake caliper",
            "NormTermName": "Adaptor",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Caliper (motorcycle)"
        },
        "05312": {
            "GenartName": "Worklight",
            "NormTermName": "Headlight",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workplace Lighting"
        },
        "05313": {
            "GenartName": "Inspection Lamp",
            "NormTermName": "Inspection Lamp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05314": {
            "GenartName": "Screw Set, brake disc",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Disc"
        },
        "05315": {
            "GenartName": "Oil Collection Unit",
            "NormTermName": "Oil Catch Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05316": {
            "GenartName": "Oil Extraction Unit",
            "NormTermName": "Oil Extraction Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05317": {
            "GenartName": "Oil Collection/Extraction Unit",
            "NormTermName": "Oil Catch/Extraction Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05318": {
            "GenartName": "Fuel Suction Device",
            "NormTermName": "Fuel Suction Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05319": {
            "GenartName": "Flush System, transmission",
            "NormTermName": "Flushing System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Transmission"
        },
        "05320": {
            "GenartName": "Flush System, air conditioning",
            "NormTermName": "Flushing System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning"
        },
        "05321": {
            "GenartName": "Brake Line Grommet",
            "NormTermName": "Grommet",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Line (motorcycle)"
        },
        "05322": {
            "GenartName": "Flush System, steering",
            "NormTermName": "Flushing System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Steering Hydraulics"
        },
        "05323": {
            "GenartName": "Tension Element, body repairs",
            "NormTermName": "Pull Element",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Repair Systems"
        },
        "05324": {
            "GenartName": "Ferrule, brake line",
            "NormTermName": "Sleeve",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Line (motorcycle)"
        },
        "05325": {
            "GenartName": "Panel Beating System",
            "NormTermName": "Panel Beating System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05326": {
            "GenartName": "Distributor Piece, brake line",
            "NormTermName": "Connector",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Line Distributor Piece (motorcycle)"
        },
        "05327": {
            "GenartName": "Body Straightening Bench",
            "NormTermName": "Body Straightening Bench",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Repair Systems"
        },
        "05328": {
            "GenartName": "Body Straightening Set",
            "NormTermName": "Body Straightening Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Repair Systems"
        },
        "05329": {
            "GenartName": "Sandblaster",
            "NormTermName": "Sandblaster",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Repair Systems"
        },
        "05330": {
            "GenartName": "Dry Ice Blaster",
            "NormTermName": "Dry Ice Blaster",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Repair Systems"
        },
        "05331": {
            "GenartName": "Pulling Tower",
            "NormTermName": "Pulling Tower",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Repair Systems"
        },
        "05332": {
            "GenartName": "Workshop Pit",
            "NormTermName": "Workshop Pit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05333": {
            "GenartName": "Cover, workshop pit",
            "NormTermName": "Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05334": {
            "GenartName": "Enclosure, workshop pit",
            "NormTermName": "Enclosure",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Pit"
        },
        "05335": {
            "GenartName": "Sound Insulation Cover, exhaust fumes extraction fan",
            "NormTermName": "Sound Insulation Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust Fumes Extraction Fan"
        },
        "05336": {
            "GenartName": "Horizontal Boom, exhaust extraction",
            "NormTermName": "Boom",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust Hose"
        },
        "05338": {
            "GenartName": "Soldering Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Soldered Connection"
        },
        "05339": {
            "GenartName": "Dryer, spray painting",
            "NormTermName": "Dehumidifier",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Spray Paint Systems"
        },
        "05340": {
            "GenartName": "Track System, dryer",
            "NormTermName": "Track System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Dryer"
        },
        "05341": {
            "GenartName": "Paint Table",
            "NormTermName": "Paint Table",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Spray Paint Systems"
        },
        "05342": {
            "GenartName": "Spray Paint Booths",
            "NormTermName": "Spray Paint Booths",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05343": {
            "GenartName": "Drip Station, spray painting",
            "NormTermName": "Drip Station",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Spray Paint Systems"
        },
        "05344": {
            "GenartName": "Collection Pan",
            "NormTermName": "Collection Pan",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05345": {
            "GenartName": "Wheel Washer",
            "NormTermName": "Wheel Washer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05346": {
            "GenartName": "Footrest System",
            "NormTermName": "Footrest System",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Motorcycle"
        },
        "05347": {
            "GenartName": "Spring Tension Press",
            "NormTermName": "Spring Tension Press",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05348": {
            "GenartName": "Kingpin Press",
            "NormTermName": "Kingpin Press",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Presses"
        },
        "05349": {
            "GenartName": "Sweeper",
            "NormTermName": "Sweeper",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05350": {
            "GenartName": "Joint Set, footrest",
            "NormTermName": "Joint Kit",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Footrest (motorcycle)"
        },
        "05351": {
            "GenartName": "Broom",
            "NormTermName": "Broom",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05352": {
            "GenartName": "Snow Plough",
            "NormTermName": "Snow Plough",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05353": {
            "GenartName": "Footrest Set",
            "NormTermName": "Footrest Set",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Motorcycle"
        },
        "05354": {
            "GenartName": "Steam Cleaner",
            "NormTermName": "Steam Cleaner",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cleaning Equipment/Washing Systems"
        },
        "05355": {
            "GenartName": "Heel Guard, footrest",
            "NormTermName": "Heel Guard",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Footrest System (motorcycle)"
        },
        "05356": {
            "GenartName": "Steam Ironing Station",
            "NormTermName": "Steam Ironing Station",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05357": {
            "GenartName": "Ultrasonic Cleaner",
            "NormTermName": "Ultrasonic Cleaner",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cleaning Equipment/Washing Systems"
        },
        "05358": {
            "GenartName": "Plasma Cutter",
            "NormTermName": "Plasma Cutter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cut/Joining Systems"
        },
        "05359": {
            "GenartName": "Friction Multi-Plate Set, clutch",
            "NormTermName": "Friction Multi-Plate Set",
            "AssGrpName": "Clutch",
            "UsageName": "Motorcycle"
        },
        "05360": {
            "GenartName": "Barrel Trolley",
            "NormTermName": "Barrel Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Supply systems for diverse media"
        },
        "05361": {
            "GenartName": "Spray Extraction Device",
            "NormTermName": "Spray Extraction Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cleaning Equipment/Washing Systems"
        },
        "05362": {
            "GenartName": "Clutch Spring Set",
            "NormTermName": "Spring Kit",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Disc"
        },
        "05363": {
            "GenartName": "Steel Lining Disc Set, clutch",
            "NormTermName": "Steel Lining Disc Set",
            "AssGrpName": "Clutch",
            "UsageName": "Motorcycle"
        },
        "05364": {
            "GenartName": "Handlebars",
            "NormTermName": "Control/Trailing Arm",
            "AssGrpName": "Steering",
            "UsageName": "Motorcycle"
        },
        "05365": {
            "GenartName": "Stopcock, compressed air system",
            "NormTermName": "Shut-Off Cock",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05368": {
            "GenartName": "Dummy Cap, compressed air system",
            "NormTermName": "Cap",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05369": {
            "GenartName": "Dummy Stop, compressed air technology",
            "NormTermName": "Plug",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05370": {
            "GenartName": "Pipeline Socket, compressed air system",
            "NormTermName": "Pipeline Socket",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05371": {
            "GenartName": "Clamping Ring, compressed air system",
            "NormTermName": "Clamping Ring",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05372": {
            "GenartName": "Connector/Distributor Piece, compressed air system",
            "NormTermName": "Connector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05373": {
            "GenartName": "Reduction Piece, compressed air system",
            "NormTermName": "Reducer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05374": {
            "GenartName": "Support Sleeve, compressed air system",
            "NormTermName": "Sleeve",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05375": {
            "GenartName": "Socket, compressed air system",
            "NormTermName": "Socket",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05376": {
            "GenartName": "Clamp, compressed air system",
            "NormTermName": "Clamp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05377": {
            "GenartName": "Air Release, compressed air system",
            "NormTermName": "Air Expulsion",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05378": {
            "GenartName": "Cover, cylinder lift system",
            "NormTermName": "Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05379": {
            "GenartName": "Installation Frame, cylinder lift system",
            "NormTermName": "Installation Frame",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05380": {
            "GenartName": "Installation Cassette, cylinder lift system",
            "NormTermName": "Installation Cassette",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "05381": {
            "GenartName": "Bar Ends",
            "NormTermName": "Bar Ends",
            "AssGrpName": "Steering",
            "UsageName": "Motorcycle"
        },
        "05382": {
            "GenartName": "Handlebar Pipe",
            "NormTermName": "Handlebar Pipe",
            "AssGrpName": "Steering",
            "UsageName": "Motorcycle"
        },
        "05383": {
            "GenartName": "Handlebars Mounting",
            "NormTermName": "Holder",
            "AssGrpName": "Steering",
            "UsageName": "Handlebars (motorcycle)"
        },
        "05384": {
            "GenartName": "Spacer, handlebars",
            "NormTermName": "Spacer",
            "AssGrpName": "Steering",
            "UsageName": "Handlebars (motorcycle)"
        },
        "05385": {
            "GenartName": "Spring Balancer, compressed air system",
            "NormTermName": "Balancer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "05386": {
            "GenartName": "Hose Line, air compressor",
            "NormTermName": "Hose Line",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Compressor"
        },
        "05387": {
            "GenartName": "Adapter, A/C unit service nozzle",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Systems"
        },
        "05388": {
            "GenartName": "Adapter Set, A/C unit service nozzle",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Systems"
        },
        "05389": {
            "GenartName": "Adapter, refrigerant bottle",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Refrigerant Bottle"
        },
        "05390": {
            "GenartName": "Seal, refrigerant bottle adapter",
            "NormTermName": "Seal",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Refrigerant Bottle Adapter"
        },
        "05391": {
            "GenartName": "Sealing Cap, service valve",
            "NormTermName": "Cap",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Systems"
        },
        "05392": {
            "GenartName": "Sealing Plug, air conditioning",
            "NormTermName": "Plug",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Systems"
        },
        "05393": {
            "GenartName": "High Pressure Line, air conditioning",
            "NormTermName": "Hose Line",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Air Conditioning (high pressure line)"
        },
        "05394": {
            "GenartName": "Low Pressure Line, air conditioning",
            "NormTermName": "Hose Line",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Air Conditioning (low pressure line)"
        },
        "05395": {
            "GenartName": "Measuring Device, torque",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Torque"
        },
        "05396": {
            "GenartName": "Measuring Device, RPM",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "RPM"
        },
        "05397": {
            "GenartName": "Hygrometer",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Humidity"
        },
        "05398": {
            "GenartName": "Measuring Device, fuel consumption",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Fuel Consumption"
        },
        "05399": {
            "GenartName": "Paint Meter, hardness",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Paint Hardness"
        },
        "05400": {
            "GenartName": "Paint Meter, coating thickness",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Paint Coat Thickness"
        },
        "05401": {
            "GenartName": "Mirror-based Endoscope",
            "NormTermName": "Mirror-based Endoscope",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05402": {
            "GenartName": "Handlebar Mount",
            "NormTermName": "Holder",
            "AssGrpName": "Steering",
            "UsageName": "Motorcycle Handlebar"
        },
        "05403": {
            "GenartName": "Measuring Device, tank contents",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tank Content"
        },
        "05404": {
            "GenartName": "Tank Probe",
            "NormTermName": "Probe",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tank Content"
        },
        "05405": {
            "GenartName": "Measuring Device, brake deceleration",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake Deceleration"
        },
        "05406": {
            "GenartName": "Tail Lowering",
            "NormTermName": "Tail Lowering",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Motorcycle"
        },
        "05407": {
            "GenartName": "Tail Rising",
            "NormTermName": "Tail Rising",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Motorcycle"
        },
        "05408": {
            "GenartName": "Chain Guard",
            "NormTermName": "Chain Guard",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Motorcycle"
        },
        "05409": {
            "GenartName": "Clutch Lever",
            "NormTermName": "Lever",
            "AssGrpName": "Clutch",
            "UsageName": "Motorcycle"
        },
        "05410": {
            "GenartName": "Brake Lever, handlebars",
            "NormTermName": "Lever",
            "AssGrpName": "Braking System",
            "UsageName": "Motorcycle"
        },
        "05411": {
            "GenartName": "Tester, control pressure",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Control Pressure"
        },
        "05412": {
            "GenartName": "Force Gauge",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Force Measurement"
        },
        "05413": {
            "GenartName": "Licence Plate Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Number Plate (motorcycle)"
        },
        "05414": {
            "GenartName": "Test Probe Set",
            "NormTermName": "Test Probe Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05415": {
            "GenartName": "Bunsen Burner",
            "NormTermName": "Bunsen Burner",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05416": {
            "GenartName": "Flush System, axle beam",
            "NormTermName": "Flushing System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Axle Beam"
        },
        "05417": {
            "GenartName": "Body Measuring System",
            "NormTermName": "Body Measuring System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Repair Systems"
        },
        "05418": {
            "GenartName": "Dry Vacuum",
            "NormTermName": "Suction Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Dry Vacuum"
        },
        "05419": {
            "GenartName": "Wet/Dry Vacuum Cleaner",
            "NormTermName": "Suction Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wet/Dry Vacuum Cleaner"
        },
        "05420": {
            "GenartName": "Brush Vacuum Cleaner",
            "NormTermName": "Suction Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brush Vacuum Cleaner"
        },
        "05421": {
            "GenartName": "Safety Vacuum",
            "NormTermName": "Suction Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Safety Vacuum"
        },
        "05422": {
            "GenartName": "Industrial Suction Unit",
            "NormTermName": "Suction Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Industrial Suction Unit"
        },
        "05423": {
            "GenartName": "Steam Vacuum Cleaner",
            "NormTermName": "Suction Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Steam Vacuum Cleaner"
        },
        "05424": {
            "GenartName": "Wash Vacuum Cleaner",
            "NormTermName": "Suction Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wash Vacuum Cleaner"
        },
        "05425": {
            "GenartName": "Cleaning Robot",
            "NormTermName": "Cleaning Robot",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05426": {
            "GenartName": "Soldering Device",
            "NormTermName": "Soldering Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cut/Joining Systems"
        },
        "05427": {
            "GenartName": "Oil Can",
            "NormTermName": "Tank",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Oil/Grease"
        },
        "05428": {
            "GenartName": "Grinding Dust Extractor",
            "NormTermName": "Grinding Dust Extraction System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Spray Paint Systems"
        },
        "05429": {
            "GenartName": "Welding Smoke Extractor",
            "NormTermName": "Welding Smoke Extractor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Repair Systems"
        },
        "05430": {
            "GenartName": "Barrel Pallet",
            "NormTermName": "Barrel Pallet",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Filling Stations/Catch Pans"
        },
        "05431": {
            "GenartName": "Strap, collection pan",
            "NormTermName": "Tightening Strap",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Collection Pan"
        },
        "05432": {
            "GenartName": "Attachment, collection pan",
            "NormTermName": "Attachment",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Collection Pan"
        },
        "05433": {
            "GenartName": "Grid, collection pan",
            "NormTermName": "Grille",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Collection Pan"
        },
        "05434": {
            "GenartName": "Can Holder, filling station / collection pan",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Filling Stations/Catch Pans"
        },
        "05435": {
            "GenartName": "Filling Stand",
            "NormTermName": "Filling Stand",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05436": {
            "GenartName": "Storage Tank",
            "NormTermName": "Tank",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Storage Tank"
        },
        "05437": {
            "GenartName": "Surface Protection System",
            "NormTermName": "Surface Protection System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Filling Stations/Catch Pans"
        },
        "05438": {
            "GenartName": "Disposal Tank",
            "NormTermName": "Tank",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Disposal Tank"
        },
        "05439": {
            "GenartName": "Hazardous Goods Storage Point",
            "NormTermName": "Hazardous Goods Storage Point",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Filling Stations/Catch Pans"
        },
        "05440": {
            "GenartName": "Grid, hazardous goods storage point",
            "NormTermName": "Grille",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Hazardous Goods Storage Point"
        },
        "05441": {
            "GenartName": "Lid, hazardous goods storage point",
            "NormTermName": "Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Hazardous Goods Storage Point"
        },
        "05442": {
            "GenartName": "Connector, surface protection system",
            "NormTermName": "Connector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Surface Protection System"
        },
        "05443": {
            "GenartName": "Access Ramp, surface protection system",
            "NormTermName": "Access Ramp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Surface Protection System"
        },
        "05444": {
            "GenartName": "Lubricant Tank",
            "NormTermName": "Tank",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lubricant Tank"
        },
        "05445": {
            "GenartName": "Suction Pipe, lubricant tank",
            "NormTermName": "Suction Pipe",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lubricant Tank"
        },
        "05446": {
            "GenartName": "Oil Dispenser",
            "NormTermName": "Oil Dispenser",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Mobile Dispenser/Drum Trolley"
        },
        "05447": {
            "GenartName": "Lubricant Dispenser",
            "NormTermName": "Lubricant Dispenser",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Mobile Dispenser/Drum Trolley"
        },
        "05448": {
            "GenartName": "Control Unit, tachograph",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tachograph"
        },
        "05449": {
            "GenartName": "Power Module",
            "NormTermName": "Power Module",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05450": {
            "GenartName": "Suction Pipe, storage tank",
            "NormTermName": "Suction Pipe",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Storage Tank"
        },
        "05451": {
            "GenartName": "Suction Pipe, disposal tank",
            "NormTermName": "Suction Pipe",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Disposal Tank"
        },
        "05452": {
            "GenartName": "Trestle",
            "NormTermName": "Trestle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05453": {
            "GenartName": "Collector, exhaust system",
            "NormTermName": "Collector",
            "AssGrpName": "Exhaust System",
            "UsageName": "Motorcycle"
        },
        "05454": {
            "GenartName": "Suction Hose, high pressure cleaner",
            "NormTermName": "Hose",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "High Pressure Cleaner"
        },
        "05455": {
            "GenartName": "Side Stand",
            "NormTermName": "Side Stand",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Motorcycle"
        },
        "05456": {
            "GenartName": "Brush, high pressure cleaner",
            "NormTermName": "Brush",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "High Pressure Cleaner"
        },
        "05457": {
            "GenartName": "Main Stand",
            "NormTermName": "Main Stand",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Motorcycle"
        },
        "05458": {
            "GenartName": "Surface Cleaner, high pressure cleaning",
            "NormTermName": "Surface Cleaner",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "High Pressure Cleaner"
        },
        "05459": {
            "GenartName": "Extension Hose, high pressure cleaner",
            "NormTermName": "Extension",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "High Pressure Cleaner"
        },
        "05460": {
            "GenartName": "Spray Gun, high pressure cleaner",
            "NormTermName": "Spray Gun",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "High Pressure Cleaner"
        },
        "05461": {
            "GenartName": "Silencer Insert, rear muffler",
            "NormTermName": "Insert",
            "AssGrpName": "Exhaust System",
            "UsageName": "Rear Muffler (motorcycle)"
        },
        "05462": {
            "GenartName": "Jet Pipe, high pressure cleaner",
            "NormTermName": "Jet Pipe",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "High Pressure Cleaner"
        },
        "05463": {
            "GenartName": "Mirror",
            "NormTermName": "Mirror",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Motorcycle"
        },
        "05464": {
            "GenartName": "Filter, high pressure cleaner",
            "NormTermName": "Filter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "High Pressure Cleaner"
        },
        "05465": {
            "GenartName": "Adapter, high pressure cleaner",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "High Pressure Cleaner"
        },
        "05466": {
            "GenartName": "Jet Pipe Extension, high pressure cleaner",
            "NormTermName": "Extension",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "High Pressure Cleaner Jet Pipe"
        },
        "05467": {
            "GenartName": "Nozzle, high pressure surface cleaner",
            "NormTermName": "Nozzle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "High Pressure Surface Cleaner"
        },
        "05468": {
            "GenartName": "Auto Tank Filler",
            "NormTermName": "Auto Tank Filler",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Supply systems for diverse media"
        },
        "05469": {
            "GenartName": "Fuel Dispenser",
            "NormTermName": "Fuel Dispenser",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Supply systems for diverse media"
        },
        "05470": {
            "GenartName": "Spring Mast, fuel dispenser",
            "NormTermName": "Spring Mast",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Fuel Dispenser"
        },
        "05471": {
            "GenartName": "Fork Brace",
            "NormTermName": "Fork Brace",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Motorcycle"
        },
        "05472": {
            "GenartName": "Axle Mounting, front wheel",
            "NormTermName": "Holder",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Motorcycle Axles"
        },
        "05473": {
            "GenartName": "Fork Spring",
            "NormTermName": "Spring",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Fork (motorcycle)"
        },
        "05474": {
            "GenartName": "Standpipe",
            "NormTermName": "Standpipe",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Motorcycle"
        },
        "05475": {
            "GenartName": "Immersion Tube",
            "NormTermName": "Immersion Tube",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Motorcycle"
        },
        "05476": {
            "GenartName": "Shock Absorber Spring",
            "NormTermName": "Spring",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shock Absorber (motorcycle)"
        },
        "05477": {
            "GenartName": "Chain",
            "NormTermName": "Chain",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Motorcycle"
        },
        "05478": {
            "GenartName": "Chain Set",
            "NormTermName": "Chain Set",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Motorcycle"
        },
        "05479": {
            "GenartName": "Chain Sprocket",
            "NormTermName": "Chain Sprocket",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Motorcycle"
        },
        "05480": {
            "GenartName": "Chain Pinion",
            "NormTermName": "Chain Pinion",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Motorcycle"
        },
        "05481": {
            "GenartName": "Chain Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Motorcycle Chain"
        },
        "05482": {
            "GenartName": "Drive Toothed Belt, (motorcycle)",
            "NormTermName": "Belt",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Toothed Belt, (motorcycle)"
        },
        "05483": {
            "GenartName": "Drive V-Belt, variomatic",
            "NormTermName": "Belt",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive V-Belt, variomatic (motorcycle)"
        },
        "05484": {
            "GenartName": "Foot/Running Board",
            "NormTermName": "Step Board",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Motorcycle"
        },
        "05485": {
            "GenartName": "Steering Head Bearing",
            "NormTermName": "Bearing",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Steering Head (motorcycle)"
        },
        "05486": {
            "GenartName": "Mirror Glass",
            "NormTermName": "Mirror Glass",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Motorcycle"
        },
        "05487": {
            "GenartName": "Differential Gear",
            "NormTermName": "Transmission",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "05488": {
            "GenartName": "Insert, stop light",
            "NormTermName": "Insert",
            "AssGrpName": "Lights",
            "UsageName": "Stop Light"
        },
        "05489": {
            "GenartName": "Bulb Insert, combination rear light",
            "NormTermName": "Insert",
            "AssGrpName": "Lights",
            "UsageName": "Combination Rear Light"
        },
        "05490": {
            "GenartName": "Insert, direction indicator",
            "NormTermName": "Insert",
            "AssGrpName": "Lights",
            "UsageName": "Direction Indicator"
        },
        "05491": {
            "GenartName": "Park/Position Light Insert",
            "NormTermName": "Insert",
            "AssGrpName": "Lights",
            "UsageName": "Park-/Position Light"
        },
        "05492": {
            "GenartName": "Insert, rear fog light",
            "NormTermName": "Insert",
            "AssGrpName": "Lights",
            "UsageName": "Rear Fog Light"
        },
        "05493": {
            "GenartName": "Insert, stop light",
            "NormTermName": "Insert",
            "AssGrpName": "Lights, universal",
            "UsageName": "Stop Light"
        },
        "05494": {
            "GenartName": "Bulb Insert, combination rear light",
            "NormTermName": "Insert",
            "AssGrpName": "Lights, universal",
            "UsageName": "Combination Rear Light"
        },
        "05495": {
            "GenartName": "Insert, direction indicator",
            "NormTermName": "Insert",
            "AssGrpName": "Lights, universal",
            "UsageName": "Direction Indicator"
        },
        "05496": {
            "GenartName": "Park/Position Light Insert",
            "NormTermName": "Insert",
            "AssGrpName": "Lights, universal",
            "UsageName": "Park-/Position Light"
        },
        "05497": {
            "GenartName": "Insert, rear fog light",
            "NormTermName": "Insert",
            "AssGrpName": "Lights, universal",
            "UsageName": "Rear Fog Light"
        },
        "05498": {
            "GenartName": "Mounting, air compressor",
            "NormTermName": "Mounting",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "05499": {
            "GenartName": "Fan, traction battery",
            "NormTermName": "Fan",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Traction Battery"
        },
        "05500": {
            "GenartName": "Seal Ring Set, adjusting element (eccentric shaft)",
            "NormTermName": "Seal Ring Set",
            "AssGrpName": "Engine Timing",
            "UsageName": "Adjustment Element (eccentric shaft)"
        },
        "05501": {
            "GenartName": "Spring Washer, exhaust system",
            "NormTermName": "Springwasher",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust System Mounting"
        },
        "05502": {
            "GenartName": "Hydrostatic Drive",
            "NormTermName": "Hydrostatic Drive",
            "AssGrpName": "Axle Drive",
            "UsageName": null
        },
        "05503": {
            "GenartName": "Fastener, vacuum hose",
            "NormTermName": "Holder",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Vacuum Line"
        },
        "05504": {
            "GenartName": "Fastener, electric cable",
            "NormTermName": "Holder",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Electric Cable"
        },
        "05505": {
            "GenartName": "Lathe Chisel",
            "NormTermName": "Chisel",
            "AssGrpName": "Tool, universal",
            "UsageName": "Lathe Chisel"
        },
        "05506": {
            "GenartName": "Bevel Edge Chisel",
            "NormTermName": "Chisel",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bevel Edge Chisel"
        },
        "05507": {
            "GenartName": "Firmer Chisel",
            "NormTermName": "Chisel",
            "AssGrpName": "Tool, universal",
            "UsageName": "Firmer Chisel"
        },
        "05508": {
            "GenartName": "Internal Puller",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Internal Puller"
        },
        "05509": {
            "GenartName": "Hollow Chisel",
            "NormTermName": "Chisel",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hollow Chisel"
        },
        "05510": {
            "GenartName": "External Puller",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "External Puller"
        },
        "05511": {
            "GenartName": "Internal/External Puller",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Internal/External Puller"
        },
        "05512": {
            "GenartName": "Drill Bit",
            "NormTermName": "Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drill Bit"
        },
        "05513": {
            "GenartName": "Thread Drill Bit",
            "NormTermName": "Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Thread Drill Bit"
        },
        "05514": {
            "GenartName": "Screwdriver Bit",
            "NormTermName": "Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Screwdriver Bit"
        },
        "05515": {
            "GenartName": "Adapter, brakes bleeder hose",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Bleeder Hose (brake system)"
        },
        "05517": {
            "GenartName": "Wire Brush",
            "NormTermName": "Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wire Brush"
        },
        "05518": {
            "GenartName": "Sanding Brush",
            "NormTermName": "Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Sanding Brush"
        },
        "05519": {
            "GenartName": "Torque Multiplier",
            "NormTermName": "Torque Multiplier",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05520": {
            "GenartName": "Router",
            "NormTermName": "Milling Machine",
            "AssGrpName": "Tool, universal",
            "UsageName": "Router"
        },
        "05521": {
            "GenartName": "Biscuit Jointer",
            "NormTermName": "Milling Machine",
            "AssGrpName": "Tool, universal",
            "UsageName": "Biscuit Jointer"
        },
        "05522": {
            "GenartName": "Edge Trimmer",
            "NormTermName": "Milling Machine",
            "AssGrpName": "Tool, universal",
            "UsageName": "Edge Trimmer"
        },
        "05523": {
            "GenartName": "Multifunction Milling Machine",
            "NormTermName": "Milling Machine",
            "AssGrpName": "Tool, universal",
            "UsageName": "Multifunction Milling Machine"
        },
        "05524": {
            "GenartName": "Smooth Cut File",
            "NormTermName": "File",
            "AssGrpName": "Tool, universal",
            "UsageName": "Smooth Cut File"
        },
        "05525": {
            "GenartName": "Double Cut File",
            "NormTermName": "File",
            "AssGrpName": "Tool, universal",
            "UsageName": "Double Cut File"
        },
        "05526": {
            "GenartName": "Fine Cut File",
            "NormTermName": "File",
            "AssGrpName": "Tool, universal",
            "UsageName": "Fine Cut File"
        },
        "05527": {
            "GenartName": "Second Cut File",
            "NormTermName": "File",
            "AssGrpName": "Tool, universal",
            "UsageName": "Second Cut File"
        },
        "05528": {
            "GenartName": "Roughing File",
            "NormTermName": "File",
            "AssGrpName": "Tool, universal",
            "UsageName": "Roughing File"
        },
        "05529": {
            "GenartName": "Rough File",
            "NormTermName": "File",
            "AssGrpName": "Tool, universal",
            "UsageName": "Rough Cut File"
        },
        "05530": {
            "GenartName": "Thread File",
            "NormTermName": "File",
            "AssGrpName": "Tool, universal",
            "UsageName": "Thread File"
        },
        "05531": {
            "GenartName": "File Blade",
            "NormTermName": "File Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05532": {
            "GenartName": "File Handle",
            "NormTermName": "File Handle",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05533": {
            "GenartName": "Router",
            "NormTermName": "Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Router"
        },
        "05534": {
            "GenartName": "Side Milling Cutter",
            "NormTermName": "Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Side Milling Cutter"
        },
        "05535": {
            "GenartName": "Semicircular Milling Cutter",
            "NormTermName": "Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Semicircular Profile Cutter"
        },
        "05536": {
            "GenartName": "V-Shaped Milling Cutter",
            "NormTermName": "Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "V-Shaped Milling Cutter"
        },
        "05537": {
            "GenartName": "Shell-end Mill",
            "NormTermName": "Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Shell-end Mill"
        },
        "05538": {
            "GenartName": "Angular Mill",
            "NormTermName": "Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angular Mill"
        },
        "05539": {
            "GenartName": "Slot Miller",
            "NormTermName": "Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Slot Miller"
        },
        "05540": {
            "GenartName": "T-Slot Cutter",
            "NormTermName": "Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "T-Slot Cutter"
        },
        "05541": {
            "GenartName": "End Mill",
            "NormTermName": "Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "End Mill"
        },
        "05542": {
            "GenartName": "End Mill",
            "NormTermName": "Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "End Mill"
        },
        "05543": {
            "GenartName": "Cylindrical Milling Cutter",
            "NormTermName": "Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cylindrical Milling Cutter"
        },
        "05544": {
            "GenartName": "Sledgehammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Sledgehammer"
        },
        "05545": {
            "GenartName": "Machinist Hammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Machinist Hammer"
        },
        "05546": {
            "GenartName": "Soft Face Hammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Soft Face Hammer"
        },
        "05547": {
            "GenartName": "Bumping Hammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bumping Hammer"
        },
        "05548": {
            "GenartName": "Rubber Hammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Rubber Hammer"
        },
        "05549": {
            "GenartName": "Wooden Hammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wooden Hammer"
        },
        "05550": {
            "GenartName": "Mallet",
            "NormTermName": "Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Mallet"
        },
        "05551": {
            "GenartName": "Hammer Head",
            "NormTermName": "Hammer Head",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05552": {
            "GenartName": "Hammer Shaft",
            "NormTermName": "Hammer Shaft",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05553": {
            "GenartName": "Hammer Wedge",
            "NormTermName": "Hammer Wedge",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05554": {
            "GenartName": "Fusion Welding Machine",
            "NormTermName": "Welding Machine",
            "AssGrpName": "Tool, universal",
            "UsageName": "Fusion Welding Machine"
        },
        "05555": {
            "GenartName": "Hot Air Blower",
            "NormTermName": "Hot Air Blower",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05556": {
            "GenartName": "Smoothing Plane",
            "NormTermName": "Smoothing Plane",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05557": {
            "GenartName": "Scriber",
            "NormTermName": "Scriber",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05558": {
            "GenartName": "Chisel Scraper",
            "NormTermName": "Chisel Scraper",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05559": {
            "GenartName": "Cable Knife",
            "NormTermName": "Knife",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable Knife"
        },
        "05560": {
            "GenartName": "Multifunction Knife",
            "NormTermName": "Knife",
            "AssGrpName": "Tool, universal",
            "UsageName": "Multifunction Knife"
        },
        "05561": {
            "GenartName": "Protractors",
            "NormTermName": "Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": "Protractors"
        },
        "05562": {
            "GenartName": "Ruler",
            "NormTermName": "Ruler",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05563": {
            "GenartName": "Spirit Level",
            "NormTermName": "Scale",
            "AssGrpName": "Tool, universal",
            "UsageName": "Spirit Level"
        },
        "05564": {
            "GenartName": "Folding Measuring Stick",
            "NormTermName": "Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": "Folding Measuring Stick"
        },
        "05565": {
            "GenartName": "Levelling Device",
            "NormTermName": "Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": "Levelling Device"
        },
        "05566": {
            "GenartName": "Nut Splitter",
            "NormTermName": "Nut Splitter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05567": {
            "GenartName": "Nail Gun",
            "NormTermName": "Nail Gun",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05569": {
            "GenartName": "Paint Brush",
            "NormTermName": "Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Paint Brush"
        },
        "05570": {
            "GenartName": "Cleaning Brush",
            "NormTermName": "Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cleaning Brush"
        },
        "05571": {
            "GenartName": "Agitator",
            "NormTermName": "Agitator",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05572": {
            "GenartName": "Jack Saw",
            "NormTermName": "Saw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Jack Saw"
        },
        "05573": {
            "GenartName": "Hand-held Circular Saw",
            "NormTermName": "Saw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hand-held Circular Saw"
        },
        "05574": {
            "GenartName": "Hand Band Saw",
            "NormTermName": "Saw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hand Band Saw"
        },
        "05575": {
            "GenartName": "Hacksaw",
            "NormTermName": "Saw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hacksaw"
        },
        "05576": {
            "GenartName": "Japan Saw",
            "NormTermName": "Saw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Japan Saw"
        },
        "05577": {
            "GenartName": "Chainsaw",
            "NormTermName": "Saw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Chainsaw"
        },
        "05578": {
            "GenartName": "Keyhole Saw",
            "NormTermName": "Saw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Keyhole Saw"
        },
        "05580": {
            "GenartName": "Foam Saw",
            "NormTermName": "Saw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Foam Saw"
        },
        "05582": {
            "GenartName": "Plunge Saw",
            "NormTermName": "Saw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Plunge Saw"
        },
        "05583": {
            "GenartName": "Dry Cutter",
            "NormTermName": "Saw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Dry Cutter"
        },
        "05586": {
            "GenartName": "Wire Rope Cutter",
            "NormTermName": "Scissors",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wire Rope Cutter"
        },
        "05587": {
            "GenartName": "Mitre Shears",
            "NormTermName": "Scissors",
            "AssGrpName": "Tool, universal",
            "UsageName": "Mitre Shears"
        },
        "05588": {
            "GenartName": "Cable Shears",
            "NormTermName": "Scissors",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable Shears"
        },
        "05589": {
            "GenartName": "Combination Scissors",
            "NormTermName": "Scissors",
            "AssGrpName": "Tool, universal",
            "UsageName": "Combination Scissors"
        },
        "05592": {
            "GenartName": "Bolt Extractor",
            "NormTermName": "Bolt Extractor",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05593": {
            "GenartName": "Screw Extractor",
            "NormTermName": "Screw Extractor",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05594": {
            "GenartName": "Double Articulated Wrench",
            "NormTermName": "Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": "Articulated Wrench"
        },
        "05595": {
            "GenartName": "Open-end Spanner",
            "NormTermName": "Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": "Open-end Spanner"
        },
        "05596": {
            "GenartName": "Open-end Spanner",
            "NormTermName": "Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": "Open-end Wrench"
        },
        "05597": {
            "GenartName": "Ring Spanner",
            "NormTermName": "Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ring Spanner"
        },
        "05598": {
            "GenartName": "Slogging Ring Wrench",
            "NormTermName": "Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": "Slogging Ring Wrench"
        },
        "05599": {
            "GenartName": "Ring Spanner",
            "NormTermName": "Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ring Spanner"
        },
        "05600": {
            "GenartName": "Starter/Block Spanner",
            "NormTermName": "Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": "Starter/Block Spanner"
        },
        "05601": {
            "GenartName": "Spatula",
            "NormTermName": "Spatula",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05602": {
            "GenartName": "Scraper",
            "NormTermName": "Scraper",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05603": {
            "GenartName": "Pin Punch",
            "NormTermName": "Pin Punch",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05604": {
            "GenartName": "Taper Pin Punch",
            "NormTermName": "Taper Pin Punch",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05605": {
            "GenartName": "Hollow Punch",
            "NormTermName": "Hollow Punch",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05606": {
            "GenartName": "Hexagon Tubular Box Wrench",
            "NormTermName": "Hexagon Tubular Box Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05607": {
            "GenartName": "Countersink",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Countersink"
        },
        "05608": {
            "GenartName": "Slotted Countersink",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Slotted Countersink"
        },
        "05609": {
            "GenartName": "Sheet Metal Cone Bit",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Sheet Metal Cone Bit"
        },
        "05610": {
            "GenartName": "Body Drill",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Body Drill"
        },
        "05611": {
            "GenartName": "Double End Drill Bit",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Double End Drill Bit"
        },
        "05612": {
            "GenartName": "Wood Twist Drill",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Twist Drill"
        },
        "05613": {
            "GenartName": "Wood Drill Machine",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wood Drill Machine"
        },
        "05614": {
            "GenartName": "Attached Counterbore",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Attached Counterbore"
        },
        "05615": {
            "GenartName": "Wood Auger",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Auger Bit"
        },
        "05616": {
            "GenartName": "Forstner Bit",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Forstner Bit"
        },
        "05617": {
            "GenartName": "Drill Head",
            "NormTermName": "Drill Head",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05618": {
            "GenartName": "Tool Module",
            "NormTermName": "Tool Module",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05619": {
            "GenartName": "Notching Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Notching Pliers"
        },
        "05620": {
            "GenartName": "Bolt Cutter",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bolt Cutter"
        },
        "05621": {
            "GenartName": "Wire Twisting Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wire Twisting Pliers"
        },
        "05622": {
            "GenartName": "Pincer",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pincer"
        },
        "05623": {
            "GenartName": "Piston Ring Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Piston Ring Pliers"
        },
        "05624": {
            "GenartName": "Hole Punch",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hole Punch"
        },
        "05625": {
            "GenartName": "Impact Driver",
            "NormTermName": "Impact Driver",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05627": {
            "GenartName": "4 Way Wheel Brace",
            "NormTermName": "4 Way Wheel Brace",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05628": {
            "GenartName": "Soldering Iron",
            "NormTermName": "Soldering Iron",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05629": {
            "GenartName": "Extension, socket wrench",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Socket Wrench"
        },
        "05630": {
            "GenartName": "Shaft, socket wrench",
            "NormTermName": "Shaft",
            "AssGrpName": "Tool, universal",
            "UsageName": "Socket Wrench"
        },
        "05631": {
            "GenartName": "Universal Joint, socket wrench",
            "NormTermName": "Universal Joint",
            "AssGrpName": "Tool, universal",
            "UsageName": "Socket Wrench"
        },
        "05632": {
            "GenartName": "Connector Sleeve, flow divider (injection system)",
            "NormTermName": "Fitting",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Flow Divider (injection system)"
        },
        "05633": {
            "GenartName": "Oil Jet, piston underside cooling",
            "NormTermName": "Nozzle",
            "AssGrpName": "Lubrication",
            "UsageName": "Piston Underside Cooling"
        },
        "05634": {
            "GenartName": "Support Frame/Subframe",
            "NormTermName": "Frame",
            "AssGrpName": "Driver Cab",
            "UsageName": "Engine Support"
        },
        "05635": {
            "GenartName": "Gas Spring, tool cabinet flap",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Driver Cab",
            "UsageName": "Toolbox Door"
        },
        "05636": {
            "GenartName": "Panel Beating Tools",
            "NormTermName": "Panel Beating Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05637": {
            "GenartName": "Friction Ratchet",
            "NormTermName": "Ratchet",
            "AssGrpName": "Tool, universal",
            "UsageName": "Friction Ratchet"
        },
        "05638": {
            "GenartName": "Reversible Ratchet",
            "NormTermName": "Ratchet",
            "AssGrpName": "Tool, universal",
            "UsageName": "Reversible Ratchet"
        },
        "05639": {
            "GenartName": "Scrubber",
            "NormTermName": "Scrubber",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05640": {
            "GenartName": "Frame, gear lever gaiter",
            "NormTermName": "Frame",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gear Lever Gaiter"
        },
        "05641": {
            "GenartName": "Seal, adjusting element (eccentric shaft)",
            "NormTermName": "Seal",
            "AssGrpName": "Engine Timing",
            "UsageName": "Adjustment Element (eccentric shaft)"
        },
        "05642": {
            "GenartName": "Holder, sun visor",
            "NormTermName": "Holder",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Sun Visor"
        },
        "05643": {
            "GenartName": "Switch Valve Holder (differential lock)",
            "NormTermName": "Holder",
            "AssGrpName": "Axle Drive",
            "UsageName": "Switch Valve (differential lock)"
        },
        "05644": {
            "GenartName": "Seal, ignition distributor",
            "NormTermName": "Seal",
            "AssGrpName": "Ignition System",
            "UsageName": "Distributor"
        },
        "05645": {
            "GenartName": "Car Cover",
            "NormTermName": "Cover",
            "AssGrpName": "Accessories",
            "UsageName": "Vehicle"
        },
        "05646": {
            "GenartName": "Car Cover",
            "NormTermName": "Cover",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Vehicle"
        },
        "05647": {
            "GenartName": "Retaining Clip, rocker cover",
            "NormTermName": "Retaining Clip",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Cylinder Head Cover"
        },
        "05648": {
            "GenartName": "Banjo Bolt, oil filler neck",
            "NormTermName": "Screw",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Oil Filler Neck"
        },
        "05649": {
            "GenartName": "Fan Housing (engine cooling)",
            "NormTermName": "Housing",
            "AssGrpName": "Cooling System",
            "UsageName": "Fan (engine cooling)"
        },
        "05650": {
            "GenartName": "Cover, fan housing (engine cooling)",
            "NormTermName": "Lid",
            "AssGrpName": "Cooling System",
            "UsageName": "Fan Housing (engine cooling)"
        },
        "05651": {
            "GenartName": "Fuel Tap Lever",
            "NormTermName": "Rod",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tap"
        },
        "05652": {
            "GenartName": "Fuel Tap",
            "NormTermName": "Fuel Tap",
            "AssGrpName": "Fuel Supply System",
            "UsageName": null
        },
        "05653": {
            "GenartName": "Engine Trestle",
            "NormTermName": "Engine Trestle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05654": {
            "GenartName": "Relay, fuel preheater",
            "NormTermName": "Relay",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Preheater"
        },
        "05655": {
            "GenartName": "Control Valve, fuel quantity (common rail system)",
            "NormTermName": "Valve",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Fuel Quantity (common rail system)"
        },
        "05656": {
            "GenartName": "Pressure Relief Valve, common rail system",
            "NormTermName": "Valve",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Common Rail System (pressure control)"
        },
        "05657": {
            "GenartName": "Hinge Hole Drill",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hinge Hole Drill"
        },
        "05658": {
            "GenartName": "Disc Cutter",
            "NormTermName": "Disc Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05659": {
            "GenartName": "Flat Drill Bit",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flat Drill Bit"
        },
        "05660": {
            "GenartName": "Cased and Installation Drills",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cased and Installation Drills"
        },
        "05661": {
            "GenartName": "Concrete Drill",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Concrete Drill"
        },
        "05662": {
            "GenartName": "Stone Drills",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Stone Drills"
        },
        "05663": {
            "GenartName": "Tile Drill",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tile Drill"
        },
        "05664": {
            "GenartName": "Multi-Purpose Drill",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Multi-Purpose Drill"
        },
        "05665": {
            "GenartName": "Monier Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Monier Pliers"
        },
        "05666": {
            "GenartName": "Pipe Wrench/Water Pump Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Wrench/Water Pump Pliers"
        },
        "05667": {
            "GenartName": "Hose Clamp Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hose Clamp Pliers"
        },
        "05668": {
            "GenartName": "Shear Joint Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Shear Joint Pliers"
        },
        "05669": {
            "GenartName": "End Cutting Nippers",
            "NormTermName": "End Cutting Nippers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05670": {
            "GenartName": "Connector, brake line",
            "NormTermName": "Connector",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Line"
        },
        "05671": {
            "GenartName": "Balance Weight Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Balance Weight Pliers"
        },
        "05672": {
            "GenartName": "Circlip Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Circlip Pliers"
        },
        "05673": {
            "GenartName": "Memory Card",
            "NormTermName": "Data Memory",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Memory Card"
        },
        "05674": {
            "GenartName": "Memory Card Reader",
            "NormTermName": "Reader",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Memory Card"
        },
        "05675": {
            "GenartName": "Membrane Spring Clutch",
            "NormTermName": "Membrane Spring",
            "AssGrpName": "Clutch",
            "UsageName": "Motorcycle"
        },
        "05676": {
            "GenartName": "Ignition Cable",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Ignition System, universal",
            "UsageName": null
        },
        "05677": {
            "GenartName": "Clutch Stiffening Plate",
            "NormTermName": "Stiffening Plate",
            "AssGrpName": "Clutch",
            "UsageName": "Motorcycle"
        },
        "05678": {
            "GenartName": "Spray Booth",
            "NormTermName": "Spray Booth",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05679": {
            "GenartName": "Flow Cup",
            "NormTermName": "Flow Cup",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Spray Paint Systems"
        },
        "05680": {
            "GenartName": "Dosage Module, soot/particulate filter regeneration",
            "NormTermName": "Dosage Module",
            "AssGrpName": "Exhaust System",
            "UsageName": "Soot/Particulate Filter Regeneration"
        },
        "05681": {
            "GenartName": "Injection Unit, soot/particulate filter regeneration",
            "NormTermName": "Injection Unit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Soot/Particulate Filter Regeneration"
        },
        "05682": {
            "GenartName": "Short Shifter",
            "NormTermName": "Short Shifter",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "05683": {
            "GenartName": "Clutch Actuator",
            "NormTermName": "Clutch Actuator",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "05684": {
            "GenartName": "Relay, radiator fan",
            "NormTermName": "Relay",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator Fan"
        },
        "05685": {
            "GenartName": "Oil, all-wheel-drive coupling",
            "NormTermName": "Oil",
            "AssGrpName": "Axle Drive",
            "UsageName": "All-Wheel-Drive Coupling"
        },
        "05686": {
            "GenartName": "Oil, all-wheel-drive coupling",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "All-Wheel-Drive Coupling"
        },
        "05687": {
            "GenartName": "Ladder, roof rack (tailgate)",
            "NormTermName": "Ladder",
            "AssGrpName": "Carriers",
            "UsageName": "Roof Rack"
        },
        "05688": {
            "GenartName": "Relay, leveling control",
            "NormTermName": "Relay",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leveling Control"
        },
        "05689": {
            "GenartName": "Clutch Pressure Plate",
            "NormTermName": "Pressure Plate",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "05690": {
            "GenartName": "Release Tool Set",
            "NormTermName": "Assortment",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05691": {
            "GenartName": "Rim",
            "NormTermName": "Rim",
            "AssGrpName": "Wheels, universal",
            "UsageName": null
        },
        "05692": {
            "GenartName": "Complete Wheel",
            "NormTermName": "Complete Wheel",
            "AssGrpName": "Wheels",
            "UsageName": null
        },
        "05693": {
            "GenartName": "Complete Wheel",
            "NormTermName": "Complete Wheel",
            "AssGrpName": "Wheels, universal",
            "UsageName": null
        },
        "05694": {
            "GenartName": "Wheel Stud",
            "NormTermName": "Bolt",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Wheel"
        },
        "05695": {
            "GenartName": "Wheel Bolt",
            "NormTermName": "Screw",
            "AssGrpName": "Wheels, universal",
            "UsageName": null
        },
        "05696": {
            "GenartName": "Wheel Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Wheel"
        },
        "05697": {
            "GenartName": "Cap, wheel nut",
            "NormTermName": "Cap",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Wheel Nut"
        },
        "05698": {
            "GenartName": "Holder, hubcap",
            "NormTermName": "Holder",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Hubcap"
        },
        "05699": {
            "GenartName": "Switch, lifting axle control",
            "NormTermName": "Switch",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Lifting Axle Control"
        },
        "05700": {
            "GenartName": "Ampere Pliers",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Tool, universal",
            "UsageName": "Current Measurement"
        },
        "05701": {
            "GenartName": "Welding Machine",
            "NormTermName": "Welding Machine",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cut/Joining Systems"
        },
        "05702": {
            "GenartName": "Wire Feeder",
            "NormTermName": "Wire Feeder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cut/Joining Systems"
        },
        "05703": {
            "GenartName": "Operating Unit, lift",
            "NormTermName": "Operating Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Lift"
        },
        "05704": {
            "GenartName": "Access Ramp, vehicle lift",
            "NormTermName": "Access Ramp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Lift"
        },
        "05705": {
            "GenartName": "Access Ramp, vehicle lift",
            "NormTermName": "Access Flap",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Lift"
        },
        "05706": {
            "GenartName": "Column Extension, lift",
            "NormTermName": "Column Extension",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Lift"
        },
        "05707": {
            "GenartName": "Floor Level Balance",
            "NormTermName": "Floor Level Balance",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Lift"
        },
        "05708": {
            "GenartName": "Hose Line, soot/particulate filter regeneration",
            "NormTermName": "Hose Line",
            "AssGrpName": "Exhaust System",
            "UsageName": "Soot/Particulate Filter Regeneration"
        },
        "05709": {
            "GenartName": "Screw, starter",
            "NormTermName": "Screw",
            "AssGrpName": "Starter System",
            "UsageName": "Starter"
        },
        "05710": {
            "GenartName": "Sleeve Kit, engine",
            "NormTermName": "Cylinder Set",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Engine"
        },
        "05711": {
            "GenartName": "Housing Cover, oil pump",
            "NormTermName": "Lid",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "05713": {
            "GenartName": "Plastic Scissors",
            "NormTermName": "Scissors",
            "AssGrpName": "Tool, universal",
            "UsageName": "Plastic"
        },
        "05714": {
            "GenartName": "Burnishing Machine",
            "NormTermName": "Burnishing Machine",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05715": {
            "GenartName": "Connecting Flange, oil cooler",
            "NormTermName": "Flange",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Cooler"
        },
        "05717": {
            "GenartName": "Cover, V-belt",
            "NormTermName": "Cover",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Belt"
        },
        "05718": {
            "GenartName": "Insert, sports muffler",
            "NormTermName": "Insert",
            "AssGrpName": "Exhaust System",
            "UsageName": "Sports Muffler"
        },
        "05719": {
            "GenartName": "Butterfly Valve, heating control",
            "NormTermName": "Lid",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heater Control"
        },
        "05720": {
            "GenartName": "Touchscreen Stylus",
            "NormTermName": "Stylus",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Touchscreen"
        },
        "05721": {
            "GenartName": "Electric Cable, welding machine",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Welding Machine"
        },
        "05722": {
            "GenartName": "Control Unit, battery management",
            "NormTermName": "Control Unit",
            "AssGrpName": "Electric Supply, universal",
            "UsageName": "Battery Management"
        },
        "05723": {
            "GenartName": "ABS Control Light",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments, universal",
            "UsageName": "ABS Control Light"
        },
        "05724": {
            "GenartName": "Connecting Set, side marker light",
            "NormTermName": "Connector Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Side Marker Light"
        },
        "05725": {
            "GenartName": "Trim Cover, tail light",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Lights, universal",
            "UsageName": "Tail Light"
        },
        "05726": {
            "GenartName": "Trim Cover, direction indicator",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Lights, universal",
            "UsageName": "Direction Indicator"
        },
        "05727": {
            "GenartName": "Trim Cover, reverse light",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Lights, universal",
            "UsageName": "Reverse Light"
        },
        "05728": {
            "GenartName": "Eyelid, rear fog light",
            "NormTermName": "Cover",
            "AssGrpName": "Lights, universal",
            "UsageName": "Rear Fog Light"
        },
        "05729": {
            "GenartName": "Bulb Holder, end outline marker light",
            "NormTermName": "Lamp Base",
            "AssGrpName": "Lights, universal",
            "UsageName": "End Outline Marker Light"
        },
        "05731": {
            "GenartName": "Wheel Alignment Support, vehicle lift",
            "NormTermName": "Wheel Alignment Support",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Lift"
        },
        "05732": {
            "GenartName": "Access Extension, lift",
            "NormTermName": "Lifting extension",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Lift"
        },
        "05733": {
            "GenartName": "Attachment, lift",
            "NormTermName": "Attachment",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Lift"
        },
        "05734": {
            "GenartName": "Tools Storage Tray",
            "NormTermName": "Storage Tray",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tools"
        },
        "05735": {
            "GenartName": "Extension Set, leak detection set (aircon)",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Leak Detection Set (aircon)"
        },
        "05736": {
            "GenartName": "Pedal Travel Sensor, clutch pedal",
            "NormTermName": "Sensor",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Pedal"
        },
        "05737": {
            "GenartName": "Repair Kit, manual transmission",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "05738": {
            "GenartName": "Installation Frame, scissors lift",
            "NormTermName": "Installation Frame",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Lift"
        },
        "05739": {
            "GenartName": "Installation Frame, cylinder lift",
            "NormTermName": "Installation Frame",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Post Vehicle Lift"
        },
        "05740": {
            "GenartName": "Wheel Alignment Set, lift",
            "NormTermName": "Wheel Alignment Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wheel Alignment Testing Systems"
        },
        "05741": {
            "GenartName": "Chassis Head",
            "NormTermName": "Chassis Head",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "05742": {
            "GenartName": "Bump Stop, axle beam",
            "NormTermName": "Buffer",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Beam"
        },
        "05743": {
            "GenartName": "Retaining Spring, clutch release bearing",
            "NormTermName": "Spring",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Release Bearing"
        },
        "05744": {
            "GenartName": "Stud, torsion bar",
            "NormTermName": "Screw",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Torsion Bar"
        },
        "05745": {
            "GenartName": "Clutch Lever",
            "NormTermName": "Lever",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "05746": {
            "GenartName": "Guide, shift rod",
            "NormTermName": "Guide",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shift Rod"
        },
        "05747": {
            "GenartName": "Bellow, shift rod",
            "NormTermName": "Bellow",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shift Rod"
        },
        "05748": {
            "GenartName": "Dust Protection Cap, fork",
            "NormTermName": "Cap",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Fork (motorcycle)"
        },
        "05749": {
            "GenartName": "Seal Ring, fork",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Fork (motorcycle)"
        },
        "05750": {
            "GenartName": "Air Quality Sensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Air Quality"
        },
        "05751": {
            "GenartName": "Screw, shift fork",
            "NormTermName": "Screw",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shift Fork"
        },
        "05752": {
            "GenartName": "Guide, gear lever",
            "NormTermName": "Guide",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gear Lever"
        },
        "05753": {
            "GenartName": "Caster Shim, axle beam",
            "NormTermName": "Caster Shim",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Beam"
        },
        "05754": {
            "GenartName": "Holding Clamp, stop buffer (axle beam)",
            "NormTermName": "Holding Bracket",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Bump Stop (axle beam)"
        },
        "05755": {
            "GenartName": "Cover, chassis head",
            "NormTermName": "Lid",
            "AssGrpName": "Body",
            "UsageName": "Chassis Head"
        },
        "05756": {
            "GenartName": "Bush, tie rod end (steering damper mounting)",
            "NormTermName": "Bush",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod End (steering damper mounting)"
        },
        "05757": {
            "GenartName": "Light Insert, brake/tail light",
            "NormTermName": "Insert",
            "AssGrpName": "Lights, universal",
            "UsageName": "Brake-/Stoplight"
        },
        "05759": {
            "GenartName": "Spiral Hose",
            "NormTermName": "Spiral Hose",
            "AssGrpName": "Compressed Air System, universal",
            "UsageName": null
        },
        "05760": {
            "GenartName": "Holder, auxiliary water pump",
            "NormTermName": "Holder",
            "AssGrpName": "Cooling System",
            "UsageName": "Auxiliary Water Pump"
        },
        "05761": {
            "GenartName": "Charging Cable, electric vehicle",
            "NormTermName": "Charging Cable",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Electric Vehicle"
        },
        "05762": {
            "GenartName": "Universal Container",
            "NormTermName": "Tank",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Warehouse and operations equipment"
        },
        "05763": {
            "GenartName": "Control Unit, fuel pump",
            "NormTermName": "Control Unit",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "05764": {
            "GenartName": "Repair Set, wash water line",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": "Washer Fluid Pipe"
        },
        "05765": {
            "GenartName": "Compressor, compressed air system",
            "NormTermName": "Compressor",
            "AssGrpName": "Compressed Air System, universal",
            "UsageName": "Compressed Air"
        },
        "05766": {
            "GenartName": "Valve, compressed-air system",
            "NormTermName": "Valve",
            "AssGrpName": "Compressed Air System, universal",
            "UsageName": "Compressed Air"
        },
        "05767": {
            "GenartName": "Display, self-diagnosis unit",
            "NormTermName": "Display",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Self-diagnosis Unit"
        },
        "05768": {
            "GenartName": "Storage Tray, cylinder vehicle lift",
            "NormTermName": "Storage Tray",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cylinder Vehicle Lift"
        },
        "05769": {
            "GenartName": "Field Probe, oscilloscope",
            "NormTermName": "Probe",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Oscilloscope"
        },
        "05770": {
            "GenartName": "Mortise Chisel",
            "NormTermName": "Chisel",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05771": {
            "GenartName": "Counterbore",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05772": {
            "GenartName": "Step Drill",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Step Drill"
        },
        "05773": {
            "GenartName": "Tenon Cutter",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tenon Cutter"
        },
        "05774": {
            "GenartName": "Twist Drill Bit",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Twist Drill Bit"
        },
        "05775": {
            "GenartName": "Drill",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drill"
        },
        "05776": {
            "GenartName": "Pressure Regulating Nozzle",
            "NormTermName": "Nozzle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pressure Regulating Nozzle, compressed air"
        },
        "05777": {
            "GenartName": "Standard Nozzle",
            "NormTermName": "Nozzle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Standard Nozzle, compressed air system"
        },
        "05778": {
            "GenartName": "Roofing Hammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Roofing Hammer"
        },
        "05779": {
            "GenartName": "Masons Hammer",
            "NormTermName": "Hammer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Masons Hammer"
        },
        "05780": {
            "GenartName": "Soldering Gun",
            "NormTermName": "Soldering Gun",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05781": {
            "GenartName": "Soldering Tip",
            "NormTermName": "Soldering Tip",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05783": {
            "GenartName": "Jaw Pads, vice",
            "NormTermName": "Jaw Pads",
            "AssGrpName": "Tool, universal",
            "UsageName": "Vice"
        },
        "05784": {
            "GenartName": "Rotary Plate, vice",
            "NormTermName": "Rotary Plate",
            "AssGrpName": "Tool, universal",
            "UsageName": "Vice"
        },
        "05785": {
            "GenartName": "Seat Frame",
            "NormTermName": "Seat Frame",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "05786": {
            "GenartName": "Assortment, exhaust pressure sensor",
            "NormTermName": "Assortment",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Exhaust Pressure Sensor"
        },
        "05787": {
            "GenartName": "Assortment, exhaust pressure sensor",
            "NormTermName": "Assortment",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Exhaust Pressure Sensor"
        },
        "05788": {
            "GenartName": "Removal Drift, camshaft sprocket",
            "NormTermName": "Removal Drift",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft Sprocket"
        },
        "05789": {
            "GenartName": "Support Device, transmission jack",
            "NormTermName": "Support Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Transmission Jack"
        },
        "05790": {
            "GenartName": "Axle Nut Wrench",
            "NormTermName": "Axle Nut",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Axle Nut"
        },
        "05791": {
            "GenartName": "Press-on Mandrel Set, valve stem seal",
            "NormTermName": "Press-on Mandrel Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Valve Stem Seal"
        },
        "05792": {
            "GenartName": "Holder, dial gauge",
            "NormTermName": "Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Dial Gauge"
        },
        "05793": {
            "GenartName": "Pressure Plate Set, spring compressor",
            "NormTermName": "Pressure Plate Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spring Compressor"
        },
        "05794": {
            "GenartName": "Insert Holder, socket wrench",
            "NormTermName": "Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Socket Wrench Insert"
        },
        "05795": {
            "GenartName": "Cutting Wheel, pipe cutter",
            "NormTermName": "Cutting Wheel",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Cutter"
        },
        "05796": {
            "GenartName": "Milling Tool Set, glow plug",
            "NormTermName": "Milling Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow Plug"
        },
        "05797": {
            "GenartName": "Torque Limiter",
            "NormTermName": "Torque Limiter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05798": {
            "GenartName": "Thread Cutter Tool Set, spark plug",
            "NormTermName": "Thread Cutter Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spark Plug"
        },
        "05799": {
            "GenartName": "Puller Set, glow plug pin",
            "NormTermName": "Puller Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow Plug Pin"
        },
        "05801": {
            "GenartName": "Wing Protector",
            "NormTermName": "Wing Protector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05802": {
            "GenartName": "Puller, steering column stalk",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Pitman Arm"
        },
        "05803": {
            "GenartName": "Honing Tool",
            "NormTermName": "Honing Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05804": {
            "GenartName": "Brake Lines Spanner",
            "NormTermName": "Spanner, double ring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Line"
        },
        "05805": {
            "GenartName": "Honing Stone Set",
            "NormTermName": "Honing Stone Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05806": {
            "GenartName": "Vernier Calliper, drum brake",
            "NormTermName": "Vernier Caliper",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drum Brake"
        },
        "05807": {
            "GenartName": "Adjusting Lever, brake shoe",
            "NormTermName": "Adjusting Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Shoe"
        },
        "05808": {
            "GenartName": "Mandrel",
            "NormTermName": "Mandrel",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05809": {
            "GenartName": "Dismantling Tool, stop light",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Stop Light"
        },
        "05810": {
            "GenartName": "Removal Lever, door panel",
            "NormTermName": "Removal Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Interior Door Panel"
        },
        "05811": {
            "GenartName": "Puller, steering wheel",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Steering Wheel"
        },
        "05812": {
            "GenartName": "Release Tool, steering lock cover",
            "NormTermName": "Release Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Steering Lock"
        },
        "05813": {
            "GenartName": "Release Tool, airbag",
            "NormTermName": "Release Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Airbag"
        },
        "05814": {
            "GenartName": "Release Tool Set, radio",
            "NormTermName": "Release Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Radio CD"
        },
        "05816": {
            "GenartName": "Cutting Wire Set, glass removal",
            "NormTermName": "Cutting Wire Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Glass removal"
        },
        "05817": {
            "GenartName": "Allen Key, screwdriver bit",
            "NormTermName": "Allen Key",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05818": {
            "GenartName": "Removal Pliers, door panel",
            "NormTermName": "Release Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Interior Door Panel"
        },
        "05819": {
            "GenartName": "Release Tool Set, ignition lock",
            "NormTermName": "Release Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ignition Lock"
        },
        "05820": {
            "GenartName": "Puller, steering wheel spacer sleeve",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Steering Wheel Spacer Sleeve"
        },
        "05821": {
            "GenartName": "Adjustment Key, headlight",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Front Light"
        },
        "05822": {
            "GenartName": "Adjustment Tool, window wash water jet",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Windscreen Washer Fluid Jet"
        },
        "05823": {
            "GenartName": "Mounting Tool, window seal",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Window Seal"
        },
        "05824": {
            "GenartName": "Pliers, upholstery clamps",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Upholstery Clamps"
        },
        "05825": {
            "GenartName": "Removal Tool, door handle/window winder clip",
            "NormTermName": "Removal Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Door Grip-/window crank retainer"
        },
        "05826": {
            "GenartName": "Feeler Gauge",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05827": {
            "GenartName": "Claw-type Grip",
            "NormTermName": "Claw-type Grip",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05828": {
            "GenartName": "Roller Screw Set",
            "NormTermName": "Roller Screw Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05829": {
            "GenartName": "Magnetizing/Demagnetizing Block",
            "NormTermName": "Magnetizing/Demagnetizing Block",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05830": {
            "GenartName": "Micrometer",
            "NormTermName": "Micrometer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05831": {
            "GenartName": "Tweezer Set",
            "NormTermName": "Tweezer Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05832": {
            "GenartName": "Screwdriver Tool Set",
            "NormTermName": "Screwdriver Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05833": {
            "GenartName": "Mounting Lever",
            "NormTermName": "Mounting Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05834": {
            "GenartName": "Workshop Mirror",
            "NormTermName": "Workshop Mirror",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05835": {
            "GenartName": "Divider, drawer (tool trolley)",
            "NormTermName": "Divider",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drawer (tool trolley)"
        },
        "05836": {
            "GenartName": "Storage Case, straightedge",
            "NormTermName": "Storage Case",
            "AssGrpName": "Tool, universal",
            "UsageName": "Straightedge"
        },
        "05837": {
            "GenartName": "Hook Tool Set",
            "NormTermName": "Mounting Aid",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hook Set"
        },
        "05838": {
            "GenartName": "Mounting Lever Set",
            "NormTermName": "Mounting Lever Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05839": {
            "GenartName": "Magnetic Lift",
            "NormTermName": "Magnetic Lift",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05840": {
            "GenartName": "Socket Wrench Set",
            "NormTermName": "Socket Wrench Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "05841": {
            "GenartName": "Pliers Set, circlip",
            "NormTermName": "Pliers Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Circlip"
        },
        "05842": {
            "GenartName": "Mounting Tool Set, window seal",
            "NormTermName": "Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Window Seal"
        },
        "05843": {
            "GenartName": "Retaining Pin, camshaft",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Camshaft"
        },
        "05844": {
            "GenartName": "Retaining Tool, camshaft",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Camshaft"
        },
        "05845": {
            "GenartName": "Pliers, spark plug socket",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Spark Plug Head"
        },
        "05846": {
            "GenartName": "Test Tool, brake line",
            "NormTermName": "Tester",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Line"
        },
        "05847": {
            "GenartName": "Adapter, flaring tool",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bordering Tool"
        },
        "05848": {
            "GenartName": "Adapter, impact puller",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Puller"
        },
        "05849": {
            "GenartName": "Adapter, mounting device (wheel hub/wheel bearing)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "05850": {
            "GenartName": "Adapter Set, mounting device (wheel hub/wheel bearing)",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "05851": {
            "GenartName": "Dismantling Tool, seal ring",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Seal Ring"
        },
        "05852": {
            "GenartName": "Spindle, puller",
            "NormTermName": "Spindle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Puller"
        },
        "05853": {
            "GenartName": "Spindle Set, puller",
            "NormTermName": "Spindle Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Puller"
        },
        "05854": {
            "GenartName": "Installation Tool, fan (fluid friction coupling)",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Fan (fluid friction coupling)"
        },
        "05855": {
            "GenartName": "Safety Guard Set, spring compressor pressure plate",
            "NormTermName": "Protection Agent",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Compressor"
        },
        "05856": {
            "GenartName": "Pressure Plate Set, spring compressor",
            "NormTermName": "Pressure Plate Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Compressor"
        },
        "05857": {
            "GenartName": "Pliers, bulb",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bulb"
        },
        "05858": {
            "GenartName": "Ejector Set, ball joint",
            "NormTermName": "Ejector Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ball Joint"
        },
        "05859": {
            "GenartName": "Puller, steering column stalk",
            "NormTermName": "Puller",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Pitman Arm"
        },
        "05860": {
            "GenartName": "Puller, injector pump gear",
            "NormTermName": "Puller",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injector Pump Gear"
        },
        "05861": {
            "GenartName": "Puller, guide rail pin",
            "NormTermName": "Extractor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Guide Rail Pin"
        },
        "05862": {
            "GenartName": "Puller, crankshaft gear",
            "NormTermName": "Puller",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Crankshaft Gear"
        },
        "05863": {
            "GenartName": "Mounting Device, engine/ transmission unit",
            "NormTermName": "Mounting Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Engine/ Transmission Unit"
        },
        "05864": {
            "GenartName": "Adapter, mounting device (engine/transmission unit)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Mounting Device (engine/transmission unit)"
        },
        "05865": {
            "GenartName": "Retaining Pin, crankshaft",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Crankshaft"
        },
        "05866": {
            "GenartName": "Retaining Pin, flywheel",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Flywheel"
        },
        "05867": {
            "GenartName": "Retaining Tool, crankshaft",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Crankshaft"
        },
        "05868": {
            "GenartName": "Retaining Tool Set, valve timing",
            "NormTermName": "Retaining Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Timing"
        },
        "05869": {
            "GenartName": "Pliers, glow plug socket",
            "NormTermName": "Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Glow Plug Socket"
        },
        "05870": {
            "GenartName": "Fuel Filter Spanner",
            "NormTermName": "Key",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Fuel Filter"
        },
        "05871": {
            "GenartName": "Adapter, cooling system pressure test set",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Cooling System Pressure Test Set"
        },
        "05872": {
            "GenartName": "Hose Connector Set, pressure sensor (soot/part. filter)",
            "NormTermName": "Connector Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Pressure Sensor (soot/particulate filter)"
        },
        "05873": {
            "GenartName": "Fork Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Fork Oil (Motorcycle)"
        },
        "05874": {
            "GenartName": "Mounting Ring, dust cap (brake caliper pistons)",
            "NormTermName": "Mounting/Dismantling Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Dust Cap (brake caliper piston)"
        },
        "05875": {
            "GenartName": "Kneepad",
            "NormTermName": "Kneepad",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05876": {
            "GenartName": "Mounting Base, cable tie",
            "NormTermName": "Mounting Base",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "05877": {
            "GenartName": "Conductive Paint",
            "NormTermName": "Conductive Paint",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05878": {
            "GenartName": "Sealing Screw, torque converter",
            "NormTermName": "Screw",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Torque Converter"
        },
        "05879": {
            "GenartName": "Seal Ring, corrugated pipe",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Corrugated Pipe"
        },
        "05880": {
            "GenartName": "Lens, ABS control lamp",
            "NormTermName": "Lens",
            "AssGrpName": "Instruments, universal",
            "UsageName": "ABS Control Light"
        },
        "05881": {
            "GenartName": "OBD Adapter, tyre-pressure monitoring system",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "05882": {
            "GenartName": "Swirl Flaps, suction pipe",
            "NormTermName": "Swirl Flap Set",
            "AssGrpName": "Air Supply",
            "UsageName": "Intake Manifold"
        },
        "05883": {
            "GenartName": "Switch, rear hatch release",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Rear Hatch Release"
        },
        "05884": {
            "GenartName": "Switch, coming home function",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Coming Home Function"
        },
        "05885": {
            "GenartName": "Blind Cover, switch",
            "NormTermName": "Lid",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Switch"
        },
        "05886": {
            "GenartName": "Mount, lift table",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lift Table"
        },
        "05887": {
            "GenartName": "Pipe Cutter, exhaust system",
            "NormTermName": "Pipe Cutter",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Exhaust Pipe"
        },
        "05888": {
            "GenartName": "Cutting Chain, exhaust pipe cutter",
            "NormTermName": "Cutting Chain",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Exhaust System Pipe Cutter"
        },
        "05889": {
            "GenartName": "Expander, exhaust pipe",
            "NormTermName": "Expander",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Exhaust Pipe"
        },
        "05890": {
            "GenartName": "Cutting Tool Set, exhaust pipe",
            "NormTermName": "Cutting Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Exhaust Pipe"
        },
        "05891": {
            "GenartName": "Cutting Wheel, exhaust pipe cutter",
            "NormTermName": "Cutting Wheel",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Exhaust System Pipe Cutter"
        },
        "05892": {
            "GenartName": "Expander Set, exhaust pipe",
            "NormTermName": "Expander Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Exhaust Pipe"
        },
        "05893": {
            "GenartName": "Adapter, spring compressor",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Compressor"
        },
        "05894": {
            "GenartName": "Holder, spring compressor",
            "NormTermName": "Bracket",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Compressor"
        },
        "05895": {
            "GenartName": "Adapter, pressure plate spring compressor",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Pressure Plate Spring Compressor"
        },
        "05896": {
            "GenartName": "Pin Wrench, strut",
            "NormTermName": "Pin Wrench Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Suspension Strut"
        },
        "05897": {
            "GenartName": "Holder Tool, strut",
            "NormTermName": "Holding Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Suspension Strut"
        },
        "05898": {
            "GenartName": "Separator, ball joint",
            "NormTermName": "Spread Lever",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ball Joint Mounting"
        },
        "05899": {
            "GenartName": "Spreader Socket Wrench Insert, ball joint",
            "NormTermName": "Spreader Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ball Joint Mounting"
        },
        "05900": {
            "GenartName": "Oil Filter Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Oil Filter"
        },
        "05901": {
            "GenartName": "Service Dipstick, oil change",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Oil Change"
        },
        "05902": {
            "GenartName": "Pressure Pipe, pressure sensor (soot/particulate filter)",
            "NormTermName": "Pipe",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Pressure Sensor (soot/particulate filter)"
        },
        "05903": {
            "GenartName": "Hose Connector Set, pressure sensor (soot/part. filter)",
            "NormTermName": "Connector Kit",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Pressure Sensor (soot/particulate filter)"
        },
        "05904": {
            "GenartName": "Cover, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "05905": {
            "GenartName": "Breakout Box",
            "NormTermName": "Breakout Box",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "05906": {
            "GenartName": "Adapter, breakout box",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Breakout Box"
        },
        "05907": {
            "GenartName": "Weld-On Bung, pressure sensor (soot/particulate filter)",
            "NormTermName": "Weld-On Bung",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Pressure Sensor (soot/particulate filter)"
        },
        "05908": {
            "GenartName": "Weld-On Bung, exhaust temperature sensor",
            "NormTermName": "Weld-On Bung",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Emission Temperature Sensor"
        },
        "05909": {
            "GenartName": "Pressure Piece, transmission bearing",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Transmission Bearing Mounting"
        },
        "05910": {
            "GenartName": "Pressure Plate, transmission bearing",
            "NormTermName": "Pressure Plate",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Transmission Bearing Mounting"
        },
        "05911": {
            "GenartName": "Guide Bolt, gearshift",
            "NormTermName": "Guide Bolt",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "05912": {
            "GenartName": "Repair Kit, optic fibre cable",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Optic Fibre Cable"
        },
        "05913": {
            "GenartName": "Housing, auxiliary direction indicator",
            "NormTermName": "Housing",
            "AssGrpName": "Signal System",
            "UsageName": "Auxiliary Direction Indicator"
        },
        "05914": {
            "GenartName": "Housing, auxiliary direction indicator",
            "NormTermName": "Housing",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Auxiliary Direction Indicator"
        },
        "05915": {
            "GenartName": "Grille, rotating beacon",
            "NormTermName": "Grille",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Rotating Beacon"
        },
        "05916": {
            "GenartName": "Frame, interior light",
            "NormTermName": "Frame",
            "AssGrpName": "Lights, universal",
            "UsageName": "Interior Light"
        },
        "05917": {
            "GenartName": "Frame, interior light",
            "NormTermName": "Frame",
            "AssGrpName": "Lights",
            "UsageName": "Interior Light"
        },
        "05918": {
            "GenartName": "Filling Device, transmission oil",
            "NormTermName": "Filling Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Transmission Oil"
        },
        "05919": {
            "GenartName": "Switch, low beam",
            "NormTermName": "Switch",
            "AssGrpName": "Lights, universal",
            "UsageName": "Low Beam"
        },
        "05920": {
            "GenartName": "Switch, all-wheel drive",
            "NormTermName": "Switch",
            "AssGrpName": "Axle Drive, universal",
            "UsageName": "All-Wheel Drive"
        },
        "05921": {
            "GenartName": "Switch, work light",
            "NormTermName": "Switch",
            "AssGrpName": "Lights, universal",
            "UsageName": "Worklight"
        },
        "05922": {
            "GenartName": "Switch, end outline marker light",
            "NormTermName": "Switch",
            "AssGrpName": "Lights, universal",
            "UsageName": "End Outline Marker Light"
        },
        "05923": {
            "GenartName": "Switch, spotlight",
            "NormTermName": "Switch",
            "AssGrpName": "Lights, universal",
            "UsageName": "Spotlight"
        },
        "05924": {
            "GenartName": "Switch, park brake actuation",
            "NormTermName": "Switch",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Detecting brake activation"
        },
        "05925": {
            "GenartName": "Switch, horn",
            "NormTermName": "Switch",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Horn"
        },
        "05926": {
            "GenartName": "Switch, signal horn",
            "NormTermName": "Switch",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Signal Horn"
        },
        "05927": {
            "GenartName": "Switch, wiper wash interval",
            "NormTermName": "Switch",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": "Wipe-/Wash Interval"
        },
        "05928": {
            "GenartName": "Switch, park light",
            "NormTermName": "Switch",
            "AssGrpName": "Lights, universal",
            "UsageName": "Park Light"
        },
        "05930": {
            "GenartName": "Switch, parking light",
            "NormTermName": "Switch",
            "AssGrpName": "Lights, universal",
            "UsageName": "Parking Light"
        },
        "05931": {
            "GenartName": "Start/Stop Button",
            "NormTermName": "Start/Stop Button",
            "AssGrpName": "Starter System, universal",
            "UsageName": null
        },
        "05932": {
            "GenartName": "Bearing Shell, air spring bellow carrier",
            "NormTermName": "Bearing Shell",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Spring Bellow Carrier"
        },
        "05934": {
            "GenartName": "Bolt, axle strut",
            "NormTermName": "Bolt",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Strut"
        },
        "05935": {
            "GenartName": "Output Shaft, manual transmission",
            "NormTermName": "Shaft",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "05936": {
            "GenartName": "Valve Cleaner",
            "NormTermName": "Valve Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05937": {
            "GenartName": "Marking Paint",
            "NormTermName": "Marking Paint",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05938": {
            "GenartName": "Cleaner, air flow meter",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Air Mass Sensor"
        },
        "05939": {
            "GenartName": "Adjustment/Control Element, parking brake",
            "NormTermName": "Adjusting-/Control Element",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake"
        },
        "05940": {
            "GenartName": "Adjusting Nut, clutch cable",
            "NormTermName": "Nut",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Cable"
        },
        "05941": {
            "GenartName": "Guide, clutch cable",
            "NormTermName": "Guide",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Cable"
        },
        "05942": {
            "GenartName": "Silicone Remover",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Silicone Remover"
        },
        "05943": {
            "GenartName": "Securing Bolt, clutch cable",
            "NormTermName": "Bolt",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Cable"
        },
        "05944": {
            "GenartName": "Hand Cleaning Cloths",
            "NormTermName": "Hand Cleaning Cloths",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05945": {
            "GenartName": "Rim Paint",
            "NormTermName": "Paint",
            "AssGrpName": "Chemical Products",
            "UsageName": "Rims"
        },
        "05946": {
            "GenartName": "Clutch Peak Torque Limiter",
            "NormTermName": "Torque Limiter",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch peak torque limiter"
        },
        "05947": {
            "GenartName": "Cleaner, dye penetrant crack testing",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Dye penetrant crack testing"
        },
        "05948": {
            "GenartName": "Penetrant, dye crack testing",
            "NormTermName": "Penetrant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Dye penetrant crack testing"
        },
        "05949": {
            "GenartName": "Developer, dye penetrant crack testing",
            "NormTermName": "Developer",
            "AssGrpName": "Chemical Products",
            "UsageName": "Dye penetrant crack testing"
        },
        "05950": {
            "GenartName": "Long-life Grease",
            "NormTermName": "Long-life Grease",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05951": {
            "GenartName": "Adhesive Grease",
            "NormTermName": "Adhesive Grease",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05952": {
            "GenartName": "Seal, clutch cable (guide)",
            "NormTermName": "Seal",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Cable (Guide)"
        },
        "05953": {
            "GenartName": "Drilling/Cutting Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Drilling/Cutting"
        },
        "05954": {
            "GenartName": "Electronic Cleaner",
            "NormTermName": "Electronic Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05955": {
            "GenartName": "Industrial Cleaner",
            "NormTermName": "Industrial Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05956": {
            "GenartName": "Cleaning Concentrate",
            "NormTermName": "Cleaning Concentrate",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05957": {
            "GenartName": "Dry Lubricant",
            "NormTermName": "Dry Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05958": {
            "GenartName": "High Pressure Grease",
            "NormTermName": "High Pressure Grease",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05959": {
            "GenartName": "Gear Lubricant",
            "NormTermName": "Gear Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05960": {
            "GenartName": "Pipe Cleaner",
            "NormTermName": "Pipe Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05961": {
            "GenartName": "Stainless Steel Cleaner",
            "NormTermName": "Stainless Steel Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05962": {
            "GenartName": "Anti Corrosion Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Corrosion Protection"
        },
        "05963": {
            "GenartName": "Lock Lubricant",
            "NormTermName": "Lock Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05964": {
            "GenartName": "Multi-function Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Multi-function Oil"
        },
        "05965": {
            "GenartName": "Drilling/Cutting Paste",
            "NormTermName": "Drilling/Cutting Paste",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05966": {
            "GenartName": "Dewatering Agent",
            "NormTermName": "Dewatering Agent",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05967": {
            "GenartName": "Paint Remover",
            "NormTermName": "Paint Remover",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05968": {
            "GenartName": "Pickling Paste",
            "NormTermName": "Pickling Paste",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05969": {
            "GenartName": "V-Belt Spray",
            "NormTermName": "V-Belt Spray",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "05970": {
            "GenartName": "Rivet",
            "NormTermName": "Rivet",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "05971": {
            "GenartName": "Pin",
            "NormTermName": "Pin",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "05972": {
            "GenartName": "Holding Clamp, handbrake rod",
            "NormTermName": "Holding Bracket",
            "AssGrpName": "Braking System",
            "UsageName": "Handbrake Lever"
        },
        "05973": {
            "GenartName": "Hose Clamp",
            "NormTermName": "Clamp",
            "AssGrpName": "Standard Parts",
            "UsageName": "Hose"
        },
        "05974": {
            "GenartName": "Mounting Set, heater flap cable",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heater Flap Cable"
        },
        "05975": {
            "GenartName": "Boot, heater flap cable",
            "NormTermName": "Bellow",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heater Flap Cable"
        },
        "05976": {
            "GenartName": "Intermediate piece, heater flap cable - heater flap lever",
            "NormTermName": "Connector",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heater flap cable - heater flap lever"
        },
        "05977": {
            "GenartName": "Boot, axle pipe",
            "NormTermName": "Bellow",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Axle Pipe"
        },
        "05978": {
            "GenartName": "Nut, wheel hub",
            "NormTermName": "Nut",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Wheel Hub"
        },
        "05979": {
            "GenartName": "Plug, brake support plate",
            "NormTermName": "Plug",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Mounting Plate"
        },
        "05980": {
            "GenartName": "Protection Hose, accelerator cable",
            "NormTermName": "Hose",
            "AssGrpName": "Air Supply",
            "UsageName": "Accelerator Cable"
        },
        "05981": {
            "GenartName": "Insulating Bush, steering wheel",
            "NormTermName": "Isolation Bush",
            "AssGrpName": "Steering",
            "UsageName": "Steering Wheel"
        },
        "05982": {
            "GenartName": "Assortment, hose clamps",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Hose Clamp"
        },
        "05983": {
            "GenartName": "Reset Ring, direction indicator switch/lever",
            "NormTermName": "Reset Ring",
            "AssGrpName": "Signal System",
            "UsageName": "Direction Indicator Switch/Lever"
        },
        "05984": {
            "GenartName": "Bearing, steeering column",
            "NormTermName": "Bearing",
            "AssGrpName": "Steering",
            "UsageName": "Steering Column"
        },
        "05985": {
            "GenartName": "Plunger, fuel pump",
            "NormTermName": "Tappet",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "05986": {
            "GenartName": "Supporting Ring, steering column",
            "NormTermName": "Supporting Ring",
            "AssGrpName": "Steering",
            "UsageName": "Steering Column"
        },
        "05987": {
            "GenartName": "Guide Ring, stabiliser coupling rod",
            "NormTermName": "Guide Ring",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser Coupling Rod"
        },
        "05988": {
            "GenartName": "Trunnion, accelerator cable",
            "NormTermName": "Nipple",
            "AssGrpName": "Air Supply",
            "UsageName": "Trunnion, accelerator cable"
        },
        "05989": {
            "GenartName": "Guide Tube, bonnet cable",
            "NormTermName": "Guide Tube",
            "AssGrpName": "Body",
            "UsageName": "Bonnet Cable"
        },
        "05990": {
            "GenartName": "Tension Cable, folding roof",
            "NormTermName": "Tension Cable",
            "AssGrpName": "Body",
            "UsageName": "Folding Roof"
        },
        "05991": {
            "GenartName": "Cable Pull, sunroof",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Body",
            "UsageName": "Sunroof"
        },
        "05992": {
            "GenartName": "Handle, hand brake lever",
            "NormTermName": "Handle",
            "AssGrpName": "Braking System",
            "UsageName": "Hand Brake Lever"
        },
        "05993": {
            "GenartName": "Bolt, parking brake lever",
            "NormTermName": "Bolt",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake Lever"
        },
        "05994": {
            "GenartName": "Tension Spring, brake pedal",
            "NormTermName": "Spring",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Pedal"
        },
        "05995": {
            "GenartName": "Repair Set, accelerator pedal",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Air Supply",
            "UsageName": "Accelerator Pedal"
        },
        "05996": {
            "GenartName": "Repair Set, steering column",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Steering",
            "UsageName": "Steering Column"
        },
        "05997": {
            "GenartName": "Brake-/Clutch Pedal Unit",
            "NormTermName": "Pedal Unit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake-/Clutch Pedal Unit"
        },
        "05998": {
            "GenartName": "Test Spray, smoke alarm",
            "NormTermName": "Test Spray",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Smoke Alarm"
        },
        "05999": {
            "GenartName": "Hook",
            "NormTermName": "Hook",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "06000": {
            "GenartName": "Shackle",
            "NormTermName": "Shackle",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "06001": {
            "GenartName": "Cable Shortening Set",
            "NormTermName": "Cable Shortening Set",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "06002": {
            "GenartName": "Pedal Pad Set",
            "NormTermName": "Pedal Pad Set",
            "AssGrpName": "Accessories",
            "UsageName": null
        },
        "06004": {
            "GenartName": "Screw Cap",
            "NormTermName": "Screw Cap",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "06005": {
            "GenartName": "Nut, screw top",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Screw Cap"
        },
        "06006": {
            "GenartName": "Cool Bag",
            "NormTermName": "Cool Bag",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "06007": {
            "GenartName": "Electric Motor, steering gear",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Steering",
            "UsageName": "Steering Gear"
        },
        "06008": {
            "GenartName": "Grommet",
            "NormTermName": "Grommet",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "06009": {
            "GenartName": "Workshop Press",
            "NormTermName": "Workshop Press",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06010": {
            "GenartName": "Carrier Plate, transmission jack",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Transmission Jack"
        },
        "06011": {
            "GenartName": "Software, welder",
            "NormTermName": "Software",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Welding Machine"
        },
        "06012": {
            "GenartName": "Welding Arm, welder",
            "NormTermName": "Welding Arm",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Welding Machine"
        },
        "06013": {
            "GenartName": "Electrode, welder",
            "NormTermName": "Electrode",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Welding Machine"
        },
        "06014": {
            "GenartName": "Electrode Set, welder",
            "NormTermName": "Electrode Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Welding Machine"
        },
        "06015": {
            "GenartName": "Trolley, battery charger/starter",
            "NormTermName": "Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Battery Charger/Starter"
        },
        "06016": {
            "GenartName": "Holder, battery charger/starter",
            "NormTermName": "Bracket",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Battery Charger/Starter"
        },
        "06017": {
            "GenartName": "Colour Ribbon, battery tester",
            "NormTermName": "Colour Ribbon",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Battery Tester"
        },
        "06018": {
            "GenartName": "Switch, rear window heating",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Rear Windscreen Heating"
        },
        "06019": {
            "GenartName": "Switch, windscreen heating",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Windscreen Heating"
        },
        "06020": {
            "GenartName": "Switch, electronic damper regulation",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Electronic damping adjustment"
        },
        "06021": {
            "GenartName": "Switch, differential lock",
            "NormTermName": "Switch",
            "AssGrpName": "Axle Drive, universal",
            "UsageName": "Differential Lock"
        },
        "06022": {
            "GenartName": "Switch, interior blower",
            "NormTermName": "Switch",
            "AssGrpName": "Heating/Ventilation, universal",
            "UsageName": "Interior Blower"
        },
        "06023": {
            "GenartName": "Switch, lift/lower (attachments)",
            "NormTermName": "Switch",
            "AssGrpName": "Power Take-Off, universal",
            "UsageName": "Lifting/Lowering (attachments)"
        },
        "06024": {
            "GenartName": "Switch, neutral position",
            "NormTermName": "Switch",
            "AssGrpName": "Power Take-Off, universal",
            "UsageName": "Neutral position"
        },
        "06025": {
            "GenartName": "Switch, wipe interval control",
            "NormTermName": "Switch",
            "AssGrpName": "Headlight Cleaning, universal",
            "UsageName": "Windscreen Wiper"
        },
        "06026": {
            "GenartName": "Switch, wash water system",
            "NormTermName": "Switch",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": "Washing Water"
        },
        "06027": {
            "GenartName": "Holding Bracket",
            "NormTermName": "Holding Bracket",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "06028": {
            "GenartName": "Sunroof",
            "NormTermName": "Sunroof",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "06029": {
            "GenartName": "Breakout Box",
            "NormTermName": "Breakout Box",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "06030": {
            "GenartName": "Adapter, breakout box",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Breakout Box"
        },
        "06031": {
            "GenartName": "Hexagon screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Hexagon"
        },
        "06032": {
            "GenartName": "Square-head screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Square"
        },
        "06033": {
            "GenartName": "Panhead countersunk screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Countersunk panhead"
        },
        "06034": {
            "GenartName": "Panhead screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Panhead"
        },
        "06035": {
            "GenartName": "Cylinder Head Cap Bolt",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Cylinder Head Cap Bolt"
        },
        "06037": {
            "GenartName": "Countersunk head screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Countersunk Head"
        },
        "06038": {
            "GenartName": "T-head bolt",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Hammer Head"
        },
        "06039": {
            "GenartName": "Plate screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Plate screw"
        },
        "06040": {
            "GenartName": "Knurled Screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Knurled screw"
        },
        "06041": {
            "GenartName": "Flat-head screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Flat-head screw"
        },
        "06042": {
            "GenartName": "Triangular head screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Triangular head screw"
        },
        "06043": {
            "GenartName": "Wing Screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Wing Screw"
        },
        "06044": {
            "GenartName": "Set screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Set screw"
        },
        "06045": {
            "GenartName": "Round-head screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Round-head screw"
        },
        "06046": {
            "GenartName": "Capstan screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Capstan screw"
        },
        "06047": {
            "GenartName": "Ski/Snowboard Holder, roof carrier",
            "NormTermName": "Ski/Snowboard Holder",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "06048": {
            "GenartName": "Number Plate Screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Licence Plate"
        },
        "06049": {
            "GenartName": "Snowboard Holder, roof rack",
            "NormTermName": "Snowboard Holder",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "06050": {
            "GenartName": "Eye Bolt",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Eye Bolt"
        },
        "06051": {
            "GenartName": "Sheet Metal Screw",
            "NormTermName": "Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": "Sheet Metal Screw"
        },
        "06052": {
            "GenartName": "Capstan Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Capstan Nut"
        },
        "06053": {
            "GenartName": "Groove Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Groove Nut"
        },
        "06054": {
            "GenartName": "Lifting Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Lifting Nut"
        },
        "06055": {
            "GenartName": "Wing Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Wing Nut"
        },
        "06056": {
            "GenartName": "Cap Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Cap Nuts"
        },
        "06057": {
            "GenartName": "Hexagon Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Hexagon Nut"
        },
        "06058": {
            "GenartName": "Square Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Square Nut"
        },
        "06059": {
            "GenartName": "Ski Holder, trailer hitch",
            "NormTermName": "Ski Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Towbar Carrier"
        },
        "06060": {
            "GenartName": "Locknut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Locknut"
        },
        "06061": {
            "GenartName": "Slotted Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Slotted Nut"
        },
        "06062": {
            "GenartName": "Turnbuckle Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Turnbuckle Nut"
        },
        "06063": {
            "GenartName": "Sheet Metal Nut",
            "NormTermName": "Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": "Sheet Metal Nut"
        },
        "06064": {
            "GenartName": "Roof Rack Load Bar",
            "NormTermName": "Rod",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "06065": {
            "GenartName": "Roof Rack Foot",
            "NormTermName": "Base",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "06066": {
            "GenartName": "Ladder Holder, roof rack",
            "NormTermName": "Ladder Holder",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "06067": {
            "GenartName": "Pipe Box, roof rack",
            "NormTermName": "Pipe Box",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "06068": {
            "GenartName": "Solid Rivet",
            "NormTermName": "Rivet",
            "AssGrpName": "Standard Parts",
            "UsageName": "Solid Rivet"
        },
        "06069": {
            "GenartName": "Hollow Rivet",
            "NormTermName": "Rivet",
            "AssGrpName": "Standard Parts",
            "UsageName": "Hollow Rivet"
        },
        "06070": {
            "GenartName": "Semi-Hollow Rivet",
            "NormTermName": "Rivet",
            "AssGrpName": "Standard Parts",
            "UsageName": "Semi-Hollow Rivet"
        },
        "06071": {
            "GenartName": "Tubular Rivet",
            "NormTermName": "Rivet",
            "AssGrpName": "Standard Parts",
            "UsageName": "Tubular Rivet"
        },
        "06072": {
            "GenartName": "Roof Box",
            "NormTermName": "Roof Box",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": null
        },
        "06073": {
            "GenartName": "Protective Sleeve, roof box",
            "NormTermName": "Protective Sleeve",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Box"
        },
        "06074": {
            "GenartName": "Ski Holder, roof box",
            "NormTermName": "Ski Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Box"
        },
        "06075": {
            "GenartName": "Lamp, roof box",
            "NormTermName": "Light",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Box"
        },
        "06077": {
            "GenartName": "Luggage Box, roof rack",
            "NormTermName": "Luggage Box",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "06078": {
            "GenartName": "Net, luggage basket",
            "NormTermName": "Luggage Net",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Luggage Basket"
        },
        "06079": {
            "GenartName": "Holding Clip, interior panels",
            "NormTermName": "Clip",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Interior Trim"
        },
        "06080": {
            "GenartName": "Clip, trim/protection strip",
            "NormTermName": "Clip",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Trim/Protection Strip"
        },
        "06081": {
            "GenartName": "Retaining Clip, windows",
            "NormTermName": "Clip",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Window Fitting"
        },
        "06082": {
            "GenartName": "Luggage Bag, luggage basket",
            "NormTermName": "Luggage Bag",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Luggage Basket"
        },
        "06083": {
            "GenartName": "Tablet Holder",
            "NormTermName": "Bracket",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Tablet"
        },
        "06084": {
            "GenartName": "Thermal Battery Sleeve",
            "NormTermName": "Protective Sleeve",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Battery"
        },
        "06085": {
            "GenartName": "Tow Bar",
            "NormTermName": "Rod",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Towing Aid"
        },
        "06086": {
            "GenartName": "Adapter, through axle (bike holder)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Through Axle (bike holder)"
        },
        "06087": {
            "GenartName": "Adapter, roof rack",
            "NormTermName": "Adaptor",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "06088": {
            "GenartName": "Battery Pack",
            "NormTermName": "Battery",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Charging Cable"
        },
        "06089": {
            "GenartName": "Anti-Fog Cloth",
            "NormTermName": "Anti-Fog Cloth",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "06090": {
            "GenartName": "Boat Carrier, roof rack",
            "NormTermName": "Boat Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "06091": {
            "GenartName": "Windscreen Cover",
            "NormTermName": "Cover",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Windscreen"
        },
        "06093": {
            "GenartName": "Camera",
            "NormTermName": "Camera",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "06094": {
            "GenartName": "Gas Spring, cargo bay/boot mesh barrier",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Accessories",
            "UsageName": "Boot/Cargo Bay Mesh Barrier"
        },
        "06095": {
            "GenartName": "Charger Device, mobile communication",
            "NormTermName": "Charger",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Mobile Communication"
        },
        "06096": {
            "GenartName": "Air Dehumidifier",
            "NormTermName": "Air Dehumidifier",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "06097": {
            "GenartName": "Tyre Stand",
            "NormTermName": "Tyre Stand",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Tyre Storage"
        },
        "06098": {
            "GenartName": "Snow Broom",
            "NormTermName": "Snow Broom",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "06099": {
            "GenartName": "Snow Shovel",
            "NormTermName": "Snow Shovel",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "06100": {
            "GenartName": "Protective Hose, marten protection",
            "NormTermName": "Protective Hose",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Marten Repellent"
        },
        "06101": {
            "GenartName": "Distributor, cigarette lighter",
            "NormTermName": "Distributor",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Cigarette Lighter"
        },
        "06102": {
            "GenartName": "Pressure Transducer, actuator (throttle valve)",
            "NormTermName": "Pressure Converter",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Acuator (throttle valve)"
        },
        "06103": {
            "GenartName": "Body Mounting, chassis straightener",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Straightening Bench"
        },
        "06104": {
            "GenartName": "Vehicle Sleds, chassis straightening bench",
            "NormTermName": "Vehicle Sleds",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Straightening Bench"
        },
        "06105": {
            "GenartName": "Chain-/Clamp Set, chassis straightener",
            "NormTermName": "Chain-/Clamp Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Straightening Bench"
        },
        "06106": {
            "GenartName": "Cable Winch, chassis straightener",
            "NormTermName": "Cable Winch",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Straightening Bench"
        },
        "06107": {
            "GenartName": "Pressure Piston, chassis straightener",
            "NormTermName": "Pressure Piston",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Straightening Bench"
        },
        "06108": {
            "GenartName": "Attachment, pressure piston (chassis straightener)",
            "NormTermName": "Attachment",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pressure Piston, (chassis straightener)"
        },
        "06109": {
            "GenartName": "Connecting Element, pressure piston (chassis straightener)",
            "NormTermName": "Connector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pressure Piston, (chassis straightener)"
        },
        "06110": {
            "GenartName": "Protection Cap, pressure piston (chassis straightener)",
            "NormTermName": "Cap",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pressure Piston, (chassis straightener)"
        },
        "06111": {
            "GenartName": "Mount, pressure piston (chassis straightener)",
            "NormTermName": "Bracket",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pressure Piston, (chassis straightener)"
        },
        "06112": {
            "GenartName": "Display, pulling tower (chassis straightener)",
            "NormTermName": "Gauge",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pulling Tower (chassis straightener)"
        },
        "06113": {
            "GenartName": "Clamping Set, pull tower (chassis straightener)",
            "NormTermName": "Fastening Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pulling Tower (chassis straightener)"
        },
        "06114": {
            "GenartName": "Extension, pressure piston (chassis straightener)",
            "NormTermName": "Extension",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pressure Piston, (chassis straightener)"
        },
        "06115": {
            "GenartName": "Connector, pressure piston extension pipe",
            "NormTermName": "Connector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pressure Piston Extension Pipe"
        },
        "06116": {
            "GenartName": "Cover Cap, plug housing",
            "NormTermName": "Cap",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Plug Housing"
        },
        "06117": {
            "GenartName": "Holder, contact carrier",
            "NormTermName": "Holder",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Contact Carrier"
        },
        "06118": {
            "GenartName": "Secondary Lock, plug housing",
            "NormTermName": "Locks",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Plug Housing"
        },
        "06119": {
            "GenartName": "Cover, cable connector box",
            "NormTermName": "Cover",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Cable Junction Box"
        },
        "06120": {
            "GenartName": "Cover, power socket",
            "NormTermName": "Cover",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Socket"
        },
        "06121": {
            "GenartName": "Holder, fuse box",
            "NormTermName": "Holder",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Fuse Box"
        },
        "06122": {
            "GenartName": "Screw-on Plate, fuse holder",
            "NormTermName": "Base Plate",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Fuse Holder"
        },
        "06123": {
            "GenartName": "Bridge, fuse holder",
            "NormTermName": "Connector",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Fuse Holder"
        },
        "06124": {
            "GenartName": "Cable End Bush",
            "NormTermName": "Cable End Bush",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "06125": {
            "GenartName": "Handle, socket",
            "NormTermName": "Handle",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Plug Housing"
        },
        "06126": {
            "GenartName": "Vibration Damper, combination rear light",
            "NormTermName": "Damper",
            "AssGrpName": "Lights, universal",
            "UsageName": "Combination Rear Light"
        },
        "06127": {
            "GenartName": "Base, side marker light",
            "NormTermName": "Base",
            "AssGrpName": "Lights, universal",
            "UsageName": "Side Marker Light"
        },
        "06128": {
            "GenartName": "Base, end outline marker light",
            "NormTermName": "Base",
            "AssGrpName": "Lights, universal",
            "UsageName": "End Outline Marker Light"
        },
        "06129": {
            "GenartName": "Assortment, solder connections",
            "NormTermName": "Assortment",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Soldered Connector"
        },
        "06130": {
            "GenartName": "Frontal Protection Bar",
            "NormTermName": "Bracket",
            "AssGrpName": "Body",
            "UsageName": "Frontal Protection Bar"
        },
        "06131": {
            "GenartName": "Rollbar",
            "NormTermName": "Bracket",
            "AssGrpName": "Body",
            "UsageName": "Rollbar"
        },
        "06132": {
            "GenartName": "Adapter, keyhole saw",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Keyhole Saw"
        },
        "06134": {
            "GenartName": "Holder Set, measuring target (body measuring system)",
            "NormTermName": "Holder Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Measuring Targets"
        },
        "06135": {
            "GenartName": "Retrofit Set,´engine sound system",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Accessories",
            "UsageName": "Engine Sound System"
        },
        "06136": {
            "GenartName": "Tow Bar Baggage Basket",
            "NormTermName": "Luggage Box",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Trailer Hitch"
        },
        "06137": {
            "GenartName": "Pressure Sensor, oscilloscope",
            "NormTermName": "Sensor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Oscilloscope"
        },
        "06138": {
            "GenartName": "Chain Set, oil pump drive",
            "NormTermName": "Chain Set",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "06140": {
            "GenartName": "Assortment, soot/particulate filter repair",
            "NormTermName": "Assortment",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Soot/Particulate Filter"
        },
        "06141": {
            "GenartName": "Weld Protection Screw, exhaust temp. sensor weld-on bung",
            "NormTermName": "Screw",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Exhaust temp. sensor weld-on bungs"
        },
        "06142": {
            "GenartName": "Weld Protection Cap, exhaust temp. sensor weld-on bung",
            "NormTermName": "Cap",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Exhaust temp. sensor weld-on bungs"
        },
        "06143": {
            "GenartName": "Connecting Rail, fuse box",
            "NormTermName": "Connector",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Fuse Box"
        },
        "06144": {
            "GenartName": "Auxiliary Oil Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Lubrication",
            "UsageName": "Auxiliary Pump"
        },
        "06145": {
            "GenartName": "Switch, steering column adjustment",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Steering Column Adjustment"
        },
        "06146": {
            "GenartName": "Bulb Socket, direction indicator",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Lights",
            "UsageName": "Direction Indicator"
        },
        "06147": {
            "GenartName": "Gasket Set, axle casing mounting",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle casing mounting"
        },
        "06148": {
            "GenartName": "Repair Kit, pressure relief valve (air compressor)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Pressure Relief Valve (air compressor)"
        },
        "06149": {
            "GenartName": "Bellow, ball joint",
            "NormTermName": "Bellow",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Ball Joint"
        },
        "06150": {
            "GenartName": "Electric Cable, electric motor steering gear",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Steering",
            "UsageName": "Steering gear electric motor"
        },
        "06151": {
            "GenartName": "Screw Plug, timing chain tensioner",
            "NormTermName": "Screw",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain Tensioner"
        },
        "06152": {
            "GenartName": "Measuring Device, timing chain tension",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Timing Chain Tension"
        },
        "06153": {
            "GenartName": "Measuring Device, timing chain tension",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Tool, universal",
            "UsageName": "Timing Chain Tension"
        },
        "06154": {
            "GenartName": "Connector Cable, camshaft sensor",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Camshaft Sensor"
        },
        "06155": {
            "GenartName": "Spring Seat, multi-plate clutch",
            "NormTermName": "Spring Seat",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Multi-Plate Clutch"
        },
        "06156": {
            "GenartName": "Sliding Rail Bolts, timing chain",
            "NormTermName": "Bolt",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain"
        },
        "06157": {
            "GenartName": "Holder, timing chain tensioner",
            "NormTermName": "Holder",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain Tensioner"
        },
        "06158": {
            "GenartName": "Test Plate, axle/joint play tester",
            "NormTermName": "Test Plate",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Axle/Joint Play Detector"
        },
        "06159": {
            "GenartName": "Control Unit, negative pressure",
            "NormTermName": "Control Unit",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum"
        },
        "06160": {
            "GenartName": "Seal, ignition coil",
            "NormTermName": "Seal",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition Coil"
        },
        "06161": {
            "GenartName": "Marten Repellent",
            "NormTermName": "Marten Repellent",
            "AssGrpName": "Accessories",
            "UsageName": null
        },
        "06162": {
            "GenartName": "Switch, parking distance control",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Distance Control"
        },
        "06163": {
            "GenartName": "Switch, ESP",
            "NormTermName": "Switch",
            "AssGrpName": "Drive Dynamics Control",
            "UsageName": "ESP"
        },
        "06164": {
            "GenartName": "Switch, tailgate",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Tailgate"
        },
        "06165": {
            "GenartName": "Switch, lane change assistant",
            "NormTermName": "Switch",
            "AssGrpName": "Safety Systems",
            "UsageName": "Lane Change Assistant"
        },
        "06166": {
            "GenartName": "Multi-Function Switch",
            "NormTermName": "Switch",
            "AssGrpName": "Central Electrics",
            "UsageName": "Multi-function"
        },
        "06167": {
            "GenartName": "Connecting Rod Bearing Set",
            "NormTermName": "Bearing Kit",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Connecting Rod"
        },
        "06168": {
            "GenartName": "Thrust Washer Set, camshaft",
            "NormTermName": "Thrust Washer Set",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft"
        },
        "06169": {
            "GenartName": "Thrust Washer Set, crankshaft",
            "NormTermName": "Thrust Washer Set",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft"
        },
        "06170": {
            "GenartName": "Flat Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flat Pliers"
        },
        "06171": {
            "GenartName": "Round Nose Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Round Nose Pliers"
        },
        "06174": {
            "GenartName": "Holder, coolant compensation tank",
            "NormTermName": "Holder",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Expansion Tank"
        },
        "06175": {
            "GenartName": "Kit, pipe wrench/water pump pliers",
            "NormTermName": "Pliers Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Wrench/Water Pump Pliers"
        },
        "06176": {
            "GenartName": "Wire Stripping Tools",
            "NormTermName": "Wire Stripping Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "strip"
        },
        "06177": {
            "GenartName": "Blade, cable cutter",
            "NormTermName": "Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable Cutter"
        },
        "06178": {
            "GenartName": "Blade, cable knife",
            "NormTermName": "Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable Knife"
        },
        "06179": {
            "GenartName": "Centre Cutter",
            "NormTermName": "Centre Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06180": {
            "GenartName": "Tool Board",
            "NormTermName": "Pegboard",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tools"
        },
        "06181": {
            "GenartName": "Locator, crimping pliers",
            "NormTermName": "Mounting Aid",
            "AssGrpName": "Tool, universal",
            "UsageName": "Crimping Pliers"
        },
        "06182": {
            "GenartName": "Test Stand, tools",
            "NormTermName": "Test Stand",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tools"
        },
        "06183": {
            "GenartName": "Rotating Stand, tools",
            "NormTermName": "Rotating Stand",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tools"
        },
        "06184": {
            "GenartName": "Money Tray",
            "NormTermName": "Money Tray",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06185": {
            "GenartName": "Length Stop, insulation stripper",
            "NormTermName": "Length Stop",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable stripper"
        },
        "06186": {
            "GenartName": "Tile Nibbling Pincer",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tile Nibbling Pincer"
        },
        "06187": {
            "GenartName": "Assembly Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06188": {
            "GenartName": "Fitting Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Instruments"
        },
        "06189": {
            "GenartName": "Pliers Wrench",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pliers Wrench"
        },
        "06190": {
            "GenartName": "Presentation Stand",
            "NormTermName": "Presentation Stand",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06191": {
            "GenartName": "Cutter Head, bolt cutter",
            "NormTermName": "Cutter Head",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bolt Cutter"
        },
        "06192": {
            "GenartName": "Grip Inserts, hose clamp pliers",
            "NormTermName": "Grip Inserts",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hose Clamp Pliers"
        },
        "06193": {
            "GenartName": "Glas Nibbling Pincer",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glas Nibbling Pincer"
        },
        "06194": {
            "GenartName": "Switchboard Key",
            "NormTermName": "Key",
            "AssGrpName": "Tool, universal",
            "UsageName": "Switchboard"
        },
        "06195": {
            "GenartName": "Adjusting Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Adjusting Pliers"
        },
        "06196": {
            "GenartName": "Punch Lock Riveters",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Punch Lock Riveters"
        },
        "06197": {
            "GenartName": "Tow Bar Ski/Snowboard Holder",
            "NormTermName": "Ski/Snowboard Holder",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Trailer Hitch"
        },
        "06198": {
            "GenartName": "Blade Set, insulation stripper",
            "NormTermName": "Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable stripper"
        },
        "06199": {
            "GenartName": "Pegboard Wall",
            "NormTermName": "Pegboard Wall",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Sales Assistance"
        },
        "06200": {
            "GenartName": "Hook, pegboard wall",
            "NormTermName": "Hook",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Sales Assistance"
        },
        "06201": {
            "GenartName": "Magnetic Strips, pegboard wall",
            "NormTermName": "Magnetic Strips",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Sales Assistance"
        },
        "06202": {
            "GenartName": "Holder, pegboard wall",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Sales Assistance"
        },
        "06203": {
            "GenartName": "Pliers Set",
            "NormTermName": "Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pliers"
        },
        "06204": {
            "GenartName": "Load Assist, roof rack",
            "NormTermName": "Load Assist",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "06205": {
            "GenartName": "Tool Light",
            "NormTermName": "Tool Light",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06206": {
            "GenartName": "Board Holder, roof rack",
            "NormTermName": "Board Holder",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "06207": {
            "GenartName": "Steps, workshop pit",
            "NormTermName": "Steps",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Pit"
        },
        "06208": {
            "GenartName": "Safety Cover, workshop pit",
            "NormTermName": "Fuse",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Pit"
        },
        "06209": {
            "GenartName": "Remote Control, cylinder lift system",
            "NormTermName": "Remote Control",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Post Lift System"
        },
        "06210": {
            "GenartName": "Dryer, wheel dolly",
            "NormTermName": "Dehumidifier",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wheel Jack"
        },
        "06211": {
            "GenartName": "Mounting, compressor pressure tank",
            "NormTermName": "Mounting",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Tank"
        },
        "06212": {
            "GenartName": "Mounting Device, vehicle seat",
            "NormTermName": "Mounting Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Seat"
        },
        "06213": {
            "GenartName": "Software, compressed air system",
            "NormTermName": "Software",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "06214": {
            "GenartName": "Sensor, post vehicle lift",
            "NormTermName": "Sensor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cylinder Vehicle Lift"
        },
        "06215": {
            "GenartName": "Silencer, compressor relief air",
            "NormTermName": "Silencer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressor Relief Air"
        },
        "06216": {
            "GenartName": "Pressure Plate, workshop press",
            "NormTermName": "Pressure Plate",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Presses"
        },
        "06217": {
            "GenartName": "Trolley, lifting accessories",
            "NormTermName": "Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Accessories"
        },
        "06218": {
            "GenartName": "Spindle, block",
            "NormTermName": "Spindle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Trestle"
        },
        "06219": {
            "GenartName": "Control Post, vehicle lift operating unit",
            "NormTermName": "Post",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Lift Operating Unit"
        },
        "06220": {
            "GenartName": "Extension, trolley jack",
            "NormTermName": "Extension",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Mobile Jack"
        },
        "06221": {
            "GenartName": "Wheel Dolly Mounting",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wheel Jack"
        },
        "06222": {
            "GenartName": "Winch, workshop press",
            "NormTermName": "Winch",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Press"
        },
        "06223": {
            "GenartName": "Heat Exchanger, air compressor",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Compressor"
        },
        "06224": {
            "GenartName": "Roof Rack",
            "NormTermName": "Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Rack"
        },
        "06225": {
            "GenartName": "Roof Load Carriers",
            "NormTermName": "Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Roof Carriers"
        },
        "06226": {
            "GenartName": "Extension Set, tow bar coupling bike carrier",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Tow Bar Coupling Bike Carrier"
        },
        "06227": {
            "GenartName": "Dismantling Tool, injector",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector"
        },
        "06228": {
            "GenartName": "Polishing Cotton Wool",
            "NormTermName": "Polishing Cotton Wool",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06229": {
            "GenartName": "Wall Mounting, hand cleaner",
            "NormTermName": "Bracket",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Hand Cleaner"
        },
        "06230": {
            "GenartName": "Hand Cleaner Dispenser",
            "NormTermName": "Dispenser",
            "AssGrpName": "Chemical Products",
            "UsageName": "Hand Cleaner"
        },
        "06231": {
            "GenartName": "Adjustment Gauge, drum brakes",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Drum Brake"
        },
        "06232": {
            "GenartName": "Repair Set, brake caliper guide thread",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Brake Caliper Guide Thread"
        },
        "06233": {
            "GenartName": "Hook, brake caliper (brake pad replacement)",
            "NormTermName": "Hook",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Hook, brake caliper (brake pad replacement)"
        },
        "06234": {
            "GenartName": "Socket Wrench, bleeder screw/valve",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Bleeder Screw/Valve"
        },
        "06235": {
            "GenartName": "Socket Set, bleeder screw/valve",
            "NormTermName": "Socket Wrench Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Bleeder Screw/Valve"
        },
        "06236": {
            "GenartName": "Wheel and Tyre Trolley",
            "NormTermName": "Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tyre"
        },
        "06237": {
            "GenartName": "Gauge, disc brake pad",
            "NormTermName": "Gauge",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Disc Brake Pad"
        },
        "06238": {
            "GenartName": "Assembly Tool, brake spring (parking brake)",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Parking Brake"
        },
        "06239": {
            "GenartName": "Tool Belt",
            "NormTermName": "Tool Belt",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06240": {
            "GenartName": "Socket Wrench Insert, cylinder head bolt",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Cylinder Head Bolt"
        },
        "06241": {
            "GenartName": "Oil Filter Spanner Set",
            "NormTermName": "Oil Filter Spanner Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06242": {
            "GenartName": "Material Lifter",
            "NormTermName": "Material Lifter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "06243": {
            "GenartName": "Cable Set, tail light conversion",
            "NormTermName": "Electric Cable Set",
            "AssGrpName": "Lights",
            "UsageName": "Combination Rear Light Conversion"
        },
        "06244": {
            "GenartName": "Repair Mount, exhaust system",
            "NormTermName": "Holder",
            "AssGrpName": "Exhaust System",
            "UsageName": "Repair Holder"
        },
        "06245": {
            "GenartName": "Extension Set, tailgate bike carrier",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Tailgate Bike Carrier"
        },
        "06246": {
            "GenartName": "Bike Carrier, tow bar",
            "NormTermName": "Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Tow Bar Coupling Bike Carrier"
        },
        "06247": {
            "GenartName": "Bike Carrier, tailgate",
            "NormTermName": "Rack",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Tailgate"
        },
        "06248": {
            "GenartName": "Cable Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Cable Lock"
        },
        "06249": {
            "GenartName": "Scanner, body measuring system",
            "NormTermName": "Scanner",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Measuring System"
        },
        "06250": {
            "GenartName": "Tray, scanner (body measuring system)",
            "NormTermName": "Tray",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Scanner (body measuring system)"
        },
        "06251": {
            "GenartName": "Tripod, scanner (body measuring system)",
            "NormTermName": "Tripod",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Scanner (body measuring system)"
        },
        "06252": {
            "GenartName": "Extension, measuring targets (body measuring system)",
            "NormTermName": "Extension",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Measuring Targets"
        },
        "06253": {
            "GenartName": "Measuring Target Set",
            "NormTermName": "Measuring Targets",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Measuring System"
        },
        "06254": {
            "GenartName": "Weight, measuring target (body measuring system)",
            "NormTermName": "Weight",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Measuring Targets"
        },
        "06255": {
            "GenartName": "Jack Receptor",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Jack"
        },
        "06256": {
            "GenartName": "Wheel Chock, body straightening bench",
            "NormTermName": "Wheel Chock",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Straightening Bench"
        },
        "06257": {
            "GenartName": "Pithead",
            "NormTermName": "Pithead",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06258": {
            "GenartName": "Holding Device, driver cab",
            "NormTermName": "Holding Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06259": {
            "GenartName": "Control Unit, compressed air system",
            "NormTermName": "Control Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "06260": {
            "GenartName": "Valve, compressed air system",
            "NormTermName": "Valve",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "06261": {
            "GenartName": "Auxiliary Heater, air compressor",
            "NormTermName": "Parking Heater",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Compressor"
        },
        "06262": {
            "GenartName": "Support Device, pit lift",
            "NormTermName": "Support Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pit Jack"
        },
        "06263": {
            "GenartName": "Shelf Stopper, pegboard wall",
            "NormTermName": "Shelf Stopper",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pegboard Wall"
        },
        "06264": {
            "GenartName": "Shelves, pegboard wall",
            "NormTermName": "Shelf Base",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pegboard Wall"
        },
        "06265": {
            "GenartName": "Cabinet Display, pegboard wall",
            "NormTermName": "Display Cabinet",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pegboard Wall"
        },
        "06266": {
            "GenartName": "Lock, pegboard wall hook",
            "NormTermName": "Lock",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pegboard Wall Hook"
        },
        "06267": {
            "GenartName": "Blade, wire stripper",
            "NormTermName": "Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wire Stripping Tools"
        },
        "06268": {
            "GenartName": "Duckbill Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Duckbill Pliers"
        },
        "06269": {
            "GenartName": "Replacement Tips, circlip pliers",
            "NormTermName": "Replacement Tips",
            "AssGrpName": "Tool, universal",
            "UsageName": "Circlip Pliers"
        },
        "06270": {
            "GenartName": "Circlip Tool",
            "NormTermName": "Circlip Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06271": {
            "GenartName": "Body Pliers",
            "NormTermName": "Body Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06272": {
            "GenartName": "Potters' Pincers",
            "NormTermName": "Potters' Pincers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06273": {
            "GenartName": "Cone Pliers",
            "NormTermName": "Cone Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06274": {
            "GenartName": "Slip Joint Pliers",
            "NormTermName": "Slip Joint Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06275": {
            "GenartName": "Four-Mandrel Pliers",
            "NormTermName": "Four-Mandrel Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06276": {
            "GenartName": "Tool Set, solar cable connectors",
            "NormTermName": "Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Solar Cable Connectors"
        },
        "06277": {
            "GenartName": "Compression Tool, plug connector",
            "NormTermName": "Compression Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06278": {
            "GenartName": "Insertion Tool",
            "NormTermName": "Insertion Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06279": {
            "GenartName": "Spare Magazine, crimping pliers",
            "NormTermName": "Spare Magazine",
            "AssGrpName": "Tool, universal",
            "UsageName": "Crimping Pliers"
        },
        "06280": {
            "GenartName": "Repair Set, cable shears",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06281": {
            "GenartName": "Glass Flat Nose Pliers",
            "NormTermName": "Glass Flat Nose Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06282": {
            "GenartName": "Glass Breaking Pliers",
            "NormTermName": "Glass Breaking Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06283": {
            "GenartName": "Blade, sheet metal nibbler",
            "NormTermName": "Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": "Nibbler Shear"
        },
        "06284": {
            "GenartName": "Cover Cloth",
            "NormTermName": "Cover Cloth",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06285": {
            "GenartName": "Clamp, cover cloth",
            "NormTermName": "Clamp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06286": {
            "GenartName": "Oil Level Sensor, compressed air system",
            "NormTermName": "Sensor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "06287": {
            "GenartName": "Stopper Set, hose/pipeline",
            "NormTermName": "Stopper Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Line"
        },
        "06288": {
            "GenartName": "Temperature Probe, multimeter",
            "NormTermName": "Sensor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Multimeter"
        },
        "06289": {
            "GenartName": "Attachment Set, hot air dryer",
            "NormTermName": "Attachment Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Hot Air Fan"
        },
        "06290": {
            "GenartName": "Press-fit/Extraction Tools",
            "NormTermName": "Press-fit/Extraction Tools",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06291": {
            "GenartName": "Stopper, hose-/pipeline",
            "NormTermName": "Stopper",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Line"
        },
        "06292": {
            "GenartName": "Base Load Change Control, air compressor",
            "NormTermName": "Base Load Change Control",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Compressor"
        },
        "06293": {
            "GenartName": "Joint Control, air compressor",
            "NormTermName": "Joint Control",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Compressor"
        },
        "06294": {
            "GenartName": "Calibration, air compressor systems",
            "NormTermName": "Calibration",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Compressor Systems Service"
        },
        "06295": {
            "GenartName": "Force Gauge, vehicle lift system",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "06296": {
            "GenartName": "Steam Trap, vehicle lift system",
            "NormTermName": "Trap",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Lifting Systems"
        },
        "06297": {
            "GenartName": "Pump Sump, installation cartridge (post lift)",
            "NormTermName": "Pump Sump",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Installation Cassette, cyilnder lift"
        },
        "06298": {
            "GenartName": "Engine Stand",
            "NormTermName": "Rack",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Engine"
        },
        "06299": {
            "GenartName": "Safety Limitation, pit lift",
            "NormTermName": "Safety Limitation",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pit Jack"
        },
        "06300": {
            "GenartName": "Directional Valve Block, steering",
            "NormTermName": "Directional Control Valve Block",
            "AssGrpName": "Steering",
            "UsageName": null
        },
        "06301": {
            "GenartName": "Mounting, pulling element (car body technology)",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Repair Systems"
        },
        "06302": {
            "GenartName": "Pulling Elements Set, car body technology",
            "NormTermName": "Pulling Elements Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Repair Systems"
        },
        "06303": {
            "GenartName": "Support Trestle",
            "NormTermName": "Support Trestle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06304": {
            "GenartName": "Cross Member (car body measuring system)",
            "NormTermName": "Body Cross Member",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Measuring System"
        },
        "06305": {
            "GenartName": "Wheel Stand, car body work",
            "NormTermName": "Wheelbase",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Repair Systems"
        },
        "06306": {
            "GenartName": "Gas Spring, seat height adjustment",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Seat Height Adjustment"
        },
        "06307": {
            "GenartName": "Gas Spring, backrest adjustment",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Seat Back Adjustment"
        },
        "06308": {
            "GenartName": "Mounting, vehicle frame",
            "NormTermName": "Mounting",
            "AssGrpName": "Body",
            "UsageName": "Vehicle Frame"
        },
        "06309": {
            "GenartName": "Spring Split Pin, fuel filter (thermostat)",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Filter (thermostat)"
        },
        "06310": {
            "GenartName": "Housing, oil cooler",
            "NormTermName": "Housing",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Cooler"
        },
        "06311": {
            "GenartName": "Strap, cargo space cover",
            "NormTermName": "Strap",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Cargo Space Cover"
        },
        "06312": {
            "GenartName": "Retaining Spring, coolant flange sealing plug",
            "NormTermName": "Spring",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Flange Sealing Plug"
        },
        "06313": {
            "GenartName": "Switch, seat adjustment",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Seat Adjustment"
        },
        "06314": {
            "GenartName": "Protective Cover, transfer case",
            "NormTermName": "Lid",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transfer Case"
        },
        "06315": {
            "GenartName": "Weld-on Bung, NOx sensor",
            "NormTermName": "Weld-On Bung",
            "AssGrpName": "Exhaust System",
            "UsageName": "NOx Sensor"
        },
        "06316": {
            "GenartName": "Oil, air conditioning system",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Air Conditioning"
        },
        "06317": {
            "GenartName": "Covering, steering column",
            "NormTermName": "Panelling",
            "AssGrpName": "Steering",
            "UsageName": "Steering Column"
        },
        "06318": {
            "GenartName": "Seal, fuel tank",
            "NormTermName": "Seal",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank"
        },
        "06319": {
            "GenartName": "Holding Clamp, heater hose",
            "NormTermName": "Holding Bracket",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heater Hose"
        },
        "06321": {
            "GenartName": "Switch, automatic vibration (attachments)",
            "NormTermName": "Switch",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Automatic Vibration (Attachments)"
        },
        "06322": {
            "GenartName": "Tyre Sealant Set",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Tyre Sealant Set"
        },
        "06323": {
            "GenartName": "Sealant, tyre sealant set",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Tyre Sealant Set"
        },
        "06324": {
            "GenartName": "Filling Hose Set, tyre sealant set",
            "NormTermName": "Filler Hose Set",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Tyre Sealant Set"
        },
        "06325": {
            "GenartName": "Sealant Set, tyre sealant set",
            "NormTermName": "Sealant Set",
            "AssGrpName": "Chemical Products",
            "UsageName": "Tyre Sealant Set"
        },
        "06327": {
            "GenartName": "Repair Kit, headlight",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "06328": {
            "GenartName": "Repair Kit, window regulator",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Window Regulator"
        },
        "06329": {
            "GenartName": "Bike Carrier, tailgate",
            "NormTermName": "Rack",
            "AssGrpName": "Carriers",
            "UsageName": "Tailgate"
        },
        "06330": {
            "GenartName": "SCR Catalytic Converter Cleaning",
            "NormTermName": "SCR Catalytic Converter Cleaning",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "06331": {
            "GenartName": "Catalytic Converter Cleaning",
            "NormTermName": "Catalytic Converter Cleaning",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "06332": {
            "GenartName": "Attachment, tool trolley",
            "NormTermName": "Attachment",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06333": {
            "GenartName": "Anti-Slip Mat, tool trolley drawer",
            "NormTermName": "Floor Mat",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Drawer (tool trolley)"
        },
        "06334": {
            "GenartName": "Castor Set, tool trolley",
            "NormTermName": "Pulley Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06335": {
            "GenartName": "Roller, tool trolley",
            "NormTermName": "Pulley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06336": {
            "GenartName": "Trailer Box, tool trolley",
            "NormTermName": "Van",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06337": {
            "GenartName": "Can Holder, tool trolley",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06338": {
            "GenartName": "Paper Roll Holder, tool trolley",
            "NormTermName": "Paper Roll Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06339": {
            "GenartName": "Tool Box",
            "NormTermName": "Van",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tools"
        },
        "06340": {
            "GenartName": "Cable Repair Set, wiper motor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Wiper Motor"
        },
        "06341": {
            "GenartName": "Cable Repair Set, lambda sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Lambda Sensor"
        },
        "06342": {
            "GenartName": "Cable Repair Set, air flow meter",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Air Mass Sensor"
        },
        "06343": {
            "GenartName": "Cable Repair Set, direction indicator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Signal System",
            "UsageName": "Direction Indicator"
        },
        "06344": {
            "GenartName": "Cable Repair Set, wheel speed sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System",
            "UsageName": "Wheel Speed"
        },
        "06345": {
            "GenartName": "Cable Repair Set, side marker lights",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Side Marker Light"
        },
        "06346": {
            "GenartName": "Cable Repair Set, combination rear light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Combination Rear Light"
        },
        "06347": {
            "GenartName": "Cable Repair Kit, headlight",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "06348": {
            "GenartName": "Cable Repair Set, central electrics",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Central Electrics",
            "UsageName": "Central Electrics"
        },
        "06349": {
            "GenartName": "Cable Repair Set, EGR valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "EGR Valve"
        },
        "06350": {
            "GenartName": "Cable Repair Set, injector valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector"
        },
        "06351": {
            "GenartName": "Cable Repair Set, intake manifold pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Intake Manifold Pressure Sensor"
        },
        "06352": {
            "GenartName": "Cable Repair Set, end outline marker light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "End Outline Marker Light"
        },
        "06353": {
            "GenartName": "Cable Repair Set, switch (reverse light)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Switch (reverse light)"
        },
        "06354": {
            "GenartName": "Cable Repair Set, wiper motor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": "Wiper Motor"
        },
        "06355": {
            "GenartName": "Cable Repair Set, lambda sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Lambda Sensor"
        },
        "06356": {
            "GenartName": "Cable Repair Set, air flow meter",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Air Mass Sensor"
        },
        "06357": {
            "GenartName": "Cable Repair Set, direction indicator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Direction Indicator"
        },
        "06358": {
            "GenartName": "Cable Repair Set, wheel speed sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Wheel Speed Sensor"
        },
        "06359": {
            "GenartName": "Cable Repair Set, side marker lights",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Side Marker Light"
        },
        "06360": {
            "GenartName": "Cable Repair Set, combination rear light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Combination Rear Light"
        },
        "06361": {
            "GenartName": "Cable Repair Kit, headlight",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "06362": {
            "GenartName": "Cable Repair Set, central electrics",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Electric Supply, universal",
            "UsageName": "Central Electrics"
        },
        "06363": {
            "GenartName": "Cable Repair Set, EGR valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust Gas Recirculation, Universal",
            "UsageName": "EGR Valve"
        },
        "06364": {
            "GenartName": "Cable Repair Set, injector valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Injector"
        },
        "06365": {
            "GenartName": "Cable Repair Set, intake manifold pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Intake Manifold Pressure Sensor"
        },
        "06366": {
            "GenartName": "Cable Repair Set, end outline marker light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "End Outline Marker Light"
        },
        "06367": {
            "GenartName": "Cable Repair Set, switch (reverse light)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Switch (reverse light)"
        },
        "06368": {
            "GenartName": "Switch, extend hydraulic cylinder",
            "NormTermName": "Switch",
            "AssGrpName": "Power Take-Off",
            "UsageName": "Extend (hydraulic cylinder)"
        },
        "06369": {
            "GenartName": "Switch, lift (lifting unit)",
            "NormTermName": "Switch",
            "AssGrpName": "Power Take-Off",
            "UsageName": "Lift (lifting unit)"
        },
        "06370": {
            "GenartName": "Switch, lower (lifting unit)",
            "NormTermName": "Switch",
            "AssGrpName": "Power Take-Off",
            "UsageName": "Lowering (lifting unit)"
        },
        "06371": {
            "GenartName": "Switch, retract hydraulic cylinder",
            "NormTermName": "Switch",
            "AssGrpName": "Power Take-Off",
            "UsageName": "Retract (hydraulic cylinder)"
        },
        "06372": {
            "GenartName": "Switch, raise/lower (attachments)",
            "NormTermName": "Switch",
            "AssGrpName": "Power Take-Off",
            "UsageName": "Lifting/Lowering (attachments)"
        },
        "06373": {
            "GenartName": "Switch, on/off (attachments)",
            "NormTermName": "Switch",
            "AssGrpName": "Power Take-Off",
            "UsageName": "On/Off Switch (attachments)"
        },
        "06374": {
            "GenartName": "Anchor Rail, Load Securing",
            "NormTermName": "Anchor Rail",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "06375": {
            "GenartName": "Joist Hanger, Anchor Rail",
            "NormTermName": "Joist Hanger",
            "AssGrpName": "Load Securing",
            "UsageName": "Anchor Rail"
        },
        "06376": {
            "GenartName": "Disinfectant",
            "NormTermName": "Disinfectant",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06377": {
            "GenartName": "Bit Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drill Bit"
        },
        "06378": {
            "GenartName": "Increasing Adapter, ratchet",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet"
        },
        "06379": {
            "GenartName": "Reducing Adapter, ratchet",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06380": {
            "GenartName": "Safety Helmet",
            "NormTermName": "Safety Helmet",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06381": {
            "GenartName": "Tester, protective gloves",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Protective Gloves"
        },
        "06382": {
            "GenartName": "Pull-on Glove",
            "NormTermName": "Gloves",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Work Clothing"
        },
        "06383": {
            "GenartName": "Pull-on Glove",
            "NormTermName": "Gloves",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Protective Gloves"
        },
        "06384": {
            "GenartName": "Storage Bag, electrician protective gloves",
            "NormTermName": "Bag",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Electrician Protective Gloves"
        },
        "06385": {
            "GenartName": "Face Shield, safety helmet",
            "NormTermName": "Face Safety Shield",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Safety Helmet"
        },
        "06386": {
            "GenartName": "Carrier Bag, safety helmet",
            "NormTermName": "Bag",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Safety Helmet"
        },
        "06387": {
            "GenartName": "Barrier Tape",
            "NormTermName": "Barrier Tape",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06388": {
            "GenartName": "Warning Sign",
            "NormTermName": "Warning Sign",
            "AssGrpName": "Standard Parts",
            "UsageName": "Electrical Voltage"
        },
        "06389": {
            "GenartName": "Prohibition Sign",
            "NormTermName": "Prohibition Sign",
            "AssGrpName": "Standard Parts",
            "UsageName": "Switching Forbidden"
        },
        "06390": {
            "GenartName": "Sealing Pliers",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Sealant"
        },
        "06391": {
            "GenartName": "Water Recirculation Pump, parking heater",
            "NormTermName": "Pump",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Heater"
        },
        "06393": {
            "GenartName": "Drive Plate, servo pump",
            "NormTermName": "Drive Plate",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering Pump"
        },
        "06394": {
            "GenartName": "Socket Wrench, lambda sensor",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Lambda Sensor"
        },
        "06395": {
            "GenartName": "Pliers, exhaust pipe clamp",
            "NormTermName": "Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Exhaust Pipe Clamp"
        },
        "06396": {
            "GenartName": "Cleaning Tool, exhaust recirculation housing",
            "NormTermName": "Cleaning Equipment",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Exhaust Gas Recirculation Housing"
        },
        "06397": {
            "GenartName": "Power Socket, drive shaft",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Drive Shaft"
        },
        "06398": {
            "GenartName": "Tensioner, hose clamp",
            "NormTermName": "Tensioner",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Hose Clamp"
        },
        "06399": {
            "GenartName": "Locking Pliers, hose clamp",
            "NormTermName": "Vise-grip Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hose Clamp"
        },
        "06400": {
            "GenartName": "Pliers, fuel line quick coupling",
            "NormTermName": "Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Fuel Line Quick Coupling"
        },
        "06401": {
            "GenartName": "Drive Flange, belt pulley",
            "NormTermName": "Flange",
            "AssGrpName": "Belt Drive",
            "UsageName": "Belt Pulley"
        },
        "06402": {
            "GenartName": "Control, injection pump",
            "NormTermName": "Adjusting Element Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump"
        },
        "06403": {
            "GenartName": "Actuator, exhaust gas recirculation cooler",
            "NormTermName": "Actuator",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "Exhaust Gas Recirculation Cooler"
        },
        "06404": {
            "GenartName": "Workshop Service Trolley",
            "NormTermName": "Workshop Service Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06405": {
            "GenartName": "Platform Trolley",
            "NormTermName": "Platform Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06406": {
            "GenartName": "Work Platform",
            "NormTermName": "Work Platform",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06407": {
            "GenartName": "Machine Steps",
            "NormTermName": "Machine Steps",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06408": {
            "GenartName": "Pane Set-down Table",
            "NormTermName": "Pane Set-down Table",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06409": {
            "GenartName": "Paint Mixer Cup",
            "NormTermName": "Paint Mixer Cup",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06410": {
            "GenartName": "Paper Roll Trolley",
            "NormTermName": "Paper Roll Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06411": {
            "GenartName": "Pallet Cage",
            "NormTermName": "Pallet Cage",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06412": {
            "GenartName": "Transport Trolley",
            "NormTermName": "Transport Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06413": {
            "GenartName": "Bottle Trolley",
            "NormTermName": "Bottle Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06414": {
            "GenartName": "Steering Wheel Cover",
            "NormTermName": "Steering Wheel Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06415": {
            "GenartName": "Shift Lever Cover",
            "NormTermName": "Shift Lever Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06416": {
            "GenartName": "Hand Brake Lever Cover",
            "NormTermName": "Hand Brake Lever Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06417": {
            "GenartName": "Seat Cover",
            "NormTermName": "Seat Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06418": {
            "GenartName": "Footwell Cover",
            "NormTermName": "Footwell Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06419": {
            "GenartName": "Cover Set, interior",
            "NormTermName": "Cover Set, interior",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06420": {
            "GenartName": "Tyre case",
            "NormTermName": "Tyre case",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06421": {
            "GenartName": "Bag, wheel bolts",
            "NormTermName": "Bag",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wheel Bolts"
        },
        "06422": {
            "GenartName": "Vehicle Front Cover",
            "NormTermName": "Vehicle Front Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06423": {
            "GenartName": "Mechanics' Protective Mat",
            "NormTermName": "Mechanics' Protective Mat",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06424": {
            "GenartName": "Workshop Creeper",
            "NormTermName": "Workshop Creeper",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06425": {
            "GenartName": "Workshop Stool",
            "NormTermName": "Workshop Stool",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06426": {
            "GenartName": "Obstacle Avoidance Trolley",
            "NormTermName": "Obstacle Avoidance Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06427": {
            "GenartName": "Obstacle Avoidance Assistant",
            "NormTermName": "Obstacle Avoidance Assistant",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06428": {
            "GenartName": "Storage Case, obstacle avoidance assistant",
            "NormTermName": "Storage Rack",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Maneuvering"
        },
        "06429": {
            "GenartName": "Wheel Chock",
            "NormTermName": "Wheel Chock",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06430": {
            "GenartName": "Access Ramp",
            "NormTermName": "Access Ramp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06431": {
            "GenartName": "Supports",
            "NormTermName": "Supports",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06432": {
            "GenartName": "Workshop Crane",
            "NormTermName": "Workshop Crane",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06433": {
            "GenartName": "Engine Transverse Strut",
            "NormTermName": "Engine Transverse Strut",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06434": {
            "GenartName": "Load Positioning Device, workshop crane",
            "NormTermName": "Load Positioning Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Crane"
        },
        "06435": {
            "GenartName": "Mounting Device, universal",
            "NormTermName": "Mounting Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Universal"
        },
        "06436": {
            "GenartName": "Cleaning Station",
            "NormTermName": "Cleaning Station",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06437": {
            "GenartName": "Filling Hose, transmission",
            "NormTermName": "Hose",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Transmission"
        },
        "06438": {
            "GenartName": "Filling Adapter Set, transmission",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06439": {
            "GenartName": "Filling Hose, all-wheel-drive coupling",
            "NormTermName": "Hose",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "All-Wheel-Drive Coupling"
        },
        "06440": {
            "GenartName": "Oil, compressed air tools",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Compressed Air Systems"
        },
        "06441": {
            "GenartName": "Oil Can",
            "NormTermName": "Oil Can",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06442": {
            "GenartName": "Oil Pen",
            "NormTermName": "Oil Pen",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06443": {
            "GenartName": "Adapter Set, grease gun",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Grease Gun"
        },
        "06444": {
            "GenartName": "Hose, grease gun",
            "NormTermName": "Hose",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Grease Gun"
        },
        "06445": {
            "GenartName": "Nozzle, grease gun",
            "NormTermName": "Nozzle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Grease Gun"
        },
        "06446": {
            "GenartName": "Nozzle Extension, grease gun",
            "NormTermName": "Extension",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Grease Gun Nozzle"
        },
        "06447": {
            "GenartName": "Filler Hose, fill/extraction pump",
            "NormTermName": "Hose",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Fill-/Extractor Pump"
        },
        "06448": {
            "GenartName": "Measuring Cup Set",
            "NormTermName": "Measuring Cup Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06449": {
            "GenartName": "Funnel Set",
            "NormTermName": "Funnel Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06450": {
            "GenartName": "Wall Holder, cleaning paper roll",
            "NormTermName": "Bracket",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cleaning Paper Roll"
        },
        "06451": {
            "GenartName": "Floor Stand, cleaning paper roll",
            "NormTermName": "Stator",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cleaning Paper Roll"
        },
        "06452": {
            "GenartName": "Magnetic Tray",
            "NormTermName": "Magnetic Tray",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06453": {
            "GenartName": "Magnet Holder",
            "NormTermName": "Magnet Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06454": {
            "GenartName": "Barrel Funnel",
            "NormTermName": "Barrel Funnel",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06455": {
            "GenartName": "Barrel Pump",
            "NormTermName": "Barrel Pump",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06456": {
            "GenartName": "Compressed Air Gun Set",
            "NormTermName": "Compressed Air Gun Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06457": {
            "GenartName": "Shield, compressed air pistol",
            "NormTermName": "Shield",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Spray Gun"
        },
        "06458": {
            "GenartName": "Removal Lever, hubcap",
            "NormTermName": "Removal Lever",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Hubcap"
        },
        "06459": {
            "GenartName": "Torque Limiter Set",
            "NormTermName": "Torque Limiter Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06460": {
            "GenartName": "Power Socket Set",
            "NormTermName": "Power Socket Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06461": {
            "GenartName": "Arrester, tyre-pressure monitoring system",
            "NormTermName": "Arrester",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "06462": {
            "GenartName": "Bleeding Unit Set, tyre-pressure monitoring system",
            "NormTermName": "Bleeder Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "06463": {
            "GenartName": "Mounting Tool, valve seal (tyre-pressure monitoring system)",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Seal (tyre pressure monitoring system)"
        },
        "06464": {
            "GenartName": "Valve Insert",
            "NormTermName": "Valve Insert",
            "AssGrpName": "Wheels, universal",
            "UsageName": null
        },
        "06465": {
            "GenartName": "Assortment, tyre-pressure monitoring system",
            "NormTermName": "Assortment",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "06466": {
            "GenartName": "Thread Chaser",
            "NormTermName": "Thread Chaser",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06467": {
            "GenartName": "Tyre Pressure Tester",
            "NormTermName": "Tester",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tyre Pressure"
        },
        "06468": {
            "GenartName": "Tester, tyre pressure/tread depth",
            "NormTermName": "Tester",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tyre Pressure/Tread Depth"
        },
        "06469": {
            "GenartName": "Filling Nipple, compressed air tyre gauge/filler",
            "NormTermName": "Nipple",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Tyre Gauge/-Filler"
        },
        "06470": {
            "GenartName": "Bead Pusher, tyre fitting machine",
            "NormTermName": "Bead Pusher",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tyre Fitting Maschine"
        },
        "06471": {
            "GenartName": "Thread Cutting Tool, tyre valve",
            "NormTermName": "Thread Cutting Tools",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tyre valve"
        },
        "06472": {
            "GenartName": "Socket, tyre valve removal/installation",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tyre Valve Insert"
        },
        "06473": {
            "GenartName": "Screwdriver, tyre valve insert",
            "NormTermName": "Screwdriver",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tyre Valve Insert"
        },
        "06474": {
            "GenartName": "Puller Lever, tyre valve",
            "NormTermName": "Lever",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tyre valve"
        },
        "06475": {
            "GenartName": "Valve Puller, tyre",
            "NormTermName": "Valve Puller",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tyre"
        },
        "06476": {
            "GenartName": "Disassembly Tool, adhesive weight",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Adhesive weight"
        },
        "06477": {
            "GenartName": "Shears, adhesive weight",
            "NormTermName": "Scissors",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Adhesive weight"
        },
        "06478": {
            "GenartName": "Disassembly Tool Set, adhesive weight",
            "NormTermName": "Disassembly Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Adhesive weight"
        },
        "06479": {
            "GenartName": "Brush, tyre fitting paste",
            "NormTermName": "Brush",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tyre Fitting Paste"
        },
        "06480": {
            "GenartName": "Tyre Fitting Paste",
            "NormTermName": "Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Tyre Fitting"
        },
        "06481": {
            "GenartName": "Marking Chalk",
            "NormTermName": "Marking Chalk",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06482": {
            "GenartName": "Tyre Vulcanising Set",
            "NormTermName": "Tyre Vulcanising Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06483": {
            "GenartName": "Steel Cord Cutter",
            "NormTermName": "Steel Cord Cutter",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06484": {
            "GenartName": "Socket Wrench Insert, oil drain plug",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Oil Drain Plug"
        },
        "06485": {
            "GenartName": "Disassembly Tool Set, stop light",
            "NormTermName": "Disassembly Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Stop Light"
        },
        "06486": {
            "GenartName": "Plastic Repair Set",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Synthetic Material Repair"
        },
        "06487": {
            "GenartName": "Hexagon Tubular Box Wrench, bumper",
            "NormTermName": "Hexagon Tubular Box Wrench",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Bumper"
        },
        "06488": {
            "GenartName": "Plastic Repair Clamp",
            "NormTermName": "Plastic Repair Clamp",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06489": {
            "GenartName": "Soldering Iron, plastic repairs",
            "NormTermName": "Soldering Iron",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Synthetic Material Repair"
        },
        "06490": {
            "GenartName": "Reinforcement Mesh, plastic repairs",
            "NormTermName": "Reinforcing Mat",
            "AssGrpName": "Chemical Products",
            "UsageName": "Synthetic Material Repair"
        },
        "06491": {
            "GenartName": "Pin Wrench Set, park assist sensor",
            "NormTermName": "Pin Wrench",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Parking Assist Sensor"
        },
        "06492": {
            "GenartName": "Hole Punching Set, parking assist sensor",
            "NormTermName": "Hole Punch Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Parking Assist Sensor"
        },
        "06493": {
            "GenartName": "Induction Heating Gun",
            "NormTermName": "Induction Heating Gun",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06494": {
            "GenartName": "Induction Coil, hot gun",
            "NormTermName": "Induction Coil",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Induction Heating Gun"
        },
        "06495": {
            "GenartName": "Induction Pads, hot gun",
            "NormTermName": "Induction Pads",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Induction Heating Gun"
        },
        "06496": {
            "GenartName": "Induction Heating Gun Set",
            "NormTermName": "Induction Heating Gun Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06497": {
            "GenartName": "Suction Set, trim fitting",
            "NormTermName": "Suction Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Trim Strip Installation"
        },
        "06498": {
            "GenartName": "Socket Wrench, door hinge",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Door Hinge"
        },
        "06499": {
            "GenartName": "Groove Nut Key",
            "NormTermName": "Groove Nut Key",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06500": {
            "GenartName": "Drift Set, door hinge roll pin",
            "NormTermName": "Drift Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Door Hinge Roll Pin"
        },
        "06501": {
            "GenartName": "Door Alignment Block",
            "NormTermName": "Door Alignment Block",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06502": {
            "GenartName": "Pressure Pad",
            "NormTermName": "Pressure Pad",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06503": {
            "GenartName": "Pressure Pad Set",
            "NormTermName": "Pressure Pad Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06504": {
            "GenartName": "Opening Wire, vehicle door",
            "NormTermName": "Opening Wire",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Vehicle Door"
        },
        "06505": {
            "GenartName": "Opening Plate, vehicle door",
            "NormTermName": "Opening Plate",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Vehicle Door"
        },
        "06506": {
            "GenartName": "Opener Set, vehicle door",
            "NormTermName": "Opener Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Vehicle Door"
        },
        "06507": {
            "GenartName": "Pliers, exterior mirror",
            "NormTermName": "Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Exterior Mirror"
        },
        "06508": {
            "GenartName": "Chisel Set",
            "NormTermName": "Chisel Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06510": {
            "GenartName": "Cooling Lubricant Stick",
            "NormTermName": "Cooling Lubricant Stick",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06511": {
            "GenartName": "Spot Weld Cutter",
            "NormTermName": "Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drill Out Spot Welds"
        },
        "06512": {
            "GenartName": "Spot Weld Drill",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drill Out Spot Welds"
        },
        "06513": {
            "GenartName": "Slide Hammer",
            "NormTermName": "Slide Hammer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06514": {
            "GenartName": "Slide Hammer Set",
            "NormTermName": "Slide Hammer Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06515": {
            "GenartName": "Edge Striker",
            "NormTermName": "Edge Striker",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06518": {
            "GenartName": "Retrofit Set, welding unit",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Welding Machine"
        },
        "06519": {
            "GenartName": "Consumables, welding device",
            "NormTermName": "Consumables",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Welding Machine"
        },
        "06520": {
            "GenartName": "Clamp, bodywork",
            "NormTermName": "Tensioner",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Repair Systems"
        },
        "06521": {
            "GenartName": "Gripping Jaws, clamping element",
            "NormTermName": "Gripper Jaws",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Bodywork Clamp"
        },
        "06522": {
            "GenartName": "Dinging Hammer Set",
            "NormTermName": "Dinging Hammer Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06523": {
            "GenartName": "Dolly",
            "NormTermName": "Dolly",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06524": {
            "GenartName": "Heel Dolly Set",
            "NormTermName": "Heel Dolly Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06525": {
            "GenartName": "Rebound Mandrel",
            "NormTermName": "Rebound Mandrel",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06526": {
            "GenartName": "Rebound Mandrel Set",
            "NormTermName": "Rebound Mandrel Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06527": {
            "GenartName": "Panel Beating Pry Bar",
            "NormTermName": "Panel Beating Pry Bar",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06528": {
            "GenartName": "Panel Beating Pry Bar Set",
            "NormTermName": "Panel Beating Pry Bar Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06529": {
            "GenartName": "Dent Mirror",
            "NormTermName": "Dent Mirror",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06530": {
            "GenartName": "Adhesive Sheets, panel beating tools (dent repair)",
            "NormTermName": "Adhesive Sheets",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Panel Beating Tools"
        },
        "06531": {
            "GenartName": "Adhesive Sheet Set, panel beating tools (dent repair)",
            "NormTermName": "Adhesive Sheet Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Panel Beating Tools"
        },
        "06532": {
            "GenartName": "Aluminium Hammer",
            "NormTermName": "Aluminium Hammer",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06533": {
            "GenartName": "Serrated Hammer",
            "NormTermName": "Serrated Hammer",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06534": {
            "GenartName": "Finishing Hammer",
            "NormTermName": "Finishing Hammer",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06535": {
            "GenartName": "Pick Hammer",
            "NormTermName": "Pick Hammer",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06536": {
            "GenartName": "Flat pick hammer",
            "NormTermName": "Flat pick hammer",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06537": {
            "GenartName": "Metal-working Hammer",
            "NormTermName": "Metal-working Hammer",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06538": {
            "GenartName": "Wing Panel Beating Hammer",
            "NormTermName": "Wing Panel Beating Hammer",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06539": {
            "GenartName": "Dent Set",
            "NormTermName": "Dent Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06540": {
            "GenartName": "Contour Lever",
            "NormTermName": "Contour Lever",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06541": {
            "GenartName": "Dent Tool",
            "NormTermName": "Dent Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06542": {
            "GenartName": "Fold Aligning Tool",
            "NormTermName": "Fold Aligning Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06543": {
            "GenartName": "Handling Tool",
            "NormTermName": "Handling Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06544": {
            "GenartName": "Tread Aligning Block",
            "NormTermName": "Tread Aligning Block",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06545": {
            "GenartName": "Wiper Arm Set, window cleaning",
            "NormTermName": "Wiper Arm Set",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Window Cleaning System"
        },
        "06546": {
            "GenartName": "Folding Pliers",
            "NormTermName": "Folding Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06547": {
            "GenartName": "Bevelling Pliers",
            "NormTermName": "Bevelling Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06548": {
            "GenartName": "Manual Table Shears",
            "NormTermName": "Manual Table Shears",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06549": {
            "GenartName": "Body Clamping Set",
            "NormTermName": "Body Clamping Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06550": {
            "GenartName": "Holder, vise grip",
            "NormTermName": "Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Vise-grip Pliers"
        },
        "06551": {
            "GenartName": "Blind Rivet Pliers Set",
            "NormTermName": "Blind Rivet Pliers Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06552": {
            "GenartName": "Assortment, blind rivets",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Blind Rivet"
        },
        "06553": {
            "GenartName": "Assortment, threaded rivets",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Threaded Rivet"
        },
        "06554": {
            "GenartName": "Cap",
            "NormTermName": "Cap",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Head Protection"
        },
        "06555": {
            "GenartName": "Safety Helmet, welding",
            "NormTermName": "Safety Helmet",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Welding"
        },
        "06556": {
            "GenartName": "Safety Goggles",
            "NormTermName": "Safety Goggles",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Eye Protection"
        },
        "06557": {
            "GenartName": "Ear Plugs",
            "NormTermName": "Ear Plugs",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Hearing Protection"
        },
        "06558": {
            "GenartName": "Encapsulating Hearing Protection",
            "NormTermName": "Encapsulating Hearing Protection",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Hearing Protection"
        },
        "06559": {
            "GenartName": "Welding Jacket",
            "NormTermName": "Welding Jacket",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06560": {
            "GenartName": "Welding Sleeves",
            "NormTermName": "Welding Sleeves",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Welding"
        },
        "06561": {
            "GenartName": "Welding Gloves",
            "NormTermName": "Protective Glove",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Welding"
        },
        "06562": {
            "GenartName": "Electrician Protective Gloves",
            "NormTermName": "Electrician Protective Gloves",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06563": {
            "GenartName": "Flame Protection Mat",
            "NormTermName": "Flame Protection Mat",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06564": {
            "GenartName": "Flame Protection Mat Set",
            "NormTermName": "Flame Protection Mat Set",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06565": {
            "GenartName": "Welding Apron",
            "NormTermName": "Welding Apron",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Welding"
        },
        "06566": {
            "GenartName": "Welding Protection Sheet",
            "NormTermName": "Welding Protection Sheet",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Welding"
        },
        "06567": {
            "GenartName": "Welding Protection Sheet Set",
            "NormTermName": "Welding Protection Sheet Set",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Welding"
        },
        "06568": {
            "GenartName": "Safety Shoes",
            "NormTermName": "Safety Shoes",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06569": {
            "GenartName": "Safety Shoes",
            "NormTermName": "Safety Shoes",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06570": {
            "GenartName": "Work Shoes",
            "NormTermName": "Work Shoes",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06571": {
            "GenartName": "Surgical Mask",
            "NormTermName": "Surgical Mask",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06572": {
            "GenartName": "Respiratory Mask",
            "NormTermName": "Respiratory Mask",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06573": {
            "GenartName": "Filter, respiratory mask",
            "NormTermName": "Filter",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Respiratory Mask"
        },
        "06574": {
            "GenartName": "Locking Pliers Set",
            "NormTermName": "Locking Pliers Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06575": {
            "GenartName": "Body Protection Mat",
            "NormTermName": "Body Protection Mat",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06577": {
            "GenartName": "Soldering Block Set",
            "NormTermName": "Soldering Block Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06578": {
            "GenartName": "Sanding Block",
            "NormTermName": "Sanding Block",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06579": {
            "GenartName": "Cleaning Disc",
            "NormTermName": "Cleaning Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06581": {
            "GenartName": "Cup Brush, angle grinder",
            "NormTermName": "Cup Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "06582": {
            "GenartName": "Brush, drilling machine",
            "NormTermName": "Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drill"
        },
        "06583": {
            "GenartName": "Brush Disc, drill",
            "NormTermName": "Disc Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drill"
        },
        "06584": {
            "GenartName": "Round Brush, angle grinder",
            "NormTermName": "Round Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "06585": {
            "GenartName": "Disassembly Tool, instrument cluster",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Instrument Cluster"
        },
        "06586": {
            "GenartName": "Disassembly Tool Set, airbag",
            "NormTermName": "Disassembly Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Airbag"
        },
        "06587": {
            "GenartName": "Mounting Tool, door lock/lock cylinder housing",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Door Lock/Lock Cylinder Housing"
        },
        "06588": {
            "GenartName": "Mounting/Disassembly Tool Set, radio",
            "NormTermName": "Installation/Removal Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Radio"
        },
        "06589": {
            "GenartName": "Release Pliers, plastic expanding rivets",
            "NormTermName": "Release Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Plastic Expanding Rivets"
        },
        "06590": {
            "GenartName": "Release Pliers Set, plastic spreading rivets",
            "NormTermName": "Release Pliers Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Plastic Expanding Rivets"
        },
        "06591": {
            "GenartName": "Contour Gauge",
            "NormTermName": "Contour Gauge",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06592": {
            "GenartName": "Feeler Gauge, gap size",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Body Gap Size"
        },
        "06593": {
            "GenartName": "Feeler Gauge Set, gap size",
            "NormTermName": "Gauge Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Body Gap Size"
        },
        "06594": {
            "GenartName": "Thermo Management Module",
            "NormTermName": "Thermo Management Module",
            "AssGrpName": "Cooling System",
            "UsageName": null
        },
        "06595": {
            "GenartName": "Frame, welding protection sheet",
            "NormTermName": "Frame",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Welding Protection Sheet"
        },
        "06596": {
            "GenartName": "Torque Screwdriver",
            "NormTermName": "Screwdriver",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque"
        },
        "06597": {
            "GenartName": "Screw, injection valve holder",
            "NormTermName": "Screw",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Holder"
        },
        "06598": {
            "GenartName": "Torque Limiter, spark plug",
            "NormTermName": "Torque Limiter",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spark Plug"
        },
        "06599": {
            "GenartName": "Angle Measuring Disc",
            "NormTermName": "Angle Measuring Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06600": {
            "GenartName": "Plug-in Square Tang, torque wrench",
            "NormTermName": "Plug-in Square Tang",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "06601": {
            "GenartName": "Push-thru Square Drive, torque wrench",
            "NormTermName": "Push-through Square Drive",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "06602": {
            "GenartName": "Torque Wrench Set",
            "NormTermName": "Torque Wrench Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06603": {
            "GenartName": "Connection piece, delivery module (urea injection)",
            "NormTermName": "Fitting",
            "AssGrpName": "Exhaust System",
            "UsageName": "Delivery Module Cooling (urea injection)"
        },
        "06604": {
            "GenartName": "Valve Set, delivery module (urea injection)",
            "NormTermName": "Valve Set",
            "AssGrpName": "Exhaust System",
            "UsageName": "Delivery Module (urea injection)"
        },
        "06605": {
            "GenartName": "Lever Arm Extension, torque wrench",
            "NormTermName": "Lever Arm Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "06606": {
            "GenartName": "Intake Manifold, delivery module (urea injection)",
            "NormTermName": "Fitting",
            "AssGrpName": "Exhaust System",
            "UsageName": "Delivery Module (urea injection)"
        },
        "06607": {
            "GenartName": "Return Connector, delivery module (urea injection)",
            "NormTermName": "Fitting",
            "AssGrpName": "Exhaust System",
            "UsageName": "Delivery Module Return (urea injection)"
        },
        "06608": {
            "GenartName": "Plug-in Ring Spanner, torque wrench",
            "NormTermName": "Plug-in Ring Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "06610": {
            "GenartName": "Plug-in Open-ended Spanner, torque wrench",
            "NormTermName": "Plug-in Open-ended Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "06611": {
            "GenartName": "Plug-in Square Key, torque wrench",
            "NormTermName": "Plug-in Square Key",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "06612": {
            "GenartName": "Plug-in Changeover Ratchet Head, torque wrench",
            "NormTermName": "Plug-in Changeover Ratchet Head",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "06613": {
            "GenartName": "Plug-in Bit Holder, torque wrench",
            "NormTermName": "Plug-in Bit Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "06614": {
            "GenartName": "Plug-in Increasing Adapter, torque wrench",
            "NormTermName": "Plug-in Increasing Adapter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "06615": {
            "GenartName": "Plug-in Welded Piece, torque wrench",
            "NormTermName": "Plug-in Welded Piece",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "06616": {
            "GenartName": "Plug-in Reducing Adapter, torque wrench",
            "NormTermName": "Plug-in Reducing Adapter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "06617": {
            "GenartName": "Square Adapter Bit",
            "NormTermName": "Square Adapter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Screwdriver Bit"
        },
        "06618": {
            "GenartName": "Square Adapter Bit Set",
            "NormTermName": "Square Adapter Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Screwdriver Bit"
        },
        "06619": {
            "GenartName": "Pump, delivery module (urea injection)",
            "NormTermName": "Pump",
            "AssGrpName": "Exhaust System",
            "UsageName": "Delivery Module (urea injection)"
        },
        "06620": {
            "GenartName": "Heating, tank unit (urea injection)",
            "NormTermName": "Heater",
            "AssGrpName": "Exhaust System",
            "UsageName": "Tank Unit (urea injection)"
        },
        "06621": {
            "GenartName": "Mounting Set, delivery module (urea injection)",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Delivery Module (urea injection)"
        },
        "06622": {
            "GenartName": "Mounting Set, dosage module (urea injection)",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Dosage Module (urea injection)"
        },
        "06623": {
            "GenartName": "Bit Screwdriver",
            "NormTermName": "Bit Screwdriver",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06624": {
            "GenartName": "Reversible Ratchet Bit",
            "NormTermName": "Reversible Ratchet Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06625": {
            "GenartName": "Screwdriver Bit Set",
            "NormTermName": "Screwdriver Bit Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06626": {
            "GenartName": "Bit, reversible ratchet",
            "NormTermName": "Bit Ratchet Ring Socket",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06627": {
            "GenartName": "Angled Screwdriver Set",
            "NormTermName": "Angle Screwdriver Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06628": {
            "GenartName": "Screwdriver Handle, double blade (plug-on screwdriver)",
            "NormTermName": "Screwdriver Handle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Double Blade (plug-on screwdriver)"
        },
        "06629": {
            "GenartName": "Double Blade, plug-on screwdriver",
            "NormTermName": "Double Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": "Plug-on Screwdriver"
        },
        "06630": {
            "GenartName": "Double Blade, plug-in screwdriver",
            "NormTermName": "Double Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Plug-on Screwdriver"
        },
        "06631": {
            "GenartName": "Bit Screwdriver Set",
            "NormTermName": "Bit Screwdriver Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06633": {
            "GenartName": "Impact Screwdriver Set",
            "NormTermName": "Impact Screwdriver Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06636": {
            "GenartName": "Thread Roughening Pliers",
            "NormTermName": "Thread Roughening Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06637": {
            "GenartName": "Ratchet Ring Open-ended Spanner",
            "NormTermName": "Ratchet Ring Open-ended Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06638": {
            "GenartName": "Ratchet Ring Open-ended Spanner Set",
            "NormTermName": "Ratchet Ring Open-ended Spanner Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06639": {
            "GenartName": "Adjustable Spanner Set",
            "NormTermName": "Adjustable Spanner Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06640": {
            "GenartName": "Impact Open-ended Wrench",
            "NormTermName": "Impact Open-ended Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06641": {
            "GenartName": "Open-end Spanner Set",
            "NormTermName": "Open-end Spanner Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06642": {
            "GenartName": "Extension, ring spanner",
            "NormTermName": "Ring Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06643": {
            "GenartName": "Ratchet Ring Spanner",
            "NormTermName": "Ratchet Ring Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06644": {
            "GenartName": "Ratchet Ring Spanner",
            "NormTermName": "Ratchet Ring Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06645": {
            "GenartName": "Ratchet Double Ring Spanner",
            "NormTermName": "Ratchet Double Ring Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06646": {
            "GenartName": "Ratchet Double Ring Spanner Set",
            "NormTermName": "Ratchet Double Ring Spanner Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06647": {
            "GenartName": "Starter/Block Spanner Set",
            "NormTermName": "Starter/Block Spanner Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06648": {
            "GenartName": "Double Ring Spanner Set, bleeder screw/valve",
            "NormTermName": "Spanner Set, double ring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bleeder Screw/Valve"
        },
        "06649": {
            "GenartName": "Double Articulated Wrench Set",
            "NormTermName": "Double Articulated Wrench Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06650": {
            "GenartName": "Pin, hexagon tubular box wrench",
            "NormTermName": "Pin",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hexagon Tubular Box Wrench"
        },
        "06651": {
            "GenartName": "Wheel Nut Socket Set",
            "NormTermName": "Wheel Nut Socket Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06652": {
            "GenartName": "Hook Wrench",
            "NormTermName": "Hook Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06653": {
            "GenartName": "Pin Type Face Spanner",
            "NormTermName": "Pin Type Face Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06654": {
            "GenartName": "Trowel",
            "NormTermName": "Trowel",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "06655": {
            "GenartName": "Plasterer's Float",
            "NormTermName": "Plasterer's Float",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "06656": {
            "GenartName": "Smoothing Trowel",
            "NormTermName": "Smoothing Trowel",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "06657": {
            "GenartName": "Mortar Bucket",
            "NormTermName": "Mortar Bucket",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "06658": {
            "GenartName": "Bucket",
            "NormTermName": "Bucket",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06659": {
            "GenartName": "Plaster Bowl",
            "NormTermName": "Plaster Bowl",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "06660": {
            "GenartName": "Increasing/Reducing Adapter Set, ratchet",
            "NormTermName": "Increasing/Reducing Adapter Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet"
        },
        "06661": {
            "GenartName": "Sleeve, socket wrench insert (wheel bolt)",
            "NormTermName": "Sleeve",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Socket Wrench Insert (wheel bolt)"
        },
        "06662": {
            "GenartName": "Square Drive Handle",
            "NormTermName": "Square Drive Handle",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06663": {
            "GenartName": "Extension Set, socket wrench",
            "NormTermName": "Extension Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Socket Wrench"
        },
        "06665": {
            "GenartName": "Square screwdriver",
            "NormTermName": "Square screwdriver",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06666": {
            "GenartName": "Reversible Ratchet Set",
            "NormTermName": "Reversible Ratchet Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06667": {
            "GenartName": "Tool Set",
            "NormTermName": "Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06668": {
            "GenartName": "Square Crank",
            "NormTermName": "Square Crank",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06669": {
            "GenartName": "Socket Wrench Set, spark plug",
            "NormTermName": "Socket Wrench Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spark Plug"
        },
        "06670": {
            "GenartName": "Internal Extractor",
            "NormTermName": "Internal Extractor",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06671": {
            "GenartName": "Arrester, inner extractor",
            "NormTermName": "Arrester",
            "AssGrpName": "Tool, universal",
            "UsageName": "Internal Extractor"
        },
        "06672": {
            "GenartName": "Internal Extractor Set",
            "NormTermName": "Internal Extractor Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06673": {
            "GenartName": "Puller Set, ball bearing",
            "NormTermName": "Puller Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ball Bearing"
        },
        "06674": {
            "GenartName": "Hook Set, ball bearing extractor",
            "NormTermName": "Hook Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ball Bearing Puller"
        },
        "06675": {
            "GenartName": "Ripping Knife",
            "NormTermName": "Ripping Knife",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06676": {
            "GenartName": "Puller Device, ripping knife",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ripping Knife"
        },
        "06677": {
            "GenartName": "Ripping Knife Set",
            "NormTermName": "Ripping Knife Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06678": {
            "GenartName": "Clamp, puller",
            "NormTermName": "Retaining Clip",
            "AssGrpName": "Tool, universal",
            "UsageName": "Puller"
        },
        "06679": {
            "GenartName": "Hooks, puller",
            "NormTermName": "Hook",
            "AssGrpName": "Tool, universal",
            "UsageName": "Puller"
        },
        "06680": {
            "GenartName": "Hook Set, puller",
            "NormTermName": "Hook Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Puller"
        },
        "06681": {
            "GenartName": "Puller, camshaft gear/sprocket",
            "NormTermName": "Puller",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Camshaft Gear/Sprocket"
        },
        "06682": {
            "GenartName": "Puller Set, wiper arm",
            "NormTermName": "Puller Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wiper Arm"
        },
        "06683": {
            "GenartName": "Puller, brake slack adjuster",
            "NormTermName": "Puller",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Brake Slack Adjuster"
        },
        "06684": {
            "GenartName": "Holder, micrometer",
            "NormTermName": "Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Micrometer"
        },
        "06685": {
            "GenartName": "Vernier Calliper, brake drum",
            "NormTermName": "Vernier Caliper",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Drum"
        },
        "06686": {
            "GenartName": "Video Endoscope Set",
            "NormTermName": "Video Endoscope Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06687": {
            "GenartName": "Camera Probe, video endoscope",
            "NormTermName": "Probe",
            "AssGrpName": "Tool, universal",
            "UsageName": "Video Endoscope"
        },
        "06688": {
            "GenartName": "Cable Extension, camera probe (video endoscope)",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Camera Probe (video endoscope)"
        },
        "06689": {
            "GenartName": "Guide Ball, camera probe (video endoscope)",
            "NormTermName": "Guide Ball",
            "AssGrpName": "Tool, universal",
            "UsageName": "Camera Probe (video endoscope)"
        },
        "06690": {
            "GenartName": "Magnet Attachment, camera probe (video endoscope)",
            "NormTermName": "Magnet Attachment",
            "AssGrpName": "Tool, universal",
            "UsageName": "Camera Probe (video endoscope)"
        },
        "06691": {
            "GenartName": "Brush Attachment, camera probe (video endoscope)",
            "NormTermName": "Brush Attachment",
            "AssGrpName": "Tool, universal",
            "UsageName": "Camera Probe (video endoscope)"
        },
        "06692": {
            "GenartName": "Hook Attachment, camera probe (video endoscope)",
            "NormTermName": "Hook attachment",
            "AssGrpName": "Tool, universal",
            "UsageName": "Camera Probe (video endoscope)"
        },
        "06693": {
            "GenartName": "Front reflector, camera probe (video endoscope)",
            "NormTermName": "Front reflector",
            "AssGrpName": "Tool, universal",
            "UsageName": "Camera Probe (video endoscope)"
        },
        "06694": {
            "GenartName": "Software, video endoscope",
            "NormTermName": "Software",
            "AssGrpName": "Tool, universal",
            "UsageName": "Video Endoscope"
        },
        "06695": {
            "GenartName": "Cabinetmaker's Hammer",
            "NormTermName": "Cabinetmaker's Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06696": {
            "GenartName": "Attachment, club hammer",
            "NormTermName": "Attachment",
            "AssGrpName": "Tool, universal",
            "UsageName": "Mallet"
        },
        "06697": {
            "GenartName": "Claw Hammer",
            "NormTermName": "Claw Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06698": {
            "GenartName": "Welding Hammer",
            "NormTermName": "Welding Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06699": {
            "GenartName": "Axe",
            "NormTermName": "Axe",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06700": {
            "GenartName": "Hatchet",
            "NormTermName": "Hatchet",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06701": {
            "GenartName": "Pickaxe",
            "NormTermName": "Pickaxe",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06702": {
            "GenartName": "Pick",
            "NormTermName": "Pick",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06703": {
            "GenartName": "Splitting Maul",
            "NormTermName": "Splitting Maul",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06704": {
            "GenartName": "Twist Drill Bit Set",
            "NormTermName": "Twist Drill Bit Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06705": {
            "GenartName": "Thread Tap Set",
            "NormTermName": "Thread Tap Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06706": {
            "GenartName": "Sheet Metal Conical Drill Bit Set",
            "NormTermName": "Sheet Metal Conical Drill Bit Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06707": {
            "GenartName": "Step Drill Set",
            "NormTermName": "Step Drill Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06708": {
            "GenartName": "Countersink Set",
            "NormTermName": "Countersink Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06709": {
            "GenartName": "Spot Weld Cutter Set",
            "NormTermName": "Spot Weld Cutter Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06710": {
            "GenartName": "Spot Weld Drill Set",
            "NormTermName": "Spot Weld Drill Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06711": {
            "GenartName": "Milling Drill",
            "NormTermName": "Milling Drill",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06712": {
            "GenartName": "Ratchet Holder, thread tap",
            "NormTermName": "Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Thread Tap"
        },
        "06713": {
            "GenartName": "Adapter Set, thread die",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Threading Die"
        },
        "06714": {
            "GenartName": "Extension, thread tap",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Thread Tap"
        },
        "06715": {
            "GenartName": "Centring Drill, keyhole saw",
            "NormTermName": "Centring Drill",
            "AssGrpName": "Tool, universal",
            "UsageName": "Keyhole Saw"
        },
        "06716": {
            "GenartName": "Extension, keyhole saw",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Keyhole Saw"
        },
        "06717": {
            "GenartName": "Hole Saw Set",
            "NormTermName": "Compass Saw Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06718": {
            "GenartName": "Screw Hole Punch",
            "NormTermName": "Screw Hole Punch",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06719": {
            "GenartName": "Saw Blade, jigsaw",
            "NormTermName": "Saw Blade",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Jigsaw"
        },
        "06720": {
            "GenartName": "Workbench",
            "NormTermName": "Workbench",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06721": {
            "GenartName": "Workbench",
            "NormTermName": "Workbench",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06722": {
            "GenartName": "Hook, shaped recesses for tools",
            "NormTermName": "Hook",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06723": {
            "GenartName": "Double Hook, pegboard tool table",
            "NormTermName": "Double Hook",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06724": {
            "GenartName": "Clamp, shaped recesses for tools",
            "NormTermName": "Clamp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06725": {
            "GenartName": "Round Holder, tool pegboard",
            "NormTermName": "Round Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06726": {
            "GenartName": "Double Clamp, pegboard tool table",
            "NormTermName": "Double Clamp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06727": {
            "GenartName": "Triple Hook, pegboard tool table",
            "NormTermName": "Triple Hook",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06728": {
            "GenartName": "Cable Holder, tool pegboard",
            "NormTermName": "Cable Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06729": {
            "GenartName": "Screwdriver holder",
            "NormTermName": "Screwdriver Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Screwdriver"
        },
        "06730": {
            "GenartName": "Tray Base, tool pegboard",
            "NormTermName": "Tray Base",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06731": {
            "GenartName": "Six-way hook, tool pegboard",
            "NormTermName": "Six-way Hook",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06732": {
            "GenartName": "Drill Bit / Female Hex Holder, tool pegboard",
            "NormTermName": "Drill Bit / Hex Socket Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06733": {
            "GenartName": "Visible Storage Box Holder, shaped recesses for tools",
            "NormTermName": "Visible Storage Box Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06734": {
            "GenartName": "Saw Holder, tool pegboard",
            "NormTermName": "Saw Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06735": {
            "GenartName": "Base Plate, tool pegboard",
            "NormTermName": "Base Plate",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06736": {
            "GenartName": "Wrench holder, tool pegboard",
            "NormTermName": "Spanner Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06737": {
            "GenartName": "Pipe Holder, tool pegboard",
            "NormTermName": "Pipe Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06738": {
            "GenartName": "Round Can Holder, tool pegboard",
            "NormTermName": "Round Can Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06739": {
            "GenartName": "Universal Tool Holder, tool pegboard",
            "NormTermName": "Universal Tool Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06740": {
            "GenartName": "Socket wrench insert holder",
            "NormTermName": "Socket Wrench Insert Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Socket Wrench Insert"
        },
        "06741": {
            "GenartName": "Hose Holder, tool pegboard",
            "NormTermName": "Hose Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06742": {
            "GenartName": "Paper Roll Holder, tool pegboard",
            "NormTermName": "Paper Roll Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06743": {
            "GenartName": "Brochure Holder, tool pegboard",
            "NormTermName": "Brochure Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06744": {
            "GenartName": "Drawing holder, tool pegboard",
            "NormTermName": "Drawing Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06745": {
            "GenartName": "Magnet Holder, pegboard tool table",
            "NormTermName": "Magnet Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06746": {
            "GenartName": "Bit Holder, pegboard tool wall",
            "NormTermName": "Bit Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Board"
        },
        "06747": {
            "GenartName": "Folding Knife",
            "NormTermName": "Folding Knife",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06748": {
            "GenartName": "Folding Knife Set",
            "NormTermName": "Folding Knife Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06749": {
            "GenartName": "Snap-off Blade Set, cutter",
            "NormTermName": "Snap-off Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cutter"
        },
        "06750": {
            "GenartName": "Trapezoidal Blade Set, cutter",
            "NormTermName": "Trapezoidal Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cutter"
        },
        "06751": {
            "GenartName": "Hook Blade Set, cutter",
            "NormTermName": "Hook Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cutter"
        },
        "06752": {
            "GenartName": "Sheet Metal Shears Set",
            "NormTermName": "Sheet Metal Shears Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06753": {
            "GenartName": "Hand Protection Grip, chisel",
            "NormTermName": "Hand Protection Handle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Chisel"
        },
        "06754": {
            "GenartName": "Drift Set",
            "NormTermName": "Taper Pin Punch Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06755": {
            "GenartName": "Split Pin Drift Set",
            "NormTermName": "Split Pin Drift Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06756": {
            "GenartName": "Split Pin Drift, brake pad",
            "NormTermName": "Pin Punch",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Pad/Brake Lining"
        },
        "06757": {
            "GenartName": "Awl",
            "NormTermName": "Awl",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06758": {
            "GenartName": "Split Pin + Drift Set",
            "NormTermName": "Split Pin / Drift Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06759": {
            "GenartName": "Chisel + Drift Set",
            "NormTermName": "Chisel / Drift Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06760": {
            "GenartName": "Embossing Stamp Set",
            "NormTermName": "Embossing Stamp Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06761": {
            "GenartName": "Hook Tool",
            "NormTermName": "Hook Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06762": {
            "GenartName": "Hollow Punch Set",
            "NormTermName": "Hollow Punch Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06763": {
            "GenartName": "Round Hole Punch",
            "NormTermName": "Round Hole Punch",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06764": {
            "GenartName": "Ring Stamping Die Set",
            "NormTermName": "Ring Stamping Die Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06765": {
            "GenartName": "Round Hole Punch Set",
            "NormTermName": "Round Hole Punch Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06766": {
            "GenartName": "Rivet Puller",
            "NormTermName": "Rivet Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06767": {
            "GenartName": "Rivet Puller Set",
            "NormTermName": "Rivet Puller Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06768": {
            "GenartName": "Rivet Setter",
            "NormTermName": "Rivet Setter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06769": {
            "GenartName": "Rivet Setting Set",
            "NormTermName": "Rivet Setting Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06770": {
            "GenartName": "Rivet Puller / Setter",
            "NormTermName": "Rivet Puller / Setter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06771": {
            "GenartName": "Safety Rubber Boots",
            "NormTermName": "Safety Rubber Boots",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06772": {
            "GenartName": "T-Shirt",
            "NormTermName": "T-Shirt",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06773": {
            "GenartName": "Polo Shirt",
            "NormTermName": "Polo-Shirt",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06774": {
            "GenartName": "Shirt",
            "NormTermName": "Shirt",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06775": {
            "GenartName": "Work Jacket",
            "NormTermName": "Work Jacket",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06776": {
            "GenartName": "Work Trousers",
            "NormTermName": "Work Trousers",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06777": {
            "GenartName": "Overall",
            "NormTermName": "Overall",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06778": {
            "GenartName": "Work Vest",
            "NormTermName": "Work Vest",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "06779": {
            "GenartName": "Divider Set, drawer (workshop trolley)",
            "NormTermName": "Organiser Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Drawer (tool trolley)"
        },
        "06780": {
            "GenartName": "Storage compartment, drawer (workshop trolley)",
            "NormTermName": "Insert Compartment",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Drawer (tool trolley)"
        },
        "06781": {
            "GenartName": "Tool Board, workshop trolley",
            "NormTermName": "Pegboard",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06782": {
            "GenartName": "Worktop, tool trolley attachment",
            "NormTermName": "Worktop",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Werkzeugwagennaufsatz"
        },
        "06783": {
            "GenartName": "Storage Tray, workshop trolley",
            "NormTermName": "Storage Tray",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06784": {
            "GenartName": "Work Tray, tool trolley",
            "NormTermName": "Work Tray",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06785": {
            "GenartName": "Mounting Tool Set, torsion bar",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Torsion Bar"
        },
        "06786": {
            "GenartName": "Chassis, workshop trolley attachment",
            "NormTermName": "Chassis",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Werkzeugwagennaufsatz"
        },
        "06787": {
            "GenartName": "Siphon, tool trolley",
            "NormTermName": "Siphon",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06788": {
            "GenartName": "End Fitting, anchor rail",
            "NormTermName": "End Stop",
            "AssGrpName": "Load Securing",
            "UsageName": "Anchor Rail"
        },
        "06789": {
            "GenartName": "End Piece, anchor rail",
            "NormTermName": "End Piece",
            "AssGrpName": "Load Securing",
            "UsageName": "Anchor Rail"
        },
        "06790": {
            "GenartName": "End Piece, locking beam",
            "NormTermName": "End Piece",
            "AssGrpName": "Load Securing",
            "UsageName": "Locking Beam"
        },
        "06791": {
            "GenartName": "Headlamp",
            "NormTermName": "Headlamp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06792": {
            "GenartName": "End Piece, lashing rail",
            "NormTermName": "End Piece",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Rail"
        },
        "06793": {
            "GenartName": "Flat Hook",
            "NormTermName": "Flat Hook",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "06794": {
            "GenartName": "Tripod, working lights",
            "NormTermName": "Tripod",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Worklight"
        },
        "06795": {
            "GenartName": "Clamp, worklight",
            "NormTermName": "Clamp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Worklight"
        },
        "06796": {
            "GenartName": "Roller Set, work light stand",
            "NormTermName": "Pulley Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Worklight Tripod"
        },
        "06797": {
            "GenartName": "Emergency light",
            "NormTermName": "Emergency light",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06798": {
            "GenartName": "Tool Cabinet",
            "NormTermName": "Tool Cabinet",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06799": {
            "GenartName": "Locker",
            "NormTermName": "Locker",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06800": {
            "GenartName": "Fork Head Load Hook",
            "NormTermName": "Fork Head Load Hook",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "06801": {
            "GenartName": "Ring Spanner Set",
            "NormTermName": "Ring Spanner Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06802": {
            "GenartName": "Open-ended Puller Wrench",
            "NormTermName": "Open-ended Puller Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06803": {
            "GenartName": "Arrester, lashing tray",
            "NormTermName": "Counter Plate",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Recess"
        },
        "06804": {
            "GenartName": "Lashing Recess",
            "NormTermName": "Lashing Recess",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "06805": {
            "GenartName": "Lashing Ring",
            "NormTermName": "Lashing Ring",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "06806": {
            "GenartName": "Tightening Strap",
            "NormTermName": "Tightening Strap",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "06807": {
            "GenartName": "Coupling Spanner, fire brigade coupling",
            "NormTermName": "Coupling Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": "Fire Brigade Coupling"
        },
        "06808": {
            "GenartName": "Valve Wheel Hook",
            "NormTermName": "Valve Wheel Hook",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06809": {
            "GenartName": "Knob Wrench",
            "NormTermName": "Knob Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06810": {
            "GenartName": "Valve Hook",
            "NormTermName": "Valve Hook",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06811": {
            "GenartName": "Barrel Key",
            "NormTermName": "Barrel Key",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06812": {
            "GenartName": "Channel Cover Hook",
            "NormTermName": "Channel Cover Hook",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06813": {
            "GenartName": "Push-thru Square Drive, ratchet",
            "NormTermName": "Push-through Square Drive",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet"
        },
        "06814": {
            "GenartName": "Holding Tool Set, crank shaft",
            "NormTermName": "Holding Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Crankshaft"
        },
        "06815": {
            "GenartName": "Extension, crank shaft holding tool",
            "NormTermName": "Extension",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Crankshaft Holding Tool"
        },
        "06816": {
            "GenartName": "Guide Tube, crank shaft holding tool",
            "NormTermName": "Sleeve",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Crankshaft Holding Tool"
        },
        "06817": {
            "GenartName": "Mounting Stand, cylinder head",
            "NormTermName": "Stator",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cylinder Head"
        },
        "06818": {
            "GenartName": "Extractor Set, engine/transmission guide sleeve",
            "NormTermName": "Puller Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Engine/Transmission Guide Sleeve"
        },
        "06819": {
            "GenartName": "Boring Tool, studs",
            "NormTermName": "Extracting Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Stud"
        },
        "06820": {
            "GenartName": "Boring Tool Set, studs",
            "NormTermName": "Extracting Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Stud"
        },
        "06821": {
            "GenartName": "Bolt Extractor Set",
            "NormTermName": "Bolt Extractor Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06822": {
            "GenartName": "Nut Splitter Set",
            "NormTermName": "Nut Splitter Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06823": {
            "GenartName": "Lashing Strap Set",
            "NormTermName": "Lashing Strap Set",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "06824": {
            "GenartName": "Lifting Straps",
            "NormTermName": "Lifting Straps",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "06825": {
            "GenartName": "Sign",
            "NormTermName": "Sign",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "06826": {
            "GenartName": "Tray, cylinder head components",
            "NormTermName": "Tray",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cylinder Head Components"
        },
        "06827": {
            "GenartName": "Compressed Air Adapter, spark plug / glow plug hole",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spark/Glow Plug hole"
        },
        "06828": {
            "GenartName": "Retainer, bucket tappet",
            "NormTermName": "Retainer",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Bucket Tappet"
        },
        "06829": {
            "GenartName": "Mounting Tool, valve spring",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Spring"
        },
        "06830": {
            "GenartName": "Edge Protection, lashing strap",
            "NormTermName": "Edge Protection",
            "AssGrpName": "Load Securing",
            "UsageName": "Tension Strap"
        },
        "06831": {
            "GenartName": "Clamp lock, lashing strap",
            "NormTermName": "Clamp Lock",
            "AssGrpName": "Load Securing",
            "UsageName": "Tension Strap"
        },
        "06832": {
            "GenartName": "Thrust Piece Set, valve spring installation tool",
            "NormTermName": "Thrust Piece Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Spring Installation Tool"
        },
        "06833": {
            "GenartName": "Thrust Piece, valve spring installation tool",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Spring Installation Tool"
        },
        "06834": {
            "GenartName": "Loading Bar",
            "NormTermName": "Loading Bar",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "06835": {
            "GenartName": "Mounting Tool Set, valve spring",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Spring"
        },
        "06836": {
            "GenartName": "Pliers, valve spacer plates",
            "NormTermName": "Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Spacer Plates"
        },
        "06837": {
            "GenartName": "Press-on Mandrel Set, valve stem seal",
            "NormTermName": "Press-on Mandrel Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Stem Seal"
        },
        "06838": {
            "GenartName": "Press-on Mandrel, valve stem seal",
            "NormTermName": "Pressing Mandrel",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Stem Seal"
        },
        "06839": {
            "GenartName": "Impact Extractor, valve stem seal",
            "NormTermName": "Extractor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Stem Seal"
        },
        "06840": {
            "GenartName": "Load Securing Net",
            "NormTermName": "Load Securing Net",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "06841": {
            "GenartName": "Tool Set, valve stem sealing",
            "NormTermName": "Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Stem Seal"
        },
        "06842": {
            "GenartName": "Blade Set, scraper",
            "NormTermName": "Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Scraper"
        },
        "06843": {
            "GenartName": "Impact Puller Set, shaft seal",
            "NormTermName": "Impact Extractor Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Shaft Seal"
        },
        "06844": {
            "GenartName": "Puller Set, shaft seal",
            "NormTermName": "Puller Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Shaft Seal"
        },
        "06845": {
            "GenartName": "Milling Tool Set, valve seat",
            "NormTermName": "Milling Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Seat"
        },
        "06846": {
            "GenartName": "Mounting Tool, valve keeper",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Keeper"
        },
        "06847": {
            "GenartName": "Piston Ring Compressor Set",
            "NormTermName": "Lashing Strap Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Piston Ring"
        },
        "06848": {
            "GenartName": "Holding Tool Set, cylinder liner",
            "NormTermName": "Holding Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Cylinder Sleeve"
        },
        "06849": {
            "GenartName": "Retaining Tool Set, cylinder liner",
            "NormTermName": "Retaining Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Cylinder Sleeve"
        },
        "06850": {
            "GenartName": "Tester, carburettor synchronisation",
            "NormTermName": "Tester",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Carburettor Synchronisation"
        },
        "06851": {
            "GenartName": "Motorcycle Rocker Stand",
            "NormTermName": "Motorcycle Rocker Stand",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "06852": {
            "GenartName": "Tester, oil pressure",
            "NormTermName": "Tester",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Oil Pressure"
        },
        "06853": {
            "GenartName": "Tester, fuel system pressure",
            "NormTermName": "Tester",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Fuel System Pressure"
        },
        "06854": {
            "GenartName": "Pliers Set, hose clamps",
            "NormTermName": "Pliers Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hose Clamp"
        },
        "06855": {
            "GenartName": "Release Pliers, hose line",
            "NormTermName": "Release Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hose Line"
        },
        "06856": {
            "GenartName": "Spanner, turbocharger",
            "NormTermName": "Key",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Turbocharger"
        },
        "06857": {
            "GenartName": "Adapter, turbocharger (oil pressure test device)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Turbocharger (oil return tester)"
        },
        "06858": {
            "GenartName": "Adapter, turbocharger charge air system",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Turbocharger Charge Air System"
        },
        "06859": {
            "GenartName": "Plug, turbocharger charge air system",
            "NormTermName": "Plug",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Turbocharger Charge Air System"
        },
        "06860": {
            "GenartName": "Adapter Set, turbocharger charge air system testing",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Turbocharger Charge Air System Testing"
        },
        "06861": {
            "GenartName": "Retaining Tool Set, camshaft",
            "NormTermName": "Retaining Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Camshaft"
        },
        "06862": {
            "GenartName": "Locking Gauge, camshaft phase adjuster",
            "NormTermName": "Adjustment Gauge",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Phase Adjuster Retaining"
        },
        "06863": {
            "GenartName": "Spanner, timing belt tensioner pulley",
            "NormTermName": "Key",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Timing Belt Tensioner Pulley"
        },
        "06864": {
            "GenartName": "Retaining Pin, timing chain tensioner",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Timing Chain Tensioner"
        },
        "06865": {
            "GenartName": "Eyebolt, lashing strap ring",
            "NormTermName": "Screw",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Strap Ring"
        },
        "06866": {
            "GenartName": "Adjustment Mandrel, injection pump",
            "NormTermName": "Adjustment Mandrel",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injection Pump"
        },
        "06867": {
            "GenartName": "Ratchet Load Clamp, lashing chain",
            "NormTermName": "Ratchet Load Tensioner",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Chain"
        },
        "06868": {
            "GenartName": "Ring, lashing recess",
            "NormTermName": "Ring",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Recess"
        },
        "06869": {
            "GenartName": "Lifting Eye Nut, lashing recess",
            "NormTermName": "Lifting Eye Nut",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Recess"
        },
        "06870": {
            "GenartName": "Round Sling",
            "NormTermName": "Round Sling",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "06871": {
            "GenartName": "Protective Hose, lashing strap",
            "NormTermName": "Protective Hose",
            "AssGrpName": "Load Securing",
            "UsageName": "Tension Strap"
        },
        "06872": {
            "GenartName": "Filter, vacuum line",
            "NormTermName": "Filter",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Vacuum Line"
        },
        "06873": {
            "GenartName": "Barrier Tape Reel",
            "NormTermName": "Barrier Tape Reel",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06875": {
            "GenartName": "Socket Wrench Insert, injector line",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injector Line"
        },
        "06876": {
            "GenartName": "Socket Wrench Insert, injector nozzle",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injector Nozzle"
        },
        "06877": {
            "GenartName": "Set of Socket Wrench Inserts, injector nozzle",
            "NormTermName": "Set of Socket Wrench Inserts",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injector Nozzle"
        },
        "06878": {
            "GenartName": "Socket Wrench Insert, prechamber",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Pre-chamber"
        },
        "06879": {
            "GenartName": "Impact Puller Set, pre-chamber",
            "NormTermName": "Impact Extractor Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Pre-chamber"
        },
        "06880": {
            "GenartName": "Expansion Set, electric kit (towbar)",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Electric Kit (towbar)"
        },
        "06881": {
            "GenartName": "Expansion Set, electric kit (towbar)",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Electric Kit (towbar)"
        },
        "06882": {
            "GenartName": "Tester Range, fuel return quantity",
            "NormTermName": "Test Assortment",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Fuel Return Quantity"
        },
        "06883": {
            "GenartName": "Measurement Tube, fuel return quantity measurement",
            "NormTermName": "Measurement Tube",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Fuel Return Quantity Measurement"
        },
        "06884": {
            "GenartName": "Disassembly Tool, common rail injector",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Common Rail Injector"
        },
        "06885": {
            "GenartName": "Disassembly Tool Set, common rail injector",
            "NormTermName": "Disassembly Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Common Rail Injector"
        },
        "06886": {
            "GenartName": "Cleaning/Milling Tool Set, CR injector seat",
            "NormTermName": "Cleaning/Milling Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "CR Injector Seat"
        },
        "06887": {
            "GenartName": "Cleaning Brush",
            "NormTermName": "Cleaning Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06888": {
            "GenartName": "Cleaning Brush Set",
            "NormTermName": "Cleaning Brush Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06889": {
            "GenartName": "Puller Set, injection pump gear",
            "NormTermName": "Puller Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injector Pump Gear"
        },
        "06890": {
            "GenartName": "Adjustment Screw, injector pump",
            "NormTermName": "Adjustment Screw",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injection Pump"
        },
        "06891": {
            "GenartName": "Retaining Pin, injector pump",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injection Pump"
        },
        "06892": {
            "GenartName": "Retaining Pin Set, injection pump",
            "NormTermName": "Retaining Pin Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injection Pump"
        },
        "06893": {
            "GenartName": "Adjustment Gauge, unit injector",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Unit Injector (UI)"
        },
        "06894": {
            "GenartName": "Retaining Tool, injection pump",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injection Pump"
        },
        "06895": {
            "GenartName": "Release Tool Set, injector pump",
            "NormTermName": "Adjustment Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injection Pump"
        },
        "06896": {
            "GenartName": "Press-out gun",
            "NormTermName": "Press-out Gun",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pressing out of cartidges / tubular bags"
        },
        "06897": {
            "GenartName": "Rechargeable Battery Pack, press-out gun",
            "NormTermName": "Rechargeable Battery Pack",
            "AssGrpName": "Tool, universal",
            "UsageName": "Press-out Gun"
        },
        "06898": {
            "GenartName": "Charge Device, press-out gun",
            "NormTermName": "Charger",
            "AssGrpName": "Tool, universal",
            "UsageName": "Press-out Gun"
        },
        "06899": {
            "GenartName": "Press-out head, press-out gun",
            "NormTermName": "Press-out Head",
            "AssGrpName": "Tool, universal",
            "UsageName": "Press-out Gun"
        },
        "06900": {
            "GenartName": "Union Nut, press-out gun",
            "NormTermName": "Union Nut",
            "AssGrpName": "Tool, universal",
            "UsageName": "Press-out Gun"
        },
        "06901": {
            "GenartName": "Dosing Brush, assembly paste",
            "NormTermName": "Dosing Brush",
            "AssGrpName": "Chemical Products",
            "UsageName": "Mounting Paste"
        },
        "06902": {
            "GenartName": "Cable Protection Tape",
            "NormTermName": "Cable Protection Tape",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "06903": {
            "GenartName": "Compressed Air Spray",
            "NormTermName": "Compressed Air Spray",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06904": {
            "GenartName": "Graphite Spray",
            "NormTermName": "Graphite Spray",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06905": {
            "GenartName": "Ceramic Paste Spray",
            "NormTermName": "Ceramic Paste Spray",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06906": {
            "GenartName": "Mixing Tube, cartridge",
            "NormTermName": "Mixing Tube",
            "AssGrpName": "Chemical Products",
            "UsageName": "Cartridge"
        },
        "06907": {
            "GenartName": "Sealant Adhesive",
            "NormTermName": "Sealant Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06908": {
            "GenartName": "Adhesive Pad",
            "NormTermName": "Adhesive Pad",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06909": {
            "GenartName": "Oil Spot Remover",
            "NormTermName": "Oil Spot Remover",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06910": {
            "GenartName": "Binding Agent",
            "NormTermName": "Binding Agent",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06911": {
            "GenartName": "Adjustment Tool Set, valve timing",
            "NormTermName": "Adjustment Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Timing"
        },
        "06912": {
            "GenartName": "Cutting Wire, glass removal",
            "NormTermName": "Cutting Wire",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Glass removal"
        },
        "06913": {
            "GenartName": "Cable Grease",
            "NormTermName": "Cable Grease",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06914": {
            "GenartName": "PTFE spray",
            "NormTermName": "PTFE spray",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06915": {
            "GenartName": "Adhesive Agent / Cleaning Agent",
            "NormTermName": "Adhesive Agent / Cleaning Agent",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06916": {
            "GenartName": "Universal Adhesive",
            "NormTermName": "Universal Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "06917": {
            "GenartName": "Locking, tool trolley",
            "NormTermName": "Locks",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06918": {
            "GenartName": "Drawer, tool trolley",
            "NormTermName": "Drawer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06920": {
            "GenartName": "Repair Set, reversible ratchet",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Reversible Ratchet"
        },
        "06922": {
            "GenartName": "Adapter, threading die",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Threading Die"
        },
        "06923": {
            "GenartName": "Hand Dispenser, steering wheel cover",
            "NormTermName": "Hand Dispenser",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Steering Wheel Cover"
        },
        "06924": {
            "GenartName": "Bar Pulling Device",
            "NormTermName": "Bar Pulling Device",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "06925": {
            "GenartName": "Puller Bell, installation tool (wheel hub/wheel bearing)",
            "NormTermName": "Puller Bell",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Bearing Installation Tool"
        },
        "06926": {
            "GenartName": "Puller, ball bearing",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ball Bearing"
        },
        "06927": {
            "GenartName": "Adapter, oil filling funnel",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Oil Filler Funnel"
        },
        "06928": {
            "GenartName": "Drift, wheel bearing",
            "NormTermName": "Punsh",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Bearing"
        },
        "06929": {
            "GenartName": "Extension, power socket",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Power Socket"
        },
        "06930": {
            "GenartName": "Head, bumping hammer",
            "NormTermName": "Head",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bumping Hammer"
        },
        "06931": {
            "GenartName": "Anchor Rivet Head, riveting pliers",
            "NormTermName": "Anchor Rivet Head",
            "AssGrpName": "Tool, universal",
            "UsageName": "Riveting Pliers"
        },
        "06932": {
            "GenartName": "Anchor Rivet Pliers",
            "NormTermName": "Anchor Rivet Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06933": {
            "GenartName": "Stop Bracket",
            "NormTermName": "Stop Bracket",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06934": {
            "GenartName": "Bodywork Clamp",
            "NormTermName": "Bodywork Clamp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06935": {
            "GenartName": "Upholstery Clamps",
            "NormTermName": "Upholstery Clamps",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "06936": {
            "GenartName": "Strut, puller",
            "NormTermName": "Strut",
            "AssGrpName": "Tool, universal",
            "UsageName": "Puller"
        },
        "06937": {
            "GenartName": "Pressing Piece, installation tool (wheel hub / wheel bearing",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "06938": {
            "GenartName": "Nozzle Head, Bunsen burner",
            "NormTermName": "Dosing Head",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Bunsen Burner"
        },
        "06939": {
            "GenartName": "Deep Seat Milling Cutter",
            "NormTermName": "Deep Seat Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06940": {
            "GenartName": "Bending Irons",
            "NormTermName": "Bending Irons",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "06941": {
            "GenartName": "Drilling Jig",
            "NormTermName": "Drilling Jig",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06942": {
            "GenartName": "Tool Holder, tool cabinet",
            "NormTermName": "Tool Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Cabinet"
        },
        "06943": {
            "GenartName": "Impact Extractor Set, brake disc",
            "NormTermName": "Impact Extractor Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Brake Disc"
        },
        "06944": {
            "GenartName": "Scraper, brake disc",
            "NormTermName": "Scraper",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Brake Disc"
        },
        "06945": {
            "GenartName": "Siphon",
            "NormTermName": "Siphon",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "06946": {
            "GenartName": "Door, tool trolley",
            "NormTermName": "Door",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06947": {
            "GenartName": "Mouthpiece Set, riveting pliers",
            "NormTermName": "Mouthpiece Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Riveting Pliers"
        },
        "06948": {
            "GenartName": "Worktop, tool trolley",
            "NormTermName": "Worktop",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06950": {
            "GenartName": "Chain, locking pliers",
            "NormTermName": "Chain",
            "AssGrpName": "Tool, universal",
            "UsageName": "Vise-grip Pliers"
        },
        "06951": {
            "GenartName": "Tip Cutter",
            "NormTermName": "Tip Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06952": {
            "GenartName": "Catchment Chain, ball joint ejector",
            "NormTermName": "Catchment Chain",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Ball Joint Ejector"
        },
        "06953": {
            "GenartName": "Pressure Plate, spring compressor",
            "NormTermName": "Pressure Plate",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Compressor"
        },
        "06954": {
            "GenartName": "Rack Base, tool trolley",
            "NormTermName": "Shelf Base",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06955": {
            "GenartName": "Draw Knife, glass removal",
            "NormTermName": "Draw Knife",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Glass removal"
        },
        "06956": {
            "GenartName": "Draw Knife Set, glass removal",
            "NormTermName": "Draw Knife Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Glass removal"
        },
        "06957": {
            "GenartName": "Blade, draw knife (glass removal)",
            "NormTermName": "Blade",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Draw Knife (glass removal)"
        },
        "06958": {
            "GenartName": "Paper Basket, tool trolley",
            "NormTermName": "Paper Basket",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06959": {
            "GenartName": "Washer, vice",
            "NormTermName": "Washer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vice"
        },
        "06960": {
            "GenartName": "Glass Removal Set",
            "NormTermName": "Glass Removal Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06961": {
            "GenartName": "Support Plate, mobile jack",
            "NormTermName": "Support Plate",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Mobile Jack"
        },
        "06962": {
            "GenartName": "Holding Pliers, solder braid",
            "NormTermName": "Holding Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Solder Braid"
        },
        "06963": {
            "GenartName": "Bowden Cable, cable clamping pliers",
            "NormTermName": "Bowden Cable",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hose Clamp"
        },
        "06964": {
            "GenartName": "Pipe Wrench",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipes/Lines"
        },
        "06965": {
            "GenartName": "Protective Pocket, cover drape",
            "NormTermName": "Bag",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Cover Cloth"
        },
        "06966": {
            "GenartName": "Handle, plug-on square drive",
            "NormTermName": "Handle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Plug-in Square Drive"
        },
        "06967": {
            "GenartName": "Ring Stamping Die",
            "NormTermName": "Ring Stamping Die",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06968": {
            "GenartName": "Pry Bar",
            "NormTermName": "Pry Bar",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "06969": {
            "GenartName": "Suction Unit, trim fitting",
            "NormTermName": "Suction Unit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Trim Strip Installation"
        },
        "06970": {
            "GenartName": "Handle, draw knife (glass removal)",
            "NormTermName": "Handle",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Draw Knife (glass removal)"
        },
        "06971": {
            "GenartName": "Long Knife Set, glass removal",
            "NormTermName": "Long Knife Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Glass removal"
        },
        "06972": {
            "GenartName": "File Set",
            "NormTermName": "File Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06973": {
            "GenartName": "Adjusting Tool, torque wrench",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "06974": {
            "GenartName": "Threaded Pins, installation tool (wheel hub/bearing)",
            "NormTermName": "Thread Bolt",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "06975": {
            "GenartName": "Dolly, installation tool (wheel hub/wheel bearing)",
            "NormTermName": "Arrester",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "06976": {
            "GenartName": "Thrust Piece Set, installation tool (wheel hub/bearing)",
            "NormTermName": "Thrust Piece Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "06977": {
            "GenartName": "Spindle Sleeve, puller",
            "NormTermName": "Spindle Sleeve",
            "AssGrpName": "Tool, universal",
            "UsageName": "Puller"
        },
        "06978": {
            "GenartName": "Washer, puller",
            "NormTermName": "Washer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Puller"
        },
        "06979": {
            "GenartName": "Ball Bearing, extractor",
            "NormTermName": "Bearing",
            "AssGrpName": "Tool, universal",
            "UsageName": "Puller"
        },
        "06980": {
            "GenartName": "Cutting Tool, oil filter",
            "NormTermName": "Cutting Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Oil Filter"
        },
        "06981": {
            "GenartName": "Oil Dipstick",
            "NormTermName": "Oil Dipstick",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "06982": {
            "GenartName": "Impact Extractor, common rail injector",
            "NormTermName": "Impact Extractor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Common Rail Injector"
        },
        "06983": {
            "GenartName": "Adapter, impact extractor (common rail injector)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Impact Extractor (common rail injector)"
        },
        "06984": {
            "GenartName": "Mitre Angle",
            "NormTermName": "Mitre Angle",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06985": {
            "GenartName": "Dividers",
            "NormTermName": "Dividers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06986": {
            "GenartName": "Gauge, wrench profile",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wrench Profile"
        },
        "06987": {
            "GenartName": "Weld Seam Gauge",
            "NormTermName": "Weld Seam Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06988": {
            "GenartName": "Thread Gauge",
            "NormTermName": "Thread Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06989": {
            "GenartName": "Vernier Height Gauge",
            "NormTermName": "Vernier Height Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "06990": {
            "GenartName": "Drawer Panel, tool trolley",
            "NormTermName": "Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Drawer (tool trolley)"
        },
        "06991": {
            "GenartName": "Thrust Piece, press-in/out tool",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Tool, universal",
            "UsageName": "Press-in-/Extraction Tools"
        },
        "06992": {
            "GenartName": "Hydraulic Cylinder, ball joint ejector",
            "NormTermName": "Hydraulic Cylinder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ball Joint Ejector"
        },
        "06993": {
            "GenartName": "Centring Sleeve, coupling",
            "NormTermName": "Sleeve",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch"
        },
        "06994": {
            "GenartName": "Handle, tool trolley",
            "NormTermName": "Handle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "06995": {
            "GenartName": "Video grabber",
            "NormTermName": "Video grabber",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "06996": {
            "GenartName": "Hydraulic Cylinder, puller spindle",
            "NormTermName": "Hydraulic Cylinder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Puller Spindle"
        },
        "06997": {
            "GenartName": "Pull Ring, spindle",
            "NormTermName": "Pull ring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Spindle"
        },
        "06999": {
            "GenartName": "Soldering Sponge",
            "NormTermName": "Soldering Sponge",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07000": {
            "GenartName": "Guide Rail, tool trolley drawer",
            "NormTermName": "Guide Rail",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley Drawer"
        },
        "07001": {
            "GenartName": "Cleaning Tool, grease nipple",
            "NormTermName": "Cleaning Equipment",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Grease Nipple"
        },
        "07002": {
            "GenartName": "Extension, extractor separating knife",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Separating Knife, puller device"
        },
        "07003": {
            "GenartName": "Lock, tool trolley",
            "NormTermName": "Lock",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "07004": {
            "GenartName": "Base, workshop equipment",
            "NormTermName": "Base",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Press"
        },
        "07005": {
            "GenartName": "Rubber Profile, tool trolley",
            "NormTermName": "Rubber Profile",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "07006": {
            "GenartName": "Retaining Chain, tool trolley compartment",
            "NormTermName": "Chain",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley Compartment"
        },
        "07007": {
            "GenartName": "Cover, tool trolley",
            "NormTermName": "Lid",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "07008": {
            "GenartName": "Tubular Column, tool compartments",
            "NormTermName": "Tubular Column",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley Compartments"
        },
        "07009": {
            "GenartName": "Flange Driver",
            "NormTermName": "Flange Driver",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07010": {
            "GenartName": "Flange Driver Set",
            "NormTermName": "Flange Driver Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07011": {
            "GenartName": "Adapter, wiper arm puller",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wiper Arm Puller"
        },
        "07012": {
            "GenartName": "Pressing Piece Adapter, press-in/out tool",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Press-in-/Extraction Tools"
        },
        "07013": {
            "GenartName": "Adapter, slide hammer",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Slide Hammer"
        },
        "07014": {
            "GenartName": "Extension, ball bearing extractor",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ball Bearing Puller"
        },
        "07015": {
            "GenartName": "Tool Trolley Blinds",
            "NormTermName": "Blinds",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "07016": {
            "GenartName": "Pull Rod, ball bearing extractor",
            "NormTermName": "Pull Rod",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ball Bearing Puller"
        },
        "07017": {
            "GenartName": "Nut Set, pull rod (ball bearing extractor)",
            "NormTermName": "Nut Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pull Rod (ball bearing extractor)"
        },
        "07018": {
            "GenartName": "Protective Mat, tool trolley",
            "NormTermName": "Floor Mat",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "07019": {
            "GenartName": "Washer Set, pull rod (ball bearing extractor)",
            "NormTermName": "Washer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pull Rod (ball bearing extractor)"
        },
        "07020": {
            "GenartName": "Extension, extractor spindle",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Puller Spindle"
        },
        "07021": {
            "GenartName": "Nut, mounting tool silent bearing",
            "NormTermName": "Nut",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Installation Tool, silent bearing"
        },
        "07022": {
            "GenartName": "Spacer, silent bearing installation tool",
            "NormTermName": "Spacer",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Installation Tool, silent bearing"
        },
        "07023": {
            "GenartName": "Thrust Piece Set, installation tool ball joint",
            "NormTermName": "Thrust Piece Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ball Joint Mounting Tools"
        },
        "07024": {
            "GenartName": "Adapter, installation tool ball joint",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ball Joint Mounting Tools"
        },
        "07025": {
            "GenartName": "Work Coat",
            "NormTermName": "Work Coat",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "07026": {
            "GenartName": "Footbed insole",
            "NormTermName": "Footbed insole",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "07027": {
            "GenartName": "Cleaning disc, oil sump",
            "NormTermName": "Cleaning Disc",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Oil Sump"
        },
        "07028": {
            "GenartName": "Cleaning Disc Set, oil sump",
            "NormTermName": "Cleaning Disc Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Oil Sump"
        },
        "07029": {
            "GenartName": "Holder, oil tray cleaning disc",
            "NormTermName": "Holder",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Oil Tray Cleaning Disc"
        },
        "07030": {
            "GenartName": "Guide Pin, oil tray separating knife",
            "NormTermName": "Pin",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Oil Tray Separating Knife"
        },
        "07031": {
            "GenartName": "Eccentric Tool, track rod ball joint",
            "NormTermName": "Eccentric Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Track Rod Ball Joint"
        },
        "07032": {
            "GenartName": "Hydraulic Cylinder, workshop press",
            "NormTermName": "Hydraulic Cylinder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Press"
        },
        "07033": {
            "GenartName": "Protective Work Suit",
            "NormTermName": "Protective Work Suit",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "07034": {
            "GenartName": "Coloured Filter Screen",
            "NormTermName": "Coloured Filter Screen",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07035": {
            "GenartName": "Magnifying Glass",
            "NormTermName": "Magnifying Glass",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07036": {
            "GenartName": "Locking Piece, tool trolley locking",
            "NormTermName": "Locking Piece",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley Locking"
        },
        "07037": {
            "GenartName": "Soldering Tool Kit",
            "NormTermName": "Soldering Tool Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07038": {
            "GenartName": "Attachment, soldering iron",
            "NormTermName": "Attachment",
            "AssGrpName": "Tool, universal",
            "UsageName": "Soldering Iron"
        },
        "07039": {
            "GenartName": "Wire Reel, soldering iron",
            "NormTermName": "Coil",
            "AssGrpName": "Tool, universal",
            "UsageName": "Soldering Iron"
        },
        "07040": {
            "GenartName": "Soldering Block",
            "NormTermName": "Soldering Block",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07041": {
            "GenartName": "Locking Pins, tool trolley drawer",
            "NormTermName": "Locking Pins",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley Drawer"
        },
        "07042": {
            "GenartName": "Cutter Set",
            "NormTermName": "Cutter Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07043": {
            "GenartName": "Workshop Job Pocket",
            "NormTermName": "Workshop Job Pocket",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07045": {
            "GenartName": "Trim/Protection Strip, drawer front (tool trolley)",
            "NormTermName": "Trim/Protection Strip",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Drawer Front (tool trolley)"
        },
        "07046": {
            "GenartName": "Corner End Cap, tool trolley",
            "NormTermName": "Cap",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Corne Finishers"
        },
        "07047": {
            "GenartName": "Adjusting Lever, brake shoe",
            "NormTermName": "Adjusting Lever",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Shoe"
        },
        "07048": {
            "GenartName": "Mounting Tools, timing belt",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Timing Belt"
        },
        "07049": {
            "GenartName": "Installation Tools, brake spring",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spring, brake drum"
        },
        "07050": {
            "GenartName": "Wrench, brake caliper",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Bolts, brake caliper"
        },
        "07051": {
            "GenartName": "Retaining Pin, timing chain tensioner",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Timing Chain Tensioner"
        },
        "07052": {
            "GenartName": "Reamer, ABS sensor mounting",
            "NormTermName": "Reamer",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "ABS Sensor Mounting"
        },
        "07053": {
            "GenartName": "Pin Wrench, strut",
            "NormTermName": "Pin Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Suspension Strut"
        },
        "07054": {
            "GenartName": "Pressure Plate, spring compressor",
            "NormTermName": "Pressure Plate",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spring Compressor"
        },
        "07055": {
            "GenartName": "Safety Guard Set, spring compressor pressure plate",
            "NormTermName": "Protection Agent",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spring Compressor"
        },
        "07056": {
            "GenartName": "Removal Lever, door panel",
            "NormTermName": "Removal Lever",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Interior Door Panel"
        },
        "07057": {
            "GenartName": "O-ring, power socket",
            "NormTermName": "Ring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Power Socket"
        },
        "07058": {
            "GenartName": "Spark Plug Brush",
            "NormTermName": "Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Spark Plug"
        },
        "07059": {
            "GenartName": "Vulcanising Needle",
            "NormTermName": "Vulcanising Needle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07060": {
            "GenartName": "Vulcanising Rasp",
            "NormTermName": "Vulcanising Rasp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07061": {
            "GenartName": "Vulcanising Thread",
            "NormTermName": "Vulcanising Thread",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07062": {
            "GenartName": "Spanner, timing belt tensioner pulley",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Timing Belt Tensioner Pulley"
        },
        "07063": {
            "GenartName": "Socket Wrench Insert, turbocharger",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Turbocharger"
        },
        "07064": {
            "GenartName": "Socket Wrench Insert, turbocharger",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Turbocharger"
        },
        "07065": {
            "GenartName": "Compressed Air Adapter, control times (variable valve stroke",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Adjusting Unit (variable valve stroke)"
        },
        "07066": {
            "GenartName": "Compressed Air Adapter, control times (variable valve stroke",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Adjusting Unit (variable valve stroke)"
        },
        "07067": {
            "GenartName": "Assembly Device, adjusting unit (variable valve stroke)",
            "NormTermName": "Mounting Device",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Adjusting Unit (variable valve stroke)"
        },
        "07068": {
            "GenartName": "Chain Tensioner, timing chain",
            "NormTermName": "Tensioner",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Timing Chain"
        },
        "07070": {
            "GenartName": "Adapter, locking tool (control times)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Locking Tool (control times)"
        },
        "07071": {
            "GenartName": "Adapter, locking tool (control times)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Locking Tool (control times)"
        },
        "07072": {
            "GenartName": "Spacer, locking tool (control times)",
            "NormTermName": "Spacer",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Locking Tool (control times)"
        },
        "07073": {
            "GenartName": "Spacer, locking tool (control times)",
            "NormTermName": "Spacer",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Locking Tool (control times)"
        },
        "07074": {
            "GenartName": "Adapter Set, locking tool (control times)",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Locking Tool (control times)"
        },
        "07075": {
            "GenartName": "Adapter Set, locking tool (control times)",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Locking Tool (control times)"
        },
        "07076": {
            "GenartName": "Screwdriver, battery sealing plug",
            "NormTermName": "Screwdriver",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Battery Sealing Plug"
        },
        "07077": {
            "GenartName": "Thick Seat Cutters, glow plugs",
            "NormTermName": "Deep Seat Milling Cutter",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Glow Plug"
        },
        "07078": {
            "GenartName": "Adapter Set, vacuum filling device (control times)",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vacuum Filling Unit (cooling system)"
        },
        "07079": {
            "GenartName": "Hearing Protection Bracket",
            "NormTermName": "Hearing Protection Bracket",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "07080": {
            "GenartName": "Earplugs, hearing protection bracket",
            "NormTermName": "Ear Plugs",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Hearing Protection Bracket"
        },
        "07081": {
            "GenartName": "Installation Tool Set, brake service",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Brake Service"
        },
        "07082": {
            "GenartName": "Blade Set, folding knife",
            "NormTermName": "Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Folding Knife"
        },
        "07083": {
            "GenartName": "Assortment, thread repair",
            "NormTermName": "Assortment",
            "AssGrpName": "Tool, universal",
            "UsageName": "Thread Repair"
        },
        "07084": {
            "GenartName": "Bulb, workshop mirror",
            "NormTermName": "Bulb",
            "AssGrpName": "Tool, universal",
            "UsageName": "Workshop Mirror"
        },
        "07085": {
            "GenartName": "Charger, hand lamp",
            "NormTermName": "Charger",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Hand Lamp"
        },
        "07086": {
            "GenartName": "rechargeable Battery, hand lamp",
            "NormTermName": "Rechargeable Battery Pack",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Hand Lamp"
        },
        "07087": {
            "GenartName": "Roller Set, workshop creeper",
            "NormTermName": "Pulley Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Creeper"
        },
        "07088": {
            "GenartName": "Protective Cap Set, lubricating nipple",
            "NormTermName": "Protective Cap Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Grease Nipple"
        },
        "07089": {
            "GenartName": "Replacement Oil Filter Strap",
            "NormTermName": "Replacement Oil Filter Strap",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07091": {
            "GenartName": "Locking Screw, control times",
            "NormTermName": "Locking Screw",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Valve Timing"
        },
        "07092": {
            "GenartName": "Removal Lever Set, door panel",
            "NormTermName": "Removal Lever Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Interior Door Panel"
        },
        "07093": {
            "GenartName": "Locking Screw, control times",
            "NormTermName": "Locking Screw",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Timing"
        },
        "07094": {
            "GenartName": "Battery Carry Handle",
            "NormTermName": "Handle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Battery"
        },
        "07095": {
            "GenartName": "Grinding Tool, valve seat",
            "NormTermName": "Grinding Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Seat"
        },
        "07096": {
            "GenartName": "Grinding Tool, timing belt tension",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Timing Belt Tension"
        },
        "07097": {
            "GenartName": "Worktop, workbench",
            "NormTermName": "Worktop",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workbench"
        },
        "07098": {
            "GenartName": "Multifunction Tool (multi-tool)",
            "NormTermName": "Multifunction Tool (multi-tool)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07099": {
            "GenartName": "Grinding Tool, timing belt tension",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Timing Belt Tension"
        },
        "07100": {
            "GenartName": "Cartridge Clamp",
            "NormTermName": "Key",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cartridge"
        },
        "07101": {
            "GenartName": "Ratchet Tensioner",
            "NormTermName": "Ratchet Tensioner",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07102": {
            "GenartName": "Adapter, tool module",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Module"
        },
        "07103": {
            "GenartName": "Clamping Jaws Set, flaring tool",
            "NormTermName": "Clamping Jaws Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Bordering Tool"
        },
        "07104": {
            "GenartName": "Roller Set, mobile jack",
            "NormTermName": "Pulley Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Mobile Jack"
        },
        "07105": {
            "GenartName": "Hydraulic Unit, mobile jack",
            "NormTermName": "Hydraulic Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Mobile Jack"
        },
        "07106": {
            "GenartName": "Support, trestle",
            "NormTermName": "Support",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Trestle"
        },
        "07107": {
            "GenartName": "Measurement Device, turning angle measurement",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Angle Measurement"
        },
        "07108": {
            "GenartName": "Centring Insert, pressure plate spring compressor",
            "NormTermName": "Centring Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Pressure Plate Spring Compressor"
        },
        "07109": {
            "GenartName": "Centring Insert, pressure plate spring compressor",
            "NormTermName": "Centring Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Pressure Plate Spring Compressor"
        },
        "07110": {
            "GenartName": "Cover Disc, random orbit sander",
            "NormTermName": "Cover Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": "Eccentric Grinder"
        },
        "07111": {
            "GenartName": "Support Plate, random orbit sander",
            "NormTermName": "Support Plate",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Eccentric Grinder"
        },
        "07112": {
            "GenartName": "Suction Hose, random orbit sander",
            "NormTermName": "Hose",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Eccentric Grinder"
        },
        "07113": {
            "GenartName": "Actuating Lever, impact screwdriver",
            "NormTermName": "Actuating Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07114": {
            "GenartName": "Drive Set, ratchet screwdriver",
            "NormTermName": "Drive Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Ratchet Screwdriver"
        },
        "07115": {
            "GenartName": "Bushing, ratchet screwdriver",
            "NormTermName": "Bush",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Ratchet Screwdriver"
        },
        "07116": {
            "GenartName": "Drive Head,",
            "NormTermName": "Drive Head",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Ratchet Screwdriver"
        },
        "07117": {
            "GenartName": "Blow-out Pin",
            "NormTermName": "Blow-out Pin",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07118": {
            "GenartName": "Spacer Ring, pressure plate spring compressor",
            "NormTermName": "Spacer Ring",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Pressure Plate Spring Compressor"
        },
        "07119": {
            "GenartName": "Outlet Filter Set, compressed air tool",
            "NormTermName": "Filter Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Tools"
        },
        "07120": {
            "GenartName": "Spacer Ring, pressure plate spring compressor",
            "NormTermName": "Spacer Ring",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Pressure Plate Spring Compressor"
        },
        "07121": {
            "GenartName": "Accessory Set, spring compressor",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Compressor"
        },
        "07122": {
            "GenartName": "Screw Set, compressed air tool",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Tools"
        },
        "07123": {
            "GenartName": "Clamping Device, spring compressor",
            "NormTermName": "Clamping Device",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Compressor"
        },
        "07124": {
            "GenartName": "Bushing, compressed air tool",
            "NormTermName": "Bush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Compressed Air Tools"
        },
        "07125": {
            "GenartName": "Clamping disc, spring compressor",
            "NormTermName": "Clamping Disc",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Compressor"
        },
        "07126": {
            "GenartName": "Socket Wrench Set, suspension strut support mount",
            "NormTermName": "Socket Wrench Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Suspension Strut Support Mount"
        },
        "07127": {
            "GenartName": "Hook Tool",
            "NormTermName": "Hook Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "07128": {
            "GenartName": "Mounting/Disassembly Tools, radio",
            "NormTermName": "Mounting/Dismantling Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Radio"
        },
        "07129": {
            "GenartName": "Mounting/Disassembly Tools, radio",
            "NormTermName": "Mounting/Dismantling Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Radio"
        },
        "07130": {
            "GenartName": "Mounting/Disassembly Tool Set, radio",
            "NormTermName": "Installation/Removal Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Radio"
        },
        "07131": {
            "GenartName": "Brush Belt, belt grinder",
            "NormTermName": "Brush Belt",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Belt Grinder"
        },
        "07132": {
            "GenartName": "Seal Set, impact screwdriver",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07133": {
            "GenartName": "Spacer Ring, impact screwdriver",
            "NormTermName": "Spacer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07134": {
            "GenartName": "Housing, impact screwdriver",
            "NormTermName": "Housing",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07135": {
            "GenartName": "Housing Screw Set, impact screwdriver",
            "NormTermName": "Housing Screw Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07136": {
            "GenartName": "Housing Cover, impact screwdriver",
            "NormTermName": "Cover",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07137": {
            "GenartName": "Bearing, ratchet screwdriver",
            "NormTermName": "Bearing",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07138": {
            "GenartName": "Ball Set, impact screwdriver",
            "NormTermName": "Ball Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07139": {
            "GenartName": "Disc Set, ratchet screwdriver",
            "NormTermName": "Lining Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07141": {
            "GenartName": "Air Outlet, impact screwdriver",
            "NormTermName": "Air Outlet",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07142": {
            "GenartName": "Air Inlet, impact screwdriver",
            "NormTermName": "Air Inlet",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07143": {
            "GenartName": "Air Inlet Set, impact screwdriver",
            "NormTermName": "Air Inlet Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07144": {
            "GenartName": "Housing, ratchet screwdriver",
            "NormTermName": "Housing",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07145": {
            "GenartName": "Impact Mechanism, impact screwdriver",
            "NormTermName": "Impact Mechanism",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07146": {
            "GenartName": "Impact Mechanism Bushing, impact screwdriver",
            "NormTermName": "Impact Mechanism Bushing",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07147": {
            "GenartName": "Impact Mechanism Cage, impact screwdriver",
            "NormTermName": "Impact Mechanism Cage",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07148": {
            "GenartName": "Crankshaft, ratchet screwdriver",
            "NormTermName": "Shaft",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07149": {
            "GenartName": "Running Belt Set, belt grinder",
            "NormTermName": "Running Belt Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Belt Grinder"
        },
        "07150": {
            "GenartName": "Pulley, belt grinder",
            "NormTermName": "Pulley",
            "AssGrpName": "Tool, universal",
            "UsageName": "Belt Grinder"
        },
        "07151": {
            "GenartName": "Repair Set, ratchet screwdriver",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07152": {
            "GenartName": "Rotor, ratchet screwdriver",
            "NormTermName": "Rotor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07153": {
            "GenartName": "Rotor Bushing, ratchet screwdriver",
            "NormTermName": "Bush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07154": {
            "GenartName": "Handle, ratchet screwdriver",
            "NormTermName": "Handle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07155": {
            "GenartName": "Nut, ratchet screwdriver",
            "NormTermName": "Nut",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07156": {
            "GenartName": "Socket Wrench Insert, pitman arm",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Pitman Arm"
        },
        "07157": {
            "GenartName": "Grinding Disc Set, random orbit sander",
            "NormTermName": "Grinding Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Eccentric Grinder"
        },
        "07158": {
            "GenartName": "Lubricating Nipple, impact screwdriver",
            "NormTermName": "Nipple",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07159": {
            "GenartName": "Lubricating Nipple Set, impact screwdriver",
            "NormTermName": "Nipple Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07160": {
            "GenartName": "Cutting Disc Set, angle grinder",
            "NormTermName": "Cutting Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "07161": {
            "GenartName": "Changeover Lever, impact screwdriver",
            "NormTermName": "Changeover Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07162": {
            "GenartName": "Changeover Lever Set, impact screwdriver",
            "NormTermName": "Changeover Lever Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07163": {
            "GenartName": "Valve Set, belt grinder",
            "NormTermName": "Valve Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Belt Grinder"
        },
        "07164": {
            "GenartName": "Saw Blade Set, jigsaw",
            "NormTermName": "Saw Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Jigsaw"
        },
        "07165": {
            "GenartName": "Square Tang, impact screwdriver",
            "NormTermName": "Square Tang",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07166": {
            "GenartName": "Engine Housing, ratchet screwdriver",
            "NormTermName": "Motor Housing",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07167": {
            "GenartName": "Needle Set, needle rust remover",
            "NormTermName": "Needle Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Needle Scaler"
        },
        "07168": {
            "GenartName": "Ring Gear, ratchet screwdriver",
            "NormTermName": "Ring Gear",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07169": {
            "GenartName": "Cylinder, impact screwdriver",
            "NormTermName": "Cylinder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07170": {
            "GenartName": "Cylinder Set, impact screwdriver",
            "NormTermName": "Cylinder Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07171": {
            "GenartName": "Air Guide Panel Set, impact screwdriver",
            "NormTermName": "Air Guide Panel Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07172": {
            "GenartName": "Spring, impact screwdriver",
            "NormTermName": "Spring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07173": {
            "GenartName": "Spring Set, impact screwdriver",
            "NormTermName": "Spring Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07174": {
            "GenartName": "Spring Pin Set, impact screwdriver",
            "NormTermName": "Spring Pin Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07175": {
            "GenartName": "Spring Pin, impact screwdriver",
            "NormTermName": "Spring Pin",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07176": {
            "GenartName": "Air Guide Panel, impact screwdriver",
            "NormTermName": "Cooling Baffle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07177": {
            "GenartName": "Seal, impact screwdriver",
            "NormTermName": "Seal",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07178": {
            "GenartName": "Display, torque wrench",
            "NormTermName": "Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "07179": {
            "GenartName": "Compressed Air Feed, impact screwdriver",
            "NormTermName": "Compressed Air Supply",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07180": {
            "GenartName": "Blade Set, sheet metal nibbler",
            "NormTermName": "Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Nibbler Shear"
        },
        "07181": {
            "GenartName": "Repair Kit, sheet metal nibbler",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Nibbler Shear"
        },
        "07182": {
            "GenartName": "Actuating Lever, ratchet screwdriver",
            "NormTermName": "Actuating Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07183": {
            "GenartName": "Screw Set, impact screwdriver",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07184": {
            "GenartName": "Lock, torque wrench",
            "NormTermName": "Lock",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "07185": {
            "GenartName": "Silencer, impact wrench",
            "NormTermName": "Silencer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07186": {
            "GenartName": "Spacer Disc Set, impact screwdriver",
            "NormTermName": "Spacer Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07187": {
            "GenartName": "Circlip Set",
            "NormTermName": "Circlip Set",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "07188": {
            "GenartName": "Dust Bag Set, random orbit sander",
            "NormTermName": "Dust Bag Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Eccentric Grinder"
        },
        "07189": {
            "GenartName": "Control Valve, impact screwdriver",
            "NormTermName": "Valve",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07190": {
            "GenartName": "Repair Set, torque wrench",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "07191": {
            "GenartName": "Valve Screw, ratchet screwdriver",
            "NormTermName": "Screw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07192": {
            "GenartName": "Handle, torque wrench",
            "NormTermName": "Handle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "07193": {
            "GenartName": "Locking Bush, impact screwdriver",
            "NormTermName": "Bush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07194": {
            "GenartName": "Shear Pin, torque multiplier",
            "NormTermName": "Pin",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Multiplier"
        },
        "07195": {
            "GenartName": "Spindle, wiper arm",
            "NormTermName": "Spindle",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wiper Arm Puller"
        },
        "07196": {
            "GenartName": "Induction Coil Set, heat gun",
            "NormTermName": "Induction Coil Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Induction Heating Gun"
        },
        "07197": {
            "GenartName": "Puller Housing, wiper arm puller",
            "NormTermName": "Puller Housing",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wiper Arm Puller"
        },
        "07198": {
            "GenartName": "Pressure Piece, installation tool, silent bearing",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Starter System, universal",
            "UsageName": "Ball Joint Mounting Tools"
        },
        "07199": {
            "GenartName": "Press-out Device, drive shaft",
            "NormTermName": "Press-out Device",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Drive Shaft"
        },
        "07200": {
            "GenartName": "Arrester, inner/outer extractor",
            "NormTermName": "Arrester",
            "AssGrpName": "Tool, universal",
            "UsageName": "Internal/External Puller"
        },
        "07201": {
            "GenartName": "Puller, radiator fan",
            "NormTermName": "Puller",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Radiator Fan"
        },
        "07202": {
            "GenartName": "Mounting Tool, ball joint",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ball Joint"
        },
        "07203": {
            "GenartName": "Mounting Tool Set, ball joint",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ball Joint"
        },
        "07204": {
            "GenartName": "Cover, hand lamp",
            "NormTermName": "Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Hand Lamp"
        },
        "07205": {
            "GenartName": "Spindle, installation tool",
            "NormTermName": "Spindle",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Installation Tool, silent bearing"
        },
        "07206": {
            "GenartName": "Centring Disc, installation tool silent bearing",
            "NormTermName": "Centring Disc",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Installation Tool, silent bearing"
        },
        "07207": {
            "GenartName": "Press-out Screw, drive shaft",
            "NormTermName": "Press-out Screw",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Drive Shaft"
        },
        "07208": {
            "GenartName": "Centring Sleeve, installation tool silent bearing",
            "NormTermName": "Sleeve",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Installation Tool, silent bearing"
        },
        "07209": {
            "GenartName": "Axle Nut Wrench",
            "NormTermName": "Key",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Axle Nut"
        },
        "07210": {
            "GenartName": "Nut, bolt extractor",
            "NormTermName": "Nut",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Bolt Extractor"
        },
        "07211": {
            "GenartName": "Disconnecting/installation fork",
            "NormTermName": "Disconnecting/installation fork",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07212": {
            "GenartName": "Spring, end cutting nippers",
            "NormTermName": "Spring",
            "AssGrpName": "Tool, universal",
            "UsageName": "End Cutting Nippers"
        },
        "07213": {
            "GenartName": "Mounting Tool Set, clutch/flywheel",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Clutch/ Flywheel"
        },
        "07214": {
            "GenartName": "Protection Strip, tool trolley",
            "NormTermName": "Protection Strip",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "07215": {
            "GenartName": "Centring Tool Set, wheel mounting",
            "NormTermName": "Centering Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Mounting"
        },
        "07216": {
            "GenartName": "Centring Tool Set, wheel mounting",
            "NormTermName": "Centering Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Mounting"
        },
        "07217": {
            "GenartName": "Impact Head, balance weight pliers",
            "NormTermName": "Impact Head",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Balance Weight Pliers"
        },
        "07218": {
            "GenartName": "Adapter, installation tool, silent bearing",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Installation Tool, silent bearing"
        },
        "07219": {
            "GenartName": "Adapter, installation tool, silent bearing",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Installation Tool, silent bearing"
        },
        "07220": {
            "GenartName": "Centring Tool, wheel mounting",
            "NormTermName": "Centering Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Mounting"
        },
        "07221": {
            "GenartName": "Drilling Sleeve, bolt extractor",
            "NormTermName": "Drilling Sleeve",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bolt Extractor"
        },
        "07222": {
            "GenartName": "Centring Tool, wheel mounting",
            "NormTermName": "Centering Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Mounting"
        },
        "07223": {
            "GenartName": "Tool Wall Holder",
            "NormTermName": "Tool Wall Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07224": {
            "GenartName": "Wheel Nut Wrench",
            "NormTermName": "Wheel Nut Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07225": {
            "GenartName": "Software, torque measurement device",
            "NormTermName": "Software",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Torque Measurement Device"
        },
        "07226": {
            "GenartName": "Pressure Plate, installation tool, silent bearing",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Installation Tool, silent bearing"
        },
        "07227": {
            "GenartName": "Pressure Plate, installation tool, silent bearing",
            "NormTermName": "Pressure Disc",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Installation Tool, silent bearing"
        },
        "07228": {
            "GenartName": "Handle, blind rivet pliers",
            "NormTermName": "Handle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Blind Rivet Pliers"
        },
        "07229": {
            "GenartName": "Blade Set, draw knife (glass removal)",
            "NormTermName": "Blade Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Draw Knife (glass removal)"
        },
        "07230": {
            "GenartName": "Carbon Brush Set, impact screwdriver",
            "NormTermName": "Carbon Brush Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07231": {
            "GenartName": "Core Drill Bit Set, spot weld milling cutter",
            "NormTermName": "Core Drill Bit Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Spot Weld Cutter"
        },
        "07232": {
            "GenartName": "Replacement Tip, spot weld milling head",
            "NormTermName": "Replacement Tip",
            "AssGrpName": "Tool, universal",
            "UsageName": "Spot Weld Cutter"
        },
        "07233": {
            "GenartName": "Repair Set, torque multiplier",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Multiplier"
        },
        "07234": {
            "GenartName": "Repair Set, spring compressor",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Compressor"
        },
        "07235": {
            "GenartName": "Support Device, torque multiplier",
            "NormTermName": "Support Device",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Multiplier"
        },
        "07236": {
            "GenartName": "Spring, sheet metal shears",
            "NormTermName": "Spring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Sheet Metal Shear"
        },
        "07237": {
            "GenartName": "Repair Set, torque wrench lock",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench Lock"
        },
        "07238": {
            "GenartName": "Repair Kit, bevelling pliers",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Bevelling Pliers"
        },
        "07239": {
            "GenartName": "Chuck Jaws Set, blind rivet pliers",
            "NormTermName": "Chuck Jaw Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Blind Rivet Pliers"
        },
        "07240": {
            "GenartName": "Housing, installation tool, silent bearing",
            "NormTermName": "Housing",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Installation Tool, silent bearing"
        },
        "07241": {
            "GenartName": "Spindle, press-out device, drive shaft",
            "NormTermName": "Spindle",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Press-out Device (drive shaft)"
        },
        "07242": {
            "GenartName": "File Holder",
            "NormTermName": "File Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07243": {
            "GenartName": "Power Socket, brake disc",
            "NormTermName": "Power Socket",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Disc"
        },
        "07244": {
            "GenartName": "Power Socket, brake disc",
            "NormTermName": "Power Socket",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Brake Disc"
        },
        "07245": {
            "GenartName": "Handle, pump spray can",
            "NormTermName": "Handle",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Pump Spray Can"
        },
        "07246": {
            "GenartName": "Suction Tube, pump spray can",
            "NormTermName": "Suction Pipe",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pump Spray Can"
        },
        "07247": {
            "GenartName": "Suction tube Tip, pump spray can",
            "NormTermName": "Suction Tube Tip",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pump Spray Can"
        },
        "07248": {
            "GenartName": "Tension Bolt, installation tool (wheel hub/bearing)",
            "NormTermName": "Bolt",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "07249": {
            "GenartName": "Centring Sleeve, installation tool (wheel hub/bearing)",
            "NormTermName": "Sleeve",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "07250": {
            "GenartName": "Protective Jaws, pipe wrench",
            "NormTermName": "Jaw Pads",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Wrench"
        },
        "07251": {
            "GenartName": "Adapter, cooling system pressure test set",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Cooling System Pressure Test Set"
        },
        "07252": {
            "GenartName": "Installation Tool, tyre-pressure monitoring system",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "07253": {
            "GenartName": "Reducer, installation tool (wheel hub/wheel bearing)",
            "NormTermName": "Reducer",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "07254": {
            "GenartName": "Oil Service Wrench",
            "NormTermName": "Oil Service Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07255": {
            "GenartName": "Socket Wrench Insert, wheel bearing cover",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Bearing Cap"
        },
        "07256": {
            "GenartName": "Ear Plug, stethoscope",
            "NormTermName": "Ear Plugs",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Stethoscope"
        },
        "07257": {
            "GenartName": "Oil Filter Wrench, direct shift transmission",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Direct Shift Transmission"
        },
        "07258": {
            "GenartName": "Bushing, tubular column",
            "NormTermName": "Bush",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tubular column (tool trolley)"
        },
        "07259": {
            "GenartName": "Puller Set, wheel bearing inner ring",
            "NormTermName": "Puller Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Bearing Inner Ring"
        },
        "07260": {
            "GenartName": "Pin Wrench, injector holder clamping nut",
            "NormTermName": "Pin Wrench",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injector Holder, clamping nut"
        },
        "07261": {
            "GenartName": "Pin Wrench, injector holder clamping nut",
            "NormTermName": "Pin Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector Holder, clamping nut"
        },
        "07262": {
            "GenartName": "Actuating Lever, ratchet screwdriver",
            "NormTermName": "Actuating Switch",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07263": {
            "GenartName": "Seal Ring Set, ratchet screwdriver",
            "NormTermName": "Seal Ring Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07264": {
            "GenartName": "Air Outlet, ratchet screwdriver",
            "NormTermName": "Air Outlet",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07265": {
            "GenartName": "Air Inlet Set, ratchet screwdriver",
            "NormTermName": "Air Inlet Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07266": {
            "GenartName": "Adapter Set, ratchet screwdriver",
            "NormTermName": "Adapter Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07267": {
            "GenartName": "Valve Unit, ratchet screwdriver",
            "NormTermName": "Valve",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07268": {
            "GenartName": "Square Drive, ratchet screwdriver",
            "NormTermName": "Square Tang",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Screwdriver"
        },
        "07269": {
            "GenartName": "Seal Ring, random orbit sander",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Eccentric Grinder"
        },
        "07270": {
            "GenartName": "Repair Cable Set",
            "NormTermName": "Repair Cable Set",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Cable Repair Set"
        },
        "07271": {
            "GenartName": "Adapter, antenna cable",
            "NormTermName": "Adaptor",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Aerial Cable"
        },
        "07272": {
            "GenartName": "Adapter, antenna cable",
            "NormTermName": "Adaptor",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Aerial Cable"
        },
        "07273": {
            "GenartName": "Repair Set, pressure line (soot/particulate filter)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Pressure Line (soot/particulate filter)"
        },
        "07274": {
            "GenartName": "Repair Set, pressure line (soot/particulate filter)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Pressure Line (soot/particulate filter)"
        },
        "07275": {
            "GenartName": "Repair Set, vacuum line",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Standard Parts",
            "UsageName": "Vacuum Line"
        },
        "07277": {
            "GenartName": "Actuating Lever, random orbit sander",
            "NormTermName": "Actuating Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Eccentric Grinder"
        },
        "07278": {
            "GenartName": "Drive Head, random orbit sander",
            "NormTermName": "Drive Head",
            "AssGrpName": "Tool, universal",
            "UsageName": "Eccentric Grinder"
        },
        "07279": {
            "GenartName": "Bearing Set, random orbit sander",
            "NormTermName": "Bearing Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Eccentric Grinder"
        },
        "07280": {
            "GenartName": "Bearing, random orbit sander",
            "NormTermName": "Bearing",
            "AssGrpName": "Tool, universal",
            "UsageName": "Eccentric Grinder"
        },
        "07281": {
            "GenartName": "Disc Set, random orbit sander",
            "NormTermName": "Lining Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Eccentric Grinder"
        },
        "07282": {
            "GenartName": "Cable Repair Set, convertible top motor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Body",
            "UsageName": "Convertible Top Motor"
        },
        "07283": {
            "GenartName": "Cable Repair Set, convertible top motor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Convertible Top Motor"
        },
        "07284": {
            "GenartName": "Cable Repair Set, coolant temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Temperature Sensor"
        },
        "07285": {
            "GenartName": "Cable Repair Set, coolant temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Coolant Temperature Sensor"
        },
        "07286": {
            "GenartName": "Cable Repair Set, licence plate light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Licence Plate Light"
        },
        "07287": {
            "GenartName": "Cable Repair Set, licence plate light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Licence Plate Light"
        },
        "07288": {
            "GenartName": "Cable Repair Set, ignition coil",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition Coil"
        },
        "07289": {
            "GenartName": "Cable Repair Set, ignition coil",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Ignition System, universal",
            "UsageName": "Ignition Coil"
        },
        "07290": {
            "GenartName": "Cable repair set, actuator headlight levelling",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Actuator headlight levelling"
        },
        "07291": {
            "GenartName": "Cable repair set, actuator headlight levelling",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Actuator headlight levelling"
        },
        "07292": {
            "GenartName": "Cable Repair Kit, glow plug",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Glow Ignition System",
            "UsageName": "Glow Plug"
        },
        "07293": {
            "GenartName": "Cable Repair Kit, glow plug",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Glow Ignition System, universal",
            "UsageName": "Glow Plug"
        },
        "07294": {
            "GenartName": "Torque Measurement Device",
            "NormTermName": "Torque Measurement Device",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07295": {
            "GenartName": "Distance Measurement Device",
            "NormTermName": "Distance Measurement Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07296": {
            "GenartName": "Measuring Stick",
            "NormTermName": "Measuring Stick",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07297": {
            "GenartName": "Bit, oil drain plug",
            "NormTermName": "Bit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Oil Drain Plug"
        },
        "07298": {
            "GenartName": "Bore Hole Brush",
            "NormTermName": "Bore Hole Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07299": {
            "GenartName": "File Brush",
            "NormTermName": "File Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07300": {
            "GenartName": "Torque Adapter",
            "NormTermName": "Torque Adapter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07301": {
            "GenartName": "Core Drill, spot weld milling cutter",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Spot Weld Cutter"
        },
        "07302": {
            "GenartName": "Cable Repair Set, interior heating fan, (eng. preheat sys.)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Interior Heating Fan, (engine preheating system)"
        },
        "07303": {
            "GenartName": "Cable Repair Set, interior heating fan, (eng. preheat sys.)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": null
        },
        "07304": {
            "GenartName": "Cable Repair Set, alternator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Alternator",
            "UsageName": "Alternator"
        },
        "07305": {
            "GenartName": "Cable Repair Set, alternator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Alternator, universal",
            "UsageName": "Alternator"
        },
        "07306": {
            "GenartName": "Cable Repair Set, pressure switch (air conditioning)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Pressure Switch (air conditioning system)"
        },
        "07307": {
            "GenartName": "Cable Repair Set, pressure switch (air conditioning)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Pressure Switch (air conditioning system)"
        },
        "07308": {
            "GenartName": "Cable Repair Set, parking assistant sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Assist Sensor"
        },
        "07309": {
            "GenartName": "Cable Repair Set, parking assistant sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Assist Sensor"
        },
        "07310": {
            "GenartName": "Cable Repair Set, common rail set",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Common Rail System"
        },
        "07311": {
            "GenartName": "Cable Repair Set, common rail set",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Common Rail System"
        },
        "07312": {
            "GenartName": "Cable Repair Set, window regulator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Window Regulator"
        },
        "07313": {
            "GenartName": "Cable Repair Set, window regulator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Window Regulator"
        },
        "07314": {
            "GenartName": "Cable Repair Set, fuel pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "07315": {
            "GenartName": "Cable Repair Set, fuel pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Fuel Pump"
        },
        "07316": {
            "GenartName": "Cable Repair Set, fuel sender unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Sender Unit, fuel tank"
        },
        "07317": {
            "GenartName": "Cable Repair Set, fuel sender unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Sender Unit, fuel tank"
        },
        "07318": {
            "GenartName": "Cable Repair Set, trailer receptacle",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Towbar Socket"
        },
        "07319": {
            "GenartName": "Cable Repair Set, trailer plug",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Trailer Plug"
        },
        "07320": {
            "GenartName": "Cable Repair Set, sensor (intake manifold pressure)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply",
            "UsageName": "Sensor (intake manifold pressure)"
        },
        "07321": {
            "GenartName": "Cable Repair Set, sensor (intake manifold pressure)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply, universal",
            "UsageName": "Sensor (intake manifold pressure)"
        },
        "07322": {
            "GenartName": "Disc Set, jigsaw",
            "NormTermName": "Lining Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Jigsaw"
        },
        "07323": {
            "GenartName": "Bearing, jigsaw",
            "NormTermName": "Bearing",
            "AssGrpName": "Tool, universal",
            "UsageName": "Jigsaw"
        },
        "07324": {
            "GenartName": "Actuating Lever, jigsaw",
            "NormTermName": "Actuating Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Jigsaw"
        },
        "07325": {
            "GenartName": "Rotor, jigsaw",
            "NormTermName": "Rotor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Jigsaw"
        },
        "07326": {
            "GenartName": "Valve Unit, jigsaw",
            "NormTermName": "Valve",
            "AssGrpName": "Tool, universal",
            "UsageName": "Jigsaw"
        },
        "07327": {
            "GenartName": "Repair Set, jigsaw",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Jigsaw"
        },
        "07328": {
            "GenartName": "Air Outlet, jigsaw",
            "NormTermName": "Air Outlet",
            "AssGrpName": "Tool, universal",
            "UsageName": "Jigsaw"
        },
        "07329": {
            "GenartName": "Axle Nut Wrench Set",
            "NormTermName": "Key Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Axle Nut"
        },
        "07330": {
            "GenartName": "Axle Nut Wrench Set",
            "NormTermName": "Key Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Axle Nut"
        },
        "07331": {
            "GenartName": "Connection Piece, brake testing device",
            "NormTermName": "Connection Piece",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake Pressure Tester"
        },
        "07332": {
            "GenartName": "Material Container",
            "NormTermName": "Material Container",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07333": {
            "GenartName": "Drain Pipe",
            "NormTermName": "Drain Pipe",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07334": {
            "GenartName": "Cooling Water Can",
            "NormTermName": "Cooling Water Can",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07335": {
            "GenartName": "Measurement Bucket",
            "NormTermName": "Measurement Bucket",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07336": {
            "GenartName": "Pump Dispenser",
            "NormTermName": "Pump Dispenser",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07337": {
            "GenartName": "Fill Level Indicator, storage tank",
            "NormTermName": "Gauge",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Storage Tank"
        },
        "07338": {
            "GenartName": "Fill Level Indicator, disposal tank",
            "NormTermName": "Gauge",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Disposal Tank"
        },
        "07339": {
            "GenartName": "Dome Cover, storage tank",
            "NormTermName": "Lid",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Storage Tank"
        },
        "07340": {
            "GenartName": "Filling Tray, storage tank",
            "NormTermName": "Filling Tray",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Storage Tank"
        },
        "07341": {
            "GenartName": "Oil Can Station",
            "NormTermName": "Oil Can Station",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07343": {
            "GenartName": "Dial Gauge, oil can station",
            "NormTermName": "Dial Gauge",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Oil Can Station"
        },
        "07344": {
            "GenartName": "Cabinet, hose reel",
            "NormTermName": "Cabinet",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Hose Reel"
        },
        "07345": {
            "GenartName": "Barrel Trolley Set",
            "NormTermName": "Barrel Trolley Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07346": {
            "GenartName": "Oil Dispensing Station (barrel station)",
            "NormTermName": "Oil Dispensing Station",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07347": {
            "GenartName": "Hose Reel",
            "NormTermName": "Hose Reel",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07348": {
            "GenartName": "Filling station",
            "NormTermName": "Filling station",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07349": {
            "GenartName": "Barrel Support, rack",
            "NormTermName": "Barrel Rest",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Rack"
        },
        "07350": {
            "GenartName": "Collection Pan, racking level",
            "NormTermName": "Collection Pan",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Rack Level"
        },
        "07351": {
            "GenartName": "Fitting",
            "NormTermName": "Fitting",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "07352": {
            "GenartName": "Barrel Truck",
            "NormTermName": "Barrel Truck",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07353": {
            "GenartName": "Barrel Lifting Truck",
            "NormTermName": "Barrel Lifting Truck",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07354": {
            "GenartName": "Barrel Fill Level Display",
            "NormTermName": "Barrel Fill Level Display",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07355": {
            "GenartName": "Barrel Tongs",
            "NormTermName": "Barrel Tongs",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07356": {
            "GenartName": "Level Switch, barrel pump",
            "NormTermName": "Lever Switch",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Barrel Pump"
        },
        "07357": {
            "GenartName": "Universal Spray Bottle",
            "NormTermName": "Universal Spray Bottle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07358": {
            "GenartName": "Water Can",
            "NormTermName": "Water Can",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07359": {
            "GenartName": "Oil Dispensing Terminal",
            "NormTermName": "Oil Dispensing Terminal",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07360": {
            "GenartName": "Oil Dispensing Station (tank structure)",
            "NormTermName": "Oil Dispensing Station",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Oil Dispensing Station (tank structure)"
        },
        "07361": {
            "GenartName": "Chuck, straight grinder",
            "NormTermName": "Chuck",
            "AssGrpName": "Tool, universal",
            "UsageName": "Straight Grinder"
        },
        "07362": {
            "GenartName": "Chuck Key",
            "NormTermName": "Chuck Key",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07363": {
            "GenartName": "Grinding Stone",
            "NormTermName": "Grinding Stone",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07365": {
            "GenartName": "Grinding Belt, belt grinder",
            "NormTermName": "Grinding Belt",
            "AssGrpName": "Tool, universal",
            "UsageName": "Belt Grinder"
        },
        "07366": {
            "GenartName": "Sanding Disc Set, angle grinder",
            "NormTermName": "Grinding Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "07367": {
            "GenartName": "Cable Roller",
            "NormTermName": "Reel",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Electric Cable"
        },
        "07368": {
            "GenartName": "Cover, vacuum pump",
            "NormTermName": "Lid",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum Pump"
        },
        "07369": {
            "GenartName": "Pliers, chain master link",
            "NormTermName": "Pliers",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Chain Master Link"
        },
        "07370": {
            "GenartName": "Chain Rivet Extraction/Insertion Device",
            "NormTermName": "Chain Rivet Extraction/Insertion Device",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07371": {
            "GenartName": "Chain Rivet Extraction/Insertion Device",
            "NormTermName": "Chain Rivet Extraction/Insertion Device",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07372": {
            "GenartName": "Chain Separating Device",
            "NormTermName": "Chain Separating Device",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07373": {
            "GenartName": "Chain Holder",
            "NormTermName": "Chain Holder",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07374": {
            "GenartName": "Chain Brush",
            "NormTermName": "Chain Brush",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07375": {
            "GenartName": "Chain Gauge",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Motorcycle Chain"
        },
        "07376": {
            "GenartName": "Test Device, chain alignment",
            "NormTermName": "Tester",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Chain Alignment"
        },
        "07377": {
            "GenartName": "Measurement Device, chain wear",
            "NormTermName": "Measuring Device",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Chain Wear"
        },
        "07378": {
            "GenartName": "Container, brake fluid filling",
            "NormTermName": "Tank",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Brake Fluid"
        },
        "07379": {
            "GenartName": "Adapter, container (brake fluid filling)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Container (brake fluid filling)"
        },
        "07380": {
            "GenartName": "Drift, steering head bearing",
            "NormTermName": "Drift",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Steering Head Bearing"
        },
        "07381": {
            "GenartName": "Hole Punch Set, bar ends",
            "NormTermName": "Hole Punch Set",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Bar Ends"
        },
        "07382": {
            "GenartName": "Steering Head Bearing Key",
            "NormTermName": "Steering Head Bearing Key",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07383": {
            "GenartName": "Spring Balance, steering head bearing pre-tensioning",
            "NormTermName": "Scale",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Steering Head Bearing Pre-tensioning"
        },
        "07384": {
            "GenartName": "Installation Tool Set. steering head bearing",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Steering Head Bearing"
        },
        "07385": {
            "GenartName": "Puller, starter clutch",
            "NormTermName": "Puller",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Starter Clutch"
        },
        "07386": {
            "GenartName": "Spoke Wrench",
            "NormTermName": "Spoke Wrench",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07387": {
            "GenartName": "Spoke Wrench Set",
            "NormTermName": "Spoke Wrench Set",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07388": {
            "GenartName": "Torque Spoke Wrench Set",
            "NormTermName": "Torque Spoke Wrench Set",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07389": {
            "GenartName": "Torque Spoke Wrench",
            "NormTermName": "Torque Spoke Wrench",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07390": {
            "GenartName": "Impact tool, spoke weight",
            "NormTermName": "Impact Tool",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Spoke Weight"
        },
        "07391": {
            "GenartName": "Tyre Fitting Pliers",
            "NormTermName": "Tyre Fitting Pliers",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07392": {
            "GenartName": "Socket Wrench Insert, emission temperature sensor",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Emission Temperature Sensor"
        },
        "07393": {
            "GenartName": "Socket Wrench Insert, emission temperature sensor",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Emission Temperature Sensor"
        },
        "07394": {
            "GenartName": "Protective Hose, lashing chain",
            "NormTermName": "Protective Hose",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Chain"
        },
        "07395": {
            "GenartName": "Lashing Strap Dispensing Reel",
            "NormTermName": "Lashing Strap Dispensing Reel",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "07396": {
            "GenartName": "Rechargeable Battery, cordless screwdriver",
            "NormTermName": "Rechargeable Battery Pack",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cordless Screwdriver"
        },
        "07397": {
            "GenartName": "Filter, venting (fuel tank)",
            "NormTermName": "Filter",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Bleeding"
        },
        "07398": {
            "GenartName": "Filter, venting (steering gear hydraulic tank)",
            "NormTermName": "Filter",
            "AssGrpName": "Steering",
            "UsageName": "Bleeding"
        },
        "07399": {
            "GenartName": "Filter, venting (clutch hydraulic tank)",
            "NormTermName": "Filter",
            "AssGrpName": "Clutch",
            "UsageName": "Bleeding"
        },
        "07400": {
            "GenartName": "Filter, venting (brake hydraulic tank)",
            "NormTermName": "Filter",
            "AssGrpName": "Braking System",
            "UsageName": "Bleeding"
        },
        "07401": {
            "GenartName": "Stethoscope, membrane",
            "NormTermName": "Diaphragm",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Stethoscope"
        },
        "07402": {
            "GenartName": "Counter-piece, timing chain",
            "NormTermName": "Arrester",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Timing Chain"
        },
        "07403": {
            "GenartName": "Counter-piece, timing chain",
            "NormTermName": "Arrester",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Timing Chain"
        },
        "07404": {
            "GenartName": "Reaction fluid, cylinder head sealing",
            "NormTermName": "Reaction Fluid",
            "AssGrpName": "Chemical Products",
            "UsageName": "Cylinder Head Leakage"
        },
        "07405": {
            "GenartName": "Pipette",
            "NormTermName": "Pipette",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07406": {
            "GenartName": "Adapter, camera probe (videoscope)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Camera Probe (video endoscope)"
        },
        "07407": {
            "GenartName": "Adjusting Tool, wiper arm",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wiper Arm"
        },
        "07408": {
            "GenartName": "Press Frame, installation tool silent bearing",
            "NormTermName": "Press Frame",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Installation Tool, silent bearing"
        },
        "07409": {
            "GenartName": "Sticker Residue Removal Set, multi-grinder",
            "NormTermName": "Sticker Residue Removal Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Multi-grinder"
        },
        "07410": {
            "GenartName": "Connecting/distributing Piece Set, compressed air",
            "NormTermName": "Connector Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "07411": {
            "GenartName": "Grinding Belt Set, belt grinder",
            "NormTermName": "Grinding Belt Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Belt Grinder"
        },
        "07412": {
            "GenartName": "Maintenance Service, compressed air",
            "NormTermName": "Maintenance Service",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "07413": {
            "GenartName": "Electric Motor, tailgate",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Tailgate"
        },
        "07414": {
            "GenartName": "Electric Motor, bonnet",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Bonnet"
        },
        "07415": {
            "GenartName": "Electric Motor, door",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Door"
        },
        "07416": {
            "GenartName": "Locking Beam/Plate, load securing",
            "NormTermName": "Locking Beam/Plate",
            "AssGrpName": "Load Securing",
            "UsageName": "Load Securing"
        },
        "07417": {
            "GenartName": "Swivel Hook",
            "NormTermName": "Hook",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Strap"
        },
        "07418": {
            "GenartName": "Hook, lashing strap",
            "NormTermName": "Hook",
            "AssGrpName": "Load Securing",
            "UsageName": "Tension Strap"
        },
        "07419": {
            "GenartName": "Lashing Rail, load securing",
            "NormTermName": "Lashing Rail",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "07420": {
            "GenartName": "Stand Rail, load securing",
            "NormTermName": "Stand Rail",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "07421": {
            "GenartName": "Connector, lashing chain",
            "NormTermName": "Connector",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Chain"
        },
        "07422": {
            "GenartName": "Shortening Hook, lashing chain",
            "NormTermName": "Hook",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Chain"
        },
        "07423": {
            "GenartName": "Shortening Claw, lashing chain",
            "NormTermName": "Claw",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Chain"
        },
        "07424": {
            "GenartName": "Lashing Chain",
            "NormTermName": "Lashing Chain",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "07425": {
            "GenartName": "Front Wheel Hanger, stand rail",
            "NormTermName": "Bracket",
            "AssGrpName": "Load Securing",
            "UsageName": "Stand Rail"
        },
        "07426": {
            "GenartName": "Lashing Bracket",
            "NormTermName": "Bracket",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Bracket"
        },
        "07427": {
            "GenartName": "Lashing Block",
            "NormTermName": "Lashing Block",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "07428": {
            "GenartName": "Anti-Slip Coating",
            "NormTermName": "Anti-Slip Coating",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07429": {
            "GenartName": "Access Rail",
            "NormTermName": "Access Rail",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "07430": {
            "GenartName": "Lashing Strap Set",
            "NormTermName": "Belt Kit",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Lashing Strap Set"
        },
        "07431": {
            "GenartName": "Lashing Chain Hanger",
            "NormTermName": "Lashing Chain Identification Tag",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "07432": {
            "GenartName": "Fitting, lashing rail",
            "NormTermName": "Fitting",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Rail"
        },
        "07433": {
            "GenartName": "Three-Link Piece, lashing chain",
            "NormTermName": "Three-Link Piece",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Chain"
        },
        "07434": {
            "GenartName": "Ratchet, lashing strap",
            "NormTermName": "Ratchet Tensioner",
            "AssGrpName": "Load Securing",
            "UsageName": "Tension Strap"
        },
        "07435": {
            "GenartName": "Assortment, repair cable",
            "NormTermName": "Assortment",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Repair Cable"
        },
        "07436": {
            "GenartName": "Grinding Belt Set, multi grinder",
            "NormTermName": "Grinding Disc Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Multi-grinder"
        },
        "07437": {
            "GenartName": "Attachment Set, polisher",
            "NormTermName": "Attachment Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Polisher"
        },
        "07438": {
            "GenartName": "Repair Set, hose clamp pliers",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hose Clamp Pliers"
        },
        "07439": {
            "GenartName": "Supporting Ring, installation tool (wheel hub/wheel bearing)",
            "NormTermName": "Supporting Ring",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "07440": {
            "GenartName": "Supporting Ring Set, installation tool (wheel hub/bearing)",
            "NormTermName": "Support Ring Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "07441": {
            "GenartName": "Reduction Bushing, installation tool (wheel hub/bearing)",
            "NormTermName": "Reduction Bushing",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "07442": {
            "GenartName": "Pinch Protection, installation tool (wheel hub/bearing)",
            "NormTermName": "Pinch Protection",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "07443": {
            "GenartName": "Pump, workshop press",
            "NormTermName": "Pump",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Press"
        },
        "07444": {
            "GenartName": "Safety Element, fork head load hook",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Load Securing",
            "UsageName": "Fork Head Load Hook"
        },
        "07445": {
            "GenartName": "Castor Set, tool trolley attachment",
            "NormTermName": "Pulley Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Box Module"
        },
        "07446": {
            "GenartName": "Tool Box Module",
            "NormTermName": "Tool Box Module",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07447": {
            "GenartName": "Worktop, toolbox module",
            "NormTermName": "Worktop",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Box Module"
        },
        "07448": {
            "GenartName": "Anti-pilfer Device, tool box module",
            "NormTermName": "Anti-pilfer Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Box Module"
        },
        "07449": {
            "GenartName": "Divider Set, drawer (tool box module)",
            "NormTermName": "Organiser Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Box Module"
        },
        "07450": {
            "GenartName": "Chassis, tool box",
            "NormTermName": "Chassis",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Toolbox"
        },
        "07451": {
            "GenartName": "Lashing Point, lashing chain",
            "NormTermName": "Lashing Point",
            "AssGrpName": "Load Securing",
            "UsageName": "Lashing Rail"
        },
        "07452": {
            "GenartName": "Holding Tool, nut",
            "NormTermName": "Holding Tool",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Nut"
        },
        "07453": {
            "GenartName": "Forging Pliers",
            "NormTermName": "Forging Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07454": {
            "GenartName": "Machinist Hammer Attachment",
            "NormTermName": "Attachment",
            "AssGrpName": "Tool, universal",
            "UsageName": "Machinist Hammer"
        },
        "07455": {
            "GenartName": "Decorative Ring, combination rear light",
            "NormTermName": "Decorative Ring",
            "AssGrpName": "Lights, universal",
            "UsageName": "Combination Rear Light"
        },
        "07456": {
            "GenartName": "Control Unit, lights",
            "NormTermName": "Tester",
            "AssGrpName": "Lights, universal",
            "UsageName": "Lights"
        },
        "07457": {
            "GenartName": "Fastening Rail, combination rear light",
            "NormTermName": "Fastening Rail",
            "AssGrpName": "Lights, universal",
            "UsageName": "Combination Rear Light"
        },
        "07458": {
            "GenartName": "Fastening Rail/Tube, interior light",
            "NormTermName": "Fastening Rail/Tube",
            "AssGrpName": "Lights, universal",
            "UsageName": "Interior Light"
        },
        "07459": {
            "GenartName": "Floor mat",
            "NormTermName": "Floor mat",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07460": {
            "GenartName": "Safety Plug-on Grip",
            "NormTermName": "Safety Plug-on Grip",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07461": {
            "GenartName": "Cable Expanding Wedge",
            "NormTermName": "Cable Expanding Wedge",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07462": {
            "GenartName": "Pipe Calibration Tool",
            "NormTermName": "Pipe Calibration Tool",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07463": {
            "GenartName": "Plastic shear set",
            "NormTermName": "Plastic shear set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07464": {
            "GenartName": "Flaring Tool Set",
            "NormTermName": "Flaring Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07465": {
            "GenartName": "Screw, automatic transmission oil tray",
            "NormTermName": "Screw",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil sump, automatic transmission"
        },
        "07466": {
            "GenartName": "Fittings key",
            "NormTermName": "Key",
            "AssGrpName": "Tool, universal",
            "UsageName": "Instruments"
        },
        "07467": {
            "GenartName": "Wrench, basin cock",
            "NormTermName": "Key",
            "AssGrpName": "Tool, universal",
            "UsageName": "Basin Cock Nut"
        },
        "07468": {
            "GenartName": "Strap Pipe Pliers",
            "NormTermName": "Strap Pipe Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07469": {
            "GenartName": "Replacement Strap, strap pipe pliers",
            "NormTermName": "Replacement Strap",
            "AssGrpName": "Tool, universal",
            "UsageName": "Strap Pipe Pliers"
        },
        "07470": {
            "GenartName": "Pipe Bender",
            "NormTermName": "Pipe Bender",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07471": {
            "GenartName": "Base frame, pipe bender",
            "NormTermName": "Base Frame",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Bender"
        },
        "07472": {
            "GenartName": "Bending Form, pipe bender",
            "NormTermName": "Bending Form",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Bending Tool"
        },
        "07473": {
            "GenartName": "Bending Form, pipe bender",
            "NormTermName": "Bending Form",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Bender"
        },
        "07474": {
            "GenartName": "Suction Cup, grinding tool",
            "NormTermName": "Suction Disc",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Grinding Tool"
        },
        "07475": {
            "GenartName": "Cleaning Cloth",
            "NormTermName": "Cleaning Cloth",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07477": {
            "GenartName": "Backplate, polishing machine",
            "NormTermName": "Support Plate",
            "AssGrpName": "Tool, universal",
            "UsageName": "Polishing Machine"
        },
        "07478": {
            "GenartName": "Vacuum Pump, braking system",
            "NormTermName": "Pump",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Vacuum"
        },
        "07479": {
            "GenartName": "Hydrogen Peroxide Solution",
            "NormTermName": "Hydrogen Peroxide Solution",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07480": {
            "GenartName": "Flucculant",
            "NormTermName": "Flucculant",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07481": {
            "GenartName": "Vehicle Washing Brush",
            "NormTermName": "Washing Brush",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Vehicle Washing"
        },
        "07482": {
            "GenartName": "Support Wheel",
            "NormTermName": "Support Wheel",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07483": {
            "GenartName": "Clamp, support wheel / parking supports",
            "NormTermName": "Bracket",
            "AssGrpName": "Trailer",
            "UsageName": "Support Wheel / parking Supports"
        },
        "07484": {
            "GenartName": "Clamping Shell, support wheel / parking supports",
            "NormTermName": "Clamp",
            "AssGrpName": "Trailer",
            "UsageName": "Support Wheel / parking Supports"
        },
        "07485": {
            "GenartName": "Attachment, polishing machine",
            "NormTermName": "Attachment",
            "AssGrpName": "Tool, universal",
            "UsageName": "Polishing Machine"
        },
        "07486": {
            "GenartName": "Slippage Safeguard, support wheel",
            "NormTermName": "Slippage Safeguard",
            "AssGrpName": "Trailer",
            "UsageName": "Support Wheel"
        },
        "07487": {
            "GenartName": "Round Pipe Clamp, support wheel",
            "NormTermName": "Round Pipe Clamp",
            "AssGrpName": "Trailer",
            "UsageName": "Support Wheel"
        },
        "07488": {
            "GenartName": "Chrome Polish",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Chrome Polish"
        },
        "07489": {
            "GenartName": "Caustic Soda",
            "NormTermName": "Caustic Soda",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07490": {
            "GenartName": "Round Pipe Clamp Set, support wheel",
            "NormTermName": "Round Pipe Clamp Set",
            "AssGrpName": "Trailer",
            "UsageName": "Support Wheel"
        },
        "07491": {
            "GenartName": "Manoeuvring Handle, support wheel",
            "NormTermName": "Manoeuvring Handle",
            "AssGrpName": "Trailer",
            "UsageName": "Support Wheel"
        },
        "07492": {
            "GenartName": "Support Wheel Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer",
            "UsageName": "Support Wheel / parking Supports"
        },
        "07493": {
            "GenartName": "Crossbeam, support wheel holder",
            "NormTermName": "Strut",
            "AssGrpName": "Trailer",
            "UsageName": "Support Wheel Holder"
        },
        "07494": {
            "GenartName": "Bodywork File",
            "NormTermName": "Bodywork File",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07495": {
            "GenartName": "Hole/Offset Pliers",
            "NormTermName": "Hole/Offset Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07496": {
            "GenartName": "Strut, support wheel",
            "NormTermName": "Strut",
            "AssGrpName": "Trailer",
            "UsageName": "Support Wheel"
        },
        "07497": {
            "GenartName": "Spare Wheel, support wheel",
            "NormTermName": "Spare Wheel",
            "AssGrpName": "Trailer",
            "UsageName": "Support Wheel"
        },
        "07498": {
            "GenartName": "Tool Backpack",
            "NormTermName": "Tool Backpack",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07500": {
            "GenartName": "Tool Holster",
            "NormTermName": "Tool Holster",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07501": {
            "GenartName": "Knee Pads",
            "NormTermName": "Knee Pads",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "07502": {
            "GenartName": "Plug-in Axle, support wheel",
            "NormTermName": "Plug-in Axle",
            "AssGrpName": "Trailer",
            "UsageName": "Support Wheel"
        },
        "07503": {
            "GenartName": "Knob, clamp (support wheel)",
            "NormTermName": "Chuck Key",
            "AssGrpName": "Trailer",
            "UsageName": "Clamp (support wheel)"
        },
        "07504": {
            "GenartName": "Hand Crank, support wheel / parking supports",
            "NormTermName": "Crank",
            "AssGrpName": "Trailer",
            "UsageName": "Support Wheel / parking Supports"
        },
        "07505": {
            "GenartName": "Drier Concentrate",
            "NormTermName": "Drier Concentrate",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07506": {
            "GenartName": "Headlamp Treatment Set",
            "NormTermName": "Conditioning Set",
            "AssGrpName": "Chemical Products",
            "UsageName": "Headlight"
        },
        "07507": {
            "GenartName": "Anti-fog Spray",
            "NormTermName": "Anti-fog Spray",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07508": {
            "GenartName": "Washing Glove",
            "NormTermName": "Washing Glove",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07509": {
            "GenartName": "Headlight Polish",
            "NormTermName": "Headlight Polish",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07510": {
            "GenartName": "Headlight Sealing",
            "NormTermName": "Headlight Sealing",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07511": {
            "GenartName": "Polishing Cloth",
            "NormTermName": "Polishing Cloth",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07512": {
            "GenartName": "Lacquer Sealing",
            "NormTermName": "Lacquer Sealing",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07513": {
            "GenartName": "Softtop/textile Impregnator",
            "NormTermName": "Canopy/textile Impregnation",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07514": {
            "GenartName": "Metering Bottle",
            "NormTermName": "Metering Bottle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07515": {
            "GenartName": "Vehicle Squeegee",
            "NormTermName": "Squeegee",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07516": {
            "GenartName": "Cleaning Clay",
            "NormTermName": "Cleaning Clay",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07517": {
            "GenartName": "Headlight Squeegee, protective sleeve",
            "NormTermName": "Protective Sleeve",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07518": {
            "GenartName": "Resin Remover",
            "NormTermName": "Resin Remover",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07519": {
            "GenartName": "Opening Aid",
            "NormTermName": "Opening Aid",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07520": {
            "GenartName": "Cleaning Concentrate",
            "NormTermName": "Cleaning Concentrate",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07521": {
            "GenartName": "Handle, hand crank (support wheel)",
            "NormTermName": "Handle",
            "AssGrpName": "Trailer",
            "UsageName": "Hand Crank (support wheel)"
        },
        "07522": {
            "GenartName": "Holder, hand crank (support wheel)",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer",
            "UsageName": "Hand Crank (support wheel)"
        },
        "07523": {
            "GenartName": "Clamping profile, storage box",
            "NormTermName": "Clamping Profile",
            "AssGrpName": "Trailer",
            "UsageName": "Storage Box"
        },
        "07524": {
            "GenartName": "Parking Support, trailer",
            "NormTermName": "Parking Supports",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07525": {
            "GenartName": "Flange Plate, parking supports",
            "NormTermName": "Flange Plate",
            "AssGrpName": "Trailer",
            "UsageName": "Parking Supports"
        },
        "07526": {
            "GenartName": "Trailer Jack, parking supports",
            "NormTermName": "Trailer Jack",
            "AssGrpName": "Trailer",
            "UsageName": "Parking Supports"
        },
        "07527": {
            "GenartName": "Trailer Jack Set, parking supports",
            "NormTermName": "Trailer Jack Set",
            "AssGrpName": "Trailer",
            "UsageName": "Parking Supports"
        },
        "07528": {
            "GenartName": "Adapter Set, trailer jack",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer Jack"
        },
        "07529": {
            "GenartName": "Jacking Support System",
            "NormTermName": "Jacking Support System",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07530": {
            "GenartName": "Nose Load Scale",
            "NormTermName": "Nose Load Scale",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07531": {
            "GenartName": "Spindle Nut, parking supports",
            "NormTermName": "Spindle Nut",
            "AssGrpName": "Trailer",
            "UsageName": "Parking Supports"
        },
        "07532": {
            "GenartName": "Mounting Rail, parking supports",
            "NormTermName": "Mounting Rail",
            "AssGrpName": "Trailer",
            "UsageName": "Parking Supports"
        },
        "07533": {
            "GenartName": "Angled Lever Latch",
            "NormTermName": "Angled Lever Latch",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07534": {
            "GenartName": "Case Lock",
            "NormTermName": "Case Lock",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07535": {
            "GenartName": "Pendulum Latch",
            "NormTermName": "Pendulum Latch",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07536": {
            "GenartName": "Pendulum Pin, pendulum latch",
            "NormTermName": "Pendulum Stud",
            "AssGrpName": "Trailer",
            "UsageName": "Pendulum Latch"
        },
        "07537": {
            "GenartName": "Pendulum Bolt, pendulum latch",
            "NormTermName": "Pendulum Pin",
            "AssGrpName": "Trailer",
            "UsageName": "Pendulum Latch"
        },
        "07538": {
            "GenartName": "Eccentric Lock",
            "NormTermName": "Eccentric Lock",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07539": {
            "GenartName": "Spring Latch",
            "NormTermName": "Spring Latch",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07540": {
            "GenartName": "Spring Latch",
            "NormTermName": "Spring Latch",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07541": {
            "GenartName": "Counterpiece, lock",
            "NormTermName": "Arrester",
            "AssGrpName": "Trailer",
            "UsageName": "Sealing Cap"
        },
        "07542": {
            "GenartName": "Retaining Cam, eccentric lock",
            "NormTermName": "Retaining Cam",
            "AssGrpName": "Trailer",
            "UsageName": "Eccentric Lock"
        },
        "07543": {
            "GenartName": "Latch",
            "NormTermName": "Latch",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07544": {
            "GenartName": "Long-Travel Lock",
            "NormTermName": "Long-Travel Lock",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07545": {
            "GenartName": "Espagnolette Set",
            "NormTermName": "Espagnolette Set",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07546": {
            "GenartName": "Pipe Bending Tool Set",
            "NormTermName": "Pipe Bending Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07547": {
            "GenartName": "Bending Lever, pipe bending tool",
            "NormTermName": "bending Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Bending Tool"
        },
        "07548": {
            "GenartName": "Tool Mount, pipe bending tool",
            "NormTermName": "Tool Mount",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Bending Tool"
        },
        "07549": {
            "GenartName": "Sub-frame, pipe bending tool",
            "NormTermName": "Base Frame",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Bending Tool"
        },
        "07550": {
            "GenartName": "Pressing Roller, pipe bending tool",
            "NormTermName": "Pulley",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Bending Tool"
        },
        "07551": {
            "GenartName": "hydraulic Power Unit, pipe bending device",
            "NormTermName": "Hydraulic Unit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Bender"
        },
        "07552": {
            "GenartName": "Door Hinge",
            "NormTermName": "Hinge",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer Door"
        },
        "07553": {
            "GenartName": "Espagnolette",
            "NormTermName": "Espagnolette",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07554": {
            "GenartName": "Clamping Pin, espagnolette",
            "NormTermName": "Clamping Pin",
            "AssGrpName": "Trailer",
            "UsageName": "Espagnolette"
        },
        "07555": {
            "GenartName": "Attachment Ring, espagnolette",
            "NormTermName": "Attachment Ring",
            "AssGrpName": "Trailer",
            "UsageName": "Espagnolette"
        },
        "07556": {
            "GenartName": "Bar Guide, espagnolette",
            "NormTermName": "Bar Guide",
            "AssGrpName": "Trailer",
            "UsageName": "Espagnolette"
        },
        "07557": {
            "GenartName": "Guide Support, espagnolette",
            "NormTermName": "Guide Support",
            "AssGrpName": "Trailer",
            "UsageName": "Espagnolette"
        },
        "07558": {
            "GenartName": "Dividing Wall Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer",
            "UsageName": "Separating Wall"
        },
        "07559": {
            "GenartName": "Pipe End Piece, box rod",
            "NormTermName": "Pipe End Piece",
            "AssGrpName": "Trailer",
            "UsageName": "Box Bar"
        },
        "07560": {
            "GenartName": "Pin, pipe end piece",
            "NormTermName": "Pin",
            "AssGrpName": "Trailer",
            "UsageName": "Pipe End Piece"
        },
        "07561": {
            "GenartName": "Tyre Repair Spray",
            "NormTermName": "Sealant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Tyre Repair"
        },
        "07562": {
            "GenartName": "Hook, pipe end piece",
            "NormTermName": "Hook",
            "AssGrpName": "Trailer",
            "UsageName": "Pipe End Piece"
        },
        "07563": {
            "GenartName": "Tie-on",
            "NormTermName": "Tie-on",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07564": {
            "GenartName": "Panic Lock",
            "NormTermName": "Panic Lock",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07565": {
            "GenartName": "Holder, quarter panel",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer",
            "UsageName": "Quarter Panel"
        },
        "07566": {
            "GenartName": "Holder, middle wall",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer",
            "UsageName": "Middle Wall"
        },
        "07567": {
            "GenartName": "Floor Fan",
            "NormTermName": "Floor Fan",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07568": {
            "GenartName": "Spade/Spar Holder",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer",
            "UsageName": "Spade/Spar"
        },
        "07569": {
            "GenartName": "Door Catch",
            "NormTermName": "Door Catch",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07570": {
            "GenartName": "Holder, door stay",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer",
            "UsageName": "Door Catch"
        },
        "07571": {
            "GenartName": "Protective Mounting, door stay",
            "NormTermName": "Protective Mounting",
            "AssGrpName": "Trailer",
            "UsageName": "Door Catch"
        },
        "07572": {
            "GenartName": "Housing, door stay",
            "NormTermName": "Housing",
            "AssGrpName": "Trailer",
            "UsageName": "Door Catch"
        },
        "07573": {
            "GenartName": "Pin, door stay",
            "NormTermName": "Pin",
            "AssGrpName": "Trailer",
            "UsageName": "Door Catch"
        },
        "07574": {
            "GenartName": "Clamp Buffer, door stay",
            "NormTermName": "Buffer",
            "AssGrpName": "Trailer",
            "UsageName": "Door Catch"
        },
        "07575": {
            "GenartName": "Bonnet Stay",
            "NormTermName": "Bonnet Stay",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07576": {
            "GenartName": "Bar Holder",
            "NormTermName": "Bar Holder",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07577": {
            "GenartName": "Spring Bar",
            "NormTermName": "Spring Bar",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07578": {
            "GenartName": "Tailgate Outrigger",
            "NormTermName": "Tailgate Outrigger",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07579": {
            "GenartName": "Step Board, trailer",
            "NormTermName": "Step Board",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07580": {
            "GenartName": "Ball Hitch",
            "NormTermName": "Ball Hitch",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07581": {
            "GenartName": "Remote Control, tail lift",
            "NormTermName": "Remote Control",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Tail Lift"
        },
        "07582": {
            "GenartName": "Friction Lining, ball hitch",
            "NormTermName": "Friction Lining",
            "AssGrpName": "Trailer",
            "UsageName": "Ball Hitch"
        },
        "07583": {
            "GenartName": "Pulling Eye",
            "NormTermName": "Tow Eye",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07584": {
            "GenartName": "Diskus Padlock",
            "NormTermName": "Lock",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Diskus Padlock"
        },
        "07585": {
            "GenartName": "Anti-theft device, ball hitch (trailer)",
            "NormTermName": "Anti-Theft Device",
            "AssGrpName": "Trailer",
            "UsageName": "Ball Hitch (trailer)"
        },
        "07586": {
            "GenartName": "Folding Pipe Clip",
            "NormTermName": "Folding Pipe Clip",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07587": {
            "GenartName": "Linch Pin, trailer",
            "NormTermName": "Linch Pin",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07588": {
            "GenartName": "Floor Roller, tail lift",
            "NormTermName": "Pulley",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Tail Lift"
        },
        "07589": {
            "GenartName": "Screw-on Hinge Base",
            "NormTermName": "Screw-on Hinge Base",
            "AssGrpName": "Trailer",
            "UsageName": "Hinge"
        },
        "07590": {
            "GenartName": "Hinge Set, trailer",
            "NormTermName": "Hinge Set",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07591": {
            "GenartName": "Operating Unit, tail lift",
            "NormTermName": "Operating Unit",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Tail Lift"
        },
        "07592": {
            "GenartName": "Holder, operating unit (tail lift)",
            "NormTermName": "Holder",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Operating Unit (tail lift)"
        },
        "07593": {
            "GenartName": "Foot Switch, tail lift",
            "NormTermName": "Switch",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Tail Lift"
        },
        "07594": {
            "GenartName": "Press Piece, foot switch (tail lift)",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Foot Switch (tail lift)"
        },
        "07595": {
            "GenartName": "Foot Switch Housing, tail lift",
            "NormTermName": "Foot Switch Housing",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Tail Lift"
        },
        "07596": {
            "GenartName": "Hinge, tailgate",
            "NormTermName": "Hinge",
            "AssGrpName": "Trailer",
            "UsageName": "Tailboard"
        },
        "07597": {
            "GenartName": "Handle",
            "NormTermName": "Handle",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07598": {
            "GenartName": "Receiver, radio controller (tail lift)",
            "NormTermName": "Receiver",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Radio Control (tail lift)"
        },
        "07599": {
            "GenartName": "Bushing, floor roller (tail lift)",
            "NormTermName": "Bush",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Floor Roller (tail lift)"
        },
        "07600": {
            "GenartName": "Hinge Bearing, trailer",
            "NormTermName": "Hinge Bearing",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07601": {
            "GenartName": "Hinge, trailer",
            "NormTermName": "Hinge",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07602": {
            "GenartName": "Hinge Set, case lid",
            "NormTermName": "Hinge Set",
            "AssGrpName": "Trailer",
            "UsageName": "Case Lid"
        },
        "07603": {
            "GenartName": "Tailgate Profile",
            "NormTermName": "Tailgate Profile",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07604": {
            "GenartName": "Edging Profile",
            "NormTermName": "Edging Profile",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07605": {
            "GenartName": "Corner Profile",
            "NormTermName": "Corner Profile",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07606": {
            "GenartName": "Push-fit Slat, tailgate profile",
            "NormTermName": "Push-fit Slat",
            "AssGrpName": "Trailer",
            "UsageName": "Tailgate Profile"
        },
        "07607": {
            "GenartName": "Protective Cap, tailgate profile",
            "NormTermName": "Cap",
            "AssGrpName": "Trailer",
            "UsageName": "Tailgate Profile"
        },
        "07608": {
            "GenartName": "Sealing Stoppers, tailgate profile",
            "NormTermName": "Plug",
            "AssGrpName": "Trailer",
            "UsageName": "Tailgate Profile"
        },
        "07609": {
            "GenartName": "Shock Absorber, trailer",
            "NormTermName": "Damper",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07610": {
            "GenartName": "Holder, shock absorber (trailer)",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer",
            "UsageName": "Shock Absorber (trailer)"
        },
        "07611": {
            "GenartName": "Wing, trailer",
            "NormTermName": "Wing",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07612": {
            "GenartName": "Keder Profile, wing (trailer)",
            "NormTermName": "Welting Profile",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07613": {
            "GenartName": "Step Insert, wing (trailer)",
            "NormTermName": "Step Insert",
            "AssGrpName": "Trailer",
            "UsageName": "Wing (trailer)"
        },
        "07614": {
            "GenartName": "Pulling Eye",
            "NormTermName": "Lock",
            "AssGrpName": "Trailer",
            "UsageName": "Pulling Eye"
        },
        "07615": {
            "GenartName": "U-Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "07616": {
            "GenartName": "Key Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Trailer",
            "UsageName": "Key Lock"
        },
        "07617": {
            "GenartName": "Case Lock, ball hitch",
            "NormTermName": "Lock",
            "AssGrpName": "Trailer",
            "UsageName": "Ball Hitch"
        },
        "07618": {
            "GenartName": "Coupling Lock, trailer",
            "NormTermName": "Lock",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07619": {
            "GenartName": "Wheel Clamp",
            "NormTermName": "Wheel Clamp",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07620": {
            "GenartName": "Espagnolette Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Trailer",
            "UsageName": "Espagnolette Lock"
        },
        "07621": {
            "GenartName": "Safety Pin, parking supports",
            "NormTermName": "Bolt",
            "AssGrpName": "Trailer",
            "UsageName": "Parking Supports"
        },
        "07622": {
            "GenartName": "Holder, spare wheel",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer",
            "UsageName": "Spare Wheel"
        },
        "07623": {
            "GenartName": "Spare Wheel Cover",
            "NormTermName": "Spare Wheel Cover",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07624": {
            "GenartName": "Bulbs Assortment",
            "NormTermName": "Assortment",
            "AssGrpName": "Lights",
            "UsageName": "Bulbs"
        },
        "07625": {
            "GenartName": "Wheel Seal",
            "NormTermName": "Wheel Seal",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07626": {
            "GenartName": "Covering, ball hitch / drawbar",
            "NormTermName": "Cover",
            "AssGrpName": "Trailer",
            "UsageName": "Ball Hitch / Drawbar"
        },
        "07627": {
            "GenartName": "Speed Sign",
            "NormTermName": "Speed Sign",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "07628": {
            "GenartName": "Type Label",
            "NormTermName": "Type Label",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "07629": {
            "GenartName": "Running Axle",
            "NormTermName": "Running Axle",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07630": {
            "GenartName": "Washing Polish",
            "NormTermName": "Washing Polish",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "07631": {
            "GenartName": "Brake Axle",
            "NormTermName": "Brake Axle",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07632": {
            "GenartName": "Drawbar",
            "NormTermName": "Drawbar",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07633": {
            "GenartName": "Intermediate Piece, drawbar",
            "NormTermName": "Connector",
            "AssGrpName": "Trailer",
            "UsageName": "Drawbar"
        },
        "07634": {
            "GenartName": "Clamping Collar Set, drawbar",
            "NormTermName": "Clamping Collar Set",
            "AssGrpName": "Trailer",
            "UsageName": "Drawbar"
        },
        "07635": {
            "GenartName": "Drawbar Spar, V-drawbar",
            "NormTermName": "Drawbar Spar",
            "AssGrpName": "Trailer",
            "UsageName": "V-drawbar"
        },
        "07636": {
            "GenartName": "Drawbar Spar Set, V-drawbar",
            "NormTermName": "Drawbar Spar Set",
            "AssGrpName": "Trailer",
            "UsageName": "V-drawbar"
        },
        "07637": {
            "GenartName": "Drawbar Spar Connection",
            "NormTermName": "Drawbar Spar Connection",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07638": {
            "GenartName": "Chain Oil",
            "NormTermName": "Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": "Chain Oil"
        },
        "07639": {
            "GenartName": "Angle Support, drawbar spar",
            "NormTermName": "Angle Support",
            "AssGrpName": "Trailer",
            "UsageName": "Drawbar Spar"
        },
        "07640": {
            "GenartName": "Drawbar Support",
            "NormTermName": "Drawbar Support",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07641": {
            "GenartName": "Overrun Brake",
            "NormTermName": "Overrun Brake",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "07642": {
            "GenartName": "Guide, break-away cable",
            "NormTermName": "Guide",
            "AssGrpName": "Trailer",
            "UsageName": "Break-away Cable"
        },
        "07643": {
            "GenartName": "Break-away Cable, overrun brake",
            "NormTermName": "Break-away Cable",
            "AssGrpName": "Trailer",
            "UsageName": "Overrun Brake"
        },
        "07644": {
            "GenartName": "Spacer, overrun brake",
            "NormTermName": "Spacer",
            "AssGrpName": "Trailer",
            "UsageName": "Overrun Brake"
        },
        "07645": {
            "GenartName": "Support Block, drawbar",
            "NormTermName": "Support Block",
            "AssGrpName": "Trailer",
            "UsageName": "Drawbar"
        },
        "07646": {
            "GenartName": "Damper, overrun brake",
            "NormTermName": "Damper",
            "AssGrpName": "Trailer",
            "UsageName": "Overrun Brake"
        },
        "07647": {
            "GenartName": "Handbrake lever, trailer",
            "NormTermName": "Lever",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07648": {
            "GenartName": "Handle, handbrake lever (trailer)",
            "NormTermName": "Handle",
            "AssGrpName": "Trailer",
            "UsageName": "Handbrake Lever (trailer)"
        },
        "07649": {
            "GenartName": "Spring Mechanism, overrun brake (trailer)",
            "NormTermName": "Spring Loaded Cylinder",
            "AssGrpName": "Trailer",
            "UsageName": "Control Device (trailer)"
        },
        "07650": {
            "GenartName": "Gas Spring, handbrake lever",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Trailer",
            "UsageName": "Hand Brake Lever"
        },
        "07651": {
            "GenartName": "Bellows, overrun brake",
            "NormTermName": "Bellow",
            "AssGrpName": "Trailer",
            "UsageName": "Overrun Brake"
        },
        "07652": {
            "GenartName": "Pull Rod, overrun brake",
            "NormTermName": "Pull Rod",
            "AssGrpName": "Trailer",
            "UsageName": "Overrun Brake"
        },
        "07653": {
            "GenartName": "Bush, tie rod",
            "NormTermName": "Bush",
            "AssGrpName": "Trailer",
            "UsageName": "Pull Rod"
        },
        "07654": {
            "GenartName": "Bearing Screw, overrun brake / drawbar",
            "NormTermName": "Screw",
            "AssGrpName": "Trailer",
            "UsageName": "Overrun Brake / drawbar"
        },
        "07655": {
            "GenartName": "Locking lever, overrun brake",
            "NormTermName": "Chuck Key",
            "AssGrpName": "Trailer",
            "UsageName": "Overrun Brake"
        },
        "07656": {
            "GenartName": "Bowden cable, overrun brake",
            "NormTermName": "Bowden Cable",
            "AssGrpName": "Trailer",
            "UsageName": "Overrun Brake"
        },
        "07657": {
            "GenartName": "Cable Pull, overrun brake",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Trailer",
            "UsageName": "Overrun Brake"
        },
        "07658": {
            "GenartName": "Brake Cable, trailer",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07659": {
            "GenartName": "Holder, brake cable",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer",
            "UsageName": "Brake Cable"
        },
        "07660": {
            "GenartName": "Receptacle, brake cable / brake linkage",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer",
            "UsageName": "Brake Cable / Brake Linkage"
        },
        "07661": {
            "GenartName": "Lifting Eye, brake cable",
            "NormTermName": "Lifting Eye",
            "AssGrpName": "Trailer",
            "UsageName": "Brake Cable"
        },
        "07662": {
            "GenartName": "Brake cable Connector, trailer",
            "NormTermName": "Brake Cable Connection",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07663": {
            "GenartName": "Pulling Yoke, brake cable",
            "NormTermName": "Bracket",
            "AssGrpName": "Trailer",
            "UsageName": "Brake Cable"
        },
        "07664": {
            "GenartName": "Brake Linkage, trailer",
            "NormTermName": "Brake Linkage",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07665": {
            "GenartName": "Turnbuckle, brake linkage",
            "NormTermName": "Turnbuckle",
            "AssGrpName": "Trailer",
            "UsageName": "Brake Linkage"
        },
        "07666": {
            "GenartName": "Abutment Mount, tandem trailer",
            "NormTermName": "Abutment Mount",
            "AssGrpName": "Trailer",
            "UsageName": "Tandem Trailer"
        },
        "07667": {
            "GenartName": "System Canister",
            "NormTermName": "System Canister",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07668": {
            "GenartName": "Dispenser, system canister",
            "NormTermName": "Dispenser",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "System Canister"
        },
        "07669": {
            "GenartName": "Stop Bracket, overrun brake",
            "NormTermName": "Stop Bracket",
            "AssGrpName": "Trailer",
            "UsageName": "Overrun Brake"
        },
        "07670": {
            "GenartName": "Stop, overrun brake",
            "NormTermName": "Stop",
            "AssGrpName": "Trailer",
            "UsageName": "Overrun Brake"
        },
        "07671": {
            "GenartName": "Pulling Ring, pull rod (overrun brake)",
            "NormTermName": "Pulling Ring",
            "AssGrpName": "Trailer",
            "UsageName": "Pull Rod (overrun brake)"
        },
        "07672": {
            "GenartName": "Pull Rod Repair Set",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Trailer",
            "UsageName": "Pull Rod"
        },
        "07673": {
            "GenartName": "Coupling Ball, towing device",
            "NormTermName": "Coupling Ball",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Trailer Hitch"
        },
        "07674": {
            "GenartName": "Impact/Ram Protection, ball hitch",
            "NormTermName": "Impact/Ram Protection",
            "AssGrpName": "Trailer",
            "UsageName": "Ball Hitch"
        },
        "07675": {
            "GenartName": "Holder, brake linkage",
            "NormTermName": "Holder",
            "AssGrpName": "Trailer",
            "UsageName": "Brake Linkage"
        },
        "07676": {
            "GenartName": "Adapter, brake linkage",
            "NormTermName": "Adaptor",
            "AssGrpName": "Trailer",
            "UsageName": "Brake Linkage"
        },
        "07677": {
            "GenartName": "Nut, brake linkage (fastening)",
            "NormTermName": "Nut",
            "AssGrpName": "Trailer",
            "UsageName": "Brake Linkage (fastening)"
        },
        "07678": {
            "GenartName": "Clamping Nut, brake linkage",
            "NormTermName": "Nut",
            "AssGrpName": "Trailer",
            "UsageName": "Brake Linkage"
        },
        "07679": {
            "GenartName": "Brake Linkage Set, single axle trailer",
            "NormTermName": "Brake Linkage Set",
            "AssGrpName": "Trailer",
            "UsageName": "Single-axle trailer"
        },
        "07680": {
            "GenartName": "Brake Linkage Set, tandem axle trailer",
            "NormTermName": "Brake Linkage Set",
            "AssGrpName": "Trailer",
            "UsageName": "Tandem Trailer"
        },
        "07681": {
            "GenartName": "Control Rod, overrun brake",
            "NormTermName": "Control Rod",
            "AssGrpName": "Trailer",
            "UsageName": "Overrun Brake"
        },
        "07682": {
            "GenartName": "Adjusting Rod, overrun brake",
            "NormTermName": "Adjusting Rod",
            "AssGrpName": "Trailer",
            "UsageName": "Overrun Brake"
        },
        "07683": {
            "GenartName": "Wheel Hub, trailer",
            "NormTermName": "Wheel Hub",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07684": {
            "GenartName": "Brake Drum, trailer",
            "NormTermName": "Brake Drum",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07685": {
            "GenartName": "Brake Shoe Set, trailer",
            "NormTermName": "Brake Shoe Set",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07686": {
            "GenartName": "Spreading Lock, drum brake (trailer)",
            "NormTermName": "Spreading Lock",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07687": {
            "GenartName": "Spring Set, brake shoes (drum brake trailer)",
            "NormTermName": "Spring Kit",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07688": {
            "GenartName": "Adjuster Set, drum brake (trailer)",
            "NormTermName": "Adjuster Set",
            "AssGrpName": "Trailer",
            "UsageName": "drum Brake (trailer)"
        },
        "07689": {
            "GenartName": "Auto-reverse Lever, drum brake (trailer)",
            "NormTermName": "Auto-reverse Lever",
            "AssGrpName": "Trailer",
            "UsageName": "drum Brake (trailer)"
        },
        "07690": {
            "GenartName": "Leaf Spring, drum brake adjuster (trailer)",
            "NormTermName": "Leaf Spring",
            "AssGrpName": "Trailer",
            "UsageName": "Adjuster, drum brake (trailer)"
        },
        "07691": {
            "GenartName": "Pressing Bracket, drum brake adjuster (trailer)",
            "NormTermName": "Bracket",
            "AssGrpName": "Trailer",
            "UsageName": "Adjuster, drum brake (trailer)"
        },
        "07692": {
            "GenartName": "Wheel Bearing, trailer",
            "NormTermName": "Bearing",
            "AssGrpName": "Trailer",
            "UsageName": "Wheel Hub"
        },
        "07693": {
            "GenartName": "Protective Cap, ball head (trailer hitch)",
            "NormTermName": "Cap",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Ball Head (trailer hitch)"
        },
        "07694": {
            "GenartName": "Shaft Seal, wheel bearing (trailer)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Trailer",
            "UsageName": "Wheel Bearing (trailer)"
        },
        "07695": {
            "GenartName": "Nut, wheel bearing (trailer)",
            "NormTermName": "Nut",
            "AssGrpName": "Trailer",
            "UsageName": "Wheel Bearing (trailer)"
        },
        "07696": {
            "GenartName": "Wheel Bearing Set, trailer",
            "NormTermName": "Bearing Kit",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "07697": {
            "GenartName": "Cap, wheel bearing (trailer)",
            "NormTermName": "Cap",
            "AssGrpName": "Trailer",
            "UsageName": "Wheel Bearing (trailer)"
        },
        "07698": {
            "GenartName": "Nose Load Plate",
            "NormTermName": "Nose Load Plate",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "07699": {
            "GenartName": "Pull Rod Damping",
            "NormTermName": "Damper",
            "AssGrpName": "Trailer",
            "UsageName": "Pull Rod"
        },
        "07700": {
            "GenartName": "Brake Linkage Compensation Profile, single axle trailer",
            "NormTermName": "Compensating Profile",
            "AssGrpName": "Trailer",
            "UsageName": "Single-axle trailer"
        },
        "07701": {
            "GenartName": "Brake Linkage Compensation Profile, tandem axle trailer",
            "NormTermName": "Compensating Profile",
            "AssGrpName": "Trailer",
            "UsageName": "Tandem Trailer"
        },
        "07702": {
            "GenartName": "Cable Repair Set, intake air temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Intake Air Temperature Sensor"
        },
        "07703": {
            "GenartName": "Power Steering Fluid",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Power Steering"
        },
        "07704": {
            "GenartName": "Cable Repair Set, camshaft sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Camshaft Sensor"
        },
        "07705": {
            "GenartName": "Cable Repair Set, crankshaft position sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Crankshaft Position Sensor"
        },
        "07706": {
            "GenartName": "Cable Repair Set, outside temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Instruments",
            "UsageName": "Outside Temperature Sensor"
        },
        "07707": {
            "GenartName": "Cable Repair Set, idle regulating valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Idle Speed Control Valve"
        },
        "07708": {
            "GenartName": "Cable Repair Set, knocking sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Knock Sensor"
        },
        "07709": {
            "GenartName": "V-Belt",
            "NormTermName": "Belt",
            "AssGrpName": "Belt Drive, universal",
            "UsageName": "V-Belt"
        },
        "07710": {
            "GenartName": "V-Ribbed Belt",
            "NormTermName": "Belt",
            "AssGrpName": "Belt Drive, universal",
            "UsageName": "V-Ribbed Belt"
        },
        "07711": {
            "GenartName": "V-Belt Set",
            "NormTermName": "Belt Kit",
            "AssGrpName": "Belt Drive, universal",
            "UsageName": "V-Belt"
        },
        "07712": {
            "GenartName": "V-Ribbed Belt Set",
            "NormTermName": "Belt Kit",
            "AssGrpName": "Belt Drive, universal",
            "UsageName": "V-Ribbed Belt"
        },
        "07713": {
            "GenartName": "Slide Rail Holder, tail lift",
            "NormTermName": "Slide Rail Holder",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Tail Lift"
        },
        "07715": {
            "GenartName": "Hose Clamp Pliers Set",
            "NormTermName": "Pliers Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hose Clamp Pliers Set"
        },
        "07716": {
            "GenartName": "Removal Drift, camshaft sprocket",
            "NormTermName": "Removal Drift",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Camshaft Sprocket"
        },
        "07717": {
            "GenartName": "Puller, spark plug connector",
            "NormTermName": "Puller",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spark Plug Head"
        },
        "07718": {
            "GenartName": "Puller, ignition coil plug",
            "NormTermName": "Puller",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ignition Coil Plug"
        },
        "07719": {
            "GenartName": "Puller Set, spark plug connector",
            "NormTermName": "Puller Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ignition Coil Plug"
        },
        "07720": {
            "GenartName": "Puller Hook, front grille",
            "NormTermName": "Puller Hooks",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Front grille"
        },
        "07721": {
            "GenartName": "Airbrush Gun",
            "NormTermName": "Airbrush Gun",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07722": {
            "GenartName": "Charger (universal)",
            "NormTermName": "Charger",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "07723": {
            "GenartName": "Oil Distributor, bearing ladder (crankshaft)",
            "NormTermName": "Oil Distributor",
            "AssGrpName": "Crankcase",
            "UsageName": "Bearing Ladder (crankshaft)"
        },
        "07724": {
            "GenartName": "Locking Screw, rack centre position (steering)",
            "NormTermName": "Locking Screw",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Toothed Rack Centre Position"
        },
        "07725": {
            "GenartName": "Attachment Set, hot air dryer",
            "NormTermName": "Attachment",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hot Air Fan"
        },
        "07726": {
            "GenartName": "Push-fit Ratchet",
            "NormTermName": "Push-fit Ratchet",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07727": {
            "GenartName": "Pu,ler Set, injector nozzle",
            "NormTermName": "Puller Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injector Nozzle"
        },
        "07728": {
            "GenartName": "Masonry Drill Set",
            "NormTermName": "Drill Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Masonry Drill Set"
        },
        "07729": {
            "GenartName": "Flaring Tool, wheel arch",
            "NormTermName": "Flaring Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Arch"
        },
        "07730": {
            "GenartName": "Crowbar",
            "NormTermName": "Crowbar",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07731": {
            "GenartName": "Valve Seat",
            "NormTermName": "Valve Seat",
            "AssGrpName": "Cylinder Head, universal",
            "UsageName": "Valve"
        },
        "07732": {
            "GenartName": "Socket Wrench Insert, brake caliper",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Brake Caliper"
        },
        "07733": {
            "GenartName": "Valve keeper",
            "NormTermName": "Valve Keeper",
            "AssGrpName": "Engine Timing, universal",
            "UsageName": "Valve"
        },
        "07734": {
            "GenartName": "Adjusting Disc, valve clearance",
            "NormTermName": "Adjusting Disc",
            "AssGrpName": "Engine Timing, universal",
            "UsageName": "Valve Clearance"
        },
        "07735": {
            "GenartName": "Test Device, common rail high pressure pump",
            "NormTermName": "Tester",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Common Rail High Pressure Pump"
        },
        "07736": {
            "GenartName": "Valve Guide",
            "NormTermName": "Guide",
            "AssGrpName": "Cylinder Head, universal",
            "UsageName": "Valve Guide"
        },
        "07737": {
            "GenartName": "Chart Sheets, compression pressure recorder",
            "NormTermName": "Chart Sheets",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Compression Pressure Recorder"
        },
        "07738": {
            "GenartName": "Filling Device, diesel fuel filter",
            "NormTermName": "Filling Device",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Diesel Fuel Filter"
        },
        "07739": {
            "GenartName": "Document Holder, tool trolley",
            "NormTermName": "Document Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "07741": {
            "GenartName": "Thrust Piece Set, installation tool silent bearing",
            "NormTermName": "Thrust Piece Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Installation Tool, silent bearing"
        },
        "07742": {
            "GenartName": "Seal Ring, valve stem",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cylinder Head, universal",
            "UsageName": "Valve Stem"
        },
        "07743": {
            "GenartName": "Disconnecting/installation Fork Set",
            "NormTermName": "Disconnecting/installation Fork Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07744": {
            "GenartName": "Gas Spring, pop-top",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Pop-top"
        },
        "07745": {
            "GenartName": "Gas Spring, bed (pop-top)",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Bed (pop-top)"
        },
        "07746": {
            "GenartName": "Plug-on Screwdriver",
            "NormTermName": "Plug-on Screwdriver",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07747": {
            "GenartName": "Compressed Air Comb",
            "NormTermName": "Compressed Air Comb",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07748": {
            "GenartName": "Nozzle Set, spray gun",
            "NormTermName": "Nozzle Set",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Spray Gun"
        },
        "07749": {
            "GenartName": "Insertion Tool, threaded insert",
            "NormTermName": "Insertion Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Thread Insert"
        },
        "07750": {
            "GenartName": "Plug-fit Socket Set, torque wrench",
            "NormTermName": "Key Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "07751": {
            "GenartName": "Sticker, return",
            "NormTermName": "Sticker",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Return"
        },
        "07752": {
            "GenartName": "Adjustment Device, camber adjustment",
            "NormTermName": "Adjusting Device",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Camber Adjustment"
        },
        "07753": {
            "GenartName": "Hammer Set",
            "NormTermName": "Hammer Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07754": {
            "GenartName": "Cartridge Tip Pliers",
            "NormTermName": "Cartridge Tip Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07755": {
            "GenartName": "Pronged Wrench",
            "NormTermName": "Pronged Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07756": {
            "GenartName": "Magnet, socket wrench insert",
            "NormTermName": "Magnet",
            "AssGrpName": "Tool, universal",
            "UsageName": "Socket Wrench Insert"
        },
        "07757": {
            "GenartName": "Hexagon Tubular Box Wrench Set",
            "NormTermName": "Hexagon Tubular Box Wrench Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07758": {
            "GenartName": "Extension, ring / open-ended spanner",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ring-/Open End Spanner"
        },
        "07759": {
            "GenartName": "Spark Plug Gauge",
            "NormTermName": "Spark Plug Gauge",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07760": {
            "GenartName": "Multifunction Spanner",
            "NormTermName": "Multifunction Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07761": {
            "GenartName": "Retaining Wrench, fan (fluid friction coupling)",
            "NormTermName": "Arrester Spanner",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Fan (fluid friction coupling)"
        },
        "07762": {
            "GenartName": "Arrester Spanner, toothed belt sprocket",
            "NormTermName": "Arrester Spanner",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Toothed Belt Sprocket"
        },
        "07763": {
            "GenartName": "Fabric Shears",
            "NormTermName": "Fabric Shears",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07764": {
            "GenartName": "Cable Drum, tool trolley",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley Attachment"
        },
        "07765": {
            "GenartName": "Floor Roller Set, tail lift",
            "NormTermName": "Pulley Kit",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Tail Lift"
        },
        "07766": {
            "GenartName": "Bush",
            "NormTermName": "Bush",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "07767": {
            "GenartName": "Ring, floor roller (tail lift)",
            "NormTermName": "Ring",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Floor Roller (tail lift)"
        },
        "07768": {
            "GenartName": "Platform Tip, tail lift",
            "NormTermName": "Platform Tip",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Tail Lift"
        },
        "07769": {
            "GenartName": "Cover, operating unit (tail lift)",
            "NormTermName": "Lid",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Operating Unit (tail lift)"
        },
        "07770": {
            "GenartName": "Hydraulic Cylinder, tail lift",
            "NormTermName": "Hydraulic Cylinder",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Tail Lift"
        },
        "07771": {
            "GenartName": "Bellows, hydraulic cylinder (tail lift)",
            "NormTermName": "Bellow",
            "AssGrpName": "Body",
            "UsageName": "Hydraulic Cylinder (tail lift)"
        },
        "07772": {
            "GenartName": "Mounting Bolt, floor roller (tail lift)",
            "NormTermName": "Bolt",
            "AssGrpName": "Body",
            "UsageName": "Floor Roller (tail lift)"
        },
        "07773": {
            "GenartName": "Solenoid Valve, tail lift",
            "NormTermName": "Valve",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Tail Lift"
        },
        "07774": {
            "GenartName": "Holder, remote control, tail lift",
            "NormTermName": "Bracket",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Remote Control, tail lift"
        },
        "07775": {
            "GenartName": "Hydraulic Power Unit, tail lift",
            "NormTermName": "Hydraulic Unit",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Tail Lift"
        },
        "07776": {
            "GenartName": "Pump, hydraulic power unit (tail lift)",
            "NormTermName": "Pump",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Hydraulic Power Unit (tail lift)"
        },
        "07777": {
            "GenartName": "Suction Hose, power unit pump (tail lift)",
            "NormTermName": "Hose Line",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Power Unit Pump (tail lift)"
        },
        "07778": {
            "GenartName": "Roll-off Safety, tail lift",
            "NormTermName": "Roll-off Safety",
            "AssGrpName": "Body",
            "UsageName": "Tail Lift"
        },
        "07779": {
            "GenartName": "Cover, hydraulic power unit (tail lift)",
            "NormTermName": "Lid",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Hydraulic Power Unit (tail lift)"
        },
        "07780": {
            "GenartName": "Seal (hydraulic power unit)",
            "NormTermName": "Closure",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Cover (hydraulic power unit)"
        },
        "07781": {
            "GenartName": "Holder, tow bar socket",
            "NormTermName": "Bracket",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Towbar Socket"
        },
        "07782": {
            "GenartName": "Seal Set, tail lift",
            "NormTermName": "Seal Set",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Tail Lift"
        },
        "07783": {
            "GenartName": "Thread Tap, spark plug",
            "NormTermName": "Thread Tap",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spark Plug"
        },
        "07784": {
            "GenartName": "Holder, spray gun",
            "NormTermName": "Holder",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Spray Gun"
        },
        "07785": {
            "GenartName": "Filling Hose, urea solution",
            "NormTermName": "Filling Hose",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Urea Solution"
        },
        "07786": {
            "GenartName": "Filter Spanner, urea",
            "NormTermName": "Key",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Urea"
        },
        "07787": {
            "GenartName": "Removal Lever, tailgate panelling",
            "NormTermName": "Removal Lever",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tailgate Panelling"
        },
        "07788": {
            "GenartName": "Fillet Weld Scrubber",
            "NormTermName": "Fillet Weld Scrubber",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07789": {
            "GenartName": "Adhesive Fixing Mallet",
            "NormTermName": "Adhesive Fixing Mallet",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07790": {
            "GenartName": "Adhesive Fixing Mallet Set",
            "NormTermName": "Adhesive Fixing Mallet Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07791": {
            "GenartName": "Wing Puller",
            "NormTermName": "Wing Puller",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07792": {
            "GenartName": "Coupling / Magnet Wheel Holder",
            "NormTermName": "Coupling / Magnet Wheel Holder",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07793": {
            "GenartName": "Copper Hammer",
            "NormTermName": "Copper Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07794": {
            "GenartName": "Compressed Air Mini Oiler",
            "NormTermName": "Compressed Air Mini Oiler",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07795": {
            "GenartName": "Puller, battery terminal clamp",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Battery Terminal Clamp"
        },
        "07796": {
            "GenartName": "Test Spark Plug",
            "NormTermName": "Test Spark Plug",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07797": {
            "GenartName": "Reamer, Lambda sensor removal",
            "NormTermName": "Reamer",
            "AssGrpName": "Tool, universal",
            "UsageName": "Lambda Sensor Holder"
        },
        "07798": {
            "GenartName": "Reamer Set, glow plugs",
            "NormTermName": "Reamer Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glow Plug"
        },
        "07799": {
            "GenartName": "Cutting Wire, handle",
            "NormTermName": "Cutting Wire, handle",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07800": {
            "GenartName": "Cutting Wire, arrestor",
            "NormTermName": "Cutting Wire, arrestor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07801": {
            "GenartName": "Impact Extractor, wheel hub",
            "NormTermName": "Impact Extractor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Hub"
        },
        "07802": {
            "GenartName": "Wheel Hub Grinder",
            "NormTermName": "Wheel Hub Grinder",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07803": {
            "GenartName": "Removal Tool, rain/light sensor",
            "NormTermName": "Removal Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Rain/Light sensor"
        },
        "07804": {
            "GenartName": "Cleaning Brush Set, spray gun",
            "NormTermName": "Brush Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spray Gun"
        },
        "07805": {
            "GenartName": "Test Device, relay",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Relay"
        },
        "07806": {
            "GenartName": "Test Device, relay socket",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Relay Socket"
        },
        "07807": {
            "GenartName": "Pliers, relay",
            "NormTermName": "Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Relay"
        },
        "07808": {
            "GenartName": "Double Ring Spanner, injection lines",
            "NormTermName": "Spanner, double ring",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injection Pipe"
        },
        "07809": {
            "GenartName": "Water Squeegee",
            "NormTermName": "Water Squeegee",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07810": {
            "GenartName": "Weld-on Bung, NOx sensor",
            "NormTermName": "Weld-On Bung",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "NOx Sensor"
        },
        "07811": {
            "GenartName": "Weld-On Bung, lambda sensor",
            "NormTermName": "Weld-On Bung",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Exhaust Pipe"
        },
        "07812": {
            "GenartName": "Pliers, interior mirror",
            "NormTermName": "Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Interior Mirror"
        },
        "07813": {
            "GenartName": "Test Device, cigarette lighter",
            "NormTermName": "Tester",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cigarette Lighter"
        },
        "07814": {
            "GenartName": "Two-Prong Spanner, angle grinder",
            "NormTermName": "Key",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "07815": {
            "GenartName": "Compression Pressure Recorder",
            "NormTermName": "Compression Pressure Recorder",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07816": {
            "GenartName": "Valve, water pump",
            "NormTermName": "Valve",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "07817": {
            "GenartName": "Bonnet/tailgate support",
            "NormTermName": "Bonnet/tailgate support",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07818": {
            "GenartName": "Insert, plug-in axle (motorcycle)",
            "NormTermName": "Insert",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Plug-on Axle (motorcycle)"
        },
        "07819": {
            "GenartName": "Release Pliers, rivet head (rivet breaker)",
            "NormTermName": "Release Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Expanding Rivet"
        },
        "07820": {
            "GenartName": "Safety Cable, spring compressor",
            "NormTermName": "Safety Cable",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Compressor"
        },
        "07821": {
            "GenartName": "Tool Set, swing arm",
            "NormTermName": "Tool Set",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Swing Arm"
        },
        "07823": {
            "GenartName": "Machine Thread Tap",
            "NormTermName": "Machine Thread Tap",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07824": {
            "GenartName": "Oil Filter Chain",
            "NormTermName": "Oil Filter Chain",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07825": {
            "GenartName": "Oil Filter Spider",
            "NormTermName": "Oil Filter Spider",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07826": {
            "GenartName": "Oil Dipstick Set",
            "NormTermName": "Oil Dipstick Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07827": {
            "GenartName": "Magnet Wheel Puller",
            "NormTermName": "Magnet Wheel Puller",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07828": {
            "GenartName": "Magnet Wheel Puller Set",
            "NormTermName": "Magnet Wheel Puller Set",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "07829": {
            "GenartName": "Windscreen Cut-out Device",
            "NormTermName": "Windscreen Cut-out Device",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07830": {
            "GenartName": "Cutting Wire, penetrating blade",
            "NormTermName": "Cutting Wire, penetrating blade",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07831": {
            "GenartName": "Quick Clamp Nut, angle grinder",
            "NormTermName": "Nut",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "07832": {
            "GenartName": "Circle Cutter, jigsaw",
            "NormTermName": "Circle Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Jigsaw"
        },
        "07833": {
            "GenartName": "Angle Table, jigsaw",
            "NormTermName": "Angle Table",
            "AssGrpName": "Tool, universal",
            "UsageName": "Jigsaw"
        },
        "07834": {
            "GenartName": "Sole Plate, jigsaw",
            "NormTermName": "Sole Plate",
            "AssGrpName": "Tool, universal",
            "UsageName": "Jigsaw"
        },
        "07836": {
            "GenartName": "Saw Blade, multifunction tool (oscillator)",
            "NormTermName": "Saw Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": "Multifunction Tool (oscillator)"
        },
        "07837": {
            "GenartName": "Saw Blade Set, multifunction tool (oscillator)",
            "NormTermName": "Saw Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Multifunction Tool (oscillator)"
        },
        "07838": {
            "GenartName": "Sensor, headlight levelling",
            "NormTermName": "Sensor",
            "AssGrpName": "Lights",
            "UsageName": "Headlight Levelling"
        },
        "07839": {
            "GenartName": "Saw Blade, sabre saw",
            "NormTermName": "Saw Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": "Sabre Saw"
        },
        "07840": {
            "GenartName": "Saw Blade Set, sabre saw",
            "NormTermName": "Saw Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Sabre Saw"
        },
        "07841": {
            "GenartName": "Soft Jaws, fitting pliers",
            "NormTermName": "Soft Jaws",
            "AssGrpName": "Tool, universal",
            "UsageName": "Fitting Pliers"
        },
        "07842": {
            "GenartName": "Mounting Flange, angle grinder",
            "NormTermName": "Flange",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "07843": {
            "GenartName": "Handle, angle grinder",
            "NormTermName": "Handle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "07844": {
            "GenartName": "Spade",
            "NormTermName": "Spade",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "07845": {
            "GenartName": "Depth Calipers",
            "NormTermName": "Depth Calipers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07846": {
            "GenartName": "Carpet Shears",
            "NormTermName": "Carpet Shears",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07847": {
            "GenartName": "Piston Push Gun",
            "NormTermName": "Piston Push Gun",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07848": {
            "GenartName": "Socket Wrench Set, lambda sensor",
            "NormTermName": "Socket Wrench Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Lambda Sensor"
        },
        "07849": {
            "GenartName": "Flushing Liquid, cylinder head sealing",
            "NormTermName": "Rinsing Liquid",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Cylinder Head Leakage"
        },
        "07850": {
            "GenartName": "Brake Lever, footrest system",
            "NormTermName": "Lever",
            "AssGrpName": "Braking System",
            "UsageName": "Footrest System (motorcycle)"
        },
        "07851": {
            "GenartName": "Foot Shift Lever, footrest",
            "NormTermName": "Lever",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Footrest System (motorcycle)"
        },
        "07853": {
            "GenartName": "Hydraulic Cylinder Set, puller spindle",
            "NormTermName": "Hydraulic Cylinder Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Puller Spindle"
        },
        "07854": {
            "GenartName": "Assortment, T-unions",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "T-Union"
        },
        "07855": {
            "GenartName": "Assortment, circlips",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Circlip"
        },
        "07856": {
            "GenartName": "Assortment, grease nipples",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Grease Nipple"
        },
        "07857": {
            "GenartName": "Assortment, cotter pins",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Split Pin"
        },
        "07858": {
            "GenartName": "Assortment, roll pins",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Roll Pin"
        },
        "07859": {
            "GenartName": "Assortment, securing bolts",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Securing Bolt"
        },
        "07860": {
            "GenartName": "Assortment, securing elements",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Securing Element"
        },
        "07861": {
            "GenartName": "Controller, towbar",
            "NormTermName": "Control Unit",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Trailer Hitch"
        },
        "07862": {
            "GenartName": "Oil Scraper Ring, cylinder liner",
            "NormTermName": "Oil Scraper Ring",
            "AssGrpName": "Crankcase",
            "UsageName": "Cylinder Sleeve"
        },
        "07863": {
            "GenartName": "Radio",
            "NormTermName": "Radio",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07864": {
            "GenartName": "Bit Adapter, ratchet ring spanner",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Ring Spanner"
        },
        "07865": {
            "GenartName": "Double Grinding Machine",
            "NormTermName": "Double Grinding Machine",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07866": {
            "GenartName": "Compressed Air Manometer",
            "NormTermName": "Manometer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air Systems"
        },
        "07867": {
            "GenartName": "Installation Tool Set, direct shift transmission",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Direct Shift Transmission"
        },
        "07868": {
            "GenartName": "Dashboard Protection Panel Set, glass removal",
            "NormTermName": "Protective Panel Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Glass removal"
        },
        "07870": {
            "GenartName": "Socket Wrench Set, cylinder head bolt",
            "NormTermName": "Socket Wrench Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Cylinder Head Bolt"
        },
        "07871": {
            "GenartName": "Adapter Set, fuel system pressure tester",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tester, fuel system pressure"
        },
        "07872": {
            "GenartName": "Adapter Set, engine tester",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Engine Test Device"
        },
        "07873": {
            "GenartName": "Weld-on Part, bodywork spotter",
            "NormTermName": "Weld-on Part",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Bodywork Spotter"
        },
        "07874": {
            "GenartName": "Electronic Detector",
            "NormTermName": "Electronic Detector",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07875": {
            "GenartName": "Alignment Measurement Device, belt drive",
            "NormTermName": "Alignment Measurement Device",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Belt Drive"
        },
        "07876": {
            "GenartName": "Socket Wrench Set, drive shaft",
            "NormTermName": "Socket Wrench Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Drive Shaft"
        },
        "07877": {
            "GenartName": "Light, workshop pit",
            "NormTermName": "Light",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Pit"
        },
        "07878": {
            "GenartName": "Hook, pegboard (tool trolley)",
            "NormTermName": "Hook",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Pegboard (tool trolley)"
        },
        "07879": {
            "GenartName": "Bodywork Spotter Set",
            "NormTermName": "Bodywork Spotter Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07880": {
            "GenartName": "Hose Crimp",
            "NormTermName": "Hose Crimp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07881": {
            "GenartName": "Ratchet Wrench, air conditioning",
            "NormTermName": "Ratchet Wrench",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Air Conditioning"
        },
        "07882": {
            "GenartName": "Tool Set, air conditioning system",
            "NormTermName": "Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Air Conditioning"
        },
        "07883": {
            "GenartName": "Special Socket Wrench Insert, ABS rotor",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "ABS Rotor"
        },
        "07884": {
            "GenartName": "Removal Tool, hose clamp",
            "NormTermName": "Removal Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hose Clamp"
        },
        "07885": {
            "GenartName": "Heat Shield",
            "NormTermName": "Heat Shield",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Heat Shield"
        },
        "07886": {
            "GenartName": "Core Drill",
            "NormTermName": "Core Drill",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07887": {
            "GenartName": "Exhaust Tip",
            "NormTermName": "Cover",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Tailpipe"
        },
        "07888": {
            "GenartName": "Core Drill Set",
            "NormTermName": "Core Drill Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07889": {
            "GenartName": "Mount, exhaust system",
            "NormTermName": "Holder",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": null
        },
        "07890": {
            "GenartName": "Repair Set, tyre",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tyre"
        },
        "07891": {
            "GenartName": "Installer Set, shaft seal",
            "NormTermName": "Installer Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Shaft Seal"
        },
        "07893": {
            "GenartName": "Magnetic Box Column Drill",
            "NormTermName": "Magnetic Box Column Drill",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07894": {
            "GenartName": "Hammer Wrench, brake caliper fastening",
            "NormTermName": "Key",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "brake caliper Fastening"
        },
        "07895": {
            "GenartName": "Toolbox, tool trolley",
            "NormTermName": "Tool Box",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "07896": {
            "GenartName": "Telescopic Measurement Gauge",
            "NormTermName": "Gauge",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Body Gap Size"
        },
        "07897": {
            "GenartName": "Removal Tool, door handle",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Door Handle"
        },
        "07898": {
            "GenartName": "Installation/removal Tool, door hinge",
            "NormTermName": "Mounting/Dismantling Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Door Hinge"
        },
        "07899": {
            "GenartName": "Pin Wrench, ball bearing groove nut",
            "NormTermName": "Pin Wrench",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ball Bearing Groove Nut"
        },
        "07900": {
            "GenartName": "Pin Wrench, ball bearing groove nut",
            "NormTermName": "Pin Wrench Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ball Bearing Groove Nut"
        },
        "07901": {
            "GenartName": "Socket Wrench, hub cap (CV axle)",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Hub Cap (CV axle)"
        },
        "07902": {
            "GenartName": "Hammer Wrench, hub cap (CV axle)",
            "NormTermName": "Key",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Hub Cap (CV axle)"
        },
        "07903": {
            "GenartName": "Removal Tool Set, injection nozzle (expansion valve)",
            "NormTermName": "Removal Kit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injection Nozzle (expansion valve)"
        },
        "07904": {
            "GenartName": "Installation Tool, timing chain",
            "NormTermName": "Mounting Device",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Timing Chain"
        },
        "07905": {
            "GenartName": "Tool Set, timing chain",
            "NormTermName": "Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Timing Chain"
        },
        "07906": {
            "GenartName": "Assortment, fuel line repair",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Fuel Line"
        },
        "07907": {
            "GenartName": "Socket Wrench Insert, steel valve assembly (valve inserter)",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Steel Valve Installation (steel valve inst. tool)"
        },
        "07908": {
            "GenartName": "Socket Wrench Insert, steering knuckle (spreader)",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Steering Knuckle"
        },
        "07909": {
            "GenartName": "Rubber Aligning Tool",
            "NormTermName": "Rubber Aligning Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07910": {
            "GenartName": "Mounting Aid, spark plug",
            "NormTermName": "Mounting Aid",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spark Plug"
        },
        "07911": {
            "GenartName": "Mounting Sleeve, ignition lock",
            "NormTermName": "Installation Sleeve",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ignition Lock"
        },
        "07912": {
            "GenartName": "Test Device, radio keys",
            "NormTermName": "Tester",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Radio Key"
        },
        "07913": {
            "GenartName": "Screwdriver, exterior mirror",
            "NormTermName": "Screwdriver",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Exterior Mirror"
        },
        "07914": {
            "GenartName": "Screwdriver, steering column shroud",
            "NormTermName": "Screwdriver",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Steering Column Shroud"
        },
        "07915": {
            "GenartName": "Slide Hammer Puller, drier cartridge (A/C system)",
            "NormTermName": "Impact Extractor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Drier Cartridge (A/C system)"
        },
        "07916": {
            "GenartName": "Striking Tool Set",
            "NormTermName": "Striking Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07917": {
            "GenartName": "Roughing Wheel Set, angle grinder",
            "NormTermName": "Roughing Wheel Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "07919": {
            "GenartName": "Pressing Frame, universal",
            "NormTermName": "Press Frame",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07920": {
            "GenartName": "Needle Set, rear lock removal",
            "NormTermName": "Needle Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Rear Lock Removal"
        },
        "07921": {
            "GenartName": "Spanner Set, toothed belt tensioning",
            "NormTermName": "Key Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Timing Belt Tension"
        },
        "07922": {
            "GenartName": "Socket Wrench Insert, injection line",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injection Pipe"
        },
        "07923": {
            "GenartName": "Clamping Wedge Set, exhaust system",
            "NormTermName": "Clamping Wedge Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Exhaust System"
        },
        "07924": {
            "GenartName": "Socket Wrench Insert, reverser",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Reverse Switch"
        },
        "07925": {
            "GenartName": "Adjustable Clamp, tie rod",
            "NormTermName": "Pliers",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tie Rod"
        },
        "07926": {
            "GenartName": "Track Rod, axial linkage clamp",
            "NormTermName": "Track Rod, axial linkage clamp",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07927": {
            "GenartName": "Installation Tool Set, suspension strut",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Suspension Strut"
        },
        "07928": {
            "GenartName": "Guide Tube, pin wrench",
            "NormTermName": "Guide Tube",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Pin Wrench"
        },
        "07929": {
            "GenartName": "Universal Axle Lever",
            "NormTermName": "Universal Axle Lever",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "07930": {
            "GenartName": "Ultrasonic Leak Detector",
            "NormTermName": "Ultrasonic Leak Detector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "07931": {
            "GenartName": "Diagnostics Set, tank replacement",
            "NormTermName": "Diagnostics Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tank Replacement"
        },
        "07932": {
            "GenartName": "Oil Filter Wrench, direct shift transmission",
            "NormTermName": "Key",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Direct Shift Transmission"
        },
        "07933": {
            "GenartName": "Adapter Set, ratchet ring spanner",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Ring Spanner"
        },
        "07934": {
            "GenartName": "Electrical Tool Set",
            "NormTermName": "Electrical Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07935": {
            "GenartName": "Socket Wrench, control switch-differential lock",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Control Switch, differential lock"
        },
        "07936": {
            "GenartName": "Tool Set, spring lock",
            "NormTermName": "Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Lock"
        },
        "07937": {
            "GenartName": "Socket Wrench Insert, spring bolt",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Bolt"
        },
        "07938": {
            "GenartName": "Adapter, ratchet ring wrench",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Socket Wrench"
        },
        "07939": {
            "GenartName": "Clamping and Adjusting Device, suspension strut",
            "NormTermName": "Clamping and Adjusting Device",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Suspension Strut"
        },
        "07940": {
            "GenartName": "Clamping Screw, flaring tool",
            "NormTermName": "Clamping Screw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bordering Tool"
        },
        "07941": {
            "GenartName": "Spindle Screw, flaring tool",
            "NormTermName": "Spindle Screw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bordering Tool"
        },
        "07942": {
            "GenartName": "Repair Kit, workshop mirror",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Workshop Mirror"
        },
        "07943": {
            "GenartName": "Workshop Mirror Set",
            "NormTermName": "Workshop Mirror Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07944": {
            "GenartName": "Regulator, impact screwdriver",
            "NormTermName": "Regulator",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07945": {
            "GenartName": "Retaining Ring, mechanism housing (impact screwdriver)",
            "NormTermName": "Retaining Ring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Mechanism Housing (impact screwdriver)"
        },
        "07946": {
            "GenartName": "Handle, impact screwdriver",
            "NormTermName": "Handle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07947": {
            "GenartName": "Retaining Plate, mechanism housing (impact screwdriver)",
            "NormTermName": "Retaining Plate",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Mechanism Housing (impact screwdriver)"
        },
        "07948": {
            "GenartName": "Bearing Plate, impact screwdriver",
            "NormTermName": "Mounting",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "07949": {
            "GenartName": "Oscillation Unit, windscreen separation device",
            "NormTermName": "Oscillation Unit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Windscreen Cut-out Device"
        },
        "07950": {
            "GenartName": "Brush/Nozzle Set, suction/blast gun",
            "NormTermName": "Brush/Nozzle Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Suction/Blast Gun"
        },
        "07951": {
            "GenartName": "Hose Set, suction/blast gun",
            "NormTermName": "Hose Line Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Suction/Blast Gun"
        },
        "07952": {
            "GenartName": "Deflector, suction/blast gun",
            "NormTermName": "Deflector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Suction/Blast Gun"
        },
        "07953": {
            "GenartName": "Socket wrench insert, ratchet ring wrench",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ratchet Ring Spanner"
        },
        "07954": {
            "GenartName": "Tool Set, trailer hitch installation",
            "NormTermName": "Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Trailer Hitch Installation"
        },
        "07955": {
            "GenartName": "Gauge, cutting angle",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cutting Angle"
        },
        "07956": {
            "GenartName": "Splitting Wedge",
            "NormTermName": "Splitting Wedge",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07957": {
            "GenartName": "Post Driving Hammer",
            "NormTermName": "Post Driving Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07958": {
            "GenartName": "Draw Knife",
            "NormTermName": "Draw Knife",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07959": {
            "GenartName": "Shaft Set",
            "NormTermName": "Shaft Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07960": {
            "GenartName": "Sickle",
            "NormTermName": "Sickle",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07961": {
            "GenartName": "Brush Hook",
            "NormTermName": "Brush Hook",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07962": {
            "GenartName": "Pickaroon",
            "NormTermName": "Pickaroon",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07963": {
            "GenartName": "Peavey",
            "NormTermName": "Peavey",
            "AssGrpName": "Tool, universal",
            "UsageName": "Logging Tool"
        },
        "07964": {
            "GenartName": "Timber Grabs",
            "NormTermName": "Timber Grabs",
            "AssGrpName": "Tool, universal",
            "UsageName": "Logging Tool"
        },
        "07965": {
            "GenartName": "Turning Hook",
            "NormTermName": "Turning Hook",
            "AssGrpName": "Tool, universal",
            "UsageName": "Logging Tool"
        },
        "07966": {
            "GenartName": "Draw Hook",
            "NormTermName": "Draw Hook",
            "AssGrpName": "Tool, universal",
            "UsageName": "Logging Tool"
        },
        "07967": {
            "GenartName": "Load Hooks",
            "NormTermName": "Load Hooks",
            "AssGrpName": "Tool, universal",
            "UsageName": "Logging Tool"
        },
        "07968": {
            "GenartName": "Bark Remover",
            "NormTermName": "Bark Remover",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07969": {
            "GenartName": "Eccentric Angle Bending Device",
            "NormTermName": "Eccentric Angle Bending Device",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07970": {
            "GenartName": "Bending Form, eccentric angle bending device",
            "NormTermName": "Bending Form",
            "AssGrpName": "Tool, universal",
            "UsageName": "Eccentric Angle Bending Device"
        },
        "07971": {
            "GenartName": "Club Hammer",
            "NormTermName": "Club Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07972": {
            "GenartName": "Glaszier's Hammer",
            "NormTermName": "Glaszier's Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07973": {
            "GenartName": "Boiler Scaling Hammer",
            "NormTermName": "Boiler Scaling Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07974": {
            "GenartName": "Lead Hammer",
            "NormTermName": "Lead Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07975": {
            "GenartName": "Cold Cutting Chisel Hammer",
            "NormTermName": "Cold Cutting Chisel Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07976": {
            "GenartName": "Hot Cutting Chisel Hammer",
            "NormTermName": "Hot Cutting Chisel Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07977": {
            "GenartName": "Drift Hammer",
            "NormTermName": "Drift Hammer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07978": {
            "GenartName": "Rivet Puller/Setter Set",
            "NormTermName": "Rivet Puller/Setter Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "07979": {
            "GenartName": "Universal Joint Set, socket wrench",
            "NormTermName": "Linkage Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Socket Wrench"
        },
        "07980": {
            "GenartName": "Clamp, die grinder",
            "NormTermName": "Clamp",
            "AssGrpName": "Tool, universal",
            "UsageName": "Straight Grinder"
        },
        "07981": {
            "GenartName": "Oilfilter Spanner",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Oil Filter"
        },
        "07982": {
            "GenartName": "Oil/Fuel Filter Wrench",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Oil/Fuel Filter Wrench"
        },
        "07983": {
            "GenartName": "Mounting Sleeve Set, brake disc/-shoe",
            "NormTermName": "Mounting Sleeve Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Disc/Shoe"
        },
        "07984": {
            "GenartName": "Mounting Sleeve Set, brake disc/drum",
            "NormTermName": "Installation Sleeve",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Disc/Shoe"
        },
        "07985": {
            "GenartName": "Adapter Ring, wheel hub",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Hub"
        },
        "07986": {
            "GenartName": "Spring Compressor Set, suspension spring",
            "NormTermName": "Spring Compressor Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Suspension Spring"
        },
        "07987": {
            "GenartName": "Protective Insert, spring compressor pressure plate",
            "NormTermName": "Protective Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spring Compressor"
        },
        "07988": {
            "GenartName": "Protective Insert, spring compressor pressure plate",
            "NormTermName": "Protective Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Compressor"
        },
        "07989": {
            "GenartName": "Carbon Scraper Ring, cylinder lining",
            "NormTermName": "Carbon Scraper Ring",
            "AssGrpName": "Crankcase",
            "UsageName": "Cylinder Sleeve"
        },
        "07990": {
            "GenartName": "Control Unit, steering column switch",
            "NormTermName": "Control Unit",
            "AssGrpName": "Instruments",
            "UsageName": "Steering Column Switch"
        },
        "07991": {
            "GenartName": "Adapter, mounting device (wheel hub/wheel bearing)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "07992": {
            "GenartName": "Repair Kit, window regulator",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Window Regulator"
        },
        "07993": {
            "GenartName": "Repair Kit, headlight",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Lights, universal",
            "UsageName": "Headlight"
        },
        "07994": {
            "GenartName": "Mounting Bracket, bumper",
            "NormTermName": "Holder",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Bumper"
        },
        "07995": {
            "GenartName": "Holder, side marker lights",
            "NormTermName": "Holder",
            "AssGrpName": "Lights",
            "UsageName": "Side Marker Light"
        },
        "07996": {
            "GenartName": "Holder, side marker lights",
            "NormTermName": "Holder",
            "AssGrpName": "Lights, universal",
            "UsageName": "Side Marker Light"
        },
        "07997": {
            "GenartName": "Shifting Gate",
            "NormTermName": "Shifting Gate",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "07998": {
            "GenartName": "Repair Kit, shifting gate",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shifting Gate"
        },
        "07999": {
            "GenartName": "Safety Harness",
            "NormTermName": "Safety Harness",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08000": {
            "GenartName": "Safety Harness, work positioning",
            "NormTermName": "Safety Harness",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Work Positioning"
        },
        "08001": {
            "GenartName": "Cable Set, interior blower switch",
            "NormTermName": "Cable Set",
            "AssGrpName": "Heating/Ventilation, universal",
            "UsageName": "Interior Blower Switch"
        },
        "08002": {
            "GenartName": "Screw Set, camshaft bearing block",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Bearing Block"
        },
        "08003": {
            "GenartName": "Cable Repair Set, front fog light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Front Fog Light"
        },
        "08004": {
            "GenartName": "Cable Repair Set, front fog light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Front Fog Light"
        },
        "08005": {
            "GenartName": "Cable Repair Set, brake fluid reservoir sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Fluid Reservoir Sensor"
        },
        "08006": {
            "GenartName": "Cable Repair Set, brake fluid reservoir sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Fluid Reservoir Sensor"
        },
        "08007": {
            "GenartName": "Cover, steering wheel",
            "NormTermName": "Cover",
            "AssGrpName": "Steering",
            "UsageName": "Steering Wheel"
        },
        "08008": {
            "GenartName": "Bearing, crankcase ventilation valve",
            "NormTermName": "Mounting",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase Ventilation Valve"
        },
        "08009": {
            "GenartName": "Seal Ring, coolant hose",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Hose"
        },
        "08010": {
            "GenartName": "Oil Jet, cam lubrication",
            "NormTermName": "Nozzle",
            "AssGrpName": "Lubrication",
            "UsageName": "Cam Lobe Lubrication"
        },
        "08011": {
            "GenartName": "Oil Dipstick, automatic transmission",
            "NormTermName": "Dipstick",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "08012": {
            "GenartName": "Oil Dipstick, manual transmission",
            "NormTermName": "Dipstick",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "08013": {
            "GenartName": "Locking Pin, auto. trans. dipstick sealing piece",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "08014": {
            "GenartName": "Screw, engine mount support",
            "NormTermName": "Screw",
            "AssGrpName": "Engine Mounting System",
            "UsageName": "Engine Mount Support"
        },
        "08015": {
            "GenartName": "Puller Set, camshaft hub",
            "NormTermName": "Puller Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft Hub"
        },
        "08016": {
            "GenartName": "Puller Set, camshaft hub",
            "NormTermName": "Puller Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Camshaft Hub"
        },
        "08017": {
            "GenartName": "Tool Set, timing chain",
            "NormTermName": "Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Timing Chain"
        },
        "08018": {
            "GenartName": "Retaining Tool, water pump",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Water Pump"
        },
        "08019": {
            "GenartName": "Arrestor Spannel, water pump",
            "NormTermName": "Arrester Spanner",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Water Pump"
        },
        "08020": {
            "GenartName": "Cable Repair Set, injection pump shut-off valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump Shut-off Valve"
        },
        "08021": {
            "GenartName": "Cable Repair Set, tailgate",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Electric Supply, universal",
            "UsageName": "Tailgate"
        },
        "08022": {
            "GenartName": "Cable Repair Set, door",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Electric Supply, universal",
            "UsageName": "Door"
        },
        "08023": {
            "GenartName": "Cable Repair Set, boot lid",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Electric Supply, universal",
            "UsageName": "Boot Lid"
        },
        "08024": {
            "GenartName": "Pressure Sensor, master brake cylinder",
            "NormTermName": "Sensor",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Master Cylinder"
        },
        "08025": {
            "GenartName": "Taillight Cover",
            "NormTermName": "Cover",
            "AssGrpName": "Lights, universal",
            "UsageName": "Combination Rear Light"
        },
        "08026": {
            "GenartName": "Marker Light Set",
            "NormTermName": "Light Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Clearance Light"
        },
        "08027": {
            "GenartName": "Cable Repair Set, controller (heating/ventilation)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Controller (heating/ventilation)"
        },
        "08028": {
            "GenartName": "Cable Repair Set, controller (heating/ventilation)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation, universal",
            "UsageName": "Controller (heating/ventilation)"
        },
        "08029": {
            "GenartName": "Cable Repair Set, exhaust gas pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Exhaust Pressure Sensor"
        },
        "08030": {
            "GenartName": "Cable Repair Set, exhaust gas pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Exhaust Pressure Sensor"
        },
        "08031": {
            "GenartName": "Expansion Set, locking tool (control times)",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Locking Tool (control times)"
        },
        "08032": {
            "GenartName": "Expansion Set, belt pulley puller",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Belt Pulley Puller"
        },
        "08033": {
            "GenartName": "Draw-in Tool Set, drive shaft",
            "NormTermName": "Setter Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Drive Shaft"
        },
        "08034": {
            "GenartName": "Thread Cutting Tool Set, drive shaft",
            "NormTermName": "Thread Cutter Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Drive Shaft"
        },
        "08035": {
            "GenartName": "Holder, battery charger",
            "NormTermName": "Bracket",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Battery Charger"
        },
        "08036": {
            "GenartName": "Bollard Set",
            "NormTermName": "Bollard Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08037": {
            "GenartName": "Adapter, trolley (battery charger / starter)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Trolley, battery charger/starter"
        },
        "08038": {
            "GenartName": "Clamping Pliers, jump starter",
            "NormTermName": "Pliers",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Jump Starter"
        },
        "08039": {
            "GenartName": "Press-out Tool, wheel (CV)",
            "NormTermName": "Press-out Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel"
        },
        "08040": {
            "GenartName": "Battery, jump starter",
            "NormTermName": "Battery",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Jump Starter"
        },
        "08041": {
            "GenartName": "Protective Sleeve, battery charger",
            "NormTermName": "Protective Sleeve",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Battery Charger"
        },
        "08042": {
            "GenartName": "Threaded Pins, draw-in tool (drive shaft)",
            "NormTermName": "Bolt",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Draw-in Tool (drive shaft)"
        },
        "08043": {
            "GenartName": "Printer, battery test device",
            "NormTermName": "Printer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Battery Tester"
        },
        "08044": {
            "GenartName": "Office Chair",
            "NormTermName": "Office Chair",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Equipment"
        },
        "08045": {
            "GenartName": "Charge Indicator, battery charger",
            "NormTermName": "Charge Indicator",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Battery Charger"
        },
        "08046": {
            "GenartName": "Extension Cable, battery charger",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Battery Charger"
        },
        "08047": {
            "GenartName": "Voltage Stabiliser, start/stop system",
            "NormTermName": "Regulator",
            "AssGrpName": "Starter System",
            "UsageName": "Start/stop System"
        },
        "08048": {
            "GenartName": "Draw-in Tool Set, wheel bolts",
            "NormTermName": "Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Bolt"
        },
        "08049": {
            "GenartName": "Adapter, draw-in tool (wheel stud)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Bolt Draw-in Aid"
        },
        "08050": {
            "GenartName": "Retainer, fuel filter",
            "NormTermName": "Retainer",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Fuel Filter"
        },
        "08051": {
            "GenartName": "Wheel Removal Tool",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Rim"
        },
        "08052": {
            "GenartName": "Counter Plate, wheel bolt pull-in aid",
            "NormTermName": "Counter Plate",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Removal Tool"
        },
        "08053": {
            "GenartName": "Hook, wheel removal tool",
            "NormTermName": "Hook",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Removal Tool"
        },
        "08054": {
            "GenartName": "Retainer, draw-on tool (wheel stud)",
            "NormTermName": "Arrester",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Draw-in Tool (wheel stud)"
        },
        "08055": {
            "GenartName": "Assortment, air conditioning line repairs",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Climate Control Hose"
        },
        "08056": {
            "GenartName": "Centring Mount, draw-in tool (wheel stud)",
            "NormTermName": "Centering Device",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Draw-in Tool (wheel stud)"
        },
        "08057": {
            "GenartName": "Clamping Sleeve, draw-in tool (drive shaft)",
            "NormTermName": "Sleeve",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Draw-in Tool (drive shaft)"
        },
        "08058": {
            "GenartName": "Clamping Nut, draw-in tool (wheel stud)",
            "NormTermName": "Nut",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Draw-in Tool (wheel stud)"
        },
        "08059": {
            "GenartName": "Traffic Cone",
            "NormTermName": "Traffic Cone",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08060": {
            "GenartName": "Fan",
            "NormTermName": "Fan",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08061": {
            "GenartName": "Drive-on Ramp Set",
            "NormTermName": "Drive-on Ramp Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08062": {
            "GenartName": "Dent Repair Set",
            "NormTermName": "Dent Repair Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08063": {
            "GenartName": "Removal Tool, combination rear light",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Combination Rear Light"
        },
        "08064": {
            "GenartName": "Assortment, O-rings",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "O-Ring"
        },
        "08065": {
            "GenartName": "Quick Contact Cable, battery charger",
            "NormTermName": "Quick Contact Cable",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Battery Charger"
        },
        "08066": {
            "GenartName": "Clamp Cable, battery charger",
            "NormTermName": "Clamp Cable",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Battery Charger"
        },
        "08067": {
            "GenartName": "Removal Tool Set, track rod ball joint",
            "NormTermName": "Removal Kit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Track Rod Ball Joint"
        },
        "08068": {
            "GenartName": "Universal Support Block",
            "NormTermName": "Universal Support Block",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08069": {
            "GenartName": "Soft Toy",
            "NormTermName": "Soft Toy",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08070": {
            "GenartName": "Locking Mandrel, water pump",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Water Pump"
        },
        "08071": {
            "GenartName": "Locking Mandrel, water pump",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Water Pump"
        },
        "08072": {
            "GenartName": "Spring Safety, angled lever latch",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Trailer",
            "UsageName": "Angled Lever Latch"
        },
        "08073": {
            "GenartName": "Locking Eyes, angled lever latch",
            "NormTermName": "Locking Eyes",
            "AssGrpName": "Trailer",
            "UsageName": "Angled Lever Latch"
        },
        "08074": {
            "GenartName": "Spring Safety, pendulum latch",
            "NormTermName": "Securing Elements",
            "AssGrpName": "Trailer",
            "UsageName": "Pendulum Latch"
        },
        "08075": {
            "GenartName": "Safety Cord, angled lever latch",
            "NormTermName": "Safety Cord",
            "AssGrpName": "Trailer",
            "UsageName": "Angled Lever Latch"
        },
        "08076": {
            "GenartName": "Grouting Rubber",
            "NormTermName": "Grouting Rubber",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "08077": {
            "GenartName": "Grouting Sponge",
            "NormTermName": "Grouting Sponge",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "08078": {
            "GenartName": "Sand Sieve",
            "NormTermName": "Strainer",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "08079": {
            "GenartName": "Plumb Bob",
            "NormTermName": "Plumb Bob",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "08080": {
            "GenartName": "Marking Cord",
            "NormTermName": "Marking Cord",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "08081": {
            "GenartName": "Marking Powder",
            "NormTermName": "Marking Powder",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "08082": {
            "GenartName": "Pencil",
            "NormTermName": "Pencil",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08083": {
            "GenartName": "Masonry Hook",
            "NormTermName": "Masonry Hook",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "08084": {
            "GenartName": "Shovel",
            "NormTermName": "Shovel",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "08085": {
            "GenartName": "Dustpan",
            "NormTermName": "Dustpan",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08086": {
            "GenartName": "Broom/Shovel Handle",
            "NormTermName": "Broom/shovel Handle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08087": {
            "GenartName": "Rake",
            "NormTermName": "Rake",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "08088": {
            "GenartName": "Fuel Sensor, ethanol-petrol ratio",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Ethanol-Petrol Ratio"
        },
        "08089": {
            "GenartName": "Hose, heat exchanger (heating)",
            "NormTermName": "Hose Line",
            "AssGrpName": "Heating/Ventilation, universal",
            "UsageName": "Heat Exchanger"
        },
        "08090": {
            "GenartName": "Hoseline Set, leakage fuel",
            "NormTermName": "Hose Line Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Leak Fuel"
        },
        "08091": {
            "GenartName": "Auxiliary Heating, driver cab",
            "NormTermName": "Auxiliary Heating",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab"
        },
        "08092": {
            "GenartName": "Underrun Protection",
            "NormTermName": "Underrun Protection",
            "AssGrpName": "Body",
            "UsageName": "CV (commercial vehicles)"
        },
        "08093": {
            "GenartName": "Spring, bonnet lock fitting",
            "NormTermName": "Spring",
            "AssGrpName": "Body",
            "UsageName": "Bonnet Lock Fitting"
        },
        "08094": {
            "GenartName": "Chain Cleaner",
            "NormTermName": "Chain Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "08095": {
            "GenartName": "Transmission Line Cutters",
            "NormTermName": "Transmission Line Cutters",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08096": {
            "GenartName": "Tool Strap",
            "NormTermName": "Strap",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tools"
        },
        "08097": {
            "GenartName": "Tool Lanyard",
            "NormTermName": "Safety Cable",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tools"
        },
        "08098": {
            "GenartName": "Blade, cable cutters",
            "NormTermName": "Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable Shears"
        },
        "08099": {
            "GenartName": "Blade Set, cable cutters",
            "NormTermName": "Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable Shears"
        },
        "08100": {
            "GenartName": "Spring, cable stripping pliers",
            "NormTermName": "Spring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable stripper"
        },
        "08101": {
            "GenartName": "Cutter Head, cable cutters",
            "NormTermName": "Cutter Head",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wire Rope Cutter"
        },
        "08102": {
            "GenartName": "Mesh Cutters",
            "NormTermName": "Mesh Cutters",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "08103": {
            "GenartName": "Cutter Head, mesh cutters",
            "NormTermName": "Cutter Head",
            "AssGrpName": "Building Tools",
            "UsageName": "Mesh Cutters"
        },
        "08104": {
            "GenartName": "Tile-breaking Pliers",
            "NormTermName": "Tile-breaking Pliers",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "08105": {
            "GenartName": "Jaw, tile-breaking pliers",
            "NormTermName": "Jaw",
            "AssGrpName": "Building Tools",
            "UsageName": "Tile-breaking Pliers"
        },
        "08106": {
            "GenartName": "Marking Gauge",
            "NormTermName": "Marking Gauge",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08107": {
            "GenartName": "Thread-cutting Nut",
            "NormTermName": "Thread-cutting Nut",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08108": {
            "GenartName": "Puller, vibration damper (belt drive)",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Vibration Damper (belt drive)"
        },
        "08109": {
            "GenartName": "Safety Hook, bonnet lock",
            "NormTermName": "Safety Hook",
            "AssGrpName": "Body",
            "UsageName": "Bonnet Lock"
        },
        "08110": {
            "GenartName": "Strippers",
            "NormTermName": "Strippers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08111": {
            "GenartName": "Calibration Mandrel, compression tool, plug connector",
            "NormTermName": "Calibration Mandrel",
            "AssGrpName": "Tool, universal",
            "UsageName": "Compression Tool, plug connector"
        },
        "08112": {
            "GenartName": "Tool Lanyard Set",
            "NormTermName": "Tool Lanyard Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tools"
        },
        "08113": {
            "GenartName": "Pliers Wrench Set",
            "NormTermName": "Pliers Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pliers Wrench"
        },
        "08114": {
            "GenartName": "Die, punch lock riveter",
            "NormTermName": "Die",
            "AssGrpName": "Tool, universal",
            "UsageName": "Punch Lock Riveters"
        },
        "08115": {
            "GenartName": "Inserts, hose clip pliers",
            "NormTermName": "Inserts",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hose Clamp Pliers"
        },
        "08116": {
            "GenartName": "Adapter Plate, footrest system",
            "NormTermName": "Adapter Plate",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Footrest System (motorcycle)"
        },
        "08117": {
            "GenartName": "Selector Rod Extension, footrest system",
            "NormTermName": "Selector Rod Extension",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Footrest System (motorcycle)"
        },
        "08118": {
            "GenartName": "Foam Insert, assortment box",
            "NormTermName": "Foam Insert",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Assortment Box"
        },
        "08119": {
            "GenartName": "Friction Wheel, water pump",
            "NormTermName": "Friction Wheel",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "08120": {
            "GenartName": "Cable Repair Set, cooling fan temperature switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling System",
            "UsageName": "Cooling Fan Temperature Switch"
        },
        "08121": {
            "GenartName": "Cable Repair Set, cooling fan temperature switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Cooling Fan Temperature Switch"
        },
        "08122": {
            "GenartName": "Cable Repair Set, daytime running light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Daytime Running Light"
        },
        "08123": {
            "GenartName": "Cable Repair Set, daytime running light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Daytime Running Light"
        },
        "08124": {
            "GenartName": "Cable repair set, control unit (battery management)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Central Electrics",
            "UsageName": "Sensor (battery management)"
        },
        "08125": {
            "GenartName": "Cable repair set, control unit (battery management)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Electric Supply, universal",
            "UsageName": "Sensor (battery management)"
        },
        "08126": {
            "GenartName": "Cable Repair Set, outside temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Outside Temperature Sensor"
        },
        "08127": {
            "GenartName": "Selector Rod, footrest system",
            "NormTermName": "Selector Rod",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Footrest System (motorcycle)"
        },
        "08128": {
            "GenartName": "Bleed Nipple, brake sys bleeding, collection container",
            "NormTermName": "Nipple",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake system bleeding, collection container"
        },
        "08129": {
            "GenartName": "Bleed Nipple Set, brake sys bleeding, collection container",
            "NormTermName": "Nipple Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake system bleeding, collection container"
        },
        "08130": {
            "GenartName": "Adapter Plate, foot brake cylinder",
            "NormTermName": "Adapter Plate",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Foot Brake Cylinder"
        },
        "08131": {
            "GenartName": "Footrest Stopper",
            "NormTermName": "Plug",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Footrest (motorcycle)"
        },
        "08132": {
            "GenartName": "Pawl, gearshift/brake lever (footrest system)",
            "NormTermName": "Carrier",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Gearshift/Brake Lever (footrest system)"
        },
        "08133": {
            "GenartName": "Spherical Head, selector rod (footrest system)",
            "NormTermName": "Ball Head",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Selector Rod ( footrest system)"
        },
        "08134": {
            "GenartName": "Spacer, clamp (fork tube / steering shaft mount)",
            "NormTermName": "Sleeve",
            "AssGrpName": "Steering",
            "UsageName": "Clamp (fork tube / steering shaft mount / 2-wheeled vehicle)"
        },
        "08135": {
            "GenartName": "Clamp, fork tube (steering shaft mount)",
            "NormTermName": "Clamp",
            "AssGrpName": "Steering",
            "UsageName": "Fork Tube (steering shaft mount / 2-wheeled vehicle)"
        },
        "08136": {
            "GenartName": "Mounting Set, brake disc",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Disc (motorcycle)"
        },
        "08137": {
            "GenartName": "Adapter Mount, brake fluid reservoir",
            "NormTermName": "Adapter Mount",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Fluid Reservoir (2-wheeled vehicle)"
        },
        "08138": {
            "GenartName": "Cable Set, interior blower switch",
            "NormTermName": "Cable Set",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Blower Switch"
        },
        "08139": {
            "GenartName": "Mount set, hydraulic cylinder (workshop press)",
            "NormTermName": "Holder Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Hydraulic cylinder"
        },
        "08140": {
            "GenartName": "Cleaning paper roll",
            "NormTermName": "Cleaning paper roll",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08141": {
            "GenartName": "Scrubber",
            "NormTermName": "Scrubber",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08142": {
            "GenartName": "Hand brush",
            "NormTermName": "Hand brush",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08143": {
            "GenartName": "Brush set",
            "NormTermName": "Brush set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08144": {
            "GenartName": "Ladder",
            "NormTermName": "Ladder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08145": {
            "GenartName": "Repair Kit, automatic transmission",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "08146": {
            "GenartName": "Transmission cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Transmission"
        },
        "08147": {
            "GenartName": "Housing, air filter",
            "NormTermName": "Housing",
            "AssGrpName": "Air Supply",
            "UsageName": "Air Filter"
        },
        "08148": {
            "GenartName": "Additive, oil-loss stop",
            "NormTermName": "Additive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Oil-loss stop"
        },
        "08149": {
            "GenartName": "Hydraulic-valve-lifter additive",
            "NormTermName": "Additive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Hydraulic Tappet"
        },
        "08150": {
            "GenartName": "Additive, soot/particulate filter regeneration",
            "NormTermName": "Additive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Soot/Particulate Filter Regeneration"
        },
        "08151": {
            "GenartName": "Throttle-valve cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Throttle Valve"
        },
        "08152": {
            "GenartName": "Ventilation tool set, fuel system",
            "NormTermName": "Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Fuel system"
        },
        "08153": {
            "GenartName": "Puller set, injector nozzle",
            "NormTermName": "Puller Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector Nozzle"
        },
        "08154": {
            "GenartName": "Expansion set, injector extractor",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector extractor"
        },
        "08155": {
            "GenartName": "Drive set, injector extractor",
            "NormTermName": "Drive Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injector extractor"
        },
        "08156": {
            "GenartName": "Hydraulic pump",
            "NormTermName": "Hydraulic pump",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08157": {
            "GenartName": "Impact Extractor, common rail injector",
            "NormTermName": "Impact Extractor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Common Rail Injector"
        },
        "08158": {
            "GenartName": "Impact puller set, pre-chamber",
            "NormTermName": "Impact Extractor Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Pre-chamber"
        },
        "08159": {
            "GenartName": "Impact puller, pre-chamber",
            "NormTermName": "Impact Extractor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Pre-chamber"
        },
        "08160": {
            "GenartName": "Cleaning/Milling Tool Set, CR injector seat",
            "NormTermName": "Cleaning/Milling Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "CR Injector Seat"
        },
        "08161": {
            "GenartName": "Base plate, footrest system",
            "NormTermName": "Base Plate",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Footrest System (motorcycle)"
        },
        "08162": {
            "GenartName": "Puller set, glow plug pin",
            "NormTermName": "Puller Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injector sleeve"
        },
        "08163": {
            "GenartName": "Extension, cleaning/milling tool (CR injector shaft)",
            "NormTermName": "Extension",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Cleaning/milling tool (CR injector shaft)"
        },
        "08164": {
            "GenartName": "Puller, ignition coil plug",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ignition Coil Plug"
        },
        "08165": {
            "GenartName": "Puller Set, spark plug connector",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ignition Coil Plug"
        },
        "08166": {
            "GenartName": "Puller, ignition coil unit",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ignition Coil Unit"
        },
        "08167": {
            "GenartName": "Puller, ignition coil unit",
            "NormTermName": "Puller",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ignition Coil Unit"
        },
        "08168": {
            "GenartName": "Reaming head, cleaning/milling tool set (CR injector shaft)",
            "NormTermName": "Reaming head",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Cleaning/milling tool set (CR injector shaft"
        },
        "08169": {
            "GenartName": "Release tool set, fuel line",
            "NormTermName": "Release Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Fuel Line"
        },
        "08170": {
            "GenartName": "Expansion Set, glow-plug removal tool",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow-plug removal tool"
        },
        "08171": {
            "GenartName": "Compressed air adapter, injector bore",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector Bore"
        },
        "08172": {
            "GenartName": "Guide bushing set, glow plug removal tool",
            "NormTermName": "Guide bushing set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow-plug removal tool"
        },
        "08173": {
            "GenartName": "Reamer set, glow plugs",
            "NormTermName": "Reamer Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow Plug"
        },
        "08174": {
            "GenartName": "Air filter kit",
            "NormTermName": "Filter Set",
            "AssGrpName": "Air Supply",
            "UsageName": "Air"
        },
        "08175": {
            "GenartName": "Filter Set, interior air",
            "NormTermName": "Filter Set",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Air"
        },
        "08176": {
            "GenartName": "Filter Set, interior air",
            "NormTermName": "Filter Set",
            "AssGrpName": "Heating/Ventilation, universal",
            "UsageName": "Interior Air"
        },
        "08177": {
            "GenartName": "Fuel filter set",
            "NormTermName": "Filter Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel"
        },
        "08178": {
            "GenartName": "Fuel filter set",
            "NormTermName": "Filter Set",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Fuel"
        },
        "08179": {
            "GenartName": "Motorcycle Wheel-Free Jack",
            "NormTermName": "Motorcycle Wheel-Free Jack",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08180": {
            "GenartName": "Tractor Separating Trolley",
            "NormTermName": "Tractor Separating Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08181": {
            "GenartName": "Mounting Aid, tyre fitting machine",
            "NormTermName": "Mounting Aid",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tyre Fitting Maschine"
        },
        "08182": {
            "GenartName": "Pressing Plate, wheel balancing machine",
            "NormTermName": "Pressing Plate",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wheel Balancing Machine"
        },
        "08183": {
            "GenartName": "Tyre Bead Breaker",
            "NormTermName": "Tyre Bead Breaker",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08184": {
            "GenartName": "Air Canon",
            "NormTermName": "Air Canon",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08185": {
            "GenartName": "Tyre Inflation Cage",
            "NormTermName": "Tyre Inflation Cage",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08186": {
            "GenartName": "Generators",
            "NormTermName": "Generators",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08187": {
            "GenartName": "Power Strip",
            "NormTermName": "Power Strip",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08188": {
            "GenartName": "Scrubbing Machine",
            "NormTermName": "Scrubbing Machine",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08189": {
            "GenartName": "Automatic Parts Washing Machine",
            "NormTermName": "Automatic Parts Washing Machine",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08190": {
            "GenartName": "Water Jet Cutter",
            "NormTermName": "Water Jet Cutter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08191": {
            "GenartName": "Scaffolding",
            "NormTermName": "Scaffolding",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08192": {
            "GenartName": "Spot-Blasting Unit",
            "NormTermName": "Spot-Blasting Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08193": {
            "GenartName": "Turning Machine, brake disc",
            "NormTermName": "Turning Machine",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake Disc"
        },
        "08194": {
            "GenartName": "Wheel Preparation System",
            "NormTermName": "Preparation System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Rims"
        },
        "08195": {
            "GenartName": "Turning Machine, brake lining",
            "NormTermName": "Turning Machine",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Brake Pad/Brake Lining"
        },
        "08196": {
            "GenartName": "Wheel Protection, tyre fitting",
            "NormTermName": "Wheel Protection",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tyre Fitting"
        },
        "08197": {
            "GenartName": "Bending Press",
            "NormTermName": "Bending Press",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08198": {
            "GenartName": "Turning Machine",
            "NormTermName": "Turning Machine",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08199": {
            "GenartName": "Table Trolley",
            "NormTermName": "Table Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08200": {
            "GenartName": "Laser Cutting System",
            "NormTermName": "Laser Cutting System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08201": {
            "GenartName": "Control Unit, blind spot detection",
            "NormTermName": "Control Unit",
            "AssGrpName": "Safety Systems",
            "UsageName": "Blind Spot Detection"
        },
        "08202": {
            "GenartName": "Warm Air Heater",
            "NormTermName": "Warm Air Heater",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08203": {
            "GenartName": "Electrical Heater",
            "NormTermName": "Electrical Heater",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08204": {
            "GenartName": "Control Unit, lane change assistant",
            "NormTermName": "Control Unit",
            "AssGrpName": "Safety Systems",
            "UsageName": "Lane Change Assistant"
        },
        "08205": {
            "GenartName": "Eye Rinsing Bottle",
            "NormTermName": "Eye Rinsing Bottle",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08206": {
            "GenartName": "Plaster Dispenser",
            "NormTermName": "Plaster Dispenser",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08207": {
            "GenartName": "Spray Plaster",
            "NormTermName": "Spray Plaster",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08208": {
            "GenartName": "Emergency Safety Shower",
            "NormTermName": "Emergency Safety Shower",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08209": {
            "GenartName": "Bandage/Medicine Cabinet",
            "NormTermName": "Cabinet",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Bandages/Medicine"
        },
        "08210": {
            "GenartName": "Stretcher",
            "NormTermName": "Stretcher",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08211": {
            "GenartName": "Flange Set, exhaust pipe",
            "NormTermName": "Flange Set",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Pipe"
        },
        "08212": {
            "GenartName": "Flange Set, exhaust pipe",
            "NormTermName": "Flange Set",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Exhaust Pipe"
        },
        "08213": {
            "GenartName": "Thermal Imaging Camera",
            "NormTermName": "Thermal Imaging Camera",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08214": {
            "GenartName": "Stepped Wrench",
            "NormTermName": "Stepped Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08216": {
            "GenartName": "Pullover",
            "NormTermName": "Pullover",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08217": {
            "GenartName": "Cap",
            "NormTermName": "Cap",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08218": {
            "GenartName": "Emergency Eye Treatment Station",
            "NormTermName": "Emergency Eye Treatment Station",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08219": {
            "GenartName": "Wall Mounting, fire extinguisher",
            "NormTermName": "Bracket",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Fire Extinguisher"
        },
        "08220": {
            "GenartName": "Motor Vehicle Mount, fire extinguisher",
            "NormTermName": "Bracket",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Fire Extinguisher"
        },
        "08221": {
            "GenartName": "Protective Cover, fire extinguisher",
            "NormTermName": "Protective Cover",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Fire Extinguisher"
        },
        "08222": {
            "GenartName": "Fire Blanket",
            "NormTermName": "Fire Blanket",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08223": {
            "GenartName": "First Aid Station",
            "NormTermName": "First Aid Station",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08224": {
            "GenartName": "First Aid Box",
            "NormTermName": "First Aid Box",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08225": {
            "GenartName": "Fastening Set, oil cooler",
            "NormTermName": "Fastening Set",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Cooler"
        },
        "08226": {
            "GenartName": "Light Bulb, ashtray",
            "NormTermName": "Bulb",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Ashtray"
        },
        "08227": {
            "GenartName": "Ring, differential stub shaft",
            "NormTermName": "Ring",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Differential Stub Shaft"
        },
        "08228": {
            "GenartName": "Holder, oil cooler (steering)",
            "NormTermName": "Bracket",
            "AssGrpName": "Steering",
            "UsageName": "Oil Cooler"
        },
        "08229": {
            "GenartName": "Repair Sleeve, crankshaft",
            "NormTermName": "Repair Sleeve",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft"
        },
        "08230": {
            "GenartName": "Washer, valve spring seat",
            "NormTermName": "Washer",
            "AssGrpName": "Engine Timing",
            "UsageName": null
        },
        "08231": {
            "GenartName": "Oil Cooler Line, steering",
            "NormTermName": "Oil Cooler Line",
            "AssGrpName": "Steering",
            "UsageName": "Steering"
        },
        "08232": {
            "GenartName": "Frost Plug Set",
            "NormTermName": "Frost Plug Set",
            "AssGrpName": "Crankcase",
            "UsageName": null
        },
        "08233": {
            "GenartName": "Connecting Element, oil cooler hose (automatic transmission)",
            "NormTermName": "Connector",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil Cooler Hose (automatic transmission)"
        },
        "08234": {
            "GenartName": "Seal Set, steering arm",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Steering",
            "UsageName": "Steering Arm"
        },
        "08235": {
            "GenartName": "Conversion Kit, electronic ignition",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Ignition System",
            "UsageName": "Electronic Ignition"
        },
        "08236": {
            "GenartName": "Starting Safety Switch, automatic transmission",
            "NormTermName": "Switch",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "08237": {
            "GenartName": "Carbon Brush Set, alternator",
            "NormTermName": "Carbon Brush Set",
            "AssGrpName": "Alternator",
            "UsageName": "Alternator"
        },
        "08238": {
            "GenartName": "Radiator Set, engine cooling",
            "NormTermName": "Radiator Set",
            "AssGrpName": "Cooling System",
            "UsageName": "Engine Cooling"
        },
        "08239": {
            "GenartName": "Repair Sleeve, torque converter",
            "NormTermName": "Sleeve",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Torque Converter"
        },
        "08240": {
            "GenartName": "Cable Repair Set, oil pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Instruments",
            "UsageName": "Oil Pressure Sensor"
        },
        "08241": {
            "GenartName": "Test Unit, fuel filler cap",
            "NormTermName": "Tester",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Fuel Filler Cap"
        },
        "08242": {
            "GenartName": "Adapter, tank cover test unit",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Tank Cover Test Unit"
        },
        "08243": {
            "GenartName": "Repair Sleeve, trans. input shaft (manual transmission)",
            "NormTermName": "Repair Sleeve",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Input Shaft"
        },
        "08244": {
            "GenartName": "Repair Sleeve, vibration damper (belt drive)",
            "NormTermName": "Repair Sleeve",
            "AssGrpName": "Belt Drive",
            "UsageName": "Vibration Damper (belt drive)"
        },
        "08245": {
            "GenartName": "Seal Ring, torque converter",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Torque Converter"
        },
        "08246": {
            "GenartName": "Protective Cap, ignition coil unit",
            "NormTermName": "Cap",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition Coil Unit"
        },
        "08247": {
            "GenartName": "Repair Kit, hydraulic pump (steering)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Steering",
            "UsageName": "Hydraulic Pump (steering)"
        },
        "08248": {
            "GenartName": "Bearing, differential stub shaft",
            "NormTermName": "Bearing",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Differential Stub Shaft"
        },
        "08249": {
            "GenartName": "Repair Sleeve, differential stub shaft",
            "NormTermName": "Repair Sleeve",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Differential Stub Shaft"
        },
        "08250": {
            "GenartName": "Bearing Set, differential stub shaft",
            "NormTermName": "Bearing Kit",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Differential Stub Shaft"
        },
        "08251": {
            "GenartName": "Adjuster Set, drum brake",
            "NormTermName": "Adjuster Set",
            "AssGrpName": "Braking System",
            "UsageName": "Drum Brake"
        },
        "08252": {
            "GenartName": "Relocation Set, oil filter",
            "NormTermName": "Relocation Set",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Filter"
        },
        "08253": {
            "GenartName": "Seal, catalytic converter",
            "NormTermName": "Seal",
            "AssGrpName": "Exhaust System",
            "UsageName": "Catalytic Converter"
        },
        "08254": {
            "GenartName": "Repair Kit, oil pump",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "08255": {
            "GenartName": "Seal Ring, shift rod",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Shift Rod"
        },
        "08256": {
            "GenartName": "Safety Switch, fuel pump",
            "NormTermName": "Switch",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "08257": {
            "GenartName": "Seal, throttle body",
            "NormTermName": "Seal",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Throttle Body"
        },
        "08258": {
            "GenartName": "Cable Repair Set, throttle sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Throttle Sensor"
        },
        "08259": {
            "GenartName": "Bulb Socket, instrument display",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Instruments",
            "UsageName": "Instrument Display"
        },
        "08260": {
            "GenartName": "Plug, main light switch",
            "NormTermName": "Plug",
            "AssGrpName": "Lights",
            "UsageName": "Headlight Switch"
        },
        "08261": {
            "GenartName": "Bulb Socket, park/position light",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Lights",
            "UsageName": "Park-/Position Light"
        },
        "08262": {
            "GenartName": "Clock Bulb",
            "NormTermName": "Bulb",
            "AssGrpName": "Instruments",
            "UsageName": "Clock"
        },
        "08263": {
            "GenartName": "Mounting Kit, tank sensor/fuel pump",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Tank Sensor/Fuel Pump"
        },
        "08264": {
            "GenartName": "Repair Sleeve, trans. output shaft (automatic transmission)",
            "NormTermName": "Repair Sleeve",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Transmission Output Shaft (automatic transmission)"
        },
        "08265": {
            "GenartName": "Repair Sleeve, transmission output shaft (transfer case)",
            "NormTermName": "Repair Sleeve",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transmission Output Shaft (transfer case)"
        },
        "08266": {
            "GenartName": "Seal, steering arm",
            "NormTermName": "Seal",
            "AssGrpName": "Steering",
            "UsageName": "Steering Arm"
        },
        "08267": {
            "GenartName": "Locking Ring, fuel pump",
            "NormTermName": "Locking Ring",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "08268": {
            "GenartName": "Nipple, steering hose",
            "NormTermName": "Nipple",
            "AssGrpName": "Steering",
            "UsageName": "Steering Hose"
        },
        "08269": {
            "GenartName": "Repair Sleeve, oil pump (automatic transmission)",
            "NormTermName": "Repair Sleeve",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil Pump (automatic transmission)"
        },
        "08270": {
            "GenartName": "Repair Sleeve, shift rod (automatic transmission)",
            "NormTermName": "Repair Sleeve",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Shift Rod (automatic transmission)"
        },
        "08271": {
            "GenartName": "Radio Display Bulb",
            "NormTermName": "Bulb",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Radio Display"
        },
        "08272": {
            "GenartName": "Piston Set",
            "NormTermName": "Piston Set",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": null
        },
        "08273": {
            "GenartName": "Nut, rocker arm",
            "NormTermName": "Nut",
            "AssGrpName": "Engine Timing",
            "UsageName": "Rocker Arm"
        },
        "08274": {
            "GenartName": "Connection Cable Set, trailer",
            "NormTermName": "Electric Cable Set",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Trailer"
        },
        "08275": {
            "GenartName": "Solenoid Switch, kickdown (automatic transmission)",
            "NormTermName": "Switch",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Kickdown (automatic transmission)"
        },
        "08276": {
            "GenartName": "Sealing Ring, coolant line",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Coolant Line"
        },
        "08277": {
            "GenartName": "Cable Pull, brake drum adjuster",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Braking System",
            "UsageName": "Cable Pull, brake drum adjuster"
        },
        "08278": {
            "GenartName": "Pull Cable Guide, brake drum adjuster",
            "NormTermName": "Guide",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Drum Adjuster"
        },
        "08279": {
            "GenartName": "Mounting Set, vibration damper (timing belt)",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": "Vibration Damper (timing belt)"
        },
        "08280": {
            "GenartName": "Centrifugal Weight, distributor",
            "NormTermName": "Centrifugal Weight",
            "AssGrpName": "Ignition System",
            "UsageName": "Distributor"
        },
        "08281": {
            "GenartName": "Repair Sleeve Set, transmission output shaft (man. trans.)",
            "NormTermName": "Repair Sleeve Set",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Output Shaft (manual transmission)"
        },
        "08282": {
            "GenartName": "Bearing, gear wheel (automatic transmission)",
            "NormTermName": "Bearing",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Gear Wheel (automatic transmission)"
        },
        "08283": {
            "GenartName": "Repair Kit, slave cylinder (power steering)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Steering",
            "UsageName": "Slave Cylinder (power steering)"
        },
        "08284": {
            "GenartName": "Bearing, layshaft (manual transmission)",
            "NormTermName": "Bearing",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Countershaft"
        },
        "08285": {
            "GenartName": "Retaining Ring, drive shaft (oil pump)",
            "NormTermName": "Retaining Ring",
            "AssGrpName": "Lubrication",
            "UsageName": "Drive Shaft (oil pump)"
        },
        "08286": {
            "GenartName": "Shim, caster/camber adjustment",
            "NormTermName": "Washer",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Caster/Camber Adjustment"
        },
        "08287": {
            "GenartName": "Bearing, transmission output shaft (manual transmission)",
            "NormTermName": "Bearing",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Output Shaft (manual transmission)"
        },
        "08288": {
            "GenartName": "Seal Ring, balance shaft",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Crankcase",
            "UsageName": "Balance Shaft"
        },
        "08289": {
            "GenartName": "Sender Unit, fuel tank",
            "NormTermName": "Sender Unit, fuel tank",
            "AssGrpName": "Fuel Supply System",
            "UsageName": null
        },
        "08290": {
            "GenartName": "Bearing, transmission input shaft (manual transmission)",
            "NormTermName": "Bearing",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Input Shaft"
        },
        "08291": {
            "GenartName": "Throttle Valve, refrigerant circuit",
            "NormTermName": "Valve",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Refrigerant Circuit"
        },
        "08292": {
            "GenartName": "Relay, fuel injection",
            "NormTermName": "Relay",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Fuel Injection"
        },
        "08293": {
            "GenartName": "Camshaft Bearing Set",
            "NormTermName": "Bearing Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft"
        },
        "08294": {
            "GenartName": "Shims Set, differential",
            "NormTermName": "Disc Set",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "08295": {
            "GenartName": "Screw, brake drum adjuster",
            "NormTermName": "Screw",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Drum Adjuster"
        },
        "08296": {
            "GenartName": "Seal Ring, servo piston",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Servo Piston (automatic transmission)"
        },
        "08297": {
            "GenartName": "Relay, sliding sunroof",
            "NormTermName": "Relay",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Sunroof"
        },
        "08298": {
            "GenartName": "Seal Set, injection system",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection System"
        },
        "08299": {
            "GenartName": "Ring, transmission output shaft (manual transmission)",
            "NormTermName": "Ring",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Output Shaft (manual transmission)"
        },
        "08300": {
            "GenartName": "Resistor, alternator",
            "NormTermName": "Resistor",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "08301": {
            "GenartName": "Bearing, steering gear",
            "NormTermName": "Bearing",
            "AssGrpName": "Steering",
            "UsageName": "Steering Gear"
        },
        "08302": {
            "GenartName": "Ring, differential stub shaft bearing",
            "NormTermName": "Ring",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Differential Stub Shaft Bearing"
        },
        "08303": {
            "GenartName": "Seal, oil pump cover",
            "NormTermName": "Seal",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump Cover"
        },
        "08304": {
            "GenartName": "Annulus, automatic transmission",
            "NormTermName": "Ring Gear",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "08305": {
            "GenartName": "Capacitor, alternator",
            "NormTermName": "Condenser",
            "AssGrpName": "Alternator",
            "UsageName": null
        },
        "08306": {
            "GenartName": "Selector Switch, fuel tank",
            "NormTermName": "Switch",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank"
        },
        "08307": {
            "GenartName": "Cable Set, air conditioning compressor",
            "NormTermName": "Cable Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Compressor"
        },
        "08308": {
            "GenartName": "Relay Socket",
            "NormTermName": "Relay Socket",
            "AssGrpName": "Central Electrics",
            "UsageName": "Relay"
        },
        "08309": {
            "GenartName": "Carbon Brush Set, starter",
            "NormTermName": "Carbon Brush Set",
            "AssGrpName": "Starter System",
            "UsageName": "Starter"
        },
        "08310": {
            "GenartName": "Strainer, fuel pump",
            "NormTermName": "Strainer",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "08311": {
            "GenartName": "Bulb, display (parking brake)",
            "NormTermName": "Bulb",
            "AssGrpName": "Braking System",
            "UsageName": "Display (parking brake)"
        },
        "08312": {
            "GenartName": "Switch, low beam",
            "NormTermName": "Switch",
            "AssGrpName": "Lights",
            "UsageName": "Low Beam"
        },
        "08313": {
            "GenartName": "Seal Set, servo pump",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering Pump"
        },
        "08314": {
            "GenartName": "Cable Repair Set, ignition switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Starter System",
            "UsageName": "Ignition Switch"
        },
        "08315": {
            "GenartName": "Rocker Arm Set, engine timing",
            "NormTermName": "Lever Set",
            "AssGrpName": "Engine Timing",
            "UsageName": null
        },
        "08316": {
            "GenartName": "Repair Sleeve, timing case cover",
            "NormTermName": "Repair Sleeve",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Case Cover"
        },
        "08317": {
            "GenartName": "Cable Repair Set, control unit (ignition system)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Ignition System",
            "UsageName": "Control Unit (ignition system)"
        },
        "08318": {
            "GenartName": "Guide Rail, push rod",
            "NormTermName": "Guide Rail",
            "AssGrpName": "Engine Timing",
            "UsageName": "Push Rod"
        },
        "08319": {
            "GenartName": "Ram Protection Buffer",
            "NormTermName": "Buffer",
            "AssGrpName": "Body",
            "UsageName": "Ram Protection"
        },
        "08320": {
            "GenartName": "Ladder",
            "NormTermName": "Ladder",
            "AssGrpName": "Body",
            "UsageName": "Ladder"
        },
        "08321": {
            "GenartName": "Belt Grinder (compressed air)",
            "NormTermName": "Belt Grinder (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08322": {
            "GenartName": "Sheet Metal Nibbler (compressed air)",
            "NormTermName": "Sheet Metal Nibbler (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08323": {
            "GenartName": "Rivet Setter (compressed air)",
            "NormTermName": "Rivet Setter (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08324": {
            "GenartName": "Drill (compressed air)",
            "NormTermName": "Drill (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08325": {
            "GenartName": "Triangular Sander (compressed air)",
            "NormTermName": "Triangular Sander (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08326": {
            "GenartName": "Random Orbit Sander (compressed air)",
            "NormTermName": "Random Orbit Sander (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08327": {
            "GenartName": "Grease Gun (compressed air)",
            "NormTermName": "Grease Gun (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08328": {
            "GenartName": "Grease Spraying Device (compressed air)",
            "NormTermName": "Grease Spraying Device (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08329": {
            "GenartName": "Impact Wrench (compressed air)",
            "NormTermName": "Impact Wrench (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08330": {
            "GenartName": "Impact Wrench Set (compressed air)",
            "NormTermName": "Impact Wrench Set (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08331": {
            "GenartName": "Orbital Sander (compressed air)",
            "NormTermName": "Orbital Sander (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08332": {
            "GenartName": "Random Orbit Sander Set (compressed air)",
            "NormTermName": "Random Orbit Sander Set (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08333": {
            "GenartName": "Cartridge Gun (compressed air)",
            "NormTermName": "Cartridge Gun (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08334": {
            "GenartName": "Oscillating Multi-Tool (compressed air)",
            "NormTermName": "Oscillating Multi-Tool (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08335": {
            "GenartName": "Multi-grinder (compressed air)",
            "NormTermName": "Multi-grinder (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08336": {
            "GenartName": "Needle Scaler (compressed air)",
            "NormTermName": "Needle Scaler (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08337": {
            "GenartName": "Pistol Grinder (compressed air)",
            "NormTermName": "Pistol Grinder (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08338": {
            "GenartName": "Polisher (compressed air)",
            "NormTermName": "Polisher (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08339": {
            "GenartName": "Ratchet Screwdriver (compressed air)",
            "NormTermName": "Ratchet Screwdriver (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08340": {
            "GenartName": "Reciprocating Saw (compressed air)",
            "NormTermName": "Reciprocating Saw (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08341": {
            "GenartName": "Suction/Blast Gun (compressed air)",
            "NormTermName": "Suction/Blast Gun (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08342": {
            "GenartName": "Pulse Foam Gun (compressed air)",
            "NormTermName": "Pulse Foam Gun (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08343": {
            "GenartName": "Straight Cutting Grinder (compressed air)",
            "NormTermName": "Straight Cutting Grinder (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08344": {
            "GenartName": "Jigsaw (compressed air)",
            "NormTermName": "Jigsaw (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08345": {
            "GenartName": "Angled Drill (compressed air)",
            "NormTermName": "Angled Drill (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08346": {
            "GenartName": "Angle Grinder (compressed air)",
            "NormTermName": "Angle Grinder (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08347": {
            "GenartName": "Demolition Hammer (compressed air)",
            "NormTermName": "Demolition Hammer (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08348": {
            "GenartName": "Demolition Hammer Set (compressed air)",
            "NormTermName": "Demolition Hammer Set (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08349": {
            "GenartName": "Straight-grip Grinder (compressed air)",
            "NormTermName": "Straight-grip Grinder (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08350": {
            "GenartName": "Belt Grinder (rechargeable battery)",
            "NormTermName": "Belt Grinder (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08351": {
            "GenartName": "Sheet Metal Nibbler (rechargeable battery)",
            "NormTermName": "Sheet Metal Nibbler (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08352": {
            "GenartName": "Hammer Drill (rechargeable battery)",
            "NormTermName": "Hammer Drill (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08353": {
            "GenartName": "Triangular Sander (rechargeable battery)",
            "NormTermName": "Triangular Sander (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08354": {
            "GenartName": "Random Orbit Sander (rechargeable battery)",
            "NormTermName": "Random Orbit Sander (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08355": {
            "GenartName": "Grease Gun (rechargeable battery)",
            "NormTermName": "Grease Gun (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08356": {
            "GenartName": "Hot Glue Gun (rechargeable battery)",
            "NormTermName": "Hot Glue Gun (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08357": {
            "GenartName": "Cartridge Gun (rechargeable battery)",
            "NormTermName": "Cartridge Gun (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08358": {
            "GenartName": "Oscillating Multi-Tool (rechargeable battery)",
            "NormTermName": "Oscillating Multi-Tool (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08359": {
            "GenartName": "Multi-grinder (rechargeable battery)",
            "NormTermName": "Multi-grinder (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08360": {
            "GenartName": "Pistol-grip Grinder (rechargeable battery)",
            "NormTermName": "Pistol-grip Grinder (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08361": {
            "GenartName": "Polisher (rechargeable battery)",
            "NormTermName": "Polisher (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08362": {
            "GenartName": "Ratchet Screwdriver (rechargeable battery)",
            "NormTermName": "Ratchet Screwdriver (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08363": {
            "GenartName": "Reciprocating Saw (rechargeable battery)",
            "NormTermName": "Reciprocating Saw (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08364": {
            "GenartName": "Combi-drill (rechargeable battery)",
            "NormTermName": "Combi-drill (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08365": {
            "GenartName": "Impact Wrench (rechargeable battery)",
            "NormTermName": "Impact Wrench (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08366": {
            "GenartName": "Impact Wrench Set (rechargeable battery)",
            "NormTermName": "Impact Wrench Set (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08367": {
            "GenartName": "Orbital Sander (rechargeable battery)",
            "NormTermName": "Orbital Sander (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08368": {
            "GenartName": "Straight-grip Grinder (rechargeable battery)",
            "NormTermName": "Straight-grip Grinder (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08369": {
            "GenartName": "Jigsaw (rechargeable battery)",
            "NormTermName": "Jigsaw (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08370": {
            "GenartName": "Angled Drill (rechargeable battery)",
            "NormTermName": "Angled Drill (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08371": {
            "GenartName": "Angle Grinder (rechargeable battery)",
            "NormTermName": "Angle Grinder (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08372": {
            "GenartName": "Belt Sander (mains)",
            "NormTermName": "Belt Sander (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08373": {
            "GenartName": "Sheet Metal Nibbler (mains)",
            "NormTermName": "Sheet Metal Nibbler (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08374": {
            "GenartName": "Transfer Case",
            "NormTermName": "Transmission",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transfer Case"
        },
        "08375": {
            "GenartName": "Drill (mains)",
            "NormTermName": "Drill (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08376": {
            "GenartName": "Triangular Sander (mains)",
            "NormTermName": "Triangular Sander (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08377": {
            "GenartName": "Random Orbit Sander (mains)",
            "NormTermName": "Random Orbit Sander (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08378": {
            "GenartName": "Hot Glue Gun (mains)",
            "NormTermName": "Hot Glue Gun (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08379": {
            "GenartName": "Oscillating Multi-Tool (mains)",
            "NormTermName": "Oscillating Multi-Tool (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08380": {
            "GenartName": "Needle Scaler (mains)",
            "NormTermName": "Needle Scaler (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08381": {
            "GenartName": "Polisher (mains)",
            "NormTermName": "Polisher (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08382": {
            "GenartName": "Reciprocating Saw (mains)",
            "NormTermName": "Reciprocating Saw (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08383": {
            "GenartName": "Impact Wrench (mains)",
            "NormTermName": "Impact Wrench (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08384": {
            "GenartName": "Impact Wrench Set (mains)",
            "NormTermName": "Impact Wrench Set (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08385": {
            "GenartName": "Jigsaw (mains)",
            "NormTermName": "Jigsaw (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08386": {
            "GenartName": "Angled Drill (mains)",
            "NormTermName": "Angled Drill (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08387": {
            "GenartName": "Reduction Tube, exhaust system",
            "NormTermName": "Reduction Tube",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust System"
        },
        "08388": {
            "GenartName": "Hammer Drill (mains)",
            "NormTermName": "Hammer Drill (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08389": {
            "GenartName": "Manual Impact Wrench",
            "NormTermName": "Manual Impact Wrench",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08390": {
            "GenartName": "Manual Impact Wrench Set",
            "NormTermName": "Manual Impact Wrench Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08391": {
            "GenartName": "Orbital Sander (mains)",
            "NormTermName": "Orbital Sander (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08392": {
            "GenartName": "Straight-grip Grinder (mains)",
            "NormTermName": "Straight-grip Grinder (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08393": {
            "GenartName": "Angle Grinder (mains)",
            "NormTermName": "Angle Grinder (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08394": {
            "GenartName": "Belt File (rechargeable battery)",
            "NormTermName": "Belt File (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08395": {
            "GenartName": "Belt File (compressed air)",
            "NormTermName": "Belt File (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08396": {
            "GenartName": "Belt File (mains)",
            "NormTermName": "Belt File (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08397": {
            "GenartName": "Manual Sheet Metal Shears",
            "NormTermName": "Manual Sheet Metal Shears",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08398": {
            "GenartName": "Sheet Metal Shears (mains)",
            "NormTermName": "Sheet Metal Shears (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08399": {
            "GenartName": "Magnetic Stand, core drill (mains)",
            "NormTermName": "Magnetic Stand, core drill (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08400": {
            "GenartName": "Magnetic Stand, core drill (rechargeable battery)",
            "NormTermName": "Magnetic Stand, core drill (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08401": {
            "GenartName": "Cleaning Gun (compressed air)",
            "NormTermName": "Cleaning Gun (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08402": {
            "GenartName": "Sheet Metal Shears (compressed air)",
            "NormTermName": "Sheet Metal Shears (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08403": {
            "GenartName": "Sheet Metal Shears (rechargeable battery)",
            "NormTermName": "Sheet Metal Shears (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08404": {
            "GenartName": "Drill (rechargeable battery)",
            "NormTermName": "Drill (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08405": {
            "GenartName": "Impact Wrench (rechargeable battery)",
            "NormTermName": "Impact Wrench (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08406": {
            "GenartName": "Demolition Hammer (rechargeable battery)",
            "NormTermName": "Demolition Hammer (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08407": {
            "GenartName": "Demolition Hammer (mains)",
            "NormTermName": "Demolition Hammer (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08408": {
            "GenartName": "Demolition Hammer Set (rechargeable battery)",
            "NormTermName": "Demolition Hammer Set (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08409": {
            "GenartName": "Demolition Hammer Set (mains)",
            "NormTermName": "Demolition Hammer Set (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08410": {
            "GenartName": "Rivet Setter (rechargeable battery)",
            "NormTermName": "Rivet Setter (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08411": {
            "GenartName": "Rivet Setter (mains)",
            "NormTermName": "Rivet Setter (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08412": {
            "GenartName": "Headlight Module",
            "NormTermName": "Headlight Module",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "08413": {
            "GenartName": "Fan, headlight",
            "NormTermName": "Fan",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "08414": {
            "GenartName": "Control Unit, headlight",
            "NormTermName": "Control Unit",
            "AssGrpName": "Lights",
            "UsageName": "Headlight"
        },
        "08415": {
            "GenartName": "Ring, fuel return quantity measurement",
            "NormTermName": "Ring",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Fuel Return Quantity Measurement"
        },
        "08416": {
            "GenartName": "Supporting Ring, injection nozzle extractor set",
            "NormTermName": "Supporting Ring",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injection Nozzle Extractor Set"
        },
        "08417": {
            "GenartName": "Test Paper, test device (urea injection)",
            "NormTermName": "Test Paper",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Test Device (urea injection)"
        },
        "08418": {
            "GenartName": "Calibration Weight, test device (urea injection)",
            "NormTermName": "Weight",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Test Device (urea injection)"
        },
        "08419": {
            "GenartName": "Lanyard, test device (urea injection)",
            "NormTermName": "Retaining Cable",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Test Device (urea injection)"
        },
        "08420": {
            "GenartName": "Technician Help, air conditioning service unit",
            "NormTermName": "Technician Help, air conditioning service unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08421": {
            "GenartName": "Technician Help Set, air conditioning service unit",
            "NormTermName": "Technician Help Set, air conditioning service unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08422": {
            "GenartName": "Drain Valve",
            "NormTermName": "Drain Valve",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08423": {
            "GenartName": "Ball Drain Valve",
            "NormTermName": "Ball Drain Valve",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08424": {
            "GenartName": "Manually-actuated Grease Gun",
            "NormTermName": "Manually-actuated Grease Gun",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08425": {
            "GenartName": "Flushing System, injection system",
            "NormTermName": "Flushing System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Injection System"
        },
        "08426": {
            "GenartName": "Hydraulic Pump, power take-off",
            "NormTermName": "Pump",
            "AssGrpName": "Power Take-Off",
            "UsageName": "Power Take-Off"
        },
        "08427": {
            "GenartName": "Power Take-Off",
            "NormTermName": "Power Take-Off",
            "AssGrpName": "Power Take-Off",
            "UsageName": null
        },
        "08428": {
            "GenartName": "Control Unit, retarder",
            "NormTermName": "Control Unit",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "08429": {
            "GenartName": "Cable Repair Set, boot lid",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Central Electrics",
            "UsageName": "Boot Lid"
        },
        "08430": {
            "GenartName": "Cable Repair Set, door",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Central Electrics",
            "UsageName": "Door"
        },
        "08431": {
            "GenartName": "Cable Repair Set, tailgate",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Central Electrics",
            "UsageName": "Tailgate"
        },
        "08432": {
            "GenartName": "Vibration Damper, crankshaft",
            "NormTermName": "Damper",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft"
        },
        "08433": {
            "GenartName": "General Spanner Set, tow bar",
            "NormTermName": "Key Set",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Tow Bar"
        },
        "08434": {
            "GenartName": "Programming Device, electric kit (tow bar)",
            "NormTermName": "Programming Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Electric Kit (towbar)"
        },
        "08435": {
            "GenartName": "Thrust Piece, valve spring installation tool",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Valve Spring Installation Tool"
        },
        "08436": {
            "GenartName": "Spindle, puller",
            "NormTermName": "Spindle",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Puller"
        },
        "08437": {
            "GenartName": "Compressed Air Adapter, spark plug / glow plug hole",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spark/Glow Plug hole"
        },
        "08438": {
            "GenartName": "Valve Set, tyre-pressure monitoring system",
            "NormTermName": "Valve Set",
            "AssGrpName": "Wheels",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "08439": {
            "GenartName": "Repair Sleeve, camshaft",
            "NormTermName": "Repair Sleeve",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft"
        },
        "08440": {
            "GenartName": "Lock Mandrel, balancer shaft",
            "NormTermName": "Retaining Pin",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Balance Shaft"
        },
        "08441": {
            "GenartName": "Disconnecting/installation fork",
            "NormTermName": "Disconnecting/installation fork",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08442": {
            "GenartName": "Adapter, impact puller",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Impact Puller"
        },
        "08443": {
            "GenartName": "Mounting Tool, valve spring",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Valve Spring"
        },
        "08444": {
            "GenartName": "Expansion Set, locking tool (control times)",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Locking Tool (control times)"
        },
        "08445": {
            "GenartName": "Pulling Lever, belt pulley",
            "NormTermName": "Key",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Belt Pulley"
        },
        "08446": {
            "GenartName": "Holding Tool Set, belt pulley",
            "NormTermName": "Holding Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Belt Pulley"
        },
        "08447": {
            "GenartName": "Puller Set, camshaft gear",
            "NormTermName": "Puller Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Camshaft Gear"
        },
        "08448": {
            "GenartName": "Face Spanner",
            "NormTermName": "Face Spanner",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08449": {
            "GenartName": "Filling Adapter, transmission",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Transmission"
        },
        "08450": {
            "GenartName": "Earth Cable, distributor",
            "NormTermName": "Earth Cable",
            "AssGrpName": "Ignition System",
            "UsageName": "Distributor"
        },
        "08451": {
            "GenartName": "Capaciter, radio",
            "NormTermName": "Condenser",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Radio"
        },
        "08452": {
            "GenartName": "Fastening Set, leaf spring",
            "NormTermName": "Fastening Set",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leaf Spring"
        },
        "08453": {
            "GenartName": "Impact Sockets Set, track widening",
            "NormTermName": "Power Socket Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Track Widening"
        },
        "08454": {
            "GenartName": "Valve Core Set, service valve",
            "NormTermName": "Valve core set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Service Valve"
        },
        "08455": {
            "GenartName": "Interior Cleaner, ultrasonic nebuliser",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Ultrasonic Nebuliser"
        },
        "08456": {
            "GenartName": "Cleaner, leak-seeking additive",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Leak-seeking additive"
        },
        "08457": {
            "GenartName": "Leak-finding spray",
            "NormTermName": "Leak-finding spray",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "08458": {
            "GenartName": "Radiator hose set",
            "NormTermName": "Hose Line Set",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator"
        },
        "08459": {
            "GenartName": "Actuator, turbocharger",
            "NormTermName": "Actuator",
            "AssGrpName": "Air Supply",
            "UsageName": "Turbocharger"
        },
        "08460": {
            "GenartName": "Retaining Wrench, fan (fluid friction coupling)",
            "NormTermName": "Arrester Spanner",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Fan (fluid friction coupling)"
        },
        "08461": {
            "GenartName": "Welding Protection Screen",
            "NormTermName": "Welding Protection Screen",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08462": {
            "GenartName": "Welding Hood",
            "NormTermName": "Welding Hood",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08463": {
            "GenartName": "Free-vision Handheld Shield",
            "NormTermName": "Free-vision Handheld Shield",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08464": {
            "GenartName": "Filler Hose Set, air conditioning service unit",
            "NormTermName": "Filler Hose Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Service Unit"
        },
        "08465": {
            "GenartName": "Valve, aut. transm. hydraulic unit",
            "NormTermName": "Valve",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Hydraulic Unit Automatic Transmission"
        },
        "08466": {
            "GenartName": "Oil Cooler Line, automatic transmission",
            "NormTermName": "Oil Cooler Line",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "08467": {
            "GenartName": "Seal, air conditioning compressor",
            "NormTermName": "Seal",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Air Conditioning Compressor"
        },
        "08468": {
            "GenartName": "Adapter, spring compressor",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spring Compressor"
        },
        "08469": {
            "GenartName": "Spring Pre-Tensioning Tool, suspension spring",
            "NormTermName": "Spring Pre-Tensioning Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Suspension Spring"
        },
        "08470": {
            "GenartName": "Expansion set, spring pre-tensioning tool",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spring Pre-Tensioning Tool"
        },
        "08471": {
            "GenartName": "Expansion set, spreader (ball joint mounting)",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spreader (ball joint mounting)"
        },
        "08472": {
            "GenartName": "Spreader, ball joint mounting",
            "NormTermName": "Spreader",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ball Joint Mounting"
        },
        "08473": {
            "GenartName": "Pressing Screw, spreader (ball joint mounting)",
            "NormTermName": "Pressing Screw",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spreader (ball joint mounting)"
        },
        "08474": {
            "GenartName": "Retainer, shock absorber",
            "NormTermName": "Retainer",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Shock Absorber"
        },
        "08475": {
            "GenartName": "Adapter Ring, pressure plate spring compressor",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Pressure Plate Spring Compressor"
        },
        "08476": {
            "GenartName": "Clamping Block, suspension strut",
            "NormTermName": "Clamping Block",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Suspension Strut"
        },
        "08477": {
            "GenartName": "Tool Set, shock absorber piston rod",
            "NormTermName": "Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Shock Absorber Piston Rod"
        },
        "08478": {
            "GenartName": "Socket Wrench Insert, shock absorber piston rod",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Shock Absorber Piston Rod"
        },
        "08479": {
            "GenartName": "Socket Wrench Insert, shock absorber piston rod",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Shock Absorber Piston Rod"
        },
        "08480": {
            "GenartName": "Assortment, cable ties",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Cable Tie"
        },
        "08481": {
            "GenartName": "Bulb Cabinet",
            "NormTermName": "Cabinet",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Bulbs"
        },
        "08482": {
            "GenartName": "Cable Repair Set, swirl valve actuator (intake manifold)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply",
            "UsageName": "Swirl Covers (induction pipe)"
        },
        "08483": {
            "GenartName": "Cable Repair Set, pressure ctrl. valve (common rail system)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Pressure Control Valve (common rail system)"
        },
        "08484": {
            "GenartName": "Stockings",
            "NormTermName": "Stockings",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08485": {
            "GenartName": "Vest",
            "NormTermName": "Vest",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08486": {
            "GenartName": "Storage, hearing protection",
            "NormTermName": "Storage",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Hearing Protection"
        },
        "08487": {
            "GenartName": "Respiratory Protection System",
            "NormTermName": "Respiratory Protection System",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08488": {
            "GenartName": "Storage, fire blanket",
            "NormTermName": "Storage",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Fire Blanket"
        },
        "08489": {
            "GenartName": "Refill Set, first aid box",
            "NormTermName": "Refill Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "First Aid Box"
        },
        "08490": {
            "GenartName": "Sterile Dressing",
            "NormTermName": "Sterile Dressing",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08491": {
            "GenartName": "Logistics box",
            "NormTermName": "Logistics box",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08492": {
            "GenartName": "Forklift Truck",
            "NormTermName": "Forklift Truck",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08493": {
            "GenartName": "Planning board",
            "NormTermName": "Planning board",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08494": {
            "GenartName": "Clipboard",
            "NormTermName": "Clipboard",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08495": {
            "GenartName": "Key Cabinet",
            "NormTermName": "Key Cabinet",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08496": {
            "GenartName": "Movement Detector",
            "NormTermName": "Movement Detector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08497": {
            "GenartName": "Smoke Alarm",
            "NormTermName": "Smoke Alarm",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08498": {
            "GenartName": "Desk",
            "NormTermName": "Desk",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08499": {
            "GenartName": "Surveillance Mirror",
            "NormTermName": "Surveillance Mirror",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08500": {
            "GenartName": "Starter, fluorescent tubes",
            "NormTermName": "Starter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Fluorescent Tubes"
        },
        "08501": {
            "GenartName": "Illuminant",
            "NormTermName": "Illuminant",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08502": {
            "GenartName": "External Spotlight",
            "NormTermName": "External Spotlight",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08503": {
            "GenartName": "Workshop Door",
            "NormTermName": "Workshop Door",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08504": {
            "GenartName": "Air Conditioning Unit",
            "NormTermName": "Air Conditioning Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08505": {
            "GenartName": "Keyboard",
            "NormTermName": "Keyboard",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08506": {
            "GenartName": "Telephone",
            "NormTermName": "Telephone",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08507": {
            "GenartName": "Roller Turner, glow plugs",
            "NormTermName": "Roller Turner",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Glow Plug"
        },
        "08508": {
            "GenartName": "Mounting Tool, shaft sealing ring (transmission)",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Shaft Sealing Ring (transmission)"
        },
        "08509": {
            "GenartName": "Puller, transmission bearing",
            "NormTermName": "Extractor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Transmission Bearing"
        },
        "08510": {
            "GenartName": "Mounting Tool Set, transmission bearing",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Transmission Bearing"
        },
        "08511": {
            "GenartName": "Cleaning/Milling Tool Set, injector shaft antechamber",
            "NormTermName": "Cleaning/Milling Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector Shaft Antechamber"
        },
        "08512": {
            "GenartName": "Adapter, pressure plate spring compressor",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Pressure Plate Spring Compressor"
        },
        "08513": {
            "GenartName": "Compression Die Set, ejector (control arm clamping screw)",
            "NormTermName": "Press-piece Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Control Arm Clamping Screw Ejector"
        },
        "08514": {
            "GenartName": "Compression Die, ejector (control arm clamping screw)",
            "NormTermName": "Press-piece",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Control Arm Clamping Screw Ejector"
        },
        "08515": {
            "GenartName": "Pull Wrench, thrust piece (contr. arm clamping screw eject.)",
            "NormTermName": "Pulling Spanner",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Control Arm Clamping Screw Ejector"
        },
        "08516": {
            "GenartName": "Impact Extractor, ball joint (control arm strut)",
            "NormTermName": "Striking Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Joint Pin (control arm strut)"
        },
        "08517": {
            "GenartName": "Base Tool, ball joint spreader",
            "NormTermName": "Base Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ball Joint Spreader"
        },
        "08518": {
            "GenartName": "Fork, ball joint spreader",
            "NormTermName": "Fork",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ball Joint Spreader"
        },
        "08519": {
            "GenartName": "Extension, ball joint spreader",
            "NormTermName": "Extension",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Ball Joint Spreader"
        },
        "08520": {
            "GenartName": "Fork, ball joint spreader",
            "NormTermName": "Fork",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ball Joint Spreader"
        },
        "08521": {
            "GenartName": "Hooked Impact Puller",
            "NormTermName": "Hook",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Puller"
        },
        "08522": {
            "GenartName": "Adapter, track rod adjusting pliers",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Track Rod Adjusting Pliers"
        },
        "08523": {
            "GenartName": "Locking Tool, toothed rack (steering)",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Toothed Rack (steering)"
        },
        "08524": {
            "GenartName": "Drift, steering wheel spacer sleeve",
            "NormTermName": "Drift",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Steering Wheel Spacer Sleeve"
        },
        "08525": {
            "GenartName": "Pin Wrench, ball joint",
            "NormTermName": "Pin Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Supporting Joint"
        },
        "08526": {
            "GenartName": "Press/Support Sleeve Set, pressing in/out tool set",
            "NormTermName": "Press/Support Sleeve Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Press-in-/Extraction Tools"
        },
        "08527": {
            "GenartName": "Drilling Tool Set, control arm clamping screw",
            "NormTermName": "Extractor Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Control Arm Clamping Screw"
        },
        "08528": {
            "GenartName": "Acid Neutralisation Agent",
            "NormTermName": "Acid Neutralisation Agent",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "08529": {
            "GenartName": "Supporting Bolt Set, injector removal tool",
            "NormTermName": "Supporting Bolt Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector Removal Tool"
        },
        "08530": {
            "GenartName": "Filling/Extraction Pump, fuel",
            "NormTermName": "Pump",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Fuel"
        },
        "08531": {
            "GenartName": "Socket Wrench Insert, glow plug",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow Plug"
        },
        "08532": {
            "GenartName": "Holding Tool, belt pulley",
            "NormTermName": "Holding Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Belt Pulley"
        },
        "08533": {
            "GenartName": "Oil Filter Spanner Set",
            "NormTermName": "Oil Filter Spanner Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08534": {
            "GenartName": "Cable Repair Set, air suspension level sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Air Suspension Level Sensor"
        },
        "08535": {
            "GenartName": "Cable Repair Set, air suspension level sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Suspension, universal",
            "UsageName": "Air Suspension Level Sensor"
        },
        "08536": {
            "GenartName": "Cable Repair Set, interior fan switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Blower Switch"
        },
        "08537": {
            "GenartName": "Cable Repair Set, interior fan switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation, universal",
            "UsageName": "Interior Blower Switch"
        },
        "08538": {
            "GenartName": "Counter Bracket, shock absorber piston rod",
            "NormTermName": "Arrester",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Shock Absorber Piston Rod"
        },
        "08539": {
            "GenartName": "Pin Wrench Set, shock absorber piston rod",
            "NormTermName": "Pin Wrench Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Shock Absorber Piston Rod"
        },
        "08540": {
            "GenartName": "Pin Wrench, shock absorber piston rod",
            "NormTermName": "Pin Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Shock Absorber Piston Rod"
        },
        "08541": {
            "GenartName": "Pulling Spanner, shock absorber piston rod",
            "NormTermName": "Pulling Spanner",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Shock Absorber Piston Rod"
        },
        "08542": {
            "GenartName": "Strut Bearing Socket",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Strut Support Mount"
        },
        "08543": {
            "GenartName": "Clamping Cylinder, spring compressor",
            "NormTermName": "Clamping Cylinder",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spring Compressor"
        },
        "08544": {
            "GenartName": "Socket Wrench, shock absorber cartridge mount",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Shock absorber cartridge fastening"
        },
        "08545": {
            "GenartName": "Adjustment Plate, clamping & adjusting device (susp. strut)",
            "NormTermName": "Adjustment Plate",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clamping and Adjusting Device (suspension strut)"
        },
        "08546": {
            "GenartName": "Expansion Set, clamping & adjusting device (susp. strut)",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clamping and Adjusting Device (suspension strut)"
        },
        "08547": {
            "GenartName": "Centring Ring Set, wheel hub/bearing installation tool",
            "NormTermName": "Centring Ring Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Hub/Wheel Bearing Mounting Tools"
        },
        "08548": {
            "GenartName": "Centring Ring, wheel hub / wheel bearing installation tool",
            "NormTermName": "Centering Ring",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Hub/Wheel Bearing Mounting Tools"
        },
        "08549": {
            "GenartName": "Drive Shaft Thrust Piece",
            "NormTermName": "Drive Shaft Thrust Piece",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08550": {
            "GenartName": "Gritter",
            "NormTermName": "Gritter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08551": {
            "GenartName": "Cover, gritter",
            "NormTermName": "Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Gritter"
        },
        "08552": {
            "GenartName": "Waste Bin",
            "NormTermName": "Waste Bin",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08553": {
            "GenartName": "Bin Liner",
            "NormTermName": "Bin Liner",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08554": {
            "GenartName": "Barrier Chain",
            "NormTermName": "Barrier Chain",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08555": {
            "GenartName": "Cooler, electric motor",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Electric Motor"
        },
        "08557": {
            "GenartName": "Heat Generator, traction battery",
            "NormTermName": "Heat Generator",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Traction Battery"
        },
        "08558": {
            "GenartName": "Traction Battery",
            "NormTermName": "Battery",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Hybrid/Electrical Drive"
        },
        "08559": {
            "GenartName": "Heat Generator, interior heating",
            "NormTermName": "Heat Generator",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Interior Heating"
        },
        "08560": {
            "GenartName": "Cooler, hydrogen",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Hydrogen"
        },
        "08561": {
            "GenartName": "Oil, dual-clutch transmission (DSG)",
            "NormTermName": "Oil",
            "AssGrpName": "Dual-Clutch Transmission (DSG)",
            "UsageName": "Dual-Clutch Transmission (DSG)"
        },
        "08562": {
            "GenartName": "Oil, continuously variable transmission (CVT)",
            "NormTermName": "Oil",
            "AssGrpName": "Continuously Variable Transmission (CVT)",
            "UsageName": "Continuously Variable Transmission (CVT)"
        },
        "08563": {
            "GenartName": "Oil, hybrid transmission (DHT)",
            "NormTermName": "Oil",
            "AssGrpName": "Hybrid Transmission (DHT)",
            "UsageName": "Hybrid Transmission (DHT)"
        },
        "08564": {
            "GenartName": "Rechargeable Broom",
            "NormTermName": "Rechargeable Broom",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08565": {
            "GenartName": "Rechargeable Window Cleaner",
            "NormTermName": "Rechargeable Window Cleaner",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08566": {
            "GenartName": "Hand-held Circular Saw (rechargeable battery)",
            "NormTermName": "Hand-held Circular Saw (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08567": {
            "GenartName": "Double Hose Clamp (ASS clamp)",
            "NormTermName": "Double Hose Clamp (ASS clamp)",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08568": {
            "GenartName": "Storage Box, torque wrench",
            "NormTermName": "Storage Box",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "08569": {
            "GenartName": "Camshaft Adjuster Kit",
            "NormTermName": "Camshaft Adjuster Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": null
        },
        "08570": {
            "GenartName": "Timing Case Cover",
            "NormTermName": "Lid",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Case"
        },
        "08571": {
            "GenartName": "Hand Drier",
            "NormTermName": "Hand Drier",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08572": {
            "GenartName": "Paper Towel Roll",
            "NormTermName": "Paper Towel Roll",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08573": {
            "GenartName": "Urea Filling Device",
            "NormTermName": "Filling Device",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Urea"
        },
        "08574": {
            "GenartName": "Flat Staple",
            "NormTermName": "Flat Staple",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08575": {
            "GenartName": "Blind Rivet Nut",
            "NormTermName": "Blind Rivet Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08576": {
            "GenartName": "Chemicals Cabinet",
            "NormTermName": "Chemicals Cabinet",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08577": {
            "GenartName": "Replacement Bag, sandblasting gun",
            "NormTermName": "Replacement Bag",
            "AssGrpName": "Machining Equipment",
            "UsageName": "Sandblasting Gun"
        },
        "08578": {
            "GenartName": "Mounting Kit, clutch release bearing retaining spring",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Release Bearing Retaining Spring"
        },
        "08579": {
            "GenartName": "Waste Grabber",
            "NormTermName": "Waste Grabber",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08580": {
            "GenartName": "Axle Crossbeam, mobile jack",
            "NormTermName": "Axle Crossbeam",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Mobile Jack"
        },
        "08581": {
            "GenartName": "First Aid Log",
            "NormTermName": "First Aid Log",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08582": {
            "GenartName": "Linked Chain",
            "NormTermName": "Linked Chain",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08583": {
            "GenartName": "House Number",
            "NormTermName": "House Number",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08584": {
            "GenartName": "Hedge Shears",
            "NormTermName": "Hedge Shears",
            "AssGrpName": "Garden Tools",
            "UsageName": null
        },
        "08585": {
            "GenartName": "Cap, number plate screw",
            "NormTermName": "Cap",
            "AssGrpName": "Standard Parts",
            "UsageName": "Number Plate Screw"
        },
        "08586": {
            "GenartName": "Thimble",
            "NormTermName": "Thimble",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08587": {
            "GenartName": "Coat Hanger",
            "NormTermName": "Coat Hanger",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08588": {
            "GenartName": "Workshop Order",
            "NormTermName": "Workshop Order",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08589": {
            "GenartName": "Advertising Sign",
            "NormTermName": "Advertising Sign",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08590": {
            "GenartName": "For Sale Sign",
            "NormTermName": "For Sale Sign",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08591": {
            "GenartName": "Toilet Paper",
            "NormTermName": "Toilet Paper",
            "AssGrpName": "Toiletries",
            "UsageName": null
        },
        "08592": {
            "GenartName": "Toilet Cleaner",
            "NormTermName": "Toilet Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "08593": {
            "GenartName": "Filling Station Glove Dispenser",
            "NormTermName": "Filling Station Glove Dispenser",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08594": {
            "GenartName": "Road Salt",
            "NormTermName": "Road Salt",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "08595": {
            "GenartName": "Spreading Grit",
            "NormTermName": "Spreading Grit",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "08596": {
            "GenartName": "Bollard",
            "NormTermName": "Bollard",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08597": {
            "GenartName": "Cable Repair Set, swirl valve actuator (intake manifold)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply, universal",
            "UsageName": "Swirl Covers (induction pipe)"
        },
        "08598": {
            "GenartName": "Cable Repair Set, pressure ctrl. valve (common rail system)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Pressure Control Valve (common rail system)"
        },
        "08599": {
            "GenartName": "Decorative Screw",
            "NormTermName": "Decorative Screw",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08600": {
            "GenartName": "Crimping Clamp",
            "NormTermName": "Crimping Clamp",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08601": {
            "GenartName": "Shaft Nut",
            "NormTermName": "Shaft Nut",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08602": {
            "GenartName": "Turnbuckle",
            "NormTermName": "Turnbuckle",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08603": {
            "GenartName": "Cable End Fastener",
            "NormTermName": "Cable End Fastener",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08604": {
            "GenartName": "Slide Piece",
            "NormTermName": "Slide Piece",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08605": {
            "GenartName": "Grooved Ring",
            "NormTermName": "Grooved Ring",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08606": {
            "GenartName": "Nilos ring",
            "NormTermName": "Nilos ring",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08607": {
            "GenartName": "Rivet Backplate",
            "NormTermName": "Rivet Backplate",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08608": {
            "GenartName": "Washer with tab",
            "NormTermName": "Washer with tab",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08609": {
            "GenartName": "Wire Rope Clamp",
            "NormTermName": "Wire Rope Clamp",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08610": {
            "GenartName": "Wire Hook",
            "NormTermName": "Wire Hook",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "08611": {
            "GenartName": "Emergency Blanket",
            "NormTermName": "Emergency Blanket",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08612": {
            "GenartName": "Cable Repair Set, stop light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Stop Light"
        },
        "08613": {
            "GenartName": "Gaiters",
            "NormTermName": "Gaiters",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08614": {
            "GenartName": "Suction Cup, for sale sign",
            "NormTermName": "Suction Disc",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08615": {
            "GenartName": "Cable Repair Set, boot light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Boot Light"
        },
        "08616": {
            "GenartName": "Pressing Roller",
            "NormTermName": "Pressing Roller",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08617": {
            "GenartName": "Roller",
            "NormTermName": "Roller",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08618": {
            "GenartName": "Storage Box, key rings",
            "NormTermName": "Storage Box",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Key Ring"
        },
        "08619": {
            "GenartName": "Oxyacetylene Hose",
            "NormTermName": "Oxyacetylene Hose",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08620": {
            "GenartName": "Mains Adapter, notebook",
            "NormTermName": "Mains Adapter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Notebook"
        },
        "08621": {
            "GenartName": "Glass Repair Drill",
            "NormTermName": "Glass Repair Drill",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08622": {
            "GenartName": "Wire Rope, cable winch",
            "NormTermName": "Wire Rope",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cable Winch"
        },
        "08623": {
            "GenartName": "Drilling Dust Trap",
            "NormTermName": "Drilling Dust Trap",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08624": {
            "GenartName": "Bucket Holder",
            "NormTermName": "Bucket Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08625": {
            "GenartName": "Seal, pressure reduction manometer",
            "NormTermName": "Seal",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08626": {
            "GenartName": "Toilet Paper Dispenser",
            "NormTermName": "Toilet Paper Dispenser",
            "AssGrpName": "Toiletries",
            "UsageName": null
        },
        "08627": {
            "GenartName": "Hygrometer",
            "NormTermName": "Hygrometer",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08628": {
            "GenartName": "Film Cleaner",
            "NormTermName": "Film Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "08629": {
            "GenartName": "Filling Valve, tyre filling gauge",
            "NormTermName": "Filling Valve",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08630": {
            "GenartName": "Hollow Knife",
            "NormTermName": "Hollow Knife",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08631": {
            "GenartName": "Small Grinding Flap Wheel",
            "NormTermName": "Small Grinding Flap Wheel",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08632": {
            "GenartName": "Inspection Mirror, Glass Repair",
            "NormTermName": "Inspection Mirror",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08633": {
            "GenartName": "Contour Disc",
            "NormTermName": "Contour Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08634": {
            "GenartName": "Crayon Holder",
            "NormTermName": "Crayon Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08635": {
            "GenartName": "Paint Stripper",
            "NormTermName": "Paint Stripper",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08636": {
            "GenartName": "Soldering Flux",
            "NormTermName": "Soldering Flux",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "08637": {
            "GenartName": "Soldering Fluid",
            "NormTermName": "Soldering Fluid",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "08638": {
            "GenartName": "Soldering Fluid Brush",
            "NormTermName": "Soldering Fluid Brush",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08639": {
            "GenartName": "Nail Hole Repair Body",
            "NormTermName": "Nail Hole Repair Body",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08640": {
            "GenartName": "Brush Head",
            "NormTermName": "Brush Head",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08641": {
            "GenartName": "Tyre Profile Cutter",
            "NormTermName": "Tyre Profile Cutter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08642": {
            "GenartName": "Repair Set, thread",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08643": {
            "GenartName": "Sal Ammoniac Blocks",
            "NormTermName": "Sal Ammoniac Blocks",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08644": {
            "GenartName": "Abrasive Roller",
            "NormTermName": "Abrasive Roller",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08645": {
            "GenartName": "Cord Runner",
            "NormTermName": "Cord Runner",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08646": {
            "GenartName": "Chuck, thread tap",
            "NormTermName": "Chuck",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08647": {
            "GenartName": "Spray Head, refill bottle",
            "NormTermName": "Spray Head",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08648": {
            "GenartName": "Spray Probe, cavity sealing",
            "NormTermName": "Spray Probe",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08649": {
            "GenartName": "Spray Probe, air conditioning cleaning gun",
            "NormTermName": "Spray Probe",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08650": {
            "GenartName": "Stabilising device, endoscope probe",
            "NormTermName": "Stabiliser",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08651": {
            "GenartName": "Static Mixer",
            "NormTermName": "Static Mixer",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "08652": {
            "GenartName": "Plug nipple",
            "NormTermName": "Plug nipple",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08653": {
            "GenartName": "Pencil Rasp",
            "NormTermName": "Pencil Rasp",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08654": {
            "GenartName": "Staples",
            "NormTermName": "Staples",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08655": {
            "GenartName": "Bench Drill",
            "NormTermName": "Bench Drill",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08656": {
            "GenartName": "Spring Pre-Tensioning Tool, fork springs",
            "NormTermName": "Spring Pre-Tensioning Tool",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "08657": {
            "GenartName": "Fleece Roll, grinding equipment",
            "NormTermName": "Fleece Roll",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08658": {
            "GenartName": "Pre-washing Sprayer",
            "NormTermName": "Pre-washing Sprayer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08659": {
            "GenartName": "Accessories Set, straight grinder",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08660": {
            "GenartName": "Core Drill",
            "NormTermName": "Core Drill",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08661": {
            "GenartName": "Suction Device, fuel vapours",
            "NormTermName": "Siphon",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08662": {
            "GenartName": "Adapter, barrel container",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08663": {
            "GenartName": "Refuelling System",
            "NormTermName": "Refuelling System",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08664": {
            "GenartName": "Bag, dry vacuum cleaner",
            "NormTermName": "Bag",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08665": {
            "GenartName": "Pressurised Can, AU test device",
            "NormTermName": "Pressurised Can",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "08666": {
            "GenartName": "Document Roll",
            "NormTermName": "Document Roll",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08667": {
            "GenartName": "Index Card, planning board",
            "NormTermName": "Index Card, planning board",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Planning board"
        },
        "08668": {
            "GenartName": "Guide Number, mirror tag",
            "NormTermName": "Guide Number, mirror tag",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08669": {
            "GenartName": "Guide Number Carrier, sandwich board",
            "NormTermName": "Guide Number Carrier, sandwich board",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08670": {
            "GenartName": "Monitor",
            "NormTermName": "Monitor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08671": {
            "GenartName": "Mop Cover",
            "NormTermName": "Mop Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08672": {
            "GenartName": "Floor Mop",
            "NormTermName": "Floor Mop",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08673": {
            "GenartName": "Spare Wheel Winch",
            "NormTermName": "Spare Wheel Winch",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08674": {
            "GenartName": "Notebook",
            "NormTermName": "Notebook",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08675": {
            "GenartName": "Trolley, tensioning support",
            "NormTermName": "Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tensioning Support"
        },
        "08676": {
            "GenartName": "Filter, wet/dry vacuum cleaner",
            "NormTermName": "Filter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wet/Dry Vacuum Cleaner"
        },
        "08677": {
            "GenartName": "Price Sheet Holder",
            "NormTermName": "Price Sheet Holder",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08678": {
            "GenartName": "Cleaning Pad",
            "NormTermName": "Cleaning Pad",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08679": {
            "GenartName": "Cleaning Rags",
            "NormTermName": "Cleaning Rags",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08680": {
            "GenartName": "Oil Change Tag",
            "NormTermName": "Oil Change Tag",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08681": {
            "GenartName": "Stores Entry Form, wheels/tyres",
            "NormTermName": "Stores Entry Form, wheels/tyres",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08682": {
            "GenartName": "Valve Connector, refrigerant bottle",
            "NormTermName": "Valve Connector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08683": {
            "GenartName": "Tyre Sticker",
            "NormTermName": "Tyre Sticker",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08684": {
            "GenartName": "Tyre Label",
            "NormTermName": "Tyre Label",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08685": {
            "GenartName": "Tyre Label Stamp",
            "NormTermName": "Tyre Label Stamp",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08686": {
            "GenartName": "Wall Mounting, gas bottle",
            "NormTermName": "Wall Mounting",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08687": {
            "GenartName": "Pulley",
            "NormTermName": "Pulley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08688": {
            "GenartName": "Spark-protection Glass, double grinding machine",
            "NormTermName": "Spark-protection Glass",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08689": {
            "GenartName": "Wall Mounting, hoseline",
            "NormTermName": "Wall Mounting",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08690": {
            "GenartName": "Key Ring Set",
            "NormTermName": "Key Ring Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08691": {
            "GenartName": "Protective Cap, manometer",
            "NormTermName": "Protective Cap",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08692": {
            "GenartName": "Hand Disinfectant Dispenser",
            "NormTermName": "Hand Disinfectant Dispenser",
            "AssGrpName": "Toiletries",
            "UsageName": null
        },
        "08693": {
            "GenartName": "Paper Towel Dispenser",
            "NormTermName": "Paper Towel Dispenser",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08694": {
            "GenartName": "Pressing Mandrel Set, workshop press",
            "NormTermName": "Pressing Mandrel Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08695": {
            "GenartName": "Mobile phone / PDA holder",
            "NormTermName": "Mobile phone / PDA holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08696": {
            "GenartName": "Tyre Container",
            "NormTermName": "Tyre Container",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08697": {
            "GenartName": "Water Hose",
            "NormTermName": "Water Hose",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08698": {
            "GenartName": "Workshop Cards",
            "NormTermName": "Workshop Cards",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08699": {
            "GenartName": "Printer",
            "NormTermName": "Printer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08700": {
            "GenartName": "Tyre Testing Trough",
            "NormTermName": "Tyre Testing Trough",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08701": {
            "GenartName": "Skin Protection Plan",
            "NormTermName": "Skin Protection Plan",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08702": {
            "GenartName": "Tyre Patch",
            "NormTermName": "Tyre Patch",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08703": {
            "GenartName": "Emergency Escape Sign",
            "NormTermName": "Emergency Escape Sign",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08704": {
            "GenartName": "Type Repair Body",
            "NormTermName": "Type Repair Body",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08705": {
            "GenartName": "Underpants",
            "NormTermName": "Underpants",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08706": {
            "GenartName": "Compression Pressure Tester",
            "NormTermName": "Compression Pressure Tester",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08707": {
            "GenartName": "Heater Test Bench",
            "NormTermName": "Heater Test Bench",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08708": {
            "GenartName": "Telescopic Rod, endoscope",
            "NormTermName": "Telescopic Rod",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08709": {
            "GenartName": "Luggage Tightener",
            "NormTermName": "Luggage Tightener",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": null
        },
        "08710": {
            "GenartName": "Suction Hose, dry vacuum cleaner",
            "NormTermName": "Suction Hose",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08711": {
            "GenartName": "Suction Probe, oil suction device",
            "NormTermName": "Suction Probe",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08712": {
            "GenartName": "Windscreen Washer Head",
            "NormTermName": "Windscreen Washer Head",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08713": {
            "GenartName": "Window/Surface Drier",
            "NormTermName": "Window/Surface Drier",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08714": {
            "GenartName": "Cutting Nozzle, plasma cutter",
            "NormTermName": "Cutting Nozzle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Plasma Cutter"
        },
        "08715": {
            "GenartName": "Quick Coupling, air conditioning service unit",
            "NormTermName": "Quick Coupling",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08716": {
            "GenartName": "Writing Case",
            "NormTermName": "Writing Case",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08717": {
            "GenartName": "Welding Sets",
            "NormTermName": "Welding Sets",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08718": {
            "GenartName": "Welding Cable Coupling",
            "NormTermName": "Welding Cable Coupling",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08719": {
            "GenartName": "Open-Fronted Storage Box",
            "NormTermName": "Open-Fronted Storage Box",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08720": {
            "GenartName": "Blasting Media, sandblasting gun",
            "NormTermName": "Blasting Media",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "08721": {
            "GenartName": "Contact Tip, welding unit",
            "NormTermName": "Contact Tip",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Welding Machine"
        },
        "08722": {
            "GenartName": "Crane Arm, wheel dolly",
            "NormTermName": "Crane Arm, wheel dolly",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08723": {
            "GenartName": "Ladder Rung",
            "NormTermName": "Ladder Rung",
            "AssGrpName": "Driver Cab, universal",
            "UsageName": null
        },
        "08724": {
            "GenartName": "Fuel Anti-Theft Device",
            "NormTermName": "Fuel Anti-Theft Device",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": null
        },
        "08725": {
            "GenartName": "Repair Set, coupling head",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Coupling Head"
        },
        "08726": {
            "GenartName": "Telescopic Extension, surface washer",
            "NormTermName": "Telescopic Extension",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Surface Washer"
        },
        "08727": {
            "GenartName": "Wet-Wipe Dispenser",
            "NormTermName": "Wet-Wipe Dispenser",
            "AssGrpName": "Toiletries",
            "UsageName": null
        },
        "08728": {
            "GenartName": "Foot Mat Beater",
            "NormTermName": "Foot Mat Beater",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08729": {
            "GenartName": "Thermal Receipt Roll",
            "NormTermName": "Thermal Receipt Roll",
            "AssGrpName": "Office Supplies",
            "UsageName": null
        },
        "08730": {
            "GenartName": "Wall Mounting, gas bottle",
            "NormTermName": "Wall Mounting, gas bottle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08731": {
            "GenartName": "Wall Mounting, high-pressure cleaner",
            "NormTermName": "Wall Mounting",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "High Pressure Cleaner"
        },
        "08732": {
            "GenartName": "Filter Set, high-pressure cleaner",
            "NormTermName": "Filter Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "High Pressure Cleaner"
        },
        "08733": {
            "GenartName": "Motorcycle Tank Protector",
            "NormTermName": "Motorcycle Tank Protector",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08734": {
            "GenartName": "Sensor, compressed-air system",
            "NormTermName": "Sensor",
            "AssGrpName": "Compressed Air System, universal",
            "UsageName": "Compressed Air System"
        },
        "08735": {
            "GenartName": "Wall Mounting, brush / high-pressure attachments",
            "NormTermName": "Wall Mounting",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08736": {
            "GenartName": "Paper Hand Towels",
            "NormTermName": "Paper Hand Towels",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08737": {
            "GenartName": "Replacement Glass, free-vision handheld shield",
            "NormTermName": "Replacement Glass",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08738": {
            "GenartName": "Tang Breaker",
            "NormTermName": "Tang Breaker",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08739": {
            "GenartName": "Barrel Lid Mat",
            "NormTermName": "Barrel Lid Mat",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08740": {
            "GenartName": "Accessory Set, plasma cutter",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08741": {
            "GenartName": "Conversion Kit, brake cylinder",
            "NormTermName": "Conversion Kit, brake cylinder",
            "AssGrpName": "Braking System, universal",
            "UsageName": null
        },
        "08742": {
            "GenartName": "Pneumatic Spring Press",
            "NormTermName": "Pneumatic Spring Press",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08743": {
            "GenartName": "Torque Wrench Safe, tool trolley",
            "NormTermName": "Torque Wrench Safe",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "08744": {
            "GenartName": "Riser, ASD filling station",
            "NormTermName": "Riser",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "08745": {
            "GenartName": "Repair Set, camshaft bearing bracket",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Bearing Block"
        },
        "08746": {
            "GenartName": "Bearing Bracket, camshaft",
            "NormTermName": "Bracket",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft"
        },
        "08747": {
            "GenartName": "Air Channel",
            "NormTermName": "Air Channel",
            "AssGrpName": "Air Supply",
            "UsageName": null
        },
        "08748": {
            "GenartName": "Oil Separator, compressed-air system",
            "NormTermName": "Trap",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Oil"
        },
        "08749": {
            "GenartName": "Pulley, reverse gear",
            "NormTermName": "Gear",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Reverse Gear"
        },
        "08750": {
            "GenartName": "Valve, seat height adjustment",
            "NormTermName": "Valve",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Seat Height Adjustment"
        },
        "08751": {
            "GenartName": "Air Spring Bellow, seat height adjustment",
            "NormTermName": "Bellow",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Seat Height Adjustment"
        },
        "08752": {
            "GenartName": "Repair Set, clutch control (transfer parts)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Control (transfer parts)"
        },
        "08753": {
            "GenartName": "Shaft, power take-off",
            "NormTermName": "Shaft",
            "AssGrpName": "Power Take-Off",
            "UsageName": "Power Take-Off"
        },
        "08754": {
            "GenartName": "Support Rail, door window",
            "NormTermName": "Support Rail",
            "AssGrpName": "Body",
            "UsageName": "Door Window"
        },
        "08755": {
            "GenartName": "Belt Pulley, air compressor",
            "NormTermName": "Belt Pulley",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "08756": {
            "GenartName": "Bracket, steering arm shaft",
            "NormTermName": "Holder",
            "AssGrpName": "Steering",
            "UsageName": "Steering Arm Shaft"
        },
        "08757": {
            "GenartName": "Hollow Shaft, spring bracket",
            "NormTermName": "Shaft",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Spring Bracket"
        },
        "08758": {
            "GenartName": "Pinion, speedometer",
            "NormTermName": "Pinion",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Speed"
        },
        "08759": {
            "GenartName": "Gear, servo pump",
            "NormTermName": "Gear",
            "AssGrpName": "Steering",
            "UsageName": "Hydraulic Pump (steering)"
        },
        "08760": {
            "GenartName": "Pressure Valve, injector pump",
            "NormTermName": "Valve",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Injection Pump"
        },
        "08761": {
            "GenartName": "Retaining Clip Set, body",
            "NormTermName": "Retaining Clip Set",
            "AssGrpName": "Body",
            "UsageName": "Body"
        },
        "08762": {
            "GenartName": "Thrust Piece Set, installation tool (wheel hub/bearing)",
            "NormTermName": "Thrust Piece Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Mounting Device (wheel hub/wheel bearing)"
        },
        "08763": {
            "GenartName": "Puller, wheel bearing inner ring",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Bearing Inner Ring"
        },
        "08764": {
            "GenartName": "Socket Wrench Insert, steering knuckle (spreader)",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Steering Knuckle"
        },
        "08765": {
            "GenartName": "Quick-Release Spindle Nut Set",
            "NormTermName": "Quick-Release Spindle Nut Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08766": {
            "GenartName": "Quick-Release Spindle Nut",
            "NormTermName": "Quick-Release Spindle Nut",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spindle"
        },
        "08767": {
            "GenartName": "Assortment, blind rivets",
            "NormTermName": "Assortment",
            "AssGrpName": "Body",
            "UsageName": "Blind Rivet"
        },
        "08768": {
            "GenartName": "Pin Wrench, strut",
            "NormTermName": "Pin Wrench Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Suspension Strut"
        },
        "08769": {
            "GenartName": "Socket Wrench Insert, control valve (camshaft adjuster)",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08770": {
            "GenartName": "Tie Rod Pair, press frame (conversion)",
            "NormTermName": "Tie Rod Pair",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08771": {
            "GenartName": "Press Frame Set",
            "NormTermName": "Press Frame Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08772": {
            "GenartName": "Adapter, hose coupling",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08773": {
            "GenartName": "Adapter, mounting",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08774": {
            "GenartName": "Press-fit/Extraction Tools",
            "NormTermName": "Press-fit/Extraction Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08775": {
            "GenartName": "Mounting Kit, supporting joint",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08776": {
            "GenartName": "Press Frame, supporting joint",
            "NormTermName": "Press Frame",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08777": {
            "GenartName": "Drift, silent bearing",
            "NormTermName": "Drift",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Silent Bearing"
        },
        "08778": {
            "GenartName": "Thrust Piece Set, installation tool silent bearing",
            "NormTermName": "Thrust Piece Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Silent Bearing"
        },
        "08779": {
            "GenartName": "Mounting Kit, control arm rubber mount",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08780": {
            "GenartName": "Mounting, press frame",
            "NormTermName": "Holder",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08781": {
            "GenartName": "Adapter, impact extractor (common rail injector)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Impact Extractor (common rail injector)"
        },
        "08782": {
            "GenartName": "Flaring Tool, brake line",
            "NormTermName": "Flaring Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Line"
        },
        "08784": {
            "GenartName": "Cable Repair Set, tailgate contact switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Central Electrics",
            "UsageName": "Tailgate Contact Switch"
        },
        "08785": {
            "GenartName": "Cable Repair Set, tailgate contact switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Electric Supply, universal",
            "UsageName": "Tailgate Contact Switch"
        },
        "08786": {
            "GenartName": "Cable Repair Set, stop light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Signal System",
            "UsageName": "Stop Light"
        },
        "08787": {
            "GenartName": "Cable Repair Set, boot light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Boot Light"
        },
        "08788": {
            "GenartName": "Cable Repair Set, high-beam headlamp bulb",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "High-Beam Headlamp Bulb"
        },
        "08789": {
            "GenartName": "Cable Repair Set, high-beam headlamp bulb",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "High-Beam Headlamp Bulb"
        },
        "08790": {
            "GenartName": "Cable Repair Set, main-beam headlamp bulb",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Main-Beam Headlamp Bulb"
        },
        "08791": {
            "GenartName": "Cable Repair Set, main-beam headlamp bulb",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Main-Beam Headlamp Bulb"
        },
        "08792": {
            "GenartName": "Cable Repair Set, fog light bulb",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Fog Light Bulb"
        },
        "08793": {
            "GenartName": "Cable Repair Set, fog light bulb",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Fog Light Bulb"
        },
        "08794": {
            "GenartName": "Mounting Ring, quick-clamp nut",
            "NormTermName": "Mounting Ring",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spindle"
        },
        "08795": {
            "GenartName": "Adapter, quick-clamp nut",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spindle"
        },
        "08796": {
            "GenartName": "Expansion Set, press in/out tool",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Silent Bearing"
        },
        "08797": {
            "GenartName": "Clamping Jaws Set, press in/out tool",
            "NormTermName": "Clamping Jaws Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Silent Bearing"
        },
        "08798": {
            "GenartName": "Spring Washer, silent bearing tool",
            "NormTermName": "Spring Washer",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Silent Bearing"
        },
        "08799": {
            "GenartName": "Thrust Piece, press-in/out tool",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Silent Bearing"
        },
        "08800": {
            "GenartName": "Thrust Piece Set, flaring tool",
            "NormTermName": "Thrust Piece Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Line"
        },
        "08801": {
            "GenartName": "Pressure Screw, flaring tool",
            "NormTermName": "Pressing Screw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Bordering Tool"
        },
        "08802": {
            "GenartName": "Vernier Calliper, brake disc diameter",
            "NormTermName": "Vernier Caliper",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Disc"
        },
        "08803": {
            "GenartName": "Socket Wrench Insert, parking brake",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Parking Brake"
        },
        "08804": {
            "GenartName": "Adaptor, brake caliper reset tool",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Reset Tool, brake caliper piston"
        },
        "08805": {
            "GenartName": "Expansion Set, flaring tool",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Line"
        },
        "08806": {
            "GenartName": "Skiving Tool, brake line",
            "NormTermName": "Skiving Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Line"
        },
        "08807": {
            "GenartName": "Reamer Set, ABS sensor mounting",
            "NormTermName": "Reamer Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "ABS Sensor Mounting"
        },
        "08808": {
            "GenartName": "Reset Tool, clutch",
            "NormTermName": "Reset Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Clutch"
        },
        "08809": {
            "GenartName": "Centring Pin Set, clutch",
            "NormTermName": "Centring Pin Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Clutch Disc"
        },
        "08810": {
            "GenartName": "Basic Tool Set, dual clutch tool",
            "NormTermName": "Basic Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Dual-Clutch Transmission (DSG)"
        },
        "08811": {
            "GenartName": "Expansion Set, dual clutch tool",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Dual-Clutch Transmission (DSG)"
        },
        "08812": {
            "GenartName": "Retrofit Kit, dual clutch tool",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Dual-Clutch Transmission (DSG)"
        },
        "08813": {
            "GenartName": "Scent Mark Remover, marten protection",
            "NormTermName": "Scent Mark Remover",
            "AssGrpName": "Chemical Products",
            "UsageName": "Marten Repellent"
        },
        "08814": {
            "GenartName": "Expansion Set, marten protection",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Marten Repellent"
        },
        "08815": {
            "GenartName": "Fastening Bracket, marten protection",
            "NormTermName": "Mounting Angle",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Marten Repellent"
        },
        "08816": {
            "GenartName": "Contact switch, bonnet (marten protection)",
            "NormTermName": "Switch",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Bonnet (marten protection)"
        },
        "08817": {
            "GenartName": "Adapter, impact puller",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injector Nozzle"
        },
        "08818": {
            "GenartName": "Mount, impact puller",
            "NormTermName": "Holder",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injector Nozzle"
        },
        "08819": {
            "GenartName": "Centring Disc, clutch",
            "NormTermName": "Centring Disc",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Clutch"
        },
        "08820": {
            "GenartName": "Release Pliers, clutch master cylinder",
            "NormTermName": "Release Pliers",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Clutch"
        },
        "08821": {
            "GenartName": "Puller, release shaft",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch"
        },
        "08822": {
            "GenartName": "Mounting Tool, clutch release bearing",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Clutch"
        },
        "08823": {
            "GenartName": "Centring Sleeve Set, clutch centring tool",
            "NormTermName": "Centring Sleeve Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Clutch"
        },
        "08824": {
            "GenartName": "Centring Piece, centring tool",
            "NormTermName": "Centring Piece",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Clutch"
        },
        "08825": {
            "GenartName": "Threaded Pin, clutch centring tool",
            "NormTermName": "Thread Bolt",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Clutch"
        },
        "08826": {
            "GenartName": "Base Plate, clutch clamping device",
            "NormTermName": "Base Plate",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Clutch"
        },
        "08827": {
            "GenartName": "Rotor, retarder",
            "NormTermName": "Rotor",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "08828": {
            "GenartName": "Weight, impact puller",
            "NormTermName": "Weight",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08829": {
            "GenartName": "Expansion Tank, compressed air system frost protection",
            "NormTermName": "Tank",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Compressed Air System Frost Protection"
        },
        "08830": {
            "GenartName": "Return Axle, oil pump",
            "NormTermName": "Return Axle",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Oil Pump"
        },
        "08831": {
            "GenartName": "Hydraulic Pump, radiator fan",
            "NormTermName": "Pump",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator Fan"
        },
        "08832": {
            "GenartName": "Hydraulic Motor, radiator fan",
            "NormTermName": "Hydraulic Motor",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator Fan"
        },
        "08833": {
            "GenartName": "Hub, layshaft",
            "NormTermName": "Hub",
            "AssGrpName": "Engine Timing",
            "UsageName": "Countershaft"
        },
        "08834": {
            "GenartName": "Valve Block, manual transmission",
            "NormTermName": "Valve Block",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "08835": {
            "GenartName": "Control Unit, manual transmission",
            "NormTermName": "Control Unit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "08836": {
            "GenartName": "Hook Spanner with Joint",
            "NormTermName": "Hook Spanner with Joint",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08837": {
            "GenartName": "Face Spanner with Joint",
            "NormTermName": "Face Spanner with Joint",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08838": {
            "GenartName": "Prism Plate, workshop press",
            "NormTermName": "Prism Plate",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Workshop Presses"
        },
        "08839": {
            "GenartName": "Reversing Aid",
            "NormTermName": "Reversing Aid",
            "AssGrpName": "Comfort Systems",
            "UsageName": null
        },
        "08840": {
            "GenartName": "Reversing Aid",
            "NormTermName": "Reversing Aid",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": null
        },
        "08841": {
            "GenartName": "Clamp, impact puller",
            "NormTermName": "Clamp",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Impact Extractor"
        },
        "08842": {
            "GenartName": "Extraction Tool, push-on contact",
            "NormTermName": "Extraction Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08843": {
            "GenartName": "Extraction Tool, push-on contact",
            "NormTermName": "Extraction Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08844": {
            "GenartName": "Extraction Tool Set, push-on contact",
            "NormTermName": "Extraction Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08845": {
            "GenartName": "Scraper Set",
            "NormTermName": "Scraper Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08846": {
            "GenartName": "Screwdriver Set, battery sealing plug",
            "NormTermName": "Screwdriver Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Battery Sealing Plug"
        },
        "08847": {
            "GenartName": "Disassembly Tool Set, airbag",
            "NormTermName": "Disassembly Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Airbag"
        },
        "08848": {
            "GenartName": "Groove Nut Socket Set",
            "NormTermName": "Groove Nut Socket Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08849": {
            "GenartName": "Disassembly Tool Set, ignition lock",
            "NormTermName": "Disassembly Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ignition Lock"
        },
        "08850": {
            "GenartName": "Mounting Sleeve, ignition lock",
            "NormTermName": "Installation Sleeve",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ignition Lock"
        },
        "08851": {
            "GenartName": "Mounting Sleeve, ignition lock trim",
            "NormTermName": "Installation Sleeve",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ignition Lock"
        },
        "08852": {
            "GenartName": "Release Tool, steering lock cover",
            "NormTermName": "Release Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Steering Lock"
        },
        "08853": {
            "GenartName": "Adjustment Tool, window regulator",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Window Regulator"
        },
        "08854": {
            "GenartName": "Extension Set, dial gauge",
            "NormTermName": "Extension Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Dial Gauge"
        },
        "08855": {
            "GenartName": "Bore Measurement Tool",
            "NormTermName": "Bore Measurement Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08856": {
            "GenartName": "Breaker Bar",
            "NormTermName": "Breaker Bar",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08857": {
            "GenartName": "Disposable Gloves",
            "NormTermName": "Disposable Gloves",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Protective Gloves"
        },
        "08858": {
            "GenartName": "Clutch Basket Holding Tool",
            "NormTermName": "Holding Tool",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Clutch"
        },
        "08859": {
            "GenartName": "Collection Pan, oil drum attachment",
            "NormTermName": "Collection Pan",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08860": {
            "GenartName": "Spray Bottle, compressed air",
            "NormTermName": "Spray Bottle",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08861": {
            "GenartName": "Adjusting Tool, camshaft",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft"
        },
        "08862": {
            "GenartName": "Adjusting Tool Set, camshaft",
            "NormTermName": "Adjustment Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft"
        },
        "08863": {
            "GenartName": "Mounting Tool Set, camshaft",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft"
        },
        "08864": {
            "GenartName": "Retaining Tool, camshaft gear/sprocket",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Camshaft Gear/Sprocket"
        },
        "08865": {
            "GenartName": "Retaining Tool Set, camshaft gear/sprocket",
            "NormTermName": "Retaining Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Camshaft Gear/Sprocket"
        },
        "08866": {
            "GenartName": "Disassembly Tool, EGR valve",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Exhaust Gas Recirculation (EGR)"
        },
        "08867": {
            "GenartName": "Blind Rivet Adapter, drill",
            "NormTermName": "Blind Rivet Adapter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drill"
        },
        "08868": {
            "GenartName": "Insulation Knife",
            "NormTermName": "Insulation Knife",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08869": {
            "GenartName": "Centre Punch Set",
            "NormTermName": "Centre Punch Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08870": {
            "GenartName": "Blade, pipe deburring device",
            "NormTermName": "Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": "Deburring Tool, pipes"
        },
        "08871": {
            "GenartName": "Adapter, compression pressure tester",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Compression Pressure Tester"
        },
        "08872": {
            "GenartName": "Pin Set (pair), face spanner",
            "NormTermName": "Pin Set (pair)",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Face Spanner"
        },
        "08873": {
            "GenartName": "Wire Rope Winch",
            "NormTermName": "Wire Rope Winch",
            "AssGrpName": "Trailer",
            "UsageName": null
        },
        "08875": {
            "GenartName": "Clip-on Rail, socket wrench Insert",
            "NormTermName": "Plug-on Rail",
            "AssGrpName": "Tool, universal",
            "UsageName": "Socket Wrench Insert"
        },
        "08876": {
            "GenartName": "Adapter, common rail injector tester",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Common Rail Injector Tester"
        },
        "08877": {
            "GenartName": "Timing Chain Rivet Extraction/Insertion Device",
            "NormTermName": "Timing Chain Rivet Extraction/Insertion Device",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08878": {
            "GenartName": "Adapter, timing chain rivet extraction/insertion device",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08879": {
            "GenartName": "Rubber Ring, cooling system pressure test set",
            "NormTermName": "Rubber Ring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cooling System Pressure Test Set"
        },
        "08880": {
            "GenartName": "Bulb, voltage tester",
            "NormTermName": "Bulb",
            "AssGrpName": "Tool, universal",
            "UsageName": "Voltage Tester"
        },
        "08881": {
            "GenartName": "Power Socket, drive shaft",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Drive Shaft"
        },
        "08882": {
            "GenartName": "Coupling/Variomatic Tool",
            "NormTermName": "Coupling/Variomatic Tool",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "08883": {
            "GenartName": "Removal Tool, door handle",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Door Handle"
        },
        "08884": {
            "GenartName": "Mounting Bolt Kit, brake caliper",
            "NormTermName": "Mounting Bolt Kit",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Caliper"
        },
        "08885": {
            "GenartName": "Heat-Protection Tape",
            "NormTermName": "Heat-Protection Tape",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": null
        },
        "08886": {
            "GenartName": "Heat-Protection Tubing",
            "NormTermName": "Heat-Protection Tubing",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": null
        },
        "08887": {
            "GenartName": "Wheel Lock Removal Kit",
            "NormTermName": "Removal Kit",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Lock"
        },
        "08888": {
            "GenartName": "Measurement Tube Set, fuel return quantity measurement",
            "NormTermName": "Measurement Tube Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Fuel Return Quantity Measurement"
        },
        "08889": {
            "GenartName": "Holding Tool Set, belt pulley",
            "NormTermName": "Holding Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08890": {
            "GenartName": "Thrust Piece, dual clutch tool",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08891": {
            "GenartName": "Adapter, hose connector",
            "NormTermName": "Adaptor",
            "AssGrpName": "Standard Parts",
            "UsageName": "Hose Connector"
        },
        "08892": {
            "GenartName": "Carry Case",
            "NormTermName": "Carry Case",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08893": {
            "GenartName": "Shopping Trolley Coin",
            "NormTermName": "Shopping Trolley Coin",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08894": {
            "GenartName": "Lighter",
            "NormTermName": "Lighter",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08895": {
            "GenartName": "Bottle Opener",
            "NormTermName": "Bottle Opener",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08896": {
            "GenartName": "Football",
            "NormTermName": "Football",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08897": {
            "GenartName": "Coffee Mug",
            "NormTermName": "Coffee Mug",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08898": {
            "GenartName": "Ballpoint Pen",
            "NormTermName": "Ballpoint Pen",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08899": {
            "GenartName": "Poster",
            "NormTermName": "Poster",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08900": {
            "GenartName": "Blotter",
            "NormTermName": "Blotter",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08901": {
            "GenartName": "Flag",
            "NormTermName": "Flag",
            "AssGrpName": "Promotional Items",
            "UsageName": null
        },
        "08902": {
            "GenartName": "Bracket, parking brake",
            "NormTermName": "Bracket",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake"
        },
        "08903": {
            "GenartName": "Pressure Sensor, brake booster",
            "NormTermName": "Sensor",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Booster"
        },
        "08904": {
            "GenartName": "Thermal Insulation, heat shield",
            "NormTermName": "Thermal Insulation",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Heat Shield"
        },
        "08905": {
            "GenartName": "Cable Repair Set, radiator fan",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator Fan"
        },
        "08906": {
            "GenartName": "Cable Repair Set, radiator fan",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Radiator Fan"
        },
        "08907": {
            "GenartName": "Seal Set, steering gear input shaft",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Steering",
            "UsageName": "Steering Gear Input Shaft"
        },
        "08908": {
            "GenartName": "Cable Repair Set, high beam/low beam switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "High Beam/Low Beam Switch"
        },
        "08909": {
            "GenartName": "Cable Repair Set, speed sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Instruments",
            "UsageName": "Speed Sensor"
        },
        "08910": {
            "GenartName": "Cable Repair Set, horn/signal horn relay",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Signal System",
            "UsageName": "Relay, horn/signal horn"
        },
        "08911": {
            "GenartName": "Repair Sleeve, transmission output shaft (manual trans.)",
            "NormTermName": "Repair Sleeve",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Output Shaft (manual transmission)"
        },
        "08912": {
            "GenartName": "Edge Protection / Sealing Rubber Solvent",
            "NormTermName": "Edge Protection / Sealing Rubber Solvent",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08913": {
            "GenartName": "Tank Sender Unit Key",
            "NormTermName": "Tank Sender Unit Key",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "08914": {
            "GenartName": "Bearing Drift Set",
            "NormTermName": "Bearing Drift Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08915": {
            "GenartName": "Bearing, compressor shaft",
            "NormTermName": "Bearing",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Compressor Shaft"
        },
        "08916": {
            "GenartName": "Cable Repair Set, switch stalk",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Instruments",
            "UsageName": "Steering Column Switch"
        },
        "08917": {
            "GenartName": "Press-out Disc, drive shaft",
            "NormTermName": "Press-out Disc",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Drive Shaft"
        },
        "08919": {
            "GenartName": "Repair Kit, window cleaning system",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Window Cleaning System"
        },
        "08921": {
            "GenartName": "Visor, welder's helmet",
            "NormTermName": "Washer",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "08922": {
            "GenartName": "Puller, toothed belt pulley",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": "Toothed Belt Sprocket"
        },
        "08923": {
            "GenartName": "Sharpening Device, drill",
            "NormTermName": "Sharpening Device",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08924": {
            "GenartName": "Mounting Disc, tile drill",
            "NormTermName": "Mounting Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tile Drill"
        },
        "08925": {
            "GenartName": "Fine Drill Set",
            "NormTermName": "Fine Drill Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08926": {
            "GenartName": "Wood Drill Bit Set",
            "NormTermName": "Wood Drill Bit Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08927": {
            "GenartName": "Multi Drill Bit Set",
            "NormTermName": "Multi Drill Bit Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08928": {
            "GenartName": "Wood Auger Drill Bit Set",
            "NormTermName": "Wood Auger Drill Bit Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Auger Bit"
        },
        "08929": {
            "GenartName": "Holding Pliers, clutch",
            "NormTermName": "Holding Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Clutch"
        },
        "08930": {
            "GenartName": "Release Pliers, muffler rubber mount",
            "NormTermName": "Release Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Exhaust System"
        },
        "08931": {
            "GenartName": "Flaring Pliers",
            "NormTermName": "Flaring Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Body"
        },
        "08932": {
            "GenartName": "Special Glue Set",
            "NormTermName": "Special Glue Set",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "08933": {
            "GenartName": "Key, CNG cylinder valve",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Gas Cylinder"
        },
        "08934": {
            "GenartName": "Nuts/Cap Wrench Set",
            "NormTermName": "Nuts/Cap Wrench Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08935": {
            "GenartName": "Numeral Punches",
            "NormTermName": "Numeral Punches",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08936": {
            "GenartName": "Reset Tool, clutch",
            "NormTermName": "Reset Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Clutch"
        },
        "08937": {
            "GenartName": "Saw Chains Sharpening Device",
            "NormTermName": "Sharpening Device",
            "AssGrpName": "Tool, universal",
            "UsageName": "Saw Chains"
        },
        "08938": {
            "GenartName": "Valve Insertion Tool Set, tyre",
            "NormTermName": "Valve Insertion Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tyre valve"
        },
        "08939": {
            "GenartName": "Reflective Strips, rev counter",
            "NormTermName": "Reflective Strips",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Rev Counter"
        },
        "08940": {
            "GenartName": "Release Tools",
            "NormTermName": "Release Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08941": {
            "GenartName": "Release Tool Set",
            "NormTermName": "Release Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "08942": {
            "GenartName": "Tool Set, trailing arm rubber bush",
            "NormTermName": "Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Control/Trailing Arm"
        },
        "08943": {
            "GenartName": "Tool Set, trailing arm bush",
            "NormTermName": "Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Control/Trailing Arm"
        },
        "08944": {
            "GenartName": "Axle Kingpin Set",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Steering Knuckle"
        },
        "08945": {
            "GenartName": "Repair Sleeve, shift rod (manual transmission)",
            "NormTermName": "Repair Sleeve",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shift Rod (manual transmission)"
        },
        "08946": {
            "GenartName": "Collector Bearing, alternator",
            "NormTermName": "Bearing",
            "AssGrpName": "Alternator",
            "UsageName": "Collector Bearing, alternator"
        },
        "08947": {
            "GenartName": "Cable Repair Set, RPM sensor (automatic transmission)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "RPM Sensor (automatic transmission)"
        },
        "08948": {
            "GenartName": "Cable Repair Set, RPM sensor (manual transmission)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Manual Transmission",
            "UsageName": "RPM Sensor (manual transmission)"
        },
        "08949": {
            "GenartName": "Repair Sleeve, transmission input shaft (transfer case)",
            "NormTermName": "Repair Sleeve",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transmission Input Shaft (transfer case)"
        },
        "08950": {
            "GenartName": "Changeover Valve, fuel tank",
            "NormTermName": "Valve",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank"
        },
        "08951": {
            "GenartName": "Aligning Tool, vibration damper (crankshaft)",
            "NormTermName": "Alignment Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft"
        },
        "08952": {
            "GenartName": "Cable Repair Set, rear fog light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Rear Fog Light"
        },
        "08953": {
            "GenartName": "Ejector, control arm bushing",
            "NormTermName": "Ejector",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Control Arm"
        },
        "08954": {
            "GenartName": "Cable Repair Set, fog light switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Fog Light Switch"
        },
        "08955": {
            "GenartName": "Cable Repair Set, headlight/fog light switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Headlight/Fog Light Switch"
        },
        "08956": {
            "GenartName": "Carving Tool Set",
            "NormTermName": "Carving Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08957": {
            "GenartName": "Cable Repair Set, air con. compressor magnetic clutch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Air Conditioning Compressor Magnetic Clutch"
        },
        "08958": {
            "GenartName": "Cable Repair Set, magn. clutch relay (A/C compressor)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Magnetic Clutch Relay (air conditioning compressor)"
        },
        "08959": {
            "GenartName": "Bulb, sun visor light",
            "NormTermName": "Bulb",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Sun Visor Light"
        },
        "08960": {
            "GenartName": "Adjusting Tool, balance shaft",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Balance Shaft"
        },
        "08961": {
            "GenartName": "Retaining Tool, balancer shaft",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Balance Shaft"
        },
        "08962": {
            "GenartName": "Compressed Air Mounting Device, axle boot",
            "NormTermName": "Compressed Air Mounting Device",
            "AssGrpName": "Tool, universal",
            "UsageName": "Axle Boot"
        },
        "08963": {
            "GenartName": "Cable Repair Set, fuel pump relay",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump Relay"
        },
        "08964": {
            "GenartName": "Cable Repair Set, interior fan motor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Fan Motor"
        },
        "08965": {
            "GenartName": "Calibration Tool, accelerator pedal",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08966": {
            "GenartName": "Disassembly Tool Set, combination rear light",
            "NormTermName": "Disassembly Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Combination Rear Light"
        },
        "08967": {
            "GenartName": "Drift Set, fork sealing ring",
            "NormTermName": "Drift Set",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Motorcycle"
        },
        "08968": {
            "GenartName": "Disassembly Tool, plug connector",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Plug Connector"
        },
        "08969": {
            "GenartName": "Bulb, engine indicator lamp",
            "NormTermName": "Bulb",
            "AssGrpName": "Instruments",
            "UsageName": "Engine Indicator Lamp"
        },
        "08970": {
            "GenartName": "Bracket, ignition coil",
            "NormTermName": "Holder",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition Coil"
        },
        "08971": {
            "GenartName": "Cable Repair Set, interior fan relay",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Interior Fan Relay"
        },
        "08972": {
            "GenartName": "Alignment Tool, front axle",
            "NormTermName": "Alignment Tool",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Motorcycle"
        },
        "08973": {
            "GenartName": "Disassembly Tool, cigarette lighter socket",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cigarette Lighter"
        },
        "08974": {
            "GenartName": "Repair Kit, cleaning gun (compressed air)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08975": {
            "GenartName": "Replacement Nozzle, spray bottle",
            "NormTermName": "Replacement Nozzle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Compressed Air"
        },
        "08976": {
            "GenartName": "Repair Insert, breather valve",
            "NormTermName": "Repair Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Caliper (brake system bleeding)"
        },
        "08977": {
            "GenartName": "Bowden Cable Oiling Device",
            "NormTermName": "Bowden Cable Oiling Device",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08978": {
            "GenartName": "Cable Repair Set, cooling fan relay",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling System",
            "UsageName": "Cooling Fan Relay"
        },
        "08979": {
            "GenartName": "Sanitary Installation Key",
            "NormTermName": "Sanitary Installation Key",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08980": {
            "GenartName": "Relay, windscreen wipe interval",
            "NormTermName": "Relay",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Windscreen Wipe Interval"
        },
        "08981": {
            "GenartName": "Sanitary Installation Key Set",
            "NormTermName": "Sanitary Installation Key Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "08982": {
            "GenartName": "Seal Ring, oil dipstick guide (automatic transmission)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil Dipstick Guide (automatic transmission)"
        },
        "08983": {
            "GenartName": "Relay, daytime running light",
            "NormTermName": "Relay",
            "AssGrpName": "Lights",
            "UsageName": "Daytime Running Light"
        },
        "08984": {
            "GenartName": "Shim, castor/camber adjustment",
            "NormTermName": "Washer",
            "AssGrpName": "Steering",
            "UsageName": "Castor/Camber Adjustment"
        },
        "08985": {
            "GenartName": "Seal Set, servo-assisted steering valve",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Steering",
            "UsageName": "Servo-Assisted Steering Valve"
        },
        "08986": {
            "GenartName": "Seal Set, transmission output shaft (man. trans.)",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Output Shaft (manual transmission)"
        },
        "08987": {
            "GenartName": "Relay, mass air flow sensor",
            "NormTermName": "Relay",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Air Mass Sensor"
        },
        "08988": {
            "GenartName": "Seal, automatic transmission oil pump",
            "NormTermName": "Seal",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission Oil Pump"
        },
        "08989": {
            "GenartName": "Screw, crown gear differential",
            "NormTermName": "Screw",
            "AssGrpName": "Axle Drive",
            "UsageName": "Crown Gear Differential"
        },
        "08990": {
            "GenartName": "Cable Repair Set, windscreen wash pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Wash Waterpump, windscreen cleaning"
        },
        "08991": {
            "GenartName": "Cable Repair Set, cigarette lighter",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Cigarette Lighter"
        },
        "08992": {
            "GenartName": "Cable Repair Set, fuel tank changeover valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Tank Changeover Valve"
        },
        "08993": {
            "GenartName": "Relay, park light",
            "NormTermName": "Relay",
            "AssGrpName": "Lights",
            "UsageName": "Park Light"
        },
        "08994": {
            "GenartName": "Cable Repair Set, central locking actuator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System",
            "UsageName": "Central Locking Actuator"
        },
        "08995": {
            "GenartName": "Thrust Plate, brake caliper piston reset tool",
            "NormTermName": "Pressure Plate",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Reset Tool, brake caliper piston"
        },
        "08996": {
            "GenartName": "Spreader Insert Set, suspension strut",
            "NormTermName": "Spreader Insert Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Suspension Strut"
        },
        "08997": {
            "GenartName": "Spreader Insert, suspension strut",
            "NormTermName": "Spreader Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Suspension Strut"
        },
        "08998": {
            "GenartName": "Sealing Seat Milling Head, injector nozzle",
            "NormTermName": "Deep Seat Milling Cutter",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector Nozzle"
        },
        "08999": {
            "GenartName": "Adapter, hydraulic pump",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hydraulic Pump"
        },
        "09000": {
            "GenartName": "Hose, hydraulic pump",
            "NormTermName": "Hose",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hydraulic Pump"
        },
        "09001": {
            "GenartName": "Boot Set, propshaft",
            "NormTermName": "Boot Set",
            "AssGrpName": "Axle Drive, universal",
            "UsageName": "Propshaft"
        },
        "09002": {
            "GenartName": "Mounting Tool Set, V-ribbed belt",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "V-Ribbed Belt"
        },
        "09003": {
            "GenartName": "Clevis Set, ball joint spreader",
            "NormTermName": "Clevis Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ball Joint Spreader"
        },
        "09004": {
            "GenartName": "Axle Nut Insert Set, wheel",
            "NormTermName": "Axle Nut Insert Set",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Wheel Mounting"
        },
        "09005": {
            "GenartName": "Acrylic Glass Insert, sales wall",
            "NormTermName": "Insert",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Sales Wall"
        },
        "09006": {
            "GenartName": "Mitre Box",
            "NormTermName": "Mitre Box",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09007": {
            "GenartName": "Draw-in Tool Set, drive shaft",
            "NormTermName": "Setter Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Drive Shaft"
        },
        "09008": {
            "GenartName": "Socket Wrench Insert, wheel nut/bolt",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Nut/Bolt"
        },
        "09009": {
            "GenartName": "Socket Wrench Insert, injection line",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injection Pipe"
        },
        "09010": {
            "GenartName": "Puller, spindle nut",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "09011": {
            "GenartName": "Work Safety Set",
            "NormTermName": "Work Safety Set",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "09012": {
            "GenartName": "Measurement Rod, belt drive",
            "NormTermName": "Measurement Rod",
            "AssGrpName": "Tool, universal",
            "UsageName": "Belt Drive"
        },
        "09013": {
            "GenartName": "Assortment, clips",
            "NormTermName": "Assortment",
            "AssGrpName": "Body",
            "UsageName": "Body Holding Clip"
        },
        "09014": {
            "GenartName": "Connection Piece, injector nozzle test device",
            "NormTermName": "Connection Piece",
            "AssGrpName": "Tool, universal",
            "UsageName": "Injector Nozzle Tester"
        },
        "09015": {
            "GenartName": "Container, injector nozzle test device",
            "NormTermName": "Tank",
            "AssGrpName": "Tool, universal",
            "UsageName": "Injector Nozzle Tester"
        },
        "09016": {
            "GenartName": "Sensor, level control",
            "NormTermName": "Sensor",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leveling Control"
        },
        "09017": {
            "GenartName": "Seal, transmission output shaft (man. trans.)",
            "NormTermName": "Seal",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Output Shaft (manual transmission)"
        },
        "09018": {
            "GenartName": "Shaft Seal Set, crankshaft",
            "NormTermName": "Seal Ring Set",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft"
        },
        "09019": {
            "GenartName": "Seal, trans. output shaft (automatic transmission)",
            "NormTermName": "Seal",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Transmission Output Shaft (automatic transmission)"
        },
        "09020": {
            "GenartName": "Seal, trans. input shaft (manual transmission)",
            "NormTermName": "Seal",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Input Shaft (manual transmission)"
        },
        "09021": {
            "GenartName": "Bearing, transmission output shaft (transfer case)",
            "NormTermName": "Bearing",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transmission Output Shaft (transfer case)"
        },
        "09022": {
            "GenartName": "Sensor, charge air temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Charge Air Temperature"
        },
        "09023": {
            "GenartName": "Seal, transmission output shaft (transfer case)",
            "NormTermName": "Seal",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transmission Output Shaft (transfer case)"
        },
        "09024": {
            "GenartName": "Jet Gauge Set, fuel jet",
            "NormTermName": "Jet Gauge Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Fuel Jet"
        },
        "09025": {
            "GenartName": "Mounting Wedge Set",
            "NormTermName": "Mounting Wedge Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "09026": {
            "GenartName": "Open-Ended Spanner Set",
            "NormTermName": "Open-Ended Spanner Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09027": {
            "GenartName": "Cable Repair Set, air con. compressor series resistor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Air Con. Compressor Series Resistor"
        },
        "09028": {
            "GenartName": "Puller Set, cylinder liner",
            "NormTermName": "Puller Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Cylinder Sleeve"
        },
        "09029": {
            "GenartName": "Pull-Piece, riveting pliers",
            "NormTermName": "Pull-Piece",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09030": {
            "GenartName": "Eccentric Bush, steering knuckle",
            "NormTermName": "Bush",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Eccentric Bush Stub Axle"
        },
        "09031": {
            "GenartName": "Differential Lock Module",
            "NormTermName": "Differential Lock Module",
            "AssGrpName": "Axle Drive",
            "UsageName": null
        },
        "09032": {
            "GenartName": "Trim Pry Tool",
            "NormTermName": "Trim Pry Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09033": {
            "GenartName": "Cable Repair Set, alternator regulator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Alternator",
            "UsageName": "Alternator Regulator"
        },
        "09034": {
            "GenartName": "Trim Pry Tool Set",
            "NormTermName": "Trim Pry Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09035": {
            "GenartName": "Bulb Socket, side marker lights",
            "NormTermName": "Bulb Socket",
            "AssGrpName": "Lights",
            "UsageName": "Side Marker Light"
        },
        "09036": {
            "GenartName": "Guide Bush, glow plug removal tool",
            "NormTermName": "Guide Bush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glow-plug removal tool"
        },
        "09037": {
            "GenartName": "RPM Sensor, trans. output shaft (auto. trans.)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Transmission Output Shaft (automatic transmission)"
        },
        "09038": {
            "GenartName": "Bearing, differential housing",
            "NormTermName": "Bearing",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential Housing"
        },
        "09039": {
            "GenartName": "Seal Ring, worm shaft steering gear",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Steering",
            "UsageName": "Worm Shaft Steering Gear"
        },
        "09040": {
            "GenartName": "Pulling Pin, glow plug removal tool",
            "NormTermName": "Pulling Pin",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glow-plug removal tool"
        },
        "09041": {
            "GenartName": "Rocker Arm Pin",
            "NormTermName": "Bolt",
            "AssGrpName": "Engine Timing",
            "UsageName": "Rocker Arm Pin"
        },
        "09042": {
            "GenartName": "Relay, ignition system",
            "NormTermName": "Relay",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition System"
        },
        "09043": {
            "GenartName": "Rocker Arm Ball",
            "NormTermName": "Rocker Arm Ball",
            "AssGrpName": "Engine Timing",
            "UsageName": null
        },
        "09044": {
            "GenartName": "Assortment, insulating tape",
            "NormTermName": "Assortment",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "09045": {
            "GenartName": "Socket Wrench Insert, injection pump",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injection Pump"
        },
        "09046": {
            "GenartName": "Tank Sender Unit Key",
            "NormTermName": "Tank Sender Unit Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Sender Unit, fuel tank"
        },
        "09048": {
            "GenartName": "Gauge, charge checking light",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Charge Checking Light"
        },
        "09049": {
            "GenartName": "Tank Sender Unit Key Set",
            "NormTermName": "Tank Sender Unit Key Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Sender Unit, fuel tank"
        },
        "09050": {
            "GenartName": "Wheel Sensor Set, tyre-pressure monitoring system",
            "NormTermName": "Sensor Kit",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "09051": {
            "GenartName": "Seal, differential housing cover",
            "NormTermName": "Seal",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential Housing Cover"
        },
        "09052": {
            "GenartName": "Steel Lever, vice",
            "NormTermName": "Steel Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Vice"
        },
        "09053": {
            "GenartName": "Camber Correction Screw Set",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Camber Adjustment"
        },
        "09054": {
            "GenartName": "Ring, differential housing bearing",
            "NormTermName": "Ring",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential Housing Bearing"
        },
        "09055": {
            "GenartName": "Intermediate Shaft Guide, oil pump",
            "NormTermName": "Guide",
            "AssGrpName": "Lubrication",
            "UsageName": "Intermediate Shaft Guide Oil Pump"
        },
        "09056": {
            "GenartName": "Seal Set, transmission output shaft (auto. trans.)",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Transmission Output Shaft (automatic transmission)"
        },
        "09057": {
            "GenartName": "Seal Ring, automatic transmission oil pump",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission Oil Pump"
        },
        "09058": {
            "GenartName": "Repair Kit, air conditioning evaporator",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Air Conditioning Evaporator"
        },
        "09059": {
            "GenartName": "Fastener Clamp Set, steel piping",
            "NormTermName": "Fastener Clamp Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09060": {
            "GenartName": "Cutting Wheel Axle, pipe cutter",
            "NormTermName": "Cutting Wheel Axle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pipe Cutter"
        },
        "09061": {
            "GenartName": "Gauge Set, tie rod",
            "NormTermName": "Gauge Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Tie Rod"
        },
        "09062": {
            "GenartName": "Socket, track rod end",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Track Rod End"
        },
        "09063": {
            "GenartName": "Puller, spindle nut",
            "NormTermName": "Puller",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09064": {
            "GenartName": "Assortment, expansion anchors / anchor plugs",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "09065": {
            "GenartName": "Suction Cup, windscreen set-down table",
            "NormTermName": "Suction Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09066": {
            "GenartName": "Hose, cooling system test set",
            "NormTermName": "Hose",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Cooling System Pressure Test Set"
        },
        "09067": {
            "GenartName": "Assortment, hex. nuts",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "09068": {
            "GenartName": "Assortment, carabiner hooks",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "09069": {
            "GenartName": "Assortment, screw hooks",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "09070": {
            "GenartName": "Screw-Locking Wire",
            "NormTermName": "Screw-Locking Wire",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "09071": {
            "GenartName": "Sanding Pad Mount",
            "NormTermName": "Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09072": {
            "GenartName": "Mounting Tool Set, brake piston",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Brake Caliper Piston"
        },
        "09073": {
            "GenartName": "Diamond Core Drill Bit Set",
            "NormTermName": "Diamond Core Drill Bit Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09074": {
            "GenartName": "Earth Cable, ignition coil",
            "NormTermName": "Earth Cable",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition Coil"
        },
        "09075": {
            "GenartName": "Repair Kit, breaker bar",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Breaker Bar"
        },
        "09076": {
            "GenartName": "Repair Kit, needle scaler (compressed air)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Needle Scaler"
        },
        "09077": {
            "GenartName": "Ventilation tool set, fuel system",
            "NormTermName": "Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Fuel system"
        },
        "09078": {
            "GenartName": "Feeler Gauge",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "09079": {
            "GenartName": "Adjustment Gauge, clutch control",
            "NormTermName": "Feeler Gauge",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Basic Setting, clutch control"
        },
        "09080": {
            "GenartName": "Retaining Tool, injection pump",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injection Pump"
        },
        "09081": {
            "GenartName": "Relay, rotating beacon",
            "NormTermName": "Relay",
            "AssGrpName": "Signal System",
            "UsageName": "Rotating Beacon"
        },
        "09082": {
            "GenartName": "Container, oil bleeding device",
            "NormTermName": "Tank",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09083": {
            "GenartName": "Seal Set, push rod",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Engine Timing",
            "UsageName": "Push Rod"
        },
        "09084": {
            "GenartName": "Sleeve Set, cable connector release tool",
            "NormTermName": "Sleeve Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cable Connector"
        },
        "09085": {
            "GenartName": "Hydraulic Cylinder, workshop crane",
            "NormTermName": "Hydraulic Cylinder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Workshop Crane"
        },
        "09086": {
            "GenartName": "Bleed Tool Set, master brake cylinder",
            "NormTermName": "Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Master Cylinder"
        },
        "09087": {
            "GenartName": "Replacement Cap, refractometer",
            "NormTermName": "Replacement Cap",
            "AssGrpName": "Tool, universal",
            "UsageName": "Refractometer"
        },
        "09088": {
            "GenartName": "Fork Cap Socket",
            "NormTermName": "Fork Cap Socket",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": null
        },
        "09089": {
            "GenartName": "Pulse Generator Wheel, distributor",
            "NormTermName": "Pulse Generator Wheel",
            "AssGrpName": "Ignition System",
            "UsageName": "Distributor"
        },
        "09090": {
            "GenartName": "Drift, fork sealing ring",
            "NormTermName": "Punsh",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Fork (motorcycle)"
        },
        "09091": {
            "GenartName": "Gear Set, window motor",
            "NormTermName": "Gear Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Window Motor"
        },
        "09092": {
            "GenartName": "Roller, workshop creeper",
            "NormTermName": "Pulley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Creeper"
        },
        "09093": {
            "GenartName": "Shim, flywheel",
            "NormTermName": "Washer",
            "AssGrpName": "Clutch",
            "UsageName": "Flywheel"
        },
        "09094": {
            "GenartName": "Turning Tool Set, turning machine",
            "NormTermName": "Turning Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Turning Machine"
        },
        "09095": {
            "GenartName": "Assortment, feather keys",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "09096": {
            "GenartName": "Refill Set, stapler",
            "NormTermName": "Refill Set",
            "AssGrpName": "Standard Parts",
            "UsageName": "Stapler"
        },
        "09097": {
            "GenartName": "Assortment, tension/compression springs",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "09098": {
            "GenartName": "Roller, workshop stool",
            "NormTermName": "Pulley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Stool"
        },
        "09099": {
            "GenartName": "Sensor, leak-finding device (air conditioning)",
            "NormTermName": "Probe",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning"
        },
        "09100": {
            "GenartName": "Cable Repair Set, steering hydraulic pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Steering",
            "UsageName": "Hydraulic Pump Steering"
        },
        "09101": {
            "GenartName": "Replacement Tip, centre punch",
            "NormTermName": "Replacement Tip",
            "AssGrpName": "Tool, universal",
            "UsageName": "Centre Punch"
        },
        "09102": {
            "GenartName": "Replacement Tip, sandblasting gun",
            "NormTermName": "Replacement Tip",
            "AssGrpName": "Tool, universal",
            "UsageName": "Sandblasting Gun"
        },
        "09103": {
            "GenartName": "Assortment, push-in eyelets",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Eyelet Pliers"
        },
        "09104": {
            "GenartName": "Assortment, crocodile clips",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "09105": {
            "GenartName": "Cable Repair Set, air quality sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Air Quality Sensor"
        },
        "09106": {
            "GenartName": "Socket Wrench Set, transmission flange",
            "NormTermName": "Socket Wrench Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Gearbox Flange"
        },
        "09107": {
            "GenartName": "Cable Repair Set, siren alarm system",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System",
            "UsageName": "Siren Alarm System"
        },
        "09108": {
            "GenartName": "Cable Repair Set, air quality sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Air Quality Sensor"
        },
        "09109": {
            "GenartName": "Cable Repair Set, siren alarm system",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System, universal",
            "UsageName": null
        },
        "09110": {
            "GenartName": "Cable Repair Set, bonnet contact switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Body",
            "UsageName": "Bonnet Contact Switch"
        },
        "09111": {
            "GenartName": "Cable Repair Set, bonnet contact switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Bonnet Contact Switch"
        },
        "09112": {
            "GenartName": "Windscreen Removal Cord",
            "NormTermName": "Windscreen Removal Cord",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glass removal"
        },
        "09113": {
            "GenartName": "Cable Repair Set, longitudinal/lateral acceleration sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Drive Dynamics Control",
            "UsageName": "Longitudinal/Lateral Acceleration Sensor"
        },
        "09114": {
            "GenartName": "Cable Repair Set, longitudinal/lateral acceleration sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Drive Dynamics Control, universal",
            "UsageName": "Longitudinal/Lateral Acceleration Sensor"
        },
        "09115": {
            "GenartName": "Roller, mobile jack",
            "NormTermName": "Pulley",
            "AssGrpName": "Tool, universal",
            "UsageName": "Mobile Jack"
        },
        "09116": {
            "GenartName": "Assortment, eye set",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": "Eyelet Pliers"
        },
        "09117": {
            "GenartName": "Cable Repair Kit, coolant control valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Coolant Control Valve"
        },
        "09118": {
            "GenartName": "Cable Repair Kit, coolant control valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation, universal",
            "UsageName": "Coolant Control Valve"
        },
        "09119": {
            "GenartName": "Cable Repair Set, steering hydraulic pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Steering, universal",
            "UsageName": "Hydraulic Pump Steering"
        },
        "09120": {
            "GenartName": "Cable Repair Set, steering",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Steering",
            "UsageName": "Steering"
        },
        "09121": {
            "GenartName": "Cable Repair Set, steering",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Steering, universal",
            "UsageName": "Steering"
        },
        "09122": {
            "GenartName": "Pull-Through Wire",
            "NormTermName": "Pull-Through Wire",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "09123": {
            "GenartName": "Dies Set",
            "NormTermName": "Dies Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09124": {
            "GenartName": "Lever, control arm",
            "NormTermName": "Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Control Arm"
        },
        "09125": {
            "GenartName": "Mounting Tool Set, crankshaft seal/pilot bearing",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft Seal/Pilot Bearing"
        },
        "09126": {
            "GenartName": "Mounting Tool, radial sealing ring",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft Seal"
        },
        "09127": {
            "GenartName": "Mounting Tool, radial sealing ring",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09128": {
            "GenartName": "Oil Syringe",
            "NormTermName": "Oil Syringe",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09129": {
            "GenartName": "Headband, safety helmet",
            "NormTermName": "Headband",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Safety Helmet"
        },
        "09130": {
            "GenartName": "Roller, transmission jack",
            "NormTermName": "Pulley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Transmission Jack"
        },
        "09131": {
            "GenartName": "Assortment, spring washers",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "09132": {
            "GenartName": "Clamping and Adjusting Device, suspension strut",
            "NormTermName": "Clamping and Adjusting Device",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Suspension Strut"
        },
        "09133": {
            "GenartName": "Grease Marker Pens",
            "NormTermName": "Grease Marker Pens",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09134": {
            "GenartName": "Mounting Lever, transmission",
            "NormTermName": "Mounting Lever",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Transmission"
        },
        "09135": {
            "GenartName": "CVT Drive Belt",
            "NormTermName": "Belt",
            "AssGrpName": "Belt Drive, universal",
            "UsageName": "CVT"
        },
        "09136": {
            "GenartName": "Vernier Calliper, lift cylinder height",
            "NormTermName": "Vernier Caliper",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "09137": {
            "GenartName": "Thrust Piece, wiper arm",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wiper Arm"
        },
        "09138": {
            "GenartName": "Storage Compartment Set, tool trolley",
            "NormTermName": "Storage Compartment Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "09139": {
            "GenartName": "Bracket, alternator drive flange",
            "NormTermName": "Holder",
            "AssGrpName": "Special Tools, motorcycle, universal",
            "UsageName": "Alternator"
        },
        "09140": {
            "GenartName": "Repair Kit, wiper arm",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wiper Arm"
        },
        "09141": {
            "GenartName": "Compression Pressure Test Set",
            "NormTermName": "Compression Pressure Test Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Engine"
        },
        "09142": {
            "GenartName": "Core Drill Bit Set",
            "NormTermName": "Core Drill Bit Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drill"
        },
        "09143": {
            "GenartName": "Tile Lifter",
            "NormTermName": "Tile Lifter",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "09144": {
            "GenartName": "Seal, fill/extraction pump",
            "NormTermName": "Seal",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09145": {
            "GenartName": "Non-Heat. Appliance Socket, fill/bleeding unit (brake hydr.)",
            "NormTermName": "Non-Heating Appliance Socket",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "09146": {
            "GenartName": "Membrane Keypad, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Membrane Keypad",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "09147": {
            "GenartName": "Board, filling/bleeding unit (brake hydraulics)",
            "NormTermName": "Board",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Filling/Bleeding Unit, (brake hydraulics)"
        },
        "09148": {
            "GenartName": "Expansion Set, installation tool (clutch)",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Clutch"
        },
        "09149": {
            "GenartName": "Joint Socket Wrench Insert, transmission oil plug",
            "NormTermName": "Joint Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Transmission Oil"
        },
        "09150": {
            "GenartName": "Adjustment Plate, clamping & adjusting device (susp. strut)",
            "NormTermName": "Adjustment Plate",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Clamping and Adjusting Device (suspension strut)"
        },
        "09151": {
            "GenartName": "Puller Housing, wiper arm puller",
            "NormTermName": "Puller Housing",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wiper Arm Puller"
        },
        "09152": {
            "GenartName": "Expansion Set, clamping & adjusting device (susp. strut)",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Clamping and Adjusting Device (suspension strut)"
        },
        "09153": {
            "GenartName": "Main Body, clutch centring tool",
            "NormTermName": "Main Body",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Clutch"
        },
        "09154": {
            "GenartName": "Adapter, clutch centring tool",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Clutch"
        },
        "09155": {
            "GenartName": "Guide Bolt, press in/out tool",
            "NormTermName": "Guide Bolt",
            "AssGrpName": "Tool, universal",
            "UsageName": "Press-in-/Extraction Tools"
        },
        "09156": {
            "GenartName": "Puller, wheel hub",
            "NormTermName": "Extractor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Hub"
        },
        "09157": {
            "GenartName": "Puller Set, wheel hub",
            "NormTermName": "Puller Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Hub"
        },
        "09158": {
            "GenartName": "Spreader Insert, suspension strut",
            "NormTermName": "Spreader Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Suspension Strut"
        },
        "09159": {
            "GenartName": "Expansion Set, spreader insert (suspension strut)",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Suspension Strut"
        },
        "09160": {
            "GenartName": "Pressing Screw, spreader insert (suspension strut)",
            "NormTermName": "Pressing Screw",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Suspension Strut"
        },
        "09161": {
            "GenartName": "Expansion Set, installation tool (wheel hub/bearing)",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Hub/Wheel Bearing"
        },
        "09162": {
            "GenartName": "Spacer Plate, puller set",
            "NormTermName": "Spacer Plate",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "09163": {
            "GenartName": "Pressing Screw, installation tool (wheel hub/bearing)",
            "NormTermName": "Pressing Screw",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Hub/Wheel Bearing"
        },
        "09164": {
            "GenartName": "Clamping Jaws Set, installation tool (wheel hub/bearing)",
            "NormTermName": "Clamping Jaws Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Hub/Wheel Bearing"
        },
        "09165": {
            "GenartName": "Adapter Plate, installation tool (wheel hub/wheel bearing)",
            "NormTermName": "Adapter Plate",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Hub/Wheel Bearing"
        },
        "09166": {
            "GenartName": "Adapter Set, impact puller",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Impact Puller"
        },
        "09167": {
            "GenartName": "Perforated Plate, puller",
            "NormTermName": "Perforated Plate",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Puller"
        },
        "09168": {
            "GenartName": "Holder, wheel hub",
            "NormTermName": "Holder",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Hub"
        },
        "09169": {
            "GenartName": "Wall Mounting, torque limiter",
            "NormTermName": "Wall Mounting",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Limiter"
        },
        "09170": {
            "GenartName": "Socket Wrench Set, boring tool",
            "NormTermName": "Socket Wrench Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wheel Nut/Bolt"
        },
        "09171": {
            "GenartName": "Socket Wrench Insert, boring tool",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wheel Nut/Bolt"
        },
        "09172": {
            "GenartName": "Alligator Clip, tester",
            "NormTermName": "Alligator Clip",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Car Tester (Electrics)"
        },
        "09173": {
            "GenartName": "Adapter, brush strap",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Multi-grinder"
        },
        "09174": {
            "GenartName": "Adapter, single eliminator disc",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Multi-grinder"
        },
        "09175": {
            "GenartName": "Adapter, double eliminator disc",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Multi-grinder"
        },
        "09176": {
            "GenartName": "Dosing Hand Pump",
            "NormTermName": "Dosing Hand Pump",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09177": {
            "GenartName": "Adapter, draw-in tool (drive shaft)",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Draw-in Tool (drive shaft)"
        },
        "09178": {
            "GenartName": "Extension Set, draw-in tool (drive shaft)",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Draw-in Tool (drive shaft)"
        },
        "09179": {
            "GenartName": "Socket Wrench, differential flange",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Differential Flange"
        },
        "09180": {
            "GenartName": "Socket, transmission flange",
            "NormTermName": "Socket Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Gearbox Flange"
        },
        "09181": {
            "GenartName": "Pin Wrench, wheel bearing",
            "NormTermName": "Pin Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Bearing"
        },
        "09182": {
            "GenartName": "Pin Wrench, differential flange",
            "NormTermName": "Pin Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Differential Flange"
        },
        "09183": {
            "GenartName": "Pin Wrench, gearbox flange",
            "NormTermName": "Pin Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Gearbox Flange"
        },
        "09184": {
            "GenartName": "Pin Wrench, shaft flange",
            "NormTermName": "Pin Wrench",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Rear Axle"
        },
        "09185": {
            "GenartName": "Holder, notebook",
            "NormTermName": "Bracket",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Notebook"
        },
        "09186": {
            "GenartName": "Retaining Clip, door panel",
            "NormTermName": "Clip",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Interior Door Panel"
        },
        "09187": {
            "GenartName": "Retaining Clip, flooring (carpet)",
            "NormTermName": "Clip",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Flooring (Carpet)"
        },
        "09188": {
            "GenartName": "Retaining Spring, vehicle post cladding",
            "NormTermName": "Clip",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Vehicle Post Cladding"
        },
        "09189": {
            "GenartName": "Retaining Clip, boot / cargo area panelling",
            "NormTermName": "Clip",
            "AssGrpName": "Body",
            "UsageName": "Boot / Cargo Area Panelling"
        },
        "09190": {
            "GenartName": "Retaining Clip, engine bay insulation",
            "NormTermName": "Clip",
            "AssGrpName": "Body",
            "UsageName": "Engine Bay Insulation"
        },
        "09191": {
            "GenartName": "Retaining Clip, underbody panelling",
            "NormTermName": "Clip",
            "AssGrpName": "Body",
            "UsageName": "Underbody Panelling"
        },
        "09192": {
            "GenartName": "Cable Repair Set, windscreen wash pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": "Wash Waterpump, windscreen cleaning"
        },
        "09193": {
            "GenartName": "Cable Repair Set, accelerator pedal sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Accelerator Pedal Sensor"
        },
        "09194": {
            "GenartName": "Cable Repair Set, accelerator pedal sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Accelerator Pedal Sensor"
        },
        "09195": {
            "GenartName": "Pump Unit, compressed air spray bottle",
            "NormTermName": "Pump Unit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Compressed Air Spray Bottle"
        },
        "09196": {
            "GenartName": "Release Tool, compressed air quick coupler",
            "NormTermName": "Removal Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Compressed Air Quick Coupler"
        },
        "09197": {
            "GenartName": "Protective Sleeve, tyre fitting lever",
            "NormTermName": "Protective Sleeve",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tyre Fitting Lever"
        },
        "09198": {
            "GenartName": "Articulated Joint, impact wrench",
            "NormTermName": "Universal Joint",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "09199": {
            "GenartName": "Reduction Adapter, impact wrench",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "09200": {
            "GenartName": "Blade Set, impact wrench",
            "NormTermName": "Lining Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "09201": {
            "GenartName": "Holder, impact wrench",
            "NormTermName": "Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "09202": {
            "GenartName": "Blade Set, straight grinder",
            "NormTermName": "Lining Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Straight Grinder"
        },
        "09203": {
            "GenartName": "Actuation Lever, straight grinder",
            "NormTermName": "Actuating Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Straight Grinder"
        },
        "09204": {
            "GenartName": "Actuation Lever, angle grinder",
            "NormTermName": "Actuating Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "09205": {
            "GenartName": "Blade Set, windscreen separation device",
            "NormTermName": "Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Windscreen Cut-out Device"
        },
        "09206": {
            "GenartName": "Venturi Nozzle, compressed air pistol",
            "NormTermName": "Nozzle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Compressed Air Spray Gun"
        },
        "09207": {
            "GenartName": "Head, tyre bead breaker",
            "NormTermName": "Head",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tyre Bead Breaker"
        },
        "09208": {
            "GenartName": "Head Guide, tyre bead breaker",
            "NormTermName": "Guide",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tyre Bead Breaker"
        },
        "09209": {
            "GenartName": "Blasting Media, soda blasting gun",
            "NormTermName": "Blasting Media",
            "AssGrpName": "Chemical Products",
            "UsageName": "Soda Blasting Gun"
        },
        "09210": {
            "GenartName": "Planetary Gear, impact wrench",
            "NormTermName": "Planetary Gear",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "09211": {
            "GenartName": "Maintenance Service, torque wrench",
            "NormTermName": "Maintenance Service",
            "AssGrpName": "Tool, universal",
            "UsageName": "Torque Wrench"
        },
        "09212": {
            "GenartName": "Assortment, oil drain plugs",
            "NormTermName": "Assortment",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Drain Plug"
        },
        "09213": {
            "GenartName": "Cable Repair Set, pressure transducer",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "Pressure Transducer"
        },
        "09214": {
            "GenartName": "Cable Repair Set, pressure transducer",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust Gas Recirculation, Universal",
            "UsageName": "Pressure Transducer"
        },
        "09215": {
            "GenartName": "Repair Kit, selector shaft (automatic transmission)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Selector Shaft"
        },
        "09216": {
            "GenartName": "Spacer",
            "NormTermName": "Spacer",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "09217": {
            "GenartName": "Heater hose",
            "NormTermName": "Hose Line",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heat Exchanger"
        },
        "09218": {
            "GenartName": "Shift Cylinder (manual transmission)",
            "NormTermName": "Cylinder",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "09219": {
            "GenartName": "Switch, parking distance control",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Distance Control"
        },
        "09220": {
            "GenartName": "Intake Snorkel",
            "NormTermName": "Snorkel",
            "AssGrpName": "Air Supply",
            "UsageName": "Induction Air"
        },
        "09221": {
            "GenartName": "Switch, brake actuation (cruise control)",
            "NormTermName": "Switch",
            "AssGrpName": "Cruise Control",
            "UsageName": "Brake Actuation (cruise control)"
        },
        "09222": {
            "GenartName": "Mounting Lifting Aid",
            "NormTermName": "Mounting Lifting Aid",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09223": {
            "GenartName": "Swivel Bearing, mounting lifting aid",
            "NormTermName": "Swivel Bearing",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Mounting Lifting Aid"
        },
        "09224": {
            "GenartName": "Actuating Switch, multi-grinder",
            "NormTermName": "Actuating Switch",
            "AssGrpName": "Tool, universal",
            "UsageName": "Multi-grinder"
        },
        "09225": {
            "GenartName": "Drive Head, extended grinder",
            "NormTermName": "Drive Head",
            "AssGrpName": "Tool, universal",
            "UsageName": "Extended Grinder"
        },
        "09226": {
            "GenartName": "Actuating Lever, extended grinder",
            "NormTermName": "Actuating Lever",
            "AssGrpName": "Tool, universal",
            "UsageName": "Extended Grinder"
        },
        "09227": {
            "GenartName": "Actuator, transfer case",
            "NormTermName": "Actuator",
            "AssGrpName": "Axle Drive",
            "UsageName": "Transfer Case"
        },
        "09228": {
            "GenartName": "Clamping Gripper, spring compressor",
            "NormTermName": "Clamping Gripper",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Compressor"
        },
        "09229": {
            "GenartName": "Seal Ring Set, impact wrench",
            "NormTermName": "Seal Ring Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "09230": {
            "GenartName": "End Plate, impact wrench",
            "NormTermName": "End Plate",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "09231": {
            "GenartName": "Fastening Nut, chuck (straight grinder)",
            "NormTermName": "Nut",
            "AssGrpName": "Tool, universal",
            "UsageName": "Chuck (straight grinder)"
        },
        "09232": {
            "GenartName": "Rotating Ring, impact wrench",
            "NormTermName": "Rotating Ring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "09233": {
            "GenartName": "Fastening Set, extended grinder",
            "NormTermName": "Fastening Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Extended Grinder"
        },
        "09234": {
            "GenartName": "Connecting Nut, straight grinder",
            "NormTermName": "Nut",
            "AssGrpName": "Tool, universal",
            "UsageName": "Straight Grinder"
        },
        "09235": {
            "GenartName": "Bevel Gear, straight grinder",
            "NormTermName": "Transmission",
            "AssGrpName": "Tool, universal",
            "UsageName": "Straight Grinder"
        },
        "09236": {
            "GenartName": "Fastening Set, extended angle grinder",
            "NormTermName": "Fastening Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Extended Angle Grinder"
        },
        "09237": {
            "GenartName": "Assortment, spacer",
            "NormTermName": "Assortment",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Spacer"
        },
        "09238": {
            "GenartName": "Cable Repair Set, condensation sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Condensation Sensor"
        },
        "09239": {
            "GenartName": "Cable Repair Set, condensation sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Condensation Sensor"
        },
        "09240": {
            "GenartName": "Cable Repair Set, pressure sensor (hydraulic unit)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System",
            "UsageName": "Pressure Sensor (hydraulic unit)"
        },
        "09241": {
            "GenartName": "Cable Repair Set, pressure sensor (hydraulic unit)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Pressure Sensor (hydraulic unit)"
        },
        "09242": {
            "GenartName": "Cable Repair Set, shock absorber",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shock Absorber"
        },
        "09243": {
            "GenartName": "Cable Repair Set, shock absorber",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Suspension, universal",
            "UsageName": "Shock Absorber"
        },
        "09244": {
            "GenartName": "Fastening Nut, extended grinder",
            "NormTermName": "Nut",
            "AssGrpName": "Tool, universal",
            "UsageName": "Extended Grinder"
        },
        "09245": {
            "GenartName": "Bevel Gear, extended grinder",
            "NormTermName": "Transmission",
            "AssGrpName": "Tool, universal",
            "UsageName": "Extended Grinder"
        },
        "09246": {
            "GenartName": "Connection Tube, extended grinder",
            "NormTermName": "Connection Tube",
            "AssGrpName": "Tool, universal",
            "UsageName": "Extended Grinder"
        },
        "09247": {
            "GenartName": "Blade Set, polisher",
            "NormTermName": "Lining Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Polishing Machine"
        },
        "09248": {
            "GenartName": "Planetary Gear, polisher",
            "NormTermName": "Planetary Gear",
            "AssGrpName": "Tool, universal",
            "UsageName": "Polishing Machine"
        },
        "09249": {
            "GenartName": "Locking Button Set, extended grinder",
            "NormTermName": "Locking Button Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Extended Grinder"
        },
        "09250": {
            "GenartName": "Cover Disc, extended grinder",
            "NormTermName": "Cover Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": "Extended Grinder"
        },
        "09251": {
            "GenartName": "Blade Set, extended grinder",
            "NormTermName": "Lining Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Extended Grinder"
        },
        "09252": {
            "GenartName": "Bearing Holder, impact wrench",
            "NormTermName": "Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "09253": {
            "GenartName": "Pulley Set, impact screwdriver",
            "NormTermName": "Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench"
        },
        "09254": {
            "GenartName": "Motor Pulley Set, impact wrench",
            "NormTermName": "Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Impact Wrench Motor"
        },
        "09255": {
            "GenartName": "Pulley Locking, angle grinder",
            "NormTermName": "Pulley Locking",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "09256": {
            "GenartName": "Suction Pipe, straight grinder",
            "NormTermName": "Suction Pipe",
            "AssGrpName": "Tool, universal",
            "UsageName": "Straight Grinder"
        },
        "09257": {
            "GenartName": "Fastening Nut Set, angle grinder",
            "NormTermName": "Nut Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "09258": {
            "GenartName": "Cleaner, turbocharger",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Turbocharger"
        },
        "09259": {
            "GenartName": "Mounting Kit, inner tie rod",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Steering",
            "UsageName": "Axle Joint"
        },
        "09260": {
            "GenartName": "Mounting Kit, tie rod",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Steering",
            "UsageName": "Tie Rod"
        },
        "09261": {
            "GenartName": "Mounting Kit, axle beam",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Axle Beam"
        },
        "09262": {
            "GenartName": "Mounting Kit, control/trailing arm mounting",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Control/Trailing Arm Mounting"
        },
        "09263": {
            "GenartName": "Assortment, cable repair set",
            "NormTermName": "Assortment",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Cable Repair Set"
        },
        "09264": {
            "GenartName": "Cable Repair Set, charge pressure regulating valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply",
            "UsageName": "Boost Pressure Control Valve"
        },
        "09265": {
            "GenartName": "Cable Repair Set, charge pressure regulating valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply, universal",
            "UsageName": "Boost Pressure Control Valve"
        },
        "09266": {
            "GenartName": "Seal Set, compression pressure tester",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Compression Pressure Tester"
        },
        "09267": {
            "GenartName": "Release Tool, shock absorber",
            "NormTermName": "Removal Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Shock Absorber"
        },
        "09268": {
            "GenartName": "Suction Bellows, test device for cylinder head sealing",
            "NormTermName": "Gaiter",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Test Device for cylinder head sealing"
        },
        "09269": {
            "GenartName": "Support Plate, press-fit/extraction tool set",
            "NormTermName": "Support Plate",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Press-fit/Extraction Tools"
        },
        "09270": {
            "GenartName": "Sleeve, press-fit/extraction tool set",
            "NormTermName": "Sleeve",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Press-fit/Extraction Tools"
        },
        "09271": {
            "GenartName": "Attachment, scraper",
            "NormTermName": "Attachment",
            "AssGrpName": "Tool, universal",
            "UsageName": "Scraper"
        },
        "09272": {
            "GenartName": "Holding Tool Set, crank shaft",
            "NormTermName": "Holding Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft"
        },
        "09273": {
            "GenartName": "Repair Kit, stone chip (windscreen)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Stone Chip (windscreen)"
        },
        "09274": {
            "GenartName": "Release Tool, radiator sealing cap",
            "NormTermName": "Removal Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Radiator Sealing Cap"
        },
        "09275": {
            "GenartName": "Pressure Screw Set, mounting tool (drive shaft joint)",
            "NormTermName": "Pressure Screw Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Mounting Tool (drive shaft joint)"
        },
        "09276": {
            "GenartName": "Mounting Bracket, press frame supporting joint",
            "NormTermName": "Bracket",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Press Frame Supporting Joint"
        },
        "09277": {
            "GenartName": "Mounting Tool Set, brake caliper",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Caliper"
        },
        "09278": {
            "GenartName": "Release Tool Set, compressed air quick coupler",
            "NormTermName": "Removal Kit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Compressed Air Quick Coupler"
        },
        "09279": {
            "GenartName": "Press Frame, supporting joint",
            "NormTermName": "Press Frame",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "09280": {
            "GenartName": "Test Device Set, coolant flow (water pump)",
            "NormTermName": "Test Equipment Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Coolant flow (water pump)"
        },
        "09281": {
            "GenartName": "Hose, coolant flow test device set",
            "NormTermName": "Hose Line",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Coolant Flow Test Device Set (water pump)"
        },
        "09282": {
            "GenartName": "Screw Aid",
            "NormTermName": "Screw Aid",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09283": {
            "GenartName": "Welding Gun, dent repair (mains)",
            "NormTermName": "Welding Gun (mains)",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Dent Repair (mains)"
        },
        "09284": {
            "GenartName": "Counter Bracket, welding point drill",
            "NormTermName": "Arrester",
            "AssGrpName": "Tool, universal",
            "UsageName": "Welding Point Drill"
        },
        "09285": {
            "GenartName": "Mount Set, jack",
            "NormTermName": "Mount Set",
            "AssGrpName": "Body",
            "UsageName": "Jack"
        },
        "09286": {
            "GenartName": "Connection Pieces, fuel system pressure tester",
            "NormTermName": "Fitting",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Fuel System Pressure Tester"
        },
        "09287": {
            "GenartName": "Track Rod, axial linkage clamp set",
            "NormTermName": "Track Rod, axial linkage clamp set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "09288": {
            "GenartName": "Container, fuel system pressure tester",
            "NormTermName": "Tank",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Fuel System Pressure Tester"
        },
        "09289": {
            "GenartName": "Blade Drill Bit, piping (sanitary)",
            "NormTermName": "Drill Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Piping (sanitary)"
        },
        "09290": {
            "GenartName": "Adapter, oil pressure tester",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Oil Pressure Tester"
        },
        "09291": {
            "GenartName": "Clamping Device, clutch pressure plate",
            "NormTermName": "Clamping Device",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Clutch Pressure Plate"
        },
        "09292": {
            "GenartName": "Cable Repair Kit, camshaft adjuster",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Engine Timing, universal",
            "UsageName": "Camshaft Adjuster"
        },
        "09293": {
            "GenartName": "Cable Repair Kit, camshaft adjuster",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Adjuster"
        },
        "09294": {
            "GenartName": "Flushing/Cleaning Gun, cooling system",
            "NormTermName": "Flushing/Cleaning Gun",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cooling System"
        },
        "09295": {
            "GenartName": "Stepped plate, ball joint separator set",
            "NormTermName": "Stepped plate",
            "AssGrpName": "Tool, universal",
            "UsageName": "Ball Joint Separator Set"
        },
        "09296": {
            "GenartName": "Heater, air dryer",
            "NormTermName": "Heater",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Dryer"
        },
        "09297": {
            "GenartName": "Pressure Converter, coolant control valve",
            "NormTermName": "Pressure Converter",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Coolant Control Valve"
        },
        "09298": {
            "GenartName": "Cable Repair Set, charge pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Charge Pressure Sensor"
        },
        "09299": {
            "GenartName": "Cable Repair Set, charge pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Charge Pressure Sensor"
        },
        "09300": {
            "GenartName": "Cable Repair Set, auxiliary water pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling System",
            "UsageName": "Auxiliary Water Pump"
        },
        "09301": {
            "GenartName": "Cable Repair Set, auxiliary water pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Auxiliary Water Pump"
        },
        "09302": {
            "GenartName": "Cable Repair Set, control unit motor sound system",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Accessories",
            "UsageName": "Control Unit Motor Sound System"
        },
        "09303": {
            "GenartName": "Cable Repair Set, control unit motor sound system",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Control Unit Motor Sound System"
        },
        "09304": {
            "GenartName": "Idle Switch, automatic transmission",
            "NormTermName": "Switch",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Idle"
        },
        "09305": {
            "GenartName": "Idle Switch, manual transmission",
            "NormTermName": "Switch",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Idle"
        },
        "09306": {
            "GenartName": "Flange, camshaft position sensor",
            "NormTermName": "Flange",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Camshaft Position Sensor"
        },
        "09307": {
            "GenartName": "Rocker/Release Lever, pulley bolt",
            "NormTermName": "Rocker/Release Lever",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Pulley Bolt"
        },
        "09308": {
            "GenartName": "Bodywork Roof",
            "NormTermName": "Bodywork Roof",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "09309": {
            "GenartName": "Auxiliary water pump (heating water circuit)",
            "NormTermName": "Pump",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Auxiliary Pump"
        },
        "09310": {
            "GenartName": "Repair Set, ABS sensor",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "ABS Sensor"
        },
        "09311": {
            "GenartName": "Cable Repair Set, water pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "09312": {
            "GenartName": "Cable Repair Set, water pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Water Pump"
        },
        "09313": {
            "GenartName": "Cable Repair Set, secondary air pump pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": "Secondary Air Pump Pressure Sensor"
        },
        "09314": {
            "GenartName": "Cable Repair Set, secondary air pump pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Secondary Air Injection, universal",
            "UsageName": "Secondary Air Pump Pressure Sensor"
        },
        "09315": {
            "GenartName": "Cable Repair Set, cylinder shutdown check valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Cylinder Shutdown Check Valve"
        },
        "09316": {
            "GenartName": "Cable Repair Set, cylinder shutdown check valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Cylinder Shutdown Check Valve"
        },
        "09317": {
            "GenartName": "Cable Repair Set, brake servo pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Servo Pressure Sensor"
        },
        "09318": {
            "GenartName": "Cable Repair Set, brake servo pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Servo Pressure Sensor"
        },
        "09319": {
            "GenartName": "Cable repair kit, water filter (fuel filter)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Water filter (fuel filter)"
        },
        "09320": {
            "GenartName": "Cable repair kit, water filter (fuel filter)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Water filter (fuel filter)"
        },
        "09321": {
            "GenartName": "Cable Repair Set, exhaust flap servomotor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Flap Servomotor"
        },
        "09322": {
            "GenartName": "Cable Repair Set, exhaust flap servomotor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Exhaust Flap Servomotor"
        },
        "09323": {
            "GenartName": "Cable Repair Set, turbocharger RPM sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply",
            "UsageName": "Turbocharger RPM Sensor"
        },
        "09324": {
            "GenartName": "Cable Repair Set, turbocharger RPM sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply, universal",
            "UsageName": "Turbocharger RPM Sensor"
        },
        "09325": {
            "GenartName": "Cable Repair Set, gear switch gear detection",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gear Switch, gear detection"
        },
        "09326": {
            "GenartName": "Cable Repair Set, gear switch gear detection",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Manual Transmission, universal",
            "UsageName": "Gear Switch, gear detection"
        },
        "09327": {
            "GenartName": "Cable Repair Set, trailer socket switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Trailer Socket Switch"
        },
        "09328": {
            "GenartName": "Cable Repair Set, trailer socket switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Trailer Socket Switch"
        },
        "09329": {
            "GenartName": "Cable Repair Set, urea tank heating element",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea Tank Heating Element"
        },
        "09330": {
            "GenartName": "Cable Repair Set, urea tank heating element",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Urea Tank Heating Element"
        },
        "09331": {
            "GenartName": "Cable Repair Set, fuel regulator sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Regulator Sensor"
        },
        "09332": {
            "GenartName": "Cable Repair Set, fuel regulator sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Fuel Regulator Sensor"
        },
        "09333": {
            "GenartName": "Calibration Set, driver assistance system",
            "NormTermName": "Calibration Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Driver Assistance System"
        },
        "09334": {
            "GenartName": "Calibration Table, driver assistance system",
            "NormTermName": "Calibration Table",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Driver Assistance System"
        },
        "09335": {
            "GenartName": "Hybrid Adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Hybrid Adhesive"
        },
        "09336": {
            "GenartName": "Cable Repair Set, charcoal canister pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Charcoal Canister Pressure Sensor"
        },
        "09337": {
            "GenartName": "Cable Repair Set, charcoal canister pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Charcoal Canister Pressure Sensor"
        },
        "09338": {
            "GenartName": "Cable Repair Set, engine block heater",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Engine Block Heater"
        },
        "09339": {
            "GenartName": "Cable Repair Set, engine block heater",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Engine Block Heater"
        },
        "09341": {
            "GenartName": "Cable Repair Set, air pressure sensor height adaptation",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Air Pressure Sensor Height Adaptation"
        },
        "09342": {
            "GenartName": "Cable Repair Set, air pressure sensor height adaptation",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Air Pressure Sensor Height Adaptation"
        },
        "09343": {
            "GenartName": "Bearing Bush, ball joint",
            "NormTermName": "Bush",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Ball Joint"
        },
        "09344": {
            "GenartName": "Cable Repair Set, swirl valve pos. sensor (intake manifold)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply",
            "UsageName": "Swirl Valve Position Sensor (intake manifold)"
        },
        "09345": {
            "GenartName": "Cable Repair Set, swirl valve pos. sensor (intake manifold)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply, universal",
            "UsageName": "Swirl Valve Position Sensor (intake manifold)"
        },
        "09346": {
            "GenartName": "Repair Kit, intake manifold module",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Air Supply",
            "UsageName": "Intake Manifold Module"
        },
        "09347": {
            "GenartName": "Accessory Set, spring compressor",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Spring Compressor"
        },
        "09348": {
            "GenartName": "Spindle, press frame supporting joint",
            "NormTermName": "Spindle",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Press Frame Supporting Joint"
        },
        "09349": {
            "GenartName": "Drive Set, universal",
            "NormTermName": "Drive Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "09350": {
            "GenartName": "Expansion Set, press-out device, driveshaft",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Press-out Device, driveshaft"
        },
        "09351": {
            "GenartName": "Circlip Tool",
            "NormTermName": "Circlip Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "09352": {
            "GenartName": "Weld-In Thread, particle sensor",
            "NormTermName": "Weld-On Bung",
            "AssGrpName": "Exhaust System",
            "UsageName": "Particle Sensor"
        },
        "09353": {
            "GenartName": "Weld-In Thread, particle sensor",
            "NormTermName": "Weld-On Bung",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Particle Sensor"
        },
        "09354": {
            "GenartName": "Cable Repair Set, fuel filler flap",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Body",
            "UsageName": "Fuel Filler Flap"
        },
        "09355": {
            "GenartName": "Cable Repair Set, fuel filler flap",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Fuel Filler Flap"
        },
        "09356": {
            "GenartName": "Tipping Device Set, driver cab",
            "NormTermName": "Tipping Device Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Driver Cab"
        },
        "09357": {
            "GenartName": "Tipping Device, driver cab",
            "NormTermName": "Tipping Device",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Driver Cab"
        },
        "09358": {
            "GenartName": "Cable Repair Set, tailgate locking actuator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System",
            "UsageName": "Tailgate Locking Actuator"
        },
        "09359": {
            "GenartName": "Cable Repair Set, tailgate locking actuator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Tailgate Locking Actuator"
        },
        "09360": {
            "GenartName": "Cable Repair Set, heat pump expansion valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Heat Pump Expansion Valve"
        },
        "09361": {
            "GenartName": "Mounting Tool, relay valve seal",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Braking System"
        },
        "09362": {
            "GenartName": "Mounting Tool Set Tool, lift cylinder seal",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Cylinder Sleeve"
        },
        "09363": {
            "GenartName": "Cable Repair Set, heat pump expansion valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Heat Pump Expansion Valve"
        },
        "09364": {
            "GenartName": "Installation Tool, lift cylinder seal",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Cylinder Sleeve"
        },
        "09365": {
            "GenartName": "Cable Repair Set, fuel temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Fuel Temperature Sensor"
        },
        "09366": {
            "GenartName": "Lever, sealing ring installation",
            "NormTermName": "Lever",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Cylinder Head Leakage"
        },
        "09367": {
            "GenartName": "Cable Repair Set, fuel temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Fuel Temperature Sensor"
        },
        "09368": {
            "GenartName": "Puller Set, differential main shaft",
            "NormTermName": "Puller Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Differential"
        },
        "09369": {
            "GenartName": "Holder, impact socket",
            "NormTermName": "Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09370": {
            "GenartName": "Mounting Tool, transmission bearing",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Transmission Bearing"
        },
        "09371": {
            "GenartName": "Key, solenoid valve",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Air Conditioning"
        },
        "09372": {
            "GenartName": "Bridge, workshop pit",
            "NormTermName": "Bridge",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Pit"
        },
        "09373": {
            "GenartName": "Cable Repair Set, tailgate opening control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Control Unit, tailgate opening"
        },
        "09374": {
            "GenartName": "Fastening Screw, puller",
            "NormTermName": "Fastening Screw",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09375": {
            "GenartName": "Cable Repair Set, tailgate opening control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Control Unit, tailgate opening"
        },
        "09376": {
            "GenartName": "Extension, twist drill",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09377": {
            "GenartName": "Cable Repair Set, particle sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Particle Sensor"
        },
        "09378": {
            "GenartName": "Cable Repair Set, particle sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Particle Sensor"
        },
        "09379": {
            "GenartName": "Cable Repair Set, steering column lock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Steering",
            "UsageName": "Steering Column Lock"
        },
        "09380": {
            "GenartName": "Cable Repair Set, steering column lock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Steering, universal",
            "UsageName": "Steering Column Lock"
        },
        "09381": {
            "GenartName": "Cable Repair Set, stop light switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Signal System",
            "UsageName": "Stop Light Switch"
        },
        "09382": {
            "GenartName": "Cable Repair Set, stop light switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Stop Light Switch"
        },
        "09383": {
            "GenartName": "Cable Repair Set, fuel pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Fuel pressure sensor"
        },
        "09384": {
            "GenartName": "Cable Repair Set, fuel pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Fuel pressure sensor"
        },
        "09385": {
            "GenartName": "Connecting Cable, oil pressure switch",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pressure Switch"
        },
        "09386": {
            "GenartName": "Connecting Cable, oil pressure switch",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Lubrication, universal",
            "UsageName": "Oil Pressure Switch"
        },
        "09387": {
            "GenartName": "Cable Repair Set, thermostat",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling System",
            "UsageName": "Thermostat"
        },
        "09388": {
            "GenartName": "Cable Repair Set, thermostat",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Thermostat"
        },
        "09389": {
            "GenartName": "Cable Repair Set, exhaust gas temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust System",
            "UsageName": "Emission Temperature Sensor"
        },
        "09390": {
            "GenartName": "Cable Repair Set, exhaust gas temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Emission Temperature Sensor"
        },
        "09391": {
            "GenartName": "Cable Repair Set, refrigerant shut-off valve (heat pump)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Refrigerant Shut-Off Valve (heat pump)"
        },
        "09392": {
            "GenartName": "Cable Repair Set, refrigerant shut-off valve (heat pump)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Refrigerant Shut-Off Valve (heat pump)"
        },
        "09393": {
            "GenartName": "Cable Repair Set, heat pump sensor module",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Heat Pump Sensor Module"
        },
        "09394": {
            "GenartName": "Cable Repair Set, heat pump sensor module",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Heat Pump Sensor Module"
        },
        "09395": {
            "GenartName": "Cable Repair Set, charge socket actuator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Charge Socket Actuator"
        },
        "09396": {
            "GenartName": "Cable Repair Set, charge socket actuator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Hybrid/Electrical Drive, universal",
            "UsageName": "Charge Socket Actuator"
        },
        "09397": {
            "GenartName": "Cable Repair Set, differential pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust System",
            "UsageName": "Differential Pressure Sensor"
        },
        "09398": {
            "GenartName": "Cable Repair Set, differential pressure sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Differential Pressure Sensor"
        },
        "09399": {
            "GenartName": "Installation/Removal Set, leaf spring",
            "NormTermName": "Installation/Removal Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Leaf Spring"
        },
        "09400": {
            "GenartName": "Installation/Removal Set, kingpin",
            "NormTermName": "Installation/Removal Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Kingppin"
        },
        "09401": {
            "GenartName": "Installation/Removal Set, leaf spring",
            "NormTermName": "Installation/Removal Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Leaf Spring"
        },
        "09402": {
            "GenartName": "Installation/Removal Set, kingpin",
            "NormTermName": "Installation/Removal Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Kingppin"
        },
        "09403": {
            "GenartName": "Release Tool, tie rod",
            "NormTermName": "Removal Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Tie Rod"
        },
        "09404": {
            "GenartName": "WiFi Transmitter, video endoscope",
            "NormTermName": "WiFi Transmitter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Video Endoscope"
        },
        "09405": {
            "GenartName": "Stencil Set, spray painting",
            "NormTermName": "Stencil Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Spray Paint Systems"
        },
        "09406": {
            "GenartName": "Mounting Tool, axle bushing",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Axle"
        },
        "09407": {
            "GenartName": "Mounting Tool Set, axle bushings",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Axle"
        },
        "09408": {
            "GenartName": "Mounting Tool, crankshaft seal/pilot bearing",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft Seal/Pilot Bearing"
        },
        "09409": {
            "GenartName": "Puller Set, roller bearing",
            "NormTermName": "Puller Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Roller Bearing"
        },
        "09410": {
            "GenartName": "Puller Set, transmission shaft",
            "NormTermName": "Puller Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Transmission Shaft"
        },
        "09411": {
            "GenartName": "Reamer Adapter, glow-plug",
            "NormTermName": "Reamer Adapter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glow Plug"
        },
        "09412": {
            "GenartName": "Mounting Tool, unit injector",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Unit Injector (UI)"
        },
        "09413": {
            "GenartName": "Puller, balancer shaft",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Balance Shaft"
        },
        "09414": {
            "GenartName": "Forked Cutting Head",
            "NormTermName": "Forked Cutting Head",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09415": {
            "GenartName": "Aluminium Solder Set",
            "NormTermName": "Aluminium Solder Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09416": {
            "GenartName": "Puller Housing, brake shoe pin",
            "NormTermName": "Puller Housing",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Shoe Pin"
        },
        "09417": {
            "GenartName": "Puller Housing, brake shoe pin",
            "NormTermName": "Puller Housing",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Shoe Pin"
        },
        "09418": {
            "GenartName": "Hydraulic Puller Set, brake shoe pin",
            "NormTermName": "Hydraulic Puller Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Shoe Pin"
        },
        "09419": {
            "GenartName": "Hydraulic Puller Set, brake shoe pin",
            "NormTermName": "Hydraulic Puller Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Shoe Pin"
        },
        "09420": {
            "GenartName": "Hose Connector, radiator hose",
            "NormTermName": "Hose Connector",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Radiator Hose"
        },
        "09421": {
            "GenartName": "Protective Hose, suspension spring",
            "NormTermName": "Protective Hose",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Suspension Spring"
        },
        "09422": {
            "GenartName": "Pressure Screw Set, mounting tool (drive shaft joint)",
            "NormTermName": "Pressure Screw Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Mounting Tool (drive shaft joint)"
        },
        "09423": {
            "GenartName": "Pressure Screw, mounting tool (drive shaft joint)",
            "NormTermName": "Pressing Screw",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Mounting Tool (drive shaft joint)"
        },
        "09424": {
            "GenartName": "Socket Wrench Insert, drive shaft",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Drive Shaft"
        },
        "09425": {
            "GenartName": "Socket Wrench Insert, wheel bearing",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Bearing"
        },
        "09426": {
            "GenartName": "Draw Bar, press frame (mounting tool silent bearing)",
            "NormTermName": "Pull Rod",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Press Frame (installation tool silent bearing)"
        },
        "09427": {
            "GenartName": "Base Plate, press frame (mounting tool silent bearing)",
            "NormTermName": "Base Plate",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Press Frame (installation tool silent bearing)"
        },
        "09428": {
            "GenartName": "Socket Wrench Insert, oil pressure switch",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Oil Pressure Switch"
        },
        "09429": {
            "GenartName": "Puller, piston cooling oil jet",
            "NormTermName": "Extractor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Piston Cooling Oil Jet"
        },
        "09430": {
            "GenartName": "Locking Pliers, compressed air line",
            "NormTermName": "Vise-grip Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hoses, compressed air"
        },
        "09431": {
            "GenartName": "Double-Ended Ratchet Ring Spanner, glow-plug",
            "NormTermName": "Ratchet Double Ring Spanner",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glow Plug"
        },
        "09432": {
            "GenartName": "Socket Wrench Insert, crankshaft pulley bolt",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Crankshaft Pulley Bolt"
        },
        "09433": {
            "GenartName": "Socket Wrench Insert, engine oil level sensor",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Engine Oil Level Sensor"
        },
        "09434": {
            "GenartName": "Spacer Ring, kingpin press",
            "NormTermName": "Spacer Ring",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Kingpin Press"
        },
        "09435": {
            "GenartName": "Thrust Piece Set, kingpin press",
            "NormTermName": "Thrust Piece Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Kingpin Press"
        },
        "09436": {
            "GenartName": "Thrust Piece, kingpin press",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Kingpin Press"
        },
        "09437": {
            "GenartName": "Support Ring, kingpin press",
            "NormTermName": "Supporting Ring",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Kingpin Press"
        },
        "09438": {
            "GenartName": "Disassembly Tool, kingpin",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Kingppin"
        },
        "09439": {
            "GenartName": "Adapter, kingpin disassembly tool",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Kingpin Disassembly Tool"
        },
        "09440": {
            "GenartName": "Installation/Removal Tool Set, drawbar bearing",
            "NormTermName": "Installation/Removal Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Drawbar Bearing"
        },
        "09441": {
            "GenartName": "Puller, wheel hub",
            "NormTermName": "Puller",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Hub"
        },
        "09442": {
            "GenartName": "Base Frame, wheel hub puller",
            "NormTermName": "Frame",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Hub Puller"
        },
        "09443": {
            "GenartName": "Pin Wrench, ball bearing groove nut",
            "NormTermName": "Pin Wrench Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ball Bearing Groove Nut"
        },
        "09444": {
            "GenartName": "Socket Wrench Insert, shock absorber (mounting bolt)",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Shock Absorber Fastening Screw"
        },
        "09445": {
            "GenartName": "Shaft Seal Drift",
            "NormTermName": "Installer",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Shaft Seal"
        },
        "09446": {
            "GenartName": "Pressing Ring, shaft seal drift",
            "NormTermName": "Pressure Ring",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Shaft Seal Drift"
        },
        "09447": {
            "GenartName": "Mounting Tool Set, clutch pressure plate",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Clutch Pressure Plate"
        },
        "09448": {
            "GenartName": "Adapter, mounting device for clutch/flywheel",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Mounting Device for Clutch/Flywheel"
        },
        "09449": {
            "GenartName": "Expansion Set, installation tool for clutch / press plate",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Installation Tool for Clutch / Press Plate"
        },
        "09450": {
            "GenartName": "Bracket, clutch installation tool",
            "NormTermName": "Bracket",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Clutch Installation Tool"
        },
        "09451": {
            "GenartName": "Hammer Wrench Set, brake caliper fastening",
            "NormTermName": "Key Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "brake caliper Fastening"
        },
        "09452": {
            "GenartName": "Hammer Wrench, brake caliper fastening",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "brake caliper Fastening"
        },
        "09453": {
            "GenartName": "Hammer Wrench, longitudinal shaft screw connection",
            "NormTermName": "Key",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Longitudinal Shaft Screw Connection"
        },
        "09454": {
            "GenartName": "Thrust Piece Set, installation tool ball joint",
            "NormTermName": "Thrust Piece Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ball Joint Mounting Tools"
        },
        "09455": {
            "GenartName": "Ejector, ball joint",
            "NormTermName": "Ejector",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Ball Joint"
        },
        "09456": {
            "GenartName": "Connecting Cable, camshaft adjuster actuating solenoid",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Adjuster Actuating Solenoid"
        },
        "09457": {
            "GenartName": "Connecting Cable, camshaft adjuster actuating solenoid",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Engine Timing, universal",
            "UsageName": "Camshaft Adjuster Actuating Solenoid"
        },
        "09458": {
            "GenartName": "Press Frame, installation tool silent bearing",
            "NormTermName": "Press Frame",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Installation Tool, silent bearing"
        },
        "09459": {
            "GenartName": "Expansion set, injector extractor",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Injector extractor"
        },
        "09460": {
            "GenartName": "Release Pliers, hose line",
            "NormTermName": "Release Pliers",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Hose Line"
        },
        "09461": {
            "GenartName": "Thread Tap, nozzle holder mount",
            "NormTermName": "Thread Tap",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Nozzle Holder Mount"
        },
        "09462": {
            "GenartName": "Centering Tool, clutch disc",
            "NormTermName": "Centering Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Clutch Disc"
        },
        "09463": {
            "GenartName": "Puller, release shaft",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Clutch"
        },
        "09464": {
            "GenartName": "Centring Pin, core drill",
            "NormTermName": "Centring Pin",
            "AssGrpName": "Tool, universal",
            "UsageName": "Core Drill"
        },
        "09465": {
            "GenartName": "Angled Adapter, cordless screwdriver",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cordless Screwdriver"
        },
        "09466": {
            "GenartName": "Indexable Insert Cutter Set, milling tool",
            "NormTermName": "Indexable Insert Cutter Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Milling Tool"
        },
        "09467": {
            "GenartName": "Cutter with Indexable Inserts",
            "NormTermName": "Milling Cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Cutter with Indexable Inserts"
        },
        "09468": {
            "GenartName": "Calibration Device, driver assistance system",
            "NormTermName": "Calibration Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Driver Assistance System"
        },
        "09469": {
            "GenartName": "Attachment Holder, belt sander",
            "NormTermName": "Holder",
            "AssGrpName": "Tool, universal",
            "UsageName": "Belt Grinder"
        },
        "09470": {
            "GenartName": "Extension, multi-grinder",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Multi-grinder"
        },
        "09471": {
            "GenartName": "Extension, straight grinder",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Straight Grinder"
        },
        "09472": {
            "GenartName": "Sub-frame, handheld circular saw",
            "NormTermName": "Base Frame",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hand-held Circular Saw"
        },
        "09473": {
            "GenartName": "Drywall Screwdriver (mains operated)",
            "NormTermName": "Drywall Screwdriver (mains operated)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09474": {
            "GenartName": "Drywall Screwdriver (rechargeable battery)",
            "NormTermName": "Drywall Screwdriver (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09475": {
            "GenartName": "Circular Table Saw (mains operated)",
            "NormTermName": "Circular Table Saw (mains operated)",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09476": {
            "GenartName": "Depth Limiter, saw chain",
            "NormTermName": "Depth Limiter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Saw Chains"
        },
        "09477": {
            "GenartName": "Submersible pump",
            "NormTermName": "Pump",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09478": {
            "GenartName": "Stapler Nail",
            "NormTermName": "Stapler Nail",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09479": {
            "GenartName": "Dust Protection Filter, angle grinder",
            "NormTermName": "Filter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Dust Cover"
        },
        "09480": {
            "GenartName": "Chippings Extraction System, woodworking",
            "NormTermName": "Siphon",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wood Chippings Extraction System"
        },
        "09481": {
            "GenartName": "Protective Pouch, tuning module (engine perform. increase)",
            "NormTermName": "Protective Pouch",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Tuning Module (engine performance increase)"
        },
        "09482": {
            "GenartName": "Protective Hood, angle grinder",
            "NormTermName": "Protective Cover",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "09483": {
            "GenartName": "Reduction Adapter, drill chuck",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drill Chuck"
        },
        "09484": {
            "GenartName": "Adapter, machine thread tap",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Machine Thread Tap"
        },
        "09485": {
            "GenartName": "Connecting Set, quick coupling",
            "NormTermName": "Connector Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Quick Coupling"
        },
        "09486": {
            "GenartName": "Ejector Pin, core drill",
            "NormTermName": "Ejector Pin",
            "AssGrpName": "Tool, universal",
            "UsageName": "Core Drill"
        },
        "09487": {
            "GenartName": "Band Saw (rechargeable battery)",
            "NormTermName": "Band Saw (rechargeable battery)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09488": {
            "GenartName": "Concrete Drilling Kit",
            "NormTermName": "Drill Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Concrete Drill"
        },
        "09489": {
            "GenartName": "Retaining Spring, demolition hammer (compressed air)",
            "NormTermName": "Spring",
            "AssGrpName": "Tool, universal",
            "UsageName": "Demolition Hammer (compressed air)"
        },
        "09490": {
            "GenartName": "Filter, chippings extraction system for woodworking",
            "NormTermName": "Filter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Chippings Extraction System for Woodworking"
        },
        "09491": {
            "GenartName": "Flow Drill",
            "NormTermName": "Flow Drill",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09492": {
            "GenartName": "Hands-Free System",
            "NormTermName": "Hands-Free System",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "09493": {
            "GenartName": "Deep Well Pump",
            "NormTermName": "Deep Well Pump",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09494": {
            "GenartName": "Housing, tuning module (engine performance increase)",
            "NormTermName": "Housing",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Tuning Module (engine performance increase)"
        },
        "09495": {
            "GenartName": "Main Board, tuning module (engine performance increase)",
            "NormTermName": "Board",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Tuning Module (engine performance increase)"
        },
        "09496": {
            "GenartName": "Domestic Water Pressure Booster",
            "NormTermName": "Domestic Water Pressure Booster",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09497": {
            "GenartName": "Blade, smoothing plane",
            "NormTermName": "Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": "Smoothing Plane"
        },
        "09498": {
            "GenartName": "Cable Set, tuning module (engine performance increase)",
            "NormTermName": "Electric Cable Set",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Tuning Module (engine performance increase)"
        },
        "09499": {
            "GenartName": "Mitre Saw (mains)",
            "NormTermName": "Saw",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09500": {
            "GenartName": "Fillet Weld Sander (mains)",
            "NormTermName": "Fillet Weld Sander (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09501": {
            "GenartName": "Fillet Weld Sander (rechargeable)",
            "NormTermName": "Fillet Weld Sander (rechargeable)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09502": {
            "GenartName": "Glue Stick, hot glue gun",
            "NormTermName": "Glue Stick",
            "AssGrpName": "Chemical Products",
            "UsageName": "Hot Glue Gun"
        },
        "09503": {
            "GenartName": "Flap Wheel Sanding Disc, angle grinder",
            "NormTermName": "Grinding Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder"
        },
        "09504": {
            "GenartName": "Long-Neck Sander (mains)",
            "NormTermName": "Long-Neck Sander (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09505": {
            "GenartName": "Pump, all-wheel-drive coupling",
            "NormTermName": "Pump",
            "AssGrpName": "Axle Drive",
            "UsageName": "All-Wheel-Drive Coupling"
        },
        "09506": {
            "GenartName": "Line Laser",
            "NormTermName": "Line Laser",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09507": {
            "GenartName": "Ultrasonic Nebuliser",
            "NormTermName": "Ultrasonic Nebuliser",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Cleaning"
        },
        "09508": {
            "GenartName": "Hose Set, ultrasonic nebuliser",
            "NormTermName": "Hose Line Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Ultrasonic Nebuliser"
        },
        "09509": {
            "GenartName": "Ozone generator",
            "NormTermName": "Ozone generator",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Cleaning"
        },
        "09510": {
            "GenartName": "Filling Kit, UV additive",
            "NormTermName": "Filling Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09511": {
            "GenartName": "Flushing Adapter Set, air conditioning system",
            "NormTermName": "Flushing Adapter Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Systems"
        },
        "09512": {
            "GenartName": "Sealant Remover, air conditioning service unit",
            "NormTermName": "Sealant Remover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Systems"
        },
        "09513": {
            "GenartName": "Tester, compressor oil/refrigerant",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning"
        },
        "09514": {
            "GenartName": "Mounting Tool, brake caliper",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Caliper"
        },
        "09515": {
            "GenartName": "Foot Pump",
            "NormTermName": "Foot Pump",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "09516": {
            "GenartName": "Foot Pump",
            "NormTermName": "Foot Pump",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "09517": {
            "GenartName": "Expanding Pliers",
            "NormTermName": "Expanding Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09518": {
            "GenartName": "Flaring Cone",
            "NormTermName": "Flaring Cone",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09519": {
            "GenartName": "Crimping Strip",
            "NormTermName": "Crimping Strip",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09520": {
            "GenartName": "Mounting Tool Set, brake shoes",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Shoes"
        },
        "09521": {
            "GenartName": "Reset Tool, drum brake",
            "NormTermName": "Reset Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Drum Brake"
        },
        "09522": {
            "GenartName": "Adjusting Tool, drum brake",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Drum Brake"
        },
        "09523": {
            "GenartName": "Release Tool, brake pedal",
            "NormTermName": "Release Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Pedal"
        },
        "09524": {
            "GenartName": "Bending Tool Set, brake line",
            "NormTermName": "Bending Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Line"
        },
        "09525": {
            "GenartName": "Bending Tool, brake line",
            "NormTermName": "Bending Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Line"
        },
        "09526": {
            "GenartName": "Release Pliers, brake pedal",
            "NormTermName": "Release Pliers",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Brake Pedal"
        },
        "09527": {
            "GenartName": "Fork",
            "NormTermName": "Fork",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "09528": {
            "GenartName": "Hoe",
            "NormTermName": "Hoe",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "09529": {
            "GenartName": "Cleaning Pin, thread",
            "NormTermName": "Cleaning Pin",
            "AssGrpName": "Tool, universal",
            "UsageName": "Thread"
        },
        "09530": {
            "GenartName": "Release Tool, handle",
            "NormTermName": "Removal Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Grab Handle"
        },
        "09531": {
            "GenartName": "Mounting Sleeve, piston ring",
            "NormTermName": "Installation Sleeve",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Piston Ring"
        },
        "09532": {
            "GenartName": "Mounting Tool Set, triangular control arm",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Triangular Control Arm"
        },
        "09533": {
            "GenartName": "Mounting Tool, triangular control arm",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Triangular Control Arm"
        },
        "09534": {
            "GenartName": "Mounting/Dismantling Sleeve, brake caliper",
            "NormTermName": "Mounting/Dismantling Sleeve",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Brake Caliper"
        },
        "09535": {
            "GenartName": "Puller, belt pulley",
            "NormTermName": "Puller",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Belt Pulley"
        },
        "09536": {
            "GenartName": "Locking Tool, flywheel",
            "NormTermName": "Retaining Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Flywheel"
        },
        "09537": {
            "GenartName": "Mounting/Dismantling Tool, wheel studs",
            "NormTermName": "Mounting/Dismantling Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Bolt"
        },
        "09538": {
            "GenartName": "Mounting/Dismantling Tool Set, wheel studs",
            "NormTermName": "Installation/Removal Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wheel Bolt"
        },
        "09539": {
            "GenartName": "Mounting/Dismantling Tool, brake disc",
            "NormTermName": "Mounting/Dismantling Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Brake Disc"
        },
        "09540": {
            "GenartName": "Trim Pry",
            "NormTermName": "Trim Pry",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09541": {
            "GenartName": "Pressure Cylinder, leak-finding (air conditioning)",
            "NormTermName": "Pressure Cylinder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning"
        },
        "09542": {
            "GenartName": "Filling Adapter, pneumatic brake system",
            "NormTermName": "Adaptor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "09543": {
            "GenartName": "Filling Adapter, pneumatic brake system",
            "NormTermName": "Adaptor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "09544": {
            "GenartName": "Adapter, demolition hammer",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Chisel Hammer"
        },
        "09545": {
            "GenartName": "Mounting Tool Set, engine/ transmission unit",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Engine/ Transmission Unit"
        },
        "09546": {
            "GenartName": "Spray Probe, soot/particulate filter",
            "NormTermName": "Spray Probe",
            "AssGrpName": "Tool, universal",
            "UsageName": "Soot/Particulate Filter"
        },
        "09547": {
            "GenartName": "Spray Lance, soot/particulate filter",
            "NormTermName": "Spray Lance",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Soot/Particulate Filter"
        },
        "09548": {
            "GenartName": "Metering Pump",
            "NormTermName": "Metering Pump",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09549": {
            "GenartName": "Leak Warning Device",
            "NormTermName": "Leak Warning Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09550": {
            "GenartName": "Cartridge Gun",
            "NormTermName": "Cartridge Gun",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09551": {
            "GenartName": "Cartridge Pipe",
            "NormTermName": "Cartridge Pipe",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09552": {
            "GenartName": "Assortment, cable tie mounting base",
            "NormTermName": "Assortment",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "09553": {
            "GenartName": "Workshop Trolley",
            "NormTermName": "Workshop Trolley",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09554": {
            "GenartName": "Computer",
            "NormTermName": "Computer",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09555": {
            "GenartName": "Mouse, computer",
            "NormTermName": "Mouse",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Computer"
        },
        "09556": {
            "GenartName": "Exhaust Gas Measurement Module",
            "NormTermName": "Exhaust Gas Measurement Module",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09557": {
            "GenartName": "Measurement Module, RPM",
            "NormTermName": "Measurement Module",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09558": {
            "GenartName": "Diagnostics Module, controller",
            "NormTermName": "Diagnostics Module",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09559": {
            "GenartName": "Suction Device, refrigerant",
            "NormTermName": "Siphon",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning"
        },
        "09560": {
            "GenartName": "Measurement Module, system analysis",
            "NormTermName": "Measurement Module",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09561": {
            "GenartName": "Tester, refrigerant",
            "NormTermName": "Tester",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning"
        },
        "09562": {
            "GenartName": "Mounting Tool Set, trailing/control arm bush",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Trailing/Control Arm Bush"
        },
        "09563": {
            "GenartName": "Shock Absorber Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Shock Absorber"
        },
        "09564": {
            "GenartName": "Air Filter Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Air Filter"
        },
        "09565": {
            "GenartName": "Spindle Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Spindle"
        },
        "09566": {
            "GenartName": "Auto Shampoo",
            "NormTermName": "Auto Shampoo",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09567": {
            "GenartName": "Degreaser",
            "NormTermName": "Degreaser",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09568": {
            "GenartName": "Velcro Tape",
            "NormTermName": "Velcro Tape",
            "AssGrpName": "Standard Parts",
            "UsageName": null
        },
        "09569": {
            "GenartName": "Cable Repair Set, heating element",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply",
            "UsageName": null
        },
        "09570": {
            "GenartName": "Cable Repair Set, heating element",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply, universal",
            "UsageName": null
        },
        "09571": {
            "GenartName": "Cable Repair Set, throttle position setting sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": null
        },
        "09572": {
            "GenartName": "Cable Repair Set, throttle position setting sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": null
        },
        "09573": {
            "GenartName": "Cable Repair Set, radar sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Safety Systems",
            "UsageName": null
        },
        "09574": {
            "GenartName": "Cable Repair Set, radar sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Security Systems, universal",
            "UsageName": null
        },
        "09575": {
            "GenartName": "Software, torque wrench",
            "NormTermName": "Software",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Torque Wrench"
        },
        "09576": {
            "GenartName": "Bit, air conditioning system valve",
            "NormTermName": "Bit",
            "AssGrpName": "Tool, universal",
            "UsageName": "Air Conditioning"
        },
        "09577": {
            "GenartName": "Suction Attachment, cleaning gun (compressed air)",
            "NormTermName": "Suction Attachment",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cleaning Gun (compressed air)"
        },
        "09578": {
            "GenartName": "Mounting Plate, multi-grinder",
            "NormTermName": "Mounting Plate",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Multi-grinder"
        },
        "09579": {
            "GenartName": "Replacement Grinding Disc Set, multi grinder",
            "NormTermName": "Replacement Grinding Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Multi-grinder"
        },
        "09580": {
            "GenartName": "Hook Set, brake caliper",
            "NormTermName": "Hook Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Brake Caliper"
        },
        "09581": {
            "GenartName": "Holding Tool, glow plug / spark plug",
            "NormTermName": "Holding Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Glow Plug / Spark Plug"
        },
        "09582": {
            "GenartName": "Mounting/Lifting Aid, brake caliper",
            "NormTermName": "Mounting Lifting Aid",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Brake Caliper"
        },
        "09583": {
            "GenartName": "Special Socket Wrench Insert, oil drain plug",
            "NormTermName": "Socket Wrench Insert",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Oil Drain Plug"
        },
        "09586": {
            "GenartName": "Impact Puller, wheel bearing",
            "NormTermName": "Impact Extractor",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Bearing"
        },
        "09587": {
            "GenartName": "Mounting Base, wiring harness",
            "NormTermName": "Mounting Base",
            "AssGrpName": "Standard Parts",
            "UsageName": "Cable Fixing"
        },
        "09588": {
            "GenartName": "Repair Kit, camshaft adjustment",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Adjustment"
        },
        "09589": {
            "GenartName": "Hose Connector, radiator hose",
            "NormTermName": "Hose Connector",
            "AssGrpName": "Cooling System",
            "UsageName": "Radiator Hose"
        },
        "09590": {
            "GenartName": "Cable Repair Set, engine oil level sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lubrication",
            "UsageName": "Engine Oil Level Sensor"
        },
        "09591": {
            "GenartName": "Cable Repair Set, engine oil level sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lubrication, universal",
            "UsageName": "Engine Oil Level Sensor"
        },
        "09592": {
            "GenartName": "Cable Repair Set, distance sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Instruments",
            "UsageName": "Distance Sensor"
        },
        "09593": {
            "GenartName": "Cable Repair Set, distance sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Distance Sensor"
        },
        "09594": {
            "GenartName": "Cable Repair Set, radio clock receiver",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Instruments",
            "UsageName": "Radio Clock Receiver"
        },
        "09595": {
            "GenartName": "Cable Repair Set, radio clock receiver",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Radio Clock Receiver"
        },
        "09596": {
            "GenartName": "Cable Repair Set, slave cylinder",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Clutch",
            "UsageName": "Slave Cylinder"
        },
        "09597": {
            "GenartName": "Cable Repair Set, slave cylinder",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Clutch, universal",
            "UsageName": "Slave Cylinder"
        },
        "09598": {
            "GenartName": "Cable Repair Set, shock absorber movement sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Shock Absorber Movement Sensor"
        },
        "09599": {
            "GenartName": "Cable Repair Set, shock absorber movement sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Suspension, universal",
            "UsageName": "Shock Absorber Movement Sensor"
        },
        "09600": {
            "GenartName": "Cable Repair Set, fuel pump control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump Control Unit"
        },
        "09601": {
            "GenartName": "Cable Repair Set, fuel pump control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Fuel Pump Control Unit"
        },
        "09602": {
            "GenartName": "Cable Repair Set, NOx sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust System",
            "UsageName": "NOx Sensor"
        },
        "09603": {
            "GenartName": "Cable Repair Set, NOx sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "NOx Sensor"
        },
        "09604": {
            "GenartName": "Cable Repair Set, master cylinder",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Clutch",
            "UsageName": "Master Cylinder"
        },
        "09605": {
            "GenartName": "Cable Repair Set, master cylinder",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Clutch, universal",
            "UsageName": "Master Cylinder"
        },
        "09606": {
            "GenartName": "Retrofit Kit, SCR catalytic converter",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "SCR catalytic converter"
        },
        "09607": {
            "GenartName": "Cable Repair Set, brake lining wear indicator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Lining Wear Indicator"
        },
        "09608": {
            "GenartName": "Cable Repair Set, brake lining wear indicator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Lining Wear Indicator"
        },
        "09609": {
            "GenartName": "Pressure Transducer, exhaust flap controller",
            "NormTermName": "Pressure Converter",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Flap Controller"
        },
        "09610": {
            "GenartName": "Cable Repair Set, turbocharger pressure transducer",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply",
            "UsageName": "Turbocharger Pressure Transducer"
        },
        "09611": {
            "GenartName": "Cable Repair Set, turbocharger pressure transducer",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply, universal",
            "UsageName": "Turbocharger Pressure Transducer"
        },
        "09612": {
            "GenartName": "Cable Repair Set, camshaft adjuster central magnet",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Adjuster Central Magnet"
        },
        "09613": {
            "GenartName": "Cable Repair Set, camshaft adjuster central magnet",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Engine Timing, universal",
            "UsageName": "Camshaft Adjuster Central Magnet"
        },
        "09614": {
            "GenartName": "Seal Set, crankshaft",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankshaft"
        },
        "09615": {
            "GenartName": "Retrofit Kit, blind spot assistant",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Security Systems, universal",
            "UsageName": null
        },
        "09616": {
            "GenartName": "Retrofit Kit, blind spot assistant",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Safety Systems",
            "UsageName": null
        },
        "09617": {
            "GenartName": "Suction Set, electrical tool",
            "NormTermName": "Suction Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Electrical Power Tool"
        },
        "09618": {
            "GenartName": "Paint Additive",
            "NormTermName": "Additive",
            "AssGrpName": "Chemical Products",
            "UsageName": "Paint"
        },
        "09619": {
            "GenartName": "Mounting Shaft, polisher",
            "NormTermName": "Mounting Shaft",
            "AssGrpName": "Tool, universal",
            "UsageName": "Polishing Machine"
        },
        "09620": {
            "GenartName": "Submersible Pump, wastewater",
            "NormTermName": "Pump",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Wastewater"
        },
        "09621": {
            "GenartName": "Software, engine performance increase",
            "NormTermName": "Software",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Engine Power Increase"
        },
        "09622": {
            "GenartName": "Diamond Sheet",
            "NormTermName": "Diamond Sheet",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09623": {
            "GenartName": "Machine Thread Tap Set",
            "NormTermName": "Machine Thread Tap Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Machine Thread Tap"
        },
        "09624": {
            "GenartName": "Universal Deburrer",
            "NormTermName": "Deburring Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09625": {
            "GenartName": "Blade Set, universal deburrer",
            "NormTermName": "Blade Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Universal Deburrer"
        },
        "09626": {
            "GenartName": "Electrical Tool Set (rechargeable)",
            "NormTermName": "Electrical Tool Set (rechargeable)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09627": {
            "GenartName": "Electrical Tool Set (mains)",
            "NormTermName": "Electrical Tool Set (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09628": {
            "GenartName": "Fine Grinding Cylinder",
            "NormTermName": "Fine Grinding Cylinder",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09629": {
            "GenartName": "Needle File Set",
            "NormTermName": "Needle File Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09630": {
            "GenartName": "Filter, domestic water pressure booster",
            "NormTermName": "Filter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Domestic Water Pressure Booster"
        },
        "09631": {
            "GenartName": "Milling/Grinding Motor, router",
            "NormTermName": "Milling/Grinding Motor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Router"
        },
        "09632": {
            "GenartName": "Reduction Adapter, electrical power tool chuck",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Electrical Power Tool Chuck"
        },
        "09633": {
            "GenartName": "Rasps",
            "NormTermName": "File",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09634": {
            "GenartName": "Reduction Ring, handheld circular saw blade",
            "NormTermName": "Reduction Bushing",
            "AssGrpName": "Tool, universal",
            "UsageName": "Saw Blade - Handheld Circular Saw"
        },
        "09635": {
            "GenartName": "Saw Blade, handheld circular saw",
            "NormTermName": "Saw Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hand-held Circular Saw"
        },
        "09636": {
            "GenartName": "Leaf Blower (rechargeable)",
            "NormTermName": "Leaf Blower",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09637": {
            "GenartName": "Star-shaped Milling Cutter, concrete planer",
            "NormTermName": "Star-shaped Milling Cutter",
            "AssGrpName": "Building Tools",
            "UsageName": "Concrete Planer"
        },
        "09638": {
            "GenartName": "Concrete Planer (mains)",
            "NormTermName": "Concrete Planer",
            "AssGrpName": "Building Tools",
            "UsageName": "Concrete Planer"
        },
        "09639": {
            "GenartName": "Concrete Planer (mains)",
            "NormTermName": "Concrete Planer",
            "AssGrpName": "Building Tools",
            "UsageName": null
        },
        "09640": {
            "GenartName": "Thread Former",
            "NormTermName": "Thread Former",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09641": {
            "GenartName": "Template Follower, router",
            "NormTermName": "Template Follower",
            "AssGrpName": "Tool, universal",
            "UsageName": "Router"
        },
        "09642": {
            "GenartName": "Hose Coupling, domestic water pressure booster",
            "NormTermName": "Hose Coupling",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Domestic Water Pressure Booster"
        },
        "09643": {
            "GenartName": "Dry-Running Protection, domestic water pressure booster",
            "NormTermName": "Dry-Running Protection",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Domestic Water Pressure Booster"
        },
        "09644": {
            "GenartName": "Hose, fuel system pressure tester",
            "NormTermName": "Hose",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Fuel System Pressure Tester"
        },
        "09645": {
            "GenartName": "Pin Wrench Set, injector holder clamping nut",
            "NormTermName": "Pin Wrench Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector Holder, clamping nut"
        },
        "09646": {
            "GenartName": "Retaining Tool Set, crankshaft",
            "NormTermName": "Retaining Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft Pulley"
        },
        "09647": {
            "GenartName": "Bleeding Tool Set, fuel system",
            "NormTermName": "Bleeding Tool",
            "AssGrpName": "Tool, universal",
            "UsageName": "Fuel system"
        },
        "09648": {
            "GenartName": "Pliers, spacer",
            "NormTermName": "Pliers",
            "AssGrpName": "Tool, universal",
            "UsageName": "Spacer"
        },
        "09649": {
            "GenartName": "Repair Kit, fuel filter housing",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Filter Housing"
        },
        "09650": {
            "GenartName": "Cable Sheath Saw",
            "NormTermName": "Saw",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09651": {
            "GenartName": "Valve Milling Head, water cock",
            "NormTermName": "Valve Milling Head",
            "AssGrpName": "Tool, universal",
            "UsageName": "Valve Seat"
        },
        "09652": {
            "GenartName": "Valve Milling Head Set, water cock",
            "NormTermName": "Valve Milling Head Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Valve Seat"
        },
        "09653": {
            "GenartName": "Dashboard Protective Cover, glass repair",
            "NormTermName": "Schutztafel",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09654": {
            "GenartName": "Mounting/Dismantling Tool, injector",
            "NormTermName": "Mounting/Dismantling Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector Nozzle"
        },
        "09655": {
            "GenartName": "Adjusting Tool, injector",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Injector Nozzle"
        },
        "09656": {
            "GenartName": "Mounting Aid, clutch",
            "NormTermName": "Mounting Aid",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09657": {
            "GenartName": "Mounting Aid Set, clutch",
            "NormTermName": "Mounting Aid Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09658": {
            "GenartName": "Reduction Ring Set, angle grinder cutting disc",
            "NormTermName": "Reduction Ring Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Angle Grinder Cutting Disc"
        },
        "09659": {
            "GenartName": "Machine Stand",
            "NormTermName": "Machine Stand",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09660": {
            "GenartName": "Needle file",
            "NormTermName": "Needle file",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09661": {
            "GenartName": "Saw Blade, portable band saw",
            "NormTermName": "Saw Blade",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hand Band Saw"
        },
        "09662": {
            "GenartName": "Nail Set, pneumatic nailer",
            "NormTermName": "Nail Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Pneumatic Nailer"
        },
        "09663": {
            "GenartName": "Wall Chaser",
            "NormTermName": "Milling Machine",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09664": {
            "GenartName": "Oil Drain Clutch",
            "NormTermName": "Coupling",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Valve Screw"
        },
        "09665": {
            "GenartName": "Oil Drain Set",
            "NormTermName": "Oil Drain Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "09666": {
            "GenartName": "Pencil Grinder Set",
            "NormTermName": "Pencil Grinder Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Straight Grinder"
        },
        "09667": {
            "GenartName": "Cable Repair Set, engine block heater pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Parking Heater"
        },
        "09668": {
            "GenartName": "Cable Repair Set, engine block heater pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Parking Heater"
        },
        "09669": {
            "GenartName": "Cable Repair Set, horn/signal horn",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Signal System",
            "UsageName": "Horn"
        },
        "09670": {
            "GenartName": "Cable Repair Set, horn/signal horn",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Horn"
        },
        "09671": {
            "GenartName": "Cable Repair Set, crankcase ventilation heating",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase Ventilation"
        },
        "09672": {
            "GenartName": "Cable Repair Set, crankcase ventilation heating",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Crankcase, universal",
            "UsageName": "Crankcase Ventilation"
        },
        "09673": {
            "GenartName": "Cable Repair Set, parking brake actuator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake Caliper"
        },
        "09674": {
            "GenartName": "Cable Repair Set, parking brake actuator",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Parking Brake Caliper"
        },
        "09675": {
            "GenartName": "Cable Repair Set, windscreen wash pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": "Wash Waterpump, headlight cleaning"
        },
        "09676": {
            "GenartName": "Cable Repair Set, windscreen wash pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Headlight Cleaning, universal",
            "UsageName": "Wash Waterpump, headlight cleaning"
        },
        "09677": {
            "GenartName": "Cable Repair Kit, washer fluid level sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washer Fluid Level"
        },
        "09678": {
            "GenartName": "Cable Repair Kit, washer fluid level sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": "Washer Fluid Level"
        },
        "09679": {
            "GenartName": "Cable Repair Set, tank vent valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Tank Ventilation"
        },
        "09680": {
            "GenartName": "Cable Repair Set, tank vent valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Tank Ventilation"
        },
        "09681": {
            "GenartName": "Cable Repair Set, windscreen wash nozzle",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": "Windscreen Wash Nozzle"
        },
        "09682": {
            "GenartName": "Cable Repair Set, windscreen wash nozzle",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Headlight Cleaning, universal",
            "UsageName": "Windscreen Wash Nozzle"
        },
        "09683": {
            "GenartName": "Cable Repair Set, brake system hydraulic pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Hydraulics"
        },
        "09684": {
            "GenartName": "Cable Repair Set, brake system hydraulic pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Hydraulics"
        },
        "09685": {
            "GenartName": "Cable Repair Set, engine bearing changeover valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Engine Mounting System",
            "UsageName": "Engine Mount"
        },
        "09686": {
            "GenartName": "Cable Repair Set, engine bearing changeover valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Engine Mounting System, universal",
            "UsageName": "Engine Mount"
        },
        "09687": {
            "GenartName": "Cable Repair Set, camshaft position sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Camshaft"
        },
        "09688": {
            "GenartName": "Cable Repair Set, camshaft position sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Camshaft"
        },
        "09689": {
            "GenartName": "Cable Repair Set, front windscreen heating control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Windscreen Heating"
        },
        "09690": {
            "GenartName": "Cable Repair Set, front windscreen heating control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Windscreen Heating"
        },
        "09691": {
            "GenartName": "Cable Repair Set, kickdown magnetic switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Solenoid Switch"
        },
        "09692": {
            "GenartName": "Cable Repair Set, kickdown magnetic switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Automatic Transmission, universal",
            "UsageName": "Solenoid Switch"
        },
        "09693": {
            "GenartName": "Cable Repair Set, throttle valve pressure transducer",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Acuator (throttle valve)"
        },
        "09694": {
            "GenartName": "Cable Repair Set, throttle valve pressure transducer",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Acuator (throttle valve)"
        },
        "09695": {
            "GenartName": "Cable Repair Set, fuel regenerating valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Charcoal Canister"
        },
        "09696": {
            "GenartName": "Cable Repair Set, fuel regenerating valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Charcoal Canister"
        },
        "09697": {
            "GenartName": "Cable Repair Set, brake system vacuum pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum"
        },
        "09698": {
            "GenartName": "Cable Repair Set, brake system vacuum pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Vacuum"
        },
        "09699": {
            "GenartName": "Cable Repair Set, secondary air pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": "Secondary Air"
        },
        "09700": {
            "GenartName": "Cable Repair Set, secondary air pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Secondary Air Injection, universal",
            "UsageName": "Secondary Air"
        },
        "09701": {
            "GenartName": "Cable Repair Set, VLIM/VIS/VIM pressure transducer",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply",
            "UsageName": "Change-over Cover (induction pipe)"
        },
        "09702": {
            "GenartName": "Cable Repair Set, VLIM/VIS/VIM pressure transducer",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply, universal",
            "UsageName": "Change-over Cover (induction pipe)"
        },
        "09703": {
            "GenartName": "Cable Repair Set, wind deflector electric motor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Wind Deflector"
        },
        "09704": {
            "GenartName": "Cable Repair Set, wind deflector electric motor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Wind Deflector"
        },
        "09705": {
            "GenartName": "Cable Repair Set, compressor damping adjustment",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Electronic damping adjustment"
        },
        "09706": {
            "GenartName": "Cable Repair Set, compressor damping adjustment",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Suspension, universal",
            "UsageName": "Electronic damping adjustment"
        },
        "09707": {
            "GenartName": "Mounting Tool Set, crankshaft pulley",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft Gear"
        },
        "09708": {
            "GenartName": "Micromotor Handpiece",
            "NormTermName": "Micromotor Handpiece",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09709": {
            "GenartName": "Oil Drain Valve Screw",
            "NormTermName": "Oil Drain Valve Screw",
            "AssGrpName": "Tool, universal",
            "UsageName": "Oil Drain Hose"
        },
        "09710": {
            "GenartName": "Hand Polishing Felt",
            "NormTermName": "Hand Polishing Felt",
            "AssGrpName": "Chemical Products",
            "UsageName": "Metal"
        },
        "09711": {
            "GenartName": "Riffler File",
            "NormTermName": "File",
            "AssGrpName": "Tool, universal",
            "UsageName": "Riffler File"
        },
        "09712": {
            "GenartName": "Grinding Disc, double grinding machine",
            "NormTermName": "Grinding Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": "Double Grinding Machine"
        },
        "09713": {
            "GenartName": "Sharpening File",
            "NormTermName": "File",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09714": {
            "GenartName": "Grinding Belt Arm, belt file",
            "NormTermName": "Grinding Belt Arm",
            "AssGrpName": "Tool, universal",
            "UsageName": "Belt File"
        },
        "09715": {
            "GenartName": "Fine Bag Filter, compressed air",
            "NormTermName": "Fine Bag Filter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Compressed Air Systems"
        },
        "09716": {
            "GenartName": "Reversible blade",
            "NormTermName": "Reversible blade",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09717": {
            "GenartName": "Dust bag, rotary sander",
            "NormTermName": "Dust bag",
            "AssGrpName": "Tool, universal",
            "UsageName": "Eccentric Grinder"
        },
        "09718": {
            "GenartName": "Dust bag, saw",
            "NormTermName": "Dust bag",
            "AssGrpName": "Tool, universal",
            "UsageName": "Saw"
        },
        "09719": {
            "GenartName": "Dust bag, plane",
            "NormTermName": "Dust bag",
            "AssGrpName": "Tool, universal",
            "UsageName": "Smoothing Plane"
        },
        "09720": {
            "GenartName": "Chip extraction nozzle",
            "NormTermName": "Fitting",
            "AssGrpName": "Tool, universal",
            "UsageName": "Chip extraction"
        },
        "09721": {
            "GenartName": "Quick-change adapter, drill chuck (core drill)",
            "NormTermName": "Quick-change insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drill chuck (core drill)"
        },
        "09722": {
            "GenartName": "Blade, hedge trimmer",
            "NormTermName": "Shear blade",
            "AssGrpName": "Garden Tools",
            "UsageName": "Hedge Shears"
        },
        "09723": {
            "GenartName": "Urea",
            "NormTermName": "Urea",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea"
        },
        "09724": {
            "GenartName": "Inlet connector, injection nozzle",
            "NormTermName": "Inlet connector",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Nozzle"
        },
        "09725": {
            "GenartName": "Socket union, injection nozzle inlet connector",
            "NormTermName": "Union Bolt",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Nozzle"
        },
        "09726": {
            "GenartName": "Polishing paste",
            "NormTermName": "Polishing paste",
            "AssGrpName": "Chemical Products",
            "UsageName": "Body"
        },
        "09727": {
            "GenartName": "Stick-On Polishing Disc",
            "NormTermName": "Stick-On Polishing Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09728": {
            "GenartName": "Tapered Mandrel, core drill",
            "NormTermName": "Tapered Mandrel",
            "AssGrpName": "Tool, universal",
            "UsageName": "Core Drill"
        },
        "09729": {
            "GenartName": "Circle-Cutting Device, band saw",
            "NormTermName": "Circle-Cutting Device",
            "AssGrpName": "Tool, universal",
            "UsageName": "Band Saw"
        },
        "09730": {
            "GenartName": "Quick-Clamp Adapter, keyhole saw",
            "NormTermName": "Quick-Clamp Adapter",
            "AssGrpName": "Tool, universal",
            "UsageName": "Keyhole Saw"
        },
        "09731": {
            "GenartName": "Fleece Disc, grinding equipment",
            "NormTermName": "Fleece Disc",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09732": {
            "GenartName": "Oil Misting Device",
            "NormTermName": "Oil Misting Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Compressed Air System"
        },
        "09733": {
            "GenartName": "Polishing Disc Set",
            "NormTermName": "Polishing Disc Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Polishing Machine"
        },
        "09734": {
            "GenartName": "Tube Belt Sander (rechargeable)",
            "NormTermName": "Tube Belt Sander (rechargeable)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09735": {
            "GenartName": "Tube Belt Sander (mains)",
            "NormTermName": "Tube Belt Sander (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09736": {
            "GenartName": "Suction Nozzle, dry vacuum cleaner",
            "NormTermName": "Suction Nozzle",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Dry Vacuum"
        },
        "09737": {
            "GenartName": "Pipe Nozzle, compressed air equipment",
            "NormTermName": "Pipe Nozzle",
            "AssGrpName": "Tool, universal",
            "UsageName": "Compressed Air Systems"
        },
        "09738": {
            "GenartName": "Suction Hose, chippings extraction system",
            "NormTermName": "Suction Hose",
            "AssGrpName": "Tool, universal",
            "UsageName": "Chip extraction"
        },
        "09739": {
            "GenartName": "Hose Connector Set, chippings extraction system",
            "NormTermName": "Hose Connector Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Chip extraction"
        },
        "09740": {
            "GenartName": "Grinding Belt, double grinding machine",
            "NormTermName": "Grinding Belt",
            "AssGrpName": "Tool, universal",
            "UsageName": "Double Grinding Machine"
        },
        "09741": {
            "GenartName": "Thermal Printer Paper, air conditioning service unit",
            "NormTermName": "Thermal Printer Paper",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Service Unit"
        },
        "09742": {
            "GenartName": "Heating Tape Set, air conditioning service unit",
            "NormTermName": "Heating Strip Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Service Unit"
        },
        "09743": {
            "GenartName": "Service Filter, air conditioning service unit",
            "NormTermName": "Service Filter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Service Unit"
        },
        "09744": {
            "GenartName": "Vacuum Pump Set",
            "NormTermName": "Pump Set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09745": {
            "GenartName": "USB Stick",
            "NormTermName": "USB Stick",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09746": {
            "GenartName": "Diagnosis Unit, exhaust gas after-treatment",
            "NormTermName": "Diagnosis Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Exhaust Gas After-Treatment"
        },
        "09747": {
            "GenartName": "Mounting Flange, turning machine",
            "NormTermName": "Mounting Flange",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Turning Machine"
        },
        "09748": {
            "GenartName": "Mounting Flange Set, turning machine",
            "NormTermName": "Mounting Flange Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Turning Machine"
        },
        "09749": {
            "GenartName": "Disassembly Tool, drive shaft",
            "NormTermName": "Disassembly Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Drive Shaft"
        },
        "09750": {
            "GenartName": "Thread Repair Set, wheel bolts",
            "NormTermName": "Thread Repair Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Bolt"
        },
        "09751": {
            "GenartName": "Thread Repair, wheel bolts",
            "NormTermName": "Thread Repair",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Bolt"
        },
        "09752": {
            "GenartName": "Thread Repair, wheel bolts",
            "NormTermName": "Thread Repair",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wheel Bolt"
        },
        "09753": {
            "GenartName": "Thread Repair Set, wheel bolts",
            "NormTermName": "Thread Repair Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wheel Bolt"
        },
        "09754": {
            "GenartName": "Press-out Device Set, drive shaft",
            "NormTermName": "Press-out Device Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Drive Shaft"
        },
        "09755": {
            "GenartName": "Separating Tool, wheel mounting",
            "NormTermName": "Separating Tool",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Mounting"
        },
        "09756": {
            "GenartName": "Separating Tool Set, wheel mounting",
            "NormTermName": "Cutting Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Wheel Mounting"
        },
        "09757": {
            "GenartName": "Separating Tool, wheel mounting",
            "NormTermName": "Separating Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Mounting"
        },
        "09758": {
            "GenartName": "Separating Tool Set, wheel mounting",
            "NormTermName": "Cutting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Wheel Mounting"
        },
        "09759": {
            "GenartName": "Adapter Plate, cylinder liner",
            "NormTermName": "Adapter Plate",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Cylinder Sleeve"
        },
        "09760": {
            "GenartName": "Retaining Ring, puller",
            "NormTermName": "Retaining Ring",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Differential Main Shaft"
        },
        "09761": {
            "GenartName": "Release Tool, dual-clutch transmission (DSG)",
            "NormTermName": "Release Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Dual-Clutch Transmission (DSG)"
        },
        "09762": {
            "GenartName": "Adapter, pipe deburring device",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Deburring Tool, pipes"
        },
        "09763": {
            "GenartName": "Mounting Tool Set, axle beam mounting",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Wheel Suspension",
            "UsageName": null
        },
        "09764": {
            "GenartName": "Mounting Tool Set, timing case cover",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Timing Case Cover"
        },
        "09765": {
            "GenartName": "Handpiece, flexible shaft system",
            "NormTermName": "Handpiece",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flexible Shaft System"
        },
        "09766": {
            "GenartName": "Tuning Module Set, engine performance increase",
            "NormTermName": "Tuning Module Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Engine Power Increase"
        },
        "09767": {
            "GenartName": "Motor, flexible shaft system",
            "NormTermName": "Motor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flexible Shaft System"
        },
        "09768": {
            "GenartName": "Pump Mounting Set, domestic water pressure booster",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Domestic Water Pressure Booster Pump"
        },
        "09769": {
            "GenartName": "Stapler (rechargeable)",
            "NormTermName": "Stapler (rechargeable)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09770": {
            "GenartName": "Stapler (mains)",
            "NormTermName": "Stapler (mains)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09771": {
            "GenartName": "Stapler (compressed air)",
            "NormTermName": "Stapler (compressed air)",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09772": {
            "GenartName": "Foot Pedal, flexible shaft system",
            "NormTermName": "Pedal",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flexible Shaft System"
        },
        "09773": {
            "GenartName": "Flexible Shaft Sleeve, flexible shaft system",
            "NormTermName": "Sleeve",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flexible Shaft System"
        },
        "09774": {
            "GenartName": "Flexible Shaft, flexible shaft system",
            "NormTermName": "Shaft",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flexible Shaft System"
        },
        "09775": {
            "GenartName": "Carbon Brush, flexible shaft system (motor)",
            "NormTermName": "Carbon Brush",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flexible Shaft System (Motor)"
        },
        "09776": {
            "GenartName": "Bearing, flexible shaft system (motor)",
            "NormTermName": "Bearing",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flexible Shaft System (Motor)"
        },
        "09777": {
            "GenartName": "Rotor, flexible shaft system (motor)",
            "NormTermName": "Rotor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Flexible Shaft System (Motor)"
        },
        "09778": {
            "GenartName": "Sight Glass, analysis of coolant (A/C service unit)",
            "NormTermName": "Inspection Glass",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Analysis of Coolant (air conditioning service unit)"
        },
        "09779": {
            "GenartName": "Camshaft Carrier",
            "NormTermName": "Camshaft Carrier",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft"
        },
        "09780": {
            "GenartName": "Pneumatic Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09781": {
            "GenartName": "Turbine Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09782": {
            "GenartName": "Insulating Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09783": {
            "GenartName": "Grinding Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09784": {
            "GenartName": "Cooling Lubricant",
            "NormTermName": "Cooling Lubricant",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09785": {
            "GenartName": "Slipway Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": "Turning Machine"
        },
        "09786": {
            "GenartName": "Forming Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09787": {
            "GenartName": "Honing Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09788": {
            "GenartName": "Quenching Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09789": {
            "GenartName": "Heat Transfer Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09790": {
            "GenartName": "Filter, vacuum line",
            "NormTermName": "Filter",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum Line"
        },
        "09791": {
            "GenartName": "White Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09792": {
            "GenartName": "Mould Oil",
            "NormTermName": "Oil",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09793": {
            "GenartName": "Cleaning Additive, heat transfer system",
            "NormTermName": "Cleaning Additive",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09794": {
            "GenartName": "Cable Repair Set, exterior mirror heating",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Body",
            "UsageName": "Exterior mirror Heating"
        },
        "09795": {
            "GenartName": "Cable Repair Set, exterior mirror heating",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Exterior mirror Heating"
        },
        "09796": {
            "GenartName": "Mixer Protection Agent",
            "NormTermName": "Mixer Protection Agent",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "09797": {
            "GenartName": "Fuel Additive",
            "NormTermName": "Additive",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel"
        },
        "09798": {
            "GenartName": "Sensor, blind spot detection",
            "NormTermName": "Sensor",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Blind Spot Detection"
        },
        "09799": {
            "GenartName": "Cable Set, blind spot sensor",
            "NormTermName": "Cable Set",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Blind Spot Sensor"
        },
        "09800": {
            "GenartName": "Control Unit, indicator detection",
            "NormTermName": "Control Unit",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Indicator Detection"
        },
        "09801": {
            "GenartName": "Camera, blind spot assistant",
            "NormTermName": "Camera",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Blind Spot Assistant"
        },
        "09802": {
            "GenartName": "Control Unit, blind spot assistant",
            "NormTermName": "Control Unit",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Blind Spot Assistant"
        },
        "09803": {
            "GenartName": "Extension Set, drilling tool (control arm clamping screw)",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Control Arm Clamping Screw"
        },
        "09804": {
            "GenartName": "Release Pliers, injector seal",
            "NormTermName": "Release Pliers",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": null
        },
        "09805": {
            "GenartName": "Conversion Kit, spring compressor",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Spring Compressor"
        },
        "09806": {
            "GenartName": "Cable Repair Set, air conditioning compressor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Air Conditioning Compressor"
        },
        "09807": {
            "GenartName": "Cable Repair Set, air conditioning compressor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Air Conditioning Compressor"
        },
        "09808": {
            "GenartName": "Cable Repair Set, engine mount",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Engine Mounting System",
            "UsageName": "Engine Mount"
        },
        "09809": {
            "GenartName": "Cable Repair Set, engine mount",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Engine Mounting System, universal",
            "UsageName": "Engine Mount"
        },
        "09810": {
            "GenartName": "Repair Kit, headlight (bracket)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Lights",
            "UsageName": "Headlight (bracket)"
        },
        "09811": {
            "GenartName": "SCR Catalytic Converter",
            "NormTermName": "SCR Catalytic Converter",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "09812": {
            "GenartName": "Repair Pipe, exhaust pipe",
            "NormTermName": "Repair Pipe",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust Pipe"
        },
        "09813": {
            "GenartName": "All-Wheel-Drive Coupling",
            "NormTermName": "All-Wheel-Drive Coupling",
            "AssGrpName": "Axle Drive",
            "UsageName": null
        },
        "09814": {
            "GenartName": "Sensor, all-wheel-drive coupling",
            "NormTermName": "Sensor",
            "AssGrpName": "Axle Drive",
            "UsageName": "All-Wheel-Drive Coupling"
        },
        "09815": {
            "GenartName": "Friction Measurement Device",
            "NormTermName": "Friction Measurement Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09816": {
            "GenartName": "Retainer, wheel suspension control/trailing arm",
            "NormTermName": "Retainer",
            "AssGrpName": "Special Tools, universal",
            "UsageName": null
        },
        "09817": {
            "GenartName": "Drive Chain, automatic transmission",
            "NormTermName": "Chain",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "09818": {
            "GenartName": "Hole Punching Set, parking assist sensor",
            "NormTermName": "Hole Punch Set",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Parking Assist Sensor"
        },
        "09819": {
            "GenartName": "Blade, hose cutter",
            "NormTermName": "Knife",
            "AssGrpName": "Tool, universal",
            "UsageName": "Hose"
        },
        "09820": {
            "GenartName": "Coil, pipe cleaning device",
            "NormTermName": "Coil",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09821": {
            "GenartName": "Cable Repair Set, level sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Level Sensor"
        },
        "09822": {
            "GenartName": "Cable Repair Set, level sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Wheel Suspension, Universal",
            "UsageName": "Level Sensor"
        },
        "09823": {
            "GenartName": "Cable Repair Set, heating flap servomotor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heating Flap Servomotor"
        },
        "09824": {
            "GenartName": "Cable Repair Set, heating flap servomotor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation, universal",
            "UsageName": "Heating Flap Servomotor"
        },
        "09825": {
            "GenartName": "Cable Repair Set, rear axle steering control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Steering",
            "UsageName": "Rear Axle Steering Control Unit"
        },
        "09826": {
            "GenartName": "Cable Repair Set, rear axle steering control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Steering, universal",
            "UsageName": "Rear Axle Steering Control Unit"
        },
        "09827": {
            "GenartName": "Cable Repair Set, steering column",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Steering",
            "UsageName": "Steering Column"
        },
        "09828": {
            "GenartName": "Cable Repair Set, steering column",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Steering, universal",
            "UsageName": "Steering Column"
        },
        "09829": {
            "GenartName": "Cable Repair Set, brake pedal travel sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Pedal Travel Sensor"
        },
        "09830": {
            "GenartName": "Cable Repair Set, brake pedal travel sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Pedal Travel Sensor"
        },
        "09831": {
            "GenartName": "Replacement Wheel, workshop trolley",
            "NormTermName": "Spare Wheel",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Workshop Trolley"
        },
        "09832": {
            "GenartName": "Cable Repair Set, aerial booster",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Aerial Booster"
        },
        "09833": {
            "GenartName": "Cable Repair Set, aerial booster",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Aerial Booster"
        },
        "09834": {
            "GenartName": "Cable Repair Set, rain sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Rain Sensor"
        },
        "09835": {
            "GenartName": "Cable Repair Set, rain sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Rain Sensor"
        },
        "09836": {
            "GenartName": "Cable Repair Set, rain/light sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Rain/Light sensor"
        },
        "09837": {
            "GenartName": "Cable Repair Set, rain/light sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Rain/Light sensor"
        },
        "09838": {
            "GenartName": "Cable Repair Set, glove compartment switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Glove Compartment Switch"
        },
        "09839": {
            "GenartName": "Cable Repair Set, glove compartment switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": "Glove Compartment Switch"
        },
        "09840": {
            "GenartName": "Cable Repair Set, seat occupation mat",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Safety Systems",
            "UsageName": "Seat Occupation Mat"
        },
        "09841": {
            "GenartName": "Cable Repair Set, seat occupation mat",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Seat Occupation Mat"
        },
        "09842": {
            "GenartName": "Cable Repair Set, foot-operated parking brake switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System",
            "UsageName": "Foot-Operated Parking Brake Switch"
        },
        "09843": {
            "GenartName": "Cable Repair Set, foot-operated parking brake switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Foot-Operated Parking Brake Switch"
        },
        "09844": {
            "GenartName": "Cable Repair Set, analogue clock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Instruments",
            "UsageName": "Analogue Clock"
        },
        "09845": {
            "GenartName": "Cable Repair Set, analogue clock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Instruments, universal",
            "UsageName": "Analogue Clock"
        },
        "09846": {
            "GenartName": "Cable Repair Set, sun visor light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Sun Visor Light"
        },
        "09847": {
            "GenartName": "Cable Repair Set, sun visor light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": "Sun Visor Light"
        },
        "09848": {
            "GenartName": "Cable Repair Set, trailer ball hitch lock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Lock, trailer ball hitch"
        },
        "09849": {
            "GenartName": "Cable Repair Set, trailer ball hitch lock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Lock, trailer ball hitch"
        },
        "09850": {
            "GenartName": "Cable Repair Set, interior light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Interior Light"
        },
        "09851": {
            "GenartName": "Cable Repair Set, interior light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Interior Light"
        },
        "09852": {
            "GenartName": "Cable Repair Set, tailgate handle",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System",
            "UsageName": "Tailgate Handle"
        },
        "09853": {
            "GenartName": "Cable Repair Set, tailgate handle",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Tailgate Handle"
        },
        "09854": {
            "GenartName": "Cable Repair Set, boot lid handle",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System",
            "UsageName": "Boot Lid Handle"
        },
        "09855": {
            "GenartName": "Cable Repair Set, boot lid handle",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Boot Lid Handle"
        },
        "09856": {
            "GenartName": "Cable Repair Set, auxiliary stop light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Signal System",
            "UsageName": "Auxiliary Stop Light"
        },
        "09857": {
            "GenartName": "Cable Repair Set, auxiliary stop light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Signal System, universal",
            "UsageName": "Auxiliary Stop Light"
        },
        "09858": {
            "GenartName": "Cable Repair Set, steering wheel remote control",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Steering Wheel Remote Control"
        },
        "09859": {
            "GenartName": "Cable Repair Set, steering wheel remote control",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Steering Wheel Remote Control"
        },
        "09860": {
            "GenartName": "Cable Repair Set, loudspeaker",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Loudspeaker"
        },
        "09861": {
            "GenartName": "Cable Repair Set, loudspeaker",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Loudspeaker"
        },
        "09862": {
            "GenartName": "Cable Repair Set, glove compartment light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Glove Compartment Light"
        },
        "09863": {
            "GenartName": "Cable Repair Set, glove compartment light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Glove Compartment Light"
        },
        "09864": {
            "GenartName": "Taximeter",
            "NormTermName": "Taximeter",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "09865": {
            "GenartName": "Taximeter",
            "NormTermName": "Taximeter",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": null
        },
        "09866": {
            "GenartName": "Mirror Taximeter",
            "NormTermName": "Mirror Taximeter",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "09867": {
            "GenartName": "Mirror Taximeter",
            "NormTermName": "Mirror Taximeter",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": null
        },
        "09868": {
            "GenartName": "Cable Repair Set, mirror taximeter",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "09869": {
            "GenartName": "Cable Repair Set, mirror taximeter",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": null
        },
        "09870": {
            "GenartName": "Cable Repair Set, interior mirror",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Body",
            "UsageName": "Interior Mirror"
        },
        "09871": {
            "GenartName": "Cable Repair Set, interior mirror",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Interior Mirror"
        },
        "09872": {
            "GenartName": "Cable Repair Set, antenna",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Aerial"
        },
        "09873": {
            "GenartName": "Cable Repair Set, antenna",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Aerial"
        },
        "09874": {
            "GenartName": "Cable Repair Set, camera",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Camera"
        },
        "09875": {
            "GenartName": "Cable Repair Set, camera",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Camera"
        },
        "09876": {
            "GenartName": "Cable Repair Set, locking cylinder, ignition lock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System",
            "UsageName": "Locking Cylinder, ignition lock"
        },
        "09877": {
            "GenartName": "Cable Repair Set, locking cylinder, ignition lock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Locking Cylinder, ignition lock"
        },
        "09878": {
            "GenartName": "Additive Pump",
            "NormTermName": "Pump",
            "AssGrpName": "Exhaust System",
            "UsageName": "Additive"
        },
        "09879": {
            "GenartName": "Exhaust Gas Sensor Set",
            "NormTermName": "Exhaust Gas Sensor Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": null
        },
        "09880": {
            "GenartName": "Cable Repair Set, locking cylinder, door lock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System",
            "UsageName": "Locking Cylinder, door lock"
        },
        "09881": {
            "GenartName": "Cable Repair Set, locking cylinder, door lock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Locking Cylinder, door lock"
        },
        "09882": {
            "GenartName": "Cable Repair Set, soft-top lock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Body",
            "UsageName": "Soft-Top Lock"
        },
        "09883": {
            "GenartName": "Cable Repair Set, soft-top lock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Soft-Top Lock"
        },
        "09884": {
            "GenartName": "Cable Repair Set, footwell lighting",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Footwell Lighting"
        },
        "09885": {
            "GenartName": "Cable Repair Set, footwell lighting",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Footwell Lighting"
        },
        "09886": {
            "GenartName": "Cable Repair Set, ashtray lighting",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Ashtray Lighting"
        },
        "09887": {
            "GenartName": "Cable Repair Set, ashtray lighting",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Ashtray Lighting"
        },
        "09888": {
            "GenartName": "Seal, camshaft adjustment",
            "NormTermName": "Seal",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Adjustment"
        },
        "09889": {
            "GenartName": "Gasket Set, crankcase ventilation",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase Ventilation"
        },
        "09890": {
            "GenartName": "Repair Set, EGR system",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "EGR System"
        },
        "09891": {
            "GenartName": "Cable Repair Set, glow plug control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Glow Ignition System",
            "UsageName": "Glow Plug Control Unit"
        },
        "09892": {
            "GenartName": "Manual",
            "NormTermName": "Manual",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "09893": {
            "GenartName": "Service Book",
            "NormTermName": "Service Book",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "09894": {
            "GenartName": "Welding Protection Curtain",
            "NormTermName": "Welding Protection Curtain",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "09895": {
            "GenartName": "Frame, welding protection curtain",
            "NormTermName": "Frame",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Welding Protection Curtain"
        },
        "09896": {
            "GenartName": "Welding Protection Cover, tailgate",
            "NormTermName": "Cover",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Tailgate"
        },
        "09897": {
            "GenartName": "Welding Protection Cover, wheel cover",
            "NormTermName": "Cover",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Wheel Cover"
        },
        "09898": {
            "GenartName": "Welding Protection Blanket, windscreen",
            "NormTermName": "Cover",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Windscreen"
        },
        "09899": {
            "GenartName": "Welding Protection Blanket, door",
            "NormTermName": "Cover",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": "Door"
        },
        "09900": {
            "GenartName": "Welding Cushion",
            "NormTermName": "Welding Cushion",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "09901": {
            "GenartName": "Welding Spatter Protection Blanket",
            "NormTermName": "Welding Spatter Protection Blanket",
            "AssGrpName": "Protective Clothing/Equipment",
            "UsageName": null
        },
        "09902": {
            "GenartName": "Cable Repair Set, windscreen heating",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Windscreen Heating"
        },
        "09903": {
            "GenartName": "Cable Repair Set, windscreen heating",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Windscreen Heating"
        },
        "09904": {
            "GenartName": "Cable Repair Set, windscreen antenna",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Windscreen Antenna"
        },
        "09905": {
            "GenartName": "Cable Repair Set, windscreen antenna",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Windscreen Antenna"
        },
        "09906": {
            "GenartName": "Cable Repair Set, pressure transducer",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": "Secondary Air"
        },
        "09907": {
            "GenartName": "Cable Repair Set, pressure transducer",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Secondary Air Injection, universal",
            "UsageName": "Secondary Air"
        },
        "09908": {
            "GenartName": "Car Key",
            "NormTermName": "Car Key",
            "AssGrpName": "Lock System",
            "UsageName": null
        },
        "09909": {
            "GenartName": "Housing, car key",
            "NormTermName": "Housing",
            "AssGrpName": "Lock System",
            "UsageName": "Car Key"
        },
        "09910": {
            "GenartName": "Pipe Cleaning Device",
            "NormTermName": "Pipe Cleaning Device",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "09911": {
            "GenartName": "Cutting/Chamfering Base Device",
            "NormTermName": "Cutting/Chamfering Base Device",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09912": {
            "GenartName": "Clamping Jaws Set, chamfering base device",
            "NormTermName": "Clamping Jaws Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Chamfering Base Device"
        },
        "09913": {
            "GenartName": "Cutting Gun",
            "NormTermName": "Cutting Gun",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "09914": {
            "GenartName": "Installation Wrench, WC seat",
            "NormTermName": "Key",
            "AssGrpName": "Tool, universal",
            "UsageName": "WC Seat"
        },
        "09915": {
            "GenartName": "Installation Tool, drain valve",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drain Valve"
        },
        "09916": {
            "GenartName": "Adapter, drain valve installation tool",
            "NormTermName": "Adaptor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drain Valve"
        },
        "09917": {
            "GenartName": "Installation Tool Set, drain valve",
            "NormTermName": "Mounting Tool Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Drain Valve"
        },
        "09918": {
            "GenartName": "Adapter, grease gun",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Grease Gun"
        },
        "09919": {
            "GenartName": "Protective Sheath, impact screwdriver",
            "NormTermName": "Protective Sleeve",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Impact Wrench"
        },
        "09920": {
            "GenartName": "Cable Repair Set, engine mount control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Engine Mounting System",
            "UsageName": "Engine Mount Control Unit"
        },
        "09921": {
            "GenartName": "Cable Repair Set, engine mount control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Engine Mounting System, universal",
            "UsageName": "Engine Mount Control Unit"
        },
        "09922": {
            "GenartName": "Cable Repair Set, keyless system control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Keyless System Control Unit"
        },
        "09923": {
            "GenartName": "Cable Repair Set, keyless system control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Keyless System Control Unit"
        },
        "09924": {
            "GenartName": "Cable Repair Set, vehicle power system control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Central Electrics",
            "UsageName": "Vehicle Power System Control Unit"
        },
        "09925": {
            "GenartName": "Cable Repair Set, vehicle power system control unit",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Electric Supply, universal",
            "UsageName": "Vehicle Power System Control Unit"
        },
        "09926": {
            "GenartName": "Screw Set, automatic transmission oil pan",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil sump, automatic transmission"
        },
        "09927": {
            "GenartName": "Cable Repair Set, seat heating",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Seat Heating"
        },
        "09928": {
            "GenartName": "Cable Repair Set, seat heating",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Seat Heating"
        },
        "09929": {
            "GenartName": "Protective Cap, battery pole",
            "NormTermName": "Cap",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Battery Terminal"
        },
        "09930": {
            "GenartName": "Cable Repair Set, tyre pressure monitoring Rx antenna",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Wheels",
            "UsageName": "Tyre Pressure Monitoring Receiving Antenna"
        },
        "09931": {
            "GenartName": "Cable Repair Set, tyre pressure monitoring Rx antenna",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Tyre Pressure Monitoring Receiving Antenna"
        },
        "60000": {
            "GenartName": "Pulley, side tarpaulin",
            "NormTermName": "Pulley",
            "AssGrpName": "Trailer",
            "UsageName": "Side Tarpaulin"
        },
        "60001": {
            "GenartName": "Repair Set, EGR valve",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "EGR Valve"
        },
        "60002": {
            "GenartName": "Repair Set, EGR module",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "EGR Module"
        },
        "60003": {
            "GenartName": "Adapter Ring Set, brake disc fastening impact test",
            "NormTermName": "Adapter Ring Set",
            "AssGrpName": "Tool, universal",
            "UsageName": "Brake Disc Fastening Impact Test"
        },
        "60004": {
            "GenartName": "Clamping Screw, carburettor pull cable",
            "NormTermName": "Screw",
            "AssGrpName": "Carburettor",
            "UsageName": "Carburettor Pull Cable"
        },
        "60005": {
            "GenartName": "Clamping Screw, carburettor pull cable",
            "NormTermName": "Screw",
            "AssGrpName": "Carburettor, universal",
            "UsageName": "Carburettor Pull Cable"
        },
        "60006": {
            "GenartName": "Piston + Connecting Rod, air compressor",
            "NormTermName": "Piston + Connecting Rod",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "60007": {
            "GenartName": "Adapter, air compressor",
            "NormTermName": "Adaptor",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "60008": {
            "GenartName": "Pressure Regulator, air compressor",
            "NormTermName": "Regulator",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "60009": {
            "GenartName": "Accessory Kit, brake shoes",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Shoes"
        },
        "60010": {
            "GenartName": "Storage Box, Fire Extinguisher",
            "NormTermName": "Storage Box",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Fire Extinguisher"
        },
        "60011": {
            "GenartName": "Cylinder Head Gasket, air compressor",
            "NormTermName": "Seal",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "60012": {
            "GenartName": "Cylinder Head Gasket Set, air compressor",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "60013": {
            "GenartName": "Bushing, retarder",
            "NormTermName": "Bush",
            "AssGrpName": "Braking System",
            "UsageName": "Retarder"
        },
        "60014": {
            "GenartName": "Bellows, shift cylinder",
            "NormTermName": "Bellow",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Shift Cylinder"
        },
        "60015": {
            "GenartName": "Sensor, driver's cab tipping device",
            "NormTermName": "Sensor",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Tilt Unit"
        },
        "60016": {
            "GenartName": "Sensor, driver cab lock",
            "NormTermName": "Sensor",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver's Cab Locking"
        },
        "60017": {
            "GenartName": "Handle, stowage box",
            "NormTermName": "Handle",
            "AssGrpName": "Driver Cab",
            "UsageName": "Stowage Box"
        },
        "60018": {
            "GenartName": "Sender Unit, fuel tank",
            "NormTermName": "Sender Unit, fuel tank",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": null
        },
        "60019": {
            "GenartName": "Shift Fork",
            "NormTermName": "Shift Fork",
            "AssGrpName": "Manual Transmission",
            "UsageName": null
        },
        "60020": {
            "GenartName": "Cable Repair Set, oil pressure switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pressure Switch"
        },
        "60021": {
            "GenartName": "Cable Repair Set, oil pressure switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lubrication, universal",
            "UsageName": "Oil Pressure Switch"
        },
        "60022": {
            "GenartName": "Cable Repair Set, auxiliary heating metering pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Auxiliary Heating Metering Pump"
        },
        "60023": {
            "GenartName": "Cable Repair Set, auxiliary heating metering pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Auxiliary Heating Metering Pump"
        },
        "60024": {
            "GenartName": "Cable Repair Set, injection pump RPM sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump RPM Sensor"
        },
        "60025": {
            "GenartName": "Cable Repair Set, injection pump RPM sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Injection Pump RPM Sensor"
        },
        "60026": {
            "GenartName": "Cable Repair Set, cold-start valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Cold-start Valve"
        },
        "60027": {
            "GenartName": "Cable Repair Set, cold-start valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Cold-start Valve"
        },
        "60028": {
            "GenartName": "Cable Repair Set, valve overrun shut-off",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Valve Overrun Shut-Off"
        },
        "60029": {
            "GenartName": "Cable Repair Set, valve overrun shut-off",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Valve Overrun Shut-Off"
        },
        "60030": {
            "GenartName": "Cable Repair Set, secondary air valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": "Secondary Air Valve"
        },
        "60031": {
            "GenartName": "Cable Repair Set, secondary air valve",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Secondary Air Injection, universal",
            "UsageName": "Secondary Air Valve"
        },
        "60032": {
            "GenartName": "Cable Repair Set, fresh air flaps' servomotor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Fresh Air Flaps' Servomotor"
        },
        "60033": {
            "GenartName": "Cable Repair Set, fresh air flaps' servomotor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation, universal",
            "UsageName": "Fresh Air Flaps' Servomotor"
        },
        "60034": {
            "GenartName": "Cable Repair Set, differential lock switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential Lock Switch"
        },
        "60035": {
            "GenartName": "Cable Repair Set, differential lock switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Axle Drive, universal",
            "UsageName": "Differential Lock Switch"
        },
        "60036": {
            "GenartName": "Cable Repair Set, evaporator temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Evaporator Temperature Sensor"
        },
        "60037": {
            "GenartName": "Cable Repair Set, evaporator temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Evaporator Temperature Sensor"
        },
        "60038": {
            "GenartName": "Cable Repair Set, air con. blower temperature switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Air Con. Blower Temperature Switch"
        },
        "60039": {
            "GenartName": "Cable Repair Set, air con. blower temperature switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Air Con. Blower Temperature Switch"
        },
        "60040": {
            "GenartName": "Torque Multiplier, crankshaft pulley bolt",
            "NormTermName": "Torque Multiplier",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Crankshaft Pulley Bolt"
        },
        "60041": {
            "GenartName": "Clamping Piece, exhaust system",
            "NormTermName": "Clamping Piece",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": null
        },
        "60042": {
            "GenartName": "Cable Pull, automatic transmission shift lock",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission Shift Lock"
        },
        "60043": {
            "GenartName": "Bellows, air spring strut",
            "NormTermName": "Bellow",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Air Spring Strut"
        },
        "60044": {
            "GenartName": "Cable Repair Set, control unit (garage door opener)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Accessories",
            "UsageName": "Control Unit (garage door opener)"
        },
        "60045": {
            "GenartName": "Cable Repair Set, control unit (garage door opener)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Control Unit (garage door opener)"
        },
        "60046": {
            "GenartName": "Cable Repair Set, control unit (tank leakage diagnostics)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Control Unit (tank leakage diagnostics)"
        },
        "60047": {
            "GenartName": "Cable Repair Set, control unit (tank leakage diagnostics)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Control Unit (tank leakage diagnostics)"
        },
        "60048": {
            "GenartName": "Cable Repair Set, control unit (all-wheel drive)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Axle Drive",
            "UsageName": "Control Unit (all-wheel drive)"
        },
        "60049": {
            "GenartName": "Cable Repair Set, control unit (all-wheel drive)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Axle Drive, universal",
            "UsageName": "Control Unit (all-wheel drive)"
        },
        "60050": {
            "GenartName": "Cable Repair Set, injection pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump"
        },
        "60051": {
            "GenartName": "Cable Repair Set, injection pump",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Injection Pump"
        },
        "60052": {
            "GenartName": "Reducer, flange connection (exhaust system)",
            "NormTermName": "Reducer",
            "AssGrpName": "Exhaust System",
            "UsageName": "Flange Connection (exhaust system)"
        },
        "60053": {
            "GenartName": "Reducer, flange connection (exhaust system)",
            "NormTermName": "Reducer",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Flange Connection (exhaust system)"
        },
        "60054": {
            "GenartName": "Cable Repair Set, automatic starting system",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Carburettor",
            "UsageName": "Automatic Choke"
        },
        "60055": {
            "GenartName": "Cable Repair Set, automatic starting system",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Carburettor, universal",
            "UsageName": "Automatic Choke"
        },
        "60056": {
            "GenartName": "Cable Repair Set, valve circulating air shut-off",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Carburettor",
            "UsageName": "Valve Circulating Air Shut-Off"
        },
        "60057": {
            "GenartName": "Cable Repair Set, valve circulating air shut-off",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Carburettor, universal",
            "UsageName": "Valve Circulating Air Shut-Off"
        },
        "60058": {
            "GenartName": "Rubber Buffer, sliding door",
            "NormTermName": "Buffer",
            "AssGrpName": "Body",
            "UsageName": "Sliding Door"
        },
        "60059": {
            "GenartName": "Lid, glove compartment",
            "NormTermName": "Lid",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Glove Compartment"
        },
        "60060": {
            "GenartName": "Relay, air compressor (air suspension)",
            "NormTermName": "Relay",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor (air suspension)"
        },
        "60061": {
            "GenartName": "Sensor, front windscreen",
            "NormTermName": "Sensor",
            "AssGrpName": "Body",
            "UsageName": "Windscreen"
        },
        "60062": {
            "GenartName": "Slide Shoe, seat adjustment",
            "NormTermName": "Sliding Shoe",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Seat Adjustment"
        },
        "60063": {
            "GenartName": "Sensor, outer door handle",
            "NormTermName": "Sensor",
            "AssGrpName": "Lock System",
            "UsageName": "Outer door handle"
        },
        "60064": {
            "GenartName": "Actuator, fuel filler flap",
            "NormTermName": "Actuator",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Fuel Filler Flap"
        },
        "60065": {
            "GenartName": "Retrofit Kit, nitrous oxide injection",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Nitrous Oxide Injection"
        },
        "60066": {
            "GenartName": "Parts kit, multi-plate clutch oil change (AWD)",
            "NormTermName": "Parts Set",
            "AssGrpName": "Service/Maintenance",
            "UsageName": "Multi-plate clutch oil change (all-wheel drive)"
        },
        "60067": {
            "GenartName": "Control Valve, multi-plate clutch (all-wheel drive)",
            "NormTermName": "Valve",
            "AssGrpName": "Axle Drive",
            "UsageName": "Multi-Plate Clutch (all-wheel drive)"
        },
        "60068": {
            "GenartName": "Cable Repair Set, kickdown switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply",
            "UsageName": "Kickdown Switch"
        },
        "60069": {
            "GenartName": "Cable Repair Set, kickdown switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply, universal",
            "UsageName": "Kickdown Switch"
        },
        "60070": {
            "GenartName": "Game Tray",
            "NormTermName": "Rubber Tub",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Game Tray"
        },
        "60071": {
            "GenartName": "Cover, game tray",
            "NormTermName": "Lid",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Game Tray"
        },
        "60072": {
            "GenartName": "Tray Tarpaulin",
            "NormTermName": "Tarpaulin",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Tray Tarpaulin"
        },
        "60073": {
            "GenartName": "Retrofit Kit, luggage basket lighting (trailer coupler)",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Luggage Basket Lighting (trailer coupler carrier)"
        },
        "60074": {
            "GenartName": "Lowering Device, luggage basket (trailer coupler carrier)",
            "NormTermName": "Lowering Device",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Luggage Basket (trailer coupler carrier)"
        },
        "60075": {
            "GenartName": "Cable Pull, filler cap release",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Body",
            "UsageName": "Tank Cap Unlock"
        },
        "60076": {
            "GenartName": "Cable Set, tailgate",
            "NormTermName": "Cable Set",
            "AssGrpName": "Lights",
            "UsageName": "Tailgate"
        },
        "60077": {
            "GenartName": "Hygiene protection film",
            "NormTermName": "Foil",
            "AssGrpName": "Accessories",
            "UsageName": "Hygiene protection film"
        },
        "60078": {
            "GenartName": "Hygiene protection film",
            "NormTermName": "Foil",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Hygiene protection film"
        },
        "60079": {
            "GenartName": "Repair Kit, mechatronics (automatic transmission)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Mechatronics (automatic transmission)"
        },
        "60080": {
            "GenartName": "Oil Collecting Container, crankcase ventilation",
            "NormTermName": "Tank",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase Ventilation Valve"
        },
        "60081": {
            "GenartName": "Rear Door Lock",
            "NormTermName": "Lock",
            "AssGrpName": "Lock System",
            "UsageName": "Hatch Door"
        },
        "60082": {
            "GenartName": "Gel Pad, optical element",
            "NormTermName": "Gel Pad",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Optical Element"
        },
        "60083": {
            "GenartName": "Cable Repair Set, ignition system",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Ignition System",
            "UsageName": "Ignition System"
        },
        "60084": {
            "GenartName": "Cable Repair Set, ignition system",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Ignition System, universal",
            "UsageName": "Ignition System"
        },
        "60085": {
            "GenartName": "Cable Repair Set, tailgate lock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System",
            "UsageName": "Tailgate Lock"
        },
        "60086": {
            "GenartName": "Cable Repair Set, tailgate lock",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Tailgate Lock"
        },
        "60087": {
            "GenartName": "Clamping Piece, cylinder head cover",
            "NormTermName": "Clamping Piece",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Cylinder Head Cover"
        },
        "60088": {
            "GenartName": "Locking Pin, door lock",
            "NormTermName": "Locking Pin",
            "AssGrpName": "Lock System",
            "UsageName": "Door Lock"
        },
        "60089": {
            "GenartName": "Timing Belt, power take-off",
            "NormTermName": "Belt",
            "AssGrpName": "Belt Drive",
            "UsageName": "Power Take-Off"
        },
        "60090": {
            "GenartName": "Bulb, side marker light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Side Marker Light"
        },
        "60091": {
            "GenartName": "Magnetic Closure, glove compartment lid",
            "NormTermName": "Closure",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Glove Compartment Lid"
        },
        "60092": {
            "GenartName": "Cable Repair Set, reverse light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Reverse Light"
        },
        "60093": {
            "GenartName": "Cable Repair Set, reverse light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Reverse Light"
        },
        "60094": {
            "GenartName": "Assembly Paste, diesel injector / glow plug",
            "NormTermName": "Mounting Aid",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Diesel Injector / Glow Plug"
        },
        "60095": {
            "GenartName": "Control Arm Coupling Rod, level control",
            "NormTermName": "Strut",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leveling Control"
        },
        "60096": {
            "GenartName": "Seal, camshaft sealing cover",
            "NormTermName": "Seal",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Sealing Cover"
        },
        "60097": {
            "GenartName": "Cable Repair Set, blind spot sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Safety Systems",
            "UsageName": "Blind Spot Sensor"
        },
        "60098": {
            "GenartName": "Cable Repair Set, blind spot sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Blind Spot Sensor"
        },
        "60099": {
            "GenartName": "Cable Repair Kit, actuator (throttle valve)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Acuator (throttle valve)"
        },
        "60100": {
            "GenartName": "Cable Repair Kit, actuator (throttle valve)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Acuator (throttle valve)"
        },
        "60101": {
            "GenartName": "Cable Repair Set, control unit (battery management)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Central Electrics",
            "UsageName": "Control Unit (battery management)"
        },
        "60102": {
            "GenartName": "Cable Repair Set, control unit (battery management)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Electric Supply, universal",
            "UsageName": "Control Unit (battery management)"
        },
        "60103": {
            "GenartName": "Cable Repair Set, fuel filter heating",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Filter Heating"
        },
        "60104": {
            "GenartName": "Cable Repair Set, fuel filter heating",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Fuel Filter Heating"
        },
        "60105": {
            "GenartName": "Cable Repair Set, control valve (fuel quantity)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Control Valve (fuel quantity)"
        },
        "60106": {
            "GenartName": "Cable Repair Set, control valve (fuel quantity)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Control Valve (fuel quantity)"
        },
        "60107": {
            "GenartName": "Cable Repair Set, charge-air temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Charge-Air Temperature Sensor"
        },
        "60108": {
            "GenartName": "Cable Repair Set, charge-air temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Charge-Air Temperature Sensor"
        },
        "60109": {
            "GenartName": "Cable Repair Set, water sensor (fuel system)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Water Sensor (fuel system)"
        },
        "60110": {
            "GenartName": "Cable Repair Set, water sensor (fuel system)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Water Sensor (fuel system)"
        },
        "60111": {
            "GenartName": "Cable Repair Set, clutch pedal travel sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch Pedal Travel Sensor"
        },
        "60112": {
            "GenartName": "Cable Repair Set, clutch pedal travel sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Clutch, universal",
            "UsageName": "Clutch Pedal Travel Sensor"
        },
        "60113": {
            "GenartName": "Engine Oil Additive",
            "NormTermName": "Additive",
            "AssGrpName": "Lubrication",
            "UsageName": "Engine Oil"
        },
        "60114": {
            "GenartName": "Installation Tool, charge-air hose retaining clip",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Charge-Air Hose Retaining Clip"
        },
        "60115": {
            "GenartName": "Installation Tool, charge-air hose retaining clip",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Charge-Air Hose Retaining Clip"
        },
        "60116": {
            "GenartName": "Cable Repair Set, actuator (charge pressure regulator)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Actuator (charge pressure regulator)"
        },
        "60117": {
            "GenartName": "Cable Repair Set, actuator (charge pressure regulator)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Actuator (charge pressure regulator)"
        },
        "60118": {
            "GenartName": "Cable Repair Set, chang. valve (bypass valve / EGR cooler)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "Changeover Valve (bypass valve / EGR cooler)"
        },
        "60119": {
            "GenartName": "Cable Repair Set, chang. valve (bypass valve / EGR cooler)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust Gas Recirculation, Universal",
            "UsageName": "Changeover Valve (bypass valve / EGR cooler)"
        },
        "60120": {
            "GenartName": "Gas Spring, fuel filler cap",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Fuel Filler Cap"
        },
        "60121": {
            "GenartName": "Camping Awning",
            "NormTermName": "Awning",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Camping Awning"
        },
        "60122": {
            "GenartName": "Camping Stove",
            "NormTermName": "Stove",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Camping Stove"
        },
        "60123": {
            "GenartName": "Guide Rail, sliding door",
            "NormTermName": "Guide Rail",
            "AssGrpName": "Body",
            "UsageName": "Sliding Door"
        },
        "60124": {
            "GenartName": "Repair Kit, guide rail (sliding door)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Body",
            "UsageName": "Guide Rail (sliding door)"
        },
        "60125": {
            "GenartName": "Seal Ring, detonation sensor",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Knock Sensor"
        },
        "60126": {
            "GenartName": "Sanitary Pipe-Cleaning Set",
            "NormTermName": "Sanitary Pipe-Cleaning Set",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "60127": {
            "GenartName": "Sanitary Pipe Pig, pipe-cleaning device",
            "NormTermName": "Sanitary Pipe Pig, pipe-cleaning device",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "60128": {
            "GenartName": "Extension, chimney-sweeping tool",
            "NormTermName": "Extension",
            "AssGrpName": "Tool, universal",
            "UsageName": "Chimney-Sweeping Tool"
        },
        "60129": {
            "GenartName": "Hand Crank, chimney-sweeping tool",
            "NormTermName": "Crank",
            "AssGrpName": "Tool, universal",
            "UsageName": "Chimney-Sweeping Tool"
        },
        "60130": {
            "GenartName": "Sanitary Die Set, thread-cutting nut",
            "NormTermName": "Sanitary Die Set, thread-cutting nut",
            "AssGrpName": "Tool, universal",
            "UsageName": "Thread-cutting Nut"
        },
        "60132": {
            "GenartName": "Distributor unit, air conditioning system",
            "NormTermName": "Distributor unit",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Distributor unit"
        },
        "60133": {
            "GenartName": "Auxiliary Water Pump, air conditioning system",
            "NormTermName": "Pump",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Auxiliary Pump"
        },
        "60134": {
            "GenartName": "Gas pump, EVAP active carbon tank (tank ventilation)",
            "NormTermName": "Gas pump",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "EVAP charcoal canister (tank ventilation)"
        },
        "60135": {
            "GenartName": "Valve, piston crown cooling",
            "NormTermName": "Valve",
            "AssGrpName": "Lubrication",
            "UsageName": "Piston Underside Cooling"
        },
        "60136": {
            "GenartName": "Adhesive, rain sensor",
            "NormTermName": "Adhesive",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Rain Sensor"
        },
        "60137": {
            "GenartName": "Oil bottle, reserve sample transmission oil",
            "NormTermName": "Tank",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Reserve sample transmission oil"
        },
        "60138": {
            "GenartName": "Oil bottle set, reserve sample transmission oil",
            "NormTermName": "Tank set",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Reserve sample transmission oil"
        },
        "60139": {
            "GenartName": "Repair set, multi-plate clutch pump (all-wheel drive)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Axle Drive",
            "UsageName": "Multi-plate clutch pump (all-wheel drive)"
        },
        "60140": {
            "GenartName": "Seal Ring, injection valve (urea injection)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Exhaust System",
            "UsageName": "Injection valve (urea injection)"
        },
        "60141": {
            "GenartName": "Mounting Kit, bumper",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Body Parts, universal",
            "UsageName": "Bumper"
        },
        "60142": {
            "GenartName": "Cover, tool trolley",
            "NormTermName": "Cover",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "60143": {
            "GenartName": "Charging station, electric vehicle",
            "NormTermName": "Charging station",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Electric Vehicle"
        },
        "60144": {
            "GenartName": "Charging cable holder, electric vehicle",
            "NormTermName": "Bracket",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Electric Vehicle"
        },
        "60145": {
            "GenartName": "Column, charging station (electric vehicle)",
            "NormTermName": "Column",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Charging station (electric vehicle)"
        },
        "60146": {
            "GenartName": "Power meter, charging station (electric vehicle)",
            "NormTermName": "Power meter",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Charging station (electric vehicle)"
        },
        "60147": {
            "GenartName": "Storage Bag, charging cable (electric vehicle)",
            "NormTermName": "Bag",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Charging Cable (electric vehicle)"
        },
        "60148": {
            "GenartName": "Cover frame, charging station (electric vehicle)",
            "NormTermName": "Frame",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Charging station (electric vehicle)"
        },
        "60149": {
            "GenartName": "Registration card, charging station (electric vehicle)",
            "NormTermName": "Registration card",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Charging station (electric vehicle)"
        },
        "60150": {
            "GenartName": "Body, tool trolley",
            "NormTermName": "Body",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "60151": {
            "GenartName": "Supercharger, charging",
            "NormTermName": "Compressor",
            "AssGrpName": "Air Supply",
            "UsageName": "Charging (supercharged/turbocharged)"
        },
        "60152": {
            "GenartName": "Adapter cable, loudspeaker",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Loudspeaker"
        },
        "60153": {
            "GenartName": "Remote control, car radio",
            "NormTermName": "Remote Control",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Car Radio"
        },
        "60154": {
            "GenartName": "Tyre-Pressure Monitoring System",
            "NormTermName": "Tyre-Pressure Monitoring System",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Tyre-Pressure Monitoring System"
        },
        "60155": {
            "GenartName": "Screw plug, cylinder head",
            "NormTermName": "Screw",
            "AssGrpName": "Cylinder Head",
            "UsageName": "Sealing Cap"
        },
        "60156": {
            "GenartName": "Cable Set, hands-free kit (radio)",
            "NormTermName": "Cable Set",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Hands-free kit for radio"
        },
        "60157": {
            "GenartName": "WiFi modem, mobile communication",
            "NormTermName": "WiFi modem",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "WiFi modem"
        },
        "60158": {
            "GenartName": "Gauge, air filter saturation",
            "NormTermName": "Gauge",
            "AssGrpName": "Air Supply",
            "UsageName": "Air filter saturation"
        },
        "60159": {
            "GenartName": "Oil pipe, manual transmission",
            "NormTermName": "Pipe",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "60160": {
            "GenartName": "Output flange, automatic transmission",
            "NormTermName": "Flange",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Gearbox Flange"
        },
        "60161": {
            "GenartName": "Output flange, manual transmission",
            "NormTermName": "Flange",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gearbox Flange"
        },
        "60162": {
            "GenartName": "Display, fuel filter saturation",
            "NormTermName": "Gauge",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel filter saturation"
        },
        "60163": {
            "GenartName": "Water Sensor, fuel system",
            "NormTermName": "Sensor",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Fuel System Water Sensor"
        },
        "60164": {
            "GenartName": "Adapter cable, sensor (battery management)",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Central Electrics",
            "UsageName": "Sensor (battery management)"
        },
        "60165": {
            "GenartName": "Adapter cable, sensor (battery management)",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Electric Supply, universal",
            "UsageName": "Sensor (battery management)"
        },
        "60166": {
            "GenartName": "Water pump, traction battery",
            "NormTermName": "Pump",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Traction Battery"
        },
        "60167": {
            "GenartName": "Screw set, oil sump",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Sump"
        },
        "60168": {
            "GenartName": "Screw Kit, timing case",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Case"
        },
        "60169": {
            "GenartName": "Sealing set, mechatronics (automatic transmission)",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Mechatronics (automatic transmission)"
        },
        "60170": {
            "GenartName": "Inverter",
            "NormTermName": "Inverter",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": null
        },
        "60171": {
            "GenartName": "DC converter",
            "NormTermName": "DC converter",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": null
        },
        "60172": {
            "GenartName": "Charger, traction battery",
            "NormTermName": "Charger",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Traction Battery"
        },
        "60173": {
            "GenartName": "Control Unit, battery management",
            "NormTermName": "Control Unit",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Battery Management"
        },
        "60174": {
            "GenartName": "Wear plate, door lock",
            "NormTermName": "Wear plate",
            "AssGrpName": "Lock System",
            "UsageName": "Door Lock"
        },
        "60175": {
            "GenartName": "Seal, charger (traction battery)",
            "NormTermName": "Seal",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Charger (traction battery)"
        },
        "60176": {
            "GenartName": "Spring Travel Limiter, suspension spring",
            "NormTermName": "Spring Travel Limiter",
            "AssGrpName": "Suspension, universal",
            "UsageName": "Suspension Spring"
        },
        "60177": {
            "GenartName": "Adapter, loudspeaker",
            "NormTermName": "Adaptor",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Loudspeaker"
        },
        "60178": {
            "GenartName": "Protective grille, loudspeaker",
            "NormTermName": "Protective grille",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Loudspeaker"
        },
        "60179": {
            "GenartName": "Reader, SD-USB-BT (FM-Universal)",
            "NormTermName": "Reader",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Reader"
        },
        "60180": {
            "GenartName": "Interface, AUX-IN-USB (auto radio)",
            "NormTermName": "Interface",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "AUX-IN-USB (auto radio)"
        },
        "60181": {
            "GenartName": "Crossover network, loudspeaker",
            "NormTermName": "Crossover network",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Loudspeaker"
        },
        "60182": {
            "GenartName": "Casing, low frequency (loudspeaker)",
            "NormTermName": "Housing",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Low frequency (loudspeaker)"
        },
        "60183": {
            "GenartName": "Oil Filter, transmission (E-axle)",
            "NormTermName": "Filter",
            "AssGrpName": "Electric Drive",
            "UsageName": "Transmission (E-axle)"
        },
        "60184": {
            "GenartName": "Repair Kit, soot/particulate filter",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Soot/Particulate Filter"
        },
        "60185": {
            "GenartName": "Repair Kit, catalytic converter",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Catalytic Converter"
        },
        "60186": {
            "GenartName": "Repair Kit, front muffler",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Front Muffler"
        },
        "60187": {
            "GenartName": "Repair Kit, centre muffler",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Centre Muffler"
        },
        "60188": {
            "GenartName": "Seal Ring, oil jet (timing chain)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Engine Timing",
            "UsageName": "Oil jet (timing chain)"
        },
        "60189": {
            "GenartName": "Adapter cable, thermostat",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Cooling System",
            "UsageName": "Thermostat"
        },
        "60190": {
            "GenartName": "Adapter cable, thermostat",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Thermostat"
        },
        "60191": {
            "GenartName": "Wheel rim repair kit",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Chemical Products",
            "UsageName": "Rims"
        },
        "60192": {
            "GenartName": "Warning device, child seat",
            "NormTermName": "Detector",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Child seat"
        },
        "60193": {
            "GenartName": "Dust discharge valve, air filter",
            "NormTermName": "Valve",
            "AssGrpName": "Air Supply",
            "UsageName": "Dust discharge (air filter)"
        },
        "60194": {
            "GenartName": "Seal, oil cooler (automatic transmission)",
            "NormTermName": "Seal",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil cooler (automatic transmission)"
        },
        "60195": {
            "GenartName": "Filter Head, fuel filter",
            "NormTermName": "Filter head",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Filter"
        },
        "60196": {
            "GenartName": "Torque Support",
            "NormTermName": "Torque Support",
            "AssGrpName": "Engine Mounting System",
            "UsageName": null
        },
        "60197": {
            "GenartName": "Reverse camera kit",
            "NormTermName": "Reverse camera kit",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": null
        },
        "60198": {
            "GenartName": "Solenoid valve, automatic transmission",
            "NormTermName": "Valve",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "60199": {
            "GenartName": "Solenoid valve kit, automatic transmission",
            "NormTermName": "Valve Set",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "60200": {
            "GenartName": "Seal, soot/particulate filter",
            "NormTermName": "Seal",
            "AssGrpName": "Exhaust System",
            "UsageName": "Soot/Particulate Filter"
        },
        "60201": {
            "GenartName": "Mounting kit, injection valve",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector"
        },
        "60202": {
            "GenartName": "Exhaust Spark Arrestor",
            "NormTermName": "Exhaust Spark Arrestor",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "60203": {
            "GenartName": "Electric kit, powersupply extension",
            "NormTermName": "Expansion Set",
            "AssGrpName": "Trailer Hitch",
            "UsageName": "Towing device electrical kit"
        },
        "60204": {
            "GenartName": "Multimedia Interface",
            "NormTermName": "Interface",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Multimedia"
        },
        "60205": {
            "GenartName": "Connecting Cable, multimedia interface",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Multimedia Interface"
        },
        "60206": {
            "GenartName": "Retrofit Kit, bulb (headlight)",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Lights, universal",
            "UsageName": "Main-Beam Headlamp Bulb"
        },
        "60207": {
            "GenartName": "Retrofit Set, bulb (front fog light)",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Lights, universal",
            "UsageName": "Fog Light Bulb"
        },
        "60208": {
            "GenartName": "Control Board, jump starter",
            "NormTermName": "Board",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Jump Starter"
        },
        "60209": {
            "GenartName": "Polarity reversing relay, motor rotation direction",
            "NormTermName": "Relay",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Motor rotation direction"
        },
        "60210": {
            "GenartName": "DAB+ adapter, radio",
            "NormTermName": "DAB+ adapter",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Radio"
        },
        "60211": {
            "GenartName": "Spare cable, battery tester",
            "NormTermName": "Spare cable",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Battery tester"
        },
        "60212": {
            "GenartName": "Mounting/dismantling tool set, dashboard",
            "NormTermName": "Installation/Removal Tool Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Dashboard"
        },
        "60213": {
            "GenartName": "Trim, radio",
            "NormTermName": "Cover",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Radio"
        },
        "60214": {
            "GenartName": "Stowage box",
            "NormTermName": "Stowage box",
            "AssGrpName": "Accessories",
            "UsageName": null
        },
        "60215": {
            "GenartName": "Safe",
            "NormTermName": "Safe",
            "AssGrpName": "Accessories",
            "UsageName": null
        },
        "60216": {
            "GenartName": "Stowage box",
            "NormTermName": "Stowage box",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "60217": {
            "GenartName": "Safe",
            "NormTermName": "Safe",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "60218": {
            "GenartName": "Housing, automated manual transmission (AMT)",
            "NormTermName": "Lid",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60219": {
            "GenartName": "Shaft Seal, transmission output shaft (AMT)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission output shaft (AMT)"
        },
        "60220": {
            "GenartName": "Shaft Seal, transmission input shaft (AMT)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission input shaft (AMT)"
        },
        "60221": {
            "GenartName": "Shaft Seal, gear flange (AMT)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Gear flange (AMT)"
        },
        "60222": {
            "GenartName": "Shaft Seal, main shaft (AMT)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Main shaft (AMT)"
        },
        "60223": {
            "GenartName": "Shaft Seal, switching shaft (AMT)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Switching shaft (AMT)"
        },
        "60224": {
            "GenartName": "Shaft Seal, countershaft (AMT)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Countershaft (AMT)"
        },
        "60225": {
            "GenartName": "Seal, housing of automated manual transmission (AMT)",
            "NormTermName": "Seal",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Housing of automated manual transmission (AMT)"
        },
        "60226": {
            "GenartName": "Sleeve nut, power steering",
            "NormTermName": "Union Bolt",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering"
        },
        "60227": {
            "GenartName": "Seal, transmission output shaft (AMT)",
            "NormTermName": "Seal",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission output shaft (AMT)"
        },
        "60228": {
            "GenartName": "Seal, transmission input shaft (AMT)",
            "NormTermName": "Seal",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission input shaft (AMT)"
        },
        "60229": {
            "GenartName": "Seal, transmission control unit (AMT)",
            "NormTermName": "Seal",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission control unit (AMT)"
        },
        "60230": {
            "GenartName": "Seal Ring, oil filter (AMT)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Oil Filter (AMT)"
        },
        "60231": {
            "GenartName": "Seal Ring, oil strainer (AMT)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Oil filter (AMT)"
        },
        "60232": {
            "GenartName": "Seal Ring, oil pump (AMT)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Oil pump (AMT)"
        },
        "60233": {
            "GenartName": "Seal Ring, oil dipstick guide (AMT)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Oil dipstick guide (AMT)"
        },
        "60234": {
            "GenartName": "Seal ring, oil pump (AMT)",
            "NormTermName": "Seal",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Oil pump (AMT)"
        },
        "60235": {
            "GenartName": "Seal, oil sump (AMT)",
            "NormTermName": "Seal",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Oil Sump (AMT)"
        },
        "60236": {
            "GenartName": "Seal Ring Set, solenoid valve (AMT)",
            "NormTermName": "Seal Ring Set",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Solenoid valve (AMT)"
        },
        "60237": {
            "GenartName": "Gasket kit, automated manual transmission (AMT)",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60238": {
            "GenartName": "Seal kit, transmission output shaft (AMT)",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission output shaft (AMT)"
        },
        "60239": {
            "GenartName": "Seal kit, transmission input shaft (AMT)",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission input shaft (AMT)"
        },
        "60240": {
            "GenartName": "Seal kit, transmission control unit (AMT)",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission control unit (AMT)"
        },
        "60241": {
            "GenartName": "Spacer ring, transmission shaft (AMT)",
            "NormTermName": "Spacer Ring",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission shaft (AMT)"
        },
        "60242": {
            "GenartName": "Oil filter, automated manual transmission (AMT)",
            "NormTermName": "Filter",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60243": {
            "GenartName": "Drive flange, axle shaft (AMT)",
            "NormTermName": "Flange",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Axle shaft (AMT)"
        },
        "60244": {
            "GenartName": "Drive flange, auxiliary drive (AMT)",
            "NormTermName": "Flange",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Power Take-Off (AMT)"
        },
        "60245": {
            "GenartName": "RPM sensor, water pump",
            "NormTermName": "Sensor",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "60246": {
            "GenartName": "Housing,automated manual transmission (AMT)",
            "NormTermName": "Housing",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60247": {
            "GenartName": "Cap, wheel bolt",
            "NormTermName": "Cap",
            "AssGrpName": "Wheels",
            "UsageName": "Wheel bolt"
        },
        "60248": {
            "GenartName": "Housing, clutch (AMT)",
            "NormTermName": "Housing",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Clutch (AMT)"
        },
        "60249": {
            "GenartName": "Housing, oil filter (AMT)",
            "NormTermName": "Housing",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Oil Filter (AMT)"
        },
        "60250": {
            "GenartName": "Cap, wheel bolt",
            "NormTermName": "Cap",
            "AssGrpName": "Wheels, universal",
            "UsageName": "Wheel bolt"
        },
        "60251": {
            "GenartName": "Automated manual transmission (AMT)",
            "NormTermName": "Transmission",
            "AssGrpName": "Automated manual transmission",
            "UsageName": null
        },
        "60252": {
            "GenartName": "Holder, transmission mounting (AMT)",
            "NormTermName": "Holder",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission mounting (AMT)"
        },
        "60253": {
            "GenartName": "Sensor, adaptive cruise control",
            "NormTermName": "Sensor",
            "AssGrpName": "Safety Systems",
            "UsageName": "Adaptive cruise control"
        },
        "60254": {
            "GenartName": "Ring gear, automated manual transmission (AMT)",
            "NormTermName": "Ring Gear",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60255": {
            "GenartName": "Housing lid, hydraulic filter (automatic transmission)",
            "NormTermName": "Lid",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Hydraulic Filter"
        },
        "60256": {
            "GenartName": "Seal Ring, hydraulic filter housing cover (auto. trans.)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Hydraulic Filter"
        },
        "60257": {
            "GenartName": "Cable Repair Set, RPM sensor (AMT)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "RPM sensor (AMT)"
        },
        "60258": {
            "GenartName": "Cable repair set, gear switch gear detection (AMT)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Gear switch gear detection (AMT)"
        },
        "60259": {
            "GenartName": "Impulse generator wheel, camshaft adjustment",
            "NormTermName": "Pulse Generator Wheel",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Adjustment"
        },
        "60260": {
            "GenartName": "Cable repair kit, transmission control unit (AMT)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission control unit (AMT)"
        },
        "60261": {
            "GenartName": "Bearing, countershaft (AMT)",
            "NormTermName": "Bearing",
            "AssGrpName": "Automatic Transmission, universal",
            "UsageName": "Countershaft (AMT)"
        },
        "60262": {
            "GenartName": "Bearing, transmission input shaft (AMT)",
            "NormTermName": "Bearing",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission input shaft (AMT)"
        },
        "60263": {
            "GenartName": "Bearing, transmission output shaft (AMT)",
            "NormTermName": "Bearing",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission output shaft (AMT)"
        },
        "60264": {
            "GenartName": "Bearing kit, automated manual transmission (AMT)",
            "NormTermName": "Bearing Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60265": {
            "GenartName": "Storage, automated manual transmission (AMT)",
            "NormTermName": "Mounting",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60266": {
            "GenartName": "Ring, transmission output shaft (AMT)",
            "NormTermName": "Ring",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission output shaft (AMT)"
        },
        "60267": {
            "GenartName": "Collar, shift cylinder (AMT)",
            "NormTermName": "Bellow",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Shift cylinder (AMT)"
        },
        "60268": {
            "GenartName": "Nut, transmission shaft (AMT)",
            "NormTermName": "Nut",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission shaft (AMT)"
        },
        "60269": {
            "GenartName": "Oil, automated manual transmission (AMT)",
            "NormTermName": "Oil",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60270": {
            "GenartName": "Oil filter set, automated manual transmission (AMT)",
            "NormTermName": "Filter Set",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60271": {
            "GenartName": "Oil cooler, automated manual transmission (AMT)",
            "NormTermName": "Heat Exchanger",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60272": {
            "GenartName": "Oil cooler line, automated manual transmission (AMT)",
            "NormTermName": "Oil Cooler Line",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60273": {
            "GenartName": "Oil Sump, automated manual transmission (AMT)",
            "NormTermName": "Oil Sump",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60274": {
            "GenartName": "Oil dipstick, automated manual transmission (AMT)",
            "NormTermName": "Dipstick",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60275": {
            "GenartName": "Planetary gear set, automated manual transmission (AMT)",
            "NormTermName": "Planetary Gear Set",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60276": {
            "GenartName": "Oil sump, automated manual transmission (AMT)",
            "NormTermName": "Pump",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60277": {
            "GenartName": "Repair kit, transmission control unit (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission control unit (AMT)"
        },
        "60278": {
            "GenartName": "Repair kit, shift cylinder (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Shift cylinder (AMT)"
        },
        "60279": {
            "GenartName": "Repair kit, anti-rotation actuator (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Anti-rotation actuator (AMT)"
        },
        "60280": {
            "GenartName": "Repair kit, automated manual transmission (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60281": {
            "GenartName": "Repair kit, transmission output shaft (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission output shaft (AMT)"
        },
        "60282": {
            "GenartName": "Repair kit, transmission input shaft (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission input shaft (AMT)"
        },
        "60283": {
            "GenartName": "Repair kit, main shaft (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Main shaft (AMT)"
        },
        "60284": {
            "GenartName": "Repair kit, reverse gear (rev. idler gear-AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Reverse gear (rev. idler gear-AMT)"
        },
        "60285": {
            "GenartName": "Repair kit, countershaft (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Countershaft (AMT)"
        },
        "60286": {
            "GenartName": "Oil pipe, automated manual transmission (AMT)",
            "NormTermName": "Pipe",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60287": {
            "GenartName": "Oil Pressure Switch, automated manual transmission (AMT)",
            "NormTermName": "Switch",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60288": {
            "GenartName": "Switch, driving gear (AMT)",
            "NormTermName": "Switch",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Drive position (AMT)"
        },
        "60289": {
            "GenartName": "Idler switch, automated manual transmission (AMT)",
            "NormTermName": "Switch",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60290": {
            "GenartName": "Switch, reverse gear (AMT)",
            "NormTermName": "Switch",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Reverse gear (AMT)"
        },
        "60291": {
            "GenartName": "Switch, split gear (AMT)",
            "NormTermName": "Switch",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Split gear (AMT)"
        },
        "60292": {
            "GenartName": "Screw, oil sump (AMT)",
            "NormTermName": "Screw",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Oil Sump (AMT)"
        },
        "60293": {
            "GenartName": "Installation tool, oil sump gasket",
            "NormTermName": "Mounting Tools",
            "AssGrpName": "Tool, universal",
            "UsageName": "Oil sump gasket"
        },
        "60294": {
            "GenartName": "Screw plug, transmission housing (AMT)",
            "NormTermName": "Screw",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission housing (AMT)"
        },
        "60295": {
            "GenartName": "Speed sensor, automated manual transmission (AMT)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60296": {
            "GenartName": "Oil pressure sensor, automated manual transmission (AMT)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60297": {
            "GenartName": "Oil temperature sensor, automated manual transmission (AMT)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60298": {
            "GenartName": "Sensor, switch module (AMT)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Switch module (AMT)"
        },
        "60299": {
            "GenartName": "Selector lever module, transmission shift (AMT)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Gear switch (AMT)"
        },
        "60300": {
            "GenartName": "Control unit, automated manual transmission (AMT)",
            "NormTermName": "Control Unit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60301": {
            "GenartName": "Synchronizer cone, speed change gear (AMT)",
            "NormTermName": "Synchronizer Cone",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Speed change gear (AMT)"
        },
        "60302": {
            "GenartName": "Synchronizer body, automated manual transmission (AMT)",
            "NormTermName": "Synchronizer Body",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Manual transmission (AMT)"
        },
        "60303": {
            "GenartName": "Synchronizer ring, automated manual transmission (AMT)",
            "NormTermName": "Synchronizer Ring",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60304": {
            "GenartName": "Solenoid valve, shift cylinder (AMT)",
            "NormTermName": "Valve",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Shift cylinder (AMT)"
        },
        "60305": {
            "GenartName": "Valve, transmission oil cooler (AMT)",
            "NormTermName": "Valve",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission oil cooler (AMT)"
        },
        "60306": {
            "GenartName": "Valve block, automated manual transmission (AMT)",
            "NormTermName": "Valve Block",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60307": {
            "GenartName": "Drive shaft, oil pump (AMT)",
            "NormTermName": "Shaft",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Oil pump (AMT)"
        },
        "60308": {
            "GenartName": "Transmission output shaft, autom. manual transmission (AMT)",
            "NormTermName": "Shaft",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60309": {
            "GenartName": "Transmission input shaft, autom. manual transmission (AMT)",
            "NormTermName": "Shaft",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60310": {
            "GenartName": "Main shaft, automated manual transmission (AMT)",
            "NormTermName": "Shaft",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60311": {
            "GenartName": "Switching shaft, automated manual transmission (AMT)",
            "NormTermName": "Shaft",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60312": {
            "GenartName": "Deflection shaft, gearshift mechanism (AMT)",
            "NormTermName": "Shaft",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Gearshift mechanism (AMT)"
        },
        "60313": {
            "GenartName": "Countershaft, automated manual transmission (AMT)",
            "NormTermName": "Shaft",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60314": {
            "GenartName": "Tooth wheel, transmission output shaft (AMT)",
            "NormTermName": "Gear",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission output shaft (AMT)"
        },
        "60315": {
            "GenartName": "Tooth wheel, transmission input shaft (AMT)",
            "NormTermName": "Gear",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Transmission input shaft (AMT)"
        },
        "60316": {
            "GenartName": "Tooth wheel, countershaft (AMT)",
            "NormTermName": "Gear",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Countershaft (AMT)"
        },
        "60317": {
            "GenartName": "Tooth wheel, main shaft (AMT)",
            "NormTermName": "Gear",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Main shaft (AMT)"
        },
        "60318": {
            "GenartName": "Tooth wheel, reverse gear (rev. idler gear-AMT)",
            "NormTermName": "Gear",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Reverse gear (rev. idler gear-AMT)"
        },
        "60319": {
            "GenartName": "Switching cylinder, automated manual transmission (AMT)",
            "NormTermName": "Cylinder",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60320": {
            "GenartName": "locking cylinder, automated manual transmission (AMT)",
            "NormTermName": "Cylinder",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60321": {
            "GenartName": "Vacuum line, intake manifold",
            "NormTermName": "Hose Line",
            "AssGrpName": "Air Supply",
            "UsageName": "Intake Manifold"
        },
        "60322": {
            "GenartName": "Gasket, aerial foot",
            "NormTermName": "Seal",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Aerial foot"
        },
        "60323": {
            "GenartName": "Lid, manual transmission",
            "NormTermName": "Lid",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "60324": {
            "GenartName": "Lid, automatic transmission",
            "NormTermName": "Lid",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "60325": {
            "GenartName": "Steering Wheel Remote Control, navigation system / car radio",
            "NormTermName": "Remote Control",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": "Steering Wheel Remote Control- navigation system/radio"
        },
        "60326": {
            "GenartName": "Nozzle chain, window cleaning",
            "NormTermName": "Nozzle chain",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washing Water"
        },
        "60327": {
            "GenartName": "Resonator, charge air cooling",
            "NormTermName": "Resonator",
            "AssGrpName": "Air Supply",
            "UsageName": "Charge Air Cooling"
        },
        "60328": {
            "GenartName": "Interior Cleaner/-Disinfecter",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Interior"
        },
        "60329": {
            "GenartName": "Sensor, crankcase pressure",
            "NormTermName": "Sensor",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase pressure"
        },
        "60330": {
            "GenartName": "coil, air spring struts valve",
            "NormTermName": "Coil",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "air spring struts valve"
        },
        "60331": {
            "GenartName": "Filling nozzle, washer fluid reservoir",
            "NormTermName": "Fitting",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washer Fluid Reservoir"
        },
        "60332": {
            "GenartName": "Holder, timing chain slide rail",
            "NormTermName": "Holder",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing chain slide rail"
        },
        "60333": {
            "GenartName": "Tarpaulin rope",
            "NormTermName": "Tarpaulin rope",
            "AssGrpName": "Load Securing",
            "UsageName": null
        },
        "60334": {
            "GenartName": "Sensor, keyless system",
            "NormTermName": "Sensor",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Keyless system"
        },
        "60335": {
            "GenartName": "Sensor, collision warning",
            "NormTermName": "Sensor",
            "AssGrpName": "Safety Systems",
            "UsageName": "collision warning"
        },
        "60336": {
            "GenartName": "Brake Hose, compressed-air system",
            "NormTermName": "Hose Line",
            "AssGrpName": "Compressed Air System, universal",
            "UsageName": "Compressed Air System"
        },
        "60337": {
            "GenartName": "Test Connection",
            "NormTermName": "Test Connection",
            "AssGrpName": "Compressed Air System, universal",
            "UsageName": null
        },
        "60338": {
            "GenartName": "Filter Kit, crankcase ventilation",
            "NormTermName": "Filter Set",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Crankcase Ventilation"
        },
        "60339": {
            "GenartName": "Display, hydraulic fluid filter saturation",
            "NormTermName": "Gauge",
            "AssGrpName": "Hydraulic System",
            "UsageName": "Hydraulic fluid filter saturation"
        },
        "60340": {
            "GenartName": "Bump stop, secondary air pump",
            "NormTermName": "Buffer",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": "Secondary Air Pump"
        },
        "60341": {
            "GenartName": "Slip ring, horn",
            "NormTermName": "Slip Ring",
            "AssGrpName": "Signal System",
            "UsageName": "Signal Horn"
        },
        "60342": {
            "GenartName": "Valve, fuel-pressure regulator",
            "NormTermName": "Valve",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel-pressure regulator"
        },
        "60343": {
            "GenartName": "Auxiliary Water Pump, turbocharger",
            "NormTermName": "Pump",
            "AssGrpName": "Air Supply",
            "UsageName": "Turbocharger"
        },
        "60344": {
            "GenartName": "Auxiliary Water Pump, charge air cooler",
            "NormTermName": "Pump",
            "AssGrpName": "Air Supply",
            "UsageName": "Charge Air Cooler"
        },
        "60347": {
            "GenartName": "Auxiliary Water Pump, auxiliary heater",
            "NormTermName": "Pump",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Auxiliary heater"
        },
        "60349": {
            "GenartName": "Glass polish",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Glass polish"
        },
        "60350": {
            "GenartName": "Interior Cleaner",
            "NormTermName": "Cleaner",
            "AssGrpName": "Chemical Products",
            "UsageName": "Interior"
        },
        "60351": {
            "GenartName": "Screw set, torsional absorber (clutch)",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Clutch",
            "UsageName": "Torsion Damper"
        },
        "60352": {
            "GenartName": "Drive-shaft set",
            "NormTermName": "Shaft Kit",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "60353": {
            "GenartName": "Shoe Set, centrifugal clutch",
            "NormTermName": "Shoe Set",
            "AssGrpName": "Clutch",
            "UsageName": "Motorcycle"
        },
        "60354": {
            "GenartName": "Stub shaft set, differential gear",
            "NormTermName": "Stub shaft set",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Differential"
        },
        "60355": {
            "GenartName": "Axle brake, automated manual transmission (AMT)",
            "NormTermName": "Axle brake",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60356": {
            "GenartName": "Selector lever, transmission shift (AMT)",
            "NormTermName": "Selector lever",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Gear switch (AMT)"
        },
        "60357": {
            "GenartName": "Cable Repair Set, actuator (switch unit AMT)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Setting element (switch unit AMT)"
        },
        "60358": {
            "GenartName": "Setting element, switch unit (AMT)",
            "NormTermName": "Actuator",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Switch unit (AMT)"
        },
        "60359": {
            "GenartName": "Repair kit, axle brake (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Axle brake (AMT)"
        },
        "60360": {
            "GenartName": "Repair kit, clutch master cylinder (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Clutch master cylinder (AMT)"
        },
        "60361": {
            "GenartName": "Control unit, selector lever (gear switch AMT)",
            "NormTermName": "Control Unit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Selector lever (gear switch AMT)"
        },
        "60362": {
            "GenartName": "Solenoid valve, clutch master cylinder (AMT)",
            "NormTermName": "Valve",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Clutch master cylinder (AMT)"
        },
        "60363": {
            "GenartName": "Solenoid valve, power take-off (AMT)",
            "NormTermName": "Valve",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Power Take-Off (AMT)"
        },
        "60364": {
            "GenartName": "Switch, power take-off (AMT)",
            "NormTermName": "Switch",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Power Take-Off (AMT)"
        },
        "60365": {
            "GenartName": "Bulb, full-beam/daylight running/position light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Full-beam/daylight running/position light"
        },
        "60366": {
            "GenartName": "Bulb, high beam/daytime running light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "High Beam/Daytime Running Light"
        },
        "60367": {
            "GenartName": "Bulb, daylight running/position light",
            "NormTermName": "Bulb",
            "AssGrpName": "Lights",
            "UsageName": "Daylight running/position light"
        },
        "60368": {
            "GenartName": "Holder, air compressor",
            "NormTermName": "Holder",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Compressor"
        },
        "60369": {
            "GenartName": "Solenoid valve, control unit (AMT)",
            "NormTermName": "Valve",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Control unit (AMT)"
        },
        "60370": {
            "GenartName": "Shaft, reverse gear (AMT)",
            "NormTermName": "Shaft",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Reverse gear (AMT)"
        },
        "60371": {
            "GenartName": "Clutch Master Cylinder, automated manual transmission (AMT)",
            "NormTermName": "Cylinder",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60372": {
            "GenartName": "Driver module, lights",
            "NormTermName": "Driver module",
            "AssGrpName": "Lights",
            "UsageName": null
        },
        "60373": {
            "GenartName": "Cable Repair Set, parking light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Parking Light"
        },
        "60374": {
            "GenartName": "Cable Repair Set, parking light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Parking Light"
        },
        "60375": {
            "GenartName": "Clutch, power take-off",
            "NormTermName": "Coupling",
            "AssGrpName": "Power Take-Off",
            "UsageName": "Power Take-Off"
        },
        "60376": {
            "GenartName": "Torsional damper, power take-off",
            "NormTermName": "Torsion Damper",
            "AssGrpName": "Power Take-Off",
            "UsageName": "Power Take-Off"
        },
        "60377": {
            "GenartName": "Torque limiter, automatic transmission",
            "NormTermName": "Torque Limiter",
            "AssGrpName": "Automatic Transmission",
            "UsageName": null
        },
        "60378": {
            "GenartName": "Separator element, starter battery",
            "NormTermName": "Separator element",
            "AssGrpName": "Starter System",
            "UsageName": "Starter Battery"
        },
        "60379": {
            "GenartName": "Cable Repair Set, interior fan motor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation, universal",
            "UsageName": "Interior Fan Motor"
        },
        "60380": {
            "GenartName": "Cable repair set, coolant level sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant level sensor"
        },
        "60381": {
            "GenartName": "Cable repair set, coolant level sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Coolant level sensor"
        },
        "60382": {
            "GenartName": "Connection cable, switch valve (crankcase ventilation)",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Crankcase",
            "UsageName": "Switch valve (crankcase ventilation)"
        },
        "60383": {
            "GenartName": "Cable repair set, solenoid valve (differential)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Axle Drive",
            "UsageName": "Solenoid valve (differential)"
        },
        "60384": {
            "GenartName": "Cable repair set, solenoid valve (differential)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Axle Drive, universal",
            "UsageName": "Solenoid valve (differential)"
        },
        "60385": {
            "GenartName": "Cable repair set, contact switch (sunroof)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Contact switch (sunroof)"
        },
        "60386": {
            "GenartName": "Cable repair set, contact switch (sunroof)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Contact switch (sunroof)"
        },
        "60387": {
            "GenartName": "Cable repair set, air compressor (4-wheel control)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air compressor (4-wheel control)"
        },
        "60388": {
            "GenartName": "Cable repair set, air compressor (4-wheel control)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Compressed Air System, universal",
            "UsageName": "Air compressor (4-wheel control)"
        },
        "60389": {
            "GenartName": "Cable repair set, injection valve (urea injection)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust System",
            "UsageName": "Injection valve (urea injection)"
        },
        "60390": {
            "GenartName": "Cable repair set, injection valve (urea injection)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Injection valve (urea injection)"
        },
        "60391": {
            "GenartName": "Cable Repair Set, washer fluid jet (window cleaning)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Window Cleaning",
            "UsageName": "Washer Fluid Jet (window cleaning)"
        },
        "60392": {
            "GenartName": "Cable Repair Set, washer fluid jet (window cleaning)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Window Cleaning, universal",
            "UsageName": "Washer Fluid Jet (window cleaning)"
        },
        "60393": {
            "GenartName": "Cable repair set, door entry light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights",
            "UsageName": "Entry light"
        },
        "60394": {
            "GenartName": "Cable repair set, door entry light",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lights, universal",
            "UsageName": "Entry light"
        },
        "60395": {
            "GenartName": "Valve, under-pressure socket (ignition distributor)",
            "NormTermName": "Valve",
            "AssGrpName": "Ignition System",
            "UsageName": null
        },
        "60396": {
            "GenartName": "Cable repair set, oil temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil temperature sensor"
        },
        "60397": {
            "GenartName": "Cable repair set, oil temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lubrication, universal",
            "UsageName": "Oil temperature sensor"
        },
        "60398": {
            "GenartName": "Cable repair set, contact switch (door handle)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System",
            "UsageName": "Contact switch (door handle)"
        },
        "60399": {
            "GenartName": "Cable repair set, contact switch (door handle)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Contact switch (door handle)"
        },
        "60400": {
            "GenartName": "Closing aid, door",
            "NormTermName": "Closing aid",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Door"
        },
        "60401": {
            "GenartName": "Closing aid, tailgate",
            "NormTermName": "Closing aid",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Tailgate"
        },
        "60402": {
            "GenartName": "Repair Kit, air conditioning",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": null
        },
        "60403": {
            "GenartName": "Drive Wheel, speedometer cable",
            "NormTermName": "Drive wheel",
            "AssGrpName": "Instruments",
            "UsageName": "Speedometer Cable"
        },
        "60404": {
            "GenartName": "Closing Plate, exhaust system",
            "NormTermName": "Closing Plate",
            "AssGrpName": "Exhaust System",
            "UsageName": null
        },
        "60405": {
            "GenartName": "Cap, boost pressure control valve",
            "NormTermName": "Cap",
            "AssGrpName": "Air Supply",
            "UsageName": "Boost Pressure Control Valve"
        },
        "60406": {
            "GenartName": "Locking Ring, boost pressure control valve",
            "NormTermName": "Locking Ring",
            "AssGrpName": "Air Supply",
            "UsageName": "Boost Pressure Control Valve"
        },
        "60407": {
            "GenartName": "End Piece, pressure box control rod",
            "NormTermName": "End Piece",
            "AssGrpName": "Air Supply",
            "UsageName": null
        },
        "60408": {
            "GenartName": "Seat Ring, boost pressure control valve",
            "NormTermName": "Valve Seat",
            "AssGrpName": "Air Supply",
            "UsageName": "Boost Pressure Control Valve"
        },
        "60409": {
            "GenartName": "Gauge, transmission oil temperature",
            "NormTermName": "Gauge",
            "AssGrpName": "Instruments",
            "UsageName": "Gear oil temperature"
        },
        "60410": {
            "GenartName": "Adapter Kit, oil pressure regulating valve",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil pressure control valve"
        },
        "60411": {
            "GenartName": "Sealing Plug, vacuum hose",
            "NormTermName": "Sealing plug",
            "AssGrpName": "Air Supply, universal",
            "UsageName": null
        },
        "60412": {
            "GenartName": "Sensor, boost pressure control valve",
            "NormTermName": "Sensor",
            "AssGrpName": "Air Supply",
            "UsageName": "Boost Pressure Control Valve"
        },
        "60413": {
            "GenartName": "Bracket, additional gauge",
            "NormTermName": "Holder",
            "AssGrpName": "Instruments",
            "UsageName": null
        },
        "60414": {
            "GenartName": "Bracket, fuel-pressure regulator",
            "NormTermName": "Holder",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel-pressure regulator"
        },
        "60415": {
            "GenartName": "Adapter Kit, fuel-pressure regulator",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel-pressure regulator"
        },
        "60416": {
            "GenartName": "Diaphragm, fuel-pressure regulator",
            "NormTermName": "Diaphragm",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel-pressure regulator"
        },
        "60417": {
            "GenartName": "Adapter, fuel-pressure regulator",
            "NormTermName": "Adaptor",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel-pressure regulator"
        },
        "60418": {
            "GenartName": "Cap, recirculating air valve",
            "NormTermName": "Cap",
            "AssGrpName": "Air Supply",
            "UsageName": "Recirculating Air Valve"
        },
        "60419": {
            "GenartName": "Sealing Plug, recirculating air valve",
            "NormTermName": "Sealing plug",
            "AssGrpName": "Air Supply",
            "UsageName": "Recirculating Air Valve"
        },
        "60420": {
            "GenartName": "Bracket, boost pressure control valve",
            "NormTermName": "Holder",
            "AssGrpName": "Air Supply",
            "UsageName": "Boost Pressure Control Valve"
        },
        "60421": {
            "GenartName": "Adapter, boost pressure sensor",
            "NormTermName": "Adaptor",
            "AssGrpName": "Air Supply",
            "UsageName": "Charge Pressure Sensor"
        },
        "60422": {
            "GenartName": "Anti-Lag Valve",
            "NormTermName": "Valve",
            "AssGrpName": "Air Supply",
            "UsageName": null
        },
        "60423": {
            "GenartName": "Flange, anti-lag valve",
            "NormTermName": "Flange",
            "AssGrpName": "Air Supply",
            "UsageName": "Anti-Lag Valve"
        },
        "60424": {
            "GenartName": "Cover, recirculating air valve",
            "NormTermName": "Lid",
            "AssGrpName": "Air Supply",
            "UsageName": "Recirculating Air Valve"
        },
        "60425": {
            "GenartName": "Repair set, piston/cylinder",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": "Piston/cylinder"
        },
        "60426": {
            "GenartName": "Valve Kit, intake/exhaust valve",
            "NormTermName": "Valve Set",
            "AssGrpName": "Engine Timing",
            "UsageName": "Intake/Exhaust Valve"
        },
        "60427": {
            "GenartName": "Oil, compressor",
            "NormTermName": "Oil",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Oil, compressor"
        },
        "60428": {
            "GenartName": "Screw plug, oil filter housing (lid)",
            "NormTermName": "Screw",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Filter Housing"
        },
        "60429": {
            "GenartName": "Cable repair kit, electro-motor steering gear",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Steering",
            "UsageName": "Steering gear electric motor"
        },
        "60430": {
            "GenartName": "Cable repair kit, electro-motor steering gear",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Steering, universal",
            "UsageName": "Steering gear electric motor"
        },
        "60431": {
            "GenartName": "Cable repair kit, control unit cooler fan",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling System",
            "UsageName": "Control unit cooler fan"
        },
        "60432": {
            "GenartName": "Cable repair kit, control unit cooler fan",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Cooling, universal",
            "UsageName": "Control unit cooler fan"
        },
        "60433": {
            "GenartName": "Bushing set, clutch",
            "NormTermName": "Sleeve Kit",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch"
        },
        "60434": {
            "GenartName": "2-stroke engine oil",
            "NormTermName": "Oil",
            "AssGrpName": "Lubrication",
            "UsageName": "Engine"
        },
        "60435": {
            "GenartName": "2-stroke engine oil",
            "NormTermName": "Oil",
            "AssGrpName": "Lubrication, universal",
            "UsageName": "Engine"
        },
        "60436": {
            "GenartName": "Sensor, evaporator temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Evaporator temperature"
        },
        "60437": {
            "GenartName": "Buffer, cable pull (parking brake)",
            "NormTermName": "Buffer",
            "AssGrpName": "Braking System",
            "UsageName": "Cable pull (parking brake)"
        },
        "60438": {
            "GenartName": "Connecting element, cable pull (parking brake)",
            "NormTermName": "Connector",
            "AssGrpName": "Braking System",
            "UsageName": "Cable pull (parking brake)"
        },
        "60439": {
            "GenartName": "Holding clamp, cable pull (parking brake)",
            "NormTermName": "Holding Bracket",
            "AssGrpName": "Braking System",
            "UsageName": "Cable pull (parking brake)"
        },
        "60440": {
            "GenartName": "Bellows, cable pull (parking brake)",
            "NormTermName": "Bellow",
            "AssGrpName": "Braking System",
            "UsageName": "Cable pull (parking brake)"
        },
        "60441": {
            "GenartName": "Repair set, cable pull (parking brake)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Cable pull (parking brake)"
        },
        "60442": {
            "GenartName": "Relay, secondary air pump",
            "NormTermName": "Relay",
            "AssGrpName": "Secondary Air Injection",
            "UsageName": "Secondary Air Pump"
        },
        "60443": {
            "GenartName": "RPM sensor, injection pump",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump"
        },
        "60444": {
            "GenartName": "Heating Element, thermostat preheater system",
            "NormTermName": "Heating Element",
            "AssGrpName": "Cooling System",
            "UsageName": "Thermostat preheater"
        },
        "60445": {
            "GenartName": "Pressure sensor, coolant",
            "NormTermName": "Sensor",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant"
        },
        "60446": {
            "GenartName": "Injection timing mechanism, injection pump",
            "NormTermName": "Injection timing mechanism",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump"
        },
        "60448": {
            "GenartName": "Oil bottle, reserve sample engine oil",
            "NormTermName": "Tank",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Reserve sample engine oil"
        },
        "60449": {
            "GenartName": "Oil bottle set, reserve sample engine oil",
            "NormTermName": "Tank set",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Reserve sample engine oil"
        },
        "60450": {
            "GenartName": "Oil pump set",
            "NormTermName": "Oil pump set",
            "AssGrpName": "Lubrication",
            "UsageName": null
        },
        "60451": {
            "GenartName": "Cable Repair Set, ignition switch",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Starter System, universal",
            "UsageName": "Ignition Switch"
        },
        "60452": {
            "GenartName": "Pressure sensor, work hydraulics",
            "NormTermName": "Sensor",
            "AssGrpName": "Hydraulic System",
            "UsageName": "Operating Hydraulics"
        },
        "60453": {
            "GenartName": "Guide Tube, cable pull (parking brake)",
            "NormTermName": "Guide Tube",
            "AssGrpName": "Braking System",
            "UsageName": "Cable pull (parking brake)"
        },
        "60454": {
            "GenartName": "Repair kit, transmission input shaft (manual transmission)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Transmission Input Shaft (manual transmission)"
        },
        "60455": {
            "GenartName": "Repair kit, main shaft (manual transmission)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Main shaft (manual transmission)"
        },
        "60456": {
            "GenartName": "Repair Kit, output flange (manual transmission)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Output flange (manual transmission)"
        },
        "60457": {
            "GenartName": "Repair kit, synchronisation (manual transmission)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Synchronisation (manual transmission)"
        },
        "60458": {
            "GenartName": "Repair kit, oil pump (manual transmission)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Oil pump (manual transmission)"
        },
        "60459": {
            "GenartName": "Repair kit, countershaft (manual transmission)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Countershaft (manual transmission)"
        },
        "60460": {
            "GenartName": "Repair kit, output flange (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Output flange (AMT)"
        },
        "60461": {
            "GenartName": "Adjuster, brake lever (handlebars)",
            "NormTermName": "Adjuster",
            "AssGrpName": "Braking System",
            "UsageName": "Brake lever (bicycle handlebars)"
        },
        "60462": {
            "GenartName": "Adjuster, clutch lever (handlebars)",
            "NormTermName": "Adjuster",
            "AssGrpName": "Clutch",
            "UsageName": "Clutch lever (bicycle handlebars)"
        },
        "60463": {
            "GenartName": "Repair kit, synchronisation (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Synchronisation (AMT)"
        },
        "60464": {
            "GenartName": "Repair kit, oil pump (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Oil pump (AMT)"
        },
        "60465": {
            "GenartName": "Rotation sensor, injection pump",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump"
        },
        "60466": {
            "GenartName": "Sensor, compression pressure",
            "NormTermName": "Sensor",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Compression pressure"
        },
        "60467": {
            "GenartName": "Sensor, turbocharger",
            "NormTermName": "Sensor",
            "AssGrpName": "Air Supply",
            "UsageName": "Turbocharger"
        },
        "60468": {
            "GenartName": "Sensor, urea temperature",
            "NormTermName": "Sensor",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea temperature"
        },
        "60469": {
            "GenartName": "Voltage control, injection valve",
            "NormTermName": "Regulator",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector"
        },
        "60470": {
            "GenartName": "Actuator, heating flap",
            "NormTermName": "Actuator",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Heating flap"
        },
        "60471": {
            "GenartName": "Lid, urea filter housing",
            "NormTermName": "Lid",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea filter housing"
        },
        "60472": {
            "GenartName": "Bonnet Handle",
            "NormTermName": "Handle",
            "AssGrpName": "Body",
            "UsageName": "Bonnet"
        },
        "60473": {
            "GenartName": "Aerial foot",
            "NormTermName": "Aerial foot",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": null
        },
        "60474": {
            "GenartName": "Aerial foot",
            "NormTermName": "Aerial foot",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "60475": {
            "GenartName": "Fuse",
            "NormTermName": "Fuse",
            "AssGrpName": "Central Electrics",
            "UsageName": null
        },
        "60476": {
            "GenartName": "Connection cable, switch valve (crankcase ventilation)",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Crankcase, universal",
            "UsageName": "Switch valve (crankcase ventilation)"
        },
        "60477": {
            "GenartName": "Rain Sensor",
            "NormTermName": "Sensor",
            "AssGrpName": "Comfort Systems, universal",
            "UsageName": "Rain Sensor"
        },
        "60478": {
            "GenartName": "Repair kit, Oil Pump (automatic transmission)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil Pump (automatic transmission)"
        },
        "60479": {
            "GenartName": "Switch, glove compartment light",
            "NormTermName": "Switch",
            "AssGrpName": "Lights",
            "UsageName": "Glove Compartment Light"
        },
        "60480": {
            "GenartName": "Mechatronics, autom. manual trans. (AMT)",
            "NormTermName": "Mechatronics",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Automated manual transmission (AMT)"
        },
        "60481": {
            "GenartName": "Adapter cable, High-Pressure Pump",
            "NormTermName": "Adapter Cable",
            "AssGrpName": "Mixture Formation",
            "UsageName": "High Pressure Pump"
        },
        "60482": {
            "GenartName": "Repair kit, Lamella (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Lamella (AMT)"
        },
        "60483": {
            "GenartName": "Repair kit, range group (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Range group (AMT)"
        },
        "60484": {
            "GenartName": "Repair kit, Tooth Wheel (AMT)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Tooth wheel (AMT)"
        },
        "60485": {
            "GenartName": "Workbench, traction battery",
            "NormTermName": "Workbench",
            "AssGrpName": "Tool, universal",
            "UsageName": "Traction Battery"
        },
        "60486": {
            "GenartName": "Lifting frame, traction battery",
            "NormTermName": "Lifting frame",
            "AssGrpName": "Tool, universal",
            "UsageName": "Traction Battery"
        },
        "60487": {
            "GenartName": "Nut, input shaft (differential)",
            "NormTermName": "Nut",
            "AssGrpName": "Axle Drive",
            "UsageName": "Input shaft (differential)"
        },
        "60488": {
            "GenartName": "Thrust Washer, automatic transmission",
            "NormTermName": "Thrust Washer",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "60489": {
            "GenartName": "Oil Well, manual transmission",
            "NormTermName": "Oil Trap",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "60490": {
            "GenartName": "Clamping sleeve, bearings (bevel gear)",
            "NormTermName": "Sleeve",
            "AssGrpName": "Axle Drive",
            "UsageName": "Bearing (bevel gear)"
        },
        "60491": {
            "GenartName": "Freewheel, torque converter",
            "NormTermName": "Freewheel",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Torque Converter"
        },
        "60492": {
            "GenartName": "Sensor Ring, manual transmission",
            "NormTermName": "Sensor Ring",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "60493": {
            "GenartName": "Shift lock, manual transmission",
            "NormTermName": "Shift locking",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "60494": {
            "GenartName": "Thrust Washer, differential",
            "NormTermName": "Thrust Washer",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "60495": {
            "GenartName": "Oil Cooler Line, manual transmission",
            "NormTermName": "Oil Cooler Line",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "60496": {
            "GenartName": "Repair kit, tooth wheel (manual transmission)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Tooth wheel (manual transmission)"
        },
        "60497": {
            "GenartName": "Repair kit, range group (manual transmission)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Range group (manual transmission)"
        },
        "60498": {
            "GenartName": "Sensor, neutral position (manual transmission)",
            "NormTermName": "Sensor",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Neutral position"
        },
        "60499": {
            "GenartName": "Sensor, rotor position (electric engine)",
            "NormTermName": "Sensor",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Electric Motor"
        },
        "60500": {
            "GenartName": "Sensor, transmission position (manual transmission)",
            "NormTermName": "Sensor",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "60501": {
            "GenartName": "Sensor, transmission position (automatic transmission)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "60502": {
            "GenartName": "Control unit, air mass sensor",
            "NormTermName": "Control Unit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Air Mass"
        },
        "60503": {
            "GenartName": "Control unit, differential lock",
            "NormTermName": "Control Unit",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential Lock"
        },
        "60504": {
            "GenartName": "PCB, mechatronics (automatic transmission)",
            "NormTermName": "Board",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Mechatronics (automatic transmission)"
        },
        "60505": {
            "GenartName": "PCB, mechatronics (AMT)",
            "NormTermName": "Board",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Mechatronics (AMT)"
        },
        "60506": {
            "GenartName": "Inverter/DC converter combination",
            "NormTermName": "Inverter/DC converter combination",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": null
        },
        "60507": {
            "GenartName": "Battery module, traction battery",
            "NormTermName": "Battery module",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Traction Battery"
        },
        "60508": {
            "GenartName": "Switch, shift cylinder (AMT)",
            "NormTermName": "Switch",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Shift cylinder (AMT)"
        },
        "60509": {
            "GenartName": "Shift cylinder, split gear (AMT)",
            "NormTermName": "Cylinder",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Split gear (AMT)"
        },
        "60510": {
            "GenartName": "Decoupling tensioner, V-ribbed belt",
            "NormTermName": "Tensioner",
            "AssGrpName": "Belt Drive",
            "UsageName": "V-Ribbed Belt"
        },
        "60511": {
            "GenartName": "Tow bar storage box",
            "NormTermName": "Stowage box",
            "AssGrpName": "Trailer",
            "UsageName": "Drawbar"
        },
        "60512": {
            "GenartName": "Support plate, trailer",
            "NormTermName": "Support plate",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer"
        },
        "60513": {
            "GenartName": "Solar sensor, air conditioning system",
            "NormTermName": "Sensor",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Solar sensor"
        },
        "60514": {
            "GenartName": "Carbon brush set, electric motor",
            "NormTermName": "Carbon Brush Set",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Electric Motor"
        },
        "60515": {
            "GenartName": "Holder, carbon brushes (electric motor)",
            "NormTermName": "Holder",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": "Carbon brushes (electric motor)"
        },
        "60516": {
            "GenartName": "Ramp",
            "NormTermName": "Ramp",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "60517": {
            "GenartName": "Ramp set",
            "NormTermName": "Ramp set",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "60518": {
            "GenartName": "Caravan protective cover",
            "NormTermName": "Protective Sleeve",
            "AssGrpName": "Trailer",
            "UsageName": "Caravan"
        },
        "60519": {
            "GenartName": "Caravan Mirror",
            "NormTermName": "Mirror",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Caravan Mirror"
        },
        "60520": {
            "GenartName": "Windscreen seal",
            "NormTermName": "Windscreen seal",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "60521": {
            "GenartName": "Anti-Squeal Spray, brake pad (back plate)",
            "NormTermName": "Anti-Squeal spray",
            "AssGrpName": "Chemical Products",
            "UsageName": "Brake Pad (back plate)"
        },
        "60522": {
            "GenartName": "Shift Piston, multi-plate clutch (automatic transmission)",
            "NormTermName": "Piston",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Multi-Plate Clutch (automatic transmission)"
        },
        "60523": {
            "GenartName": "Charge air pipe",
            "NormTermName": "Pipe",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger"
        },
        "60524": {
            "GenartName": "Balance shaft module",
            "NormTermName": "Balance shaft module",
            "AssGrpName": "Crankcase",
            "UsageName": null
        },
        "60525": {
            "GenartName": "Tow hook",
            "NormTermName": "Hook",
            "AssGrpName": "Accessories",
            "UsageName": null
        },
        "60526": {
            "GenartName": "Attachment module, valve train system (fully variable)",
            "NormTermName": "Attachment module",
            "AssGrpName": "Engine Timing",
            "UsageName": "Valve train system (fully variable)"
        },
        "60527": {
            "GenartName": "Crank, parking brake",
            "NormTermName": "Crank",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Parking Brake"
        },
        "60528": {
            "GenartName": "Display/Operating Unit (touchscreen)",
            "NormTermName": "Display/Operating Unit",
            "AssGrpName": "Communication / Information Systems",
            "UsageName": null
        },
        "60529": {
            "GenartName": "Display/Operating Unit (touchscreen)",
            "NormTermName": "Display/Operating Unit",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": null
        },
        "60530": {
            "GenartName": "Remote control, hand lamp",
            "NormTermName": "Remote Control",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Hand Lamp"
        },
        "60531": {
            "GenartName": "LAN cable",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "60532": {
            "GenartName": "Sensor, blind spot detection",
            "NormTermName": "Sensor",
            "AssGrpName": "Safety Systems",
            "UsageName": "Blind Spot Detection"
        },
        "60533": {
            "GenartName": "CVT Drive Belt",
            "NormTermName": "Belt",
            "AssGrpName": "Belt Drive",
            "UsageName": "CVT"
        },
        "60534": {
            "GenartName": "Threaded sleeve, leaf spring bearing",
            "NormTermName": "Sleeve",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leaf Spring Mounting"
        },
        "60535": {
            "GenartName": "Contact switch, bonnet",
            "NormTermName": "Switch",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Bonnet"
        },
        "60536": {
            "GenartName": "Bellows, stabiliser bar",
            "NormTermName": "Bellow",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Stabiliser"
        },
        "60537": {
            "GenartName": "Seal set, connector compressed air system",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Compressed-air System Coupling"
        },
        "60538": {
            "GenartName": "Cylinder head hood seal, air compressor",
            "NormTermName": "Seal",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Cylinder Head Cover"
        },
        "60539": {
            "GenartName": "Cylinder Head Bolt, air compressor",
            "NormTermName": "Screw",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Cylinder Head"
        },
        "60540": {
            "GenartName": "Cylinder Head Bolt Set, air compressor",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Cylinder Head"
        },
        "60541": {
            "GenartName": "Bushing, air-conditioning condenser",
            "NormTermName": "Bush",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Air-conditioning condenser"
        },
        "60542": {
            "GenartName": "Head Band, headlamp",
            "NormTermName": "Strap",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Headlamp"
        },
        "60543": {
            "GenartName": "Actuator, 4-wheel drive",
            "NormTermName": "Actuator",
            "AssGrpName": "Wheel Drive",
            "UsageName": "All-Wheel Drive"
        },
        "60544": {
            "GenartName": "Cover, oil separator (crankcase ventilation)",
            "NormTermName": "Lid",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase Ventilation"
        },
        "60545": {
            "GenartName": "Trailer Tarpaulin",
            "NormTermName": "Tarpaulin",
            "AssGrpName": "Trailer",
            "UsageName": "Trailer Tarpaulin"
        },
        "60546": {
            "GenartName": "Repair Kit, oil separator (crankcase ventilation)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase Ventilation"
        },
        "60547": {
            "GenartName": "Holder, ABS connection cable",
            "NormTermName": "Holder",
            "AssGrpName": "Braking System",
            "UsageName": "ABS"
        },
        "60548": {
            "GenartName": "Gasket, servo pump",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering Pump"
        },
        "60549": {
            "GenartName": "Cable Set, automatic transmission",
            "NormTermName": "Cable Set",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "60550": {
            "GenartName": "Cable Set, manual transmission",
            "NormTermName": "Cable Set",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual Transmission"
        },
        "60551": {
            "GenartName": "Shaft Seal Set, differential",
            "NormTermName": "Shaft Seal Set",
            "AssGrpName": "Axle Drive",
            "UsageName": "Differential"
        },
        "60552": {
            "GenartName": "Air Filter, traction battery casing",
            "NormTermName": "Filter",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Drive Battery Casing"
        },
        "60553": {
            "GenartName": "Solenoid Valve, urea tank",
            "NormTermName": "Valve",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea Tank"
        },
        "60554": {
            "GenartName": "Cover, timing chain tensioner (assembly opening)",
            "NormTermName": "Closure",
            "AssGrpName": "Engine Timing",
            "UsageName": "Timing Chain Tensioner"
        },
        "60555": {
            "GenartName": "Bump Stop, hatch door/hatchback",
            "NormTermName": "Buffer",
            "AssGrpName": "Body",
            "UsageName": "Hatch Door/Back"
        },
        "60556": {
            "GenartName": "Stopper, suspension strut support mount",
            "NormTermName": "Stop",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Suspension Strut Support Mount"
        },
        "60557": {
            "GenartName": "Body Control Unit",
            "NormTermName": "Control Unit",
            "AssGrpName": "Central Electrics",
            "UsageName": null
        },
        "60558": {
            "GenartName": "Control unit, all-wheel drive",
            "NormTermName": "Control Unit",
            "AssGrpName": "Axle Drive",
            "UsageName": "All-Wheel Drive"
        },
        "60559": {
            "GenartName": "Oil line, camshaft adjustment",
            "NormTermName": "Pipe",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Adjustment"
        },
        "60560": {
            "GenartName": "Bulb",
            "NormTermName": "Bulb",
            "AssGrpName": "Electric Universal Parts",
            "UsageName": null
        },
        "60561": {
            "GenartName": "Riveting machine",
            "NormTermName": "Riveting machine",
            "AssGrpName": "Workshop Equipment",
            "UsageName": null
        },
        "60562": {
            "GenartName": "Washer Fluid Pump, safety system",
            "NormTermName": "Pump",
            "AssGrpName": "Safety Systems",
            "UsageName": "Washing Water"
        },
        "60563": {
            "GenartName": "Washer Fluid Pump, comfort system",
            "NormTermName": "Pump",
            "AssGrpName": "Comfort Systems",
            "UsageName": "Washing Water"
        },
        "60564": {
            "GenartName": "Friction Disc, oil immersed brake",
            "NormTermName": "Friction Disc",
            "AssGrpName": "Braking System",
            "UsageName": "Oil Immersed Brake"
        },
        "60565": {
            "GenartName": "Intermediate Disc, oil immersed brake",
            "NormTermName": "Intermediate Disc",
            "AssGrpName": "Braking System",
            "UsageName": "Oil Immersed Brake"
        },
        "60566": {
            "GenartName": "Mounting Kit, control/trailing arm",
            "NormTermName": "Mounting Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": "Control/Trailing Arm"
        },
        "60567": {
            "GenartName": "Retaining spring, coolant pipe",
            "NormTermName": "Spring",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant Pipe"
        },
        "60568": {
            "GenartName": "Cover, headlamp cleaning system",
            "NormTermName": "Cover",
            "AssGrpName": "Headlight Cleaning",
            "UsageName": "Headlight cleaning system"
        },
        "60569": {
            "GenartName": "Holder, wing",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Wing"
        },
        "60570": {
            "GenartName": "Holder, spoiler",
            "NormTermName": "Holder",
            "AssGrpName": "Body",
            "UsageName": "Spoiler"
        },
        "60571": {
            "GenartName": "Mudflap",
            "NormTermName": "Mudflap",
            "AssGrpName": "Accessories",
            "UsageName": null
        },
        "60572": {
            "GenartName": "Position sensor, EGR valve",
            "NormTermName": "Sensor",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "EGR Valve"
        },
        "60573": {
            "GenartName": "Electric Motor",
            "NormTermName": "Electric Motor",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Hybrid/Electrical Drive"
        },
        "60574": {
            "GenartName": "Mounting kit, SCR catalytic converter",
            "NormTermName": "Assembly Kit",
            "AssGrpName": "Exhaust System",
            "UsageName": "SCR catalytic converter"
        },
        "60575": {
            "GenartName": "Water pump, cooling circuit electrical drive",
            "NormTermName": "Pump",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Cooling circuit electrical drive"
        },
        "60576": {
            "GenartName": "Additive, soot/particulate filter regeneration",
            "NormTermName": "Additive",
            "AssGrpName": "Exhaust System",
            "UsageName": "Soot/Particulate Filter Regeneration"
        },
        "60577": {
            "GenartName": "Connection Piece, coolant line",
            "NormTermName": "Fitting",
            "AssGrpName": "Cooling System",
            "UsageName": "Coolant"
        },
        "60578": {
            "GenartName": "Assortment, chip filter",
            "NormTermName": "Assortment",
            "AssGrpName": "Air Conditioning, universal",
            "UsageName": "Chip filter"
        },
        "60579": {
            "GenartName": "Rescue Hook, personal rescue (high-voltage range)",
            "NormTermName": "Rescue Hook",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Personal Rescue (high-voltage range)"
        },
        "60580": {
            "GenartName": "Adapter set, coupling ball (towing device)",
            "NormTermName": "Adaptor Kit",
            "AssGrpName": "Towbar, universal",
            "UsageName": "Coupling ball (towing device)"
        },
        "60581": {
            "GenartName": "Wheel holder, bike rack (towing device)",
            "NormTermName": "Wheel holder",
            "AssGrpName": "Carrier Unit, Universal",
            "UsageName": "Tow Bar Coupling Bike Carrier"
        },
        "60582": {
            "GenartName": "Retaining spring, bulb (headlight)",
            "NormTermName": "Spring",
            "AssGrpName": "Lights",
            "UsageName": "Bulb (headlight)"
        },
        "60583": {
            "GenartName": "Holder, coolant line",
            "NormTermName": "Holder",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Coolant Line"
        },
        "60584": {
            "GenartName": "Thermostat, electric drive cooling circuit",
            "NormTermName": "Thermostat",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Cooling circuit electrical drive"
        },
        "60585": {
            "GenartName": "Retaining Clip Set, heat shield",
            "NormTermName": "Retaining Clip Set",
            "AssGrpName": "Exhaust System",
            "UsageName": "Heat Shield"
        },
        "60586": {
            "GenartName": "Set of spokes, rim",
            "NormTermName": "Set of spokes",
            "AssGrpName": "Wheels",
            "UsageName": "Rim"
        },
        "60587": {
            "GenartName": "Gas spring, side window",
            "NormTermName": "Gas Spring",
            "AssGrpName": "Body",
            "UsageName": "Side Window"
        },
        "60588": {
            "GenartName": "Dryer, traction battery",
            "NormTermName": "Dehumidifier",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Traction Battery"
        },
        "60589": {
            "GenartName": "Steering bearings set",
            "NormTermName": "Steering bearings set",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Steering Head (motorcycle)"
        },
        "60590": {
            "GenartName": "Spoke, rim",
            "NormTermName": "Spoke",
            "AssGrpName": "Wheels",
            "UsageName": "Rim"
        },
        "60591": {
            "GenartName": "Nipple, spoke",
            "NormTermName": "Nipple",
            "AssGrpName": "Wheels",
            "UsageName": "Rim"
        },
        "60592": {
            "GenartName": "Front wheel fork",
            "NormTermName": "Fork",
            "AssGrpName": "Motorcycle Frames",
            "UsageName": "Fork (motorcycle)"
        },
        "60593": {
            "GenartName": "Flush Kit, air conditioning service unit",
            "NormTermName": "Flush Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Service Unit"
        },
        "60594": {
            "GenartName": "WiFi adapter, air conditioning service unit",
            "NormTermName": "WiFi adapter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Service Unit"
        },
        "60595": {
            "GenartName": "Software update, air conditioning service unit",
            "NormTermName": "Software",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Air Conditioning Service Unit"
        },
        "60596": {
            "GenartName": "Mount, sealant remover (air conditioning service unit)",
            "NormTermName": "Holder",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Sealant Remover (air conditioning service unit)"
        },
        "60597": {
            "GenartName": "Filter, sealant remover (air conditioning service unit)",
            "NormTermName": "Filter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Sealant Remover (air conditioning service unit)"
        },
        "60598": {
            "GenartName": "Filter, flush tank (air-conditioning)",
            "NormTermName": "Filter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Flush tank (air conditioning)"
        },
        "60599": {
            "GenartName": "Filling device, compressor oil",
            "NormTermName": "Filling Unit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Oil, compressor"
        },
        "60600": {
            "GenartName": "Test leak, leak detector (air-conditioning)",
            "NormTermName": "Test leak",
            "AssGrpName": "Chemical Products",
            "UsageName": "Leak detector (air conditioning)"
        },
        "60601": {
            "GenartName": "Hose set, pressure regulator",
            "NormTermName": "Hose set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cylinder pressure regulator"
        },
        "60602": {
            "GenartName": "Filter, leak detector (air-conditioning)",
            "NormTermName": "Filter",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Leak detector (air conditioning)"
        },
        "60603": {
            "GenartName": "Cable Set, glow plug",
            "NormTermName": "Cable Set",
            "AssGrpName": "Glow Ignition System",
            "UsageName": "Glow Plug"
        },
        "60604": {
            "GenartName": "Fuel Filler Flap",
            "NormTermName": "Lid",
            "AssGrpName": "Body",
            "UsageName": null
        },
        "60605": {
            "GenartName": "Tank unit, additive (soot/particulate filter regeneration)",
            "NormTermName": "Tank Unit",
            "AssGrpName": "Exhaust System",
            "UsageName": "Soot/Particulate Filter Regeneration"
        },
        "60606": {
            "GenartName": "Sensor, neutral position (automatic transmission)",
            "NormTermName": "Sensor",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Neutral position"
        },
        "60607": {
            "GenartName": "Seal Ring, crankcase ventilation",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase Ventilation"
        },
        "60608": {
            "GenartName": "Seal Ring Set, crankcase ventilation",
            "NormTermName": "Seal Ring Set",
            "AssGrpName": "Crankcase",
            "UsageName": "Crankcase Ventilation"
        },
        "60609": {
            "GenartName": "Mounting Aid, anti-fog sensor",
            "NormTermName": "Mounting Aid",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Anti-fog Sensor"
        },
        "60610": {
            "GenartName": "Brake pedal feeling simulator",
            "NormTermName": "Brake pedal feeling simulator",
            "AssGrpName": "Braking System",
            "UsageName": null
        },
        "60611": {
            "GenartName": "Seal, hydraulic pump",
            "NormTermName": "Seal",
            "AssGrpName": "Steering",
            "UsageName": "Hydraulic Pump"
        },
        "60612": {
            "GenartName": "Seal, pump drive hydraulics",
            "NormTermName": "Seal",
            "AssGrpName": "Hydraulic System",
            "UsageName": "Hydraulic Pump Unit"
        },
        "60613": {
            "GenartName": "Seal set, pump drive hydraulics",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Hydraulic System",
            "UsageName": "Hydraulic Pump Unit"
        },
        "60614": {
            "GenartName": "Seal, servo pump",
            "NormTermName": "Seal",
            "AssGrpName": "Steering",
            "UsageName": "Power Steering Pump"
        },
        "60615": {
            "GenartName": "Seat Support",
            "NormTermName": "Support",
            "AssGrpName": "Interior Equipment, universal",
            "UsageName": null
        },
        "60616": {
            "GenartName": "Seal, tank unit (urea injection)",
            "NormTermName": "Seal",
            "AssGrpName": "Exhaust System",
            "UsageName": "Tank Unit (urea injection)"
        },
        "60617": {
            "GenartName": "Seal ring, tank unit (urea injection)",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Exhaust System",
            "UsageName": "Tank Unit (urea injection)"
        },
        "60618": {
            "GenartName": "Seal set, tank unit (urea injection)",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Exhaust System",
            "UsageName": "Tank Unit (urea injection)"
        },
        "60619": {
            "GenartName": "Seal Ring Set, tank unit (urea injection)",
            "NormTermName": "Seal Ring Set",
            "AssGrpName": "Exhaust System",
            "UsageName": "Tank Unit (urea injection)"
        },
        "60620": {
            "GenartName": "Operating Unit, steering column",
            "NormTermName": "Operating Unit",
            "AssGrpName": "Central Electrics",
            "UsageName": "Steering Column"
        },
        "60621": {
            "GenartName": "Eccentric shaft, camshaft adjustment",
            "NormTermName": "Shaft",
            "AssGrpName": "Engine Timing",
            "UsageName": "Camshaft Adjustment"
        },
        "60622": {
            "GenartName": "Sensor, ammoniac content (urea injection)",
            "NormTermName": "Sensor",
            "AssGrpName": "Exhaust System",
            "UsageName": "Ammoniac content (urea injection)"
        },
        "60623": {
            "GenartName": "Bearing, driver’s cab",
            "NormTermName": "Mounting",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab"
        },
        "60624": {
            "GenartName": "Bearing, driver’s cab stabilizer",
            "NormTermName": "Mounting",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Stabiliser"
        },
        "60625": {
            "GenartName": "Mounting Kit, driver’s cab stabilizer",
            "NormTermName": "Mounting Kit",
            "AssGrpName": "Driver Cab",
            "UsageName": "Driver Cab Stabiliser"
        },
        "60626": {
            "GenartName": "Seal set, electric motor",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Electric Motor"
        },
        "60627": {
            "GenartName": "Filter, air compressor (air brake system)",
            "NormTermName": "Filter",
            "AssGrpName": "Braking System",
            "UsageName": "Compressed air compressor (brake system)"
        },
        "60628": {
            "GenartName": "Filter, venting (hydraulic tank)",
            "NormTermName": "Filter",
            "AssGrpName": "Hydraulic System",
            "UsageName": "Bleeding"
        },
        "60629": {
            "GenartName": "Filter, gear oil cooling (manual gearbox)",
            "NormTermName": "Filter",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Gear oil cooling"
        },
        "60630": {
            "GenartName": "Filter, gear oil cooling (automatic gearbox)",
            "NormTermName": "Filter",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Gear oil cooling"
        },
        "60631": {
            "GenartName": "Filter, gear oil cooling (AMT)",
            "NormTermName": "Filter",
            "AssGrpName": "Automated manual transmission",
            "UsageName": "Gear oil cooling"
        },
        "60632": {
            "GenartName": "Repair set, clutch",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "60633": {
            "GenartName": "Pin, timing chain rivet extraction/insertion device",
            "NormTermName": "Pin",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Timing Chain Rivet Extraction/Insertion Device"
        },
        "60634": {
            "GenartName": "Pin, timing chain rivet extraction/insertion device",
            "NormTermName": "Pin",
            "AssGrpName": "Tool, universal",
            "UsageName": "Timing Chain Rivet Extraction/Insertion Device"
        },
        "60635": {
            "GenartName": "Test paper, oil quality",
            "NormTermName": "Test Paper",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Oil quality"
        },
        "60636": {
            "GenartName": "Hydraulic cable set, automatic gearbox control",
            "NormTermName": "Pipe Set",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic gearbox control"
        },
        "60637": {
            "GenartName": "Hydraulic cable set, manual gearbox control",
            "NormTermName": "Pipe Set",
            "AssGrpName": "Manual Transmission",
            "UsageName": "Manual gearbox control"
        },
        "60638": {
            "GenartName": "Sensor, urea quality",
            "NormTermName": "Sensor",
            "AssGrpName": "Exhaust System",
            "UsageName": "Urea quality"
        },
        "60639": {
            "GenartName": "Cartridge Kit, front fork",
            "NormTermName": "Cartridge Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Fork (motorcycle)"
        },
        "60640": {
            "GenartName": "Holder, fuel injector",
            "NormTermName": "Holder",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injector Nozzle"
        },
        "60641": {
            "GenartName": "Connection cable, fuse box (drive battery)",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Fuse box (drive battery)"
        },
        "60642": {
            "GenartName": "Connection cable, battery module (drive battery)",
            "NormTermName": "Electric Cable",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Battery module (drive battery)"
        },
        "60643": {
            "GenartName": "Cell contact, battery module (drive battery)",
            "NormTermName": "Cell contact",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Battery module (drive battery)"
        },
        "60644": {
            "GenartName": "Cover, cell contact (battery module)",
            "NormTermName": "Cover",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Cell contact (battery module)"
        },
        "60645": {
            "GenartName": "Vacuum actuator, swirl flaps (intake manifold)",
            "NormTermName": "Vacuum Cell",
            "AssGrpName": "Air Supply",
            "UsageName": "Swirl flaps (intake manifold)"
        },
        "60646": {
            "GenartName": "Pump, air supply",
            "NormTermName": "Pump",
            "AssGrpName": "Air Supply",
            "UsageName": null
        },
        "60647": {
            "GenartName": "Bolt, brake disc",
            "NormTermName": "Screw",
            "AssGrpName": "Braking System, universal",
            "UsageName": "Brake Disc"
        },
        "60648": {
            "GenartName": "Seal, end-shield covers",
            "NormTermName": "Seal",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "End-shield cover"
        },
        "60649": {
            "GenartName": "Seal Kit, oil pump",
            "NormTermName": "Gasket Set",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "60650": {
            "GenartName": "Hydraulic cable set, suspension",
            "NormTermName": "Pipe Set",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "60651": {
            "GenartName": "Camera Holder, lane departure warning system",
            "NormTermName": "Bracket",
            "AssGrpName": "Safety Systems",
            "UsageName": "Lane Departure Warning System"
        },
        "60652": {
            "GenartName": "Magnet, locking system",
            "NormTermName": "Magnet",
            "AssGrpName": "Lock System, universal",
            "UsageName": "Locking System"
        },
        "60653": {
            "GenartName": "Expansion module, pneumatic suspension",
            "NormTermName": "Expansion module",
            "AssGrpName": "Compressed-air System",
            "UsageName": "Air Suspension"
        },
        "60654": {
            "GenartName": "Brake kit, pneumatic brake system",
            "NormTermName": "Brake Kit",
            "AssGrpName": "Braking System",
            "UsageName": "Pneumatic Brake System"
        },
        "60655": {
            "GenartName": "Suspension kit, pneumatic suspension",
            "NormTermName": "Suspension kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Pneumatic Suspension"
        },
        "60656": {
            "GenartName": "Hose, wheel sensor (tyre-pressure monitoring system)",
            "NormTermName": "Hose",
            "AssGrpName": "Wheels",
            "UsageName": "Wheel Sensor"
        },
        "60657": {
            "GenartName": "Counterweight, wheel sensor (tyre-pressure monitoring sys.)",
            "NormTermName": "Counterweight",
            "AssGrpName": "Wheels",
            "UsageName": "Wheel Sensor"
        },
        "60658": {
            "GenartName": "Extraction Sleeve, drive shaft",
            "NormTermName": "Sleeve",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Drive Shaft"
        },
        "60659": {
            "GenartName": "Clamping Sleeve, drive shaft",
            "NormTermName": "Sleeve",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Drive Shaft"
        },
        "60660": {
            "GenartName": "Window Adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Body",
            "UsageName": "Window Fitting"
        },
        "60661": {
            "GenartName": "Return Stop Valve, oil filter",
            "NormTermName": "Valve",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Filter"
        },
        "60662": {
            "GenartName": "Balance Shaft, crank drive",
            "NormTermName": "Shaft",
            "AssGrpName": "Crankshaft Drive",
            "UsageName": null
        },
        "60663": {
            "GenartName": "Brake Service Paste",
            "NormTermName": "Lubricant",
            "AssGrpName": "Braking System",
            "UsageName": "Brake Service Paste"
        },
        "60664": {
            "GenartName": "Gasket, inverter",
            "NormTermName": "Seal",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Inverter"
        },
        "60665": {
            "GenartName": "Screen, filling pipe (fuel tank)",
            "NormTermName": "Strainer",
            "AssGrpName": "Fuel Supply System, universal",
            "UsageName": "Filler Pipe (fuel tank)"
        },
        "60666": {
            "GenartName": "Pulley, air conditioning compressor",
            "NormTermName": "Belt Pulley",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Air Conditioning Compressor"
        },
        "60667": {
            "GenartName": "Gasket, stator housing",
            "NormTermName": "Seal",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Stator housing"
        },
        "60668": {
            "GenartName": "Wall holder, heat shrink tube assortment",
            "NormTermName": "Wall Mounting",
            "AssGrpName": "Standard Parts",
            "UsageName": "Heat-shrinkable tubing range"
        },
        "60669": {
            "GenartName": "Repair kit, high-pressure pump",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Mixture Formation",
            "UsageName": "High Pressure Pump"
        },
        "60670": {
            "GenartName": "Seal, thermostat",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cooling System",
            "UsageName": "Thermostat"
        },
        "60671": {
            "GenartName": "Gasket, thermostat housing",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cooling System",
            "UsageName": "Thermostat Housing"
        },
        "60672": {
            "GenartName": "Seal, oil filter",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Filter"
        },
        "60673": {
            "GenartName": "Seal, fuel filter",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Filter"
        },
        "60674": {
            "GenartName": "Seal, injection pump",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Injection Pump"
        },
        "60675": {
            "GenartName": "Seal, fuel filler neck",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Filler Neck"
        },
        "60676": {
            "GenartName": "Seal, water pump",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Cooling System",
            "UsageName": "Water Pump"
        },
        "60677": {
            "GenartName": "Seal, throttle nozzle",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Mixture Formation",
            "UsageName": "Throttle Body"
        },
        "60678": {
            "GenartName": "Seal, oil pump",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Pump"
        },
        "60679": {
            "GenartName": "Seal, oil filter housing",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Lubrication",
            "UsageName": "Oil Filter Housing"
        },
        "60680": {
            "GenartName": "Seal, fuel pump",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Fuel Supply System",
            "UsageName": "Fuel Pump"
        },
        "60681": {
            "GenartName": "Seal, vacuum pump",
            "NormTermName": "Seal Ring",
            "AssGrpName": "Braking System",
            "UsageName": "Vacuum Pump"
        },
        "60682": {
            "GenartName": "Water Pump, inverter",
            "NormTermName": "Pump",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Inverter"
        },
        "60683": {
            "GenartName": "Paper catalogue",
            "NormTermName": "Paper catalogue",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "60684": {
            "GenartName": "Friction Lining, clutch disc",
            "NormTermName": "Friction Lining",
            "AssGrpName": "Clutch",
            "UsageName": null
        },
        "60685": {
            "GenartName": "Accessory Set, rivet machine",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Riveting Machine"
        },
        "60686": {
            "GenartName": "Adapter, respiratory safety mask seal test",
            "NormTermName": "Adaptor",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Respiratory safety mask seal test"
        },
        "60687": {
            "GenartName": "Guide Tube, oil sump (automatic transmission)",
            "NormTermName": "Guide Tube",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil Sump, automatic transmission"
        },
        "60688": {
            "GenartName": "Overflow Pipe, oil sump (automatic transmission)",
            "NormTermName": "Overflow Pipe",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil Sump, automatic transmission"
        },
        "60689": {
            "GenartName": "Retrofit kit, axle load scale",
            "NormTermName": "Conversion Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Axle load display"
        },
        "60690": {
            "GenartName": "Repair kit, transmission (E-axle)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Transmission (E-axle)"
        },
        "60691": {
            "GenartName": "Repair kit, E-engine (E-axle)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "E-engine (E-axle)"
        },
        "60692": {
            "GenartName": "Bearings, starter couplings (two-wheel)",
            "NormTermName": "Bearing",
            "AssGrpName": "Starter System",
            "UsageName": "Starter coupling (two-wheel)"
        },
        "60693": {
            "GenartName": "Assembly grease, electrical components",
            "NormTermName": "Lubricant",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Electrical components"
        },
        "60694": {
            "GenartName": "Root cutter",
            "NormTermName": "Root cutter",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "60695": {
            "GenartName": "Attachment, sleeve welding device",
            "NormTermName": "Attachment",
            "AssGrpName": "Tool, universal",
            "UsageName": "Sleeve welding device"
        },
        "60696": {
            "GenartName": "Tension bolt, puller (guide rail pin)",
            "NormTermName": "Tension bolt",
            "AssGrpName": "Tool, universal",
            "UsageName": "Puller (guide rail pin)"
        },
        "60697": {
            "GenartName": "Puller set, guide rail pin",
            "NormTermName": "Puller Set",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Guide Rail Pin"
        },
        "60698": {
            "GenartName": "Tension bolt, wheel nut",
            "NormTermName": "Tension bolt",
            "AssGrpName": "Tool, universal",
            "UsageName": "Wheel Nut"
        },
        "60699": {
            "GenartName": "Puller, glow plug pin",
            "NormTermName": "Extractor",
            "AssGrpName": "Tool, universal",
            "UsageName": "Glow Plug Pin"
        },
        "60700": {
            "GenartName": "Puller, glow plug pin",
            "NormTermName": "Extractor",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Glow Plug Pin"
        },
        "60701": {
            "GenartName": "Insert, nibbler",
            "NormTermName": "Insert",
            "AssGrpName": "Tool, universal",
            "UsageName": "Nibbler Shear"
        },
        "60702": {
            "GenartName": "Thrust piece, rivet tool",
            "NormTermName": "Thrust Piece",
            "AssGrpName": "Tool, universal",
            "UsageName": "Rivet tool"
        },
        "60703": {
            "GenartName": "Sleeve, rivet tool",
            "NormTermName": "Sleeve",
            "AssGrpName": "Tool, universal",
            "UsageName": "Rivet tool"
        },
        "60704": {
            "GenartName": "Guide tube, nut wrench",
            "NormTermName": "Guide Tube",
            "AssGrpName": "Special Tools, universal",
            "UsageName": "Groove nut key"
        },
        "60705": {
            "GenartName": "Welding device set",
            "NormTermName": "Welding device set",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cut/Joining Systems"
        },
        "60706": {
            "GenartName": "Mounting Kit, wheel suspension",
            "NormTermName": "Mounting Kit",
            "AssGrpName": "Wheel Suspension",
            "UsageName": null
        },
        "60707": {
            "GenartName": "Gasket, transmission (E-axle)",
            "NormTermName": "Seal",
            "AssGrpName": "Electric Drive",
            "UsageName": "Transmission (E-axle)"
        },
        "60708": {
            "GenartName": "Bushing Set, leaf spring",
            "NormTermName": "Sleeve Kit",
            "AssGrpName": "Suspension/Damping",
            "UsageName": "Leaf Spring Mounting"
        },
        "60709": {
            "GenartName": "Locking clip, exhaust system",
            "NormTermName": "Clip",
            "AssGrpName": "Exhaust System",
            "UsageName": "Exhaust System"
        },
        "60710": {
            "GenartName": "Solar charging controller, battery (solar module)",
            "NormTermName": "Charging controller",
            "AssGrpName": "Accessories, universal",
            "UsageName": "Battery (solar module)"
        },
        "60711": {
            "GenartName": "Jack",
            "NormTermName": "Jack",
            "AssGrpName": "Accessories",
            "UsageName": null
        },
        "60712": {
            "GenartName": "Jack",
            "NormTermName": "Jack",
            "AssGrpName": "Accessories, universal",
            "UsageName": null
        },
        "60713": {
            "GenartName": "Kettle",
            "NormTermName": "Kettle",
            "AssGrpName": "Accessories",
            "UsageName": null
        },
        "60714": {
            "GenartName": "Espresso machine",
            "NormTermName": "Espresso machine",
            "AssGrpName": "Accessories",
            "UsageName": null
        },
        "60715": {
            "GenartName": "Control lever, control unit (air conditioning unit)",
            "NormTermName": "Actuating Lever",
            "AssGrpName": "Air Conditioning",
            "UsageName": "Control unit (air conditioning unit)"
        },
        "60716": {
            "GenartName": "Cable Repair Set, intake air temperature sensor",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Mixture Formation, universal",
            "UsageName": "Intake Air Temperature Sensor"
        },
        "60717": {
            "GenartName": "Oil volume storage, automatic transmission",
            "NormTermName": "Oil volume storage",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "60718": {
            "GenartName": "Repair kit, oil volume storage (autom. transm.)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Oil volume storage (automatic transmission)"
        },
        "60719": {
            "GenartName": "Vacuum line, pressure control valve",
            "NormTermName": "Hose Line",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "Pressure control valve"
        },
        "60720": {
            "GenartName": "Control pressure line, pressure control valve",
            "NormTermName": "Hose Line",
            "AssGrpName": "Exhaust Gas Recirculation (EGR)",
            "UsageName": "Pressure control valve"
        },
        "60721": {
            "GenartName": "Actuator, active bonnet",
            "NormTermName": "Actuator",
            "AssGrpName": "Body",
            "UsageName": "Bonnet"
        },
        "60722": {
            "GenartName": "Mouthpiece, riveting pliers",
            "NormTermName": "Mouthpiece",
            "AssGrpName": "Tool, universal",
            "UsageName": "Riveting Pliers"
        },
        "60723": {
            "GenartName": "Rack base, cabinet system",
            "NormTermName": "Shelf Base",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Cabinet system"
        },
        "60724": {
            "GenartName": "Adjustment Tool, window wash water jet",
            "NormTermName": "Adjustment Tool",
            "AssGrpName": "Vehicle Specific Tools",
            "UsageName": "Windscreen Washer Fluid Jet"
        },
        "60725": {
            "GenartName": "Grease gun (mechanical)",
            "NormTermName": "Grease Gun",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "60726": {
            "GenartName": "Cable pull, tool trolley",
            "NormTermName": "Cable Pull",
            "AssGrpName": "Workshop Equipment",
            "UsageName": "Tool Trolley"
        },
        "60727": {
            "GenartName": "Flashlight set",
            "NormTermName": "Light Set",
            "AssGrpName": "Workshop Devices",
            "UsageName": "Hand Lamp"
        },
        "60728": {
            "GenartName": "Cable repair set, resistor (interior blower)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Heating/Ventilation",
            "UsageName": "Resistor (interior blower)"
        },
        "60729": {
            "GenartName": "Cable repair set, sliding door",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Body",
            "UsageName": "Sliding Door"
        },
        "60730": {
            "GenartName": "Socket",
            "NormTermName": "Socket",
            "AssGrpName": "Interior Equipment",
            "UsageName": null
        },
        "60731": {
            "GenartName": "Accessory set, sound amplifier",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Exhaust System, universal",
            "UsageName": "Sound amplifier"
        },
        "60732": {
            "GenartName": "Oil partition plate, automatic transmission",
            "NormTermName": "Oil partition plate",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Automatic Transmission"
        },
        "60734": {
            "GenartName": "Connecting piece, connection hose (air filter)",
            "NormTermName": "Fitting",
            "AssGrpName": "Air Supply",
            "UsageName": "Intake Hose"
        },
        "60735": {
            "GenartName": "Repair set, shaft drive",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "60736": {
            "GenartName": "Repair set, support (engine holder)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Support"
        },
        "60737": {
            "GenartName": "Repair set, self-aligning support (engine holder)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Self-aligning support"
        },
        "60738": {
            "GenartName": "Repair set, parking lock",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Parking lock"
        },
        "60739": {
            "GenartName": "Repair set, electric clutch",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Electric clutch"
        },
        "60740": {
            "GenartName": "Repair set, guard",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Guard"
        },
        "60741": {
            "GenartName": "Screw set, guard",
            "NormTermName": "Bolt Kit",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Guard"
        },
        "60742": {
            "GenartName": "Repair set, service cover",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Service cover"
        },
        "60743": {
            "GenartName": "Repair set, cover of cooler housing",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Cover of cooler housing"
        },
        "60744": {
            "GenartName": "Repair set, coolant pipe",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Coolant Pipe"
        },
        "60745": {
            "GenartName": "Repair set, engine holder (E-engine)",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Hybrid/Electrical Drive",
            "UsageName": "Engine holder (E-engine)"
        },
        "60746": {
            "GenartName": "Reflector strips, warning markings",
            "NormTermName": "Reflector strips",
            "AssGrpName": "Security Systems, universal",
            "UsageName": "Warning Marking"
        },
        "60747": {
            "GenartName": "Hydraulic cable, suspension",
            "NormTermName": "Pipe",
            "AssGrpName": "Suspension/Damping",
            "UsageName": null
        },
        "60748": {
            "GenartName": "Silicon sealant",
            "NormTermName": "Seal",
            "AssGrpName": "Chemical Products",
            "UsageName": "Silicon sealant"
        },
        "60749": {
            "GenartName": "Gasket, engine oil tank",
            "NormTermName": "Seal",
            "AssGrpName": "Lubrication",
            "UsageName": "Engine oil tank"
        },
        "60750": {
            "GenartName": "Retaining adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "60751": {
            "GenartName": "Structural adhesive",
            "NormTermName": "Adhesive",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "60752": {
            "GenartName": "Activator, adhesive",
            "NormTermName": "Activator",
            "AssGrpName": "Chemical Products",
            "UsageName": null
        },
        "60753": {
            "GenartName": "Cartridge opener",
            "NormTermName": "Cartridge opener",
            "AssGrpName": "Tool, universal",
            "UsageName": null
        },
        "60754": {
            "GenartName": "Antenna combiner",
            "NormTermName": "Antenna combiner",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Aerial"
        },
        "60755": {
            "GenartName": "Accessory set, antenna",
            "NormTermName": "Accessory Kit",
            "AssGrpName": "Communication/ Information Systems, universal",
            "UsageName": "Aerial"
        },
        "60757": {
            "GenartName": "Cable repair kit, actuator (turbocharger)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply",
            "UsageName": "Actuator (turbocharger)"
        },
        "60758": {
            "GenartName": "Cable repair kit, actuator (turbocharger)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Air Supply, universal",
            "UsageName": "Actuator (turbocharger)"
        },
        "60759": {
            "GenartName": "Coolant pipe, loader",
            "NormTermName": "Coolant pipe",
            "AssGrpName": "Air Supply",
            "UsageName": "Charger"
        },
        "60760": {
            "GenartName": "Towbar bush, drawbar",
            "NormTermName": "Bush",
            "AssGrpName": "Trailer",
            "UsageName": "Drawbar"
        },
        "60761": {
            "GenartName": "Intermediate drive shaft",
            "NormTermName": "Shaft",
            "AssGrpName": "Wheel Drive",
            "UsageName": "Drive Shaft"
        },
        "60762": {
            "GenartName": "Lever, door release",
            "NormTermName": "Lever",
            "AssGrpName": "Lock System",
            "UsageName": "Door Release"
        },
        "60763": {
            "GenartName": "Cam, door lock",
            "NormTermName": "Cam",
            "AssGrpName": "Lock System",
            "UsageName": "Door Lock"
        },
        "60764": {
            "GenartName": "Repair kit, door lock",
            "NormTermName": "Repair Kit",
            "AssGrpName": "Lock System",
            "UsageName": "Door Lock"
        },
        "60765": {
            "GenartName": "Frame, door release lever",
            "NormTermName": "Frame",
            "AssGrpName": "Interior Equipment",
            "UsageName": "Lever, door release"
        },
        "60766": {
            "GenartName": "Cable repair kit, solenoid valves (shift valve box)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Automatic Transmission",
            "UsageName": "Solenoid valves (shift valve box)"
        },
        "60767": {
            "GenartName": "Cable repair kit, solenoid valves (shift valve box)",
            "NormTermName": "Cable Repair Set",
            "AssGrpName": "Automatic Transmission, universal",
            "UsageName": "Solenoid valves (shift valve box)"
        },
        "60768": {
            "GenartName": "Brake shoes, parking brake",
            "NormTermName": "Brake Shoe",
            "AssGrpName": "Braking System",
            "UsageName": "Parking Brake"
        }
    }
};

export default genart_descriptions;
