import React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "./useStore";

export const useI18nState = () => {
  const [availableLanguages, selectedLanguage] = useStore((state:any) => [
    state.availableLanguages,
    state.selectedLanguage,
  ]);

  const [
    internalSetLanguage,
    setAvailableLanguages,
    resetAvailableLanguages,
    getCountriesByCurrentLangCode,
  ] = useStore((state:any) => [
    state.setLanguage,
    state.setAvailableLanguages,
    state.resetAvailableLanguages,
    state.getCountriesByCurrentLangCode,
  ]);

  const { i18n } = useTranslation();

  const setLanguage = React.useCallback(
    (lang:any) => {
      i18n.changeLanguage(lang);
    },
    [i18n]
  );

  React.useEffect(() => internalSetLanguage(i18n.language), [i18n.language, internalSetLanguage]);

  return {
    availableLanguages,
    selectedLanguage,

    setLanguage,
    setAvailableLanguages,
    resetAvailableLanguages,
    getCountriesByCurrentLangCode,
  };
};
