
import { useEffect, useState } from "react";
import { useI18nState } from "../../../store";
import {ICountry} from "../countryList/ICountry"

// Dynamically load country list file based on the current Language Code (langCode)
export default function useCountries() {
  const { getCountriesByCurrentLangCode, selectedLanguage } = useI18nState();
  const [countries, setCountries] = useState<ICountry[]>([]);
  
  useEffect(() => {
    getCountriesByCurrentLangCode().then((countries:ICountry[]) =>
      setCountries(countries)
    );
  }, [getCountriesByCurrentLangCode, selectedLanguage]);

  return countries;
}
